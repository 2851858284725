import {
  AfterViewInit, ChangeDetectionStrategy,
  ChangeDetectorRef, Component,
  ElementRef,
  EventEmitter, Input, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Article } from 'src/app/modules/articles/entities/article';
import { Issuer } from 'src/app/modules/auxiliary/entities/issuer';
import { StatesOfWork } from 'src/app/modules/auxiliary/entities/status-of-work';
import { Client } from 'src/app/modules/client/entities/client';
import { ClientSeries } from 'src/app/modules/client/entities/client-series';
import { Series } from 'src/app/modules/client/entities/series';
import { DropDownOption } from 'src/app/modules/common/entities/dropdown-option';
import { ModalNames, DeliverynoteViewTemplate } from 'src/app/modules/common/global';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonFunctions } from 'src/app/modules/common/services/common-functions';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import {
  ShowInvalidFormControls, ValidateDatePicker,
  ValidateNgSelect,
  Delay
} from 'src/app/modules/common/services/validators';
import { WorkTypeArticleTemplate } from 'src/app/modules/work-type/entities/work-type-model-templates';
import { NumberingProcess, NumberingProcessData } from 'src/app/shared/numbering-process/numbering-process';
import { NumberingProcessService } from 'src/app/shared/numbering-process/numbering-process.service';
import swal from 'sweetalert2';
import { BillInvoiceModel } from '../../entities/bill-invoice-model';
import { DeliveryNote } from '../../entities/delivery-note';
import { GenerateNumberModel, SavedGeneratedNumber } from '../../entities/generate-number-model';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtArticle } from '../../entities/main-work-type-article';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { ArticleTemplateService } from '../../services/article-template.service';
import { DeliveryNoteService } from '../../services/delivery-note.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';
import { DeliverynoteService } from 'src/app/modules/delivery-note/services/deliverynote.service';
import * as moment from 'moment';
import { IvaTypes } from 'src/app/modules/auxiliary/entities/iva-types';
import { IvaBreakdown } from 'src/app/modules/auxiliary/entities/iva-breakdown';
import { Town } from 'src/app/modules/auxiliary/entities/town';
import { Patient } from '../../entities/patient';
import { PatientService } from 'src/app/modules/client/services/patient.service';
import { AuxiliaryService } from 'src/app/modules/auxiliary/services/auxiliary.service';
import { DeliveryNoteCalculationService } from 'src/app/modules/common/services/calculations/delivery-note-calculation.service';
import { ArticleCalculationService } from 'src/app/modules/common/services/calculations/article-calculation.service';
import { toFixedNumber } from 'src/app/modules/common/services/calculations/calculation';
import { SubWorkService } from '../../services/sub-work.service';

@Component({
  selector: 'app-delivery-note-modal',
  templateUrl: './delivery-note-modal.component.html',
  styleUrls: ['./delivery-note-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryNoteModalComponent implements OnInit, AfterViewInit {
  @Input() finishedWorkEnabled: boolean;
  @Input() mwtStatusList: StatesOfWork[];
  @Output() OnChangeDeliveryNoteList: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();
  @Output() SyncDeliveryNoteData = new EventEmitter();
  @Output() print = new EventEmitter();
  @ViewChild('patientDecsriptionInput') private patientDecsriptionInput: any;
  @ViewChild('lblpostalCode') lblpostcodeElement: ElementRef;
  @Input() editPostalCodeComponent: any

  showContent = false;
  transferable: any = {};
  subModalView = false;

  // form groups
  deliveryNoteFormGroup: FormGroup;

  // objects
  private _deliveryNoteToBeBilled: DeliveryNote;

  selectedMainWorkType: MainWorkType;
  mwtArticle: MwtArticle;
  selectedSubWork: SubWork;
  selectedWork: Work;
  hasLoaded = true;
  deliveryNote: DeliveryNote;
  issuer: Issuer;
  usedSeries: Series;
  client: Client;
  generateNumberModel: GenerateNumberModel;
  numOfDigits = 5;
  today: any;
  billInvoiceModel: BillInvoiceModel;
  usedSeriesForInvoiceHeader: Series;
  generateNumberModelForInvoiceHeader: GenerateNumberModel;

  // lists
  deliveryNoteList: DeliveryNote[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];
  mainWorkTypeList: MainWorkType[] = [];
  seriesList: Series[] = [];

  issureList: Issuer[] = [];
  issureDropdownList: DropDownOption[] = [];

  deliveryMwtArticleList: MwtArticle[] = [];
  savedGeneratedNumberList: SavedGeneratedNumber[] = [];
  articleTemplateList: WorkTypeArticleTemplate[] = [];
  seriesListForBilling: Series[] = [];
  articleList: Article[] = [];
  ivaTypeList: IvaTypes[] = [];
  ivaBreakdownList: IvaBreakdown[] = [];

  selectedTemplate: { value: number, description: string };
  reportTemplateList: { value: number, description: string }[] = [];


  currentNPData: NumberingProcessData;

  dnNumberingProcess: NumberingProcess;
  dnSeries: {
    id: number;
    type: 'Work' | 'Delivery Note' | 'Bill' | 'Budget';
    series: string;
  };
  dnMaxSize = 5;
  dnFormGroup: FormGroup;

  ivNumberingProcess: NumberingProcess;
  ivSeries: {
    id: number;
    type: 'Work' | 'Delivery Note' | 'Bill' | 'Budget';
    series: string;
  };
  ivMaxSize = 5;

  selectedDeliveryNoteId = 0;

  isEdit = false;

  patient: Patient;
  public patientFormGroup: FormGroup;
  public patientEditModel: Patient;
  public townDropdownList: Town[] = [];
  townListDropdownList: DropDownOption[] = [];
  public townDropdownActiveList: Town[] = [];
  townListDropdownActiveList: DropDownOption[] = [];
  public checkClientIdAvailable = false;
  public requiredMessage: string;
  inputDatepickerTimer: any;
  townActiveList: Town[] = [];
  editTown: Town;

  constructor(
    private _modalService: ModalService,
    public _commonUiService: CommonUIService,
    private _formBuilder: FormBuilder,
    private _apiService: ApiService,
    private _workService: WorkService,
    public commonUiService: CommonUIService,
    public _commonDataService: CommonDataService,
    private _numberingProcessService: NumberingProcessService,
    private _dropdownService: DropdownService,
    private _toastyService: Toast,
    private _articleTemplateService: ArticleTemplateService,
    private __service: DeliveryNoteService,
    private cdref: ChangeDetectorRef,
    private _deliverynoteService: DeliverynoteService,
    private _patientService: PatientService, private auxiliaryService: AuxiliaryService,
    private _deliveryNoteCalculationService: DeliveryNoteCalculationService,
    private _articleCalculationService: ArticleCalculationService,
    private _subWorkService: SubWorkService
  ) {
    this.deliveryNote = new DeliveryNote();
    this.client = new Client();
    this.issuer = new Issuer();
    this.usedSeries = new Series();
    this.selectedWork = new Work();
    this.mwtArticle = new MwtArticle();
    this.usedSeriesForInvoiceHeader = new Series();
    this._deliveryNoteToBeBilled = new DeliveryNote();
    this.billInvoiceModel = new BillInvoiceModel();
    this.generateNumberModel = new GenerateNumberModel();
    this.generateNumberModelForInvoiceHeader = new GenerateNumberModel();
    this.patientEditModel = new Patient();
    this.GetTownList();
    this.InitFormGroups();

    this.auxiliaryService.onUpdateTown.subscribe((arg) => {
      if (arg && arg.model == 'edit') {
        this.patientFormGroup.controls.postalCode.setValue(arg.pc);
        this.patientFormGroup.controls.townId.setValue(arg.description + ' - ' + arg.pc);
        this.lblpostcodeElement.nativeElement.click();
      }
      if (document.querySelector('#delivery-modal-editPatient')) {
        document.querySelector('#delivery-modal-editPatient').classList
          .remove('delivery-modal-editPatient--index');
      }
    });
  }

  ngOnInit() {
    this.reportTemplateList = DeliverynoteViewTemplate;
    this.editTown = new Town();
  }

  ngAfterViewInit(): void {
    this.dnNumberingProcess = new NumberingProcess({
      isNextAvailableNumber: true,
      nextAvailableNumber: 0,
      isAnother: false,
      isNoNumberAssigned: false,
      isOtherAvailableNumbers: false,
      anotherNumber: 1,
      otherAvailableNumber: 0
    });

    this.ivNumberingProcess = new NumberingProcess({
      isNextAvailableNumber: true,
      nextAvailableNumber: 0,
      isAnother: false,
      isNoNumberAssigned: false,
      isOtherAvailableNumbers: false,
      anotherNumber: 1,
      otherAvailableNumber: 0
    });

    this._modalService.modalSubject.subscribe(async modalObject => {
      if (modalObject) {
        if (modalObject.modal === ModalNames.DeliveryNoteModal) {
          if (modalObject.flag && modalObject.transferable != null) {
            this.selectedMainWorkTypeList = modalObject.transferable.selectedMainWorkTypeList;
            this.selectedSubWork = modalObject.transferable.selectedSubWork;
            this.selectedWork = modalObject.transferable.selectedWork;
            this.mainWorkTypeList = modalObject.transferable.mainWorkTypeList;
            this.LoadList();
            this.subModalView = false;
            if (!modalObject.transferable.isEdit) {
              this.transferable = modalObject.transferable;
              this.showContent = true;
              await this.OnOpenGenerateDeliveryNoteModal();
            } else if (
              modalObject.transferable.isEdit &&
              modalObject.transferable.selectedDeliveryNoteId > 0
            ) {
              this.selectedDeliveryNoteId = modalObject.transferable.selectedDeliveryNoteId;
              this.transferable = modalObject.transferable;
              this.showContent = true;
              await this.OnEditDeliveryNoteModal();
            }
            this.cdref.markForCheck();
          }
        }
      }
    });
  }

  InitFormGroups() {
    this.deliveryNoteFormGroup = this._formBuilder.group({
      generateDate: ['', [ValidateDatePicker]],
      issuerId: [],
      seriesId: ['', [ValidateNgSelect]],
      generateNo: [],
      concept: [],
      articleId: [],
      workFinished: []
    });

    this.patientFormGroup = this._formBuilder.group({
      name: ['', [Validators.required]],
      surnames: [],
      historyNumber: [],
      dateOfBirth: [],
      dateOfBirthView: [''],
      clientName: [],
      nif: [],
      address: [],
      postalCode: [''],
      townId: [],
      provinceName: [],
      countryName: [],
      notes: [],
      email: ['', [Validators.email]]
    });
  }

  OnClose() {
    this.showContent = false;
  }

  LoadList() {
    this.articleList = this._dropdownService.articleList
      ? this._dropdownService.articleList
      : [];

    this.issureList = this._dropdownService.issureList
      ? this._dropdownService.issureList
      : [];

    if (this.issureList) {
      this.issureList = this.issureList.filter(x => x.status);
      this.issureDropdownList = [];
      this.issureDropdownList.push(
        new DropDownOption(
          '0',
          this._commonDataService.localizationLabelList['select'],
          false
        )
      );
      this.issureList.forEach(element => {
        const option = new DropDownOption(
          element.id.toString(),
          element.description,
          false
        );
        this.issureDropdownList.push(option);
      });
      this.issureDropdownList = CommonFunctions.Sort(
        this.issureDropdownList,
        'label'
      );
    }
    this.ivaTypeList = this._dropdownService.ivaTypeList;
    this.LoadTownList();
    this.cdref.markForCheck();
  }

  LoadTownList() {
    this._apiService.get(API.town.getTownList)
      .subscribe(res => {
        this.townDropdownList = res;
        this.townDropdownActiveList = res.filter(x => x.status);
        this.townDropdownList = CommonFunctions.Sort(this.townDropdownList, 'description');
        this.townDropdownActiveList = CommonFunctions.Sort(this.townDropdownActiveList, 'description');
      });
  }

  async OnOpenGenerateDeliveryNoteModal() {
    try {
      this.isEdit = false;
      this.deliveryNoteFormGroup.controls.seriesId.enable();
      this.commonUiService.isSpinnerVisible = true;
      this.cdref.markForCheck();
      this.deliveryNoteFormGroup.reset();
      this.articleTemplateList = [];
      this.deliveryMwtArticleList = [];
      this.ivaBreakdownList = [];

      this.deliveryNote = new DeliveryNote();
      this.deliveryNote.articleList = [];

      this.deliveryNote.generateDateView = this.commonUiService.ConvertToPickerDateFormat(
        new Date().toISOString()
      );
      this.deliveryNote.generateDate = this.commonUiService.ConvertToPickerDateFormat(
        new Date().toISOString()
      );
      this.issuer = new Issuer();
      this.client = new Client();
      this.patient = new Patient();
      this.deliveryNote.sumPrice = 0;
      this.deliveryNote.totalDiscount = 0;
      this.deliveryNote.totalFinalPrice = 0;
      this.deliveryNote.totalVatAmount = 0;
      this.ivaBreakdownList = [];
      this.commonUiService.isSpinnerVisible = true;

      const defaultIssuer = this.issureList.find(a => a.isDefault);

      this._apiService.get(API.invoice.getSeriesByIssuerId + defaultIssuer.id)
        .subscribe((list: Series[]) => {
          list = list ? list : [];
          // tslint:disable-next-line: max-line-length
          this.seriesList = list.filter(x => x.seriesType.toLowerCase() === 'delivery note'
            && x.status && x.year === (new Date().getFullYear().toString()));
        }, err => { console.log(err + 'series load error'); });

      if (this.selectedSubWork.id) {
        const idList = this.selectedMainWorkTypeList.map(m => ({
          id: Number(m.id)
        }));
        if (idList) {
          const articleTemplateList = await this._articleTemplateService.GetArticleTemplateListByMainWorkTypeIdList(
            idList
          );
          this.articleTemplateList = articleTemplateList
            ? articleTemplateList
            : [];
        }
        if (this.articleTemplateList.length > 0) {
          this.deliveryNoteList = await this.__service.GetDeliveryNoteListBySubWorkId(
            this.selectedSubWork.id
          );
          if (this.deliveryNoteList.length > 0) {
            this.deliveryNoteList.forEach((deliveryNote, i: number) => {
              deliveryNote.cssClass = this.GetDeliveryNoteBorderColorClass(i);
              if (deliveryNote.articleList.length > 0) {
                deliveryNote.articleList.forEach(article => {
                  const articleTemplate: WorkTypeArticleTemplate = this.articleTemplateList.find(
                    x => Number(x.mwtArticleId) === Number(article.mwtArticleId)
                  );
                  if (articleTemplate) {
                    articleTemplate.cssBorderColorClass = deliveryNote.cssClass;
                  }
                });
              }
            });
          }
          const articleList: MwtArticle[] = [];

          this.articleTemplateList.forEach(articleTemplate => {
            if (articleTemplate.cssBorderColorClass === undefined || articleTemplate.cssBorderColorClass === '') {
              if (!articleTemplate.isClosed) {
                const mwtArticle = new MwtArticle();
                mwtArticle.articleId = articleTemplate.articleId;
                mwtArticle.description1 = articleTemplate.articleName;
                mwtArticle.mainWorkTypeId = articleTemplate.workTypeId;
                mwtArticle.id = articleTemplate.mwtArticleId;
                mwtArticle.quantity = articleTemplate.quantity;
                mwtArticle.price = articleTemplate.price;
                mwtArticle.code = articleTemplate.code;
                mwtArticle.discount = articleTemplate.discount;
                mwtArticle.discountAmount = articleTemplate.discountAmount;
                mwtArticle.isAmount = articleTemplate.isAmount;

                mwtArticle.waste = articleTemplate.waste;
                mwtArticle.finalPrice = articleTemplate.finalPrice;
                mwtArticle.quantityWithWaste = articleTemplate.quantityWithWaste;
                mwtArticle.priceWithDiscount = articleTemplate.priceWithDiscount;
                mwtArticle.lineSubTotal = articleTemplate.lineSubTotal;
                mwtArticle.lineDiscountAmount = articleTemplate.lineDiscountAmount;

                //iva
                if (articleTemplate.ivaTypeId && articleTemplate.ivaTypeId != 0) {
                  mwtArticle.ivaTypeId = articleTemplate.ivaTypeId;
                  mwtArticle.ivaPercentage = articleTemplate.ivaPercentage;
                  mwtArticle.ivaAmount = articleTemplate.ivaAmount;
                  const ivaBreakdown = new IvaBreakdown();
                  ivaBreakdown.ivaTypeId = articleTemplate.ivaTypeId;
                  ivaBreakdown.iva = articleTemplate.ivaPercentage + '%';
                  ivaBreakdown.base = this._deliveryNoteCalculationService.getIvaBaseAmount(mwtArticle.priceWithDiscount, mwtArticle.quantityWithWaste);
                  ivaBreakdown.ivaAmount = articleTemplate.ivaAmount;
                  this.ivaBreakdownList.push(ivaBreakdown);
                }
                this.deliveryNote.totalVatAmount = toFixedNumber(this.deliveryNote.totalVatAmount + (mwtArticle.ivaAmount ? mwtArticle.ivaAmount : 0));
                // end iva

                this.deliveryNote.sumPrice = toFixedNumber(this.deliveryNote.sumPrice + (mwtArticle.lineSubTotal ? mwtArticle.lineSubTotal : 0))
                this.deliveryNote.totalFinalPrice = toFixedNumber(this.deliveryNote.totalFinalPrice + mwtArticle.finalPrice);
                this.deliveryNote.totalDiscount = toFixedNumber(this.deliveryNote.totalDiscount + (mwtArticle.lineDiscountAmount ? mwtArticle.lineDiscountAmount : 0));
                articleList.push(mwtArticle);
              }
            }
          });

          if (this.ivaBreakdownList && this.ivaBreakdownList.length > 0) {
            this.ivaBreakdownList = this._deliverynoteService.LoadVatBreakdown(this.ivaBreakdownList);
          }
          this.deliveryNote.sumPrice = this.deliveryNote.sumPrice ? toFixedNumber(this.deliveryNote.sumPrice) : 0;
          this.deliveryNote.totalDiscount = this.deliveryNote.totalDiscount ? this.deliveryNote.totalDiscount : 0;
          this.deliveryNote.totalVatAmount = this.deliveryNote.totalVatAmount ? this.deliveryNote.totalVatAmount : 0;
          this.deliveryNote.totalWithDiscount = this._deliveryNoteCalculationService.getTotalWithDiscountAmount(this.deliveryNote.sumPrice, this.deliveryNote.totalDiscount);
          this.deliveryNote.totalPrice = this.deliveryNote.totalFinalPrice ? this.deliveryNote.totalFinalPrice : 0;
          this.deliveryMwtArticleList = articleList;
          this.cdref.markForCheck();
        }
      }
      if (this.selectedWork.clientId !== undefined) {
        this.deliveryNote.clientId = this.selectedWork.clientId;
        this._workService
          .GetClientById(this.selectedWork.clientId)
          .then(client => {
            this.client = client;
            this.cdref.markForCheck();
            this._workService
              .GetSeriesByClientId(this.selectedWork.clientId)
              .then((list: ClientSeries[]) => {
                if (list) {
                  const series: ClientSeries = list.find(
                    x => x.seriesType === 'Delivery Note'
                  );
                  if (series) {
                    this.deliveryNote.issuerId = series.issuerId
                      ? series.issuerId.toString()
                      : '0';
                    const _seriesId = series != null ? series.seriesId : 0;
                    this.OnChangeIssuer(_seriesId);
                  } else {
                    // this.deliveryNote.issuerId = '0';
                    // this.deliveryNote.seriesId = 0;
                    this.deliveryNote.issuerId = (defaultIssuer) ? defaultIssuer.id.toString() : '0';
                    this.deliveryNote.seriesId = (this.seriesList.length > 0) ? this.seriesList[0].id : 0;
                    if (this.seriesList.length > 0) {
                      this.OnChangeIssuer(this.seriesList[0].id);
                    }
                    this.cdref.markForCheck();
                  }
                  if (this.issuer) {
                    this.issuer.issuerTown = '';
                    if (this.issuer.townId) {
                      this.issuer.issuerTown = this._dropdownService.townList.find(x => x.id == this.issuer.townId).description;
                    }
                  }
                }
              });
          });
      } else {
        this.deliveryNote.issuerId = '0';
        this.deliveryNote.seriesId = 0;
        this.cdref.markForCheck();
      }

      if (this.selectedWork.patientId != null) {
        this.deliveryNote.patientId = this.selectedWork.patientId;
        this.LoadPatient();
      }

    } catch (error) {
      console.log(error);
    } finally {
      this.commonUiService.isSpinnerVisible = false;
      // when finished work enabled in auxiliary settings, (set default true or false).
      this.deliveryNote.workFinished = this.finishedWorkEnabled;
      this.cdref.markForCheck();
    }
  }

  async GetTownList() {
    const townList = await this.auxiliaryService.GetTownList()
    if (townList) {
      this.townActiveList = [];
      this.townActiveList = townList.filter(x => x.status);
    }
  }

  async OnEditDeliveryNoteModal() {
    if (this.selectedDeliveryNoteId > 0) {
      this.isEdit = true;
      this.deliveryNoteFormGroup.controls.seriesId.disable();
      this.commonUiService.isSpinnerVisible = true;
      this.cdref.markForCheck();
      this.deliveryNoteFormGroup.reset();
      this.deliveryMwtArticleList = [];
      this.deliveryNote = new DeliveryNote();
      this.deliveryNote.articleList = [];
      this.client = new Client();
      this.patient = new Patient();

      this.commonUiService.isSpinnerVisible = true;
      if (this.selectedSubWork.id) {
        const idList = this.selectedMainWorkTypeList.map(m => ({
          id: Number(m.id)
        }));
        if (idList) {
          const articleTemplateList = await this._articleTemplateService.GetArticleTemplateListByMainWorkTypeIdList(
            idList
          );
          this.articleTemplateList = articleTemplateList
            ? articleTemplateList
            : [];
        }
        if (this.articleTemplateList.length > 0) {
          this.deliveryNoteList = await this.__service.GetDeliveryNoteListBySubWorkId(
            this.selectedSubWork.id
          );
          if (this.deliveryNoteList.length > 0) {
            this.deliveryNoteList.forEach((deliveryNote, i: number) => {
              deliveryNote.cssClass = this.GetDeliveryNoteBorderColorClass(i);
              if (deliveryNote.articleList.length > 0) {
                deliveryNote.articleList.forEach(article => {
                  const articleTemplate: WorkTypeArticleTemplate = this.articleTemplateList.find(
                    x => Number(x.mwtArticleId) === Number(article.mwtArticleId)
                  );
                  if (articleTemplate) {
                    articleTemplate.cssBorderColorClass = deliveryNote.cssClass;
                  }
                });
              }
            });
          }
        }
      }

      this.deliveryNote = await this.GetDeliveryNoteById(
        this.selectedDeliveryNoteId
      );

      if (this.selectedWork.patientId != null) {
        this.deliveryNote.patientId = this.selectedWork.patientId;
        this.LoadPatient();
      }

      if (this.selectedWork.clientId) {
        this.deliveryNote.clientId = this.selectedWork.clientId;
        this.client = await this._workService.GetClientById(
          this.selectedWork.clientId
        );
      }

      const _deliveryNote = Object.assign({}, this.deliveryNote);
      if (this.deliveryNote.id) {
        this.deliveryNote.issuerId =
          this.deliveryNote.issuerId != null
            ? this.deliveryNote.issuerId.toString()
            : '0';
        this.deliveryNote.seriesId =
          this.deliveryNote.seriesId != null
            ? this.deliveryNote.seriesId
            : 0;
        this.OnChangeIssuer(this.deliveryNote.seriesId, true);
        if (this.deliveryNote.generateDate !== undefined) {
          const generateDate: string = this.deliveryNote.generateDate
            .toString()
            .substring(0, 10);
          const date: string[] = generateDate.split('-');
          this.deliveryNote.generateDateView = {
            day: Number(date[2]),
            month: Number(date[1]),
            year: Number(date[0])
          };
        }
        this.deliveryNote.sumPrice = 0;
        this.deliveryNote.totalDiscount = 0;
        this.deliveryNote.totalFinalPrice = 0;
        this.deliveryNote.totalVatAmount = 0;
        this.ivaBreakdownList = [];
        const articleList: MwtArticle[] = [];
        this.deliveryNote.articleList.forEach(article => {
          const articleTemplate = this.articleTemplateList.find(
            x => x.mwtArticleId === article.mwtArticleId
          );
          if (articleTemplate !== undefined) {
            const mwtArticle = new MwtArticle();
            mwtArticle.articleId = articleTemplate.articleId;
            mwtArticle.description1 = articleTemplate.articleName;
            mwtArticle.code = articleTemplate.code;
            mwtArticle.mainWorkTypeId = articleTemplate.workTypeId;
            mwtArticle.id = articleTemplate.mwtArticleId;
            mwtArticle.quantity = articleTemplate.quantity;
            mwtArticle.price = articleTemplate.price;
            mwtArticle.deliveryNoteArticleId = article.deliveryNoteArticleId;
            mwtArticle.discount = articleTemplate.discount;
            mwtArticle.discountAmount = articleTemplate.discountAmount;
            mwtArticle.isAmount = articleTemplate.isAmount;

            mwtArticle.waste = articleTemplate.waste;
            mwtArticle.finalPrice = articleTemplate.finalPrice;
            mwtArticle.quantityWithWaste = articleTemplate.quantityWithWaste;
            mwtArticle.priceWithDiscount = articleTemplate.priceWithDiscount;
            mwtArticle.lineSubTotal = articleTemplate.lineSubTotal;
            mwtArticle.lineDiscountAmount = articleTemplate.lineDiscountAmount;

            this.deliveryNote.sumPrice = toFixedNumber(this.deliveryNote.sumPrice + (mwtArticle.lineSubTotal ? mwtArticle.lineSubTotal : 0))
            this.deliveryNote.totalFinalPrice = toFixedNumber(this.deliveryNote.totalFinalPrice + mwtArticle.finalPrice);
            this.deliveryNote.totalDiscount = toFixedNumber(this.deliveryNote.totalDiscount + (mwtArticle.lineDiscountAmount ? mwtArticle.lineDiscountAmount : 0));

            //iva
            if (articleTemplate.ivaTypeId && articleTemplate.ivaTypeId != 0) {
              mwtArticle.ivaTypeId = articleTemplate.ivaTypeId;
              mwtArticle.ivaPercentage = articleTemplate.ivaPercentage;
              mwtArticle.ivaAmount = articleTemplate.ivaAmount;
              const ivaBreakdown = new IvaBreakdown();
              ivaBreakdown.ivaTypeId = articleTemplate.ivaTypeId;
              ivaBreakdown.iva = articleTemplate.ivaPercentage + '%';
              ivaBreakdown.base = this._deliveryNoteCalculationService.getIvaBaseAmount(mwtArticle.priceWithDiscount, mwtArticle.quantityWithWaste);
              ivaBreakdown.ivaAmount = articleTemplate.ivaAmount;
              this.ivaBreakdownList.push(ivaBreakdown);
            }
            articleList.push(mwtArticle);
          }
        });
        if (this.ivaBreakdownList && this.ivaBreakdownList.length > 0) {
          this.ivaBreakdownList = this._deliverynoteService.LoadVatBreakdown(this.ivaBreakdownList);
        }

        this.deliveryNote.totalVatAmount = this.deliveryNote.tax;
        this.deliveryNote.sumPrice = this.deliveryNote.sumPrice ? toFixedNumber(this.deliveryNote.sumPrice) : 0;
        this.deliveryNote.totalDiscount = this.deliveryNote.totalDiscount ? this.deliveryNote.totalDiscount : 0;
        this.deliveryNote.totalVatAmount = this.deliveryNote.totalVatAmount ? this.deliveryNote.totalVatAmount : 0;
        this.deliveryNote.totalWithDiscount = this._deliveryNoteCalculationService.getTotalWithDiscountAmount(this.deliveryNote.sumPrice, this.deliveryNote.totalDiscount);
        this.deliveryNote.totalPrice = this.deliveryNote.totalFinalPrice ? this.deliveryNote.totalFinalPrice : 0;
        this.deliveryMwtArticleList = articleList;
        this.deliveryNote.generateNo = _deliveryNote.generateNo;

        if (this.selectedWork.clientId) {
          this.deliveryNote.clientId = this.selectedWork.clientId;
        }

        if (this.selectedWork.patientId != null) {
          this.deliveryNote.patientId = this.selectedWork.patientId;
        }

        this.cdref.markForCheck();
      }

      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  OnChangeIssuer(seriesId?: number, onLoad?: boolean) {
    if (
      this.deliveryNote.issuerId !== undefined &&
      Number(this.deliveryNote.issuerId) !== 0
    ) {
      this.deliveryNote.generateNo = '';
      const issuer = this.issureList.find(
        x => Number(x.id) === Number(this.deliveryNote.issuerId)
      );
      if (issuer !== undefined) {
        this.issuer = issuer;
        const year = new Date().getFullYear();
        this.commonUiService.isSpinnerVisible = true;
        this._workService
          .GetSeriesListByYearAndIssuerId(year.toString(), this.issuer.id)
          .then(seriesList => {
            this.seriesList = seriesList.filter(
              x => x.seriesType === 'Delivery Note' && x.status
                && x.year === (new Date().getFullYear().toString())
            );

            if (seriesId != null && seriesId > 0) {
              this.deliveryNote.seriesId = seriesId;
            } else if (this.seriesList != null && this.seriesList.length > 0) {
              this.deliveryNote.seriesId = this.seriesList[0].id;
            } else {
              this.deliveryNote.seriesId = 0;
            }

            if (onLoad !== true) {
              this.OnSelectSeries();
            }

            this.cdref.markForCheck();
            this.commonUiService.isSpinnerVisible = false;
          });
      }
    }
  }

  async OnSelectSeries() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (Number(this.deliveryNote.seriesId) > 0) {
        const series = await this._workService.GetSeriesById(
          this.deliveryNote.seriesId
        );
        if (series) {
          await this.LoadDnNumberingProcessComponent(series);
          const genNumberObject = this._numberingProcessService.GetGeneratedNumber(
            this.dnNumberingProcess,
            this.dnMaxSize,
            { id: series.id, type: 'Delivery Note', series: series.series }
          );
          if (genNumberObject) {
            this.deliveryNote.generateNo = genNumberObject.generateNumber;
            this.currentNPData = genNumberObject as NumberingProcessData;
          }
        }
      } else {
        this.deliveryNote.generateNo = '';
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  private async GetDeliveryNoteById(id: number) {
    return this._apiService
      .get(API.main_work_type_work.getDeliveryNoteById + id)
      .toPromise();
  }

  private async LoadDnNumberingProcessComponent(series: Series) {
    try {
      if (series) {
        this.dnSeries = {
          id: series.id,
          type: 'Delivery Note',
          series: series.series
        };
        this.dnNumberingProcess = new NumberingProcess({
          isNextAvailableNumber: true,
          nextAvailableNumber: series.lastNumber,
          isAnother: false,
          isNoNumberAssigned: false,
          isOtherAvailableNumbers: false,
          anotherNumber: 1,
          otherAvailableNumber: 0
        });
        const reservedNumberList = await this._workService.GetSavedNumberListBySeriesId(
          series.id
        );
        if (reservedNumberList) {
          this.dnNumberingProcess.otherAvailableNumberList = reservedNumberList.map(
            r => r.savedNumber.toString()
          );
        }
        this.cdref.markForCheck();
      }
    } catch (error) {
      console.log(error);
    }
  }

  private GetDeliveryNoteBorderColorClass(index: number): string {
    if (index !== undefined) {
      return this._workService.deliveryNoteBorderColorClassList[index];
    }
  }

  async OnSaveDeliveryNote(event?: any) {
    try {
      if (this.deliveryNote) {
        if (this.deliveryNoteFormGroup.invalid) {
          ShowInvalidFormControls(this.deliveryNoteFormGroup);
          return;
        }
        this.commonUiService.isSpinnerVisible = true;
        this.deliveryNote.articleList = [];
        this.deliveryNote.subTotal = 0;
        this.deliveryNote.discount = 0;
        this.deliveryNote.tax = 0;
        this.deliveryMwtArticleList.forEach(mwtArticle => {
          const article = new Article();
          article.mwtArticleId = mwtArticle.id ? mwtArticle.id : 0;
          article.id = mwtArticle.articleId ? mwtArticle.articleId : 0;
          article.description1 = mwtArticle.description1;
          article.code = mwtArticle.code;
          article.ivaTypeId = mwtArticle.ivaTypeId;
          article.ivaAmount = mwtArticle.ivaAmount;
          article.iva = mwtArticle.ivaPercentage;
          article.articleDiscount = mwtArticle.discount
            ? mwtArticle.discount
            : 0;
          article.articleDiscountAmount = mwtArticle.discountAmount
            ? mwtArticle.discountAmount
            : 0;
          article.articleFinalPrice = mwtArticle.finalPrice
            ? mwtArticle.finalPrice
            : 0;
          article.articleIsAmount = mwtArticle.isAmount ? mwtArticle.isAmount : false;
          article.articlePrice = mwtArticle.price ? mwtArticle.price : 0;
          article.articleQuantity = mwtArticle.quantity ? mwtArticle.quantity : 0;
          article.articleWaste = mwtArticle.waste ? mwtArticle.waste : 0;
          article.mainWorkTypeId = mwtArticle.mainWorkTypeId ? mwtArticle.mainWorkTypeId : 0;
          article.deliveryNoteArticleId = mwtArticle.deliveryNoteArticleId ? mwtArticle.deliveryNoteArticleId : 0;
          article.isDiscountOverridden = true;
          article.lineDiscountAmount = mwtArticle.lineDiscountAmount ? mwtArticle.lineDiscountAmount : 0;
          article.quantityWithWaste = mwtArticle.quantityWithWaste ? mwtArticle.quantityWithWaste : 0;
          article.lineSubTotal = mwtArticle.lineSubTotal ? mwtArticle.lineSubTotal : 0;
          article.discountAmount = mwtArticle.discountAmount ? mwtArticle.discountAmount : 0;
          article.priceWithDiscount = mwtArticle.priceWithDiscount ? mwtArticle.priceWithDiscount : 0;

          this.deliveryNote.articleList.push(article);
        });
        const deliveryNoteDateValue = this.deliveryNote.generateDate;
        if (this.deliveryNote.generateDate !== undefined) {
          this.deliveryNote.generateDate = this.commonUiService.ChangeNgbDateToCustomFormat(
            this.deliveryNote.generateDateView
          );
        }
        if (
          this.deliveryNote.id !== undefined &&
          this.deliveryNote.id !== null &&
          this.deliveryNote.id !== 0
        ) {
          if (Number(this.deliveryNote.issuerId) === 0) {
            this.deliveryNote.issuerId = null;
          }
          if (this.deliveryNote.seriesId === 0) {
            this.deliveryNote.seriesId = null;
          }
          this.deliveryNote.description = this.deliveryNote.concept;
        } else {
          if (Number(this.deliveryNote.issuerId) === 0) {
            this.deliveryNote.issuerId = null;
          }
          if (this.deliveryNote.seriesId === 0) {
            this.deliveryNote.seriesId = null;
          }
          this.deliveryNote.status = true;
          this.deliveryNote.description = this.deliveryNote.concept;
          this.deliveryNote.subWorkId = this.selectedSubWork.id;
          this.deliveryNote.isInvoiced = false;
        }

        this.deliveryNote.subTotal = this.deliveryNote.sumPrice
        this.deliveryNote.discount = this.deliveryNote.totalDiscount;
        this.deliveryNote.totalPrice = this.deliveryNote.totalFinalPrice;
        this.deliveryNote.tax = this.deliveryNote.totalVatAmount;
        this.deliveryNote.isMailed = false;
        let delNote = new DeliveryNote();
        delNote = { ...this.deliveryNote };
        delNote.generateDateView = undefined;
        delNote.issuerId = Number(delNote.issuerId);

        const response = await this.GenerateDeliveryNote(delNote);
        if (response) {

          const body = JSON.parse(response['_body']);
          switch (body['_statusCode']) {
            case 200:
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList[
                  'delivery_notes'
                ],
                msg: this._commonDataService.localizationLabelList[
                  'save_success'
                ]
              });
              this.OnClose();
              this.commonUiService.isSpinnerVisible = false;
              // continue numbering process
              if (this.currentNPData) {
                if (this.currentNPData.generateNumber) {
                  await this._numberingProcessService.ContinueNumberingProcess(
                    this.currentNPData
                  );
                }
              }

              let list = this.deliveryMwtArticleList.filter(
                dna => dna.id !== undefined
              );
              list = list ? list : [];
              if (list.length > 0) {
                await this.SaveMwtArticleList(list);
              }
              // if 'establish finished work when making delivery notes' configurations enabled;
              if (this.finishedWorkEnabled && this.deliveryNote.workFinished) {
                // change the status to 'finalized' in all the type of works which are related to the specific delivery note.

                let mwtIdList = this.deliveryMwtArticleList.map(
                  da => da.mainWorkTypeId
                );
                mwtIdList = mwtIdList
                  ? mwtIdList.filter((x, i, a) => a.indexOf(x) === i)
                  : [];

                if (mwtIdList.length > 0) {
                  const idlist = mwtIdList.filter((x, i, a) => a.indexOf(x) === i);

                  if (idlist) {
                    for (let index = 0; index < idlist.length; index++) {
                      const mwtId = idlist[index];
                      const mwtToBeUpdated = this.selectedMainWorkTypeList.find(
                        mwt => Number(mwt.id) === mwtId
                      );
                      if (mwtToBeUpdated && this.mwtStatusList) {
                        const mwtStatus = this.mwtStatusList.find(
                          s => s.description.toLowerCase() === 'finalizado'
                        );
                        if (mwtStatus) {
                          mwtToBeUpdated.manualStatus = true;
                          mwtToBeUpdated.workTypeStatusId = mwtStatus.id;
                          await this._workService.UpdateMainWorkType(
                            mwtToBeUpdated
                          );
                        }
                      }
                    }
                    if (this.selectedMainWorkTypeList.length === idlist.length
                      && this.selectedSubWork) {
                      this.selectedSubWork.subWorkStatus = 'Finalizado';
                      this.selectedSubWork.manualStatus = true;

                      const subWork = Object.assign({}, this.selectedSubWork);
                      subWork.mainWorkTypeList = [];
                      subWork.doctorId = subWork.doctorId ? subWork.doctorId : null;
                      await this._subWorkService
                        .EditSubWork(subWork);

                      const subWorkList = await this._subWorkService
                        .GetSubWorkListByWorkId(this.selectedWork.id);

                      if (subWorkList && subWorkList.length > 0) {
                        const finalizedList = subWorkList
                          .filter(s => s.subWorkStatus === 'Finalizado');
                        if (finalizedList.length === subWorkList.length) {
                          this.selectedWork.workStatus = 'Finalizado';
                          await this._workService.EditWork(this.selectedWork);
                        }
                      }
                    }
                  }
                }
                this.OnChangeDeliveryNoteList.emit(mwtIdList);
              }
              this.SyncDeliveryNoteData.emit('');

              this._workService.SetDetailObject({
                selectedMainWorkTypeList: this.selectedMainWorkTypeList,
                selectedMainWorkType: this.selectedMainWorkType,
                selectedSubWork: this.selectedSubWork,
                selectedWork: this.selectedWork,
                mainWorkTypeList: this.mainWorkTypeList,
                invokeComponents: ['Article'],
                transferable: []
              });


              swal({
                title: this._commonDataService.localizationLabelList['delivery_notes'],
                text: this._commonDataService.localizationLabelList['print_warning'],
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this._commonDataService.localizationLabelList['accept_yes'],
                cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
              }).then((result) => {
                if (result.value) {
                  this.OnPrintDeliveryNote(this.deliveryNote).then();
                }
                this.OnClose();
              });


              break;
            case 409:
              this._toastyService.warning({
                title: this._commonDataService.localizationLabelList[
                  'delivery_notes'
                ],
                msg: 'Number is already in use!'
              });
              break;
            default:
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList[
                  'delivery_notes'
                ],
                msg: this._commonDataService.localizationLabelList['save_error']
              });
              break;
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnPrintDeliveryNote(deliveryNote: DeliveryNote) {
    if (deliveryNote) {
      deliveryNote.issuer = this.issuer;
      if (deliveryNote.issuer) {
        const town = this._dropdownService.townList.find(x => x.id == deliveryNote.issuer.townId);
        deliveryNote.issuerTown = town ? town.description : '';
        deliveryNote.issuer.provinceName = town ? town.provinceName : '';
      }
      this.print.emit(deliveryNote);
    }
    // if (deliveryNote) {
    //   // this.selectedTemplate = DeliverynoteViewTemplate[1];
    //   if (this.selectedWork) {
    //     this.selectedTemplate = DeliverynoteViewTemplate[0];
    //     // set current template (call api and get saved template for client or auxiliary template)
    //     const selectedCdt = await this._deliverynoteService.GetSavedTemplateByClientId(Number(this.selectedWork.clientId));
    //     if (selectedCdt) {
    //       this.selectedTemplate = this.reportTemplateList.find(rt => rt.value === selectedCdt.reportConfigValue);
    //       if (!this.selectedTemplate) { this.selectedTemplate = this.reportTemplateList[0]; }
    //     }
    //   }

    //   this.today = new Date().toISOString().substring(0, 10);
    //   this.deliveryNote = deliveryNote;
    //   this.deliveryNote.generateDateStr = this.deliveryNote.generateDate.toString().substring(0, 10);
    //   this.selectedWork.generateNo = (this.selectedWork.generateNo + this.selectedSubWork.generateNo.toString()).toString();
    //   let mwtList = await this._workService.GetMainWorkTypeListBySubWorkId(this.selectedSubWork.id);
    //   if (mwtList.length > 0) {
    //     this.deliveryNote.mainWorkTypeDesc = mwtList.filter(mwt => mwt.status).map(e => e.name).join(', ');
    //   }
    //   const createdDateList = [] as any[];
    //   const deliveryDateList = [] as any[];
    //   mwtList.forEach(item => {
    //     if (item) {
    //       if (item.receiveDate) { createdDateList.push(moment(item.receiveDate).format('DD/MM/YYYY')); }
    //       if (item.deliveryDate) { deliveryDateList.push(moment(item.deliveryDate).format('DD/MM/YYYY')); }
    //     }
    //   });
    //   this.deliveryNote.createdDateList = createdDateList.join(', ');
    //   this.deliveryNote.deliveryDateList = deliveryDateList.join(', ');
    //   if (this.selectedWork.clientId) {
    //     const client: any = await this._workService.GetClientById(
    //       this.selectedWork.clientId
    //     );
    //     if (client) {
    //       if (client.doctor) {
    //         if (client.doctor.length > 0) {
    //           const doctor = client.doctor.find(d => d.isDefault === true);
    //           this.deliveryNote.collaboratorName = doctor ? doctor.name : '';
    //         }
    //       }
    //       this.client = client;
    //       this.client.Nif = client.nif;
    //       this.issuer = this.issureList.find(
    //         x => Number(x.id) === Number(this.deliveryNote.issuerId)
    //       );
    //       this.deliveryNote.clientTown = client.townName;
    //       this.deliveryNote.issuer = this.issuer;
    //       if (this.deliveryNote.issuer) {
    //         const town = this._dropdownService.townList.find(x => x.id == deliveryNote.issuer.townId);
    //         this.deliveryNote.issuerTown = town ? town.description : '';
    //       }
    //       this.selectedWork.generatedWorkNo = (this.selectedWork.generateNo + this.selectedSubWork.generateNo.toString()).toString();
    //       this.selectedTemplate.value = 1; // on very first it loads the article details
    //       this.cdref.markForCheck();
    //       await Delay(300);
    //       this.commonUiService.PrintHtmlReport('delivery-note-section-new');
    //     }
    //   }
    // }
  }


  private GenerateDeliveryNote(deliveryNote: DeliveryNote) {
    return this._apiService
      .post(API.sub_work.generateDeliveryNote, deliveryNote, false)
      .toPromise();
  }

  private SaveMwtArticleList(list: MwtArticle[]) {
    return this._apiService
      .post(API.main_work_type_work.saveMwtArticleList, list, false, true)
      .toPromise();
  }

  async OnOpenAddDeliveryNoteArticleModal(modal: string) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      this.commonUiService.isPopupOpened = true;
      this.transferable.isEdit = false;
      this.showContent = false;
      this._modalService.Invoke(
        ModalNames.DeliveryNoteArticleModal,
        true,
        this.transferable
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  OnSaveDeliveryNoteArticle(mwtArticle) {
    this.showContent = true;
    if (mwtArticle != null) {
      this.SaveDeliveryNoteArticle(mwtArticle);
    }
  }

  async SaveDeliveryNoteArticle(mwtArticle: MwtArticle) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      const index = this.deliveryMwtArticleList.findIndex(
        a => a.articleId === mwtArticle.articleId
      );
      if (index > -1) {
        this.deliveryMwtArticleList[index] = mwtArticle; // update relevent object in the list
      } else {
        if (mwtArticle) {
          if (this.selectedMainWorkTypeList.length === 1) {
            mwtArticle.mainWorkTypeId = this.selectedMainWorkTypeList[0].id;
          }
        }
        this.deliveryMwtArticleList.push(mwtArticle);
      }
      this.deliveryNote.totalFinalPrice = 0;
      this.deliveryNote.sumPrice = 0;
      this.deliveryNote.totalDiscount = 0;
      this.deliveryNote.totalVatAmount = 0;
      this.ivaBreakdownList = [];
      for (const mwtArticle of this.deliveryMwtArticleList) {
        mwtArticle.discountAmount = 0;
        this.deliveryNote.sumPrice = toFixedNumber(this.deliveryNote.sumPrice + (mwtArticle.lineSubTotal ? mwtArticle.lineSubTotal : 0))
        this.deliveryNote.totalFinalPrice = toFixedNumber(this.deliveryNote.totalFinalPrice + mwtArticle.finalPrice);
        this.deliveryNote.totalDiscount = toFixedNumber(this.deliveryNote.totalDiscount + (mwtArticle.lineDiscountAmount ? mwtArticle.lineDiscountAmount : 0));

        //iva
        if (mwtArticle.ivaTypeId && mwtArticle.ivaTypeId != 0) {
          const ivaBreakdown = new IvaBreakdown();
          ivaBreakdown.ivaTypeId = mwtArticle.ivaTypeId;
          ivaBreakdown.iva = mwtArticle.ivaPercentage + '%';
          ivaBreakdown.base = this._deliveryNoteCalculationService.getIvaBaseAmount(mwtArticle.priceWithDiscount, mwtArticle.quantityWithWaste);
          ivaBreakdown.ivaAmount = mwtArticle.ivaAmount;
          this.ivaBreakdownList.push(ivaBreakdown);
        }
        this.deliveryNote.totalVatAmount = toFixedNumber(this.deliveryNote.totalVatAmount + (mwtArticle.ivaAmount ? mwtArticle.ivaAmount : 0));
        // end iva
      }
      this.deliveryNote.sumPrice = this.deliveryNote.sumPrice ? toFixedNumber(this.deliveryNote.sumPrice) : 0;
      this.deliveryNote.totalDiscount = this.deliveryNote.totalDiscount ? this.deliveryNote.totalDiscount : 0;
      this.deliveryNote.totalVatAmount = this.deliveryNote.totalVatAmount ? this.deliveryNote.totalVatAmount : 0;
      this.deliveryNote.totalWithDiscount = this._deliveryNoteCalculationService.getTotalWithDiscountAmount(this.deliveryNote.sumPrice, this.deliveryNote.totalDiscount);
      this.deliveryNote.totalPrice = this.deliveryNote.totalFinalPrice ? this.deliveryNote.totalFinalPrice : 0;

      if (this.ivaBreakdownList && this.ivaBreakdownList.length > 0) {
        this.ivaBreakdownList = this._deliverynoteService.LoadVatBreakdown(this.ivaBreakdownList);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnDeleteDeliveryNoteArticle(article: any, index: number) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      swal({
        title: this._commonDataService.localizationLabelList['delivery_notes'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList[
          'common_yes'
        ],
        cancelButtonText: this._commonDataService.localizationLabelList[
          'common_cancel'
        ]
      }).then(async result => {
        if (result.value) {
          if (index > -1) {
            this.deliveryMwtArticleList.splice(index, 1);
            this.deliveryNote.totalFinalPrice = 0;
            this.deliveryNote.sumPrice = 0;
            this.deliveryNote.totalDiscount = 0;
            this.deliveryNote.totalVatAmount = 0;
            this.ivaBreakdownList = [];
            for (const mwtArticle of this.deliveryMwtArticleList) {
              //mwtArticle.discountAmount = 0;
              this.deliveryNote.sumPrice = toFixedNumber(this.deliveryNote.sumPrice + (mwtArticle.lineSubTotal ? mwtArticle.lineSubTotal : 0))
              this.deliveryNote.totalFinalPrice = toFixedNumber(this.deliveryNote.totalFinalPrice + mwtArticle.finalPrice);
              this.deliveryNote.totalDiscount = toFixedNumber(this.deliveryNote.totalDiscount + (mwtArticle.lineDiscountAmount ? mwtArticle.lineDiscountAmount : 0));

              //iva
              if (mwtArticle.ivaTypeId && mwtArticle.ivaTypeId != 0) {
                const ivaBreakdown = new IvaBreakdown();
                ivaBreakdown.ivaTypeId = mwtArticle.ivaTypeId;
                ivaBreakdown.iva = mwtArticle.ivaPercentage + '%';
                ivaBreakdown.base = this._deliveryNoteCalculationService.getIvaBaseAmount(mwtArticle.priceWithDiscount, mwtArticle.quantityWithWaste);
                ivaBreakdown.ivaAmount = mwtArticle.ivaAmount;
                this.ivaBreakdownList.push(ivaBreakdown);
              }
              this.deliveryNote.totalVatAmount = Number(this.deliveryNote.totalVatAmount + (mwtArticle.ivaAmount ? mwtArticle.ivaAmount : 0));
              // end iva
            }
            this.deliveryNote.sumPrice = this.deliveryNote.sumPrice ? toFixedNumber(this.deliveryNote.sumPrice) : 0;
            this.deliveryNote.totalDiscount = this.deliveryNote.totalDiscount ? this.deliveryNote.totalDiscount : 0;
            this.deliveryNote.totalVatAmount = this.deliveryNote.totalVatAmount ? this.deliveryNote.totalVatAmount : 0;
            this.deliveryNote.totalWithDiscount = this._deliveryNoteCalculationService.getTotalWithDiscountAmount(this.deliveryNote.sumPrice, this.deliveryNote.totalDiscount);
            this.deliveryNote.totalPrice = this.deliveryNote.totalFinalPrice ? this.deliveryNote.totalFinalPrice : 0;

            if (this.ivaBreakdownList && this.ivaBreakdownList.length > 0) {
              this.ivaBreakdownList = this._deliverynoteService.LoadVatBreakdown(this.ivaBreakdownList);
            }
          }
        }
      });
    } catch (error) { console.log(error); } finally {
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  private DeleteDeliveryNoteArticle(id: number) {
    return this._apiService
      .get(API.main_work_type_work.deleteDeliveryNoteArticle + id)
      .toPromise();
  }

  OnEditDeliveryNoteArticle(article: MwtArticle, modal: string) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      this.transferable.isEdit = true;
      this.transferable.mwtArticle = new MwtArticle();
      if (!article.ivaTypeId) {
        article.ivaTypeId = 0;
        article.ivaAmount = 0;
      }
      this.transferable.mwtArticle = article;
      this.showContent = false;
      this.cdref.markForCheck();
      this._modalService.Invoke(
        ModalNames.DeliveryNoteArticleModal,
        true,
        this.transferable
      );
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  onKey(target) {
    if (target) { target.className = "ng-cus-focus"; target.focus(); }
  }

  //#region Patient
  async OnUpdatePatient(event: any) {
    swal({
      title: this._commonDataService.localizationLabelList['patient'],
      text: this._commonDataService.localizationLabelList['patient_record_update_confirmation'],
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
      cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
    }).then(async (result) => {
      if (result.value) {
        try {
          this._commonUiService.isSpinnerVisible = true;
          if (this.patientFormGroup.invalid) { ShowInvalidFormControls(this.patientFormGroup); return; }
          if (this.patientEditModel) {
            if (this.patientEditModel.dateOfBirthView) {
              this.patientEditModel.dateOfBirth = new Date(this._commonUiService.ConvertDateFormatToString(this.patientEditModel.dateOfBirthView))
                .toISOString();
            }

            if (this.patientEditModel.id > 0) {
              const response = await this._patientService.UpdatePatient(this.patientEditModel);
              if (response) {
                const body = JSON.parse(response['_body']);
                if (body['_isSuccsess']) {
                  this._toastyService.success({
                    title: this._commonDataService.localizationLabelList['patient'],
                    msg: this._commonDataService.localizationLabelList['update_success'],
                    showClose: true,
                    theme: 'default',
                    timeout: 5000
                  });

                  this.LoadPatient();
                  this.cdref.markForCheck();
                } else {
                  this._toastyService.error({
                    title: this._commonDataService.localizationLabelList['patient'],
                    msg: this._commonDataService.localizationLabelList['update_error'],
                    showClose: true,
                    theme: 'default',
                    timeout: 5000
                  });
                }
                this.ClosePatientModal(event);
              }

            }
          }
        } catch (error) { console.log(error); } finally {
          this.cdref.markForCheck();
          this.subModalView = false;
          this._commonUiService.isSpinnerVisible = false;
        }
      }
      else {
        this.ClosePatientModal(event);
      }
    }).catch(err => console.log(err));
  }

  ChangeDeliveryNoteClient(event: any) {
    let isToPatient = event.target.checked
    this.deliveryNote.isToPatient = isToPatient;
    this.cdref.markForCheck();
  }

  LoadPatient() {
    this._workService.GetPatientById(this.selectedWork.patientId)
      .then(patient => {
        this.patient = patient;
        this.cdref.markForCheck();
      });
  }

  OnOpenChangeClientModal(modal: string) {
    this.subModalView = true;
    this._commonUiService.openModal(modal);
  }

  public CloseModal(event: any) {
    this.subModalView = false;
    this._commonUiService.closeModal(event);
  }

  public ClosePatientModal(event: any) {
    this.subModalView = false;
    (((((event.target.parentElement.parentElement).parentElement).parentElement).parentElement).parentElement).classList.remove('md-show');
  }

  public OnOpenEditPatientModal(modal: string): void {
    if (modal) {
      try {
        this._commonUiService.isSpinnerVisible = true;
        this.subModalView = true;
        this.patientFormGroup.reset();
        this.patientEditModel = new Patient();
        if (this.selectedWork.patientId) {

          this._workService.GetPatientById(this.selectedWork.patientId).then(patient => {
            if (patient) {
              Object.assign(this.patientEditModel, patient);
              this.patientFormGroup.controls.name.setValue(patient.name);
              this.patientFormGroup.controls.surnames.setValue(patient.surnames);
              this.patientFormGroup.controls.historyNumber.setValue(patient.historyNumber);
              this.patientFormGroup.controls.clientName.setValue(patient.clientName);
              this.patientFormGroup.controls.nif.setValue(patient.nif);
              this.patientFormGroup.controls.address.setValue(patient.address);
              this.patientFormGroup.controls.provinceName.setValue(patient.provinceName);
              this.patientFormGroup.controls.countryName.setValue(patient.countryName);
              this.patientFormGroup.controls.notes.setValue(patient.notes);

              if (this.patientEditModel.townId !== undefined && this.patientEditModel.townId !== null) {
                this._apiService.get(API.town.getTownById + this.patientEditModel.townId)
                  .subscribe(
                    res => {
                      if (res) {
                        this.patientFormGroup.controls.postalCode.setValue(res.postcode);
                        this.patientFormGroup.controls.townId.setValue(res.descriptionWithPostcode);
                        this.patientEditModel.postalCode = res.postcode;
                        this.patientEditModel.townName = res.description;
                        this.patientEditModel.provinceName = res.provinceName;
                        this.patientEditModel.countryName = res.countryName;
                        this.patientDecsriptionInput.nativeElement.click();
                        this.cdref.markForCheck();
                      }
                    });
              } else {
                this.patientFormGroup.controls.townId.setValue('');
                this.patientFormGroup.controls.postalCode.setValue('');
                this.patientEditModel.postalCode = '';
                this.patientEditModel.provinceName = '';
                this.patientEditModel.countryName = '';
                this.patientDecsriptionInput.nativeElement.click();
                this.cdref.markForCheck();
              }
              if (this.patientEditModel.dateOfBirth) {
                this.patientEditModel.dateOfBirthView = this._commonUiService.ConvertToPickerDateFormat(this.patientEditModel.dateOfBirth);
              }
            }
            this._commonUiService.isSpinnerVisible = false;
          });

          this._commonUiService.openModal(modal);

        }
      } catch (ex) {
        console.log(ex);
        this._commonUiService.isSpinnerVisible = false;
      }
    }
  }

  clearTown() {
    this.patientEditModel.townId = null;
    this.patientEditModel.provinceName = '';
    this.patientEditModel.countryName = '';
    this.patientEditModel.postalCode = '';
    this.patientFormGroup.controls['postalCode'].setValue('');
    this.patientFormGroup.controls['townId'].setValue('');
    this.editTown = new Town();
  }

  OnChangeTown(town: Town) {
    if (town) {
      this.patientEditModel.townId = Number(town.id);
      this.patientEditModel.provinceName = town.provinceName;
      this.patientEditModel.countryName = town.countryName;
      this.patientEditModel.postalCode = town.postcode;
      this.patientFormGroup.controls['postalCode'].setValue(town.postcode);
      this.editTown = town;
    }
  }

  onSkipCharCountExceedsTown() {
    this.searchTowns(this.patientFormGroup.controls.townId.value);
  }

  async searchTowns(query = '') {
    const townList = await this._dropdownService.SearchTowns(query);
    this.townDropdownActiveList = townList ? townList : [];
    this.townDropdownActiveList = CommonFunctions.Sort(this.townDropdownActiveList, 'description');
  }

  OnChangePostcode(town: Town) {
    if (town) {
      this.patientEditModel.townId = Number(town.id);
      this.patientEditModel.provinceName = town.provinceName;
      this.patientEditModel.countryName = town.countryName;
      this.patientEditModel.postalCode = town.postcode;
      this.patientFormGroup.controls['townId'].setValue(town.descriptionWithPostcode);
      this.editTown = town;
    }
  }

  async searchPostcodes(query = '') {
    const townList = await this._dropdownService.SearchPostcodes(query);
    this.townActiveList = townList ? townList : [];
    this.townActiveList = CommonFunctions.Sort(this.townActiveList.filter(x => x.postcode), 'postcode');
  }

  onSkipCharCountExceedsPostcode() {
    this.searchPostcodes(this.patientFormGroup.controls.postalCode.value);
  }

  async OnOpenAddClientPcModal(modal: string) {
    if (this.editTown) {
      this.editTown.id = this.patientEditModel.townId;
      this.editTown.provinceName = this.patientEditModel.provinceName;
      this.editTown.countryName = this.patientEditModel.countryName;

      this.editTown.postcode = this.patientFormGroup.get('postalCode').value;
      this.editTown.model = 'edit';
      document.querySelector('#delivery-modal-editPatient').classList.add('delivery-modal-editPatient--index');

      const town = this.townDropdownActiveList.find(x => x.id == this.patientEditModel.townId);
      if (town) {
        this.editTown.countryId = town.countryId;
        this.editTown.provinceId = town.provinceId;
        this.editTown.description = town.description;
      }
    }
    this.editPostalCodeComponent.OpenModal(this.editTown);
  }

  openModal(modal) {
    document.querySelector('#' + modal).classList.add('md-show');
  }

  //#endregion
}
