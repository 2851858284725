import API from './../../../../common/api.config.json';
import { ApiService } from '../../../../common/services/api.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommonDataService } from '../../../../common/services/common.data.service';
import { Phases } from '../../../entities/phases';
import { NgxPermissionsService } from 'ngx-permissions';
import { Toast } from 'src/app/modules/common/services/toast.service';


@Component({
  templateUrl: './phases.component.html',
  selector: 'phases-list',
  styleUrls: ['./phases.component.scss']
})

export class PhasesComponent implements OnInit {

  successMessage: any;
  updateMessage: any;
  errorMessage: any;
  deleteMessage: any;
  statusMessage: any;
  deleteErrorMessage: any;
  tableName: any;
  isTest: any;
  defaultValue: any;

  phases: Phases;
  isLoad: boolean;

  position = 'bottom-right';
  phasesList: Phases[];
  private _phasesList: Phases[] = [];

  phasesFormGroup: FormGroup;
  phasesForm: NgForm;
  hasEditPermission = false;
  isDisabledPhases = false;

  @ViewChild('description') nameField: ElementRef;
  timeValidator = {
    day: true,
    hour: true,
    minute: true
  };

  constructor(
    private _router: Router,
    private _apiService: ApiService,
    private _ngxPermissionService: NgxPermissionsService,
    private _fb: FormBuilder,
    private _commonUIService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: Toast) {
    this.phases = new Phases();
    this.isDisabledPhases = false;

  }

  ngOnInit(): void {

    this.phasesFormGroup = this._fb.group({
      description: ['', [
        Validators.required
      ]],
      code: [''],
      day: [null, [Validators.min(0)]],
      hour: [null, [Validators.min(0), Validators.max(23)]],
      minute: [null, [Validators.min(0), Validators.max(59)]],
    });

    this.LoadPhasesList();
    this.isLoad = false;
    this.SetPermissions();
  }

  async SetPermissions() {
    this.hasEditPermission = await this._ngxPermissionService.hasPermission('EditDeleteAuxiliarytables');
  }

  LoadPhasesList() {
    this._apiService.get(API.phases.getPhasesList)
      .subscribe(res => {
        this.phasesList = res;
        this._phasesList = res;
        this.isLoad = true;
      });
  }

  LoadEditPhases(phases: Phases) {
    phases.isEdit = true;
  }

  timeValidate(phases: Phases) {
    if (phases.day !== null && phases.day < 0) {
      this.timeValidator.day = false;
    } else if ((phases.day !== null && phases.day >= 0) || phases.day === null) {
      this.timeValidator.day = true;
    }

    if (phases.hour !== null && (phases.hour < 0 || phases.hour > 23)) {
      this.timeValidator.hour = false;
    } else if ((phases.hour !== null && (phases.hour >= 0 || phases.hour < 24)) || phases.hour === null) {
      this.timeValidator.hour = true;
    }


    if (phases.minute !== null && (phases.minute < 0 || phases.minute > 59)) {
      this.timeValidator.minute = false;
    } else if ((phases.minute !== null && (phases.minute >= 0 || phases.minute < 60)) || phases.minute === null) {
      this.timeValidator.minute = true;
    }

    return (this.timeValidator.day && this.timeValidator.hour && this.timeValidator.minute);
  }

  EditPhases(phases: Phases, statusChange: boolean) {

    if (!this.timeValidate(phases)) {
      return;
    }

    this.isDisabledPhases = true;

    this.statusMessage = this._commonDataService.localizationLabelList['status_updated'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.tableName = this._commonDataService.localizationLabelList['phases'];
    this.successMessage = this._commonDataService.localizationLabelList['save_success'];

    phases.isEdit = false;

    this._apiService.put(API.phases.editPhases + phases.id, phases, false)
      .subscribe(
        res => {

          if (res.status == 204 || res.status == 200) {

            let message = '';

            if (statusChange) {
              message = this.successMessage;
            }
            else {
              message = this.updateMessage;
            }

            this._toastyService.success({
              title: this.tableName,
              msg: message
            });

            this.LoadPhasesList();
          }
        },
        err => {
          console.log(err);
        },
        () => {
          this.isDisabledPhases = false;
        }
      );
  }

  RemoveEditPhases(phases: Phases) {
    phases.isEdit = false;
    this.LoadPhasesList();
  }

  ActiveDeactivePhases(phases: Phases) {

    if (phases) {
      if (phases.status) {
        phases.status = false;
      }
      else {
        phases.status = true;
      }
      this.EditPhases(phases, true);
    }
  }

  OnOpenAddPhasesModal() {

    this.successMessage = this._commonDataService.localizationLabelList['save_success'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.errorMessage = this._commonDataService.localizationLabelList['save_error'];
    this.tableName = this._commonDataService.localizationLabelList['phases'];

    this.phases = new Phases();
    this.phasesFormGroup.reset();
    this._commonUIService.openModal('phases-forms-modal');

    setTimeout(() => {
      this.nameField.nativeElement.focus();
    }, 100);

  }

  OnClosePhasesModal(event) {
    (((((event.target.parentElement.parentElement).parentElement).parentElement).parentElement).parentElement).classList.remove('md-show');
  }

  SavePhases(phases: Phases, event) {
    this.isDisabledPhases = true;

    if (this.phasesFormGroup.invalid) {
      Object.keys(this.phasesFormGroup.controls).forEach(field => {
        const control = this.phasesFormGroup.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      this.isDisabledPhases = false;
      return;
    }
    this.OnClosePhasesModal(event);

    phases.day = this.phasesFormGroup.get('day').value;
    phases.hour = this.phasesFormGroup.get('hour').value;
    phases.minute = this.phasesFormGroup.get('minute').value;

    this._apiService.post(API.phases.savePhases, phases, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {
            console.log(res);

            this._toastyService.success({
              title: this.tableName,
              msg: this.successMessage
            });
            this.LoadPhasesList();
          }
        },
        err => {
          this._toastyService.error({
            title: this.tableName,
            msg: err
          });
          this.isDisabledPhases = false;
        },
        () => {
          this.isDisabledPhases = false;
        }
      );
  }

  ActiveDeactiveIsTest(phases: Phases) {

    if (phases) {
      if (phases.isTest) {
        phases.isTest = false;
      }
      else {
        phases.isTest = true;
      }
      this.EditPhases(phases, true);
    }
  }

  EditByDefaultPhases(phases: Phases) {

    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.tableName = this._commonDataService.localizationLabelList['phases'];
    this.defaultValue = this._commonDataService.localizationLabelList['default'];

    phases.isEdit = false;
    this._apiService.put(API.phases.saveByDefault + phases.id,
      phases, false)
      .subscribe(
        res => {

          if (res.status == 204 || res.status == 200) {
            let message = '';
            message = this.updateMessage;
            this._toastyService.success({
              title: this.defaultValue,
              msg: message
            });
            this.LoadPhasesList();
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  DeletePhases(id: number) {
    this.isDisabledPhases = true;

    this.deleteMessage = this._commonDataService.localizationLabelList['delete_success'];
    this.deleteErrorMessage = this._commonDataService.localizationLabelList['delete_error'];
    this.tableName = this._commonDataService.localizationLabelList['phases'];

    if (id) {
      this._apiService.delete(API.phases.deletePhases, id.toString())
        .subscribe(
          res => {
            if (res._statusCode == 200) {
              let index: number = this._phasesList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._phasesList.splice(index, 1);
                this._toastyService.success({
                  title: this.tableName,
                  msg: this.deleteMessage
                });
              }
            }
            if (res._statusCode == 409) {
              let index: number = this._phasesList.findIndex(e => e.id === id);
              if (index !== -1) {
                //this._phasesList.splice(index, 1);
                this._toastyService.warning({
                  title: this.tableName,
                  msg: this.deleteErrorMessage
                });
              }
            }
          },
          err => {
            this._toastyService.error({
              title: this.tableName,
              msg: err
            });
            this.isDisabledPhases = false;
          },
          () => {
            this.isDisabledPhases = false;
            this.phasesList = this._phasesList;
          }
        );
    }
  }
}

