import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkRoutingModule } from './work-routing.module';
import { WorkComponent } from './work.component';
import { SharedModule } from '../../shared/shared.module';
import { DataTableModule } from 'angular2-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ToastyModule } from 'ng2-toasty';
import { UiSwitchModule } from 'ng2-ui-switch';
import { SelectModule } from 'ng-select';
import { TextMaskModule } from 'angular2-text-mask';
import { WorkTypeCarouselComponent } from './components/work-type-carousel/work-type-carousel.component';
import { NgbModule, NgbDateParserFormatter, NgbDatepickerI18n } from 'imp-ng-bootstrap';
import { NguCarouselModule } from '@ngu/carousel';
import { WorkExpandListComponent } from './components/work-expand-list/work-expand-list.component';
import { GenerateNumberComponent } from './components/generate-number/generate-number.component';
import { TagInputModule } from 'ngx-chips';
import { WorkStatusService } from './services/work-status.service';
import { NgxTypeaheadModule } from 'ngx-typeahead';
import { DentboxApiService } from '../common/dentbox/services/dentbox-api.service';
import { DateParserFormatter } from '../common/date-parser-formater';
import { TabWorkComponent } from './components/work-expand-list/tab-work/tab-work.component';
import { TabTasksComponent } from './components/work-expand-list/tab-tasks/tab-tasks.component';
import { TabPhasesComponent } from './components/work-expand-list/tab-phases/tab-phases.component';
import { TeethSelectorModule } from 'teeth-selector';
import { SanityGuaranteeReportComponent } from './components/sanity-guarantee-report/sanity-guarantee-report.component';
import { UsingReportComponent } from './components/using-report/using-report.component';
import { WorkReportComponent } from './components/work-report/work-report.component';
import { WorkService } from './services/work.service';
import { PhasesComponent } from './components/phases/phases.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { WorkersComponent } from './components/workers/workers.component';
import { NotesComponent } from './components/notes/notes.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { PrescriptionsComponent } from './components/prescriptions/prescriptions.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { DeliveryNotesComponent } from './components/delivery-notes/delivery-notes.component';
import { DeliveryNoteReportComponent } from './components/delivery-note-report/delivery-note-report.component';
import { ProductsComponent } from './components/products/products.component';
import { BudgetsComponent } from './components/budgets/budgets.component';
import { BudgetReportComponent } from './components/budget-report/budget-report.component';
import { ColorsComponent } from './components/colors/colors.component';
import { ImplantDataComponent } from './components/implant-data/implant-data.component';
import { ClinicMaterialsComponent } from './components/clinic-materials/clinic-materials.component';
import { MainWorkTypesComponent } from './components/main-work-types/main-work-types.component';
import { ConformityReportComponent } from './components/conformity-report/conformity-report.component';
import { WorkStatisticComponent } from './components/work-statistic/work-statistic.component';
import { PiecesComponent } from './components/pieces/pieces.component';
import { PhaseTemplateService } from './services/phase-template.service';
import { ProductTemplateService } from './services/product-template.service';
import { ArticleTemplateService } from './services/article-template.service';
import { MwtWorkerService } from './services/mwt-worker.service';
import { ImplantDataService } from './services/implant-data.service';
import { MwtColorService } from './services/mwt-color.service';
import { MwtClinicMaterialService } from './services/mwt-clinic-material.service';
import { NoteService } from './services/note.service';
import { PrescriptionService } from './services/prescription.service';
import { DocumentService } from './services/document.service';
import { DeliveryNoteService } from './services/delivery-note.service';
import { BudgetService } from './services/budget.service';
import { SubWorkService } from './services/sub-work.service';
import { NgxMaskModule } from 'ngx-mask';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { NovaAutoCompleteLibraryModule } from 'nova-auto-complete-library';
import { NovaSearchModule } from 'nova-search';
import { WorkModalComponent } from './popups/work-modal/work-modal.component';
import { NotesModalComponent } from './popups/notes-modal/notes-modal.component';
import { GenerateBudgetModalComponent } from './popups/generate-budget-modal/generate-budget-modal.component';
import { BudgetMainWorkTypeModalComponent } from './popups/budget-main-work-type-modal/budget-main-work-type-modal.component';
import { AddArticleModalComponent } from './popups/add-article-modal/add-article-modal.component';
import { ClinicMaterialModalComponent } from './popups/clinic-material-modal/clinic-material-modal.component';
import { ColorModalComponent } from './popups/color-modal/color-modal.component';
import { DeliveryNoteModalComponent } from './popups/delivery-note-modal/delivery-note-modal.component';
import { DeliveryNoteArticleModalComponent } from './popups/delivery-note-article-modal/delivery-note-article-modal.component';
import { BillModelComponent } from './popups/bill-model/bill-model.component';
import { BillNewInvoiceModelComponent } from './popups/bill-new-invoice-model/bill-new-invoice-model.component';
import { AddDocumentviewModalComponent } from './popups/add-documentview-modal/add-documentview-modal.component';
import { PhasesModalComponent } from './popups/phases-modal/phases-modal.component';
import { SubWorkModalComponent } from './popups/sub-work-modal/sub-work-modal.component';
import { CancelPhasesModalComponent } from './popups/cancel-phases-modal/cancel-phases-modal.component';
import { TaskModalComponent } from './popups/task-modal/task-modal.component';
import { ViewDocumentviewModalComponent } from './popups/view-documentview-modal/view-documentview-modal.component';
import { MainWorkTypeModalComponent } from './popups/main-work-type-modal/main-work-type-modal.component';
import { ArticleModalComponent } from './popups/article-modal/article-modal.component';
import { PrescriptionModalComponent } from './popups/prescription-modal/prescription-modal.component';
import { PrecriptionViewModalComponent } from './popups/precription-view-modal/precription-view-modal.component';
import { ImplantDataModelComponent } from './popups/implant-data-model/implant-data-model.component';
import { ProductModalComponent } from './popups/product-modal/product-modal.component';
import { WorkerModalComponent } from './popups/worker-modal/worker-modal.component';
import { ShipmentCollectionComponent } from './components/shipment-collection/shipment-collection.component';
import { ShipmentCollectionModalComponent } from './popups/shipment-collection-modal/shipment-collection-modal.component';
import { NgxSelectorPiezasModule } from '@ngx-infomed/selector-piezas';
import { AddColorModalComponent } from './popups/add-color-modal/add-color-modal.component';
import { ExternalWorkComponent } from './components/external-work/external-work.component';
import { ExternalShipmentsModalComponent } from './popups/external-shipments-modal/external-shipments-modal.component';
import { AddWorktypeModalComponent } from './popups/add-worktype-modal/add-worktype-modal.component';
import { WarehouseProductModalComponent } from './popups/warehouse-product-modal/warehouse-product-modal.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { TaskTopesEditModalComponent } from './popups/task-modal/task-topes-edit-modal/task-topes-edit-modal.component';
import { SectionTopesEditModalComponent } from './popups/task-modal/section-topes-edit-modal/section-topes-edit-modal.component';
import { JobSearchComponent } from '../job-search/job-search.component';
import { ShortDate } from '../common/pipes/short-date.pipe';
import { AssociatedResrvationsComponent } from './popups/work-modal/associated-resrvations/associated-resrvations.component';
import { I18n, CustomDatepickerI18nService } from '../common/services/custom-date-picker-i18n.service';
import { AddMultipleDeliverynotesComponent } from './popups/add-multiple-deliverynotes/add-multiple-deliverynotes.component';
import { EmailReportComponent } from './components/email-report/email-report.component';
import { AssociatedFreeBudgetComponent } from './popups/work-modal/associated-free-budget/associated-free-budget.component';
import { DetailViewFreeBudgetComponent } from './popups/work-modal/detail-view-free-budget/detail-view-free-budget.component';
import { AddMetalModalComponent } from './popups/add-metal-modal/add-metal-modal.component';
import { IncidentWorkViewComponent } from './components/incident-work-view/incident-work-view.component';
import { IncidentAddWorkComponent } from './popups/incident-add-work/incident-add-work.component';
import { IncidentDocViewComponent } from './popups/incident-doc-view/incident-doc-view.component';
import { TabIncidentComponent } from './components/work-expand-list/tab-incident/tab-incident.component';
import { ExternalWorkAttachmentsComponent } from './popups/external-work-attachments/external-work-attachments.component';
import { AppExternalDocViewComponent } from './popups/app-external-doc-view/app-external-doc-view.component';


@NgModule({
  imports: [
    CommonModule,
    WorkRoutingModule,
    SharedModule,
    DataTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    UiSwitchModule,
    SelectModule,
    NgbModule,
    NguCarouselModule,
    TextMaskModule,
    ToastyModule.forRoot(),
    TagInputModule,
    NgxTypeaheadModule,
    TeethSelectorModule,
    NovaAutoCompleteLibraryModule,
    NovaSearchModule,
    NgxMaskModule.forRoot(),
    NgxSelectorPiezasModule,
    NgxBarcodeModule
  ],
  declarations: [
    WorkComponent,
    WorkTypeCarouselComponent,
    WorkExpandListComponent,
    GenerateNumberComponent,
    TabWorkComponent,
    TabTasksComponent,
    TabPhasesComponent,
    SanityGuaranteeReportComponent,
    UsingReportComponent,
    WorkReportComponent,
    ConformityReportComponent,
    PhasesComponent,
    TasksComponent,
    WorkersComponent,
    NotesComponent,
    DocumentsComponent,
    PrescriptionsComponent,
    ArticlesComponent,
    DeliveryNotesComponent,
    DeliveryNoteReportComponent,
    ProductsComponent,
    BudgetsComponent,
    BudgetReportComponent,
    ColorsComponent,
    ImplantDataComponent,
    ClinicMaterialsComponent,
    MainWorkTypesComponent,
    ConformityReportComponent,
    WorkStatisticComponent,
    PiecesComponent,
    InvoicesComponent,
    WorkModalComponent,
    NotesModalComponent,
    ClinicMaterialModalComponent,
    ColorModalComponent,
    GenerateBudgetModalComponent,
    BudgetMainWorkTypeModalComponent,
    DeliveryNoteModalComponent,
    DeliveryNoteArticleModalComponent,
    BillModelComponent,
    BillNewInvoiceModelComponent,
    AddArticleModalComponent,
    AddDocumentviewModalComponent,
    PhasesModalComponent,
    SubWorkModalComponent,
    ViewDocumentviewModalComponent,
    MainWorkTypeModalComponent,
    ArticleModalComponent,
    CancelPhasesModalComponent,
    TaskModalComponent,
    ViewDocumentviewModalComponent,
    PrescriptionModalComponent,
    PrecriptionViewModalComponent,
    ImplantDataModelComponent,
    ProductModalComponent,
    WorkerModalComponent,
    ShipmentCollectionComponent,
    ShipmentCollectionModalComponent,
    AddColorModalComponent,
    AddWorktypeModalComponent,
    ExternalWorkComponent,
    ExternalShipmentsModalComponent,
    WarehouseProductModalComponent,
    AssociatedResrvationsComponent,
    AddMultipleDeliverynotesComponent,
    EmailReportComponent,
    AssociatedFreeBudgetComponent,
    DetailViewFreeBudgetComponent,
    AddMetalModalComponent,
    IncidentWorkViewComponent,
    IncidentAddWorkComponent,
    IncidentDocViewComponent,
    AddMetalModalComponent,
    TabIncidentComponent,
    AppExternalDocViewComponent,
    ExternalWorkAttachmentsComponent
  ],
  exports: [],
  providers: [
    WorkStatusService,
    WorkService,
    PhaseTemplateService,
    ProductTemplateService,
    ArticleTemplateService,
    MwtWorkerService,
    ImplantDataService,
    MwtColorService,
    MwtClinicMaterialService,
    NoteService,
    PrescriptionService,
    DocumentService,
    DeliveryNoteService,
    BudgetService,
    SubWorkService,
    DentboxApiService,
    I18n,
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }
  ]
})
export class WorkModule { constructor() { } }
