import { Pipe, PipeTransform } from '@angular/core';
import { environment } from "../../../../environments/environment";
import { DatePipe } from "@angular/common";
import * as moment from 'moment';

@Pipe({
    name: 'customShortDate'
})

export class CustomShortDate extends DatePipe implements PipeTransform {

    transform(value: any): any {
        if (!value) {
            return '';
        }
        const date = moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
        return date;
    }
}
