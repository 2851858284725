import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FreeBudget } from 'src/app/modules/budgets/entities/freeBudget';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { BudgetService } from '../../../services/budget.service';

@Component({
  selector: 'app-associated-free-budget',
  templateUrl: './associated-free-budget.component.html',
  styleUrls: ['./associated-free-budget.component.scss']
})
export class AssociatedFreeBudgetComponent implements OnInit {

  @Input() freeBudgetList: FreeBudget[];

  freeBudget: FreeBudget;

  constructor(
    public _commonUiService: CommonUIService,
    private _budgetService: BudgetService,
    private cdref: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  async openBudgetDetailModal(id: number) {
    await this._budgetService.GetFreeBudgetById(id)
    .then(res => {
      if(res){
        this.freeBudget = res;
        this.freeBudget.articleList.forEach(budget => {
          budget.quantityWithWaste = Number(
            ((budget.quantity * (budget.waste + 100)) / 100).toFixed(2)
          );
          budget.priceWithDiscount =  Number(
            ((budget.price * (100 - budget.discount)) / 100).toFixed(2)
          );
        });
        this.cdref.markForCheck();
        this._commonUiService.OpenBasicModal('associated-free-budgetdetail-modal');
      }
    });
  }

}
