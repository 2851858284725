import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { WorkService } from '../../services/work.service';
import { ApiService } from '../../../common/services/api.service';
import API from './../../../common/api.config.json';
import { WorkTypePhaseTemplate } from '../../../work-type/entities/work-type-model-templates';
import swal from 'sweetalert2';
import { MwtPhase } from '../../entities/main-work-type-phase';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { CommonDataService } from '../../../common/services/common.data.service';
import { MainWorkType } from '../../entities/main-work-type';
import { FormGroup } from '@angular/forms';
import { ToastyService, ToastData } from 'ng2-toasty';
import { Phases } from '../../entities/phases';
import { LabSection } from '../../entities/lab-section';
import { Work } from '../../entities/work';
import { MwtArticle } from '../../entities/main-work-type-article';
import { PhaseTemplateService } from '../../services/phase-template.service';
import { IWorkComponent } from '../../entities/i-work-component';
import { SubWork } from '../../entities/sub-work';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/modules/common/services/modal.service';

@Component({
  selector: 'phases',
  templateUrl: './phases.component.html',
  styleUrls: ['./phases.component.scss']
})
export class PhasesComponent implements OnInit, OnDestroy, IWorkComponent {
  @ViewChild('sectionElement') sectionElement: any;
  // subscriptions
  private workService: Subscription;

  // form groups
  addPhaseFormGroup: FormGroup;

  // lists
  phaseTemplateList: WorkTypePhaseTemplate[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];
  phaseStatusList: string[] = [];
  phaseList: Phases[] = [];
  labSectionList: LabSection[] = [];
  mwtArticleList: MwtArticle[] = [];
  selectedSubWork: SubWork;
  mainWorkTypeList: MainWorkType[];

  // objects
  selectedMainWorkType: MainWorkType;
  selectedWork: Work;
  hasLoaded = true;
  mwtPhase: MwtPhase;
  c = 'C';
  l = 'L';

  @Output() refresh = new EventEmitter();
  showPopUp = false;
  allClosed = false;

  constructor(private _workService: WorkService,
    private _apiService: ApiService,
    private __service: PhaseTemplateService,
    public commonUiService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: ToastyService,
    private _modalService: ModalService) {

    this.workService = this._workService.detailObject.share().subscribe((args) => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (args.invokeComponents.includes('Phase') || args.invokeComponents.includes('*')) {
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this.selectedMainWorkType = args.selectedMainWorkType;
          this.selectedWork = args.selectedWork;
          this.selectedSubWork = args.selectedSubWork;
          this.phaseTemplateList = [];
          this.allClosed = this.selectedMainWorkTypeList.every(x => x.isClosed);
          this.SyncPhaseData();
        }
      }
    });
  }

  ngOnInit() {
    this.mwtPhase = new MwtPhase();
  }

  ngOnDestroy() { this.workService.unsubscribe(); }

  //#region Resources

  //#endregion

  //#region CRUD

  private async SyncPhaseData() {
    this.phaseTemplateList = [];
    try {
      this.hasLoaded = false;
      const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
      if (idList) {
        let phaseTemplateList = await this.__service.GetPhaseTemplateListByMainWorkTypeIdList(idList);
        phaseTemplateList = phaseTemplateList ? phaseTemplateList : [];
        if (phaseTemplateList.length > 0) {
          for (let index = 0; index < phaseTemplateList.length; index++) {
            const phaseTemplate = phaseTemplateList[index];
            const currentMainWorkType = this.selectedMainWorkTypeList.find(m => Number(m.id) === phaseTemplate.workTypeId);
            if (currentMainWorkType) {
              phaseTemplate.order = currentMainWorkType.order;
              phaseTemplate.cssClass = currentMainWorkType.cssClass;
              phaseTemplate.symbol = currentMainWorkType.symbol;
              if (currentMainWorkType.isClosed) {
                phaseTemplate.isClosed = true;
              } else {
                phaseTemplate.isClosed = false;
              }
            }
          }
        }
        this.phaseTemplateList = phaseTemplateList;
      }
    } catch (error) { console.error(error); }
    finally {
      this._workService.SetDetailObject({
        selectedMainWorkTypeList: this.selectedMainWorkTypeList,
        selectedMainWorkType: this.selectedMainWorkType,
        selectedSubWork: this.selectedSubWork,
        selectedWork: this.selectedWork,
        mainWorkTypeList: this.mainWorkTypeList,
        invokeComponents: ['Task'],
        transferable: [{ name: 'phaseTemplateList', object: this.phaseTemplateList }]
      });
      this.hasLoaded = true;
    }
  }

  private RemoveMwtPhase(mwtPhaseId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.delete(API.main_work_type_work.deleteMwtPhase, mwtPhaseId.toString() + '/' + this.selectedWork.id, true)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }
  //#endregion

  //#region Events

  updateView() {
    this.phaseTemplateList = [];
    this.SyncPhaseData();
    this.refresh.emit(this.selectedSubWork);
  }

  /**
   * when change the main work type of phase if selected main work type list has multiple items
   */

  OnRemoveMwtPhase(phaseTemplate: WorkTypePhaseTemplate) {
    if (phaseTemplate) {
      swal({
        title: this._commonDataService.localizationLabelList['phases'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      }).then((result) => {
        if (result.value) {
          this.commonUiService.isSpinnerVisible = true;
          this.RemoveMwtPhase(phaseTemplate.mwtPhaseId)
            .then(response => {
              if (response['_statusCode'] === 200) {
                const index: number = this.phaseTemplateList.findIndex(x => Number(x.mwtPhaseId) === Number(phaseTemplate.mwtPhaseId));
                if (index !== -1) { this.phaseTemplateList.splice(index, 1); }
                this._toastyService.success({
                  title: this._commonDataService.localizationLabelList['phases'],
                  msg: this._commonDataService.localizationLabelList['delete_success'],
                  showClose: true,
                  theme: 'default',
                  timeout: 3000,
                  onAdd: (toast: ToastData) => { },
                  onRemove: (toast: ToastData) => { }
                });
              } else if (response['_statusCode'] === 409) {
                this._toastyService.error({
                  title: this._commonDataService.localizationLabelList['phases'],
                  msg: this._commonDataService.localizationLabelList['delete_error'],
                  showClose: true,
                  theme: 'default',
                  timeout: 3000,
                  onAdd: (toast: ToastData) => { },
                  onRemove: (toast: ToastData) => { }
                });
              }
              this.commonUiService.isSpinnerVisible = false;
            });
        }
      });
    }
  }

  OnChangePhaseDates() {
    if (this.mwtPhase.startDateObject && this.mwtPhase.endDateObject) {
      const start = new Date(this.commonUiService.ConvertDateFormatToString(this.mwtPhase.startDateObject));
      const end = new Date(this.commonUiService.ConvertDateFormatToString(this.mwtPhase.endDateObject));
      if (end < start) {
        this.mwtPhase.endDateObject = null;
        this._toastyService.error({
          title: this._commonDataService.localizationLabelList['works'],
          msg: this._commonDataService.localizationLabelList['end_date_should_be_greater_than_start_date'],
          showClose: true,
          theme: 'default',
          timeout: 5000,
          onAdd: (toast: ToastData) => { },
          onRemove: (toast: ToastData) => { }
        });
      }
    }
  }

  //#endregion

  //#region Other

  OnOpenModal(modal: string) {
    let mwtList = [] as MainWorkType[];
    for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
      const mwt = this.selectedMainWorkTypeList[index];
      if (!mwt.isClosed) {
        mwtList.push(mwt);
      }
    }
    this.showPopUp = true;
    this._modalService.Invoke(modal, true, {
      isEdit: false,
      selectedMainWorkTypeList: mwtList,
      selectedMainWorkType: this.selectedMainWorkType,
      selectedWork: this.selectedWork
    }, false);
  }

  OnEditMwtPhase(phaseTemplate: WorkTypePhaseTemplate) {
    let mwtList = [] as MainWorkType[];
    for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
      const mwt = this.selectedMainWorkTypeList[index];
      if (!mwt.isClosed) {
        mwtList.push(mwt);
      }
    }
    this.showPopUp = true;
    this._modalService.Invoke('phases-modal', true, {
      isEdit: true,
      phaseTemplate: phaseTemplate,
      selectedMainWorkTypeList: mwtList,
      selectedMainWorkType: this.selectedMainWorkType,
      selectedWork: this.selectedWork
    }, true);
  }
  //#endregion
}

