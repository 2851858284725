export const ToastyTypes = {
  Success: 'success',
  Error: 'error',
  Warning: 'warning',
  Info: 'info'
};

export const CustomTranslations = {
  'en': {
    'select': '--Select--',
    'served': 'Served',
    'pending': 'Pending',
    'finalized': 'Finalized',
    'general': 'General',
    'clinic': 'Clinic',
    'laboratory': 'Laboratory',
    'all': 'All',
    'departures': 'Departures',
    'regularization': 'Regularization',
    'entry': 'Tickets',
    'new': 'New',
    'order_accepted': 'Accepted',
    'sent_order': 'Sent',
    'cancel_order': 'Cancelled',
    'order_sent': 'Orders sent',
    'at_reception': 'At reception',
    'received': 'Received',
    'on_reception': 'On reception',
    'generatereceiptbycustomer': 'Generate Receipt By Customer',
    'generatereceiptbyinvoice': 'Generate Receipt By Invoice',
    'receipt': 'Receipt',
    'classic_view': 'Receipt classic view',
    'high': 'High',
    'half': 'Half',
    'low': 'Low',
    'shipment': 'Shipment',
    'pickup': 'Pick up',
    'inventory': 'Inventory',
    'with_details': 'Delivery note with details',
    'without_details': 'Delivery note without details',
    'templete_1': 'Template 1',
    'templete_2': 'Template 2',
    'templete_3': 'Template 3',
    'inv_templete_1': 'Inv without detail',
    'inv_templete_2': 'Inv with detail',
    'sent': 'Sent',
    'in_clinic': 'In clinic',
    'in_laboratory': 'In laboratory',
    'order_date': 'Order Date',
    'deliver_date': 'Deliver Date',
    'actual_delivery_date': 'Actual Delivery Date',
    'january': 'January',
    'february': 'February',
    'march': 'March',
    'april': 'April',
    'may': 'May',
    'june': 'June',
    'july': 'July',
    'august': 'August',
    'september': 'September',
    'october': 'October',
    'november': 'November',
    'december': 'December',
    'normal': 'Normal',
    'urgent': 'Urgent',
    'work': 'Work',
    'task': 'Task',
    'phases': 'Phases',
    'article': 'Article',
    'product': 'Product',
    'color': 'Color',
    'clinicmaterial': 'ClinicMaterial',
    'mainworktype': 'MainWorkType',
    'implantdata': 'ImplantData',
    'view': 'View',
    'add': 'Add',
    'delete': 'Delete',
    'finalizado': 'Finalized',
    'cancelada': 'Cancelled',
    'procesointerno': 'Internal process',
    'pendiente': 'Pending',
    'procesoexternalizado': "Outsourced process",
    'enpruebas': 'In testing',
    'manufacturing': 'Manufacturing',
    'post-marketing': 'Post-Marketing'
  },
  'es': {
    'select': '--Seleccionar--',
    'served': 'Servido',
    'pending': 'Pendiente',
    'finalized': 'Finalizado',
    'general': 'General',
    'clinic': 'Clínica',
    'laboratory': 'Laboratorio',
    'all': 'Todos',
    'departures': 'Salidas',
    'regularization': 'Regularización',
    'entry': 'Entradas',
    'new': 'Nuevo',
    'order_accepted': 'Aceptado',
    'sent_order': 'Enviado',
    'cancel_order': 'Cancelado',
    'order_sent': 'Pedidos enviados',
    'at_reception': 'En recepción',
    'received': 'Recepcionado',
    'on_reception': 'En recepción',
    'generatereceiptbycustomer': 'Generar recibo por cliente',
    'generatereceiptbyinvoice': 'Generar recibo por cliente/Factura',
    'receipt': 'Recibo',
    'classic_view': 'Recibo vista clásica',
    'high': 'Alta',
    'half': 'Media',
    'low': 'Baja',
    'shipment': 'Envío',
    'pickup': 'Recogida',
    'inventory': 'Inventario',
    'with_details': 'Albarán con detalle',
    'without_details': 'Albarán sin detalle',
    'templete_1': 'Plantilla 1',
    'templete_2': 'Plantilla 2',
    'templete_3': 'Plantilla 3',
    'inv_templete_1': 'Fra sin detalle',
    'inv_templete_2': 'Fra con detalle',
    'sent': 'Enviado',
    'in_clinic': 'En clínica',
    'in_laboratory': 'En Laboratorio',
    'order_date': 'Fecha Encargo',
    'deliver_date': 'Fecha Entrega',
    'actual_delivery_date': 'Fecha Entrega real',
    'january': 'Enero',
    'february': 'Febrero',
    'march': 'Marzo',
    'april': 'Abril',
    'may': 'Mayo',
    'june': 'Junio',
    'july': 'Julio',
    'august': 'Agosto',
    'september': 'Septiembre',
    'october': 'Octubre',
    'november': 'Noviembre',
    'december': 'Diciembre',
    'normal': 'Normal',
    'urgent': 'Urgente',
    'work': 'Trabajo',
    'task': 'Tarea',
    'phases': 'Fases',
    'article': 'Artículo',
    'product': 'Producto',
    'color': 'Color',
    'clinicmaterial': 'Materiales de clínica',
    'mainworktype': 'Tipo de trabajo',
    'implantdata': 'Datos del implante',
    'view': 'Ver',
    'add': 'Añadir',
    'delete': 'Borrar',
    'finalizado': 'Finalizado',
    'cancelada': 'Cancelada',
    'procesointerno': 'Proceso Interno',
    'pendiente': 'Pendiente',
    'procesoexternalizado': "Proceso externalizado",
    'enpruebas': 'En Pruebas',
    'manufacturing': 'Fabricación',
    'post-marketing': 'Poscomercialización'
  },
  'pt': {
    'select': '--Selecione--',
    'served': 'Servido',
    'pending': 'Pendiente',
    'finalized': 'Finalizado',
    'general': 'Geral',
    'clinic': 'Consultório',
    'laboratory': 'Laboratório',
    'all': 'Todos',
    'departures': 'Salidas',
    'regularization': 'Regularización',
    'entry': 'Entradas',
    'new': 'Nuevo',
    'order_accepted': 'Pedido aceptado',
    'sent_order': 'Pedido enviado',
    'cancel_order': 'Cancelar pedido',
    'order_sent': 'Pedidos enviados',
    'at_reception': 'En recepción',
    'received': 'Recepcionado',
    'on_reception': 'En recepción',
    'generatereceiptbycustomer': 'Generar recibo por cliente',
    'generatereceiptbyinvoice': 'Generar recibo por cliente/Factura',
    'receipt': 'Recibo',
    'classic_view': 'Recibo vista clásica',
    'high': 'Alta',
    'half': 'Media',
    'low': 'Baja',
    'shipment': 'Envío',
    'pickup': 'Recogida',
    'inventory': 'Inventario',
    'with_details': 'Albarán con Detalle',
    'without_details': 'Albarán sin detalle',
    'templete_1': 'Plantilla 1',
    'templete_2': 'Plantilla 2',
    'templete_3': 'Plantilla 3',
    'inv_templete_1': 'Fra sem detalhe',
    'inv_templete_2': 'Fra com detalhe',
    'sent': 'Enviado',
    'in_clinic': 'En clínica',
    'in_laboratory': 'En Laboratorio',
    'january': 'Enero',
    'february': 'Febrero',
    'march': 'Marzo',
    'april': 'Abril',
    'may': 'Mayo',
    'june': 'Junio',
    'july': 'Julio',
    'august': 'Agosto',
    'september': 'Septiembre',
    'october': 'Octubre',
    'november': 'Noviembre',
    'december': 'Diciembre',
    'normal': 'Normal',
    'urgent': 'Urgente',
    'work': 'Trabalho',
    'task': 'Tarefa',
    'phases': 'Fases',
    'article': 'Artigo',
    'product': 'Produtos',
    'color': 'Color',
    'clinicmaterial': 'Material da clínica',
    'mainworktype': 'Tipo de trabalho',
    'implantdata': 'Dados do implante',
    'view': 'Visão',
    'add': 'Adicionar',
    'delete': 'Eliminar',
    'finalizado': 'Finalizado',
    'cancelada': 'Cancelada',
    'procesointerno': 'Proceso Interno',
    'pendiente': 'Pendiente',
    'procesoexternalizado': "Proceso externalizado",
    'enpruebas': 'En Pruebas',
    'manufacturing': 'Fabricação',
    'post-marketing': 'Pós-comercialização'
  }
};

export const Constants = {
  Operarios: 'operarios',
  ReceiptGenerateType: 'ReceiptGenerateType'
};

export const SeriesTypes = {
  DeliveryNote: 'Delivery Note',
  Invoice: 'Bill',
  Budget: 'Budget',
  Order: 'Order',
  Receipt: 'Receipt',
  CancelledInvoice: 'Invoice Cancel',
  CancelledReceipt: 'Reciept Cancel',
};

export const ReportTemplate = [
  { value: 1, description: 'Template 1' },
  { value: 2, description: 'Template 2' },
  { value: 3, description: 'Template 3' }
];

export const InvoiceReportTemplate = [
  { value: 1, description: 'inv_templete_1' },
  { value: 2, description: 'inv_templete_2' }
];

export const ReceiptReportTemplate = [
  { value: 1, description: 'Template 1' },
  { value: 2, description: 'Template 2' },
  { value: 3, description: 'Template 3' }
];

export const ReceiptViewTemplate = [
  { value: 1, description: 'classic_view' },
  { value: 2, description: 'receipt' },
];

export const DeliverynoteViewTemplate = [
  { value: 1, description: 'with_details' },
  { value: 2, description: 'without_details' },
];

// don't change value property of this list, (used in product movement popup)
export const MovementTypes = [
  { value: 1, description: 'Entry' },
  { value: 2, description: 'Departures' },
  { value: 3, description: 'Regularization' }
];

// don't change value property of this list, (used in order popup)
export const OrderStatus = [
  { value: 1, description: 'New' },
  { value: 2, description: 'order_accepted' },
  { value: 3, description: 'sent_order' },
  { value: 4, description: 'cancel_order' }
];

export const ReceiptStatus = [
  { id: 1, value: 'Orders Sent', description: 'order_sent' },
  { id: 2, value: 'At reception', description: 'at_reception' },
  { id: 3, value: 'Received', description: 'received' }
];

export const shippingMethods = [
  { id: 1, value: 'method1', description: 'method 1' },
  { id: 2, value: 'method2', description: 'method 2' },
  { id: 3, value: 'method3', description: 'method 3' },
  { id: 4, value: 'method4', description: 'method 4' }
];

export const OrderReceptionStatus = [
  { id: 1, value: 'on_reception', description: 'On reception' },
  { id: 2, value: 'received', description: 'Received' },
];

// don't change value property of this list, (used in external data popup)
export const ExternalDataStatus = [
  { value: 1, description: 'sent' },
  { value: 2, description: 'received' }
];

// don't change value property of this list, (used in external data popup)
export const LocalionTypes = [
  { value: 1, description: 'in_clinic' },
  { value: 2, description: 'in_laboratory' }
];

export const dateTypes = [
  { value: 1, description: 'order_date' },
  { value: 2, description: 'deliver_date' },
  { value: 3, description: 'actual_delivery_date' },
];

export const ListNames = {
  ClientList: 'client-list',
  PatientList: 'patient-list',
  ClinicMaterialList: 'clinic-material-list',
  WorkStatesList: 'work-states-list',
  SubWorkSituationList: 'sub-work-situation-list',
  MainWorkTypeStatusList: 'mwt-status-list',
  ArticleList: 'articleList',
  IssuerList: 'issureList',
  ColorList: 'colorList',
  LocalizationList: 'localizationList',
  MetalList: 'metalList',
  WorkTypeTemplateList: 'workTypeTemplateList',
  ProductList: 'productList',
  WorkerList: 'worker-list',
  MessengerList: 'messenger-list',
  ShippingFormList: 'shipping-form-list',
  ExternalCenterList: 'external-center-list',
  SubscriptionReasonList: 'subscription-reason-list',
  LabSectionList: 'lab-section-list',
  TownList: 'town-list',
  TaskList: 'task-list',
  IvaTypeList: 'iva-type-list',
  IncidentList: 'incident-list',
  UserList: 'user-list'
};

export const ModalNames = {
  WorkModal: 'add-edit-work-modal',
  ClinicMaterialModal: 'clinic-material-modal',
  ImplantDataModal: 'implant-data-modal-1',
  NoteModal: 'note-modal',
  DocumentModal: 'document-modal',
  DocumentViewModal: 'document-view-modal',
  phasesModal: 'phases-modal',
  CancelphasesModal: 'cancel-phases-modal',
  TaskModal: 'task-modal',
  ColorModal: 'color-modal',
  AddColorModal: 'add-color-modal-2',
  BudgetModal: 'generate-budget-modal',
  ArticleModal2: 'add-article-modal-2',
  BudgetMainWorkTypeModal: 'budget-main-work-type-modal',
  SubWorkModal: 'sub-work-modal',
  DeliveryNoteModal: 'generate-delivery-modal',
  DeliveryNoteArticleModal: 'delivery-note-article-modal',
  BillModal: 'bill-model',
  BillNewInvoiceModal: 'bill-new-invoice-model',
  MainWorkTypeModal: 'add-main-work-type-modal',
  NewMainWorkTypeModal: 'add-worktype-modal',
  ArticleModal: 'add-article-modal',
  PrescriptionModal: 'add-prescriptions-modal',
  PrescriptionViewModal: 'add-prescription-view-modal',
  ProductModal: 'add-product-modal',
  WarehouseProductsModal: 'confirm-warehouse-products',
  WorkerModal: 'add-worker-modal',
  ShipmentModal: 'add-shippment-collection-modal',
  ExternalWorkModal: 'edit-external-work',
  IncidentModal: 'incidents-work-modal',
  IncidentDocumentModal: 'incidents-doc-view-modal',
  ExternalDocumentModal: 'external-doc-view-modal',

  // main task component
  Task: {
    AddPhase: 'add-phase-modal',
    AddProcess: 'add-process-modal',
    AddWorker: 'add-worker-modal'
  },
  AddMetalModal: 'add-metal-modal-2',
};

// don't change value property of this list, (used in shipment popup)
export const UrgencyType = [
  { value: 1, description: 'high' },
  { value: 2, description: 'half' },
  { value: 3, description: 'low' }
];

export const ShipmentTypes = {
  Shipment: 'shipment',
  Pickup: 'pickup'
};

export const ClientDefaultPrintReportTypes = {
  DeliveryNote: 'deliveryNote',
  Receipt: 'receipt',
  Invoice: 'invoice'
};

export const Piezas = [18, 17, 16, 15, 14, 13, 12, 11,
  21, 22, 23, 24, 25, 26, 27, 28,
  48, 47, 46, 45, 44, 43, 42, 41,
  31, 32, 33, 34, 35, 36, 37, 38];

export const NoteTypes = {
  Clinic: 'clinic',
  Lab: 'lab',
  General: 'general'
};

export const FolderPaths = {
  Uploads: 'Uploads',
  Worker: 'Worker',
  Auth: 'Auth',
  ExcelFiles: 'ExcelFiles',
  ExternalData: 'ExternalData',
  LabConfiguration: 'LabConfiguration',
  Document: 'Document',
  Documents: 'Documents',
  Prescriptions: 'Prescriptions',
  Product: 'Product',
  Provider: 'Provider'
};

// don't change value property of this list, (used in external data popup)
export const MonthsList = [
  { value: 1, description: 'january' },
  { value: 2, description: 'february' },
  { value: 3, description: 'march' },
  { value: 4, description: 'april' },
  { value: 5, description: 'may' },
  { value: 6, description: 'june' },
  { value: 7, description: 'july' },
  { value: 8, description: 'august' },
  { value: 9, description: 'september' },
  { value: 10, description: 'october' },
  { value: 11, description: 'november' },
  { value: 12, description: 'december' }
];

export const InvoiceStatusTypes = {
  Pending: 'pending',
  Cancelled: 'cancelled',
  Cancelled2: 'cancelled2'
};

export const ReceiptStatusTypes = {
  Pending: 'pending',
  Cancelled: 'cancelled',
  Cancelled2: 'cancelled2'
};

// don't change value property of this list, (used in job search)
export const PriorityOption = [
  { value: 1, description: 'normal' },
  { value: 2, description: 'urgent' }
];

// hardcoded dropdown to change when language is changed
export const DropdownNames = {
  InvoiceList: 'invoice-list',
  ReceiptList: 'receipt-list',
  DeliveryList: 'delivery-list',
  MonthList: 'month-list',
  JobSearchDateTypeList: 'job-search-date-type-list',
  JobSearchPriorityList: 'job-search-priority-list',
  UrgencyTypeList: 'urgency-type-list',
  OrderReceptionStatusList: 'order-reception-status-list',
  OrderStatusList: 'order-status-list',
  MovementTypesList: 'movement-types-list',
  MovementTypesList2: 'movement-types-list-2',
  TaskStatusList: 'task-status-list',
  PhaseStatusList: 'phase-status-list',
  PhaseStatusList2: 'phase-status-list-2',
  GenderList: 'gender-list',
  ProductInventoryFrequencyList: 'product-inventory-frequency-list',
  AuxiliaryNumTypeList: 'auxiliary-num-type-list',
  WorkStateList: 'work-state-list',
  OrderStatusList2: 'order-status-list-2',
  ArticleExpandDateList: 'article-expand-date-list',
  PeriodList: 'period-list',
  WorkTypeExpandDateList: 'work-type-expand-date-list',
  ReceiptGenerateTypeList: 'receipt-generate-type-list',
  WarehouseStockStatus: 'warehouse-stock-status',
  ExternalShipmentStatus: 'external-shipment-status',
  NoteTypeList: 'note-type-list',
  InvoiceStatusList: 'invoice-status-list',
  IncidentTypeList: 'incident-type-list'
};

export const InvoiceList = [
  { value: 1, description: 'Inv without detail' },
  { value: 2, description: 'Inv with detail' }
];

export const ReceiptList = [
  { value: 1, description: 'Receipt classic view' },
  { value: 2, description: 'Receipt' }
];

export const DeliveryList = [
  { value: 1, description: 'Delivery note with details' },
  { value: 2, description: 'Delivery note without details' }
];

export const MonthList = [
  { value: 0, description: '--Select--' },
  { value: 1, description: 'January' },
  { value: 2, description: 'February' },
  { value: 3, description: 'March' },
  { value: 4, description: 'April' },
  { value: 5, description: 'May' },
  { value: 6, description: 'June' },
  { value: 7, description: 'July' },
  { value: 8, description: 'August' },
  { value: 9, description: 'September' },
  { value: 10, description: 'October' },
  { value: 11, description: 'November' },
  { value: 12, description: 'December' }
];

export const JobSearchDateTypeList = [
  // { value: 0, description: 'All' },
  { value: 1, description: 'Order Date' },
  { value: 2, description: 'Deliver Date' },
  { value: 3, description: 'Actual Delivery Date' }
];

export const JobSearchPriorityList = [
  { value: 0, description: '--Select--' },
  { value: 1, description: 'Normal' },
  { value: 2, description: 'Urgent' }
];

export const UrgencyTypeList = [
  { value: 1, description: 'High' },
  { value: 2, description: 'Half' },
  { value: 3, description: 'Low' }
];
////
export const OrderReceptionStatusList = [
  { id: 0, value: 0, description: '--Select--' },
  { id: 1, value: 1, description: 'On reception' },
  { id: 2, value: 2, description: 'Received' }
];

export const OrderStatusList = [
  { value: 0, description: '--Select--' },
  { value: 1, description: 'New' },
  { value: 2, description: 'Order accepted' },
  { value: 3, description: 'Sent order' },
  { value: 4, description: 'Cancel order' }
];

export const MovementTypesList = [
  { value: 0, description: '--Select--' },
  { value: 1, description: 'Tickets' },
  { value: 2, description: 'Departures' },
  { value: 3, description: 'Regularization' }
];

export const MovementTypesList2 = [
  { value: 0, description: 'All' },
  { value: 1, description: 'Tickets' },
  { value: 2, description: 'Departures' },
  { value: 3, description: 'Regularization' }
];

export const TaskStatusList = [
  { id: 'Pendiente', value: 'Pendiente', text: 'Pending' },
  { id: 'Iniciada', value: 'Iniciada', text: 'Started' },
  { id: 'Finalizado', value: 'Finalizado', text: 'Finalized' },
];


export const PhaseStatusList = [
  { id: 0, value: 0, text: 'Select phase status' },
  { id: 1, value: 'Todos', text: 'All' },
  { id: 2, value: 'Pendiente', text: 'Pending' },
  { id: 3, value: 'Finalizado', text: 'Finalized' },
  { id: 4, value: 'En curso', text: 'In Progress' },
  { id: 5, value: 'Cancelada', text: 'Cancelled' }
];

export const PhaseStatusList2 = [
  { value: 'En curso', text: 'In Progress' },
  { value: 'Finalizado', text: 'Finalized' },
  { value: 'Cancelada', text: 'Cancelled' },
  { value: 'En pruebas', text: 'In tests' },
  { value: 'Tareas finalizadas', text: 'Completed tasks' },
  { value: 'Pendiente', text: 'Pending' },
  { value: 'Externo', text: 'External' }
];

export const GenderList = [
  { value: '0', description: '--Select--' },
  { value: 'male', description: 'Male' },
  { value: 'female', description: 'Female' },
  { value: 'other', description: 'Other' }
];

export const ProductInventoryFrequencyList = [
  { value: '0', description: '-Select inventory frequency-' },
  { value: 'Anual', description: 'Annual' },
  { value: 'Semestral', description: 'Biannual' },
  { value: 'Mensual', description: 'Monthly' },
  { value: 'No_Inventariable', description: 'Not inventoriyable' },
  { value: 'Trimestral', description: 'Quarterly' }
];

export const AuxiliaryNumTypeList = [
  { value: '0', description: '-Select number type-' },
  { value: 'Anual', description: 'Annual' },
  { value: 'Continuo', description: 'Continues' }
];

export const WorkStateList = [
  { value: '0', description: '-Select-' },
  { value: 'Cancelada', description: 'Cancelled' },
  { value: 'Finalizado', description: 'Finalized' },
  { value: 'Proceso Interno', description: 'Internal process' },
  { value: 'En Pruebas', description: 'In Testing' },
  { value: 'Proceso externalizado', description: 'Outsourced process' },
  { value: 'Pendiente', description: 'Pending' },
  { value: 'Todos', description: 'All' }
];

export const OrderStatusList2 = [
  { value: 0, description: '--Select--' },
  { value: 1, description: 'New' },
  { value: 2, description: 'Accepted' },
  { value: 3, description: 'Sent' },
  { value: 4, description: 'Cancelled' }
];

export const ArticleExpandDateList = [
  { value: 1, description: 'Created date' },
  { value: 2, description: 'Delivery date' },
];

export const PeriodList = [
  { id: 0, value: 0, description: '--Select period--' },
  { id: 2, value: 3, description: 'Last 3 months' },
  { id: 3, value: 6, description: 'Last 6 months' },
  { id: 4, value: 9, description: 'Last 9 months' },
  { id: 5, value: 12, description: 'Last 12 months' }
];

export const WorkTypeExpandDateList = [
  { value: 1, description: 'Created date' },
  { value: 2, description: 'Delivery date' },
];

export const WorkScheduleType = [
  { value: 'general', description: 'general' },
  { value: 'period', description: 'period' },
  { value: 'day', description: 'day' },
];

export const ReceiptGenerateTypeList = [
  { value: 1, description: 'Generate Receipt By Customer' },
  { value: 2, description: 'Generate Receipt By Invoice' }
];

export const WarehouseStockStatus = [
  { value: 0, description: 'All' },
  { value: 1, description: 'Active' },
  { value: 2, description: 'Inactive' }
];

export const ExternalShipmentStatus = [
  { value: 0, description: '-Select-' },
  { value: 1, description: 'Sent' },
  { value: 2, description: 'Received' }
];

export const NoteTypeList = [
  { value: 'general', description: 'General' },
  { value: 'clinic', description: 'Clinic' },
  { value: 'lab', description: 'Laboratory' }
];

export const WorkConfigSelection = [
  { value: 'PatientSearchSelection', description: 'PatientSearchSelection' }
];

export const InvoiceStatusList = [
  { value: 0, description: 'All' },
  { value: 1, description: 'No Doc' },
  { value: 2, description: 'Delivery note' },
  { value: 3, description: 'Invoiced' }
];

export const LableConfigList = [
  { value: 'nif', description: 'nif' },
  { value: 'dni', description: 'dni' },
  { value: 'social_sec_no', description: 'social_sec_no' },
  { value: 'collegiate_no', description: 'collegiate_no' },
  { value: 'license_number', description: 'license_number' },
  { value: 'vat', description: 'vat' },
  { value: 'town', description: 'town' },
  { value: 'province', description: 'province' },
  { value: 'cp', description: 'cp' }
];

export const IncidentTypeList = [
  { value: '1', description: 'Manufacturing', textCode: 'manufacturing' },
  { value: '2', description: 'Post-Marketing', textCode: 'post-marketing' },
];

