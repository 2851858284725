import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { TaskTopes } from 'src/app/modules/tasks/entities/task-topes';
import { MwtTaskService } from '../../../services/mwt-task.service';

@Component({
  selector: 'app-task-topes-edit-modal',
  templateUrl: './task-topes-edit-modal.component.html'
})
export class TaskTopesEditModalComponent implements OnInit {
  @Input() taskTopes: TaskTopes;
  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  constructor(public commonUiService: CommonUIService, private _toastyService: Toast, private _commonDataService: CommonDataService,
    private _taskService: MwtTaskService) { }

  ngOnInit() {
  }

  OpenModal() {
    this.commonUiService.OpenBasicModal('edit-daily-task');
  }

  async OnSaveTaskTopes() {
    this.commonUiService.isSpinnerVisible = true;
    const topesResponse = await this._taskService.UpdateTaskTopes(this.taskTopes);
    if (topesResponse) {
      const body = JSON.parse(topesResponse['_body']);
      if (body['_isSuccsess']) {
        this.commonUiService.CloseBasicModal('edit-daily-task');
        this.closeModel.emit(this.taskTopes);
        this._toastyService.success({
          title: this._commonDataService.localizationLabelList['task_stops'],
          msg: this._commonDataService.localizationLabelList['save_success']
        });
        this.commonUiService.isSpinnerVisible = false;
      } else {
        this._toastyService.error({
          title: this._commonDataService.localizationLabelList['task_stops'],
          msg: this._commonDataService.localizationLabelList['save_error']
        });
        this.commonUiService.isSpinnerVisible = false;
        this.commonUiService.CloseBasicModal('edit-daily-task');
      }
    }
  }

}
