import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Doctor } from 'src/app/modules/work/entities/doctor';
import { SavedNumber } from '../../../shared/numbering-process/numbering-process';
import { Article } from '../../articles/entities/article';
import { ClinicMaterial } from '../../auxiliary/entities/clinic-material';
import { Color } from '../../auxiliary/entities/Color';
import { Localizations } from '../../auxiliary/entities/localizations';
import { Metals } from '../../auxiliary/entities/metals';
import { StatesOfWork } from '../../auxiliary/entities/status-of-work';
import { Client } from '../../client/entities/client';
import { Issuer } from '../../client/entities/client-issuer';
import { ClientSeries } from '../../client/entities/client-series';
import { Series } from '../../client/entities/series';
import { DropDownOption } from '../../common/entities/dropdown-option';
import { ApiService } from '../../common/services/api.service';
import { CommonDataService } from '../../common/services/common.data.service';
import { InvoiceHeader } from '../../invoice/entities/invoice-header';
import { Product } from '../../products/entities/product';
import { WorkType } from '../../work-type/entities/work-type';
import { WorkTypeArticleTemplate, WorkTypeTemplate } from '../../work-type/entities/work-type-model-templates';
import { Budget } from '../entities/budget';
import { DeliveryNote } from '../entities/delivery-note';
import { Document } from '../entities/document';
import { SavedGeneratedNumber } from '../entities/generate-number-model';
import { MainWorkType } from '../entities/main-work-type';
import { MwtColor } from '../entities/main-work-type-color';
import { MwtDto } from '../entities/mwt-dto';
import { Patient } from '../entities/patient';
import { SubWork } from '../entities/sub-work';
import { Task } from '../entities/task';
import { Work } from '../entities/work';
import API from './../../common/api.config.json';
import { TaskDetail } from '../../work-tablet/entities/task-detail-tablet';
import { MwtNo, WorkNo } from '../entities/global-search-no-filters';

@Injectable()
export class WorkService {

  public invoiceData: InvoiceHeader[] = [];
  public deliveryNoteData: DeliveryNote[] = [];
  public budgetData: Budget[] = [];
  isShowEmailPopup = false;

  private subject: BehaviorSubject<{
    selectedMainWorkTypeList: MainWorkType[],
    selectedMainWorkType: MainWorkType,
    selectedSubWork: SubWork,
    selectedWork: Work,
    mainWorkTypeList: MainWorkType[],
    invokeComponents: string[],
    transferable?: { name: string, object: any | any[] }[]
  }> =
    new BehaviorSubject<{
      selectedMainWorkTypeList: MainWorkType[],
      selectedMainWorkType: MainWorkType,
      selectedSubWork: SubWork,
      selectedWork: Work,
      mainWorkTypeList: MainWorkType[],
      invokeComponents: string[],
      transferable?: { name: string, object: any | any[] }[]
    }>
      ({
        selectedMainWorkTypeList: [],
        selectedMainWorkType: new MainWorkType(),
        selectedSubWork: new SubWork(),
        selectedWork: new Work(),
        mainWorkTypeList: [],
        invokeComponents: [],
        transferable: []
      });

  detailObject = this.subject.asObservable();

  symbolClassList = [
    'icon-triangle',
    'icon-circle',
    'icon-square',
    'icon-heart',
    'icon-shield',
    'icon-play',
    'icon-bookmark',
    'icon-disc',
    'icon-star',
  ];

  badgeColorList = [
    '#f44336',
    '#4CAF50',
    '#3F51B5',
    '#9C27B0',
    '#E91E63',
    '#2196F3',
    '#00BCD4',
    '#FF5722',
    '#FF9800',
  ];

  cardBackgroundClassList = [
    'typeofwork1',
    'typeofwork2',
    'typeofwork3',
    'typeofwork4',
    'typeofwork5',
    'typeofwork6',
    'typeofwork7',
    'typeofwork8',
    'typeofwork9',
  ];

  deliveryNoteBorderColorClassList = [
    'deliverynote1',
    'deliverynote2',
    'deliverynote3',
    'deliverynote4',
    'deliverynote5',
    'deliverynote6',
    'deliverynote7',
    'deliverynote8',
    'deliverynote9',
    'deliverynote10',
    'deliverynote11',
    'deliverynote12',
    'deliverynote13',
    'deliverynote14',
    'deliverynote15',
    'deliverynote16',
    'deliverynote17',
    'deliverynote18',
    'deliverynote19',
    'deliverynote20'
  ];

  phaseStatusList = ['En curso', 'Finalizado', 'Cancelada',
    'En pruebas', 'Tareas finalizadas', 'Pendiente', 'Externo'];

  taskStatusList = ['Pendiente', 'Finalizado', 'Iniciada'];

  noteTypeList: any[] = [
    { label: 'general', value: 'general' },
    { label: 'clinic', value: 'clinic' },
    { label: 'laboratory', value: 'lab' }];

  constructor(private _apiService: ApiService,
    private _commonDataService: CommonDataService) { }

  //#region rxjs

  SetDetailObject(args:
    {
      selectedMainWorkTypeList: MainWorkType[],
      selectedMainWorkType: MainWorkType,
      selectedSubWork: SubWork,
      selectedWork: Work,
      mainWorkTypeList: MainWorkType[],
      invokeComponents: string[],
      transferable?: { name: string, object: any | any[] }[]
    }): void { this.subject.next(args); }

  //#endregion

  //#region Lists

  GetArticleTemplateListByWorkTypeTemplateId(workTypeId: number): Promise<WorkTypeArticleTemplate[]> {
    return new Promise<WorkTypeArticleTemplate[]>((resolve, reject) => {
      this._apiService.get(API.work_type.getArticleListById + workTypeId)
        .subscribe((list: WorkTypeArticleTemplate[]) => resolve(list),
          err => reject(err));
    });
  }

  GetClientList() { return this._apiService.get(API.client.getClientList, true).map(list => list as Client[]).toPromise(); }

  GetPatientList() { return this._apiService.get(API.patient.getPatientList, true).map(list => list as Patient[]).toPromise(); }

  GetDoctorListByClientId(clientId: any) {
    return this._apiService.get(API.doctor.getDoctorListByClientId + clientId)
      .map(list => list as Doctor[]).toPromise();
  }

  GetWorkTypeTemplateList(): Promise<WorkType[]> {
    return new Promise<WorkType[]>((resolve, reject) => {
      this._apiService.get(API.work_type.getWorkTypeList, true)
        .subscribe((list: WorkType[]) => resolve(list),
          err => reject(err));
    });
  }

  GetMainWorkTypeStatusList() {
    return this._apiService.get(API.status_of_work.getStatusOfWorkList, true).map(list => list as StatesOfWork[]).toPromise();
  }

  async GetWorkStatesDropdownList(): Promise<DropDownOption[]> {
    const workStatesList = await this.GetMainWorkTypeStatusList();
    const workStatesDropDownList: DropDownOption[] = [];
    workStatesList.forEach(element => {
      const option = new DropDownOption(
        element.description,
        this._commonDataService.localizationLabelList[element.description],
        false
      );
      workStatesDropDownList.push(option);
    });

    workStatesDropDownList.push(new DropDownOption('Todos', this._commonDataService.localizationLabelList['Todos'], false));
    workStatesDropDownList.sort((a, b) => (a.label > b.label) ? 1 : -1);
    workStatesDropDownList.unshift(new DropDownOption('0', this._commonDataService.localizationLabelList['select'], false));

    return workStatesDropDownList;
  }

  GetSubWorkSituationDropDownList(): DropDownOption[] {
    const subWorkSituationDropDownList: DropDownOption[] = [];
    subWorkSituationDropDownList.push(new DropDownOption(this._commonDataService.localizationLabelList['select'],
      this._commonDataService.localizationLabelList['select'], false));
    subWorkSituationDropDownList.push(new DropDownOption('Nuevo', 'Nuevo', false));
    subWorkSituationDropDownList.push(new DropDownOption('Repetición', 'Repetición', false));
    subWorkSituationDropDownList.push(new DropDownOption('Varios', 'Varios', false));
    return subWorkSituationDropDownList;
  }

  GetTaskList() { return this._apiService.get(API.task.getTaskList, true).map(list => list as Task[]).toPromise(); }

  //#endregion

  //#region Work

  AddWork(work: Work): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.post(API.work.addWork, work, false, true)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }

  EditWork(work: Work) {
    return this._apiService.put(API.work.editWork + work.id, work, false, true).toPromise();
  }

  RemoveWork(workId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.delete(API.work.deleteWork, workId.toString(), true)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }

  async GetWorkById(id: number) { return this._apiService.get(API.work.getById + id).map(x => x as Work).toPromise(); }

  async GetExistingWork(clientId: number, patientId: number) {
    return this._apiService.get(API.work.getExistingWorks + clientId + '/' + patientId, true).map(x => x as Work).toPromise();
  }

  SearchWorkNo(query: string) {
    return this._apiService.get(API.work.search_work_no + query, false)
      .map(list => list as WorkNo[])
      .toPromise();
  }

  SearchMwtNo(query: string) {
    return this._apiService.get(API.main_work_type_work.search_mwt_no + query, false)
      .map(list => list as MwtNo[])
      .toPromise();
  }

  //#endregion

  //#region MainWorkType

  SaveMwt(mwtDto: MwtDto): Promise<any> {
    return this._apiService
      .post(API.main_work_type_work.saveMwt, mwtDto, false, true)
      .toPromise();
  }

  UploadMwtDocuments(formData: FormData) {
    return this._apiService
      .upload(API.file_upload.multi_upload, formData)
      .toPromise();
  }

  AddMainWorkType(mainWorkType: MainWorkType): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.post(API.main_work_type_work.saveMainWorkType, mainWorkType, false, true)
        .subscribe(response => resolve(response),
          err => reject(Error(err)));
    });
  }

  UpdateMainWorkType(mainWorkType: MainWorkType): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.put(API.main_work_type_work.updateMainWorkType + mainWorkType.id, mainWorkType, false, true)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }

  DeleteMainWorkType(mainWorkType: MainWorkType): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.put(API.main_work_type_work.deleteMainWorkType + mainWorkType.id, mainWorkType, false, true)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }


  CheckBeforeDelete(id: number) {
    return this._apiService.get(API.main_work_type_work.checkBeforeDelete + id, true).toPromise();
  }

  CheckPlanningDates(mwtId: number) {
    return this._apiService.get(API.main_work_type_work.check_planning_dates
      + mwtId, true).toPromise();
  }

  SavePlanningDates(mwtId: number) {
    return this._apiService.post(API.main_work_type_work.save_planning_dates
      + mwtId, true).toPromise();
  }

  //#endregion

  //#region Number Series

  RemoveSavedGeneratedNumberBySeriesIdAndSavedNumber(seriesId: number, savedNumber: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._apiService.get(API.series.removeBySeriesIdAndSavedNumber + seriesId + '/' + savedNumber, true)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }

  GetSeriesBySeriesType(seriesType: string): Promise<Series> {
    return new Promise<Series>((resolve, reject) => {
      this._apiService.get(API.series.getBySeriesType + seriesType, true)
        .subscribe((series: Series) => resolve(series),
          err => reject(err));
    });
  }

  GetSeriesByClientId(clientId: number): Promise<ClientSeries[]> {
    return new Promise<ClientSeries[]>((resolve, reject) => {
      this._apiService.get(API.client_series.getClientSeriesByClientId + clientId)
        .subscribe((list: ClientSeries[]) => resolve(list),
          err => reject(Error(err)));
    });
  }

  GetSeriesListByYearAndIssuerId(year: string, issuerId: number): Promise<Series[]> {
    return new Promise<Series[]>((resolve, reject) => {
      this._apiService.get(API.client_series.getSeriesByYearAndIssuerId + issuerId + '/' + year)
        .subscribe((list: Series[]) => resolve(list),
          err => reject(Error(err)));
    });
  }

  GetSeriesList(): Promise<Series[]> {
    return new Promise<Series[]>((resolve, reject) => {
      this._apiService.get(API.series.getSeriesList)
        .subscribe((list: Series[]) => resolve(list),
          err => reject(err));
    });
  }

  GetSeriesById(id: number): Promise<Series> {
    return new Promise<Series>((resolve, reject) => {
      this._apiService.get(API.series.getById + id)
        .subscribe((series: Series) => resolve(series),
          err => reject(err));
    });
  }

  GetSeriesListByIssuerId(issuerId: number): Promise<Series[]> {
    return new Promise<Series[]>((resolve, reject) => {
      this._apiService.get(API.invoice.getSeriesByIssuerId + issuerId)
        .subscribe((list: Series[]) => resolve(list),
          err => reject(err));
    });
  }

  GetWorkTypeListByArticleId(id: number): Promise<WorkType[]> {
    return new Promise<WorkType[]>((resolve, reject) => {
      this._apiService.get(API.work_type.GetWorkTypeListByArticleId + id)
        .subscribe((workTypeList: WorkType[]) => resolve(workTypeList),
          err => reject(err));
    });
  }

  GetDefaultIssuer() {
    return this._apiService.get(API.issuer.getDefaultIssuer)
      .map(i => i as Issuer).toPromise();
  }

  GetIssureList(): Promise<Issuer[]> {
    return new Promise<Issuer[]>((resolve, reject) => {
      this._apiService.get(API.issuer.getIssuerList)
        .subscribe((list: Issuer[]) => resolve(list.filter(x => x.status === true)),
          err => reject(err));
    });
  }

  GetSavedGeneratedNumberListBySeriesId(seriesId: number): Promise<SavedGeneratedNumber[]> {
    return new Promise<SavedGeneratedNumber[]>((resolve, reject) => {
      this._apiService.get(API.series.GetSavedGeneratedNumberListBySeriesId + seriesId, true)
        .subscribe((list: SavedGeneratedNumber[]) => resolve(list),
          err => reject(err));
    });
  }

  GetSavedNumberListBySeriesId(id: number) {
    return this._apiService.get(API.series.GetSavedGeneratedNumberListBySeriesId + id, true)
      .map(list => list as SavedNumber[]).toPromise();
  }

  UpdateSeries(config: Series): Promise<Series> {
    return new Promise<Series>((resolve, reject) => {
      this._apiService.post(API.series.updateSeries, config, true, true)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }

  SaveAsSavedGeneratedNumberList(list: SavedGeneratedNumber[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._apiService.post(API.series.saveAsSavedGeneratedNumberList, list, true, true)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }

  //#endregion

  //#region Client

  GetClientById(clientId: number): Promise<Client> {
    return new Promise<Client>((resolve, reject) => {
      this._apiService.get(API.client.getClientById + clientId)
        .subscribe((client: Client) => resolve(client),
          err => reject(Error(err)));
    });
  }

  LoadDefaultClient(): Promise<Client> {
    return new Promise<Client>((resolve, reject) => {
      this._apiService.get(API.client.getDefaulClient)
        .subscribe((client: Client) => resolve(client),
          err => reject(Error(err)));
    });
  }

  GetClientIssuerByClientId(clientId: number): Promise<Issuer> {
    return new Promise<Issuer>((resolve, reject) => {
      this._apiService.get(API.client.getClientIssuerByClientId + clientId)
        .subscribe((issuer: Issuer) => resolve(issuer),
          err => reject(Error(err)));
    });
  }

  GetIssuerById(issuerId: number): Promise<Issuer> {
    return new Promise<Issuer>((resolve, reject) => {
      this._apiService.get(API.issuer.getIssuerById + issuerId)
        .subscribe((issuer: Issuer) => resolve(issuer),
          err => reject(Error(err)));
    });
  }

  //#endregion

  //#region MwtColor

  GetMwtColorListByMainWorkTypeId(mainWorkTypeId: number): Promise<MwtColor[]> {
    return new Promise<MwtColor[]>((resolve, reject) => {
      this._apiService.get(API.main_work_type_work.getMwtColorListByMainWorkTypeId + mainWorkTypeId)
        .subscribe((list: MwtColor[]) => resolve(list),
          err => reject(err));
    });
  }

  SaveMwtColor(color: MwtColor): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.post(API.main_work_type_work.saveColor, color, false, true)
        .subscribe(response => resolve(JSON.parse(response['_body'])),
          err => reject(Error(err)));
    });
  }

  GetVerbalLocalizationList() {
    return this._apiService.get(API.localization.getActiveLocationsList).map(x => x as Localizations[]).toPromise();
  }

  GetColorList() { return this._apiService.get(API.color.getActiveColorList).map(x => x as Color[]).toPromise(); }

  GetMetalList() {
    return this._apiService.get(API.metals.getActiveMetalsList).map(x => x as Metals[]).toPromise();
  }

  //#endregion

  //#region Article template

  GetArticleListByMainWorkTypeId(mainWorkTypeId: number): Promise<WorkTypeArticleTemplate[]> {
    return new Promise<WorkTypeArticleTemplate[]>((resolve, reject) => {
      this._apiService.get(API.main_work_type_work.getArticleListByMainWorkTypeId + mainWorkTypeId, true)
        .subscribe((list: WorkTypeArticleTemplate[]) => resolve(list),
          err => reject(err));
    });
  }

  //#endregion

  //#region Document

  AddDocument(document: Document): Promise<Document> {
    return new Promise<Document>((resolve, reject) => {
      this._apiService.post(API.main_work_type_work.saveDocumentDetails, document, true)
        .subscribe((doc: Document) => resolve(doc),
          err => reject(err));
    });
  }

  UploadDocument(formData: FormData): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.upload(API.main_work_type_work.uploadDocument, formData)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }

  //#endregion

  LoadArticleList(): Promise<Article[]> {
    return new Promise<Article[]>((resolve, reject) => {
      this._apiService.get(API.article.getArticles)
        .subscribe((list: Article[]) => resolve(list),
          err => reject(err));
    });
  }

  LoadIssuerList(): Promise<Issuer[]> {
    return new Promise<Issuer[]>((resolve, reject) => {
      this._apiService.get(API.issuer.getIssuerList)
        .subscribe((list: Issuer[]) => resolve(list.length > 0 ? list.filter(x => x.status === true) : []),
          err => reject(err));
    });
  }

  //#region Clinic Material List

  GetClinicMaterialList() {
    return this._apiService.get(API.clinic_material.getActiveClinicMaterialList).map(x => x as ClinicMaterial[]).toPromise();
  }

  //#endregion

  GetProductList(): Promise<Product[]> {
    return new Promise<Product[]>((resolve, reject) => {
      this._apiService.get(API.product.getProductList)
        .subscribe((list: Product[]) => resolve(list),
          err => reject(err));
    });
  }

  GetPhasesTasksArticlesProductsByTemplateId(templateId: number) {
    return this._apiService.get(API.work_type.workTypePhase.getWorkTypePhaseListByWorkTypeId + templateId)
      .map(x => x as WorkTypeTemplate).toPromise();
  }

  GetMainWorkTypeListBySubWorkId(subWorkId: number) {
    return this._apiService.get(API.main_work_type_work.getMainWorkTypeListBySubWorkId + subWorkId)
      .map(x => x as MainWorkType[]).toPromise();
  }

  syncWorkStatusByWorkId(workId: number) {
    return this._apiService.get(API.work.sync_work_status_by_workId + workId).
      map(response => response as { result: string }).toPromise();
  }

  updateGlobalViewTaskDetail(taskDetail: TaskDetail) {
    return this._apiService.post(API.work.update_global_view_task_detail, taskDetail, true).
      map(response => response as { result: string }).toPromise();
  }

  GetWorkByMainwork(mwtNo: string) {
    return this._apiService.get(API.Global.GetWorkByMainworktypeNo + mwtNo, true).toPromise();
  }

  GetWorkAndSubWork(workNum: string, subWorkNum: string) {
    return this._apiService.get(API.work.get_work_and_sub_work + workNum + '/' + subWorkNum, true)
      .toPromise();
  }

  CheckDateAvailability(date: string) {
    return this._apiService.get(API.lab_calender.check_date_availability + date, true)
      .toPromise();
  }

  async GetProductByBarcode(barcode: any) {
    return this._apiService.get(API.product.getProductByBarcode + barcode, true)
      .map((product: Product) => product).toPromise();
  }

  GetPatientById(patientId: number): Promise<Patient> {
    return new Promise<Patient>((resolve, reject) => {
      this._apiService.get(API.patient.getPatientById + patientId, false)
        .subscribe((patient: Patient) => resolve(patient),
          err => reject(Error(err)));
    });
  }

  SearchWorkByGeneratedNo(geneNo: string) {
    return this._apiService.get(API.work.searchWorkByGeneratedNo + geneNo, true).toPromise();
  }

  CheckMWTGenerateNoIsExists(geneNo: string) {
    return this._apiService.get(API.main_work_type_work.checkMWTGenerateNoIsExists + geneNo, true).toPromise();
  }

  CheckDeliveryNotesAndInvoices(workId: string) {
    return this._apiService.get(API.work.checkDeliveryNotesAndInvoices + workId, true).toPromise();
  }
}
