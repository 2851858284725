import {
  ChangeDetectorRef, Component,
  EventEmitter, OnInit,
  Output, ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { LabSectionTopes } from 'src/app/modules/auxiliary/sections/components/entities/lab-section-topes';
import { ModalNames } from 'src/app/modules/common/global';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonFunctions } from 'src/app/modules/common/services/common-functions';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Toast } from 'src/app/modules/common/services/toast.service.js';
import { ShowInvalidFormControls, ValidateNgSelect } from 'src/app/modules/common/services/validators';
import { TaskTopes } from 'src/app/modules/tasks/entities/task-topes';
import {
  WorkTypePhaseTemplate,
  WorkTypeTaskTemplate
} from 'src/app/modules/work-type/entities/work-type-model-templates';
import swal from 'sweetalert2';
import { WorkerTopes } from '../../../workers/entities/worker-topes';
import { DailySectionsValue } from '../../entities/daily-sections-value';
import { DailyTaskValue } from '../../entities/daily-task-value';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtPhase } from '../../entities/main-work-type-phase';
import { MtwPhaseTask } from '../../entities/main-work-type-phase-task';
import { Phases } from '../../entities/phases';
import { SubWork } from '../../entities/sub-work';
import { Task } from '../../entities/task';
import { Work } from '../../entities/work';
import { Worker } from '../../entities/worker';
import { LabHolidaysService } from '../../services/lab-holidays.service';
import { MwtTaskService } from '../../services/mwt-task.service';
import { MwtWorkerService } from '../../services/mwt-worker.service';
import { PhaseTemplateService } from '../../services/phase-template.service';
import { WorkStatusService } from '../../services/work-status.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';
import { SectionTopesEditModalComponent } from './section-topes-edit-modal/section-topes-edit-modal.component';
import { TaskTopesEditModalComponent } from './task-topes-edit-modal/task-topes-edit-modal.component';

@Component({
  selector: 'app-task-modal',
  templateUrl: './task-modal.component.html'
})
export class TaskModalComponent implements OnInit {
  showContent = false;
  hideContent = true;
  // form groups
  addTaskFormGroup: FormGroup;
  workerTopesFormGroup: FormGroup;

  // list
  taskStatusList: string[] = [];
  taskAutoCompleteList = [];
  workerAutoCompleteList = [];
  selectedMainWorkTypeList: MainWorkType[] = [];
  phaseTemplateList: WorkTypePhaseTemplate[] = [];
  selectedSubWork: SubWork;
  mainWorkTypeList: MainWorkType[];
  taskList: Task[] = [];
  taskActiveList: Task[] = [];
  workerList: Worker[] = [];
  phaseList: Phases[] = [];
  phaseStatusList: string[] = [];
  // selected work
  selectedWork: Work;
  dailyMaxlimit = 0;
  allPhaseTemplateList: WorkTypePhaseTemplate[] = [];

  // object
  mwtPhaseTask: MtwPhaseTask;
  selectedMainWorkType: MainWorkType;
  taskTopes: TaskTopes;
  dailyTaskValue: DailyTaskValue;
  isCount = false;
  showWorkerTopesModal = false;
  dailyWorkerTope: WorkerTopes;
  labSectionTopes: LabSectionTopes;
  selectedSectionId = 0;
  showTaskTopesModal = false;
  showSectionTopesModal = false;
  isEdit = false;
  dailySectionsValue: DailySectionsValue;
  isTaskEdit = false;
  canEditWorkLoad = false;
  canEditWorker = false;

  // events
  @Output() updateView = new EventEmitter();
  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  // child
  @ViewChild('tasktatusElement') tasktatusElement: any;
  @ViewChild(TaskTopesEditModalComponent) taskTopesEditModalComponent: TaskTopesEditModalComponent;
  @ViewChild(SectionTopesEditModalComponent) sectionTopesEditModalComponent: SectionTopesEditModalComponent;

  constructor(
    private _workService: WorkService,
    private _apiService: ApiService,
    private _phaseTemplateService: PhaseTemplateService,
    public commonUiService: CommonUIService,
    public _commonDataService: CommonDataService,
    private _formBuilder: FormBuilder,
    private _toastyService: Toast,
    private _modalService: ModalService,
    private cdref: ChangeDetectorRef,
    private _mwtWorkerService: MwtWorkerService,
    private _taskService: MwtTaskService,
    public dropdownService: DropdownService, private _ngxPermissionService: NgxPermissionsService,
    private _statusService: WorkStatusService, private _labHolidaysService: LabHolidaysService
  ) {
    this.mwtPhaseTask = new MtwPhaseTask();
    this.initform();
    this.showContent = true;
    this.hideContent = false;
    this.taskTopes = new TaskTopes();
    this.dailyTaskValue = new DailyTaskValue();
    this.labSectionTopes = new LabSectionTopes();
    this.dailySectionsValue = new DailySectionsValue();
  }

  ngOnInit() {
    this.mwtPhaseTask = new MtwPhaseTask();
    this.initform();
    this.modalListner();

    const permissionWorkLoad = this._ngxPermissionService.getPermission('EditWorkloads');
    this.canEditWorkLoad = permissionWorkLoad !== undefined;

    const permissionWorker = this._ngxPermissionService.getPermission('ViewWorkers');
    this.canEditWorker = permissionWorker !== undefined;
  }

  private LoadPopupData() {
    if (this.taskList != null && this.taskList.length > 0) {
      if (!this.isTaskEdit) {
        this.taskActiveList = this.taskList.filter(x => x.status);
      } else {
        this.taskActiveList = this.taskList;
      }
      this.taskAutoCompleteList = this._commonDataService.GetAutoCompleteList(
        this.taskActiveList,
        'name'
      );
    }
    if (this.workerList != null && this.workerList.length > 0) {
      const workerActiveList = this.workerList.filter(x => x.status);
      if (workerActiveList) {
        this.workerAutoCompleteList = this._commonDataService.GetAutoCompleteList(
          workerActiveList,
          'name'
        );
      }
    }
    this.cdref.markForCheck();
  }

  private GetPhaseList() {
    return this._apiService
      .get(API.phases.getPhasesList)
      .map(list => list as Phases[])
      .toPromise();
  }

  initform() {
    this.addTaskFormGroup = this._formBuilder.group({
      mainWorkTypeId: ['', [ValidateNgSelect]],
      taskId: [],
      taskName: ['', [Validators.required]],
      deliveryDate: [],
      taskStatus: ['', [ValidateNgSelect]],
      phaseId: ['', [ValidateNgSelect]],
      endTime: ['', [Validators.minLength(4)]],
      workerId: [],
      workerName: [''],
      taskValue: []
    });
    this.workerTopesFormGroup = this._formBuilder.group({
      totalDailyTopes: [],
      totalDailyTasks: [],
      topeOfTheDay: [0, Validators.required],
      date: []
    });
  }

  modalListner() {
    if (this._modalService.modal === ModalNames.TaskModal) {
      if (this._modalService.transferable && !this._modalService.transferable.isEdit) {
        this.readTransferedInits(this._modalService.transferable);
        this.OnOpenAddTaskModal();
      } else if (this._modalService.transferable && this._modalService.transferable.isEdit) {
        this.readTransferedInits(this._modalService.transferable);
        const phaseTemplate = <WorkTypePhaseTemplate>this._modalService.transferable.phaseTemplate;
        const task = this._modalService.transferable.task;
        this.OnEditPhaseTask(phaseTemplate, task);
      }
    }
    this.cdref.markForCheck();
  }

  readTransferedInits(transferable: any) {
    this.selectedMainWorkTypeList = transferable.selectedMainWorkTypeList;
    this.selectedMainWorkType = transferable.selectedMainWorkType;
    this.selectedWork = transferable.selectedWork;
    this.taskList = transferable.taskList;
    this.workerList = transferable.workerList;
    this.allPhaseTemplateList = transferable.phaseTemplateList;
    if (this.selectedMainWorkTypeList.length === 1) {
      this.phaseTemplateList = this.allPhaseTemplateList.filter(x => x.workTypeId === this.selectedMainWorkTypeList[0].id);
      if (this.phaseTemplateList.length === 0) {
        this._toastyService.warning({
          title: this._commonDataService.localizationLabelList['phase_task'],
          msg: this._commonDataService.localizationLabelList['not_available_phases']
        });
      }
    } else {
      this.phaseTemplateList = this.allPhaseTemplateList;
    }
    this.isTaskEdit = transferable.isEdit;
    this.LoadPopupData();
    this.cdref.markForCheck();
  }

  async LoadMwtPhaseTaskPopupData() {
    this.taskStatusList = this._workService.taskStatusList;
    this.phaseList = await this.GetPhaseList();
    this.cdref.markForCheck();
  }

  private ValidateTaskDates(
    endDate: string,
    phaseTemplate: WorkTypePhaseTemplate
  ): any {
    if (endDate && phaseTemplate) {
      if (endDate.includes('Z')) {
        endDate = endDate.split('Z')[0];
      }

      const start = new Date(phaseTemplate.startDate);
      const end = new Date(phaseTemplate.endDate);
      const endDateX = new Date(endDate);

      if (start <= endDateX && end >= endDateX) {
        return { isValid: true, message: '' };
      } else {
        return { isValid: false, message: 'Finish date is not valid' };
      }
    }
  }

  OnChangeMainWorkType(mainWorkTypeId: number) {
    this.phaseTemplateList = this.allPhaseTemplateList.filter(x => x.workTypeId === Number(mainWorkTypeId));
    if (this.phaseTemplateList.length === 0) {
      this._toastyService.warning({
        title: this._commonDataService.localizationLabelList['phase_task'],
        msg: this._commonDataService.localizationLabelList['not_available_phases']
      });
    }
  }

  async OnChangeTask(taskItem: { display: string; value: number; id: number }) {
    if (taskItem) {
      this.mwtPhaseTask.taskId = taskItem.id;
      this.mwtPhaseTask.taskName = taskItem.display;
      if (Number(this.mwtPhaseTask.taskId) > 0 && this.mwtPhaseTask.deliveryDate != null) {
        this.dailyTaskValue = new DailyTaskValue();
        const taskValue = await this._taskService.GetMwtTaskTotalValueAndDialyLimit(this.mwtPhaseTask.taskId,
          this.mwtPhaseTask.deliveryDate);
        this.dailyTaskValue = taskValue ? taskValue : null;
        if (this.dailyTaskValue != null && this.dailyTaskValue.taskTope != null) {
          this.taskTopes = this.dailyTaskValue.taskTope;
        } else {
          this.taskTopes = null;
        }
      }
      this.isCount = false;
    }
    this.cdref.markForCheck();
  }

  async OnChangeWorker(workerItem?: {
    display: string;
    value: number;
    id: number;
  }) {
    if (workerItem) {
      this.mwtPhaseTask.workerId = workerItem.id;
      this.mwtPhaseTask.workerName = workerItem.display;
    } else {
      this.mwtPhaseTask.workerId = null;
      this.mwtPhaseTask.workerName = '';
    }
    this.cdref.markForCheck();
  }

  private SaveTask(mwtPhaseTask: MtwPhaseTask): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService
        .post(API.main_work_type_work.saveTask, mwtPhaseTask, false, true)
        .subscribe(
          response => resolve(response),
          err => reject(err)
        );
    });
  }

  async OnSaveMwtPhaseTask(event: any) {
    try {
      if (event) {
        event.target.disabled = true;
        if (this.selectedMainWorkTypeList.length === 1) {
          this.addTaskFormGroup.controls['mainWorkTypeId'].clearValidators();
          this.addTaskFormGroup.controls['mainWorkTypeId'].setErrors(null);
          this.addTaskFormGroup.controls['mainWorkTypeId'].setValidators(null);
        } else {
          this.addTaskFormGroup.controls['mainWorkTypeId'].setValidators(
            ValidateNgSelect
          );
        }
        this.addTaskFormGroup.updateValueAndValidity();
        if (this.addTaskFormGroup.invalid) {
          ShowInvalidFormControls(this.addTaskFormGroup);
          return;
        }
        if (
          this.mwtPhaseTask.id === null ||
          this.mwtPhaseTask.id === undefined
        ) {
          if (this.selectedMainWorkTypeList.length === 1) {
            this.mwtPhaseTask.mainWorkTypeId = this.selectedMainWorkTypeList[0].id;
          } else {
            this.selectedMainWorkType.id = this.mwtPhaseTask.mainWorkTypeId;
          }
        }
        if (this.mwtPhaseTask.deliveryDateObject) {
          this.mwtPhaseTask.deliveryDate = this.mwtPhaseTask.deliveryDateObject
            ? this.commonUiService.ChangeNgbDateToCustomFormat(
              this.mwtPhaseTask.deliveryDateObject
            )
            : '';
        } else {
          this.mwtPhaseTask.deliveryDate = null;
        }
        if (this.mwtPhaseTask.endTime) {
          if (!this.mwtPhaseTask.endTime.toString().includes(':')) {
            this.mwtPhaseTask.endTime = CommonFunctions.Get24HoursTimeFormat(
              this.mwtPhaseTask.endTime
            );
          }
        }
        if (!Number(this.mwtPhaseTask.workerId)) {
          this.mwtPhaseTask.workerId = null;
        }

        if (this.mwtPhaseTask.deliveryDate != null) {
          // check exceeding the number of tasks
          let totalTaskVlaue = 0;
          this.mwtPhaseTask.taskValue = this.mwtPhaseTask.taskValue ? Number(this.mwtPhaseTask.taskValue) : 0;
          if (this.dailyTaskValue && this.dailyTaskValue.taskTope != null) {
            this.dailyTaskValue.totalTaskVlaue = this.dailyTaskValue.totalTaskVlaue ? Number(this.dailyTaskValue.totalTaskVlaue) : 0;
            totalTaskVlaue = (Number(this.dailyTaskValue.totalTaskVlaue) + Number(this.mwtPhaseTask.taskValue));
          }
          if (this.taskTopes != null && this.taskTopes.id > 0 && Number(this.taskTopes.tope) > 0) {
            this.taskTopes.tope = this.taskTopes.tope ? Number(this.taskTopes.tope) : 0;
            if (Number(totalTaskVlaue) > Number(this.taskTopes.tope)) {
              this.showTaskTopesModal = true;
              return swal({
                text: this._commonDataService.localizationLabelList['task_value_exceeded_msg'],
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this._commonDataService.localizationLabelList['common_accept'],
                allowOutsideClick: false,
              }).then(async (result) => {
                if (result.value) {
                  this.taskTopesEditModalComponent.OpenModal();
                  return;
                } else {
                  this.OnCloseModal();
                  return;
                }
              }).catch(err => console.log(err));
            }
          }
          // check exceeding the number of section values
          let totalSectionVlaue = 0;
          if (this.dailySectionsValue && this.dailySectionsValue.labSectionTope != null) {
            this.dailySectionsValue.totalTaskVlaue = this.dailySectionsValue.totalTaskVlaue ?
              Number(this.dailySectionsValue.totalTaskVlaue) : 0;
            totalSectionVlaue = (Number(this.dailySectionsValue.totalTaskVlaue) + Number(this.mwtPhaseTask.taskValue));
          }
          if (this.labSectionTopes != null && this.labSectionTopes.id > 0 && Number(this.labSectionTopes.tope) > 0) {
            this.labSectionTopes.tope = this.labSectionTopes.tope ? Number(this.labSectionTopes.tope) : 0;
            if (Number(totalSectionVlaue) > Number(this.labSectionTopes.tope)) {
              this.showSectionTopesModal = true;
              return swal({
                text: this._commonDataService.localizationLabelList['lab_task_value_exceeded_msg'],
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this._commonDataService.localizationLabelList['common_accept'],
                allowOutsideClick: false,
              }).then(async (result) => {
                if (result.value) {
                  this.sectionTopesEditModalComponent.OpenModal(this.labSectionTopes.dailyMaxLimit);
                  return;
                } else {
                  this.OnCloseModal();
                  return;
                }
              }).catch(err => console.log(err));
            }
          }
        }
        await this.OnSaveTask();
      }
    } catch (error) {
      console.log(error);
    } finally {
      event.target.disabled = false;
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnSaveTask() {
    try {
      // this.commonUiService.isSpinnerVisible = true;
      const phaseTemplate = this.phaseTemplateList.find(
        x => Number(x.mwtPhaseId) === Number(this.mwtPhaseTask.mwtphaseId)
      );

      const mainWorkType = this.selectedMainWorkTypeList.find(
        x => Number(x.id) === Number(this.mwtPhaseTask.mainWorkTypeId)
      );

      const validationArr = await this.ValidateWorkDatesA(
        mainWorkType.deliveryDate
          ? new Date(mainWorkType.deliveryDate)
          : null,
        phaseTemplate.endDate ? new Date(phaseTemplate.endDate) : null,
        this.mwtPhaseTask.deliveryDate ? new Date(this.mwtPhaseTask.deliveryDate) : null
      );

      if (validationArr.length > 0) {
        const wResult = validationArr.find(v => v.type === 'w');
        const pResult = validationArr.find(v => v.type === 'p');
        if (wResult) {
          mainWorkType.deliveryDate = wResult.value;
          this._workService.UpdateMainWorkType(mainWorkType);
        }
        if (pResult) {
          const mwtPhase = new MwtPhase({
            mainWorkTypeId: phaseTemplate.workTypeId
              ? phaseTemplate.workTypeId
              : null,
            id: phaseTemplate.mwtPhaseId,
            mwtArticleId: phaseTemplate.mwtArticleId
              ? phaseTemplate.mwtArticleId
              : null,
            phaseOderNo: phaseTemplate.phaseOrderNo,
            phaseStatus: phaseTemplate.phaseStatus
              ? phaseTemplate.phaseStatus
              : 'Pendiente',
            sectionId: phaseTemplate.labSectionId
              ? phaseTemplate.labSectionId
              : null,
            phaseId: phaseTemplate.phaseId,
            startDate: phaseTemplate.startDate,
            endDate: phaseTemplate.endDate
          });

          mwtPhase.endDate = pResult.value;
          const phaseResponse = await this._phaseTemplateService.SaveMwtPhase(
            mwtPhase
          );
          if (phaseResponse) {
            const body = JSON.parse(phaseResponse['_body']);
            if (body['_isSuccsess']) {
              this.viewUpdate();
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList[
                  'task'
                ],
                msg: this._commonDataService.localizationLabelList[
                  'phase_assigned_to_task_successfully'
                ]
              });
            } else {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList[
                  'task'
                ],
                msg: this._commonDataService.localizationLabelList[
                  'save_error'
                ]
              });
            }
          }
        }
      }

      if (this.mwtPhaseTask.workerId) {
        if (this.mwtPhaseTask.deliveryDate) {
          const availabilityResponse: {
            dateList: string[],
            isAllow: boolean
          } = await this._mwtWorkerService
            .CheckWorkerAvailabilityForDeliveryDate(this.mwtPhaseTask.workerId, this.mwtPhaseTask.taskId, this.mwtPhaseTask.deliveryDate);
          if (availabilityResponse) {
            let availabilitySwalResult = null;
            if (availabilityResponse.isAllow) {
              availabilityResponse.dateList = availabilityResponse.dateList ? availabilityResponse.dateList : [];
              if (availabilityResponse.dateList.length > 0) {
                let dateStr = '';
                for (let index = 0; index < availabilityResponse.dateList.length; index++) {
                  const date = availabilityResponse.dateList[index];
                  dateStr += (date.substring(0, 10) + ', ');
                }
                availabilitySwalResult = await swal({
                  text: this._commonDataService
                    .localizationLabelList['allowed_free_days'] + '. ( ' + dateStr.substring(0, dateStr.length - 2) + ' ) ',
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: this._commonDataService.localizationLabelList['common_accept'],
                  allowOutsideClick: false,
                });
              }
            } else {
              availabilitySwalResult = await swal({
                text: this._commonDataService
                  .localizationLabelList['not_allowed_holidays'],
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this._commonDataService.localizationLabelList['common_accept'],
                allowOutsideClick: false,
              });
              if (availabilitySwalResult) {
                if (availabilitySwalResult.value) {
                  if (!this.mwtPhaseTask.workerId) {
                    this.mwtPhaseTask.workerId = 0;
                    this.mwtPhaseTask.workerName = '';
                  } else {
                    const wkr = this.workerAutoCompleteList.find(w => w.id === this.mwtPhaseTask.workerId);
                    if (wkr) {
                      this.mwtPhaseTask.workerId = wkr.id;
                      this.mwtPhaseTask.workerName = wkr.name;
                    }
                  }
                  return;
                }
              }
            }
          }
          if (this.mwtPhaseTask.taskValue) {
            const taskValueResponse = await this._phaseTemplateService.CheckDailyTaskHoursLimitForWorker(this.mwtPhaseTask.workerId,
              this.mwtPhaseTask.deliveryDate, this.mwtPhaseTask.taskValue, this.mwtPhaseTask.id);
            if (taskValueResponse.tope != null && taskValueResponse.tope.id > 0 && Number(taskValueResponse.tope.tope) > 0) {
              if (taskValueResponse.result === 'invalid') {
                const workerX = this.workerAutoCompleteList.find(w => w.id === this.mwtPhaseTask.workerId);
                const swalResult = await swal({
                  text: this._commonDataService
                    .localizationLabelList['worker_task_value_exceeded_msg_1'] +
                    (workerX ? workerX.display : (this.mwtPhaseTask.workerName ? this.mwtPhaseTask.workerName : '')) +
                    this._commonDataService
                      .localizationLabelList['worker_task_value_exceeded_msg_2'],
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: this._commonDataService.localizationLabelList['common_accept'],
                  allowOutsideClick: false,
                });
                if (swalResult) {
                  if (swalResult.value) {
                    if (taskValueResponse.tope) {
                      this.dailyWorkerTope = taskValueResponse.tope;
                      this.workerTopesFormGroup.setValue({
                        totalDailyTopes: taskValueResponse.tope.dailyTotal + Number(this.mwtPhaseTask.taskValue),
                        totalDailyTasks: taskValueResponse.tope.dailyMaxlimit,
                        topeOfTheDay: taskValueResponse.tope.tope,
                        date: this.mwtPhaseTask.deliveryDate
                      });
                    }
                    this.showWorkerTopesModal = true;
                    this.hideContent = true;
                    return;
                  } else {
                    await this.OnCancelWorkerTopes();
                    return;
                  }
                }
              } else if (taskValueResponse.result === 'error' || taskValueResponse.result === 'not_defined') {
                console.error('error has occured while checking'); return;
              }
            }
          }
        } else {
          const deliveryDateSwalResult = await swal({
            text: this._commonDataService.localizationLabelList['no_delivery_date'],
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this._commonDataService.localizationLabelList['common_accept'],
            allowOutsideClick: false,
          });
          if (deliveryDateSwalResult) {
            if (deliveryDateSwalResult.value) {
              if (!this.mwtPhaseTask.workerId) {
                this.mwtPhaseTask.workerId = 0;
                this.mwtPhaseTask.workerName = '';
              } else {
                const wkr = this.workerAutoCompleteList.find(w => w.id === this.mwtPhaseTask.workerId);
                if (wkr) {
                  this.mwtPhaseTask.workerId = wkr.id;
                  this.mwtPhaseTask.workerName = wkr.name;
                }
              }
              return;
            }
          }
        }
      }
      // save
      // this.commonUiService.isSpinnerVisible = true;
      await this.SaveTaskAsync();
    } catch (error) {
      console.log(error);
    } finally {
      this.cdref.markForCheck();
      // this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnEditPhaseTask(
    phase: WorkTypePhaseTemplate,
    task: WorkTypeTaskTemplate
  ) {
    if (phase && task) {
      this.commonUiService.isSpinnerVisible = true;
      this.addTaskFormGroup.markAsPristine();
      this.addTaskFormGroup.markAsUntouched();
      this.mwtPhaseTask = new MtwPhaseTask();
      this.mwtPhaseTask.id = task.mwtPhaseTaskId;
      this.mwtPhaseTask.mwtphaseId = phase.mwtPhaseId;
      this.mwtPhaseTask.taskId = task.taskId;
      this.mwtPhaseTask.workerId = task.workerId;
      this.mwtPhaseTask.taskValue = task.taskValue;
      this.addTaskFormGroup.controls['taskName'].reset();
      this.addTaskFormGroup.controls['taskName'].setValue(task.taskName);
      this.addTaskFormGroup.controls['workerName'].reset();
      this.addTaskFormGroup.controls['workerName'].setValue(task.workerName);
      this.addTaskFormGroup.controls['taskValue'].reset();
      this.addTaskFormGroup.controls['taskValue'].setValue(task.taskValue);
      await this.LoadMwtPhaseTaskPopupData();
      this.mwtPhaseTask.mainWorkTypeId = phase.workTypeId;
      this.mwtPhaseTask.taskStatus = task.taskStatus;
      this.mwtPhaseTask.deliveryDateObject = this.commonUiService.ConvertToPickerDateFormat(
        task.deliveryDate
      );
      this.mwtPhaseTask.endTime = task.endTime;
      this.commonUiService.isSpinnerVisible = false;
      this.showContent = true;
      this.hideContent = false;
      this.tasktatusElement.nativeElement.click();
      this.isEdit = true;
      this.OnChangeMainWorkType(phase.workTypeId);
      await this.OnChangeDeliveryDate();
      this.cdref.markForCheck();
    }
  }

  async OnOpenAddTaskModal() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      this.commonUiService.isPopupOpened = true;
      this.isEdit = false;
      this.addTaskFormGroup.reset();
      await this.LoadMwtPhaseTaskPopupData();
      this.mwtPhaseTask = new MtwPhaseTask();
      this.mwtPhaseTask.mwtphaseId = 0;
      this.mwtPhaseTask.mainWorkTypeId = 0;
      this.addTaskFormGroup.controls.taskName.setValue('');
      this.mwtPhaseTask.taskId = '0';
      this.addTaskFormGroup.controls.workerName.setValue('');
      this.mwtPhaseTask.workerId = '0';
      this.mwtPhaseTask.taskStatus = 'Pendiente';
      this.mwtPhaseTask.taskValue = 1;
      this.addTaskFormGroup.controls.taskValue.setValue(1);
      this.commonUiService.isSpinnerVisible = false;
      this.showContent = true;
      this.hideContent = false;
      this.cdref.markForCheck();
    } catch (e) {
      console.log(e);
    } finally {
      this.cdref.markForCheck();
    }
  }

  viewUpdate() {
    this.updateView.emit();
  }

  OnCloseModal() { this.showContent = false; this.hideContent = true; this.closeModel.emit(); }

  async ValidateWorkDatesA(
    mwt: Date,
    phase: Date,
    task: Date
  ): Promise<{ type: 'p' | 'w'; value: string }[]> {
    let arr = [];
    let message = '';
    if (task === null) {
      return arr;
    }
    if (phase && task && mwt) {
      if (task > phase) {
        message = this._commonDataService.localizationLabelList[
          'greater_than_phase_date'
        ];
        if (task > mwt) {
          message =
            message +
            this._commonDataService.localizationLabelList[
            'greater_than_phase_date_and_type_of_work'
            ];
        }
      } else if (phase.getTime() === task.getTime()) {
        if (task > mwt) {
          message =
            this._commonDataService.localizationLabelList[
            'greater_than_type_of_work_date'
            ];
        } else {
          return arr;
        }
      } else { return arr; }

      const result1 = await swal({
        title: this._commonDataService.localizationLabelList['tasks'],
        text: message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList[
          'common_yes'
        ],
        cancelButtonText: this._commonDataService.localizationLabelList[
          'common_cancel'
        ]
      });

      if (result1) {
        if (result1.value) {
          if (task > phase) {
            phase = task;
            arr.push({
              type: 'p',
              value: moment(phase).format('YYYY-MM-DD')
            });
            if (task > mwt) {
              mwt = task;
              arr.push({
                type: 'w',
                value: moment(mwt).format('YYYY-MM-DD')
              });
            }
          } else if (phase.getTime() === task.getTime()) {
            if (task > mwt) {
              mwt = task;
              arr.push({
                type: 'w',
                value: moment(mwt).format('YYYY-MM-DD')
              });
            }
          }
        }
        return arr;
      }
    } else {
      if (phase === null && mwt) {
        if (task > mwt) {
          const result2 = await swal({
            title: this._commonDataService.localizationLabelList['tasks'],
            text: this._commonDataService.localizationLabelList[
              'greater_than_type_of_work_date'
            ],
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this._commonDataService.localizationLabelList[
              'common_yes'
            ],
            cancelButtonText: this._commonDataService.localizationLabelList[
              'common_cancel'
            ]
          });
          if (result2.value) {
            mwt = task;
            arr.push({
              type: 'w',
              value: moment(mwt).format('YYYY-MM-DD')
            });

            const result3 = await swal({
              title: this._commonDataService.localizationLabelList['tasks'],
              text: this._commonDataService.localizationLabelList[
                'phase_delivery_date_empty'
              ],
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this._commonDataService.localizationLabelList[
                'common_yes'
              ],
              cancelButtonText: this._commonDataService.localizationLabelList[
                'common_cancel'
              ]
            });

            if (result3.value) {
              phase = task;
              arr.push({
                type: 'p',
                value: moment(phase).format('YYYY-MM-DD')
              });
            }
          } else {
            const result3 = await swal({
              title: this._commonDataService.localizationLabelList['tasks'],
              text: this._commonDataService.localizationLabelList[
                'phase_delivery_date_empty'
              ],
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this._commonDataService.localizationLabelList[
                'common_yes'
              ],
              cancelButtonText: this._commonDataService.localizationLabelList[
                'common_cancel'
              ]
            });

            if (result3.value) {
              phase = task;
              arr.push({
                type: 'p',
                value: moment(phase).format('YYYY-MM-DD')
              });
            }
          }
        } else {
          const result4 = await swal({
            title: this._commonDataService.localizationLabelList['tasks'],
            text: this._commonDataService.localizationLabelList[
              'phase_delivery_date_empty'
            ],
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this._commonDataService.localizationLabelList[
              'common_yes'
            ],
            cancelButtonText: this._commonDataService.localizationLabelList[
              'common_cancel'
            ]
          });

          if (result4.value) {
            phase = task;
            arr.push({
              type: 'p',
              value: moment(phase).format('YYYY-MM-DD')
            });
          }
        }
      } else if (phase === null && mwt === null) {
        const result5 = await swal({
          title: this._commonDataService.localizationLabelList['tasks'],
          text: this._commonDataService.localizationLabelList[
            'phase_and_workType_delivery_dates_empty'
          ],
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this._commonDataService.localizationLabelList[
            'common_yes'
          ],
          cancelButtonText: this._commonDataService.localizationLabelList[
            'common_cancel'
          ]
        });
        if (result5.value) {
          mwt = task;
          phase = task;
          arr = [
            {
              type: 'w',
              value: moment(mwt).format('YYYY-MM-DD')
            },
            {
              type: 'p',
              value: moment(phase).format('YYYY-MM-DD')
            }
          ];
        }
      } else if (phase && mwt === null) {
        if (task > phase) {
          const result6 = await swal({
            title: this._commonDataService.localizationLabelList['tasks'],
            text: this._commonDataService.localizationLabelList[
              'greater_than_phase_date'
            ],
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this._commonDataService.localizationLabelList[
              'common_yes'
            ],
            cancelButtonText: this._commonDataService.localizationLabelList[
              'common_cancel'
            ]
          });
          if (result6.value) {
            phase = task;
            arr.push({
              type: 'p',
              value: moment(phase).format('YYYY-MM-DD')
            });
            const result7 = await swal({
              title: this._commonDataService.localizationLabelList['tasks'],
              text: this._commonDataService.localizationLabelList[
                'type_of_work_date_empty'
              ],
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this._commonDataService.localizationLabelList[
                'common_yes'
              ],
              cancelButtonText: this._commonDataService.localizationLabelList[
                'common_cancel'
              ]
            });

            if (result7.value) {
              mwt = task;
              arr.push({
                type: 'w',
                value: moment(mwt).format('YYYY-MM-DD')
              });
            }
          }
        } else {
          const result8 = await swal({
            title: this._commonDataService.localizationLabelList['tasks'],
            text: this._commonDataService.localizationLabelList[
              'type_of_work_date_empty'
            ],
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this._commonDataService.localizationLabelList[
              'common_yes'
            ],
            cancelButtonText: this._commonDataService.localizationLabelList[
              'common_cancel'
            ]
          });

          if (result8.value) {
            mwt = task;
            arr.push({
              type: 'w',
              value: moment(mwt).format('YYYY-MM-DD')
            });
          }
        }
      }
      return arr;
    }
  }

  async SaveTaskAsync() {
    this.commonUiService.isSpinnerVisible = true;
    this.mwtPhaseTask.taskOrderNo = -1;
    const response = await this.SaveTask(this.mwtPhaseTask);
    if (response) {
      const body = JSON.parse(response['_body']);
      if (body['_isSuccsess']) {
        this.OnCloseModal();
        this.viewUpdate();
        this._statusService.SetChangedStatus(true);
        this._toastyService.success({
          title: this._commonDataService.localizationLabelList[
            'task'
          ],
          msg: this._commonDataService.localizationLabelList[
            'phase_save_successfully'
          ]
        });
        this._workService.SetDetailObject({
          selectedMainWorkTypeList: this.selectedMainWorkTypeList,
          selectedMainWorkType: this.selectedMainWorkType,
          selectedSubWork: this.selectedSubWork,
          selectedWork: this.selectedWork,
          mainWorkTypeList: this.mainWorkTypeList,
          invokeComponents: ['Phase']
        });
      } else {
        this._toastyService.error({
          title: this._commonDataService.localizationLabelList[
            'phase_task'
          ],
          msg: this._commonDataService.localizationLabelList['save_error']
        });
      }
    }
    this.commonUiService.isSpinnerVisible = false;
  }

  //#region lab section

  async OnChangePhase(phaseId: number) {
    if (phaseId > 0) {
      const phase = this.phaseTemplateList.find(x => x.mwtPhaseId === Number(phaseId));
      if (phase) {
        this.selectedSectionId = Number(phase.labSectionId) > 0 ? Number(phase.labSectionId) : 0;
        if (Number(this.selectedSectionId) > 0 && this.mwtPhaseTask.deliveryDate != null) {
          this.dailySectionsValue = new DailySectionsValue();
          const sectionValue = await this._taskService.GetLabSectionTotalTaskValueAndDialyLimit(phase.labSectionId,
            this.mwtPhaseTask.deliveryDate);
          this.dailySectionsValue = sectionValue ? sectionValue : null;
          if (this.dailySectionsValue != null) {
            if (this.dailySectionsValue.labSectionTope != null) {
              this.labSectionTopes = this.dailySectionsValue.labSectionTope;
            } else {
              this.labSectionTopes = null;
            }
          }
        }
      }
    }
    this.cdref.markForCheck();
  }

  async OnChangeDeliveryDate() {
    if (this.mwtPhaseTask.deliveryDateObject) {
      this.mwtPhaseTask.deliveryDate = this.mwtPhaseTask.deliveryDateObject ? this.commonUiService.ChangeNgbDateToCustomFormat(
        this.mwtPhaseTask.deliveryDateObject) : '';
    } else {
      this.mwtPhaseTask.deliveryDate = null;
    }

    const deliveryDateObj = this.mwtPhaseTask.deliveryDate;
    if (deliveryDateObj) {
      const deliveryDate = this.commonUiService.ChangeNgbDateToCustomFormat(deliveryDateObj);
      const isAvailable = await this._labHolidaysService.checkDate(deliveryDate);
      if (!isAvailable) {
        this.mwtPhaseTask.deliveryDateObject = null;
      }
    }

    if (Number(this.mwtPhaseTask.taskId) > 0 && this.mwtPhaseTask.deliveryDate != null) {
      const taskValue = await this._taskService.GetMwtTaskTotalValueAndDialyLimit(this.mwtPhaseTask.taskId,
        this.mwtPhaseTask.deliveryDate);
      this.dailyTaskValue = new DailyTaskValue();
      this.dailyTaskValue = taskValue ? taskValue : null;
      if (this.dailyTaskValue != null) {
        if (this.dailyTaskValue.taskTope != null) {
          this.taskTopes = this.dailyTaskValue.taskTope;
        } else {
          this.taskTopes = null;
        }
        if (this.isEdit) {
          this.dailyTaskValue.totalTaskVlaue -= Number(this.mwtPhaseTask.taskValue) ? Number(this.mwtPhaseTask.taskValue) : 0;
        }
      }
    }
    this.isCount = false;
    if (Number(this.mwtPhaseTask.mwtphaseId) > 0) {
      const phase = this.phaseTemplateList.find(x => x.mwtPhaseId === Number(this.mwtPhaseTask.mwtphaseId));
      if (phase) {
        this.selectedSectionId = Number(phase.labSectionId) > 0 ? Number(phase.labSectionId) : 0;
        if (Number(this.selectedSectionId) > 0 && this.mwtPhaseTask.deliveryDate != null) {
          this.dailySectionsValue = new DailySectionsValue();
          const sectionValue = await this._taskService.GetLabSectionTotalTaskValueAndDialyLimit(phase.labSectionId,
            this.mwtPhaseTask.deliveryDate);
          this.dailySectionsValue = sectionValue ? sectionValue : null;
          if (this.dailySectionsValue != null) {
            if (this.dailySectionsValue.labSectionTope != null) {
              this.labSectionTopes = this.dailySectionsValue.labSectionTope;
            } else {
              this.labSectionTopes = null;
            }
            if (this.isEdit) {
              this.dailySectionsValue.totalTaskVlaue -= Number(this.mwtPhaseTask.taskValue) ? Number(this.mwtPhaseTask.taskValue) : 0;
            }
          }
        }
      }
    }
    if (this.isEdit) {
      this.isEdit = false;
    }
    this.cdref.markForCheck();
  }

  GetTaskTopesResponse(taskTopes: TaskTopes) {
    this.taskTopes = taskTopes;
    this.showTaskTopesModal = false;
  }

  GetLabSectionTopesResponse(labSectionTopes: LabSectionTopes) {
    this.labSectionTopes = labSectionTopes;
    this.showSectionTopesModal = false;
  }

  //#endregion

  //#region Worker Topes

  async OnSaveWorkerTopes() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (this.workerTopesFormGroup.invalid) { ShowInvalidFormControls(this.workerTopesFormGroup); } else {
        const form = this.workerTopesFormGroup.value as {
          totalDailyTopes: number,
          totalDailyTasks: number,
          topeOfTheDay: number,
          date: string
        };
        if (form.topeOfTheDay < form.totalDailyTopes) {
          this._toastyService.error({
            title: this._commonDataService.localizationLabelList['worker_topes'],
            msg: this._commonDataService.localizationLabelList['tope_of_the_day_must_be_greater_than_or_equal_to_total_daily_topes']
          });
        } else {
          if (this.dailyWorkerTope) {
            this.dailyWorkerTope.tope = form.topeOfTheDay;
            this.dailyWorkerTope.dailyMaxlimit = form.topeOfTheDay;
            const response = await this._phaseTemplateService
              .UpdateWorkerTopes(this.dailyWorkerTope.id, this.dailyWorkerTope);
            if (response) {
              const body = JSON.parse(response['_body']);
              if (body._statusCode === 200) {
                await this.SaveTaskAsync();
              } else {
                await this.OnCancelWorkerTopes();
              }
            }
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnCancelWorkerTopes() {
    this.showWorkerTopesModal = false;
    this.mwtPhaseTask.workerName = '';
    this.mwtPhaseTask.workerId = null;
    this.hideContent = false;
    await this.SaveTaskAsync();
  }

  //#endregion
}
