import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Article } from 'src/app/modules/articles/entities/article';
import { IvaTypes } from 'src/app/modules/auxiliary/entities/iva-types';
import { ListNames, ModalNames } from 'src/app/modules/common/global';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { ShowInvalidFormControls, ValidateNgSelect } from 'src/app/modules/common/services/validators';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtArticle } from '../../entities/main-work-type-article';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { IvaCalculationService } from '../../services/iva-calculation.service';
import API from './../../../common/api.config.json';
import { ArticleCalculationService } from '../../../common/services/calculations/article-calculation.service';
import { toFixedNumber } from '../../../common/services/calculations/calculation';

@Component({
  selector: 'app-delivery-note-article-modal',
  templateUrl: './delivery-note-article-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryNoteArticleModalComponent implements OnInit, AfterViewInit {
  @Output() onSaveDeliveryNoteArticle = new EventEmitter();
  @Input() ivaTypeList: IvaTypes[];
  showContent = false;
  isFinalPriceCal = false;
  // form groups
  articleFormGroup: FormGroup;

  // objects
  transferable: any = {};
  selectedArticle: MwtArticle = new MwtArticle();
  mwtArticle: MwtArticle = new MwtArticle();
  selectedWork: Work;
  selectedSubWork: SubWork;
  selectedMainWorkType: MainWorkType;
  mainWorkTypeList: MainWorkType[];
  articleList: Article[] = [];

  // lists
  selectedMainWorkTypeList: MainWorkType[] = [];

  constructor(
    private _modalService: ModalService,
    private _formBuilder: FormBuilder,
    private _apiService: ApiService,
    public commonUiService: CommonUIService,
    private _dropdownService: DropdownService,
    private _ivaCalculationService: IvaCalculationService,
    private _calculationService: ArticleCalculationService,
    private cdref: ChangeDetectorRef
  ) {
    this.mwtArticle = new MwtArticle();
    this.InitFormGroups();
  }

  async ngOnInit() {
    if (this._dropdownService.ivaTypeList.length === 0) {
      await this._dropdownService.LoadListAsync([ListNames.IvaTypeList]);
    }
  }

  ngAfterViewInit(): void {
    this._modalService.modalSubject.subscribe(modalObject => {
      if (modalObject) {
        if (modalObject.modal === ModalNames.DeliveryNoteArticleModal) {
          if (
            modalObject.flag &&
            modalObject.transferable != null
          ) {
            this.selectedMainWorkTypeList =
              modalObject.transferable.selectedMainWorkTypeList;
            this.selectedSubWork = modalObject.transferable.selectedSubWork;
            this.selectedWork = modalObject.transferable.selectedWork;
            this.mainWorkTypeList = modalObject.transferable.mainWorkTypeList;
            this.LoadList();

            if (!modalObject.transferable.isEdit) {
              this.OnOpenAddDeliveryNoteArticleModal();
            } else if (modalObject.transferable.isEdit &&
              modalObject.transferable.mwtArticle != null) {
              this.OnEditDeliveryNoteArticle(modalObject.transferable.mwtArticle);
            }
          }
          this.showContent = modalObject.flag;
        }
      }
    });
  }

  LoadList() {
    this.articleList = this._dropdownService.articleList
      ? this._dropdownService.articleList
      : [];
    if (this.articleList) {
      this.articleList = this.articleList.filter(x => x.isActive);
    }
    this.cdref.markForCheck();
  }

  InitFormGroups() {
    this.articleFormGroup = this._formBuilder.group({
      articleId: [],
      articleName: ['', [ValidateNgSelect]],
      mainWorkTypeId: ['', [ValidateNgSelect]],
      quantity: [],
      waste: ['', [Validators.required]],
      price: [],
      discount: [],
      finalPrice: [],
      description1: [],
      quantityWithWaste: [],
      priceWithDiscount: [],
      ivaTypeId: [],
      ivaAmount: []
    });
  }

  private OnOpenAddDeliveryNoteArticleModal() {
    this.mwtArticle = new MwtArticle();
    this.articleFormGroup.reset();
    this.articleFormGroup.controls.articleName.setValue('');
    this.articleFormGroup.controls.ivaTypeId.setValue(0);
    this.mwtArticle.priceWithDiscount = 0;
    this.mwtArticle.quantityWithWaste = 0;
    this.mwtArticle.lineDiscountAmount = 0;
    this.mwtArticle.lineSubTotal = 0;
    this.mwtArticle.ivaTypeId = 0;
    this.mwtArticle.ivaAmount = 0;
    this.commonUiService.isPopupOpened = true;
    this.cdref.markForCheck();
  }

  OnEditDeliveryNoteArticle(article: MwtArticle) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (article) {
        this.mwtArticle = { ...article };
        this.articleFormGroup.controls.articleName.setValue(
          this.mwtArticle.description1
        );
        this.articleFormGroup.controls.articleId.setValue(
          this.mwtArticle.articleId
        );
      }
    } catch (error) { console.log(error); } finally {
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  OnClose() {
    this.onSaveDeliveryNoteArticle.emit(null);
    this.showContent = false;
  }

  async OnChangeWorkTypeOrArticle() {
    try {
      if (
        this.selectedMainWorkTypeList &&
        this.mwtArticle.mainWorkTypeId &&
        Number(this.mwtArticle.articleId)
      ) {
        const workTypeId = this.selectedMainWorkTypeList.find(
          x => Number(x.id) === Number(this.mwtArticle.mainWorkTypeId)
        ).workTypeId;
        const quantity = await this.GetQuantityByWorkTypeArticleId(
          workTypeId,
          Number(this.mwtArticle.articleId)
        );
        this.mwtArticle.quantity = quantity ? Number(quantity) : 1;
        this.cdref.markForCheck();
        this.OnKeyUpQuantityOrWaste();
        this.OnKeyUpPriceOrDiscount();
      }
    } catch (error) {
      console.log(error);
    }
  }

  private GetQuantityByWorkTypeArticleId(
    workTypeId: number,
    articleId: number
  ) {
    return this._apiService
      .get(
        API.work_type.getQuantityByWorkTypeArticleId +
        workTypeId +
        '/' +
        articleId,
        true
      )
      .map(x => x as number)
      .toPromise();
  }

  OnKeyUpQuantityOrWaste() {
    if (
      this.mwtArticle.waste !== undefined &&
      this.mwtArticle.quantity !== undefined
    ) {
      this.mwtArticle.quantity = Number(this.mwtArticle.quantity);
      this.mwtArticle.quantityWithWaste = this._calculationService
        .getQuantityWithWaste(toFixedNumber(this.mwtArticle.quantity),
          toFixedNumber(this.mwtArticle.waste));
    }
    this.cdref.markForCheck();
  }

  OnKeyUpPriceOrDiscount() {
    this.isFinalPriceCal = false;
    if (this.mwtArticle.price !== undefined && this.mwtArticle.discount !== undefined) {
      this.mwtArticle.priceWithDiscount = this._calculationService.
        getPriceWithDiscount(this.mwtArticle.price,
          this.mwtArticle.isAmount ? toFixedNumber(this.mwtArticle.discountAmount)
            : toFixedNumber(this.mwtArticle.discount), this.mwtArticle.isAmount);
    }
    this.cdref.markForCheck();
  }

  OnChangeQtyWithWasteOrPriceWithDiscount() {
    if (!this.isFinalPriceCal) {
      if (this.mwtArticle.priceWithDiscount !== undefined
        && this.mwtArticle.quantityWithWaste !== undefined) {
        const finalPriceWithoutIva =
          this._calculationService
            .getArticleFinalTotal(toFixedNumber(this.mwtArticle.quantityWithWaste),
              toFixedNumber(this.mwtArticle.priceWithDiscount), 0);

        if (this.mwtArticle.ivaTypeId) {
          this.mwtArticle.ivaAmount = this._calculationService
            .getIvaAmount(finalPriceWithoutIva,
              this.mwtArticle.ivaPercentage ? toFixedNumber(this.mwtArticle.ivaPercentage) : 0);
        }
        this.mwtArticle.finalPrice = toFixedNumber(finalPriceWithoutIva
          + this.mwtArticle.ivaAmount);

        this.mwtArticle.discountAmount = this._calculationService
          .getDiscountAmount(this.mwtArticle.price, this.mwtArticle.discount);

        this.mwtArticle.lineSubTotal = this.GetLineSubTotal(this.mwtArticle.price,
          this.mwtArticle.quantityWithWaste);

        this.mwtArticle.lineDiscountAmount = this
          .GetLineDiscountAmount(this.mwtArticle.finalPrice,
            this.mwtArticle.ivaAmount, this.mwtArticle.lineSubTotal);
      }
    }
    this.cdref.markForCheck();
  }

  OnKeyUpPriceWithDiscount(e: any) {
    if (e.keyCode === 9) {
      if (e.preventDefault) { e.preventDefault(); }
      return false;
    }
    this.isFinalPriceCal = false;
    if (this.mwtArticle.priceWithDiscount !== undefined && this.mwtArticle.discount !== undefined) {
      this.mwtArticle.price = this._calculationService
        .getPriceFromPriceWithDiscount(toFixedNumber(this.mwtArticle.priceWithDiscount),
          this.mwtArticle.isAmount ? toFixedNumber(this.mwtArticle.discountAmount)
            : toFixedNumber(this.mwtArticle.discount), this.mwtArticle.isAmount);
    }
    this.cdref.markForCheck();
  }

  OnKeyUpFinalPrice() {
    this.isFinalPriceCal = true;
    if (this.mwtArticle.finalPrice !== undefined && this.mwtArticle.ivaAmount !== undefined) {
      const finalPriceWithoutIva = toFixedNumber(this.mwtArticle.finalPrice - this.mwtArticle.ivaAmount);
      this.mwtArticle.priceWithDiscount = toFixedNumber(finalPriceWithoutIva
        / this.mwtArticle.quantityWithWaste);
      this.mwtArticle.price = this._calculationService
        .getPriceFromPriceWithDiscount(toFixedNumber(this.mwtArticle.priceWithDiscount),
          this.mwtArticle.isAmount ? toFixedNumber(this.mwtArticle.discountAmount)
            : toFixedNumber(this.mwtArticle.discount), this.mwtArticle.isAmount);
    }
    this.cdref.markForCheck();
  }

  async OnChangeArticle(article: Article) {
    try {
      if (article.id) {
        this.commonUiService.isSpinnerVisible = true;
        this.mwtArticle.articleId = article.id;
        this.mwtArticle.description1 = article.description1;
        await this.OnSelectArticle();
      }
    } catch (error) { console.log(error); } finally {
      this.cdref.markForCheck();
      this.OnChangeWorkTypeOrArticle();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnSelectArticle() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (this.mwtArticle.articleId) {
        this.articleFormGroup.controls.articleId.setValue(this.mwtArticle.articleId);
        if (this.selectedWork.clientId) {
          const mwtArticle = await this.GetMwtArticlePriceDetails(
            this.mwtArticle.articleId,
            this.selectedWork.clientId
          );
          if (mwtArticle) {

            this.mwtArticle.price = mwtArticle.price !== null ? mwtArticle.price : 0;
            this.mwtArticle.discount = mwtArticle.discount !== null ? mwtArticle.discount : 0;
            this.mwtArticle.discountAmount = mwtArticle.discountAmount ? mwtArticle.discountAmount : 0;
            this.mwtArticle.isAmount = mwtArticle.isAmount !== null ? mwtArticle.isAmount : false;
            this.mwtArticle.waste = mwtArticle.waste !== null ? mwtArticle.waste : 0;
            this.mwtArticle.priceWithDiscount = mwtArticle.priceWithDiscount !== null
              ? mwtArticle.priceWithDiscount : 0;
            this.mwtArticle.quantityWithWaste = mwtArticle.quantityWithWaste !== null
              ? mwtArticle.quantityWithWaste : 0;
            this.mwtArticle.lineDiscountAmount = mwtArticle.lineDiscountAmount !== null
              ? mwtArticle.lineDiscountAmount : 0;
            this.mwtArticle.lineSubTotal = mwtArticle.lineSubTotal !== null ? mwtArticle.lineSubTotal : 0;

            if (mwtArticle.ivaTypeId) {
              this.mwtArticle.ivaTypeId = Number(mwtArticle.ivaTypeId);
              this.mwtArticle.ivaAmount = toFixedNumber(mwtArticle.ivaAmount ? mwtArticle.ivaAmount : 0);
              this.mwtArticle.ivaPercentage = toFixedNumber(mwtArticle.ivaPercentage);
            } else {
              this.mwtArticle.ivaTypeId = 0;
              this.mwtArticle.ivaAmount = 0;
              this.mwtArticle.ivaPercentage = 0;
            }

            let workTypeId = 0;
            if (this.selectedMainWorkTypeList.length === 1) {
              workTypeId = this.selectedMainWorkTypeList[0].workTypeId;
            } else {
              const mwt = this.selectedMainWorkTypeList.find(
                m => Number(m.id) === Number(this.mwtArticle.mainWorkTypeId)
              );
              if (mwt) {
                workTypeId = mwt.workTypeId;
              }
            }
            if (workTypeId > 0) {
              const quantity = await this.GetQuantityByWorkTypeArticleId(
                workTypeId,
                Number(this.mwtArticle.articleId)
              );
              this.mwtArticle.quantity = quantity ? Number(quantity) : 1;
            } else {
              this.mwtArticle.quantity = 1;
            }
            this.OnKeyUpQuantityOrWaste();
            this.OnKeyUpPriceOrDiscount();
          }
        }
        const article = this.articleList.find(
          x => Number(x.id) === Number(this.mwtArticle.articleId)
        );
        if (article) {
          this.mwtArticle.description1 = article.description1;
          this.mwtArticle.code = article.code;
        }
      } else {
        this.mwtArticle.description1 = '';
      }
    } catch (error) { console.log(error); } finally {
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  private GetMwtArticlePriceDetails(
    articleId: number,
    clientId: number
  ): Promise<MwtArticle> {
    return new Promise<MwtArticle>((resolve, reject) => {
      this._apiService
        .get(
          API.main_work_type_work.getMwtArticlePriceDetails +
          articleId +
          '/' +
          clientId
        )
        .subscribe((mwtArticle: MwtArticle) => resolve(mwtArticle));
    });
  }

  GetLineDiscountAmount(finalTotal: number, vatAmount: number, subTotal: number) {
    try {
      return toFixedNumber(subTotal - (finalTotal - vatAmount));
    } catch (error) { console.log(error); }
  }

  GetLineSubTotal(price: number, quantityWithWaste: number) {
    try {
      return this._calculationService
        .getLineSubTotal(price, quantityWithWaste);
    } catch (error) { console.log(error); }
  }

  async OnSaveDeliveryNoteArticle(event: any) {
    if (this.selectedMainWorkTypeList != null && this.selectedMainWorkTypeList.length === 1) {
      this.articleFormGroup.controls.mainWorkTypeId
        .setValue(this.selectedMainWorkTypeList[0].id);
    }
    if (this.articleFormGroup.invalid) {
      ShowInvalidFormControls(this.articleFormGroup);
      return;
    }
    this.onSaveDeliveryNoteArticle.emit(this.mwtArticle);
    this.showContent = false;
    this.cdref.markForCheck();
  }

  OnChangeIvaType() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (Number(this.mwtArticle.ivaTypeId) && this.ivaTypeList && this.ivaTypeList.length > 0) {
        const iType = this.ivaTypeList.find(it => it.id === Number(this.mwtArticle.ivaTypeId));
        if (iType) { this.mwtArticle.ivaPercentage = iType.iva; }
      } else { this.mwtArticle.ivaPercentage = 0; }
      this.mwtArticle.ivaAmount = 0;
      this.OnChangeQtyWithWasteOrPriceWithDiscount();
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }
}
