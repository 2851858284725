import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Metals } from 'src/app/modules/auxiliary/entities/metals';
import { ModalNames } from 'src/app/modules/common/global';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { ShowInvalidFormControls, ValidateNgSelect } from 'src/app/modules/common/services/validators';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtMetal } from '../../entities/main-work-type-metal';
import { Work } from '../../entities/work';
import { MwtColorService } from '../../services/mwt-color.service';
import * as moment from 'moment';

@Component({
  selector: 'app-add-metal-modal',
  templateUrl: './add-metal-modal.component.html',
  styleUrls: ['./add-metal-modal.component.scss']
})
export class AddMetalModalComponent implements OnInit {
  showContent = false;

  @Output() refreshData = new EventEmitter();
  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  formGroup: FormGroup;
  mwtMetalList: MwtMetal[] = [];
  selectedWork: Work;
  selectedMainWorkTypeList: MainWorkType[] = [];
  metalListDropdown: Metals[] = [];
  metalValidateMessage: any;
  isMetalExists = false;
  saveDisabled = true;


  constructor(private _formBuilder: FormBuilder,
    private _modalService: ModalService,
    public dropdownService: DropdownService,
    private _commonUiService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: Toast,
    private _mwtColorService: MwtColorService) {
    this.showContent = true;
  }

  ngOnInit() {
    this.InitFormGroup();
    this.mwtMetalList = [] as MwtMetal[];
    this.ModalListener();
    if (this.dropdownService.metalList) {
      this.metalListDropdown = this.dropdownService.metalList.filter(x => x.status == true);
    }
  }

  private InitFormGroup() {
    this.formGroup = this._formBuilder.group({
      workTypeId: [],
      mainWorkTypeId: ['', ValidateNgSelect],
      metalName: [],
      metalId: []
    });
  }

  private ModalListener() {
    if (this._modalService.modal === ModalNames.AddMetalModal) {
      this.selectedMainWorkTypeList = this._modalService.transferable.mainWorkTypeList as MainWorkType[];
      this.selectedWork = this._modalService.transferable.selectedWork as Work;
      if (!this._modalService.isEdit) {
        this.OnAdd();
      }
    }
  }

  private async OnAdd() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      this.formGroup.controls.metalFormArray = this._formBuilder.array([]);
      if (this.selectedMainWorkTypeList.length === 1) {
        this.formGroup.controls.mainWorkTypeId.setValue(this.selectedMainWorkTypeList[0].id);
      } else {
        this.formGroup.controls.mainWorkTypeId.setValue(0);
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  async OnSelectMetal(metal: Metals) {
    this.isMetalExists = false;
    if (metal) {
      this.formGroup.controls.metalId.setValue(Number(metal.id));
    } else {
      this.formGroup.controls.metalId.setValue(null);
    }
  }

  OnClose() {
    this.closeModel.emit();
    this.showContent = false;
  }

  async OnChangeMainWorkType(mwtId: number) {
    if (mwtId > 0) {
      this.formGroup.controls.mainWorkTypeId.setValue(Number(mwtId));
    } else {
      this.formGroup.controls.mainWorkTypeId.setValue(0);
    }
  }

  AddMwtMetalToList() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      this.isMetalExists = false;
      const user = this._commonDataService.GetUserFromSession();
      if (this.formGroup.value) {
        const formData = this.formGroup.value as {
          workTypeId: number, mainWorkTypeId: number,
          metalName: string, metalId: number
        };
        const metal = new MwtMetal();

        if (formData.metalId) {
          metal.metalId = Number(formData.metalId);
          metal.metalName = formData.metalName;
        }
        formData.mainWorkTypeId = formData.mainWorkTypeId ? Number(formData.mainWorkTypeId) : null;
        metal.mainWorkTypeId = Number(formData.mainWorkTypeId);
        const index: number = this.mwtMetalList.findIndex(e => e.metalId == metal.metalId);
        if (index !== -1) {
          this.isMetalExists = true;
          this.metalValidateMessage = this._commonDataService.localizationLabelList['doctorMetalErrorMessage'];
          return;
        }
        metal.createdBy = user ? user.id : null;
        metal.createdDate = moment().format('YYYY-MM-DD')
        this.mwtMetalList.push(metal);
        this.saveDisabled = (this.mwtMetalList.length > 0) ? false : true;
        this.ClearForm();
      }
    } catch (error) { console.log(error); } finally {
      this.ClearForm();
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  OnRemoveMetal(index: any) {
    if (index !== undefined) {
      if (index !== -1) {
        this.mwtMetalList.splice(index, 1);
        this.saveDisabled = (this.mwtMetalList.length > 0) ? false : true;
      }
    } else { console.log('Metal is not defined!'); }
  }


  private ClearForm() {
    this.formGroup.controls.metalId.setValue('');
    this.formGroup.controls.metalName.setValue('');
  }

  async OnSaveMwtMetal() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (this.formGroup.invalid) { ShowInvalidFormControls(this.formGroup); } else {
        const mwtMetalList = this.mwtMetalList ? this.mwtMetalList : [];
        mwtMetalList.forEach(metal => metal.mainWorkTypeId = Number(this.formGroup.controls.mainWorkTypeId.value));
        const response = await this._mwtColorService.SaveAllMwtMetals(mwtMetalList);
        if (response) {
          const body = JSON.parse(response['_body']);
          if (body._isSuccsess) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['metal'],
              msg: this._commonDataService.localizationLabelList['save_success'],
            });
            this.OnClose();
            this.refreshData.emit();
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['metal'],
              msg: this._commonDataService.localizationLabelList['save_error'],
            });
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

}
