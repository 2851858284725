import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonFunctions } from 'src/app/modules/common/services/common-functions';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { Color } from '../../../auxiliary/entities/Color';
import { Localizations } from '../../../auxiliary/entities/localizations';
import { Metals } from '../../../auxiliary/entities/metals';
import { ModalNames, Piezas } from '../../../common/global';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { ModalService } from '../../../common/services/modal.service';
import { CheckProperties, ShowInvalidFormControls, ValidateNgSelect } from '../../../common/services/validators';
import { WorkTypeArticleTemplate } from '../../../work-type/entities/work-type-model-templates';
import { ArticlesComponent } from '../../components/articles/articles.component';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtArticle } from '../../entities/main-work-type-article';
import { MwtColor } from '../../entities/main-work-type-color';
import { Work } from '../../entities/work';
import { ArticleTemplateService } from '../../services/article-template.service';
import { IvaCalculationService } from '../../services/iva-calculation.service';
import { MwtColorService } from '../../services/mwt-color.service';

@Component({
  selector: 'app-add-color-modal',
  templateUrl: './add-color-modal.component.html',
  styleUrls: ['./add-color-modal.component.scss']
})
export class AddColorModalComponent implements OnInit {

  @Output() refreshData = new EventEmitter();
  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  @ViewChild('alertpopover') alertPopOverElement: any;
  @ViewChild('articleLbl') articleLblElement: ElementRef;

  showContent = false;
  saveDisabled = false;
  PIEZAS = Piezas;
  formGroup: FormGroup;
  mwtColor: MwtColor;
  selectedWork: Work;
  isArticleDisabled = false;

  selectedMainWorkTypeList: MainWorkType[] = [];
  articleTemplateList: WorkTypeArticleTemplate[] = [];
  mwtArticleList: MwtArticle[] = [];

  metalListDropdown: Metals[] = [];
  localizationListDropdown: Localizations[] = [];
  colorListDropdown: Color[] = [];

  constructor(private _formBuilder: FormBuilder,
    private _modalService: ModalService,
    public dropdownService: DropdownService,
    private _articleTemplateService: ArticleTemplateService,
    private _mwtColorService: MwtColorService,
    private _commonUiService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: Toast,
    private _ivaCalculationService: IvaCalculationService) {
    this.showContent = true;
    this.saveDisabled = true;
    this.selectedWork = new Work();
  }

  ngOnInit() {
    this.InitFormGroup();
    this.mwtColor = new MwtColor();
    this.ModalListener();
    this.ColorFormGroupListener();
    this.ColorFormArrayListener();
    if (this.dropdownService.metalList) {
      this.metalListDropdown = this.dropdownService.metalList.filter(x => x.status == true);
    }
    if (this.dropdownService.localizationList) {
      this.localizationListDropdown = this.dropdownService.localizationList;
    }
    if (this.dropdownService.colorList) {
      this.colorListDropdown = this.dropdownService.colorList;
    }
  }

  private ModalListener() {
    if (this._modalService.modal === ModalNames.AddColorModal) {
      this.selectedMainWorkTypeList = this._modalService.transferable.mainWorkTypeList as MainWorkType[];
      this.selectedWork = this._modalService.transferable.selectedWork as Work;
      if (!this._modalService.isEdit) {
        this.OnAdd();
      }
    }
  }

  private ColorFormArrayListener() {
    this.formGroup.controls.colorFormArray.valueChanges.subscribe((formArray: any[]) => {
      if (formArray) {
        const index = formArray.findIndex(element => CheckProperties(element));
        this.saveDisabled = index > -1;
      }
    });
  }

  private ColorFormGroupListener() {
    // this.formGroup.valueChanges.subscribe(form => {
    //   const isButtonVisible = (this.formGroup.controls.mainWorkTypeId.value && this.formGroup.controls.mainWorkTypeId.value > 0) &&
    //     !(this.formGroup.get('pieces').errors && this.formGroup.get('pieces').errors.pattern) &&
    //     (form.localizationId !== undefined || form.colorId !== undefined || form.pieces !== undefined)
    //     && (form.localizationId !== null || form.colorId !== null || form.pieces !== null);

    //   if (isButtonVisible && !this.alertPopOverElement.isOpen()) {
    //     this.alertPopOverElement.open();
    //     console.log('opened');
    //   } else {
    //     this.alertPopOverElement.close();
    //     console.log('closed');
    //   }
    // });
  }

  private InitFormGroup() {
    this.formGroup = this._formBuilder.group({
      workTypeId: [],
      mainWorkTypeId: ['', ValidateNgSelect],
      articleName: [], articleId: [],
      localizationText: [], localizationId: [],
      colorId: [], colorName: [],
      guideName: [], metalName: [], metalId: [],
      pieces: ['', Validators.pattern('^[1-9]{2,2}(,[1-9]{2,2})*$')],
      notes: [], colorFormArray: this._formBuilder.array([])
    });
  }

  private CreateColorFormGroup(init?: {
    colorArticleText: string,
    colorMwtId: number;
    colorArticleId: number,
    colorMetalText: string;
    colorMetalId: number;
    colorLocalizationText: string,
    colorLocalizationId: number,
    colorColorText: string,
    colorColorId: number,
    colorColorGuideName: string,
    colorNote: string;
    colorPiezasText: string
  }) {
    let group = {
      colorArticleText: [], colorArticleId: [],
      colorMetalText: [], colorMetalId: [], colorMwtId: [],
      colorLocalizationText: [], colorLocalizationId: [],
      colorColorGuideName: [], colorNote: [],
      colorColorText: [], colorColorId: [],
      colorPiezasText: ['', Validators.pattern('^[1-9]{2,2}(,[1-9]{2,2})+(-[1-9]{2,2})*$')]
    };
    if (init) {
      group = {
        colorArticleText: [init.colorArticleText],
        colorArticleId: [init.colorArticleId],
        colorMwtId: [init.colorMwtId],
        colorMetalText: [init.colorMetalText],
        colorMetalId: [init.colorMetalId],
        colorLocalizationText: [init.colorLocalizationText],
        colorLocalizationId: [init.colorLocalizationId],
        colorColorText: [init.colorColorText],
        colorColorId: [init.colorColorId],
        colorColorGuideName: [init.colorColorGuideName],
        colorNote: [init.colorNote],
        colorPiezasText: [init.colorPiezasText, Validators.pattern('^[0-9]{1,3}(,[0-9]{1,3})*$')]
      };
    }
    return this._formBuilder.group(group);
  }

  private AddColorFormGroup(init?: {
    colorArticleText: string,
    colorMwtId: number,
    colorArticleId: number,
    colorMetalText: string,
    colorMetalId: number,
    colorLocalizationText: string,
    colorLocalizationId: number,
    colorColorText: string,
    colorColorId: number,
    colorColorGuideName: string,
    colorNote: string,
    colorPiezasText: string
  }) {
    const colorGroups = this.formGroup.controls.colorFormArray as FormArray;
    colorGroups.push(this.CreateColorFormGroup(init ? init : undefined));
  }

  private ClearForm() {
    this.formGroup.controls.articleName.setValue('');
    this.formGroup.controls.articleId.setValue('');
    this.formGroup.controls.metalId.setValue('');
    this.formGroup.controls.metalName.setValue('');
    this.formGroup.controls.localizationId.setValue(null);
    this.formGroup.controls.localizationText.setValue('');
    this.formGroup.controls.colorId.setValue(null);
    this.formGroup.controls.colorName.setValue('');
    this.formGroup.controls.pieces.setValue(null);
    this.formGroup.controls.notes.setValue('');
    this.formGroup.controls.guideName.setValue('');
  }

  private async OnAdd() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      this.formGroup.controls.colorFormArray = this._formBuilder.array([]);
      if (this.selectedMainWorkTypeList.length === 1) {
        this.formGroup.controls.mainWorkTypeId.setValue(this.selectedMainWorkTypeList[0].id);
        this.articleTemplateList = await this._articleTemplateService
          .GetArticleTemplateListByWorkTypeTemplateId(Number(this.selectedMainWorkTypeList[0].workTypeId));
      } else {
        this.formGroup.controls.mainWorkTypeId.setValue(0);
      }
      this.mwtArticleList = [];
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  async OnChangeMainWorkType(mwtId: number) {
    this.articleTemplateList = [];
    if (mwtId) {
      const mwt = this.selectedMainWorkTypeList.find(m => m.id === Number(mwtId));
      if (mwt) {
        this.articleTemplateList = await this._articleTemplateService
          .GetArticleTemplateListByWorkTypeTemplateId(Number(mwt.workTypeId));
      }
    }
  }

  OnSelectArticle(articleTemplate: WorkTypeArticleTemplate) {
    if (articleTemplate) {
      this.formGroup.controls.articleId.setValue(articleTemplate.articleId);
    } else {
      this.formGroup.controls.articleId.setValue(null);
    }
  }

  async OnSelectMetal(metal: Metals) {
    let oldMetalId = 0;
    oldMetalId = this.formGroup.controls.metalId.value ? this.formGroup.controls.metalId.value : 0;
    if (metal) {
      this.formGroup.controls.metalId.setValue(Number(metal.id));
      if (metal.articleId > 0) {
        const articleTemplate = this.articleTemplateList.find(x => x.articleId == metal.articleId);
        if (articleTemplate) {
          this.formGroup.controls.articleId.setValue(articleTemplate.articleId);
          this.formGroup.controls.articleName.setValue(articleTemplate.articleName);
          this.articleLblElement.nativeElement.click();
          this.isArticleDisabled = true;
          await this.OnAddMetelArticle(metal, oldMetalId);
        } else {
          const art = this.dropdownService.articleList.find(a => a.id === metal.articleId && a.isActive == true);
          if (art) {
            const template = new WorkTypeArticleTemplate();
            template.articleId = metal.articleId;
            template.articleName = metal.articleName;
            this.articleTemplateList.push(template);
            this.formGroup.controls.articleId.setValue(metal.articleId);
            this.formGroup.controls.articleName.setValue(metal.articleName);
            this.articleLblElement.nativeElement.click();
          }
          this.isArticleDisabled = true;
          await this.OnAddMetelArticle(metal, oldMetalId);
        }
      } else {
        this.formGroup.controls.articleId.setValue(null);
        this.formGroup.controls.articleName.setValue('');
        this.isArticleDisabled = true;
        await this.OnAddMetelArticle(metal, oldMetalId);
      }
    } else {
      this.formGroup.controls.metalId.setValue(null);
      this.formGroup.controls.articleId.setValue(null);
      this.formGroup.controls.articleName.setValue('');
      this.isArticleDisabled = false;
      await this.OnAddMetelArticle(undefined, oldMetalId);
    }
  }

  async OnAddMetelArticle(newMetal: Metals, oldMetalId: number) {
    const oldMetal = this.dropdownService.metalList.filter(o => o.status == true && o.id === oldMetalId)[0];
    if (newMetal) {
      if (newMetal.articleId > 0) {
        if (Number(oldMetalId) != newMetal.id) {
          if (oldMetal) {
            if (Number(oldMetal.id) > 0 && Number(oldMetal.articleId) > 0) {
              this.RemoveMetalArticle(oldMetal.articleId);
              await this.OnAddArticle({ id: newMetal.articleId, label: newMetal.articleName });
            }
          } else { await this.OnAddArticle({ id: newMetal.articleId, label: newMetal.articleName }); }
        }
      } else {
        if (oldMetal && Number(oldMetal.id) > 0 && Number(oldMetal.articleId) > 0) { this.RemoveMetalArticle(oldMetal.articleId); }
      }
    } else {
      if (oldMetal && Number(oldMetal.id) > 0 && Number(oldMetal.articleId) > 0) { this.RemoveMetalArticle(oldMetal.articleId); }
    }
  }

  OnSelectLocalization(localization: Localizations) {
    if (localization) {
      this.formGroup.controls.localizationId.setValue(Number(localization.id));
    } else {
      this.formGroup.controls.localizationId.setValue(null);
    }
  }

  OnSelectColor(color: Color) {
    if (color) {
      this.formGroup.controls.colorId.setValue(Number(color.id));
      this.formGroup.controls.guideName.setValue(color.guideName);
    } else {
      this.formGroup.controls.colorId.setValue(null);
      this.formGroup.controls.guideName.setValue('');
    }
  }

  OnTypePiezasNumber(control: any) {
    const value = CommonFunctions.ValidatePiezasNumber(control.value);
    control.setValue(value);
  }

  AddMwtColorToList() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (this.formGroup.value) {
        const formData = this.formGroup.value as {
          workTypeId: number, mainWorkTypeId: number,
          articleName: string, articleId: number,
          localizationText: string, localizationId: number,
          colorId: number, colorName: string,
          guideName: string, metalName: string, metalId: number,
          pieces: string, notes: string, colorFormArray: any[]
        };
        const group = {
          colorMwtId: null,
          colorArticleText: '',
          colorMetalText: '',
          colorMetalId: null,
          colorArticleId: null,
          colorLocalizationText: '',
          colorLocalizationId: null,
          colorColorText: '',
          colorColorId: null,
          colorColorGuideName: '',
          colorNote: '',
          colorPiezasText: ''
        };

        if (formData.articleId) {
          group.colorArticleId = Number(formData.articleId);
          group.colorArticleText = formData.articleName;
        }
        if (formData.metalId) {
          group.colorMetalId = Number(formData.metalId);
          group.colorMetalText = formData.metalName;
        }
        if (formData.colorId) {
          group.colorColorId = Number(formData.colorId);
          group.colorColorText = formData.colorName;
        }
        if (formData.localizationId) {
          group.colorLocalizationId = Number(formData.localizationId);
          group.colorLocalizationText = formData.localizationText;
        }

        group.colorMwtId = formData.mainWorkTypeId ? Number(formData.mainWorkTypeId) : null;
        group.colorNote = formData.notes ? formData.notes : '';
        group.colorColorGuideName = formData.guideName ? formData.guideName : '';
        group.colorPiezasText = formData.pieces ? formData.pieces : '';
        this.ClearForm();
        this.AddColorFormGroup(group);
        this.isArticleDisabled = false;
      }
    } catch (error) { console.log(error); } finally {
      this.ClearForm();
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  OnTypeColorPiezasNumber(control: any) {
    const value = CommonFunctions.ValidatePiezasNumber(control.value);
    control.setValue(value);
  }

  SetColorColorId(color: Color, group: FormGroup) {
    if (color && group) {
      group.controls.colorColorId.setValue(Number(color.id));
    } else {
      group.controls.colorColorId.setValue(null);
    }
  }

  SetColorLocalizationId(localization: Localizations, group: FormGroup) {
    if (localization && group) {
      group.controls.colorLocalizationId.setValue(Number(localization.id));
    } else {
      group.controls.colorLocalizationId.setValue(null);
    }
  }

  async SetColorMetelId(metal: Metals, group: FormGroup) {
    let oldMetalId = 0;
    oldMetalId = group.controls.colorMetalId.value ? group.controls.colorMetalId.value : 0;
    if (metal && group) {
      group.controls.colorMetalId.setValue(Number(metal.id));
      if (metal.articleId) {
        const articleTemplate = this.articleTemplateList.find(x => x.articleId == metal.articleId);
        if (articleTemplate) {
          group.controls.colorArticleId.setValue(articleTemplate.articleId);
        } else {
          group.controls.colorArticleId.setValue(metal.articleId);
          await this.OnAddMetelArticle(metal, oldMetalId);
        }
      } else {
        group.controls.colorArticleId.setValue(null);
        await this.OnAddMetelArticle(metal, oldMetalId);
      }
    } else {
      group.controls.colorMetalId.setValue(null);
      group.controls.colorArticleId.setValue(null);
      await this.OnAddMetelArticle(undefined, oldMetalId);
    }
  }

  OnRemoveColorRow(index: number) {
    const formArray = this.formGroup.controls.colorFormArray as FormArray;
    if (formArray) {
      const list = formArray.getRawValue();
      const color = list[index];
      formArray.removeAt(index);
      if (color) { this.RemoveMetalArticle(color.colorArticleId); }
    }
  }

  async OnSaveMwtColor() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (this.formGroup.invalid) { ShowInvalidFormControls(this.formGroup); } else {
        if (this.formGroup.controls.colorFormArray.value) {
          const formArray = this.formGroup.controls.colorFormArray.value as {
            colorArticleText: string, colorMwtId: number,
            colorArticleId: number, colorMetalText: string,
            colorMetalId: number, colorLocalizationText: string,
            colorLocalizationId: number, colorColorText: string,
            colorColorId: number, colorColorGuideName: string,
            colorNote: string, colorPiezasText: string
          }[];
          if (formArray) {
            const index = formArray.findIndex(element => (!element.colorPiezasText) && (!element.colorLocalizationText) &&
              (!element.colorColorText) && (!element.colorMetalText));
            if (index > -1) {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['work_color'],
                msg: this._commonDataService.localizationLabelList['please_select_pieces']
              });
            } else {
              if (this.mwtArticleList.length > 0) {
                const list = await this._articleTemplateService.SaveAllMwtArticle(this.mwtArticleList);
              }
              let artList = await this._articleTemplateService.GetArticleTemplateListByMainWorkTypeIdList(
                [{ id: Number(this.formGroup.value.mainWorkTypeId) }]);

              let mwtColorList = formArray.map(fe => (new MwtColor({
                mainWorkTypeId: fe.colorMwtId,
                colorId: fe.colorColorId,
                metalId: fe.colorMetalId,
                localizationId: fe.colorLocalizationId,
                notes: fe.colorNote,
                pieces: fe.colorPiezasText,
                mwtArticleId: fe.colorArticleId
              })));
              mwtColorList = mwtColorList ? mwtColorList : [];
              if (mwtColorList.length > 0) {
                artList = artList ? artList : [];
                mwtColorList.forEach(color => {
                  const article = artList.find(x => x.articleId === color.mwtArticleId);
                  color.mwtArticleId = article ? Number(article.mwtArticleId) : null;
                });
                const response = await this._mwtColorService.SaveAllMwtColors(mwtColorList);
                if (response) {
                  const body = JSON.parse(response['_body']);
                  if (body._isSuccsess) {
                    this._toastyService.success({
                      title: this._commonDataService.localizationLabelList['work_color'],
                      msg: this._commonDataService.localizationLabelList['save_success'],
                    });
                    this.OnClose();
                    this.refreshData.emit();
                  } else {
                    this._toastyService.error({
                      title: this._commonDataService.localizationLabelList['work_color'],
                      msg: this._commonDataService.localizationLabelList['save_error'],
                    });
                  }
                }
              } else {
                this._toastyService.warning({
                  title: this._commonDataService.localizationLabelList['work_color'],
                  msg: this._commonDataService.localizationLabelList['save_error'],
                });
              }
            }
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  OnClose() {
    this.closeModel.emit();
    this.showContent = false;
  }

  async RemoveMetalArticle(articleId: number) {
    if (Number(articleId) > 0) {
      const index = this.mwtArticleList.findIndex(x => x.articleId === Number(articleId));
      if (index != -1) {
        const mwtArticle = this.mwtArticleList.find(o => o.articleId == Number(articleId));
        this.mwtArticleList.splice(index, 1);
        if (mwtArticle.quantity > 1) {
          const templateId = Number(this.formGroup.value.mainWorkTypeId);
          let quantity = 1;
          if (templateId > 0) {
            const qty = await this._articleTemplateService
              .GetQuantityByWorkTypeArticleId(templateId, mwtArticle.articleId);
            quantity = qty ? qty : 1;
          }
          mwtArticle.quantity = (mwtArticle.quantity - quantity);

          mwtArticle.quantityWithWaste = this._articleTemplateService.GetQuantityWithWaste(mwtArticle);
          mwtArticle.priceWithDiscount = this._articleTemplateService.GetPriceWithDiscount(mwtArticle);
          const finalPriceWithoutIva = this._articleTemplateService.GetFinalPriceWithoutIva(mwtArticle);

          let ivaAmount = 0;
          if (mwtArticle.ivaTypeId) {
            ivaAmount = this._ivaCalculationService
              .getIvaAmount(finalPriceWithoutIva, mwtArticle.ivaPercentage ? mwtArticle.ivaPercentage : 0);
            mwtArticle.ivaAmount = Number(ivaAmount.toFixed(2));
          }
          mwtArticle.finalPrice = Number((finalPriceWithoutIva + ivaAmount).toFixed(2));
          this.mwtArticleList.push(mwtArticle);
        }
      }
    }
  }

  async OnAddArticle(article: { id: number, label: string }) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      const art = this.dropdownService.articleList.find(a => a.id === article.id && a.isActive == true);
      if (art)
        if (article.id) {
          if (this.selectedWork && this.selectedWork.clientId) {
            const mwtArticle = await this._articleTemplateService.GetMwtArticle(this.selectedWork.clientId, { id: article.id, description: article.label }, Number(this.formGroup.value.mainWorkTypeId));
            const index = this.mwtArticleList.findIndex(x => x.articleId === mwtArticle.articleId);

            if (index != -1) {
              for (let idx = 0; index < this.mwtArticleList.length; idx++) {
                const element = this.mwtArticleList[idx];
                if (element.articleId === mwtArticle.articleId) {
                  mwtArticle.quantity += element.quantity;
                  mwtArticle.finalPrice += element.finalPrice;
                  this.mwtArticleList.splice(index, 1);
                }
              }
            }
            this.mwtArticleList.push(mwtArticle);
          }
        }
    } catch (error) { console.log(error); }
    finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

}
