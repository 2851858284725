import { WorkReservation } from "../../reservations/entities/work-reservation";

export class MainWorkType {
  id: any;
  name: string;
  receiveDate?: any;
  deliveryDate?: any;
  workTypeStatusId: any;
  status: boolean;
  workId: number;
  workTypeStatusName: string;
  workTypeId: any;
  workTypeName: string;
  subWorkId: number;
  generateNo: string;
  cssClass: string;
  symbol: string;
  productDescriptionId?: number;
  therapeuticPurposeId?: number;
  productDescriptionName?: string;
  therapeuticPurposeName?: string;
  deliveryTime: any;
  realDeliveryDate: any;
  realDeliveryTime: any;
  clientId: number;
  isImplantData: boolean;
  doctorName: string;
  manualStatus: boolean;
  isSelected: boolean;
  order: number;
  isExternal: boolean;
  createdBy: number;
  createdDate: string;
  priorityId: number;
  deliveryDateView?: any;

  observation: string;
  alert: string;
  isReservation: boolean;
  reservationId: number;
  workReservation?: WorkReservation;

  reportTypeId: number;
  isPrint: boolean;
  isMail: boolean;
  isOpen: boolean;
  isClosed: boolean;
  bateaNo: string;

  isDentboxWork: boolean;
  dentboxWorkGuid: string;


  constructor(init?: Partial<MainWorkType>) {
    Object.assign(this, init);
  }
}

