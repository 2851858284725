import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Priority } from 'src/app/modules/common/entities/dropdown-enum';
import { FolderPaths, NoteTypes } from 'src/app/modules/common/global';
import { CommonFunctions } from 'src/app/modules/common/services/common-functions';
import { Toast } from 'src/app/modules/common/services/toast.service';
import * as uuid from 'uuid';
import { ExternalDataStatus, ListNames } from '../../../common/global';
import { CommonDataService } from '../../../common/services/common.data.service';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { DropdownService } from '../../../common/services/dropdown.service';
import { CheckProperties, ShowInvalidFormControls, ValidateDatePicker, ValidateNgSelect } from '../../../common/services/validators';
import { WorkTypeProductTemplate } from '../../../work-type/entities/work-type-model-templates';
import { Document } from '../../entities/document';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtArticle } from '../../entities/main-work-type-article';
import { MwtClinicMaterial } from '../../entities/main-work-type-clinic-material';
import { MwtColor } from '../../entities/main-work-type-color';
import { MWTExternalData } from '../../entities/main-work-type-external-data';
import { MWTExternalDataDocuments } from '../../entities/main-work-type-external-data-documents';
import { MwtImplantData } from '../../entities/main-work-type-implant-data';
import { Mwtproduct } from '../../entities/main-work-type-product';
import { MwtDto } from '../../entities/mwt-dto';
import * as _ from 'lodash';
import {
  ArticleFormModel, ClinicMaterialFormModel, ColorFormModel, DocumentFormModel,
  ExternalAttachmentFormModel, ExternalFormModel, ImplantDataFormModel, MetalFormModel, MwtFormModel, PrescriptionFormModel, ProductFormModel,
  WorkTypeFormModel
} from '../../entities/mwt-popup-models';
import { Note } from '../../entities/note';
import { Prescription } from '../../entities/prescription';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { ArticleTemplateService } from '../../services/article-template.service';
import { ExternalWorkService } from '../../services/external-work.service';
import { WorkService } from '../../services/work.service';
import { WorkReservationService } from 'src/app/modules/reservations/services/work-reservation.service';
import { LabHolidaysService } from '../../services/lab-holidays.service';
import { DentboxLabService } from 'src/app/modules/common/dentbox/services/dentbox-lab.service';
import { DentboxWorkStatusModel } from 'src/app/modules/common/entities/dentbox-models';
import { Product } from 'src/app/modules/products/entities/product';
import { IvaCalculationService } from '../../services/iva-calculation.service';
import { ClientMetal } from 'src/app/modules/client/entities/client-metal';
import { ClientService } from 'src/app/modules/client/services/client.service';
import { Metals } from 'src/app/modules/auxiliary/entities/metals';
import { MwtMetal } from '../../entities/main-work-type-metal';
import { ArticleCalculationService } from '../../../common/services/calculations/article-calculation.service';
import { toFixedNumber } from '../../../common/services/calculations/calculation';

@Component({
  selector: 'app-add-worktype-modal',
  templateUrl: './add-worktype-modal.component.html',
  styleUrls: ['./add-worktype-modal.component.scss']
})
export class AddWorktypeModalComponent implements OnInit {

  showExternalPanel = false;
  showImplantPanel = false;
  collapseImplantPanel = false;
  showIsExternalCheck = false;
  mwtFormGroup: FormGroup;
  workTypeFormGroup: FormGroup;
  externalFormGroup: FormGroup;
  searchArticleName: FormControl;
  searchProductName: FormControl;

  nextOutSourceNo: string;
  defaultExternalCenter: { id: number, description: string, default: boolean };
  defaultMwtStatusId: string;

  @Input() selectedSubWork: SubWork;
  @Input() selectedWork: Work;
  @Input() mwt: MainWorkType;
  @ViewChild('articleElement') articleElement: ElementRef;
  @ViewChild('productInput') productInputVariable: ElementRef;

  templateList: { id: number, description: string, isImplant: boolean, isExternal: boolean }[] = [];
  mwtStatusList: { id: number, description: string }[] = [];
  colorList: { id: number, description: string }[] = [];
  localizationList: { id: number, description: string }[] = [];
  metalList: { id: number, description: string }[] = [];
  clinicMaterialList: { id: number, description: string }[] = [];
  articleList: { id: number, description: string }[] = [];
  productList: { id: number, description: string }[] = [];
  externalCenterList: { id: number, description: string, default: boolean }[] = [];
  shippingMethodList: { id: number, description: string }[] = [];
  statusIdList = ExternalDataStatus;
  clientMetalList: ClientMetal[] = [];
  tempProductTemplateList: any;

  @Output() popupClosed = new EventEmitter<boolean>();
  isMetalExists = false;
  metalValidateMessage: any;

  priorityId = 1;
  // in here using enum as Priority
  priorityEnum = Priority;

  constructor(
    private _formBuilder: FormBuilder,
    public dropdownService: DropdownService,
    private _commonUiService: CommonUIService,
    public _commonDataService: CommonDataService,
    private _articleTemplateService: ArticleTemplateService,
    private _workService: WorkService,
    private _externalWorkService: ExternalWorkService,
    private _toastyService: Toast,
    private reservationService: WorkReservationService,
    private _labHolidaysService: LabHolidaysService,
    private _dentboxLabService: DentboxLabService,
    private _clientService: ClientService,
    private _calculationService: ArticleCalculationService) {
    this._commonUiService.isSpinnerVisible = true;
    this.workTypeFormGroup = this.GetWorkTypeFormGroup();
    this.externalFormGroup = this.GetExternalFormGroup();
    this.searchArticleName = new FormControl('');
    this.searchProductName = new FormControl('');
  }

  async ngOnInit() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      this.InitFormGroup();
      await this.InitResources();
      if (this.mwt && this.mwtFormGroup) {

        // set defaults
        const wtGroup = this.mwtFormGroup.get('workTypeFormGroup') as FormGroup;
        if (wtGroup) {
          wtGroup.controls.receivedDate.setValue(this._commonUiService
            .ConvertToPickerDateFormat(this.mwt.receiveDate));
          wtGroup.controls.deliveryDate.setValue(this._commonUiService
            .ConvertToPickerDateFormat(this.mwt.deliveryDate));
          wtGroup.controls.clientObservations.setValue(this.mwt.observation);
          wtGroup.controls.generalObservations.setValue(this.mwt.alert);
        }
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  OnClosePopup() { this.popupClosed.emit(false); }

  private async InitResources() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (this.dropdownService.workTypeTemplateList.length === 0) {
        await this.dropdownService.LoadListAsync([ListNames.WorkTypeTemplateList]);
      }
      if (this.dropdownService.mainWorkTypeStatusList.length === 0) {
        await this.dropdownService.LoadListAsync([ListNames.MainWorkTypeStatusList]);
      }
      if (this.dropdownService.colorList.length === 0) {
        await this.dropdownService.LoadListAsync([ListNames.ColorList]);
      }
      if (this.dropdownService.localizationList.length === 0) {
        await this.dropdownService.LoadListAsync([ListNames.LocalizationList]);
      }
      if (this.dropdownService.metalList.length === 0) {
        await this.dropdownService.LoadListAsync([ListNames.MetalList]);
      }
      if (this.dropdownService.clinicMaterialList.length === 0) {
        await this.dropdownService.LoadListAsync([ListNames.ClinicMaterialList]);
      }
      if (this.dropdownService.articleList.length === 0) {
        await this.dropdownService.LoadListAsync([ListNames.ArticleList]);
      }
      if (this.dropdownService.productList.length === 0) {
        await this.dropdownService.LoadListAsync([ListNames.ProductList]);
      }
      if (this.dropdownService.externalCenterList.length === 0) {
        await this.dropdownService.LoadListAsync([ListNames.ExternalCenterList]);
      }
      if (this.dropdownService.shippingFormList.length === 0) {
        await this.dropdownService.LoadListAsync([ListNames.ShippingFormList]);
      }
      if (this.dropdownService.ivaTypeList.length === 0) {
        await this.dropdownService.LoadListAsync([ListNames.IvaTypeList]);
      }

      this.templateList = this.dropdownService.workTypeTemplateList
        .filter(wt => wt.status === true).map(wt => ({
          id: wt.id,
          description: wt.description,
          isImplant: wt.isImplant,
          isExternal: wt.externalConfiguration
        }));

      this.mwtStatusList = this.dropdownService.mainWorkTypeStatusList
        .filter(s => s.status === true).map(s => ({ id: s.id, description: s.description }));

      this.colorList = this.dropdownService
        .colorList.map(c => ({ id: c.id, description: c.description }));

      this.localizationList = this.dropdownService
        .localizationList.map(l => ({ id: l.id, description: l.description }));

      this.metalList = this.dropdownService.metalList
        .filter(x => x.status == true).map(m => ({ id: m.id, description: m.description }));

      this.clinicMaterialList = this.dropdownService.clinicMaterialList != null && this.dropdownService.clinicMaterialList.length > 0?
      this.dropdownService.clinicMaterialList.map(c => ({ id: c.id, description: c.description })) : [];

      this.articleList = this.dropdownService.articleList
        .filter(a => a.isActive === true).map(a => ({ id: a.id, description: a.description1 }));

      this.productList = this.dropdownService.productList
        .filter(p => p.status === true).map(p => ({ id: p.id, description: p.name }));

      this.shippingMethodList = this.dropdownService.shippingFormList
        .filter(s => s.status === true).map(s => ({ id: s.id, description: s.description }));

      this.externalCenterList = this.dropdownService.externalCenterList
        .filter(e => e.status === true)
        .map(e => ({ id: e.id, description: e.name, default: e.isDefault }));

      this.clientMetalList = await this._clientService
        .GetClientMetalListByClientId(Number(this.selectedWork.clientId));

      await this.SetDefaults();

    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  private async SetDefaults() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      const nextNo = await this._externalWorkService.GetNextOutSourceNo();
      this.nextOutSourceNo = nextNo ? nextNo.toString() : '';

      const defaultStatus = this.mwtStatusList.find(s => s.description === 'Pendiente');
      this.defaultMwtStatusId = defaultStatus ? defaultStatus.id.toString() : '0';

      this.mwtFormGroup.get('workTypeFormGroup.mwtStatusId').setValue(this.defaultMwtStatusId);

      // separate formgroup for novasearch
      if (this.mwt != null && this.mwt.isReservation) {
        this.workTypeFormGroup.controls.templateName
          .setValue(this.mwt.workTypeName ? this.mwt.workTypeName : '');
        this.workTypeFormGroup.controls.templateId
          .setValue(this.mwt.workTypeId ? this.mwt.workTypeId : '');
        this.mwtFormGroup.get('workTypeFormGroup.deliveryTime')
          .setValue(this.mwt.deliveryTime ? this.mwt.deliveryTime : '');
        this.articleElement.nativeElement.click();
        await this.SetWorkTypeTemplate({
          id: this.mwt.workTypeId,
          description: this.mwt.workTypeName
        });
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  //#region Form

  private InitFormGroup() {
    this.mwtFormGroup = this._formBuilder.group({
      workTypeFormGroup: this.GetWorkTypeFormGroup(),
      externalFormGroup: this.GetExternalFormGroup(),
      implantDataFormGroup: this.GetImplantDataFormGroup(),
      colorFormArray: this._formBuilder.array([]),
      clinicMaterialFormArray: this._formBuilder.array([]),
      documentFormArray: this._formBuilder.array([]),
      prescriptionFormArray: this._formBuilder.array([]),
      searchArticleName: this.searchArticleName,
      articleFormArray: this._formBuilder.array([]),
      searchProductName: this.searchProductName,
      productFormArray: this._formBuilder.array([]),
      metalFormArray: this._formBuilder.array([])
    });
  }

  private GetWorkTypeFormGroup() {
    try {
      const group = {
        templateName: ['', Validators.required],
        templateId: [0, Validators.required],
        mwtNo: [this.GenerateMwtNo()],
        mwtStatusId: ['0', ValidateNgSelect],
        receivedDate: [this.GetCurrentDate()],
        deliveryDate: [null],
        deliveryTime: [''],
        actualDeliveryDate: [null],
        actualDeliveryTime: [''],
        isExternal: [false],
        clientObservations: [''],
        labObservations: [''],
        generalObservations: [''],
        bateaNo: [''],
        metalText: ['']
      };
      return this._formBuilder.group(group);
    } catch (error) { console.log(error); }
  }

  private GetExternalFormGroup() {
    try {
      const group = {
        externalCenterId: [null],
        externalCenterName: [''],
        statusId: ['0'],
        shippingMethodId: ['0'],
        requestDate: [null, ValidateDatePicker],
        outSourcedNo: [''],
        deliveryDate: [null],
        deliveryTime: [''],
        observations: [''],
        deliveryNoteNo: [''],
        attachmentFormArray: this._formBuilder.array([]),
        total: []
      };
      return this._formBuilder.group(group);
    } catch (error) { console.log(error); }
  }

  private GetImplantDataFormGroup() {
    try {
      const group = {
        brand: ['', Validators.required],
        reference: ['', Validators.required],
        apical: [''],
        note: [''],
        mwtId: [null],
      };
      return this._formBuilder.group(group);
    } catch (error) { console.log(error); }
  }

  private GetColorFormGroup(init?: ColorFormModel) {
    try {
      let group = {
        mwtId: [null],
        colorId: [null],
        colorText: [''],
        localizationId: [null],
        localizationText: [''],
        metalId: [null],
        metalText: [''],
        pieces: ['', Validators.pattern('^[1-9]{2,2}(,[1-9]{2,2})*$')],
        articleId: [null],
        metalArticleId: [null]
      };
      if (init) {
        group = {
          mwtId: [init.mwtId],
          colorId: [init.colorId],
          colorText: [init.colorText],
          localizationId: [init.localizationId],
          localizationText: [init.localizationText],
          metalId: [init.metalId],
          metalText: [init.metalText],
          pieces: [init.pieces],
          articleId: [init.articleId],
          metalArticleId: [init.metalArticleId]
        };
      }
      return this._formBuilder.group(group);
    } catch (error) { console.log(error); }
  }

  private GetClinicMaterialFormGroup(init?: ClinicMaterialFormModel) {
    try {
      let group = {
        mwtId: [null],
        clinicMaterialId: [0, Validators.required],
        quantity: [1]
      };
      if (init) {
        group = {
          mwtId: [init.mwtId],
          clinicMaterialId: [init.clinicMaterialId, Validators.required],
          quantity: [init.quantity]
        };
      }
      return this._formBuilder.group(group);
    } catch (error) { console.log(error); }
  }

  private GetDocumentFormGroup(init?: DocumentFormModel) {
    try {
      let group = {
        mwtId: [null],
        name: [''],
        file: []
      };
      if (init) {
        group = {
          mwtId: [init.mwtId],
          name: [init.name],
          file: [init.file]
        };
      }
      return this._formBuilder.group(group);
    } catch (error) { console.log(error); }
  }

  private GetExternalAttachmentFormGroup(init?: ExternalAttachmentFormModel) {
    try {
      let group = {
        mwtExternalId: [null],
        name: [],
        file: []
      };
      if (init) {
        group = {
          mwtExternalId: [init.mwtExternalId],
          name: [init.name],
          file: [init.file]
        };
      }
      return this._formBuilder.group(group);
    } catch (error) { console.log(error); }
  }

  private GetPrescriptionFormGroup(init?: PrescriptionFormModel) {
    try {
      let group = {
        subWorkId: [this.selectedSubWork ? this.selectedSubWork.id : null],
        name: [''],
        file: []
      };
      if (init) {
        group = {
          subWorkId: [this.selectedSubWork ? this.selectedSubWork.id : null],
          name: [init.name],
          file: [init.file]
        };
      }
      return this._formBuilder.group(group);
    } catch (error) { console.log(error); }
  }

  private GetArticleFormGroup(init?: ArticleFormModel) {
    try {
      let group = {
        mwtId: [null],
        articleId: [0, Validators.required],
        articleName: ['', Validators.required],
        price: [],
        quantity: [],
        waste: [],
        discount: [],
        discountAmount: [],
        finalPrice: [],
        workTypeArticleId: [],
        ivaTypeId: [0],
        ivaPercentage: [],
        ivaAmount: [],
        quantityWithWaste: [],
        priceWithDiscount: [],
        lineDiscountAmount: [],
        lineSubTotal: []
      };
      if (init) {
        group = {
          mwtId: [init.mwtId],
          articleId: [init.articleId, Validators.required],
          articleName: [init.articleName, Validators.required],
          price: [init.price],
          quantity: [init.quantity],
          discount: [init.discount],
          waste: [init.waste],
          discountAmount: [init.discountAmount],
          finalPrice: [init.finalPrice],
          workTypeArticleId: [init.workTypeArticleId],
          ivaTypeId: [init.ivaTypeId],
          ivaPercentage: [init.ivaPercentage],
          ivaAmount: [init.ivaAmount],
          quantityWithWaste: [init.quantityWithWaste],
          priceWithDiscount: [init.priceWithDiscount],
          lineDiscountAmount: [init.lineDiscountAmount],
          lineSubTotal: [init.lineSubTotal]
        };
      }
      return this._formBuilder.group(group);
    } catch (error) { console.log(error); }
  }

  private GetProductFormGroup(init?: ProductFormModel) {
    try {
      let group = {
        articleId: [0],
        mwtId: [null],
        productId: [0, Validators.required],
        productName: ['', Validators.required],
        quantity: []
      };
      if (init) {
        group = {
          articleId: [init.articleId],
          mwtId: [init.mwtId],
          productId: [init.productId, Validators.required],
          productName: [init.productName, Validators.required],
          quantity: [init.quantity]
        };
      }
      return this._formBuilder.group(group);
    } catch (error) { console.log(error); }
  }

  private GetMetalFormGroup(init?: MetalFormModel) {
    try {
      let group = {
        mwtId: [null],
        metalId: [null],
        metalText: [''],
      };
      if (init) {
        group = {
          mwtId: [init.mwtId],
          metalId: [init.metalId],
          metalText: [init.metalText]
        };
      }
      return this._formBuilder.group(group);
    } catch (error) { console.log(error); }
  }

  private AddColorFormGroup(init?: ColorFormModel) {
    try {
      const colorGroups = this.mwtFormGroup.controls.colorFormArray as FormArray;
      colorGroups.push(this.GetColorFormGroup(init ? init : undefined));
    } catch (error) { console.log(error); }
  }

  private AddClinicMaterialFormGroup(init?: ClinicMaterialFormModel) {
    try {
      const clinicMaterialGroups = this.mwtFormGroup.controls.clinicMaterialFormArray as FormArray;
      clinicMaterialGroups.push(this.GetClinicMaterialFormGroup(init ? init : undefined));
    } catch (error) { console.log(error); }
  }

  private AddArticleFormGroup(init?: ArticleFormModel) {
    try {
      const articleGroups = this.mwtFormGroup.controls.articleFormArray as FormArray;
      articleGroups.push(this.GetArticleFormGroup(init ? init : undefined));
    } catch (error) { console.log(error); }
  }

  private AddProductFormGroup(init?: ProductFormModel) {
    try {
      const productGroups = this.mwtFormGroup.controls.productFormArray as FormArray;
      productGroups.push(this.GetProductFormGroup(init ? init : undefined));
    } catch (error) { console.log(error); }
  }

  private AddDocumentFormGroup(init?: DocumentFormModel) {
    try {
      const documentGroups = this.mwtFormGroup.controls.documentFormArray as FormArray;
      documentGroups.push(this.GetDocumentFormGroup(init ? init : undefined));
    } catch (error) { console.log(error); }
  }

  private AddPrescriptionFormGroup(init?: PrescriptionFormModel) {
    try {
      const prescriptionGroups = this.mwtFormGroup.controls.prescriptionFormArray as FormArray;
      prescriptionGroups.push(this.GetPrescriptionFormGroup(init ? init : undefined));
    } catch (error) { console.log(error); }
  }

  private AddExternalAttachmentFormGroup(init?: ExternalAttachmentFormModel) {
    try {
      const attachmentGroups = this.mwtFormGroup.get('externalFormGroup.attachmentFormArray') as FormArray;
      attachmentGroups.push(this.GetExternalAttachmentFormGroup(init ? init : undefined));
    } catch (error) { console.log(error); }
  }

  private AddMetalFormGroup(init?: MetalFormModel) {
    try {
      const metalGroups = this.mwtFormGroup.controls.metalFormArray as FormArray;
      metalGroups.push(this.GetMetalFormGroup(init ? init : undefined));
    } catch (error) { console.log(error); }
  }

  //#endregion

  OnAddColorGroup() {
    this.AddColorFormGroup(new ColorFormModel({
      colorId: null,
      colorText: '',
      localizationId: null,
      localizationText: '',
      metalId: null,
      metalText: '',
      pieces: '',
      mwtId: null,
      articleId: null,
      metalArticleId: null
    }));
  }

  OnAddClinicMaterialGroup() {
    this.AddClinicMaterialFormGroup(new ClinicMaterialFormModel({
      mwtId: null,
      clinicMaterialId: 0,
      quantity: 1
    }));
  }

  OnAddArticleGroup() {
    this.AddArticleFormGroup(new ArticleFormModel({
      mwtId: null,
      articleId: null,
      articleName: '',
      discount: 0,
      price: 0,
      discountAmount: 0,
      waste: 0,
      finalPrice: 0,
      quantity: 0,
      ivaTypeId: 0,
      ivaPercentage: 0,
      ivaAmount: 0,
      priceWithDiscount: 0,
      quantityWithWaste: 0,
      lineDiscountAmount: 0,
      lineSubTotal: 0
    }));
  }

  OnAddMetalGroup() {
    this.AddMetalFormGroup(new MetalFormModel({
      mwtId: null,
      metalId: null,
      metalText: ''
    }));
  }

  OnAddProductGroup() {
    this.AddProductFormGroup(new ProductFormModel({
      mwtId: null,
      productId: null,
      productName: '',
      quantity: 0
    }));
  }

  private GenerateMwtNo() {
    const now = moment().format('YYYY-MM-DD HH:mm:ss');
    const dateTimeArr = now.split(' ');
    const dateArr = dateTimeArr[0].split('-');
    const timeArr = dateTimeArr[1].split(':');
    return dateArr[2] + dateArr[1] + dateArr[0] + timeArr[0] + timeArr[1] + timeArr[2];
  }

  GetCurrentDate() {
    return this._commonUiService
      .ConvertToPickerDateFormat(moment().format('YYYY-MM-DD'));
  }

  async SetWorkTypeTemplate(template: { id: number, description: string }, isEmpty = false) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      this.tempProductTemplateList = null;
      const group = this.mwtFormGroup.controls.workTypeFormGroup as FormGroup;
      let wtTemplate: { id: number, description: string, isImplant: boolean, isExternal: boolean };

      CommonFunctions.ClearFormArray(this.mwtFormGroup.get('articleFormArray') as FormArray);
      CommonFunctions.ClearFormArray(this.mwtFormGroup.get('productFormArray') as FormArray);
      CommonFunctions.ClearFormArray(this.mwtFormGroup.get('colorFormArray') as FormArray);
      CommonFunctions.ClearFormArray(this.mwtFormGroup.get('metalFormArray') as FormArray);

      if (!isEmpty) {
        wtTemplate = this.templateList.find(temp => temp.id === template.id);

        if (wtTemplate) {
          this.showIsExternalCheck = wtTemplate.isExternal;
          this.showImplantPanel = wtTemplate.isImplant;

          if (this.showIsExternalCheck) { group.controls.isExternal.setValue(false); }

          const includings = await this._workService
            .GetPhasesTasksArticlesProductsByTemplateId(wtTemplate.id);
          if (includings) {
            if (includings.workTypeArticleList && includings.workTypeArticleList.length > 0) {
              let productTemplateList: WorkTypeProductTemplate[] = [];
              const mwtArticleList = await this
                .GetMwtArticlePriceList(this.selectedWork.clientId,
                  includings.workTypeArticleList
                    .map(x => ({
                      id: x.articleId,
                      description: x.articleName,
                      quantity: x.quantity,
                      workTypeArticleId: x.workTypeArticleId
                    })));

              if (mwtArticleList) {
                for (let mwtArtIndex = 0; mwtArtIndex < mwtArticleList.length; mwtArtIndex++) {
                  const art = mwtArticleList[mwtArtIndex];
                  this.AddArticleFormGroup(new ArticleFormModel({
                    mwtId: null,
                    articleId: art.articleId,
                    workTypeArticleId: art.workTypeArticleId,
                    articleName: art.description1,
                    price: art.price ? art.price : 0,
                    quantity: Number(art.quantity),
                    discount: art.discount,
                    waste: art.waste,
                    discountAmount: art.discountAmount ? art.discountAmount : 0,
                    finalPrice: art.finalPrice ? Number(art.finalPrice) : 0,
                    ivaTypeId: art.ivaTypeId ? art.ivaTypeId : 0,
                    ivaPercentage: art.ivaPercentage,
                    ivaAmount: art.ivaAmount,
                    quantityWithWaste: art.quantityWithWaste ? art.quantityWithWaste : 0,
                    priceWithDiscount: art.priceWithDiscount ? art.priceWithDiscount : 0,
                    lineDiscountAmount: art.lineDiscountAmount ? art.lineDiscountAmount : 0,
                    lineSubTotal: art.lineSubTotal ? art.lineSubTotal : 0
                  }));
                }
              }

              for (let artIndex = 0; artIndex < includings.workTypeArticleList.length; artIndex++) {
                const artTemplate = includings.workTypeArticleList[artIndex];

                for (let proIndex = 0; proIndex < artTemplate.productList.length; proIndex++) {
                  artTemplate.productList[proIndex].articleId = artTemplate.articleId;
                }

                if (artTemplate) {
                  if (artTemplate.productList) {
                    productTemplateList = productTemplateList.concat(artTemplate.productList);
                  }
                }
              }

              this.tempProductTemplateList = null;
              this.tempProductTemplateList = JSON.stringify(productTemplateList);

              var productIds = [];
              for (let proIndex = 0; proIndex < productTemplateList.length; proIndex++) {
                const proTemplate = productTemplateList[proIndex];
                if (!productIds.includes(proTemplate.productId)) {
                  if (proTemplate) {
                    let art = includings.workTypeArticleList.filter(a => a.articleId == proTemplate.articleId);
                    let artQty = art && art[0].quantity ? art[0].quantity : 1;

                    this.AddProductFormGroup(new ProductFormModel({
                      mwtId: null,
                      productId: proTemplate.productId,
                      productName: proTemplate.productName,
                      quantity: proTemplate.quantity ? proTemplate.quantity * artQty : 1 * artQty,
                      articleId: proTemplate.articleId
                    }));
                  }
                } else {
                  // if product exist without duplicating the record, update the quantity
                  var products = this.mwtFormGroup.get('productFormArray') as FormArray;
                  var existingProduct = products.value.filter(x => x.productId == proTemplate.productId);
                  if (existingProduct && existingProduct.length !== 0) {
                    let art = includings.workTypeArticleList.filter(a => a.articleId == proTemplate.articleId);
                    let artQty = art && art[0].quantity ? art[0].quantity : 1;

                    var currentQty = proTemplate.quantity ? proTemplate.quantity * artQty : 1 * artQty;
                    var quantity = existingProduct[0].quantity + currentQty;
                    const index = products.value.findIndex(x => x.productId == proTemplate.productId);
                    products.at(index).setValue(
                      new ProductFormModel({
                        mwtId: null,
                        productId: existingProduct[0].productId,
                        productName: existingProduct[0].productName,
                        quantity: quantity,
                        articleId: proTemplate.articleId
                      })
                    );
                  }
                }
                productIds.push(proTemplate.productId);
              }
            }
          }
          // add client meatal or worktype metal to panel
          if (this.clientMetalList.length > 0) {
            this.LoadMetelsAndArticles(false);
          } else {
            const mwt = this.dropdownService.workTypeTemplateList.find(x => x.id == wtTemplate.id);
            if (mwt) {
              if (mwt.metalId > 0 && mwt.isMetalUsed) {
                this.LoadMetelsAndArticles(true, mwt.metalId);
              }
            }
          }
        }
      }
      group.controls.templateName.setValue(wtTemplate ? wtTemplate.description : '');
      group.controls.templateId.setValue(wtTemplate ? wtTemplate.id : null);
      this.showExternalPanel = false;
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  OnChangeExternal() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      this.showExternalPanel = !this.showExternalPanel;
      if (this.showExternalPanel) {

        this.defaultExternalCenter = this.externalCenterList.find(e => e.default);
        const group = this.mwtFormGroup.controls.externalFormGroup as FormGroup;

        this.externalFormGroup.controls.externalCenterName
          .setValue(this.defaultExternalCenter ? this.defaultExternalCenter.description : '');

        this.externalFormGroup.controls.externalCenterId
          .setValue(this.defaultExternalCenter ? this.defaultExternalCenter.id : null);

        group.controls.externalCenterName.setValue(this.defaultExternalCenter ?
          this.defaultExternalCenter.description : '');
        group.controls.externalCenterId.setValue(this.defaultExternalCenter ?
          this.defaultExternalCenter.id : null);

        group.controls.outSourcedNo.setValue(this.nextOutSourceNo ? this.nextOutSourceNo : '');

        group.controls.requestDate.setValue(this.GetCurrentDate());

        group.controls.statusId.setValue('1');
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  SetExternalCenter(externalCenter: { id: number, description: string, default: boolean }, isEmpty = false) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      const group = this.mwtFormGroup.controls.externalFormGroup as FormGroup;
      let exCenter: { id: number, description: string, default: boolean };
      if (!isEmpty) {
        exCenter = this.externalCenterList.find(ex => ex.id === externalCenter.id);
      }
      group.controls.externalCenterName.setValue(exCenter ? exCenter.description : '');
      group.controls.externalCenterId.setValue(exCenter ? exCenter.id : null);
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  SetColor(color: { id: number, description: string }, group: FormGroup, isEmpty = false) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      let colorObj: { id: number, description: string };
      if (!isEmpty) {
        colorObj = this.colorList.find(c => c.id === color.id);
      }
      group.controls.colorText.setValue(colorObj ? colorObj.description : '');
      group.controls.colorId.setValue(colorObj ? colorObj.id : null);
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  SetLocalization(localization: { id: number, description: string }, group: FormGroup, isEmpty = false) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      let local: { id: number, description: string };
      if (!isEmpty) {
        local = this.localizationList.find(l => l.id === localization.id);
      }
      group.controls.localizationText.setValue(local ? local.description : '');
      group.controls.localizationId.setValue(local ? local.id : null);
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  SetMetal(metal: { id: number, description: string }, group: FormGroup, index: number, isEmpty = false) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      let colorFormArray = [];
      const formArray = this.mwtFormGroup.get('colorFormArray').value;
      colorFormArray = formArray ? formArray : [];
      let colorForm: any;
      if (colorFormArray) {
        colorForm = colorFormArray[index];
      }
      let metalObj: { id: number, description: string };
      if (!isEmpty) {
        metalObj = this.metalList.find(m => m.id === metal.id);
      }
      group.controls.metalText.setValue(metalObj ? metalObj.description : '');
      group.controls.metalId.setValue(metalObj ? metalObj.id : null);
      if (metal) {
        const metalData = this.dropdownService.metalList
          .filter(o => o.status == true && o.id === metal.id)[0];
        if (metalData.articleId > 0) {
          const article = this.articleList.find(a => a.id == Number(metalData.articleId));
          group.controls.metalArticleId.setValue(Number(metalData.articleId));
          if (Number(colorForm.metalId) != metal.id) {
            this.RemoveMetalArticle(colorForm);
            if (article) { this.OnSearchArticle(article); }
          }
        } else {
          if (Number(colorForm.metalId) != metal.id) { this.RemoveMetalArticle(colorForm); }
        }
      } else {
        if (colorForm && Number(colorForm.metalId) > 0 && Number(colorForm.metalArticleId) > 0) {
          this.RemoveMetalArticle(colorForm);
        }
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  RemoveMetalArticle(colorForm: any) {
    if (colorForm && Number(colorForm.metalId) > 0 && Number(colorForm.metalArticleId) > 0) {
      const articleArray = this.mwtFormGroup.controls.articleFormArray as FormArray;
      const index = articleArray.controls.findIndex(x => x.value.articleId
        === Number(colorForm.metalArticleId));
      if (index != -1) {
        this.mwtFormGroup.get('articleFormArray').value.forEach(async element => {
          if (element.articleId === Number(colorForm.metalArticleId)) {
            const article = element;
            articleArray.removeAt(index);
            if (article.quantity > 1) {
              const workTypeGroup = this.mwtFormGroup.controls.workTypeFormGroup as FormGroup;
              const templateId = workTypeGroup.controls.templateId.value as number;
              let quantity = 1;
              if (templateId > 0) {
                const qty = await this._articleTemplateService
                  .GetQuantityByWorkTypeArticleId(templateId, article.articleId);
                quantity = qty ? qty : 1;
              }
              article.quantity = (article.quantity - quantity);
              this.OnUpdateArticleArray(article);
            }
          }
        });
      }
    }
  }

  OnUpdateArticleArray(colorForm: any) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      const mwtArticle = colorForm as MwtArticle;

      mwtArticle.quantityWithWaste = this.GetQuantityWithWaste(mwtArticle);
      mwtArticle.priceWithDiscount = this.GetPriceWithDiscount(mwtArticle);
      const finalPriceWithoutIva = this.GetFinalPriceWithoutIva(mwtArticle);

      let ivaAmount = 0;
      if (mwtArticle.ivaTypeId) {
        ivaAmount = this._calculationService
          .getIvaAmount(finalPriceWithoutIva,
            mwtArticle.ivaPercentage ? mwtArticle.ivaPercentage : 0);
        mwtArticle.ivaAmount = toFixedNumber(ivaAmount);
      }
      mwtArticle.finalPrice = toFixedNumber((finalPriceWithoutIva + ivaAmount));

      mwtArticle.discountAmount = this._calculationService
        .getDiscountAmount(mwtArticle.price, mwtArticle.discount);

      mwtArticle.lineSubTotal = this.GetLineSubTotal(mwtArticle.price,
        mwtArticle.quantityWithWaste);

      mwtArticle.lineDiscountAmount = this.GetLineDiscountAmount(mwtArticle.finalPrice,
        ivaAmount, mwtArticle.lineSubTotal);

      this.AddArticleFormGroup(new ArticleFormModel({
        mwtId: null,
        articleId: colorForm.articleId,
        articleName: colorForm.articleName,
        price: mwtArticle.price ? toFixedNumber(mwtArticle.price) : 0,
        quantity: toFixedNumber(mwtArticle.quantity),
        discount: toFixedNumber(mwtArticle.discount),
        waste: toFixedNumber(mwtArticle.waste),
        discountAmount: toFixedNumber(mwtArticle.discountAmount),
        finalPrice: mwtArticle.finalPrice ? toFixedNumber(mwtArticle.finalPrice) : 0,
        ivaTypeId: mwtArticle.ivaTypeId ? mwtArticle.ivaTypeId : 0,
        ivaPercentage: mwtArticle.ivaPercentage ? mwtArticle.ivaPercentage : 0,
        ivaAmount: mwtArticle.ivaAmount ? mwtArticle.ivaAmount : 0,
        quantityWithWaste: mwtArticle.quantityWithWaste ? mwtArticle.quantityWithWaste : 0,
        priceWithDiscount: mwtArticle.priceWithDiscount ? mwtArticle.priceWithDiscount : 0,
        lineDiscountAmount: mwtArticle.lineDiscountAmount ? mwtArticle.lineDiscountAmount : 0,
        lineSubTotal: mwtArticle.lineSubTotal ? mwtArticle.lineSubTotal : 0
      }));
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  OnTypePiezasNumber(group: FormGroup) {
    const pieces = CommonFunctions.ValidatePiezasNumber(group.controls.pieces.value);
    group.controls.pieces.setValue(pieces);
  }

  OnRemoveMwtColor(index: number) {
    this._commonUiService.isSpinnerVisible = true;
    const formArray = this.mwtFormGroup.controls.colorFormArray as FormArray;
    if (formArray) {
      const list = formArray.getRawValue();
      const color = list[index];
      formArray.removeAt(index);
      if (color) { this.RemoveMetalArticle(color); }
    }
    this._commonUiService.isSpinnerVisible = false;
  }

  OnRemoveMwtClinicMaterial(index: number) {
    this._commonUiService.isSpinnerVisible = true;
    const formArray = this.mwtFormGroup.controls.clinicMaterialFormArray as FormArray;
    if (formArray) { formArray.removeAt(index); }
    this._commonUiService.isSpinnerVisible = false;
  }

  async OnSearchArticle(article: { id: number, description: string }, isEmpty = false) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      const control = this.mwtFormGroup.controls.searchArticleName as FormControl;
      if (!isEmpty) {
        if (article) {
          const art = this.dropdownService.articleList.find(a => a.id === article.id && a.isActive == true);
          if (art) {
            if (this.selectedWork && this.selectedWork.clientId) {
              const mwtArticle = await this.GetMwtArticle(this.selectedWork.clientId,
                { id: art.id, description: art.description1 });
              const formArray = this.mwtFormGroup.controls.articleFormArray as FormArray;
              const index = formArray.controls.findIndex(x => x.value.articleId === mwtArticle.articleId);
              const articleArray = this.mwtFormGroup.get('articleFormArray') as FormArray;
              let existingArticle = articleArray.value.filter(x => x.articleId === mwtArticle.articleId);

              if (index != -1 && existingArticle && existingArticle.length > 0) {
                existingArticle[0].quantity = existingArticle[0].quantity + 1;
                existingArticle[0].quantityWithWaste = this.GetQuantityWithWaste(existingArticle[0]);
                existingArticle[0].priceWithDiscount = this.GetPriceWithDiscount(existingArticle[0]);
                const finalPriceWithoutIva = this.GetFinalPriceWithoutIva(existingArticle[0]);

                let ivaAmount = 0;
                if (existingArticle[0].ivaTypeId) {
                  ivaAmount = this._calculationService
                    .getIvaAmount(finalPriceWithoutIva,
                      existingArticle[0].ivaPercentage ? existingArticle[0].ivaPercentage : 0);
                  existingArticle[0].ivaAmount = toFixedNumber(ivaAmount);
                }
                existingArticle[0].finalPrice = toFixedNumber(finalPriceWithoutIva + ivaAmount);

                existingArticle[0].discountAmount = this._calculationService
                  .getDiscountAmount(existingArticle[0].price, existingArticle[0].discount);

                existingArticle[0].lineSubTotal = this.GetLineSubTotal(existingArticle[0].price,
                  existingArticle[0].quantityWithWaste);

                existingArticle[0].lineDiscountAmount = this.GetLineDiscountAmount(existingArticle[0].finalPrice,
                  ivaAmount, existingArticle[0].lineSubTotal);

                articleArray.at(index).setValue(
                  new ArticleFormModel({
                    mwtId: null,
                    workTypeArticleId: existingArticle[0].workTypeArticleId,
                    articleId: art.id,
                    articleName: art.description1,
                    price: existingArticle[0].price ? existingArticle[0].price : 0,
                    quantity: existingArticle[0].quantity,
                    discount: existingArticle[0].discount,
                    waste: existingArticle[0].waste,
                    discountAmount: existingArticle[0].discountAmount,
                    finalPrice: existingArticle[0].finalPrice ? Number((existingArticle[0].finalPrice)) : 0,
                    ivaTypeId: existingArticle[0].ivaTypeId ? existingArticle[0].ivaTypeId : 0,
                    ivaPercentage: existingArticle[0].ivaPercentage ? existingArticle[0].ivaPercentage : 0,
                    ivaAmount: existingArticle[0].ivaAmount ? existingArticle[0].ivaAmount : 0,
                    priceWithDiscount: existingArticle[0].priceWithDiscount ?
                      existingArticle[0].priceWithDiscount : 0,
                    quantityWithWaste: existingArticle[0].quantityWithWaste ?
                      existingArticle[0].quantityWithWaste : 0,
                    lineDiscountAmount: existingArticle[0].lineDiscountAmount ?
                      existingArticle[0].lineDiscountAmount : 0,
                    lineSubTotal: existingArticle[0].lineSubTotal ?
                      existingArticle[0].lineSubTotal : 0
                  })
                );
                this.RecalculateProductQty();
              }
              else {
                this.AddArticleFormGroup(new ArticleFormModel({
                  mwtId: null,
                  articleId: art.id,
                  articleName: art.description1,
                  price: mwtArticle.price ? mwtArticle.price : 0,
                  quantity: Number(mwtArticle.quantity),
                  discount: mwtArticle.discount,
                  waste: mwtArticle.waste,
                  discountAmount: mwtArticle.discountAmount,
                  finalPrice: mwtArticle.finalPrice ? Number(mwtArticle.finalPrice) : 0,
                  ivaTypeId: mwtArticle.ivaTypeId ? mwtArticle.ivaTypeId : 0,
                  ivaPercentage: mwtArticle.ivaPercentage ? mwtArticle.ivaPercentage : 0,
                  ivaAmount: mwtArticle.ivaAmount ? mwtArticle.ivaAmount : 0,
                  quantityWithWaste: mwtArticle.quantityWithWaste ? mwtArticle.quantityWithWaste : 0,
                  priceWithDiscount: mwtArticle.priceWithDiscount ? mwtArticle.priceWithDiscount : 0,
                  lineDiscountAmount: mwtArticle.lineDiscountAmount ? mwtArticle.lineDiscountAmount : 0,
                  lineSubTotal: mwtArticle.lineSubTotal ? mwtArticle.lineSubTotal : 0
                }));
              }
            }
          }
        }
      }
      control.setValue('');
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  async SetMwtArticle(article: { id: number, description: string }, group: FormGroup, isEmpty = false) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (!isEmpty) {
        const art = this.dropdownService.articleList.find(a => a.id === article.id);
        if (art) {
          if (this.selectedWork.clientId) {
            const mwtArticle = await this.GetMwtArticle(this.selectedWork.clientId,
              { id: art.id, description: art.description1 });
            const formArray = this.mwtFormGroup.controls.articleFormArray as FormArray;
            const index = formArray.controls.findIndex(x => x.value.articleId === mwtArticle.articleId);
            if (index == -1) {
              group.setValue(new ArticleFormModel({
                mwtId: null,
                articleId: art.id,
                articleName: art.description1,
                price: mwtArticle.price ? mwtArticle.price : 0,
                quantity: Number(mwtArticle.quantity),
                discount: mwtArticle.discount,
                waste: 0,
                discountAmount: mwtArticle.discountAmount ? mwtArticle.discountAmount : 0,
                finalPrice: mwtArticle.finalPrice ?
                  toFixedNumber(mwtArticle.finalPrice) : 0,
                quantityWithWaste: mwtArticle.quantityWithWaste ?
                  toFixedNumber(mwtArticle.quantityWithWaste) : 0,
                priceWithDiscount: mwtArticle.priceWithDiscount ?
                  toFixedNumber(mwtArticle.priceWithDiscount) : 0,
                lineDiscountAmount: mwtArticle.lineDiscountAmount ?
                  toFixedNumber(mwtArticle.lineDiscountAmount) : 0,
                lineSubTotal: mwtArticle.lineSubTotal ?
                  toFixedNumber(mwtArticle.lineSubTotal) : 0
              }));
              this.RecalculateProductQty();
            }
            this.articleElement.nativeElement.click();
          }
        }
      } else { group.reset(); }
    } catch (error) { console.log(error); } finally {
      this.articleElement.nativeElement.click();
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  private async GetMwtArticle(clientId: number, article: { id: number, description: string }) {
    try {
      const mwtArticle = await this._articleTemplateService
        .GetMwtArticlePriceDetails(article.id, clientId);
      if (mwtArticle) {
        mwtArticle.articleId = article.id;
        mwtArticle.description1 = article.description;
        const workTypeGroup = this.mwtFormGroup.controls.workTypeFormGroup as FormGroup;
        const templateId = workTypeGroup.controls.templateId.value as number;
        if (templateId > 0) {
          const quantity = await this._articleTemplateService
            .GetQuantityByWorkTypeArticleId(templateId, Number(article.id));
          mwtArticle.quantity = quantity ? quantity : 1;
        } else { mwtArticle.quantity = 1; }
        mwtArticle.quantityWithWaste = this.GetQuantityWithWaste(mwtArticle);
        mwtArticle.priceWithDiscount = this.GetPriceWithDiscount(mwtArticle);

        const finalPriceWithoutIva = this.GetFinalPriceWithoutIva(mwtArticle);
        let ivaAmount = 0;
        if (mwtArticle.ivaTypeId) {
          ivaAmount = this._calculationService
            .getIvaAmount(finalPriceWithoutIva,
              mwtArticle.ivaPercentage ? toFixedNumber(mwtArticle.ivaPercentage) : 0);
          mwtArticle.ivaAmount = toFixedNumber(ivaAmount);
        }
        mwtArticle.finalPrice = toFixedNumber(finalPriceWithoutIva + ivaAmount);

        mwtArticle.discountAmount = this._calculationService
          .getDiscountAmount(mwtArticle.price, mwtArticle.discount);

        mwtArticle.lineSubTotal = this.GetLineSubTotal(mwtArticle.price,
          mwtArticle.quantityWithWaste);

        mwtArticle.lineDiscountAmount = this.GetLineDiscountAmount(mwtArticle.finalPrice,
          ivaAmount, mwtArticle.lineSubTotal);

        return mwtArticle;
      }
    } catch (error) { console.log(error); }
  }

  private async GetMwtArticlePriceList(clientId: number, article: {
    id: number, description: string, quantity: number, workTypeArticleId: number
  }[]) {
    try {
      let paraList: any[] = article.map(a => ({ id: a.id }));
      paraList = paraList ? paraList : [];
      paraList.push({ clientId: clientId });

      const mwtArticleList = await this._articleTemplateService
        .GetMwtArticlePriceDetailList(paraList);

      if (mwtArticleList) {
        for (let index = 0; index < mwtArticleList.length; index++) {
          const mwtArticle = mwtArticleList[index];
          article.forEach(art => {
            if (Number(mwtArticle.articleId) === Number(art.id)) {
              mwtArticle.quantity = Number(art.quantity) > 0 ? art.quantity : 1;
              mwtArticle.workTypeArticleId = art.workTypeArticleId ? art.workTypeArticleId : null
            }
          });
          // mwtArticle.quantity = 1;
          mwtArticle.quantityWithWaste = this.GetQuantityWithWaste(mwtArticle);
          mwtArticle.priceWithDiscount = this.GetPriceWithDiscount(mwtArticle);
          const finalPriceWithoutIva = this.GetFinalPriceWithoutIva(mwtArticle);
          let ivaAmount = 0;
          if (mwtArticle.ivaTypeId) {
            ivaAmount = this._calculationService
              .getIvaAmount(finalPriceWithoutIva, mwtArticle.ivaPercentage ?
                toFixedNumber(mwtArticle.ivaPercentage) : 0);
            mwtArticle.ivaAmount = toFixedNumber(ivaAmount);
          }
          mwtArticle.finalPrice = toFixedNumber(finalPriceWithoutIva + ivaAmount);
          mwtArticle.discountAmount = this._calculationService
            .getDiscountAmount(mwtArticle.price, mwtArticle.discount);

          mwtArticle.lineSubTotal = this.GetLineSubTotal(mwtArticle.price,
            mwtArticle.quantityWithWaste);

          mwtArticle.lineDiscountAmount = this.GetLineDiscountAmount(mwtArticle.finalPrice,
            ivaAmount, mwtArticle.lineSubTotal);
        }
      }
      return mwtArticleList ? mwtArticleList : [];
    } catch (error) { console.log(error); }
  }

  private GetQuantityWithWaste(mwtArticle: MwtArticle) {
    mwtArticle.quantity = Number(mwtArticle.quantity);
    return this._calculationService
      .getQuantityWithWaste(toFixedNumber(mwtArticle.quantity ? mwtArticle.quantity : 0),
        toFixedNumber(mwtArticle.waste ? mwtArticle.waste : 0));
  }

  private GetPriceWithDiscount(mwtArticle: MwtArticle) {
    if (mwtArticle.price !== undefined && mwtArticle.discount !== undefined) {
      return this._calculationService
        .getPriceWithDiscount(toFixedNumber(mwtArticle.price),
          mwtArticle.isAmount ? toFixedNumber(mwtArticle.discountAmount) :
            toFixedNumber(mwtArticle.discount), mwtArticle.isAmount);
    }
  }

  private GetFinalPriceWithoutIva(mwtArticle: MwtArticle) {
    if (mwtArticle.priceWithDiscount !== undefined && mwtArticle.quantityWithWaste !== undefined) {
      return this._calculationService.
        getArticleFinalTotal(toFixedNumber(mwtArticle.quantityWithWaste),
          toFixedNumber(mwtArticle.priceWithDiscount), 0)
    }
  }

  private GetLineDiscountAmount(finalTotal: number, vatAmount: number, subTotal: number) {
    try {
      return toFixedNumber(subTotal - (finalTotal - vatAmount));
    } catch (error) { console.log(error); }
  }

  private GetLineSubTotal(price: number, quantityWithWaste: number) {
    try {
      return this._calculationService
        .getLineSubTotal(price, quantityWithWaste);
    } catch (error) { console.log(error); }
  }

  OnChangeVariables(group: FormGroup, isQtyChange = false) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      const mwtArticle = group.value as MwtArticle;
      mwtArticle.quantityWithWaste = this.GetQuantityWithWaste(mwtArticle);
      mwtArticle.priceWithDiscount = this.GetPriceWithDiscount(mwtArticle);

      const finalPriceWithoutIva = this.GetFinalPriceWithoutIva(mwtArticle);

      let ivaAmount = 0;
      if (mwtArticle.ivaTypeId) {
        ivaAmount = this._calculationService
          .getIvaAmount(finalPriceWithoutIva,
            mwtArticle.ivaPercentage ? toFixedNumber(mwtArticle.ivaPercentage) : 0);
        mwtArticle.ivaAmount = toFixedNumber(ivaAmount);
      }
      mwtArticle.finalPrice = toFixedNumber(finalPriceWithoutIva + ivaAmount);
      mwtArticle.discountAmount = this._calculationService
        .getDiscountAmount(mwtArticle.price, mwtArticle.discount);

      mwtArticle.lineSubTotal = this.GetLineSubTotal(mwtArticle.price,
        mwtArticle.quantityWithWaste);

      mwtArticle.lineDiscountAmount = this.GetLineDiscountAmount(mwtArticle.finalPrice,
        ivaAmount, mwtArticle.lineSubTotal);

      group.controls.price.setValue(mwtArticle.price);
      group.controls.quantity.setValue(Number(mwtArticle.quantity));
      group.controls.discount.setValue(mwtArticle.discount);
      group.controls.discountAmount.setValue(mwtArticle.discountAmount);
      group.controls.ivaTypeId.setValue(mwtArticle.ivaTypeId);
      group.controls.ivaAmount.setValue(mwtArticle.ivaAmount);
      group.controls.ivaPercentage.setValue(mwtArticle.ivaPercentage);
      group.controls.finalPrice.setValue(mwtArticle.finalPrice);
      group.controls.quantityWithWaste.setValue(mwtArticle.quantityWithWaste);
      group.controls.priceWithDiscount.setValue(mwtArticle.priceWithDiscount);
      group.controls.lineDiscountAmount.setValue(mwtArticle.lineDiscountAmount);
      group.controls.lineSubTotal.setValue(mwtArticle.lineSubTotal);

      if (isQtyChange) {
        this.RecalculateProductQty();
      }

    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  RecalculateProductQty() {
    const tempProList = JSON.parse(this.tempProductTemplateList);
    const articleArray = this.mwtFormGroup.get('articleFormArray') as FormArray;
    let products = this.mwtFormGroup.get('productFormArray') as FormArray;

    if (tempProList) {
      for (let proIndex = 0; proIndex < tempProList.length; proIndex++) {
        let existingArticle = articleArray.value.filter(x =>
          x.articleId == tempProList[proIndex].articleId);
        tempProList[proIndex].quantity = existingArticle && existingArticle.length != 0 ?
          tempProList[proIndex].quantity * existingArticle[0].quantity : 0;
      }
      let groupedProducts = _.groupBy(tempProList, "productId");
      for (let key in groupedProducts) {
        if (groupedProducts.hasOwnProperty(key)) {
          let proList = groupedProducts[key];
          let existingProduct = products.value.filter(x => x.productId == proList[0].productId);
          const indexExistingPro = products.value.findIndex(x => x.productId == proList[0].productId);

          if (existingProduct && existingProduct.length !== 0) {
            products.at(indexExistingPro).setValue(
              new ProductFormModel({
                mwtId: null,
                articleId: existingProduct[0].articleId,
                productId: existingProduct[0].productId,
                productName: existingProduct[0].productName,
                quantity: _.sumBy(proList, function (o) { return o.quantity; })
              })
            );
            if (this.productInputVariable) {
              this.productInputVariable.nativeElement.click();
            }
          }
        }
      }

      const formArrayProduct = this.mwtFormGroup.controls.productFormArray as FormArray;
      this.mwtFormGroup.controls.productFormArray.value.forEach(element => {
        if (element.quantity == 0) {
          const _index = this.mwtFormGroup.controls.productFormArray.value.indexOf(element);
          if (formArrayProduct) { formArrayProduct.removeAt(_index); }
        }
      });
    }
  }

  OnChangeIvaType(group: FormGroup) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      const mwtArticle = group.value as MwtArticle;
      if (Number(mwtArticle.ivaTypeId)) {
        const iType = this.dropdownService.ivaTypeList
          .find(it => it.id === Number(mwtArticle.ivaTypeId));
        if (iType) { mwtArticle.ivaPercentage = toFixedNumber(iType.iva); }
      } else { mwtArticle.ivaPercentage = 0; }
      mwtArticle.ivaAmount = 0;
      group.setValue(mwtArticle);
      this.OnChangeVariables(group);
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  OnRemoveMwtArticle(index: number) {
    this._commonUiService.isSpinnerVisible = true;
    const formArray = this.mwtFormGroup.controls.articleFormArray as FormArray;

    if (formArray) {
      formArray.removeAt(index);
      this.RecalculateProductQty();
    }
    this._commonUiService.isSpinnerVisible = false;
  }

  OnSearchProduct(pro: { id: number, description: string }, isEmpty = false) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      const control = this.mwtFormGroup.controls.searchProductName as FormControl;
      var products = this.mwtFormGroup.get('productFormArray') as FormArray;
      if (!isEmpty) {
        const product = this.dropdownService.productList.find(p => p.id === pro.id);
        // Before adding check if already same product exist
        var existingProduct = products.value.filter(x => x.productId == pro.id);
        if (existingProduct && existingProduct.length !== 0) {
          var quantity = existingProduct[0].quantity + 1;
          const index = products.value.findIndex(x => x.productId == pro.id);
          products.at(index).setValue(
            new ProductFormModel({
              mwtId: null,
              productId: product.id,
              productName: product.name,
              quantity: quantity,
              articleId: existingProduct[0].articleId
            })
          );
          control.setValue('');
          this.productInputVariable.nativeElement.click();
          return;
        }

        if (product) {
          this.AddProductFormGroup(new ProductFormModel({
            mwtId: null,
            productId: product.id,
            productName: product.name,
            quantity: 1
          }));
        }
      }
      control.setValue('');
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  SetMwtProduct(pro: { id: number, description: string },
    group: FormGroup, index: number, isEmpty = false,) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (!isEmpty) {
        const product = this.dropdownService.productList.find(p => p.id === pro.id);
        var products = this.mwtFormGroup.get('productFormArray') as FormArray;
        if (product) {
          // check if product exist more than once
          var existingProduct = products.value.filter(x => x.productId == pro.id);
          if (existingProduct == null || existingProduct.length == 0) {
            products.at(index).setValue(
              new ProductFormModel({
                mwtId: null,
                productId: product.id,
                productName: product.name,
                quantity: group.value.quantity != null ? group.value.quantity : 1
              })
            );
          }
        }
      } else { group.reset(); }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  OnRemoveMwtProduct(index: number) {
    this._commonUiService.isSpinnerVisible = true;
    const formArray = this.mwtFormGroup.controls.productFormArray as FormArray;
    if (formArray) { formArray.removeAt(index); }
    this._commonUiService.isSpinnerVisible = false;
  }

  OnFileChange(fileEvent: any, type: 'document' | 'prescription' | 'external-attachments') {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (fileEvent) {
        const fileList = fileEvent.target.files as File[];
        if (fileList) {
          for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            switch (type) {
              case 'document':
                this.AddDocumentFormGroup(new DocumentFormModel({
                  file: file,
                  name: file.name,
                  mwtId: null
                }));
                break;
              case 'prescription':
                this.AddPrescriptionFormGroup(new PrescriptionFormModel({
                  file: file,
                  name: file.name,
                  subWorkId: this.selectedSubWork ? this.selectedSubWork.id : null
                }));
                break;
              case 'external-attachments':
                this.AddExternalAttachmentFormGroup(new ExternalAttachmentFormModel({
                  file: file,
                  name: file.name,
                  mwtExternalId: null
                }));
                break;
              default: break;
            }
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  OnRemoveAttachment(index: number,
    type: 'document' | 'prescription' | 'external-attachments') {
    try {
      this._commonUiService.isSpinnerVisible = true;
      let formArray = new FormArray([]);
      switch (type) {
        case 'document':
          formArray = this.mwtFormGroup.controls.documentFormArray as FormArray;
          break;
        case 'prescription':
          formArray = this.mwtFormGroup.controls.prescriptionFormArray as FormArray;
          break;
        case 'external-attachments':
          formArray = this.mwtFormGroup
            .get('externalFormGroup.attachmentFormArray') as FormArray;
          break;
        default: break;
      }
      if (formArray) { formArray.removeAt(index); }

    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  private Get24HoursTime(timeStr: string) {
    if (!timeStr.includes(':')) {
      return CommonFunctions.Get24HoursTimeFormat(timeStr);
    }
    return timeStr;
  }

  private SetFormValidations() {
    try {
      const externalGroup = this.mwtFormGroup.get('externalFormGroup') as FormGroup;
      if (this.showExternalPanel) {
        externalGroup.controls.requestDate.setValidators([ValidateDatePicker]);
      } else {
        externalGroup.controls.requestDate.clearValidators();
        externalGroup.controls.requestDate.setErrors(null);
        externalGroup.controls.requestDate.setValidators(null);
      }
      const implantGroup = this.mwtFormGroup.get('implantDataFormGroup') as FormGroup;

      if (!CheckProperties(implantGroup.value as ImplantDataFormModel)) {
        implantGroup.controls.brand.setValidators([Validators.required]);
        implantGroup.controls.reference.setValidators([Validators.required]);
      } else {
        implantGroup.controls.brand.clearValidators();
        implantGroup.controls.brand.setErrors(null);
        implantGroup.controls.brand.setValidators(null);

        implantGroup.controls.reference.clearValidators();
        implantGroup.controls.reference.setErrors(null);
        implantGroup.controls.reference.setValidators(null);
      }
      externalGroup.controls.requestDate.updateValueAndValidity();
      implantGroup.controls.brand.updateValueAndValidity();
      implantGroup.controls.reference.updateValueAndValidity();
    } catch (error) { console.log(error); }
  }

  private TriggerFormErrors() {
    ShowInvalidFormControls(this.mwtFormGroup);
    ShowInvalidFormControls(this.mwtFormGroup.get('workTypeFormGroup') as FormGroup);
    ShowInvalidFormControls(this.mwtFormGroup.get('externalFormGroup') as FormGroup);
    ShowInvalidFormControls(this.mwtFormGroup.get('implantDataFormGroup') as FormGroup);
    ShowInvalidFormControls(this.mwtFormGroup.get('articleFormArray') as FormGroup);
    ShowInvalidFormControls(this.mwtFormGroup.get('productFormArray') as FormGroup);
  }

  private GetMwt(form: WorkTypeFormModel, userId: number) {
    return new MainWorkType({
      clientId: this.selectedWork ? this.selectedWork.clientId : null,
      subWorkId: this.selectedSubWork ? this.selectedSubWork.id : null,
      name: form.templateName,
      workTypeId: form.templateId,
      generateNo: form.mwtNo,
      workTypeStatusId: form.mwtStatusId ? Number(form.mwtStatusId) : null,
      receiveDate: form.receivedDate ? this._commonUiService
        .ChangeNgbDateToCustomFormat(form.receivedDate) : null,
      deliveryDate: form.deliveryDate ? this._commonUiService
        .ChangeNgbDateToCustomFormat(form.deliveryDate) : null,
      deliveryTime: form.deliveryTime ? this.Get24HoursTime(form.deliveryTime) : null,
      realDeliveryDate: form.actualDeliveryDate ? this._commonUiService
        .ChangeNgbDateToCustomFormat(form.actualDeliveryDate) : null,
      realDeliveryTime: form.actualDeliveryTime ? this.Get24HoursTime(form.actualDeliveryTime) : null,
      status: true,
      manualStatus: false,
      createdBy: userId,
      createdDate: moment().format('YYYY-MM-DD'),
      isReservation: this.mwt ? this.mwt.isReservation : false,
      reservationId: this.mwt ? this.mwt.reservationId : null,
      bateaNo: form.bateaNo
    });
  }

  private GetMwtNote(content: string, type: string, userId: number) {
    return new Note({
      content: content,
      createdDateTime: moment().format('YYYY-MM-DD'),
      modifiedDateTime: moment().format('YYYY-MM-DD'),
      type: type,
      status: true,
      userId: userId
    });
  }

  private GetMwtExternalData(form: ExternalFormModel, userId: number) {
    return new MWTExternalData({
      externalCenterId: form.externalCenterId ? form.externalCenterId : null,
      statusId: Number(form.statusId) ? Number(form.statusId) : null,
      shippingMethodId: Number(form.shippingMethodId) ? Number(form.shippingMethodId) : null,
      requestDate: form.requestDate ? this._commonUiService
        .ChangeNgbDateToCustomFormat(form.requestDate) : null,
      deliveryDate: form.deliveryDate ? this._commonUiService
        .ChangeNgbDateToCustomFormat(form.deliveryDate) : null,
      deliveryTime: form.deliveryTime ? this.Get24HoursTime(form.deliveryTime) : null,
      outSourcedNo: form.outSourcedNo ? form.outSourcedNo : null,
      observations: form.observations ? form.observations : null,
      mwtExternalDataDocuments: [],
      deliveryNoteNo: form.deliveryNoteNo ? form.deliveryNoteNo : null,
      total: form.total ? toFixedNumber(form.total) : 0,
      createdBy: userId,
      createdDate: moment().format('YYYY-MM-DD'),
      modifiedBy: userId,
      modifiedDate: moment().format('YYYY-MM-DD'),
      status: true
    });
  }

  private GetMwtExternalDataDocumentList(formArray: ExternalAttachmentFormModel[]) {
    const formData = new FormData();
    formData.append('folderName', FolderPaths.ExternalData);
    const externalDocumentList: MWTExternalDataDocuments[] = [];
    if (formArray.length > 0) {
      for (let index = 0; index < formArray.length; index++) {
        const form = formArray[index];
        const extension = form.name.split('.')[(form.name.split('.')).length - 1];
        const generatedName = (uuid.v4().toString() + '.' + extension).toString().replace(/-/gi, '');
        externalDocumentList.push(new MWTExternalDataDocuments({
          fileName: form.name,
          generateFileName: generatedName
        }));
        formData.append(generatedName, form.file, generatedName);
      }
    }
    return { list: externalDocumentList, formData: formData };
  }

  private GetMwtImplantData(form: ImplantDataFormModel, userId: number) {
    return new MwtImplantData({
      model: form.brand,
      reference: form.reference,
      apical: form.apical ? Number(form.apical) : null,
      note: form.note,
      createdBy: userId,
      createdDate: moment().format('YYYY-MM-DD'),
      status: true
    });
  }

  private GetMwtColorList(formArray: ColorFormModel[], userId: number) {
    const mwtColorList: MwtColor[] = [];
    if (formArray.length > 0) {
      for (let index = 0; index < formArray.length; index++) {
        const form = formArray[index];
        mwtColorList.push(new MwtColor({
          colorId: form.colorId ? form.colorId : null,
          localizationId: form.localizationId ? form.localizationId : null,
          metalId: form.metalId ? form.metalId : null,
          mwtArticleId: null,
          pieces: form.pieces ? form.pieces : null,
          notes: null,
          createdBy: userId,
          createdDate: moment().format('YYYY-MM-DD'),
          status: true,
          metalArticleId: form.metalArticleId
        }));
      }
    }
    return mwtColorList;
  }

  private GetMwtClinicList(formArray: ClinicMaterialFormModel[], userId: number) {
    const mwtClinicMaterialList: MwtClinicMaterial[] = [];
    if (formArray.length > 0) {
      for (let index = 0; index < formArray.length; index++) {
        const form = formArray[index];
        mwtClinicMaterialList.push(new MwtClinicMaterial({
          clinicMaterialId: form.clinicMaterialId ? Number(form.clinicMaterialId) : null,
          quantity: form.quantity ? Number(form.quantity) : null,
          note: '',
          createdBy: userId,
          createdDate: moment().format('YYYY-MM-DD'),
          status: true
        }));
      }
    }
    return mwtClinicMaterialList;
  }

  private GetDocumentList(formArray: DocumentFormModel[]) {
    const formData = new FormData();
    formData.append('folderName', FolderPaths.Document);
    const documentList: Document[] = [];
    if (formArray.length > 0) {
      for (let index = 0; index < formArray.length; index++) {
        const form = formArray[index];
        const extension = form.name.split('.')[(form.name.split('.')).length - 1];
        const generatedName = (uuid.v4().toString() + '.' + extension).toString().replace(/-/gi, '');
        documentList.push(new Document({
          fileName: form.name,
          generateFileName: generatedName,
          status: true
        }));
        formData.append(generatedName, form.file, generatedName);
      }
    }
    return { list: documentList, formData: formData };
  }

  private GetPrescriptionList(formArray: PrescriptionFormModel[]) {
    const formData = new FormData();
    formData.append('folderName', FolderPaths.Prescriptions);
    const prescriptionList: Prescription[] = [];
    if (formArray.length > 0) {
      for (let index = 0; index < formArray.length; index++) {
        const form = formArray[index];
        const extension = form.name.split('.')[(form.name.split('.')).length - 1];
        const generatedName = (uuid.v4().toString() + '.' + extension).toString().replace(/-/gi, '');
        prescriptionList.push(new Prescription({
          subWorkId: form.subWorkId,
          fileName: form.name,
          generatedFileName: generatedName,
          status: true
        }));
        formData.append(generatedName, form.file, generatedName);
      }
    }
    return { list: prescriptionList, formData: formData };
  }

  private GetMwtArticleList(formArray: ArticleFormModel[], userId: number) {
    const mwtArticleList: MwtArticle[] = [];
    if (formArray.length > 0) {
      for (let index = 0; index < formArray.length; index++) {
        const form = formArray[index];
        mwtArticleList.push(new MwtArticle({
          articleId: form.articleId ? form.articleId : null,
          workTypeArticleId: form.workTypeArticleId ? form.workTypeArticleId : null,
          quantity: form.quantity ? Number(form.quantity) : 0,
          waste: form.waste ? toFixedNumber(form.waste) : 0,
          price: form.price ? toFixedNumber(form.price) : 0,
          priceWithDiscount: form.priceWithDiscount ? toFixedNumber(form.priceWithDiscount) : 0,
          quantityWithWaste: form.quantityWithWaste ? toFixedNumber(form.quantityWithWaste) : 0,
          discount: form.discount ? toFixedNumber(form.discount) : 0,
          discountAmount: toFixedNumber(form.discountAmount),
          isAmount: form.discountAmount !== undefined && form.discountAmount !== null
            && form.discountAmount === 0,
          ivaTypeId: Number(form.ivaTypeId) ? Number(form.ivaTypeId) : null,
          ivaAmount: form.ivaAmount ? toFixedNumber(form.ivaAmount) : null,
          ivaPercentage: form.ivaPercentage ? toFixedNumber(form.ivaPercentage) : null,
          finalPrice: form.finalPrice ? toFixedNumber(form.finalPrice) : 0,
          lineDiscountAmount: form.lineDiscountAmount ? toFixedNumber(form.lineDiscountAmount) : 0,
          lineSubTotal: form.lineSubTotal ? toFixedNumber(form.lineSubTotal) : 0,
          isDiscountOverridden: false,
          createdBy: userId,
          createdDate: moment().format('YYYY-MM-DD'),
          status: true
        }));
      }
    }
    return mwtArticleList;
  }

  private GetMwtProductList(formArray: ProductFormModel[], userId: number) {
    const mwtProductList: Mwtproduct[] = [];
    if (formArray.length > 0) {
      for (let index = 0; index < formArray.length; index++) {
        const form = formArray[index];
        mwtProductList.push(new Mwtproduct({
          reltedArticleId: form.articleId,
          productId: form.productId ? Number(form.productId) : null,
          quantity: form.quantity ? Number(form.quantity) : null,
          lotId: null,
          mwtArticleId: null,
          stockOutDate: null,
          price: null,
          createdBy: userId,
          createdDate: moment().format('YYYY-MM-DD'),
          status: true,
          isMomentUpdate: false
        }));
      }
    }
    return mwtProductList;
  }

  private GetMwtMetalList(formArray: MetalFormModel[], userId: number) {
    const mwtMetalList: MwtMetal[] = [];
    if (formArray.length > 0) {
      for (let index = 0; index < formArray.length; index++) {
        const form = formArray[index];
        mwtMetalList.push(new MwtMetal({
          metalId: form.metalId ? form.metalId : null,
          createdBy: userId,
          createdDate: moment().format('YYYY-MM-DD')
        }));
      }
    }
    return mwtMetalList;
  }

  async OnSaveMwt() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      this.SetFormValidations();
      if (this.mwtFormGroup.invalid) { this.TriggerFormErrors(); } else {
        const mwtDto = new MwtDto();
        let externalFormData = new FormData();
        let documentFormData = new FormData();
        let prescriptionFormData = new FormData();

        const mwtFormData = this.mwtFormGroup.value as MwtFormModel;
        if (mwtFormData) {
          const user = this._commonDataService.GetUserFromSession();

          const wtForm = mwtFormData.workTypeFormGroup;
          if (wtForm) {
            const mwt = this.GetMwt(wtForm, user ? user.id : null);

            const isExists = await this._workService.CheckMWTGenerateNoIsExists(mwt.generateNo);
            if (isExists) {
              mwt.generateNo = this.GenerateMwtNo();
            }

            mwtDto.mainWorkType = mwt;
            mwtDto.mainWorkType.priorityId = this.priorityId;
            if (this.selectedWork && this.selectedWork.clientId) {
              mwtDto.mainWorkType.clientId = this.selectedWork.clientId;
            }

            const noteList: Note[] = [];
            if (wtForm.generalObservations && wtForm.generalObservations.length > 0) {
              noteList.push(this.GetMwtNote(wtForm.generalObservations,
                NoteTypes.General, user ? user.id : null));
            }

            if (wtForm.clientObservations && wtForm.clientObservations.length > 0) {
              noteList.push(this.GetMwtNote(wtForm.clientObservations,
                NoteTypes.Clinic, user ? user.id : null));
            }

            if (wtForm.labObservations && wtForm.labObservations.length > 0) {
              noteList.push(this.GetMwtNote(wtForm.labObservations,
                NoteTypes.Lab, user ? user.id : null));
            }
            mwtDto.noteList = noteList;
          }

          const externalForm = mwtFormData.externalFormGroup;
          if (!CheckProperties(externalForm) && this.showExternalPanel) {
            const mwtExternalData = this.GetMwtExternalData(externalForm, user ? user.id : null);
            mwtDto.mwtExternalData = mwtExternalData;

            if (externalForm.attachmentFormArray.length > 0) {
              const exListData = this.GetMwtExternalDataDocumentList(externalForm.attachmentFormArray);
              if (exListData) {
                mwtDto.mwtExternalData.mwtExternalDataDocuments = exListData.list;
                externalFormData = exListData.formData;
              }
            }
          }

          const implantDataForm = mwtFormData.implantDataFormGroup;
          if (!CheckProperties(implantDataForm)) {
            const mwtImplantData = this.GetMwtImplantData(implantDataForm, user ? user.id : null);
            mwtDto.mwtImplantData = mwtImplantData;
          }

          const colorFormArray = mwtFormData.colorFormArray;
          if (colorFormArray.length > 0) {
            const mwtColorList = this.GetMwtColorList(colorFormArray, user ? user.id : null);
            mwtDto.mwtColorList = mwtColorList;
          }

          const clinicMaterialFormArray = mwtFormData.clinicMaterialFormArray;
          if (clinicMaterialFormArray.length > 0) {
            const mwtClinicMaterialList = this.GetMwtClinicList(clinicMaterialFormArray,
              user ? user.id : null);
            mwtDto.mwtClinicMaterialList = mwtClinicMaterialList;
          }

          const articleFormArray = mwtFormData.articleFormArray;
          if (articleFormArray.length > 0) {
            const mwtArticleList = this.GetMwtArticleList(articleFormArray, user ? user.id : null);
            mwtDto.mwtArticleList = mwtArticleList;
          }

          const productFormArray = mwtFormData.productFormArray;
          if (productFormArray.length > 0) {
            const mwtProductList = this.GetMwtProductList(productFormArray, user ? user.id : null);
            mwtDto.mwtProductList = mwtProductList;
          }

          const documentFormArray = mwtFormData.documentFormArray;
          if (documentFormArray.length > 0) {
            const docListData = this.GetDocumentList(documentFormArray);
            if (docListData) {
              mwtDto.documentList = docListData.list;
              documentFormData = docListData.formData;
            }
          }

          const prescriptionFormArray = mwtFormData.prescriptionFormArray;
          if (prescriptionFormArray.length > 0) {
            const presListData = this.GetPrescriptionList(prescriptionFormArray);
            if (presListData) {
              mwtDto.prescriptionList = presListData.list;
              prescriptionFormData = presListData.formData;
            }
          }

          const metalFormArray = mwtFormData.metalFormArray;
          if (metalFormArray.length > 0) {
            const mwtMetalList = this.GetMwtMetalList(metalFormArray, user ? user.id : null);
            mwtDto.mwtMetalList = mwtMetalList;
          }

          mwtDto.isIncludeArticleProduct = false;
          const mwtDtoResponse = await this._workService.SaveMwt(mwtDto);
          if (mwtDtoResponse) {
            const body = JSON.parse(mwtDtoResponse['_body']);
            if (body['_statusCode'] === 200) {
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList['work_type'],
                msg: this._commonDataService.localizationLabelList['save_success']
              });
              const isReservation = this.mwt ? this.mwt.isReservation : false;
              if (isReservation) {
                this.reservationService.UpdateReservation(this.mwt.workReservation);
              }
              const isDentboxWork = this.mwt ? this.mwt.isDentboxWork : false;
              if (isDentboxWork) {
                this.UpdateDentboxWork(this.mwt.dentboxWorkGuid, this.selectedWork.generateNo);
              }
              const mwt = body['_data'] as MwtDto;
              if (mwt) {
                if (mwt.mwtExternalData && mwt.mwtExternalData.mwtExternalDataDocuments) {
                  if (mwt.mwtExternalData.mwtExternalDataDocuments.length > 0) {
                    const ids = mwt.mwtExternalData.mwtExternalDataDocuments
                      .map(e => ({ id: mwt.mwtExternalData.id, name: e.generateFileName }));
                    externalFormData.append('ids', JSON.stringify(ids));
                    await this._workService.UploadMwtDocuments(externalFormData);
                  }
                }
                if (mwt.documentList) {
                  if (mwt.documentList.length > 0) {
                    const ids = mwt.documentList.map(d => ({ id: d.id, name: d.generateFileName }));
                    documentFormData.append('ids', JSON.stringify(ids));
                    await this._workService.UploadMwtDocuments(documentFormData);
                  }
                }
                if (mwt.prescriptionList) {
                  if (mwt.prescriptionList.length > 0) {
                    const ids = mwt.prescriptionList.map(p => ({ id: p.id, name: p.generatedFileName }));
                    prescriptionFormData.append('ids', JSON.stringify(ids));
                    await this._workService.UploadMwtDocuments(prescriptionFormData);
                  }
                }
              }
            } else {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['work_type'],
                msg: this._commonDataService.localizationLabelList['save_error']
              });
            }
            this.popupClosed.emit(true);
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  async UpdateDentboxWork(guid: string, workNo: string) {
    const user = this._commonDataService.GetUserFromSession();
    const dentboxResult = await this._dentboxLabService
      .UpdateDentboxWork(guid, localStorage.currentLang,
        new DentboxWorkStatusModel({
          action: 'Accept',
          dateTime: moment().format('YYYY-MM-DD hh:mm:ss'),
          reason: this._commonDataService.localizationLabelList['prosthetic_lab_has_accepted_the_work'],
          user: user.workerName ? user.workerName : user.username,
          workNo: workNo
        }));
  }

  // in here using enum as Priority
  OnChangePriority(id: number) {
    if (Priority.Normal === Number(id)) {
      this.priorityId = Priority.Normal;
    } else if (Priority.Urgent === Number(id)) {
      this.priorityId = Priority.Urgent;
    }
  }

  DeliveryDateKeyUp() {
    const date = this.mwtFormGroup.get('workTypeFormGroup.deliveryDate').value;
    if (date) {
      const newDate = this._commonUiService.ConvertStringToNgbDate(date);
      if (newDate) {
        this.mwtFormGroup.get('workTypeFormGroup.deliveryDate').setValue(newDate);
        this.onChangeDeliveryDate();
      }
    }
  }

  ActualDeliveryDateKeyUp() {
    const date = this.mwtFormGroup.get('workTypeFormGroup.actualDeliveryDate').value;
    if (date) {
      const newDate = this._commonUiService.ConvertStringToNgbDate(date);
      if (newDate) {
        this.mwtFormGroup.get('workTypeFormGroup.actualDeliveryDate').setValue(newDate);
      }
    }
  }

  private validateDates() {
    const deliveryDate = this.mwtFormGroup.get('workTypeFormGroup.deliveryDate').value;
    const receivedDate = this.mwtFormGroup.get('workTypeFormGroup.receivedDate').value;
    if (deliveryDate && receivedDate) {
      const delivery_date = new Date(this._commonUiService.ChangeNgbDateToCustomFormat(deliveryDate));
      const recieve_date = new Date(this._commonUiService.ChangeNgbDateToCustomFormat(receivedDate));
      if (delivery_date < recieve_date) {
        this.mwtFormGroup.get('workTypeFormGroup.deliveryDate').setValue(null);
        this._toastyService.error({
          title: this._commonDataService.localizationLabelList['works'],
          msg: this._commonDataService.localizationLabelList['delivery_date_should_be_greater_than_recieve_date']
        });
      }
    }
  }

  async onChangeDeliveryDate() {
    const deliveryDateObj = this.mwtFormGroup.get('workTypeFormGroup.deliveryDate').value;
    if (deliveryDateObj) {
      const deliveryDate = this._commonUiService.ChangeNgbDateToCustomFormat(deliveryDateObj);
      const isAvailable = await this._labHolidaysService.checkDate(deliveryDate);
      if (isAvailable) { this.validateDates(); } else {
        this.mwtFormGroup.get('workTypeFormGroup.deliveryDate').setValue(null);
      }
    }
  }

  async onChangeRealDeliveryDate() {
    const actualDeliveryDateObj = this.mwtFormGroup.get('workTypeFormGroup.actualDeliveryDate').value;
    if (actualDeliveryDateObj) {
      const actualDeliveryDate = this._commonUiService.ChangeNgbDateToCustomFormat(actualDeliveryDateObj);
      const isAvailable = await this._labHolidaysService.checkDate(actualDeliveryDate);
      if (!isAvailable) {
        this.mwtFormGroup.get('workTypeFormGroup.actualDeliveryDate').setValue(null);
      }
    }
  }

  async onChangeReceivedDate() {
    const receivedDateObj = this.mwtFormGroup.get('workTypeFormGroup.receivedDate').value;
    if (receivedDateObj) {
      const receivedDate = this._commonUiService.ChangeNgbDateToCustomFormat(receivedDateObj);
      const isAvailable = await this._labHolidaysService.checkDate(receivedDate);
      if (isAvailable) { this.validateDates(); } else {
        this.mwtFormGroup.get('workTypeFormGroup.receivedDate').setValue(null);
      }
    }
  }

  async onChangeExternalDeliveryDate() {
    const deliveryDateObj = this.mwtFormGroup.get('externalFormGroup.deliveryDate').value;
    if (deliveryDateObj) {
      const deliveryDate = this._commonUiService.ChangeNgbDateToCustomFormat(deliveryDateObj);
      const isAvailable = await this._labHolidaysService.checkDate(deliveryDate);
      if (!isAvailable) {
        this.mwtFormGroup.get('externalFormGroup.deliveryDate').setValue(null);
      }
    }
  }

  async onChangeExternalRequestDate() {
    const requestDateObj = this.mwtFormGroup.get('externalFormGroup.requestDate').value;
    if (requestDateObj) {
      const requestDate = this._commonUiService.ChangeNgbDateToCustomFormat(requestDateObj);
      const isAvailable = await this._labHolidaysService.checkDate(requestDate);
      if (!isAvailable) {
        this.mwtFormGroup.get('externalFormGroup.requestDate').setValue(null);
      }
    }
  }

  async onBarcodeInput() {
    if (this.mwtFormGroup.controls.searchProductName.value != "") {
      const product: Product = await this._workService
        .GetProductByBarcode(this.mwtFormGroup.controls.searchProductName.value.toString());
      if (product && product.status) {
        this.mwtFormGroup.controls.searchProductName.setValue(product.name);
        var obj = ({ id: product.id, description: product.name });
        this.OnSearchProduct(obj);
        this.productInputVariable.nativeElement.click();
      } else {
        this._toastyService.error({
          title: this._commonDataService.localizationLabelList['works'],
          msg: this._commonDataService.localizationLabelList['barcode_product_not_found']
        });
      }
    }
  }

  private LoadMetelsAndArticles(isMWTMetel = false, metalId?: number) {
    if (isMWTMetel) {
      const metel = this.dropdownService.metalList
        .filter(o => o.status == true && o.id === metalId)[0];
      if (metel) {
        this.OnAddMetelsAndArticles(metel);
      }
    } else {
      if (this.clientMetalList.length > 0) {
        for (let index = 0; index < this.clientMetalList.length; index++) {
          const element = this.clientMetalList[index];
          const metel = this.dropdownService.metalList
            .filter(o => o.status == true && o.id === element.metalId)[0];
          if (metel) {
            this.OnAddMetelsAndArticles(metel);
          }
        }
      }
    }
  }

  private OnAddMetelsAndArticles(metal: Metals) {
    this.AddMetalFormGroup(new MetalFormModel({
      mwtId: null,
      metalId: metal.id ? metal.id : null,
      metalText: metal.description ? metal.description : null
    }));
    this.workTypeFormGroup.controls.metalText.setValue('');
    if (metal.articleId > 0) {
      const article = this.articleList.find(a => a.id == Number(metal.articleId));
      this.OnSearchArticle(article);
    }
  }

  OnSelectMetal(metal: Metals) {
    this.isMetalExists = false;
    if (metal) {
      const formArray = this.mwtFormGroup.controls.metalFormArray as FormArray;
      if (formArray) {
        const list = formArray.getRawValue();
        const metalData = list.find(m => Number(m.metalId) == metal.id);
        if (metalData) {
          this.isMetalExists = true;
          this.metalValidateMessage = this._commonDataService
            .localizationLabelList['doctorMetalErrorMessage'];
          return;
        }
      }
      this.AddMetalFormGroup(new MetalFormModel({
        mwtId: null,
        metalId: metal.id ? metal.id : null,
        metalText: metal.description ? metal.description : null
      }));
      this.workTypeFormGroup.controls.metalText.setValue('');
    }
  }

  OnRemoveMwtMetal(index: number) {
    this._commonUiService.isSpinnerVisible = true;
    const formArray = this.mwtFormGroup.controls.metalFormArray as FormArray;
    if (formArray) { formArray.removeAt(index); }
    this.isMetalExists = false;
    this._commonUiService.isSpinnerVisible = false;
  }
}
