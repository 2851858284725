import { Component, OnInit, Input } from '@angular/core';
import { OrderReceiptDetail } from 'src/app/modules/orders/entities/order-receipt-detail';
import { OrderReceipt } from 'src/app/modules/common/entities/order-recipt';
import { OnChanges } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { ApiService } from 'src/app/modules/common/services/api.service';
import API from '../../../modules/common/api.config.json';

@Component({
  selector: 'app-return-order-report',
  templateUrl: './return-order-report.component.html'
})
export class ReturnOrderReportComponent implements OnInit {

  @Input() orderDetailList: OrderReceiptDetail[];
  @Input() receipt: OrderReceipt;
  @Input() reportId: string;
  @Input() imageData: string;

  constructor(private _apiService: ApiService) {}

  ngOnInit() { }
}
