import { AbstractControl, FormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Crypto } from './crypto';

export function ValidateNgSelect(control: AbstractControl) {
    if (control.value) {
        const index = control.value;
        if (index === '0' || index === 0) { return { isValid: false }; } else { return null; }
    }
    return { isValid: false };
}

export function ValidateAutoComplete(control: AbstractControl) {
    if (control.value) {
        const autoCompleteObject: any = control.value;
        if (!autoCompleteObject) { return { isValid: false }; } else { return null; }
    }
    return { isValid: false };
}

export function ValidateNgSelect2(control: AbstractControl) {
    if (control.value) {
        const index = control.value as string;
        if (index.toLowerCase() === '--Select--'.toLowerCase()
            || index.toLowerCase() === '-Select-'.toLowerCase()
            || index.toLowerCase() === '--Seleccione--'.toLowerCase()
            || index.toLowerCase() === '-Seleccione-'.toLowerCase()) { return { isValid: false }; } else { return null; }
    }
    return { isValid: false };
}

export function ShowInvalidFormControls(formGroup: FormGroup) {
    if (formGroup !== undefined) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            control.markAsDirty({ onlySelf: true });
            control.markAsTouched({ onlySelf: true });
        });
    }
}

export function ValidateDatePicker(control: AbstractControl) {
    if (control.value !== undefined && control.value !== null && control.value !== '') {
        const date = control.value;
        if (date.year === undefined || date.month === undefined || date.day === undefined ||
            !isNumber(date.year) || !isNumber(date.month) || !isNumber(date.day)) {
            return { invalidDate: true };
        }
        return null;
    }
    return { required: true };
}

export function isNumber(value: string | number): boolean {
    return !isNaN(Number(value.toString()));
}

export function Delay(ms: number): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function CheckUsernamePassword(group: FormGroup): ValidatorFn { // here we have the 'passwords' group
    return (c: AbstractControl) => {
        const password: string = group.controls.password.value as string;
        const username: string = group.controls.username.value as string;
        return password === username ? { same: true } : null;
    };
}

// if the object is empty, this will return true
export function CheckProperties(obj: any): boolean {
    for (const key in obj) {
        if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '' && obj[key] !== '0' && obj[key].length > 0) {
            return false;
        }
    }
    return true;
}

export function CheckRecentPasswords(group: FormGroup): ValidatorFn {
    return (c: AbstractControl) => {
        const password = group.controls.password.value as string;
        const recentPasswords = group.controls.recentPasswords.value as string;
        if (password && recentPasswords) {
            const encryptedPasswords: string[] = recentPasswords.startsWith('[') && recentPasswords.endsWith(']') ?
                JSON.parse(recentPasswords) : [];
            const decryptedPasswords: string[] = [];
            encryptedPasswords.forEach(pswd => decryptedPasswords.push(Crypto.Decrypt2(pswd)));
            if (decryptedPasswords) { return decryptedPasswords.includes(password) ? { recentlyUsed: true } : null; }
        }
        return null;
    };
}

export function CheckObject(x: any): boolean {
    return (x == null) ? false : (x === null) ? false : (typeof x === 'undefined') ? false : true;
}

export function RegexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!control.value) {
            return null;
        }
        const valid = regex.test(control.value);
        return valid ? null : error;
    };
}
