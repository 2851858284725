import { Worker } from '../../workers/entities/worker';
export class TaskWorker {
    id: number;
    taskId: number;
    workerId: number;
    workerName: string;
    worker: Worker;

    display: string;
    status: boolean;
    value: any;
}
