import { MwtIncident } from "./main-work-type-incident";

export class IncidentReport {
  workNo: string;
  mainWorkTypeNo: string;
  typeofWorkName: string;
  date: string;
  doctorName: string;
  doctorRegistrationNo: string;
  clinicName: string;
  patientName: string;
  issuerName: string;
  issuerRegistrationNo: string;

  mwtIncidentList: MwtIncident[] = [];
  incidentCount: number;
}
