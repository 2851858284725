import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { WorkReservation } from 'src/app/modules/reservations/entities/work-reservation';
import { WorkReservationService } from 'src/app/modules/reservations/services/work-reservation.service';
import { NumberingProcess, NumberingProcessData } from 'src/app/shared/numbering-process/numbering-process';
import { NumberingProcessService } from 'src/app/shared/numbering-process/numbering-process.service';
import { SubWork } from '../../../entities/sub-work';
import { Work } from '../../../entities/work';
import { SubWorkService } from '../../../services/sub-work.service';
import { WorkService } from '../../../services/work.service';

@Component({
  selector: 'app-associated-resrvations',
  templateUrl: './associated-resrvations.component.html',
  styleUrls: ['./associated-resrvations.component.scss']
})
export class AssociatedResrvationsComponent implements OnInit {

  // work create
  work: Work;
  defaultDoctor: any;
  public _existingWork: Work;
  lastPrescriptionMonth = '';
  lastPrescriptionYear: number;

  wNumberingProcess: NumberingProcess;
  wSeries: { id: number, type: 'Work' | 'Delivery Note' | 'Bill' | 'Budget' | 'Receipt', series: string };
  wMaxSize = 5;
  currentNPData: NumberingProcessData;

  @Input() reservationList: WorkReservation[] = [];

  constructor(public commonUiService: CommonUIService, private _workService: WorkService, private _router: Router,
    public commonDataService: CommonDataService, private reservationService: WorkReservationService, private _toastyService: Toast,
    private _numberingProcessService: NumberingProcessService, private _subWorkService: SubWorkService) { }

  ngOnInit() {
  }

  //#region Reservation details

  //#endregion

  //#region Work Create

  async OnCreateWork(reservation: any) {
    if (reservation.statusId === 1) {
      this.OnSaveWork(reservation);
    }
  }

  async OnSaveWork(reservation: WorkReservation) {
    try {
      if (reservation) {
        this.commonUiService.isSpinnerVisible = true;
        this.work = new Work();
        let transferable: {
          work: Work; subWork: SubWork; isEdit: boolean; existing: boolean;
          mwtWork: {
            workTypeId: number; deliveryDate: any; orderDate: any, workTypeName: string, reservationId: number,
            observation: string, deliveryTime: any, workReservation?: WorkReservation
          }
        };

        this.work.clientId = reservation.clientId ? reservation.clientId : null;
        this.work.patientId = reservation.patientId ? reservation.patientId : null;
        this.work.clientName = reservation.clientName ? reservation.clientName : null;
        this.work.patientName = reservation.patientName ? reservation.patientName : null;
        // check already existing client - patient combination
        await this.ShowExisingWorkMessage(this.work.clientId, this.work.patientId);
        if (this._existingWork.id) {
          // add new subwork for the work
          const subWork = new SubWork();
          subWork.situation = 'Nuevo';
          subWork.createdDate = moment().format('YYYY-MM-DD');
          subWork.status = true;
          subWork.workId = this._existingWork.id;
          subWork.subWorkStatus = 'Pendiente';
          // get count of sub works from api
          const count = await this._subWorkService.GetSubWorkCountByWorkId(this._existingWork.id);
          subWork.generateNo = count ? (count + 1) : 1;

          const response = await this._subWorkService.AddSubWork(subWork);
          if (response) {
            const body = JSON.parse(response['_body']);
            if (body['_statusCode'] === 200) {
              // get full work object from work list
              // (because it has full object data including doctor, patient and client names)
              this._toastyService.success({
                title: this.commonDataService.localizationLabelList['sub_work'],
                msg: this.commonDataService.localizationLabelList['save_success'],
              });
              reservation.workId = this._existingWork.id;
              reservation.statusId = 2;
              const workNo = (this._existingWork.generateNo + body['_data'].generateNo.toString()).toString();
              reservation.workNumber = workNo;
              transferable = {
                work: this._existingWork,
                subWork: body['_data'] as SubWork,
                isEdit: false,
                existing: true,
                mwtWork: {
                  workTypeId: reservation.workTypeId, deliveryDate: reservation.deliveryDate, orderDate: reservation.orderDate,
                  workTypeName: reservation.workTypeName, reservationId: reservation.id, observation: reservation.observations,
                  deliveryTime: reservation.deliveryTime, workReservation: reservation
                }
              };
            } else {
              this._toastyService.error({
                title: this.commonDataService.localizationLabelList['sub_work'],
                msg: this.commonDataService.localizationLabelList['save_error'],
              });
            }
          }
        } else {
          // set generated number
          const series = await this._workService.GetSeriesBySeriesType('Work');
          if (series) {
            this.wNumberingProcess = new NumberingProcess({
              isNextAvailableNumber: true,
              nextAvailableNumber: series.lastNumber,
              isAnother: false,
              isNoNumberAssigned: false,
              isOtherAvailableNumbers: false,
              anotherNumber: 1,
              otherAvailableNumber: 0
            });
            const genNumberObject = this._numberingProcessService.GetGeneratedNumber(this.wNumberingProcess, this.wMaxSize,
              { id: series.id, type: 'Work', series: series.series });
            if (genNumberObject) {
              this.work.generateNo = genNumberObject.generateNumber;
            }
          }
          this.work.workStatus = 'Pendiente';
          this.work.status = true;
          this.LoadDoctorList(Number(this.work.clientId));
          const response = await this._workService.AddWork(this.work);
          if (response) {
            const body = JSON.parse(response['_body']);
            switch (body['_statusCode']) {
              case 200:
                const subWork: SubWork = new SubWork();
                subWork.createdDate = moment().format('YYYY-MM-DD');
                subWork.situation = 'Nuevo';
                subWork.status = true;
                subWork.subWorkStatus = 'Pendiente';
                subWork.workId = (body['_data']).id;
                subWork.generateNo = 1;

                const subWorkResponse = await this._subWorkService.AddSubWork(subWork);
                if (subWorkResponse) {
                  const subWorkBody = JSON.parse(subWorkResponse['_body']);
                  if (subWorkBody['_statusCode'] === 200) {
                    reservation.workId = (body['_data']).id;
                    reservation.statusId = 2;
                    const workNo = ((body['_data']).generateNo + subWorkBody['_data'].generateNo.toString()).toString();
                    reservation.workNumber = workNo;
                    transferable = {
                      work: body['_data'] as Work,
                      subWork: subWorkBody['_data'] as SubWork,
                      isEdit: false,
                      existing: false,
                      mwtWork: {
                        workTypeId: reservation.workTypeId, deliveryDate: reservation.deliveryDate, orderDate: reservation.orderDate,
                        workTypeName: reservation.workTypeName, reservationId: reservation.id, observation: reservation.observations,
                        deliveryTime: reservation.deliveryTime, workReservation: reservation
                      }
                    };
                    this._toastyService.success({
                      title: this.commonDataService.localizationLabelList['sub_work'],
                      msg: this.commonDataService.localizationLabelList['save_success'],
                    });
                  } else {
                    this._toastyService.error({
                      title: this.commonDataService.localizationLabelList['sub_work'],
                      msg: this.commonDataService.localizationLabelList['save_error'],
                    });
                  }
                }
                this._toastyService.success({
                  title: this.commonDataService.localizationLabelList['works'],
                  msg: this.commonDataService.localizationLabelList['save_success'],
                });
                break;
              case 409:
                this._toastyService.warning({
                  title: this.commonDataService.localizationLabelList['works'],
                  msg: 'Number is already in use!',
                });
                break;
              default:
                this._toastyService.error({
                  title: this.commonDataService.localizationLabelList['works'],
                  msg: this.commonDataService.localizationLabelList['save_error'],
                }); break;
            }
            this.commonUiService.isSpinnerVisible = false;
          }
        }

        if (transferable != null && transferable.work != null && transferable.work.clientName == null) {
          transferable.work.clientName = reservation.clientName;
        }
        if (transferable != null && transferable.work != null && transferable.work.doctorName == null) {
          transferable.work.doctorName = this.work.doctorName;
        }
        if (transferable != null && transferable.work != null && transferable.work.patientName == null) {
          transferable.work.patientName = reservation.patientName;
        }
        this.OnLoadWork(transferable.work.id, transferable);
      }
    } catch (error) { console.log(error); }
    finally {
      this.commonUiService.isSpinnerVisible = false;
    }

  }

  async LoadDoctorList(clientId: number) {
    this.defaultDoctor = {};
    if (clientId > 0) {
      let doctorList = await this._workService.GetDoctorListByClientId(clientId);
      doctorList = doctorList ? doctorList.filter(c => c.status) : [];
      if (doctorList.length > 0) {
        doctorList.forEach(t => t.name = t.code != null ? t.name + ' - ' + t.code : t.name);
        this.defaultDoctor = doctorList.find(a => a.isDefault === true);
        if (this.defaultDoctor) {
          this.work.doctorId = this.defaultDoctor.id;
          this.work.doctorName = this.defaultDoctor.name;
        } else {
          this.work.doctorId = 0;
          this.work.doctorName = '';
        }
      }
    }
  }

  async ShowExisingWorkMessage(clientId: number, patientId: number) {
    this._existingWork = await this._workService.GetExistingWork(clientId, patientId); // returns null, when it doesn't exist
    this.lastPrescriptionMonth = '';
    if (this._existingWork != null) {
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      this.lastPrescriptionMonth = monthNames[new Date(this._existingWork.lastPrescriptionDate).getMonth()];
      this.lastPrescriptionMonth = this.lastPrescriptionMonth.toLocaleLowerCase();
      this.lastPrescriptionYear = new Date(this._existingWork.lastPrescriptionDate).getFullYear();
    } else { this._existingWork = new Work(); }
  }

  private OnLoadWork(workid: number, transferableData: any) {
    if (workid) {
      this._router.navigate(['/work/'], {
        queryParams: {
          workid: workid, '78dab9bb04fa45aa8f55ee9e01184a9f': JSON.stringify({
            work: transferableData.work,
            subWork: transferableData.subWork,
            isEdit: transferableData.isEdit,
            existing: transferableData.existing,
            mwtWork: transferableData.mwtWork,
            isReservation: true
          })
        }
      });
    } else { console.log('work id null'); }
  }
  //#endregion

}
