import { Component, OnInit, AfterViewInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { CommonUIService } from '../../common/services/common.ui.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonDataService } from '../../common/services/common.data.service';
import { DropdownService } from '../../common/services/dropdown.service';
import { Patient } from '../../work/entities/patient';
import { ShowInvalidFormControls } from '../../common/services/validators';
import { PatientService } from '../../client/services/patient.service';
import { ToastyService } from 'ng2-toasty';
import { WorkModalService } from '../services/work-modal.service';
import { Town } from '../../auxiliary/entities/town';
import { CommonFunctions } from '../../common/services/common-functions';
import { AuxiliaryService } from '../../auxiliary/services/auxiliary.service';

@Component({
  selector: 'app-add-new-patient',
  templateUrl: './add-new-patient.component.html'
})
export class AddNewPatientComponent implements OnInit, AfterViewInit {
  isCollapsed = false;
  patient: Patient;
  @ViewChild('editPostalCodeComponent') editPostalCodeComponent: any
  @ViewChild('lblpostalCode') lblpostcodeElement: ElementRef;

  @Input() clientId: number;
  @Input() clientName: string;
  @Input() isReservation: boolean;
  @Input() patientNames: { name: string, surname: string };
  patientFormGroup: FormGroup;
  @Input() patientHistoryNo: string;

  @Output() addPatientFromReservationEmit: EventEmitter<Patient> = new EventEmitter();
  @Output() dentboxPanelRemoveClass: EventEmitter<string> = new EventEmitter();

  townListDropdownList = [];
  townList: Town[] = [];
  townPostalCodeList: Town[] = [];
  editTown: Town;

  constructor(public _commonUiService: CommonUIService,
    private _formBuilder: FormBuilder,
    private _toastyService: ToastyService,
    private _dropdownService: DropdownService,
    public _commonDataService: CommonDataService,
    public _patientService: PatientService,
    public _workModalService: WorkModalService,
    private _auxiliaryService: AuxiliaryService) {
    this.patient = new Patient();
    this.editTown = new Town();
    this._auxiliaryService.onUpdateTown.subscribe((arg) => {
      if (arg && arg.model == 'gen') {
        this.patientFormGroup.controls.postalCode.setValue(arg.pc);
        this.patientFormGroup.controls.townId.setValue(arg.description + ' - ' + arg.pc);
        this.patient.postalCode = arg.pc;
        if (this.lblpostcodeElement) { this.lblpostcodeElement.nativeElement.click(); }
      }
      if (document.querySelector('#dentbox-add-patient-modal')) {
        document.querySelector('#dentbox-add-patient-modal').classList.remove('set-index-dentbox-pc');
      }
    });
  }

  ngOnInit() {
    this.InitFormGroups();
    this._workModalService.onOpenPatientModal.subscribe(() => {
      this.onOpenModal();
    });
  }

  async ngAfterViewInit() {
    this.LoadPopupData();
  }

  private InitFormGroups() {
    this.patientFormGroup = this._formBuilder.group({
      name: ['', [Validators.required]],
      surnames: [],
      historyNumber: [],
      dateOfBirth: [],
      dateOfBirthView: [null],
      clientName: [],
      nif: [],
      address: [],
      postalCode: ['', [Validators.maxLength(10)]],
      townId: [],
      provinceName: [],
      countryName: [],
      notes: [],
      email: ['', [Validators.email]],
    });
  }

  private LoadPopupData() {
    this.townListDropdownList = this._commonDataService.GetDropDownList(this._dropdownService.townList, 'description');
  }

  onOpenModal() {
    this.patient = new Patient();
    this.patient.clientId = this.clientId;
    this.patient.clientName = this.clientName;
    this.patient.historyNumber = this.patientHistoryNo;

    if (this.patientNames) {
      this.patientFormGroup.controls.name.setValue(this.patientNames.name);
      this.patientFormGroup.controls.surnames.setValue(this.patientNames.surname);
    }
    if (this.patientHistoryNo) {
      this.patientFormGroup.controls.historyNumber.setValue(this.patientHistoryNo);
      this.patientFormGroup.controls.historyNumber.disable();
    }
    this.isCollapsed = false;
  }

  onCloseModal() {
    this.dentboxPanelRemoveClass.emit('close');
    this._commonUiService.CloseModalEx('dentbox-add-patient-modal');
  }

  onTownChange() {
    const town = this._dropdownService.townList.find(o => o.id == this.patient.townId);
    if (town) {
      this.patient.provinceName = town.provinceName;
      this.patient.countryName = town.countryName;
    }
  }

  async onSavePatient() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (this.patientFormGroup.invalid) { ShowInvalidFormControls(this.patientFormGroup); return; }
      if (this.patient) {
        if (this.patient.dateOfBirthView) {
          this.patient.dateOfBirth = new Date(this._commonUiService.ConvertDateFormatToString(this.patient.dateOfBirthView))
            .toISOString();
        }

        const response = await this._patientService.AddPatient(this.patient);
        if (response) {
          const body = JSON.parse(response['_body']);
          if (body['_isSuccsess']) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['patient'],
              msg: this._commonDataService.localizationLabelList['save_success'],
              showClose: true,
              theme: 'default',
              timeout: 5000
            });
            const newPatient = body['_data'] as Patient;
            this.onCloseModal();
            if (this.isReservation) {
              this.addPatientFromReservationEmit.emit(newPatient);
            } else {
              this._workModalService.onAddNewPatient.emit(newPatient);
            }
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['patient'],
              msg: this._commonDataService.localizationLabelList['save_error'],
              showClose: true,
              theme: 'default',
              timeout: 5000
            });
          }
          // this._commonUiService.CloseModalEx(modal);
        }

      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }



  /* patient modal town */
  OnChangeTown(town: Town) {
    if (town) {
      this.patient.townId = Number(town.id);
      this.patient.provinceName = town.provinceName;
      this.patient.countryName = town.countryName;
      this.patient.postalCode = town.postcode;
      this.patientFormGroup.controls.postalCode.setValue(town.postcode);
      this.editTown = town;
    }
  }

  onSkipCharCountExceedsTown() {
    this.searchTowns(this.patientFormGroup.controls.townId.value);
  }

  async searchTowns(query = '') {
    const townList = await this._dropdownService.SearchTowns(query);
    this.townList = townList ? townList : [];
    this.townList = CommonFunctions.Sort(this.townList.filter(x => x.description), 'description');
  }

  async OnOpenAddWorkPcModal(modal: string) {
    if (this.editTown) {
      this.editTown.id = this.patient.townId;
      this.editTown.postcode = this.patientFormGroup.controls.postalCode.value;
      this.editTown.provinceName = this.patient.provinceName
      this.editTown.countryName = this.patient.countryName
      this.editTown.model = 'gen';
      if (document.querySelector('#dentbox-add-patient-modal')) {
        document.querySelector('#dentbox-add-patient-modal').classList.add('set-index-dentbox-pc');
      }
    }
    if (this.editPostalCodeComponent) {
      this.editPostalCodeComponent.OpenModal(this.editTown);
    }
  }

  OpenModal(modal: string) { document.querySelector('#' + modal).classList.add('md-show'); }

  async searchPostcodes(query = '') {
    const townPostalList = await this._dropdownService.SearchPostcodes(query);
    this.townPostalCodeList = townPostalList ? townPostalList.filter(x => x.postcode) : [];
    this.townPostalCodeList = CommonFunctions.Sort(this.townPostalCodeList, 'postcode');
  }

  onSkipCharCountExceedsPostcode() {
    this.searchPostcodes(this.patientFormGroup.controls.postalCode.value);
  }

  OnChangePostcode(town: Town) {
    this.patient.townId = Number(town.id);
    this.patient.provinceName = town.provinceName;
    this.patient.countryName = town.countryName;
    this.patient.postalCode = town.postcode;
    this.patientFormGroup.controls.townId.setValue(town.descriptionWithPostcode);
    this.editTown = town;
  }

  clearTown() {
    this.patient.townId = null;
    this.patient.provinceName = '';
    this.patient.countryName = '';
    this.patient.postalCode = '';
    this.patientFormGroup.controls.townId.setValue(null);
    this.patientFormGroup.controls.postalCode.setValue(null);
    this.editTown = new Town();
  }
  /* end for patient modal town */
}
