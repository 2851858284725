import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { Article } from '../../../../articles/entities/article';
import { DropDownOption } from '../../../../common/entities/dropdown-option';
import { ApiService } from '../../../../common/services/api.service';
import { CommonDataService } from '../../../../common/services/common.data.service';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import { Metals } from '../../../entities/metals';
import API from './../../../../common/api.config.json';


@Component({
  templateUrl: './metals.component.html',
  selector: 'metals-list',
  styleUrls: ['./metals.component.scss']
})

export class MetalsComponent implements OnInit {

  successMessage: any;
  updateMessage: any;
  errorMessage: any;
  deleteMessage: any;
  statusMessage: any;
  deleteErrorMessage: any;
  tableName: any;
  defaultValue: any;

  metals: Metals;
  isLoad: boolean;

  position = 'bottom-right';
  metalsList: Metals[];
  articleList: Article[];
  private _metalsList: Metals[] = [];

  metalsFormGroup: FormGroup;
  metalsForm: NgForm;
  articleDropdown: DropDownOption[] = [];
  provinceDropdown: DropDownOption[] = [];

  @ViewChild('description') nameField: ElementRef;
  isDisabledMetals = false;

  constructor(
    private _router: Router,
    private _apiService: ApiService,
    private _fb: FormBuilder,
    private _commonUIService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: Toast) {
    this.metals = new Metals();
    this.isLoad = false;
    this.isDisabledMetals = false;

  }

  ngOnInit(): void {

    this.metalsFormGroup = this._fb.group({
      description: ['', [
        Validators.required
      ]],
      code: [],
      articleId: []
    })

    this.LoadMetalsList();
    //this.metals = new Metals();

  }

  LoadMetalsList() {
    this._apiService.get(API.metals.getMetalsList)
      .subscribe(res => {
        this.metalsList = res;
        this._metalsList = res;
        this.isLoad = true;
      });
  }

  LoadArticleList() {
    this._apiService.get(API.article.getAllArticles)
      .subscribe(res => {
        this.metals.articleId = 0;
        this.articleList = res.filter(x => x.isActive);
      });
  }

  LoadEditMetals(metals: Metals) {
    metals.isEdit = true;
    this.LoadArticleList()
    console.log(metals);
  }

  EditMetals(metals: Metals, statusChange: boolean) {
    this.isDisabledMetals = true;

    this.statusMessage = this._commonDataService.localizationLabelList['status_updated'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.tableName = this._commonDataService.localizationLabelList['metals'];
    // if first option is selected then no articale id
    if (isNaN(Number(metals.articleId)))   // not a number
      metals.articleId = null;

    metals.isEdit = false;

    this._apiService.put(API.metals.editMetals + metals.id, metals, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {

            let message = '';
            message = this.updateMessage;

            this._toastyService.success({
              title: this.tableName,
              msg: message
            });

            this.LoadMetalsList();
          }
        },
        err => {
          console.log(err);
        },
        () => {
          this.isDisabledMetals = false;
        }
      );
  }

  RemoveEditMetals(metals: Metals) {
    metals.isEdit = false;
    this.LoadMetalsList();
  }

  ActiveDeactiveMetals(metals: Metals) {

    if (metals) {
      if (metals.status) {
        metals.status = false;
      }
      else {
        metals.status = true;
      }
      this.EditMetals(metals, true);
    }
  }

  OnOpenAddMetalsModal() {

    this.successMessage = this._commonDataService.localizationLabelList['save_success'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.errorMessage = this._commonDataService.localizationLabelList['save_error'];
    this.tableName = this._commonDataService.localizationLabelList['metals'];

    this.metals = new Metals(); this.metalsFormGroup.reset();
    this.LoadArticleList();
    this._commonUIService.openModal('metals-modal');

    setTimeout(() => {
      this.nameField.nativeElement.focus();
    }, 100);

  }

  OnCloseMetalsModal(event) {
    (((((event.target.parentElement.parentElement).parentElement).parentElement).parentElement).parentElement).classList.remove('md-show');
  }

  SaveMetals(metals: Metals, event) {
    this.isDisabledMetals = true;

    if (this.metalsFormGroup.invalid) {
      Object.keys(this.metalsFormGroup.controls).forEach(field => {
        const control = this.metalsFormGroup.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      this.isDisabledMetals = false;
      return;
    }

    this.OnCloseMetalsModal(event);
    if (metals.articleId == 0 || metals.articleId == undefined) {
      metals.articleId = null;
    }
    this._apiService.post(API.metals.saveMetals, metals, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {
            this._toastyService.success({
              title: this.tableName,
              msg: this.successMessage
            });
            this.LoadMetalsList();
          }
        },
        err => {
          this._toastyService.error({
            title: this.tableName,
            msg: err
          });
          this.isDisabledMetals = false;
        },
        () => {
          this.isDisabledMetals = false;
        }
      );
  }

  DeleteMetals(id: number) {
    this.isDisabledMetals = true;

    this.deleteMessage = this._commonDataService.localizationLabelList['delete_success'];
    this.deleteErrorMessage = this._commonDataService.localizationLabelList['delete_error'];
    this.tableName = this._commonDataService.localizationLabelList['metals'];

    if (id) {
      this._apiService.delete(API.metals.deleteMetals, id.toString())
        .subscribe(
          res => {
            if (res._statusCode == 200) {
              let index: number = this._metalsList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._metalsList.splice(index, 1);
                this._toastyService.success({
                  title: this.tableName,
                  msg: this.deleteMessage
                });
              }
            }
            if (res._statusCode == 409) {
              let index: number = this._metalsList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._toastyService.warning({
                  title: this.tableName,
                  msg: this.deleteErrorMessage
                });
              }
            }
          },
          err => {
            this._toastyService.error({
              title: this.tableName,
              msg: err
            });
            this.isDisabledMetals = false;

          },
          () => {
            this.isDisabledMetals = false;
            this.metalsList = this._metalsList;
          }
        );
    }
  }

  private PopulateArticleDropDown(list: Article[]): void {
    if (list) {
      this.articleDropdown = [];
      list.forEach((element) => {
        let option = new DropDownOption(element.id.toString(), element.code, false);
        this.articleDropdown.push(option);
      });
    }
    else console.log('Article list is empty!');
  }

  ActiveDeactiveIncludeInStatistics(metals: Metals) {

    if (metals) {
      if (metals.includeInStatistics) {
        metals.includeInStatistics = false;
      }
      else {
        metals.includeInStatistics = true;
      }
      this.EditMetals(metals, true);
    }
  }
}



