import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import swal from 'sweetalert2';
import { ApiService } from '../../../common/services/api.service';
import { CommonDataService } from '../../../common/services/common.data.service';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { ProviderViewModel } from '../../../provider/entities/provider-view-model';
import { IWorkComponent } from '../../entities/i-work-component';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtImplantData } from '../../entities/main-work-type-implant-data';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { ImplantDataService } from '../../services/implant-data.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'implant-data',
  templateUrl: './implant-data.component.html',
  styleUrls: ['./implant-data.component.scss']
})
export class ImplantDataComponent implements OnInit, OnDestroy, IWorkComponent {

  // subscriptions
  private workService: Subscription;

  // lists
  mwtImplantDataList: MwtImplantData[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];
  manufacturerList: ProviderViewModel[] = [];
  mainWorkTypeList: MainWorkType[];

  // objects
  selectedMainWorkType: MainWorkType;
  selectedSubWork: SubWork;
  implantData: MwtImplantData;
  selectedWork: Work;
  hasLoaded = true;
  isMwtImplantDataListVisible = true;

  showPopUp = false;
  allClosed = false;

  constructor(private _workService: WorkService, private _apiService: ApiService, private _modalService: ModalService,
    private __service: ImplantDataService, public commonUiService: CommonUIService, private _commonDataService: CommonDataService,
    private _toastyService: Toast) {
    this.workService = this._workService.detailObject.share().subscribe((args) => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (args.invokeComponents.includes('ImplantData') || args.invokeComponents.includes('*')) {
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this.selectedWork = args.selectedWork;
          this.allClosed = this.selectedMainWorkTypeList.every(x => x.isClosed);
          this.SyncMwtImplantData();
        }
      }
    });
  }

  ngOnInit() { this.implantData = new MwtImplantData(); }

  ngOnDestroy() { this.workService.unsubscribe(); }

  //#region Resources

  private GetManufacturerList() {
    return this._apiService.get(API.provider_manufacturer_details.getProviderManufacturerDetails, true)
      .map(x => x as ProviderViewModel[]).toPromise();
  }

  //#endregion

  //#region CRUD

  async SyncMwtImplantData() {
    this.mwtImplantDataList = [];
    try {
      this.hasLoaded = false;
      const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
      if (idList) {
        let implantDataList = await this.__service.GetImplantDataListByMainWorkTypeIdList(idList);
        implantDataList = implantDataList ? implantDataList : [];
        if (implantDataList.length > 0) {
          for (let index = 0; index < implantDataList.length; index++) {
            const implantData = implantDataList[index];
            const currentMainWorkType = this.selectedMainWorkTypeList.find(m => Number(m.id) === implantData.mainWorkTypeId);
            if (currentMainWorkType) {
              implantData.order = currentMainWorkType.order;
              implantData.cssClass = currentMainWorkType.cssClass;
              implantData.symbol = currentMainWorkType.symbol;
              if (currentMainWorkType.isClosed) {
                implantData.isClosed = true;
              } else {
                implantData.isClosed = false;
              }
            }
          }
        }
        this.mwtImplantDataList = implantDataList;
      }
    } catch (error) { console.log(error); }
    finally { this.hasLoaded = true; }
  }

  private DeleteImplantData(id: number) {
    return this._apiService.delete(API.main_work_type_work.deleteImplantData, id.toString() + '/' + this.selectedWork.id, true)
      .map(isDeleted => isDeleted as boolean).toPromise();
  }

  //#endregion

  //#region Events

  async LoadImplantDataPopup() { this.manufacturerList = await this.GetManufacturerList(); }

  async OnRemoveMwtImplantData(id: number) {
    if (id) {
      swal({
        title: this._commonDataService.localizationLabelList['implant_data'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      }).then(async (result) => {
        if (result.value) {
          this.commonUiService.isSpinnerVisible = true;
          const isDeleted = await this.DeleteImplantData(id);
          if (isDeleted) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['implant_data'],
              msg: this._commonDataService.localizationLabelList['delete_success']
            });
            this.SyncMwtImplantData();
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['implant_data'],
              msg: this._commonDataService.localizationLabelList['delete_error']
            });
          }
          this.commonUiService.isSpinnerVisible = false;
        }
      });
    }
  }

  //#endregion

  OnOpenAddModal(modal: string) {
    let mwtList = [] as MainWorkType[];
    for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
      const mwt = this.selectedMainWorkTypeList[index];
      if (!mwt.isClosed) {
        mwtList.push(mwt);
      }
    }
    this.showPopUp = true;
    this._modalService.Invoke(modal, true, {
      mainWorkTypeList: mwtList,
      selectedWork: this.selectedWork,
      implantData: undefined, isEdit: false
    });
  }

  OnOpenEditModal(implantData: MwtImplantData, modal: string) {
    this.showPopUp = true;
    this._modalService.Invoke(modal, true, {
      mainWorkTypeList: this.selectedMainWorkTypeList,
      selectedWork: this.selectedWork,
      implantData: implantData, isEdit: true
    });
  }
}
