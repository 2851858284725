import { Injectable } from '@angular/core';
import { WorkService } from './work.service';
import { Toast } from '../../common/services/toast.service';
import { CommonDataService } from '../../common/services/common.data.service';

@Injectable({
    providedIn: 'root'
})
export class LabHolidaysService {

    constructor(
        private _workService: WorkService,
        private _toastyService: Toast,
        private _commonDataService: CommonDataService) { }

    async checkDate(date: string) {
        const result: { isAvailable: boolean } = await this._workService.CheckDateAvailability(date);
        if (result) {
            if (!result.isAvailable) {
                this._toastyService.error({
                    title: this._commonDataService.localizationLabelList['works'],
                    msg: this._commonDataService.localizationLabelList['date_is_not_available']
                });
            }
            return result.isAvailable;
        }
        return false;
    }
}
