import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { DentboxValidationService } from './dentbox/services/dentbox-validation.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private _route: Router, private _permissionService: NgxPermissionsService,
    private _dentboxValidationService: DentboxValidationService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (sessionStorage['access-token'] && sessionStorage['access-token'].length > 20) {
      if (this._permissionService.getPermissions()) {
        if (sessionStorage['permissions']) {
          const permissions: string[] = JSON.parse(atob(sessionStorage['permissions']));
          this._permissionService.loadPermissions(permissions);
        }
      }
      return true;
    } else {
      this._route.navigate(['/']);
      return false;
    }
  }
}
