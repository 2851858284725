import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
  Component, ElementRef, EventEmitter, OnInit, Output, ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Article } from 'src/app/modules/articles/entities/article';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { ModalService } from '../../../common/services/modal.service';
import { ShowInvalidFormControls, ValidateNgSelect } from '../../../common/services/validators';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtArticle } from '../../entities/main-work-type-article';
import { Work } from '../../entities/work';
import { ArticleTemplateService } from '../../services/article-template.service';
import { DropdownService } from '../../../common/services/dropdown.service';
import { ListNames } from 'src/app/modules/common/global';
import { toFixedNumber } from '../../../common/services/calculations/calculation';
import { ArticleCalculationService } from '../../../common/services/calculations/article-calculation.service';

@Component({
  selector: 'app-article-modal',
  templateUrl: './article-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleModalComponent implements OnInit, AfterViewInit {
  addArticleFormGroup: FormGroup;
  mwtArticle: MwtArticle;
  selectedWork: Work;
  selectedMainWorkTypeList: MainWorkType[] = [];
  articleDropdownItemList: Article[] = [];
  showContent = false;
  isDiscountEnabled = false;
  isFinalPriceCal = false;
  @ViewChild('articleInput') articleInputVariable: ElementRef;

  @Output() articleSaved = new EventEmitter<MwtArticle>();
  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  constructor(
    private _formBuilder: FormBuilder,
    private commonUiService: CommonUIService,
    private _articleTemplateService: ArticleTemplateService,
    private _modalService: ModalService,
    public dropdownService: DropdownService,
    private _commonUiService: CommonUIService,
    private _calculationService: ArticleCalculationService,
    private cdref: ChangeDetectorRef) {
    this.mwtArticle = new MwtArticle();
    this.selectedWork = new Work();
    this.InitFormGroups();
    this.showContent = true;
  }

  async ngOnInit() {
    if (this.dropdownService.ivaTypeList.length === 0) {
      await this.dropdownService.LoadListAsync([ListNames.IvaTypeList]);
    }
  }

  ngAfterViewInit(): void {
    this.mwtArticle = new MwtArticle();
    this.selectedWork = new Work();
    this.articleDropdownItemList = this._modalService.transferable
      .articleDropdownItemList as Article[];
    if (this.articleDropdownItemList) {
      this.articleDropdownItemList = this.articleDropdownItemList
        .filter(x => x.isActive);
    }
    this.selectedMainWorkTypeList = this._modalService.transferable
      .selectedMainWorkTypeList as MainWorkType[];
    this.selectedWork = this._modalService.transferable.selectedWork as Work;
    this.isDiscountEnabled = this._modalService.transferable
      .isDiscountEnabled as boolean;
    if (this._modalService.isEdit) {
      this.OnEdit(this._modalService.transferable.articleTemplate);
    } else {
      this.OnAdd();
    }
  }

  private InitFormGroups() {
    this.addArticleFormGroup = this._formBuilder.group({
      articleId: ['', [ValidateNgSelect]],
      mainWorkTypeId: ['', [ValidateNgSelect]],
      quantity: [], waste: [],
      price: [], discount: [],
      finalPrice: [], description1: [],
      quantityWithWaste: [], priceWithDiscount: [],
      ivaTypeId: [], ivaAmount: []
    });
  }

  OnAdd() {
    try {
      this.addArticleFormGroup.markAsPristine();
      this.addArticleFormGroup.markAsUntouched();
      this.addArticleFormGroup.controls['articleId'].reset();
      this.mwtArticle = new MwtArticle();
      this.mwtArticle.articleId = 0;
      this.mwtArticle.mainWorkTypeId = 0;
      this.mwtArticle.quantity = 1;
      this.mwtArticle.price = 0;
      this.mwtArticle.waste = 0;
      this.mwtArticle.finalPrice = 0;
      this.mwtArticle.discount = 0;
      this.mwtArticle.quantityWithWaste = 0;
      this.mwtArticle.priceWithDiscount = 0;
      this.mwtArticle.ivaTypeId = 0;
      this.mwtArticle.ivaAmount = 0;
      this.mwtArticle.lineDiscountAmount = 0;
      this.mwtArticle.lineSubTotal = 0;
      this.cdref.markForCheck();
    } catch (error) { console.log(error); }
  }

  OnEdit(article: any) {
    try {
      this.addArticleFormGroup.controls['articleId'].reset();
      this.mwtArticle = Object.assign({}, article);
      this.addArticleFormGroup.controls['articleId'].setValue(article.articleName);
      this.mwtArticle.description1 = article.articleName;
      this.mwtArticle.articleId = article.articleId;
      this.mwtArticle.id = article.mwtArticleId;
      this.mwtArticle.quantity = this.mwtArticle.quantity === null ? 0 : Number(article.quantity);
      this.mwtArticle.price = this.mwtArticle.price === null ? 0 : article.price;
      this.mwtArticle.discount = this.mwtArticle.discount === null ? 0 : article.discount;
      this.mwtArticle.waste = this.mwtArticle.waste === null ? 0 : article.waste;
      this.mwtArticle.mainWorkTypeId = article.workTypeId;
      this.mwtArticle.ivaTypeId = article.ivaTypeId;
      this.mwtArticle.ivaPercentage = article.ivaPercentage;
      this.mwtArticle.ivaAmount = article.ivaAmount;
      this.mwtArticle.lineDiscountAmount = article.lineDiscountAmount;
      this.mwtArticle.lineSubTotal = article.lineSubTotal;
      this.cdref.markForCheck();
      const htmlLabel = document.getElementById('article-label');
      if (htmlLabel) { htmlLabel.click(); }
      this.OnKeyUpQuantityOrWaste();
      this.OnKeyUpPriceOrDiscount();
      this.OnChangeQtyWithWasteOrPriceWithDiscount();
      if (this.articleInputVariable) {
        this.articleInputVariable.nativeElement.click();
      }
    } catch (error) { console.log(error); }
  }

  async OnChangeArticle(article: { id: number, label: string }) {
    try {
      if (article.id) {
        this.commonUiService.isSpinnerVisible = true;
        this.mwtArticle.articleId = article.id;
        this.mwtArticle.description1 = article.label;
        await this.OnSelectArticle();
      }
    } catch (error) { console.log(error); } finally {
      this.OnChangeWorkTypeOrArticle();
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnChangeWorkTypeOrArticle() {
    try {
      if (this.selectedMainWorkTypeList && this.mwtArticle.mainWorkTypeId
        && Number(this.mwtArticle.articleId)) {
        const workTypeId = this.selectedMainWorkTypeList.find(x => Number(x.id)
          === Number(this.mwtArticle.mainWorkTypeId)).workTypeId;
        const quantity = await this._articleTemplateService
          .GetQuantityByWorkTypeArticleId(workTypeId, Number(this.mwtArticle.articleId));
        this.mwtArticle.quantity = quantity ? Number(quantity) : 1;
        this.cdref.markForCheck();
        this.OnKeyUpQuantityOrWaste();
        this.OnKeyUpPriceOrDiscount();
      }
    } catch (error) { console.log(error); }
  }

  async OnSelectArticle() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (this.mwtArticle.articleId) {
        if (this.selectedWork.clientId) {
          const mwtArticle = await this._articleTemplateService
            .GetMwtArticlePriceDetails(this.mwtArticle.articleId, this.selectedWork.clientId);
          if (mwtArticle) {
            this.mwtArticle.price = mwtArticle.price !== null ? mwtArticle.price : 0;
            this.mwtArticle.discount = mwtArticle.discount !== null ? mwtArticle.discount : 0;
            this.mwtArticle.discountAmount = mwtArticle.discountAmount ? mwtArticle.discountAmount : 0;
            this.mwtArticle.isAmount = mwtArticle.isAmount !== null ? mwtArticle.isAmount : false;
            this.mwtArticle.waste = mwtArticle.waste !== null ? mwtArticle.waste : 0;
            this.mwtArticle.priceWithDiscount = mwtArticle.priceWithDiscount !== null
              ? mwtArticle.priceWithDiscount : 0;
            this.mwtArticle.quantityWithWaste = mwtArticle.quantityWithWaste !== null
              ? mwtArticle.quantityWithWaste : 0;
            this.mwtArticle.lineDiscountAmount = mwtArticle.lineDiscountAmount !== null
              ? mwtArticle.lineDiscountAmount : 0;
            this.mwtArticle.lineSubTotal = mwtArticle.lineSubTotal !== null ? mwtArticle.lineSubTotal : 0;

            if (mwtArticle.ivaTypeId) {
              this.mwtArticle.ivaTypeId = Number(mwtArticle.ivaTypeId);
              this.mwtArticle.ivaAmount = toFixedNumber(mwtArticle.ivaAmount ? mwtArticle.ivaAmount : 0);
              this.mwtArticle.ivaPercentage = toFixedNumber(mwtArticle.ivaPercentage);
            } else {
              this.mwtArticle.ivaTypeId = 0;
              this.mwtArticle.ivaAmount = 0;
              this.mwtArticle.ivaPercentage = 0;
            }

            let workTypeId = 0;
            if (this.selectedMainWorkTypeList.length === 1) {
              workTypeId = this.selectedMainWorkTypeList[0].workTypeId;
            } else {
              const mwt = this.selectedMainWorkTypeList.find(m => Number(m.id)
                === Number(this.mwtArticle.mainWorkTypeId));
              if (mwt) { workTypeId = mwt.workTypeId; }
            }
            if (workTypeId > 0) {
              const quantity = await this._articleTemplateService
                .GetQuantityByWorkTypeArticleId(workTypeId, Number(this.mwtArticle.articleId));
              this.mwtArticle.quantity = quantity ? quantity : 1;
            } else { this.mwtArticle.quantity = 1; }
            this.OnKeyUpQuantityOrWaste();
            this.OnKeyUpPriceOrDiscount();
          }
        }
        const article = this.articleDropdownItemList.find(x => Number(x.id)
          === Number(this.mwtArticle.articleId));
        if (article) { this.mwtArticle.description1 = article.description1; }
      } else { this.mwtArticle.description1 = ''; }
    } catch (error) { console.log(error); }
    finally {
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  OnKeyUpQuantityOrWaste() {
    if (this.mwtArticle.waste !== undefined && this.mwtArticle.quantity !== undefined) {
      this.mwtArticle.quantity = Number(this.mwtArticle.quantity);
      this.mwtArticle.quantityWithWaste =
        this._calculationService
          .getQuantityWithWaste(toFixedNumber(this.mwtArticle.quantity),
            toFixedNumber(this.mwtArticle.waste));
    }
    this.cdref.markForCheck();
  }

  OnKeyUpPriceOrDiscount() {
    this.isFinalPriceCal = false;
    if (this.mwtArticle.price !== undefined && this.mwtArticle.discount !== undefined) {
      this.mwtArticle.priceWithDiscount = this._calculationService.
        getPriceWithDiscount(this.mwtArticle.price,
          this.mwtArticle.isAmount ? toFixedNumber(this.mwtArticle.discountAmount)
            : toFixedNumber(this.mwtArticle.discount), this.mwtArticle.isAmount);
    }
    this.cdref.markForCheck();
  }

  OnChangeQtyWithWasteOrPriceWithDiscount() {
    if (!this.isFinalPriceCal) {
      if (this.mwtArticle.priceWithDiscount !== undefined
        && this.mwtArticle.quantityWithWaste !== undefined) {
        const finalPriceWithoutIva =
          this._calculationService
            .getArticleFinalTotal(toFixedNumber(this.mwtArticle.quantityWithWaste),
              toFixedNumber(this.mwtArticle.priceWithDiscount), 0);

        if (this.mwtArticle.ivaTypeId) {
          this.mwtArticle.ivaAmount = this._calculationService
            .getIvaAmount(finalPriceWithoutIva,
              this.mwtArticle.ivaPercentage ? toFixedNumber(this.mwtArticle.ivaPercentage) : 0);
        }
        this.mwtArticle.finalPrice = toFixedNumber(finalPriceWithoutIva
          + this.mwtArticle.ivaAmount);

        this.mwtArticle.discountAmount = this._calculationService
          .getDiscountAmount(this.mwtArticle.price, this.mwtArticle.discount);

        this.mwtArticle.lineSubTotal = this.GetLineSubTotal(this.mwtArticle.price,
          this.mwtArticle.quantityWithWaste);

        this.mwtArticle.lineDiscountAmount = this
          .GetLineDiscountAmount(this.mwtArticle.finalPrice,
            this.mwtArticle.ivaAmount, this.mwtArticle.lineSubTotal);
      }
    }
    this.cdref.markForCheck();
  }

  GetLineDiscountAmount(finalTotal: number, vatAmount: number, subTotal: number) {
    try {
      return toFixedNumber(subTotal - (finalTotal - vatAmount));
    } catch (error) { console.log(error); }
  }

  GetLineSubTotal(price: number, quantityWithWaste: number) {
    try {
      return this._calculationService
        .getLineSubTotal(price, quantityWithWaste);
    } catch (error) { console.log(error); }
  }

  OnKeyUpPriceWithDiscount(e: any) {
    if (e.keyCode === 9) {
      if (e.preventDefault) { e.preventDefault(); }
      return false;
    }
    this.isFinalPriceCal = false;
    if (this.mwtArticle.priceWithDiscount !== undefined &&
      this.mwtArticle.discount !== undefined) {
      this.mwtArticle.price = this._calculationService
        .getPriceFromPriceWithDiscount(toFixedNumber(this.mwtArticle.priceWithDiscount),
          this.mwtArticle.isAmount ? toFixedNumber(this.mwtArticle.discountAmount)
            : toFixedNumber(this.mwtArticle.discount), this.mwtArticle.isAmount);
    }
    this.cdref.markForCheck();
  }

  OnKeyUpFinalPrice() {
    this.isFinalPriceCal = true;
    if (this.mwtArticle.finalPrice !== undefined && this.mwtArticle.ivaAmount !== undefined) {
      const finalPriceWithoutIva = toFixedNumber(this.mwtArticle.finalPrice - this.mwtArticle.ivaAmount);
      this.mwtArticle.priceWithDiscount = toFixedNumber(finalPriceWithoutIva
        / this.mwtArticle.quantityWithWaste);
      this.mwtArticle.price = this._calculationService
        .getPriceFromPriceWithDiscount(toFixedNumber(this.mwtArticle.priceWithDiscount),
          this.mwtArticle.isAmount ? toFixedNumber(this.mwtArticle.discountAmount)
            : toFixedNumber(this.mwtArticle.discount), this.mwtArticle.isAmount);
    }
    this.cdref.markForCheck();
  }

  OnSaveMwtArticle() {
    if (this.selectedMainWorkTypeList.length === 1) {
      this.addArticleFormGroup.controls['mainWorkTypeId'].clearValidators();
      this.addArticleFormGroup.controls['mainWorkTypeId'].setErrors(null);
      this.addArticleFormGroup.controls['mainWorkTypeId'].setValidators(null);
    } else { this.addArticleFormGroup.controls['mainWorkTypeId'].setValidators(ValidateNgSelect); }
    this.addArticleFormGroup.updateValueAndValidity();
    if (this.addArticleFormGroup.invalid) {
      ShowInvalidFormControls(this.addArticleFormGroup);
    } else {
      this.showContent = false;
      this.articleSaved.emit(this.mwtArticle);
    }
    this.cdref.markForCheck();
  }

  OnClose() {
    this.showContent = false;
    this.closeModel.emit();
  }

  OnClearArticle() {
    this.addArticleFormGroup.controls
      .articleId.setValue('');
  }

  OnChangeIvaType() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (Number(this.mwtArticle.ivaTypeId)) {
        const iType = this.dropdownService.ivaTypeList
          .find(it => it.id === Number(this.mwtArticle.ivaTypeId));
        if (iType) { this.mwtArticle.ivaPercentage = iType.iva; }
      } else { this.mwtArticle.ivaPercentage = 0; }
      this.mwtArticle.ivaAmount = 0;
      this.OnChangeQtyWithWasteOrPriceWithDiscount();
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }
}
