import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';

@Component({
  selector: 'app-external-work-attachments',
  templateUrl: './external-work-attachments.component.html',
  styleUrls: ['./external-work-attachments.component.scss']
})
export class ExternalWorkAttachmentsComponent implements OnInit {

  showContent = false;
  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  constructor(public commonUiService: CommonUIService,) { }

  ngOnInit() {
  }

  OnClose() {
    this.showContent = false;
    this.closeModel.emit();
    this.commonUiService.isPopupOpened = false;
  }

}
