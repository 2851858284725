import API from './../../common/api.config.json';
import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import { ClientDefaultPrintReport } from '../../client/entities/client-default-print-report';
import { IvaBreakdown } from '../../auxiliary/entities/iva-breakdown';
import * as _ from 'lodash';
import { toFixedNumber } from '../../common/services/calculations/calculation';
@Injectable({
  providedIn: 'root'
})
export class DeliverynoteService {

  ivaBreakdownList: IvaBreakdown[] = [];

  constructor(private _apiService: ApiService) { }

  GetSavedTemplateByClientId(clientId: number) {
    return this._apiService.get(API.delivery_note.getSavedTemplateByClientId + clientId)
      .map(x => x as ClientDefaultPrintReport).toPromise();
  }

  LoadVatBreakdown(list: IvaBreakdown[]) {
    // according to vat type the list is grouped and sum
    this.ivaBreakdownList = [];
    var groupList = _.toArray(_.groupBy(list, 'ivaTypeId')) as any[];
    if (groupList) {
      for (let i = 0; i < groupList.length; i++) {
        if (groupList[i][0] && groupList[i][0].ivaTypeId) {
          const totalVatAmount = groupList[i].reduce((sum, current) => sum + current.ivaAmount, 0);
          const totalBase = groupList[i].reduce((sum, current) => sum + current.base, 0);
          const iva = _.toArray(groupList[i]) as any[];
          if (iva && iva[0]) {
            this.ivaBreakdownList.push({
              ivaTypeId: iva[0].ivaTypeId,
              iva: iva[0].iva,
              base: totalBase ? toFixedNumber(Number(totalBase)) : 0,
              ivaAmount: totalVatAmount ? toFixedNumber(Number(totalVatAmount)) : 0
            });
          }
        }
      }
    }
    return this.ivaBreakdownList;
  }

}
