export class MwtClinicMaterial {
    id: number;
    mainWorkTypeId: number;
    clinicMaterialId: number;
    quantity: number;
    note: string;
    clinicMaterialName: string;
    mainWorkTypeName: string;
    status: boolean;
    workId: number;
    cssClass: string;
    symbol: string;
    order: number;
    createdBy: number;
    createdDate: string;
    isClosed: boolean;

    constructor(init?: Partial<MwtClinicMaterial>) { if (init) { Object.assign(this, init); } }
}
