import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import { CommonFunctions } from '../../common/services/common-functions';
import API from './../../common/api.config.json';
import { MwtIncident } from '../entities/main-work-type-incident';
import { MwtIncidentDocument } from '../entities/main-work-type-incident-document';

@Injectable({
  providedIn: 'root'
})
export class IncidentService {

  constructor(private _apiService: ApiService) { }

  GetIncidentListByMainWorkTypeIdList(idList: { id: number }[]) {
    try {
      const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
      if (params) {
        const query = params.toString().replace('+', '%20');
        return this._apiService.get(API.work_panels.getMwtincidentListByMainWorkTypeIdList + query, true)
          .map(n => n as MwtIncident[]).toPromise();
      }
    } catch (error) { console.log(error); }
  }

  SaveIncident(incident: MwtIncident) {
    return this._apiService.post(API.incident_data.saveIncident, incident, false)
      .toPromise();
  }

  DeleteIncident(id: number, workId: number) {
    return this._apiService.delete(API.incident_data.deleteIncident, id.toString() + '/' + workId, true)
      .map(isDeleted => isDeleted as boolean)
      .toPromise();
  }

  public async SaveIncidentDataDocument(document: MwtIncidentDocument): Promise<MwtIncidentDocument> {
    return new Promise<MwtIncidentDocument>((resolve, reject) => {
      this._apiService.post(API.incident_data.saveIncidentDataDocument, document, true)
        .subscribe((externalDocument: MwtIncidentDocument) => resolve(externalDocument),
          err => reject(err));
    });
  }
}
