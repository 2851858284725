import { Client } from '../../work/entities/client';
export class AutomaticInvoiceModel {
    fromDeliveryDate: any;
    fromDeliveryDateObject: any;
    toDeliveryDate: any;
    toDeliveryDateObject: any;
    deliveryNoteIssuerId: any;
    deliveryNoteSeriesId: any;
    automaticInvoiceClientList: { id: number, name: string, isSelected: boolean }[] = [];
    defaultOrSpecificIssuerAndSeries: string;
    automaticInvoiceIssuerId: any;
    automaticInvoiceSeiriesId: any;
    automaticInvoiceDate: any;
    automaticInvoiceDateObject: any;
    numOfInvoicesToReserve: number;
    automaticInvoiceComments: string;
    clientIdList: number[];
    userId: number;
}