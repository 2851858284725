export class DateFormat {
  day: number;
  month: number;
  year: number;
  isFullDayLeave: boolean;
  absenceReasonText: string;
  startTime: string;
  endTime: string;
  isWorkingDay: boolean;

  constructor(_day?: number, _month?: number, _year?: number, _isFullDayLeave?: boolean, _absenceReasonText?: string,
    _isWorkingDay?: boolean) {
    this.day = _day;
    this.month = _month;
    this.year = _year;
    this.isFullDayLeave = _isFullDayLeave;
    this.absenceReasonText = _absenceReasonText;
    this.isWorkingDay = _isWorkingDay;
  }
}
