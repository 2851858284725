import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import API from '../../common/api.config.json';
import { Town } from '../entities/town';
import { SubscriptionReason } from '../entities/subscription-reason.model';
import { LabCalendarConfiguration } from '../../lab-calender/entities/lab-calendar-configuration';
import { LabSection } from '../../work/entities/lab-section';

@Injectable({
  providedIn: 'root'
})
export class AuxiliaryService {

  onUpdateTown: EventEmitter<{ pc: string, description: string, model: string }> = new EventEmitter();

  constructor(private _apiService: ApiService) { }

  GetTownById(id: number) {
    return this._apiService.get(API.town.getTownById + id)
      .map(town => town as Town).toPromise();
  }

  GetSubscriptionReasonList() {
    return this._apiService.get(API.reasonForSubscription.getReasonForSubscription)
      .map(list => list as SubscriptionReason[]).toPromise();
  }

  GetLabSectionList() {
    return this._apiService.get(API.lab_section.getLabSections)
      .map(list => list as LabSection[]).toPromise();
  }

  GetTownList() {
    return this._apiService.get(API.town.getTownList)
      .map(list => list as Town[]).toPromise();
  }

  GetLabCalendarConfigurationByYear(issuerId: number, year: string) {
    return this._apiService.get(API.lab_calendar_configuration.get_lab_calendar_configuration_by_year + issuerId + '/' + year)
      .map(config => config as LabCalendarConfiguration).toPromise();
  }

  SaveLabCalendarConfiguration(configuration: LabCalendarConfiguration) {
    return this._apiService.post(API.lab_calendar_configuration.save_lab_calendar_configuration, configuration).toPromise();
  }

  UpdateTown(id: number, town: Town) {
    return this._apiService.put(API.town.editTown + id, town, false).toPromise();
  }
}
