export class DentboxWorkStatusModel {
    action: 'Accept' | 'Reject';
    dateTime: string;
    user: string;
    workNo?: string;
    reason?: string;

    constructor(init?: Partial<DentboxWorkStatusModel>) {
        if (init) { Object.assign(this, init); }
    }
}

