import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalNames } from 'src/app/modules/common/global';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { ValidateNgSelect } from 'src/app/modules/common/services/validators';
import { Budget } from '../../entities/budget';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtArticle } from '../../entities/main-work-type-article';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';

@Component({
  selector: 'app-budget-main-work-type-modal',
  templateUrl: './budget-main-work-type-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BudgetMainWorkTypeModalComponent implements OnInit, AfterViewInit {
  @Output() onBudjetMainWorkTypeModalClose = new EventEmitter();

  showContent = false;

  // objects
  transferable: any = {};
  budget: Budget;
  selectedWork: Work;
  selectedSubWork: SubWork;
  selectedMainWorkType: MainWorkType;
  mainWorkTypeList: MainWorkType[];

  // lists
  selectedMainWorkTypeList: MainWorkType[] = [];
  deliveryMwtArticleList: MwtArticle[] = [];

  // form goups
  budgetMainWorkTypeFormGroup: FormGroup;

  constructor(
    private _modalService: ModalService,
    private _formBuilder: FormBuilder,
    private _apiService: ApiService,
    private _workService: WorkService,
    public commonUiService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: Toast,
    private cdref: ChangeDetectorRef
  ) {
    this.budget = new Budget();

    this.InitFormGroups();
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this._modalService.modalSubject.subscribe(modalObject => {
      if (modalObject) {
        if (modalObject.modal === ModalNames.BudgetMainWorkTypeModal) {
          if (
            modalObject.flag &&
            modalObject.transferable != null &&
            modalObject.transferable.selectedMainWorkTypeList != null &&
            modalObject.transferable.budget != null
          ) {
            this.transferable = modalObject.transferable;
            this.budget = modalObject.transferable.budget;
            this.selectedMainWorkTypeList =
              modalObject.transferable.selectedMainWorkTypeList;
            this.selectedWork = modalObject.transferable.selectedWork;
            this.selectedSubWork = modalObject.transferable.selectedSubWork;
            this.selectedMainWorkType =
              modalObject.transferable.selectedMainWorkType;
            this.mainWorkTypeList = modalObject.transferable.mainWorkTypeList;
            this.deliveryMwtArticleList = modalObject.transferable.deliveryMwtArticleList;
            this.cdref.markForCheck();
            this.OnOpenModal();
          }
          this.showContent = modalObject.flag;
        }
      }
    });
  }

  InitFormGroups() {
    this.budgetMainWorkTypeFormGroup = this._formBuilder.group({
      mainWorkTypeId: ['', [ValidateNgSelect]]
    });
  }

  OnOpenModal() {
    this.budgetMainWorkTypeFormGroup.reset();
    this.budgetMainWorkTypeFormGroup.controls.mainWorkTypeId.setValue(
      this.budget.workTypeId
    );
    this.cdref.markForCheck();
  }

  OnClose() {
    this.onBudjetMainWorkTypeModalClose.emit();
    this.showContent = false;
  }

  public OnSaveAsDeliveryNote(event: any) {
    if (this.budget && this.deliveryMwtArticleList) {
      try {
        this.commonUiService.isSpinnerVisible = true;
        this.budget.generatedDate = this.budget.generatedDate
          ? new Date(
            this.commonUiService.ConvertDateFormatToString(
              this.budget.generatedDate
            )
          ).toISOString()
          : null;
        this.budget.validDate = this.budget.validDate
          ? new Date(
            this.commonUiService.ConvertDateFormatToString(
              this.budget.validDate
            )
          ).toISOString()
          : null;
        this.budget.subWorkId = this.selectedSubWork.id;
        this.SaveBudgetAsDeliveryNote(this.budget).then(
          (args: { isSaved: boolean, message: string }) => {
            if (args.isSaved) {
              this._workService.SetDetailObject({
                selectedMainWorkTypeList: this.selectedMainWorkTypeList,
                selectedMainWorkType: this.selectedMainWorkType,
                selectedSubWork: this.selectedSubWork,
                selectedWork: this.selectedWork,
                mainWorkTypeList: this.mainWorkTypeList,
                invokeComponents: ['DeliveryNote', 'Article']
              });
              this._toastyService.success({
                title: 'Budgets',
                msg: this._commonDataService.localizationLabelList['save_success']
              });
              this.showContent = false;
            } else {
              if (args.message === 'no_series') {
                this._toastyService.error({
                  title: 'Budgets',
                  msg: this._commonDataService.localizationLabelList['no_series'],
                });
              } else {
                this._toastyService.error({
                  title: 'Budgets',
                  msg: this._commonDataService.localizationLabelList['save_error'],
                });
              }
              this.showContent = false;
            }
            this.cdref.markForCheck();
            this.onBudjetMainWorkTypeModalClose.emit();
            this.commonUiService.isSpinnerVisible = false;
          });
      } catch (error) {
        this._toastyService.error({
          title: 'Budgets',
          msg: this._commonDataService.localizationLabelList['save_error'],
        });
        this.showContent = false;
        this.cdref.markForCheck();
        this.onBudjetMainWorkTypeModalClose.emit();
        this.commonUiService.isSpinnerVisible = false;
      }
    }
  }

  private async SaveBudgetAsDeliveryNote(budget: Budget): Promise<{ isSaved: boolean, message: string }> {
    return this._apiService
      .post(API.budget.saveAsDeliveryNote, budget, true)
      .toPromise();
  }
}
