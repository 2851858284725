import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/modules/common/services/api.service';
import API from './../../common/api.config.json';
import { InvoiceCancellation } from '../entities/invoice-cancellation';

@Injectable({
    providedIn: 'root'
})
export class InvoiceCancellationService {

    constructor(private _apiService: ApiService) { }

    SaveInvoiceCancellation(cancellation: InvoiceCancellation) {
        return this._apiService.post(API.invoice_cancellation.save_invoice_cancellation,
            cancellation, false).toPromise();
    }
}
