export class WarehouseStockMovement {

  constructor() {
    this.isEdit = false;
  }

  id: number;
  warehouseId: number;
  productId: number;
  description: string;
  transactionDate: any;
  lotId: number;
  stockIn: number;
  stockOut: number;
  price: number;
  expireDate: any;
  typeofworkNo: string;
  providerId?: number;
  productGroupId: number;
  outType: string;
  reason: string;
  createdDate: any;
  createdBy: number;
  modifiedDate: any;
  modifiedBy: number;

  transactionDateView: any;
  isEdit: boolean;

  labSectionId: number | null;
  regularizationReasonId: number | null;
  lotName: string;
  productName: string;
  providerName: string;
  warehouseName: string;
  actualStock: number | null;
}
