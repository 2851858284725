import {
  Component, OnInit, Input, Output, EventEmitter,
  SimpleChanges, OnChanges, KeyValueDiffer, KeyValueDiffers
} from '@angular/core';
import { InvoiceHeader } from '../../entities/invoice-header';
import { InvoiceDetail } from '../../entities/invoice-detail';
import { DeliveryNote } from '../../../work/entities/delivery-note';
import { CommonDataService } from '../../../common/services/common.data.service';
import { ApiService } from '../../../common/services/api.service';
import { Issuer } from '../../../client/entities/client-issuer';
import API from '../../../common/api.config.json';
import { Series } from '../../entities/series';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { DoCheck } from '@angular/core';
import { DropDownOption } from '../../../common/entities/dropdown-option';
import { CommonFunctions } from '../../../common/services/common-functions';
import { Toast } from '../../../../modules/common/services/toast.service';
import { toFixedNumber } from '../../../common/services/calculations/calculation';

const NUM_OF_NOTES = 5;

@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html',
  styleUrls: ['./invoice-edit.component.scss']
})
export class InvoiceEditComponent implements OnInit, OnChanges, DoCheck {

  /* emitters */
  @Output() OnInvoiceDetailListToBeSavedChanged: EventEmitter<InvoiceDetail[]> = new EventEmitter<InvoiceDetail[]>();
  @Output() OnPendingInvoiceDetailListChanged: EventEmitter<InvoiceDetail[]> = new EventEmitter<InvoiceDetail[]>();

  /* properties */
  @Input() invoiceHeader: InvoiceHeader;
  @Input() pendingInvoiceDetailList: InvoiceDetail[];
  @Input() invoicedDeliveryNoteList: any[];
  @Input() pendingDeliveryNoteList: DeliveryNote[];
  @Input() load: string;
  @Input() singleDeliveryNoteId: number;

  // lists
  issuerList: Issuer[] = [];
  issuerDropdownList: DropDownOption[] = [];
  seriesList: Series[] = [];
  __invoicedDeliveryNoteList: any[] = [];

  currentPageNumber = 1;
  pages = [];
  showPendingClicked = false;
  isAllChecked = false;
  differ: KeyValueDiffer<string, any>;
  deliveryNoteFilter: {
    fromDate: any, toDate: any, issuerId?: any, seriesId?: any
  } = { fromDate: null, toDate: null, issuerId: '0', seriesId: 0 };

  currentPageSize = 0;

  constructor(private _toastyService: Toast,
    public _commonDataService: CommonDataService,
    private _commonUiService: CommonUIService,
    private _apiService: ApiService,
    private differs: KeyValueDiffers
  ) { this.differ = this.differs.find({}).create(); }

  ngOnInit() {
    if (this.invoicedDeliveryNoteList.length != null) {
      this.getPages();
      this.GoToPage({
        count: this.invoicedDeliveryNoteList.length,
        currentPageNumber: this.currentPageNumber
      });
    }
    this.currentPageSize = 0;
    this.OnShowPending();
    this.LoadIssuerList();
    this.LoadSeriesList();
    this.isAllChecked = this.invoicedDeliveryNoteList.length === 0 ? false :
      this.GetCheckedUncheckedStatus(this.invoicedDeliveryNoteList);
  }

  ngOnChanges(changes: SimpleChanges) {
    const loadedInvoicedDeliveryNoteListChange = changes['invoicedDeliveryNoteList'];
    if (loadedInvoicedDeliveryNoteListChange) {
      const list: any[] = loadedInvoicedDeliveryNoteListChange.currentValue;
      if (list) {
        if (this.invoicedDeliveryNoteList.length) {
          this.GoToPage({
            count: this.invoicedDeliveryNoteList.length,
            currentPageNumber: this.currentPageNumber
          });
        }
        this.OnShowPending();
      }
    }
  }

  ngDoCheck() {
    const change = this.differ.diff(this);
    if (change) {
      this.OnShowPending();
    }
  }

  //#region Dropdowns

  private LoadIssuerList() {
    this._apiService.get(API.issuer.getIssuerList)
      .subscribe((list: Issuer[]) => this.issuerList = list,
        err => console.log(err),
        () => {
          this.issuerDropdownList = this._commonDataService
            .GetDropDownList(this.issuerList, 'description');

          this.issuerDropdownList = CommonFunctions
            .Sort(this.issuerDropdownList, 'label');

          this.issuerDropdownList.unshift(
            new DropDownOption('0',
              this._commonDataService.localizationLabelList['select'], false));

        });
  }

  private LoadSeriesList() {
    this._apiService.get(API.series.getSeriesList)
      .subscribe((list: Series[]) => this.seriesList = list,
        err => console.log(err),
        () => {
          this.seriesList = this.seriesList
            .filter(x => x.seriesType.toLowerCase() === 'delivery note');
        });
  }

  //#endregion

  //#region Events

  OnToggleLeftCheckBoxes(deliveryNoteId: number) {
    if (this.invoicedDeliveryNoteList && deliveryNoteId &&
      this.invoiceHeader.invoiceDetailList) {
      const invoiceDeliveryNote: any = this.invoicedDeliveryNoteList
        .find(x => Number(x.deliveryNoteId) === deliveryNoteId);
      if (invoiceDeliveryNote) {
        invoiceDeliveryNote.isChecked = !invoiceDeliveryNote.isChecked;
      }
      const checkedDeliveryNoteList: any[] = [...this.invoicedDeliveryNoteList
        .filter(x => x.isChecked)];
      if (checkedDeliveryNoteList) {
        const list: InvoiceDetail[] = [];
        this.invoiceHeader.invoiceDetailList.forEach(detail => {
          const index: number = checkedDeliveryNoteList
            .findIndex(x => Number(x.deliveryNoteId) === detail.deliveryNoteId);
          if (index !== -1) { list.push(detail); }
        });
        this.OnInvoiceDetailListToBeSavedChanged.emit(list);
      }
      this.isAllChecked = this.GetCheckedUncheckedStatus(this.invoicedDeliveryNoteList);
    }
  }

  OnShowPending() {
    this.showPendingClicked = false;
    if (this.pendingDeliveryNoteList) {
      this.pendingDeliveryNoteList.forEach(deliveryNote => {
        const index: number = this.invoicedDeliveryNoteList
          .findIndex(x => Number(x.deliveryNoteId) === deliveryNote.id);
        if (index === -1) {
          this.invoicedDeliveryNoteList.push(
            {
              deliveryNoteGenNo: deliveryNote.generateNo,
              deliveryNoteId: deliveryNote.id,
              isChecked: this.load === 'all',
              isVisible: true,
              isCollapsed: true,
              deliveryNoteConcept: deliveryNote.concept,
              deliveryNoteIssuerId: deliveryNote.issuerId,
              deliveryNoteSeriesId: deliveryNote.seriesId,
              deliveryNoteGenDate: deliveryNote.generateDate,
              deliveryNoteGenDateObject: new Date(deliveryNote.generateDate
                .toString().substring(0, 10)),
              deliveryNoteTotalPrice: toFixedNumber(deliveryNote.totalFinalPrice ?
                deliveryNote.totalFinalPrice : deliveryNote.totalPrice)
            });
        }
        // tslint:disable-next-line: no-shadowed-variable
        this.invoicedDeliveryNoteList.forEach(deliveryNote => {
          if (Number(deliveryNote.deliveryNoteId) ===
            this.singleDeliveryNoteId && this.load === 'single') {
            deliveryNote.isChecked = true;
            this.OnInvoiceDetailListToBeSavedChanged.emit();
          }
        });
      });

      this.pendingInvoiceDetailList.forEach(detail => {
        const index: number = this.invoiceHeader.invoiceDetailList ?
          this.invoiceHeader.invoiceDetailList.indexOf(detail) : -1;
        if (index === -1) {
          if (this.invoiceHeader.invoiceDetailList) {
            this.invoiceHeader.invoiceDetailList.push(detail);
          }
        }
      });

      this.OnInvoiceDetailListToBeSavedChanged
        .emit(this.invoiceHeader.invoiceDetailList ? [...this.invoiceHeader
          .invoiceDetailList.filter(x => x.isInvoiced)] : []);
      if (this.invoicedDeliveryNoteList.length) {
        this.GoToPage({
          count: this.invoicedDeliveryNoteList.length,
          currentPageNumber: this.currentPageNumber
        });
      }

      if (this.__invoicedDeliveryNoteList.length === 0) {
        this.__invoicedDeliveryNoteList = [...this.invoicedDeliveryNoteList];
      }

      this.isAllChecked = this.GetCheckedUncheckedStatus(this.invoicedDeliveryNoteList);
      this.getPages();
    }
  }

  GoToPrevious() {
    this.currentPageNumber--;
    if (this.invoicedDeliveryNoteList.length) {
      this.GoToPage({
        count: this.invoicedDeliveryNoteList.length,
        currentPageNumber: this.currentPageNumber
      });
    }
  }

  GoToNext() {
    this.currentPageNumber++;
    if (this.invoicedDeliveryNoteList.length) {
      this.GoToPage({
        count: this.invoicedDeliveryNoteList.length,
        currentPageNumber: this.currentPageNumber
      });
    }
  }

  GoToPage(pageData: { count: number, currentPageNumber: number }) {
    const first: number = (pageData.currentPageNumber - 1) * NUM_OF_NOTES;
    const last: number = pageData.currentPageNumber * NUM_OF_NOTES;
    this.invoicedDeliveryNoteList.forEach(item => {
      item.isVisible = false;
      item.isCollapsed = true;
      const index: number = this.invoicedDeliveryNoteList.indexOf(item);
      if (first <= index && index < last) { item.isVisible = true; }
      this.currentPageNumber = pageData.currentPageNumber;
    });
  }

  OnFilterDeliveryNoteList() {
    this.invoicedDeliveryNoteList = [...this.__invoicedDeliveryNoteList];
    if (this.deliveryNoteFilter) {
      if (this.deliveryNoteFilter.issuerId && this.deliveryNoteFilter.issuerId !== '0') {
        this.invoicedDeliveryNoteList =
          this.invoicedDeliveryNoteList.filter(x =>
            Number(x.deliveryNoteIssuerId) === Number(this.deliveryNoteFilter.issuerId));
      }
      if (this.deliveryNoteFilter.seriesId && this.deliveryNoteFilter.seriesId !== '0') {
        this.invoicedDeliveryNoteList =
          this.invoicedDeliveryNoteList.filter(x =>
            Number(x.deliveryNoteSeriesId) === Number(this.deliveryNoteFilter.seriesId));
      }
      if (this.deliveryNoteFilter.fromDate && this.deliveryNoteFilter.toDate) {
        const from = new Date(this._commonUiService
          .ConvertDateFormatToString(this.deliveryNoteFilter.fromDate).substring(0, 10));
        const to = new Date(this._commonUiService
          .ConvertDateFormatToString(this.deliveryNoteFilter.toDate).substring(0, 10));
        this.invoicedDeliveryNoteList =
          this.invoicedDeliveryNoteList.filter(x =>
            from <= x.deliveryNoteGenDateObject && to >= x.deliveryNoteGenDateObject);
      } else {
        if (this.deliveryNoteFilter.toDate && (this.deliveryNoteFilter.fromDate === undefined
          || this.deliveryNoteFilter.fromDate === null)) {
          this._toastyService.error({
            title: this._commonDataService.localizationLabelList['invoice'],
            msg: this._commonDataService.localizationLabelList['from_date_is_missing']
          });
        }
        if (this.deliveryNoteFilter.fromDate &&
          (this.deliveryNoteFilter.toDate === undefined
            || this.deliveryNoteFilter.toDate === null)) {
          this._toastyService.error({
            title: this._commonDataService.localizationLabelList['invoice'],
            msg: this._commonDataService.localizationLabelList['to_date_is_missing']
          });
        }
      }
    }
  }

  OnSelectFromDate() {
    this.deliveryNoteFilter.toDate = this.deliveryNoteFilter.toDate ?
      this.deliveryNoteFilter.toDate : this.deliveryNoteFilter.fromDate;
  }

  OnTogglCommonCheckBoxes(allChecked: boolean) {
    if (allChecked !== undefined) {
      for (let index = 0; index < this.invoicedDeliveryNoteList.length; index++) {
        const deliveryNote = this.invoicedDeliveryNoteList[index];
        deliveryNote.isChecked = allChecked;
        this.isAllChecked = !allChecked;
        this.OnToggleLeftCheckBoxes(deliveryNote.deliveryNoteId);
      }
    }
  }

  //#endregion

  //#region Other

  GetCheckedUncheckedStatus(list: any[]): boolean {
    return list.every(x => x.isChecked === true);
  }

  //#endregion

  getPages() {
    const totalPages = this.invoicedDeliveryNoteList.length === 0 ? 1 :
      Math.ceil(this.invoicedDeliveryNoteList.length / NUM_OF_NOTES);
    if (this.currentPageSize !== totalPages) {
      this.currentPageSize = totalPages;
      this.pages = [];
      const start = 1;
      const end = totalPages;
      let itPage = start;
      while (itPage <= end) {
        this.pages.push(itPage);
        itPage++;
      }
    }
  }
}
