import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import API from 'src/app/modules/common/api.config.json';
import { LocalionTypes, ModalNames } from 'src/app/modules/common/global';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { WorkTypeArticleTemplate, WorkTypePhaseTemplate } from 'src/app/modules/work-type/entities/work-type-model-templates';
import swal from 'sweetalert2';
import { ShowInvalidFormControls, ValidateNgSelect } from '../../../common/services/validators';
import { LabSection } from '../../entities/lab-section';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtArticle } from '../../entities/main-work-type-article';
import { MwtPhase } from '../../entities/main-work-type-phase';
import { Phases } from '../../entities/phases';
import { Work } from '../../entities/work';
import { ArticleTemplateService } from '../../services/article-template.service';
import { LabHolidaysService } from '../../services/lab-holidays.service';
import { PhaseTemplateService } from '../../services/phase-template.service';
import { WorkStatusService } from '../../services/work-status.service';
import { WorkService } from '../../services/work.service';

@Component({
  selector: 'app-phases-modal',
  templateUrl: './phases-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhasesModalComponent implements OnInit, AfterViewInit {
  showContent = false;

  // form groups
  addPhaseFormGroup: FormGroup;

  // lists
  selectedMainWorkTypeList: MainWorkType[] = [];
  mwtArticleList: MwtArticle[] = [];
  phaseStatusList: string[] = [];
  labSectionList: LabSection[] = [];
  phaseList: Phases[] = [];

  // objects
  selectedWork: Work;
  mwtPhase: MwtPhase;
  selectedMainWorkType: MainWorkType;
  articleDropdown = true;
  phaseDropdown = true;
  labSectionDropdown = true;
  localionTypes = [];

  // events
  @Output() updateView = new EventEmitter();
  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  constructor(private _modalService: ModalService,
    private _phaseTemplateService: PhaseTemplateService,
    private _workService: WorkService,
    private _apiService: ApiService,
    private _articleTemplateService: ArticleTemplateService,
    public commonUiService: CommonUIService,
    public _commonDataService: CommonDataService,
    private _formBuilder: FormBuilder,
    private _toastyService: Toast,
    private _statusService: WorkStatusService,
    private cdref: ChangeDetectorRef,
    public dropdownService: DropdownService,
    private _labHolidaysService: LabHolidaysService) {
    this.mwtPhase = new MwtPhase();
    this.localionTypes = LocalionTypes;
    this.addPhaseFormGroup = this._formBuilder.group({
      mainWorkTypeId: ['', [ValidateNgSelect]],
      labSectionName: [''],
      mwtArticleName: [''],
      startDate: [],
      endDate: [],
      phaseStatus: ['', [ValidateNgSelect]],
      phaseName: ['', [ValidateNgSelect]],
      locationId: []
    });
    this.showContent = true;
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.mwtPhase = new MwtPhase();
    this.modalListner();
  }

  async OnOpenAddPhaseModal() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      this.commonUiService.isPopupOpened = true;
      // this.addPhaseFormGroup.markAsPristine();
      // this.addPhaseFormGroup.markAsUntouched();
      this.addPhaseFormGroup.reset();
      this.addPhaseFormGroup.controls.mwtArticleName.disable();
      await this.LoadMwtPhasePopupData();
      if (this.selectedMainWorkTypeList.length === 1) {
        this.mwtPhase.mainWorkTypeId = this.selectedMainWorkTypeList[0].id;
        this.OnChangeMainWorkType(true);
      }
      this.addPhaseFormGroup.controls.locationId.setValue('0');
      this.mwtPhase = new MwtPhase();
      this.mwtPhase.mainWorkTypeId = 0;
      this.mwtPhase.phaseId = 0;
      this.mwtPhase.sectionId = 0;
      this.mwtPhase.mwtArticleId = 0;
      this.mwtPhase.locationId = 0;
      this.mwtPhase.phaseStatus = 'Pendiente';
    } catch (error) { console.log(error); }
    finally {
      this.cdref.markForCheck();
      this.showContent = true;
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  private GetPhaseList() {
    return this._apiService.get(API.phases.getPhasesList).map(list => list as Phases[]).toPromise();
  }

  private GetLabSectionList() {
    return this._apiService.get(API.secciones_laboratorio.getSectionOfLabList)
      .map(list => list as LabSection[]).toPromise();
  }

  OnChangeSection(labSection: LabSection) {
    if (labSection) {
      this.mwtPhase.sectionId = Number(labSection.id);
      this.mwtPhase.labSectionName = labSection.description;
    }
    this.cdref.markForCheck();
  }

  OnChangePhase(phases: Phases) {
    if (phases) {
      this.mwtPhase.phaseId = Number(phases.id);
      this.mwtPhase.phaseName = phases.description;
    }
    this.cdref.markForCheck();
  }

  async OnChangePhaseDates() {
    if (this.mwtPhase.startDateObject) {
      const startDate = this.commonUiService.ChangeNgbDateToCustomFormat(this.mwtPhase.startDateObject);
      const isAvailable = await this._labHolidaysService.checkDate(startDate);
      if (isAvailable) { this.validateDates(); } else {
        this.mwtPhase.startDateObject = null;
        this.addPhaseFormGroup.get('startDate').setValue(null);
      }
    }
    if (this.mwtPhase.endDateObject) {
      const endDate = this.commonUiService.ChangeNgbDateToCustomFormat(this.mwtPhase.endDateObject);
      const isAvailable = await this._labHolidaysService.checkDate(endDate);
      if (isAvailable) { this.validateDates(); } else {
        this.mwtPhase.endDateObject = null;
        this.addPhaseFormGroup.get('endDate').setValue(null);
      }
    }
  }

  private validateDates() {
    if (this.mwtPhase.startDateObject && this.mwtPhase.endDateObject) {
      const start = new Date(this.commonUiService.ConvertDateFormatToString(this.mwtPhase.startDateObject));
      const end = new Date(this.commonUiService.ConvertDateFormatToString(this.mwtPhase.endDateObject));
      this.cdref.markForCheck();
      if (end < start) {
        this.mwtPhase.endDateObject = null;
        this._toastyService.error({
          title: this._commonDataService.localizationLabelList['works'],
          msg: this._commonDataService.localizationLabelList['end_date_should_be_greater_than_start_date']
        });
      }
    }
  }

  OnChangeMwtArticle(mwtArticle: MwtArticle) {
    if (mwtArticle) {
      this.mwtPhase.mwtArticleId = Number(mwtArticle.id);
      this.mwtPhase.mwtArticleName = mwtArticle.description1;
    }
    this.cdref.markForCheck();
  }

  async OnChangeMainWorkType(edit = false) {
    try {
      if (this.mwtPhase.mainWorkTypeId) {
        if (!edit) { this.articleDropdown = true; }
        this.commonUiService.isSpinnerVisible = true; // get mwt article list for selected mwt id
        const templateList: WorkTypeArticleTemplate[] =
          await this._articleTemplateService.GetArticleTemplateListByMainWorkTypeIdList([{ id: this.mwtPhase.mainWorkTypeId }]);
        if (templateList) { // map from WorkTypeArticleTemplate[] to MwtArticle[]
          this.mwtArticleList = templateList.map(x => {
            const mwtArticle = new MwtArticle();
            mwtArticle.workTypeArticleId = x.workTypeArticleId;
            mwtArticle.articleId = x.articleId;
            mwtArticle.id = x.mwtArticleId;
            mwtArticle.description1 = x.articleName;
            return mwtArticle;
          });
        }
        this.addPhaseFormGroup.controls.mwtArticleName.enable();
        this.commonUiService.isSpinnerVisible = false;
        if (!edit) { this.articleDropdown = false; }
      }
      this.cdref.markForCheck();
    } catch (error) { console.log(error); }
  }

  private async LoadMwtPhasePopupData() {
    this.phaseList = await this.GetPhaseList();
    if (this.phaseList) {
      this.phaseList = this.phaseList.filter(x => x.status);
    }
    this.labSectionList = await this.GetLabSectionList();
    if (this.labSectionList) {
      this.labSectionList = this.labSectionList.filter(x => x.status);
    }
    this.phaseStatusList = this._workService.phaseStatusList;
    this.cdref.markForCheck();
  }

  private async ValidatePhaseDates(startDate: string, endDate: string, mainWorkType: MainWorkType) {
    if (startDate && endDate && mainWorkType.deliveryDate != '') {
      const deliveryDate = new Date(mainWorkType.deliveryDate.substring(0, 10));
      const recievedDate = new Date(mainWorkType.receiveDate.substring(0, 10));
      const from = new Date(startDate.substring(0, 10));
      const to = new Date(endDate.substring(0, 10));
      this.cdref.markForCheck();
      if (from >= recievedDate && to <= deliveryDate && from <= deliveryDate && to >= recievedDate) {
        return { isValid: true, message: '' };
      } else {
        if (to > deliveryDate && from >= recievedDate) {
          return { isValid: false, message: 'InvalidDeliveryDate' };
        } else { return { isValid: false, message: 'Phase dates in not in the range' }; }
      }
    } else {
      if (!startDate && endDate && mainWorkType) {
        if (endDate > mainWorkType.deliveryDate) {
          const result0 = await swal({
            title: this._commonDataService.localizationLabelList['tasks'],
            text: this._commonDataService.localizationLabelList[
              'greater_than_type_of_work_date'
            ],
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this._commonDataService.localizationLabelList[
              'common_yes'
            ],
            cancelButtonText: this._commonDataService.localizationLabelList[
              'common_cancel'
            ]
          });
          if (result0.value) {
            mainWorkType.deliveryDate = endDate;
            await this._workService.UpdateMainWorkType(mainWorkType);
            return { isValid: true, message: '' };
          } else { return; }
        }
      }
      if (mainWorkType.deliveryDate == '') {
        const result1 = await swal({
          title: this._commonDataService.localizationLabelList['phases'],
          text: this._commonDataService.localizationLabelList['type_of_work_date_empty'],
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this._commonDataService.localizationLabelList[
            'common_yes'
          ],
          cancelButtonText: this._commonDataService.localizationLabelList[
            'common_cancel'
          ]
        });
        if (result1.value) {
          mainWorkType.deliveryDate = endDate;
          await this._workService.UpdateMainWorkType(mainWorkType);
          return { isValid: true, message: '' };
        } else {
          return { isValid: true, message: '' };
        }
      }
    }
  }

  async OnEditMwtPhase(phaseTemplate: WorkTypePhaseTemplate) {
    if (phaseTemplate) {
      this.labSectionDropdown = true;
      this.phaseDropdown = true;
      this.articleDropdown = true;
      this.commonUiService.isSpinnerVisible = true;
      this.commonUiService.isPopupOpened = true;
      this.addPhaseFormGroup.reset();
      this.mwtPhase = new MwtPhase();
      this.mwtPhase.mainWorkTypeId = phaseTemplate.workTypeId ? phaseTemplate.workTypeId : 0;
      this.addPhaseFormGroup.controls.mainWorkTypeId.setValue(phaseTemplate.workTypeId);
      await this.OnChangeMainWorkType(true);
      await this.LoadMwtPhasePopupData();
      this.addPhaseFormGroup.controls.phaseName.setValue(phaseTemplate.phaseName);
      this.addPhaseFormGroup.controls.labSectionName.setValue(phaseTemplate.labSectionName);
      this.addPhaseFormGroup.controls.mwtArticleName.setValue(phaseTemplate.mwtArticleName);
      this.addPhaseFormGroup.controls.locationId.setValue(phaseTemplate.locationId ? phaseTemplate.locationId : '0');
      this.mwtPhase.id = phaseTemplate.mwtPhaseId;

      if (this.mwtArticleList) {
        let mwtA = new MwtArticle();
        if (phaseTemplate.workTypeArticleId) {
          mwtA = this.mwtArticleList
            .find(mwtA => mwtA.workTypeArticleId === phaseTemplate.workTypeArticleId);
        } else {
          mwtA = this.mwtArticleList
            .find(mwtA => mwtA.id === phaseTemplate.mwtArticleId);
        }
        if (mwtA) {
          this.mwtPhase.mwtArticleId = mwtA.id;
          this.mwtPhase.mwtArticleName = mwtA.description1;
          this.addPhaseFormGroup.controls.mwtArticleName.setValue(mwtA.description1);
        } else {
          this.mwtPhase.mwtArticleId = null;
          this.mwtPhase.mwtArticleName = '';
          this.addPhaseFormGroup.controls.mwtArticleName.setValue('');
        }
      }

      // this.mwtPhase.phaseOderNo = phaseTemplate.phaseOrderNo;
      this.mwtPhase.phaseOderNo = -1;
      this.mwtPhase.phaseStatus = phaseTemplate.phaseStatus ? phaseTemplate.phaseStatus : 'Pendiente';
      this.mwtPhase.sectionId = phaseTemplate.labSectionId !== undefined
        && phaseTemplate.labSectionId !== null ? phaseTemplate.labSectionId : 0;
      this.mwtPhase.phaseId = phaseTemplate.phaseId;
      this.mwtPhase.startDateObject = phaseTemplate.startDate ?
        this.commonUiService.ConvertToPickerDateFormat(phaseTemplate.startDate) : '';
      this.mwtPhase.endDateObject = phaseTemplate.endDate ? this.commonUiService.ConvertToPickerDateFormat(phaseTemplate.endDate) : '';
      this.labSectionDropdown = false;
      this.phaseDropdown = false;
      this.articleDropdown = false;
      this.cdref.markForCheck();
      this.showContent = true;
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnSaveMwtPhase(event: any) {
    if (event && this.mwtPhase) {
      if (this.selectedMainWorkTypeList.length === 1) {
        this.addPhaseFormGroup.controls['mainWorkTypeId'].clearValidators();
        this.addPhaseFormGroup.controls['mainWorkTypeId'].setErrors(null);
        this.addPhaseFormGroup.controls['mainWorkTypeId'].setValidators(null);
      } else { this.addPhaseFormGroup.controls['mainWorkTypeId'].setValidators(ValidateNgSelect); }
      this.addPhaseFormGroup.updateValueAndValidity();
      if (!this.mwtPhase.startDateObject) {
        this.addPhaseFormGroup.controls.startDate.reset();
      }
      if (!this.mwtPhase.endDateObject) {
        this.addPhaseFormGroup.controls.endDate.reset();
      }
      if (this.addPhaseFormGroup.invalid) {
        ShowInvalidFormControls(this.addPhaseFormGroup);
        return;
      }
      if (this.mwtPhase.id === undefined || this.mwtPhase.id === null) {
        if (this.selectedMainWorkTypeList.length === 1) {
          this.mwtPhase.mainWorkTypeId = (this.selectedMainWorkTypeList[0]).id;
        } else { this.selectedMainWorkType.id = this.mwtPhase.mainWorkTypeId; }
        this.mwtPhase.phaseOderNo = 0;
      }
      this.mwtPhase.mwtArticleId = this.addPhaseFormGroup.controls.mwtArticleName.value ? Number(this.mwtPhase.mwtArticleId) : null;
      this.mwtPhase.sectionId = this.addPhaseFormGroup.controls.labSectionName.value ? Number(this.mwtPhase.sectionId) : null;
      this.mwtPhase.phaseId = this.addPhaseFormGroup.controls.phaseName.value ? Number(this.mwtPhase.phaseId) : null;
      this.mwtPhase.locationId = this.addPhaseFormGroup.controls.locationId.value ? this.addPhaseFormGroup.controls.locationId.value : null;

      if (Number(this.mwtPhase.sectionId) === 0 || this.mwtPhase.sectionId === null
        || this.mwtPhase.sectionId === undefined) { this.mwtPhase.sectionId = null; }
      this.mwtPhase.phaseStatus = this.mwtPhase.phaseStatus ? this.mwtPhase.phaseStatus : 'Pendiente';

      if (this.mwtPhase.startDateObject) {
        this.mwtPhase.startDate = this.commonUiService.ChangeNgbDateToCustomFormat(this.mwtPhase.startDateObject);
      }
      if (this.mwtPhase.endDateObject) {
        this.mwtPhase.endDate = this.commonUiService.ChangeNgbDateToCustomFormat(this.mwtPhase.endDateObject);
      }

      const mainWorkType = this.selectedMainWorkTypeList
        .find(x => Number(x.id) === Number(this.mwtPhase.mainWorkTypeId));
      let obj: { isValid: boolean, message: string } = null;
      if (this.mwtPhase.endDate) {
        obj = await this.ValidatePhaseDates(this.mwtPhase.startDate, this.mwtPhase.endDate, mainWorkType);
      }
      if (obj == null || (obj && mainWorkType)) {
        if (obj == null || obj.isValid) {
          this.commonUiService.isSpinnerVisible = true;
          const response = await this._phaseTemplateService.SaveMwtPhase(this.mwtPhase);
          if (response) {
            const body = JSON.parse(response['_body']);
            this._statusService.SetChangedStatus(true);
            if (body['_isSuccsess']) {
              this.viewUpdate();
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList['phases'],
                msg: this._commonDataService.localizationLabelList['phase_save_successfully']
              });
            } else {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['phases'],
                msg: this._commonDataService.localizationLabelList['save_error']
              });
            }
            this.OnClose();
            this.commonUiService.isSpinnerVisible = false;
            if (this.mwtPhase.phaseStatus.toLowerCase() === 'cancelada') {
              this.OnCancelledMwtPhase(this.mwtPhase);
            }
          }
        } else {
          if (obj.message === 'InvalidDeliveryDate') {
            const result = await swal({
              title: this._commonDataService.localizationLabelList['phases'],
              text: this._commonDataService.localizationLabelList['extend_delivery_date_warning'],
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
              cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
            });
            if (result) {
              if (result.value) {
                mainWorkType.deliveryDate = this.mwtPhase.endDate;
                this._workService.UpdateMainWorkType(mainWorkType);
                const response = await this._phaseTemplateService.SaveMwtPhase(this.mwtPhase);
                if (response) {
                  const body = JSON.parse(response['_body']);
                  if (body['_isSuccsess']) {
                    this.viewUpdate();
                    this._toastyService.success({
                      title: this._commonDataService.localizationLabelList['phases'],
                      msg: this._commonDataService.localizationLabelList['phase_save_successfully']
                    });
                  } else {
                    this._toastyService.error({
                      title: this._commonDataService.localizationLabelList['phases'],
                      msg: this._commonDataService.localizationLabelList['save_error']
                    });
                  }
                  this.OnClose();
                  this.commonUiService.isSpinnerVisible = false;
                  if (this.mwtPhase.phaseStatus.toLowerCase() === 'cancelada') {
                    this.OnCancelledMwtPhase(this.mwtPhase);
                  }
                }
              } else {
                const response = await this._phaseTemplateService.SaveMwtPhase(this.mwtPhase);
                if (response) {
                  const body = JSON.parse(response['_body']);
                  if (body['_isSuccsess']) {
                    this.viewUpdate();
                    this._toastyService.success({
                      title: this._commonDataService.localizationLabelList['phases'],
                      msg: this._commonDataService.localizationLabelList['phase_save_successfully']
                    });
                  } else {
                    this._toastyService.error({
                      title: this._commonDataService.localizationLabelList['phases'],
                      msg: this._commonDataService.localizationLabelList['save_error']
                    });
                  }
                  this.OnClose();
                  this.commonUiService.isSpinnerVisible = false;
                  if (this.mwtPhase.phaseStatus.toLowerCase() === 'cancelada') {
                    this.OnCancelledMwtPhase(this.mwtPhase);
                  }
                }

              }
            }
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['phases'],
              msg: this._commonDataService.localizationLabelList['finish_date_is_not_valid']
            });
          }
        }
      }
    }
  }

  async OnCancelledMwtPhase(mwtPhase: MwtPhase) {
    this.mwtPhase = mwtPhase;
    this.mwtPhase.motiveId = 0;
    this.cdref.markForCheck();
    this.commonUiService.openModal('cancel-phase-modal');
    this._modalService.Invoke(ModalNames.CancelphasesModal, true, {
      mwtPhase: this.mwtPhase
    });
  }

  modalListner() {
    if (this._modalService.modal === ModalNames.phasesModal) {
      if (this._modalService.transferable && !this._modalService.transferable.isEdit) {
        this.selectedMainWorkTypeList = this._modalService.transferable.selectedMainWorkTypeList;
        this.selectedMainWorkType = this._modalService.transferable.selectedMainWorkType;
        this.selectedWork = this._modalService.transferable.selectedWork;
        this.OnOpenAddPhaseModal();
      } else if (this._modalService.transferable && this._modalService.transferable.isEdit) {
        this.selectedMainWorkTypeList = this._modalService.transferable.selectedMainWorkTypeList;
        this.selectedMainWorkType = this._modalService.transferable.selectedMainWorkType;
        this.selectedWork = this._modalService.transferable.selectedWork;
        const phaseTemplate = <WorkTypePhaseTemplate>this._modalService.transferable.phaseTemplate;
        this.OnEditMwtPhase(phaseTemplate);
      }
    }
    this.cdref.markForCheck();
  }

  viewUpdate() { this.updateView.emit(); }

  OnClose() {
    this.closeModel.emit();
    this._modalService.Invoke(ModalNames.phasesModal, false, '');
  }

}
