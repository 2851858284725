import { Note } from './note';
import { WorkTypeArticleTemplate, WorkTypePhaseTemplate,
         WorkTypeProductTemplate } from '../../work-type/entities/work-type-model-templates';
import { MwtClinicMaterial } from './main-work-type-clinic-material';

export class WorkReport {
    workNo: string;
    clientName: string;
    doctorName: string;
    defaultDoctor: string;
    clientTelephone: string;
    patientName: string;
    deliveryDate: string;
    entryDate: string;
    workShiftName: string;
    mainWorkTypeNo: string;
    subWorkNo: string;
    mainWorkTypeStatus: string;
    clinicNote: Note[];
    labNote: Note[];
    generalNote: Note[];
    deliveryNoteNoList: string[] = [];
    phasesList: WorkTypePhaseTemplate[] = [];
    workerList: string[] = [];
    colourList: string;
    piecesList: string;
    qualitType: string;
    mainWorkTypeName: string;
    clinicMaterialList: MwtClinicMaterial[] = [];
    articleList: WorkTypeArticleTemplate[] = [];
    productList: WorkTypeProductTemplate[] = [];
}
