import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import { CommonFunctions } from '../../common/services/common-functions';
import API from './../../common/api.config.json';
import { Worker } from '../../workers/entities/worker';
import { MwtWorker } from '../entities/main-work-type-worker';

@Injectable({
  providedIn: 'root'
})
export class MwtWorkerService {

  constructor(private _apiService: ApiService) { }

  GetWorkerListByMainWorkTypeIdList(idList: { id: number }[]) {
    try {
      const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
      if (params) {
        const query = params.toString().replace('+', '%20');
        return this._apiService.get(API.work_panels.getWorkerListByMainWorkTypeIdList + query, true)
          .map(a => a as Worker[]).toPromise();
      }
    } catch (error) { console.log(error); }
  }

  GetWorkerList() {
    return this._apiService.get(API.worker.getWorkerList, true)
      .map((list => (list as Worker[]).filter(w => w.status))).toPromise();
  }

  AddMwtWorker(mwtWorker: MwtWorker): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.post(API.main_work_type_work.addMwtWorker, mwtWorker, false)
        .subscribe((response) => resolve(response),
          err => reject(err));
    });
  }

  CheckWorkerAvailabilityForDeliveryDate(workerId: number, taskId: number, date: string) {
    return this._apiService.get(API.main_work_type_work.check_worker_availability_for_delivery_date
      + workerId + '/' + taskId + '/' + date)
      .toPromise();
  }
}
