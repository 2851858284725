import { Component, OnInit } from '@angular/core';
import { WorkPageConfiguration } from '../../../entities/work-page-configuration';
import { ApiService } from '../../../../common/services/api.service';
import API from './../../../../common/api.config.json';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import { ShowInvalidFormControls } from '../../../../common/services/validators';
import { CommonDataService } from '../../../../common/services/common.data.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Toast } from 'src/app/modules/common/services/toast.service';

@Component({
  selector: 'work-page-configuration',
  templateUrl: './work-page-configuration.component.html',
  styleUrls: ['./work-page-configuration.component.scss']
})
export class WorkPageConfigurationComponent implements OnInit {

  // objects
  workPageConfiguration: WorkPageConfiguration;
  workPageConfigurationList: WorkPageConfiguration[] = [];
  isConfigurationListLoaded = false;
  hasEditPermission = false;

  // form groups
  workPageFormGroup: FormGroup;

  constructor(private _apiService: ApiService,
    public _commonUiService: CommonUIService,
    private _toastyService: Toast,
    private _commonDataService: CommonDataService,
    private _ngxPermissionService: NgxPermissionsService,
    private _fb: FormBuilder) {
    this.workPageFormGroup = this._fb.group({
      description: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.isConfigurationListLoaded = false;
    this.workPageConfiguration = new WorkPageConfiguration();
    this.LoadWorkPageConfigurationList();
    this.SetPermissions();
  }

  async SetPermissions() {
    this.hasEditPermission = await this._ngxPermissionService.hasPermission('EditConfiguration');
  }

  //#region WorkPageConfiguration

  private LoadWorkPageConfigurationList() {
    this._apiService.get(API.work_page_configuration.workPageConfigurationList)
      .subscribe((list: WorkPageConfiguration[]) => this.workPageConfigurationList = list,
        err => console.error(err),
        () => this.isConfigurationListLoaded = true);
  }

  private async AddWorkPageConfiguration(config: WorkPageConfiguration): Promise<WorkPageConfiguration> {
    return new Promise<WorkPageConfiguration>((resolve, reject) => {
      this._apiService.post(API.work_page_configuration.workPageConfigurationList, config, true)
        .subscribe((workPageConfiguration: WorkPageConfiguration) => resolve(workPageConfiguration),
          err => reject(err));
    });
  }

  private async UpdateWorkPageConfiguration(config: WorkPageConfiguration): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._apiService.put(API.work_page_configuration.updateWorkPageConfiguration + config.id, config, false)
        .subscribe((response: any) => {
          const body = JSON.parse(response['_body']);
          resolve(body['_isSuccsess']);
        }, err => reject(err));
    });
  }

  //#endregion

  //#region Events

  OnSaveWorkPageConfiguration(event: any) {
    if (this.workPageFormGroup.invalid) { ShowInvalidFormControls(this.workPageFormGroup); return; }
    this.AddWorkPageConfiguration(this.workPageConfiguration)
      .then(config => { if (config) { this.LoadWorkPageConfigurationList(); } this.CloseModal(event); });
  }

  OnChangeIsRequired(config: WorkPageConfiguration) {
    if (config) {
      config.isRequired = !config.isRequired;
      this.isConfigurationListLoaded = false;
      this.UpdateWorkPageConfiguration(config)
        .then(isUpdated => {
          if (isUpdated) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['work_page_config'],
              msg: this._commonDataService.localizationLabelList['work_page_config_update_success']
            });
          } else {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['work_page_config'],
              msg: this._commonDataService.localizationLabelList['work_page_config_update_error']
            });
          }
          this.isConfigurationListLoaded = true;
        });
    }
  }

  OnOpenWorkPageConfigurationModal(modal: string) {
    this.workPageConfiguration = new WorkPageConfiguration();
    this._commonUiService.openModal(modal);
  }

  CloseModal(event: any) {
    (((((event.target.parentElement.parentElement).parentElement)
      .parentElement).parentElement).parentElement).classList.remove('md-show');
  }

  //#endregion

}
