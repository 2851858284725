import { Component, OnInit, EventEmitter, Output, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ModalService } from '../../../common/services/modal.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastData, ToastyService } from 'ng2-toasty';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { ApiService } from '../../../common/services/api.service';
import API from '../../../common/api.config.json';
import { Prescription } from '../../entities/prescription';
import { ModalNames } from '../../../common/global';
import { ShowInvalidFormControls } from '../../../common/services/validators';
import { PrescriptionService } from '../../services/prescription.service';
import { SubWork } from '../../entities/sub-work';

@Component({
  selector: 'app-prescription-modal',
  templateUrl: './prescription-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrescriptionModalComponent implements OnInit, AfterViewInit {

  addPrescriptionFormGroup: FormGroup;
  prescription: Prescription;
  selectedSubWork: SubWork;
  @Output() prescriptionSaved = new EventEmitter();
  @Output() closeModel: EventEmitter<any> = new EventEmitter();
  showContent = false;

  constructor(private _formBuilder: FormBuilder, private _commonDataService: CommonDataService,
    private commonUiService: CommonUIService, private _apiService: ApiService,
    private _modalService: ModalService, private _toastyService: ToastyService,
    private _prescriptionService: PrescriptionService,
    private cdref: ChangeDetectorRef) {
    this.prescription = new Prescription();
    this.addPrescriptionFormGroup = this._formBuilder.group({ file: ['', Validators.required] });
    this.showContent = true;
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (this._modalService.modal === ModalNames.PrescriptionModal) {
      this.selectedSubWork = this._modalService.transferable.selectedSubWork as SubWork;
      this.OnAdd();
    }
  }

  private OnAdd() {
    this.addPrescriptionFormGroup.reset();
    this.cdref.markForCheck();
  }

  private UploadPrescription(formData: FormData) {
    this._apiService.upload(API.file_upload.upload, formData)
      .subscribe(res => {
        if (res['body'] == 200) {
          this.commonUiService.isPopupOpened = false;
          this.showContent = false;
          this.prescriptionSaved.emit();
          this.OnClose();
          this._toastyService.success({
            title: this._commonDataService.localizationLabelList['prescription'],
            msg: this._commonDataService.localizationLabelList['prescription_upload_successfully'],
            showClose: true,
            theme: 'default',
            timeout: 5000,
            onAdd: (toast: ToastData) => { },
            onRemove: (toast: ToastData) => { }
          });
          console.clear();
        }
      },
        err => console.log(err),
        () => this.commonUiService.isSpinnerVisible = false);
  }

  OnChoosePrescription(event: any) {
    if (event) {
      const files: any = event.target.files;
      this.prescription.fileList = files;
      this.cdref.markForCheck();
    }
  }

  OnSavePrescription() {
    if (this.prescription) {
      if (this.addPrescriptionFormGroup.invalid) { ShowInvalidFormControls(this.addPrescriptionFormGroup); return; }
      this.commonUiService.isSpinnerVisible = true;
      this.prescription.subWorkId = this.selectedSubWork.id;
      if (this.prescription.fileList.length > 0) {
        this.prescription.fileName = (this.prescription.fileList[0]).name;
        this.prescription.extension =
          ((this.prescription.fileList[0]).name).split('.')[(((this.prescription.fileList[0]).name).split('.')).length - 1];
        this._prescriptionService.AddPrescription(this.prescription)
          .then(prescription => {
            if (prescription) {
              const formData: FormData = new FormData();
              formData.append(prescription.fileName, this.prescription.fileList[0], prescription.generatedFileName);
              formData.append('folderName', 'Prescriptions');
              formData.append('id', prescription.id.toString());
              this.UploadPrescription(formData);
            }
          });
      }
      this.cdref.markForCheck();
    }
  }

  OnClose() {
    this.showContent = false;
    this.commonUiService.isPopupOpened = false;
    this.closeModel.emit();
  }

}
