export class JobSearchFilter {
  clientId;
  doctorId;
  patientId;
  patientName;
  dateTypeId;
  workNo;
  workTypeNo;
  deliverNoteNo;
  fromDate;
  toDate;
  state;
  priorityId;
  workTypeCode;
}
