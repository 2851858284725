export class DropdownOption {
    id: number | string;
    value: number | string;
    display: string;
    label: string;
    disabled?: boolean;

    constructor(init?: Partial<DropdownOption>) {
        Object.assign(this, init);
    }
}
