import API from '../../common/api.config.json';
import { Injectable } from '@angular/core';
import { WorkConfigurationSelection } from '../entities/work-configuration-selection';
import { ApiService } from '../../common/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class WorkConfigurationService {

  constructor(private _apiService: ApiService) { }

  GetWorkConfigSelectionList() {
    try {
      return this._apiService.get(API.work_page_configuration.getWorkConfigSelection, true)
        .map(n => n as WorkConfigurationSelection[]).toPromise();
    } catch (error) { console.log(error); }
  }

  UpdateWorkConfigSelection(config: WorkConfigurationSelection) {
    try {
      return this._apiService.post(API.work_page_configuration.updateWorkConfigSelection, config, true)
        .map(n => n as boolean).toPromise();
    } catch (error) { console.log(error); }
  }
}
