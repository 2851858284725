import { Component, OnInit } from '@angular/core';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import { IncidentReason } from '../../../entities/incident-reason';
import { IncidentReasonService } from '../../../services/incident-reason.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShowInvalidFormControls } from '../../../../common/services/validators';
import { Toast } from '../../../../common/services/toast.service';
import { CommonDataService } from '../../../../common/services/common.data.service';
import { DropdownService } from '../../../../common/services/dropdown.service';
import { IncidentTypeList } from '../../../../common/global';
import { CustomTranslatePipe } from '../../../../common/pipes/custom-translate.pipe';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-incidents',
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.scss']
})
export class IncidentsComponent implements OnInit {

  incidentReasonList: IncidentReason[] = [];
  loading = false;

  form: FormGroup;
  incidentReason: IncidentReason;

  constructor(
    private _formBuilder: FormBuilder,
    private _commonUIService: CommonUIService,
    private _service: IncidentReasonService,
    private _toastyService: Toast,
    private _commonDataService: CommonDataService,
    private _cTranslatePipe: CustomTranslatePipe,
    public dropdownService: DropdownService,
    private _translationService: TranslateService
  ) {
    this.loading = false;
    this._translationService.onLangChange
      .subscribe(l => this.setTranslatedTypeLabels());
  }

  ngOnInit() {
    this.incidentReason = new IncidentReason();
    this.initForm();
    this.loadList();
  }

  private initForm() {
    this.form = this._formBuilder.group({
      incidentType: [0],
      description: [null, [
        Validators.required,
        Validators.maxLength(49)
      ]]
    });
  }

  private loadList() {
    this.loading = true;
    this._service.getList()
      .subscribe((list: IncidentReason[]) =>
        this.incidentReasonList = list,
        err => console.log(err),
        () => {
          this.setTranslatedTypeLabels();
          this.loading = false;
        });
  }

  private setTranslatedTypeLabels() {
    for (let ir of this.incidentReasonList) {
      if (ir.type) {
        ir.typeName = this._cTranslatePipe
          .transform(IncidentTypeList[ir.type - 1].textCode);
      } else { ir.typeName = ''; }
    }
  }

  openModal(modal: string) {
    this.incidentReason = new IncidentReason();
    this.initForm();
    this._commonUIService.openModal(modal);
  }

  closeModal(modal: string) {
    this._commonUIService.CloseModalEx(modal);
  }

  editIncidentReason(modal: string, ir: IncidentReason) {
    try {
      this._commonUIService.isSpinnerVisible = true;
      this.incidentReason = ir;
      this.form.setValue({
        incidentType: ir.type,
        description: ir.description
      });
      this._commonUIService.openModal(modal);
    } catch (error) {
      this._toastyService.error({
        title: this._commonDataService.localizationLabelList['incident_reasons'],
        msg: error
      });
    } finally {
      this._commonUIService.isSpinnerVisible = false;
    }
  }

  async saveIncidentReason(modal: string) {
    try {
      this._commonUIService.isSpinnerVisible = true;
      if (this.form.invalid) {
        ShowInvalidFormControls(this.form);
      } else {
        const formValue = this.form.value as {
          incidentType: string;
          description: string
        };
        if (formValue) {
          const ir = new IncidentReason({
            description: formValue.description,
            type: Number(formValue.incidentType) ?
              Number(formValue.incidentType) as 1 | 2 | null : null,
            status: true
          });
          if (this.incidentReason && this.incidentReason.id) {
            ir.id = this.incidentReason.id;
            ir.status = this.incidentReason.status;
          }
          const response = await this._service.save(ir);
          if (response) {
            const body = JSON.parse(response._body);
            if (body._statusCode === 200) {
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList['incident_reasons'],
                msg: this._commonDataService.localizationLabelList['save_success'],
              });
              this.closeModal(modal);
              this.loadList();
            } else {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['incident_reasons'],
                msg: this._commonDataService.localizationLabelList['save_error'],
              });
            }
          }
        }
      }
    } catch (error) {
      this._toastyService.error({
        title: this._commonDataService.localizationLabelList['incident_reasons'],
        msg: error
      });
    } finally {
      this._commonUIService.isSpinnerVisible = false;
    }
  }

  async editStatus(ir: IncidentReason) {
    try {
      this._commonUIService.isSpinnerVisible = true;
      ir.status = !ir.status;
      const response = await this._service.save(ir);
      if (response) {
        const body = JSON.parse(response._body);
        if (body._statusCode === 200) {
          this._toastyService.success({
            title: this._commonDataService.localizationLabelList['incident_reasons'],
            msg: this._commonDataService.localizationLabelList['save_success'],
          });
          this.loadList();
        } else {
          this._toastyService.error({
            title: this._commonDataService.localizationLabelList['incident_reasons'],
            msg: this._commonDataService.localizationLabelList['save_error'],
          });
        }
      }
    } catch (error) {
      this._toastyService.error({
        title: this._commonDataService.localizationLabelList['incident_reasons'],
        msg: error
      });
    } finally {
      this._commonUIService.isSpinnerVisible = false;
    }
  }

  async removeIncidentReason(id: number) {
    try {
      this._commonUIService.isSpinnerVisible = true;
      if (id) {
        const response = await this._service.delete(id);
        if (response) {
          if (response._statusCode === 200) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['incident_reasons'],
              msg: this._commonDataService.localizationLabelList['delete_success']
            });
            this.loadList();
          } else if (response._statusCode === 409) {
            this._toastyService.warning({
              title: this._commonDataService.localizationLabelList['incident_reasons'],
              msg: this._commonDataService.localizationLabelList['delete_error']
            });
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['incident_reasons'],
              msg: this._commonDataService.localizationLabelList['delete_error']
            });
          }
        }
      }
    } catch (error) {
      this._toastyService.error({
        title: this._commonDataService.localizationLabelList['incident_reasons'],
        msg: error
      });
    } finally {
      this._commonUIService.isSpinnerVisible = false;
    }
  }
}
