import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';
import { CurrencyPipe, getCurrencySymbol } from '@angular/common';
import { CommonDataService } from '../services/common.data.service';
import { isNumber } from 'util';
import { toFixedNumber } from '../services/calculations/calculation';

@Pipe({
  name: 'novacurrency'
})
export class NovaCurrencyPipe implements PipeTransform {

  constructor(
    private currencyPipe: CurrencyPipe,
    private commonDataService: CommonDataService) {
  }

  transform(value: any, onlySymbol = false) {
    // comes from settings
    const currency = this.commonDataService.appCurrency;

    if (!currency) { return value; }

    let symbol = getCurrencySymbol(currency, 'wide');

    if (onlySymbol && currency) {
      switch (currency) {
        case 'PYG':
          symbol = '₲';
          break;
        case 'PAB':
          symbol = 'B/.';
          break;
        case 'CLP':
          symbol = '$';
          break;
        default:
          break;
      }
      return symbol;
    }

    if (value == '' || value == null || value == undefined) { value = 0; }

    if (!isNumber(value)) { return value; }
    let currencyValue = value.toString();
    const decimalValue = toFixedNumber(Number(currencyValue));
    if (decimalValue !== undefined && decimalValue !== null) {

      // currency check EUR / USD / PYG / PAB / CLP
      if (currencyValue) {
        switch (currency) {
          case 'EUR':
            currencyValue = this.currencyPipe
              .transform(decimalValue, currency, 'symbol', '1.2-2', 'es');
            break;
          case 'USD':
            currencyValue = this.currencyPipe
              .transform(decimalValue, currency, 'symbol', '1.2-2', 'en');
            break;
          case 'PYG':
            currencyValue = this.currencyPipe
              .transform(decimalValue, currency, 'symbol', '1.2-2', 'en');
            currencyValue = currencyValue.replace(currency, '₲ ');
            break;
          case 'PAB':
            currencyValue = this.currencyPipe
              .transform(decimalValue, currency, 'symbol', '1.2-2', 'es-pa');
            currencyValue = currencyValue.replace(currency, ' B/.');
            break;
          case 'CLP':
            currencyValue = this.currencyPipe
              .transform(decimalValue, currency, 'symbol', '1.2-2', 'es-cl');
            currencyValue = currencyValue.replace(currency, '$ ');
            break;
          default:
            break;
        }
      }
    }
    return currencyValue;
  }
}
