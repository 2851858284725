import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { CommonDataService } from '../../../common/services/common.data.service';
import { Toast } from '../../../common/services/toast.service';
import { InvoiceService } from '../../../work/services/invoice.service';
import { InvoiceHeader } from '../../entities/invoice-header';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-close-billing',
  templateUrl: './close-billing.component.html',
  styleUrls: ['./close-billing.component.scss']
})
export class CloseBillingComponent implements OnInit {

  @Output() closeCBModal = new EventEmitter();

  filtersForm: FormGroup;
  invoiceList: InvoiceHeader[] = [];
  closeButtonVisible = false;
  unblockButtonVisible = false;
  buttonEnabled = true;

  constructor(
    private _formBuilder: FormBuilder,
    private _commonUiService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: Toast,
    private _invoiceService: InvoiceService,
    private _datePipe: DatePipe) {
    const days = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
    this.filtersForm = this._formBuilder.group({
      startDate: [this.getFirstDate()],
      endDate: [this.getEndDate(days)]
    });
  }

  ngOnInit() {
    this.filtersForm.valueChanges
      .subscribe((formValue: { startDate: string, endDate: string }) => {
        this.buttonEnabled = this.validateDateRange(
          this._commonUiService.ChangeNgbDateToCustomFormat(formValue.startDate),
          this._commonUiService.ChangeNgbDateToCustomFormat(formValue.endDate));
        if (!this.buttonEnabled) { this.invoiceList = []; }
      });
  }

  private getDate(substractCount?: number) {
    const date = new Date();
    if (substractCount) {
      date.setDate(new Date().getDate() - substractCount);
    }
    return this._commonUiService.ToNgbDate(date);
  }

  private getEndDate(additionCount?: number) {
    var firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const date = moment(firstDay, 'YYYY-MM-DD').toDate();
    date.setDate(date.getDate() + additionCount - 1);
    const endDate = this._commonUiService.ToNgbDate(date);
    return endDate;
  }

  private getFirstDate() {
    var firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const date = moment(firstDay, 'YYYY-MM-DD').toDate();
    date.setDate(date.getDate());
    const firstDate = this._commonUiService.ToNgbDate(date);
    return firstDate;
  }

  closeModal() { this.closeCBModal.emit(); }

  clear() {
    this.filtersForm.reset();
    this.invoiceList = [];
    this.closeButtonVisible = false;
    this.unblockButtonVisible = false;
  }

  validateDateRange(from: string, to: string) {
    if (from && to) {
      const fromDate = new Date(from);
      const toDate = new Date(to);
      if (fromDate > toDate) {
        this._toastyService.error({
          title: this._commonDataService
            .localizationLabelList['invoice'],
          msg: this._commonDataService
            .localizationLabelList['invalid_date_range']
        });
        return false;
      }
      return true;
    }
    return false;
  }

  async search(warningEnabled = true) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      const form = this.filtersForm.value as { startDate: string, endDate: string };
      if (form) {
        this.invoiceList = [];
        const result = await this._invoiceService
          .getInvoiceListByDateRange(
            this._commonUiService.ChangeNgbDateToCustomFormat(form.startDate),
            this._commonUiService.ChangeNgbDateToCustomFormat(form.endDate));

        if (result) {
          this.invoiceList = this.invoiceList
            .concat(result.closedList, result.unblockedList);
          this.closeButtonVisible = result.unblockedList.length > 0;
          this.unblockButtonVisible = result.closedList.length > 0;

          if (warningEnabled && result.markedDateRangeList
            && result.markedDateRangeList.length > 0) {
            this._toastyService.warning({
              title: this._commonDataService
                .localizationLabelList['invoice'],
              msg: this._commonDataService
                .localizationLabelList['closing_billing_warning'] + ' (' +
                this._datePipe.transform(result.markedDateRangeList[0].startDate, 'dd-MM-yyyy') + ' to ' +
                this._datePipe.transform(result.markedDateRangeList[0].endDate, 'dd-MM-yyyy') + ')'
            });
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  async onCloseBilling(closeBilling: boolean) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      const form = this.filtersForm.value as { startDate: string, endDate: string };
      const result = await this._invoiceService
        .closeUnblockInvoices({
          close: closeBilling, start:
            this._commonUiService.ChangeNgbDateToCustomFormat(form.startDate), end:
            this._commonUiService.ChangeNgbDateToCustomFormat(form.endDate)
        });
      if (result && result.updated) {
        this._toastyService.success({
          title: this._commonDataService
            .localizationLabelList['invoice'],
          msg: closeBilling ? this._commonDataService
            .localizationLabelList['invoice_closed_successfully'] :
            this._commonDataService
              .localizationLabelList['invoice_unblocked_successfully']
        });
        await this.search(false);
      } else {
        this._toastyService.error({
          title: this._commonDataService
            .localizationLabelList['invoice'],
          msg: this._commonDataService
            .localizationLabelList['save_error']
        });
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }


}
