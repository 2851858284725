import { Issuer } from '../../auxiliary/entities/issuer';
import { Series } from '../../auxiliary/entities/series';
import { ReceiptDetail } from './receipt-detail';
import { Client } from '../../client/entities/client';

export class ReceiptHeader {
    id: number;
    receiptNo: string;
    receiptDate: any;
    totalAmount: number;
    issuerId: number;
    seriesId: number;
    concept: string;
    isPrinted: boolean;
    status: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedDate: Date;
    receiptGenerateTypeNo: number;
    issuerName: string;
    seriesName: string;
    receiptDetailList: ReceiptDetail[];
    totalInWord: string;
    printedDate: Date;
    oldTotal: number;

    clientName: string;
    clientEmail: string;
    client: Client;
    issuer: Issuer;
    series: Series;
    receiptDateView?: any;
    isEdit: boolean;
    receiptStatus: string;
    previousReceiptNo: string;
    grandTotal: number;
    labPicturePath: string;
    isMailed: boolean;
    cancellationObservation: string;
    cancellationDate: string;
    cancellationNo: string;
}

export enum ReceiptGenerateTypes {
    GenerateReceiptByCustomer = 1,
    GenerateReceiptByInvoice = 2
}
