import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonFunctions } from 'src/app/modules/common/services/common-functions';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { MtwPhaseTask } from 'src/app/modules/work/entities/main-work-type-phase-task';
import { Task } from 'src/app/modules/work/entities/task';
import { Work } from 'src/app/modules/work/entities/work';
import API from '../../../../common/api.config.json';
import { ApiService } from '../../../../common/services/api.service';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import { RequestData } from '../../../../work/pagination/requestData';
import { Client } from '../../../entities/client';
import { LabSection } from '../../../entities/lab-section';
import { MainWorkTypePhaseTaskViewModel } from '../../../entities/main-work-type-phase-task-view-model';
import { Worker } from '../../../entities/worker';

@Component({
  selector: 'app-tab-tasks',
  templateUrl: './tab-tasks.component.html',
  styleUrls: ['./tab-tasks.component.scss']
})

export class TabTasksComponent implements OnInit {

  @ViewChild('fd') from_date_picker: ElementRef;
  @ViewChild('td') to_date_picker: ElementRef;
  @Output() public OnClickWorkForWorkExpand:
    EventEmitter<{ workId: number, subWorkId: number }> =
    new EventEmitter<{ workId: number, subWorkId: number }>();

  private taskList = 1;

  fromDate: any;
  toDate: any;
  workNumberValue: any;
  taskStatusValue: string;
  labSectionValue: any;
  labSectionName: string;
  workerValue: any;
  taskValue: any;
  workerName: string;
  taskName: string;
  dateStringFormatForFromDate: any;
  dateStringFormatForToDate: any;
  isShowExpandlist = false;
  time: any;

  // pagination propeties
  sessionGetUrl: string;
  pagTaskList: any[] = [];
  extra: any = {};
  requestData: RequestData = new RequestData(1, 20, '', '', '', this.taskList);
  refresh = true;

  public enableExportButton = false;
  public enableClientDropDown = false;
  public enableLabSectionDropDown = false;
  public enableWorkerDropDown = false;
  public enableTaskDropDown = false;
  labSectionList: LabSection[] = [];
  workerList: Worker[] = [];
  clientList: Client[] = [];
  mtwPhaseTaskList: Task[] = [];
  taskStatusList: any[] = [];

  mainWorkTypePhaseTaskViewModel: MainWorkTypePhaseTaskViewModel;
  work: Work;
  mtwPhaseTask: MtwPhaseTask;
  phaseTaskFormGroup: FormGroup;

  constructor(
    private _apiService: ApiService,
    private _formBuilder: FormBuilder,
    public _commonUIService: CommonUIService,
    public _commonDataService: CommonDataService,
    public dropdownService: DropdownService) {
    this.mainWorkTypePhaseTaskViewModel = new MainWorkTypePhaseTaskViewModel();
    this.work = new Work();
    this.mtwPhaseTask = new MtwPhaseTask();
  }

  ngOnInit() {

    this.labSectionValue = '';
    this.labSectionName = '';
    this.fromDate = '';
    this.toDate = '';
    this.workerValue = '';
    this.taskValue = '';
    this.workerName = '';
    this.taskName = '';
    this.time = '';
    this.taskStatusValue = 'Pendiente';

    this.phaseTaskFormGroup = this._formBuilder.group({
      time: ['', [Validators.minLength(4)]],
      taskName: [''],
      workerName: [''],
      labSectionName: ['']
    });

    this.sessionGetUrl = API.work.getMwtPhaseTaskExpandPaginationList;
    this.enableExportButton = true;

    this.phaseTaskFormGroup.controls.taskName.disable();
    this.phaseTaskFormGroup.controls.workerName.disable();
    this.phaseTaskFormGroup.controls.labSectionName.disable();

    this.LoadWorkerList();
    this.LoadClientList();
    this.LoadLabSectionList();
    this.LoadTaskStatusList();
    this.LoadTaskList();
    this.EnableSearchButton();
  }

  LoadTaskStatusList() {
    this.taskStatusList = [
      { id: 'Pendiente', text: 'Pendiente' },
      { id: 'Finalizado', text: 'Finalizado' },
      { id: 'Iniciada', text: 'Iniciada' },
    ];
  }

  public LoadWorkerList() {
    this._apiService.get(API.worker.getWorkerList)
      .subscribe(res => {
        this.workerList = res;
        this.enableWorkerDropDown = true;
        this.phaseTaskFormGroup.controls.workerName.enable();
      });
  }

  public LoadTaskList() {
    this._apiService.get(API.task.getTaskList)
      .subscribe(res => {
        this.mtwPhaseTaskList = res;
        this.enableTaskDropDown = true;
        this.phaseTaskFormGroup.controls.taskName.enable();
      });
  }

  public LoadLabSectionList() {
    this._apiService.get(API.lab_sections.getlabSectionsList)
      .subscribe(res => {
        this.labSectionList = res;
        console.log(this.labSectionList);
        this.enableLabSectionDropDown = true;
        this.phaseTaskFormGroup.controls.labSectionName.enable();
      });
  }

  public LoadClientList() {
    this._apiService.get(API.client.getClients)
      .subscribe(res => {
        this.clientList = res;
        this.enableClientDropDown = true;
      });
  }

  public OnSelectLabSection(labSection: LabSection): void {
    this.labSectionValue = labSection.id;
    this.labSectionName = labSection.description;
    this.enableExportButton = true;
  }

  public OnSelectWorker(worker: Worker): void {
    this.workerValue = worker.id;
    this.workerName = worker.name;
    this.enableExportButton = true;
  }

  public OnSelectTask(task: Task): void {
    this.taskValue = task.id;
    this.taskName = task.name;
    this.enableExportButton = true;
  }

  EnableSearchButton() {
    if (this.taskStatusValue || this.dateStringFormatForFromDate || this.dateStringFormatForToDate
      || this.phaseTaskFormGroup.controls.labSectionName.value || this.phaseTaskFormGroup.controls.workerName.value ||
      this.phaseTaskFormGroup.controls.taskName.value || this.time) {
      this.enableExportButton = true;
    } else {
      this.enableExportButton = false;
      this.isShowExpandlist = false;
    }
  }

  DisableSearchButton(event) {
    this.enableExportButton = false;
    this.labSectionValue = '';
    this.workerValue = '';
    this.taskValue = '';
    this.time = '';
  }

  FilterTaskByText() {
    this.requestData.page = 1;
    if ((this.from_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = this._commonUIService.ChangeNgbDateToCustomFormat(this.dateStringFormatForFromDate).toString();
      if (dateString !== undefined) {
        this.fromDate = dateString;
      } else {
        this.fromDate = '';
      }
    } else {
      this.fromDate = '';
    }

    if ((this.to_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = this._commonUIService.ChangeNgbDateToCustomFormat(this.dateStringFormatForToDate).toString();
      if (dateString !== undefined) {
        this.toDate = dateString;
      } else {
        this.toDate = '';
      }
    } else {
      this.toDate = '';
    }

    if (this.time != "") {
      if (!this.time.toString().includes(':')) {
        this.time = CommonFunctions.Get24HoursTimeFormat(this.time);
      }
    } else {
      this.time = "";
    }

    this.taskValue = this.phaseTaskFormGroup.controls.taskName.value ? this.taskValue : '';
    this.workerValue = this.phaseTaskFormGroup.controls.workerName.value ? this.workerValue : '';
    this.labSectionValue = this.phaseTaskFormGroup.controls.labSectionName.value ? this.labSectionValue : '';

    this.requestData.filter = `${this.taskStatusValue};${this.fromDate};${this.toDate};${this.time};${this.taskValue};${this.workerValue};${this.labSectionValue}`;
    this.refresh = true;
    this.isShowExpandlist = true;

  }

  ExportAsXLSX(): void {
    if ((this.from_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = this._commonUIService.ChangeNgbDateToCustomFormat(this.dateStringFormatForFromDate).toString();
      if (dateString !== undefined) {
        this.fromDate = dateString;
      } else {
        this.fromDate = '';
      }
    } else {
      this.fromDate = '';
    }

    if ((this.to_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = this._commonUIService.ChangeNgbDateToCustomFormat(this.dateStringFormatForToDate).toString();
      if (dateString !== undefined) {
        this.toDate = dateString;
      } else {
        this.toDate = '';
      }
    } else {
      this.toDate = '';
    }

    if (this.time != "") {
      if (!this.time.toString().includes(':')) {
        this.time = CommonFunctions.Get24HoursTimeFormat(this.time);
      }
    } else {
      this.time = "";
    }

    this.mainWorkTypePhaseTaskViewModel.taskStatus = this.taskStatusValue;
    this.mainWorkTypePhaseTaskViewModel.fromDate = this.fromDate;
    this.mainWorkTypePhaseTaskViewModel.toDate = this.toDate;
    this.mainWorkTypePhaseTaskViewModel.workerValue = +this.workerValue;
    this.mainWorkTypePhaseTaskViewModel.taskValue = +this.taskValue;
    this.mainWorkTypePhaseTaskViewModel.time = this.time;
    this.mainWorkTypePhaseTaskViewModel.labSectionValue = +this.labSectionValue;

    const params = new URLSearchParams();
    // tslint:disable-next-line: forin
    for (const key in this.mainWorkTypePhaseTaskViewModel) {
      params.set(key, this.mainWorkTypePhaseTaskViewModel[key]);
    }

    const query = params.toString().replace('+', '%20');

    const url = this._apiService.BASE_URL + `/api/Works/MwtPhaseTaskExcelExport?${query}`;
    window.open(url, '_blank');
  }

  LoadWorkRightPanel(mainWorkTypePhaseTask: MtwPhaseTask, event) {
    if (mainWorkTypePhaseTask) {
      if (mainWorkTypePhaseTask.workId && mainWorkTypePhaseTask.subWorkId) {
        this.OnClickWorkForWorkExpand.emit({
          workId: mainWorkTypePhaseTask.workId,
          subWorkId: mainWorkTypePhaseTask.subWorkId
        });
        this._commonUIService.isActiveRightPanelOnly = false;
        this._commonUIService.isActiveLeftPanelOnly = false;
        this._commonUIService.isActiveBothPanel = false;
        this._commonUIService.isActiveStatisticPanel = false;
      }
    }
  }
}

