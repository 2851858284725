export class GenerateNumberModel {
    isReserveActualNumber: boolean;
    isNextAvailableNumber: boolean;
    isAnother: boolean;
    isNoNumberAssigned: boolean;
    isOtherAvailableNumbers: boolean;

    reserveActualNumber: number;
    nextAvailableNumber: number;
    anotherNumber: number;
    otherAvailableNumbers: string;
}

export class SavedGeneratedNumber {
    seriesId: number;
    savedNumber: number;
}
