import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Issuer } from 'src/app/modules/auxiliary/entities/issuer';
import { ClientSeries } from 'src/app/modules/client/entities/client-series';
import { Series } from 'src/app/modules/client/entities/series';
import { ModalNames } from 'src/app/modules/common/global';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { ShowInvalidFormControls, ValidateNgSelect } from 'src/app/modules/common/services/validators';
import { NumberingProcess } from 'src/app/shared/numbering-process/numbering-process';
import { NumberingProcessService } from 'src/app/shared/numbering-process/numbering-process.service';
import { BillInvoiceModel } from '../../entities/bill-invoice-model';
import { DeliveryNote } from '../../entities/delivery-note';
import { MainWorkType } from '../../entities/main-work-type';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { WorkService } from '../../services/work.service';

@Component({
  selector: 'app-bill-new-invoice-model',
  templateUrl: './bill-new-invoice-model.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillNewInvoiceModelComponent implements OnInit, AfterViewInit {
  showContent = false;

  billInvoiceModel: BillInvoiceModel = new BillInvoiceModel();
  arg: string;
  // form groups
  generatedNumberForInvoiceHeaderFormGroup: FormGroup;
  _deliveryNoteToBeBilled: DeliveryNote = new DeliveryNote();

  selectedWork: Work;
  selectedSubWork: SubWork;
  selectedMainWorkType: MainWorkType;

  // lists
  selectedMainWorkTypeList: MainWorkType[] = [];
  mainWorkTypeList: MainWorkType[];
  seriesListForBilling: Series[] = [];
  seriesList: Series[] = [];
  issureList: Issuer[] = [];

  ivNumberingProcess: NumberingProcess;
  ivSeries: {
    id: number;
    type: 'Work' | 'Delivery Note' | 'Bill' | 'Budget';
    series: string;
  };
  ivMaxSize = 5;

  constructor(
    private _modalService: ModalService,
    private commonUiService: CommonUIService,
    private _formBuilder: FormBuilder,
    private _dropdownService: DropdownService,
    private _workService: WorkService,
    private _numberingProcessService: NumberingProcessService,
    private _router: Router,
    private _apiService: ApiService,
    private cdref: ChangeDetectorRef
  ) {
    this.billInvoiceModel = new BillInvoiceModel();
    this.InitFormGroups();
  }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this._modalService.modalSubject.subscribe(modalObject => {
      if (modalObject) {
        if (modalObject.modal === ModalNames.BillNewInvoiceModal) {
          if (modalObject.flag && modalObject.transferable != null) {
            this._deliveryNoteToBeBilled =
              modalObject.transferable.deliveryNoteToBeBilled;
            this.arg = modalObject.transferable.arg;
            this.selectedMainWorkTypeList =
              modalObject.transferable.selectedMainWorkTypeList;
            this.selectedSubWork = modalObject.transferable.selectedSubWork;
            this.selectedWork = modalObject.transferable.selectedWork;
            this.mainWorkTypeList = modalObject.transferable.mainWorkTypeList;
            this.LoadList();
            this.OnBillDeliveryNotes(this.arg);
          }
          this.showContent = modalObject.flag;
        }
      }
    });
  }

  OnClose() {
    this.showContent = false;
  }

  LoadList() {
    this.issureList = [];
    if (this._dropdownService.issureList) {
      this.issureList = this._dropdownService.issureList.filter(x => x.status);
    }
    this.cdref.markForCheck();
  }

  InitFormGroups() {
    this.generatedNumberForInvoiceHeaderFormGroup = this._formBuilder.group({
      generatedNumber: ['', [Validators.required]],
      issuerId: ['', [ValidateNgSelect]],
      seriesId: ['', [ValidateNgSelect]]
    });
  }

  async OnBillDeliveryNotes(arg: string) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      this.generatedNumberForInvoiceHeaderFormGroup
        .controls.generatedNumber.setValue('');
      const defaultIssuer = this.issureList.find(a => a.isDefault);
      const list = await this._workService.GetSeriesList();
      this.seriesList = list.filter(
        x =>
          x.issuerId === Number(defaultIssuer.id) &&
          x.seriesType.toLowerCase() === 'bill' && x.status &&
          x.year === new Date().getFullYear().toString()
      );

      this.generatedNumberForInvoiceHeaderFormGroup.markAsPristine();
      this.generatedNumberForInvoiceHeaderFormGroup.markAsUntouched();
      this.billInvoiceModel = new BillInvoiceModel();
      this.billInvoiceModel.issuerId = 0;
      this.billInvoiceModel.seriesId = 0;
      this.billInvoiceModel.load = arg;
      if (this.selectedWork.clientId) {
        const client = await this._workService.GetClientById(
          this.selectedWork.clientId
        );
        if (client) {
          let clientSeriesList = await this._workService.GetSeriesByClientId(
            client.id
          );
          clientSeriesList = clientSeriesList ? clientSeriesList : [];
          clientSeriesList = _.chain(clientSeriesList)
            .groupBy('seriesId')
            .map(value => value[0])
            .value() as ClientSeries[];
          clientSeriesList = clientSeriesList ? clientSeriesList : [];
          if (clientSeriesList.length > 0) {
            const invoiceSeries = clientSeriesList.find(
              cs => cs.seriesType.toLowerCase() === 'bill'
            );
            if (invoiceSeries) {
              this.billInvoiceModel.issuerId = invoiceSeries.issuerId
                ? invoiceSeries.issuerId
                : 0;
              this.cdref.markForCheck();
              const seriesList = await this._workService.GetSeriesListByYearAndIssuerId(
                new Date().getFullYear().toString(),
                this.billInvoiceModel.issuerId
              );
              if (seriesList) {
                this.seriesListForBilling = seriesList.filter(
                  s =>
                    s.seriesType.toLowerCase() === 'bill' &&
                    s.status &&
                    s.year === new Date().getFullYear().toString()
                );
                this.billInvoiceModel.seriesId = invoiceSeries.seriesId
                  ? invoiceSeries.seriesId
                  : 0;
                this.cdref.markForCheck();
                this.OnSelectInvoiceHeaderSeries();
              }
            }
          } else {

            this.seriesListForBilling = this.seriesList;
            this.billInvoiceModel.issuerId = (defaultIssuer) ? defaultIssuer.id : 0;
            this.billInvoiceModel.seriesId = (this.seriesList.length > 0) ? this.seriesList[0].id : 0;
            if (this.seriesList.length > 0) {
              this.OnChangeBillIssuer(this.seriesList[0].id);
            }
          }
        }
      }
      this.cdref.markForCheck();
    } catch (error) { console.log(error); } finally {
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnSelectInvoiceHeaderIssuer() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (Number(this.billInvoiceModel.issuerId)) {
        const list = await this._workService.GetSeriesList();
        if (list) {
          this.seriesListForBilling = list.filter(
            x =>
              x.issuerId === Number(this.billInvoiceModel.issuerId) &&
              x.seriesType.toLowerCase() === 'bill' && x.status &&
              x.year === new Date().getFullYear().toString()
          );

          if (this.seriesListForBilling != null && this.seriesListForBilling.length > 0) {
            this.billInvoiceModel.seriesId = this.seriesListForBilling[0].id;
          } else {
            this.billInvoiceModel.seriesId = 0;
          }
          this.cdref.markForCheck();
          this.OnSelectInvoiceHeaderSeries();
        }
      } else {
        this.seriesListForBilling = [];
        this.billInvoiceModel.issuerId = 0;
        this.billInvoiceModel.seriesId = 0;
        this.billInvoiceModel.generatedNumber = '';
        this.billInvoiceModel = new BillInvoiceModel();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnSelectInvoiceHeaderSeries() {
    if (Number(this.billInvoiceModel.seriesId)) {
      const series = await this._workService.GetSeriesById(
        this.billInvoiceModel.seriesId
      );
      if (series) {
        await this.LoadIvNumberingProcessComponent(series);
        const genNumberObject = this._numberingProcessService.GetGeneratedNumber(
          this.ivNumberingProcess,
          this.ivMaxSize,
          { id: series.id, type: 'Bill', series: series.series }
        );
        if (genNumberObject) {
          this.billInvoiceModel.generatedNumber =
            genNumberObject.generateNumber;
        }
      }
    } else {
      this.billInvoiceModel.generatedNumber = '';
    }
    this.cdref.markForCheck();
  }


  public OnChangeBillIssuer(seriesId?: number, onLoad?: boolean) {
    if (this.billInvoiceModel.issuerId !== undefined && Number(this.billInvoiceModel.issuerId) !== 0) {
      const issuer: Issuer = this.issureList.find(
        (x, i, a) => Number(x.id) === Number(this.billInvoiceModel.issuerId)
      );
      if (issuer) {
        // this.issuer = issuer;
        const year: number = new Date().getFullYear();
        this._workService
          .GetSeriesListByYearAndIssuerId(year.toString(), issuer.id)
          .then(list => {
            this.seriesList = list.filter(
              x =>
                x.year === new Date().getFullYear().toString() &&
                x.seriesType.toLowerCase() === 'bill' &&
                x.status
            );

            if (seriesId != null && seriesId > 0) {
              this.billInvoiceModel.seriesId = seriesId;
            } else if (this.seriesList != null && this.seriesList.length > 0) {
              this.billInvoiceModel.seriesId = this.seriesList[0].id;
            } else {
              this.billInvoiceModel.seriesId = 0;
            }
            if (onLoad !== true) {
              this.OnSelectInvoiceHeaderSeries();
            }

            this.cdref.markForCheck();
          });
      } else {
        this.billInvoiceModel.generatedNumber = '';
        this.cdref.markForCheck();
      }
    } else {
      this.billInvoiceModel.generatedNumber = "";
    }
    this.cdref.markForCheck();
  }

  private async LoadIvNumberingProcessComponent(series: Series) {
    try {
      if (series) {
        this.ivSeries = { id: series.id, type: 'Bill', series: series.series };
        this.ivNumberingProcess = new NumberingProcess({
          isNextAvailableNumber: true,
          nextAvailableNumber: series.lastNumber,
          isAnother: false,
          isNoNumberAssigned: false,
          isOtherAvailableNumbers: false,
          anotherNumber: 1,
          otherAvailableNumber: 0
        });
        const reservedNumberList = await this._workService.GetSavedNumberListBySeriesId(
          series.id
        );
        if (reservedNumberList) {
          this.ivNumberingProcess.otherAvailableNumberList = reservedNumberList.map(
            r => r.savedNumber.toString()
          );
        }
        this.cdref.markForCheck();
      }
    } catch (error) {
      console.log(error);
    }
  }

  OnSaveNewInvoiceHeader() {
    if (this.generatedNumberForInvoiceHeaderFormGroup.invalid) {
      ShowInvalidFormControls(this.generatedNumberForInvoiceHeaderFormGroup);
      return;
    }
    if (this.billInvoiceModel && this._deliveryNoteToBeBilled) {

      this._router.navigate(['/invoice'], {
        queryParams: {
          load: this.billInvoiceModel.load,
          delivery: this._deliveryNoteToBeBilled.id,
          client: this.selectedWork.clientId,
          clientName: this.selectedWork.clientName,
          issuer: this.billInvoiceModel.issuerId,
          series: this.billInvoiceModel.seriesId,
          ignNumber: this.billInvoiceModel.generatedNumber
        }
      });
      this.cdref.markForCheck();
    }
  }
}
