import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastData, ToastyService } from 'ng2-toasty';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { DropDownOption } from 'src/app/modules/common/entities/dropdown-option';
import { ModalNames } from 'src/app/modules/common/global';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { WorkerTopes } from 'src/app/modules/workers/entities/worker-topes';
import swal from 'sweetalert2';
import { ApiService } from '../../../common/services/api.service';
import { CommonDataService } from '../../../common/services/common.data.service';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { ShowInvalidFormControls } from '../../../common/services/validators';
import { TaskWorker } from '../../../tasks/entities/task-worker';
import { WorkTypePhaseTemplate, WorkTypeTaskTemplate } from '../../../work-type/entities/work-type-model-templates';
import { Worker } from '../../../workers/entities/worker';
import { IWorkComponent } from '../../entities/i-work-component';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtWorker } from '../../entities/main-work-type-worker';
import { SubWork } from '../../entities/sub-work';
import { Task } from '../../entities/task';
import { Work } from '../../entities/work';
import { MwtWorkerService } from '../../services/mwt-worker.service';
import { PhaseTemplateService } from '../../services/phase-template.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit, OnDestroy, IWorkComponent {

  // subscriptions
  private workService: Subscription;

  // lists
  phaseTemplateList: WorkTypePhaseTemplate[] = [];
  uniquePhaseTemplateList: WorkTypePhaseTemplate[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];
  selectedSubWork: SubWork;
  mainWorkTypeList: MainWorkType[];
  workerList: Worker[] = [];
  filterWorkerList: { workerName: string, id: number }[] = [];
  taskList: Task[] = [];
  taskActiveList: Task[] = [];
  workerDropdownFilter: DropDownOption[] = [];

  // objects
  selectedMainWorkType: MainWorkType;
  selectedWork: Work;
  hasLoaded = true;

  @Output() refresh = new EventEmitter<any>();
  showPopUp = false;
  showWorkerTopesModal = false;
  dailyWorkerTope: WorkerTopes;
  canEditWork = false;
  canEditWorkLoad = false;
  allClosed = false;
  canEditWorker = false;

  // forms
  workerTopesFormGroup: FormGroup;

  constructor(private _workService: WorkService,
    private _apiService: ApiService,
    private _phaseTemplateService: PhaseTemplateService,
    private _mwtWorkerService: MwtWorkerService,
    public commonUiService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: ToastyService,
    private _modalService: ModalService, private _ngxPermissionService: NgxPermissionsService,
    private _formBuilder: FormBuilder) {
    this.workService = this._workService.detailObject.share().subscribe((args) => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (args.invokeComponents.includes('Task') || args.invokeComponents.includes('*')) {
          this.hasLoaded = false;
          this.phaseTemplateList = [];
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this.selectedMainWorkType = args.selectedMainWorkType;
          this.selectedWork = args.selectedWork;
          this.selectedSubWork = args.selectedSubWork;
          this.allClosed = this.selectedMainWorkTypeList.every(x => x.isClosed);
          if (args.selectedMainWorkTypeList.length > 0) {
            if (this.workerList.length === 0) { this.LoadWorkerList(); }
          }
          if (args.transferable.length > 0) {
            const phaseTemplateListDetail = args.transferable.find(t => t.name === 'phaseTemplateList');
            if (phaseTemplateListDetail) {
              this.phaseTemplateList = phaseTemplateListDetail.object as WorkTypePhaseTemplate[];
              this.SyncTaskData();
            }
          }
        }
      }
    });
    this.workerTopesFormGroup = this._formBuilder.group({
      totalDailyTopes: [],
      totalDailyTasks: [],
      topeOfTheDay: [0, Validators.required],
      date: [],
      workerId: [],
      mwtPhaseId: [],
      taskId: []
    });
  }

  ngOnInit() {
    this.dailyWorkerTope = new WorkerTopes();
    this.LoadTaskList();
    this.LoadWorkerList();
    const permission = this._ngxPermissionService.getPermission('EditWork');
    this.canEditWork = permission !== undefined;

    const permissionWorkLoad = this._ngxPermissionService.getPermission('EditWorkloads');
    this.canEditWorkLoad = permissionWorkLoad !== undefined;

    const permissionWorker = this._ngxPermissionService.getPermission('ViewWorkers');
    this.canEditWorker = permissionWorker !== undefined;
  }

  ngOnDestroy() { this.workService.unsubscribe(); }

  //#region Resources

  LoadWorkerList() {
    this._apiService.get(API.worker.getWorkerList, true)
      .subscribe((list: Worker[]) =>{ 
        this.workerList = list.filter(x => x.proffesionalCategory === 'Operarios' && x.status)
      },
        err => console.log(err));
  }

  LoadTaskList() {
    this._apiService.get(API.task.getTaskList, true)
      .subscribe((list: Task[]) =>
        this.taskList = list,
        err => console.log(err));
  }

  //#endregion

  //#region CRUD

  private SyncTaskData() {
    try {
      if (this.phaseTemplateList.length > 0) {
        for (let i = 0; i < this.phaseTemplateList.length; i++) {
          const phaseTemplate = this.phaseTemplateList[i];
          const currentMainWorkType = this.selectedMainWorkTypeList.find(m => Number(m.id) === phaseTemplate.workTypeId);
          if (currentMainWorkType) {
            phaseTemplate.cssClass = currentMainWorkType.cssClass;
            phaseTemplate.symbol = currentMainWorkType.symbol;
            phaseTemplate.order = currentMainWorkType.order;
            phaseTemplate.startDate = phaseTemplate.startDate ? phaseTemplate.startDate.substring(0, 10) : '';
            phaseTemplate.endDate = phaseTemplate.endDate ? phaseTemplate.endDate.substring(0, 10) : '';

            phaseTemplate.taskList = phaseTemplate.taskList ? phaseTemplate.taskList : [];
            if (phaseTemplate.taskList.length > 0) {
              for (let j = 0; j < phaseTemplate.taskList.length; j++) {
                const taskTemplate = phaseTemplate.taskList[j];
                if (taskTemplate) {
                  taskTemplate.taskWorkerControl = new FormControl();

                  taskTemplate.isVisible = true;
                  taskTemplate.order = phaseTemplate.order;
                  taskTemplate.workerList = taskTemplate.workerList ? taskTemplate.workerList : [];
                  if (currentMainWorkType.isClosed) {
                    taskTemplate.isClosed = true;
                  } else {
                    taskTemplate.isClosed = false;
                  }

                  // push aasigned workers to filter dropdown list
                  if (taskTemplate.workerId) {
                    const index = this.filterWorkerList.findIndex(x => x.id === taskTemplate.workerId);
                    if (index === -1) {
                      this.filterWorkerList.push({
                        workerName: taskTemplate.workerName,
                        id: taskTemplate.workerId
                      });
                    }
                    taskTemplate.taskWorkerControl.setValue(taskTemplate.workerName);
                  }
                  if (taskTemplate.workerList.length > 0) {
                    const taskWorkers: TaskWorker[] = [];
                    for (let k = 0; k < taskTemplate.workerList.length; k++) {
                      const worker = taskTemplate.workerList[k];
                      if(this.workerList && this.workerList.length>0){
                        const tempWorker: Worker = this.workerList.find(x=>x.id == worker.workerId);
                        if(tempWorker == undefined){
                          continue;
                        }
                       }
                      if (worker) {
                        const twr = new TaskWorker();
                        twr.taskId = taskTemplate.taskId;
                        twr.workerId = worker.workerId;
                        twr.workerName = worker.workerName;
                        taskWorkers.push(twr);
                      }
                    }
                    taskTemplate.taskWorkerList = taskWorkers;
                    taskTemplate.hasWorkersAssigned = taskWorkers.length > 0;

                    const tw = new TaskWorker();
                    tw.taskId = taskTemplate.taskId;
                    tw.workerId = -1;
                    tw.workerName = 'All';
                    taskTemplate.taskWorkerList.push(tw); // add 'all' option to the list
                  } else {
                    const taskWorkers: TaskWorker[] = [];
                    this.workerList.forEach(worker => {
                      const tw = new TaskWorker();
                      tw.taskId = taskTemplate.taskId;
                      tw.workerId = worker.id;
                      tw.workerName = worker.name;
                      taskWorkers.push(tw);
                    });
                    taskTemplate.taskWorkerList = taskWorkers;
                    taskTemplate.hasWorkersAssigned = taskWorkers.length > 0;
                  }
                }
              }
            }
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this.hasLoaded = true;
    }
  }

  private AssignWorkerForTask(mwtPhaseId: number, taskId: number, workerId: number): Promise<any> {
    return new Promise<MwtWorker[]>((resolve, reject) => {
      this._apiService.put(API.main_work_type_work.assignWorkerForTask + mwtPhaseId + '/' + taskId + '/' + workerId)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }

  private RemovePhaseTask(mwtPhaseId: number, taskId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.delete(API.main_work_type_work.deletePhaseTask,
        mwtPhaseId.toString() + '/' + taskId.toString() + '/' + this.selectedWork.id, true)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }

  //#endregion

  //#region Events

  async OnChangeTaskWorker(mwtPhaseId: number, taskId: number, taskWorker: any, isTypeAhead: boolean = false) {
    if (mwtPhaseId && taskId && taskWorker) {
      const workerId: number = !isTypeAhead ? Number(taskWorker) : Number(taskWorker.workerId);
      const phaseTemplate: WorkTypePhaseTemplate = this.phaseTemplateList.find(p => p.mwtPhaseId === mwtPhaseId);
      phaseTemplate.taskList = phaseTemplate.taskList ? phaseTemplate.taskList : [];
      const taskTemplate: WorkTypeTaskTemplate = phaseTemplate.taskList.find(t => t.taskId === taskId);

      if (workerId === -1) {
        // if user selects 'all' option, populate all workers to te respected typeahead
        // const phaseTemplate: WorkTypePhaseTemplate = this.phaseTemplateList.find(p => p.mwtPhaseId === mwtPhaseId);
        if (phaseTemplate) {
          // phaseTemplate.taskList = phaseTemplate.taskList ? phaseTemplate.taskList : [];
          // const taskTemplate: WorkTypeTaskTemplate = phaseTemplate.taskList.find(t => t.taskId === taskId);
          if (taskTemplate) {
            const taskWorkers: TaskWorker[] = [];
            this.workerList.forEach(wkr => {
              const tw = new TaskWorker();
              tw.taskId = taskTemplate.taskId;
              tw.workerId = wkr.id;
              tw.workerName = wkr.name;
              taskWorkers.push(tw);
            });
            taskTemplate.taskWorkerList = taskWorkers;
            taskTemplate.hasWorkersAssigned = taskWorkers.length > 0;
          }
        }
      } else {
        if (taskTemplate) {
          if (workerId && taskTemplate.deliveryDate) {

            const availabilityResponse: {
              dateList: string[],
              isAllow: boolean
            } = await this._mwtWorkerService
              .CheckWorkerAvailabilityForDeliveryDate(workerId, taskTemplate.taskId, taskTemplate.deliveryDate);
            if (availabilityResponse) {
              let availabilitySwalResult = null;
              if (availabilityResponse.isAllow) {
                availabilityResponse.dateList = availabilityResponse.dateList ? availabilityResponse.dateList : [];
                if (availabilityResponse.dateList.length > 0) {
                  let dateStr = '';
                  for (let index = 0; index < availabilityResponse.dateList.length; index++) {
                    const date = availabilityResponse.dateList[index];
                    dateStr += (date.substring(0, 10) + ', ');
                  }
                  availabilitySwalResult = await swal({
                    text: this._commonDataService
                      .localizationLabelList['allowed_free_days'] + '. ( ' + dateStr.substring(0, dateStr.length - 2) + ' ) ',
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: this._commonDataService.localizationLabelList['common_accept'],
                    allowOutsideClick: false,
                  });
                }
              } else {
                availabilitySwalResult = await swal({
                  text: this._commonDataService
                    .localizationLabelList['not_allowed_holidays'],
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: this._commonDataService.localizationLabelList['common_accept'],
                  allowOutsideClick: false,
                });
                if (availabilitySwalResult) {
                  if (availabilitySwalResult.value) {
                    if (!taskTemplate.workerId) {
                      taskTemplate.taskWorkerControl.setValue('');
                    } else {
                      const wkr = this.workerList.find(w => w.id === taskTemplate.workerId);
                      if (wkr) {
                        taskTemplate.workerId = wkr.id;
                        taskTemplate.taskWorkerControl.setValue(wkr.name);
                      }
                    }
                    return;
                  }
                }
              }
            }
            if (taskTemplate.taskValue) {
              const taskValueResponse = await this._phaseTemplateService.CheckDailyTaskHoursLimitForWorker(workerId,
                taskTemplate.deliveryDate, taskTemplate.taskValue, taskTemplate.mwtPhaseTaskId);
              if (taskValueResponse.result === 'invalid') {
                const taskWorkerX = taskTemplate.taskWorkerList.find(tw => tw.workerId === workerId);
                const swalResult = await swal({
                  text: this._commonDataService
                    .localizationLabelList['worker_task_value_exceeded_msg_1'] + (taskWorkerX ? taskWorkerX.workerName : '') +
                    this._commonDataService
                      .localizationLabelList['worker_task_value_exceeded_msg_2'],
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: this._commonDataService.localizationLabelList['common_accept'],
                  allowOutsideClick: false,
                });
                if (swalResult) {
                  if (swalResult.value) {
                    if (taskValueResponse.tope) {
                      this.dailyWorkerTope = taskValueResponse.tope;
                      this.workerTopesFormGroup.setValue({
                        totalDailyTopes: taskValueResponse.tope.dailyTotal + Number(taskTemplate.taskValue),
                        totalDailyTasks: taskValueResponse.tope.dailyMaxlimit,
                        topeOfTheDay: taskValueResponse.tope.tope,
                        date: taskTemplate.deliveryDate,
                        workerId: workerId,
                        mwtPhaseId: phaseTemplate.mwtPhaseId,
                        taskId: taskTemplate.taskId
                      });
                    }
                    this.showWorkerTopesModal = true;
                    return;
                  } else {
                    this.OnCancelWorkerTopes();
                    this.SyncTaskData();
                    return;
                  }
                }
              } else if (taskValueResponse.result === 'error' || taskValueResponse.result === 'not_defined') {
                console.error('error has occured while checking'); return;
              }
            }
          } else {
            if (!taskTemplate.deliveryDate) {
              const deliveryDateSwalResult = await swal({
                text: this._commonDataService.localizationLabelList['no_delivery_date'],
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this._commonDataService.localizationLabelList['common_accept'],
                allowOutsideClick: false,
              });
              if (deliveryDateSwalResult) {
                if (deliveryDateSwalResult.value) {
                  if (!taskTemplate.workerId) {
                    this._workService.SetDetailObject({
                      selectedMainWorkTypeList: this.selectedMainWorkTypeList,
                      selectedMainWorkType: this.selectedMainWorkType,
                      selectedSubWork: this.selectedSubWork,
                      selectedWork: this.selectedWork,
                      mainWorkTypeList: this.mainWorkTypeList,
                      invokeComponents: ['Task'],
                      transferable: [
                        { name: 'phaseTemplateList', object: this.phaseTemplateList },
                      ]
                    });
                  } else {
                    const wkr = this.workerList.find(w => w.id === taskTemplate.workerId);
                    if (wkr) {
                      taskTemplate.workerId = wkr.id;
                      taskTemplate.taskWorkerControl.setValue(wkr.name);
                    }
                  }
                  return;
                }
              }
            }
          }
        }
        this.commonUiService.isSpinnerVisible = true;
        await this.OnAssignTaskWorker(mwtPhaseId, taskId, workerId);
        this.commonUiService.isSpinnerVisible = false;
      }
    }
  }

  OnFilterByWorker(workerId: any) {
    if (Number(workerId)) {
      this.phaseTemplateList.forEach(phase => {
        if (phase.taskList.length > 0) {
          phase.taskList.forEach(task => {
            if (Number(task.workerId) === Number(workerId)) { task.isVisible = true; } else { task.isVisible = false; }
          });
        }
      });
    } else {
      this.phaseTemplateList.forEach(phase => {
        if (phase.taskList.length > 0) { phase.taskList.forEach(task => task.isVisible = true); }
      });
    }
  }

  updateView() {
    this.SyncTaskData();
    this.refresh.emit(this.selectedSubWork);
  }

  OnRemovePhaseTask(mwtPhaseId: number, taskId: number) {
    if (mwtPhaseId && taskId) {
      swal({
        title: this._commonDataService.localizationLabelList['phase_task'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      }).then((result) => {
        if (result.value) {
          this.RemovePhaseTask(mwtPhaseId, taskId)
            .then(response => {
              if (response['_statusCode'] === 200) {
                this.phaseTemplateList.forEach(phase => {
                  if (Number(phase.mwtPhaseId) === Number(mwtPhaseId)) {
                    if (phase.taskList.length > 0) {
                      const index: number = phase.taskList.findIndex((x, i, a) => Number(x.taskId) === Number(taskId));
                      if (index !== -1) { phase.taskList.splice(index, 1); }
                    }
                  }
                });
                this._toastyService.success({
                  title: this._commonDataService.localizationLabelList['phase_task'],
                  msg: this._commonDataService.localizationLabelList['delete_success'],
                  showClose: true,
                  theme: 'default',
                  timeout: 3000,
                  onAdd: (toast: ToastData) => { },
                  onRemove: (toast: ToastData) => { }
                });
              } else if (response['_statusCode'] === 409) {
                this._toastyService.error({
                  title: this._commonDataService.localizationLabelList['phase_task'],
                  msg: this._commonDataService.localizationLabelList['delete_success'],
                  showClose: true,
                  theme: 'default',
                  timeout: 3000,
                  onAdd: (toast: ToastData) => { },
                  onRemove: (toast: ToastData) => { }
                });
              }
            });
        }
      });
    }
  }

  OnOpenModal(modal: string) {
    let mwtList = [] as MainWorkType[];
    for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
      const mwt = this.selectedMainWorkTypeList[index];
      if (!mwt.isClosed) {
        mwtList.push(mwt);
      }
    }
    this.showPopUp = true;
    this._modalService.Invoke(modal, true, {
      selectedMainWorkTypeList: mwtList,
      selectedMainWorkType: this.selectedMainWorkType,
      selectedWork: this.selectedWork,
      taskList: this.taskList,
      workerList: this.workerList,
      phaseTemplateList: this.phaseTemplateList,
      isEdit: false
    }, false);
  }

  OnEditPhaseTask(phase: WorkTypePhaseTemplate, task: WorkTypeTaskTemplate) {
    let mwtList = [] as MainWorkType[];
    for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
      const mwt = this.selectedMainWorkTypeList[index];
      if (!mwt.isClosed) {
        mwtList.push(mwt);
      }
    }
    this.showPopUp = true;
    this.taskActiveList = this.taskList.filter(x => x.status);
    const taskItem = this.taskList.filter(x => x.id === task.taskId);
    if (taskItem.length > 0) {
      if (this.taskActiveList.filter(x => x.id === task.taskId).length === 0) {
        this.taskActiveList.push(taskItem[0]);
      }
    }
    const transferData = {
      selectedMainWorkTypeList: mwtList,
      selectedMainWorkType: this.selectedMainWorkType,
      selectedWork: this.selectedWork,
      taskList: this.taskActiveList,
      workerList: this.workerList,
      phaseTemplateList: this.phaseTemplateList,
      phaseTemplate: phase,
      task: task,
      isEdit: true
    };
    this._modalService.Invoke(ModalNames.TaskModal, true, transferData, true);
  }

  OnCloseModal(modal: any) {
    if (modal) {
      this.commonUiService.isPopupOpened = false;
      this.commonUiService.CloseModalEx(modal);
    }
  }

  async OnAssignTaskWorker(mwtPhaseId: number, taskId: number, workerId: number) {
    const response = await this.AssignWorkerForTask(mwtPhaseId, taskId, workerId);
    if (response) {
      const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
      if (idList) {
        let phaseTemplateList = await this._phaseTemplateService.GetPhaseTemplateListByMainWorkTypeIdList(idList);
        phaseTemplateList = phaseTemplateList ? phaseTemplateList : [];
        this._workService.SetDetailObject({
          selectedMainWorkTypeList: this.selectedMainWorkTypeList,
          selectedMainWorkType: this.selectedMainWorkType,
          selectedSubWork: this.selectedSubWork,
          selectedWork: this.selectedWork,
          mainWorkTypeList: this.mainWorkTypeList,
          invokeComponents: ['Worker', 'Task'],
          transferable: [
            { name: 'phaseTemplateList', object: phaseTemplateList },
            { name: 'hasChangedWorkerList', object: true }
          ]
        });
      }
      this._toastyService.success({
        title: this._commonDataService.localizationLabelList['task_workers'],
        msg: this._commonDataService.localizationLabelList['worker_assigned_to_task_successfully'],
        showClose: true,
        theme: 'default',
        timeout: 5000,
        onAdd: (toast: ToastData) => { },
        onRemove: (toast: ToastData) => { }
      });
    } else {
      this._toastyService.error({
        title: this._commonDataService.localizationLabelList['task_workers'],
        msg: this._commonDataService.localizationLabelList['save_error'],
        showClose: true,
        theme: 'default',
        timeout: 5000,
        onAdd: (toast: ToastData) => { },
        onRemove: (toast: ToastData) => { }
      });
    }
  }

  //#endregion

  //#region Worker Topes

  async OnSaveWorkerTopes() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (this.workerTopesFormGroup.invalid) { ShowInvalidFormControls(this.workerTopesFormGroup); } else {
        const form = this.workerTopesFormGroup.value as {
          totalDailyTopes: number,
          totalDailyTasks: number,
          topeOfTheDay: number,
          date: string,
          workerId: number,
          mwtPhaseId: number,
          taskId: number
        };
        if (form.topeOfTheDay < form.totalDailyTopes) {
          this._toastyService.error({
            title: this._commonDataService.localizationLabelList['worker_topes'],
            msg: this._commonDataService.localizationLabelList['tope_of_the_day_must_be_greater_than_or_equal_to_total_daily_topes']
          });
        } else {
          if (this.dailyWorkerTope) {
            this.dailyWorkerTope.tope = form.topeOfTheDay;
            this.dailyWorkerTope.dailyMaxlimit = form.topeOfTheDay;
            const response = await this._phaseTemplateService
              .UpdateWorkerTopes(this.dailyWorkerTope.id, this.dailyWorkerTope);
            if (response) {
              const body = JSON.parse(response['_body']);
              if (body._statusCode === 200) {
                await this.OnAssignTaskWorker(form.mwtPhaseId, form.taskId, form.workerId);
                this.OnCancelWorkerTopes();
              } else {
                this.OnCancelWorkerTopes();
                this.SyncTaskData();
              }
            }
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  OnCancelWorkerTopes() {
    this.showWorkerTopesModal = false;
  }

  //#endregion
}
