import { SafeResourceUrl } from '@angular/platform-browser';

export class MwtIncidentDocument {
  id: number;  
  generateFileName: string;
  fileName: string;
  mwtincidentId: number;
  mainWorkTypeId: number;

  file: any;
  fileList: any;
  extension: string;
  url: string;
  link: string;
  path: string;
  safeUrl: SafeResourceUrl;
}
