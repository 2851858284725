import { Injectable } from '@angular/core';
import { DeliveryNote } from '../entities/delivery-note';
import { ApiService } from '../../common/services/api.service';
import API from './../../common/api.config.json';
import { ClientDefaultPrintReport } from '../../client/entities/client-default-print-report';
import { BehaviorSubject } from 'rxjs';
import { DeliveryNoteListChangedSubjectArgs } from '../entities/multiple-delivery-notes-models';
import { DeliveryNoteNo } from '../entities/global-search-no-filters';

@Injectable({
  providedIn: 'root'
})
export class DeliveryNoteService {

  deliveryNoteListChangedSubject:
    BehaviorSubject<DeliveryNoteListChangedSubjectArgs> = new BehaviorSubject(new DeliveryNoteListChangedSubjectArgs());
  deliveryNoteListChanged = this.deliveryNoteListChangedSubject.asObservable();

  constructor(private _apiService: ApiService) { }

  setChangedDeliveryNoteList(args: DeliveryNoteListChangedSubjectArgs) {
    this.deliveryNoteListChangedSubject.next(args);
  }

  GetDeliveryNoteListBySubWorkId(subWorkId: number) {
    return this._apiService.get(API.sub_work.getDeliveryNotesBySubWorkId + subWorkId, true)
      .map(d => d as DeliveryNote[]).toPromise();
  }

  UpdateInvoiceDetailList(list: DeliveryNote[]) {
    return this._apiService.put(API.invoice.updateInvoiceDetail, list)
      .map(isUpdated => isUpdated as boolean).toPromise();
  }

  UpdateClientsDeliveryNoteList(list: DeliveryNote[]) {
    return this._apiService.put(API.delivery_note.updateClientsDeliveryNoteList, list)
      .toPromise();
  }

  GenerateDeliveryNote(deliveryNote: DeliveryNote) {
    return this._apiService
      .post(API.sub_work.generateDeliveryNote, deliveryNote, false)
      .toPromise();
  }

  SaveMwtArticleList(list: any[]) {
    return this._apiService.post(API.main_work_type_work.saveMwtArticleList, list, true, true)
      .map(isSaved => isSaved as boolean).toPromise();
  }

  GetSavedTemplateByClientId(clientId: number) {
    return this._apiService.get(API.delivery_note.getSavedTemplateByClientId + clientId)
      .map(x => x as ClientDefaultPrintReport).toPromise();
  }

  SearchDeliveryNoteNo(query: string) {
    return this._apiService.get(API.delivery_note.search_delivery_note_no + query, false)
      .map(list => list as DeliveryNoteNo[])
      .toPromise();
  }
}
