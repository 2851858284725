import { Component, OnInit, Input } from '@angular/core';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { UsingReportModel } from '../../entities/using-report-model';

@Component({
  selector: 'app-using-report',
  templateUrl: './using-report.component.html'
})
export class UsingReportComponent implements OnInit {

  @Input() public usingReport: UsingReportModel;
  imageData: any;

  constructor(private _commonUIService: CommonUIService) { }

  ngOnInit() {
    this.LoadLabConfigurationList();
  }

  async LoadLabConfigurationList() {
    this.imageData = await this._commonUIService.LoadLabConfigurationList();
  }

}
