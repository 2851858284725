import { WorkerLabSections } from "./worker-lab-sections";

export class Worker {
  id: any;
  status: boolean;
  code: string;
  name: string;
  address: string;
  postalCode: string;
  telephonePersonal: string;
  telephoneOffice: string;
  registerNumber: string;
  shiftTime: string;
  shiftTypeId: number;
  workerTypeId: number;
  notes: string;
  proffesionalCategory: any;
  vacationStartDate: any;
  vacationEndDate: any;
  labSectionId: number;
  percentage: number;
  points: number;
  maxPoints: number;
  monthlyPoints: number;
  annualCost: number;
  warehouseId: number;
  defaultWarehouseId: number;
  townId: number;
  imageName: string;
  imagePathUrl: string;
  email: string;
  labSectionName: string;
  shiftTypeName: string;
  warehouseName: string;
  workTypeName: string;
  townName: string;
  countryName: string;
  provinceName: string;
  createdDate: any;
  crearedBy: number;
  modifiedDate: any;
  modifiedBy: number;
  dateFormatVacStartDate?: any;
  dateFormatVacEndDate?: any;
  orderSymbolList: { order: number, symbol: string }[] = [];
  userLoginId: number;
  order: number;
  mainworktypeId: number;
  initals: string;
  workerLabSections: WorkerLabSections[];
  surname: string;
  socialSecurityNumber: string;
  dni: string;
  birthDate: any;
  dailyTotalTasks: string;

  constructor() {
    this.status = true;
  }
}
