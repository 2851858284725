import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { MotivesCancelPhase } from '../../../entities/motives-cancel-phase';
import { FormGroup, NgForm, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../../common/services/api.service';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import API from './../../../../common/api.config.json';
import { CommonDataService } from '../../../../common/services/common.data.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Toast } from 'src/app/modules/common/services/toast.service';

@Component({
  selector: 'motives-cancel-phase-list',
  templateUrl: './motives-cancel-phase.component.html',
  styleUrls: ['./motives-cancel-phase.component.scss']
})
export class MotivesCancelPhaseComponent implements OnInit {

  successMessage: any;
  updateMessage: any;
  errorMessage: any;
  deleteMessage: any;
  statusMessage: any;
  deleteErrorMessage: any;
  tableName: any;
  defaultValue: any;

  motivesCancelPhase: MotivesCancelPhase;
  isLoaded: boolean;
  position = 'bottom-right';
  motivesCancelPhaseList: MotivesCancelPhase[];
  private _motivesCancelPhaseList: MotivesCancelPhase[] = [];

  motivesCancelPhaseFormGroup: FormGroup;
  motivesCancelPhaseForm: NgForm;
  hasEditPermission = false;
  isDisabledMotives = false;

  @ViewChild('description') nameField: ElementRef;

  constructor(
    private _apiService: ApiService,
    private _ngxPermissionService: NgxPermissionsService,
    private _fb: FormBuilder,
    private _commonUIService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: Toast) {
    this.motivesCancelPhase = new MotivesCancelPhase();
    this.isLoaded = false;
    this.isDisabledMotives = false;

  }

  ngOnInit() {
    this.motivesCancelPhaseFormGroup = this._fb.group({
      description: ['', [
        Validators.required
      ]]
    });
    this.LoadMotivesCancelPhaseList();
    this.SetPermissions();
  }

  async SetPermissions() {
    this.hasEditPermission = await this._ngxPermissionService.hasPermission('EditDeleteAuxiliarytables');
  }

  LoadMotivesCancelPhaseList() {
    this._apiService.get(API.motives_cancel_phase.getMotivesCancelPhaseList)
      .subscribe(res => {
        this.motivesCancelPhaseList = res;
        this._motivesCancelPhaseList = res;
        this.isLoaded = true;
      });
  }


  LoadEditMotivesCancelPhase(motivesCancelPhase: MotivesCancelPhase) {
    motivesCancelPhase.isEdit = true;
  }

  EditMotivesCancelPhase(motivesCancelPhase: MotivesCancelPhase, statusChange: boolean) {
    this.isDisabledMotives = true;

    this.statusMessage = this._commonDataService.localizationLabelList['status_updated'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.tableName = this._commonDataService.localizationLabelList['motives_cancel_phase'];

    motivesCancelPhase.isEdit = false;
    this._apiService.put(API.motives_cancel_phase.editMotivesCancelPhase + motivesCancelPhase.id,
      motivesCancelPhase, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {
            let message = '';
            if (statusChange) {
              message = this.statusMessage;
            }
            else {
              message = this.updateMessage;
            }
            this._toastyService.success({
              title: this.tableName,
              msg: message
            });
            this.LoadMotivesCancelPhaseList();
          }
        },
        err => {
          console.log(err);
        },
        () => {
          this.isDisabledMotives = false;
        }
      );
  }

  RemoveEditMotivesCancelPhase(motivesCancelPhase: MotivesCancelPhase) {
    motivesCancelPhase.isEdit = false;
    this.LoadMotivesCancelPhaseList();
  }

  ActiveDeactiveMotivesCancelPhase(motivesCancelPhase: MotivesCancelPhase) {
    if (motivesCancelPhase) {
      if (motivesCancelPhase.status) {
        motivesCancelPhase.status = false;
      }
      else {
        motivesCancelPhase.status = true;
      }
      this.EditMotivesCancelPhase(motivesCancelPhase, true);
    }
  }

  OnOpenAddMotivesCancelPhaseModal() {

    this.successMessage = this._commonDataService.localizationLabelList['save_success'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.errorMessage = this._commonDataService.localizationLabelList['save_error'];
    this.tableName = this._commonDataService.localizationLabelList['motives_cancel_phase'];

    this.motivesCancelPhase = new MotivesCancelPhase(); this.motivesCancelPhaseFormGroup.reset();
    this._commonUIService.openModal('motives-cancel-phase-modal');

    setTimeout(() => {
      this.nameField.nativeElement.focus();
    }, 100);

  }

  OnCloseMotivesCancelPhaseModal(event) {
    (((((event.target.parentElement.parentElement).parentElement).parentElement).parentElement).parentElement).classList.remove('md-show');
  }

  SaveMotivesCancelPhase(motivesCancelPhase: MotivesCancelPhase, event) {
    this.isDisabledMotives = true;
    if (this.motivesCancelPhaseFormGroup.invalid) {
      Object.keys(this.motivesCancelPhaseFormGroup.controls).forEach(field => {
        const control = this.motivesCancelPhaseFormGroup.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      this.isDisabledMotives = false;
      return;
    }
    motivesCancelPhase.status = true;
    if (this.motivesCancelPhaseList.length === 0)
      motivesCancelPhase.byDefault = true;
    else
      motivesCancelPhase.byDefault = false;

    this.OnCloseMotivesCancelPhaseModal(event);

    this._apiService.post(API.motives_cancel_phase.saveMotivesCancelPhase, motivesCancelPhase, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {
            this._toastyService.success({
              title: this.tableName,
              msg: this.successMessage
            });
            this.LoadMotivesCancelPhaseList();
          }
        },
        err => {
          this._toastyService.error({
            title: this.tableName,
            msg: err
          });
          this.isDisabledMotives = false;
        },
        () => {
          this.isDisabledMotives = false;
        }
      );
  }

  EditByDefaultMotivesCancelPhase(motivesCancelPhase: MotivesCancelPhase) {

    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.defaultValue = this._commonDataService.localizationLabelList['default'];
    this.tableName = this._commonDataService.localizationLabelList['motives_cancel_phase'];

    motivesCancelPhase.isEdit = false;
    this._apiService.put(API.motives_cancel_phase.saveByDefault + motivesCancelPhase.id,
      motivesCancelPhase, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {
            let message = '';
            message = this.updateMessage;
            this._toastyService.success({
              title: this.tableName,
              msg: message
            });
            this.LoadMotivesCancelPhaseList();
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  DeleteMotivesCancelPhase(id: number) {
    this.isDisabledMotives = true;

    this.deleteMessage = this._commonDataService.localizationLabelList['delete_success'];
    this.deleteErrorMessage = this._commonDataService.localizationLabelList['delete_error'];
    this.tableName = this._commonDataService.localizationLabelList['motives_cancel_phase'];

    if (id) {
      this._apiService.delete(API.motives_cancel_phase.deleteMotivesCancelPhase, id.toString())
        .subscribe(
          res => {
            if (res._statusCode == 200) {
              let index: number = this._motivesCancelPhaseList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._motivesCancelPhaseList.splice(index, 1);
                this._toastyService.success({
                  title: this.tableName,
                  msg: this.deleteMessage
                });
              }
            }
            if (res._statusCode == 409) {
              let index: number = this._motivesCancelPhaseList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._toastyService.warning({
                  title: this.tableName,
                  msg: this.deleteErrorMessage
                });
              }
            }
          },
          err => {
            this._toastyService.error({
              title: this.tableName,
              msg: err
            });
            this.isDisabledMotives = false;
          },
          () => {
            this.isDisabledMotives = false;
            this.motivesCancelPhaseList = this._motivesCancelPhaseList;
          }
        );
    }
  }

}
