export class WarehouseProduct {
  id: number;
  warehouseId: number;
  productld: number;
  idealStock: any;
  minimumStock: any;
  actualStock: any;
  lastIn: number;
  lastOut: number;
  modifiedDate: any;
  createdDate: any;
  status: boolean;

  productName: string;
  productCode: string;
  warehouseName: string;

  // Stock filter properties
  wpStatus: any;
  idealStockCB: boolean;
  minimumStockCB: boolean;
  actualStockCB: boolean;

  constructor() {
    this.idealStockCB = true;
    this.minimumStockCB = true;
    this.actualStockCB = true;
  }
}
