export const DENTBOX_API_VERSION = '/api/v1';
export const DENTBOX_FILE_VERSION = '/file/v1';

export const DENTBOX_AUTH = {
  GET_ACCESS_TOKEN: 'authentication/token',
  GET_SESSION_TOKEN: 'authentication/dentbox',
  GET_TICKET: 'authentication/ticketweb'
};

export const DENTBOX_LAB = {
  GET_CONNECTED_CENTERS: 'novalab/labs/connections/'
};

export const DENTBOX_LAB_WORKS = {
  GET_LAB_WORKS: 'novalab/labs/works',
  UPDATE_LAB_WORKS: 'novalab/labs/works/',
};
