import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrabajosTableRoutingModule } from './trabajos-table-routing.module';
import { TrabajosTableComponent } from './trabajos-table.component';
import { SharedModule } from '../../../shared/shared.module';
import { HttpModule } from '@angular/http';
import { DataTableModule } from 'angular2-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { StatusOfWorkComponent } from './components/status-of-work/status-of-work.component';
import { ShippingFormsComponent } from './components/shipping-forms/shipping-forms.component';
import { TherapeuticPurposesComponent } from './components/therapeutic-purposes/therapeutic-purposes.component';
import { PhasesComponent } from './components/phases/phases.component';
import { LocalizationsComponent } from './components/localizations/localizations.component';
import { MotivesCancelPhaseComponent } from './components/motives-cancel-phase/motives-cancel-phase.component';
import { MetalsComponent } from './components/metals/metals.component';
import { ResourceComponent } from './components/resource/resource.component';
import { UiSwitchModule } from 'ng2-ui-switch';
import { ToastyModule } from 'ng2-toasty';
import { SelectModule } from 'ng-select';
import { ColorComponent } from './components/color/color.component';
import { ProductDescriptionsComponent } from './components/product-description/product-description.component';
import { GroupOfMaterialComponent } from './components/group-of-material/group-of-material.component';
import { ClinicMaterialComponent } from './components/clinic-material/clinic-material.component';
import { WorkMotiveNoFeeComponent } from './components/work-motive-no-fee/work-motive-no-fee.component';
import { TypesOfQualityComponent } from './components/types-of-quality/types-of-quality.component';
import { GuideComponent } from './components/guide/guide.component';
import { WorkPageConfigurationComponent } from './components/work-page-configuration/work-page-configuration.component';
import { ProcessComponent } from './components/process/process.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ReservationMethodsComponent } from './components/reservation-methods/reservation-methods.component';
import { IncidentsComponent } from './components/incidents/incidents.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  imports: [
    CommonModule,
    TrabajosTableRoutingModule,
    SharedModule,
    HttpModule,
    DataTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    SelectModule,
    NgxMaskModule.forRoot(options),
    UiSwitchModule,
    ToastyModule.forRoot()
  ],
  declarations: [
    TrabajosTableComponent,
    StatusOfWorkComponent,
    ShippingFormsComponent,
    TherapeuticPurposesComponent,
    PhasesComponent,
    LocalizationsComponent,
    MotivesCancelPhaseComponent,
    MetalsComponent,
    ResourceComponent,
    ColorComponent,
    ProductDescriptionsComponent,
    GroupOfMaterialComponent,
    ClinicMaterialComponent,
    WorkMotiveNoFeeComponent,
    TypesOfQualityComponent,
    GuideComponent,
    ProcessComponent,
    ReservationMethodsComponent,
    IncidentsComponent
  ],

})

export class TrabajosTableModule { }
