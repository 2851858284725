export class ProductProvider {
  id: number;
  productId: number;
  providerManufacId: any;
  reference: string;
  description1: string;
  description2: string;
  price: any;
  discount: any;
  unitTypes: string;
  unitEquivalent: string;
  barcode: string;
  status: boolean;

  providerId: number;
  productName: string;
  providerName: string;
  providerCode: string;
  shippingCost: number;
  providerEmail: string;
  minPriceOrder: number;

  actualStock?: number;
  productCode: string;

  constructor() {
    this.status = true;
  }
}
