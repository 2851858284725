import { MainWorkType } from './main-work-type';
import { MWTExternalData } from './main-work-type-external-data';
import { MwtImplantData } from './main-work-type-implant-data';
import { Note } from './note';
import { MwtColor } from './main-work-type-color';
import { MwtClinicMaterial } from './main-work-type-clinic-material';
import { Prescription } from './prescription';
import { Document } from './document';
import { MwtArticle } from './main-work-type-article';
import { Mwtproduct } from './main-work-type-product';
import { MwtMetal } from './main-work-type-metal';

export class MwtDto {
    mainWorkType: MainWorkType;
    mwtExternalData: MWTExternalData;
    mwtImplantData: MwtImplantData;
    noteList: Note[];
    mwtColorList: MwtColor[];
    mwtClinicMaterialList: MwtClinicMaterial[];
    prescriptionList: Prescription[];
    documentList: Document[];
    mwtArticleList: MwtArticle[];
    mwtProductList: Mwtproduct[];
    isIncludeArticleProduct: boolean;
    mwtMetalList: MwtMetal[];

    constructor(init?: Partial<MwtDto>) { if (init) { Object.assign(this, init); } }
}
