import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { Localizations } from 'src/app/modules/auxiliary/entities/localizations';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import swal from 'sweetalert2';
import { Color } from '../../../auxiliary/entities/Color';
import { Metals } from '../../../auxiliary/entities/metals';
import { ApiService } from '../../../common/services/api.service';
import { CommonDataService } from '../../../common/services/common.data.service';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { IWorkComponent } from '../../entities/i-work-component';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtColor } from '../../entities/main-work-type-color';
import { MwtMetal } from '../../entities/main-work-type-metal';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { MwtColorService } from '../../services/mwt-color.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';
declare var $: any;

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss']
})
export class ColorsComponent implements OnInit, OnDestroy, IWorkComponent {
  // subscriptions
  private workService: Subscription;
  @Output() refresh = new EventEmitter<any>();

  // lists
  mwtColorList: MwtColor[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];
  mainWorkTypeList: MainWorkType[] = [];
  selectedTeethList: number[] = [];
  colorList: Color[] = [];
  localizationList: Localizations[] = [];
  metalList: Metals[] = [];
  mwtMetalList: MwtMetal[] = [];

  // objects
  mwtColor: MwtColor;
  selectedSubWork: SubWork;
  selectedWork: Work;
  selectedMainWorkType: MainWorkType;
  hasLoaded = true;
  isNewMwtColor = false;
  isColorsEditable = false;
  colorNameControl = new FormControl('');
  metalNameControl = new FormControl('');
  isAvailableLocalization = false;

  showAddPopUp = false;
  showEditPopUp = false;
  rowEditable = false;
  canEditWork = false;
  allClosed = false;
  showAddMetalPopUp = false;

  constructor(private _workService: WorkService, private _apiService: ApiService, private _modalService: ModalService,
    private __service: MwtColorService, public commonUiService: CommonUIService, private _dropdownService: DropdownService,
    private _commonDataService: CommonDataService, private _toastyService: Toast, private _ngxPermissionService: NgxPermissionsService) {
    this.workService = this._workService.detailObject.share().subscribe(async (args) => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (args.invokeComponents.includes('Color') || args.invokeComponents.includes('*')) {
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this.selectedWork = args.selectedWork;
          this.selectedMainWorkType = args.selectedMainWorkType;
          this.selectedSubWork = args.selectedSubWork;
          this.mainWorkTypeList = args.mainWorkTypeList;
          this.isAvailableLocalization = false;
          this.allClosed = this.selectedMainWorkTypeList.every(x => x.isClosed);
          await this.SyncMwtColorData();
          await this.SyncMwtMetalData();
          this.isColorsEditable = false;
        }
      }
    });
  }

  ngOnInit() {
    this.colorList = this._dropdownService.colorList;
    this.metalList = this._dropdownService.metalList;
    const permission = this._ngxPermissionService.getPermission('EditWork');
    this.canEditWork = permission !== undefined;
  }

  ngOnDestroy() { this.workService.unsubscribe(); }

  //#region CRUD

  async SyncMwtColorData() {
    this.mwtColorList = [];
    try {
      this.hasLoaded = false;
      const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
      if (idList) {
        let mwtColorList = await this.__service.GetMwtColorListByMainWorkTypeIdList(idList);
        mwtColorList = mwtColorList ? mwtColorList : [];
        if (mwtColorList.length > 0) {
          for (let index = 0; index < mwtColorList.length; index++) {
            const mwtColor = mwtColorList[index];

            mwtColor.mwtColorControl = new FormControl(mwtColor.colorText);
            mwtColor.mwtColorMetalControl = new FormControl(mwtColor.metalName);

            const currentMainWorkType = this.selectedMainWorkTypeList.find(m => Number(m.id) === mwtColor.mainWorkTypeId);
            if (currentMainWorkType) {
              mwtColor.order = currentMainWorkType.order;
              mwtColor.cssClass = currentMainWorkType.cssClass;
              mwtColor.symbol = currentMainWorkType.symbol;
              if (currentMainWorkType.isClosed) {
                mwtColor.isClosed = true;
              } else {
                mwtColor.isClosed = false;
              }
            }
          }
        }
        this.mwtColorList = mwtColorList;
        this.mwtColorList.forEach(element => {
          if (element.localizationId && element.localizationText) {
            this.isAvailableLocalization = true;
          }
        });
      }
    } catch (error) { console.log(error); }
    finally { this.hasLoaded = true; }
  }

  private async DeleteMwtColor(mwtColorId: number) {
    return this._apiService.delete(API.main_work_type_work.deleteColor,
      mwtColorId.toString() + '/' + this.selectedWork.id, true).map(isDeleted => isDeleted as boolean).toPromise();
  }

  //#endregion

  //#region Events

  // inline events
  OnColorsEditable(state: boolean) {
    this.isColorsEditable = state;
    if (!this.isColorsEditable) {
      this.OnSaveAllMwtColors();
    }
    this.rowEditable = state;
  }

  OnSelectInlineColorLocalization(localization: Localizations, mwtColor: MwtColor) {
    if (localization) {
      mwtColor.localizationId = Number(localization.id);
      mwtColor.localizationText = localization.description;
    }
  }

  OnChangeInlineMwtColorItem(color: Color, mwtColor: MwtColor) {
    if (color && mwtColor) {
      mwtColor.colorId = color.id;
      mwtColor.colorText = color.description;
    } else {
      mwtColor.colorId = null;
      mwtColor.colorText = '';
    }
  }

  OnChangeInlineMwtColorMetal(metal: Metals, mwtColor: MwtColor) {
    if (metal && mwtColor) {
      mwtColor.metalId = metal.id;
      mwtColor.metalName = metal.description;
    } else {
      mwtColor.metalId = null;
      mwtColor.metalName = '';
    }
  }

  OnChangeInlineMwtColorNotes(value, mwtColor: MwtColor) {
    if (value !== undefined && value !== null && mwtColor) {
      mwtColor.notes = value;
    }
  }

  async OnSaveAllMwtColors() {
    this.commonUiService.isSpinnerVisible = true;
    const mwtColorList = this.mwtColorList.map(mwtC => ({
      id: mwtC.id,
      mwtArticleId: mwtC.mwtArticleId,
      localizationId: mwtC.localizationId,
      colorId: mwtC.colorId,
      pieces: mwtC.pieces,
      notes: mwtC.notes,
      status: mwtC.status,
      colorText: mwtC.colorText,
      localizationText: mwtC.localizationText,
      mwtArticleName: mwtC.mwtArticleName,
      guideName: mwtC.guideName,
      metalId: mwtC.metalId,
      metalName: mwtC.metalName,
      mainWorkTypeId: mwtC.mainWorkTypeId,
      cssClass: mwtC.cssClass,
      symbol: mwtC.symbol,
      order: mwtC.order,
      workId: mwtC.workId,
      createdBy: mwtC.createdBy,
      createdDate: mwtC.createdDate,
      isClosed: mwtC.isClosed,
    }));
    const response = await this.SaveAllMwtColors(mwtColorList as MwtColor[]);
    if (response['_isSuccsess']) {
      this._toastyService.success({
        title: this._commonDataService.localizationLabelList['work_color'],
        msg: this._commonDataService.localizationLabelList['save_success']
      });
      this._workService.SetDetailObject({
        selectedMainWorkTypeList: this.selectedMainWorkTypeList,
        selectedMainWorkType: this.selectedMainWorkType,
        selectedSubWork: this.selectedSubWork,
        selectedWork: this.selectedWork,
        mainWorkTypeList: this.mainWorkTypeList,
        invokeComponents: ['Color'],
      });
    } else {
      this._toastyService.error({
        title: this._commonDataService.localizationLabelList['work_color'],
        msg: this._commonDataService.localizationLabelList['save_error']
      });
    }
    this.commonUiService.isSpinnerVisible = false;
  }

  private async SaveAllMwtColors(colorList: MwtColor[]) {
    return this._apiService.post(API.main_work_type_work.saveAllColor, colorList, false, true)
      .map(response => JSON.parse(response['_body'])).toPromise();
  }
  //

  OnOpenAddModal(modal: string) {
    let mwtList = [] as MainWorkType[];
    for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
      const mwt = this.selectedMainWorkTypeList[index];
      if (!mwt.isClosed) {
        mwtList.push(mwt);
      }
    }

    this.showAddPopUp = true;
    this._modalService.Invoke(modal, true, {
      mainWorkTypeList: mwtList,
      selectedWork: this.selectedWork,
      mwtColor: undefined, isEdit: false
    }, false);
  }

  OnOpenEditModal(mwtColor: MwtColor, modal: string) {
    let mwtList = [] as MainWorkType[];
    for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
      const mwt = this.selectedMainWorkTypeList[index];
      if (!mwt.isClosed) {
        mwtList.push(mwt);
      }
    }
    this.showEditPopUp = true;
    this._modalService.Invoke(modal, true, {
      mainWorkTypeList: mwtList,
      selectedWork: this.selectedWork,
      mwtColor: mwtColor, isEdit: true
    }, true);
  }

  async OnRemoveMwtColor(mwtColorId: number, mwtArticleId: number) {
    if (mwtColorId) {
      swal({
        title: this._commonDataService.localizationLabelList['work_color'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      }).then(async (result) => {
        if (result.value) {
          this.commonUiService.isSpinnerVisible = true;
          const isDeleted = await this.DeleteMwtColor(mwtColorId);
          if (isDeleted) {
            if (Number(mwtArticleId) > 0) {
              this._toastyService.warning({
                title: this._commonDataService.localizationLabelList['work_color'],
                msg: this._commonDataService.localizationLabelList['metal_configured_with_an_article']
              });
            }
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['work_color'],
              msg: this._commonDataService.localizationLabelList['delete_success']
            });
            await this.SyncMwtColorData();
            this._workService.SetDetailObject({
              selectedMainWorkTypeList: this.selectedMainWorkTypeList,
              selectedMainWorkType: this.selectedMainWorkType,
              selectedSubWork: this.selectedSubWork,
              selectedWork: this.selectedWork,
              mainWorkTypeList: this.mainWorkTypeList,
              invokeComponents: ['Color'],
            });
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['work_color'],
              msg: this._commonDataService.localizationLabelList['delete_error']
            });
          }
          this.commonUiService.isSpinnerVisible = false;
        }
      });
    }
  }

  //#endregion

  closeModel() {
    this.showAddPopUp = false;
    this.showEditPopUp = false;
    this.showAddMetalPopUp = false;
  }

  refreshArticlePanel() {
    this.refresh.emit(this.selectedSubWork);
  }

  //#region MwtMetal

  async SyncMwtMetalData() {
    this.mwtMetalList = [];
    try {
      this.hasLoaded = false;
      const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
      if (idList) {
        let mwtMetalList = await this.__service.GetMwtmatelListByMainWorkTypeId(idList);
        mwtMetalList = mwtMetalList ? mwtMetalList : [];
        if (mwtMetalList.length > 0) {
          for (let index = 0; index < mwtMetalList.length; index++) {
            const mwtMetal = mwtMetalList[index];

            mwtMetal.mwtMetalControl = new FormControl(mwtMetal.metalName);

            const currentMainWorkType = this.selectedMainWorkTypeList.find(m => Number(m.id) === mwtMetal.mainWorkTypeId);
            if (currentMainWorkType) {
              mwtMetal.order = currentMainWorkType.order;
              mwtMetal.cssClass = currentMainWorkType.cssClass;
              mwtMetal.symbol = currentMainWorkType.symbol;
              if (currentMainWorkType.isClosed) {
                mwtMetal.isClosed = true;
              } else {
                mwtMetal.isClosed = false;
              }
            }
          }
        }
        this.mwtMetalList = mwtMetalList;
        this._workService.SetDetailObject({
          selectedMainWorkTypeList: this.selectedMainWorkTypeList,
          selectedMainWorkType: this.selectedMainWorkType,
          selectedSubWork: this.selectedSubWork,
          selectedWork: this.selectedWork,
          mainWorkTypeList: this.mainWorkTypeList,
          invokeComponents: ['Pieces'],
          transferable: [{ name: 'mwtColorList', object: this.mwtColorList }, { name: 'mwtMetalList', object: this.mwtMetalList }]
        });
      }
    } catch (error) { console.log(error); }
    finally { this.hasLoaded = true; }
  }

  OnOpenAddMetalModal(modal: any) {
    let mwtList = [] as MainWorkType[];
    for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
      const mwt = this.selectedMainWorkTypeList[index];
      if (!mwt.isClosed) {
        mwtList.push(mwt);
      }
    }

    this.showAddMetalPopUp = true;
    this._modalService.Invoke(modal, true, {
      mainWorkTypeList: mwtList,
      selectedWork: this.selectedWork, isEdit: false
    }, false);
  }


  async OnRemoveMwtMetal(mwtMetalId: number) {
    if (mwtMetalId) {
      swal({
        title: this._commonDataService.localizationLabelList['metal'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      }).then(async (result) => {
        if (result.value) {
          this.commonUiService.isSpinnerVisible = true;
          const isDeleted = await this.DeleteMwtMetal(mwtMetalId);
          if (isDeleted) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['metal'],
              msg: this._commonDataService.localizationLabelList['delete_success']
            });
            await this.SyncMwtMetalData();
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['metal'],
              msg: this._commonDataService.localizationLabelList['delete_error']
            });
          }
          this.commonUiService.isSpinnerVisible = false;
        }
      });
    }
  }

  private async DeleteMwtMetal(mwtMetalId: number) {
    return this._apiService.delete(API.main_work_type_work.deleteMatel,
      mwtMetalId.toString() + '/' + this.selectedWork.id, true).map(isDeleted => isDeleted as boolean).toPromise();
  }

  //#endregion

}
