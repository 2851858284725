import { Injectable } from '@angular/core';
import { ToastyService, ToastOptions } from 'ng2-toasty';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class Toast {

  defaults;
  constructor(private _toastyService: ToastyService,
    private toastr: ToastrService) {
    this.setDefaults();
  }

  setDefaults() {
    this.defaults = {
      timeOut: 3000
    };
  }

  success(options: ToastOptions) {
    const mergedOptions = { ...this.defaults, ...options };
    this.toastr.success(mergedOptions.msg, mergedOptions.title);
  }

  error(options: ToastOptions) {
    const mergedOptions = { ...this.defaults, ...options };
    this.toastr.error(mergedOptions.msg, mergedOptions.title);
  }

  warning(options: ToastOptions) {
    const mergedOptions = { ...this.defaults, ...options };
    this.toastr.warning(mergedOptions.msg, mergedOptions.title);
  }

  info(options: ToastOptions) {
    const mergedOptions = { ...this.defaults, ...options };
    this.toastr.info(mergedOptions.msg, mergedOptions.title);
  }
}
