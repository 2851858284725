import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { NumberingProcessData, NumberingProcess } from './numbering-process';
import API from './../../modules/common/api.config.json';

@Injectable({
    providedIn: 'root'
})
export class NumberingProcessService {

    constructor(private _apiService: ApiService) { }

    CheckGenerateNumber(npd: NumberingProcessData) {
        return this._apiService.getUrlEncoded(API.series.checkGenerateNumber, npd, true)
            .map(x => x as boolean).toPromise();
    }

    ContinueNumberingProcess(npd: NumberingProcessData) {
        return this._apiService.getUrlEncoded(API.series.continueNumberingProcess, npd, true, false)
            .map(x => x as boolean).toPromise();
    }

    GetGeneratedNumber(numberingProcess: NumberingProcess, maxSize: number,
        series: {
            id: number, type: 'Work' | 'Delivery Note' | 'Bill' | 'Budget' | 'Receipt' | 'Invoice Cancel' | 'Receipt Cancel',
            series: string
        }, year?: number) {
        if (numberingProcess.isAnother) {
            if (Number(numberingProcess.nextAvailableNumber) === Number(numberingProcess.anotherNumber)) {
                return {
                    generateNumber: 'same_number',
                    genNumber: 0,
                    deleteNumber: 0,
                    seriesId: series.id,
                    seriesType: (series.type ===
                        'Delivery Note' ? 'DeliveryNote' : series.type) as
                        'DeliveryNote' | 'Work' | 'Bill' | 'Budget' | 'Delivery Note' | 'Receipt' | 'Invoice Cancel'
                };
            }
        }
        const isWork = series.type === 'Work';
        const prefix = !isWork ? series.series.toUpperCase() + '-' : '';
        const _year = year > 0 ? year.toString().slice(-2) : new Date().getFullYear().toString().slice(-2);
        const yearSuffix = !isWork ? '/' + _year : '';
        const numToBeUsed = numberingProcess.isNextAvailableNumber ?
            (numberingProcess.nextAvailableNumber ? numberingProcess.nextAvailableNumber : 1) :
            numberingProcess.isAnother ? numberingProcess.anotherNumber :
                numberingProcess.isOtherAvailableNumbers ? numberingProcess.otherAvailableNumber : 1;
        const gno = prefix + numToBeUsed.toString().padStart(maxSize, '0') + yearSuffix;
        return {
            generateNumber: gno,
            genNumber: Number(numToBeUsed),
            deleteNumber: 0,
            seriesId: series.id,
            seriesType: (series.type ===
                'Delivery Note' ? 'DeliveryNote' : series.type) as 'DeliveryNote' | 'Work' | 'Bill' | 'Budget' | 'Delivery Note' | 'Receipt' | 'Invoice Cancel'
        };
    }
}
