export class MwtPhaseViewModel {
  phaseDate: number;
  fromDate: string;
  toDate: string;
  phaseStatus: string;
  workNumberValue: string;
  clientValue: number;
  phaseValue: number;
  labSectionValue: number;
  phaseIsTestValue: string;
}
