import {
    Directive, Input, OnInit, ComponentFactoryResolver,
    Renderer, ViewContainerRef, ComponentRef, Output, EventEmitter
} from '@angular/core';
import { PaginationComponent, AdvanceSearchDTO } from './pagination.component';
import { RequestData } from './requestData';

@Directive({
    selector: "[pagination]"
})
export class PaginationDirective implements OnInit {
    constructor(
        private resolver: ComponentFactoryResolver,
        private renderer: Renderer,
        public viewContainerRef: ViewContainerRef
       ) {
    }


    @Input() url: any;
    @Input() recordsPerPage = 8;
    @Input() NgModel: any[];
    @Input() Extra: any;

    @Input() query = '';
    @Input() refresh = false;
    @Input() postLoad = false;
    @Input() requestData: RequestData;
    @Input() advanceQuery: AdvanceSearchDTO = null;
    pages: any = [];
    @Output() NgModelChange = new EventEmitter();
    @Output() refreshChange = new EventEmitter();
    @Output() ExtraChange = new EventEmitter();
    


    componentRef: ComponentRef<PaginationComponent>;
    component: PaginationComponent;

    ngOnInit(): void {
    }

    ngOnChanges() {
        // Check changes of the UI or model
        if (this.componentRef) {
            if (this.refresh) {
                this.component.url = this.url;
                this.component.requestData = this.requestData;
                this.componentRef.instance.selectedPage = this.requestData.page;
                this.componentRef.instance.Refresh();
                setTimeout(() => {
                    this.refresh = false;
                    this.refreshChange.emit(this.refresh);
                });
            }
        }
    }

    ngAfterViewInit() {
        // Create conponent factory
        let factory = this.resolver.resolveComponentFactory(PaginationComponent);

        // Add component 
        this.componentRef = this.viewContainerRef.createComponent(factory);
        this.component = this.componentRef.instance;
        this.component.url = this.url;
        this.component.recordsPerPage = this.recordsPerPage;
        this.component.requestData = this.requestData;
        this.component.postLoad = this.postLoad;

        this.component.dataReturn.subscribe(this.DataReturn);
    };

    DataReturn = (items: any): void => {
        if (items.extra) {
            this.Extra = items.extra;
            this.NgModel = items.list;
            this.ExtraChange.emit(this.Extra);
        } else {
            this.NgModel = items;
        }

        this.NgModelChange.emit(this.NgModel);
    }
}
