export class ProductLot {
  id: number;
  productId: number;
  lotId: number;
  lotNumber: string;
  expireDate?: any;
  unitPerLot: number;
  isNotToUse: boolean;
  status: boolean;

  regDate: any;
  expireDateView: any;
  regDateView: any;
  productName: string;
  lotModificationDate: any;

  isregDateViewRequired: boolean;

  quantity ?: number;
  warehouseId?: any;
  warehouseName: string;
  note: string;
}
