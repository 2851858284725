import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ApiService {

    BASE_URL: string;

    constructor(
        private http: Http,
        private httpClient: HttpClient,
        private router: Router) {
    }

    async setBaseUrl() {
        if (sessionStorage['c2VydmVyLW9yaWdpbg==']) {
            this.BASE_URL = atob(sessionStorage['c2VydmVyLW9yaWdpbg==']);
        } else {
            const Config = await this.getJSON();
            if (Config) {
                this.BASE_URL = Config.base_url;
                sessionStorage['c2VydmVyLW9yaWdpbg=='] = btoa(Config.base_url);
            } else {
                sessionStorage.clear();
                localStorage.clear();
                this.router.navigate(['/']);
                throw new Error('Config file is not found!');
            }
        }
    }

    private getJSON() {
        return this.http.get('app/config.json')
            .map(res => res.json()).toPromise();
    }

    get(url: string, isAuthorizationIncluded: boolean = false) {
        let headers = new Headers({
            'Content-Type': 'application/json'
        });

        if (isAuthorizationIncluded) {
            headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': atob(sessionStorage['access-token'])
            });
        }
        const options = new RequestOptions({ headers: headers });
        if (url.charAt(0) !== '/') {
            url = '/' + url;
        }
        url = url.replace('//', '/').replace('///', '/');

        const uri = this.BASE_URL + url;
        return this.http
            .get(uri, options)
            .pipe(map(response => response.json()['_data']), catchError(err => of(err)));
    }

    getUrlEncoded(url: string, model: any, isAuthorizationIncluded = false, mapJson = true) {
        let headers = new Headers({
            'Content-Type': 'application/json'
        });
        if (isAuthorizationIncluded) {
            headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': atob(sessionStorage['access-token'])
            });
        }
        const options = new RequestOptions({ headers: headers });
        if (url.charAt(0) !== '/') {
            url = '/' + url;
        }
        const params = new URLSearchParams();
        for (const key in model) {
            if (model.hasOwnProperty(key)) {
                params.set(key, model[key]);
            }
        }
        const query = params.toString().replace('+', '%20');
        const uri = this.BASE_URL + url + '?' + query;

        if (mapJson) {
            return this.http
                .get(uri, options)
                .pipe(map(response => response.json()['_data']), catchError(err => of(err)));
        } else {
            return this.http.get(uri, options)
                .pipe(map(response => response as any), catchError(err => of(err)));
        }
    }

    post(url: string, data?: any, mapJson: boolean = true, isAuthorizationIncluded: boolean = false) {
        let headers = new Headers({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': this.BASE_URL
        });

        if (isAuthorizationIncluded) {
            headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': atob(sessionStorage['access-token'])
            });
        }
        const options = new RequestOptions({ headers: headers });
        if (url.charAt(0) !== '/') {
            url = '/' + url;
        }

        if (mapJson) {
            return this.http.post(this.BASE_URL + url, data, options)
                .pipe(map(response => <any>response.json()['_data']), catchError(err => of('error')));
        } else {
            return this.http.post(this.BASE_URL + url, data, options);
        }
    }

    delete(url: string, id: string, isAuthorizationIncluded: boolean = false) {
      let headers = new Headers({
        'Content-Type': 'application/json'
      });

      if (isAuthorizationIncluded) {
        headers = new Headers({
          'Content-Type': 'application/json',
          'Authorization': atob(sessionStorage['access-token'])
        });
      }
      const options = new RequestOptions({ headers: headers });
      if (url.charAt(0) !== '/') {
        url = '/' + url;
      }
      if (url.slice(url.length - 1) !== '/') {
        url = url + '/';
      }

      return this.http.delete(this.BASE_URL + url + id, options)
        .pipe(map(response => <any>(response).json()), catchError(err => of('error')));
    }

    put(url: string, data?: any, mapJson: boolean = true, isAuthorizationIncluded: boolean = false) {
        let headers = new Headers({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': this.BASE_URL
        });

        if (isAuthorizationIncluded) {
            headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': atob(sessionStorage['access-token'])
            });
        }
        const options = new RequestOptions({ headers: headers });
        if (url.charAt(0) !== '/') {
            url = '/' + url;
        }
        url = url.replace('//', '/').replace('///', '/');

        if (mapJson) {
            return this.http.put(this.BASE_URL + url, JSON.stringify(data), options)
                .pipe(map(response => <any>response.json()['_data']));
        } else {
            return this.http.put(this.BASE_URL + url, data, options);
        }
    }

    upload(url: string, formData: FormData, isAuthorizationIncluded: boolean = false): Observable<HttpEvent<any>> {
        let headers: HttpHeaders = new HttpHeaders();
        if (isAuthorizationIncluded) {
            headers = new HttpHeaders({
                'Authorization': atob(sessionStorage['access-token'])
            });
        }
        const params = new HttpParams();
        const options = {
            params: params,
            reportProgress: true,
            headers: headers
        };
        const req = new HttpRequest('POST', this.BASE_URL + url, formData, options);
        return this.httpClient.request(req);
    }

    // getListWithPagination(url: string, requestData: RequestData, isAuthorizationIncluded: boolean = false) {
    //     let headers = new Headers({
    //         'Content-Type': 'application/json'
    //     });

    //     if (isAuthorizationIncluded) {
    //         headers = new Headers({
    //             'Content-Type': 'application/json',
    //             'Authorization': atob(sessionStorage['access-token'])
    //         });
    //     }
    //     const options = new RequestOptions({ headers: headers });
    //     if (url.charAt(0) !== '/') {
    //         url = '/' + url;
    //     }

    //     const uri = this.BASE_URL + url;
    //     return this.http
    //         .get(uri, options)
    //         .pipe(map(response => response.json()['_data']), catchError(err => of(err)));
    // }
}

