import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { WorkTypeArticleTemplate } from 'src/app/modules/work-type/entities/work-type-model-templates';
import swal from 'sweetalert2';
import { Article } from '../../../articles/entities/article';
import { ListNames } from '../../../common/global';
import { DropdownService } from '../../../common/services/dropdown.service';
import { ModalService } from '../../../common/services/modal.service';
import { PriceList } from '../../../price-list/entities/price-list';
import { DeliveryNote } from '../../entities/delivery-note';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtArticle } from '../../entities/main-work-type-article';
import { Work } from '../../entities/work';
import { ArticleTemplateService } from '../../services/article-template.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';
import { MwtArticleUsage } from '../../entities/mwt-article-usage';
import { SubWorkService } from '../../services/sub-work.service';
import { SubWork } from '../../entities/sub-work';
import { IvaCalculationService } from '../../services/iva-calculation.service';
import { ArticleCalculationService } from '../../../common/services/calculations/article-calculation.service';
import { toFixedNumber } from '../../../common/services/calculations/calculation';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit, OnDestroy {

  // subscriptions
  private workService: Subscription;

  // lists
  articleTemplateList: WorkTypeArticleTemplate[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];
  articleList: Article[] = [];
  articleBorderClassList: { mwtArticleId: number; borderClass: string; }[] = [];
  deliveryNoteList: DeliveryNote[] = [];

  // objects
  hasLoaded = true;
  isDiscountEnabled = true;
  selectedWork: Work;
  selectedSubWork: SubWork;
  mainWorkTypeList: MainWorkType[] = [];
  mwtArticle: MwtArticle;
  editableMwtArticle: MwtArticle;
  selectedMainWorkType: MainWorkType;
  total = 0;
  isPanelEditable = false;
  mwtArticleUsage: MwtArticleUsage;

  showPopUp = false;
  allClosed = false;
  @Output() refresh = new EventEmitter<any>();

  constructor(private _workService: WorkService,
    private _apiService: ApiService,
    private __service: ArticleTemplateService,
    public commonUiService: CommonUIService,
    private _modalService: ModalService,
    public _dropdownService: DropdownService,
    private _commonDataService: CommonDataService,
    private _articleTemplateService: ArticleTemplateService,
    private _toastyService: Toast,
    private _subWorkService: SubWorkService,
    private _ivaCalculationService: IvaCalculationService,
    private _calculationService: ArticleCalculationService) {
    this.workService = this._workService.detailObject.share().subscribe((args) => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (args.invokeComponents.includes('Article') || args.invokeComponents.includes('*')) {
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this.selectedMainWorkType = args.selectedMainWorkType;
          this.selectedWork = args.selectedWork;
          this.selectedSubWork = args.selectedSubWork;
          this.mainWorkTypeList = args.mainWorkTypeList;
          this.allClosed = this.selectedMainWorkTypeList.every(x => x.isClosed);
          if (args.transferable && args.transferable.length > 0) {
            const deliveryNoteListDetail = args.transferable.find(t => t.name === 'deliveryNoteList');
            if (deliveryNoteListDetail) {
              this.deliveryNoteList = deliveryNoteListDetail.object as DeliveryNote[];
            }
          } else { this.SyncArticleData(); }
        }
      }
    });
  }

  ngOnInit() {
    this.mwtArticle = new MwtArticle();
    this.editableMwtArticle = new MwtArticle();
    this.LoadList();
  }

  ngOnDestroy() { this.workService.unsubscribe(); }

  //#region Resources

  private GetPriceListById(id: number): Promise<PriceList> {
    return new Promise<PriceList>((resolve, reject) => {
      this._apiService.get(API.price_list.getPiceListById + id)
        .subscribe((priceList: PriceList) => resolve(priceList),
          err => reject(Error(err)));
    });
  }

  private async LoadList() {
    if (this._dropdownService.articleList !== undefined || this._dropdownService.articleList.length === 0) {
      this._dropdownService.LoadList([ListNames.ArticleList]);
    }
    if (this._dropdownService.ivaTypeList.length === 0) {
      await this._dropdownService.LoadListAsync([ListNames.IvaTypeList]);
    }
    this.articleList = this._dropdownService.articleList;
  }

  //#endregion

  //#region CRUD

  private async SyncArticleData() {
    this.articleTemplateList = [];
    try {
      this.hasLoaded = false;
      const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
      if (idList) {
        let articleTemplateList = await this.__service.GetArticleTemplateListByMainWorkTypeIdList(idList);
        articleTemplateList = articleTemplateList ? articleTemplateList : [];
        this.total = 0;
        if (articleTemplateList.length > 0) {
          for (let index = 0; index < articleTemplateList.length; index++) {
            const articleTemplate = articleTemplateList[index];
            articleTemplate.articleIdControl = new FormControl();
            articleTemplate.articleIdControl.patchValue(articleTemplate.articleName);
            const currentMainWorkType = this.selectedMainWorkTypeList
              .find(m => Number(m.id) === articleTemplate.workTypeId);
            if (currentMainWorkType) {
              articleTemplate.order = currentMainWorkType.order;
              articleTemplate.cssClass = currentMainWorkType.cssClass;
              articleTemplate.symbol = currentMainWorkType.symbol;
              this.total = toFixedNumber(this.total + articleTemplate.finalPrice);
            }
          }
        }
        this.articleTemplateList = articleTemplateList;
      }
    } catch (error) { console.error(error); }
    finally {
      this.isPanelEditable = false;
      this.hasLoaded = true;
      this.SetArticleBorders();
    }
  }

  private SetArticleBorders() {
    try {
      this.deliveryNoteList = this.deliveryNoteList ? this.deliveryNoteList : [];
      if (this.deliveryNoteList.length > 0) {
        for (let i = 0; i < this.deliveryNoteList.length; i++) {
          const deliveryNote = this.deliveryNoteList[i];
          if (deliveryNote) {
            deliveryNote.articleList = deliveryNote.articleList ? deliveryNote.articleList : [];
            for (let j = 0; j < deliveryNote.articleList.length; j++) {
              const deliveryNoteArticle = deliveryNote.articleList[j];
              if (deliveryNoteArticle) {
                const articleTemplate = this.articleTemplateList
                  .find(a => a.mwtArticleId === Number(deliveryNoteArticle.mwtArticleId));
                if (articleTemplate) {
                  articleTemplate.cssBorderColorClass = deliveryNote.cssClass;
                }
              }
            }
          }
        }
      }
    } catch (error) { console.log(error); }
  }

  private AddMwtArticle(mwtArticle: MwtArticle): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.post(API.main_work_type_work.saveMwtArticle, mwtArticle, false, true)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }

  private SaveMwtArticleList(list: any[]) {
    return this._apiService.post(API.main_work_type_work.saveMwtArticleList, list, true, true)
      .map(isSaved => isSaved as boolean).toPromise();
  }

  private RemoveMwtArticle(mwtArticle: WorkTypeArticleTemplate): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.delete(API.main_work_type_work.deleteMwtArticle,
        mwtArticle.mwtArticleId.toString() + '/' + this.selectedWork.id +
        '/' + mwtArticle.workTypeId.toString() + '/' + mwtArticle.articleId.toString(), true)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }

  private RemoveMwtArticleOnly(mwtArticle: WorkTypeArticleTemplate): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.delete(API.main_work_type_work.deleteMwtArticleOnly,
        mwtArticle.mwtArticleId.toString() + '/' + this.selectedWork.id +
        '/' + mwtArticle.workTypeId.toString() + '/' + mwtArticle.articleId.toString(), true)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }

  //#endregion

  //#region Events

  async OnOpenArticleModal(modal: string, isEdit: boolean, articleTemplate?: any) {
    try {
      let mwtList = [] as MainWorkType[];
      for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
        const mwt = this.selectedMainWorkTypeList[index];
        if (!mwt.isClosed) {
          mwtList.push(mwt);
        }
      }
      let articleDropdownItemList = [];
      if (this.articleList) { articleDropdownItemList = this._dropdownService.articleList; }
      if (isEdit) {
        this.commonUiService.isSpinnerVisible = true;
        this.mwtArticleUsage = await this._articleTemplateService
          .CheckMwtArticleUsage(articleTemplate.mwtArticleId);
        this.commonUiService.isSpinnerVisible = false;
        if (this.mwtArticleUsage) {
          if (this.mwtArticleUsage.usedInInvoices) {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['article'],
              msg: this._commonDataService.localizationLabelList['used_in_invoice']
            });
          } else if (this.mwtArticleUsage.usedDeliveryNoteId) {
            const swalResult = await swal({
              title: this._commonDataService.localizationLabelList['article'],
              text: this._commonDataService.localizationLabelList['used_in_delivery_note'],
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
              cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
            });
            if (swalResult) {
              if (swalResult.value) {
                this.commonUiService.isSpinnerVisible = true;
                this.editableMwtArticle = Object.assign({}, articleTemplate);
                this.showPopUp = true;
              }
            }
          } else {
            this.commonUiService.isSpinnerVisible = true;
            this.editableMwtArticle = Object.assign({}, articleTemplate);
            this.showPopUp = true;
          }
        }
      } else { this.showPopUp = true; }
      this._modalService.Invoke(modal, true, {
        selectedWork: this.selectedWork,
        articleDropdownItemList: articleDropdownItemList,
        selectedMainWorkTypeList: mwtList,
        isDiscountEnabled: this.isDiscountEnabled,
        articleTemplate: articleTemplate
      }, isEdit);
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnSelectArticle() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (this.mwtArticle.articleId) {
        if (this.selectedWork.clientId) {
          const mwtArticle = await this._articleTemplateService
            .GetMwtArticlePriceDetails(this.mwtArticle.articleId, this.selectedWork.clientId);
          if (mwtArticle) {
            if (mwtArticle.price !== null) { this.mwtArticle.price = mwtArticle.price; }
            if (mwtArticle.discount !== null) { this.mwtArticle.discount = mwtArticle.discount; }
            this.mwtArticle.discountAmount = mwtArticle.discountAmount ? mwtArticle.discountAmount : 0;

            if (mwtArticle.isAmount !== null) { this.mwtArticle.isAmount = mwtArticle.isAmount; }
            if (mwtArticle.waste !== null) { this.mwtArticle.waste = mwtArticle.waste; }
            if (mwtArticle.quantityWithWaste !== null) {
              this.mwtArticle.quantityWithWaste = mwtArticle.quantityWithWaste;
            }
            if (mwtArticle.priceWithDiscount !== null) {
              this.mwtArticle.priceWithDiscount = mwtArticle.priceWithDiscount;
            }
            if (mwtArticle.lineDiscountAmount !== null) {
              this.mwtArticle.lineDiscountAmount = mwtArticle.lineDiscountAmount;
            }
            if (mwtArticle.lineSubTotal !== null) {
              this.mwtArticle.lineSubTotal = mwtArticle.lineSubTotal;
            }
            let workTypeId = 0;
            if (this.selectedMainWorkTypeList.length === 1) {
              workTypeId = this.selectedMainWorkTypeList[0].workTypeId;
            } else {
              const mwt = this.selectedMainWorkTypeList.find(m => Number(m.id)
                === Number(this.mwtArticle.mainWorkTypeId));
              if (mwt) { workTypeId = mwt.workTypeId; }
            }
            if (workTypeId > 0) {
              const quantity = await this._articleTemplateService
                .GetQuantityByWorkTypeArticleId(workTypeId, Number(this.mwtArticle.articleId));
              this.mwtArticle.quantity = quantity ? quantity : 1;
            } else { this.mwtArticle.quantity = 1; }
            this.OnKeyUpQuantityOrWaste();
            this.OnKeyUpPriceOrDiscount();
          }
        }
        const article = this.articleList.find(x => Number(x.id)
          === Number(this.mwtArticle.articleId));
        if (article) { this.mwtArticle.description1 = article.description1; }
      } else { this.mwtArticle.description1 = ''; }
    } catch (error) { console.log(error); }
    finally { this.commonUiService.isSpinnerVisible = false; }
  }

  OnKeyUpQuantityOrWaste() {
    if (this.mwtArticle.waste !== undefined && this.mwtArticle.quantity !== undefined) {
      this.mwtArticle.quantityWithWaste =
        this._calculationService
          .getQuantityWithWaste(toFixedNumber(this.mwtArticle.quantity),
            toFixedNumber(this.mwtArticle.waste));
    }
  }

  OnKeyUpPriceOrDiscount() {
    if (this.mwtArticle.price !== undefined && this.mwtArticle.discount !== undefined) {
      this.mwtArticle.priceWithDiscount = this._calculationService.
        getPriceWithDiscount(this.mwtArticle.price,
          this.mwtArticle.isAmount ? toFixedNumber(this.mwtArticle.discountAmount)
            : toFixedNumber(this.mwtArticle.discount), this.mwtArticle.isAmount);
    }
  }

  OnChangeQtyWithWasteOrPriceWithDiscount() {
    if (this.mwtArticle.priceWithDiscount !== undefined
      && this.mwtArticle.quantityWithWaste !== undefined) {
      const finalPriceWithoutIva =
        this._calculationService
          .getArticleFinalTotal(toFixedNumber(this.mwtArticle.quantityWithWaste),
            toFixedNumber(this.mwtArticle.priceWithDiscount), 0);

      if (this.mwtArticle.ivaTypeId) {
        this.mwtArticle.ivaAmount = this._calculationService
          .getIvaAmount(finalPriceWithoutIva,
            this.mwtArticle.ivaPercentage ? toFixedNumber(this.mwtArticle.ivaPercentage) : 0);
      }
      this.mwtArticle.finalPrice = toFixedNumber(finalPriceWithoutIva
        + this.mwtArticle.ivaAmount);

      this.mwtArticle.discountAmount = this._calculationService
        .getDiscountAmount(this.mwtArticle.price, this.mwtArticle.discount);

      this.mwtArticle.lineSubTotal = this.GetLineSubTotal(this.mwtArticle.price,
        this.mwtArticle.quantityWithWaste);

      this.mwtArticle.lineDiscountAmount = this
        .GetLineDiscountAmount(this.mwtArticle.finalPrice,
          this.mwtArticle.ivaAmount, this.mwtArticle.lineSubTotal);
    }
  }

  GetLineDiscountAmount(finalTotal: number, vatAmount: number, subTotal: number) {
    try {
      return toFixedNumber(subTotal - (finalTotal - vatAmount));
    } catch (error) { console.log(error); }
  }

  GetLineSubTotal(price: number, quantityWithWaste: number) {
    try {
      return this._calculationService
        .getLineSubTotal(price, quantityWithWaste);
    } catch (error) { console.log(error); }
  }

  AfterArticleSaved(mwtArticle: MwtArticle) {
    if (mwtArticle) {
      this.mwtArticle = mwtArticle;
      this.OnSaveMwtArticle();
      this.showPopUp = false;
    }
  }

  OnSaveMwtArticle() {
    if (this.mwtArticle) {
      if (this.selectedMainWorkTypeList.length === 1) {
        this.mwtArticle.mainWorkTypeId = (this.selectedMainWorkTypeList[0]).id;
      }
      this.commonUiService.isSpinnerVisible = true;
      this.mwtArticle.workId = this.selectedWork.id;

      // if (this.mwtArticle.isAmount) {
      //   this.mwtArticle.discount = 0; this.editableMwtArticle.discount = 0;
      // } else { this.mwtArticle.discountAmount = 0; }
      if (this.mwtArticle.discount !== this.editableMwtArticle.discount) {
        this.mwtArticle.isDiscountOverridden = true;
      }
      this.AddMwtArticle(this.mwtArticle)
        .then(async response => {
          const body = JSON.parse(response['_body']);
          if (body['_isSuccsess']) {
            if (this.mwtArticleUsage) {
              if (this.mwtArticleUsage.usedInDeliveryNotes) {
                await this.SaveDeliveryNotes([{ deliveryNoteId: this.mwtArticleUsage.usedDeliveryNoteId }]);
              }
            }
            this.SyncArticleData();
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['article'],
              msg: this._commonDataService.localizationLabelList['save_success']
            });
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['article'],
              msg: this._commonDataService.localizationLabelList['save_error']
            });
          }
          this.mwtArticleUsage = new MwtArticleUsage();
          this.commonUiService.isSpinnerVisible = false;
        });
    }
  }

  async SaveDeliveryNotes(list: { deliveryNoteId: number }[]) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      const response = await this._subWorkService
        .SetDeliveryNoteCalculations(list);
      if (response) {
        if (response.isUpdated) {
          this._toastyService.success({
            title: this._commonDataService.localizationLabelList['delivery_notes'],
            msg: this._commonDataService.localizationLabelList['save_success']
          });
          this._workService.SetDetailObject({
            selectedMainWorkTypeList: this.selectedMainWorkTypeList,
            selectedMainWorkType: this.selectedMainWorkType,
            selectedSubWork: this.selectedSubWork,
            selectedWork: this.selectedWork,
            mainWorkTypeList: this.mainWorkTypeList,
            invokeComponents: ['DeliveryNote'],
            transferable: []
          });
        } else {
          this._toastyService.error({
            title: this._commonDataService.localizationLabelList['delivery_notes'],
            msg: this._commonDataService.localizationLabelList['save_error']
          });
        }
      }
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  OnRemoveMwtArticle(article: WorkTypeArticleTemplate) {
    if (article) {
      swal({
        title: this._commonDataService.localizationLabelList['article'],
        text: this._commonDataService.localizationLabelList['article_delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      }).then((result) => {
        if (result.value) {
          this.RemoveMwtArticle(article)
            .then(response => {
              if (response['_statusCode'] === 200) {
                this.refresh.emit(this.selectedSubWork);
                this._toastyService.success({
                  title: this._commonDataService.localizationLabelList['article'],
                  msg: this._commonDataService.localizationLabelList['delete_success']
                });
                this.SyncArticleData();
              } else if (response['_statusCode'] === 409 || response['_statusCode'] === 400) {
                this._toastyService.error({
                  title: this._commonDataService.localizationLabelList['article'],
                  msg: this._commonDataService.localizationLabelList['delete_error']
                });
              }
            });
        } else if (result.dismiss) {
          this.RemoveMwtArticleOnly(article)
            .then(response => {
              if (response['_statusCode'] === 200) {
                this.refresh.emit(this.selectedSubWork);
                this._toastyService.success({
                  title: this._commonDataService.localizationLabelList['article'],
                  msg: this._commonDataService.localizationLabelList['delete_success']
                });
                this.SyncArticleData();
              } else if (response['_statusCode'] === 409 || response['_statusCode'] === 400) {
                this._toastyService.error({
                  title: this._commonDataService.localizationLabelList['article'],
                  msg: this._commonDataService.localizationLabelList['delete_error']
                });
              }
            });
        }
      });
    }
  }

  OnCloseModal(event: any) {
    if (event) {
      this.commonUiService.isPopupOpened = false;
      this.commonUiService.CloseModal(event);
    }
  }

  async OnEditPanel() {
    try {
      this.isPanelEditable = !this.isPanelEditable;
      if (this.isPanelEditable) {
        this.commonUiService.isSpinnerVisible = true;
        // if toggle to editable mode
        this.articleList = await this._apiService
          .get(API.article.getArticleDropdownList)
          .map(x => x as Article[]).toPromise();
      } else {
        if (this.articleTemplateList) {
          let result: { option: boolean, linkedDeliveryNoteIds: number[] };
          if (this.articleTemplateList.length > 0) {
            const index = this.articleTemplateList
              .findIndex(a => a.isDelivered && !a.isInvoiced);
            if (index > -1) {
              result = await this.AlertUsedArticles();
              if (!result.option) { this.SyncArticleData(); return; }
            }
            this.commonUiService.isSpinnerVisible = true;
            this.articleTemplateList.forEach(a => {
              a.mainWorkTypeId = a.workTypeId;
              a.id = a.mwtArticleId;
              a.isDiscountOverridden = true;
            });
            const isSaved = await this.SaveMwtArticleList(this.articleTemplateList);
            if (isSaved) {
              this.SyncArticleData();
              if (result && result.option) {
                const mappedDelNoteIds = result
                  .linkedDeliveryNoteIds.map(d => ({ deliveryNoteId: d }));
                await this.SaveDeliveryNotes(mappedDelNoteIds);
              }
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList['article'],
                msg: this._commonDataService.localizationLabelList['save_success']
              });
            } else {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['article'],
                msg: this._commonDataService.localizationLabelList['save_error']
              });
            }
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnChangePanelArticle(arg: any, index: number) {
    try {
      if (arg.id && index > -1 && this.articleTemplateList) {

        this.commonUiService.isSpinnerVisible = true;
        const article = arg as Article;

        this.mwtArticle.articleId = Number(article.id);
        await this.OnSelectArticle();
        this.OnChangeQtyWithWasteOrPriceWithDiscount();

        const list = [...this.articleTemplateList];
        const articleTemplate = list[index];

        articleTemplate.articleId = this.mwtArticle.articleId;
        articleTemplate.articleName = this.mwtArticle.description1;
        articleTemplate.discount = this.mwtArticle.discount;
        articleTemplate.discountAmount = this.mwtArticle.discountAmount;
        articleTemplate.finalPrice = this.mwtArticle.finalPrice;
        articleTemplate.isAmount = this.mwtArticle.isAmount;
        articleTemplate.price = this.mwtArticle.price;
        articleTemplate.quantity = this.mwtArticle.quantity;
        articleTemplate.waste = this.mwtArticle.waste;
        articleTemplate.isDiscountOverridden = this.mwtArticle.isDiscountOverridden;
        articleTemplate.priceWithDiscount = this.mwtArticle.priceWithDiscount;
        articleTemplate.quantityWithWaste = this.mwtArticle.quantityWithWaste;
        articleTemplate.lineDiscountAmount = this.mwtArticle.lineDiscountAmount;
        articleTemplate.lineSubTotal = this.mwtArticle.lineSubTotal;

        this.articleTemplateList = [...list];
      }
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnChangeRowValues(index: number, value: any, prop: string) {
    try {
      this.commonUiService.isSpinnerVisible = true;

      const list = [...this.articleTemplateList];
      const article = list[index] as any;
      this.mwtArticle = Object.assign({}, article);

      switch (prop) {
        case 'discount':
          this.mwtArticle.discount = toFixedNumber(Number(value));
          this.mwtArticle.isDiscountOverridden = true;
          break;
        case 'quantity':
          this.mwtArticle.quantity = toFixedNumber(Number(value));
          break;
        case 'price':
          this.mwtArticle.price = toFixedNumber(Number(value));
          break;
        case 'ivaType':
          const iType = this._dropdownService.ivaTypeList
            .find(it => it.id === toFixedNumber(Number(value)));
          if (iType) { this.mwtArticle.ivaPercentage = iType.iva; }
          this.mwtArticle.ivaTypeId = Number(value);
          this.mwtArticle.ivaAmount = 0;
          break;
        default: break;
      }

      this.mwtArticle.description1 = article.articleName;
      this.mwtArticle.articleId = article.articleId;

      this.OnKeyUpQuantityOrWaste();
      this.OnKeyUpPriceOrDiscount();
      this.OnChangeQtyWithWasteOrPriceWithDiscount();

      article.ivaAmount = Number(this.mwtArticle.ivaAmount);
      article.ivaTypeId = Number(this.mwtArticle.ivaTypeId);
      article.ivaPercentage = Number(this.mwtArticle.ivaPercentage);

      article.articleId = this.mwtArticle.articleId;
      article.articleName = this.mwtArticle.description1;
      article.discount = this.mwtArticle.discount;
      article.discountAmount = this.mwtArticle.discountAmount;
      article.finalPrice = this.mwtArticle.finalPrice;
      article.isAmount = this.mwtArticle.isAmount;
      article.price = this.mwtArticle.price;
      article.quantity = this.mwtArticle.quantity;
      article.waste = this.mwtArticle.waste;
      article.priceWithDiscount = this.mwtArticle.priceWithDiscount;
      article.quantityWithWaste = this.mwtArticle.quantityWithWaste;
      article.lineDiscountAmount = this.mwtArticle.lineDiscountAmount;
      article.lineSubTotal = this.mwtArticle.lineSubTotal;
      article.isDiscountOverridden = this.mwtArticle.isDiscountOverridden;

      list[index] = article;
      this.articleTemplateList = [...list];
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  private async AlertUsedArticles() {
    try {
      if (this.articleTemplateList) {
        const notInvoicedList = this.articleTemplateList.filter(a => !a.isInvoiced);
        if (notInvoicedList && notInvoicedList.length > 0) {
          const index = notInvoicedList.findIndex(a => a.isDelivered);
          if (index > -1) {
            const swalResult = await swal({
              title: this._commonDataService.localizationLabelList['article'],
              text: this._commonDataService.localizationLabelList['used_in_delivery_note_plural'],
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
              cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
            });
            if (swalResult) {
              const linkedDeliveryNotes = notInvoicedList.filter(a => a.linkedDeliveryNoteId)
                .map(a => a.linkedDeliveryNoteId);
              return {
                option: swalResult.value,
                linkedDeliveryNoteIds: linkedDeliveryNotes ? linkedDeliveryNotes : []
              };
            }
          }
        }
      }
      return { option: false, linkedDeliveryNoteIds: [] };
    } catch (error) { console.log(error); }
  }

  //#endregion
}
