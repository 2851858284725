import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TherapeuticPurpose } from 'src/app/modules/auxiliary/entities/therapeutic-purposes';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { ProductDescriptions } from '../../../auxiliary/entities/product-descriptions';
import { ApiService } from '../../../common/services/api.service';
import { CommonDataService } from '../../../common/services/common.data.service';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { MainWorkType } from '../../entities/main-work-type';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'main-work-types',
  templateUrl: './main-work-types.component.html',
  styleUrls: ['./main-work-types.component.scss']
})
export class MainWorkTypesComponent implements OnInit, OnDestroy {

  // form group
  mwtFormGroup: FormGroup;

  // lists
  selectedMainWorkTypeList: MainWorkType[] = [];
  productDescriptionList: ProductDescriptions[] = [];
  therapeuticPurposeList: TherapeuticPurpose[] = [];

  // objects
  private _subscription: Subscription;
  mainWorkType: MainWorkType;
  hasLoaded = true;

  @Output() emitToWork: EventEmitter<any> = new EventEmitter();

  constructor(private __service: WorkService, public commonUiService: CommonUIService,
    private _formBuilder: FormBuilder, private _apiService: ApiService,
    private _commonDataService: CommonDataService, private _toastyService: Toast) {
    this._subscription = this.__service.detailObject.subscribe((args) => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (args.invokeComponents.includes('WorkType') || args.invokeComponents.includes('*')) {
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
        }
      }
    });
    this.mwtFormGroup = this._formBuilder.group({
      productDescriptionId: [],
      therapeuticPurposeId: []
    });
  }

  ngOnInit() { this.mainWorkType = new MainWorkType(); }

  ngOnDestroy() { this._subscription.unsubscribe(); }

  GetProductDescriptionList() {
    return this._apiService.get(API.product_descriptions.getProductDescriptions)
      .map(list => list as ProductDescriptions[]).toPromise();
  }

  GetTherapeuticPurposeList() {
    return this._apiService.get(API.therapeutic_purposes.getTherapeuticPurposeList)
      .map(list => list as TherapeuticPurpose[]).toPromise();
  }

  async LoadPopupData() {
    try {
      this.productDescriptionList = await this.GetProductDescriptionList();
      this.therapeuticPurposeList = await this.GetTherapeuticPurposeList();
    } catch (error) { console.log(error); }
  }

  OnEditMwtModal(mwt: MainWorkType, modal: string) {
    try {
      if (mwt && modal) {
        this.commonUiService.isSpinnerVisible = true;
        this.commonUiService.isPopupOpened = true;
        this.LoadPopupData();
        this.mainWorkType = { ...mwt };
        if (!this.mainWorkType.therapeuticPurposeId) { this.mainWorkType.therapeuticPurposeId = 0; }
        if (!this.mainWorkType.productDescriptionId) { this.mainWorkType.productDescriptionId = 0; }
        this.commonUiService.openModal(modal);
      }
    } catch (error) { console.log(error); }
    finally { this.commonUiService.isSpinnerVisible = false; }
  }

  async OnSaveMwt(event: any) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (this.mainWorkType) {
        this.mainWorkType.productDescriptionId = this.mainWorkType.productDescriptionId ? this.mainWorkType.productDescriptionId : null;
        this.mainWorkType.therapeuticPurposeId = this.mainWorkType.therapeuticPurposeId ? this.mainWorkType.therapeuticPurposeId : null;
        const response = await this.__service.UpdateMainWorkType(this.mainWorkType);
        if (response) {
          const body = JSON.parse(response['_body']);
          if (body) {
            if (body._isSuccsess) {
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList['work'],
                msg: this._commonDataService.localizationLabelList['save_success']
              });
              let mwt = this.selectedMainWorkTypeList.find(m => Number(m.id) === Number(this.mainWorkType.id));
              const symbol = mwt.symbol;
              if (mwt) {
                mwt = body._data as MainWorkType;
                mwt.symbol = symbol;
                mwt.cssClass = this.mainWorkType.cssClass;
                mwt.order = this.mainWorkType.order;
                mwt.workTypeName = this.mainWorkType.workTypeName;
                if (mwt.productDescriptionId) {
                  mwt.productDescriptionName = this.productDescriptionList.find(pd => pd.id === mwt.productDescriptionId).description;
                }
                if (mwt.therapeuticPurposeId) {
                  mwt.therapeuticPurposeName = this.therapeuticPurposeList.find(td => td.id === mwt.therapeuticPurposeId).description;
                }
                const index = this.selectedMainWorkTypeList.findIndex(m => Number(m.id) === Number(this.mainWorkType.id));
                if (index !== -1) { this.selectedMainWorkTypeList[index] = mwt; }
                this.emitToWork.emit(mwt);
              }
            } else {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['work'],
                msg: this._commonDataService.localizationLabelList['save_error']
              });
              this.OnCloseModal('mwt-modal');
            }
          }
        }
      }
    } catch (error) { console.log(error); }
    finally {
      this.OnCloseModal('mwt-modal');
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  OnCloseModal(modal: any) {
    if (modal) {
      this.commonUiService.isPopupOpened = false;
      this.commonUiService.CloseModalEx(modal);
    }
  }
}

