import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IvaBreakdown } from '../../auxiliary/entities/iva-breakdown';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  ivaBreakdownList: IvaBreakdown[] = [];

  constructor() { }

  LoadVatBreakdown(list: IvaBreakdown[]){
    // according to vat type the list is grouped and sum
    this.ivaBreakdownList = []; 
    var groupList = _.toArray(_.groupBy(list, 'ivaTypeId')) as any[];
    if(groupList){
      for (let i = 0; i < groupList.length; i++) {
        if(groupList[i][0] && groupList[i][0].ivaTypeId){
            const totalVatAmount = groupList[i].reduce((sum, current) => sum + current.ivaAmount, 0);
            const totalBase = groupList[i].reduce((sum, current) => sum + current.base, 0);
            const iva = _.toArray(groupList[i]) as any[];
            if(iva && iva[0]){
            this.ivaBreakdownList.push({
              ivaTypeId: iva[0].ivaTypeId,            
              iva: iva[0].iva ,
              base: totalBase,
              ivaAmount: totalVatAmount
            });
          }
        }
      }
    }   
    return this.ivaBreakdownList;
  }
}