import { SavedGeneratedNumber } from '../../work/entities/generate-number-model';
export class Series {

  id: number;
  seriesType: string;
  issuerId: number;
  series: string;
  year: string;
  lastNumber?: number;
  numberType: string;
  issuerType: string;
  status: boolean;
  savedGeneratedNumber: SavedGeneratedNumber[];

  ClientSeries() {
    this.status = true;
  }
}
