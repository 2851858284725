import { EventEmitter, Injectable, Output } from '@angular/core';
import * as htmlToPdf from 'html2pdf.js';
import { NgbDate } from 'imp-ng-bootstrap/datepicker/ngb-date';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import swal from 'sweetalert2';
import API from '../../common/api.config.json';
import { SendEmail } from '../../work/entities/emailModel';
import { DateFormat } from '../entities/date-format';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class CommonUIService {
  isSpinnerVisible = false;
  IsExanded = false;
  IsLeftSideVisible = true;
  isSidebarDisabled = false;
  isActiveLeftPanelOnly = false;
  isActiveRightPanelOnly = false;
  isActiveBothPanel = true;
  isActiveStatisticPanel = false;

  isShowLoyoutMenu = false;
  isShowStatMenu = false;
  isShowExpandView = false;

  isPopupOpened = false;
  isPreviewDocumentOpened = false;
  imageData: string;
  currencyMark = '€'

  @Output() expandView = new EventEmitter();

  // ngbDatepicker variables
  public minDate: {};
  public maxDate: {};
  public maxAdjustmentDate: {};

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private _apiService: ApiService,
    private _translateService: TranslateService
  ) {
    this.initializeDateVariables();
  }

  openModal(element) {
    const popup = document.querySelector('#' + element);
    if (!popup) {
      console.error('cannot find modal');
      return;
    }
    popup.classList.add('md-show');
  }

  OpenBasicModal(element) {
    document.querySelector('#' + element).classList.add('show-modal');
  }

  closeModal(event) {
    event.target.parentElement.parentElement.parentElement.parentElement.classList.remove(
      'md-show'
    );
  }

  CloseModalEx(modal: string) {
    try {
      const domElement = document.getElementById(modal);
      if (domElement) {
        domElement.classList.remove('md-show');
      }
    } catch (error) {
      console.log(error);
    }
  }

  CloseModal(event) {
    this.isPopupOpened = false;
    event.path[6].classList.remove('md-show');
  }

  ClosePreviewModal(event) {
    event.path[5].classList.remove('md-show');
  }

  ConvertToPickerDateFormat(dateString: string): DateFormat {
    if (dateString) {
      let formatedDate: string = dateString;
      if (formatedDate !== undefined || formatedDate != null) {
        formatedDate = formatedDate.substr(0, 10);
        const splitted_array: string[] = formatedDate.split('-');
        // tslint:disable-next-line: radix
        return new DateFormat(
          parseInt(splitted_array[2]),
          parseInt(splitted_array[1]),
          parseInt(splitted_array[0])
        );
      }
    }
  }

  ConvertDateFormatToString(dateObject: DateFormat): string {
    if (dateObject !== undefined) {
      /*
      const date = new Date(
        dateObject.year + "-" + dateObject.month + "-" + dateObject.day
      );
      // date = addDays(date, 1);
      const dateArr: string[] = date.toJSON().substring(0, 10).split("-");
      return dateArr[0] + "-" + dateArr[1] + "-" + dateArr[2];
      */
      const month = ('0' + dateObject.month).slice(-2);
      const day = ('0' + dateObject.day).slice(-2);
      const dateString = dateObject.year + '-' + month + '-' + day;
      return moment(dateString, 'YYYY-MM-DD').format('YYYY-MM-DD');
    }
  }

  ChangeNgbDateToCustomFormat(ngbDate): any {
    if (ngbDate && ngbDate.year) {
      return (
        ngbDate.year +
        '-' +
        this.GetWholeNumber(ngbDate.month.toString()) +
        '-' +
        this.GetWholeNumber(ngbDate.day.toString())
      );
    }
    return ngbDate;
  }

  dateToCustomFormat(date: Date): any {
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate()
    );
  }

  ToNgbDate(date: Date): NgbDate {
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  private GetWholeNumber(num: string) {
    if (num) {
      if (num.length === 1) {
        num = num.padStart(2, '0');
      }
      return num;
    }
  }

  openSuccessSwal(_title: string, _text: string) {
    swal({
      title: _title,
      text: _text,
      type: 'success',
      // tslint:disable-next-line: deprecation
    }).catch(swal.noop);
  }

  PrintHtmlReport(section: string): void {
    let popupWindow: Window;
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      popupWindow = window.open(
        '',
        '_blank',
        'top=0,left=0,height=' + screen.height + ',width= ' + screen.width
      );
      if (popupWindow) {
        popupWindow.document.open();
        popupWindow.document.write(`
            <html>
              <head>
                <title></title>
                <style>
                @page { size: auto;  margin-top: 0mm; }
                @media print, screen{
                body{font-family:"Heebo", sans-serif;}
                .row{display:flex;flex-wrap:wrap;margin-right:-15px;margin-left:-15px;}
                .container-fluid{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
                .col-md-12{flex:0 0 100%;max-width:100%;}
                .col-md-6{flex:0 0 50%;max-width:50%;}
                .col-md-4 {flex: 0 0 33.33333333%;max-width: 33.33333333%;}
                .col-md-8 {flex: 0 0 66.66666667%;max-width: 66.66666667%;}
                .col-md-3 {flex: 0 0 25%; max-width: 25%;}
                .col-md-5 {-ms-flex: 0 0 41.66666667%;
                  flex: 0 0 41.66666667%;
                  max-width: 41.66666667%;}
                .w300{ width:300px;}
                .w400{ width:400px;}
                .w450{ width:450px;}
                .w200{ width:200px;}
                .w250{ width:250px;}
                .mr-2{margin-right:10px;}
                .mr-15{margin-right:15px;}
                .text-uppercase{text-transform:uppercase !important;}
                .float-left{float:left;}
                .float-right{float:right;}
                .f-14{font-size:14px;}
                .f-15{font-size:15px;}
                .f-12{font-size:12px;}
                .p-10{padding:10px;}
                .p-5{padding:5px;}
                .m-5{margin:5px;}
                .m-l-15{margin-left:15px !important;}
                .m-r-15{margin-right:15px !important;}
                .m-r-30{margin-right:30px !important;}
                .m-r-45{margin-right:45px !important;}
                .p-l-5{padding-left:5px;}
                .p-l-30{padding-left:30px;}
                .p-l-10{padding-left:10px;}
                .p-l-15{padding-left:15px;}
                .p-l-100{padding-left:100px;}
                .p-b-5{padding-bottom:5px;}
                .p-r-30{padding-right:30px !important;}
                .p-r-40{padding-right:40px !important;}
                .p-r-50{padding-right:50px;}
                .p-0{ padding:0px;}
                .m-0{ margin:0px;}
                .pl-0{padding-left:0px !important;}
                .pr-0{padding-right:0px !important;}
                .m-b-0{margin-bottom: 0px !important;}
                .p-b-20{padding-bottom: 20px !important;}
                .m-t-0{margin-top: 0px !important;}
                .m-b-5{margin-bottom:5px !important;}
                .m-t-5{margin-top:5px;}
                .m-t-20{margin-top:20px !important;}
                .m-t-10{margin-top:10px !important; }
                .m-t-15{margin-top:15px;}
                .p-t-30{padding-top:30px !important;}
                .m-b-30{margin-bottom:30px !important;}
                .m-b-20{margin-bottom:20px !important;}
                .text-right{text-align:right;}
                .text-blue{color:#265b9e;-webkit-print-color-adjust:exact; }
                .text-white{color:#fff;-webkit-print-color-adjust:exact;}
                .text-darkblue{color: #314577;-webkit-print-color-adjust:exact;}
                .font-weight-bold{font-weight:700 !important;}
                .text-center{text-align:center !important;}
                .text-right{text-align:right !important;}
                .text-left{text-align:left !important;}
                table{border-collapse: collapse;}
                .table{width:100%;}
                 td{vertical-align:top;}
                 .border-bottom-dash{border-bottom:1px dashed #314577;}
                 .border-top-dash{border-top:1px dashed #314577;}
                 .p-20{padding:20px;}
                .position-left{position:absolute; left:0px;}
                .position-right{position:relative; right: 25px;}
                .position-top-50{position:relative; top: 50px;}
                .position-right-30{position:relative; right: 30px;}
                .position-left-30{position:relative; left: 30px;}
                .position-right-40{position:relative; right: 40px;}
                .position-right-67{position:relative; right:67px;}
                .position-left:20px{position:relative; left:20px;}
                .position-left:20px{position:relative; left:20px;}
                .position-left:50px{position:relative; left:50px;}
                .content-body{padding:0 15px;}
                .text-underline{ text-decoration: underline;}
                .table > thead.bg3 > tr > th{color:#ffffff;-webkit-print-color-adjust:exact;}
                .bg{background:#cbd0dd;-webkit-print-color-adjust:exact;}
                .bg2{background:#E6E8EE; -webkit-print-color-adjust:exact;}
                .bg3{background:#314577; -webkit-print-color-adjust:exact;}
                .titlehead{background:#07a3e1c2 !important;-webkit-print-color-adjust:exact;color:#fff;}
                .answerfield{background:#efefef !important;-webkit-print-color-adjust:exact;color:#333;}
                .report-table{background-color: #07a3e1 !important; color: #fff !important;
                  -webkit-print-color-adjust:exact; width:100%; text-align:left;
                  padding-left: 5px; padding-right:5px; padding-top:3px; padding-bottom:3px; font-size:14px;}
                .report-dt tr td:nth-child(1){width:10%;}
                .report-dt tr td:nth-child(2){width:50%;}
                .report-dt tr td:nth-child(3){width:15%;}
                .report-dt tr td:nth-child(4){width:20%; padding-right:30px;}
                .report-dt tbody tr {border-bottom:1px dashed #90A4AE;}
                .report-dt tbody tr td{font-size:13px;}
                .report-dt .item-three{-webkit-print-color-adjust:exact; background-color:#CFD8DC;}
                .report-dt .item-four{-webkit-print-color-adjust:exact; background-color:#CFD8DC;}
                .delivery-note-table tr th:nth-child(1){padding-left:5px;}
                .delivery-note-table tr th:nth-child(6){padding-left:5px; text-align:right; padding-right:30px;}
                .delivery-note-table tr td:nth-child(1){width:10%; padding-left:5px;}
                .delivery-note-table tr td:nth-child(2){width:10%;}
                .delivery-note-table tr td:nth-child(3){width:30%;}
                .delivery-note-table tr td:nth-child(4){width:20%;}
                .delivery-note-table tr td:nth-child(5){width:10%}
                .delivery-note-table tr td:nth-child(6){width:20%; padding-right:30px; padding-left:5px; text-align:right;}
                .delivery-note-table .item-four{-webkit-print-color-adjust:exact; background-color:#CFD8DC;}
                .delivery-note-table tbody tr {border-bottom:1px dashed #90A4AE; font-size:14px;}
                #conformity-report-section .checkbox-primary input[type="checkbox"]:checked + label::before {
                    background-color: #07a3e1 !important;
                    border-color: #07a3e1 !important;
                    -webkit-print-color-adjust:exact;
                }
                .sanity-material-tbl tr th:nth-child(1){width: 40%;}
                .sanity-material-tbl tr th:nth-child(2){width: 15%;}
                .sanity-material-tbl tr th:nth-child(3){width: 15%;}
                .sanity-material-tbl tr th:nth-child(4){width: 30%;}
                .sanity-material-tbl tr td:nth-child(1){width: 40%;}
                .sanity-material-tbl tr td:nth-child(2){width: 15%;}
                .sanity-material-tbl tr td:nth-child(3){width: 15%;}
                .sanity-material-tbl tr td:nth-child(4){width: 30%;}
                .address-infor-panel .d-block{display:block !important;}
                .address-panel{background-color:#ddd;
                  -webkit-print-color-adjust:exact; width:200px; margin-bottom:20px; border-radius:3px; padding:10px; }
                .address-panel p{margin:0px; padding:0px; font-size:12px;}
                .work-report-print .card-header.bg3{color:#fff !important; -webkit-print-color-adjust:exact;}
                .work-report-print .set-padding-left{padding-left:30px !important;}
                .work-reportt .text-white{color:#fff !important; -webkit-print-color-adjust:exact;}
                .print-report-id.text-center{text-align:center !important; width:100% !important;}
                .price-list-amount{text-align:right !important; padding-right:30px;}
                .price-list{padding-right:20px;text-align:right !important;}
                .price-list2{padding-right:20px;text-align:right !important;position:relative; right:-15px !important;}
                .invoice-table-report td.text-right{text-align:right !important; width:100% !important; padding-right:30px !important;}
                .invoice-table-report tr th:nth-child(1){width: 10%;}
                .invoice-table-report tr th:nth-child(2){width: 10%;}
                .invoice-table-report tr th:nth-child(3){width: 50%;}
                .invoice-table-report tr th:nth-child(4){width: 30%;}
                .budget-report-mr-3{margin-right:30px !important;}
                .w300.w350{width:350px !important;}
                .w410{width: 410px !important;}
                .w-percentage-97{width:97% !important;}
                #work-report-section.work-reportt .col-md-4 img{max-width:100% !important;}
                #work-report-section.work-reportt h2.color-white{color:#fff !important; -webkit-print-color-adjust:exact;}
                .m-t--20{margin-top:-50px !important;}
                #invoice-section .col-md-6 img{max-width:100% !important;}
                #invoice-section .invoice-header h1{display:block !important; padding-right:20px !important;}
                #invoice-section .invoice-header .float-right div{margin-right:20px !important;}
                .table.innertble-report {font-size:12px !important; margin-left:60px !important;
                  margin-top:15px !important; display:inline-block !important; max-width:100% !important;}
                .table.innertble-report thead tr th{color:#000 !important; -webkit-print-color-adjust:exact;}
                .table.innertble-report tr th:nth-child(1){width: 10%;}
                .table.innertble-report tr th:nth-child(2){width: 20%;}
                .table.innertble-report tr th:nth-child(3){width: 50%;}
                .table.innertble-report tr th:nth-child(4){width: 20%;}
                .bg4{background-color:#ddd !important;  -webkit-print-color-adjust:exact;}
                .m-t-30{margin-top:30px !important;}
                .w-60{width:60% !important;}
                .set-color-margin{border-top:5px solid #314577;}
                .doc-name-panel h4{color:#fff;  -webkit-print-color-adjust:exact; font-size: 14px!important;}
                .doc-name-panel{margin-bottom:0px !important; width: 300px !important; padding:3px 20px !important;}
                .table-provider-rport-expense .provider-name{height:17px;}
                .table-provider-rport-expense tr:nth-child(2) td:nth-child(1) {width:30%;}
                .table-provider-rport-expense tr:nth-child(2) td:nth-child(2) {width:15%;}
                .table-provider-rport-expense tr:nth-child(2) td:nth-child(3) {width:35%;}
                .table-provider-rport-expense tr:nth-child(2) td:nth-child(4) {width:20%;}
                .sanity-gurantee-reporttbl tr td:nth-child(1){width:25%;}
                .sanity-gurantee-reporttbl tr td:nth-child(2){width:25%;}
                .sanity-gurantee-reporttbl tr td:nth-child(3){width:25%;}
                .sanity-gurantee-reporttbl tr td:nth-child(4){width:25%;}
                .sanity-gurantee-reporttbl tr td:nth-child(5){width:25%;}

                .sanitygurantee-report-tbl tr td:nth-child(1){width:28%;}
                .sanitygurantee-report-tbl tr td:nth-child(2){width:100%; word-break: break-word;}
                .sanitygurantee-report-tbl tr td:nth-child(3){width:28%;}
                .sanitygurantee-report-tbl tr td:nth-child(4){width:28%;}

                strong{font-weight:bold;}
                .table-list-report-expense tr th:nth-child(1){width:70px; text-align:left; vertical-align:top;}
                .table-list-report-expense tr th:nth-child(2){width:70px; text-align:left; vertical-align:top;}
                .table-list-report-expense tr th:nth-child(3){width:60px; text-align:left; vertical-align:top;}
                .table-list-report-expense tr th:nth-child(4){width:200px; text-align:left; vertical-align:top;}
                .table-list-report-expense tr th:nth-child(5){width:70px; text-align:left; vertical-align:top;}
                .table-list-report-expense tr th:nth-child(6){width:80px; text-align:left; vertical-align:top;}
                .table-list-report-expense tr th:nth-child(7){width:90px; vertical-align:top;}
                .table-list-report-expense tr th:nth-child(8){width:90px; vertical-align:top;}
                .table-list-report-expense tr th:nth-child(9){width:95px; vertical-align:top; padding-right: 34px;}
                .table-list-report-expense {table-layout:unset;}
                .table-list-report-expense tr td:nth-child(1){width:70px; white-space:pre-wrap; padding-right:20px;}
                .table-list-report-expense tr td:nth-child(2){width:70px;}
                .table-list-report-expense tr td:nth-child(3){width:60px;}
                .table-list-report-expense tr td:nth-child(4){width:200px; white-space:pre-wrap; padding-right:20px;}
                .table-list-report-expense tr td:nth-child(5){width:70px;}
                .table-list-report-expense tr td:nth-child(6){width:80px;}
                .table-list-report-expense tr td:nth-child(7){width:90px;}
                .table-list-report-expense tr td:nth-child(8){width:90px;}
                .table-list-report-expense tr td:nth-child(9){width:95px; padding-right: 34px;}
                .w-80{width:80%;}
                .budget-tble-2 tr td:nth-child(1){width:200px;}
                .budget-tble-2 tr td:nth-child(2){width:300px;}
                .budget-tble-2 tr td:nth-child(3){width:100px;}
                .budget-tble-2 tr td:nth-child(4){width:50px;}
                .budget-tble-2 tr td:nth-child(5){width:150px;}
                .budget-tble-2 tr th:nth-child(1){width:200px;}
                .budget-tble-2 tr th:nth-child(2){width:300px;}
                .budget-tble-2 tr th:nth-child(3){width:100px;}
                .budget-tble-2 tr th:nth-child(4){width:50px;}
                .budget-tble-2 tr th:nth-child(5){width:150px;}
                .m-b-30{margin-bottom: 30px;}
                .invoice-deliverynote-tbl tr td{padding-top:5px; padding-bottom:5px;}
                .invoice-deliverynote-tbl tr td:nth-child(1){width:100px;}
                .invoice-deliverynote-tbl tr td:nth-child(2){width:100px;}
                .invoice-deliverynote-tbl tr th:nth-child(3){width:500px;}
                .invoice-deliverynote-tbl tr td:nth-child(4){width:150px;}
                .invoice-deliverynote-tbl tr td:nth-child(5){width:150px; padding-right: 68px;}
                .invoice-deliverynote-tbl tr th:nth-child(1){width:50px;}
                .invoice-deliverynote-tbl tr th:nth-child(2){width:100px;}
                .invoice-deliverynote-tbl tr th:nth-child(3){width:500px;}
                .invoice-deliverynote-tbl tr th:nth-child(4){width:150px;}
                .invoice-deliverynote-tbl tr th:nth-child(5){width:150px; padding-right: 68px;}

                .budgetworkreport-tbl tr td:nth-child(1){width:400px;}
                .budgetworkreport-tbl tr td:nth-child(2){width:100px;}
                .budgetworkreport-tbl tr td:nth-child(3){width:80px;}
                .budgetworkreport-tbl tr td:nth-child(4){width:150px;}
                .budgetworkreport-tbl tr th:nth-child(1){width:400px;}
                .budgetworkreport-tbl tr th:nth-child(2){width:100px;}
                .budgetworkreport-tbl tr th:nth-child(3){width:80px;}
                .budgetworkreport-tbl tr th:nth-child(4){width:150px;}

                .max-width-60{max-width:60% !important;}
                .f-13{font-size:13px !important;}
                .f-15{font-size:15px !important;}
                .report-provider-details-expense tr td:nth-child(1){width:50px;}
                .report-provider-details-expense tr td:nth-child(2){width:70px;}
                .report-provider-details-expense tr td:nth-child(3){width:70px;}
                .report-provider-details-expense tr td:nth-child(4){width:60px;}
                .report-provider-details-expense tr td:nth-child(5){width:60px;}
                .report-provider-details-expense tr td:nth-child(6){width:60px;}
                .report-provider-details-expense tr td:nth-child(7){width:60px;}
                .report-provider-details-expense tr td:nth-child(8){width:90px; padding-right:30px;}
                .grey-panel-container{
                  padding:5px 20px;
                  width:550px;
                  float:right;
                }
                .overflow-hidden{
                  overflow:hidden !important;
                }
                .blue-box-container{
                  padding: 10px;
                  background-color: #DEF6FF;
                  -webkit-print-color-adjust:exact;
                }
                .border-dashed-styles{
                  border-top: 2px dashed #ddd;
                  padding-top: 20px;
                  padding-bottom: 20px;
                }

                .invoice-report2-tbl tr td:nth-child(1){width:100px;}
                .invoice-report2-tbl tr td:nth-child(2){width:150px; white-space: break-spaces;}
                .invoice-report2-tbl tr td:nth-child(3){width:120px; padding-right:30px;}
                .invoice-report2-tbl tr td:nth-child(4){width:60px;}
                .invoice-report2-tbl tr td:nth-child(5){width:60px;}
               
                .invoice-report2-tbl tr td:nth-child(6){width:250px; padding-right:40px;}
                .invoice-report2-tbl tr th:nth-child(1){width:100px;}
                .invoice-report2-tbl tr th:nth-child(2){width:150px;}
                .invoice-report2-tbl tr th:nth-child(3){width:120px; padding-right:30px;}
                .invoice-report2-tbl tr th:nth-child(4){width:60px;}
                .invoice-report2-tbl tr th:nth-child(5){width:60px;}
                
                .invoice-report2-tbl tr th:nth-child(6){width:250px; padding-right:40px;}
                .p-b-30{padding-bottom: 30px;}
                .orders-print-tbl{
                    width:100%;
                    max-width:100%;
                }
                .orders-print-tbl tr th:nth-child(1){width:200px;}
                .orders-print-tbl tr th:nth-child(2){width:50px;}
                .orders-print-tbl tr th:nth-child(3){width:50px;}
                .orders-print-tbl tr th:nth-child(4){width:50px;}
                .orders-print-tbl tr th:nth-child(5){width:50px;}
                .orders-print-tbl tr th:nth-child(6){width:50px;}
                .orders-print-tbl tr th:nth-child(7){width:50px;}
                .orders-print-tbl tr th:nth-child(8){width:60px;}
                .orders-print-tbl tr td:nth-child(1){width:200px;}
                .orders-print-tbl tr td:nth-child(2){width:50px;}
                .orders-print-tbl tr td:nth-child(3){width:50px;}
                .orders-print-tbl tr td:nth-child(4){width:50px;}
                .orders-print-tbl tr td:nth-child(5){width:50px;}
                .orders-print-tbl tr td:nth-child(6){width:50px;}
                .orders-print-tbl tr td:nth-child(7){width:50px;}
                .orders-print-tbl tr td:nth-child(8){width:60px;}
                .align-self-end {
                  -ms-flex-item-align: end !important;
                  align-self: flex-end !important;
                }
                .report-provider-details-expense tr th:nth-child(1){width:50px; text-align:left;}
                .report-provider-details-expense tr th:nth-child(2){width:70px; text-align:left;}
                .report-provider-details-expense tr th:nth-child(3){width:70px; text-align:left;}
                .report-provider-details-expense tr th:nth-child(4){width:60px; text-align:left;}
                .report-provider-details-expense tr th:nth-child(5){width:60px; text-align:left;}
                .report-provider-details-expense tr th:nth-child(6){width:60px; text-align:left;}
                .report-provider-details-expense tr th:nth-child(7){width:60px; text-align:left;}
                .report-provider-details-expense tr th:nth-child(8){width:90px; padding-right:30px;}
                .invoice-report-templatetbl tr th:nth-child(1){width:100px; text-align:left;}
                .invoice-report-templatetbl tr th:nth-child(2){width:200px; text-align:left;}
                .invoice-report-templatetbl tr th:nth-child(3){width:120px; text-align:left; padding-right:40px;}
                .invoice-report-templatetbl tr th:nth-child(4){width:100px; text-align:left;}
                .invoice-report-templatetbl tr th:nth-child(5){width:100px; text-align:left;}
                .invoice-report-templatetbl tr th:nth-child(6){width:200px; text-align:left; padding-right:30px;}
                .invoice-report-templatetbl tr td:nth-child(1){width:100px; text-align:left;}
                .invoice-report-templatetbl tr td:nth-child(2){width:200px; text-align:left;}
                .invoice-report-templatetbl tr td:nth-child(3){width:120px; text-align:left; padding-right:40px;}
                .invoice-report-templatetbl tr td:nth-child(4){width:100px; text-align:left;}
                .invoice-report-templatetbl tr td:nth-child(5){width:100px; text-align:left;}
                .invoice-report-templatetbl tr td:nth-child(6){width:200px; text-align:left; padding-right:30px;}
                .work-report-img{max-width:100%; height:auto;}
                .work-report-print-tbl tr th:nth-child(1){color: #fff !important;text-align:left; -webkit-print-color-adjust:exact;}
                .work-report-print-tbl tr th:nth-child(2){color: #fff !important;text-align:left; -webkit-print-color-adjust:exact;}
                .work-report-print-tbl tr th:nth-child(3){color: #fff !important;text-align:left; -webkit-print-color-adjust:exact;}
                .work-report-print-tbl .obs {float:left; width: 100%; font-size: 12px;}
                .work-report-print-tbl .obs h3{float:left; width: 100%; font-size: 12px; color: #FFFFFF;}
                .work-report-print-tbl-obs{margin-top: 25px; position: relative;}
                .work-report-print-tbl-obs tbody tr td {padding-top: 8px;}
                .work-report-print-tbl-obs tbody tr td ul {padding-left: 20px; padding-bottom: 15px; margin: 0px;}
                .work-report-print-tbl-obs tbody tr td ul li{list-style-type: circle;}
                .invoice-report-templatetbl tr.result-display td:nth-child(1){width:100px; text-align:left;}
                .invoice-report-templatetbl tr.result-display td:nth-child(2){width:200px; text-align:left;}
                .invoice-report-templatetbl tr.result-display td:nth-child(3){width:120px; text-align:left; padding-right:40px !important;}
                .invoice-report-templatetbl tr.result-display td:nth-child(4){width:100px; text-align:left;}
                .invoice-report-templatetbl tr.result-display td:nth-child(5){width:100px; text-align:left;}
                .invoice-report-templatetbl tr.result-display td:nth-child(6){width:200px; text-align:left; padding-right:30px !important;}
                .work-report-subheading{margin-bottom: 2px; padding-bottom: 0px; margin-top: 0px;}
                .span.note-text{font-size:10px; color:rgb(68, 67, 67) !important;  text-transform: lowercase !important;}
                .recpit-copy{border-radius:5px; width:90%; border:none !important;}
                .p-t-20{padding-top:20px;}
                .w-260{width:260px !important;}
                .return-order-report-tbl tr td:nth-child(1){width:80px; padding-right: 30px;}
                .return-order-report-tbl tr td:nth-child(2){width:200px;}
                .return-order-report-tbl tr td:nth-child(3){width:200px; padding-right: 30px !important;}
                .return-order-report-tbl tr td:nth-child(4){width:200px;}
                .return-order-report-tbl tr td:nth-child(5){width:200px;}
                .return-order-report-tbl tr th:nth-child(1){width:80px; padding-right: 30px;}
                .return-order-report-tbl tr th:nth-child(2){width:200px;}
                .return-order-report-tbl tr th:nth-child(3){width:200px; padding-right: 30px !important;}
                .return-order-report-tbl tr th:nth-child(4){width:200px;}
                .return-order-report-tbl tr th:nth-child(5){width:200px;}
                .p-t-0{
                  padding-top:0px;
                }
                .p-b-0{
                  padding-top:0px;
                }
                .m-t-0{
                  margin-top:0px;
                }
                .m-b-0{
                  margin-top:0px;
                }
                .deliverynote-tbl-list tr th:nth-child(1){width:100px;}
                .deliverynote-tbl-list tr th:nth-child(2){width:250px;}
                .deliverynote-tbl-list tr th:nth-child(3){width:150px;}
                .deliverynote-tbl-list tr th:nth-child(4){width:70px;}
                .deliverynote-tbl-list tr th:nth-child(5){width:40px;}
                .deliverynote-tbl-list tr th:nth-child(6){width:40px;}
                .deliverynote-tbl-list tr th:nth-child(7){width:120px;}
                .deliverynote-tbl-list tr th:nth-child(8){width:150px;}
                .deliverynote-tbl-list tr td:nth-child(1){width:100px;}
                .deliverynote-tbl-list tr td:nth-child(2){width:450px;}
                .deliverynote-tbl-list tr td:nth-child(3){width:60px;}
                .deliverynote-tbl-list tr td:nth-child(4){width:50px;}
                .deliverynote-tbl-list tr td:nth-child(5){width:70px;}
                .deliverynote-tbl-list tr td:nth-child(6){width:70px;}
                .deliverynote-tbl-list tr td:nth-child(7){width:70px;}
                .deliverynote-tbl-list tr td:nth-child(8){width:150px;}
                .sanity-report-tbl1 tr td:nth-child(1){width:40%;}
                .sanity-report-tbl1 tr td:nth-child(2){width:15%;}
                .sanity-report-tbl1 tr td:nth-child(3){width:15%;}
                .sanity-report-tbl1 tr td:nth-child(4){width:30%;}
                .sanity-report-tbl1 tr th:nth-child(1){width:40%;}
                .sanity-report-tbl1 tr th:nth-child(2){width:15%;}
                .sanity-report-tbl1 tr th:nth-child(3){width:15%;}
                .sanity-report-tbl1 tr th:nth-child(4){width:30%;}
                .operators-tbl-sanity thead tr {color:#fff; text-align:left;}
                .operators-tbl-sanity tr th:nth-child(1){width:30%;}
                .operators-tbl-sanity tr th:nth-child(2){width:50%;}
                .operators-tbl-sanity tr th:nth-child(3){width:20%;}
                .operators-tbl-sanity tr td:nth-child(1){width:30%;}
                .operators-tbl-sanity tr td:nth-child(2){width:50%;}
                .operators-tbl-sanity tr td:nth-child(3){width:20%;}
                .sanity-report-operators tr td:nth-child(1){width:30%;}
                .sanity-report-operators tr td:nth-child(2){width:50%;}
                .sanity-report-operators tr td:nth-child(3){width:20%;}
                .sanity-report-operators tr th:nth-child(1){width:30%;}
                .sanity-report-operators tr th:nth-child(2){width:50%;}
                .sanity-report-operators tr th:nth-child(3){width:20%;}
                .d-block{display:block !important;}
                .table-budget-articles tr td:nth-child(1){width:500px;}
                .table-budget-articles tr td:nth-child(2){width:50px;}
                .table-budget-articles tr td:nth-child(3){width:50px;}
                .table-budget-articles tr td:nth-child(4){width:150px;}
                .table-budget-articles tr th:nth-child(1){width:500px;}
                .table-budget-articles tr th:nth-child(2){width:50px;}
                .table-budget-articles tr th:nth-child(3){width:50px;}
                .table-budget-articles tr th:nth-child(4){width:150px;}
                .deliverynote-details{padding-right:45px !important;}
                .deliverynotereport-tbl tr td:nth-child(1){width:100px;}
                .deliverynotereport-tbl tr td:nth-child(2){width:250px;}
                .deliverynotereport-tbl tr td:nth-child(3){width:150px;}
                .deliverynotereport-tbl tr td:nth-child(4){width:50px;}
                .deliverynotereport-tbl tr td:nth-child(5){width:auto;}
                .deliverynotereport-tbl tr td:nth-child(6){width:150px;}
                .deliverynotereport-tbl th td:nth-child(1){width:100px;}
                .deliverynotereport-tbl th td:nth-child(2){width:250px;}
                .deliverynotereport-tbl th td:nth-child(3){width:150px;}
                .deliverynotereport-tbl th td:nth-child(4){width:50px;}
                .deliverynotereport-tbl th td:nth-child(5){width:auto;}
                .deliverynotereport-tbl th td:nth-child(6){width:150px;}
                .justify-content-between{
                  -ms-flex-pack: justify !important;
                  justify-content: space-between !important;
                }
                .light-blue-container, .bg5 {
                      background-color:#def6ff !important;
                      -webkit-print-color-adjust:exact;
                }
                .address-infor-budget span{display:block !important;}
                .w-160{width:160px !important;}
                .w-350{width:350px !important;}
                .using-report-tbl tr td:nth-child(1){width:200px;}
                .using-report-tbl tr td:nth-child(2){width:200px;}
                .upper-box-border{border-bottom:1px solid #ddd; padding-top:20px !important;}
                .recpiet-report-tetxt span{font-weight:normal !important;}
                .work-report-print-tbl tr th:nth-child(1){color: #fff !important;text-align:left; -webkit-print-color-adjust:exact;}
                .work-report-print-tbl tr th:nth-child(2){color: #fff !important;text-align:left; -webkit-print-color-adjust:exact;}
                .work-report-print-tbl tr th:nth-child(3){color: #fff !important;text-align:left; -webkit-print-color-adjust:exact;}
                .work-report-print-tbl .obs {float:left; width: 100%; font-size: 12px;}
                .work-report-print-tbl .obs h3{float:left; width: 100%; font-size: 12px; color: #FFFFFF;}
                .work-report-print-tbl-obs{margin-top: 25px; position: relative;}
                .work-report-print-tbl-obs tbody tr td {padding-top: 8px;}
                .work-report-print-tbl-obs tbody tr td ul {padding-left: 20px; padding-bottom: 15px; margin: 0px;}
                .work-report-print-tbl-obs tbody tr td ul li{list-style-type: circle;}
                .work-status{color: #fff !important; padding-left: 10px; -webkit-print-color-adjust:exact;}
                .max-width-80{max-width:80% !important;}
                .invoicereport-tbl tr th:nth-child(1){width:30px !important;}
                .invoicereport-tbl tr th:nth-child(2){width:50px !important;}
                .invoicereport-tbl tr th:nth-child(3){width:100px !important;}
                .invoicereport-tbl tr th:nth-child(4){width:100px !important;}
                .invoicereport-tbl tr th:nth-child(5){width:100px !important;}
                .invoicereport-tbl tr th:nth-child(6){width:30px !important;}
                .invoicereport-tbl tr td:nth-child(1){width:30px !important;}
                .invoicereport-tbl tr td:nth-child(2){width:50px !important;}
                .invoicereport-tbl tr td:nth-child(3){width:100px !important;}
                .invoicereport-tbl tr td:nth-child(4){width:100px !important;}
                .invoicereport-tbl tr td:nth-child(5){width:100px !important;}
                .invoicereport-tbl tr td:nth-child(6){width:30px !important;}

                .dnr-tel {width: 100%; float: left;}

                .invoice-report2-tbl tr th.show-totalDiscount-view {padding-right: 0 !important;}
                .invoice-report2-tbl tr td.show-totalDiscount-view {padding-right: 0 !important;}

                .invoice-report2-tbl tr th.show-BreakdownList-view {width: 80px !important;}
                .invoice-report2-tbl tr td.show-BreakdownList-view {width: 80px !important;}

                .invoice-report2-tbl tr th.show-BreakdownList-view + th {padding-right: 30px; width: 150px;}
                .invoice-report2-tbl tr td.show-BreakdownList-view + td {padding-right: 30px; width: 150px;}

                .using-report-tbl .answerfield span {
                  float: left;
                  width: auto;
              }

                .sanityreport-tbl tr td:nth-child(1){width:25%;}
                .w-28-percentage{width:28%;}
                .w-25-percentage{width:28%;}
                .invoice-receiptreport-one tr th:nth-child(1){width:100px; padding-right: 30px;}
                .invoice-receiptreport-one tr th:nth-child(2){width:450px}
                .invoice-receiptreport-one tr th:nth-child(3){width:50px; padding-right:30px;}
                .receipt-report-two{padding:30px 30px 0px 30px!important;}

                .receipt-report-twotbl tr {padding: 0px !important; margin: 0px !important; border:none !important;}
                .receipt-report-twotbl tr td {padding: 0px !important; margin: 0px !important; border:none !important;}
                .receipt-report-twotbl tr td span{padding: 0px !important; margin: 0px !important;}
                .invoice-receiptreport-one tr td:nth-child(1){width:100px; padding-right: 30px;}
                .invoice-receiptreport-one tr td:nth-child(2){width:450px}
                .invoice-receiptreport-one tr td:nth-child(3){width:50px; padding-right:30px;}
                .text-align-span{display:inline-block; word-wrap:break-word; white-space: pre-line;}
                .report-observations{display:inline-block; word-wrap:break-word; white-space: pre-line;}
                .work-number-title{margin-top: 20px !important; margin-bottom: 10px !important;}
                .client-infor span{font-weight:normal !important;}
                h4.invoice-report-template{color:#000 !important; margin-left:-15px !important; padding-left:-30px !important; -webkit-print-color-adjust:exact;}
                .description-box{background-color:#f6f6f6 !important; -webkit-print-color-adjust:exact;
                  padding:10px !important; border-radius:5px;}
                  .underline-none{text-decoration:none !important;}
                  .w-100{width:100% !important;}
                  .grey-box{background-color:#f6f6f6 !important; -webkit-print-color-adjust:exact;}
                  .guarantee-report-pdf-view tr td.text-blue{
                    width:150px !important;
                    display:inline-block !important;
                    text-align:left !important;
                  }
                  .position-right-60{position:relative; right: 60px;}
                  .invoice-total-view .bg2{padding:10px;}
                  .guarantee-report-pdf-view tr td{
                    width:600px !important;
                    display:inline-block !important;
                    text-align:left !important;
                  }
                  .delivery-note-values span{width:122px !important; display:inline-block;}
                  .delivery-note-values{
                    padding: 20px;
                    position: relative;
                    right: 20px;
                    font-weight:bold;
                    width:250px;
                  }
                  .delivery-note-vat{
                    padding: 10px;
                    position: relative;
                    left: 20px;
                    font-weight:bold;
                    width:290px;
                  }
                  .hide-vat-view{
                    position: relative;
                    left: 90px;
                  }

                  .budget-tble-2 tr th:nth-child(6) {
                    width: 250px;
                  }

                  .budget-tble-2 tr th:nth-child(7) {
                    width: 300px;
                  }

                  .display-table-row-group{
                      display: table-row-group;
                  }

                  @page {
                    margin-top: 20px;
                 }
                  
              }

              #incident-report-section {
                padding: 0 0 0 40px;
              }


              .container-fluid-new {
                width: 97% !important;
                padding-right: 15px;
                padding-left: 15px;
                margin-right: auto;
                margin-left: auto;
            }

            .row-block {
              marign: 0  !important;
              padding: 10px 15px !important;
              border-bottom: solid 3px #EEEEEE;
            }

            .row-block .col-md-2 {
              width: 20% !important;
              margin-bottom: 5px;
            }

            .foot-note{bottom: 0; position: fixed; height:100px;font-size: 9px;}
            .foot-h{height:100px;}
                </style>
              </head>
              <body>${html}</body>
            </html>`);


        // chrome browser fix
        popupWindow.setTimeout(function () {
          popupWindow.focus(); // necessary for IE >= 10
          popupWindow.stop();
          popupWindow.print();
        }, 1000)
      }
    }
  }

  convertHtmlToPdfBase64(html) {
    // let htmlToSave = new htmlToPdf().from(html).save();
    let htmlTo = new htmlToPdf()
      .from(html)
      .outputPdf()
      .then((pdf) => {
        this.sendEmailInvoiceRequest(btoa(pdf));
        console.log(btoa(pdf));
      });
  }

  sendEmailInvoiceRequest(pdfBase64) {
    let data = {
      base64string: pdfBase64,
    };
    this._apiService
      .put(API.invoice_cancellation.sendEmailInvoice, data)
      .subscribe(
        (res) => { },
        (err) => console.log(err)
      );
  }

  CloseBasicModal(event) {
    document.querySelector('#' + event).classList.remove('show-modal');
  }

  LoadLabConfigurationList(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this._apiService.get(API.lab_configuration.loadLogoList).subscribe(
        (res) => {
          if (res.length > 0) {
            res.forEach((element) => {
              if (
                element.imagePathUrl !== null ||
                element.imagePathUrl !== undefined
              ) {
                this.imageData =
                  'data:image/png;base64,' + element.imagePathUrl;
              } else {
                this.imageData = undefined;
              }
              resolve(this.imageData);
            });
          } else {
            resolve(this.imageData);
          }
        },
        (err) => reject(err)
      );
    });
  }

  async ConvertHtmlToPdfBase64(section: string) {
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      const template = `
      <html>
      <head>
        <title></title>
        <style>
        @page { size: auto;  margin-top: 0mm; }
        @media print, screen{
        body{font-family:"Heebo", sans-serif;}
        .row{display:flex;flex-wrap:wrap;margin-right:-15px;margin-left:-15px;}
        .container-fluid{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
        .col-md-12{flex:0 0 100%;max-width:100%;}
        .col-md-6{flex:0 0 50%;max-width:50%;}
        .col-md-4 {flex: 0 0 33.33333333%;max-width: 33.33333333%;}
        .col-md-5 {-ms-flex: 0 0 41.66666667%;
          flex: 0 0 41.66666667%;
          max-width: 41.66666667%;}
        .col-md-8 {flex: 0 0 66.66666667%;max-width: 66.66666667%;}
        .col-md-3 {flex: 0 0 25%; max-width: 25%;}
        #conformity-report-section .checkbox-primary input[type="checkbox"]:checked + label::before {
          background-color: #07a3e1 !important;
          border-color: #07a3e1 !important;
          -webkit-print-color-adjust:exact;
        }
        #conformity-report-section .description{
          width:45%;
        }
        .m-l-15{margin-left:15px !important;}
        .m-r-15{margin-right:15px !important;}
        .m-r-30{margin-right:30px !important;}
        .m-r-45{margin-right:45px !important;}
        .border-dashed-styles{
          border-top: 2px dashed #ddd;
          padding-top: 20px;
          padding-bottom: 20px;
        }
        .orders-print-tbl{
          width:100% !important;
          max-width:100% !important;
          table-layout:uset;
        }
        .orders-print-tbl tr th:nth-child(1){width:200px;}
        .orders-print-tbl tr th:nth-child(2){width:100px;}
        .orders-print-tbl tr th:nth-child(3){width:100px;}
        .orders-print-tbl tr th:nth-child(4){width:100px;}
        .orders-print-tbl tr th:nth-child(5){width:60px;}
        .orders-print-tbl tr th:nth-child(6){width:100px;}
        .orders-print-tbl tr th:nth-child(7){width:60px;}
        .orders-print-tbl tr th:nth-child(8){width:60px;}
        .orders-print-tbl tr td:nth-child(1){width:200px;}
        .orders-print-tbl tr td:nth-child(2){width:100px;}
        .orders-print-tbl tr td:nth-child(3){width:100px;}
        .orders-print-tbl tr td:nth-child(4){width:100px;}
        .orders-print-tbl tr td:nth-child(5){width:60px;}
        .orders-print-tbl tr td:nth-child(6){width:100px;}
        .orders-print-tbl tr td:nth-child(7){width:60px;}
        .orders-print-tbl tr td:nth-child(8){width:60px;}
        .sanityreport-tbl tr td:nth-child(1){width:32%;}
        .blue-box-container.container-panel-list{width:350px;max-width:350px; position:relative !important; right:60px !important;}
        .w300{ width:300px;}
        .w400{ width:400px;}
        .w450{ width:450px;}
        .w200{ width:200px;}
        .w250{ width:250px;}
        .text-uppercase{text-transform:uppercase !important;}
        .operators-tbl-sanity thead tr {color:#fff; text-align:left;}
        .float-left{float:left;}
        .float-right{float:right;}
        .f-14{font-size:14px;}
        .f-15{font-size:15px;}
        .f-12{font-size:12px;}
        .p-10{padding:10px;}
        .p-5{padding:5px;}
        .m-5{margin:5px;}
        .p-l-5{padding-left:5px;}
        .p-l-30{padding-left:30px;}
        .p-l-10{padding-left:10px;}
        .p-l-15{padding-left:15px;}
        .p-l-100{padding-left:100px;}
        .p-b-5{padding-bottom:5px;}
        .p-r-30{padding-right:30px !important;}
        .p-r-50{padding-right:50px;}
        .p-0{ padding:0px;}
        .p-20{padding:20px;}
        .m-0{ margin:0px;}
        .pl-0{padding-left:0px !important;}
        .pr-0{padding-right:0px !important;}
        .m-b-0{margin-bottom: 0px !important;}
        .p-b-20{padding-bottom: 20px !important;}
        .m-t-0{margin-top: 0px !important;}
        .m-b-5{margin-bottom:5px !important;}
        .m-t-5{margin-top:5px;}
        .m-t-20{margin-top:20px !important;}
        .m-t-10{margin-top:10px !important; }
        .m-t-15{margin-top:15px;}
        .p-t-30{padding-top:30px !important;}
        .m-b-30{margin-bottom:30px !important;}
        .m-b-20{margin-bottom:20px !important;}
        .text-right{text-align:right;}
        .border-top-dash{border-top:1px dashed #314577;}
        .text-blue{color:#265b9e;-webkit-print-color-adjust:exact; }
        .text-white{color:#fff;-webkit-print-color-adjust:exact;}
        .text-darkblue{color: #314577;-webkit-print-color-adjust:exact;}
        .font-weight-bold{font-weight:700 !important;}
        .text-center{text-align:center !important;}
        .text-right{text-align:right !important;}
        .text-left{text-align:left !important;}
        table{border-collapse: collapse;}
        .table{width:100%;}
         td{vertical-align:top;}
         .position-left-30{position:relative; left: 30px;}
         .position-right-60{position:relative; right: 60px;}
         .invoice-total-view .bg2{padding:10px;}
         .border-bottom-dash{border-bottom:1px dashed #314577;}
        .position-left{position:absolute; left:0px;}
        .position-right{position:relative; right: 25px;}
        .position-top-50{position:relative; top: 50px;}
        .position-right-30{position:relative; right: 30px;}
        .position-right-40{position:relative; right: 40px;}
        .position-right-67{position:relative; right:67px;}
        .position-left:20px{position:relative; left:20px;}
        .position-left:20px{position:relative; left:20px;}
        .position-left:50px{position:relative; left:50px;}
        .address-infor-panel .d-block{display:block !important;}
        .content-body{padding:0 15px;}
        .d-block{display:block !important;}
        .w-80{width:80%;}
        .text-underline{ text-decoration: underline;}
        .table > thead.bg3 > tr > th{color:#ffffff;-webkit-print-color-adjust:exact;}
        .bg{background:#cbd0dd;-webkit-print-color-adjust:exact;}
        .bg2{background:#E6E8EE; -webkit-print-color-adjust:exact;}
        .bg3{background:#314577; -webkit-print-color-adjust:exact;}
        .titlehead{background:#07a3e1c2 !important;-webkit-print-color-adjust:exact;color:#fff;}
        .answerfield{background:#efefef !important;-webkit-print-color-adjust:exact;color:#333;}
        .report-table{background-color: #07a3e1 !important; color: #fff !important;
          -webkit-print-color-adjust:exact; width:100%; text-align:left;
          padding-left: 5px; padding-right:5px; padding-top:3px; padding-bottom:3px; font-size:14px;}
        .report-dt tr td:nth-child(1){width:10%;}
        .report-dt tr td:nth-child(2){width:50%;}
        .report-dt tr td:nth-child(3){width:15%;}
        .report-dt tr td:nth-child(4){width:20%; padding-right:30px;}
        .report-dt tbody tr th{margin-top: 2px;}
        .report-dt tbody tr {border-bottom:1px dashed #90A4AE;}
        .report-dt tbody tr td{font-size:13px;}
        .receipt-report-twotbl tr {padding: 0px !important; margin: 0px !important; border:none !important;}
        .receipt-report-twotbl tr td {padding: 0px !important; margin: 0px !important; border:none !important;}
        .report-dt .item-three{-webkit-print-color-adjust:exact; background-color:#CFD8DC;}
        .report-dt .item-four{-webkit-print-color-adjust:exact; background-color:#CFD8DC;}
        .delivery-note-table tr th:nth-child(1){padding-left:5px;}
        .delivery-note-table tr th:nth-child(6){padding-left:5px; text-align:right; padding-right:30px;}
        .delivery-note-table tr td:nth-child(1){width:10%; padding-left:5px;}
        .delivery-note-table tr td:nth-child(2){width:10%;}
        .delivery-note-table tr td:nth-child(3){width:30%;}
        .delivery-note-table tr td:nth-child(4){width:20%;}
        .delivery-note-table tr td:nth-child(5){width:10%}
        .delivery-note-table tr td:nth-child(6){width:20%; padding-right:30px; padding-left:5px; text-align:right;}
        .delivery-note-table .item-four{-webkit-print-color-adjust:exact; background-color:#CFD8DC;}
        .delivery-note-table tbody tr {border-bottom:1px dashed #90A4AE; font-size:14px;}
        .address-panel{background-color:#ddd;
          -webkit-print-color-adjust:exact; width:200px; margin-bottom:20px; border-radius:3px; padding:10px; }
        .p-r-40{padding-right:40px !important;}
        .address-panel p{margin:0px; padding:0px; font-size:12px;}
        .work-report-print .card-header.bg3{color:#fff !important; -webkit-print-color-adjust:exact;}
        .work-report-print .set-padding-left{padding-left:30px !important;}
        .work-reportt .text-white{color:#fff !important; -webkit-print-color-adjust:exact;}
        .print-report-id.text-center{text-align:center !important; width:100% !important;}
        .price-list-amount{text-align:right !important; padding-right:30px;}
        .price-list{padding-right:20px;text-align:right !important;}
        .price-list2{padding-right:20px;text-align:right !important;position:relative; right:-15px !important;}
        .invoice-table-report td.text-right{text-align:right !important; width:100% !important; padding-right:30px !important;}
        .invoice-table-report tr th:nth-child(1){width: 10%;}
        .invoice-table-report tr th:nth-child(2){width: 10%;}
        .invoice-table-report tr th:nth-child(3){width: 50%;}
        .invoice-table-report tr th:nth-child(4){width: 30%;}
        .budget-report-mr-3{margin-right:30px !important;}
        .w300.w350{width:350px !important;}
        .w410{width: 410px !important;}
        .align-self-end {
          -ms-flex-item-align: end !important;
          align-self: flex-end !important;
        }
        .w-percentage-97{width:97% !important;}
        .w-28-percentage{width:28%;}
        .w-25-percentage{width:28%;}
        #work-report-section.work-reportt .col-md-4 img{max-width:100% !important;}
        #work-report-section.work-reportt h2.color-white{color:#fff !important; -webkit-print-color-adjust:exact;}
        .m-t--20{margin-top:-50px !important;}
        #invoice-section .col-md-6 img{max-width:100% !important;}
        #invoice-section .invoice-header h1{display:block !important; padding-right:20px !important;}
        #invoice-section .invoice-header .float-right div{margin-right:20px !important;}
        .table.innertble-report {font-size:12px !important; margin-left:60px !important;
          margin-top:15px !important; display:inline-block !important; max-width:100% !important;}
        .table.innertble-report thead tr th{color:#000 !important; -webkit-print-color-adjust:exact;}
        .table.innertble-report tr th:nth-child(1){width: 10%;}
        .table.innertble-report tr th:nth-child(2){width: 20%;}
        .table.innertble-report tr th:nth-child(3){width: 50%;}
        .table.innertble-report tr th:nth-child(4){width: 20%;}
        .sanity-material-tbl tr th:nth-child(1){width: 40%;}
        .sanity-material-tbl tr th:nth-child(2){width: 15%;}
        .sanity-material-tbl tr th:nth-child(3){width: 15%;}
        .sanity-material-tbl tr th:nth-child(4){width: 30%;}
        .sanity-material-tbl tr td:nth-child(1){width: 40%;}
        .sanity-material-tbl tr td:nth-child(2){width: 15%;}
        .sanity-material-tbl tr td:nth-child(3){width: 15%;}
        .sanity-material-tbl tr td:nth-child(4){width: 30%;}
        .bg4{background-color:#ddd !important;  -webkit-print-color-adjust:exact;}
        .m-t-30{margin-top:30px !important;}
        .set-color-margin{border-top:5px solid #314577;}
        .sanity-report-operators tr td:nth-child(1){width:30%;}
        .sanity-report-operators tr td:nth-child(2){width:50%;}
        .sanity-report-operators tr td:nth-child(3){width:20%;}
        .sanity-report-operators tr th:nth-child(1){width:30%;}
        .sanity-report-operators tr th:nth-child(2){width:50%;}
        .sanity-report-operators tr th:nth-child(3){width:20%;}
        .operators-tbl-sanity tr th:nth-child(1){width:30%;}
        .operators-tbl-sanity tr th:nth-child(2){width:50%;}
        .operators-tbl-sanity tr th:nth-child(3){width:20%;}
        .operators-tbl-sanity tr td:nth-child(1){width:30%;}
        .operators-tbl-sanity tr td:nth-child(2){width:50%;}
        .operators-tbl-sanity tr td:nth-child(3){width:20%;}
        .doc-name-panel h4{color:#fff;  -webkit-print-color-adjust:exact; font-size: 14px!important;}
        .doc-name-panel{margin-bottom:0px !important; width: 300px !important; padding:3px 20px !important;}
        .table-provider-rport-expense .provider-name{height:17px;}
        .table-provider-rport-expense tr:nth-child(2) td:nth-child(1) {width:30%;}
        .table-provider-rport-expense tr:nth-child(2) td:nth-child(2) {width:15%;}
        .table-provider-rport-expense tr:nth-child(2) td:nth-child(3) {width:35%;}
        .table-provider-rport-expense tr:nth-child(2) td:nth-child(4) {width:20%;}
        strong{font-weight:bold;}
        .deliverynote-details{padding-right:45px !important;}
        .table-list-report-expense tr th:nth-child(1){width:70px; text-align:left; vertical-align:top;}
        .table-list-report-expense tr th:nth-child(2){width:70px; text-align:left; vertical-align:top;}
        .table-list-report-expense tr th:nth-child(3){width:60px; text-align:left; vertical-align:top;}
        .table-list-report-expense tr th:nth-child(4){width:200px; text-align:left; vertical-align:top;}
        .table-list-report-expense tr th:nth-child(5){width:70px; text-align:left; vertical-align:top;}
        .table-list-report-expense tr th:nth-child(6){width:80px; text-align:left; vertical-align:top;}
        .table-list-report-expense tr th:nth-child(7){width:90px; vertical-align:top;}
        .table-list-report-expense tr th:nth-child(8){width:90px; vertical-align:top;}
        .table-list-report-expense tr th:nth-child(9){width:95px; vertical-align:top; padding-right: 34px;}
        .table-list-report-expense {table-layout:unset;}
        .table-list-report-expense tr td:nth-child(1){width:70px; white-space:pre-wrap; padding-right:20px;}
        .table-list-report-expense tr td:nth-child(2){width:70px;}
        .table-list-report-expense tr td:nth-child(3){width:60px;}
        .table-list-report-expense tr td:nth-child(4){width:200px; white-space:pre-wrap; padding-right:20px;}
        .table-list-report-expense tr td:nth-child(5){width:70px;}
        .table-list-report-expense tr td:nth-child(6){width:80px;}
        .table-list-report-expense tr td:nth-child(7){width:90px;}
        .table-list-report-expense tr td:nth-child(8){width:90px;}
        .table-list-report-expense tr td:nth-child(9){width:95px; padding-right: 34px;}
        .work-report-subheading{margin-bottom: 2px; padding-bottom: 0px; margin-top: 0px; padding-left: 20px;}
        .work-report-img{max-width:100%; height:auto;}
        .sanity-gurantee-reporttbl tr td:nth-child(1){width:25%;}
        .sanity-gurantee-reporttbl tr td:nth-child(2){width:25%;}
        .sanity-gurantee-reporttbl tr td:nth-child(3){width:25%;}
        .sanity-gurantee-reporttbl tr td:nth-child(4){width:25%;}
        .sanity-gurantee-reporttbl tr td:nth-child(5){width:25%;}
        .deliverynotereport-tbl tr td:nth-child(1){width:100px;}
        .deliverynotereport-tbl tr td:nth-child(2){width:250px;}
        .deliverynotereport-tbl tr td:nth-child(3){width:150px;}
        .deliverynotereport-tbl tr td:nth-child(4){width:50px;}
        .deliverynotereport-tbl tr td:nth-child(5){width:auto;}
        .deliverynotereport-tbl tr td:nth-child(6){width:150px;}
        .deliverynotereport-tbl th td:nth-child(1){width:100px;}
        .deliverynotereport-tbl th td:nth-child(2){width:250px;}
        .deliverynotereport-tbl th td:nth-child(3){width:150px;}
        .deliverynotereport-tbl th td:nth-child(4){width:50px;}
        .deliverynotereport-tbl th td:nth-child(5){width:auto;}
        .deliverynotereport-tbl th td:nth-child(6){width:150px;}
        .sanitygurantee-report-tbl tr td:nth-child(1){width:50%; vertical-align: top;}
        .sanitygurantee-report-tbl tr td:nth-child(2){width:100%; word-break: break-word;}
        .sanitygurantee-report-tbl tr td:nth-child(3){width:28%;}
        .sanitygurantee-report-tbl tr td:nth-child(4){width:28%;}
        .m-t-0{
          margin-top:0px;
        }
        .m-b-0{
          margin-top:0px;
        }
        .sanity-report-tbl1 tr td:nth-child(1){width:40%;}
        .sanity-report-tbl1 tr td:nth-child(2){width:15%;}
        .sanity-report-tbl1 tr td:nth-child(3){width:15%;}
        .sanity-report-tbl1 tr td:nth-child(4){width:30%;}
        .sanity-report-tbl1 tr th:nth-child(1){width:40%;}
        .sanity-report-tbl1 tr th:nth-child(2){width:15%;}
        .sanity-report-tbl1 tr th:nth-child(3){width:15%;}
        .sanity-report-tbl1 tr th:nth-child(4){width:30%;}
        .delivery-note-values span{width:122px !important; display:inline-block;}
        .work-number-title{margin-top: 20px !important; margin-bottom: 10px !important;}
        .report-provider-details-expense tr td:nth-child(1){width:50px;}
        .report-provider-details-expense tr td:nth-child(2){width:70px;}
        .report-provider-details-expense tr td:nth-child(3){width:70px;}
        .report-provider-details-expense tr td:nth-child(4){width:60px;}
        .report-provider-details-expense tr td:nth-child(5){width:60px;}
        .report-provider-details-expense tr td:nth-child(6){width:60px;}
        .report-provider-details-expense tr td:nth-child(7){width:60px;}
        .report-provider-details-expense tr td:nth-child(8){width:90px; padding-right:30px;}
        .w-60{width:60% !important;}
        .invoice-receiptreport-one tr th:nth-child(1){width:100px; padding-right: 30px;}
        .invoice-receiptreport-one tr th:nth-child(2){width:450px}
        .invoice-receiptreport-one tr th:nth-child(3){width:50px; padding-right:30px;}

        .invoice-receiptreport-one tr td:nth-child(1){width:100px; padding-right: 30px;}
        .invoice-receiptreport-one tr td:nth-child(2){width:450px}
        .invoice-receiptreport-one tr td:nth-child(3){width:50px; padding-right:30px;}
        .w-100{width:100% !important;}
        .receipt-report-two{padding:30px 30px 0px 30px!important;}
        .invoice-deliverynote-tbl tr td:nth-child(1){width:100px;}
        .invoice-deliverynote-tbl tr td:nth-child(2){width:100px;}
        .invoice-deliverynote-tbl tr td:nth-child(3){width:500px;}
        .invoice-deliverynote-tbl tr td:nth-child(4){width:150px;}
        .invoice-deliverynote-tbl tr td:nth-child(5){width:150px; padding-right: 68px;}

        .invoice-deliverynote-tbl tr th:nth-child(1){width:100px;}
        .invoice-deliverynote-tbl tr th:nth-child(2){width:100px;}
        .invoice-deliverynote-tbl tr th:nth-child(3){width:500px;}
        .invoice-deliverynote-tbl tr th:nth-child(4){width:150px;}
        .invoice-deliverynote-tbl tr th:nth-child(5){width:150px; padding-right: 68px;}
        .blue-box-container{
          padding: 10px;
          background-color: #DEF6FF;
          -webkit-print-color-adjust:exact;
        }
        .f-15{font-size:15px !important;}
        .w-260{width:260px !important;}
        .using-report-tbl tr td:nth-child(1){width:200px;}
        .using-report-tbl tr td:nth-child(2){width:200px;}
        .budgetworkreport-tbl tr td:nth-child(1){width:400px;}
        .budgetworkreport-tbl tr td:nth-child(2){width:100px;}
        .budgetworkreport-tbl tr td:nth-child(3){width:80px;}
        .budgetworkreport-tbl tr td:nth-child(4){width:150px;}
        .budgetworkreport-tbl tr th:nth-child(1){width:400px;}
        .budgetworkreport-tbl tr th:nth-child(2){width:100px;}
        .budgetworkreport-tbl tr th:nth-child(3){width:80px;}
        .budgetworkreport-tbl tr th:nth-child(4){width:150px;}

        .grey-panel-container{
          padding:5px 20px;
          width:550px;
          float:right;
        }

        .using-report-tbl .answerfield span {
          float: left;
          width: auto;
        }

        .overflow-hidden{
          overflow:hidden !important;
        }
        .invoice-report2-tbl tr td:nth-child(1){width:100px;}
        .invoice-report2-tbl tr td:nth-child(2){width:100px; white-space: break-spaces;}
        .invoice-report2-tbl tr td:nth-child(3){width:120px; padding-right:40px;}
        .invoice-report2-tbl tr td:nth-child(4){width:100px;}
        .invoice-report2-tbl tr td:nth-child(5){width:100px;}
        
        .invoice-report2-tbl tr td:nth-child(6){width:100px; padding-right:10px;}
        
        .invoice-report2-tbl tr th:nth-child(1){width:100px;}
        .invoice-report2-tbl tr th:nth-child(2){width:100px;}
        .invoice-report2-tbl tr th:nth-child(3){width:120px; padding-right:40px;}
        .invoice-report2-tbl tr th:nth-child(4){width:100px;}
        .invoice-report2-tbl tr th:nth-child(5){width:100px;}
        
        .invoice-report2-tbl tr th:nth-child(6){width:100px; padding-right:10px;}

        .invoice-deliverynote-tbl .total-price-panel {margin: 0 !important; padding: 0 10px 0 0 !important; text-align: right;}


        .invoice-report2-tbl tr th.show-totalDiscount-view {padding-right: 0 !important; width: 40px !important;}
        .invoice-report2-tbl tr td.show-totalDiscount-view {padding-right: 0 !important; width: 40px !important;}

        .invoice-report2-tbl tr th.show-BreakdownList-view {width: 80px !important; padding-right: 0 !important;}
        .invoice-report2-tbl tr td.show-BreakdownList-view {width: 80px !important; padding-right: 0 !important;}

        .invoice-report2-tbl tr th.show-BreakdownList-view + th {padding-right: 30px; width: 150px;}
        .invoice-report2-tbl tr td.show-BreakdownList-view + td {padding-right: 30px; width: 150px;}

        .invoice-report2-tbl tr th.show-totalDiscount-view + th.show-BreakdownList-view {padding-right: 0 !important;}
        .invoice-report2-tbl tr td.show-totalDiscount-view + td.show-BreakdownList-view {padding-right: 0 !important;}

        .invoice-deliverynote-tbl tr th.top-tabl-header {text-align: right !important;}

        .invoice-report2-tbl tr th.show-BreakdownList-view + th {width: 150px !important; padding-right: 10px !important;}
        .invoice-report2-tbl tr td.show-BreakdownList-view + td {width: 150px !important; padding-right: 10px !important;}

        .invoice-report2-tbl tr th.show-totalDiscount-view + th.show-BreakdownList-view + th {width: 110px !important;}
        .invoice-report2-tbl tr td.show-totalDiscount-view + td.show-BreakdownList-view + td {width: 110px !important;}

        
        
        .max-width-60{max-width:60% !important;}
        .report-provider-details-expense tr th:nth-child(1){width:50px; text-align:left;}
        .report-provider-details-expense tr th:nth-child(2){width:70px; text-align:left;}
        .report-provider-details-expense tr th:nth-child(3){width:70px; text-align:left;}
        .report-provider-details-expense tr th:nth-child(4){width:60px; text-align:left;}
        .report-provider-details-expense tr th:nth-child(5){width:60px; text-align:left;}
        .report-provider-details-expense tr th:nth-child(6){width:60px; text-align:left;}
        .report-provider-details-expense tr th:nth-child(7){width:60px; text-align:left;}
        .report-provider-details-expense tr th:nth-child(8){width:90px; padding-right:30px;}
        .invoice-report-templatetbl tr th:nth-child(1){width:100px; text-align:left;}
        .invoice-report-templatetbl tr th:nth-child(2){width:200px; text-align:left;}
        .invoice-report-templatetbl tr th:nth-child(3){width:120px; text-align:left; padding-right:40px;}
        .invoice-report-templatetbl tr th:nth-child(4){width:100px; text-align:left;}
        .invoice-report-templatetbl tr th:nth-child(5){width:100px; text-align:left;}
        .invoice-report-templatetbl tr th:nth-child(6){width:200px; text-align:left; padding-right:30px;}
        .invoice-report-templatetbl tr td:nth-child(1){width:100px; text-align:left;}
        .invoice-report-templatetbl tr td:nth-child(2){width:200px; text-align:left;}
        .invoice-report-templatetbl tr td:nth-child(3){width:120px; text-align:left; padding-right:40px;}
        .invoice-report-templatetbl tr td:nth-child(4){width:100px; text-align:left;}
        .invoice-report-templatetbl tr td:nth-child(5){width:100px; text-align:left;}
        .invoice-report-templatetbl tr td:nth-child(6){width:200px; text-align:left; padding-right:30px;}
        .invoice-report-templatetbl tr.result-display td:nth-child(1){width:100px; text-align:left;}
        .invoice-report-templatetbl tr.result-display td:nth-child(2){width:200px; text-align:left;}
        .invoice-report-templatetbl tr.result-display td:nth-child(3){width:120px; text-align:left; padding-right:40px !important;}
        .invoice-report-templatetbl tr.result-display td:nth-child(4){width:100px; text-align:left;}
        .invoice-report-templatetbl tr.result-display td:nth-child(5){width:100px; text-align:left;}
        .invoice-report-templatetbl tr.result-display td:nth-child(6){width:200px; text-align:left; padding-right:30px !important;}
        .m-b-30{margin-bottom: 30px;}
        .p-b-30{padding-bottom: 30px;}
        .p-t-20{padding-top:20px;}
        .deliverynote-tbl-list tr th:nth-child(1){width:100px;}
        .deliverynote-tbl-list tr th:nth-child(2){width:150px;}
        .deliverynote-tbl-list tr th:nth-child(3){width:150px;}
        .deliverynote-tbl-list tr th:nth-child(4){width:70px;}
        .deliverynote-tbl-list tr th:nth-child(5){width:90px; text-align: right;}
        .deliverynote-tbl-list tr th:nth-child(6){width:90px;}
        .deliverynote-tbl-list tr th:nth-child(7){width:120px;}
        .deliverynote-tbl-list tr th:nth-child(8){width:150px;}

        .deliverynote-tbl-list tr td:nth-child(1){width:100px;}
        .deliverynote-tbl-list tr td:nth-child(2){width:450px;}
        .deliverynote-tbl-list tr td:nth-child(3){width:60px;}
        .deliverynote-tbl-list tr td:nth-child(4){width:50px;}
        .deliverynote-tbl-list tr td:nth-child(5){width:90px; text-align: right;}
        .deliverynote-tbl-list tr td:nth-child(6){width:90px;}
        .deliverynote-tbl-list tr td:nth-child(7){width:70px;}
        .deliverynote-tbl-list tr td:nth-child(8){width:150px;}

        .w-160{width:160px !important;}
        .w-350{width:350px !important;}
        .return-order-report-tbl tr td:nth-child(1){width:80px; padding-right: 30px;}
        .return-order-report-tbl tr td:nth-child(2){width:200px;}
        .return-order-report-tbl tr td:nth-child(3){width:200px; padding-right: 30px !important;}
        .return-order-report-tbl tr td:nth-child(4){width:200px;}
        .return-order-report-tbl tr td:nth-child(5){width:200px;}
        .return-order-report-tbl tr th:nth-child(1){width:80px; padding-right: 30px;}
        .return-order-report-tbl tr th:nth-child(2){width:200px;}
        .return-order-report-tbl tr th:nth-child(3){width:200px; padding-right: 30px !important;}
        .return-order-report-tbl tr th:nth-child(4){width:200px;}
        .return-order-report-tbl tr th:nth-child(5){width:200px;}
        .work-status{color: #fff !important; padding-left: 10px; -webkit-print-color-adjust:exact;}
        .max-width-80{max-width:80% !important;}
        .work-report-print-tbl tr th:nth-child(1){color: #fff !important;text-align:left; -webkit-print-color-adjust:exact;}
        .work-report-print-tbl tr th:nth-child(2){color: #fff !important;text-align:left; -webkit-print-color-adjust:exact;}
        .work-report-print-tbl tr th:nth-child(3){color: #fff !important;text-align:left; -webkit-print-color-adjust:exact;}
        .work-report-print-tbl .obs {float:left; width: 100%; font-size: 12px;}
        .work-report-print-tbl .obs h3{float:left; width: 100%; font-size: 12px; color: #FFFFFF;}
        .work-report-print-tbl td {white-space: pre-line;}
        .work-report-print-tbl-obs{margin-top: 25px; position: relative; width:100%; display:inline-table;     white-space: pre-line;}
        .work-report-print-tbl-obs tbody tr td {padding-top: 8px; word-break: break-word; width:100%; white-space: pre-line;}
        .work-report-print-tbl-obs tbody tr td ul {padding-left: 20px; padding-bottom: 15px; margin: 0px; float:left; width: 100%;}
        .work-report-print-tbl-obs tbody tr td ul li{list-style-type: circle; float:left; width: 100%;}
        h4.invoice-report-template{color:#000 !important; margin-left:-15px !important; padding-left:-30px !important; -webkit-print-color-adjust:exact;}
        .span.note-text{font-size:10px; color:rgb(68, 67, 67) !important;  text-transform: lowercase !important;}
        .recpit-copy{border:1px solid #ddd; border-radius:5px; width:90%;}
        .upper-box-border{border-bottom:1px solid #ddd; padding-top:20px !important;}
        .recpiet-report-tetxt span{font-weight:normal !important;}
        .client-infor span{font-weight:normal !important;}
        .f-13{font-size:13px !important;}
        .text-align-span{display:inline-block; word-wrap:break-word; white-space: pre-line;}
        .report-observations{display:inline-block; word-wrap:break-word; white-space: pre-line;}
        .work-status{color: #fff !important; padding-left: 10px; -webkit-print-color-adjust:exact;}
        .description-box{background-color:#f6f6f6 !important; -webkit-print-color-adjust:exact;
          padding:10px !important; border-radius:5px;}
          .underline-none{text-decoration:none !important;}
           .invoice-report2 tr th:nth-child(1){width:60px !important;}
           .invoice-report2 tr th:nth-child(2){width:100px !important;}
           .invoice-report2 tr th:nth-child(3){width:80px !important;}
           .invoice-report2 tr th:nth-child(4){width:60px !important;}
           .invoice-report2 tr th:nth-child(5){width:60px !important;}
           .invoice-report2 tr th:nth-child(6){width:60px !important;}
           .invoicereport-tbl tr th:nth-child(1){width:30px !important;}
           .invoicereport-tbl tr th:nth-child(2){width:50px !important;}
           .invoicereport-tbl tr th:nth-child(3){width:100px !important;}
           .invoicereport-tbl tr th:nth-child(4){width:100px !important;}
           .invoicereport-tbl tr th:nth-child(5){width:100px !important;}
           .invoicereport-tbl tr th:nth-child(6){width:30px !important;}
           .invoicereport-tbl tr td:nth-child(1){width:30px !important;}
           .invoicereport-tbl tr td:nth-child(2){width:50px !important;}
           .invoicereport-tbl tr td:nth-child(3){width:100px !important;}
           .invoicereport-tbl tr td:nth-child(4){width:100px !important;}
           .invoicereport-tbl tr td:nth-child(5){width:100px !important;}
           .invoicereport-tbl tr td:nth-child(6){width:30px !important;}
           .w450 {width:380px !important;}
           .margin-left-set{padding-left:100px !important;}
           .pdf1-view .budget-tble-2 tr th:nth-child(3){width:100px !important; }
           .pdf2-view p.recpit-detail-infor{margin-top:-20px !important;}
           .font-weight-normal{font-weight:normal !important;}
           .guarantee-report-pdf-view tr td.nodefactura-txt{
            width:400px !important;
            display:block !important;
            text-align:left !important;
          }
          .address-infor-budget span{display:block !important;}
          .guarantee-report-pdf-view tr td.nodefactura{
            width:400px !important;
            display:block !important;
            text-align:left !important;
          }
          .light-blue-container, .bg5 {
            background-color:#def6ff !important;
            -webkit-print-color-adjust:exact;
        }
          .node-factura-view{
            width:100%;
          }
          .justify-content-between{
            -ms-flex-pack: justify !important;
            justify-content: space-between !important;
          }
          .delivery-note-values{
            padding: 20px;
            position: relative;
            right: 20px;
            font-weight:bold;
            width:250px;
          }
          .delivery-note-vat{
            padding: 10px;
            position: relative;
            left: 20px;
            font-weight:bold;
            width:250px;
          }
          .p-t-0{
            padding-top:0px;
          }
          .p-b-0{
            padding-top:0px;
          }

          // .vat-breakdown-listoi.position-left-30{
          //   left:5px !important.
          // }

           .invoice-table.position-right-60{
              right:-10px !important;
              float:right !important;
            }

            .invoice-table .w300{
              padding:5px !important;
              margin:0px !important;
            }

            // .position-left-30.vat-breakdown-list{
            //   left:10px !important;
            // }

          //  .vat-breakdown-list.p-20{
          //   padding:5px !important;
          //  }

          //  .w300.vat-breakdown-list{
          //   width:500px !important;
          //   max-width:500px !important;
          // }

          .vat-breakdown-list .table{
            width:300px !important;
          }

          .hide-vat-view{
            position: relative !important;
            left: 150px !important;
          }

          .invoice-deliverynote-tbl .border-bottom-dash{
            border:none !important;
          }

          .delivery-note-values{
            padding:5px !important;
            width:300px;
          }

          .free-budgetvalues.w-350{
            padding:5px !important;
            margin-left:0px !important;
            margin-right:0px !important;
          }

          .budget-tble-2 tr td:nth-child(1) {
            width: 100px !important;
          }
          .budget-tble-2 tr td:nth-child(2){
            width: 100px;
          }
          .budget-tble-2 tr td:nth-child(3) {
            width: 100px;
          }
          .budget-tble-2 tr td:nth-child(4) {
            width: 100px;
          }
          .budget-tble-2 tr td:nth-child(5) {
            width: 150px;
          }
          .budget-tble-2 tr td:nth-child(6) {
            text-align: right;
          }
          .budget-tble-2 tr td:nth-child(7) {
            width: 150px;
          }



          #incident-report-section {
            padding: 0 0 0 40px;
          }


          .container-fluid-new {
            width: 97% !important;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
        }

        .row-block {
          marign: 0  !important;
          padding: 10px 15px !important;
          border-bottom: solid 3px #EEEEEE;
        }

        .row-block .col-md-2 {
          width: 20% !important;
          margin-bottom: 5px;
        }



          .budget-tble-2 tr th:nth-child(1) {
            width: 100px;
          }
          .budget-tble-2 tr th:nth-child(2) {
            width: 100px;
          }
          .budget-tble-2 tr th:nth-child(3) {
            width: 100px;
          }
          .budget-tble-2 tr th:nth-child(4) {
            width: 100px;
          }
          .budget-tble-2 tr th:nth-child(5) {
            width: 150px;
          }
          .budget-tble-2 tr th:nth-child(6) {
            text-align: right;
          }
          .budget-tble-2 tr th:nth-child(7) {
            width: 150px;
          }


          .invoice-receiptreport-one tr th:nth-child(2) {
            width: 250px !important;
          }

          .invoice-receiptreport-one tr th:nth-child(3) {
            width: 150px !important;
          }

          .invoice-receiptreport-one tr th:nth-child(3) {
            text-align: right !important;
          }

          .invoice-receiptreport-one tr td:nth-child(3) {
            text-align: right !important;
          }

          .vat-breakdown-list.m-t-30.w300 {
            margin-top: 10px !important;
            padding-bottom: 0px !important;
            width: 600px !important;
            max-width: 600px !important;
          }

          .vat-dtails-show .m-t-30 {
            margin-top: 0px !important;
          }

          .sanitygurantee-work tr td:nth-child(1){
          vertical-align: top;
          width:32%;
          }

           .answerfield span, .sanitygurantee-item span, .nodefactura span{
            display: block;
            float: left;
           }

           .sanitygurantee-title span, .nodefactura-txt span{
            display: block;
            float: left;
            width:10px;
           }
           .foot-note{ padding-right:10px; padding-left:10px;}
           .foot-note p { font-size:8px !important;}
       }

        </style>
      </head>
      <body>${html}</body>
    </html>`;
      return new htmlToPdf().from(template).outputPdf();
    }
  }

  sendEmailRequest(emailmodel: any) {
    return this._apiService
      .post(API.email.sendEmail, emailmodel, false)
      .map((res) => res)
      .toPromise();
  }

  initializeDateVariables() {
    this.maxDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };
    this.minDate = {
      year: new Date().getFullYear() - 100,
      month: 1,
      day: 1
    };
    this.maxAdjustmentDate = {
      year: new Date().getFullYear() + 1,
      month: 1,
      day: 1
    };
  }

  ConvertStringToNgbDate(dateString: string) {
    if (dateString != null && dateString.length === 8 && Number(dateString)) {
      const date = dateString.slice(0, 2);
      const month = dateString.slice(2, 4);
      const year = dateString.slice(4, 8);
      let dateObj = new NgbDate(0, 0, 0);
      if (Number(date) > 31) {
        return null;
      } else if (Number(month) > 12) {
        return null;
      } else {
        return dateObj = new NgbDate(Number(year), Number(month), Number(date));
      }
    } else {
      return null;
    }
  }

  public formatHourMinute(number: any) {
    const hours = parseInt(String(number), 10);
    // tslint:disable-next-line: radix
    const minutes = parseInt((number + '').split(':')[1]);
    return hours + 'h' + ' ' + minutes + 'm';
  }

  public formatTotalHourMinute(number: any) {
    let hours = 0;
    let calculatedMinutes = '00';
    if (number && number !== '') {
      hours = parseInt(String(number), 10);
      const tempNo = (number + '').split('.')[1];
      let floatingNumbers = 0;
      if (tempNo) {
        // tslint:disable-next-line: radix
        floatingNumbers = parseFloat('0.' + tempNo);
      }
      const tempNoSp = (number + '').split(',')[1];
      if (tempNoSp) {
        // tslint:disable-next-line: radix
        floatingNumbers = parseFloat('0.' + tempNoSp);
      }

      if (floatingNumbers === 0) {
        return hours + ' h' + ' ' + calculatedMinutes + ' min';
      }

      if (!isNaN(floatingNumbers)) {
        const minutes = Math.round(floatingNumbers * 60);
        if (!isNaN(minutes)) {
          calculatedMinutes = ('0' + minutes).slice(-2);
        }
        return hours + ' h' + ' ' + calculatedMinutes + ' min';
      } else {
        return hours + ' h' + ' ' + calculatedMinutes + ' min';
      }
    } else {
      return hours + ' h' + ' ' + calculatedMinutes + ' min';
    }
  }

  public Translate(text: string): string {
    let translatedString = "";
    this._translateService.get(text).subscribe(async (translation: string) => {
      translatedString = translation;
    });

    return translatedString;
  }
}
