export class MainWorkTypePhaseTaskViewModel {
  fromDate: string;
  toDate: string;
  clientValue: number;
  workerValue: number;
  labSectionValue: number;
  taskValue: number;
  time: string;
  taskStatus: string;
}
