export class MwtImplantData {
    id: number;
    mainWorkTypeId: number;
    providerManufacId: number;
    providerManufacName: string;
    manufacturerName: string;
    model: string;
    reference: string;
    longitud: number;
    apical: number;
    occlusal: number;
    note: string;
    cssClass: string;
    symbol: string;
    workId: number;
    order: number;
    createdDate: string;
    createdBy: number;
    status: boolean;
    isClosed: boolean;

    constructor(init?: Partial<MwtImplantData>) {
        if (init) { Object.assign(this, init); }
    }
}
