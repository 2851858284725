export class Resource{
    id:any;
    description:string;
    status: boolean;

    isEdit?: boolean;
    isDescriptionRequired: boolean;

    constructor() {
      this.status = true;
    }
}
