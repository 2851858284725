import API from './../../../../common/api.config.json';
import { ApiService } from '../../../../common/services/api.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommonDataService } from '../../../../common/services/common.data.service';
import { ShippingForms } from '../../../entities/shipping-forms';
import { Toast } from 'src/app/modules/common/services/toast.service';


@Component({
  templateUrl: './shipping-forms.component.html',
  selector: 'shipping-form-list',
  styleUrls: []
})

export class ShippingFormsComponent implements OnInit {

  successMessage: any;
  updateMessage: any;
  errorMessage: any;
  deleteMessage: any;
  statusMessage: any;
  deleteErrorMessage: any;
  tableName: any;

  shippingForms: ShippingForms;
  isLoad: boolean;

  position = 'bottom-right';
  shippingFormsList: ShippingForms[];
  private _shippingFormsList: ShippingForms[] = [];

  shippingFormsFormGroup: FormGroup;
  shippingFormsForm: NgForm;

  @ViewChild('description') nameField: ElementRef;
  isDisabledShipping = false;

  constructor(
    private _router: Router,
    private _apiService: ApiService,
    private _fb: FormBuilder,
    private _commonUIService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: Toast) {
    this.shippingForms = new ShippingForms();
    this.isDisabledShipping = false;
  }

  ngOnInit(): void {

    this.shippingFormsFormGroup = this._fb.group({
      description: ['', [
        Validators.required
      ]]
    })

    this.LoadShippingFormsList();
    this.isLoad = false;
  }

  LoadShippingFormsList() {
    this._apiService.get(API.shipping_forms.getShippingFormsList)
      .subscribe(res => {
        this.shippingFormsList = res;
        this._shippingFormsList = res;
        this.isLoad = true;
      });
  }

  LoadEditShippingForms(shippingForms: ShippingForms) {
    shippingForms.isEdit = true;
  }

  EditShippingForms(shippingForms: ShippingForms, statusChange: boolean) {
    this.isDisabledShipping = true;

    this.statusMessage = this._commonDataService.localizationLabelList['status_updated'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.tableName = this._commonDataService.localizationLabelList['shipping_forms'];

    shippingForms.isEdit = false;

    this._apiService.put(API.shipping_forms.editShippingForms + shippingForms.id, shippingForms, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {

            let message = '';

            if (statusChange) {
              message = this.statusMessage;
            }
            else {
              message = this.updateMessage;
            }

            this._toastyService.success({
              title: this.tableName,
              msg: message
            });

            this.LoadShippingFormsList();
          }
        },
        err => {
          console.log(err);
        },
        () => {
          this.isDisabledShipping = false;
        }
      );
  }

  RemoveEditShippingForms(shippingForms: ShippingForms) {
    shippingForms.isEdit = false;
    this.LoadShippingFormsList();
  }

  ActiveDeactiveShippingForms(shippingForms: ShippingForms) {

    if (shippingForms) {
      if (shippingForms.status) {
        shippingForms.status = false;
      }
      else {
        shippingForms.status = true;
      }
      this.EditShippingForms(shippingForms, true);
    }
  }

  OnOpenAddShippingFormsModal() {

    this.successMessage = this._commonDataService.localizationLabelList['save_success'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.errorMessage = this._commonDataService.localizationLabelList['save_error'];
    this.tableName = this._commonDataService.localizationLabelList['shipping_forms'];

    this.shippingForms = new ShippingForms(); this.shippingFormsFormGroup.reset();
    this._commonUIService.openModal('shipping-forms-modal');

    setTimeout(() => {
      this.nameField.nativeElement.focus();
    }, 100);

  }

  OnCloseShippingFormsModal(event) {
    (((((event.target.parentElement.parentElement).parentElement).parentElement).parentElement).parentElement).classList.remove('md-show');
  }

  SaveShippingForms(shippingForms: ShippingForms, event) {
    this.isDisabledShipping = true;

    if (this.shippingFormsFormGroup.invalid) {
      Object.keys(this.shippingFormsFormGroup.controls).forEach(field => {
        const control = this.shippingFormsFormGroup.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      this.isDisabledShipping = false;
      return;
    }

    this.OnCloseShippingFormsModal(event);

    this._apiService.post(API.shipping_forms.saveShippingForms, shippingForms, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {
            this._toastyService.success({
              title: this.tableName,
              msg: this.successMessage
            });
            this.LoadShippingFormsList();
          }
        },
        err => {
          this._toastyService.error({
            title: this.tableName,
            msg: err
          });
          this.isDisabledShipping = false;
        },
        () => {
          this.isDisabledShipping = false;
        }
      );
  }

  DeleteShippingForms(id: number) {
    this.isDisabledShipping = true;

    this.deleteMessage = this._commonDataService.localizationLabelList['delete_success'];
    this.deleteErrorMessage = this._commonDataService.localizationLabelList['delete_error'];
    this.tableName = this._commonDataService.localizationLabelList['shipping_forms'];

    if (id) {
      this._apiService.delete(API.shipping_forms.deleteShippingForms, id.toString())
        .subscribe(
          res => {
            if (res._statusCode == 200) {
              let index: number = this._shippingFormsList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._shippingFormsList.splice(index, 1);
                this._toastyService.success({
                  title: this.tableName,
                  msg: this.deleteMessage
                });
              }
            }
            if (res._statusCode == 409) {
              let index: number = this._shippingFormsList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._toastyService.warning({
                  title: this.tableName,
                  msg: this.deleteErrorMessage
                });
              }
            }
          },
          err => {
            this._toastyService.error({
              title: this.tableName,
              msg: err
            });
            this.isDisabledShipping = false;
          },
          () => {
            this.isDisabledShipping = false;
            this.shippingFormsList = this._shippingFormsList;
          }
        );
    }
  }
}

