import { PriceListViewModel } from '../../../modules/price-list/entities/price-list-view-model';
import { Component, OnInit, Input } from '@angular/core';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';

@Component({
  selector: 'app-price-list-report',
  templateUrl: './price-list-report.component.html'
})
export class PriceListReportComponent implements OnInit {

  @Input() priceListViewModel: PriceListViewModel;
  @Input() groupedArticlePriceList: any[];
  @Input() reportId: string;
  imageData: any;

  constructor(private _commonUIService: CommonUIService) { }

  ngOnInit() {
    this.LoadLabConfigurationList();
  }

  async LoadLabConfigurationList() {
    this.imageData = await this._commonUIService.LoadLabConfigurationList();
  }
}
