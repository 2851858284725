import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastData, ToastyService } from 'ng2-toasty';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import swal from 'sweetalert2';
import { IWorkComponent } from '../../entities/i-work-component';
import { MainWorkType } from '../../entities/main-work-type';
import { Note } from '../../entities/note';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { NoteService } from '../../services/note.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'notes',
  templateUrl: './notes.component.html'
})
export class NotesComponent implements OnInit, OnDestroy, IWorkComponent {

  // subscriptions
  private workService: Subscription;


  // lists
  noteTypeList: any[] = [];
  noteList: Note[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];
  mainWorkTypeList: MainWorkType[];

  // objects
  hasLoaded = true;
  selectedMainWorkType: MainWorkType;
  selectedSubWork: SubWork;
  selectedWork: Work;

  showPopUp = false;
  allClosed = false;

  constructor(private _workService: WorkService, private _apiService: ApiService, private changeDetector: ChangeDetectorRef,
    private __service: NoteService, public commonUiService: CommonUIService, private _commonDataService: CommonDataService,
    private _formBuilder: FormBuilder, private _toastyService: ToastyService,
    private _modalService: ModalService) {

    this.workService = this._workService.detailObject.share().subscribe((args) => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (args.invokeComponents.includes('Note') || args.invokeComponents.includes('*')) {
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this.allClosed = this.selectedMainWorkTypeList.every(x => x.isClosed);
          this.SyncNoteData();
        }
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void { this.workService.unsubscribe(); }

  //#region CRUD

  private async SyncNoteData() {
    this.noteList = [];
    try {
      this.hasLoaded = false;
      const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
      if (idList) {
        let noteList = await this.__service.GetNoteListByMainWorkTypeIdList(idList);
        noteList = noteList ? noteList : [];
        if (noteList.length > 0) {
          for (let index = 0; index < noteList.length; index++) {
            const note = noteList[index];
            const currentMainWorkType = this.selectedMainWorkTypeList.find(m => Number(m.id) === note.mainWorkTypeId);
            if (currentMainWorkType) {
              note.cssClass = currentMainWorkType.cssClass;
              note.order = currentMainWorkType.order;
              note.symbol = currentMainWorkType.symbol;
              if (currentMainWorkType.isClosed) {
                note.isClosed = true;
              } else {
                note.isClosed = false;
              }
            }
          }
        }
        this.noteList = noteList;
      }
    } catch (error) { console.log(error); }
    finally { this.hasLoaded = true; }
  }


  private DeleteNote(noteId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.delete(API.note.deleteNote, noteId.toString())
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }


  modalSaved(note: Note) {
    this.noteList.push(note);
  }

  OnOpenModal(modal: string) {
    let mwtList = [] as MainWorkType[];
    for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
      const mwt = this.selectedMainWorkTypeList[index];
      if (!mwt.isClosed) {
        mwtList.push(mwt);
      }
    }
    this.showPopUp = true;
    this._modalService.Invoke(modal, true, {
      selectedWork: this.selectedWork,
      selectedMainWorkTypeList: mwtList
    }, false);
  }

  //#endregion


  OnDeleteNote(note: Note, event: any) {
    if (note && event) {
      swal({
        title: this._commonDataService.localizationLabelList['notes'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      }).then((result) => {
        if (result.value) {
          this.DeleteNote(note.id)
            .then(response => {
              if (response['_statusCode']) {
                const index: number = this.noteList.findIndex(x => x.id === note.id);
                if (index !== -1) { this.noteList.splice(index, 1); }
                this._toastyService.success({
                  title: this._commonDataService.localizationLabelList['notes'],
                  msg: this._commonDataService.localizationLabelList['delete_success'],
                  showClose: true,
                  theme: 'default',
                  timeout: 5000,
                  onAdd: (toast: ToastData) => { },
                  onRemove: (toast: ToastData) => { }
                });
              } else {
                this._toastyService.error({
                  title: this._commonDataService.localizationLabelList['notes'],
                  msg: this._commonDataService.localizationLabelList['save_error'],
                  showClose: true,
                  theme: 'default',
                  timeout: 5000,
                  onAdd: (toast: ToastData) => { },
                  onRemove: (toast: ToastData) => { }
                });
              }
            });
        }
      });
    }
  }

  OnCloseModal(modal: string) {
    if (modal) {
      this.commonUiService.isPopupOpened = false;
      this.commonUiService.CloseModalEx(modal);
    }
  }

  //#endregion

  //#region Other


  //#endregion

}
