
export class Product {
  id: any;
  name: string;
  labSectionId: any;
  code: string;
  provManufacId?: any;
  materialGroupId: any;
  units?: string;
  ivatypeId?: any;
  sellingPrice?: number;
  activeLotId?: number;
  lotId?: any;
  expiryDate?: any;
  isProductGuideForStat?: boolean;
  isNotTakeAccountStock?: boolean;
  comment: string;
  barcode: string;
  inventoryFrequency: string;
  lastInventoryDate?: any;
  status: boolean;
  iva: number;
  warehouseSectionId?: any;
  createdDate: any;
  createdBy: number;
  modifiedDate: any;
  modifiedBy: number;

  imageName: string;
  imagePathUrl: string;

  ivatypeName: string;
  labSectionName: string;
  lotName: string;
  materialGroupName: string;
  manufacturerName: string;
  warehouseSectionName: string;

  // frontend use
  expiryDateView: any;
  lastInventoryDateView: any;
  productId: number;
  quantity: number;

  initialAmount: any;
  manufacturerReferenceNo: string;
  manufacturerFullTitle: string;

  unitTypeId?: number;
  providerReference: string;
  productReference: string;

  constructor() {
    this.status = true;
  }
}
