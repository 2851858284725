import { Injectable } from '@angular/core';
import { toFixedNumber } from './calculation';
import { CalculationService } from './calculation.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryNoteCalculationService extends CalculationService {

  constructor() { super(); }

  /**
    * total final amount = total with dsicount + total vat amount
    * @param totalWithDiscount 
    * @param totalVatAmount
    */
   getTotalFinalAmount(totalWithDiscount: number, totalVatAmount: number) {
    return toFixedNumber(totalWithDiscount + totalVatAmount);
  }

  /**
    * total with dsicount = sum amount - total discount amount
    * @param sumPrice 
    * @param totalDiscount
    */
   getTotalWithDiscountAmount(sumPrice: number, totalDiscount: number) {
    return toFixedNumber(sumPrice - totalDiscount);
  }
}
