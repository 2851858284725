import { Injectable } from '@angular/core';
import { DENTBOX_API_VERSION, DENTBOX_FILE_VERSION } from '../dentbox-paths';
import { RequestOptions, Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { HttpEvent, HttpHeaders, HttpParams, HttpRequest, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class DentboxApiService {

    API_URL: string;
    FILE_SERVER_URL: string;
    CHAT_URL: string;

    constructor(private http: Http, private httpClient: HttpClient,
        private router: Router) {
        this.setDentboxUrls();
    }

    async setDentboxUrls() {
        if (sessionStorage['ZGVudGJveA==']) {
            const dentboxStr = atob(sessionStorage['ZGVudGJveA==']);
            const dentbox = JSON.parse(dentboxStr);
            this.API_URL = dentbox.server_url + DENTBOX_API_VERSION;
            this.FILE_SERVER_URL = dentbox.file_server_url + DENTBOX_FILE_VERSION;
            this.CHAT_URL = dentbox.chat_url;
        } else {
            const Config = await this.getJSON();
            if (Config) {
                this.API_URL = Config.dentbox.server_url + DENTBOX_API_VERSION;
                this.FILE_SERVER_URL = Config.dentbox.file_server_url + DENTBOX_FILE_VERSION;
                this.CHAT_URL = Config.dentbox.chat_url;
                sessionStorage['ZGVudGJveA=='] = btoa(JSON.stringify(Config.dentbox));
            } else {
                sessionStorage.clear();
                localStorage.clear();
                throw new Error('Config file is not found!');
            }
        }
    }

    private getJSON() {
        return this.http.get('app/config.json')
            .map(res => res.json()).toPromise();
    }

    private getRequesetOptions(headersList: { key: string, value: any }[]) {
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': this.API_URL
        });
        const token: string = sessionStorage.getItem('ZGVudGJveC1hY2Nlc3MtdG9rZW4=');
        if (token) { headers.append('Authorization', atob(token)); }

        if (headersList.length > 0) {
            for (let index = 0; index < headersList.length; index++) {
                const header = headersList[index];
                headers.append(header.key, header.value);
            }
        }
        return new RequestOptions({ headers: headers });
    }

    private getFinalUri(url: string) {
        if (url.charAt(0) !== '/') {
            url = '/' + url;
        }
        return this.API_URL + url;
    }

    get(url: string, headersList: { key: string, value: any }[] = []) {
        const options = this.getRequesetOptions(headersList);
        const uri = this.getFinalUri(url);
        return this.http
            .get(uri, options)
            .pipe(map(response => response.json()), catchError(err => of(err)));
    }

    post(url: string, data?: any, mapJson = true, headersList: { key: string, value: any }[] = []) {
        const options = this.getRequesetOptions(headersList);
        const uri = this.getFinalUri(url);
        if (mapJson) {
            return this.http.post(uri, data, options)
                .pipe(map(response => response.json()), catchError(err => of('error')));
        } else {
            return this.http.post(uri, data, options);
        }
    }

    delete(url: string, id: string, headersList: { key: string, value: any }[] = []) {
        const options = this.getRequesetOptions(headersList);
        const uri = this.getFinalUri(url);
        return this.http.delete(uri + '/' + id, options)
            .pipe(map(response => (response).json()), catchError(err => of('error')));
    }

    put(url: string, data?: any, mapJson = true, headersList: { key: string, value: any }[] = []) {
        const options = this.getRequesetOptions(headersList);
        const uri = this.getFinalUri(url);
        if (mapJson) {
            return this.http.put(uri, JSON.stringify(data), options)
                .pipe(map(response => response.json()), catchError(err => of(err)));
        } else {
            return this.http.put(uri, data, options);
        }
    }

    upload(url: string, formData: FormData): Observable<HttpEvent<any>> {
        const headers: HttpHeaders = new HttpHeaders();
        const params = new HttpParams();
        const options = {
            params: params,
            reportProgress: true,
            headers: headers
        };
        const req = new HttpRequest('POST', this.API_URL + url, formData, options);
        return this.httpClient.request(req);
    }
}

