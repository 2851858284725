import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component,
  ElementRef, EventEmitter, Input, OnInit, Output, ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ToastData, ToastyService } from 'ng2-toasty';
import { Town } from 'src/app/modules/auxiliary/entities/town';
import { FreeBudget } from 'src/app/modules/budgets/entities/freeBudget';
import { Client } from 'src/app/modules/client/entities/client';
import { DateFormat } from 'src/app/modules/common/entities/date-format';
import { DropDownOption } from 'src/app/modules/common/entities/dropdown-option';
import { ModalNames } from 'src/app/modules/common/global';
import { NumberingProcess, NumberingProcessData } from 'src/app/shared/numbering-process/numbering-process';
import { NumberingProcessService } from '../../../../shared/numbering-process/numbering-process.service';
import { AuxiliaryService } from '../../../auxiliary/services/auxiliary.service';
import { PatientService } from '../../../client/services/patient.service';
import { ListNames } from '../../../common/global';
import { CommonFunctions } from '../../../common/services/common-functions';
import { CommonDataService } from '../../../common/services/common.data.service';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { DropdownService } from '../../../common/services/dropdown.service';
import { ModalService } from '../../../common/services/modal.service';
import { Delay, ShowInvalidFormControls, ValidateNgSelect } from '../../../common/services/validators';
import { WorkReservation } from '../../../reservations/entities/work-reservation';
import { WorkReservationService } from '../../../reservations/services/work-reservation.service';
import { Doctor } from '../../entities/doctor';
import { Patient } from '../../entities/patient';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { BudgetService } from '../../services/budget.service';
import { SubWorkService } from '../../services/sub-work.service';
import { WorkService } from '../../services/work.service';

@Component({
  selector: 'app-work-modal',
  templateUrl: './work-modal.component.html',
  styleUrls: ['./work-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkModalComponent implements OnInit, AfterViewInit {

  @Input() editPostalCodeComponent: any
  @ViewChild('patientDecsriptionInput')
  @ViewChild('clientInput') clientInputVariable: ElementRef;
  @ViewChild('lblpostalCode') lblpostcodeElement: ElementRef;
  private patientDecsriptionInput: any;

  showContent = false;
  isCollapsed = false;
  isShow = false;
  clientNovaSearch = true;
  workFormGroup: FormGroup;
  patientFormGroup: FormGroup;

  work: Work;
  _existingWork: Work;
  subWork: SubWork;

  patient: Patient;
  defaultDoctor: any;
  generateNumber = '';

  wNumberingProcess: NumberingProcess;
  wSeries: {
    id: number,
    type: 'Work' | 'Delivery Note' | 'Bill' | 'Budget' | 'Receipt',
    series: string
  };
  wMaxSize = 5;
  currentNPData: NumberingProcessData;
  inputDatepickerTimer: any;

  clientAutoCompleteList = [];
  patientAutoCompleteList = [];
  doctorAutoCompleteList = [];
  townListDropdownList = [];
  patientList = [];
  defaultClient: Client;
  clientDropdownFilter: DropDownOption[] = [];
  lastPrescriptionMonth = '';
  monthIndex = -1;
  lastPrescriptionYear: number;
  doctorList: Doctor[] = [];
  townList: Town[] = [];
  townPostalCodeList: Town[] = [];
  editTown: Town;
  isClientInValid = false;
  isPatientInValid = false;
  isDoctorInValid = false;
  doctor = { id: null, name: null };
  isEditModelButtondisabled :boolean =false;

  @Output() workSaved = new EventEmitter<{
    work: Work,
    subWork: SubWork,
    isEdit: boolean,
    existing: boolean
  }>();
  @Output() lastPrescription = new EventEmitter<{
    work: Work,
    subWork: SubWork,
    isEdit: boolean,
    existing: boolean
  }>();
  @Output() filterChanged = new EventEmitter<any>();
  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  reservationList: WorkReservation[] = [];
  freeBudgetList: FreeBudget[];

  constructor(
    private _formBuilder: FormBuilder,
    public commonDataService: CommonDataService,
    private _auxiliaryService: AuxiliaryService,
    private _modalService: ModalService,
    private _dropdownService: DropdownService,
    private _workService: WorkService,
    public _commonUiService: CommonUIService,
    private _numberingProcessService: NumberingProcessService,
    public _reservationService: WorkReservationService,
    private _toastyService: ToastyService,
    private _patientService: PatientService,
    private _subWorkService: SubWorkService,
    private _budgetService: BudgetService,
    private cdref: ChangeDetectorRef) {
    this.work = new Work();
    this.subWork = new SubWork();
    this._existingWork = new Work();
    this.patient = new Patient();
    this.InitFormGroups();
    this.showContent = true;
    this.defaultClient = new Client();
    this.editTown = new Town();
    this._workService.LoadDefaultClient()
      .then(client => {
        if (client) { this.defaultClient = client; }
      });

    this._auxiliaryService.onUpdateTown
      .subscribe((arg) => {
        if (arg && arg.model == 'gen') {
          this.patientFormGroup.controls.postalCode.setValue(arg.pc);
          this.patientFormGroup.controls.townId.setValue(arg.description + ' - ' + arg.pc);
          this.patient.postalCode = arg.pc;
          this.lblpostcodeElement.nativeElement.click();
        }
        if (document.querySelector('#patient-modal')) {
          document.querySelector('#patient-modal').classList.remove('work-modal--index');
        }
      });
  }

  ngOnInit() { }

  async ngAfterViewInit(): Promise<void> {

    if (this._modalService.modal === ModalNames.WorkModal) {
      this.LoadPopupData();
      if (!this._modalService.transferable.isEdit) {
        await this.OnOpenModal();
      } else {
        this.work = this._modalService.transferable.selectedWork as Work;
        this.subWork = this._modalService.transferable.selectedSubWork as SubWork;
        
        if (this.work && this.subWork) {
          this.workFormGroup.controls.clientName.setValue(this.work.clientName);
          this.workFormGroup.controls.doctorName.setValue(this.subWork.doctorName);
          this.CheckDeliveryNotesAndInvoices(this.subWork.workId);
          if (this.work.clientId) {
            let doctorList = await this._workService.GetDoctorListByClientId(this.work.clientId);
            this.doctorList = doctorList ? doctorList.filter(p => p.status) : [];
            if (this.doctorList.length > 0) {
              if (this.subWork.doctorId) {
                const doctor = this.doctorList.find(d => d.id === this.subWork.doctorId);
                if (doctor) {
                  this.doctor.id = doctor.id;
                  this.doctor.name = doctor.name;
                }
              }
              //  this.doctorList.forEach(t => t.doctorName = t.code != null ? t.name + ' - ' + t.code : t.name);
              this.doctorAutoCompleteList = this.commonDataService.GetAutoCompleteList(this.doctorList, 'name');
            } else {
              this.workFormGroup.controls.doctorName.disable();
            }
            if (!this.clientNovaSearch) {
              this.workFormGroup.controls.clientId.setValue(this.work.clientId.toString());
            }
            // set patient details
            this.patientAutoCompleteList = CommonFunctions.GetPatientAutoCompleteList(this._dropdownService.patientList
              .filter(a => a.clientId === Number(this.work.clientId)), 'name', 'dateOfBirth');
            const selectPatient = this.patientAutoCompleteList.find(o => o.id === Number(this.work.patientId));
            if (selectPatient) { this.workFormGroup.controls.patientName.setValue(selectPatient.value); }
            this.clientInputVariable.nativeElement.click();
            this.onCheckReservationExist();
            this.ShowExisingFreeBudgetMessage(Number(this.work.clientId), Number(this.work.patientId));
          } 
        }
        this.cdref.markForCheck();
      }
    }
  }

  private InitFormGroups() {
    this.workFormGroup = this._formBuilder.group({
      name: ['', []],
      workCode: [],
      patientIdList: [],
      patientId: [],
      patientName: [],
      clientId: [],
      clientName: [],
      doctorId: [],
      doctorName: [],
      generateNo: ['', [Validators.required]]
    });
    this.patientFormGroup = this._formBuilder.group({
      name: ['', [Validators.required]],
      surnames: [],
      historyNumber: [],
      dateOfBirth: [],
      dateOfBirthView: [null],
      clientName: [],
      nif: [],
      address: [],
      postalCode: ['', [Validators.maxLength(10)]],
      townId: [],
      provinceName: [],
      countryName: [],
      notes: [],
      email: ['', [Validators.email]]
    });
  }

  private CheckDeliveryNotesAndInvoices(workId)
  {
    this._workService.CheckDeliveryNotesAndInvoices(workId).then(res => {
      if(res)
      {
        this.isEditModelButtondisabled=true;
        this.workFormGroup.controls['name'].disable();
        this.workFormGroup.controls['doctorName'].disable();
        this.workFormGroup.controls['patientName'].disable();
        this.workFormGroup.controls['clientName'].disable();
      }else{
        this.workFormGroup.controls['name'].enable();
        this.workFormGroup.controls['doctorName'].enable();
        this.workFormGroup.controls['patientName'].enable();
        this.workFormGroup.controls['clientName'].enable();
        this.isEditModelButtondisabled=false;
      }
       
    });
  }

  private LoadPopupData() {
    this.PopulateClientDropdown();
    const list = this._dropdownService.townList.filter(x => x.description != null || x.description != undefined);
    this.townListDropdownList = this.commonDataService.GetDropDownList(list, 'description');
    this.patientAutoCompleteList = [];
    this.cdref.markForCheck();
  }

  public PopulateClientDropdown() {
    this.clientDropdownFilter = [];
    if (this._dropdownService.clientList.length > 0) {
      this._dropdownService.clientList = this._dropdownService.clientList.filter(x => x.status);
      if (this._dropdownService.clientList.length <= 10) {
        // when not using Nova search
        this.clientNovaSearch = false;
        this._dropdownService.clientList.sort((a, b) => {
          return a.id - b.id;
        });
        this.clientDropdownFilter.push(new DropDownOption('0', this.commonDataService.localizationLabelList['select'], false));
        this._dropdownService.clientList.forEach((element) => {
          const option = new DropDownOption(element.id.toString(), element.name, false);
          this.clientDropdownFilter.push(option);
        });
        this.clientDropdownFilter = CommonFunctions.Sort(this.clientDropdownFilter, 'label', false);
      } else {
        this.clientAutoCompleteList = this.commonDataService.GetAutoCompleteList(this._dropdownService.clientList, 'name');
      }
    }
  }

  async OnOpenModal() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      this.workFormGroup.reset();
      await Delay(600);
      if (this.clientNovaSearch) {
        this.workFormGroup.controls.clientName.setValidators(Validators.required);
        this.workFormGroup.controls.clientId.clearValidators();
      } else {
        this.workFormGroup.controls.clientId.setValidators(ValidateNgSelect);
        this.workFormGroup.controls.clientName.clearValidators();
      }
      // this._commonUiService.isSpinnerVisible = true;
      this.work = new Work();
      this.work.clientId = '0';
      this.work.patientId = '0';
      this.work.doctorId = '';
      this.workFormGroup.controls.patientName.setValue('');
      this.workFormGroup.controls.doctorName.setValue('');
      if (this.clientNovaSearch) {
        this.workFormGroup.controls.clientName.setValue('');
      }
      this.workFormGroup.controls.doctorName.disable();
      if (Object.keys(this.defaultClient).length !== 0) {
        this.work.clientId = this.defaultClient.id;
        if (this.clientNovaSearch) {
          this.workFormGroup.controls.clientName.setValue(this.defaultClient.name);
        } else {
          this.workFormGroup.controls.clientId.setValue(this.defaultClient.id.toString());
        }
        this.LoadDoctorList(Number(this.defaultClient.id));
      }
      this._dropdownService.patientList = this._dropdownService.patientList ?
        this._dropdownService.patientList.filter(x => x.status) : null;
      this.patientAutoCompleteList = CommonFunctions
        .GetPatientAutoCompleteList(this._dropdownService.patientList
          .filter(a => a.clientId === Number(this.work.clientId)), 'name', 'dateOfBirth');

      this.clientInputVariable.nativeElement.click();
      // set generated number
      const series = await this._workService.GetSeriesBySeriesType('Work');
      if (series) {
        this.wNumberingProcess = new NumberingProcess({
          isNextAvailableNumber: true,
          nextAvailableNumber: series.lastNumber,
          isAnother: false,
          isNoNumberAssigned: false,
          isOtherAvailableNumbers: false,
          anotherNumber: 1,
          otherAvailableNumber: 0
        });
        this.generateNumber = '';
        const genNumberObject = this._numberingProcessService
          .GetGeneratedNumber(this.wNumberingProcess, this.wMaxSize,
            { id: series.id, type: 'Work', series: series.series });
        if (genNumberObject) {
          this.work.generateNo = genNumberObject.generateNumber;
          this.generateNumber = genNumberObject.generateNumber
        }
      }
      this.editTown = new Town();
    } catch (error) { console.log(error); } finally {
      this.cdref.markForCheck();
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  async OnChangeClient(clientItem: { label: string, value: number }) {
    this.patientAutoCompleteList = [];
    if (clientItem) {
      this.isClientInValid = false;
      if (clientItem.value > 0 && Number(this.work.patientId) > 0 && !this.work.id) {
        await this.ShowExisingWorkMessage(Number(clientItem.value), this.work.patientId);
        this.ShowExisingFreeBudgetMessage(Number(clientItem.value), Number(this.work.patientId));
      }
      this.LoadDoctorList(Number(clientItem.value));
      this.work.clientId = clientItem.value;
      this.work.clientName = clientItem.label;
      this.work.patientId = 0;
      this.work.patientName = '';
      this.workFormGroup.controls.patientName.setValue('');
      this.workFormGroup.controls.patientId.setValue(0);
      this._dropdownService.patientList = this._dropdownService.patientList ?
        this._dropdownService.patientList.filter(x => x.status) : null;
      this.patientAutoCompleteList = CommonFunctions
        .GetPatientAutoCompleteList(this._dropdownService.patientList
          .filter(a => a.clientId === Number(this.work.clientId)), 'name', 'dateOfBirth');
      this.onCheckReservationExist();
    }
    this.cdref.markForCheck();
  }

  OnClearClient() {
    this.isClientInValid = false;
    this.work.clientId = 0;
    this.work.clientName = '';
    this._dropdownService.patientList = this._dropdownService.patientList ? this._dropdownService.patientList.filter(x => x.status) : null;
    this.patientAutoCompleteList = CommonFunctions.GetPatientAutoCompleteList(this._dropdownService.patientList
      .filter(a => a.clientId === Number(this.work.clientId)), 'name', 'dateOfBirth');

    this.work.patientId = 0;
    this.work.patientName = '';
    this.workFormGroup.controls.patientName.setValue('');
    this.workFormGroup.controls.patientId.setValue(0);

    this.doctorAutoCompleteList = [];
    this.workFormGroup.controls.doctorName.setValue('');
    this.workFormGroup.controls.doctorId.setValue(null);
    this.workFormGroup.controls.doctorName.disable();
    this.cdref.markForCheck();
  }

  async ShowExisingWorkMessage(clientId: number, patientId: number) {
    this._existingWork = await this._workService.GetExistingWork(clientId, patientId); // returns null, when it doesn't exist
    this.lastPrescriptionMonth = '';
    this.monthIndex = -1;
    if (this._existingWork != null) {
      this.lastPrescriptionMonth = moment(this._existingWork.lastPrescriptionDate).format('MMMM');
      this.lastPrescriptionMonth = this.lastPrescriptionMonth.toLocaleLowerCase();
      this.lastPrescriptionYear = new Date(this._existingWork.lastPrescriptionDate).getFullYear();
      this.monthIndex = new Date(this._existingWork.lastPrescriptionDate).getMonth();
      this.work.generateNo = this._existingWork.generateNo;
      this.workFormGroup.controls.generateNo.setValue(this._existingWork.generateNo);
      this._toastyService.warning({
        title: this.commonDataService.localizationLabelList['work'],
        msg: this.commonDataService.localizationLabelList['work_already_exist'],
        showClose: true,
        theme: 'default',
        timeout: 5000,
        onAdd: (toast: ToastData) => { },
        onRemove: (toast: ToastData) => { }
      });
    } else {
      this._existingWork = new Work();
      this.work.generateNo = this.generateNumber;
      this.workFormGroup.controls.generateNo.setValue(this.generateNumber);
    }
    this.cdref.markForCheck();
  }

  async LoadDoctorList(clientId: number) {
    this.defaultDoctor = {};
    if (clientId > 0) {
      let doctorList = await this._workService.GetDoctorListByClientId(clientId);
      doctorList = doctorList ? doctorList.filter(c => c.status) : [];
      if (doctorList.length > 0) {
        this.workFormGroup.controls.doctorName.enable();
        this.doctorAutoCompleteList = this.commonDataService
          .GetAutoCompleteList(doctorList, 'name');
        this.defaultDoctor = doctorList.find(a => a.isDefault === true);
        if (this.defaultDoctor) {
          this.workFormGroup.controls.doctorName.setValue(this.defaultDoctor.name);
          this.doctor.id = this.defaultDoctor.id;
          this.doctor.name = this.defaultDoctor.name;
        } else {
          this.workFormGroup.controls.doctorName.setValue('');
          this.workFormGroup.controls.doctorId.setValue(null);
          this.doctor.id = null;
          this.doctor.name = null;
        }
        this.clientInputVariable.nativeElement.click();
      } else {
        this.doctor.id = null;
        this.doctor.name = null;
        this.workFormGroup.controls.doctorId.setValue(null);
        this.workFormGroup.controls.doctorName.setValue('');
        this.workFormGroup.controls.doctorName.disable();
      }
    }
    this.cdref.markForCheck();
  }

  async OnChangeDoctor(doctorItem: { display: string, value: number, id: number }) {
    this.isDoctorInValid = false;
    if (doctorItem) {
      this.doctor.id = doctorItem.id;
      const doctor = this.doctorList.find(x => x.id == Number(doctorItem.id))
      this.doctor.name = doctor && doctor.name ? doctor.name : '';
    } else {
      this.doctor.id = null;
      this.doctor.name = null;
      this.workFormGroup.controls.doctorId.setValue(null);
      this.workFormGroup.controls.doctorName.setValue('');
    }
    this.cdref.markForCheck();
  }

  async OnChangePatient(patientItem: { display: string, value: number, id: number }) {
    if (patientItem) {
      this.isPatientInValid = false;
      if (patientItem.id > 0 && this.work.clientId > 0 && !this.work.id) {
        this.ShowExisingWorkMessage(this.work.clientId, patientItem.id);
        this.ShowExisingFreeBudgetMessage(Number(this.work.clientId), Number(patientItem.id));
      }
      this.work.patientId = patientItem.id;
      this.work.patientName = patientItem.display;
      this.onCheckReservationExist();
    }
    this.cdref.markForCheck();
  }

  OnOpenPatientModal(modal: string) {
    try {
      if (this.workFormGroup.invalid) { ShowInvalidFormControls(this.workFormGroup); return; }
      this._commonUiService.isSpinnerVisible = true;
      this.isCollapsed = false;
      this.patientFormGroup.reset();
      this.patient = new Patient();
      this.editTown = new Town();
      this.work.patientId = '0';
      if (this.clientNovaSearch) {
        this.patient.clientName = this.workFormGroup.controls.clientName.value;
      } else {
        const clientddl = this.clientDropdownFilter.filter(o => o.value == this.work.clientId.toString());
        if (clientddl.length > 0) { this.patient.clientName = clientddl[0].label; }
      }
      this.patient.clientId = (Number(this.work.clientId) > 0) ? Number(this.work.clientId) : null;
      this.patientFormGroup.controls.name.setValue(this.workFormGroup.value.patientName);
      if (this.patient.clientName) { this.patientFormGroup.controls.clientName.setValue(this.patient.clientName); }
      this._commonUiService.openModal(modal);
    } catch (error) { console.log(error); }
    finally {
      this.cdref.markForCheck();
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  OnOpenEditPatientModal(modal: string) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      this.isCollapsed = true;
      this.patientFormGroup.reset();
      this.patient = new Patient();
      this.editTown = new Town();
      if (this.work.patientId) {
        this._patientService.GetPatientById(this.work.patientId).then(patient => {
          console.log(patient);
          if (patient) {
            this.patient.id = patient.id;
            this.patient.clientId = patient.clientId;
            this.patientFormGroup.controls.name.setValue(patient.name);
            this.patientFormGroup.controls.surnames.setValue(patient.surnames);
            this.patientFormGroup.controls.historyNumber.setValue(patient.historyNumber);
            this.patientFormGroup.controls.clientName.setValue(patient.clientName);
            this.patientFormGroup.controls.nif.setValue(patient.nif);
            this.patientFormGroup.controls.address.setValue(patient.address);
            this.patientFormGroup.controls.provinceName.setValue(patient.provinceName);
            this.patientFormGroup.controls.countryName.setValue(patient.countryName);
            this.patientFormGroup.controls.notes.setValue(patient.notes);
            this.patientFormGroup.controls.email.setValue(patient.email);
            if (patient.townId) {
              this.patient.townId = patient.townId;
              if (patient.town) {
                this.patientFormGroup.controls.townId.setValue(patient.town.descriptionWithPostcode);
                this.patientFormGroup.controls.postalCode.setValue(patient.town.postcode);
                this.editTown = patient.town;
              }
            } else {
              this.patientFormGroup.controls.townId.setValue('');
              this.patientFormGroup.controls.postalCode.setValue('');
            }
            this.lblpostcodeElement.nativeElement.click();
            this.patient.dateOfBirthView = patient.dateOfBirth ? this._commonUiService.ConvertToPickerDateFormat(patient.dateOfBirth) : null;
          }
          this._commonUiService.isSpinnerVisible = false;
        });
      }
      this._commonUiService.openModal(modal);
    } catch (ex) {
      console.log(ex);
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  async OnSavePatient(modal: string) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (this.patientFormGroup.invalid) { ShowInvalidFormControls(this.patientFormGroup); return; }
      if (this.patient) {
        if (this.patient.dateOfBirthView) {
          this.patient.dateOfBirth = new Date(this._commonUiService.ConvertDateFormatToString(this.patient.dateOfBirthView))
            .toISOString();
        }

        if (this.patient.id > 0) {
          const response = await this._patientService.UpdatePatient(this.patient);
          if (response) {
            const body = JSON.parse(response['_body']);
            if (body['_isSuccsess']) {
              this._toastyService.success({
                title: this.commonDataService.localizationLabelList['patient'],
                msg: this.commonDataService.localizationLabelList['update_success'],
                showClose: true,
                theme: 'default',
                timeout: 5000
              });
              await this._dropdownService.LoadListAsync([ListNames.PatientList]);
              this.patientAutoCompleteList = CommonFunctions.GetPatientAutoCompleteList(this._dropdownService.patientList, 'name', 'dateOfBirth');
              this.work.patientId = this.patient.id;
              this.work.patientName = this.patient.name;
              let viewDate = null;
              if (this.patient.dateOfBirthView) {
                viewDate = this.setPatientDOB(this.patient.dateOfBirthView);
              }
              const patientSurname = this.patient.surnames ? ' ' + this.patient.surnames : '';
              const patientHistoryNo = this.patient.historyNumber ? ' - ' + this.patient.historyNumber : '';
              this.workFormGroup.controls.patientName
                .setValue(this.patient.name + patientSurname + (viewDate ? ' - ' + viewDate : '') + patientHistoryNo);
              // change patient
              this.work.patientId = this.patient.id;
              this.work.patientName = this.patient.name;
              this.cdref.markForCheck();

              this.clientInputVariable.nativeElement.click();

            } else {
              this._toastyService.error({
                title: this.commonDataService.localizationLabelList['patient'],
                msg: this.commonDataService.localizationLabelList['update_error'],
                showClose: true,
                theme: 'default',
                timeout: 5000
              });
            }
            this._commonUiService.CloseModalEx(modal);
          }
        } else {
          const response = await this._patientService.AddPatient(this.patient);
          if (response) {
            const body = JSON.parse(response['_body']);
            if (body['_isSuccsess']) {
              this._toastyService.success({
                title: this.commonDataService.localizationLabelList['patient'],
                msg: this.commonDataService.localizationLabelList['save_success'],
                showClose: true,
                theme: 'default',
                timeout: 5000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
              const newPatient = body['_data'] as Patient;
              await this._dropdownService.LoadListAsync([ListNames.PatientList]);
              this.patientAutoCompleteList = CommonFunctions.GetPatientAutoCompleteList(this._dropdownService.patientList, 'name', 'dateOfBirth');
              if (newPatient) {
                const newPatientItem = this.patientAutoCompleteList.find(p => Number(p.id) === newPatient.id);
                this.work.patientId = newPatientItem.id;
                this.work.patientName = newPatient.name;
                this.workFormGroup.controls.patientName.setValue(newPatientItem.value);
                // this.patientDecsriptionInput.nativeElement.click();
                this.clientInputVariable.nativeElement.click();
                this.OnChangePatient(newPatientItem);
              }
            } else {
              this._toastyService.error({
                title: this.commonDataService.localizationLabelList['patient'],
                msg: this.commonDataService.localizationLabelList['save_error'],
                showClose: true,
                theme: 'default',
                timeout: 5000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
            }
            this._commonUiService.CloseModalEx(modal);
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this.cdref.markForCheck();
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  async OnClickSaveWork(event: any) {
    try {
      this.isClientInValid = false;
      this.isPatientInValid = false;
      this.isDoctorInValid = false;
      if (this.workFormGroup.invalid) { ShowInvalidFormControls(this.workFormGroup); return; }
      const clientId = this.workFormGroup.controls.clientId.value;
      const clientName = this.workFormGroup.controls.clientName.value;

      let patientName = this.workFormGroup.controls.patientName.value;
      const doctorName = this.workFormGroup.controls.doctorName.value ?
        this.workFormGroup.controls.doctorName.value : '';
      if (clientId || clientName) {
        const client = this._dropdownService.clientList.find(x => x.id === Number(clientId) || x.name === clientName);
        if (!client) {
          this.isClientInValid = true;
          return;
        } else {
          let doctorList = await this._workService.GetDoctorListByClientId(this.work.clientId);
          this.doctorList = doctorList ? doctorList.filter(p => p.status) : [];
        }
      }
      if (patientName && patientName.length > 0) {
        patientName = patientName.replace(/ /g, '');
        const patient = this.patientAutoCompleteList.find(x => x.value != null && x.value != undefined
          && x.value.replace(/ /g, '') === patientName);
        if (!patient) {
          this.isPatientInValid = true;
          return;
        }
      }
      if (doctorName.length > 0) {
        const doctor = this.doctorList.find(x => x.name === doctorName);
        if (!doctor) {
          this.isDoctorInValid = true;
          return;
        }
      }
      if (event && this.work) {
        this._commonUiService.isSpinnerVisible = true;
        let transferable: {
          work: Work;
          subWork: SubWork;
          isEdit: boolean;
          existing: boolean;
        };
        if (this.work.id) { // edit
          this.work.patientId = this.workFormGroup.controls.patientName.value ?
            this.work.patientId : null;
          const response = await this._workService.EditWork(this.work);
          if (response) {
            const body = JSON.parse(response['_body']);
            if (body['_statusCode'] === 200) {
              const worktemp = body['_data'] as Work;
              // worktemp.doctorName = worktemp.doctorName ? worktemp.doctorName : this.work.doctorName;
              transferable = {
                work: worktemp,
                subWork: undefined,
                isEdit: true,
                existing: false
              };

              if (this.subWork && this.doctor) {
                this.subWork.doctorId = this.doctor.id;
                const subWorkResponse = await this._subWorkService
                  .EditSubWork(this.subWork);
                if (subWorkResponse) {
                  const swBody = JSON.parse(response['_body']);
                  if (swBody['_statusCode'] === 200) {
                    transferable.subWork = this.subWork;
                    if (transferable.subWork) {
                      transferable.subWork.doctorId = this.doctor.id;
                      transferable.subWork.doctorName = this.doctor.name;
                    }
                  }
                }
              }
              this._toastyService.success({
                title: this.commonDataService.localizationLabelList['works'],
                msg: this.commonDataService.localizationLabelList['save_success'],
                showClose: true,
                theme: 'default',
                timeout: 5000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
            } else {
              this._toastyService.error({
                title: this.commonDataService.localizationLabelList['works'],
                msg: this.commonDataService.localizationLabelList['save_error'],
                showClose: true,
                theme: 'default',
                timeout: 5000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
            }
          }
        } else {
          // check already existing client - patient combination

          if (this._existingWork.id) {

            // search for the particular work in left bar
            this.filterChanged.emit({ filter: 'search', value: this._existingWork.generateNo });
            // add new subwork for the work
            const subWork = new SubWork();
            subWork.situation = 'Nuevo';
            subWork.createdDate = moment().format('YYYY-MM-DD');
            subWork.status = true;
            subWork.workId = this._existingWork.id;
            subWork.subWorkStatus = 'Pendiente';
            subWork.doctorId = this.doctor.id ? this.doctor.id : null;

            // get count of sub works from api
            const count = await this._subWorkService.GetSubWorkCountByWorkId(this._existingWork.id);
            subWork.generateNo = count ? (count + 1) : 1;

            const response = await this._subWorkService.AddSubWork(subWork);
            if (response) {
              const body = JSON.parse(response['_body']);
              if (body['_statusCode'] === 200) {
                // get full work object from work list
                // (because it has full object data including doctor, patient and client names)
                this._toastyService.success({
                  title: this.commonDataService.localizationLabelList['sub_work'],
                  msg: this.commonDataService.localizationLabelList['save_success'],
                  showClose: true,
                  theme: 'default',
                  timeout: 5000,
                  onAdd: (toast: ToastData) => { },
                  onRemove: (toast: ToastData) => { }
                });
                transferable = {
                  work: this._existingWork,
                  subWork: body['_data'] as SubWork,
                  isEdit: false,
                  existing: true
                };
              } else {
                this._toastyService.error({
                  title: this.commonDataService.localizationLabelList['sub_work'],
                  msg: this.commonDataService.localizationLabelList['save_error'],
                  showClose: true,
                  theme: 'default',
                  timeout: 5000,
                  onAdd: (toast: ToastData) => { },
                  onRemove: (toast: ToastData) => { }
                });
              }
            }
          } else {
            this.work.workStatus = 'Pendiente';
            this.work.status = true;
            this.work.patientId = this.workFormGroup.controls
              .patientName.value ? this.work.patientId : null;

            const response = await this._workService.AddWork(this.work);
            if (response) {
              const body = JSON.parse(response['_body']);
              switch (body['_statusCode']) {
                case 200:
                  const subWork: SubWork = new SubWork();
                  subWork.createdDate = moment().format('YYYY-MM-DD');
                  subWork.situation = 'Nuevo';
                  subWork.status = true;
                  subWork.subWorkStatus = 'Pendiente';
                  subWork.workId = (body['_data']).id;
                  subWork.generateNo = 1;
                  subWork.doctorId = this.doctor.id ? this.doctor.id : null;

                  const subWorkResponse = await this._subWorkService.AddSubWork(subWork);
                  if (subWorkResponse) {
                    const subWorkBody = JSON.parse(subWorkResponse['_body']);
                    if (subWorkBody['_statusCode'] === 200) {
                      transferable = {
                        work: body['_data'] as Work,
                        subWork: subWorkBody['_data'] as SubWork,
                        isEdit: false,
                        existing: false
                      };
                      this._toastyService.success({
                        title: this.commonDataService.localizationLabelList['sub_work'],
                        msg: this.commonDataService.localizationLabelList['save_success'],
                        showClose: true,
                        theme: 'default',
                        timeout: 5000,
                        onAdd: (toast: ToastData) => { },
                        onRemove: (toast: ToastData) => { }
                      });
                    } else {
                      this._toastyService.error({
                        title: this.commonDataService.localizationLabelList['sub_work'],
                        msg: this.commonDataService.localizationLabelList['save_error'],
                        showClose: true,
                        theme: 'default',
                        timeout: 5000,
                        onAdd: (toast: ToastData) => { },
                        onRemove: (toast: ToastData) => { }
                      });
                    }
                  }
                  this._toastyService.success({
                    title: this.commonDataService.localizationLabelList['works'],
                    msg: this.commonDataService.localizationLabelList['save_success'],
                    showClose: true,
                    theme: 'default',
                    timeout: 5000,
                    onAdd: (toast: ToastData) => { },
                    onRemove: (toast: ToastData) => { }
                  });
                  break;
                case 409:
                  this._toastyService.warning({
                    title: this.commonDataService.localizationLabelList['works'],
                    msg: this.commonDataService.localizationLabelList['number_is_already_in_use'],
                    showClose: true,
                    theme: 'default',
                    timeout: 5000,
                    onAdd: (toast: ToastData) => { },
                    onRemove: (toast: ToastData) => { }
                  });
                  break;
                default:
                  this._toastyService.error({
                    title: this.commonDataService.localizationLabelList['works'],
                    msg: this.commonDataService.localizationLabelList['save_error'],
                    showClose: true,
                    theme: 'default',
                    timeout: 5000,
                    onAdd: (toast: ToastData) => { },
                    onRemove: (toast: ToastData) => { }
                  }); break;
              }
              this._commonUiService.isSpinnerVisible = false;
            }
          }
        }
        this.showContent = false;

        const patient = this._dropdownService.patientList
          .find(x => x.id === Number(this.work.patientId));
        if (patient != null && patient.id > 0) {
          const surName = (patient.surnames != null) ? patient.surnames : '';
          const name = patient.name + ' ' + surName;
          this.work.patientName = name;

          this.work.historyNumber = patient.historyNumber;
        }
        if (transferable) {
          if (!transferable.work.clientName) {
            transferable.work.clientName = this.workFormGroup.controls.clientName.value;
          }

          transferable.work.patientName = this.work.patientName;
          transferable.work.historyNumber = this.work.historyNumber;
        }
        this.workSaved.emit(transferable);
      }
    } catch (error) { console.log(error); }
    finally {
      this.cdref.markForCheck();
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  async OnClickLastPrescription(event: any) {
    if (event) {
      const patient = this._dropdownService.patientList.find(x => x.id === Number(this.work.patientId));
      if (patient != null && patient.id > 0) {
        // this._existingWork.patientName = patient.name;
        const surName = (patient.surnames != null) ? patient.surnames : '';
        const name = patient.name + ' ' + surName;
        this._existingWork.patientName = name;

        this._existingWork.historyNumber = patient.historyNumber;
      }
      if (this._existingWork.clientName == null) {
        this._existingWork.clientName = this.workFormGroup.controls.clientName.value;
      }
      if (this._existingWork.doctorName == null) {
        this._existingWork.doctorName = this.workFormGroup.controls.doctorName.value;
      }
      this.work = this._existingWork;

      const transferable = {
        work: this._existingWork,
        subWork: this._existingWork.lastSubWork,
        isEdit: false,
        existing: true
      };
      transferable.work.patientName = this.work.patientName;
      transferable.work.historyNumber = this.work.historyNumber;
      this.lastPrescription.emit(transferable);
    }

  }

  OnClose() {
    this.showContent = false;
    this.closeModel.emit();
  }

  OnClosePatient(modal: string) {
    this._commonUiService.CloseModalEx(modal);
  }

  onClickdatepicker() {
    this.inputDatepickerTimer = setTimeout(() => {
      if (!this.inputDatepickerTimer) {
        return;
      }
      // add here if any codes needs to execute
    }, 300);
  }

  onDoubleClickdatepicker(event: any) {
    clearTimeout(this.inputDatepickerTimer);
    this.inputDatepickerTimer = undefined;
    event.toggle();
  }

  onClearPatient() {
    this._existingWork = new Work();
    this.freeBudgetList = [];
    this.reservationList = [];
  }

  DateOfBirthKeyUp() {
    const date = this.patientFormGroup.get('dateOfBirthView').value;
    if (date) {
      const newDate = this._commonUiService.ConvertStringToNgbDate(date);
      if (newDate) {
        this.patientFormGroup.get('dateOfBirthView').setValue(newDate);
      }
    }
  }

  // Reservation
  async OnOpenReservationsDetails() {
    this._commonUiService.OpenBasicModal('add-associatedresr-modal');
  }
  async onCheckReservationExist() {
    this.reservationList = [];
    if (this.work) {
      if (this.work.clientId && this.work.patientId) {
        this._reservationService.GetWorkReservationForClientPatient(this.work.clientId, this.work.patientId)
          .then(list => {
            this.reservationList = list;
            this.cdref.markForCheck();
          });
      }
    }
  }

  openFreeBudgetDetails() {
    this._commonUiService.OpenBasicModal('associated-free-budget-modal');
  }

  async ShowExisingFreeBudgetMessage(clientId: number, patientId: number) {
    this.freeBudgetList = [];
    await this._budgetService.GetFreeBudgetListByClientPatientId(clientId, patientId)
      .then(list => {
        this.freeBudgetList = list;
        this.cdref.markForCheck();
      });
  }


  /* patient modal town */
  OnChangeTown(town: Town) {
    if (town) {
      this.patient.townId = Number(town.id);
      this.patient.provinceName = town.provinceName;
      this.patient.countryName = town.countryName;
      this.patient.postalCode = town.postcode;
      this.patientFormGroup.controls.postalCode.setValue(town.postcode);
      this.editTown = town;
    }
  }
  onSkipCharCountExceedsTown() {
    this.searchTowns(this.patientFormGroup.controls.townId.value);
  }

  async searchTowns(query = '') {
    const townList = await this._dropdownService.SearchTowns(query);
    this.townList = townList ? townList : [];
    this.townList = CommonFunctions.Sort(this.townList.filter(x => x.description), 'description');
    this.cdref.markForCheck();
  }

  async OnOpenAddWorkPcModal(modal: string) {
    if (this.editTown) {
      this.editTown.id = this.patient.townId;
      this.editTown.postcode = this.patientFormGroup.controls.postalCode.value;
      this.editTown.provinceName = this.patient.provinceName
      this.editTown.countryName = this.patient.countryName
      this.editTown.model = 'gen';
      document.querySelector('#patient-modal').classList.add('work-modal--index');
    }
    this.editPostalCodeComponent.OpenModal(this.editTown);
  }

  OpenModal(modal: string) { document.querySelector('#' + modal).classList.add('md-show'); }

  async searchPostcodes(query = '') {
    const townPostalList = await this._dropdownService.SearchPostcodes(query);
    this.townPostalCodeList = townPostalList ? townPostalList.filter(x => x.postcode) : [];
    this.townPostalCodeList = CommonFunctions.Sort(this.townPostalCodeList, 'postcode');
    this.cdref.markForCheck();
  }

  onSkipCharCountExceedsPostcode() {
    this.searchPostcodes(this.patientFormGroup.controls.postalCode.value);
  }

  OnChangePostcode(town: Town) {
    this.patient.townId = Number(town.id);
    this.patient.provinceName = town.provinceName;
    this.patient.countryName = town.countryName;
    this.patient.postalCode = town.postcode;
    this.patientFormGroup.controls.townId.setValue(town.descriptionWithPostcode);
    this.editTown = town;
  }

  clearTown() {
    this.patient.townId = null;
    this.patient.provinceName = '';
    this.patient.countryName = '';
    this.patient.postalCode = '';
    this.patientFormGroup.controls.townId.setValue(null);
    this.patientFormGroup.controls.postalCode.setValue(null);
    this.editTown = new Town();
  }
  /* end for patient modal town */

  setPatientDOB(dateObject: DateFormat): string {
    if (dateObject !== undefined) {
      const month = ('0' + dateObject.month).slice(-2);
      const day = ('0' + dateObject.day).slice(-2);
      const dateString = dateObject.year + '-' + month + '-' + day;
      return moment(dateString, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
  }
}
