import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { OrderReceipt } from 'src/app/modules/common/entities/order-recipt';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { OrderReceiptService } from '../../services/order-receipt.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { Warehouse } from 'src/app/modules/warehouse/entities/warehouse';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from 'src/app/modules/orders/services/orders.service';
import { shippingMethods, OrderReceptionStatus } from 'src/app/modules/common/global';
import { CommonFunctions } from 'src/app/modules/common/services/common-functions';
import { ValidateNgSelect } from 'src/app/modules/common/services/validators';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import API from 'src/app/modules/common/api.config.json';
import { Observable, Subscription, Subject } from 'rxjs';
import { OrderDetail } from 'src/app/modules/orders/entities/order-detail';
import { OrderHeader } from 'src/app/modules/orders/entities/order-header';
import { Toast } from 'src/app/modules/common/services/toast.service';
import swal from 'sweetalert2';
type AOA = any[][];
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { isNumeric } from 'rxjs/internal/util/isNumeric';

@Component({
  selector: 'app-order-receipt-modal',
  templateUrl: './order-receipt-modal.component.html',
  styleUrls: ['./order-receipt-modal.component.scss']
})
export class OrderReceiptModalComponent implements OnInit, OnDestroy {
  @Output() RefreshOrderReceipt: EventEmitter<any> = new EventEmitter();
  @Output() setSavedItem: EventEmitter<any> = new EventEmitter();
  @Input() modalSubject: Subject<any>;

  @ViewChild('excellInput') excellInputVariable: ElementRef;
  @ViewChild('tmpMovement') movementInputVariable: ElementRef;

  @Input() receipt: OrderReceipt;
  @Output() refresh: EventEmitter<any> = new EventEmitter();

  orderDetailList: OrderDetail[] = [];
  orderReceipt: OrderReceipt;
  isEdit = false;
  isAdd = false;
  orderReceiptModal: OrderReceipt;

  orderList: any[] = [];
  shippingMethodsList: any[] = [];
  receptionStatusList: any[] = [];
  reasonForReturnList: any[] = [];
  warehouseList: Array<Warehouse> = [];
  orderFormGroup: FormGroup;
  selectedOrder: any;
  selectedProvider: any;
  showDropDown = false;
  arr_name: any[][];
  data: AOA[][];
  orderArray = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  order: any;

  @Input() useOrder: Observable<any>;
  useOrderSubscription: Subscription;
  disableSearch = false;
  fileChosen = false;
  fileChosenName = '';
  lotReturnControl = new FormControl('');
  returnLotList: any[] = [];

  constructor(
    public commonUiService: CommonUIService,
    private _fb: FormBuilder,
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _toastyService: Toast,
    public _commonUIService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _orderService: OrdersService,
    private _orderReceiptService: OrderReceiptService,
    public dropdownService: DropdownService
  ) { }

  ngOnInit() {
    this.orderReceiptModal = new OrderReceipt();
    this.initForm();
    this.loadShippingMethods();
    this.loadReceptionStatus();
    this.loadWarehouseList();
    this.loadReasonList();
    this.fetchOrders();
    this.listneForOrderInput();
    if (this.modalSubject) {
      this.modalSubject.subscribe(element => {        
        this.orderReceipt = element.receipt;
        this.orderDetailList = element.orderDetailInputList;
        this.isEdit = element.isEdit;
        this.isAdd = false;
        if (this.isEdit) {
          this.setOrderForEdit(this.orderReceipt);
          // set order details
          if (this.orderReceipt.orderId) {
            this._apiService
              .get(
                `${API.orderReceipt.getDetailListByOrderId}${this.orderReceipt.id}`
              )
              .subscribe(res => {
                const orderDetails = res;
                if (orderDetails.length > 0) {
                  const list = this.orderFormGroup.controls.orderReceiptDetailList.value;
                  if (list && list.length === 0) {
                    orderDetails.forEach(detail => {
                      this.addItemForEdit(detail);
                    });
                  }
                  setTimeout(() => {
                    this.commonUiService.OpenBasicModal(element.modal);
                    this.commonUiService.isSpinnerVisible = false;
                  }, 800);
                } else {
                  this.commonUiService.OpenBasicModal(element.modal);
                  this.commonUiService.isSpinnerVisible = false;
                }
              });
          } else {
            this.commonUiService.OpenBasicModal(element.modal);
            this.commonUiService.isSpinnerVisible = false;
          }
        } else {
          this.isEdit = false;
          this.orderFormGroup.reset();
          this.commonUiService.OpenBasicModal(element.modal);
          this.commonUiService.isSpinnerVisible = false;
        }
      });
    }
  }

  async getOrder(selectedOrderId: number): Promise<OrderHeader> {
    return this._apiService
      .get(API.orders.getOrderById + selectedOrderId)
      .map(x => x as OrderHeader)
      .toPromise();
  }

  ngOnDestroy(): void {
    if (this.useOrderSubscription) {
      this.useOrderSubscription.unsubscribe();
    }
  }

  listneForOrderInput() {
    if (!this.useOrder) {
      return;
    } else {
      this.disableSearch = true;
    }
    this.useOrderSubscription = this.useOrder.subscribe(order => {
      this.OnChangeOrder(order);
    });
  }

  async onFileChange(evt: any) {
    /* wire up file reader */
    
    const target: DataTransfer = <DataTransfer>evt.target;

    this.fileChosen = target.files.length > 0;
    if (this.fileChosen) {
      this.fileChosenName = target.files[0].name;
    }
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.onload = async (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      let orderDate = (this.data[3][8] as any) as string;
      if (orderDate) {
        orderDate = moment(orderDate, 'DD/MM/YYYY')
          .utc()
          .format();
      }

      let deliveryDate = (this.data[8][3] as any) as string;
      if (deliveryDate) {
        if (isNumeric(deliveryDate)) {
          const date = CommonFunctions.ConvertExcelDateToJSDate(deliveryDate);
          if (date) {
            const dateStr = new Date(date.getFullYear(),
              date.getDate() - 1, date.getMonth() + 1);
            deliveryDate = moment(dateStr, 'DD/MM/YYYY')
              .utc()
              .format();
          } else { deliveryDate = null; }
        } else {
          deliveryDate = moment(deliveryDate, 'DD/MM/YYYY')
            .utc()
            .format();
        }
      }

      let newOrder: any = {
        orderNo: this.data[2][8],
        orderDate: orderDate,
        providerId: this.data[1][8],
        deliveryDate: deliveryDate,
        deliveryNote: this.data[8][1],
        deliveryCharge: this.data[8][8],
        total: this.data[7][8]
      };

      const excelData = this.data.slice(11);
      this._apiService
        .get(`${API.orders.getOrderByOrderNo}${this.data[2][8]}`)
        .subscribe(res => {
          newOrder.id = res.id;
          newOrder.providerId = res.providerId;
          newOrder.providerName = res.providerName;
          newOrder.total = res.total;
          this.setOrder(newOrder);

          const orderDetails = res.orderDetail;
          this.order = res;
          orderDetails.forEach((detail, i) => {
            detail.discount += ((detail.secondDiscount) ? detail.secondDiscount : 0);
            detail = this.replaceValuesFromSupplier(detail, excelData, i);
            this.addExcelItem(detail);
          });
        });
    };
    reader.readAsBinaryString(target.files[0]);
  }

  /**
   * Replace the values taken from supplier Excel and return the order details which replaced the values
   * @param orderDetail Order Details From Foreach of the order details list
   * @param excelData Data taken from excel read, (** Splice from 11th index)
   */
  replaceValuesFromSupplier(orderDetail: any, excelData: any, index: number) {
    let data = excelData.filter((elem, i) => {
      return elem[1] == orderDetail.productCode && i === index;
    });

    if (data.length > 0) {
      orderDetail.lot = data[0][4];
      orderDetail.expirationDates = data[0][5] ? data[0][5] : null;
      orderDetail.quantity = data[0][6];
      orderDetail.unitReceived = data[0][3];
      orderDetail.unitPrice = data[0][7];
      orderDetail.unitReturned = data[0][9];

      if (data[0][10]) {
        const reason = this.reasonForReturnList
          .find(r => r.description === data[0][10]);
        orderDetail.reasonForReturnId = reason ? reason.id : null;
      }
      orderDetail.returnedLotList = [{
        lotName: data[0][11],
        quantity: data[0][12]
      }];
    }
    return orderDetail;
  }

  async OnOpenAddOrderClaimModal(modal: any) {
    this.commonUiService.OpenBasicModal('recieve-order-claim-modal');
  }

  loadShippingMethods() {
    this.shippingMethodsList = shippingMethods;
  }

  loadReceptionStatus() {
    this.receptionStatusList = OrderReceptionStatus;
  }

  loadReasonList() {
    this._apiService
      .get(API.reason_for_return.getReturnForReasonList)
      .subscribe((list: any[]) => {
        this.reasonForReturnList = list;
      });
  }

  loadWarehouseList() {
    this._apiService.get(API.ware_houses.getWareHousesList).subscribe(
      (list: Warehouse[]) => (this.warehouseList = list ? list.filter(x => x.status) : []),
      err => console.log(err),
      () => {
        this.warehouseList = CommonFunctions.Sort(
          this.warehouseList,
          'description'
        );
      }
    );
  }

  initForm() {

    this.orderFormGroup = this._fb.group({
      orderReceiptId: ['', []],
      orderReceiptDetailId: ['', []],
      orderId: ['', []],
      orderNo: ['', [ValidateNgSelect]],
      orderDate: [null, []],
      deliveryDate: [null, []],
      deliveryNoteDate: [null, [Validators.required]],
      receiptStatus: [1, ''],
      providerId: ['', []],
      shippingCost: ['', []],
      totalOrderAmount: ['', []],
      deliveryNote: ['', [Validators.required]],
      wareHouseId: [0, [ValidateNgSelect]],
      movement: ['', []],
      providerName: ['', []],
      orderReceiptDetailList: this._fb.array([])
    });


  }

  fetchOrders() {
    this._apiService.get(API.orders.getOrdersList, true).subscribe(res => {
      this.orderList = res;
    });
  }

  setOrder(order: any) {
    this.selectedOrder = order;
    this.orderFormGroup.patchValue({
      orderId: order.id ? order.id : this.selectedOrder.id
    });
    this.orderFormGroup.patchValue({ total: this.selectedOrder.total });

    this.orderFormGroup.patchValue({
      deliveryNoteDate: this.commonUiService.ToNgbDate(new Date())
    });

    if (order.orderDate) {
      this.orderFormGroup.patchValue({
        orderDate: this.commonUiService.ToNgbDate(new Date(order.orderDate))
      });
    }

    if (order.deliveryDate) {
      this.orderFormGroup.patchValue({
        deliveryDate: this.commonUiService.ToNgbDate(
          new Date(order.deliveryDate)
        )
      });
    }
    this.orderFormGroup.patchValue({
      providerName: this.selectedOrder.providerName
    });

    this.orderFormGroup.patchValue({ orderStatus: order.orderStatus });
    //// this.orderFormGroup.patchValue({ providerId: order.providerId });
    // this.orderFormGroup.patchValue({ shippingMethod: order.shippingMethodId });
    //// this.orderFormGroup.patchValue({ deliveryNotes: order.notes });

    if (order.orderNo) {
      this.orderFormGroup.patchValue({ orderNo: order.orderNo });
    }
    if (order.providerId) {
      this.orderFormGroup.patchValue({ providerId: order.providerId });
    }
    if (order.deliveryNote) {
      this.orderFormGroup.patchValue({ deliveryNote: order.deliveryNote });
    }
    if (order.deliveryCharge) {
      this.orderFormGroup.patchValue({ shippingCost: order.deliveryCharge });
    }
    if (order.total) {
      this.orderFormGroup.patchValue({ totalOrderAmount: order.total });
    }

    this.movementInputVariable.nativeElement.click();
  }

  setOrderForEdit(order: any) {
    this.selectedOrder = order;
    this.orderFormGroup.patchValue({
      orderId: order.orderId ? order.orderId : this.selectedOrder.orderId
    });
    this.orderFormGroup.patchValue({ total: this.selectedOrder.total });
    this.orderFormGroup.patchValue({
      deliveryNoteDate: this.commonUiService.ToNgbDate(
        new Date(order.deliveryNoteDate)
      )
    });
    this.orderFormGroup.patchValue({
      providerName: this.selectedOrder.providerName
    });
    this.orderFormGroup.patchValue({ shippingMethod: order.shippingMethodId });
    this.orderFormGroup.patchValue({ deliveryNotes: order.notes });
    this.orderFormGroup.patchValue({
      orderDate: this.commonUiService.ToNgbDate(new Date(order.orderDate))
    });
    this.orderFormGroup.patchValue({
      deliveryDate: this.commonUiService.ToNgbDate(new Date(order.deliveryDate))
    });
    this.orderFormGroup.patchValue({ orderStatus: order.orderStatus });
    this.orderFormGroup.patchValue({ orderNo: order.orderNo });
    this.orderFormGroup.patchValue({ providerName: order.providerName });
    this.orderFormGroup.controls['providerName'].disable();    
    this.orderFormGroup.patchValue({ providerId: order.providerId });
    this.orderFormGroup.patchValue({ deliveryNote: order.deliveryNote });
    this.orderFormGroup.patchValue({ shippingCost: order.shippingCost });
    this.orderFormGroup.patchValue({
      totalOrderAmount: order.totalOrderAmount
    });
    this.orderFormGroup.patchValue({ receiptStatus: order.receiptStatus });    
    this.orderFormGroup.patchValue({ wareHouseId: order.wareHouseId });
    this.orderFormGroup.controls['wareHouseId'].disable();
    this.orderFormGroup.patchValue({ movement: order.movement });
    this.movementInputVariable.nativeElement.click();
  }

  async OnChangeOrder(order: any) {
    const data = await this._orderReceiptService.GetReceiptByOrderId(order.id);
    if (data != null && (data.receiptStatus === 1 || data.receiptStatus === 2)) {
      this.clearDetailForm();
      this.orderReceipt = data;
      this.isEdit = true;
      this.isAdd = true;
      this.GetReceiptDataByOrderId(data.id);
      swal({
        title: this._commonDataService.localizationLabelList['order_received'],
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_accept'],
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.orderFormGroup.patchValue({ receiptStatus: 2 });
        }
      }).catch(err => console.log(err));
    } else {
      this.clearDetailForm();
      this.setOrder(order);
      this.getOrderDetails();
      this.isAdd = true;
    }
  }

  async GetReceiptDataByOrderId(receiptId: number) {
    try {

      if (this.isEdit) {
        this.commonUiService.isSpinnerVisible = true;
        this.orderDetailList = [];
        this.orderDetailList = await this._orderReceiptService.GetDetailListByOrderId(receiptId);
        this.setOrderForEdit(this.orderReceipt);
        // set order details
        if (this.orderReceipt.orderId) {
          this._apiService
            .get(
              `${API.orderReceipt.getDetailListByOrderId}${this.orderReceipt.id}`
            )
            .subscribe(res => {
              const orderDetails = res;
              if (orderDetails.length > 0) {
                orderDetails.forEach(detail => {
                  this.addItemForEdit(detail);
                });
              }
            });
        }
      }
    } catch (error) { console.log(error); }
    finally {
      this._commonUIService.isSpinnerVisible = false;
    }

  }

  clearDetailForm() {
    this.orderFormGroup.setControl(
      'orderReceiptDetailList',
      this._fb.array([])
    );
  }

  OnClearLot() {
    this.lotReturnControl.setValue('');
  }

  OnChangeLot(lotItem: { display: string; value: number; lotId: number; }, inumber: any) {
    if (lotItem) {
      this.returnLotList = this.orderDetailForm.value[inumber].lotList;
    }
  }

  resetForm() {
    this.clearDetailForm();
    this.orderFormGroup.reset();
    if (this.excellInputVariable) {
      this.excellInputVariable.nativeElement.value = '';
    }
    this.orderArray = [];
    this.orderFormGroup.patchValue({
      wareHouseId: 0,
      movement: '',
      receiptStatus: 1
    });
  }

  resetData() {
    this.order = null;
    this.selectedOrder = null;
  }

  serveOrder() {
    this.orderFormGroup.patchValue({ receiptStatus: 2 });
    this.orderDetailForm.controls.forEach(controlRow => {
      const units = controlRow.get('units').value;
      controlRow.patchValue({
        receivedUnits: units
      });
    });
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  saveOrder() {
    const orderReceipt = new OrderReceipt();
    Object.assign(orderReceipt, this.orderFormGroup.value);
    if (this.orderFormGroup.invalid) {
      this.markFormGroupTouched(this.orderFormGroup);
      return;
    }
    if (this.orderReceipt) {
      if (this.orderReceipt.id > 0) {
        orderReceipt.id = this.orderReceipt.id;
        orderReceipt.providerId = this.orderReceipt.providerId;
        orderReceipt.shippingCost = orderReceipt.shippingCost
          ? Number(orderReceipt.shippingCost)
          : 0;
        orderReceipt.receiptStatus = orderReceipt.receiptStatus
          ? Number(orderReceipt.receiptStatus)
          : 0;
        orderReceipt.orderDate = orderReceipt.orderDate
          ? this.commonUiService
            .ChangeNgbDateToCustomFormat(orderReceipt.orderDate)
            .toString()
          : null;
        orderReceipt.deliveryDate = orderReceipt.deliveryDate
          ? this.commonUiService
            .ChangeNgbDateToCustomFormat(orderReceipt.deliveryDate)
            .toString()
          : null;
        orderReceipt.deliveryNoteDate = this.commonUiService
          .ChangeNgbDateToCustomFormat(orderReceipt.deliveryNoteDate)
          .toString();
        orderReceipt.createdDate = this.commonUiService.ChangeNgbDateToCustomFormat(new Date());

        const user = this._commonDataService.GetUserFromSession();
        orderReceipt.createdBy = user.id;
        orderReceipt.modifiedBy = user.id;
        orderReceipt.modifiedDate = this.commonUiService.ChangeNgbDateToCustomFormat(new Date());
        orderReceipt.orderReceiptDetailList = this.orderDetailForm.value;
        
        orderReceipt.wareHouseId = (this.orderFormGroup.controls.wareHouseId.value)
          ? Number(this.orderFormGroup.controls.wareHouseId.value)
          : null;

        if (orderReceipt.orderReceiptDetailList.length > 0) {
          orderReceipt.orderReceiptDetailList.forEach(receiptDetail => {
            receiptDetail.orderReceiptId = receiptDetail.orderReceiptId;
            if (receiptDetail.reasonForReturnId !== null) {
              receiptDetail.reasonForReturnId = receiptDetail.reasonForReturnId
                ? Number(receiptDetail.reasonForReturnId)
                : null;
            }
            if (receiptDetail.expirationDate) {
              receiptDetail.expirationDate = this.commonUiService
                .ChangeNgbDateToCustomFormat(receiptDetail.expirationDate)
                .toString();
            }
            receiptDetail.lotList.forEach(lotItem => {
              lotItem.expirationDate = this.commonUiService.ChangeNgbDateToCustomFormat(
                lotItem.expirationDate
              );
            });
          });
        } else {
          orderReceipt.orderReceiptDetailList = [];
        }
        console.log('orderReceipt: ', orderReceipt);
        this.commonUiService.isSpinnerVisible = true;
        this._apiService
          .post(API.orderReceipt.editOrderReceipt, orderReceipt, false)
          .subscribe(
            res => {
              const body = JSON.parse(res['_body']);
              const data = body['_data'];
              const resBody = JSON.parse(res._body);
              if (resBody && resBody._statusCode === 300) {
                this._toastyService.error({
                  title: this._commonDataService.localizationLabelList['order_receipt_detail'],
                  msg: this._commonDataService.localizationLabelList['order_receipt_already_exist']
                });
              } else if (res.status == 204 || res.status == 200) {
                this._toastyService.success({
                  title: this._commonDataService.localizationLabelList['order_receipt_detail'],
                  msg: this._commonDataService.localizationLabelList['update_success']
                });
                this.closePopup();
                this.closeModal();

                this.RefreshOrderReceipt.emit(data);
                this.resetForm();
                this.resetData();

                this.fireRefresh();
              }
            },
            err => {
              console.log(err);
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['order_receipt_detail'],
                msg: this._commonDataService.localizationLabelList['save_error']
              });
              this.commonUiService.isSpinnerVisible = false;
            },
            () => {
              this.commonUiService.isSpinnerVisible = false;
              this.fileChosen = null;
            }
          );
      }
    } else {

      orderReceipt.shippingCost = (orderReceipt.shippingCost) ? Number(orderReceipt.shippingCost) : 0;
      orderReceipt.receiptStatus = (orderReceipt.receiptStatus) ? Number(orderReceipt.receiptStatus) : 0;
      orderReceipt.orderDate = orderReceipt.orderDate ? this.commonUiService.ChangeNgbDateToCustomFormat(orderReceipt.orderDate).toString() : null;
      orderReceipt.deliveryDate = orderReceipt.deliveryDate ? this.commonUiService.ChangeNgbDateToCustomFormat(orderReceipt.deliveryDate).toString() : null;
      orderReceipt.deliveryNoteDate = this.commonUiService.ChangeNgbDateToCustomFormat(orderReceipt.deliveryNoteDate).toString();
      orderReceipt.createdDate = this.commonUiService.ChangeNgbDateToCustomFormat(new Date());
      orderReceipt.wareHouseId = (orderReceipt.wareHouseId) ? Number(orderReceipt.wareHouseId) : null;

      const user = this._commonDataService.GetUserFromSession();
      orderReceipt.createdBy = user.id;
      orderReceipt.modifiedBy = user.id;
      orderReceipt.modifiedDate = this.commonUiService.ChangeNgbDateToCustomFormat(new Date());
      orderReceipt.orderReceiptDetailList = this.orderDetailForm.value;
      orderReceipt.orderReceiptDetailList.forEach(receiptDetail => {
        receiptDetail.orderReceiptId = 0;
        if (receiptDetail.reasonForReturnId !== null) {
          receiptDetail.reasonForReturnId = receiptDetail.reasonForReturnId
            ? Number(receiptDetail.reasonForReturnId)
            : null;
        }
        if (receiptDetail.expirationDate) {
          receiptDetail.expirationDate = this.commonUiService
            .ChangeNgbDateToCustomFormat(receiptDetail.expirationDate)
            .toString();
        }
        receiptDetail.lotList.forEach(lotItem => {
          lotItem.expirationDate = this.commonUiService.ChangeNgbDateToCustomFormat(
            lotItem.expirationDate
          );
        });
      });
      const that = this;
      this.commonUiService.isSpinnerVisible = true;
      console.log('orderReceipt elseo: ', orderReceipt);
      this._apiService
        .post(API.orderReceipt.addOrderReceipt, orderReceipt, false)
        .subscribe(
          res => {
            const resBody = JSON.parse(res._body);
            const data = resBody._data;
            if (resBody && resBody._statusCode === 300) {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['order_receipt_detail'],
                msg: this._commonDataService.localizationLabelList['order_receipt_already_exist']
              });
            } else if (res.status == 204 || res.status == 200) {
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList['order_receipt_detail'],
                msg: this._commonDataService.localizationLabelList['save_success']
              });
              that.setSavedItem.emit(data);
              this.closePopup();
              this.closeModal();
              this.resetForm();
              this.resetData();

              // this.fireRefresh();
            }
          },
          err => {
            console.log(err);
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['order_receipt_detail'],
              msg: this._commonDataService.localizationLabelList['save_error']
            });
            this.commonUiService.isSpinnerVisible = false;
          },
          () => {
            this.commonUiService.isSpinnerVisible = false;
            this.fileChosen = null;
          }
        );
    }
  }

  CloseModal(modal: any) {
    this.commonUiService.CloseBasicModal(modal);
  }

  fireRefresh() {
    this.refresh.emit();
  }

  getOrderDetails() {
    if (this.selectedOrder === undefined && this.selectedOrder === null) {
      return;
    }
    this._apiService
      .get(`${API.orders.getOrderById}${this.selectedOrder.id}`)
      .subscribe(res => {
        let orderDetails = res.orderDetail;
        orderDetails.forEach(detail => {
          if (detail.secondDiscount) {
            detail.discount += detail.secondDiscount;
          }
          this.addItem(detail);
        });
        this.order = res;
      });
  }

  addItem(order: any): void {
    const orderItem = this.orderFormGroup.get(
      'orderReceiptDetailList'
    ) as FormArray;
    orderItem.push(this.createItem(order));
  }

  addItemForEdit(order: any): void {
    const orderItem = this.orderFormGroup.get(
      'orderReceiptDetailList'
    ) as FormArray;
    orderItem.push(this.createItemForEdit(order));
  }

  addExcelItem(order: any): void {
    const orderItem = this.orderFormGroup.get(
      'orderReceiptDetailList'
    ) as FormArray;
    orderItem.push(this.createExcelItem(order));
  }

  changeValue(i) {
    const units = this.orderDetailForm.controls[i].get('units').value;
    const unitPrice = this.orderDetailForm.controls[i].get('unitPrice').value;
    const discount = this.orderDetailForm.controls[i].get('discount').value;
    const vat = this.orderDetailForm.controls[i].get('iva').value;

    const basePrice = this._orderService.GetBase(unitPrice, units, discount);
    const total = this._orderService.GetTotal(basePrice, vat);
    this.orderDetailForm.controls[i].patchValue({
      basePrice,
      total
    });
    this.calculateTotal();
  }

  calculateTotal() {
    let calculateTotal = 0;
    let calculateTotalWithVat = 0;
    this.orderDetailForm.value.forEach(receiptDetail => {
      const units = receiptDetail.units;
      const unitPrice = receiptDetail.unitPrice;
      const discount = receiptDetail.discount;
      const vat = receiptDetail.iva;

      const basePrice = this._orderService.GetBase(unitPrice, units, discount);
      calculateTotal += this._orderService.GetTotal(basePrice, vat);
    });
    calculateTotalWithVat =
      calculateTotal + this.orderFormGroup.controls['shippingCost'].value;
    if (calculateTotal > 0) {
      const total = Number(calculateTotalWithVat.toFixed(2));
      this.orderFormGroup.patchValue({
        totalOrderAmount: total
      });
    }
  }

  createItem(order: any): FormGroup {
    const units = Number(order.units);
    const basePrice = this._orderService.GetBase(
      order.unitPrice,
      units,
      order.discount
    );
    const total = this._orderService.GetTotal(basePrice, order.iva);

    const updateValue: any = {
      productId: [order.productId, []],
      description: [order.description, []],
      reference: [order.reference, []],
      units: [Number(order.units), []],
      unitPrice: [Number(order.unitPrice), []],
      discount: [order.discount, []],
      basePrice: [basePrice, []],
      iva: [order.iva, []],
      total: [total, []],
      receivedUnits: ['', []],
      returnUnits: ['', []],
      reasonForReturnId: [null, []],
      lotList: this._fb.array([]),
      lotReturnList: this._fb.array([])
    };
    return this._fb.group(updateValue);
  }

  createItemForEdit(order: any): FormGroup {
    const units = Number(order.units);
    const basePrice = Number(order.basePrice);
    const total = Number(order.total);
    const updateValue: any = {
      id: [order.id, []],
      orderReceiptId: [order.orderReceiptId, []],
      productId: [order.productId, []],
      description: [order.productName, []],
      reference: [order.reference, []],
      units: [Number(order.units), []],
      unitPrice: [Number(order.unitAmount), []],
      discount: [order.discount, []],
      basePrice: [basePrice, []],
      iva: [order.iva, []],
      total: [total, []],
      receivedUnits: [order.receivedUnits, []],
      returnUnits: [order.returnUnits, []],
      reasonForReturnId: [order.reasonForReturnId, []],
      lotList: this._fb.array(this.getLotArray(order.lotList as any[])),
      productName: [order.productName, []],
      unitAmount: [Number(order.unitAmount), []],
      totalInvoiceAmount: [Number(order.totalInvoiceAmount), []],
      lotReturnList: this._fb.array(this.getLotReturnArray(order.lotReturnList as any[]))

    };
    return this._fb.group(updateValue);
  }

  test(item) {
    console.log(item);
  }

  createLotItem(i) {
    const lotItem = (((this.orderFormGroup.get(
      'orderReceiptDetailList'
    ) as FormArray).controls[i] as FormArray).controls as any).lotList;
    const lotGroup: any = {
      lotName: ['', []],
      expirationDate: [null, []],
      quantity: ['', []]
    };
    lotItem.push(this._fb.group(lotGroup));
  }

  createReturnUnitItem(i) {
    const lotReturnItem = (((this.orderFormGroup.get(
      'orderReceiptDetailList'
    ) as FormArray).controls[i] as FormArray).controls as any).lotReturnList;

    this.returnLotList = this.orderDetailForm.value[i].lotList;
    let lotList = this.returnLotList.filter(a => a.lotName !== '').length;
    let lotReturnList = this.orderDetailForm.value[i].lotReturnList.length;

    if (lotList <= lotReturnList) {

      this._toastyService.warning({
        title: this._commonDataService.localizationLabelList['order_receipt_detail'],
        msg: this._commonDataService.localizationLabelList['exceed_return_unit_lot']
      });
      return;
    }

    const returnlotGroup: any = {
      lotName: ['', []],
      returnquantity: ['', []]
    };
    lotReturnItem.push(this._fb.group(returnlotGroup));



  }

  createLotItemExcel(lotList: any[]) {

  }

  getLotArray(lotList: any[]) {
    if (lotList) {
      const lotArray = lotList.map(l => (this._fb.group({
        lotName: [l.lotName, []],
        expirationDate: [this.commonUiService.ToNgbDate(new Date(l.expirationDate)), []],
        orderReceiptDetailId: [l.orderReceiptDetailId, []],
        lotId: [l.lotId, []],
        quantity: [l.quantity ? l.quantity : null, []]
      })));
      return lotArray;
    }
  }

  getLotReturnArray(lotReturnList: any[]) {
    if (lotReturnList) {
      const lotReturnArray = lotReturnList.map(l => (this._fb.group({
        lotName: [l.lotName, []],
        orderReceiptDetailId: [l.orderReceiptDetailId, []],
        lotReturnId: [l.lotReturnId, []],
        returnquantity: [l.returnQuantity ? Number(l.returnQuantity) : null, []]
      })));
      return lotReturnArray;
    }
  }

  createExcelItem(order: any): FormGroup {
    const units = Number(order.units);
    const basePrice = this._orderService.GetBase(
      order.unitPrice,
      units,
      order.discount
    );
    const total = this._orderService.GetTotal(basePrice, order.iva);
    let expirationDatee = null;
    if (order.expirationDates) {
      if (isNumeric(order.expirationDates)) {
        const date = CommonFunctions.ConvertExcelDateToJSDate(order.expirationDates);
        if (date) {
          const dateStr = new Date(date.getFullYear(),
            date.getDate() - 1, date.getMonth() + 1);
          expirationDatee = moment(dateStr, 'DD/MM/YYYY')
            .utc()
            .format();
        } else { expirationDatee = null; }
      } else {
        expirationDatee = moment(order.expirationDates, 'DD/MM/YYYY')
          .utc().format();
      }
    } else { expirationDatee = null; }

    let expirationDate = expirationDatee === null ? null
      : this.commonUiService.ToNgbDate(new Date(expirationDatee));

    let lotListGroup = this._fb.group({
      lotName: [order.lot, []],
      expirationDate: [expirationDate, []],
      quantity: [order.quantity ? Number(order.quantity) : null, []]
    });

    let returnLotGroup = this._fb.group({});
    if (order.returnedLotList && order.returnedLotList.length > 0) {
      const returnLot = order.returnedLotList[0];
      returnLotGroup = this._fb.group({
        lotName: [returnLot.lotName],
        returnquantity: [returnLot.quantity]
      });
    }

    return this._fb.group({
      orderId: order.headerId,
      productId: order.productId,
      description: order.description,
      reference: order.reference,
      units: Number(order.units),
      unitPrice: Number(order.unitPrice),
      discount: order.discount,
      basePrice: basePrice,
      iva: order.iva,
      total: total,
      receivedUnits: order.unitReceived,
      lotList: this._fb.array([lotListGroup]),
      lotName: order.lot,
      expirationDate: expirationDate,
      returnUnits: order.unitReturned,
      reasonForReturnId: order.reasonForReturnId,
      lotReturnList: this._fb.array([returnLotGroup])
    });
  }

  get orderDetailForm(): FormArray {
    return this.orderFormGroup.get('orderReceiptDetailList') as FormArray;
  }

  closePopup() {
    this.isEdit = false;
    this.orderFormGroup.controls['providerName'].enable();
    this.orderFormGroup.controls['wareHouseId'].enable();
    this.closeModal();
    this.resetForm();
    this.resetData();
    this.fileChosenName = null;
    this.commonUiService.isSpinnerVisible = false;
  }

  closeModal() {
    this.commonUiService.CloseBasicModal(
      'order-receipt-details-add-edit-modal'
    );
  }

  closeModalClaim() {
    this.commonUiService.CloseBasicModal('recieve-order-claim-modal');
  }

  clearFilterByOrderNo() {
    this.isEdit = false;
    this.orderFormGroup.reset();
    this.clearDetailForm();
    this.isAdd = false;
  }
}
