export class MwtFormModel {
    workTypeFormGroup: WorkTypeFormModel;
    externalFormGroup: ExternalFormModel;
    implantDataFormGroup: ImplantDataFormModel;
    colorFormArray: ColorFormModel[];
    clinicMaterialFormArray: ClinicMaterialFormModel[];
    documentFormArray: DocumentFormModel[];
    prescriptionFormArray: PrescriptionFormModel[];
    searchArticleName: string;
    articleFormArray: ArticleFormModel[];
    searchProductName: string;
    productFormArray: ProductFormModel[];
    metalFormArray: MetalFormModel[];

    constructor(init?: Partial<MwtFormModel>) {
        if (init) { Object.assign(this, init); }
    }
}

export class WorkTypeFormModel {
    templateName: string;
    templateId: number;
    mwtNo: string;
    mwtStatusId: number;
    receivedDate: any;
    deliveryDate: any;
    deliveryTime: string;
    actualDeliveryDate: any;
    actualDeliveryTime: string;
    isExternal: boolean;
    clientObservations: string;
    labObservations: string;
    generalObservations: string;
    bateaNo: string;
}

export class ExternalFormModel {
    mwtId: number;
    externalCenterId: number;
    externalCenterName: string;
    statusId: any;
    shippingMethodId: any;
    requestDate: any;
    outSourcedNo: string;
    deliveryDate: any;
    deliveryTime: string;
    observations: string;
    deliveryNoteNo: string;
    attachmentFormArray: ExternalAttachmentFormModel[];
    total: number;
}

export class ImplantDataFormModel {
    brand: string;
    reference: string;
    apical: number;
    note: string;
    mwtId: number;
}

export class ColorFormModel {
    mwtId: number;
    colorId: number;
    colorText: string;
    localizationId: number;
    localizationText: string;
    metalId: number;
    metalText: string;
    pieces: string;
    articleId: number;
    metalArticleId: number;
    constructor(init?: Partial<ColorFormModel>) {
        if (init) { Object.assign(this, init); }
    }
}

export class ClinicMaterialFormModel {
    mwtId: number;
    clinicMaterialId: number;
    quantity: number;

    constructor(init?: Partial<ClinicMaterialFormModel>) {
        if (init) { Object.assign(this, init); }
    }
}

export class DocumentFormModel {
    mwtId: number;
    name: string;
    file: File;

    constructor(init?: Partial<DocumentFormModel>) {
        if (init) { Object.assign(this, init); }
    }
}

export class ExternalAttachmentFormModel {
    mwtExternalId: number;
    name: string;
    file: File;

    constructor(init?: Partial<ExternalAttachmentFormModel>) {
        if (init) { Object.assign(this, init); }
    }
}

export class PrescriptionFormModel {
    subWorkId: number;
    name: string;
    file: File;

    constructor(init?: Partial<PrescriptionFormModel>) {
        if (init) { Object.assign(this, init); }
    }
}

export class ArticleFormModel {
    mwtId: number;
    articleId: number;
    articleName: string;
    price: number;
    quantity: number;
    discount: number;
    finalPrice: number;
    waste: number;
    discountAmount: number;
    workTypeArticleId: number;
    ivaTypeId: number | null;
    ivaPercentage: number | null;
    ivaAmount: number | null;
    quantityWithWaste: number | null;
    priceWithDiscount: number | null;
    lineDiscountAmount: number | null;
    lineSubTotal: number | null;

    constructor(init?: Partial<ArticleFormModel>) {
        if (init) { Object.assign(this, init); }
    }
}

export class ProductFormModel {
    mwtId: number;
    productId: number;
    productName: string;
    quantity: number;
    articleId: number;

    constructor(init?: Partial<ProductFormModel>) {
        if (init) { Object.assign(this, init); }
    }
}


export class MetalFormModel {
    mwtId: number;
    metalId: number;
    metalText: string;
    constructor(init?: Partial<MetalFormModel>) {
        if (init) { Object.assign(this, init); }
    }
}


