import API from './../../../../common/api.config.json';
import { ApiService } from '../../../../common/services/api.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { GroupOfMaterial } from '../../../entities/group-of-material';
import { TranslateService } from '@ngx-translate/core';
import { Toast } from 'src/app/modules/common/services/toast.service';


@Component({
  templateUrl: './group-of-material.component.html',
  selector: 'group-of-material-add-list',
  styleUrls: []
})

export class GroupOfMaterialComponent implements OnInit {

  groupMaterial: GroupOfMaterial;
  isLoad: boolean;
  position = 'bottom-right';
  groupMaterialList: GroupOfMaterial[];
  groupMaterialFormGroup: FormGroup;
  groupMaterialForm: NgForm;

  @ViewChild('description') nameField: ElementRef;

  constructor(
    private _router: Router,
    private _apiService: ApiService,
    private _fb: FormBuilder,
    private _commonUIService: CommonUIService,
    private _toastyService: Toast,
    private translate: TranslateService) {
    this.groupMaterial = new GroupOfMaterial();
    this.isLoad = false;
  }

  ngOnInit(): void {

    this.groupMaterialFormGroup = this._fb.group({
      description: ['', [
        Validators.required
      ]]
    })

    this.LoadGroupMaterialList();
  }

  LoadGroupMaterialList() {
    this._apiService.get(API.group_of_material.getGroupOfMaterialList)
      .subscribe(res => {
        this.groupMaterialList = res;
        this.isLoad = true;
      });
  }

  LoadEditGroupMaterial(groupMaterial: GroupOfMaterial) {
    groupMaterial.isEdit = true;
  }

  EditGroupMaterial(groupMaterial: GroupOfMaterial, statusChange: boolean) {
    groupMaterial.isEdit = false;

    this._apiService.put(API.group_of_material.editGroupOfMaterial + groupMaterial.id, groupMaterial, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {

            let message = '';

            if (statusChange) {
              message = 'Status changed !';
            }
            else {
              message = 'Updated sucessfully !';
            }

            this._toastyService.success({
              title: 'Group of Material',
              msg: message
            });

            this.LoadGroupMaterialList();
          }
        },
        err => {
          console.log(err);
        }
      );

  }

  RemoveEditGroupMaterial(groupMaterial: GroupOfMaterial) {
    groupMaterial.isEdit = false;
    this.LoadGroupMaterialList();
  }

  ActiveDeactiveGroupMaterial(groupMaterial: GroupOfMaterial) {

    if (groupMaterial) {
      if (groupMaterial.status) {
        groupMaterial.status = false;
      }
      else {
        groupMaterial.status = true;
      }
      this.EditGroupMaterial(groupMaterial, true);
    }
  }

  OnOpenAddGroupMaterialModal() {

    this.groupMaterial = new GroupOfMaterial(); this.groupMaterialFormGroup.reset();
    this._commonUIService.openModal('group-material-modal');

    setTimeout(() => {
      this.nameField.nativeElement.focus();
    }, 100);

  }

  OnCloseGroupMaterialModal(event) {
    this._commonUIService.closeModal(event);
  }

  SaveGroupMaterial(groupMaterial: GroupOfMaterial, event) {

    if (this.groupMaterialFormGroup.invalid) {
      Object.keys(this.groupMaterialFormGroup.controls).forEach(field => {
        const control = this.groupMaterialFormGroup.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }
    this.OnCloseGroupMaterialModal(event);

    this._apiService.post(API.group_of_material.saveGroupOfMaterial, groupMaterial, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {

            this._toastyService.success({
              title: 'Group of Material',
              msg: 'Saved sucesfully !'
            });
            this.LoadGroupMaterialList();
          }
        },
        err => {
          console.log(err);
          this._toastyService.error({
            title: 'Group of Material',
            msg: err
          });
        }
      );
  }
}



