import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import API from '../../common/api.config.json';
import { OrderReceipt } from '../../common/entities/order-recipt';
import { ReasonForReturn } from '../../orders/entities/reason-for-return';
import { OrderReceiptDetail } from '../../orders/entities/order-receipt-detail';
import { OrderEmailRequestModel } from '../../orders/entities/order-email-request-model';

@Injectable({
  providedIn: 'root'
})
export class OrderReceiptService {

  constructor(private _apiService: ApiService) { }

  GetReceiptByOrderId(orderId: number) {
    return this._apiService.get(API.orderReceipt.getReceiptByOrderId + orderId, true)
      .map(r => r as OrderReceipt).toPromise();
  }

  GetDetailListByOrderId(orderId: number) {
    return this._apiService.get(API.orderReceipt.getDetailListByOrderId + orderId, true)
      .map(list => list).toPromise();
  }

  GetReasonForReturnList() {
    return this._apiService.get(API.reason_for_return.getReturnForReasonList, true)
      .map(list => list as ReasonForReturn[]).toPromise();
  }

  UpdateOrderReceiptDetail(detail: OrderReceiptDetail) {
    return this._apiService.put(API.orderReceipt.updateReceiptDetail, detail, true, true)
      .map(isUpdated => isUpdated as boolean).toPromise();
  }

  SendReturnEmail(email: OrderEmailRequestModel) {
    return this._apiService.post(API.orderReceipt.sendReturnEmail, email, true, true).toPromise();
  }
}

