import { Component, OnInit, Input } from '@angular/core';
import { Issuer } from 'src/app/modules/client/entities/client-issuer';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { ToastyService } from 'ng2-toasty';

@Component({
  selector: 'app-invoice-report',
  templateUrl: './invoice-report.component.html',
  styleUrls: ['./invoice-report.component.scss']
})
export class InvoiceReportComponent implements OnInit {

  @Input() reportId: string;
  @Input() invoiceList: any[];
  @Input() issuer: Issuer;
  imageData: any;


  constructor(private _router: Router,
    private _apiService: ApiService,
    private _fb: FormBuilder,
    private httpClient: HttpClient,
    private _commonUIService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: ToastyService) { }

  ngOnInit() {
    this.LoadLabConfigurationList();
  }

  async LoadLabConfigurationList() {
    this.imageData = await this._commonUIService.LoadLabConfigurationList();
  }
}
