import { InvoiceDetail } from './invoice-detail';
import { Issuer } from '../../auxiliary/entities/issuer';
import { Patient } from '../../client/entities/patient';

export class InvoiceHeader {
  id: number;
  generateNumber: string;
  clientId: any;
  clientIdList: any[] = [];
  generateDate: any;
  issuerId?: any;
  seriesId?: any;
  discount?: number;
  discountAmount?: number;
  tax?: number;
  subTotal?: number;
  comments: string;
  invoiceStatus: string;
  status: boolean;
  createdDate: any;
  createdBy: number;
  modifiedDate?: any;
  modifiedBy?: number;

  clientName: string;
  issuerName: string;
  seriesName: string;
  baseAmount: number;
  isReceipted: boolean;
  isPaymentDone: boolean;
  isClosed: boolean;

  invoiceDetailList: InvoiceDetail[];

  invoiceTotal: number;
  sumOfSubTotal: number;
  sumOfTax: number;
  sumOfDiscount: number;

  // excel export properties
  seriesValue: string;
  clientValue: string;
  billCustomer: number;
  pendingValue: boolean;
  isClient: boolean;
  fromDate: string;
  toDate: string;


  selectedTemplate: { value: number, description: string };
  labPicturePath: string;
  cancellationGenerateNumber: string;
  cancellationGenerateDate: Date | string;
  cancellationObservation: string;

  client?: any;
  isMailed: boolean;
  patientId: number;
  billedToPatient: boolean;
  patientName: string;

  patient: Patient;

  issuer: Issuer;

  mainWorkTypeList:any[] = [];

  constructor(init?: Partial<InvoiceHeader>) {
    if (init) { Object.assign(this, init); }
  }
}

