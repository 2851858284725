import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import API from './../../common/api.config.json';
import { ClientDefaultPrintReport } from '../../client/entities/client-default-print-report';
import { InvoiceDetail } from '../../invoice/entities/invoice-detail';
import { InvoiceHeader } from '../../invoice/entities/invoice-header';
import { DeliveryNote } from '../../delivery-note/entities/delivery-note';
import { InvoiceDateRangeResult } from '../../invoice/entities/invoice-date-range-result';
import { IvaBreakdown } from '../../auxiliary/entities/iva-breakdown';
import * as _ from 'lodash';
import { BillingClientPatient } from '../../invoice/entities/billing-client-patient';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  isShowEmailPopup = false;
  isSaving = false;
  ivaBreakdownList: IvaBreakdown[] = [];

  constructor(private _apiService: ApiService) { }

  AddInvoice(invoice: InvoiceHeader) {
    return this._apiService.post(API.invoice.addInvoiceHeader, invoice, false, true).toPromise();
  }

  AddInvoiceDetailList(list: InvoiceDetail[]) {
    return this._apiService.post(API.invoice.addInvoiceDetail, list, true, true)
      .map(isSaved => isSaved as boolean).toPromise();
  }

  UpdateInvoiceHeader(header: InvoiceHeader) {
    return this._apiService.put(API.invoice.updateInvoiceHeader, header)
      .toPromise();
  }

  UpdateClientsInvoiceList(list: InvoiceHeader[]) {
    return this._apiService.put(API.invoice.updateClientsInvoiceList, list)
      .toPromise();
  }

  GetInvoiceListByWorkId(workId: number, subworkId: number) {
    return this._apiService.get(API.work_panels.getInvoiceListByWorkId + workId + '/' + subworkId)
      .map(list => list as InvoiceHeader[]).toPromise();
  }

  GetSavedTemplateByClientId(clientId: number) {
    return this._apiService.get(API.invoice.getSavedTemplateByClientId + clientId)
      .map(x => x as ClientDefaultPrintReport).toPromise();
  }

  GetFilteredInvoiceDetailList(filters: { clientId: number, issuerId: number, seriesId: number, collaboratorId: number, from: string, to: string }) {
    return this._apiService.get(API.invoice.filterDeliveryNoteList + filters.clientId +
      '/' + filters.from + '/' + filters.to + '/' + filters.issuerId + '/' + filters.seriesId + '/' + filters.collaboratorId)
      .map(list => list as InvoiceDetail[]).toPromise();
  }

  GetInvoiceDetailsById(invoiceHeaderId: number) {
    return this._apiService.get(API.invoice.getInvoiceById + invoiceHeaderId)
      .map(inv => inv as InvoiceHeader).toPromise();
  }

  GetPendingDeliveryNoteListByClientId(clientId: number) {
    return this._apiService.get(API.invoice.getPendingDeliveryNotesByClientId + clientId)
      .map(list => list as DeliveryNote[]).toPromise();
  }

  /**
   *
   * @param subTotal | sum of sub totals in the invoice
   * @param discountAmount | discount as an amount
   */
  GetInvoiceTotalAmount(subTotal: number, discountAmount: number) {
    return subTotal - discountAmount;
  }

  /**
   *
   * @param subTotal | sum of sub totals in the invoice
   * @param percentageDiscount | discount as a percentage
   */
  GetInvoiceDiscountAmount(subTotal: number, percentageDiscount: number) {
    return subTotal * percentageDiscount / 100;
  }

  /**
   *
   * @param list | selected invoice detail list 
   */
  GetInvoiceSubTotalAmount(list: any[], prop: string) {
    return list.reduce((sum, current) => sum += Number(current[prop]), 0);
  }

  getInvoiceListByDateRange(start: string, end: string) {
    return this._apiService.get(API.invoice.get_invoice_list_by_date_range + start + '/' + end)
      .map(result => result as InvoiceDateRangeResult)
      .toPromise();
  }

  closeUnblockInvoices(closeInvoiceModel: { close: boolean, start: string, end: string }) {
    return this._apiService.post(API.invoice.close_unblock_invoices, closeInvoiceModel)
      .map(result => result as { updated: boolean, sections: string })
      .toPromise();
  }

  LoadVatBreakdown(list: IvaBreakdown[]) {
    // according to vat type the list is grouped and sum
    this.ivaBreakdownList = [];
    var groupList = _.toArray(_.groupBy(list, 'ivaTypeId')) as any[];
    if (groupList) {
      for (let i = 0; i < groupList.length; i++) {
        if (groupList[i][0] && groupList[i][0].ivaTypeId) {
          const totalVatAmount = groupList[i].reduce((sum, current) => sum + current.ivaAmount, 0);
          const totalBase = groupList[i].reduce((sum, current) => sum + current.base, 0);
          const iva = _.toArray(groupList[i]) as any[];
          if (iva && iva[0]) {
            this.ivaBreakdownList.push({
              ivaTypeId: iva[0].ivaTypeId,
              iva: iva[0].iva,
              base: totalBase,
              ivaAmount: totalVatAmount
            });
          }
        }
      }
    }
    return this.ivaBreakdownList;
  }


  public async SearchBillingClientPatient(query: string, pageName: string): Promise<BillingClientPatient[]> {
    return new Promise<BillingClientPatient[]>((resolve, reject) => {
      this._apiService.get(API.client.searchBillingClientPatientList + query + '/' + pageName)
        .subscribe(
          (list: BillingClientPatient[]) => resolve(list),
          err => reject(Error(err)),
          () => { });
    });
  }
}
