import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import { CommonFunctions } from '../../common/services/common-functions';
import API from './../../common/api.config.json';
import { Note } from '../entities/note';

@Injectable({
  providedIn: 'root'
})
export class NoteService {

  constructor(private _apiService: ApiService) { }

  GetNoteListByMainWorkTypeIdList(idList: { id: number }[]) {
    try {
      const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
      if (params) {
        const query = params.toString().replace('+', '%20');
        return this._apiService.get(API.work_panels.getNoteListByMainWorkTypeIdList + query, true)
          .map(n => n as Note[]).toPromise();
      }
    } catch (error) { console.log(error); }
  }

  SaveNote(note: Note) {
    return this._apiService.post(API.note.addNote, note, false).toPromise();
  }
}
