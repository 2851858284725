import { Injectable } from '@angular/core';
import { SubWork } from '../entities/sub-work';
import { MainWorkType } from '../entities/main-work-type';
import { WorkTypePhaseTemplate, WorkTypeTaskTemplate } from '../../work-type/entities/work-type-model-templates';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class WorkStatusService {

    statusChangedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    changedStatusObject = this.statusChangedSubject.asObservable();

    GetSubWorkStatus(mainWorkTypeList: MainWorkType[]): string {
        let status = 'Pendiente';
        if (mainWorkTypeList) {
            const workTypeStatusList: string[] = mainWorkTypeList.map(x => x.workTypeStatusName);
            if (workTypeStatusList) {
                status = workTypeStatusList.every(x => x === 'Finalizado') ? 'Finalizado' :
                    workTypeStatusList.every(x => x === 'Cancelada') ? 'Cancelada' : 'Pendiente';
            }
        }
        return status;
    }

    GetWorkStatus(subWorkList: SubWork[]): string {
        let status = 'Pendiente';
        if (subWorkList) {
            const subWorkStatusList: string[] = subWorkList.map(x => x.subWorkStatus);
            if (subWorkStatusList) {
                status = subWorkStatusList.every(x => x === 'Finalizado') ? 'Finalizado' :
                    subWorkStatusList.every(x => x === 'Cancelada') ? 'Cancelada' : 'Pendiente';
            }
        }
        return status;
    }

    GetMainWorkTypeStatus(phaseTemplateList: WorkTypePhaseTemplate[]): string {
        let status = 'Pendiente';
        if (phaseTemplateList && phaseTemplateList.length > 0) {
            const phaseStatusList: string[] = phaseTemplateList.map(x => x.phaseStatus);
            if (phaseStatusList) {
                status = (phaseStatusList.every(x => x === 'Finalizado') ||
                    phaseStatusList.every(x => x === 'Tareas finalizadas')) ? 'Finalizado' :
                    (phaseStatusList.every(x => x === 'Cancelada') ? 'Cancelada' : 'Pendiente');
            }
        }
        return status;
    }

    GetPhaseTemplateStatus(taskTemplateList: WorkTypeTaskTemplate[]): string {
        let status = 'Pendiente';
        if (taskTemplateList) {
            const taskStatusList: string[] = taskTemplateList.map(x => x.taskStatus);
            if (taskStatusList) {
                status = taskStatusList.every(x => x === 'Finalizado')
                    ? 'Tareas finalizadas' : taskStatusList.every(x => x === 'Cancelada') ?
                        'Cancelada' : 'Pendiente';
            }
        }
        return status;
    }

    // rxjs

    SetChangedStatus(isChanged: boolean) { this.statusChangedSubject.next(isChanged); }
}
