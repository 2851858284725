import { Injectable } from '@angular/core';
import { ToastyService, ToastData } from 'ng2-toasty';
import { ToastyTypes } from '../global';

@Injectable({
  providedIn: 'root'
})
export class FeedbackHandlerService {

  constructor(private _toastyService: ToastyService) { }

  ShowFeedback(type: string, title: string, msg: string) {
    if (type && title && msg) {
      const options = {
        title: title,
        msg: msg,
        showClose: true,
        theme: 'default',
        timeout: 5000,
        onAdd: (toast: ToastData) => { },
        onRemove: (toast: ToastData) => { }
      };
      switch (type) {
        case ToastyTypes.Success:
          this._toastyService.success(options);
          break;
        case ToastyTypes.Error:
          this._toastyService.error(options);
          break;
        case ToastyTypes.Warning:
          this._toastyService.warning(options);
          break;
        case ToastyTypes.Info:
          this._toastyService.info(options);
          break;
        default:
          break;
      }
    }
  }
}
