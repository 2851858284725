export class Guide {
  id: number;
  description: string;
  status: boolean;
  isEdit: boolean;

  isDescriptionRequired: boolean;

  constructor() {
    this.status = true;
  }
}
