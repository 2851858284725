import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Http, Headers, RequestOptions } from "@angular/http";
import { map } from "rxjs/operators";

import { debug } from "util";
import { RequestData } from "./requestData";

import API from '../../modules/common/api.config.json';
import { CommonUIService } from '../../modules/common/services/common.ui.service';
import { ApiService } from '../../modules/common/services/api.service';

declare var apiBaseUrl: any;
declare var user: any;

@Component({
    selector: "pagination",
    template: `
        <div aria-label="Page navigation">
            <ul class="pagination justify-content-center">
                <li class="{{requestData.page == 1 ? 'disabled' : ''}} page-item" (click)="GoToFirst()">
                    <a aria-label="Previous" class="page-link">
                        <span aria-hidden="true">&#60;</span>
                    </a>
                </li>
                <li class="{{requestData.page == 1 ? 'disabled' : ''}} page-item"  (click)="GoToPrevious()">
                    <a aria-label="Previous" class="page-link">
                        <span aria-hidden="true">&#60;&#60;</span>
                    </a>
                </li>

                <li *ngFor='let _item of pages' (click)="GoToPage(_item)" class="{{_item == (requestData.page) ? 'active' : ''}} page-item"><a class="page-link">{{_item}}</a></li>

                <li class="{{requestData.page == (pageCount) ? 'disabled' : ''}} page-item" (click)="GoToNext()">
                    <a aria-label="Next" class="page-link">
                        <span aria-hidden="true">&#62;&#62;</span>
                    </a>
                </li>
                <li class="{{requestData.page == (pageCount) ? 'disabled' : ''}} page-item"  (click)="GoToLast()">
                    <a aria-label="Next" class="page-link">
                        <span aria-hidden="true">&#62;</span>
                    </a>
                </li>
            </ul>
        </div>
    `
})
export class PaginationComponent implements OnInit {

    constructor(private http: Http, private _commonUiService: CommonUIService, private _apiService: ApiService) { }

    ngOnInit(): void {
        if (this.url != null && this.url! != undefined && this.postLoad === false) {
            if (this.url.charAt(0) != "/")
                this.url = "/" + this.url;
            this.GetData();
        }
    }

    baseServiceURL: string = this._apiService.BASE_URL;
    @Input() url: string;
    @Input() recordsPerPage: number = 20;
    @Input() postLoad: boolean = false;
    @Input() selectedPage: number = 1;
    @Input() requestData: RequestData = new RequestData(this.selectedPage, this.recordsPerPage, "", "", "", 0, false);
    pages: any = [];
    data: any = [];
    isLoading: boolean = true;
    isLoadingComplete: boolean = false;
    totalCount = 0;
    pageCount = 0;
    @Output() dataReturn = new EventEmitter();

    GetData() {
        let headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': atob(sessionStorage['access-token'])
        });

        let options = new RequestOptions({ headers: headers });
        this.isLoading = true;
        setTimeout(() => {
            this.data = [];
            this.dataReturn.emit(this.data);
        }, 100);
        this.requestData.page = this.requestData.page != 1 ? this.requestData.page : this.selectedPage;
        let params = new URLSearchParams();
        for (let key in this.requestData) {
            params.set(key, this.requestData[key])
        }
        setTimeout(() => {
            this._commonUiService.isSpinnerVisible = true;
            if (this.url) {
                let query = params.toString().replace("+", "%20")
                this.http.get(this.baseServiceURL + this.url + "?" + query, options)
                    .pipe(map(response => response.json()))
                    .subscribe(res => {

                        let _data: any = res;
                        if (_data.items != null) {
                            this.data = _data.items;
                            this.pageCount = _data.pageCount;
                            this.CreatPagination();
                            this.dataReturn.emit(this.data);
                            this.isLoadingComplete = true;
                            this.totalCount = Math.ceil(_data.totalCount / 20);
                        } else {
                            this.dataReturn.emit(this.data);
                            this.isLoadingComplete = true;
                        }
                        this._commonUiService.isSpinnerVisible = false;
                    },
                        err => {
                            this.isLoadingComplete = true;
                        },
                        () => {
                            if (this._commonUiService.isSpinnerVisible) { this._commonUiService.isSpinnerVisible = false; }
                        }
                    );
            } else {
                if (this._commonUiService.isSpinnerVisible) { this._commonUiService.isSpinnerVisible = false; }
            }
        }, 200);
    }

    CreatPagination() {
        this.pages = [];

        this.pages = this.getPages(this.selectedPage);

        // for (var i = 1; i <= this.pageCount; i++) {
        //     if (i >= (this.selectedPage - 3) && i <= (this.selectedPage + 3))
        //         this.pages.push(i);
        // }
    }

    GoToPage(pageNumber: number) {
        this.selectedPage = pageNumber;
        this.requestData.page = pageNumber;
        this.GetData();
    }

    GoToFirst() {
        if (this.selectedPage == 1)
            return;
        this.selectedPage = 1;
        this.requestData.page = 1;
        this.GetData();
    }

    GoToPrevious() {
        if (this.selectedPage == 1)
            return;
        this.selectedPage = this.selectedPage - 1;
        this.requestData.page = this.requestData.page - 1;
        this.GetData();
    }

    GoToLast() {
        if (this.selectedPage == (this.pages[this.pages.length - 1]))
            return;
        this.selectedPage = this.pageCount;
        this.requestData.page = this.pageCount;
        this.GetData();
    }

    GoToNext() {
        if (this.selectedPage == (this.pages[this.pages.length - 1]))
            return;
        this.selectedPage = this.selectedPage + 1;
        this.requestData.page = this.requestData.page + 1;
        this.GetData();
    }

    Refresh() {
        this.GetData();
    }


    getPages(currentPage) {
        let options = {
            visiblePages: 4,
            totalPages: this.pageCount
        }

        var pages = [];

        var half = Math.floor(options.visiblePages / 2);
        var start = (currentPage - half + 1) - (options.visiblePages % 2);
        var end = currentPage + half;

        var visiblePages = options.visiblePages;
        if (visiblePages > options.totalPages) {
            visiblePages = options.totalPages;
        }

        // handle boundary case
        if (start <= 0) {
            start = 1;
            end = visiblePages;
        }
        if (end > options.totalPages) {
            start = options.totalPages - visiblePages + 1;
            end = options.totalPages;
        }

        var itPage = start;
        while (itPage <= end) {
            pages.push(itPage);
            itPage++;
        }

        return pages;
    }

}

export class Paginate {
    page: number;
    numberOfRecords: number;
    query: string;
    advanceSearchDTO: AdvanceSearchDTO;

    constructor(page: number = 0, numberOfRecords: number = 10, query: string, advanceSearchDTO: AdvanceSearchDTO) {
        this.page = page;
        this.numberOfRecords = numberOfRecords;
        this.query = query;
        this.advanceSearchDTO = advanceSearchDTO;
    }
}

export class AdvanceSearchDTO {
    isAdvanceSearch: boolean;
    id: number;
    referenceID: number;
    status: number;
    clientID: number;
    reference: string;
    propertyID: number;
    unitID: number;
    constructor(id: number, isAdvanceSearch: boolean, referenceID: number, status: number, clientID: number, reference: string, propertyID: number, unitID: number) {
        this.id = id;
        this.referenceID = referenceID;
        this.isAdvanceSearch = isAdvanceSearch;
        this.status = status;
        this.clientID = clientID;
        this.reference = reference;
        this.propertyID = propertyID;
        this.unitID = unitID;
    }
}
