import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BreadcrumbsComponent } from './layout/admin/breadcrumbs/breadcrumbs.component';
import { ApiService } from './modules/common/services/api.service';
import { CommonDataService } from './modules/common/services/common.data.service';
import { HttpModule } from '@angular/http';
import { ToastyService, ToastyConfig } from 'ng2-toasty';
import { ToastrModule } from 'ngx-toastr';
import { CommonUIService } from './modules/common/services/common.ui.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NguCarouselModule } from '@ngu/carousel';
import { InvoiceModule } from './modules/invoice/invoice.module';
import { NgxTypeaheadModule } from 'ngx-typeahead';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { HttpErrorInterceptor } from './modules/common/http-error-interceptor';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FeedbackHandlerService } from './modules/common/services/feedback-handler.service';
import { CustomDatepickerI18nService } from './modules/common/services/custom-date-picker-i18n.service';
import { NgxMaskModule } from 'ngx-mask';
import { registerLocaleData } from '@angular/common';
import { CommonFunctions } from './modules/common/services/common-functions';
import localEn from '@angular/common/locales/en';
import localEs from '@angular/common/locales/es';
import localPt from '@angular/common/locales/pt';
import { DecimalPipe } from '@angular/common';
import { DropdownService } from './modules/common/services/dropdown.service';
import { NgbModule, NgbDatepickerI18n } from 'imp-ng-bootstrap';
import { DentboxValidationService } from './modules/common/dentbox/services/dentbox-validation.service';
import { DentboxServices } from './modules/common/bundles';
import { HttpLoaderFactory, DEFAULT_PERFECT_SCROLLBAR_CONFIG } from './modules/common/app-constants';
import { options } from './modules/auxiliary/trabajos-table/trabajos-table.module';
import { CalculationServices } from './modules/common/services/calculations/calculation';

registerLocaleData(localEn);
registerLocaleData(localEs);
registerLocaleData(localPt);

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    BreadcrumbsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    PerfectScrollbarModule,
    AppRoutingModule,
    NgbModule.forRoot(),
    NguCarouselModule,
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right'
    }),
    InvoiceModule,
    DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxMaskModule.forRoot(options),
    NgxTypeaheadModule,
    NgxPermissionsModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.2)',
      backdropBorderRadius: '3px',
      primaryColour: '#007bff',
      secondaryColour: '#95c41f',
      tertiaryColour: '#95c41f'
    })
  ],
  schemas: [],
  providers: [
    ApiService,
    CommonDataService,
    DropdownService,
    ToastyService,
    ToastyConfig,
    CommonUIService,
    DentboxValidationService,
    DecimalPipe,
    { provide: LOCALE_ID, useValue: CommonFunctions.GetBrowserLocale() },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpErrorInterceptor,
    //   multi: true
    // },
    FeedbackHandlerService,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService },
    DentboxServices,
    CalculationServices
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }



