import { Injectable, Output, EventEmitter } from '@angular/core';
import { Patient } from '../../work/entities/patient';

@Injectable({
  providedIn: 'root'
})
export class WorkModalService {
  public onOpenPatientModal: EventEmitter<void> = new EventEmitter();
  public onAddNewPatient: EventEmitter<Patient> = new EventEmitter();
}
