import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import API from './../../common/api.config.json';
import { WarehouseStockMovement } from '../entities/warehouse-stock-movement';
import { ProductLot } from '../entities/product-lot';

@Injectable({
  providedIn: 'root'
})
export class StockMovementService {

  constructor(private _apiService: ApiService) { }

  SaveStockMovement(movement: WarehouseStockMovement) {
    return this._apiService.post(API.product.addWarehouseStockMovement, movement).toPromise();
  }

  SaveProductLot(productLot: ProductLot) {
    return this._apiService.post(API.product.addProductLot, productLot, true).toPromise();
  }

  GetProductLotByName(name: string) {
    return this._apiService.get(API.product.GetProductLotByName + name, true).toPromise();
  }
}
