import * as CryptoJS from 'crypto-js';

export class Crypto {
    static secret: string = 'novalab123';
    static dentboxSecret: string = 'e8226795bfc0edb0cace64b44fd0cc1a';
    static keySize: number = 256;
    static ivSize: number = 128;
    static saltSize: number = 256;
    static iterations: number = 1000;

    static Encrypt(cipherText: string) {
        var key = CryptoJS.enc.Utf8.parse(this.secret);
        var srcs = CryptoJS.enc.Utf8.parse(cipherText);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return encrypted.toString();
    }

    static Decrypt(encryptedText: string) {
        var key = CryptoJS.enc.Utf8.parse(this.secret);
        var decrypt = CryptoJS.AES.decrypt(encryptedText, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    }

    static Encrypt2(cipherText: string): string {
        let salt = CryptoJS.lib.WordArray.random(this.saltSize / 8);
        let key = CryptoJS.PBKDF2(this.secret, salt, {
            keySize: this.keySize / 32,
            iterations: this.iterations
        });
        let iv = CryptoJS.lib.WordArray.random(this.ivSize / 8);
        let encrypted = CryptoJS.AES.encrypt(cipherText, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });
        let encryptedHex = this.base64ToHex(encrypted.toString());
        let base64result = this.hexToBase64(salt + iv + encryptedHex);
        return base64result;
    }

    static Decrypt2(encryptedText: any): string {
        let hexResult = this.base64ToHex(encryptedText)
        let salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64));
        let iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32));
        let encrypted = this.hexToBase64(hexResult.substring(96));
        let key = CryptoJS.PBKDF2(this.secret, salt, {
            keySize: this.keySize / 32,
            iterations: this.iterations
        });
        let decrypted = CryptoJS.AES.decrypt(encrypted, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        })
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    static EncryptLicenseKey(cipherText: string): string {
        var key = CryptoJS.enc.Utf8.parse(this.dentboxSecret);
        var srcs = CryptoJS.enc.Utf8.parse(cipherText);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return encrypted.toString();
    }

    static DecryptLicenseKey(encryptedText: any): string {
        var key = CryptoJS.enc.Utf8.parse(this.dentboxSecret);
        var decrypt = CryptoJS.AES.decrypt(encryptedText, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    }

    private static hexToBase64(str): string {
        return btoa(String.fromCharCode.apply(null,
            str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
        );
    }

    private static base64ToHex(str): string {
        for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
            var tmp = bin.charCodeAt(i).toString(16);
            if (tmp.length === 1) tmp = "0" + tmp;
            hex[hex.length] = tmp;
        }
        return hex.join("");
    }

}

