import {
  ChangeDetectorRef, Component, EventEmitter,
  Input, OnInit,
  Output, ViewChild
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ToastData, ToastyService } from 'ng2-toasty';
import { ClinicMaterial } from 'src/app/modules/auxiliary/entities/clinic-material';
import { Color } from 'src/app/modules/auxiliary/entities/Color';
import { Localizations } from 'src/app/modules/auxiliary/entities/localizations';
import { Metals } from 'src/app/modules/auxiliary/entities/metals';
import { DropDownOption } from 'src/app/modules/common/entities/dropdown-option';
import { ModalNames, Piezas } from 'src/app/modules/common/global';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonFunctions } from 'src/app/modules/common/services/common-functions';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { CheckProperties, ShowInvalidFormControls, ValidateNgSelect } from 'src/app/modules/common/services/validators';
import { WorkType } from 'src/app/modules/work-type/entities/work-type';
import { WorkTypeArticleTemplate } from 'src/app/modules/work-type/entities/work-type-model-templates';
import { Document } from '../../entities/document';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtClinicMaterial } from '../../entities/main-work-type-clinic-material';
import { MwtColor } from '../../entities/main-work-type-color';
import { MwtImplantData } from '../../entities/main-work-type-implant-data';
import { Note } from '../../entities/note';
import { Prescription } from '../../entities/prescription';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { WorkConfig } from '../../entities/work-cofig';
import { ArticleTemplateService } from '../../services/article-template.service';
import { MwtColorService } from '../../services/mwt-color.service';
import { NoteService } from '../../services/note.service';
import { SubWorkService } from '../../services/sub-work.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';
import { LabHolidaysService } from '../../services/lab-holidays.service';
import { WorkStatusService } from '../../services/work-status.service';

@Component({
  selector: 'app-main-work-type-modal',
  templateUrl: './main-work-type-modal.component.html',
  styleUrls: ['./main-work-type-modal.component.scss']
})
export class MainWorkTypeModalComponent implements OnInit {
  @Output() refreshData: EventEmitter<any> = new EventEmitter();
  showContent = false;
  @Input() selectedWork: Work;
  @ViewChild('observationsElement') observationsElement: any;
  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  mainWorkTypeStatusDropDownList: DropDownOption[] = [];

  // list
  colorList: Color[] = [];
  localizationList: Localizations[] = [];
  metalList: Metals[] = [];
  workTypeTemplateList: WorkType[] = [];
  workTypeArticleList: WorkTypeArticleTemplate[] = [];
  selectedTeethList: number[] = [];
  clinicMaterialList: ClinicMaterial[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];
  mainWorkTypeList: MainWorkType[] = [];
  prescriptionList: Prescription[] = [];

  // form groups
  mainWorkTypeFormGroup: FormGroup;

  mainWorkType: MainWorkType;
  implantData: MwtImplantData;
  mwtColor: MwtColor;
  showPieces = false;
  mwtClinicMaterial: MwtClinicMaterial;
  prescription: Prescription;
  document: Document;
  workConfig: WorkConfig;
  selectedSubWork: SubWork;
  refresh: boolean;
  allowedImplant = false;
  isPrescriptionListLoaded = true;
  editableColorRowIndex = -1;
  PIEZAS = Piezas;

  constructor(private _workService: WorkService,
    private _apiService: ApiService,
    private _subWorkService: SubWorkService,
    public commonUiService: CommonUIService,
    public commonDataService: CommonDataService,
    private _dropdownService: DropdownService,
    private _modalService: ModalService,
    private _formBuilder: FormBuilder,
    private _toastyService: ToastyService,
    private _noteService: NoteService,
    private _mwtColorService: MwtColorService,
    private _articleTemplateService: ArticleTemplateService,
    private cdref: ChangeDetectorRef,
    private _labHolidaysService: LabHolidaysService,
    private _statusService: WorkStatusService) {
    this.InitModels();
    this.InitFormGroups();
    this.showContent = true;
  }

  async ngOnInit() {
    this.InitModels();
    this.InitFormGroups();
    if (this._modalService.modal === ModalNames.MainWorkTypeModal) {
      if (this._modalService.transferable) {
        this.mainWorkTypeStatusDropDownList = this._modalService.transferable.mainWorkTypeStatusDropDownList as DropDownOption[];
        this.workConfig = this._modalService.transferable.workConfig;
        this.selectedWork = this._modalService.transferable.selectedWork;
        this.selectedSubWork = this._modalService.transferable.selectedSubWork;
        if (this._modalService.transferable.isEdit) {
          await this.OnEditMainWorkType(this._modalService.transferable.mainWorkType as MainWorkType);
        } else {
          await this.OnOpenAddWorkTypeModal();
        }

      }
    }
  }

  /**
 * Load all form groups with their validations
 */
  private InitFormGroups() {
    this.mainWorkTypeFormGroup = this._formBuilder.group({
      name: [], workStatusId: ['', [ValidateNgSelect]],
      receiveDate: [], deliveryDate: [], bateaNo: [],
      deliveryTime: ['', [Validators.minLength(4)]],
      realDeliveryDate: [], realDeliveryTime: [],
      workTypeId: ['', [ValidateNgSelect]], workTypeName: ['', [ValidateNgSelect]],
      providerManufacId: [], model: ['', []],
      reference: [], longitud: [], pieces: [], occlusal: [],
      note: [], clinicMaterialName: [], quantity: [],
      materialNote: [], colorName: [],
      piecesSelectionText: ['', Validators.pattern('^[1-9]{2,2}(,[1-9]{2,2})*$')],
      localizationName: [], colorArticleName: [], colorMetalName: [],
      prescription: [], document: [], colorFormArray: this._formBuilder.array([])
    });
  }

  private CreateColorFormGroup(init?: {
    colorArticleText: string,
    colorArticleId: number,
    colorMetalText: string;
    colorMetalId: number;
    colorLocalizationText: string,
    colorLocalizationId: number,
    colorColorText: string,
    colorColorId: number,
    colorPiezasText: string
  }) {
    let group = {
      colorArticleText: [], colorArticleId: [],
      colorMetalText: [], colorMetalId: [],
      colorLocalizationText: [], colorLocalizationId: [],
      colorColorText: [], colorColorId: [], colorPiezasText: ['', Validators.pattern('^[0-9]{1,3}(,[0-9]{1,3})*$')]
    };
    if (init) {
      group = {
        colorArticleText: [init.colorArticleText],
        colorArticleId: [init.colorArticleId],
        colorMetalText: [init.colorMetalText],
        colorMetalId: [init.colorMetalId],
        colorLocalizationText: [init.colorLocalizationText],
        colorLocalizationId: [init.colorLocalizationId],
        colorColorText: [init.colorColorText],
        colorColorId: [init.colorColorId],
        colorPiezasText: [init.colorPiezasText, Validators.pattern('^[0-9]{1,3}(,[0-9]{1,3})*$')]
      };
    }
    return this._formBuilder.group(group);
  }

  private AddColorFormGroup(init?: {
    colorArticleText: string,
    colorArticleId: number,
    colorMetalText: string,
    colorMetalId: number,
    colorLocalizationText: string,
    colorLocalizationId: number,
    colorColorText: string,
    colorColorId: number,
    colorPiezasText: string
  }) {
    const colorGroups = this.mainWorkTypeFormGroup.controls.colorFormArray as FormArray;
    colorGroups.push(this.CreateColorFormGroup(init ? init : undefined));
  }

  InitModels() {
    this.mainWorkType = new MainWorkType();
    this.implantData = new MwtImplantData();
    this.mwtColor = new MwtColor();
    this.showPieces = false;
    this.mwtClinicMaterial = new MwtClinicMaterial();
    this.prescription = new Prescription();
    this.document = new Document();
    this.workConfig = new WorkConfig();
    this.selectedSubWork = new SubWork();
  }

  async OnOpenAddWorkTypeModal() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      this.mainWorkTypeFormGroup.reset();
      this.mainWorkTypeFormGroup.controls.colorArticleName.disable();
      this.mainWorkTypeFormGroup.controls.colorFormArray = this._formBuilder.array([]);
      this.selectedTeethList = undefined;
      this.mainWorkType = new MainWorkType();
      const option = this.mainWorkTypeStatusDropDownList.find(x => x.label.toLowerCase() === 'pendiente');
      if (option) {
        this.mainWorkType.workTypeStatusId = option.value.toString();
        this.mainWorkTypeFormGroup.controls.workStatusId.setValue(option.value);
      }
      this.cdref.markForCheck();
      const list = this._dropdownService.workTypeTemplateList;
      this.cdref.markForCheck();
      if (list) {
        this.workTypeTemplateList = list.filter(x => x.status);

      }
      const localizationList = this._dropdownService.localizationList;
      this.localizationList = localizationList ? localizationList : [];
      this.colorList = this._dropdownService.colorList;
      this.metalList = this._dropdownService.metalList;
      this.clinicMaterialList = this._dropdownService.clinicMaterialList;
      this.mainWorkType.id = '0';
      this.mainWorkType.workTypeName = '';
      this.mainWorkType.receiveDate = this.commonUiService.ConvertToPickerDateFormat(moment().format('YYYY-MM-DD'));
      this.selectedTeethList = [];
      this.showPieces = false;
      // this.mainWorkTypeFormGroup.markAsUntouched();
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    } catch (error) { console.log(error); }
  }

  AddMwtColorToList() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (this.mwtColor) {
        const group = {
          colorArticleText: '',
          colorMetalText: '',
          colorMetalId: null,
          colorArticleId: null,
          colorLocalizationText: '',
          colorLocalizationId: null,
          colorColorText: '',
          colorColorId: null,
          colorPiezasText: ''
        };

        if (this.mwtColor.mwtArticleId) {
          group.colorArticleId = Number(this.mwtColor.mwtArticleId);
          group.colorArticleText = this.mwtColor.mwtArticleName;
        }
        if (this.mwtColor.metalId) {
          group.colorMetalId = Number(this.mwtColor.metalId);
          group.colorMetalText = this.mwtColor.metalName;
        }
        if (this.mwtColor.colorId) {
          group.colorColorId = Number(this.mwtColor.colorId);
          group.colorColorText = this.mwtColor.colorText;
        }
        if (this.mwtColor.localizationId) {
          group.colorLocalizationId = Number(this.mwtColor.localizationId);
          group.colorLocalizationText = this.mwtColor.localizationText;
        }
        if (this.mwtColor.pieces) { group.colorPiezasText = this.mwtColor.pieces; }
        this.AddColorFormGroup(group);
      }
    } catch (error) { console.log(error); } finally {
      this.mwtColor = new MwtColor();
      this.mainWorkTypeFormGroup.controls.colorArticleName.setValue('');
      this.mainWorkTypeFormGroup.controls.colorMetalName.setValue('');
      this.mainWorkTypeFormGroup.controls.localizationName.setValue('');
      this.mainWorkTypeFormGroup.controls.colorName.setValue('');
      this.mainWorkTypeFormGroup.controls.piecesSelectionText.setValue('');
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnEditMainWorkType(mainWorkType: MainWorkType) {
    this.mainWorkTypeFormGroup.markAsPristine();
    this.mainWorkTypeFormGroup.markAsUntouched();
    this.workTypeTemplateList = this._dropdownService.workTypeTemplateList;
    // if (list) {
    //   this.workTypeTemplateList = list.filter(x => x.status);
    //   this.cdref.markForCheck();
    // }
    if (mainWorkType !== undefined) {
      Object.assign(this.mainWorkType, mainWorkType);
      this.mainWorkType.workTypeId = this.mainWorkType.workTypeId.toString();
      if (this.mainWorkType.workTypeName) {
        const workType: WorkType = this.workTypeTemplateList.find(x => Number(x.id) === Number(this.mainWorkType.workTypeId));
        if (workType) {
          this.mainWorkType.workTypeName = workType.description;
          this.mainWorkTypeFormGroup.controls.workTypeId.setValue(workType.id);
          this.mainWorkTypeFormGroup.controls.workTypeName.setValue(workType.description);
        }
      }
      if (this.mainWorkType.deliveryDate) {
        this.mainWorkType.deliveryDateView = this.commonUiService.ConvertToPickerDateFormat(this.mainWorkType.deliveryDate);
      } else {
        this.mainWorkType.deliveryDateView = null;
      }

      if (this.mainWorkType.receiveDate !== undefined && this.mainWorkType.receiveDate !== null) {
        this.mainWorkType.receiveDate = this.commonUiService.ConvertToPickerDateFormat(this.mainWorkType.receiveDate);
      }

      if (this.mainWorkType.realDeliveryDate) {
        this.mainWorkType.realDeliveryDate = this.commonUiService.ConvertToPickerDateFormat(this.mainWorkType.realDeliveryDate);
      }

      if (this.mainWorkType.workTypeStatusId) { this.mainWorkType.workTypeStatusId = this.mainWorkType.workTypeStatusId.toString(); }
      if (this.observationsElement) { this.observationsElement.nativeElement.click(); }
    }
    this.cdref.markForCheck();
  }

  OnClose() {
    this.showContent = false;
    this.commonUiService.isPopupOpened = false;
    this.closeModel.emit();
  }

  OnSelectColorLocalization(localization: Localizations) {
    if (localization) {
      this.mwtColor.localizationId = Number(localization.id);
      this.mwtColor.localizationText = localization.description;
    }
    this.cdref.markForCheck();
  }

  OnChangeWorkType(workType: WorkType) {
    if (workType) {
      this.mainWorkType.workTypeId = Number(workType.id);
      this.mainWorkType.workTypeName = workType.description;
      this.mainWorkType.isImplantData = workType.isImplant;
      this.mainWorkTypeFormGroup.controls.workTypeId.setValue(workType.id);
      this.LoadArticleListByWorkTypeTemplateId(this.mainWorkType.workTypeId);
    }
    this.cdref.markForCheck();
  }

  OnMwtStatusChanged() {
    if (Number(this.mainWorkType.workTypeStatusId) > 0) {
      this.mainWorkType.manualStatus = true;
    } else {
      this.mainWorkType.manualStatus = false;
    }
    this.cdref.markForCheck();
  }

  OnImplantAccordianChange() {
    this.mainWorkTypeFormGroup.controls['model'].clearValidators();
    this.mainWorkTypeFormGroup.controls['model'].setErrors(null);
    this.mainWorkTypeFormGroup.controls['model'].setValidators(null);

    this.mainWorkTypeFormGroup.controls['reference'].clearValidators();
    this.mainWorkTypeFormGroup.controls['reference'].setErrors(null);
    this.mainWorkTypeFormGroup.controls['reference'].setValidators(null);

    this.mainWorkTypeFormGroup.controls['reference'].updateValueAndValidity();
    this.mainWorkTypeFormGroup.controls['model'].updateValueAndValidity();

    this.implantData = new MwtImplantData();
    this.cdref.markForCheck();
  }

  OnSelectColorMwtArticle(article: WorkTypeArticleTemplate) {
    if (article) {
      this.mwtColor.mwtArticleId = article.articleId;
      this.mwtColor.mwtArticleName = article.articleName;
    }
    this.cdref.markForCheck();
  }

  private LoadArticleListByWorkTypeTemplateId(workTypeId: number) {
    this._apiService.get(API.work_type.getArticleListById + workTypeId)
      .subscribe((list: WorkTypeArticleTemplate[]) => {
        this.workTypeArticleList = list;
        if (this.workTypeArticleList.length > 0) {
          this.mainWorkTypeFormGroup.controls.colorArticleName.enable();
        } else {
          this.mainWorkTypeFormGroup.controls.colorArticleName.disable();
        }
        this.cdref.markForCheck();
      }
        ,
        err => console.log(err));
  }

  OnChangeWorkTypeColor(color: Color) {
    if (color) {
      this.mwtColor.colorId = Number(color.id);
      this.mwtColor.colorText = color.description;
    }
    this.cdref.markForCheck();
  }

  OnChangeWorkTypeColorMetal(metal: Metals) {
    if (metal) {
      this.mwtColor.metalId = Number(metal.id);
      this.mwtColor.metalName = metal.description;
    }
    this.cdref.markForCheck();
  }

  OnChangePiezasCheckbox() { this.showPieces = !this.showPieces; this.cdref.markForCheck(); }

  OnSelectPieces(pieces: number[]) {
    if (pieces) {
      this.mwtColor.pieces = this.mwtColor.pieces ? this.mwtColor.pieces : '';
      pieces.forEach(piece => {
        if (!this.mwtColor.pieces.includes(piece.toString())) {
          this.mwtColor.pieces = this.mwtColor.pieces + (this.mwtColor.pieces ? ', ' : '') + piece;
        }
      });
    }
    this.cdref.markForCheck();
  }

  OnTypePiezasNumber() {
    this.mwtColor.pieces = CommonFunctions.ValidatePiezasNumber(this.mwtColor.pieces);
  }

  OnTypeColorPiezasNumber(control: any) {
    const value = CommonFunctions.ValidatePiezasNumber(control.value);
    control.setValue(value);
  }

  SetColorColorId(color: Color, group: FormGroup) {
    if (color && group) {
      group.controls.colorColorId.setValue(Number(color.id));
    }
  }

  SetColorLocalizationId(localization: Localizations, group: FormGroup) {
    if (localization && group) {
      group.controls.colorLocalizationId.setValue(Number(localization.id));
    }
  }

  OnRemoveColorRow(index: number) {
    const control = <FormArray>this.mainWorkTypeFormGroup.controls.colorFormArray;
    control.removeAt(index);
  }

  OnChangeWorkTypeClinicMaterial(clinicMaterial: ClinicMaterial) {
    if (clinicMaterial) {
      this.mwtClinicMaterial.clinicMaterialId = Number(clinicMaterial.id);
      this.mwtClinicMaterial.clinicMaterialName = clinicMaterial.description;
    }
    this.cdref.markForCheck();
  }

  OnChoosePrescription(event: any) {
    if (event) {
      const files: any = event.target.files;
      this.prescription.fileList = files;
    }
    this.cdref.markForCheck();
  }

  OnChooseDocument(event: any) {
    if (event) {
      const files: any = event.target.files;
      this.document.fileList = files;
    }
    this.cdref.markForCheck();
  }

  OnSaveMainWorkType(event?: any) {
    try {
      if (this.mainWorkType.id && this.mainWorkType.id !== '0') { // edit

        this.mainWorkTypeFormGroup.controls['workTypeName'].clearValidators();
        this.mainWorkTypeFormGroup.controls['workTypeName'].setErrors(null);
        this.mainWorkTypeFormGroup.controls['workTypeName'].setValidators(null);

        this.mainWorkTypeFormGroup.controls['colorArticleName'].clearValidators();
        this.mainWorkTypeFormGroup.controls['colorArticleName'].setErrors(null);
        this.mainWorkTypeFormGroup.controls['colorArticleName'].setValidators(null);

        this.mainWorkTypeFormGroup.controls['clinicMaterialName'].clearValidators();
        this.mainWorkTypeFormGroup.controls['clinicMaterialName'].setErrors(null);
        this.mainWorkTypeFormGroup.controls['clinicMaterialName'].setValidators(null);

        this.mainWorkTypeFormGroup.controls['reference'].clearValidators();
        this.mainWorkTypeFormGroup.controls['reference'].setErrors(null);
        this.mainWorkTypeFormGroup.controls['reference'].setValidators(null);

        this.mainWorkTypeFormGroup.controls['model'].clearValidators();
        this.mainWorkTypeFormGroup.controls['model'].setErrors(null);
        this.mainWorkTypeFormGroup.controls['model'].setValidators(null);
      } else {
        // add
        this.mainWorkTypeFormGroup.controls['workTypeName'].setValidators(ValidateNgSelect);
        if (!CheckProperties(this.mwtClinicMaterial)) {
          this.mainWorkTypeFormGroup.controls['clinicMaterialName'].setValidators(ValidateNgSelect);
        }
        if (!CheckProperties(this.implantData)) {
          this.mainWorkTypeFormGroup.controls['reference'].setValidators(Validators.required);
          this.mainWorkTypeFormGroup.controls['model'].setValidators(Validators.required);
        }
        // if (this.showPieces && !CheckProperties(this.mwtColor)) {
        //   this.mainWorkTypeFormGroup.controls['colorArticleName'].setValidators(ValidateNgSelect);
        // }
      }
      this.cdref.markForCheck();

      // revoke
      this.mainWorkTypeFormGroup.controls['clinicMaterialName'].updateValueAndValidity();
      this.mainWorkTypeFormGroup.controls['reference'].updateValueAndValidity();
      this.mainWorkTypeFormGroup.controls['model'].updateValueAndValidity();
      this.mainWorkTypeFormGroup.controls['workTypeName'].updateValueAndValidity();
      this.mainWorkTypeFormGroup.controls['colorArticleName'].updateValueAndValidity();

      if (this.mainWorkTypeFormGroup.invalid) { ShowInvalidFormControls(this.mainWorkTypeFormGroup); return; }
      this.mainWorkType.workId = this.selectedWork.id;
      this.mainWorkType.clientId = this.selectedWork.clientId;

      let deliveryDate: string, recievedDate: string, realDeliveryDate: string;

      if (this.mainWorkType.deliveryTime) {
        if (!this.mainWorkType.deliveryTime.toString().includes(':')) {
          this.mainWorkType.deliveryTime = CommonFunctions.Get24HoursTimeFormat(this.mainWorkType.deliveryTime);
        }
      }
      if (this.mainWorkType.realDeliveryTime) {
        if (!this.mainWorkType.realDeliveryTime.toString().includes(':')) {
          this.mainWorkType.realDeliveryTime = CommonFunctions.Get24HoursTimeFormat(this.mainWorkType.realDeliveryTime);
        }
      }
      if (this.mainWorkType.deliveryDateView) {
        deliveryDate = this.commonUiService.ChangeNgbDateToCustomFormat(this.mainWorkType.deliveryDateView);
        this.mainWorkType.deliveryDate = deliveryDate;
      } else { this.mainWorkType.deliveryDate = null; }

      if (this.mainWorkType.receiveDate) {
        recievedDate = this.commonUiService.ChangeNgbDateToCustomFormat(this.mainWorkType.receiveDate);
        this.mainWorkType.receiveDate = recievedDate;
      } else { this.mainWorkType.receiveDate = null; }

      if (this.mainWorkType.realDeliveryDate) {
        realDeliveryDate = this.commonUiService.ChangeNgbDateToCustomFormat(this.mainWorkType.realDeliveryDate);
        this.mainWorkType.realDeliveryDate = realDeliveryDate;
      } else { this.mainWorkType.realDeliveryDate = null; }

      this.mainWorkType.name = this.mainWorkType.name ? this.mainWorkType.name : '';

      if (this.mainWorkType.id && this.mainWorkType.id !== '0') {
        this.commonUiService.isSpinnerVisible = true;
        this._workService.UpdateMainWorkType(this.mainWorkType)
          .then(async response => {
            const body = JSON.parse(response['_body']);
            if (body['_isSuccsess']) {
              this._toastyService.success({
                title: this.commonDataService.localizationLabelList['work_type'],
                msg: this.commonDataService.localizationLabelList['save_success'],
                showClose: true,
                theme: 'default',
                timeout: 5000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
              const list = await this._subWorkService.GetSubWorkListByWorkId(this.selectedWork.id);
              if (list) {
                const subWork = list.find(x => x.id === this.selectedSubWork.id);
                this.selectedSubWork = subWork ? subWork : this.selectedSubWork;
              }

              this._statusService.SetChangedStatus(true);
              this.showContent = false;
              this.refreshData.emit(this.selectedSubWork);
              this.commonUiService.isSpinnerVisible = false;
            } else {
              this._toastyService.error({
                title: this.commonDataService.localizationLabelList['work_type'],
                msg: this.commonDataService.localizationLabelList['save_error'],
                showClose: true,
                theme: 'default',
                timeout: 5000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
            }
            this.showContent = false;
            this.refresh = true;
            this.commonUiService.isSpinnerVisible = false;
          });
      } else {
        // add
        this.mainWorkType.workId = this.selectedWork.id;
        this.mainWorkType.clientId = this.selectedWork.clientId ? this.selectedWork.clientId : null;
        this.mainWorkType.subWorkId = this.selectedSubWork.id;

        const now = new Date();
        const timeArr: string[] = now.toTimeString().substring(0, 8).split(':');
        const dateArr: string[] = now.toISOString().substring(0, 10).split('-');
        if (timeArr && dateArr) {
          this.mainWorkType.generateNo = dateArr[2] + dateArr[1] + dateArr[0] + timeArr[0] + timeArr[1] + timeArr[2];
        }

        this.commonUiService.isSpinnerVisible = true;

        this._workService.AddMainWorkType(this.mainWorkType)
          .then(async response => {
            const body = JSON.parse(response['_body']);
            const mainWorkType = body['_data'] as MainWorkType;
            if (this.mainWorkType.name) {
              const clinicNote = new Note({
                type: 'clinic',
                createdDateTime: moment().format('YYYY-MM-DD'),
                modifiedDateTime: moment().format('YYYY-MM-DD'),
                content: this.mainWorkType.name,
                mainWorkTypeId: Number(mainWorkType.id),
                status: true
              });
              await this._noteService.SaveNote(clinicNote);
            }
            if (body['_statusCode'] === 200) {
              this._toastyService.success({
                title: this.commonDataService.localizationLabelList['work_type'],
                msg: this.commonDataService.localizationLabelList['save_success'],
                showClose: true,
                theme: 'default',
                timeout: 5000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
              this.showContent = false;
              this.commonUiService.isSpinnerVisible = false;
            } else {
              this._toastyService.error({
                title: this.commonDataService.localizationLabelList['work_type'],
                msg: this.commonDataService.localizationLabelList['save_error'],
                showClose: true,
                theme: 'default',
                timeout: 5000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
            }
            this.showContent = false;
            this.commonUiService.isSpinnerVisible = false;
            if (mainWorkType) {
              const artList = await this._articleTemplateService.GetArticleTemplateListByMainWorkTypeIdList(
                [{ id: Number(mainWorkType.id) }]);

              if (this.mainWorkTypeFormGroup.controls.colorFormArray.value) {
                const arr = this.mainWorkTypeFormGroup.controls.colorFormArray.value as {
                  colorArticleText: string,
                  colorArticleId: number,
                  colorMetalText: string;
                  colorMetalId: number;
                  colorLocalizationText: string,
                  colorLocalizationId: number,
                  colorColorText: string,
                  colorColorId: number,
                  colorPiezasText: string
                }[];

                const mwtColorList = arr.map(x => (new MwtColor({
                  mwtArticleId: x.colorArticleId ? Number(x.colorArticleId) : null,
                  localizationId: x.colorLocalizationId ? Number(x.colorLocalizationId) : null,
                  metalId: x.colorMetalId ? Number(x.colorMetalId) : null,
                  colorId: x.colorColorId ? Number(x.colorColorId) : null,
                  pieces: x.colorPiezasText,
                  status: true,
                  mainWorkTypeId: Number(mainWorkType.id),
                  workId: this.selectedWork.id
                })));

                if (artList) {
                  mwtColorList.forEach(async color => {
                    const article = artList.find(x => x.articleId === color.mwtArticleId);
                    color.mwtArticleId = article ? Number(article.mwtArticleId) : null;
                  });
                }
                await this._mwtColorService.SaveAllMwtColors(mwtColorList);
              }
              if (this.implantData.model) {
                this.implantData.mainWorkTypeId = Number(mainWorkType.id);
                this.implantData.workId = this.selectedWork.id;
                await this.SaveImplantData(this.implantData);
              }
              if (this.mwtClinicMaterial.clinicMaterialId) {
                this.mwtClinicMaterial.mainWorkTypeId = Number(mainWorkType.id);
                this.mwtClinicMaterial.workId = this.selectedWork.id;
                await this.SaveMwtClinicMaterial(this.mwtClinicMaterial);
              }
              if (this.prescription.fileList) {
                this.prescription.subWorkId = mainWorkType.subWorkId; // this.selectedSubWork.id;
                if (this.prescription.fileList.length > 0) {
                  this.prescription.fileName = (this.prescription.fileList[0]).name;
                  this.prescription.extension = ((this.prescription.fileList[0]).name).split('.')
                  [(((this.prescription.fileList[0]).name).split('.')).length - 1];
                  const prescription = await this.AddPrescription(this.prescription);
                  if (prescription) {
                    const formData: FormData = new FormData();
                    formData.append(
                      prescription.fileName,
                      this.prescription.fileList[0],
                      prescription.generatedFileName);
                    formData.append('folderName', 'Prescriptions');
                    formData.append('id', prescription.id.toString());
                    this.UploadPrescription(formData);
                  }
                }
              }
              if (this.document.fileList) {
                this.document.mainWorkTypeId = Number(mainWorkType.id);
                if (this.document.fileList.length > 0) {
                  this.document.fileName = (this.document.fileList[0]).name;
                  this.document.extension = ((this.document.fileList[0]).name).split('.')
                  [(((this.document.fileList[0]).name).split('.')).length - 1];
                  const doc = await this._workService.AddDocument(this.document);
                  if (doc) {
                    const formData: FormData = new FormData();
                    formData.append(doc.fileName, this.document.fileList[0], doc.generateFileName);
                    formData.append('folderName', 'Document');
                    formData.append('id', doc.id.toString());
                    const documentResponse = await this._workService.UploadDocument(formData);
                    if (documentResponse) {
                      if (documentResponse['body'] === 200) {
                        this._toastyService.success({
                          title: this.commonDataService.localizationLabelList['document'],
                          msg: this.commonDataService
                            .localizationLabelList['document_upload_successfully'],
                          showClose: true,
                          theme: 'default',
                          timeout: 5000,
                          onAdd: (toast: ToastData) => { },
                          onRemove: (toast: ToastData) => { }
                        });
                      }
                    }
                  }
                }
              }
            }
            this.refresh = true;
            const list = await this._subWorkService.GetSubWorkListByWorkId(this.selectedWork.id);
            if (list) {
              const subWork = list.find(x => x.id === this.selectedSubWork.id);
              this.selectedSubWork = subWork ? subWork : this.selectedSubWork;
            }
            this.refreshData.emit(this.selectedSubWork);
            if (event) { this.showContent = false; }
            this.commonUiService.isSpinnerVisible = false;
          });
      }
      this.selectedTeethList = [];
      //  this.mainWorkTypeFormGroup.markAsUntouched();
      this.cdref.markForCheck();
    } catch (error) { console.log(error); }
  }

  //#region ImplantData

  private async SaveImplantData(implantData: MwtImplantData): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.post(API.main_work_type_work.saveImplantData, implantData, false, true)
        .subscribe(res => resolve(JSON.parse(res['_body'])),
          err => reject(Error(err)));
    });
  }
  //#endregion

  //#region Clinic Materials

  private async SaveMwtClinicMaterial(mwtClinicMaterial: MwtClinicMaterial): Promise<MwtClinicMaterial> {
    return new Promise<MwtClinicMaterial>((resolve, reject) => {
      this._apiService.post(API.main_work_type_work.saveMwtClinicMaterial, mwtClinicMaterial, true, true)
        .subscribe((clinicMaterial: MwtClinicMaterial) => resolve(clinicMaterial),
          err => reject(err));
    });
  }
  //#endregion

  //#region Precription

  private async AddPrescription(prescription: Prescription): Promise<Prescription> {
    return new Promise<Prescription>((resolve, reject) => {
      this._apiService.post(API.prescriptions.addPrescription, prescription, true)
        .subscribe((pres: Prescription) => resolve(pres),
          err => reject(err));
    });
  }

  private UploadPrescription(formData: FormData) {
    this._apiService.upload(API.file_upload.upload, formData)
      .subscribe(res => {
        if (res['body'] === 200) {
          this._toastyService.success({
            title: this.commonDataService.localizationLabelList['prescription'],
            msg: this.commonDataService.localizationLabelList['prescription_upload_successfully'],
            showClose: true,
            theme: 'default',
            timeout: 5000,
            onAdd: (toast: ToastData) => { },
            onRemove: (toast: ToastData) => { }
          });
        }
      }, err => console.log(err));
  }

  //#endregion

  DeliveryDateKeyUp() {
    const date = this.mainWorkTypeFormGroup.get('deliveryDate').value;
    if (date) {
      const newDate = this.commonUiService.ConvertStringToNgbDate(date);
      if (newDate) {
        this.mainWorkTypeFormGroup.get('deliveryDate').setValue(newDate);
        this.onChangeDeliveryDate();
      }
    } else {
      this.mainWorkTypeFormGroup.get('deliveryDate').setValue(null);
    }
  }

  validateDates() {
    if (this.mainWorkType.deliveryDateView && this.mainWorkType.receiveDate) {
      const deliveryDate = new Date(this.commonUiService.ChangeNgbDateToCustomFormat(this.mainWorkType.deliveryDateView));
      const recieveDate = new Date(this.commonUiService.ChangeNgbDateToCustomFormat(this.mainWorkType.receiveDate));
      this.cdref.markForCheck();
      if (deliveryDate < recieveDate) {
        this.mainWorkType.deliveryDateView = null;
        this._toastyService.error({
          title: this.commonDataService.localizationLabelList['works'],
          msg: this.commonDataService.localizationLabelList['delivery_date_should_be_greater_than_recieve_date'],
          showClose: true,
          theme: 'default',
          timeout: 5000,
          onAdd: (toast: ToastData) => { },
          onRemove: (toast: ToastData) => { }
        });
      }
    }
  }

  async onChangeDeliveryDate() {
    if (this.mainWorkType.deliveryDateView) {
      const deliveryDate = this.commonUiService.ChangeNgbDateToCustomFormat(this.mainWorkType.deliveryDateView);
      const isAvailable = await this._labHolidaysService.checkDate(deliveryDate);
      if (isAvailable) { this.validateDates(); } else {
        this.mainWorkType.deliveryDateView = null;
      }
    }
  }

  async onChangeReceivedDate() {
    if (this.mainWorkType.receiveDate) {
      const receiveDate = this.commonUiService.ChangeNgbDateToCustomFormat(this.mainWorkType.receiveDate);
      const isAvailable = await this._labHolidaysService.checkDate(receiveDate);
      if (isAvailable) { this.validateDates(); } else {
        this.mainWorkType.receiveDate = null;
      }
    }
  }

  async onChangeRealDeliveryDate() {
    if (this.mainWorkType.realDeliveryDate) {
      const realDeliveryDate = this.commonUiService.ChangeNgbDateToCustomFormat(this.mainWorkType.realDeliveryDate);
      const isAvailable = await this._labHolidaysService.checkDate(realDeliveryDate);
      if (!isAvailable) {
        this.mainWorkType.realDeliveryDate = null;
      }
    }
  }
}
