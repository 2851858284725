import { Issuer } from '../../client/entities/client-issuer';
import { WorkTypePhaseTemplate, WorkTypeProductTemplate } from '../../work-type/entities/work-type-model-templates';

export class SanityGuaranteeReportModel {
    issuer: Issuer;
    clinic: string;
    prescriptor: string;
    doctorRegNo: string;
    patient: string;
    mainWorkTypeName: string;
    mainWorkTypeNo: string;
    deliveryNoteDates: string;
    deliveryNoteGeneratedNumbers: string;
    invoiceGeneratedNumbers: string;
    town: string;
    productTemplateList: WorkTypeProductTemplate[] = [];
    phaseTemplateList: WorkTypePhaseTemplate[] = [];
    workNo: string;
    subWorkNo: string;
}