import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { IWorkComponent } from '../../entities/i-work-component';
import { MainWorkType } from '../../entities/main-work-type';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { Subscription } from 'rxjs';
import { WorkService } from '../../services/work.service';
import { InvoiceService } from '../../services/invoice.service';
import { InvoiceHeader } from '../../../invoice/entities/invoice-header';
import { Delay } from 'src/app/modules/common/services/validators';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { ApiService } from 'src/app/modules/common/services/api.service';
import API from './../../../common/api.config.json';
import { InvoiceDetail } from 'src/app/modules/invoice/entities/invoice-detail';
import {
  InvoiceReportTemplate,
  InvoiceStatusTypes,
} from '../../../common/global';
import { SendEmail } from '../../entities/emailModel';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import swal from 'sweetalert2';
import { User } from 'src/app/modules/auth/entities/user';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { Town } from 'src/app/modules/auxiliary/entities/town';
import { IvaBreakdown } from 'src/app/modules/auxiliary/entities/iva-breakdown';
import { DeliveryNoteCalculationService } from 'src/app/modules/common/services/calculations/delivery-note-calculation.service';
import { toFixedNumber } from 'src/app/modules/common/services/calculations/calculation';
import { ArticleCalculationService } from 'src/app/modules/common/services/calculations/article-calculation.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit, OnDestroy, IWorkComponent {

  @Input() townList: Town[];

  hasLoaded = true;
  invoiceHeaderList: InvoiceHeader[] = [];
  selectedMainWorkTypeList: MainWorkType[];
  selectedMainWorkType: MainWorkType;
  selectedSubWork: SubWork;
  selectedWork: Work;
  mainWorkTypeList: MainWorkType[];
  selectedInvoiceHeader: InvoiceHeader;
  invoicedDeliveryNoteList: any[] = [];
  imageData: any;
  reportTemplateList: { value: number; description: string }[] = [];
  selectedTemplate: { value: number; description: string };
  emailmodel: SendEmail;
  private user: User = new User();
  selectedInvoiceToEmail?: InvoiceHeader = new InvoiceHeader();
  isPdf?: boolean = false;
  ivaBreakdownList: IvaBreakdown[] = [];
  deliveryNoteDesc:String;
  // subscriptions
  private workService: Subscription;

  constructor(
    private _workService: WorkService,
    private __service: InvoiceService,
    public commonUIService: CommonUIService,
    private _apiService: ApiService,
    private _toastyService: Toast,
    public commonDataService: CommonDataService,
    public dropdownService: DropdownService,
    private _deliveryNoteCalculationService: DeliveryNoteCalculationService,
    private _articleCalculationService: ArticleCalculationService,
  ) {
    this.workService = this._workService.detailObject
      .share()
      .subscribe(async (args) => {
        if (args.selectedMainWorkTypeList && args.invokeComponents) {
          if (
            args.invokeComponents.includes('Invoice') ||
            args.invokeComponents.includes('*')
          ) {
            this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
            this.selectedSubWork = args.selectedSubWork;
            this.selectedWork = args.selectedWork;
            this.mainWorkTypeList = args.mainWorkTypeList;
            await this.LoadSavedTemplate();
            await this.SyncInvoiceData();
          }
        }
      });
  }

  ngOnInit() {
    this.selectedInvoiceHeader = new InvoiceHeader();
    this.emailmodel = new SendEmail();
    this.ivaBreakdownList = [];
    this.reportTemplateList = InvoiceReportTemplate;
    this.selectedTemplate = InvoiceReportTemplate[0];
    this.LoadLabConfigurationList();
  }

  private async LoadLabConfigurationList() {
    this.imageData = await this.commonUIService.LoadLabConfigurationList();
  }

  private async LoadSavedTemplate() {
    try {
      if (this.selectedWork) {
        this.selectedTemplate = this.reportTemplateList[0];
        // set current template (call api and get saved template for client or auxiliary template)
        const selectedCdt = await this.__service.GetSavedTemplateByClientId(
          Number(this.selectedWork.clientId)
        );
        if (selectedCdt) {
          this.selectedTemplate = this.reportTemplateList.find(
            (rt) => rt.value === selectedCdt.reportConfigValue
          );
          if (!this.selectedTemplate) {
            this.selectedTemplate = this.reportTemplateList[0];
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  ngOnDestroy() {
    this.workService.unsubscribe();
  }

  private async SyncInvoiceData() {
    this.invoiceHeaderList = [];
    try {
      this.hasLoaded = false;
      if (this.selectedWork.id) {
        const invoiceList = await this.__service.GetInvoiceListByWorkId(
          this.selectedWork.id,
          this.selectedSubWork.id
        );
        this._workService.invoiceData = invoiceList;
        this.invoiceHeaderList = invoiceList
          ? invoiceList.filter(
            (i) =>
              i.invoiceStatus !== InvoiceStatusTypes.Cancelled &&
              i.invoiceStatus !== InvoiceStatusTypes.Cancelled2
          )
          : [];
        if (this.invoiceHeaderList.length > 0) {
          this.invoiceHeaderList.forEach(
            (i) => (i.selectedTemplate = this.selectedTemplate)
          );
        }
        
        
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.hasLoaded = true;
    }
  }

  //#region Print invoice
  async OnPrintInvoice(invoice: InvoiceHeader) {
    try {
      this.ivaBreakdownList = [];
      this.selectedTemplate = invoice.selectedTemplate;
      this.commonUIService.isSpinnerVisible = true;
      if (invoice.id) {
        const _invoice = await this.LoadInvoiceDetailsById(invoice.id);
        if (_invoice) {
          if (_invoice.client && _invoice.client.townId > 0 && this.townList) {
            var town = this.townList.filter(x => x.id == _invoice.client.townId);
            if (town && town.length > 0) {
              _invoice.client.provinceName = town[0].provinceName
              _invoice.client.postalCode = town[0].postcode
            }
          }
          this.selectedInvoiceHeader = _invoice;
          if (this.selectedInvoiceHeader) {
            if (this.selectedInvoiceHeader.invoiceDetailList) {
              const deliveryNoteList: any[] = this.selectedInvoiceHeader.invoiceDetailList.map(
                (x) => ({
                  deliveryNoteId: x.deliveryNoteId,
                  deliveryNoteGenNo: x.deliveryNoteGenNo,
                  deliveryNoteConcept: x.deliveryNoteConcept,
                  deliveryNoteGenDate: x.deliveryNoteGenDate,
                  deliveryNoteIssuerId: x.deliveryNoteIssuerId,
                  deliveryNoteSeriesId: x.deliveryNoteSeriesId,
                  finalPrice: x.articleFinalPrice,
                  deliveryNoteTax: x.deliveryNoteTax,
                  deliveryNoteTotal: x.deliveryNoteTotal,
                  deliveryNoteDiscount: x.deliveryNoteDiscount,
                  mainWorkTypeList: x.mainWorkTypeList
                })
              );           
              this.invoicedDeliveryNoteList = Array.from(
                new Set(deliveryNoteList.map((x) => x.deliveryNoteId))
                
              ).map((x) => ({
                deliveryNoteId: x,
                isChecked: true,
                isVisible: false,
                deliveryNoteGenNo: deliveryNoteList.find(
                  (s) => s.deliveryNoteId == x
                ).deliveryNoteGenNo,
                deliveryNoteConcept: deliveryNoteList.find(
                  (s) => s.deliveryNoteId == x
                ).deliveryNoteConcept,
                deliveryNoteIssuerId: deliveryNoteList.find(
                  (s) => s.deliveryNoteId == x
                ).deliveryNoteIssuerId,
                deliveryNoteSeriesId: deliveryNoteList.find(
                  (s) => s.deliveryNoteId == x
                ).deliveryNoteSeriesId,
                deliveryNoteGenDate: deliveryNoteList.find(
                  (s) => s.deliveryNoteId == x
                ).deliveryNoteGenDate,
                deliveryNoteGenDateObject: new Date(
                  deliveryNoteList
                    .find((s) => s.deliveryNoteId == x)
                    .deliveryNoteGenDate.toString()
                    .substring(0, 10)
                ),
                deliveryNoteTotalPrice: toFixedNumber(deliveryNoteList.find((s) => s.deliveryNoteId == x).deliveryNoteTotal),
                deliveryNoteTax: toFixedNumber(deliveryNoteList.find((s) => s.deliveryNoteId == x).deliveryNoteTax),
                deliveryNoteSubTotalPrice: toFixedNumber(deliveryNoteList.find((s) => s.deliveryNoteId == x).deliveryNoteSubTotal),
                totalUnitPrice: 0,
                totalQuantity: 0,
                totalDiscount: toFixedNumber(deliveryNoteList.find((s) => s.deliveryNoteId == x).deliveryNoteDiscount),
                mainWorkTypeList: deliveryNoteList
                .find((s) => s.deliveryNoteId == x).mainWorkTypeList,       
              }));

              this.DoCalculations(this.selectedInvoiceHeader.invoiceDetailList);
              //#region iva breakdown
              this.selectedInvoiceHeader.invoiceDetailList.forEach(art => {
                if (art.ivaTypeId && art.ivaTypeId != 0) {
                  const ivaBreakdown = new IvaBreakdown();
                  ivaBreakdown.ivaTypeId = art.ivaTypeId;
                  ivaBreakdown.iva = art.articleIvaPercentage + '%';

                  ivaBreakdown.base = this._deliveryNoteCalculationService.getIvaBaseAmount(art.articlePriceWithDiscount, art.articleQuantityWithWaste);
                  ivaBreakdown.ivaAmount = toFixedNumber(art.articleIvaAmount);
                  this.ivaBreakdownList.push(ivaBreakdown);
                }
              });
              //#endregion

              if (this.ivaBreakdownList && this.ivaBreakdownList.length > 0) {
                this.ivaBreakdownList = this.__service.LoadVatBreakdown(this.ivaBreakdownList);
              }
            }
            if (this.selectedTemplate) {
              if (this.selectedTemplate.value === 3) {
                if (
                  this.invoicedDeliveryNoteList &&
                  this.selectedInvoiceHeader
                ) {
                  for (
                    let index = 0;
                    index < this.invoicedDeliveryNoteList.length;
                    index++
                  ) {
                    const deliveryNote = this.invoicedDeliveryNoteList[index];
                    if (deliveryNote) {
                      if (this.selectedInvoiceHeader.invoiceDetailList) {
                        const invDetailList = this.selectedInvoiceHeader.invoiceDetailList.filter(
                          (x) =>
                            x.deliveryNoteId === deliveryNote.deliveryNoteId
                        );
                        if (invDetailList) {
                          deliveryNote.totalUnitPrice = toFixedNumber(invDetailList
                            .reduce((a, b) => a + (b.articlePrice || 0), 0));
                          deliveryNote.totalQuantity = toFixedNumber(invDetailList
                            .reduce((a, b) => a + (b.articleQuantity || 0), 0));
                          deliveryNote.totalDiscount = toFixedNumber(invDetailList.reduce((a, b) => {
                            return (b.isDiscountAmount ? (a + toFixedNumber(b.articleDiscountAmount
                              * b.articleQuantityWithWaste || 0)) :
                              (a + ((this._articleCalculationService
                                .getDiscountAmount(b.articlePrice, b.articleDiscount)) * toFixedNumber(b.articleQuantityWithWaste) || 0))
                            );
                          }, 0));
                        }
                      }
                    }
                  }
                }
              }
            }
            await Delay(500);
            this.isPdf = false;
            this.commonUIService.PrintHtmlReport(
              this.selectedTemplate.description
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.commonUIService.isSpinnerVisible = false;
    }
  }

  OnSelectTemplate(
    template: { value: number; description: string },
    index: number
  ) {

    this.selectedTemplate = this.reportTemplateList.find(
      (rt) => rt.value === template.value
    );
    this.invoiceHeaderList[index].selectedTemplate = this.selectedTemplate;
    // this.selectedTemplate = template;
  }

  //#endregion

  //#region Calculations
  private GetSum(arr: any[], key: string): any {
    return arr.reduce((a, b) => a + (b[key] || 0), 0);
  }

  private DoCalculations(list: InvoiceDetail[]) {
    this.CalculateInvoiceDetailListValues(list);

    this.selectedInvoiceHeader.invoiceTotal = this.selectedInvoiceHeader.invoiceTotal ?
      toFixedNumber(this.selectedInvoiceHeader.invoiceTotal)
      : toFixedNumber(this.selectedInvoiceHeader.subTotal);

    this.selectedInvoiceHeader.sumOfSubTotal = toFixedNumber(this.selectedInvoiceHeader.subTotal);
    this.selectedInvoiceHeader.sumOfDiscount =
      this._articleCalculationService
        .getDiscountAmount(this.selectedInvoiceHeader.sumOfSubTotal,
          toFixedNumber(this.selectedInvoiceHeader.discount));
    this.selectedInvoiceHeader.sumOfTax = this.selectedInvoiceHeader.tax ?
      toFixedNumber(this.selectedInvoiceHeader.tax) : 0;
    this.selectedInvoiceHeader.invoiceTotal = toFixedNumber(this.selectedInvoiceHeader.sumOfSubTotal -
      this.selectedInvoiceHeader.sumOfDiscount + this.selectedInvoiceHeader.sumOfTax);
  }

  private CalculateInvoiceDetailListValues(invoiceDetailList: InvoiceDetail[]): InvoiceDetail[] {
    if (invoiceDetailList) {
      invoiceDetailList.forEach(detail => {
        detail.articleDiscountAmount = detail.isDiscountAmount ? detail.articleDiscount :
          this._articleCalculationService
            .getDiscountAmount(toFixedNumber(detail.articlePrice),
              toFixedNumber(detail.articleDiscount));

        detail.articleQuantityWithWaste = this._articleCalculationService
          .getQuantityWithWaste(detail.articleQuantity, toFixedNumber(detail.articleWaste));
        detail.articlePriceWithDiscount = toFixedNumber(detail.articlePrice - detail.articleDiscountAmount);
      });
    }
    return invoiceDetailList;
  }

  private GetTotalArticleFinalPrice(
    invoice: InvoiceHeader,
    list: InvoiceDetail[]
  ): number {
    if (invoice && list) {
      invoice.invoiceTotal = 0;
      if (list.length > 0) {
        list.forEach(
          (invoiceDetail) =>
          (invoice.invoiceTotal =
            Number(invoice.invoiceTotal) + invoiceDetail.articleFinalPrice)
        );
      }
    }
    return invoice.invoiceTotal;
  }

  private GetSumOfArticleDiscount(
    invoice: InvoiceHeader,
    list: InvoiceDetail[]
  ): number {
    if (invoice && list) {
      invoice.sumOfDiscount = 0;
      if (list.length > 0) {
        list.forEach(
          (invoiceDetail) =>
          (invoice.sumOfDiscount =
            Number(invoice.sumOfDiscount) +
            invoiceDetail.articleDiscountAmount *
            ((invoiceDetail.articleWaste + 100) / 100) *
            invoiceDetail.articleQuantity)
        );
      }
    }
    return invoice.sumOfDiscount;
  }

  private GetSumOfSubTotalDiscount(
    invoice: InvoiceHeader,
    list: InvoiceDetail[]
  ): number {
    if (invoice && list) {
      invoice.sumOfSubTotal = 0;
      if (list.length > 0) {
        list.forEach(
          (invoiceDetail) =>
          (invoice.sumOfSubTotal =
            Number(invoice.sumOfSubTotal) +
            invoiceDetail.articlePrice *
            (((invoiceDetail.articleWaste + 100) / 100) *
              invoiceDetail.articleQuantity))
        );
      }
    }
    return invoice.sumOfSubTotal;
  }
  //#endregion

  //#region Invoice header details

  LoadInvoiceDetailsById(invoiceHeaderId: number): Promise<InvoiceHeader> {
    return new Promise<InvoiceHeader>((resolve, reject) => {
      this._apiService
        .get(API.invoice.getInvoiceById + invoiceHeaderId)
        .subscribe(
          (invoiceHeader: InvoiceHeader) => resolve(invoiceHeader),
          (err) => reject(Error(err))
        );
    });
  }

  //#endregion

  /**
   * get fired when open email compose popup load
   * */
  async OnSendEmailModalOpen(invoice: InvoiceHeader) {
    try {
      this.emailmodel = new SendEmail();
      this.user = this.commonDataService.GetUserFromSession();
      this.selectedTemplate = invoice.selectedTemplate;
      this.commonUIService.isSpinnerVisible = true;

      if (invoice.id) {
        this.selectedInvoiceToEmail = invoice;
        const _invoice = await this.LoadInvoiceDetailsById(invoice.id);
        if (_invoice) {
          if (_invoice.client && _invoice.client.townId > 0 && this.townList) {
            var town = this.townList.filter(x => x.id == _invoice.client.townId);
            if (town && town.length > 0) {
              _invoice.client.provinceName = town[0].provinceName
              _invoice.client.postalCode = town[0].postcode
            }
          }
          this.selectedInvoiceHeader = _invoice;
          this.emailmodel.password = this.user != null && this.user.userRolePassword != null ? this.user.userRolePassword : null;
          this.emailmodel.senderEmail = this.user != null && this.user.userRoleEmail != null ? this.user.userRoleEmail : null;
          this.emailmodel.subject = this.commonDataService.localizationLabelList['invoice'] + ' - (' + invoice.generateNumber + ')';
          this.emailmodel.message = this.commonDataService.localizationLabelList['invoice'] + ' - (' + invoice.generateNumber + ')';
          this.emailmodel.attachmentName = this.commonDataService.localizationLabelList['invoice'] + ' (' + invoice.generateNumber + ')' + '.pdf';

          if (_invoice.billedToPatient && _invoice.patientId) {
            const patient = await this._workService.GetPatientById(_invoice.patientId);
            this.emailmodel.receiverEmail = patient && patient.email ? patient.email : null;
          } else {
            this.emailmodel.receiverEmail = _invoice.client && _invoice.client.email ? _invoice.client.email : null;
          }

          if (!this.emailmodel.senderEmail && !this.emailmodel.receiverEmail) {
            return this._toastyService.warning({
              title: this.commonDataService.localizationLabelList['email'],
              msg: this.commonDataService.localizationLabelList[
                'sender_recipient_missing'
              ],
            });
          } else if (!this.emailmodel.senderEmail) {
            return this._toastyService.warning({
              title: this.commonDataService.localizationLabelList['email'],
              msg: this.commonDataService.localizationLabelList[
                'sender_missing'
              ],
            });
          } else if (!this.emailmodel.receiverEmail) {
            swal({
              title: this.commonDataService.localizationLabelList['email'],
              text: this.commonDataService.localizationLabelList[
                'recipient_missing'
              ],
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this.commonDataService.localizationLabelList[
                'common_yes'
              ],
              cancelButtonText: this.commonDataService.localizationLabelList[
                'common_cancel'
              ],
            }).then((result) => {
              if (result.value) {
                this.commonUIService.OpenBasicModal('invoice-send-email');
                window.scrollTo(0, 0);
              } else {
                return;
              }
            });
          } else {
            this.commonUIService.OpenBasicModal('invoice-send-email');
            window.scrollTo(0, 0);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.commonUIService.isSpinnerVisible = false;
    }
  }

  /**
   * get fired when open email compose popup close
   * */
  OnSendEmailModalClose() {
    this.commonUIService.CloseBasicModal('invoice-send-email');
    this.__service.isShowEmailPopup = false;
  }

  /**
   * get fired when sending the mail
   * */
  async OnSendEmail() {
    if (
      this.emailmodel.receiverEmail == null ||
      this.emailmodel.receiverEmail == ''
    ) {
      return this._toastyService.warning({
        title: this.commonDataService.localizationLabelList['email'],
        msg: this.commonDataService.localizationLabelList['recipient_missing'],
      });
    } else {
      this.isPdf = true;
      this.OnSendEmailModalClose();
      this.commonUIService.isSpinnerVisible = true;

      if (this.selectedInvoiceHeader) {
        this.ivaBreakdownList = [];
        if (this.selectedInvoiceHeader.invoiceDetailList) {
          const deliveryNoteList: any[] = this.selectedInvoiceHeader.invoiceDetailList.map(
            (x) => ({
              deliveryNoteId: x.deliveryNoteId,
              deliveryNoteGenNo: x.deliveryNoteGenNo,
              deliveryNoteConcept: x.deliveryNoteConcept,
              deliveryNoteGenDate: x.deliveryNoteGenDate,
              deliveryNoteIssuerId: x.deliveryNoteIssuerId,
              deliveryNoteSeriesId: x.deliveryNoteSeriesId,
              finalPrice: x.articleFinalPrice,
              deliveryNoteTax: x.deliveryNoteTax,
              deliveryNoteTotal: x.deliveryNoteTotal
            })
          );
          this.invoicedDeliveryNoteList = Array.from(
            new Set(deliveryNoteList.map((x) => x.deliveryNoteId))
          ).map((x) => ({
            deliveryNoteId: x,
            isChecked: true,
            isVisible: false,
            deliveryNoteGenNo: deliveryNoteList.find(
              (s) => s.deliveryNoteId == x
            ).deliveryNoteGenNo,
            deliveryNoteConcept: deliveryNoteList.find(
              (s) => s.deliveryNoteId == x
            ).deliveryNoteConcept,
            deliveryNoteIssuerId: deliveryNoteList.find(
              (s) => s.deliveryNoteId == x
            ).deliveryNoteIssuerId,
            deliveryNoteSeriesId: deliveryNoteList.find(
              (s) => s.deliveryNoteId == x
            ).deliveryNoteSeriesId,
            deliveryNoteGenDate: deliveryNoteList.find(
              (s) => s.deliveryNoteId == x
            ).deliveryNoteGenDate,
            deliveryNoteGenDateObject: new Date(
              deliveryNoteList
                .find((s) => s.deliveryNoteId == x)
                .deliveryNoteGenDate.toString()
                .substring(0, 10)
            ),
            deliveryNoteTotalPrice: toFixedNumber(deliveryNoteList.find((s) => s.deliveryNoteId == x).deliveryNoteTotal),
            deliveryNoteTax: toFixedNumber(deliveryNoteList.find((s) => s.deliveryNoteId == x).deliveryNoteTax),
            deliveryNoteSubTotalPrice: toFixedNumber(deliveryNoteList.find((s) => s.deliveryNoteId == x).deliveryNoteSubTotal),
            totalUnitPrice: 0,
            totalQuantity: 0,
            totalDiscount: toFixedNumber(deliveryNoteList.find((s) => s.deliveryNoteId == x).deliveryNoteDiscount)
          }));
          this.DoCalculations(this.selectedInvoiceHeader.invoiceDetailList);

          //#region iva breakdown
          this.selectedInvoiceHeader.invoiceDetailList.forEach(art => {
            if (art.ivaTypeId && art.ivaTypeId != 0) {
              const ivaBreakdown = new IvaBreakdown();
              ivaBreakdown.ivaTypeId = art.ivaTypeId;
              ivaBreakdown.iva = art.articleIvaPercentage + '%';

              ivaBreakdown.base = art.articleFinalPrice - art.articleIvaAmount;
              ivaBreakdown.base = this._deliveryNoteCalculationService.getIvaBaseAmount(art.articlePriceWithDiscount, art.articleQuantityWithWaste);
              ivaBreakdown.ivaAmount = toFixedNumber(art.articleIvaAmount);
              this.ivaBreakdownList.push(ivaBreakdown);
            }
          });
          //#endregion

          if (this.ivaBreakdownList && this.ivaBreakdownList.length > 0) {
            this.ivaBreakdownList = this.__service.LoadVatBreakdown(this.ivaBreakdownList);
          }
        }
        if (this.selectedTemplate) {
          if (this.selectedTemplate.value === 3) {
            if (this.invoicedDeliveryNoteList && this.selectedInvoiceHeader) {
              for (
                let index = 0;
                index < this.invoicedDeliveryNoteList.length;
                index++
              ) {
                const deliveryNote = this.invoicedDeliveryNoteList[index];
                if (deliveryNote) {
                  if (this.selectedInvoiceHeader.invoiceDetailList) {
                    const invDetailList = this.selectedInvoiceHeader.invoiceDetailList.filter(
                      (x) => x.deliveryNoteId === deliveryNote.deliveryNoteId
                    );
                    if (invDetailList) {
                      deliveryNote.totalUnitPrice = toFixedNumber(invDetailList
                        .reduce((a, b) => a + (b.articlePrice || 0), 0));
                      deliveryNote.totalQuantity = toFixedNumber(invDetailList
                        .reduce((a, b) => a + (b.articleQuantity || 0), 0));
                      deliveryNote.totalDiscount = toFixedNumber(invDetailList.reduce((a, b) => {
                        return (b.isDiscountAmount ? (a + toFixedNumber(b.articleDiscountAmount
                          * b.articleQuantityWithWaste || 0)) :
                          (a + ((this._articleCalculationService
                            .getDiscountAmount(b.articlePrice, b.articleDiscount)) * toFixedNumber(b.articleQuantityWithWaste) || 0))
                        );
                      }, 0));
                    }
                  }
                }
              }
            }
          }
        }

        await Delay(500);

        let base64String = await this.commonUIService.ConvertHtmlToPdfBase64(
          this.selectedInvoiceToEmail.selectedTemplate.description
        );

        if (base64String == null) {
          this.commonUIService.isSpinnerVisible = false;
          return this._toastyService.error({
            title: this.commonDataService.localizationLabelList['email'],
            msg: this.commonDataService.localizationLabelList['email_failed'],
          });
        }

        this.emailmodel.base64String = btoa(base64String);

        let response = await this.commonUIService.sendEmailRequest(this.emailmodel);
        this.commonUIService.isSpinnerVisible = false;

        if (response) {
          const body = JSON.parse(response['_body']);
          switch (body.status) {
            case 200:
              if (body.isSent) {
                return this._toastyService.success({
                  title: this.commonDataService.localizationLabelList['email'],
                  msg: this.commonDataService.localizationLabelList[
                    'email_send_succesfully'
                  ],
                });
              } else {
                return this._toastyService.error({
                  title: this.commonDataService.localizationLabelList['email'],
                  msg: this.commonDataService.localizationLabelList[
                    'email_failed'
                  ],
                });
              }
            case 404:
              return this._toastyService.error({
                title: this.commonDataService.localizationLabelList['email'],
                msg: this.commonDataService.localizationLabelList[
                  'email_failed'
                ],
              });
            default:
              return this._toastyService.error({
                title: this.commonDataService.localizationLabelList['email'],
                msg: this.commonDataService.localizationLabelList[
                  'email_failed'
                ],
              });
          }
        } else {
          return this._toastyService.error({
            title: this.commonDataService.localizationLabelList['email'],
            msg: this.commonDataService.localizationLabelList['email_failed'],
          });
        }
      }
    }
  }
}
