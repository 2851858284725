import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Delay } from 'src/app/modules/common/services/validators';
import { ApiService } from '../../../common/services/api.service';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtColor } from '../../entities/main-work-type-color';
import { MwtMetal } from '../../entities/main-work-type-metal';
import { Work } from '../../entities/work';
import { WorkService } from '../../services/work.service';

@Component({
  selector: 'pieces',
  templateUrl: './pieces.component.html',
  styleUrls: ['./pieces.component.scss']
})
export class PiecesComponent implements OnInit, OnDestroy {

  // subscriptions
  private workService: Subscription;

  // lists
  mwtColorList: MwtColor[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];
  mwtMetalList: MwtMetal[] = [];

  // objects
  hasLoaded = false;
  selectedTeethList: number[] = [];
  piecesStr = '';
  colorsStr: string;
  metalsStr: string;
  selectedWork: Work;

  constructor(private __service: WorkService, private _apiService: ApiService) {
    this.workService = this.__service.detailObject.subscribe(async (args) => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (args.invokeComponents.includes('Pieces') || args.invokeComponents.includes('*')) {
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this.selectedWork = args.selectedWork;
          if (args.transferable.length > 0) {
            const mwtColorListDetail = args.transferable.find(t => t.name === 'mwtColorList');
            if (mwtColorListDetail) {
              this.mwtColorList = mwtColorListDetail.object as MwtColor[];
              this.SyncMwtColorData();
            }
            const mwtMetalListDetail = args.transferable.find(t => t.name === 'mwtMetalList');
            if (mwtMetalListDetail) {
              this.mwtMetalList = mwtMetalListDetail.object as MwtMetal[];
              this.SyncMwtMetalData();
            }
          } else { this.hasLoaded = false; this.selectedTeethList = []; this.hasLoaded = true; }
        }
      }
    });
  }

  ngOnInit() { }

  ngOnDestroy() { this.workService.unsubscribe(); }

  //#region CRUD

  async SyncMwtColorData() {
    const colorTextList: { color: string, guide: string }[] = [];
    this.selectedTeethList = [];
    try {
      this.hasLoaded = false;
      await Delay(300);
      this.mwtColorList = this.mwtColorList ? this.mwtColorList : [];
      this.piecesStr = '';
      this.colorsStr = '';
      if (this.mwtColorList.length > 0) {
        for (let j = 0; j < this.mwtColorList.length; j++) {
          const mwtColor = this.mwtColorList[j];
          const selectors: string[] = mwtColor.pieces ? mwtColor.pieces.trim().split(',') : [];
          for (let k = 0; k < selectors.length; k++) {
            const teeth = selectors[k];
            this.selectedTeethList.push(Number(teeth));
          }
          colorTextList.push({ color: mwtColor.colorText, guide: mwtColor.guideName ? mwtColor.guideName : '' });
        }
        const set = new Set<number>([...this.selectedTeethList]);
        this.selectedTeethList = _.toArray(set);
        this.piecesStr = JSON.stringify(this.selectedTeethList).trim();
        const colors = _.uniqWith(colorTextList, _.isEqual);
        const colorList = colors.map((x: { color: string; guide: string; }) => x.color + (x.guide ? ('(' + x.guide + ')') : ''));
        colorList.forEach((color: string | number) => { this.colorsStr += (this.colorsStr.length > 0 ? ', ' : '') + color; });

        this.colorsStr = this.colorsStr.replace('null', '');
        this.piecesStr = this.piecesStr.replace('null', '');
      }
    } catch (error) { console.log(error); }
    finally { this.hasLoaded = true; }
  }

  async SyncMwtMetalData() {
    try {
      this.hasLoaded = false;
      const metalTextList: string[] = [];
      this.metalsStr = '';
      for (let index = 0; index < this.mwtMetalList.length; index++) {
        const mwtColor = this.mwtMetalList[index];
        metalTextList.push(mwtColor.metalName);
      }
      const metalList = metalTextList.filter((x, i, a) => a.indexOf(x) === i);
      metalList.forEach(metal => { this.metalsStr += (this.metalsStr.length > 0 ? ', ' : '') + metal; });

      this.metalsStr = this.metalsStr.replace('null', '');
    } catch (error) { console.log(error); }
    finally { this.hasLoaded = true; }
  }

  //#endregion

}
