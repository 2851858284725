import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgbModule, NgbDateParserFormatter } from 'imp-ng-bootstrap';
import { ToggleFullScreenDirective } from './fullscreen/toggle-fullscreen.directive';
import { AccordionAnchorDirective } from './accordion/accordionanchor.directive';
import { AccordionLinkDirective } from './accordion/accordionlink.directive';
import { AccordionDirective } from './accordion/accordion.directive';
import { HttpClientModule } from '@angular/common/http';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { TitleComponent } from '../layout/admin/title/title.component';
import { CardComponent } from './card/card.component';
import { CardToggleDirective } from './card/card-toggle.directive';
import { ModalBasicComponent } from './modal-basic/modal-basic.component';
import { ModalAnimationComponent } from './modal-animation/modal-animation.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { DataFilterPipe } from './elements/data-filter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationComponent } from './pagination/pagination.component';
import { PaginationDirective } from './pagination/pagination.directive';
import { ExcelService } from 'src/app/shared/excel-service/excel-service.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { Permit } from '../modules/common/pipes/permit.pipe';
import { PriceListReportComponent } from './reports/price-list-report/price-list-report.component';
import { CustomTranslatePipe } from '../modules/common/pipes/custom-translate.pipe';
import { WorkPageConfigurationComponent } from '../modules/auxiliary/trabajos-table/components/work-page-configuration/work-page-configuration.component';
import { DataTableModule } from 'angular2-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NovaCurrencyPipe } from '../modules/common/pipes/nova-currency.pipe';
import { NumberingProcessComponent } from './numbering-process/numbering-process.component';
import { NumberingProcessService } from './numbering-process/numbering-process.service';
import { InvoiceTemplateComponent } from './reports/invoice-template/invoice-template.component';
import { OrderReceiptModalComponent } from '../modules/order-receipt/components/order-receipt-modal/order-receipt-modal.component';
import { NovaSearchModule } from 'nova-search';
import { ReceiptReportOneComponent } from './reports/receipt-report-templates/receipt-report-one/receipt-report-one.component';
import { ReceiptReportTwoComponent } from './reports/receipt-report-templates/receipt-report-two/receipt-report-two.component';
import { NovaCurrencyMaskDirective } from '../modules/common/directives/nova-currency-mask';
import { ReturnOrderReportComponent } from './reports/return-order-report/return-order-report.component';
import { TimeFormatPipe } from '../modules/common/pipes/time-format.pipe';
import { Ng2chartComponent } from './ng2chart/ng2chart.component';
import { ChartsModule } from 'ng2-charts';
import { DateParserFormatter } from '../modules/common/date-parser-formater';
import { LineChartsComponent } from './line-charts/line-charts.component';
import { DeliveryNoteArticlePopupComponent } from './delivery-note-article-popup/delivery-note-article-popup.component';
import { CustomShortDate } from '../modules/common/pipes/custom-shortdate.pipe';
import { SectionTopesEditModalComponent } from '../modules/work/popups/task-modal/section-topes-edit-modal/section-topes-edit-modal.component';
import { TaskTopesEditModalComponent } from '../modules/work/popups/task-modal/task-topes-edit-modal/task-topes-edit-modal.component';
import { JobSearchComponent } from '../modules/job-search/job-search.component';
import { ShortDate } from '../modules/common/pipes/short-date.pipe';
import { AddNewPatientComponent } from '../modules/dashboard/add-new-patient/add-new-patient.component';
import { SelectModule } from 'ng-select';
import { EditPostcodeComponent } from './edit-postcode/edit-postcode.component';
import { ModifyLicensingSystemComponent } from './modify-licensing-system/modify-licensing-system.component';
import { NgxMaskModule } from 'ngx-mask';
import { CustomLableTranslatePipe } from '../modules/common/pipes/custom-translate-lable.pipe';
import { IncidentReportComponent } from './reports/incident-report/incident-report.component';
import { NgxBarcodeModule } from 'ngx-barcode';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    NgbModule.forRoot(),
    PerfectScrollbarModule,
    ClickOutsideModule,
    TranslateModule,
    DataTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxMaskModule,
    NovaSearchModule,
    ChartsModule,
    NovaSearchModule,
    SelectModule,
    NgxBarcodeModule
  ],
  exports: [
    NgbModule,
    ToggleFullScreenDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CardToggleDirective,
    HttpClientModule,
    PerfectScrollbarModule,
    TitleComponent,
    CardComponent,
    ModalBasicComponent,
    ModalAnimationComponent,
    SpinnerComponent,
    ClickOutsideModule,
    DataFilterPipe,
    TranslateModule,
    PaginationComponent,
    PaginationDirective,
    Permit,
    CustomTranslatePipe,
    CustomLableTranslatePipe,
    NgxPermissionsModule,
    PriceListReportComponent,
    NovaCurrencyPipe,
    WorkPageConfigurationComponent,
    NumberingProcessComponent,
    InvoiceTemplateComponent,
    OrderReceiptModalComponent,
    ReceiptReportOneComponent,
    ReceiptReportTwoComponent,
    NovaCurrencyMaskDirective,
    ReturnOrderReportComponent,
    TimeFormatPipe,
    Ng2chartComponent,
    LineChartsComponent,
    DeliveryNoteArticlePopupComponent,
    CustomShortDate,
    TaskTopesEditModalComponent,
    SectionTopesEditModalComponent,
    JobSearchComponent,
    AddNewPatientComponent,
    EditPostcodeComponent,
    ModifyLicensingSystemComponent,
    IncidentReportComponent
  ],
  declarations: [
    ToggleFullScreenDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CardToggleDirective,
    TitleComponent,
    CardComponent,
    ModalBasicComponent,
    ModalAnimationComponent,
    SpinnerComponent,
    DataFilterPipe,
    PaginationComponent,
    PaginationDirective,
    Permit,
    CustomTranslatePipe,
    CustomLableTranslatePipe,
    NovaCurrencyPipe,
    PriceListReportComponent,
    WorkPageConfigurationComponent,
    NumberingProcessComponent,
    InvoiceTemplateComponent,
    OrderReceiptModalComponent,
    ReceiptReportOneComponent,
    ReceiptReportTwoComponent,
    NovaCurrencyMaskDirective,
    ReturnOrderReportComponent,
    TimeFormatPipe,
    Ng2chartComponent,
    LineChartsComponent,
    DeliveryNoteArticlePopupComponent,
    CustomShortDate,
    TaskTopesEditModalComponent,
    SectionTopesEditModalComponent,
    JobSearchComponent,
    ShortDate,
    AddNewPatientComponent,
    EditPostcodeComponent,
    ModifyLicensingSystemComponent,
    IncidentReportComponent,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    ExcelService,
    NumberingProcessService,
    CurrencyPipe,
    NovaCurrencyPipe,
    CustomShortDate,
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter }
  ],
  schemas: [NO_ERRORS_SCHEMA],
  entryComponents: [PaginationComponent]
})
export class SharedModule { }
