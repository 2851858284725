import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import API from './../../common/api.config.json';
import { Prescription } from '../entities/prescription';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {

  constructor(private _apiService: ApiService) { }

  GetPrescriptionListBySubWorkId(subWorkId: number) {
    return this._apiService.get(API.prescriptions.getListBySubWorkId + subWorkId).map(x => x as Prescription[]).toPromise();
  }

  AddPrescription(prescription: Prescription): Promise<Prescription> {
    return new Promise<Prescription>((resolve, reject) => {
      this._apiService.post(API.prescriptions.addPrescription, prescription, true)
        .subscribe((pres: Prescription) => resolve(pres),
          err => reject(err));
    });
  }

}
