import { Component, Input, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { InvoiceHeader } from '../../entities/invoice-header';
import { CommonDataService } from '../../../common/services/common.data.service';
import { InvoiceService } from 'src/app/modules/work/services/invoice.service';
import { InvoiceDetail } from '../../entities/invoice-detail';
import { DisplayDeliveryNoteViewModel, InvoiceDeliveryNoteViewModel } from '../../entities/invoice-edit-view-models';
import * as moment from 'moment';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { Toast } from 'src/app/modules/common/services/toast.service.js';
import { DropdownService } from '../../../common/services/dropdown.service';
import { WorkService } from '../../../work/services/work.service';
import { SeriesTypes } from '../../../common/global';
import { Series } from 'src/app/modules/client/entities/series';
import { DropDownOption } from 'src/app/modules/common/entities/dropdown-option';
import API from '../../../common/api.config.json';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { Delay } from 'src/app/modules/common/services/validators';
import { toFixedNumber } from '../../../common/services/calculations/calculation';

const NUM_OF_NOTES = 5;

@Component({
  selector: 'app-invoice-edit-view',
  templateUrl: './invoice-edit-view.component.html',
  styleUrls: ['./invoice-edit-view.component.scss']
})
export class InvoiceEditViewComponent implements OnChanges, OnDestroy {

  currentPageNumber = 1;
  currentPageSize = 0;
  pages = [];
  isAllChecked = false;
  @Input() invoiceHeader: InvoiceHeader;
  @Input() checkDNObject: { load: string, singleId: number };
  @Output() invoiceDeliveryNoteListChanged = new EventEmitter<{
    list: InvoiceDeliveryNoteViewModel[],
    invoiceHeader: InvoiceHeader
  }>();

  @Output() deleteInvoiceHeaderData = new EventEmitter<InvoiceHeader>();

  issuerList: DropDownOption[] = [];
  seriesList: Series[] = [];

  deliveryNoteFilter: {
    fromDate: any, toDate: any, issuerId?: any, seriesId?: any
  } = { fromDate: null, toDate: null, issuerId: '0', seriesId: 0 };

  invoiceDeliveryNoteList: InvoiceDeliveryNoteViewModel[] = [];
  private _invoiceDeliveryNoteList: InvoiceDeliveryNoteViewModel[] = [];
  public checkInvoiceHeaderExisting = false;

  constructor(
    private _apiService: ApiService,
    public _commonDataService: CommonDataService,
    private _commonUiService: CommonUIService,
    private _toastyService: Toast,
    private _invoiceService: InvoiceService,
    private _workService: WorkService,
    private _dropdownService: DropdownService) { }

  async ngOnChanges() {
    if (this.invoiceHeader) {
      this.InitResources();
      if (this.invoiceHeader.invoiceDetailList) {
        this.invoiceDeliveryNoteList = this.GetInvoiceDeliveryNoteList(
          this.invoiceHeader.invoiceDetailList);
        await this.OnShowPending();
        this._invoiceDeliveryNoteList = this.invoiceDeliveryNoteList;
        const checkedList = this._invoiceDeliveryNoteList.filter(i => i.isChecked);
        this.DoCalculations(checkedList);
        this.invoiceDeliveryNoteListChanged
          .emit({
            list: checkedList,
            invoiceHeader: this.invoiceHeader
          });
        this.GoToPage({
          count: this.invoiceDeliveryNoteList.length,
          currentPageNumber: this.currentPageNumber
        });
        this.isAllChecked = this.invoiceDeliveryNoteList
          .every(i => i.isChecked);
      }
    }
  }

  private async InitResources() {
    const issuerList = this._dropdownService.issureList;
    this.issuerList = this._commonDataService
      .GetDropDownList(issuerList ? issuerList : [], 'description');
    const seriesList = await this._workService.GetSeriesList();
    if (seriesList) {
      var currentYear = new Date().getFullYear().toString();
      this.seriesList = seriesList.length > 0 ?
        seriesList.filter(s => s.seriesType === SeriesTypes.DeliveryNote && s.year == currentYear && s.status) : [];
    }
  }

  ngOnDestroy() {
    if (!this._invoiceService.isSaving) {
      this.OnDeleteInvoice();
      this._invoiceService.isSaving = false;
    }
  }



  async OnDeleteInvoice() {
    this.deleteInvoiceHeaderData.emit(this.invoiceHeader);
    this._apiService.get(API.invoice.checkInvoiceHeaderExisting + this.invoiceHeader.id)
      .subscribe(res => {
        if (res === true || res["_data"] === true) {
          this.checkInvoiceHeaderExisting = true;
        }
      });
    await Delay(3000);
    if (this.checkInvoiceHeaderExisting) {
      this.RemoveInvoiceHeader(this.invoiceHeader)
        .then(response => {
          if (response['_data']) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['invoice'],
              msg: this._commonDataService.localizationLabelList['save_success']
            });
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['invoice'],
              msg: this._commonDataService.localizationLabelList['save_error']
            });
          }
        });
    }
  }

  private async RemoveInvoiceHeader(invoiceHeader: InvoiceHeader): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.delete(API.invoice.deleteInvoice, invoiceHeader.id.toString())
        .subscribe(response => resolve(response),
          err => reject(Error(err)),
          () => { });
    });
  }

  private GetSum(arr: any[], key: string): any { return arr.reduce((a, b) => a + (b[key] || 0), 0); }

  private async GetPendingInvoiceDeliveryNoteViewModelListByClientId(id: number) {
    try {
      if (id) {
        let pendingDeliveryNoteList = await this._invoiceService
          .GetPendingDeliveryNoteListByClientId(this.invoiceHeader.clientId);
        pendingDeliveryNoteList = pendingDeliveryNoteList ? pendingDeliveryNoteList : [];
        if (pendingDeliveryNoteList.length > 0) {
          return pendingDeliveryNoteList.map(x => new InvoiceDeliveryNoteViewModel(
            {
              deliveryNoteGenNo: x.generateNo,
              deliveryNoteId: x.id,
              isChecked: this.checkDNObject.load === 'all' || (this.checkDNObject.load === 'single'
                && this.checkDNObject.singleId === Number(x.id)) ? true : x.isInvoiced,
              isVisible: true,
              isInvoiced: this.checkDNObject.load === 'all' || (this.checkDNObject.load === 'single'
                && this.checkDNObject.singleId === Number(x.id)) ? true : x.isInvoiced,
              isCollapsed: true,
              deliveryNoteConcept: x.concept,
              deliveryNoteIssuerId: x.issuerId,
              deliveryNoteSeriesId: x.seriesId,
              deliveryNoteGenDate: x.generateDate,
              deliveryNoteGenDateObject: new Date(moment(x.generateDate).format('YYYY-MM-DD')),
              deliveryNoteTotalPrice: toFixedNumber(this.GetSum(x.articleList, 'articleFinalPrice')),
              deliveryNoteTax: x.tax ? x.tax : 0,
              invoiceDetailList: x.articleList ? x.articleList.map(a => new InvoiceDetail({
                invoiceId: this.invoiceHeader.id,
                deliveryNoteId: x.id,
                deliveryNoteIssuerId: x.issuerId,
                deliveryNoteSeriesId: x.seriesId,
                workId: x.workId,
                workName: a.workName,
                deliveryNoteGenNo: x.generateNo,
                deliveryNoteGenDate: moment(x.generateDate).format('YYYY-MM-DD'),
                deliveryNoteConcept: x.concept,
                mwtArticleId: a.mwtArticleId,
                articleName: a.articleName,
                articleCode: a.code,
                articleQuantity: a.articleQuantity,
                articlePrice: toFixedNumber(a.articlePrice),
                articleDiscountAmount: toFixedNumber(a.articleDiscountAmount),
                articleDiscount: toFixedNumber(a.articleDiscount),
                articleFinalPrice: toFixedNumber(a.articleFinalPrice),
                articleWaste: toFixedNumber(a.articleWaste),
                invoiceHeaderNo: this.invoiceHeader.generateNumber,
                isInvoiced: x.isInvoiced,
                isDiscountAmount: a.articleIsAmount,
                clientName: this.invoiceHeader.clientName
              })) : [],
              workId: x.workId,
              invoiceId: x.invoiceId
            }
          ));
        }
        return [];
      }
    } catch (error) { console.log(error); } finally {
      this.checkDNObject = { load: '', singleId: null };
    }
  }

  private GetDeliveryNoteListFromInvoiceDetailList(detailList: InvoiceDetail[]) {
    if (detailList) {
      const deliveryNoteList = detailList
        .map(x => new DisplayDeliveryNoteViewModel({
          deliveryNoteId: x.deliveryNoteId,
          deliveryNoteGenNo: x.deliveryNoteGenNo,
          deliveryNoteConcept: x.deliveryNoteConcept,
          deliveryNoteGenDate: x.deliveryNoteGenDate,
          deliveryNoteIssuerId: x.deliveryNoteIssuerId,
          deliveryNoteSeriesId: x.deliveryNoteSeriesId,
          finalPrice: toFixedNumber(x.articleFinalPrice),
          workId: x.workId,
          invoiceId: x.invoiceId,
          isInvoiced: x.isInvoiced
        }));
      return deliveryNoteList;
    }
  }

  private GoToPage(pageData: { count: number, currentPageNumber: number }) {
    const first: number = (pageData.currentPageNumber - 1) * NUM_OF_NOTES;
    const last: number = pageData.currentPageNumber * NUM_OF_NOTES;
    this.invoiceDeliveryNoteList.forEach(item => {
      item.isVisible = false;
      item.isCollapsed = true;
      const index: number = this.invoiceDeliveryNoteList.indexOf(item);
      if (first <= index && index < last) { item.isVisible = true; }
      this.currentPageNumber = pageData.currentPageNumber;
    });
  }

  private GetPages() {
    const totalPages = this.invoiceDeliveryNoteList.length === 0 ? 1 :
      Math.ceil(this.invoiceDeliveryNoteList.length / NUM_OF_NOTES);
    if (this.currentPageSize !== totalPages) {
      this.currentPageSize = totalPages;
      this.pages = [];
      const start = 1;
      const end = totalPages;
      let itPage = start;
      while (itPage <= end) {
        this.pages.push(itPage);
        itPage++;
      }
    }
  }

  private GetInvoiceDeliveryNoteList(detailList: InvoiceDetail[]) {
    try {
      let deliveryNoteList = this.GetDeliveryNoteListFromInvoiceDetailList(detailList);
      deliveryNoteList = deliveryNoteList ? deliveryNoteList : [];
      return Array.from(new Set(deliveryNoteList.map(x => x.deliveryNoteId)))
        .map(x => {
          const delNote = deliveryNoteList.find(s => s.deliveryNoteId === x);
          return new InvoiceDeliveryNoteViewModel(
            {
              deliveryNoteGenNo: delNote.deliveryNoteGenNo,
              deliveryNoteId: x,
              isChecked: this.checkDNObject.load === 'all' || (this.checkDNObject.load === 'single'
                && this.checkDNObject.singleId === x) ? true : delNote.isInvoiced,
              isVisible: true,
              isInvoiced: this.checkDNObject.load === 'all' || (this.checkDNObject.load === 'single'
                && this.checkDNObject.singleId === x) ? true : delNote.isInvoiced,
              isCollapsed: false,
              deliveryNoteConcept: delNote.deliveryNoteConcept,
              deliveryNoteIssuerId: delNote.deliveryNoteIssuerId,
              deliveryNoteSeriesId: delNote.deliveryNoteSeriesId,
              deliveryNoteGenDate: delNote.deliveryNoteGenDate,
              deliveryNoteGenDateObject: new Date(moment(delNote
                .deliveryNoteGenDate).format('YYYY-MM-DD')),
              deliveryNoteTotalPrice: toFixedNumber(this.GetSum(deliveryNoteList
                .filter(s => s.deliveryNoteId === x), 'finalPrice')),
              invoiceDetailList: detailList.filter(d => d.deliveryNoteId === x),
              workId: delNote.workId,
              invoiceId: delNote.invoiceId
            }
          );
        });
    } catch (error) { console.log(error); }
  }

  DoCalculations(list: InvoiceDeliveryNoteViewModel[]) {
    if (list.length > 0 && this.invoiceHeader) {
      this.invoiceHeader.invoiceTotal = this._invoiceService
        .GetInvoiceSubTotalAmount(list, 'deliveryNoteTotalPrice');
      this.invoiceHeader.sumOfTax = toFixedNumber(this.GetSum(list, "deliveryNoteTax"));
      this.invoiceHeader.sumOfSubTotal = toFixedNumber(this.invoiceHeader.invoiceTotal
        - this.invoiceHeader.sumOfTax);

      if (this.invoiceHeader.discount) {
        this.invoiceHeader.sumOfDiscount = this._invoiceService
          .GetInvoiceDiscountAmount(this.invoiceHeader.sumOfSubTotal, this.invoiceHeader.discount);
      } else {
        this.invoiceHeader.sumOfDiscount = 0;
      }

      this.invoiceHeader.invoiceTotal = toFixedNumber(this.invoiceHeader.sumOfSubTotal
        - this.invoiceHeader.sumOfDiscount + (this.invoiceHeader.sumOfTax ? this.invoiceHeader.sumOfTax : 0));
      this.invoiceHeader.subTotal = toFixedNumber(this.invoiceHeader.sumOfSubTotal);
      this.invoiceHeader.tax = toFixedNumber(this.invoiceHeader.sumOfTax);
    } else {
      this.invoiceHeader.sumOfSubTotal = 0;
      this.invoiceHeader.sumOfDiscount = 0;
      this.invoiceHeader.invoiceTotal = 0;
      this.invoiceHeader.sumOfTax = 0;
      this.invoiceHeader.tax = 0;
      this.invoiceHeader.subTotal = 0;
    }
  }

  async OnShowPending() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      let pendingViewModelList = await
        this.GetPendingInvoiceDeliveryNoteViewModelListByClientId(this.invoiceHeader.clientId);
      const notExistList = [];
      if (pendingViewModelList && pendingViewModelList.length > 0) {
        for (let index = 0; index < pendingViewModelList.length; index++) {
          const pvm = pendingViewModelList[index];
          const i = this.invoiceDeliveryNoteList
            .findIndex(idn => idn.deliveryNoteId === pvm.deliveryNoteId);
          if (i === -1) { notExistList.push(pvm); }
        }
      }
      this.invoiceDeliveryNoteList = this.invoiceDeliveryNoteList
        .concat(notExistList.length > 0 ? notExistList : []);
    } catch (error) { console.log(error); } finally {
      this.GetPages();
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  OnToggleLeftCheckBoxes(invDeliveryNote: InvoiceDeliveryNoteViewModel) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (invDeliveryNote) {
        invDeliveryNote.isChecked = !invDeliveryNote.isChecked;
        invDeliveryNote.isInvoiced = invDeliveryNote.isChecked;
        const checkedList = this._invoiceDeliveryNoteList.filter(i => i.isChecked);
        this.DoCalculations(checkedList);
        this.invoiceDeliveryNoteListChanged.emit({
          list: checkedList,
          invoiceHeader: this.invoiceHeader
        });
        this.isAllChecked = this.invoiceDeliveryNoteList.every(i => i.isChecked);
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  OnToggleAllCheckBox(checked: boolean) {
    if (this.invoiceDeliveryNoteList) {
      this._invoiceDeliveryNoteList.forEach(idn => {
        idn.isChecked = !checked;
        idn.isInvoiced = !checked;
      });
      this.invoiceDeliveryNoteList.forEach(idn => {
        idn.isChecked = !checked;
        idn.isInvoiced = !checked;
      });
      this.invoiceHeader.sumOfDiscount = 0;
      this.invoiceHeader.invoiceTotal = 0;
      this.invoiceHeader.sumOfSubTotal = 0;
      const checkedList = this._invoiceDeliveryNoteList.filter(i => i.isChecked);
      this.DoCalculations(checkedList);
      this.invoiceDeliveryNoteListChanged.emit({
        list: checkedList,
        invoiceHeader: this.invoiceHeader
      });
      this.isAllChecked = this.invoiceDeliveryNoteList
        .every(i => i.isChecked);
    }
  }

  OnFilterDeliveryNoteList() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      this.invoiceDeliveryNoteList = [...this._invoiceDeliveryNoteList];
      if (this.deliveryNoteFilter) {
        if (this.deliveryNoteFilter.issuerId && this.deliveryNoteFilter.issuerId !== '0') {
          this.invoiceDeliveryNoteList =
            this.invoiceDeliveryNoteList.filter(x =>
              Number(x.deliveryNoteIssuerId) === Number(this.deliveryNoteFilter.issuerId));
        }
        if (this.deliveryNoteFilter.seriesId && this.deliveryNoteFilter.seriesId !== '0') {
          this.invoiceDeliveryNoteList =
            this.invoiceDeliveryNoteList.filter(x =>
              Number(x.deliveryNoteSeriesId) === Number(this.deliveryNoteFilter.seriesId));
        }
        if (this.deliveryNoteFilter.fromDate && this.deliveryNoteFilter.toDate) {
          const from = new Date(this._commonUiService
            .ChangeNgbDateToCustomFormat(this.deliveryNoteFilter.fromDate));
          const to = new Date(this._commonUiService
            .ChangeNgbDateToCustomFormat(this.deliveryNoteFilter.toDate));
          this.invoiceDeliveryNoteList =
            this.invoiceDeliveryNoteList.filter(x =>
              from <= x.deliveryNoteGenDateObject && to >= x.deliveryNoteGenDateObject);
        } else {
          if (this.deliveryNoteFilter.toDate && (this.deliveryNoteFilter.fromDate === undefined
            || this.deliveryNoteFilter.fromDate === null)) {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['invoice'],
              msg: this._commonDataService.localizationLabelList['from_date_is_missing']
            });
          }
          if (this.deliveryNoteFilter.fromDate &&
            (this.deliveryNoteFilter.toDate === undefined
              || this.deliveryNoteFilter.toDate === null)) {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['invoice'],
              msg: this._commonDataService.localizationLabelList['to_date_is_missing']
            });
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  OnSelectFromDate() {
    this.deliveryNoteFilter.toDate = this.deliveryNoteFilter.toDate ?
      this.deliveryNoteFilter.toDate : this.deliveryNoteFilter.fromDate;
  }
}
