import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { ModalNames } from '../../../common/global';
import { MwtIncidentDocument } from '../../entities/main-work-type-incident-document';

@Component({
  selector: 'app-incident-doc-view',
  templateUrl: './incident-doc-view.component.html',
  styleUrls: ['./incident-doc-view.component.scss']
})
export class IncidentDocViewComponent implements OnInit {
  showContent = false;
  showPopUp = false;

  document: MwtIncidentDocument;

  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  constructor(private _modalService: ModalService,) {
    this.showContent = true;
  }

  ngOnInit() {
    if (this._modalService.modal === ModalNames.IncidentDocumentModal) {
      if (this._modalService.transferable && this._modalService.transferable.document) {
        this.document = this._modalService.transferable.document;
      }
    }
  }

  OnOpenModal(modal: string) {
    this.showPopUp = true;
    this._modalService.Invoke(modal, true, {
      isEdit: false,
    }, false);
  }

  onClose() {
    this.closeModel.emit();
  }

  onDownload(document: MwtIncidentDocument) {
    if (document) {
      window.open(document.link, '_blank');
    }
  }

}
