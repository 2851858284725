export class MtwPhaseTask {
    id: number;
    mwtphaseId: number;
    taskId: any;
    taskOrderNo: number;
    workerId: any;
    taskStatus: string;

    mainWorkTypeId: number; // both phase mainwork type id and task main work type id should be same
    deliveryDate: string;
    deliveryDateObject: any;
    endTime: string;
    taskValue: number;

    // MwtPhaseTask expand list properties
    typeOfWorkNumber: any;
    subWorkNumber: any;
    clientId: number;
    clientName: string;
    workerName: string;
    taskName: string;
    date: any;
    workId: number;
    subWorkId: number;
}
