import { MainWorkType } from './main-work-type';
export class SubWork {
    id: number;
    workId: number;
    subWorkStatus: string;
    status: boolean;
    createdDate: any;
    situation: string;
    generateNo: number;
    mainWorkTypeList: MainWorkType[];
    manualStatus: boolean;
    dentboxGuid: string;
    isDentboxWork: boolean;
    description: string;
    doctorId: any;
    doctorName: string;
    doctorRegistrationNumber: string;

    constructor(init?: Partial<SubWork>) {
        if (init) { Object.assign(this, init); }
    }
}

