import API from 'src/app/modules/common/api.config.json';
import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { ApiService } from '../../../common/services/api.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DropdownService } from '../../../common/services/dropdown.service';
import { Toast } from '../../../common/services/toast.service';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { CommonDataService } from '../../../common/services/common.data.service';
import { ShowInvalidFormControls, ValidateNgSelect } from '../../../common/services/validators';
import { MainWorkType } from '../../entities/main-work-type';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { IncidentTypeList, ModalNames } from '../../../common/global';
import { MwtIncident } from '../../entities/main-work-type-incident';
import { IncidentReason } from '../../../auxiliary/entities/incident-reason';
import { Worker } from '../../../workers/entities/worker';
import { IncidentService } from '../../services/mwt-incident.service';
import { MwtIncidentDocument } from '../../entities/main-work-type-incident-document';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-incident-add-work',
  templateUrl: './incident-add-work.component.html',
  styleUrls: ['./incident-add-work.component.scss']
})
export class IncidentAddWorkComponent implements OnInit {

  @ViewChild('fileInput') fileInputVariable: ElementRef;

  // form groups
  incidentDataFormGroup: FormGroup;

  // lists
  selectedMainWorkTypeList: MainWorkType[] = [];
  incidentReasonListAll: IncidentReason[] = [];
  incidentReasonList: IncidentReason[] = [];
  workerList: Worker[] = [];
  incidentDocumentList: MwtIncidentDocument[] = [];
  incidentTypeList = IncidentTypeList;

  // objects
  selectedMainWorkType: MainWorkType;
  selectedSubWork: SubWork;
  selectedWork: Work;
  incidentData: MwtIncident;
  showContent = true;
  showPopUp = false;
  isEdit = false;

  @Output() closeModel: EventEmitter<any> = new EventEmitter();
  @Output() refreshData: EventEmitter<any> = new EventEmitter();

  constructor(private _modalService: ModalService, private _apiService: ApiService,
    private _formBuilder: FormBuilder, public _commonUiService: CommonUIService,
    public _commonDataService: CommonDataService, public dropdownService: DropdownService,
    public _incidentService: IncidentService, private _toastyService: Toast,
    private _sanitizer: DomSanitizer, private cdref: ChangeDetectorRef) {

    this.incidentDocumentList = [] as MwtIncidentDocument[];
    this.incidentData = new MwtIncident();
    this.onInItFormGroups();
  }

  ngOnInit() {
    this.onInItData();
  }

  ngAfterViewInit(): void {
    this.incidentData = new MwtIncident();
    if (this._modalService.modal === ModalNames.IncidentModal) {
      if (this._modalService.transferable) {
        this.selectedMainWorkTypeList = this._modalService.transferable.mainWorkTypeList as MainWorkType[];
        this.selectedWork = this._modalService.transferable.selectedWork as Work;
        this.isEdit = this._modalService.transferable.isEdit;
        if (this._modalService.transferable.isEdit) {
          this.onEditMwtIncidentDataModal(this._modalService.transferable.incidentData as MwtIncident);
        } else {
          this.onOpenMWTIncidentDataModal();
        }
      }
    }
  }

  onInItFormGroups() {
    this.incidentDataFormGroup = this._formBuilder.group({
      mainWorkTypeId: ['', [ValidateNgSelect]],
      incidentReasonId: ['', [ValidateNgSelect]],
      incidentReasonType: ['', [ValidateNgSelect]],
      workerName: [],
      registerTime: [],
      registerDate: [],
      isResolved: [],
      resolvedDate: [{ value: '', disabled: true }],
      note: []
    });
  }

  async onInItData() {
    const reasonList = this.dropdownService.incidentReasonList;
    this.incidentReasonList = reasonList.filter(o => o.status);
    this.incidentReasonListAll = reasonList.filter(o => o.status);
    this.workerList = this.dropdownService.workerList;
    this.cdref.markForCheck();
  }

  onOpenMWTIncidentDataModal() {
    this._commonUiService.isSpinnerVisible = true;
    this.incidentData.incidentReasonId = 0;
    this.incidentData.incidentReasonType = 0;
    if (this.selectedMainWorkTypeList.length === 1) {
      this.incidentData.mainWorkTypeId = Number(this.selectedMainWorkTypeList[0].id);
      this.incidentDataFormGroup.controls['mainWorkTypeId'].setValue(this.selectedMainWorkTypeList[0].id);
    } else { this.incidentData.mainWorkTypeId = 0; }
    this.incidentDataFormGroup.controls['registerDate'].setValue(this._commonUiService.ConvertToPickerDateFormat(new Date().toJSON()));
    const nowtime = new Date().toLocaleString('en-US', { hour12: false }).split(',')[1].trim().toString();
    this.incidentDataFormGroup.controls['registerTime'].setValue(nowtime.slice(0, 5));
    this._commonUiService.isSpinnerVisible = false;
    this.cdref.markForCheck();
  }

  // Main Methods

  async onSaveMwtIncidentData() {
    if (this.incidentDataFormGroup.invalid) { ShowInvalidFormControls(this.incidentDataFormGroup); return; }
    try {
      this._commonUiService.isSpinnerVisible = true;
      this.incidentData.workId = this.selectedWork.id;
      this.incidentData.mwtincidentDocument = [] as MwtIncidentDocument[];
      this.incidentData.mwtincidentDocument = this.incidentDocumentList;
      const loggedUser = this._commonDataService.GetUserFromSession();
      this.incidentData.createdBy = loggedUser ? loggedUser.id : null;

      if (this.incidentData.registerDate) {
        this.incidentData.registerDate = this._commonUiService.ConvertDateFormatToString(this.incidentData.registerDate);
      }
      if (this.incidentData.resolvedDate) {
        this.incidentData.resolvedDate = this._commonUiService.ConvertDateFormatToString(this.incidentData.resolvedDate);
      }

      const response = await this._incidentService.SaveIncident(this.incidentData);
      console.log(response);
      if (response) {

        if (response.status === 200) {
          this._toastyService.success({
            title: this._commonDataService.localizationLabelList['incident'],
            msg: this._commonDataService.localizationLabelList['save_success']
          });
          this.refreshData.emit();
        } else {
          this._toastyService.error({
            title: this._commonDataService.localizationLabelList['incident'],
            msg: this._commonDataService.localizationLabelList['save_error']
          });
        }
        this.OnClose();
        this._commonUiService.isSpinnerVisible = false;
      }
    } catch (error) { console.log(error); }
    finally {
      this.cdref.markForCheck();
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  async uploadDocument(formData: FormData) {
    return new Promise<void>((resolve, reject) => {
      this._apiService.upload(API.file_upload.upload, formData)
        .subscribe(res => {
          if (res['body'] == 200) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['document'],
              msg: this._commonDataService.localizationLabelList['document_upload_successfully']
            });
            resolve();
          }
        },
          err => reject(err),
          () => this._commonUiService.isSpinnerVisible = false);
    });
  }

  async getDocumentPath(incident: MwtIncidentDocument) {
    return new Promise<MwtIncidentDocument>((resolve, reject) => {
      this._apiService.post(API.incident_data.getIncidentDocumentPath, incident, true)
        .subscribe((incidentDoc: MwtIncidentDocument) => resolve(incidentDoc),
          err => reject(err));
    });
  }

  // Events
  OnChooseDocument(event: any) {
    if (event) {
      const files: any = event.target.files;      
      if (files && this.incidentDocumentList) {
        const exist = this.incidentDocumentList.find(o => o.fileName === files[0].name);
        if (exist) {
          this._toastyService.warning({
            title: this._commonDataService.localizationLabelList['incident'],
            msg: this._commonDataService.localizationLabelList['already_exists']
          });
          this.fileInputVariable.nativeElement.value = '';
          return;
        }
      }
      const documentData = new MwtIncidentDocument();
      documentData.fileList = files;
      documentData.mwtincidentId = this.incidentData.id;
      this.OnSaveIncidentDataDocument(documentData);
      this.cdref.markForCheck();
    }
  }

  public OnSaveIncidentDataDocument(incidentDataDocument: MwtIncidentDocument): void {
    if (this.incidentData.mainWorkTypeId == 0) {
      return;
    }
    if (incidentDataDocument) {
      if (incidentDataDocument.fileList.length > 0) {
        incidentDataDocument.fileName = (incidentDataDocument.fileList[0]).name;
        incidentDataDocument.extension = ((incidentDataDocument.fileList[0]).name).split('.')[(((incidentDataDocument.fileList[0]).name)
          .split('.')).length - 1];
        this._incidentService.SaveIncidentDataDocument(incidentDataDocument)
          .then(incidentDocument => {
            if (incidentDocument) {
              const formData: FormData = new FormData();
              formData.append(incidentDocument.fileName, incidentDataDocument.fileList[0], incidentDocument.generateFileName);
              formData.append('folderName', 'IncidentData');
              formData.append('id', this.incidentData.mainWorkTypeId.toString());
              this.uploadDocument(formData).then(o => {
                this.fileInputVariable.nativeElement.value = '';
                incidentDocument.mainWorkTypeId = this.incidentData.mainWorkTypeId;
                this.getDocumentPath(incidentDocument).then(incident => {
                  if (incidentDocument && incident) {
                    incidentDocument.id;
                    incidentDocument.path = (incident.path) ? incident.path : incidentDocument.path;
                    incidentDocument.extension = incidentDocument.fileName.split('.')[(incidentDocument.fileName.split('.')).length - 1];
                    incidentDocument.link = this._apiService.BASE_URL + API.incident_data.getIncidentDocument + incidentDocument.id + '/' + this.incidentData.mainWorkTypeId + '/' + incidentDocument.generateFileName;
                    if (incidentDocument.path) {
                      if (incidentDocument.extension === 'pdf') {
                        incidentDocument.path = incidentDocument.path.toString().includes('data:application/pdf;base64,')
                          ? incidentDocument.path : 'data:application/pdf;base64,' + incidentDocument.path;
                      }
                      if (incidentDocument.extension === 'png') {
                        incidentDocument.path = incidentDocument.path.toString().includes('data:image/png;base64,')
                          ? incidentDocument.path : 'data:image/png;base64,' + incidentDocument.path;
                      }
                      if (incidentDocument.extension === 'jpg') {
                        incidentDocument.path = incidentDocument.path.toString().includes('data:image/jpg;base64,')
                          ? incidentDocument.path : 'data:image/jpg;base64,' + incidentDocument.path;
                      }
                      if (incidentDocument.extension === 'jpeg') {
                        incidentDocument.path = incidentDocument.path.toString().includes('data:image/jpeg;base64,')
                          ? incidentDocument.path : 'data:image/jpeg;base64,' + incidentDocument.path;
                      }
                      if (incidentDocument.extension === 'docx') {
                        incidentDocument.path = incidentDocument.path.toString().includes('https://docs.google.com/gview?url=')
                          ? incidentDocument.path : 'https://docs.google.com/gview?url=' + incidentDocument.link + '&embedded=true';
                      }
                      if (incidentDocument.extension === 'xlsx' || incidentDocument.extension === 'xls') {
                        incidentDocument.path = incidentDocument.path.toString().includes('https://docs.google.com/gview?url=')
                          ? incidentDocument.path : 'https://docs.google.com/gview?url=' + incidentDocument.link + '&embedded=true';
                      }
                    }
                  }

                });
              });
              this.incidentDocumentList.push(incidentDocument);
              this.cdref.markForCheck();
            }
          });
      }
      this.cdref.markForCheck();
    }
  }

  onOpenPreviewDocumentModal(modal: string, document: MwtIncidentDocument) {
    if (modal && document) {
      this.showPopUp = true;
      document.safeUrl = document.path ? this._sanitizer.bypassSecurityTrustResourceUrl(document.path) : undefined;
      this._modalService.Invoke(modal, true, {
        document: document, isEdit: true
      });
    }
  }

  OnClose() {
    this.closeModel.emit();
  }

  onChangeWorker(worker: Worker) {
    if (worker) {
      this.incidentDataFormGroup.controls['workerName'].setValue(worker.name);
      this.incidentData.workerId = worker.id;
    }
  }

  onClearWorker() {
    this.incidentDataFormGroup.controls['workerName'].reset();
    this.incidentData.workerId = null;
  }

  onchangeResolve() {
    if (this.incidentData.isResolved) {
      this.incidentDataFormGroup.controls.resolvedDate
        .setValue(this._commonUiService.ConvertToPickerDateFormat(new Date().toJSON()));
    }
    else {
      this.incidentDataFormGroup.controls.resolvedDate.setValue('');
    }
  }

  async onEditMwtIncidentDataModal(mwtIncident: MwtIncident) {
    if (mwtIncident) {
      this._commonUiService.isSpinnerVisible = true;
      this._commonUiService.isPopupOpened = true;
      this.incidentDataFormGroup.markAsPristine();
      this.incidentDataFormGroup.markAsUntouched();
      this.incidentData = Object.assign(this.incidentData, mwtIncident);
      this.incidentDocumentList = Object.assign(this.incidentDocumentList, mwtIncident.mwtincidentDocument);

      if (this.incidentData.mainWorkTypeId) {
        this.incidentData.mainWorkTypeId;//= Number(this.selectedMainWorkTypeList[0].id);
        this.incidentDataFormGroup.controls['mainWorkTypeId'].setValue(this.incidentData.mainWorkTypeId);
      } else { this.incidentData.mainWorkTypeId = 0; }

      const worker = this.workerList.find(o => o.id === mwtIncident.workerId)
      if (worker) {
        this.incidentDataFormGroup.controls['workerName'].setValue(worker.name);
      }
      if (mwtIncident.registerDate) {
        this.incidentData.registerDate = this._commonUiService.ConvertToPickerDateFormat(mwtIncident.registerDate);
      }
      if (this.incidentData.resolvedDate) {
        this.incidentData.resolvedDate = this._commonUiService.ConvertToPickerDateFormat(mwtIncident.resolvedDate);
      }
      this.onChangeIncidentType();
      this._commonUiService.isSpinnerVisible = false;
      this.cdref.markForCheck();
    }
  }

  onRemoveDocumentFromList(index: number) {
    if (index !== -1) {
      this.incidentDocumentList.splice(index, 1);
    }
  }

  onChangeIncidentType() {
    if (Number(this.incidentData.incidentReasonType) === 1)
      this.incidentReasonList = this.incidentReasonListAll.filter(o => o.type === 1);
    else if (Number(this.incidentData.incidentReasonType) === 2)
      this.incidentReasonList = this.incidentReasonListAll.filter(o => o.type === 2);
    else
      this.incidentReasonList = this.incidentReasonListAll;
  }
}
