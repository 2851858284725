import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastyService } from 'ng2-toasty';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { WorkReport } from '../../entities/work-report';

@Component({
  selector: 'app-work-report',
  templateUrl: './work-report.component.html'
})
export class WorkReportComponent implements OnInit {

  @Input() public workReport: WorkReport;
  imageData: any;
  @Input() isPdf?: boolean;


  constructor(private _router: Router,
    private _apiService: ApiService,
    private _fb: FormBuilder,
    private httpClient: HttpClient,
    private _commonUIService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: ToastyService) { }

  ngOnInit() {
    this.LoadLabConfigurationList();
  }

  async LoadLabConfigurationList() {
    this.imageData = await this._commonUIService.LoadLabConfigurationList();
  }
}
