export class BudgetArticle {
    id: number;
    budgetId: number;
    articleId: number; //mwt article id
    articleName: string;
    code: string;
    status: boolean;
    quantity: number;
    waste: number;
    price: number;
    discount: number;
    ivaTypeId: number
    ivaPercentage: number;
    ivaAmount: number;
    finalPrice: number;

    quantityWithWaste: number;
    priceWithDiscount: number;
    discountAmount: number;
    lineDiscountAmount: number | null;
    lineSubTotal: number | null;
}