import { FormControl } from "@angular/forms";

export class MwtMetal {
    id: number;
    metalId: number;
    metalName: string;
    mainWorkTypeId: number;
    cssClass: string;
    symbol: string;

    order: number;
    workId: number;
    createdBy: number;
    createdDate: string;
    isClosed: boolean;

    mwtMetalControl: FormControl;

    constructor(init?: Partial<MwtMetal>) {
        Object.assign(this, init);
    }
}