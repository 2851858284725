export enum InventoryFrequency {
  Anual = 1,
  Semestral = 2,
  Mensual = 3,
  No_Inventariable = 4,
  Trimestral = 5
}

//** don't change this enum. its also refering API enum **//
export enum ClientBaseType {
  Empresa,
  Privado
}

//** don't change this enum. its also refering API enum **//
export enum Gender {
  Male = 1,
  Female = 2,
  Other = 3
}
export enum SeriesNumberType {
  Anual = "1",
  Continuo = "2"
}

//export enum SeriesYear {
//  k2017 = 2017,
//  b = 2018,
//  c = 2019,
//  d = 2020,
//  e = 2021,
//  f = 2022
//}

// don't change this enum. its also refering API enum
export enum UrgencyType {
  High = 1,
  Half = 2,
  Low = 3
}

// don't change this enum. its also refering API enum
export enum Priority {
  Normal = 1,
  Urgent = 2
}


// don't change this enum. it's using in client page -> work panel resport
export enum ClientWorkReportType {
  WorkReport = 1,
  ConformityReport = 2,
  UsingReport = 3,
  GuaranteeReport = 4
}

// don't change this enum. it's using in provider page -> offer data
export enum OfferDataType {
  ProviderOfferData = 1,
  ProviderProductOfferData = 2
}

