import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { AuthGuard } from './modules/common/auth-guard';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/auth/auth.module#AuthModule',
        pathMatch: 'full'
      },
      {
        path: 'auth',
        loadChildren: './modules/auth/auth.module#AuthModule'
      }
    ]
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'auxiliary',
        loadChildren: './modules/auxiliary/auxiliary.module#AuxiliaryModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'work',
        loadChildren: './modules/work/work.module#WorkModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'work-tablet-mode',
        loadChildren: './modules/work-tablet/work-tablet.module#WorkTabletModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'user',
        loadChildren: './modules/user/user.module#UserModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'workers',
        loadChildren: './modules/workers/workers.module#WorkersModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'provider',
        loadChildren: './modules/provider/provider.module#ProviderModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'price-list',
        loadChildren: './modules/price-list/price-list.module#PriceListModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'article',
        loadChildren: './modules/articles/articles.module#ArticlesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'products',
        loadChildren: './modules/products/products.module#ProductsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'work-type',
        loadChildren: './modules/work-type/work-type.module#WorkTypeModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'client',
        loadChildren: './modules/client/client.module#ClientModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'tasks',
        loadChildren: './modules/tasks/tasks.module#TasksModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'invoice',
        loadChildren: './modules/invoice/invoice.module#InvoiceModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'delivery-note',
        loadChildren: './modules/delivery-note/delivery-note.module#DeliveryNoteModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'configuration',
        loadChildren: './modules/configuration/configuration.module#ConfigurationModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'warehouse',
        loadChildren: './modules/warehouse/warehouse.module#WarehouseModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'orders',
        loadChildren: './modules/orders/orders.module#OrdersModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'bank',
        loadChildren: './modules/bank/bank.module#BankModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'order-receipt',
        loadChildren: './modules/order-receipt/order-receipt.module#OrderReceiptModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'expenses',
        loadChildren: './modules/expenses/expenses.module#ExpensesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'receipt',
        loadChildren: './modules/receipt/receipt.module#ReceiptModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'budgets',
        loadChildren: './modules/budgets/budgets.module#BudgetsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'messaging',
        loadChildren: './modules/messaging/messaging.module#MessagingModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'couriers',
        loadChildren: './modules/couriers/couriers.module#CouriersModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'product-inventory',
        loadChildren: './modules/product-inventory/product-inventory.module#ProductInventoryModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'external-shipment',
        loadChildren: './modules/external-shipment/external-shipment.module#ExternalShipmentModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'registered-shipments',
        loadChildren: './modules/registered-shipments/registered-shipments.module#RegisteredShipmentsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'job-search',
        loadChildren: './modules/job-search/job-search.module#JobSearchModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'work-load',
        loadChildren: './modules/work-load/work-load.module#WorkLoadModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'reservation',
        loadChildren: './modules/reservations/reservations.module#ReservationsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'lab-calender',
        loadChildren: './modules/lab-calender/lab-calender.module#LabCalenderModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'global-view-tasks',
        loadChildren: './modules/global-view-tasks/global-view-tasks.module#GlobalViewTasksModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'error-view',
        loadChildren: './modules/error-view/error-view.module#ErrorViewModule'
      },
      {
        path: 'global-view-job',
        loadChildren: './modules/global-view-jobs/global-view-jobs.module#GlobalViewJobsModule',
        canActivate: [AuthGuard]
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
