import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, NgForm } from '@angular/forms';
import API from './../../../../common/api.config.json';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { ReservationMethods } from '../../../entities/reservation-methods';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reservation-methods',
  templateUrl: './reservation-methods.component.html'
})
export class ReservationMethodsComponent implements OnInit {

  successMessage: any;
  updateMessage: any;
  errorMessage: any;
  deleteMessage: any;
  statusMessage: any;
  deleteErrorMessage: any;
  tableName: any;

  reservationMethods: ReservationMethods;
  reservationMethodsList: ReservationMethods[];
  _reservationMethodsList: ReservationMethods[] = [];
  isLoad: boolean;
  position = 'bottom-right';

  reservationMethodsFormGroup: FormGroup;
  reservationMethodsForm: NgForm;
  isDisabledReservationMethod = false;

  @ViewChild('description') nameField: ElementRef;

  constructor(
    private _router: Router,
    private _apiService: ApiService,
    private _fb: FormBuilder,
    private _commonUIService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: Toast) {
      this.isLoad = false;
      this.isDisabledReservationMethod = false;
      this.reservationMethods = new ReservationMethods();
    }

  ngOnInit() {
    this.reservationMethodsFormGroup = this._fb.group({
      description: ['', [Validators.required]]
    });
    this.loadReservationMethodList();
    this.reservationMethods = new ReservationMethods();
  }

  loadReservationMethodList() {
    this._apiService.get(API.reservation_method.getReservationMethodsList)
      .subscribe(res => {
        this.reservationMethodsList = res;
        this._reservationMethodsList = res;
        this.isLoad = true;
      });
  }

  loadEditReservationMethod(reservationMethods: ReservationMethods) {
    reservationMethods.isEdit = true;
  }

  editReservationMethod(reservationMethod: ReservationMethods, statusChange: boolean) {
    this.isDisabledReservationMethod = true;
    reservationMethod.isEdit = false;

    this.statusMessage = this._commonDataService.localizationLabelList['status_updated'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.tableName = this._commonDataService.localizationLabelList['reservation_methods'];

    this._apiService.put(API.reservation_method.editReservationMethods + reservationMethod.id, reservationMethod, false)
      .subscribe(
        res => {
          if (res.status === 204 || res.status === 200) {
            let message = '';
            if (statusChange) {
              message = this.statusMessage;
            } else {
              message = this.updateMessage;
            }
            this._toastyService.success({
              title: this.tableName,
              msg: message
            });
            this.loadReservationMethodList();
          }
        },
        err => {
          this._toastyService.error({
            title: this.tableName,
            msg: err
          });
          console.log(err);
        },
        () => {
          this.isDisabledReservationMethod = false;
        }
      );
  }

  removeEditReservationMethod(reservationMethod: ReservationMethods) {
    reservationMethod.isEdit = false;
    this.loadReservationMethodList();
  }

  activeDeactiveReservationMethod(reservationMethod: ReservationMethods) {
    if (reservationMethod) {
      if (reservationMethod.status) {
        reservationMethod.status = false;
      } else {
        reservationMethod.status = true;
      }
      this.editReservationMethod(reservationMethod, true);
    }
  }

  onOpenAddReservationMethodModal() {
    this.reservationMethods = new ReservationMethods();
    this.reservationMethodsFormGroup.reset();
    this._commonUIService.openModal('reservation-method-modal');
    setTimeout(() => {
      this.nameField.nativeElement.focus();
    }, 100);
  }

  onCloseReservationMethodModal(event) {
    this._commonUIService.CloseModalEx('reservation-method-modal');
  }


  saveReservationMethod(reservationMethods: ReservationMethods, event) {
    this.isDisabledReservationMethod = true;
    this.successMessage = this._commonDataService.localizationLabelList['save_success'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.errorMessage = this._commonDataService.localizationLabelList['save_error'];
    this.tableName = this._commonDataService.localizationLabelList['reservation_methods'];

    if (this.reservationMethodsFormGroup.invalid) {
      Object.keys(this.reservationMethodsFormGroup.controls).forEach(field => {
        const control = this.reservationMethodsFormGroup.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      this.isDisabledReservationMethod = false;
      return;
    }

    this._apiService.post(API.reservation_method.saveReservationMethods, reservationMethods, false)
      .subscribe(
        res => {
          if (res.status === 204 || res.status === 200) {
            this._toastyService.success({
              title: this.tableName,
              msg: this.successMessage
            });
            this.loadReservationMethodList();
          }
        },
        err => {
          this._toastyService.error({
            title: this.tableName,
            msg: err
          });
        },
        () => {
          this.isDisabledReservationMethod = false;
          this.onCloseReservationMethodModal(event);
        }
      );
  }

  deleteReservationMethod(id: number) {
    this.isDisabledReservationMethod = true;
    this.deleteMessage = this._commonDataService.localizationLabelList['delete_success'];
    this.deleteErrorMessage = this._commonDataService.localizationLabelList['delete_error2'];
    this.tableName = this._commonDataService.localizationLabelList['reservation_methods'];

    if (id) {
      this._apiService.delete(API.reservation_method.deleteReservationMethods, id.toString())
        .subscribe(
          res => {
            if (res._statusCode === 200) {
              const index: number = this._reservationMethodsList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._reservationMethodsList.splice(index, 1);
                this._toastyService.success({
                  title: this.tableName,
                  msg: this.deleteMessage
                });
              }
            }
            if (res._statusCode === 409) {
              const index: number = this._reservationMethodsList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._toastyService.warning({
                  title: this.tableName,
                  msg: this.deleteErrorMessage
                });
              }
            }
            if (res === 'error') {
              this._toastyService.error({
                title: this.tableName,
                msg: this.deleteErrorMessage
              });
            }
          },
          err => {
            this._toastyService.error({
              title: this.tableName,
              msg: err
            });
            console.log(err);
          },
          () => {
            this.isDisabledReservationMethod = false;
            this.reservationMethodsList = this._reservationMethodsList;
          }
        );
    }
  }
}
