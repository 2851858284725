import { MwtIncidentDocument } from "./main-work-type-incident-document";

export class MwtIncident {
  id: number;
  workId: number;
  mainWorkTypeId: number;
  incidentReasonId: number;
  reasonType: number;
  workerId: number;
  registerDate: any;
  registerTime: string;
  isResolved: boolean;
  resolvedDate: any;
  note: string;
  createdDate: any;
  createdBy: number | null;

  cssClass: string;
  symbol: string;
  order: number;
  isClosed: boolean;

  workerName: string;
  workerSurName: string;
  incidentReasonName: string;
  incidentReasonType: number | null;

  mwtincidentDocument: MwtIncidentDocument[] = [];
}
