import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { distinctUntilChanged } from 'rxjs/operators';
import { DropDownOption } from 'src/app/modules/common/entities/dropdown-option';
import { ModalNames, MovementTypes } from 'src/app/modules/common/global';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonFunctions } from 'src/app/modules/common/services/common-functions';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { ShowInvalidFormControls, ValidateNgSelect } from 'src/app/modules/common/services/validators';
import { ExternalCenter } from 'src/app/modules/external-shipment/entities/external-center';
import { Product } from 'src/app/modules/products/entities/product';
import { ProductLot } from 'src/app/modules/products/entities/product-lot';
import { ProductProvider } from 'src/app/modules/products/entities/product-provider';
import { ProductStock } from 'src/app/modules/products/entities/product-stock';
import { WarehouseStockMovement } from 'src/app/modules/products/entities/warehouse-stock-movement';
import { StockMovementService } from 'src/app/modules/products/services/stock-movement.service';
import { Warehouse } from 'src/app/modules/warehouse/entities/warehouse';
import { WarehouseProduct } from 'src/app/modules/warehouse/entities/warehouse-product';
import { WorkTypeArticleTemplate, WorkTypeProductTemplate } from 'src/app/modules/work-type/entities/work-type-model-templates';
import { Worker } from '../../../workers/entities/worker';
import { LabSection } from '../../entities/lab-section';
import { MainWorkType } from '../../entities/main-work-type';
import { Mwtproduct } from '../../entities/main-work-type-product';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { ArticleTemplateService } from '../../services/article-template.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductModalComponent implements OnInit, AfterViewInit {
  @Output() onSaveProduct = new EventEmitter();
  @Output() closeModel: EventEmitter<any> = new EventEmitter();
  @ViewChild('lotInputElement') private lotElement: any;
  @ViewChild('InputElement') private dateElement: any;
  @ViewChild('warehouseElement') warehouseElementRef: ElementRef;
  @Input() _productTemplateList: WorkTypeProductTemplate[] = [];
  @ViewChild('productInput') productInputVariable: ElementRef;

  showContent = false;
  public isCollapsed = true;

  // form groups
  addProductFormGroup: FormGroup;
  addLotFormGroup: FormGroup;
  movementFormGroup: FormGroup;
  productLotFormGroup: FormGroup;

  // lists
  mainWorkTypeList: MainWorkType[];
  selectedMainWorkTypeList: MainWorkType[] = [];
  productTemplateList: WorkTypeProductTemplate[] = [];
  articleTemplateList: WorkTypeArticleTemplate[] = [];
  productList: Product[] = [];
  articleProductList: Product[] = [];
  artiProduct: Product = new Product();
  productLotList: ProductLot[] = [];
  productAutoCompleteList: any[] = [];
  warehouseList: Warehouse[] = [];
  defaultWarehouse: Warehouse;
  warehouseProduct: WarehouseProduct;
  warehouseStockMovementlist: WarehouseStockMovement[] = [];
  workerList: Worker[] = [];
  userWorker: Worker;
  allProductLotList: ProductLot[];
  lotlist: ProductLot[];
  externalCenterList: ExternalCenter[] = [];



  // objects
  selectedSubWork: SubWork;
  selectedMainWorkType: MainWorkType;
  mwtProduct: Mwtproduct;
  selectedWork: Work;
  hasLoaded = true;
  articleID: number;
  currentStock = 0;
  currentLotStock = 0;
  isUrgentRquest = false;
  isEdit = false;
  isExternal = false;

  // movement
  productLot: ProductLot;
  isDisabledMovements = false;
  productStock: ProductStock;
  warehouseStockMovement: WarehouseStockMovement;
  selectedProvider: ProductProvider;
  selectedProduct: Product;
  labSectionList: LabSection[] = [];
  movementTypeList = MovementTypes;
  productProviderList: ProductProvider[] = [];
  productStockList: ProductStock[] = [];

  lotDropdownList: DropDownOption[] = [];

  @ViewChild('productSearch') productSearchElement: ElementRef;

  constructor(
    private _modalService: ModalService,
    private _formBuilder: FormBuilder,
    private _apiService: ApiService,
    private _translateService: TranslateService,
    public commonUiService: CommonUIService,
    public _commonDataService: CommonDataService,
    private _dropdownService: DropdownService,
    private _toastyService: Toast,
    private _articleTemplateService: ArticleTemplateService,
    private cdref: ChangeDetectorRef,
    private _movementService: StockMovementService,
    private _workService: WorkService
  ) {
    this.mwtProduct = new Mwtproduct();
    this.defaultWarehouse = new Warehouse();
    this.warehouseProduct = new WarehouseProduct();
    this.userWorker = new Worker();
    this.productLot = new ProductLot();
    this.externalCenterList = [] as ExternalCenter[];
    this.InitFormGroups();
    this.InitMoventmentFormGroups();
    this.CalculateTotal();
    this.showContent = true;
    this.isDisabledMovements = false;
  }

  ngOnInit() {
    this.LoadWarehouseList();
    this.LoadLabSectionList();
    this.LoadMovementTypeList();
  }

  async ngAfterViewInit(): Promise<void> {
    if (this._modalService.modal === ModalNames.ProductModal) {
      this.addProductFormGroup.controls.productName.enable();
      if (this._modalService.transferable !== null) {
        this.selectedMainWorkTypeList =
          this._modalService.transferable.selectedMainWorkTypeList;
        this.selectedWork = this._modalService.transferable.selectedWork;
        this.LoadList();
        this.isEdit = this._modalService.isEdit;
        if (!this._modalService.isEdit) {
          await this.OnOpenAddProductModal();
        } else if (this._modalService.isEdit && this._modalService.transferable.productTemplpate != null) {
          this.externalCenterList = this._dropdownService.externalCenterList;
          await this.OnEditMwtProductModal(this._modalService.transferable.productTemplpate);
          if (this.mwtProduct.isMomentUpdate) {
            this.addProductFormGroup.controls.productName.disable();
            this.addProductFormGroup.controls.warehouseId.disable();
            this.addProductFormGroup.controls.lotId.disable();
          }
          else {
            this.addProductFormGroup.controls.lotId.enable();
          }
          this.productInputVariable.nativeElement.click();
        }
        this.cdref.markForCheck();
      }
    }
  }

  //#region add product

  LoadList() {
    this.productList = this._dropdownService.productList
      ? this._dropdownService.productList
      : [];
    this.workerList = this._dropdownService.workerList ? this._dropdownService.workerList : [];
    this.cdref.markForCheck();
  }

  InitFormGroups() {
    this.addProductFormGroup = this._formBuilder.group({
      mainWorkTypeId: ['', [ValidateNgSelect]],
      productId: [],
      productName: ['', [Validators.required]],
      mwtArticleId: [],
      // lotId: ['', [Validators.required]],
      lotId: ['', []],
      lotNumber: ['', []],
      quantity: ['', [Validators.required]],
      price: ['', []],
      stockDate: ['', []],
      warehouseId: ['0', [ValidateNgSelect]],
      workerId: [''],
      note: [],
      externalCenterId: []
    });
  }

  OnClose() {
    this.showContent = false;
    this.closeModel.emit();
  }

  async OnOpenAddProductModal() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      this.commonUiService.isPopupOpened = true;
      this.addProductFormGroup.reset();
      await this.LoadProductPopupData();

      this.mwtProduct = new Mwtproduct();
      await this.LoadDefaultWarehouse().then(warehouse => {
        if (warehouse && warehouse.id) {
          this.defaultWarehouse = warehouse;
          this.addProductFormGroup.controls.warehouseId.setValue(warehouse.id.toString());
        } else {
          this.defaultWarehouse = new Warehouse();
          this.addProductFormGroup.controls.warehouseId.setValue('0');
        }
      });
      this.externalCenterList = this._dropdownService.externalCenterList.filter(x => x.status);
      this.isExternal = false;

      this.mwtProduct.mainWorkTypeId = 0;
      this.addProductFormGroup.controls.productName.setValue('');
      this.mwtProduct.productId = '0';
      this.mwtProduct.quantity = 1;
      this.mwtProduct.lotId = '0';
      this.mwtProduct.mwtArticleId = 0;
      this.mwtProduct.isMomentUpdate = false;

      this.productList = this.productList ? this.productList.filter(c => c.status) : [];
      if (this.productList) { this.productAutoCompleteList = this._commonDataService.GetAutoCompleteList(this.productList, 'name'); }

      this.isUrgentRquest = false;
      this.mwtProduct.workerId = 0;
      // this.addProductFormGroup.controls.workerId.reset();
      this.addProductFormGroup.controls['workerId'].setValidators(null);
      this.addProductFormGroup.controls['workerId'].updateValueAndValidity();
      this.lotElement.nativeElement.click();
    } catch (error) {
      console.log(error);
    } finally {
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  GetUserById(userId: number) {
    return this._apiService.get(API.user.getUserById + userId).map(x => x).toPromise();
  }

  async LoadProductPopupData(productId: number = 0) {
    try {
      if (productId > 0) {
        this.OnLoadLotList(productId);
      }
      this.articleTemplateList = [];
      const idList = this.selectedMainWorkTypeList.map(m => ({
        id: Number(m.id)
      }));
      if (idList) {
        const articleTemplateList = await this._articleTemplateService.GetArticleTemplateListByMainWorkTypeIdList(
          idList
        );
        this.articleTemplateList = articleTemplateList
          ? articleTemplateList
          : [];
      }
      this.cdref.markForCheck();
    } catch (error) {
      console.log(error);
    }
  }

  async OnLoadLotList(productId: number) {
    try {
      await this.LoadProductLotList(productId)
        .then(lotlist => {
          if (lotlist) {
            this.cdref.markForCheck();
            if (lotlist.length == 0) {
              this._toastyService.warning({
                title: this._commonDataService.localizationLabelList['product'],
                msg: this._commonDataService.localizationLabelList[
                  'no_quantity_lot_available_for_product'
                ]
              });
            }
          }
        })
        .catch(er => console.log(er));
    } catch (error) {
      console.log(error);
    }
  }

  async OnLoadWarehouseList(productId: number) {
    try {
      await this.LoadWarehouseListByProductId(productId)
        .then(() => {
          if (this.defaultWarehouse && this.defaultWarehouse.id) {
            const index = this.warehouseList.findIndex(w => w.id === this.defaultWarehouse.id);
            if (index === -1) {
              this.addProductFormGroup.controls.warehouseId.setValue('0');
            }
          }
        })
        .catch(er => console.log(er));
    } catch (error) { console.log(error); }
  }

  private LoadProductLotList(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService
        .get(API.product.getLotsByProductId + id)
        .subscribe(res => {
          this.productLotList = res.filter(x => !x.isNotToUse);
          this.LoadLotDropDown(this.productLotList);
          resolve(this.productLotList);
        });
    });
  }

  private LoadWarehouseListByProductId(productId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService
        .get(API.ware_houses.getWareHouseListByProductId + productId + '/' + 3)
        .subscribe(res => {
          this.warehouseList = CommonFunctions.Sort(res, 'description');
          resolve(this.warehouseList);
        });
    });
  }

  private LoadWarehouseList() {
    this._apiService.get(API.ware_houses.getWareHousesList)
      .subscribe(res => {
        let tempList = res.filter(o => o.status)
        this.warehouseList = CommonFunctions.Sort(tempList, 'description');
      });
  }

  private LoadDefaultWarehouse(): Promise<Warehouse> {
    return new Promise<any>((resolve, reject) => {
      this._apiService
        .get(API.ware_houses.getDefaultWarehouse)
        .subscribe(res => {
          resolve(res);
        });
    });
  }

  async OnChangeProduct(productItem: { display: string; value: number; id: number; }) {
    if (productItem) {
      this.mwtProduct.productId = productItem.id;
      this.mwtProduct.productName = productItem.display;

      this._apiService.get(API.product.getLotsByProductId + this.mwtProduct.productId)
        .subscribe(res => {
          this.allProductLotList = res.filter((pLot, i, arr) => arr.findIndex(t => t.lotId === pLot.lotId) === i);
          this.lotlist = this.allProductLotList.filter(x => !x.isNotToUse);
        }
        );
      this.cdref.markForCheck();
    }
  }

  OnChangeArticle(id) {
    this._apiService
      .get(API.mwt_Article.getMWTArticleId + id)
      .subscribe(res => {
        this.articleID = res;
      });
  }

  OnClearLot() {
    this.addProductFormGroup.controls.lotId.setValue("");
    this.currentLotStock = 0;
    this.mwtProduct.stockOutDate = '';
  }

  OnClearProduct() {
    this.mwtProduct.productId = 0;
    this.currentLotStock = 0;
    this.currentStock = 0;
  }

  OnClearWorker() {
    this.addProductFormGroup.controls['workerId'].setValue(null);
    this.mwtProduct.workerId = null;
    this.cdref.markForCheck();
  }

  OnSelectWorker(worker: Worker) {
    if (worker) {
      this.mwtProduct.workerId = worker.id;
      this.addProductFormGroup.controls['workerId'].setValidators([Validators.required]);
    } else {
      this.mwtProduct.workerId = null;
      this.addProductFormGroup.controls['workerId'].setValidators(null);
    }
    this.addProductFormGroup.controls['workerId'].updateValueAndValidity();
  }

  async OnCheckedUrgentRequest(urgentRquest) {
    this.isUrgentRquest = !urgentRquest;
    if (this.isUrgentRquest) {
      const userData = this._commonDataService.GetUserFromSession();
      if (userData) {
        await this.GetUserById(userData.id).then(user => {
          if (user) {
            this.userWorker = new Worker();
            this.userWorker = this.workerList.find(w => w.id === user.workerId);
            if (this.userWorker) {
              this.addProductFormGroup.controls.workerId.setValue(this.userWorker.name);
              this.addProductFormGroup.controls['workerId'].setValidators([Validators.required]);
              this.addProductFormGroup.controls['workerId'].updateValueAndValidity();
              this.mwtProduct.workerId = this.userWorker.id;
              this.lotElement.nativeElement.click();
            }
          }
        });
      }
    } else {
      this.mwtProduct.workerId = 0;
      // this.addProductFormGroup.controls.workerId.reset();
      this.addProductFormGroup.controls['workerId'].clearValidators();
      this.addProductFormGroup.controls['workerId'].setErrors(null);
      this.addProductFormGroup.controls['workerId'].setValidators(null);
      this.mwtProduct.note = null;
      this.addProductFormGroup.controls.note.reset();
      this.addProductFormGroup.controls['workerId'].updateValueAndValidity();
    }
    this.cdref.markForCheck();
  }

  async OnChangeLot(lotItem: { display: string; value: number; lotId: number; }) {
    if (lotItem) {
      this.commonUiService.isSpinnerVisible = true;
      const lotData = this.productLotList.find(
        x => Number(x.lotId) === Number(lotItem.value)
      );
      this.mwtProduct.lotId = lotItem.value.toString() ? lotItem.value.toString() : '0';
      this.currentLotStock = 0;
      if (this.mwtProduct.lotId !== undefined && this.mwtProduct.lotId !== null && this.mwtProduct.productId > 0) {
        // const list = await this.GetWarehouseStockMovementList(this.mwtProduct.productId, Number(this.mwtProduct.lotId), 0);
        // if (list.length > 0) {
        //   this.currentLotStock = list.reduce((a, b) => a + b.stockOut + b.stockIn, 0);
        // }
        const lot = this.lotlist.filter(x => x.lotId === Number(this.mwtProduct.lotId))
        this.currentLotStock = (lot.length > 0) ? ((lot[0].quantity != null) ? lot[0].quantity : 0) : 0;

      }

      this.mwtProduct.stockOutDate = (lotData) ? this.commonUiService.ConvertToPickerDateFormat(lotData.expireDate) : null;
      this.dateElement.nativeElement.click();
      this.commonUiService.isSpinnerVisible = false;
    }
    this.cdref.markForCheck();
  }

  changeLot(e) {
    let lotData = this.productLotList.filter(data => {
      return data.lotId == e;
    });

    if (lotData.length > 0 && lotData[0].expireDate) {
      this.movementFormGroup.patchValue({
        expireDate: this.commonUiService.ConvertToPickerDateFormat(lotData[0].expireDate)
      });
    }
    else {
      this.movementFormGroup.patchValue({
        expireDate: null
      });
    }
  }

  async OnSelectProduct() {
    if (this.mwtProduct.productId) {
      this.selectedProduct = new Product();
      this.commonUiService.isSpinnerVisible = true;
      this.productList = await this._apiService
        .get(API.product.getProductList)
        .map(list => list as Product[])
        .toPromise();
      this.productList = this.productList ? this.productList : [];
      const product = this.productList.find(
        x => Number(x.id) === Number(this.mwtProduct.productId)
      );
      this.selectedProduct = product;
      this.LoadProductSelectList(this.mwtProduct.productId);
      this.articleProductList = await this._apiService.get(`${API.article_product.article_product_get}/${this.articleID}`).
        map(list => list as Product[]).toPromise();
      const articleProductData = this.articleProductList.find(
        x => Number(x.productId) === Number(this.mwtProduct.productId)
      );
      await this.OnLoadLotList(this.mwtProduct.productId);
      await this.OnLoadWarehouseList(this.mwtProduct.productId);
      this.currentLotStock = 0;
      this.currentStock = 0;
      if (product) {
        this.mwtProduct.lotId = '0';
        if (product.lotId) {
          this.mwtProduct.lotId = product.lotId.toString();
        }
        if (this.mwtProduct.lotId !== undefined && this.mwtProduct.lotId !== null && this.mwtProduct.productId > 0) {
          // const list = await this.GetWarehouseStockMovementList(this.mwtProduct.productId, Number(this.mwtProduct.lotId), 0);
          // if (list.length > 0) {
          //   this.currentLotStock = list.reduce((a, b) => a + b.stockOut + b.stockIn, 0);
          // }
          const lot = this.lotlist.filter(x => x.lotId === Number(this.mwtProduct.lotId))
          this.currentLotStock = (lot.length > 0) ? lot[0].quantity : 0;
        }
        // this.expensesDetailForm.controls.productName.setValue(expensesDetail.productName);
        // this.addProductFormGroup.controls.lotId.setValue(product.lotName);
        // this.mwtProduct.lotId = product.lotName;
        // this.mwtProduct.quantity = product.units ? Number(product.units) : 1;
        // this.mwtProduct.quantity = 1;
        this.mwtProduct.quantity = articleProductData ? Number(articleProductData.quantity) : 1;
        this.mwtProduct.price = product.sellingPrice ? product.sellingPrice : 0;
        this.mwtProduct.stockOutDate = this.commonUiService.ConvertToPickerDateFormat(product.expiryDate);
        // this.mwtProduct.stockOutDate = this.commonUiService.ChangeNgbDateToCustomFormat(product.expiryDate);
        this.lotElement.nativeElement.click();
      }
      // this.OnLoadLotList(this.mwtProduct.productId);
      // show actual stock
      if ((Number(this.mwtProduct.warehouseId) > 0) && (Number(this.mwtProduct.productId) > 0)) {
        this.warehouseProduct = new WarehouseProduct();
        this.warehouseProduct = await this.GetWarehouseProduct(this.mwtProduct.warehouseId, this.mwtProduct.productId);
        if (this.warehouseProduct) {
          this.currentStock = this.warehouseProduct.actualStock ? this.warehouseProduct.actualStock : 0;
        }
      }
      this.commonUiService.isSpinnerVisible = false;
    }
    this.cdref.markForCheck();
  }

  async GetCurrentStock() {
    this.commonUiService.isSpinnerVisible = true;
    if ((Number(this.mwtProduct.warehouseId) > 0) && (Number(this.mwtProduct.productId) > 0)) {
      this.warehouseProduct = new WarehouseProduct();
      this.warehouseProduct = await this.GetWarehouseProduct(this.mwtProduct.warehouseId, this.mwtProduct.productId);
      if (this.warehouseProduct) {
        this.currentStock = this.warehouseProduct.actualStock ? this.warehouseProduct.actualStock : 0;
      }
    }
    if (this.mwtProduct.lotId !== undefined && this.mwtProduct.lotId !== null && this.mwtProduct.productId > 0) {
      const list = await this.GetWarehouseStockMovementList(this.mwtProduct.productId, Number(this.mwtProduct.lotId), 0);
      if (list.length > 0) {
        this.currentLotStock = list.reduce((a, b) => a + b.stockOut + b.stockIn, 0);
      }
    }
    this.commonUiService.isSpinnerVisible = false;
    this.cdref.markForCheck();
  }

  private async LoadLotDropDown(list: ProductLot[]) {
    if (list) {
      this.lotDropdownList = [];
      this.lotDropdownList.push(new DropDownOption('0', this._commonDataService.localizationLabelList['select'], false));
      list.forEach(element => {
        let option = new DropDownOption(element.lotId.toString(), element.lotNumber, false);
        this.lotDropdownList.push(option);
      });
    }

  }

  async OnSaveMwtProduct(event) {
    if (event) {
      if (this.selectedMainWorkTypeList.length === 1) {
        this.addProductFormGroup.controls['mainWorkTypeId'].clearValidators();
        this.addProductFormGroup.controls['mainWorkTypeId'].setErrors(null);
        this.addProductFormGroup.controls['mainWorkTypeId'].setValidators(null);
      } else {
        this.addProductFormGroup.controls['mainWorkTypeId'].setValidators(
          ValidateNgSelect
        );
      }

      if (this.isUrgentRquest) {
        this.addProductFormGroup.controls['workerId']
          .setValidators([Validators.required]);
      } else {
        this.addProductFormGroup.controls['workerId'].clearValidators();
        this.addProductFormGroup.controls['workerId'].setErrors(null);
        this.addProductFormGroup.controls['workerId'].setValidators(null);
      }
      this.addProductFormGroup.controls['mainWorkTypeId'].updateValueAndValidity();
      this.addProductFormGroup.controls['workerId'].updateValueAndValidity();

      if (this.addProductFormGroup.invalid) {
        ShowInvalidFormControls(this.addProductFormGroup);
        return;
      }
      try {
        let mwtProduct = { ...this.mwtProduct };
        this.commonUiService.isSpinnerVisible = true;
        if (this.selectedMainWorkTypeList.length === 1) {
          mwtProduct.mainWorkTypeId = this.selectedMainWorkTypeList[0].id;
        }

        mwtProduct.isExternal = this.isExternal === true ? this.isExternal : false;
        mwtProduct.externalCenterId = this.isExternal === true ? mwtProduct.externalCenterId : null;

        mwtProduct.isUrgentOrder = this.isUrgentRquest === true ? this.isUrgentRquest : false;
        mwtProduct.workerId = this.isUrgentRquest === true ? mwtProduct.workerId : null;
        mwtProduct.note = this.isUrgentRquest === true ? mwtProduct.note : null;
        mwtProduct.lotId =
          mwtProduct.lotId !== undefined &&
            mwtProduct.lotId !== null &&
            mwtProduct.lotId !== '0'
            ? mwtProduct.lotId.toString()
            : null;
        mwtProduct.mainWorkTypeId = Number(mwtProduct.mainWorkTypeId);
        const currentMwt = this.selectedMainWorkTypeList.find(
          mwt => mwt.id === mwtProduct.mainWorkTypeId
        );
        mwtProduct.mainWorkTypeNo = currentMwt
          ? currentMwt.generateNo
          : '';

        mwtProduct.mwtArticleId =
          mwtProduct.mwtArticleId !== undefined &&
            mwtProduct.mwtArticleId !== null &&
            mwtProduct.mwtArticleId !== 0
            ? Number(mwtProduct.mwtArticleId)
            : null;

        //mwtProduct.mwtArticleId =
        //  mwtProduct.mwtArticleId === 0 ||
        //  mwtProduct.mwtArticleId == null
        //    ? 0
        //    : mwtProduct.mwtArticleId;

        if (mwtProduct.stockOutDate !== undefined && mwtProduct.stockOutDate != null) {
          const date =
            mwtProduct.stockOutDate.year +
            '-' +
            mwtProduct.stockOutDate.month +
            '-' +
            mwtProduct.stockOutDate.day;
          mwtProduct.stockOutDate = this.commonUiService.ChangeNgbDateToCustomFormat(mwtProduct.stockOutDate)
          //new Date(date).toISOString();
        }
        mwtProduct.workId = this.selectedWork.id;
        if (mwtProduct.quantity == null) mwtProduct.quantity = 0.0;
        if (mwtProduct.price == null) mwtProduct.price = 0.0;
        mwtProduct.createdDate = moment().format('YYYY-MM-DD');
        // mwtProduct.createdBy = this.user.id;
        mwtProduct.isUrgentOrderCreated = false;

        //while adding new product check if product already exist 
        if (mwtProduct.id == undefined || mwtProduct.id == 0) {
          var lotId = mwtProduct.lotId ? mwtProduct.lotId : 0;
          var existProduct = this._productTemplateList
            .filter(x => x.productId == mwtProduct.productId
              && x.mainWorkTypeId == mwtProduct.mainWorkTypeId
              && (x.lotId.toString() == mwtProduct.lotId || (x.lotId === 0 && mwtProduct.lotId === null))
              && (x.isUrgentOrder == null || x.isUrgentOrder == mwtProduct.isUrgentOrder));

          if (existProduct && existProduct.length > 0) {
            if (existProduct.length == 1 && existProduct[0].warehouseId == null) {
              // if one product is found and it's warehouse id is null => then this product came from new worktype add
              mwtProduct.id = existProduct[0].id;  // this will also assign default warhouse id
              mwtProduct.quantity = mwtProduct.quantity + existProduct[0].quantity;
              mwtProduct.prevQuantity = existProduct[0].quantity;
              mwtProduct.isMomentUpdate = existProduct[0].isMomentUpdate;
            } else {
              // If product added manully and if the warehouse id same and also other details same       
              var existWithWarehouse = this._productTemplateList.filter(x => x.productId == mwtProduct.productId && x.mainWorkTypeId == mwtProduct.mainWorkTypeId
                && (x.lotId.toString() == mwtProduct.lotId || (x.lotId === 0 && mwtProduct.lotId === null))
                && x.warehouseId == mwtProduct.warehouseId
                && (x.isUrgentOrder == null || x.isUrgentOrder == mwtProduct.isUrgentOrder) && x.mwtArticleId == mwtProduct.mwtArticleId);
              if (existWithWarehouse && existWithWarehouse.length > 0) {
                mwtProduct.id = existWithWarehouse[0].id;
                mwtProduct.quantity = mwtProduct.quantity + existWithWarehouse[0].quantity;
                mwtProduct.prevQuantity = existProduct[0].quantity;
                mwtProduct.isMomentUpdate = existWithWarehouse[0].isMomentUpdate;
              }
            }
          }
        }
        else {
          //while editing - if already same record/row (identical) exist then quantity is update to exist row and current row is deleted in API
          var mwtProList = this._productTemplateList.filter(x => x.id == mwtProduct.id);
          mwtProduct.isModified = false;
          var lotIdTemp = mwtProduct.lotId ? parseInt(mwtProduct.lotId) : 0;
          if (mwtProList && mwtProList.length > 0) {
            if (mwtProList[0].mainWorkTypeId != mwtProduct.mainWorkTypeId || mwtProList[0].lotId != lotIdTemp
              || mwtProList[0].warehouseId != mwtProduct.warehouseId || mwtProList[0].isUrgentOrder != mwtProduct.isUrgentOrder
              || mwtProList[0].mwtArticleId != mwtProduct.mwtArticleId) {
              mwtProduct.isModified = true;
            }
          }
        }

        const response = await this.SaveMwtProduct(mwtProduct);
        if (response) {
          const body = JSON.parse(response['_body']);
          if (body['_isSuccsess']) {
            this.onSaveProduct.emit(true);
            //this.SyncProductData();
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['product'],
              msg: this._commonDataService.localizationLabelList[
                'save_success'
              ]
            });
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['product'],
              msg: this._commonDataService.localizationLabelList['save_error']
            });
          }
          this.commonUiService.isSpinnerVisible = false;
          this.OnClose();
        }
      } catch (error) {
        console.error(error);
        this.cdref.markForCheck();
        this.commonUiService.isSpinnerVisible = false;
      }
    }
  }

  private async SaveMwtProduct(mwtProduct: Mwtproduct) {
    return this._apiService
      .post(API.main_work_type_work.saveMwtProduct, mwtProduct, false, true)
      .toPromise();
  }

  async OnEditMwtProductModal(productTemplpate: WorkTypeProductTemplate) {
    if (productTemplpate) {
      this.commonUiService.isSpinnerVisible = true;
      this.commonUiService.isPopupOpened = true;
      this.mwtProduct = new Mwtproduct();
      this.mwtProduct.id = productTemplpate.id;
      this.mwtProduct.productId = productTemplpate.productId;
      this.mwtProduct.lotId = productTemplpate.lotId !== undefined && productTemplpate.lotId !== null ? productTemplpate.lotId.toString() : '0';
      this.mwtProduct.mainWorkTypeId = productTemplpate.mainWorkTypeId;
      this.mwtProduct.prevQuantity = productTemplpate.quantity;
      this.mwtProduct.workerId = productTemplpate.workerId;
      this.mwtProduct.isUrgentOrder = productTemplpate.isUrgentOrder;
      this.mwtProduct.note = productTemplpate.note;
      this.mwtProduct.isMomentUpdate = productTemplpate.isMomentUpdate;
      this.addProductFormGroup.controls['productName'].reset();
      this.addProductFormGroup.controls['productName'].setValue(productTemplpate.productName);
      await this.LoadProductPopupData(productTemplpate.productId);
      this.mwtProduct.price = productTemplpate.price !== undefined ? productTemplpate.price : null;
      this.mwtProduct.quantity = (productTemplpate.quantity !== undefined
        && productTemplpate.quantity !== null) ? Number(productTemplpate.quantity) : 0;
      this.mwtProduct.stockOutDate = productTemplpate.stockOutDate !== undefined ?
        this.commonUiService.ConvertToPickerDateFormat(productTemplpate.stockOutDate) : null;
      this.mwtProduct.mwtArticleId = productTemplpate.mwtArticleId ? productTemplpate.mwtArticleId : 0;
      // this.addProductFormGroup.controls.lotId.setValue(productTemplpate.activeLot);
      this.mwtProduct.isExternal = productTemplpate.isExternal;
      this.isExternal = this.mwtProduct.isExternal === true ? this.mwtProduct.isExternal : false;
      const externalCenter = this.externalCenterList.find(e => e.id == productTemplpate.externalCenterId);
      this.addProductFormGroup.controls.externalCenterId.setValue(externalCenter ? externalCenter.name : '');
      this.mwtProduct.externalCenterId = externalCenter ? externalCenter.id : null;

      this._apiService.get(API.product.getLotsByProductId + this.mwtProduct.productId).subscribe(res => {
        this.allProductLotList = res.filter((pLot, i, arr) => arr.findIndex(t => t.lotId === pLot.lotId) === i);
        this.lotlist = this.allProductLotList.filter(x => !x.isNotToUse);
      });
      if (this.mwtProduct.workerId > 0) {
        this.isUrgentRquest = this.mwtProduct.isUrgentOrder === true ? this.mwtProduct.isUrgentOrder : false;
        const worker = this.workerList.find(w => w.id === this.mwtProduct.workerId);
        if (worker) {
          this.addProductFormGroup.controls.workerId.setValue(worker.name);
        } else {
          this.addProductFormGroup.controls.workerId.setValue(null);
        }
      }

      this.lotElement.nativeElement.click();
      await this.OnLoadWarehouseList(this.mwtProduct.productId);
      this.LoadProductSelectList(this.mwtProduct.productId);
      this.mwtProduct.warehouseId = (productTemplpate.warehouseId !== undefined && productTemplpate.warehouseId !== null) ? Number(productTemplpate.warehouseId) : 0;
      if ((Number(this.mwtProduct.warehouseId) > 0) && (Number(this.mwtProduct.productId) > 0)) {
        this.warehouseProduct = new WarehouseProduct();
        this.currentStock = 0;
        this.warehouseProduct = await this.GetWarehouseProduct(this.mwtProduct.warehouseId, this.mwtProduct.productId);
        if (this.warehouseProduct) {
          this.currentStock = this.warehouseProduct.actualStock ? this.warehouseProduct.actualStock : 0;
        }
      }
      this.currentLotStock = 0;
      if (this.mwtProduct.lotId !== undefined && this.mwtProduct.lotId !== null && this.mwtProduct.productId > 0) {
        // const list = await this.GetWarehouseStockMovementList(this.mwtProduct.productId, Number(this.mwtProduct.lotId), 0);
        // if (list.length > 0) {
        //   this.currentLotStock = list.reduce((a, b) => a + b.stockOut + b.stockIn, 0);
        // }
        if (this.lotlist) {
          const lot = this.lotlist.filter(x => x.lotId === Number(this.mwtProduct.lotId))
          this.currentLotStock = (lot.length > 0) ? lot[0].quantity : 0;
        }
      }
      this.selectedProduct = new Product();
      this.selectedProduct = this.productList.find(x => Number(x.id) === Number(this.mwtProduct.productId));
      if (this.mwtProduct.isUrgentOrder) {
        this.addProductFormGroup.controls['workerId'].setValidators([Validators.required]);
        this.addProductFormGroup.controls['workerId'].updateValueAndValidity();
      } else {
        this.addProductFormGroup.controls['workerId'].setValidators(null);
        this.addProductFormGroup.controls['workerId'].updateValueAndValidity();
      }
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnChangeWarehouse(warehouseId) {
    // show actual stock
    this.currentStock = 0;
    if ((Number(warehouseId) > 0) && (this.mwtProduct.productId > 0)) {
      this.commonUiService.isSpinnerVisible = true;
      this.warehouseProduct = new WarehouseProduct();
      this.warehouseProduct = await this.GetWarehouseProduct(Number(warehouseId), this.mwtProduct.productId);
      if (this.warehouseProduct) {
        this.currentStock = this.warehouseProduct.actualStock ? this.warehouseProduct.actualStock : 0;
      }
      this.commonUiService.isSpinnerVisible = false;
    }
    this.cdref.markForCheck();
  }

  async GetWarehouseProduct(warehouseId: number, productId: number) {
    return this._apiService.get(API.warehouse.getWarehouseProduct + warehouseId + '/' + productId)
      .map(pp => pp as WarehouseProduct).toPromise();
  }

  async GetWarehouseStockMovementList(productId: number, lotId: number, warehouseId: number) {
    return this._apiService.get(API.product.getWarehouseStockMovementListByProductIdAndLotId + productId + '/' + lotId + '/' + warehouseId)
      .map(pp => pp as WarehouseStockMovement[]).toPromise();
  }

  //#endregion

  //#region add moventment

  private InitMoventmentFormGroups() {
    this.movementFormGroup = this._formBuilder.group({
      productId: [Validators.required],
      transactionDate: [],
      warehouseId: [],
      type: [],
      providerId: [],
      providerName: [],
      lotId: ['', [ValidateNgSelect]],
      expireDate: [null],
      quantity: [],
      labSectionId: [],
      unitPrice: [],
      actualStock: [],
      reasonForRegularizationId: [],
      observations: [],
      totalPrice: []
    });
    this.productLotFormGroup = this._formBuilder.group({
      lotNumber: ['', [
        Validators.required,
        Validators.maxLength(50)
      ]],
      expireDateView: [null, Validators.required],
      regDateView: [],
      isNotToUse: [],
    });
  }

  DefaultSelectData() {
    if (this.selectedProduct) {
      this.movementFormGroup.controls.unitPrice.setValue(this.selectedProduct.sellingPrice); // set default unit price
      const lot = this.productLotList.find(x => x.lotId === this.selectedProduct.lotId);
      const lotId = (lot) ? lot.lotId : this.mwtProduct.lotId;
      this.movementFormGroup.controls.lotId.setValue(lotId); // set default lot
    }

    if (this.productStock) {
      if (this.productStock.actualStock) {
        this.movementFormGroup.controls.actualStock.setValue(this.productStock.actualStock); // set current actual stock
      }
    } else {
      this.movementFormGroup.controls.actualStock.setValue(0); // set current actual stock
    }
    this.warehouseElementRef.nativeElement.click();
    this.cdref.markForCheck();
  }

  /**
* open movement modal when click new button
* set default values for form controls and load dropdown sources
* @param modal | modal name
*/
  async OnOpenMovementModal(modal: string) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      this.movementFormGroup.reset();
      // set default transaction date
      this.movementFormGroup.controls.transactionDate
        .setValue(this.commonUiService.ConvertToPickerDateFormat(new Date().toJSON()));

      if (this.mwtProduct.productId > 0) {
        this.movementFormGroup.controls.productId.setValue(this.mwtProduct.productId); // set selected product
        this.DefaultSelectData();
      } else {
        this._toastyService.warning({
          title: this._commonDataService.localizationLabelList['product'],
          msg: this._commonDataService.localizationLabelList['no_selected_product'],
        });
        return;
      }

      if (this.mwtProduct.warehouseId > 0) {
        this.movementFormGroup.controls.warehouseId.setValue(this.mwtProduct.warehouseId); // set selected warehouse by default
      }

      this.movementFormGroup.controls.type.setValue(1);
      this.movementFormGroup.controls.type.disable();
      this.movementFormGroup.controls.quantity.setValue(1);
      this.movementFormGroup.controls.labSectionId.setValue(0);
      this.movementFormGroup.controls.reasonForRegularizationId.setValue(0);

      this.SetMovementValidators(); // setting validators
      this.warehouseElementRef.nativeElement.click();
      this.cdref.markForCheck();
      this.commonUiService.OpenBasicModal(modal);
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnOpenAddLotsModal() {
    this.productLot = new ProductLot();
    this.productLotFormGroup.reset();
    this.commonUiService.OpenBasicModal('addlots-modal2');
  }

  private LoadLabSectionList() {
    this._apiService.get(API.lab_section.getLabSections)
      .subscribe((list: LabSection[]) => this.labSectionList = list.filter(ls => ls.status),
        err => console.error(err),
        () => { this.labSectionList = CommonFunctions.Sort(this.labSectionList, 'description'); });
  }

  LoadMovementTypeList() {
    this._translateService.onLangChange
      .subscribe(async (obj: { lang: string, translations: any }) => {
        if (obj && this.commonUiService.isActiveStatisticPanel) {
          this._commonDataService.SetIsStatistic(true);
        }
        this.movementTypeList = MovementTypes;
      });
  }

  LoadProductSelectList(id: number) {
    this.LoadProductProviderList(id);
    this.LoadProductStockList(id);
    this.LoadProductLotList(id);
  }

  LoadProductProviderList(id: number) {
    this._apiService
      .get(API.product.getProviderListByProductId + id)
      .subscribe(res => {
        this.productProviderList = [];
        if (res) {
          this.productProviderList = res;
        }
        if (this.productProviderList.length > 0) {
          this.movementFormGroup.controls.providerName.enable();
          this.selectedProvider = this.productProviderList[0];
        } else {
          this.movementFormGroup.controls.providerName.disable();
        }
        this.warehouseElementRef.nativeElement.click();
      });
    this.cdref.markForCheck();
  }

  LoadProductStockList(id: number) {
    this._apiService
      .get(API.product.getWarehouseListByProductId + id)
      .subscribe(res => {
        this.productStockList = [];
        if (res) {
          this.productStockList = res;
          this.productStock = this.productStockList[0];
        }
      });
    this.cdref.markForCheck();
  }

  /**
 * set form validators according to movement types
 */
  private SetMovementValidators() {
    this.movementFormGroup.controls.productId.setValidators(Validators.required);
    this.movementFormGroup.controls.transactionDate.clearValidators();
    this.movementFormGroup.controls.transactionDate.setErrors(null);
    this.movementFormGroup.controls.transactionDate.setValidators(null);

    this.movementFormGroup.controls.warehouseId.clearValidators();
    this.movementFormGroup.controls.warehouseId.setErrors(null);
    this.movementFormGroup.controls.warehouseId.setValidators(null);

    this.movementFormGroup.controls.type.clearValidators();
    this.movementFormGroup.controls.type.setErrors(null);
    this.movementFormGroup.controls.type.setValidators(null);

    this.movementFormGroup.controls.quantity.clearValidators();
    this.movementFormGroup.controls.quantity.setErrors(null);
    this.movementFormGroup.controls.quantity.setValidators(null);

    this.movementFormGroup.controls.unitPrice.clearValidators();
    this.movementFormGroup.controls.unitPrice.setErrors(null);
    this.movementFormGroup.controls.unitPrice.setValidators(null);

    this.movementFormGroup.controls.actualStock.clearValidators();
    this.movementFormGroup.controls.actualStock.setErrors(null);
    this.movementFormGroup.controls.actualStock.setValidators(null);

    this.movementFormGroup.controls.actualStock.clearValidators();
    this.movementFormGroup.controls.actualStock.setErrors(null);
    this.movementFormGroup.controls.actualStock.setValidators(null);

    this.movementFormGroup.controls.reasonForRegularizationId.clearValidators();
    this.movementFormGroup.controls.reasonForRegularizationId.setErrors(null);
    this.movementFormGroup.controls.reasonForRegularizationId.setValidators(null);

    if (this.movementFormGroup) {
      this.movementFormGroup.controls.transactionDate.setValidators(Validators.required);
      this.movementFormGroup.controls.warehouseId.setValidators(ValidateNgSelect);
      this.movementFormGroup.controls.type.setValidators(ValidateNgSelect);

      if (this.movementFormGroup.controls.type.value) {
        this.movementFormGroup.controls.quantity.setValidators(Validators.required);
        this.movementFormGroup.controls.unitPrice.setValidators(null);
      }
      this.movementFormGroup.controls.productId.updateValueAndValidity();
      this.movementFormGroup.controls.transactionDate.updateValueAndValidity();
      this.movementFormGroup.controls.warehouseId.updateValueAndValidity();
      this.movementFormGroup.controls.type.updateValueAndValidity();
      this.movementFormGroup.controls.quantity.updateValueAndValidity();
      this.movementFormGroup.controls.unitPrice.updateValueAndValidity();
      this.movementFormGroup.controls.actualStock.updateValueAndValidity();
      this.movementFormGroup.controls.reasonForRegularizationId.updateValueAndValidity();
    }
  }

  OnSelectProProvider(provider: ProductProvider) {
    if (provider) {
      this.movementFormGroup.controls.providerId.setValue(provider.providerId);
      this.movementFormGroup.controls.unitPrice.setValue(provider.price);
      this.cdref.markForCheck();
    }
  }

  ClearProProvider() {
    this.movementFormGroup.controls.providerId.reset();
    this.movementFormGroup.controls.providerName.reset();
    this.selectedProvider = new ProductProvider();
    this.cdref.markForCheck();
  }

  async OnSaveMovement(modal: string) {
    try {
      this.isDisabledMovements = true;
      this.commonUiService.isSpinnerVisible = true;
      if (this.movementFormGroup.invalid) {
        ShowInvalidFormControls(this.movementFormGroup);
        this.isDisabledMovements = false;

      } else {
        const stockMovement = new WarehouseStockMovement();
        const formValue = this.movementFormGroup.value;
        formValue.type = 1;
        stockMovement.productId = formValue.productId;
        stockMovement.transactionDate = this.commonUiService.ChangeNgbDateToCustomFormat(formValue.transactionDate);
        stockMovement.warehouseId = Number(formValue.warehouseId) ? Number(formValue.warehouseId) : null;
        stockMovement.outType = formValue.type;
        stockMovement.createdDate = new Date().toJSON();
        stockMovement.modifiedDate = new Date().toJSON();
        if (this.selectedMainWorkTypeList.length === 1) {
          stockMovement.typeofworkNo = this.selectedMainWorkTypeList[0].generateNo;
        } else if (Number(this.mwtProduct.mainWorkTypeId) > 0) {
          const currentMwt = this.selectedMainWorkTypeList.find(mwt => mwt.id === Number(this.mwtProduct.mainWorkTypeId));
          stockMovement.typeofworkNo = currentMwt ? currentMwt.generateNo : '';
        }

        const user = this._commonDataService.GetUserFromSession();
        if (!user) { return; }
        stockMovement.createdBy = user.id;
        stockMovement.modifiedBy = user.id;

        switch (Number(formValue.type)) {
          case 1:
            stockMovement.stockIn = Number(formValue.quantity);
            if (formValue.expireDate) {
              stockMovement.expireDate = this.commonUiService.ConvertDateFormatToString(formValue.expireDate);
            } else { stockMovement.expireDate = null; }
            stockMovement.labSectionId = Number(formValue.labSectionId) ? Number(formValue.labSectionId) : null;
            stockMovement.lotId = Number(formValue.lotId) ? Number(formValue.lotId) : null;
            stockMovement.providerId = Number(formValue.providerId) ? Number(formValue.providerId) : null;
            stockMovement.price = Number(formValue.unitPrice) ? Number(formValue.unitPrice) : null;
            break;
          case 2:
            stockMovement.stockOut = Number(formValue.quantity);
            if (formValue.expireDate) {
              stockMovement.expireDate = this.commonUiService.ConvertDateFormatToString(formValue.expireDate);
            } else { stockMovement.expireDate = null; }
            stockMovement.labSectionId = Number(formValue.labSectionId) ? Number(formValue.labSectionId) : null;
            stockMovement.lotId = Number(formValue.lotId) ? Number(formValue.lotId) : null;
            stockMovement.providerId = Number(formValue.providerId) ? Number(formValue.providerId) : null;
            break;
          case 3:
            if (Number(formValue.reasonForRegularizationId)) {
              stockMovement.regularizationReasonId = Number(formValue.reasonForRegularizationId);
            } else { stockMovement.regularizationReasonId = null; }
            stockMovement.description = formValue.observations;
            if (this.productStock) {
              if (this.productStock.actualStock != null && this.productStock.actualStock != undefined) {
                const difference = Number(this.productStock.actualStock) - Number(formValue.actualStock);
                stockMovement.actualStock = Number(formValue.actualStock);
                if (difference === 0) {
                  stockMovement.stockIn = 0;
                  stockMovement.stockOut = 0;
                } else {
                  if (difference > 0) {
                    stockMovement.stockOut = difference;
                  } else {
                    stockMovement.stockIn = difference * -1;
                  }
                }
              }
            }
            break;
          default:
            break;
        }

        const isSaved = await this._movementService.SaveStockMovement(stockMovement);
        if (isSaved) {
          this._toastyService.success({
            title: this._commonDataService.localizationLabelList['movement'],
            msg: this._commonDataService.localizationLabelList['save_success'],
          });
          this.CloseMovement();
          this.isDisabledMovements = false;
          await this.GetCurrentStock();
        } else {
          this._toastyService.error({
            title: this._commonDataService.localizationLabelList['movement'],
            msg: this._commonDataService.localizationLabelList['save_error'],
          });
          this.isDisabledMovements = false;
        }
      }
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
      this.isDisabledMovements = false;
    }
  }

  public async OnAddLot() {
    // check is exist
    const pLot = await this._movementService.GetProductLotByName(this.productLot.lotNumber);
    if (pLot !== null) {
      if (pLot['_data'] !== null || pLot['_data'] !== undefined) {
        this._toastyService.warning({
          title: this._commonDataService.localizationLabelList['lot'],
          msg: this._commonDataService.localizationLabelList['already_exists']
        });
        return;
      }
    }
    if (this.productLotFormGroup.invalid) { ShowInvalidFormControls(this.productLotFormGroup); } else {
      this.commonUiService.isSpinnerVisible = true;
      if (this.productLot.regDateView) {
        this.productLot.regDate = this.commonUiService.ChangeNgbDateToCustomFormat(this.productLot.regDateView).toString();
      } else {
        this.productLot.regDate = null;
      }
      if (this.productLot.expireDateView) {
        this.productLot.expireDate = this.commonUiService.ChangeNgbDateToCustomFormat(this.productLot.expireDateView).toString();
      } else {
        this.productLot.expireDate = null;
      }

      this.productLot.lotModificationDate = new Date();
      this.productLot.status = true;
      this.productLot.productId = this.selectedProduct.id;
      this.productLot.lotId = 0;
      this.productLot.isNotToUse = false;
      this.productLot.unitPerLot = 0;
      const isSaved = await this._movementService.SaveProductLot(this.productLot);
      if (isSaved) {
        this._toastyService.success({
          title: this._commonDataService.localizationLabelList['lot'],
          msg: this._commonDataService.localizationLabelList['save_success']
        });

        const productLot = await this._movementService.GetProductLotByName(this.productLot.lotNumber);
        if (productLot) {
          this.productLotList.push(productLot);
          this.movementFormGroup.get('lotId').setValue(productLot.lotId);
          this.LoadLotDropDown(this.productLotList);
          this.cdref.markForCheck();
        }
        this.commonUiService.CloseBasicModal('addlots-modal2');
      } else {
        this._toastyService.error({
          title: this._commonDataService.localizationLabelList['lot'],
          msg: this._commonDataService.localizationLabelList['save_error']
        });
      }
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async CloseMovement() {
    this.commonUiService.CloseBasicModal('movement-details-modal2');
  }

  async onBarcodeInput() {
    if (this.addProductFormGroup.controls.productName.value != "") {
      const product: Product = await this._workService.GetProductByBarcode(this.addProductFormGroup.controls.productName.value.toString());
      if (product && product.status) {
        this.addProductFormGroup.controls.productName.setValue(product.name);
        this.productInputVariable.nativeElement.click();
        var obj = ({ display: product.name, value: product.id, id: product.id });
        await this.OnChangeProduct(obj);
        this.OnSelectProduct();

      } else {
        this._toastyService.error({
          title: this._commonDataService.localizationLabelList['works'],
          msg: this._commonDataService.localizationLabelList['barcode_product_not_found']
        });
      }
    }
  }


  /**
* calculate total price whenever unit price or quantity changes
*/
  private CalculateTotal() {
    if (this.movementFormGroup.controls) {
      const controls = this.movementFormGroup.controls;
      if (controls.unitPrice && controls.totalPrice && controls.quantity) {

        this.movementFormGroup.controls.unitPrice.valueChanges
          .pipe(distinctUntilChanged()).subscribe((newValue: number) => {
            const quantity = this.movementFormGroup.controls.quantity.value as number;
            const total = (newValue * quantity).toFixed(2);
            this.movementFormGroup.controls.totalPrice.setValue(total);
          });
        this.movementFormGroup.controls.quantity.valueChanges
          .pipe(distinctUntilChanged()).subscribe((newValue: number) => {
            const unitPrice = this.movementFormGroup.controls.unitPrice.value as number;
            const total = (newValue * unitPrice).toFixed(2);
            this.movementFormGroup.controls.totalPrice.setValue(total);
          });
      }
    }
  }

  //#endregion

  //#region external-center

  async OnCheckedIsExternal(isExternal) {
    this.isExternal = !isExternal;
    if (this.isExternal) {
      const defaultExternalCenter = this.externalCenterList.find(e => e.isDefault);
      //setting default values
      this.addProductFormGroup.controls.externalCenterId.setValue(defaultExternalCenter ? defaultExternalCenter.name : '');
      this.mwtProduct.externalCenterId = defaultExternalCenter ? defaultExternalCenter.id : null;
    } else {
      this.addProductFormGroup.controls.externalCenterId.setValue('');
      this.mwtProduct.externalCenterId = null;
    }
    this.cdref.markForCheck();
  }

  async OnSelectCenter(center: ExternalCenter) {
    if (center) {
      this.mwtProduct.externalCenterId = center.id;
      this.addProductFormGroup.controls.externalCenterId.setValue(center.name);
    } else {
      this.mwtProduct.externalCenterId = null;
      this.addProductFormGroup.controls.externalCenterId.setValue('');
    }
  }


}
