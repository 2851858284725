import { CalculationService } from './calculation.service';
import { ArticleCalculationService } from './article-calculation.service';
import { DeliveryNoteCalculationService } from './delivery-note-calculation.service';
import { InvoiceCalculationService } from './invoice-calculation.service';
import { isNumber } from 'util';

export const CalculationServices = [
    CalculationService,
    ArticleCalculationService,
    DeliveryNoteCalculationService,
    InvoiceCalculationService
];

export function toFixedNumber(x: number) {
    return x ? isNumber(x) ? Number(x.toFixed(2)) : Number(x) : 0;
}

