import { Injectable } from '@angular/core';
import { toFixedNumber } from './calculation';
import { CalculationService } from './calculation.service';

@Injectable({
  providedIn: 'root'
})
export class ArticleCalculationService extends CalculationService {

  constructor() { super(); }

  /**
   * quantity with waste = (quantity * (waste + 100)) / 100
   * @param quantity 
   * @param waste | as a percentage
   */
  getQuantityWithWaste(quantity: number, waste: number) {
    return toFixedNumber((quantity * (waste + 100) / 100));
  }

  /**
   * price with discount (discount as a percentage) = (price * (100 - discount)) / 100
   * price with discount (discount as an amount) = price - discount
   * @param price 
   * @param discount | as a percentage or amount
   * @param isAmount | boolean
   */
  getPriceWithDiscount(price: number, discount: number, isAmount = false) {
    if (isAmount) { return toFixedNumber(price - discount); }
    return toFixedNumber((price * (100 - discount) / 100));
  }

  /**
   * price (discount as a percentage) = (price with discount / (100 - discount)) * 100
   * price (discount as an amount) = price with discount + discount
   * @param price 
   * @param discount | as a percentage or amount
   * @param isAmount | boolean
   */
  getPriceFromPriceWithDiscount(priceWithDiscount: number, discount: number, isAmount = false) {
    if (isAmount) { return toFixedNumber(priceWithDiscount + discount); }
    return toFixedNumber((priceWithDiscount / (100 - discount) * 100));
  }

  /**
   * final total = quantity with waste * price with discount
   * @param quantityWithWaste
   * @param priceWithDiscount
   * @param ivaAmount as an amount
   */
  getArticleFinalTotal(quantityWithWaste: number, priceWithDiscount: number, ivaAmount = 0) {
    return toFixedNumber((quantityWithWaste * priceWithDiscount) + ivaAmount);
  }

  /**
   * line sub total (final total of an article without discount) 
   * = article price without discount * quantity with waste
   * @param price article price without discount
   * @param quantityWithWaste quantity with waste
   */
  getLineSubTotal(price: number, quantityWithWaste: number) {
    return toFixedNumber(price * quantityWithWaste);
  }

  /**
   * line discount 
   * = article discount * quantity with waste
   * @param discountAmount discount amount
   * @param quantityWithWaste quantity with waste
   */
   getLineDiscount(discountAmount: number, quantityWithWaste: number) {
    return toFixedNumber(discountAmount * quantityWithWaste);
  }
}
