import { Component, OnDestroy, OnInit, ChangeDetectorRef, Compiler } from '@angular/core';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonUIService } from '../../modules/common/services/common.ui.service';
import { CommonDataService } from '../../modules/common/services/common.data.service';
import { NgxPermissionsService, NgxPermissionsObject } from 'ngx-permissions';
import { User } from '../../modules/auth/entities/user';
import { ApiService } from '../../modules/common/services/api.service';
import API from '../../modules/common/api.config.json';
import SEARCHPATH from '../../modules/common/search.path.json';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DentboxLabService } from 'src/app/modules/common/dentbox/services/dentbox-lab.service';
import { DentboxValidationService } from 'src/app/modules/common/dentbox/services/dentbox-validation.service';
import { DentboxAuthService } from 'src/app/modules/common/dentbox/services/dentbox-auth.service';
import { Permission } from '../../modules/user/entities/permission';
import swal from 'sweetalert2';
import { AttendanceRecordService } from '../../modules/workers/services/attendance-record.service';
import * as moment from 'moment';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { SubWork } from '../../modules/work/entities/sub-work';
import { Work } from '../../modules/work/entities/work';
import { WorkService } from '../../modules/work/services/work.service';
import { UserService } from '../../modules/user/services/user.service';

declare var $: any;
declare var notEnableTogglePan: boolean;
declare var require: any;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [
    trigger('notificationBottom', [
      state('an-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('an-animate',
        style({
          overflow: 'visible',
          height: AUTO_STYLE,
        })
      ),
      transition('an-off <=> an-animate', [
        animate('400ms ease-in-out')
      ])
    ]),
    trigger('slideInOut', [
      state('in', style({
        width: '280px',
        // transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        width: '0',
        // transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
    trigger('mobileHeaderNavRight', [
      state('nav-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('nav-on',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('nav-off <=> nav-on', [
        animate('400ms ease-in-out')
      ])
    ]),
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('400ms ease-in-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('mobileMenuTop', [
      state('no-block, void',
        style({
          overflow: 'visible',
          height: '0px',
        })
      ),
      state('yes-block',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('no-block <=> yes-block', [
        animate('400ms ease-in-out')
      ])
    ])
  ]
})
export class AdminComponent implements OnInit, OnDestroy {
  animateSidebar: string;
  navType: string;
  themeLayout: string;
  verticalPlacement: string;
  verticalLayout: string;
  pcodedDeviceType: string;
  verticalNavType: string;
  verticalEffect: string;
  vnavigationView: string;
  freamType: string;
  sidebarImg: string;
  sidebarImgType: string;
  layoutType: string;
  headerTheme: string;
  pcodedHeaderPosition: string;
  liveNotification: string;
  liveNotificationClass: string;
  profileNotification: string;
  profileNotificationClass: string;
  menuNotification: string;
  menuNotificationClass: string;
  chatSlideInOut: string;
  innerChatSlideInOut: string;
  searchWidth: number;
  searchWidthString: string;
  navRight: string;
  windowWidth: number;
  chatTopPosition: string;
  toggleOn: boolean;
  toggleIcon: string;
  navBarTheme: string;
  activeItemTheme: string;
  pcodedSidebarPosition: string;
  headerFixedTop: string;
  menuTitleTheme: string;
  dropDownIcon: string;
  subItemIcon: string;
  configOpenRightBar: string;
  displayBoxLayout: string;
  isVerticalLayoutChecked: boolean;
  isSidebarChecked: boolean;
  isHeaderChecked: boolean;
  headerFixedMargin: string;
  sidebarFixedHeight: string;
  sidebarFixedNavHeight: string;
  itemBorderStyle: string;
  subItemBorder: boolean;
  itemBorder: boolean;
  isCollapsedSideBar: string;
  psDisabled: string;
  perfectDisable: string;
  config: any;
  searchInterval: any;
  user: User;
  url = this._apiService.BASE_URL + API.Global.GetGlobalSearchData;
  search = '';
  api = 'http';
  isNested = false;
  isParent = false;
  href = '';
  canEditClient = false;
  canEditWork = false;

  isAdminSectionVisible = false;
  isExternalShipmentVisible = false;
  isCourierVisible = false;
  isWareHouseVisible = false;
  isLaboratoryInformationVisible = false;
  dentboxEnabled = false;

  appVersion = '';

  scroll = () => {
    const scrollPosition = window.pageYOffset;
    if (scrollPosition > 50) {
      if (this.isSidebarChecked === true) {
        this.pcodedSidebarPosition = 'fixed';
      }
      if (this.pcodedDeviceType === 'desktop') {
        this.headerFixedTop = '0';
      }
      this.sidebarFixedNavHeight = '100%';
    } else {
      if (this.pcodedDeviceType === 'desktop') {
        this.headerFixedTop = 'auto';
      }
      this.pcodedSidebarPosition = 'absolute';
      this.sidebarFixedNavHeight = '';
    }
  }

  constructor(
    private _router: Router,
    private translate: TranslateService,
    private _apiService: ApiService,
    public deviceService: DeviceDetectorService,
    private _permissionService: NgxPermissionsService,
    private _cdRef: ChangeDetectorRef,
    public _commonUIService: CommonUIService,
    private _compiler: Compiler,
    private _commonDataService: CommonDataService,
    private _dentboxLabService: DentboxLabService, private _toastyService: Toast,
    private _attendanceRecordService: AttendanceRecordService,
    private _workService: WorkService,
    private _dentboxLicenseService: DentboxValidationService,
    private _userService: UserService) {
    this.animateSidebar = '';
    this.navType = 'st2';
    this.themeLayout = 'vertical';
    this.verticalPlacement = 'left';
    this.verticalLayout = 'wide';
    this.pcodedDeviceType = 'desktop';
    this.verticalNavType = 'expanded';
    this.verticalEffect = 'shrink';
    this.vnavigationView = 'view1';
    this.freamType = 'theme1';
    this.sidebarImg = 'false';
    this.sidebarImgType = 'img1';
    this.layoutType = 'light'; // light(default) dark(dark)
    this.headerTheme = 'theme1'; // theme1(default)
    this.pcodedHeaderPosition = 'fixed';
    this.headerFixedTop = 'auto';
    this.liveNotification = 'an-off';
    this.profileNotification = 'an-off';
    this.menuNotification = 'an-off';
    this.chatSlideInOut = 'out';
    this.innerChatSlideInOut = 'out';
    this.searchWidth = 0;
    this.navRight = 'nav-on';
    this.toggleOn = true;
    this.toggleIcon = 'icon-toggle-right';
    this.navBarTheme = 'themelight1'; // themelight1(default) theme1(dark)
    this.activeItemTheme = 'theme1';
    this.pcodedSidebarPosition = 'fixed';
    this.menuTitleTheme = 'theme1'; // theme1(default) theme10(dark)
    this.dropDownIcon = 'style1';
    this.subItemIcon = 'style1';
    this.displayBoxLayout = 'd-none';
    this.isVerticalLayoutChecked = false;
    this.isSidebarChecked = true;
    this.isHeaderChecked = true;
    this.headerFixedMargin = '50px'; // 50px
    this.sidebarFixedHeight = 'calc(100vh - 55px)'; // calc(100vh - 190px)
    this.itemBorderStyle = 'none';
    this.subItemBorder = true;
    this.itemBorder = true;
    this.isCollapsedSideBar = 'no-block';
    this.perfectDisable = '';
    this.windowWidth = window.innerWidth;
    this.setMenuAttributes(this.windowWidth);
    this.setHeaderAttributes(this.windowWidth);
  }

  ngOnInit() {
    if (require('../../../../package.json') && require('../../../../package.json') != undefined) {
      this.appVersion = require('../../../../package.json').version;
    }
    this._router.events.subscribe(async val => {
      if (val['url'] && val['url'] !== null && val['url'] !== undefined) {
        this._commonUIService.IsExanded = false;
        this._commonUIService.IsLeftSideVisible = true;
        this._commonUIService.isSidebarDisabled = false;
        this._commonUIService.isActiveLeftPanelOnly = false;
        this._commonUIService.isActiveRightPanelOnly = false;
        this._commonUIService.isActiveBothPanel = true;
        this._commonUIService.isActiveStatisticPanel = false;
      }
    });
    this.setBackgroundPattern('theme1');
    this.user = this._commonDataService.GetUserFromSession();
    $(document).ready(() => {
      $('#sidebarCollapse').on('click', function () {
        if (notEnableTogglePan === false) {
          $('.sidebar').toggleClass('active');
          $('.slider-menu .topicon label em').toggleClass('icon-menu-mini');
          $('.slider-menu .topicon label em').toggleClass('icon-menu-expand');
          $('.content-wrapper').toggleClass('expand');
          $('.work-title').toggleClass('expand');
        }
      });
    });

    $(window).resize(function () {
      const width = $(window).width();
      if (width < 1366) {
        $('.sidebar').addClass('active');
        $('.slider-menu .topicon label em').toggleClass('icon-menu-expand');
        $('.slider-menu .topicon label em').toggleClass('icon-menu-mini');
        $('.content-wrapper').addClass('expand');
        $('.work-title').addClass('expand');
      } else {
        $('.sidebar').removeClass('active');
        $('.slider-menu .topicon label em').toggleClass('icon-menu-mini');
        $('.slider-menu .topicon label em').toggleClass('icon-menu-expand');
        $('.content-wrapper').removeClass('expand');
        $('.work-title').removeClass('expand');
      }
    });
    this._permissionService.permissions$
      .subscribe(permissions => this.setPagePermissions(permissions));
    this.dentboxEnabled = this._dentboxLicenseService.checkDentboxServices();
    this.CheckExternalShipmentEnable();
    const permission = this._permissionService.getPermission('ViewClients');
    const permissionWork = this._permissionService.getPermission('ViewWork');
    this.canEditClient = permission !== undefined;
    this.canEditWork = permissionWork !== undefined;
  }

  CheckExternalShipmentEnable() {
    this._apiService.get(API.external_works_configuration.loadList)
      .subscribe(res => {
        if (res && res.length > 0) {
          // this.isExternalShipmentVisible = res[0].status;
        }
      });
  }



  private setPagePermissions(permissions: NgxPermissionsObject) {
    if (permissions['ViewBudgets'] || permissions['ViewDeliveryNotes'] || permissions['ViewInvoices']
      || permissions['ViewReceipts'] || permissions['ViewBanks'] || permissions['ViewExpenses']) {
      this.isAdminSectionVisible = true;
    }
    if (permissions['ViewExternalCenters'] || permissions['ViewRegisteredExternalShipments']) {
      this.isExternalShipmentVisible = true;
    }

    if (permissions['ViewCouriers'] || permissions['ViewShippingAndCollections']) {
      this.isCourierVisible = true;
    }
    if (permissions['ViewGlobalViewWorks'] || permissions['ViewGlobalViewTasks']) {
      this.isLaboratoryInformationVisible = true;
    }
  }

  onResize(event) {
    this.windowWidth = event.target.innerWidth;
    this.setHeaderAttributes(this.windowWidth);
    let reSizeFlag = true;
    if (this.pcodedDeviceType === 'tablet' && this.windowWidth >= 768 && this.windowWidth <= 992) {
      reSizeFlag = false;
    } else if (this.pcodedDeviceType === 'phone' && this.windowWidth < 768) {
      reSizeFlag = false;
    }
    /* for check device */
    if (reSizeFlag) {
      this.setMenuAttributes(this.windowWidth);
    }
  }

  setHeaderAttributes(windowWidth) {
    if (windowWidth <= 1024) {
      this.navRight = 'nav-off';
    } else { this.navRight = 'nav-on'; }
  }

  setMenuAttributes(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.pcodedDeviceType = 'tablet';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
      this.toggleIcon = 'icon-toggle-left';
      this.headerFixedTop = '50px';
    } else if (windowWidth < 768) {
      this.pcodedDeviceType = 'phone';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
      this.toggleIcon = 'icon-toggle-left';
      this.headerFixedTop = '50px';
    } else {
      this.pcodedDeviceType = 'desktop';
      this.verticalNavType = 'expanded';
      this.verticalEffect = 'shrink';
      this.toggleIcon = 'icon-toggle-right';
    }
  }

  toggleHeaderNavRight() {
    this.navRight = this.navRight === 'nav-on' ? 'nav-off' : 'nav-on';
    this.chatTopPosition = this.chatTopPosition === 'nav-on' ? '112px' : '';
    if (this.navRight === 'nav-off' && this.innerChatSlideInOut === 'in') {
      this.toggleInnerChat();
    }
    if (this.navRight === 'nav-off' && this.chatSlideInOut === 'in') {
      this.toggleChat();
    }
  }

  toggleLiveNotification() {
    if (this.profileNotification === 'an-animate') {
      this.toggleProfileNotification();
    }
    if (this.menuNotification === 'an-animate') {
      this.toggleMenuNotification();
    }
    this.liveNotification = this.liveNotification === 'an-off' ? 'an-animate' : 'an-off';
    this.liveNotificationClass = this.liveNotification === 'an-animate' ? 'show' : '';

    if (this.liveNotification === 'an-animate' && this.innerChatSlideInOut === 'in') {
      this.toggleInnerChat();
    }
    if (this.liveNotification === 'an-animate' && this.chatSlideInOut === 'in') {
      this.toggleChat();
    }
  }

  toggleMenuNotification() {
    if (this.liveNotification === 'an-animate') {
      this.toggleLiveNotification();
    }
    if (this.profileNotification === 'an-animate') {
      this.toggleProfileNotification();
    }
    this.menuNotification = this.menuNotification === 'an-off' ? 'an-animate'
      : (this.isNested && this.isParent ? 'an-animate' : 'an-off');
    this.menuNotificationClass = this.menuNotification === 'an-animate' ? 'show' : '';

    if (this.menuNotification === 'an-animate' && this.innerChatSlideInOut === 'in') {
      this.toggleInnerChat();
    }
    if (this.menuNotification === 'an-animate' && this.chatSlideInOut === 'in') {
      this.toggleChat();
    }
  }

  OnClickSubMenu(e: any, isNested: boolean, isParent = false) {
    this.isNested = isNested;
    this.isParent = isParent;
    this.toggleMenuNotification();
    e.stopPropagation();
    $('#languageUl').removeClass('not-visible');
  }

  OnClickProfileSubMenu(e: any, isNested: boolean, isParent = false) {
    this.isNested = isNested;
    this.isParent = isParent;
    this.toggleProfileNotification();
    e.stopPropagation();

    if (!isNested && !isParent) {
      $('#languageUl').removeClass('show');
      $('#languageUl').addClass('not-visible');
    } else {
      $('#languageUl').removeClass('not-visible');
    }
  }

  toggleProfileNotification() {
    if (this.liveNotification === 'an-animate') {
      this.toggleLiveNotification();
    }
    if (this.menuNotification === 'an-animate') {
      this.toggleMenuNotification();
    }
    this.profileNotification = this.profileNotification === 'an-off' ? 'an-animate'
      : (this.isNested && this.isParent ? 'an-animate' : 'an-off');
    this.profileNotificationClass = this.profileNotification === 'an-animate' ? 'show' : '';
    if (this.profileNotification === 'an-animate' && this.innerChatSlideInOut === 'in') {
      this.toggleInnerChat();
    }
    if (this.profileNotification === 'an-animate' && this.chatSlideInOut === 'in') {
      this.toggleChat();
    }
    $('#languageUl').removeClass('not-visible');
  }

  notificationOutsideClick(ele: string) {
    if (ele === 'live' && this.liveNotification === 'an-animate') {
      this.toggleLiveNotification();
    } else if (ele === 'profile' && this.profileNotification === 'an-animate') {
      this.toggleProfileNotification();
    } else if (ele === 'menu' && this.menuNotification === 'an-animate') {
      this.toggleMenuNotification();
    }
  }

  toggleChat() {
    if (this.innerChatSlideInOut === 'in') {
      this.innerChatSlideInOut = 'out';
    } else {
      this.chatSlideInOut = this.chatSlideInOut === 'out' ? 'in' : 'out';
    }
  }

  toggleInnerChat() {
    this.innerChatSlideInOut = this.innerChatSlideInOut === 'out' ? 'in' : 'out';
  }

  searchOn() {
    document.querySelector('#main-search').classList.add('open');
    this.searchInterval = setInterval(() => {
      if (this.searchWidth >= 200) {
        clearInterval(this.searchInterval);
        return false;
      }
      this.searchWidth = this.searchWidth + 15;
      this.searchWidthString = this.searchWidth + 'px';
    }, 35);
  }

  searchOff() {
    this.searchInterval = setInterval(() => {
      if (this.searchWidth <= 0) {
        document.querySelector('#main-search').classList.remove('open');
        clearInterval(this.searchInterval);
        return false;
      }
      this.searchWidth = this.searchWidth - 15;
      this.searchWidthString = this.searchWidth + 'px';
    }, 35);
  }

  ngOnDestroy() {
    if (this.searchInterval) {
      clearInterval(this.searchInterval);
    }
  }

  toggleOpened(e) {
    if (this.windowWidth <= 992) {
      this.toggleOn = this.verticalNavType === 'offcanvas' ? true : this.toggleOn;
      if (this.navRight === 'nav-on') {
        this.toggleHeaderNavRight();
      }
      this.verticalNavType = this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';
    } else {
      this.verticalNavType = this.verticalNavType === 'expanded' ? 'collapsed' : 'expanded';
    }
    this.toggleIcon = this.verticalNavType === 'expanded' ? 'icon-toggle-right' : 'icon-toggle-left';
    this.animateSidebar = 'pcoded-toggle-animate';
    if (this.verticalNavType === 'collapsed') {
      this.perfectDisable = 'disabled';
      this.sidebarFixedHeight = '100%';
    } else {
      this.perfectDisable = '';
    }
    if (this.verticalNavType === 'collapsed' && this.isHeaderChecked === false) {
      this.setSidebarPosition();
    }
    setTimeout(() => {
      this.animateSidebar = '';
    }, 500);
  }

  onClickedOutsideSidebar(e: Event) {
    if ((this.windowWidth <= 992 && this.toggleOn && this.verticalNavType !== 'offcanvas') || this.verticalEffect === 'overlay') {
      this.toggleOn = true;
      this.verticalNavType = 'offcanvas';
      this.toggleIcon = 'icon-toggle-left';
    }
  }

  toggleRightbar() {
    this.configOpenRightBar = this.configOpenRightBar === 'open' ? '' : 'open';
  }

  setNavBarTheme(theme: string) {
    if (theme === 'themelight1') {
      this.navBarTheme = 'themelight1';
      this.menuTitleTheme = 'theme1';
      this.sidebarImg = 'false';
    } else {
      this.menuTitleTheme = 'theme9';
      this.navBarTheme = 'theme1';
      this.sidebarImg = 'false';
    }
  }

  setLayoutType(type: string) {
    if (type === 'dark') {
      this.headerTheme = 'theme1';
      this.navBarTheme = 'theme1';
      this.activeItemTheme = 'theme1';
      this.freamType = 'theme1';
      document.querySelector('body').classList.add('dark');
      this.setBackgroundPattern('theme1');
      this.menuTitleTheme = 'theme9';
      this.layoutType = type;
      this.sidebarImg = 'false';
    } else if (type === 'light') {
      this.headerTheme = 'theme1';
      this.navBarTheme = 'themelight1';
      this.menuTitleTheme = 'theme1';
      this.activeItemTheme = 'theme1';
      this.freamType = 'theme1';
      document.querySelector('body').classList.remove('dark');
      this.setBackgroundPattern('theme1');
      this.layoutType = type;
      this.sidebarImg = 'false';
    } else if (type === 'img') {
      this.sidebarImg = 'true';
      this.navBarTheme = 'themelight1';
      this.menuTitleTheme = 'theme1';
      this.freamType = 'theme1';
      document.querySelector('body').classList.remove('dark');
      this.setBackgroundPattern('theme1');
      this.activeItemTheme = 'theme1';
    }
  }

  setVerticalLayout() {
    this.isVerticalLayoutChecked = !this.isVerticalLayoutChecked;
    if (this.isVerticalLayoutChecked) {
      this.verticalLayout = 'box';
      this.displayBoxLayout = '';
      this.pcodedHeaderPosition = 'relative';
      this.pcodedSidebarPosition = 'absolute';
      this.headerFixedMargin = '';
    } else {
      this.verticalLayout = 'wide';
      this.displayBoxLayout = 'd-none';
      this.pcodedHeaderPosition = 'fixed';
      this.pcodedSidebarPosition = 'fixed';
      this.headerFixedMargin = '50px';
    }
  }

  setBackgroundPattern(pattern: string) {
    document.querySelector('body').setAttribute('themebg-pattern', pattern);
  }

  setSidebarPosition() {
    if (this.verticalNavType !== 'collapsed') {
      this.isSidebarChecked = !this.isSidebarChecked;
      this.pcodedSidebarPosition = this.isSidebarChecked === true ? 'fixed' : 'absolute';
      this.sidebarFixedHeight = this.isSidebarChecked === true ? 'calc(100vh - 50px)' : '100%';
      if (this.isHeaderChecked === false) {
        window.addEventListener('scroll', this.scroll, true);
        window.scrollTo(0, 0);
      }
    }
  }

  setHeaderPosition() {
    this.isHeaderChecked = !this.isHeaderChecked;
    this.pcodedHeaderPosition = this.isHeaderChecked === true ? 'fixed' : 'relative';
    this.headerFixedMargin = this.isHeaderChecked === true ? '50px' : '';
    if (this.isHeaderChecked === false) {
      window.addEventListener('scroll', this.scroll, true);
      window.scrollTo(0, 0);
    } else {
      window.removeEventListener('scroll', this.scroll, true);
      if (this.pcodedDeviceType === 'desktop') {
        this.headerFixedTop = 'auto';
      }
      this.pcodedSidebarPosition = 'fixed';
      if (this.verticalNavType !== 'collapsed') {
        this.sidebarFixedHeight = this.isSidebarChecked === true ? 'calc(100vh - 50px)' : 'calc(100vh + 50px)';
      }
    }
  }

  toggleOpenedSidebar() {
    this.isCollapsedSideBar = this.isCollapsedSideBar === 'yes-block' ? 'no-block' : 'yes-block';
    if (this.verticalNavType !== 'collapsed') {
      this.sidebarFixedHeight = 'calc(100vh - 50px)';
    }
  }

  hoverOutsideSidebar() {
    if (this.verticalNavType === 'collapsed') {
      const mainEle = document.querySelectorAll('.pcoded-trigger');
      for (let i = 0; i < mainEle.length; i++) {
        mainEle[i].classList.remove('pcoded-trigger');
      }
    }
  }

  fireClick(e) {
    if (this.verticalNavType === 'collapsed') {
      const parentEle = e.target.parentNode.parentNode;
      if (parentEle.classList.contains('pcoded-trigger')) {
        const subEle = parentEle.querySelectorAll('.pcoded-hasmenu');
        for (let i = 0; i < subEle.length; i++) {
          if (subEle[i].classList.contains('pcoded-trigger')) {
            subEle[i].classList.remove('pcoded-trigger');
          }
        }
      } else { e.target.click(); }
    }
  }

  fireClickLeave(e) {
    if (this.verticalNavType === 'collapsed') {
      const parentEle = <HTMLElement>e.target.parentNode.parentNode;
      const subEle = parentEle.querySelectorAll('.pcoded-hasmenu');
      for (let i = 0; i < subEle.length; i++) {
        if (subEle[i].classList.contains('pcoded-trigger')) {
          subEle[i].classList.remove('pcoded-trigger');
        }
      }
    }
  }

  Logout() { return this._apiService.get(API.user.logout, true).toPromise(); }

  async OnLogOut() {
    try {
      let user: any;
      const encryptedUser = sessionStorage['auth-user'];
      if (encryptedUser) {
        user = JSON.parse(atob(encryptedUser));
      }

      if (user && user.workerId > 0) {
        swal({
          title: this._commonDataService.localizationLabelList['do_you_want_to_end_working_today'],
          text: this._commonDataService.localizationLabelList['accept'],
          type: 'info',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: '#3085d6',
          cancelButtonText: this._commonDataService.localizationLabelList['common_cancel'],
          confirmButtonText: this._commonDataService.localizationLabelList['common_accept']
        }).then(async result => {
          if (result.value) {
            this._attendanceRecordService
              .UpdateWorkerLogOut(user.workerId, moment().format('YYYY-MM-DD'), moment().format('HH:mm'))
              .then(s => console.log(s));
            swal.close();
          }
        });
      }

      await this.Logout();
      sessionStorage.clear();
      localStorage.clear();
      this._compiler.clearCache();
      this._router.navigate(['/']);

    } catch (error) { console.log(error); }
  }

  SetLanguage(prefix: string) {
    localStorage.setItem('currentLang', prefix); // save language in local storage
    this.translate.use(prefix);
    this.translate.setDefaultLang(prefix);
    this._commonDataService.SetTranslatedLabels(prefix);
    this._commonDataService.SetCurrentLanguage(prefix);
    this._userService.updateUserLanguage(prefix);
  }

  ShowLefPanelOnly() {
    this._commonUIService.isActiveLeftPanelOnly = true;
    this._commonUIService.isActiveRightPanelOnly = false;
    this._commonUIService.isActiveBothPanel = false;
    this._commonUIService.isActiveStatisticPanel = false;
    this._commonUIService.expandView.emit(true);
  }

  ShowRightPanelOnly() {
    this._commonUIService.isActiveRightPanelOnly = true;
    this._commonUIService.isActiveLeftPanelOnly = false;
    this._commonUIService.isActiveBothPanel = false;
    this._commonUIService.isActiveStatisticPanel = false;
  }

  ShowBothPanels() {
    this.href = this._router.url;
    if (this.href === '/user/permission-list' || this.href === '/auxiliary/facturacion'
      || this.href === '/user/administracion' || this.href === '/user/almacen'
      || this.href === '/user/series' || this.href === '/user/lopd') {
      this._commonUIService.isActiveBothPanel = false;
    } else {
      this._commonUIService.isActiveBothPanel = true;
    }

    this._commonUIService.isActiveRightPanelOnly = false;
    this._commonUIService.isActiveLeftPanelOnly = false;
    this._commonUIService.isActiveStatisticPanel = false;
    this._commonUIService.expandView.emit(false);
  }

  ShowStatisticPanelOnly() {
    this._commonUIService.isActiveLeftPanelOnly = false;
    this._commonUIService.isActiveRightPanelOnly = false;
    this._commonUIService.isActiveBothPanel = false;
    this._commonUIService.isActiveStatisticPanel = true;

    this._commonDataService.SetIsStatistic(true);
  }

  //#region Intelligent Search

  handleResultSelected(result) {
    if (result) {
      if (result.value) { this.search = result.value; }
      if (result.entity && result.value) {
        if (result.entity === SEARCHPATH.Article.module) {
          const url = this._router.serializeUrl(this._router.createUrlTree([SEARCHPATH.Article.path], { queryParams: { referenceId: result.referenceId } }));
          window.open(url, '_blank');
        } else if (result.entity === SEARCHPATH.Product.module) {
          const url = this._router.serializeUrl(this._router.createUrlTree([SEARCHPATH.Product.path], { queryParams: { referenceId: result.referenceId } }));
          window.open(url, '_blank');
        } else if (result.entity === SEARCHPATH.Pricelist.module) {
          const url = this._router.serializeUrl(this._router.createUrlTree([SEARCHPATH.Pricelist.path], { queryParams: { referenceId: result.referenceId } }));
          window.open(url, '_blank');
        } else if (result.entity === SEARCHPATH.Worker.module) {
          const url = this._router.serializeUrl(this._router.createUrlTree([SEARCHPATH.Worker.path], { queryParams: { referenceId: result.referenceId } }));
          window.open(url, '_blank');
        } else if (result.entity === SEARCHPATH.ProviderManufacturer.module) {
          const url = this._router.serializeUrl(this._router.createUrlTree([SEARCHPATH.ProviderManufacturer.path], { queryParams: { referenceId: result.referenceId } }));
          window.open(url, '_blank');
        } else if (result.entity === SEARCHPATH.Client.module) {
          if (!this.canEditClient) {
            this._toastyService.warning({
              title: this._commonDataService.localizationLabelList['client'],
              msg: this._commonDataService.localizationLabelList['permission_not_granted']
            });
          } else {
            const url = this._router.serializeUrl(this._router.createUrlTree([SEARCHPATH.Client.path], { queryParams: { referenceId: result.referenceId } }));
            window.open(url, '_blank');
          }
        } else if (result.entity === SEARCHPATH.Work.module) {
          if (!this.canEditWork) {
            this._toastyService.warning({
              title: this._commonDataService.localizationLabelList['work'],
              msg: this._commonDataService.localizationLabelList['permission_not_granted']
            });
          } else {
            const url = this._router.serializeUrl(this._router.createUrlTree([SEARCHPATH.Work.path], { queryParams: { referenceId: result.referenceId } }));
            window.open(url, '_blank');
          }
        } else if (result.entity === SEARCHPATH.InvoiceHeader.module) {
          const url = this._router.serializeUrl(this._router.createUrlTree([SEARCHPATH.InvoiceHeader.path], { queryParams: { referenceId: result.referenceId } }));
          window.open(url, '_blank');
        }
      } else { console.log('result.entity or result.value null'); }
    }
  }

  //#endregion

  GoToWorkExpandPage() { window.open('/work?is_work_expand=true', '_blank'); }

  // route to dentbox
  RedirectToDentbox() {
    this._dentboxLabService.RouteToDentboxChat();
  }

  // on barcode trigger
  // onBarcodeInput(element:any) {
  //   if (element.searchQuery) {

  //     this.GetWorkByMainwork(element.searchQuery).then(subwork => {
  //       if (subwork.id > 0) {
  //         this._router.navigate([SEARCHPATH.Work.path], {
  //           queryParams: { workId: subwork.workId, subWorkId: subwork.id }
  //         });
  //       }
  //     });
  //   }
  // }

  async onBarcodeInput(element: any) {
    try {
      let subWork = new SubWork();
      if (element.searchQuery && element.searchQuery.length > 6) {
        // search by mwt no
        subWork = await this._workService.GetWorkByMainwork(element.searchQuery);
      } else if (element.searchQuery.length === 6) { // search work with prescription no
        const subWorkNum = element.searchQuery.charAt(element.searchQuery.length - 1);
        const workNum = element.searchQuery.substring(0, element.searchQuery.length - 1);
        if (workNum && subWorkNum) {
          const data: { work: Work, subWork: SubWork } = await this._workService
            .GetWorkAndSubWork(workNum, subWorkNum);
          if (data) { subWork = data.subWork; }
        }
      }
      if (subWork && subWork.id > 0) {
        if (!this.canEditWork) {
          this._toastyService.warning({
            title: this._commonDataService.localizationLabelList['work'],
            msg: this._commonDataService.localizationLabelList['permission_not_granted']
          });
        } else {
          const url = this._router.serializeUrl(this._router.createUrlTree([SEARCHPATH.Work.path], { queryParams: { workId: subWork.workId, subWorkId: subWork.id } }));
          window.open(url, '_blank');
        }


      }
    } catch (error) { console.log(error); }
  }

  GetWorkByMainwork(mwtNo: string) { return this._apiService.get(API.Global.GetWorkByMainworktypeNo + mwtNo, true).toPromise(); }

}

