import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import { CommonFunctions } from '../../common/services/common-functions';
import { MwtImplantData } from '../entities/main-work-type-implant-data';
import API from './../../common/api.config.json';
import { MwtColor } from '../entities/main-work-type-color';
import { MwtMetal } from '../entities/main-work-type-metal';

@Injectable({
  providedIn: 'root'
})
export class MwtColorService {

  constructor(private _apiService: ApiService) { }

  GetMwtColorListByMainWorkTypeIdList(idList: { id: number }[]) {
    try {
      const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
      if (params) {
        const query = params.toString().replace('+', '%20');
        return this._apiService.get(API.work_panels.getMwtColorListByMainWorkTypeIdList + query, true)
          .map(p => p as MwtColor[]).toPromise();
      }
    } catch (error) { console.log(error); }
  }

  SaveAllMwtColors(colorList: MwtColor[]) {
    return this._apiService.post(API.main_work_type_work.saveAllColor, colorList, false, true).toPromise();
  }

  GetMwtmatelListByMainWorkTypeId(idList: { id: number }[]) {
    try {
      const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
      if (params) {
        const query = params.toString().replace('+', '%20');
        return this._apiService.get(API.work_panels.getMwtmatelListByMainWorkTypeId + query, true)
          .map(p => p as MwtMetal[]).toPromise();
      }
    } catch (error) { console.log(error); }
  }

  SaveAllMwtMetals(matelList: MwtMetal[]) {
    return this._apiService.post(API.main_work_type_work.saveAllMatels, matelList, false, true).toPromise();
  }
}
