import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "timeFormat" })
export class TimeFormatPipe implements PipeTransform {
  transform(time: any): any {
    if(time != null){
      let hour = time.split(":")[0];
      let min = time.split(":")[1];
      return `${hour}:${min}`;
    }
   return time;
  }
}