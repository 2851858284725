import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-line-charts',
  templateUrl: './line-charts.component.html'
})
export class LineChartsComponent implements OnInit {
  @Input() lineChartData: ChartDataSets[];
  @Input() isChartExpand: boolean;

  public lineChartLabels: Label[] = ['1','2','3','4','5','6','7','8','9','10','11','12','13',
  '14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];

  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        ticks: {
          fontSize: 12
        }
      }],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            fontSize: 12
          }
        },
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
    legend: {
        display: true,
        labels: {
            fontSize: 14
        }
    }
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'green',
      pointBackgroundColor: 'green',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'green'
    },
    {
      borderColor: 'orange',
      pointBackgroundColor: 'orange',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'orange'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, { static: true } as any) chart: BaseChartDirective;

  constructor() {  }

  ngOnInit() {
    if(this.lineChartOptions && this.isChartExpand){
      if(this.isChartExpand){
        this.lineChartOptions.legend.labels.fontSize = 25;
        if(this.lineChartOptions.scales){
          this.lineChartOptions.scales.xAxes[0].ticks.fontSize = 20;
          this.lineChartOptions.scales.yAxes[0].ticks.fontSize =20;
        }
      }
    }
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
}
