import API from './../../../../common/api.config.json';
import { ApiService } from '../../../../common/services/api.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Color } from '../../../entities/Color';
import { Guide } from '../../../entities/Guide';
import { TranslateService } from '@ngx-translate/core';
import { CommonDataService } from '../../../../common/services/common.data.service';
import { CommonFunctions } from 'src/app/modules/common/services/common-functions';
import { Toast } from 'src/app/modules/common/services/toast.service';

@Component({
  templateUrl: './color.component.html',
  selector: 'color-add-list',
  styleUrls: ['./color.component.scss']
})

export class ColorComponent implements OnInit {

  successMessage: any;
  updateMessage: any;
  errorMessage: any;
  deleteMessage: any;
  statusMessage: any;
  deleteErrorMessage: any;
  tableName: any;

  color: Color;
  isLoad: boolean;

  checkColor: boolean = false;
  checkColorGuide: boolean = false;
  colorWarningMessage: string;

  position = 'bottom-right';
  colorList: Color[];
  private _colorList: Color[] = [];
  guideList: Guide[];
  colorFormGroup: FormGroup;
  colorForm: NgForm;
  isDisabledColor = false;


  @ViewChild('description') nameField: ElementRef;

  constructor(
    private _router: Router,
    private _apiService: ApiService,
    private _fb: FormBuilder,
    private _commonUIService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: Toast,
    private translate: TranslateService) {
    this.color = new Color();
    this.isLoad = false;
    this.isDisabledColor = false;
  }

  ngOnInit(): void {

    this.colorFormGroup = this._fb.group({
      description: ['', [
        Validators.required
      ]],
      colorGuideId: ['']
    })

    this.LoadColorList();
  }

  LoadColorList() {
    this._apiService.get(API.color.getColorList)
      .subscribe(res => {
        this.colorList = res;
        this._colorList = res;
        this.isLoad = true;
      });
  }

  LoadGuideList() {
    this._apiService.get(API.guide.getGuideList)
      .subscribe(res => {
        this.color.colorGuideId = 0;
        this.guideList = res.filter(x => x.status);
        this.guideList = CommonFunctions.Sort(this.guideList, 'description');
      });
  }

  LoadEditColor(color: Color) {
    color.isEdit = true;
    this.LoadGuideList();
    if (color) {
      const index = this.colorList.findIndex(x => x.id === color.id);
      if (index !== -1) {
        const c = new Color();
        c.id = color.id;
        c.description = color.description;
        c.colorGuideId = color.colorGuideId;
        c.isDescriptionRequired = false;
        c.guideName = color.guideName;
        c.status = color.status;
        c.isEdit = true;
        this.colorList[index] = c;
      }
    } return;
  }

  EditColor(color: Color, statusChange: boolean) {
    this.isDisabledColor = true;

    color.isEdit = false;
    this.statusMessage = this._commonDataService.localizationLabelList['status_updated'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.tableName = this._commonDataService.localizationLabelList['colors'];

    if (color.id && color.description) {
      this._apiService.put(API.color.editColor + color.id, color, false)
        .subscribe(
          res => {
            if (res.status == 204 || res.status == 200) {

              let message = '';

              if (statusChange) {
                message = this.statusMessage;
              }
              else {
                message = this.updateMessage;
              }

              this._toastyService.success({
                title: this.tableName,
                msg: message
              });

              this.LoadColorList();
            }
          },
          err => {
            console.log(err);
          },
          () => {
            this.isDisabledColor = false;
          }
        );
    } else {
      if (color) {
        const index = this.colorList.findIndex(x => x.id === color.id);
        if (index !== -1) {
          const c = new Color();
          c.id = color.id;
          c.description = '';
          c.colorGuideId = color.colorGuideId;
          c.isDescriptionRequired = true;
          c.guideName = color.guideName;
          c.status = color.status;
          this.colorList[index] = c;
        }
      } return;
    }
  }

  RemoveEditColor(color: Color) {
    color.isEdit = false;
    this.LoadColorList();
  }

  ActiveDeactiveColor(color: Color) {
    if (color) {
      color.status = !color.status;
      this.EditColor(color, true);
    }
  }

  OnOpenAddColorModal() {

    this.successMessage = this._commonDataService.localizationLabelList['save_success'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.errorMessage = this._commonDataService.localizationLabelList['save_error'];
    this.tableName = this._commonDataService.localizationLabelList['colors'];

    this.LoadGuideList();
    this.color = new Color(); this.colorFormGroup.reset();
    this._commonUIService.openModal('color-modal');

    setTimeout(() => {
      this.nameField.nativeElement.focus();
    }, 100);

  }

  OnCloseColorModal(event) {
    (((((event.target.parentElement.parentElement).parentElement).parentElement).parentElement).parentElement).classList.remove('md-show');
  }

  OnMouseOverStart(elem) {
    document.querySelector('#' + elem).classList.remove('hideBtn');
    document.querySelector('#' + elem).classList.add('showBtn');
  }

  OnMouseOutStart(elem) {
    document.querySelector('#' + elem).classList.remove('showBtn');
    document.querySelector('#' + elem).classList.add('hideBtn');
  }

  SaveColor(color: Color, event) {
    this.isDisabledColor = true;
    if (this.colorFormGroup.invalid) {
      Object.keys(this.colorFormGroup.controls).forEach(field => {
        const control = this.colorFormGroup.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      this.isDisabledColor = false;
      return;
    }

    //check the color and color guide is duplicating
    if (color.description || color.colorGuideId) {

      this._apiService.get(API.color.checkColorAndColorGuide + color.description + '/' + color.colorGuideId)
        .subscribe(res => {

          if (res) {
            this.checkColor = true;
            this.colorWarningMessage = 'Existen datos con ese mismo nombre';

          } else {

            if (this.color.colorGuideId == 0) {
              this.color.colorGuideId = null;
            }

            this.OnCloseColorModal(event);

            this._apiService.post(API.color.saveColor, color, false)
              .subscribe(
                res => {
                  if (res.status == 204 || res.status == 200) {
                    console.log(res);

                    this._toastyService.success({
                      title: this.tableName,
                      msg: this.successMessage
                    });
                    this.LoadColorList();
                  }
                },
                err => {
                  console.log(err);
                  this._toastyService.error({
                    title: this.tableName,
                    msg: err
                  });
                  this.isDisabledColor = false;
                },
                () => {
                  this.isDisabledColor = false;
                }
              );
          }
        });
    }
  }

  DeleteColor(id: number) {
    this.isDisabledColor = true;
    this.deleteMessage = this._commonDataService.localizationLabelList['delete_success'];
    this.deleteErrorMessage = this._commonDataService.localizationLabelList['delete_error'];
    this.tableName = this._commonDataService.localizationLabelList['colors'];

    if (id) {
      this._apiService.delete(API.color.deleteColor, id.toString())
        .subscribe(
          res => {
            if (res._statusCode == 200) {
              let index: number = this._colorList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._colorList.splice(index, 1);
                this._toastyService.success({
                  title: this.tableName,
                  msg: this.deleteMessage
                });
              }
            }
            if (res._statusCode == 409) {
              let index: number = this._colorList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._toastyService.warning({
                  title: this.tableName,
                  msg: this.deleteErrorMessage
                });
              }
            }
          },
          err => {
            this._toastyService.error({
              title: this.tableName,
              msg: err
            });
            this.isDisabledColor = false;

          },
          () => {
            this.isDisabledColor = false;
            this.colorList = this._colorList;
          }
        );
    }
  }

  onClickInput() {
    this.checkColor = false;
  }
}



