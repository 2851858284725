import API from './../../../../common/api.config.json';
import { ApiService } from '../../../../common/services/api.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommonDataService } from '../../../../common/services/common.data.service';
import { StatesOfWork } from '../../../entities/status-of-work';
import { Toast } from 'src/app/modules/common/services/toast.service';


@Component({
  templateUrl: './status-of-work.component.html',
  selector: 'status-of-work-list',
  styleUrls: []
})

export class StatusOfWorkComponent implements OnInit {

  successMessage: any;
  updateMessage: any;
  errorMessage: any;
  deleteMessage: any;
  statusMessage: any;
  deleteErrorMessage: any;
  tableName: any;
  defaultValue: any;
  finished: any;

  localizationLabelList: string[] = [];

  statusOfWork: StatesOfWork;
  isLoad: boolean;

  position = 'bottom-right';
  statusOfWorkList: StatesOfWork[];
  private _statusOfWorkList: StatesOfWork[] = [];

  statusOfWorkFormGroup: FormGroup;
  statusOfWorkForm: NgForm;

  @ViewChild('description') nameField: ElementRef;

  constructor(
    private _router: Router,
    private _apiService: ApiService,
    private _fb: FormBuilder,
    private _commonUIService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: Toast) {
    this.statusOfWork = new StatesOfWork();
  }

  ngOnInit(): void {

    this.statusOfWorkFormGroup = this._fb.group({
      description: ['', [
        Validators.required
      ]],
      code: ['']
    })

    this.LoadStatusOfWorkList();
    this.isLoad = false;
  }

  LoadStatusOfWorkList() {
    this._apiService.get(API.status_of_work.getStatusOfWorkList)
      .subscribe(res => {
        this.statusOfWorkList = res;
        this._statusOfWorkList = res;
        this.isLoad = true;
      });
  }

  LoadEditStatusOfWork(statusOfWork: StatesOfWork) {
    statusOfWork.isEdit = true;
  }

  EditStatusOfWork(statusOfWork: StatesOfWork, statusChange: boolean) {

    this.statusMessage = this._commonDataService.localizationLabelList['status_updated'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.tableName = this._commonDataService.localizationLabelList['states_of_work'];

    statusOfWork.isEdit = false;
    this._apiService.put(API.status_of_work.editStatusOfWork + statusOfWork.id, statusOfWork, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {

            let message = '';
            message = this.updateMessage;

            this._toastyService.success({
              title: this.tableName,
              msg: message
            });

            this.LoadStatusOfWorkList();
          }
        },
        err => {
          console.log(err);
        }
      );

  }

  RemoveEditStatusOfWork(statusOfWork: StatesOfWork) {
    statusOfWork.isEdit = false;
    this.LoadStatusOfWorkList();
  }

  ActiveDeactiveStatusOfWork(statusOfWork: StatesOfWork) {

    if (statusOfWork) {
      if (statusOfWork.status) {
        statusOfWork.status = false;
      }
      else {
        statusOfWork.status = true;
      }
      this.EditStatusOfWork(statusOfWork, true);
    }
  }

  OnOpenAddStatusOfWorkModal() {

    this.successMessage = this._commonDataService.localizationLabelList['save_success'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.errorMessage = this._commonDataService.localizationLabelList['save_error'];
    this.tableName = this._commonDataService.localizationLabelList['states_of_work'];
    this.statusOfWork = new StatesOfWork(); this.statusOfWorkFormGroup.reset();
    this._commonUIService.openModal('status-of-work-modal');

    setTimeout(() => {
      this.nameField.nativeElement.focus();
    }, 100);

  }

  OnCloseStatusOfWorkModal(event) {
    (((((event.target.parentElement.parentElement).parentElement).parentElement).parentElement).parentElement).classList.remove('md-show');
  }

  SaveStatusOfWork(statusOfWork: StatesOfWork, event) {

    if (this.statusOfWorkFormGroup.invalid) {
      Object.keys(this.statusOfWorkFormGroup.controls).forEach(field => {
        const control = this.statusOfWorkFormGroup.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }

    this.OnCloseStatusOfWorkModal(event);

    this._apiService.post(API.status_of_work.saveStatusOfWork, statusOfWork, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {
            console.log(res);

            this._toastyService.success({
              title: this.tableName,
              msg: this.successMessage
            });
            this.LoadStatusOfWorkList();
          }
          //this.OnCloseStatusOfWorkModal(event);
        },
        err => {
          console.log(err);
          this._toastyService.error({
            title: this.tableName,
            msg: err
          });
        }
      );
  }

  EditByDefaultStatusOfWork(statusOfWork: StatesOfWork) {

    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.defaultValue = this._commonDataService.localizationLabelList['default'];

    statusOfWork.isEdit = false;
    this._apiService.put(API.status_of_work.saveByDefault + statusOfWork.id,
      statusOfWork, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {
            let message = '';
            message = this.updateMessage;
            this._toastyService.success({
              title: this.defaultValue,
              msg: message
            });

            this.LoadStatusOfWorkList();
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  ActiveDeactiveNoFee(statusOfWork: StatesOfWork) {

    if (statusOfWork) {
      if (statusOfWork.noFee) {
        statusOfWork.noFee = false;
      }
      else {
        statusOfWork.noFee = true;
      }
      this.EditStatusOfWork(statusOfWork, true);
    }
  }

  ActiveDeactiveFinished(statusOfWork: StatesOfWork) {

    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.finished = this._commonDataService.localizationLabelList['finished'];

    statusOfWork.isEdit = false;
    this._apiService.put(API.status_of_work.saveFinished + statusOfWork.id,
      statusOfWork, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {
            let message = '';
            message = this.updateMessage;
            this._toastyService.success({
              title: this.finished,
              msg: message
            });
            this.LoadStatusOfWorkList();
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  RemoveStatusOfWork(statusOfWork: StatesOfWork, event?: any): void {

    this.deleteMessage = this._commonDataService.localizationLabelList['delete_success'];
    this.deleteErrorMessage = this._commonDataService.localizationLabelList['delete_error'];
    this.tableName = this._commonDataService.localizationLabelList['states_of_work'];

    if (statusOfWork !== undefined) {
      this._apiService.delete(API.status_of_work.deleteStatusOfWork, statusOfWork.id.toString())
        .subscribe(
          res => {
            if (res._statusCode == 200) {
              let index: number = this._statusOfWorkList.findIndex(e => e.id === statusOfWork.id);
              if (index !== -1) {
                this._statusOfWorkList.splice(index, 1);
                this._toastyService.success({
                  title: this.tableName,
                  msg: this.deleteMessage
                });
              }
            }
            if (res._statusCode == 409) {
              let index: number = this._statusOfWorkList.findIndex(e => e.id === statusOfWork.id);
              if (index !== -1) {
                this._toastyService.warning({
                  title: this.tableName,
                  msg: this.deleteErrorMessage
                });
              }
            }
          },
          err => {
            this._toastyService.error({
              title: this.tableName,
              msg: err
            });
          },
          () => {
            this.statusOfWorkList = this._statusOfWorkList;
          }
        );
    }
    else console.log('status of work is empty!');
  }
}

