import { Pipe, PipeTransform } from '@angular/core';
import { CustomTranslations } from '../global';

@Pipe({ name: 'ctranslate' })
export class CustomTranslatePipe implements PipeTransform {

    private currentLang: string;

    transform(value: string): string {
        const pipeValue = value.toLowerCase();
        const lang = localStorage.getItem('currentLang');
        this.currentLang = lang ? lang : 'en'; // default spanish
        if (this.currentLang) {
            const labelSet = CustomTranslations[this.currentLang];
            if (labelSet) { return labelSet[pipeValue]; }
        }
        return value;
    }
}
