import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DropdownService } from '../../modules/common/services/dropdown.service';
import { ListNames } from '../../modules/common/global';
import { ArticleTemplateService } from '../../modules/work/services/article-template.service';
import { MwtArticle } from 'src/app/modules/work/entities/main-work-type-article';
import { ArticlePopupFormModel } from './article-popup-form-model';
import { ValidateNgSelect } from 'src/app/modules/common/services/validators.js';
import { CommonUIService } from '../../modules/common/services/common.ui.service';
import { ShowInvalidFormControls } from '../../modules/common/services/validators';
import { IvaTypes } from 'src/app/modules/auxiliary/entities/iva-types';
import { ArticleCalculationService } from '../../modules/common/services/calculations/article-calculation.service';
import { toFixedNumber } from '../../modules/common/services/calculations/calculation';

@Component({
  selector: 'app-delivery-note-article-popup',
  templateUrl: './delivery-note-article-popup.component.html'
})
export class DeliveryNoteArticlePopupComponent implements OnInit {

  @Input() showArticlePopup: boolean;
  @Input() modelObject: {
    index: number;
    articleFormModel: ArticlePopupFormModel,
    clientId: number,
    mwtList: { id: number, description: string }[]
  };
  @Output() articlePopupClosed = new EventEmitter<{ isSave: boolean, index: number, formModel?: ArticlePopupFormModel }>();

  formGroup: FormGroup;
  articleList: { id: number, description: string }[] = [];
  mwtList: { id: number, description: string }[] = [];
  ivaTypeList: IvaTypes[];

  constructor(private _formBuilder: FormBuilder,
    private _commonUiService: CommonUIService,
    private _articleTemplateService: ArticleTemplateService,
    private _dropdownService: DropdownService,
    private _calculationService: ArticleCalculationService) {
    this.InitFormGroup();
  }

  ngOnInit() {
    this.InitResources();
    if (this.modelObject) {
      this.mwtList = this.modelObject.mwtList;
      if (this.modelObject.index > -1) {
        this.InitFormGroup(this.modelObject.articleFormModel);
      } else { this.InitFormGroup(); }
    }
  }

  private InitFormGroup(init?: ArticlePopupFormModel) {
    this.formGroup = this._formBuilder.group({
      mwtId: [init ? init.mwtId : 0, ValidateNgSelect],
      articleId: [init ? init.articleId : 0, Validators.required],
      articleName: [init ? init.articleName : '', Validators.required],
      quantity: [init ? init.quantity : 0],
      waste: [init ? init.waste : 0, Validators.required],
      quantityWithWaste: [init ? init.quantityWithWaste : 0],
      price: [init ? init.price : 0],
      discount: [init ? init.discount : 0],
      discountAmount: [init ? init.discountAmount : 0],
      priceWithDiscount: [init ? init.priceWithDiscount : 0],
      finalPrice: [init ? init.finalPrice : 0],
      isAmount: [init ? init.isAmount : false],
      mwtArticleId: [init ? init.mwtArticleId : 0],
      ivaTypeId: [init ? init.ivaTypeId ? init.ivaTypeId : 0 : 0],
      ivaAmount: [init ? init.ivaAmount : 0],
      ivaPercentage: [init ? init.ivaPercentage : 0],
      lineDiscountAmount: [init ? init.lineDiscountAmount : 0],
      lineSubTotal: [init ? init.lineSubTotal : 0]
    });
    if (init) {
      this.OnKeyUpQuantityOrWaste();
      this.OnKeyUpPriceOrDiscount();
      this.OnChangeQtyWithWasteOrPriceWithDiscount();
    }
  }

  private async InitResources() {
    if (this._dropdownService.ivaTypeList.length === 0) {
      await this._dropdownService.LoadListAsync([ListNames.IvaTypeList]);
    }
    this.ivaTypeList = this._dropdownService.ivaTypeList.filter(a => a.status);
    if (this._dropdownService.articleList.length === 0) {
      await this._dropdownService.LoadListAsync([ListNames.ArticleList]);
    }
    const articleList = this._dropdownService.articleList.filter(a => a.isActive);
    this.articleList = articleList.map(a => ({ id: a.id, description: a.description1 }));
  }

  OnPopupClose(isSave = false) { this.articlePopupClosed.emit({ isSave: isSave, index: this.modelObject.index }); }

  SetArticle(article: { id: number, description: string }, cleared: boolean) {
    if (!cleared) {
      this.formGroup.controls.articleId.setValue(article.id);
      this.formGroup.controls.articleName.setValue(article.description);
      this.OnSearchArticle(this.modelObject.clientId, article);
    } else {
      this.formGroup.controls.articleId.setValue(0);
      this.formGroup.controls.articleName.setValue('');
      this.formGroup.controls.quantity.setValue(0);
      this.formGroup.controls.waste.setValue(0);
      this.formGroup.controls.finalPrice.setValue(0);
      this.formGroup.controls.discountAmount.setValue(0);
      this.formGroup.controls.discount.setValue(0);
      this.formGroup.controls.price.setValue(0);
      this.formGroup.controls.isAmount.setValue(false);
      this.formGroup.controls.quantityWithWaste.setValue(0);
      this.formGroup.controls.priceWithDiscount.setValue(0);
      this.formGroup.controls.ivaTypeId.setValue(0);
      this.formGroup.controls.ivaAmount.setValue(0);
      this.formGroup.controls.ivaPercentage.setValue(0);
      this.formGroup.controls.lineDiscountAmount.setValue(0);
      this.formGroup.controls.lineSubTotal.setValue(0);
    }
  }

  async OnSearchArticle(clientId: number, article: { id: number, description: string }) {
    try {
      const mwtArticle = await this.GetMwtArticle(clientId, article);
      if (mwtArticle) {
        this.formGroup.controls.quantity.setValue(mwtArticle.quantity ? Number(mwtArticle.quantity) : 0);
        this.formGroup.controls.waste.setValue(mwtArticle.waste ? mwtArticle.waste : 0);
        this.formGroup.controls.finalPrice.setValue(mwtArticle.finalPrice ? mwtArticle.finalPrice : 0);
        this.formGroup.controls.discountAmount.setValue(mwtArticle.discountAmount ?
          mwtArticle.discountAmount : 0);
        this.formGroup.controls.discount.setValue(mwtArticle.discount ? mwtArticle.discount : 0);
        this.formGroup.controls.price.setValue(mwtArticle.price ? mwtArticle.price : 0);
        this.formGroup.controls.isAmount.setValue(mwtArticle.isAmount);
        this.formGroup.controls.quantityWithWaste.setValue(mwtArticle.quantityWithWaste);
        this.formGroup.controls.priceWithDiscount.setValue(mwtArticle.priceWithDiscount);
        this.formGroup.controls.lineDiscountAmount.setValue(mwtArticle.lineDiscountAmount);
        this.formGroup.controls.lineSubTotal.setValue(mwtArticle.lineSubTotal);
        if (mwtArticle.ivaTypeId) {
          this.formGroup.controls.ivaTypeId.setValue(mwtArticle.ivaTypeId);
          this.formGroup.controls.ivaAmount.setValue(mwtArticle.ivaAmount);
          this.formGroup.controls.ivaPercentage.setValue(mwtArticle.ivaPercentage);
        } else {
          this.formGroup.controls.ivaTypeId.setValue(0);
          this.formGroup.controls.ivaAmount.setValue(0);
          this.formGroup.controls.ivaPercentage.setValue(0);
        }
        this.OnKeyUpQuantityOrWaste();
        this.OnKeyUpPriceOrDiscount();
      }
    } catch (error) { console.log(error); }
  }

  private async GetMwtArticle(clientId: number, article: { id: number, description: string }) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      const mwtArticle = await this._articleTemplateService
        .GetMwtArticlePriceDetails(article.id, clientId);
      if (mwtArticle) {
        mwtArticle.articleId = article.id;
        mwtArticle.description1 = article.description;
        mwtArticle.quantity = 1;
        mwtArticle.quantityWithWaste = this.GetQuantityWithWaste(mwtArticle);
        mwtArticle.priceWithDiscount = this.GetPriceWithDiscount(mwtArticle);
        mwtArticle.finalPrice = this.GetFinalPrice(mwtArticle);

        mwtArticle.discountAmount = this._calculationService
          .getDiscountAmount(mwtArticle.price, mwtArticle.discount);

        mwtArticle.lineSubTotal = this.GetLineSubTotal(mwtArticle.price,
          mwtArticle.quantityWithWaste);

        mwtArticle.lineDiscountAmount = this
          .GetLineDiscountAmount(mwtArticle.finalPrice,
            mwtArticle.ivaAmount, mwtArticle.lineSubTotal);

        return mwtArticle;
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  private GetQuantityWithWaste(mwtArticle: MwtArticle) {
    if (mwtArticle.waste !== undefined && mwtArticle.quantity !== undefined) {
      mwtArticle.quantity = Number(mwtArticle.quantity);
      return this._calculationService
        .getQuantityWithWaste(toFixedNumber(mwtArticle.quantity),
          toFixedNumber(mwtArticle.waste));
    }
  }

  private GetPriceWithDiscount(mwtArticle: MwtArticle) {
    if (mwtArticle.price !== undefined && mwtArticle.discount !== undefined) {
      return this._calculationService
        .getPriceWithDiscount(toFixedNumber(mwtArticle.price),
          mwtArticle.isAmount ? toFixedNumber(mwtArticle.discountAmount)
            : toFixedNumber(mwtArticle.discount),
          mwtArticle.isAmount);
    }
  }

  private GetFinalPrice(mwtArticle: MwtArticle) {
    if (mwtArticle.priceWithDiscount !== undefined && mwtArticle.quantityWithWaste !== undefined) {
      return this._calculationService
        .getArticleFinalTotal(toFixedNumber(mwtArticle.quantityWithWaste),
          toFixedNumber(mwtArticle.priceWithDiscount));
    }
  }

  private GetLineDiscountAmount(finalTotal: number, vatAmount: number, subTotal: number) {
    try {
      return toFixedNumber(subTotal - (finalTotal - vatAmount));
    } catch (error) { console.log(error); }
  }

  private GetLineSubTotal(price: number, quantityWithWaste: number) {
    try {
      return this._calculationService
        .getLineSubTotal(price, quantityWithWaste);
    } catch (error) { console.log(error); }
  }

  public OnKeyUpQuantityOrWaste() {
    const waste = toFixedNumber(this.formGroup.controls.waste.value);
    const quantity = Number(this.formGroup.controls.quantity.value);
    let quantityWithWaste = this._calculationService
      .getQuantityWithWaste(toFixedNumber(quantity), toFixedNumber(waste));
    this.formGroup.controls.quantityWithWaste.setValue(quantityWithWaste);
  }

  OnKeyUpPriceOrDiscount() {
    const price = this.formGroup.controls.price.value;
    const isAmount = this.formGroup.controls.isAmount.value;
    const discount = this.formGroup.controls.discount.value;
    const discountAmount = this.formGroup.controls.discountAmount.value;
    const priceWithDiscount = this._calculationService
      .getPriceWithDiscount(toFixedNumber(price),
        isAmount ? toFixedNumber(discountAmount) : toFixedNumber(discount), isAmount);
    this.formGroup.controls.priceWithDiscount.setValue(priceWithDiscount);
  }

  OnChangeQtyWithWasteOrPriceWithDiscount() {
    const priceWithDiscount = this.formGroup.controls.priceWithDiscount.value;
    const quantityWithWaste = this.formGroup.controls.quantityWithWaste.value;
    const price = this.formGroup.controls.price.value;
    const discountPercentage = this.formGroup.controls.discount.value;

    let finalPrice = 0, discountAmount = 0, lineDiscountAmount = 0, lineSubTotal = 0;
    if (priceWithDiscount !== undefined && quantityWithWaste !== undefined) {
      const finalPriceWithoutIva = toFixedNumber(priceWithDiscount * quantityWithWaste);
      let ivaAmount = 0;
      if (this.formGroup.controls.ivaTypeId) {
        ivaAmount = this._calculationService
          .getIvaAmount(finalPriceWithoutIva, this.formGroup.controls.ivaPercentage.value
            ? this.formGroup.controls.ivaPercentage.value : 0);
        this.formGroup.controls.ivaAmount.setValue(toFixedNumber(ivaAmount));
      }
      finalPrice = toFixedNumber(finalPriceWithoutIva + toFixedNumber(ivaAmount));
      discountAmount = this._calculationService.getDiscountAmount(price, discountPercentage);
      lineSubTotal = this.GetLineSubTotal(price, quantityWithWaste);
      lineDiscountAmount = this.GetLineDiscountAmount(finalPrice, ivaAmount, lineSubTotal);
    }
    this.formGroup.controls.finalPrice.setValue(finalPrice);
    this.formGroup.controls.discountAmount.setValue(discountAmount);
    this.formGroup.controls.lineSubTotal.setValue(lineSubTotal);
    this.formGroup.controls.lineDiscountAmount.setValue(lineDiscountAmount);
  }

  OnSaveArticle() {
    if (this.mwtList.length === 1) {
      this.formGroup.controls.mwtId.clearValidators();
      this.formGroup.controls.mwtId.setErrors(null);
      this.formGroup.controls.mwtId.setValidators(null);
    } else {
      this.formGroup.controls.mwtId.setValidators([ValidateNgSelect]);
    }
    this.formGroup.controls.mwtId.updateValueAndValidity();
    if (this.formGroup.invalid) { ShowInvalidFormControls(this.formGroup); } else {
      const form = this.formGroup.value as ArticlePopupFormModel;
      if (this.mwtList.length === 1) { form.mwtId = Number(this.mwtList[0].id); } else {
        form.mwtId = Number(form.mwtId);
      }
      this.articlePopupClosed.emit({
        isSave: true,
        index: this.modelObject.index,
        formModel: form
      });
    }
  }

  OnChangeIvaType() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (Number(this.formGroup.controls.ivaTypeId.value)
        && this.ivaTypeList && this.ivaTypeList.length > 0) {
        const iType = this.ivaTypeList.find(it => it.id ===
          Number(this.formGroup.controls.ivaTypeId.value));
        if (iType) { this.formGroup.controls.ivaPercentage.setValue(iType.iva); }
      } else { this.formGroup.controls.ivaPercentage.setValue(0); }
      this.formGroup.controls.ivaAmount.setValue(0);
      this.OnChangeQtyWithWasteOrPriceWithDiscount();
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }
}

