import API from './../../../../common/api.config.json';
import { ApiService } from '../../../../common/services/api.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommonDataService } from '../../../../common/services/common.data.service';
import { WorkMotiveNoFee } from '../../../entities/work-motive-no-fee';
import { Toast } from 'src/app/modules/common/services/toast.service';


@Component({
  templateUrl: './work-motive-no-fee.component.html',
  selector: 'work-motive-add-list',
  styleUrls: []
})

export class WorkMotiveNoFeeComponent implements OnInit {

  successMessage: any;
  updateMessage: any;
  errorMessage: any;
  deleteMessage: any;
  statusMessage: any;
  deleteErrorMessage: any;
  tableName: any;

  workMotive: WorkMotiveNoFee;
  isLoad: boolean;

  position = 'bottom-right';
  workMotiveList: WorkMotiveNoFee[];
  private _workMotiveList: WorkMotiveNoFee[] = [];

  workMotiveFormGroup: FormGroup;
  workMotiveForm: NgForm;

  @ViewChild('description') nameField: ElementRef;
  isDisabledWorkMotive = false;

  constructor(
    private _router: Router,
    private _apiService: ApiService,
    private _fb: FormBuilder,
    private _commonUIService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: Toast) {
    this.workMotive = new WorkMotiveNoFee();
    this.isLoad = false;
    this.isDisabledWorkMotive = false;

  }

  ngOnInit(): void {

    this.workMotiveFormGroup = this._fb.group({
      description: ['', [
        Validators.required
      ]]
    })

    this.LoadworkMotiveList();
    this.workMotive = new WorkMotiveNoFee();
  }

  LoadworkMotiveList() {
    this._apiService.get(API.work_motive.getWorkMotiveList)
      .subscribe(res => {
        this.workMotiveList = res;
        this._workMotiveList = res;
        this.isLoad = true;
      });
  }

  LoadEditWorkMotive(workMotive: WorkMotiveNoFee) {
    workMotive.isEdit = true;
    console.log(workMotive);
  }

  EditWorkMotive(workMotive: WorkMotiveNoFee, statusChange: boolean) {
    this.isDisabledWorkMotive = true;

    this.statusMessage = this._commonDataService.localizationLabelList['status_updated'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.tableName = this._commonDataService.localizationLabelList['work_motive_no_fee'];

    workMotive.isEdit = false;

    this._apiService.put(API.work_motive.editWorkMotive + workMotive.id, workMotive, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {

            let message = '';

            if (statusChange) {
              message = this.statusMessage;
            }
            else {
              message = this.updateMessage;
            }

            this._toastyService.success({
              title: this.tableName,
              msg: message
            });

            this.LoadworkMotiveList();
          }
        },
        err => {
          console.log(err);
        },
        () => {
          this.isDisabledWorkMotive = false;
        }
      );
  }

  RemoveEditWorkMotive(workMotive: WorkMotiveNoFee) {
    workMotive.isEdit = false;
    this.LoadworkMotiveList();
  }

  ActiveDeactiveWorkMotive(workMotive: WorkMotiveNoFee) {

    if (workMotive) {
      if (workMotive.status) {
        workMotive.status = false;
      }
      else {
        workMotive.status = true;
      }
      this.EditWorkMotive(workMotive, true);
    }
  }

  OnOpenAddWorkMotiveModal() {

    this.successMessage = this._commonDataService.localizationLabelList['save_success'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.errorMessage = this._commonDataService.localizationLabelList['save_error'];
    this.tableName = this._commonDataService.localizationLabelList['work_motive_no_fee'];


    this.workMotive = new WorkMotiveNoFee(); this.workMotiveFormGroup.reset();
    this._commonUIService.openModal('work-motive-modal');

    setTimeout(() => {
      this.nameField.nativeElement.focus();
    }, 100);

  }

  OnCloseWorkMotiveModal(event) {
    (((((event.target.parentElement.parentElement).parentElement).parentElement).parentElement).parentElement).classList.remove('md-show');
  }

  SaveWorkMotive(workMotive: WorkMotiveNoFee, event) {
    this.isDisabledWorkMotive = true;

    if (this.workMotiveFormGroup.invalid) {
      Object.keys(this.workMotiveFormGroup.controls).forEach(field => {
        const control = this.workMotiveFormGroup.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      this.isDisabledWorkMotive = false;
      return;
    }

    this.OnCloseWorkMotiveModal(event);

    this._apiService.post(API.work_motive.saveWorkMotive, workMotive, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {
            this._toastyService.success({
              title: this.tableName,
              msg: this.successMessage
            });
            this.LoadworkMotiveList();
          }
        },
        err => {
          this._toastyService.error({
            title: this.tableName,
            msg: err
          });
          this.isDisabledWorkMotive = false;
        },
        () => {
          this.isDisabledWorkMotive = false;
        }
      );

  }

  DeleteWorkMotive(id: number) {
    this.isDisabledWorkMotive = true;

    this.deleteMessage = this._commonDataService.localizationLabelList['delete_success'];
    this.deleteErrorMessage = this._commonDataService.localizationLabelList['delete_error'];
    this.tableName = this._commonDataService.localizationLabelList['work_motive_no_fee'];

    if (id) {
      this._apiService.delete(API.work_motive.deleteWorkMotiveNoFee, id.toString())
        .subscribe(
          res => {
            if (res._statusCode == 200) {
              let index: number = this._workMotiveList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._workMotiveList.splice(index, 1);
                this._toastyService.success({
                  title: this.tableName,
                  msg: this.deleteMessage
                });
              }
            }
            if (res._statusCode == 409) {
              let index: number = this._workMotiveList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._toastyService.warning({
                  title: this.tableName,
                  msg: this.deleteErrorMessage
                });
              }
            }
          },
          err => {
            this._toastyService.error({
              title: this.tableName,
              msg: err
            });
            this.isDisabledWorkMotive = false;
          },
          () => {
            this.isDisabledWorkMotive = false;
            this.workMotiveList = this._workMotiveList;
          }
        );
    }
  }
}



