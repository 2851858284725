import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ClinicMaterial } from 'src/app/modules/auxiliary/entities/clinic-material';
import { ModalNames } from 'src/app/modules/common/global';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { ShowInvalidFormControls, ValidateNgSelect } from 'src/app/modules/common/services/validators';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtClinicMaterial } from '../../entities/main-work-type-clinic-material';
import { Work } from '../../entities/work';
import { MwtClinicMaterialService } from '../../services/mwt-clinic-material.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';

@Component({
  selector: 'app-clinic-material-modal',
  templateUrl: './clinic-material-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClinicMaterialModalComponent implements OnInit, AfterViewInit {
  showContent = false;
  @Output() refreshData: EventEmitter<any> = new EventEmitter();
  @Output() closePopup: EventEmitter<any> = new EventEmitter();

  // subscriptions
  private workService: Subscription;

  // form groups
  clinicMaterialFormGroup: FormGroup;

  // lists
  selectedMainWorkTypeList: MainWorkType[] = [];
  clinicMaterialList: ClinicMaterial[] = [];
  mainWorkTypeList: MainWorkType[];


  // objects
  mwtClinicMaterial: MwtClinicMaterial;
  selectedMainWorkType: MainWorkType;
  selectedWork: Work;

  constructor(private _workService: WorkService, private _apiService: ApiService,
    public commonUiService: CommonUIService, private _commonDataService: CommonDataService,
    private _dropdownService: DropdownService, private _modalService: ModalService,
    private __service: MwtClinicMaterialService, private _formBuilder: FormBuilder, private _toastyService: Toast,
    private cdref: ChangeDetectorRef) {
    this.mwtClinicMaterial = new MwtClinicMaterial();
    this.clinicMaterialFormGroup = this._formBuilder.group({
      mainWorkTypeId: ['', [ValidateNgSelect]],
      clinicMaterialId: ['', [ValidateNgSelect]],
      quantity: ['', [Validators.required]],
      note: []
    });
    this.showContent = true;
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.mwtClinicMaterial = new MwtClinicMaterial();
    if (this._modalService.modal === ModalNames.ClinicMaterialModal) {
      if (this._modalService.transferable) {
        this.selectedMainWorkTypeList = this._modalService.transferable.mainWorkTypeList as MainWorkType[];
        this.selectedWork = this._modalService.transferable.selectedWork as Work;
        if (this._modalService.transferable.isEdit) {
          this.OnEditMwtClinicMaterial(this._modalService.transferable.mwtClinicMaterial as MwtClinicMaterial);
        } else {
          this.OnOpenAddClinicMaterialModal();
        }
      }
    }
    this.cdref.markForCheck();
  }

  OnClose() {
    this.showContent = false;
    this.commonUiService.isPopupOpened = false;
    this.closePopup.emit();
  }

  OnOpenAddClinicMaterialModal() {
    this.commonUiService.isSpinnerVisible = true;
    this.commonUiService.isPopupOpened = true;
    this.clinicMaterialFormGroup.reset();
    if (this._dropdownService.clinicMaterialList) {
      this.clinicMaterialList = this._dropdownService.clinicMaterialList.filter(x => x.status);
    }
    this.mwtClinicMaterial = new MwtClinicMaterial();
    this.mwtClinicMaterial.clinicMaterialId = 0;
    this.mwtClinicMaterial.mainWorkTypeId = 0;
    this.mwtClinicMaterial.status = true;
    this.commonUiService.isSpinnerVisible = false;
    this.showContent = true;
    this.cdref.markForCheck();
  }

  OnEditMwtClinicMaterial(mwtClinicMaterial: MwtClinicMaterial) {
    if (mwtClinicMaterial) {
      this.commonUiService.isSpinnerVisible = true;
      this.commonUiService.isPopupOpened = true;
      this.clinicMaterialFormGroup.markAsPristine();
      this.clinicMaterialFormGroup.markAsUntouched();
      if (this._dropdownService.clinicMaterialList) {
        this.clinicMaterialList = this._dropdownService.clinicMaterialList.filter(x => x.status);
      }
      this.mwtClinicMaterial = Object.assign(this.mwtClinicMaterial, mwtClinicMaterial);
      this.commonUiService.isSpinnerVisible = false;
      this.showContent = true;
      this.cdref.markForCheck();
    }
  }

  //#region CRUD

  private SaveMwtClinicMaterial(mwtClinicMaterial: MwtClinicMaterial) {
    return this._apiService.post(API.main_work_type_work.saveMwtClinicMaterial, mwtClinicMaterial, false, true).toPromise();
  }

  //#endregion

  //#region Events
  async OnSaveMwtClinicMaterial(event) {
    if (event) {
      if (this.selectedMainWorkTypeList.length === 1) {
        this.clinicMaterialFormGroup.controls['mainWorkTypeId'].clearValidators();
        this.clinicMaterialFormGroup.controls['mainWorkTypeId'].setErrors(null);
        this.clinicMaterialFormGroup.controls['mainWorkTypeId'].setValidators(null);
      } else { this.clinicMaterialFormGroup.controls['mainWorkTypeId'].setValidators(ValidateNgSelect); }
      this.clinicMaterialFormGroup.updateValueAndValidity();
      if (this.clinicMaterialFormGroup.invalid) { ShowInvalidFormControls(this.clinicMaterialFormGroup); this.cdref.markForCheck(); return; }
      try {
        this.commonUiService.isSpinnerVisible = true;
        if (this.selectedMainWorkTypeList.length === 1) { this.mwtClinicMaterial.mainWorkTypeId = (this.selectedMainWorkTypeList[0]).id; }
        this.mwtClinicMaterial.clinicMaterialId = Number(this.mwtClinicMaterial.clinicMaterialId);
        this.mwtClinicMaterial.mainWorkTypeId = Number(this.mwtClinicMaterial.mainWorkTypeId);
        this.mwtClinicMaterial.workId = this.selectedWork.id;
        const response = await this.SaveMwtClinicMaterial(this.mwtClinicMaterial);
        if (response) {
          const body = JSON.parse(response['_body']);
          if (body['_isSuccsess']) {
            this.OnClose();
            this.refreshData.emit();
            this._toastyService.success({
              title: 'Clinic Material',
              msg: this._commonDataService.localizationLabelList['save_success']
            });
          } else {
            this._toastyService.error({
              title: 'Clinic Material',
              msg: this._commonDataService.localizationLabelList['save_error']
            });
            this.OnClose();
          }
        }
      } catch (error) { console.error(error); }
      finally {
        this.cdref.markForCheck();
        this.commonUiService.isSpinnerVisible = false;
      }
    }
  }

  //#endregion

}
