import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import API from './../../common/api.config.json';
import { SubWork } from '../entities/sub-work';
import { CommonFunctions } from '../../common/services/common-functions';

@Injectable({
  providedIn: 'root'
})
export class SubWorkService {

  constructor(private _apiService: ApiService) { }

  GetSubWorkListByWorkId(workId: number) {
    return this._apiService.get(API.sub_work.getSubWorkListByWorkId + workId, true)
      .map(list => list as SubWork[]).toPromise();
  }

  GetSubWorkById(subWorkId: number) {
    return this._apiService.get(API.sub_work.getById + subWorkId, true)
      .map(sw => sw as SubWork).toPromise();
  }

  GetSubWorkCountByWorkId(workId: number) {
    return this._apiService.get(API.sub_work.getSubWorkCountByWorkId + workId)
      .map(x => x as number).toPromise();
  }

  AddSubWork(subWork: SubWork) {
    return this._apiService.post(API.sub_work.addSubWork, subWork, false, true).toPromise();
  }

  EditSubWork(subWork: SubWork) {
    return this._apiService.put(API.sub_work.editSubWork + subWork.id, subWork, false, true).toPromise();
  }

  RemoveSubWork(subWork: SubWork) {
    return this._apiService.get(API.sub_work.deactivateSubWork + subWork.id, true).toPromise();
  }

  SetDeliveryNoteCalculations(idList: { deliveryNoteId: number }[]) {
    const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
    if (params) {
      const query = params.toString().replace('+', '%20');
      return this._apiService.get(API.sub_work.set_delivery_note_calculations + query, true).toPromise();
    }
  }
}
