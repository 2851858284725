import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    modalSubject = new Subject<{ modal: string, flag: boolean, transferable: any, isEdit?: boolean }>();
    transferable: any;
    isEdit: boolean;
    modal: string;

    constructor() { }

    Invoke(modal: string, flag: boolean, transferable: any, isEdit?: boolean) {
        this.transferable = transferable;
        this.isEdit = isEdit;
        this.modal = modal;
        this.modalSubject.next({ modal: modal, flag: flag, transferable: transferable, isEdit: isEdit });
    }
}

