import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import API from '../../common/api.config.json';
import { DentBox } from '../entities/dent-box';

@Injectable({
  providedIn: 'root'
})
export class DentBoxService {

  constructor(private _apiService: ApiService) { }

  /**
* get dentbox
*/
  getDentBoxLicenseKey() {
    return this._apiService.get(API.dent_box.getDentBoxLicenseKey).map((dent_box: DentBox) => dent_box).toPromise();
  }
}
