import { InvoiceCancellationDetail } from './invoice-cancellation-detail';

export class InvoiceCancellation {
    generatedNo: string;
    reasonForSubscriptionId: number;
    issuerId: number;
    seriesId: number;
    invoiceId: number;
    observation: string;
    invoiceNo: string;
    issuerName: string;
    reasonDescription: string;
    seriesName: string;
    detailList: InvoiceCancellationDetail[];

    constructor(init?: Partial<InvoiceCancellation>) {
        Object.assign(this, init);
    }
}
