import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {
  ValidateDatePicker,
  ValidateNgSelect,
  ShowInvalidFormControls
} from 'src/app/modules/common/services/validators';
import { Budget } from '../../entities/budget';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtArticle } from '../../entities/main-work-type-article';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { map } from 'rxjs/operators';
import { Work } from '../../entities/work';
import API from './../../../common/api.config.json';
import { Issuer } from 'src/app/modules/auxiliary/entities/issuer';
import { Client } from 'src/app/modules/client/entities/client';
import { WorkService } from '../../services/work.service';
import { Series } from 'src/app/modules/client/entities/series';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import {
  NumberingProcessData,
  NumberingProcess
} from 'src/app/shared/numbering-process/numbering-process';
import { NumberingProcessService } from 'src/app/shared/numbering-process/numbering-process.service';
import { ListNames, ModalNames } from 'src/app/modules/common/global';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { Article } from 'src/app/modules/articles/entities/article';
import { BudgetArticle } from '../../entities/budget-article';
import { ToastData, ToastyService } from 'ng2-toasty';
import {
  SavedGeneratedNumber,
  GenerateNumberModel
} from '../../entities/generate-number-model';
import { ClientSeries } from 'src/app/modules/client/entities/client-series';
import { SubWork } from '../../entities/sub-work';
import swal from 'sweetalert2';
import { WorkType } from 'src/app/modules/work-type/entities/work-type';
import { DropDownOption } from 'src/app/modules/common/entities/dropdown-option';
import { CommonFunctions } from 'src/app/modules/common/services/common-functions';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { IvaBreakdown } from 'src/app/modules/auxiliary/entities/iva-breakdown';
import { IvaTypes } from 'src/app/modules/auxiliary/entities/iva-types';
import { DeliverynoteService } from 'src/app/modules/delivery-note/services/deliverynote.service';
import { IvaCalculationService } from '../../services/iva-calculation.service';
import { ArticleCalculationService } from 'src/app/modules/common/services/calculations/article-calculation.service';
import { toFixedNumber } from 'src/app/modules/common/services/calculations/calculation';
import { DeliveryNoteCalculationService } from 'src/app/modules/common/services/calculations/delivery-note-calculation.service';

@Component({
  selector: 'app-generate-budget-modal',
  templateUrl: './generate-budget-modal.component.html',
  styleUrls: ['./generate-budget-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenerateBudgetModalComponent implements OnInit, AfterViewInit {
  showContent = false;

  isArticleListLoaded = true;
  // form goups
  budgetFormGroup: FormGroup;

  // objects
  transferable: any = {};
  selectedArticleEditObj: any = {};
  selectedArticle: MwtArticle = new MwtArticle();

  budget: Budget;
  selectedWork: Work;
  selectedSubWork: SubWork;
  selectedMainWorkType: MainWorkType;
  mainWorkTypeList: MainWorkType[];
  issuer: Issuer;
  client: Client;
  mwtArticle: MwtArticle;
  generateNumberModel: GenerateNumberModel;
  usedSeries: Series;

  // lists
  selectedMainWorkTypeList: MainWorkType[] = [];
  deliveryMwtArticleList: MwtArticle[] = [];
  issureList: Issuer[] = [];
  issureDropdownList: DropDownOption[] = [];
  ivaTypeList: IvaTypes[] = [];
  ivaBreakdownList: IvaBreakdown[] = [];

  seriesList: Series[] = [];
  articleList: Article[] = [];
  savedGeneratedNumberList: SavedGeneratedNumber[] = [];
  workTypeList: WorkType[] = [];

  currentNPData: NumberingProcessData;
  buNumberingProcess: NumberingProcess;
  buSeries: {
    id: number;
    type: 'Work' | 'Delivery Note' | 'Bill' | 'Budget';
    series: string;
  };
  buMaxSize = 5;
  buFormGroup: FormGroup;
  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  isEdit = false;
  selectedArticleIndex = -1;

  constructor(
    private _modalService: ModalService,
    private _commonUiService: CommonUIService,
    private _formBuilder: FormBuilder,
    private _apiService: ApiService,
    private _workService: WorkService,
    public commonUiService: CommonUIService,
    public _commonDataService: CommonDataService,
    private _numberingProcessService: NumberingProcessService,
    private _dropdownService: DropdownService,
    private _toastyService: Toast,
    private cdref: ChangeDetectorRef,
    private _deliverynoteService: DeliverynoteService,
    private _ivaCalculationService: IvaCalculationService,
    private _calculationService: ArticleCalculationService,
    private _deliveryNoteCalculationService: DeliveryNoteCalculationService
  ) {
    this.budget = new Budget();
    this.issuer = new Issuer();
    this.client = new Client();
    this.mwtArticle = new MwtArticle();
    this.usedSeries = new Series();
    this.selectedWork = new Work();
    this.generateNumberModel = new GenerateNumberModel();
    this.showContent = true;
    this.InitFormGroups();
  }

  ngOnInit() {
  }

  async ngAfterViewInit(): Promise<void> {
    if (this._modalService.modal === ModalNames.BudgetModal) {
      if (this._modalService.transferable != null &&
        this._modalService.transferable.selectedMainWorkTypeList != null
      ) {
        this.transferable = this._modalService.transferable;
        this.selectedMainWorkTypeList =
          this._modalService.transferable.selectedMainWorkTypeList;
        this.selectedWork = this._modalService.transferable.selectedWork;
        this.selectedSubWork = this._modalService.transferable.selectedSubWork;
        this.selectedMainWorkType =
          this._modalService.transferable.selectedMainWorkType;
        this.mainWorkTypeList = this._modalService.transferable.mainWorkTypeList;
        this.LoadList();
        if (!this._modalService.transferable.isEdit) {
          await this.OnOpenAddBudgetModal();
        } else if (this._modalService.transferable.budget != null &&
          this._modalService.transferable.isEdit) {
          await this.OnEditBudgetModal(this._modalService.transferable.budget);
        }
      }
    }
  }

  InitFormGroups() {
    this.budgetFormGroup = this._formBuilder.group({
      generatedDate: ['', [ValidateDatePicker]],
      articleId: [],
      validDate: [],
      issuerId: [],
      seriesId: ['', [ValidateNgSelect]],
      generateNo: ['', [Validators.required]],
      description: [],
      mainWorkTypeId: ['', [ValidateNgSelect]]
    });
  }

  OnClose() {
    this.showContent = false;
    this.closeModel.emit();
  }

  LoadList() {
    this.articleList = this._dropdownService.articleList
      ? this._dropdownService.articleList
      : [];

    if (this.articleList) {
      this.articleList = this.articleList.filter(x => x.isActive);
    }

    this.issureList = this._dropdownService.issureList
      ? this._dropdownService.issureList
      : [];

    if (this.issureList) {
      this.issureList = this.issureList.filter(x => x.status);
      this.issureDropdownList = [];
      this.issureDropdownList.push(new DropDownOption('0', this._commonDataService.localizationLabelList['select'], false));
      this.issureList.forEach((element) => {
        const option = new DropDownOption(element.id.toString(), element.description, false);
        this.issureDropdownList.push(option);
      });
      this.issureDropdownList = CommonFunctions.Sort(this.issureDropdownList, 'label');
    }
    this.ivaTypeList = this._dropdownService.ivaTypeList;
    this.cdref.markForCheck();
  }

  async OnOpenAddBudgetModal() {
    this.isEdit = false;
    this.budgetFormGroup.controls.seriesId.enable();
    this.commonUiService.isSpinnerVisible = true;
    this.commonUiService.isPopupOpened = true;
    this.budget = new Budget();
    this.budgetFormGroup.reset();
    this.budget.mainWorktypeId = 0;
    this.deliveryMwtArticleList = [];
    this.budget.generatedDate = this.commonUiService.ConvertToPickerDateFormat(
      new Date().toISOString()
    );
    this.issuer = new Issuer();
    this.client = new Client();
    this.budget.sumPrice = 0;
    this.budget.totalDiscount = 0;
    this.budget.totalFinalPrice = 0;
    this.budget.mainWorktypeId = 0;
    this.budgetFormGroup.controls.mainWorkTypeId.setValue(0);
    this.budget.articleId = '';
    if (this.selectedMainWorkTypeList.length === 1) {
      this.budget.mainWorktypeId = this.selectedMainWorkTypeList[0].id;
      this.OnSelectWorkType();
    }
    this.budget.workTypeName = '';

    const defaultIssuer = this.issureList.find(a => a.isDefault);

    this._apiService.get(API.invoice.getSeriesByIssuerId + defaultIssuer.id)
      .subscribe((list: Series[]) => {
        list = list ? list : [];
        // tslint:disable-next-line: max-line-length
        if (this._modalService.transferable.isEdit) {
          this.seriesList = list.filter(x => x.seriesType.toLowerCase() === 'budget'
            && x.year === (new Date().getFullYear().toString()));
        } else {
          this.seriesList = list.filter(x => x.seriesType.toLowerCase() === 'budget'
            && x.status && x.year === (new Date().getFullYear().toString()));
        }
      }, err => { console.log(err + 'series load error'); });

    if (this.selectedWork.clientId) {
      this._workService
        .GetClientById(this.selectedWork.clientId)
        .then(client => {
          this.client = client;
          this.cdref.markForCheck();
          this._workService
            .GetSeriesByClientId(this.selectedWork.clientId)
            .then((list: ClientSeries[]) => {
              if (list) {
                const series = list.find(
                  x => x.seriesType.toLowerCase() === 'budget'
                );
                if (series) {
                  this.budget.issuerId = series.issuerId ? series.issuerId.toString() : '0';
                  let _seriesId = series != null ? series.seriesId : 0;
                  this.OnChangeBudgetIssuer(_seriesId);
                } else {
                  this.budget.issuerId = (defaultIssuer) ? defaultIssuer.id.toString() : '0';
                  this.budget.seriesId = (this.seriesList.length > 0) ? this.seriesList[0].id : 0;
                  if (this.seriesList.length > 0) {
                    this.OnChangeBudgetIssuer(this.seriesList[0].id);
                  }
                }
              }
            });
        });
    }
    this.cdref.markForCheck();
    this.commonUiService.isSpinnerVisible = false;
  }

  async OnEditBudgetModal(budget: Budget) {
    if (budget) {
      this.isEdit = true;
      this.budgetFormGroup.controls.seriesId.disable();
      this.commonUiService.isSpinnerVisible = true;
      this.commonUiService.isPopupOpened = true;
      this.budget = Object.assign({}, budget);
      this.budget.issuerId = this.budget.issuerId != null ? this.budget.issuerId.toString() : '0';
      this.budget.seriesId = this.budget.seriesId != null ? this.budget.seriesId : 0;
      this.budget.generatedDate = this.commonUiService.ConvertToPickerDateFormat(
        this.budget.generatedDate.substring(0, 10)
      );
      this.budget.validDate = this.budget.validDate
        ? this.commonUiService.ConvertToPickerDateFormat(
          this.budget.validDate.substring(0, 10)
        )
        : null;
      this.budget.workTypeId = this.budget.mainWorktypeId;
      this.budgetFormGroup.controls.mainWorkTypeId.setValue(
        this.budget.workTypeId
      );
      this.budget.articleId = '';
      this.budget.workTypeName = '';
      this.LoadWorkTypeList().then(res => {
        const workTypes: WorkType[] = [];
        this.selectedMainWorkTypeList.forEach(mainWorkType => {
          const type: WorkType = this.workTypeList.find(
            x => x.id === Number(mainWorkType.workTypeId)
          );
          if (type) {
            workTypes.push(type);
          }
        });
        this.workTypeList = workTypes;
      });
      this.deliveryMwtArticleList = [];
      this.ivaBreakdownList = [];
      this.budget.sumPrice = 0;
      this.budget.totalDiscount = 0;
      this.budget.totalFinalPrice = 0;
      this.budget.totalVatAmount = 0;
      if (this.selectedWork.clientId) {
        this._workService
          .GetClientById(this.selectedWork.clientId)
          .then(client => {
            this.client = client;
            this.OnChangeBudgetIssuer(this.budget.seriesId, true);
          });
      }
      if (budget.articleList) {
        budget.articleList.forEach(budget => {
          const mwtArticle: MwtArticle = new MwtArticle();
          mwtArticle.articleId = budget.articleId;
          const article: Article = this.articleList.find(
            x => Number(x.id) === Number(budget.articleId)
          );
          if (article) {
            mwtArticle.description1 = article.description1;
            mwtArticle.code = article.code;
          }
          mwtArticle.quantity = budget.quantity;
          mwtArticle.price = budget.price;
          mwtArticle.finalPrice = budget.finalPrice;
          mwtArticle.discount = budget.discount;
          mwtArticle.waste = budget.waste;
          mwtArticle.ivaTypeId = budget.ivaTypeId;
          mwtArticle.ivaAmount = budget.ivaAmount
          mwtArticle.ivaPercentage = budget.ivaPercentage;

          mwtArticle.quantityWithWaste = budget.quantityWithWaste;
          mwtArticle.priceWithDiscount = budget.priceWithDiscount;
          mwtArticle.discountAmount = budget.discountAmount;
          mwtArticle.lineDiscountAmount = budget.lineDiscountAmount;
          mwtArticle.lineSubTotal = budget.lineSubTotal;

          this.budget.sumPrice = this.budget.subTotal ? this.budget.subTotal : 0;
          this.budget.totalDiscount = this.budget.discount ? this.budget.discount : 0;
          this.budget.totalVatAmount = this.budget.tax ? this.budget.tax : 0;
          this.budget.totalFinalPrice = this.budget.totalPrice ? this.budget.totalPrice : 0;
          this.deliveryMwtArticleList.push(mwtArticle);
        });
        this.loadVatBreakdown(budget.articleList);
      }
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  OnSelectWorkType() {
    if (this.budget.mainWorktypeId) {
      this.isArticleListLoaded = false;
      const workType = this.selectedMainWorkTypeList.find(
        x => Number(x.id) === Number(this.budget.mainWorktypeId)
      );
      if (workType) {
        this.budget.workTypeName = workType.workTypeName;
        this.budget.workTypeId = workType.id;
        this.budgetFormGroup.controls.mainWorkTypeId.setValue(workType.id);
        this.GetBudgetArticleByWorkTypeId(
          workType.workTypeId,
          this.selectedWork.clientId
        ).then((list: MwtArticle[]) => {
          if (list) {
            list.forEach(x => x.mainWorkTypeId = workType.id);
            this.deliveryMwtArticleList = []; // T-fix
            this.deliveryMwtArticleList = Object.assign([], list);
            this.DoMwtArticleCalculations(this.deliveryMwtArticleList);
            this.cdref.markForCheck();
          }
          this.budget.workTypeId = 0;
          this.budget.workTypeName = '';
          this.isArticleListLoaded = true;
        });
      } else {
        this.isArticleListLoaded = true;
      }
    }
    this.cdref.markForCheck();
  }

  private async GetBudgetArticleByWorkTypeId(
    workTypeId: number,
    clientId: number
  ) {
    return this._apiService
      .get(
        API.budget.getBudgetArticleByWorktypeId + workTypeId + '/' + clientId,
        false
      )
      .pipe(map(x => x as MwtArticle[]))
      .toPromise();
  }

  OnKey(event, target) {
    target.className = 'ng-cus-focus';
    target.focus();
    this.cdref.markForCheck();
  }

  public OnChangeBudgetIssuer(seriesId?: number, onLoad?: boolean) {
    if (this.budget.issuerId !== undefined && Number(this.budget.issuerId) !== 0) {
      const issuer: Issuer = this.issureList.find(
        (x, i, a) => Number(x.id) === Number(this.budget.issuerId)
      );
      if (issuer) {
        this.issuer = issuer;
        const year: number = new Date().getFullYear();
        this._workService
          .GetSeriesListByYearAndIssuerId(year.toString(), this.issuer.id)
          .then(list => {
            if (this._modalService.transferable.isEdit) {
              this.seriesList = list.filter(
                x =>
                  x.year === new Date().getFullYear().toString() &&
                  x.seriesType.toLowerCase() === 'budget'
              );
            } else {
              this.seriesList = list.filter(
                x =>
                  x.year === new Date().getFullYear().toString() &&
                  x.seriesType.toLowerCase() === 'budget' &&
                  x.status
              );
            }

            if (seriesId != null && seriesId > 0) {
              this.budget.seriesId = seriesId;
            } else if (this.seriesList != null && this.seriesList.length > 0) {
              this.budget.seriesId = this.seriesList[0].id;
            } else {
              this.budget.seriesId = 0;
            }

            if (onLoad !== true) {
              this.OnSelectBudgetSeries();
            }
            this.cdref.markForCheck();
          });
      }
      else {
        this.budget.generateNo = '';
        this.cdref.markForCheck();
      }
    }
    else {
      this.budget.generateNo = '';
    }
    this.cdref.markForCheck();
  }

  async OnSelectBudgetSeries() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (this.budget.seriesId) {
        const series = await this._workService.GetSeriesById(
          this.budget.seriesId
        );
        if (series) {
          await this.LoadBuNumberingProcessComponent(series);
          const genNumberObject = this._numberingProcessService.GetGeneratedNumber(
            this.buNumberingProcess,
            this.buMaxSize,
            { id: series.id, type: 'Budget', series: series.series }
          );
          if (genNumberObject) {
            this.budget.generateNo = genNumberObject.generateNumber;
            this.currentNPData = genNumberObject as NumberingProcessData;
          }
        }
        else {
          this.budget.generateNo = '';
          this.cdref.markForCheck();
        }
      } else {
        this.budget.generateNo = '';
        this.cdref.markForCheck();
      }
      this.cdref.markForCheck();
    } catch (error) {
      console.log(error);
    } finally {
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  private async LoadBuNumberingProcessComponent(series: Series) {
    try {
      if (series) {
        this.buSeries = {
          id: series.id,
          type: 'Budget',
          series: series.series
        };
        this.buNumberingProcess = new NumberingProcess({
          isNextAvailableNumber: true,
          nextAvailableNumber: series.lastNumber,
          isAnother: false,
          isNoNumberAssigned: false,
          isOtherAvailableNumbers: false,
          anotherNumber: 1,
          otherAvailableNumber: 0
        });
        const reservedNumberList = await this._workService.GetSavedNumberListBySeriesId(
          series.id
        );
        if (reservedNumberList) {
          this.buNumberingProcess.otherAvailableNumberList = reservedNumberList.map(
            r => r.savedNumber.toString()
          );
        }
        this.cdref.markForCheck();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async OnSelectBudgetArticle(article: Article) {
    if (article.id) {
      this.mwtArticle = new MwtArticle();
      this.mwtArticle.articleId = Number(article.id);
      this.mwtArticle.id = 0;
      this.mwtArticle.status = true;
      this.mwtArticle.mainWorkTypeId = 0;
      this.mwtArticle.quantity = 1;
      this.mwtArticle.price = 0;
      this.mwtArticle.waste = 0;
      this.mwtArticle.discount = 0;
      this.mwtArticle.quantityWithWaste = 0;
      this.mwtArticle.priceWithDiscount = 0;
      this.mwtArticle.ivaTypeId = article.ivaTypeId;
      await this.OnSelectArticle();
      this.budget.articleId = article.description1;
      this.mwtArticle.code = article.code;
      const index = this.deliveryMwtArticleList.findIndex(a => a.articleId === this.mwtArticle.articleId);
      if (index > -1) {
        this.deliveryMwtArticleList[index] = this.mwtArticle; // update relevent object in the list
      } else {
        this.deliveryMwtArticleList.push(Object.assign({}, this.mwtArticle));
      }
      this.cdref.markForCheck();
    }
    this.DoMwtArticleCalculations(this.deliveryMwtArticleList);
  }

  async OnSelectArticle() {
    if (this.mwtArticle.articleId) {
      if (this.selectedWork.clientId) {
        const mwtArticle = await this.GetMwtArticlePriceDetails(
          this.mwtArticle.articleId,
          this.selectedWork.clientId
        );
        if (mwtArticle) {
          if (mwtArticle.price !== null) {
            this.mwtArticle.price = mwtArticle.price;
          }
          if (mwtArticle.discount !== null) {
            this.mwtArticle.discount = mwtArticle.discount;
          }
          if (mwtArticle.isAmount !== null) {
            this.mwtArticle.isAmount = mwtArticle.isAmount;
          }
          if (mwtArticle.discountAmount !== null) {
            this.mwtArticle.discountAmount = mwtArticle.discountAmount;
          }
          if (mwtArticle.waste !== null) {
            this.mwtArticle.waste = mwtArticle.waste;
          }
          if (this.mwtArticle.ivaTypeId == null || this.mwtArticle.ivaTypeId == undefined) {
            this.mwtArticle.ivaTypeId = null;
            this.mwtArticle.ivaAmount = 0;
            this.mwtArticle.ivaPercentage = null;
          } else {
            const ivaObject = this.ivaTypeList.filter(x => x.id == this.mwtArticle.ivaTypeId);
            if (ivaObject && ivaObject.length > 0) {
              this.mwtArticle.ivaAmount = ivaObject[0].iva;
              this.mwtArticle.ivaPercentage = ivaObject[0].iva;
            }
          }
          this.cdref.markForCheck();
          this.OnKeyUpQuantityOrWaste();
          this.OnKeyUpPriceOrDiscount();
          this.OnChangeQtyWithWasteOrPriceWithDiscount();
        }
        const article: Article = this.articleList.find(
          (x, i, a) => Number(x.id) === Number(this.mwtArticle.articleId)
        );
        if (article) {
          this.mwtArticle.description1 = article.description1;
        }
      }
    } else {
      this.mwtArticle.description1 = '';
    }
    this.cdref.markForCheck();
  }

  private DoMwtArticleCalculations(list: MwtArticle[]) {
    this.ivaBreakdownList = [];
    this.budget.sumPrice = 0;
    this.budget.totalDiscount = 0;
    this.budget.totalFinalPrice = 0;
    this.budget.totalVatAmount = 0;
    this.budget.totalWithDiscount = 0;
    this.budget.subTotal = 0;
    if (list) {
      list.forEach((mwtArticle: MwtArticle) => {
        mwtArticle.quantityWithWaste = this.GetQuantityWithWaste(mwtArticle);
        mwtArticle.priceWithDiscount = this.GetPriceWithDiscount(mwtArticle);
        mwtArticle.ivaAmount = 0;
        let finalPriceWithoutIva = this.getFinalPriceWithoutIva(mwtArticle);
        if (mwtArticle.ivaTypeId) {
          mwtArticle.ivaAmount = this._ivaCalculationService.getIvaAmount(finalPriceWithoutIva, mwtArticle.ivaPercentage ? mwtArticle.ivaPercentage : 0);
          mwtArticle.ivaAmount = mwtArticle.ivaAmount ? toFixedNumber(mwtArticle.ivaAmount) : mwtArticle.ivaAmount;
        }
        mwtArticle.finalPrice = this.GetFinalPrice(mwtArticle);
        mwtArticle.discountAmount = this._calculationService.getDiscountAmount(mwtArticle.price, mwtArticle.discount);
        mwtArticle.lineSubTotal = this.GetLineSubTotal(mwtArticle.price, mwtArticle.quantityWithWaste);
        mwtArticle.lineDiscountAmount = this.GetLineDiscountAmount(mwtArticle.finalPrice, mwtArticle.ivaAmount, mwtArticle.lineSubTotal);

        this.budget.sumPrice = this.budget.sumPrice + mwtArticle.lineSubTotal;
        this.budget.totalDiscount = this.budget.totalDiscount + mwtArticle.lineDiscountAmount;
        this.budget.sumPrice = this.budget.sumPrice ? toFixedNumber(this.budget.sumPrice) : 0;
        this.budget.subTotal = this.budget.sumPrice ? toFixedNumber(this.budget.sumPrice) : 0;
        this.budget.totalDiscount = this.budget.totalDiscount ? toFixedNumber(this.budget.totalDiscount) : 0;
        this.budget.totalWithDiscount = this._deliveryNoteCalculationService.getTotalWithDiscountAmount(this.budget.subTotal, this.budget.totalDiscount);
        this.budget.totalVatAmount = this.budget.totalVatAmount ? toFixedNumber(this.budget.totalVatAmount) : 0;
        this.budget.totalVatAmount = toFixedNumber(this.budget.totalVatAmount + (mwtArticle.ivaAmount ? mwtArticle.ivaAmount : 0));
        this.budget.tax = toFixedNumber(this.budget.totalVatAmount);
        this.budget.discount = toFixedNumber(this.budget.totalDiscount);
        this.budget.totalFinalPrice = toFixedNumber((this.budget.sumPrice - this.budget.totalDiscount + this.budget.totalVatAmount));
      });
      this.loadVatBreakdown(list);
    }
    this.cdref.markForCheck();
  }

  private getFinalPriceWithoutIva(mwtArticle: MwtArticle) {
    if (mwtArticle.priceWithDiscount !== undefined && mwtArticle.quantityWithWaste !== undefined) {
      return toFixedNumber((mwtArticle.priceWithDiscount * mwtArticle.quantityWithWaste));
    }
  }

  loadVatBreakdown(budgetArticleList: any[]) {
    this.ivaBreakdownList = [];
    if (budgetArticleList) {
      budgetArticleList.forEach(article => {
        if (article.ivaTypeId && article.ivaTypeId != 0) {
          const ivaBreakdown = new IvaBreakdown();
          ivaBreakdown.ivaTypeId = article.ivaTypeId;
          ivaBreakdown.iva = article.ivaPercentage + '%';
          ivaBreakdown.base = this._deliveryNoteCalculationService.getIvaBaseAmount(article.priceWithDiscount, article.quantityWithWaste);
          ivaBreakdown.ivaAmount = toFixedNumber(article.ivaAmount);
          this.ivaBreakdownList.push(ivaBreakdown);
        }
      });
      if (this.ivaBreakdownList && this.ivaBreakdownList.length > 0) {
        this.ivaBreakdownList = this._deliverynoteService.LoadVatBreakdown(this.ivaBreakdownList);
      }
    }
  }

  private async GetMwtArticlePriceDetails(
    articleId: number,
    clientId: number
  ): Promise<MwtArticle> {
    return this._apiService
      .get(
        API.main_work_type_work.getMwtArticlePriceDetails +
        articleId +
        '/' +
        clientId
      )
      .toPromise();
  }

  private OnKeyUpQuantityOrWaste() {
    if (
      this.mwtArticle.waste !== undefined &&
      this.mwtArticle.quantity !== undefined
    ) {
      this.mwtArticle.quantityWithWaste = Number(
        (
          (this.mwtArticle.quantity * (this.mwtArticle.waste + 100)) /
          100
        ).toFixed(2)
      );
    }
    this.cdref.markForCheck();
  }

  private OnKeyUpPriceOrDiscount() {
    if (
      this.mwtArticle.price !== undefined &&
      this.mwtArticle.discount !== undefined
    ) {
      this.mwtArticle.priceWithDiscount = Number(
        (
          (this.mwtArticle.price * (100 - this.mwtArticle.discount)) /
          100
        ).toFixed(2)
      );
    }
    this.cdref.markForCheck();
  }

  private OnChangeQtyWithWasteOrPriceWithDiscount() {
    if (
      this.mwtArticle.priceWithDiscount !== undefined &&
      this.mwtArticle.quantityWithWaste !== undefined
    ) {
      this.mwtArticle.finalPrice = Number(
        (
          this.mwtArticle.priceWithDiscount * this.mwtArticle.quantityWithWaste
        ).toFixed(2)
      );
    }
    this.cdref.markForCheck();
  }

  private OnConvertBudgetToDeliveryNote(event: any, modal: string) {
    this.showContent = false;
    this.budget.mainWorktypeId = 0;
    if (this.budget.articleList) {
      this.budget.articleList.forEach(item => {
        item.id = 0;
      });
    }
    this.transferable.isEdit = true;
    this.transferable.budget = this.budget;
    this.transferable.deliveryMwtArticleList = this.deliveryMwtArticleList;
    this.cdref.markForCheck();
    this._modalService.Invoke(modal, true, this.transferable);
  }

  private OnEditBudgetMwtArticleModal(modal: string, article: MwtArticle) {
    if (modal && article) {
      this.mwtArticle = Object.assign(this.mwtArticle, article);
      // this.OnKeyUpQuantityOrWaste();
      // this.OnKeyUpPriceOrDiscount();
      // this.OnChangeQtyWithWasteOrPriceWithDiscount();
      this.commonUiService.isPopupOpened = true;
      this.cdref.markForCheck();
      this.commonUiService.openModal(modal);
    }
  }

  private OnSaveBudget(event: any) {
    if (this.budgetFormGroup.invalid) {
      ShowInvalidFormControls(this.budgetFormGroup);
      return;
    }
    this.commonUiService.isSpinnerVisible = true;
    const budgetArticleList: BudgetArticle[] = [];
    try {
      if (this.budget) {
        this.commonUiService.isSpinnerVisible = true;
        this.budget.description = this.budget.description
          ? this.budget.description
          : '';
        this.budget.generatedDate = this.budget.generatedDate
          ? this.commonUiService.ChangeNgbDateToCustomFormat(
            this.budget.generatedDate
          )
          : null;
        this.budget.validDate = this.budget.validDate
          ? this.commonUiService.ChangeNgbDateToCustomFormat(
            this.budget.validDate
          )
          : null;
        this.budget.totalPrice = 0;
        if (!this.budget.id) {
          this.budget.createdDate = new Date().toISOString();
          this.budget.status = true;
        }
        if (this.deliveryMwtArticleList) {
          this.deliveryMwtArticleList.forEach(mwtArticle => {
            const budgetArticle = new BudgetArticle();
            budgetArticle.budgetId = this.budget.id ? this.budget.id : 0;
            budgetArticle.articleId = Number(mwtArticle.articleId);
            budgetArticle.status = true;
            budgetArticle.quantity = mwtArticle.quantity;
            budgetArticle.waste = mwtArticle.waste;
            budgetArticle.price = mwtArticle.price;
            budgetArticle.finalPrice = mwtArticle.finalPrice;
            budgetArticle.discount = mwtArticle.discount;
            budgetArticle.ivaTypeId = mwtArticle.ivaTypeId;
            budgetArticle.ivaAmount = mwtArticle.ivaAmount;
            budgetArticle.ivaPercentage = mwtArticle.ivaPercentage;

            budgetArticle.quantityWithWaste = mwtArticle.quantityWithWaste;
            budgetArticle.priceWithDiscount = mwtArticle.priceWithDiscount;
            budgetArticle.discountAmount = mwtArticle.discountAmount;
            budgetArticle.lineDiscountAmount = mwtArticle.lineDiscountAmount;
            budgetArticle.lineSubTotal = mwtArticle.lineSubTotal;
            budgetArticleList.push(budgetArticle);
          });
        }
        this.budget.seriesId = this.budget.seriesId
          ? Number(this.budget.seriesId)
          : null;
        this.budget.issuerId = this.budget.issuerId
          ? this.budget.issuerId
          : null;
        this.budget.totalPrice = toFixedNumber(this.budget.totalFinalPrice);
        this.budget.articleList = budgetArticleList;
      }
      this.SaveBudget(this.budget).then(response => {
        const body = JSON.parse(response['_body']);
        if (body) {
          if (body['_isSuccsess']) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['budget'],
              msg: this._commonDataService.localizationLabelList['save_success']
            });
            this.OnClose();
            this.commonUiService.isSpinnerVisible = false;
            this._modalService.Invoke(ModalNames.BudgetModal, false, undefined);
            //this.SyncBudgetData();
            if (this.generateNumberModel) {
              if (this.generateNumberModel.isNextAvailableNumber) {
                this._workService.RemoveSavedGeneratedNumberBySeriesIdAndSavedNumber(
                  this.usedSeries.id,
                  this.usedSeries.lastNumber
                );
                this.usedSeries.lastNumber++;
                this._workService.UpdateSeries(this.usedSeries);
              } else {
                if (this.generateNumberModel.isAnother) {
                  this._workService.RemoveSavedGeneratedNumberBySeriesIdAndSavedNumber(
                    this.usedSeries.id,
                    this.generateNumberModel.anotherNumber
                  );
                  if (
                    this.generateNumberModel.anotherNumber >
                    this.generateNumberModel.nextAvailableNumber
                  ) {
                    let start: number = this.generateNumberModel
                      .nextAvailableNumber;
                    const end: number = this.generateNumberModel.anotherNumber;
                    const savedGeneratedNumberList: SavedGeneratedNumber[] = [];
                    for (start; start < end; start++) {
                      const savedGeneratedNumber: SavedGeneratedNumber = new SavedGeneratedNumber();
                      savedGeneratedNumber.seriesId = this.usedSeries.id;
                      savedGeneratedNumber.savedNumber = start;
                      if (
                        !this.savedGeneratedNumberList.some(
                          x =>
                            x.seriesId === savedGeneratedNumber.seriesId &&
                            x.savedNumber === savedGeneratedNumber.savedNumber
                        )
                      ) {
                        savedGeneratedNumberList.push(savedGeneratedNumber);
                      }
                    }
                    if (savedGeneratedNumberList.length > 0) {
                      this._workService.SaveAsSavedGeneratedNumberList(
                        savedGeneratedNumberList
                      );
                    }
                  }
                }
              }
            }
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['budget'],
              msg: this._commonDataService.localizationLabelList['save_error']
            });
          }
        }
        this.cdref.markForCheck();
        this.OnClose();
        this.commonUiService.isSpinnerVisible = false;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.OnClose();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  private async SaveBudget(budget: Budget) {
    return this._apiService
      .post(API.budget.saveBudget, budget, false)
      .toPromise();
  }

  private OnRemoveBudgetArticle(article: MwtArticle) {
    if (article && this.deliveryMwtArticleList) {
      swal({
        title: this._commonDataService.localizationLabelList['budget_article'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList[
          'common_yes'
        ],
        cancelButtonText: this._commonDataService.localizationLabelList[
          'common_cancel'
        ]
      }).then(result => {
        if (result.value) {
          const index: number = this.deliveryMwtArticleList.findIndex(
            x => Number(x.articleId) === Number(article.articleId)
          );
          if (index !== -1) {
            this.deliveryMwtArticleList.splice(index, 1);
          }
          this.DoMwtArticleCalculations(this.deliveryMwtArticleList);
        }
      });
    }
    this.cdref.markForCheck();
  }

  private async LoadWorkTypeList(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._apiService.get(API.work_type.getWorkTypeList, true).subscribe(
        (list: WorkType[]) => {
          this.workTypeList = list;
          resolve();
        },
        err => reject(err)
      );
    });
  }

  private OnEditBudgetArticle(article: MwtArticle, event: any, index) {
    if (article) {
      this.selectedArticleIndex = index;
      this.commonUiService.isPopupOpened = true;
      this.selectedArticle = article;
      this.selectedArticleEditObj = {};
      this.selectedArticleEditObj.selectedArticle = {};
      this.selectedArticleEditObj.selectedMainWorkTypeList = [];
      this.selectedArticleEditObj.selectedArticle = this.selectedArticle;
      this.selectedArticleEditObj.selectedMainWorkTypeList = this.selectedMainWorkTypeList;
      this.selectedArticleEditObj.selectedWork = this.selectedWork;
      this.cdref.markForCheck();
      this.showContent = false;
      this._modalService.Invoke(
        ModalNames.ArticleModal2,
        true,
        this.selectedArticleEditObj
      );
    }
  }

  OnSaveBudgetArticle(mwtArticle) {
    this.showContent = true;
    if (mwtArticle != null) {
      let article = this.deliveryMwtArticleList[this.selectedArticleIndex];
      article = Object.assign(article, mwtArticle);
      this.cdref.markForCheck();
      this.DoMwtArticleCalculations(this.deliveryMwtArticleList);
    }
  }

  OnBudjetMainWorkTypeModalClose() {
    this.cdref.markForCheck();
    this.showContent = true;
  }

  //#region budget calculation modification

  private GetQuantityWithWaste(mwtArticle: MwtArticle) {
    if (mwtArticle.waste !== undefined && mwtArticle.quantity !== undefined) {
      mwtArticle.quantity = Number(mwtArticle.quantity);
      return this._calculationService
        .getQuantityWithWaste(toFixedNumber(mwtArticle.quantity),
          toFixedNumber(mwtArticle.waste));
    }
  }

  private GetPriceWithDiscount(mwtArticle: MwtArticle) {
    if (mwtArticle.price !== undefined && mwtArticle.discount !== undefined) {
      return this._calculationService
        .getPriceWithDiscount(toFixedNumber(mwtArticle.price),
          mwtArticle.isAmount ? toFixedNumber(mwtArticle.discountAmount)
            : toFixedNumber(mwtArticle.discount),
          mwtArticle.isAmount);
    }
  }

  private GetFinalPrice(mwtArticle: MwtArticle) {
    if (mwtArticle.priceWithDiscount !== undefined && mwtArticle.quantityWithWaste !== undefined) {
      return this._calculationService.getArticleFinalTotal(toFixedNumber(mwtArticle.quantityWithWaste),
        toFixedNumber(mwtArticle.priceWithDiscount), toFixedNumber(mwtArticle.ivaAmount));
    }
  }

  private GetLineDiscountAmount(finalTotal: number, vatAmount: number, subTotal: number) {
    try {
      return toFixedNumber(subTotal - (finalTotal - vatAmount));
    } catch (error) { console.log(error); }
  }

  private GetLineSubTotal(price: number, quantityWithWaste: number) {
    try {
      return this._calculationService
        .getLineSubTotal(price, quantityWithWaste);
    } catch (error) { console.log(error); }
  }

  //#endregion
}
