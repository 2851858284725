
export class DeliveryNoteListChangedSubjectArgs {
    recentlySavedInfo: {
        invoiceIssuerId: number;
        invoiceIssuerName: string;
        invoiceSeriesId: number;
        invoiceSeriesName: string;
        toPatient: boolean;
        patientId: number; // previous patient
        clientId: number;
        workId: number;
    };

    constructor(init?: Partial<DeliveryNoteListChangedSubjectArgs>) {
        if (init) { Object.assign(this, init); }
    }
}
