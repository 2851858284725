export class StatesOfWork {
    id: number;
    description: string;
    finished: boolean;
    noFee: boolean;
    code: string;
    defaultVal: boolean;
    status: boolean;
    isEdit: boolean;

    constructor() {
        this.status = true;
    }
}
