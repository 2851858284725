import { Component, OnInit, Input } from '@angular/core';
import { Town } from '../../modules/auxiliary/entities/town';
import { AuxiliaryService } from '../../modules/auxiliary/services/auxiliary.service';
import { Toast } from '../../modules/common/services/toast.service';
import { CommonDataService } from '../../modules/common/services/common.data.service';

@Component({
  selector: 'app-edit-postcode',
  templateUrl: './edit-postcode.component.html',
  styleUrls: ['./edit-postcode.component.scss']
})
export class EditPostcodeComponent implements OnInit {

  @Input() town: Town;

  constructor(public _auxiliaryService: AuxiliaryService,
    private _toastyService: Toast,
    private _commonDataService: CommonDataService) {
    this.town = new Town();
  }

  ngOnInit() {
  }

  onUpdateTown(event) {
    if (this.town && this.town.id && this.town.postcode && this.town.postcode.length <= 10) {
      this._auxiliaryService.UpdateTown(this.town.id, this.town).then(o => {
        this.closeModal(event);
        this._toastyService.success({
          title: this._commonDataService.localizationLabelList['town'],
          msg: this._commonDataService.localizationLabelList['update_success']
        });
        this._auxiliaryService.onUpdateTown.emit({ pc: this.town.postcode, description: this.town.description, model: this.town.model });
      });
    } else {
      console.log('town not exist');
    }
  }

  closeModal(event: any) {
    ((((event.target.parentElement.parentElement).parentElement).parentElement).parentElement).classList.remove('md-show');
    if (document.querySelector('#add-edit-client')) {
      document.querySelector('#add-edit-client').classList.remove('client-modal--index');
    }
    if (document.querySelector('#add-edit-physical-data')) {
      document.querySelector('#add-edit-physical-data').classList.remove('client-modal--index');
    }
    if (document.querySelector('#issuer-modal')) {
      document.querySelector('#issuer-modal').classList.remove('issuer-modal--index');
    }
    if (document.querySelector('#add-edit-provider-modal')) {
      document.querySelector('#add-edit-provider-modal').classList.remove('add-edit-provider-modal--index');
    }
    if (document.querySelector('#provider-address-information-modal')) {
      document.querySelector('#provider-address-information-modal').classList.remove('provider-address-information-modal--index');
    }
    if (document.querySelector('#worker-modal')) {
      document.querySelector('#worker-modal').classList.remove('worker-modal--index');
    }
    if (document.querySelector('#worker-address-modal')) {
      document.querySelector('#worker-address-modal').classList.remove('worker-modal--index');
    }
    if (document.querySelector('#add-edit-direct-debit')) {
      document.querySelector('#add-edit-direct-debit').classList.remove('add-edit-direct-debit--index');
    }
    if (document.querySelector('#add-edit-patient')) {
      document.querySelector('#add-edit-patient').classList.remove('patient-modal--index');
    }
    if (document.querySelector('#bankdetails-add-edit-modal')) {
      document.querySelector('#bankdetails-add-edit-modal').classList.remove('bank-modal--index');
    }
    if (document.querySelector('#delivery-modal-editPatient')) {
      document.querySelector('#delivery-modal-editPatient').classList.remove('delivery-modal-editPatient--index');
    }
    if (document.querySelector('#patient-modal')) {
      document.querySelector('#patient-modal').classList.remove('work-modal--index');
    }
    if (document.querySelector('#dentbox-add-patient-modal')) {
      document.querySelector('#dentbox-add-patient-modal').classList.remove('set-index-dentbox-pc');
    }
    if (document.querySelector('#messenger-modal')) {
      document.querySelector('#messenger-modal').classList.remove('set-index-courier-pc');
    }
    if (document.querySelector('#add-external-center-modal')) {
      document.querySelector('#add-external-center-modal').classList.remove('external-center-modal--index');
    }
  }

  OpenModal(town: Town) {
    this.town = town;
    document.querySelector('#add-client-pc').classList.add('md-show');
  }
}
