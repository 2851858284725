import { Injectable } from '@angular/core';
import { toFixedNumber } from './calculation';

@Injectable({
  providedIn: 'root'
})
export class CalculationService {

  constructor() { }

  /**
    * discount amount = price * discount / 100
    * @param price 
    * @param discountPercentage
    */
  getDiscountAmount(price: number, discountPercentage: number) {
    return toFixedNumber(price * discountPercentage / 100);
  }

  /**
    * iva amount = final amount with discount * iva percentage / 100
    * @param finalAmountWithDiscount 
    * @param ivaPercentage
    */
  getIvaAmount(finalAmountWithDiscount: number, ivaPercentage: number) {
    return toFixedNumber(finalAmountWithDiscount * (ivaPercentage / 100));
  }

  /**
    * iva Base amount = price with dicount * quantity with waste
    * @param priceWithDiscount 
    * @param quantityWithWaste
    */
   getIvaBaseAmount(priceWithDiscount: number, quantityWithWaste: number) {
    return toFixedNumber(priceWithDiscount * quantityWithWaste);
  }
}

