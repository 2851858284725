import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalNames } from 'src/app/modules/common/global';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { MWTExternalDataDocuments } from '../../entities/main-work-type-external-data-documents';

@Component({
  selector: 'app-app-external-doc-view',
  templateUrl: './app-external-doc-view.component.html',
  styleUrls: ['./app-external-doc-view.component.scss']
})
export class AppExternalDocViewComponent implements OnInit {

  showContent = false;
  showPopUp = false;

  document: MWTExternalDataDocuments;

  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  constructor(private _modalService: ModalService,) {
    this.showContent = true;
  }

  ngOnInit() {
    if (this._modalService.modal === ModalNames.ExternalDocumentModal) {
      if (this._modalService.transferable && this._modalService.transferable.document) {
        this.document = this._modalService.transferable.document;
      }
    }
  }

  OnOpenModal(modal: string) {
    this.showPopUp = true;
    this._modalService.Invoke(modal, true, {
      isEdit: false,
    }, false);
  }

  onClose() {
    this.closeModel.emit();
  }

  onDownload(document: MWTExternalDataDocuments) {
    if (document) {
      window.open(document.link, '_blank');
    }
  }

}
