import { Injectable } from '@angular/core';
import { WorkTypePhaseTemplate } from '../../work-type/entities/work-type-model-templates';
import { BehaviorSubject } from 'rxjs';
import { CommonFunctions } from '../../common/services/common-functions';
import API from './../../common/api.config.json';
import { ApiService } from '../../common/services/api.service';
import { MwtPhase } from '../entities/main-work-type-phase';
import { WorkerTopes } from '../../workers/entities/worker-topes';
import { TaskPhase } from '../../tasks/entities/task-phase';

@Injectable({
  providedIn: 'root'
})
export class PhaseTemplateService {

  private subject: BehaviorSubject<WorkTypePhaseTemplate[]> = new BehaviorSubject<WorkTypePhaseTemplate[]>([]);
  phaseTemplateList = this.subject.asObservable();

  constructor(private _apiService: ApiService) { }

  SetPhaseTemplateList(phaseTemplateList: WorkTypePhaseTemplate[]): void { this.subject.next(phaseTemplateList); }

  GetPhaseTemplateListByMainWorkTypeIdList(idList: { id: number }[]) {
    try {
      const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
      if (params) {
        const query = params.toString().replace('+', '%20');
        return this._apiService.get(API.work_panels.getPhaseTemplateListByMainWorkTypeIdList + query, true)
          .map(p => p as WorkTypePhaseTemplate[]).toPromise();
      }
    } catch (error) { console.log(error); }
  }

  SaveMwtPhase(mwtPhase: MwtPhase) {
    return this._apiService
      .post(API.main_work_type_work.saveMwtPhase, mwtPhase, false, true)
      .toPromise();
  }

  CheckDailyTaskHoursLimitForWorker(workerId: number, date: string, newValue: number, mwtPhaseTaskId?: number) {
    return this._apiService
      .get(API.main_work_type_work.checkDailyTaskHoursLimitForWorker + workerId + '/' + date + '/' + newValue + '/' + mwtPhaseTaskId)
      .map(result => result as { result: string, tope: WorkerTopes }).toPromise();
  }

  UpdateWorkerTopes(id: number, workerTopes: WorkerTopes) {
    return this._apiService.put(API.worker.updateWorkerTopes + id, workerTopes, false).toPromise();
  }

  GetTaskPhaseListByTaskId(id: number) {
    return this._apiService.get(API.task_phase.getTaskPhaseList + id)
      .map(list => list as TaskPhase[]).toPromise();
  }

}
