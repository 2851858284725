import { SubWork } from "./sub-work";

export class Work {
  id: number;
  clientId?: any;
  patientId?: any;
  patientIdList: any[];
  clientIdList: any[];
  name: string;
  workCode?: number; // this should be a string
  status: boolean;
  patientName: string;
  // expand list properties
  receiveDate: any;
  deliveryDate: any;
  realDeliveryDate: any;
  clientName: string;
  labSectionId: number;
  labSectionName: string;
  doctorId?: any;
  doctorIdList: any[];
  doctorName: string;
  mainWorkTypeId: number;
  mainWorkTypeName: string;
  referrer: string;
  workStatus: any;
  generateNo: string;
  generatedNoList: string[] = [];
  manualStatus: boolean;
  mainWorkTypeGenerateNo: string;
  lastPrescriptionDate: Date;

  lastPrescriptionId: number;
  lastSubWork: SubWork;

  // excel export properties
  clientValue: number;
  labSectionValue: number;
  workTypeValue: number;
  doctorValue: number;
  fromDate: string;
  toDate: string;
  dateTypeId: any;

  searchReferrer: string;
  receivedFromDate: string;
  receivedToDate: string;
  deliveryFromDate: string;
  deliveryToDate: string;
  observations: string;
  alerts: string;

  isDentboxWork: boolean;
  historyNumber: string;
  generatedWorkNo: string;
}
