export class WorkConfig {
    FASES: boolean;
    TASKS: boolean;
    ARTICLES: boolean;
    PRODUCTS: boolean;
    DELIVERY_NOTES: boolean;
    CLINIC_MATERIALS: boolean;
    BUDGETS: boolean;
    WORK_TYPES: boolean;
    COLORS: boolean;
    IMPLANT_DATA: boolean;
    ESTABLISH_FINISHED_WORK_WHEN_MAKING_DELIVERY_NOTES: boolean;
    EXTERNAL_WORK: boolean;
    RECORD_OF_INCIDENTS: boolean;
}
