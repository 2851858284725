import { InvoiceDetail } from './invoice-detail';

export class DisplayDeliveryNoteViewModel {
    deliveryNoteId: number;
    deliveryNoteGenNo: string;
    deliveryNoteConcept: string;
    deliveryNoteGenDate: string;
    deliveryNoteIssuerId: number;
    deliveryNoteSeriesId: number;
    finalPrice: number;
    workId: number;
    invoiceId: number;
    isInvoiced: boolean;

    constructor(init?: Partial<DisplayDeliveryNoteViewModel>) {
        Object.assign(this, init);
    }
}

export class InvoiceDeliveryNoteViewModel {
    deliveryNoteGenNo: string;
    deliveryNoteId: number;
    isChecked: boolean;
    isCollapsed: boolean;
    isVisible: boolean;
    isInvoiced: boolean;
    deliveryNoteConcept: string;
    deliveryNoteIssuerId: number;
    deliveryNoteSeriesId: number;
    deliveryNoteGenDate: string;
    deliveryNoteGenDateObject: any;
    deliveryNoteTotalPrice: number;
    deliveryNoteTax: number;
    totalUnitPrice: number;
    totalQuantity: number;
    totalDiscount: number;
    workId: number;
    invoiceId: number;

    invoiceDetailList: InvoiceDetail[];

    constructor(init?: Partial<InvoiceDeliveryNoteViewModel>) {
        Object.assign(this, init);
    }
}
