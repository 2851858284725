export class ConformityReport {
    issuerName: string;
    issuerDireccion: string;
    townName: string;
    provinceName: string;
    cp: string;
    responsiblePerson: string;
    licenseNo: string;
    today: string;
    doctorName: string;
    doctorLicenseNo: string;
    defaultClinicName: string;
    patientName: string;
    deliveryNoteNos: string;
    mainWorkType: string;
    therapeuticPurposeName: string;
    productDescriptionName: string;
    deliveryDate?: any;    
    // used while showing multiple issuers
    count: any;  
    fullAddress: any; 
}