import API from '../../common/api.config.json';
import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import { WorkersAttendanceDetail } from '../entities/worker-attendance-detail';
import { CommonFunctions } from '../../common/services/common-functions';

@Injectable({
  providedIn: 'root'
})
export class AttendanceRecordService {

  constructor(private _apiService: ApiService) { }

  /**
  * get workers attendance data list for month/year
  */
  GetWorkersAttendanceDataList(idList: { workerIds: number }[], month: number, year: number) {
    const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
    if (params) {
      const query = params.toString().replace('+', '%20');
      return this._apiService
        .get(API.worker.getWorkersAttendanceDataList + query + '&month=' + month + '&year=' + year, true)
        .map(list => list as WorkersAttendanceDetail[]).toPromise();
    }
  }

  /**
  * get workers attendance data list in excel file
  */
  ExportAttendenceExcelList(idList: { workerIds: number }[], month: number, year: number) {
    const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
    if (params) {
      const query = params.toString().replace('+', '%20');
      var url = this._apiService.BASE_URL + `/api/WorkerAttendance/ExportWorkersAttendenceList?${query}&month=${month}&year=${year}`;
      window.open(url, '_blank');
    }
  }

  /**
  * update log out in worker attendence
  */
  UpdateWorkerLogOut(workerId: number, workDate: any, endTime: string) {
    return this._apiService
      .get(API.worker.updateWorkerAttendenceLogout + workerId + '/' + workDate + '/' + endTime, false)
      .map(saved => saved as boolean).toPromise();
  }

}
