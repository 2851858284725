import { Injectable } from '@angular/core';
import { Article } from '../../articles/entities/article';
import { ClinicMaterial } from '../../auxiliary/entities/clinic-material';
import { Color } from '../../auxiliary/entities/Color';
import { Issuer } from '../../auxiliary/entities/issuer';
import { Localizations } from '../../auxiliary/entities/localizations';
import { Metals } from '../../auxiliary/entities/metals';
import { ShippingForms } from '../../auxiliary/entities/shipping-forms';
import { StatesOfWork } from '../../auxiliary/entities/status-of-work';
import { SubscriptionReason } from '../../auxiliary/entities/subscription-reason.model';
import { AuxiliaryService } from '../../auxiliary/services/auxiliary.service';
import { Client } from '../../client/entities/client';
import {
  ArticleExpandDateList, AuxiliaryNumTypeList, DeliveryList,
  DropdownNames, GenderList, InvoiceList,
  JobSearchDateTypeList, JobSearchPriorityList, MonthList,
  MovementTypesList, MovementTypesList2, OrderReceptionStatusList, OrderStatusList,
  OrderStatusList2, PeriodList, PhaseStatusList, PhaseStatusList2, ProductInventoryFrequencyList, ReceiptList,
  TaskStatusList, UrgencyTypeList, WorkStateList, ReceiptGenerateTypeList, WarehouseStockStatus, ExternalShipmentStatus, NoteTypeList, InvoiceStatusList, IncidentTypeList
} from '../../common/global';
import { ExternalCenter } from '../../external-shipment/entities/external-center';
import { Messengers } from '../../messaging/entities/messenger';
import { MessagingService } from '../../messaging/services/messaging.service';
import { Product } from '../../products/entities/product';
import { WorkType } from '../../work-type/entities/work-type';
import { LabSection } from '../../work/entities/lab-section';
import { Patient } from '../../work/entities/patient';
import { Task } from '../../work/entities/task';
// import { Town } from '../../work/entities/town';
import { ExternalWorkService } from '../../work/services/external-work.service';
import { MwtWorkerService } from '../../work/services/mwt-worker.service';
import { WorkService } from '../../work/services/work.service';
import { Worker } from '../../workers/entities/worker';
import { DropdownOption } from '../dropdown-option';
import { DropDownOption } from '../entities/dropdown-option';
import { ListNames, WorkTypeExpandDateList } from '../global';
import { ApiService } from './api.service';
import API from '../api.config.json';
import { CommonDataService } from './common.data.service';
import { Town } from '../../auxiliary/entities/town';
import { IvaTypes } from '../../auxiliary/entities/iva-types';
import { IncidentReason } from '../../auxiliary/entities/incident-reason';
import { User } from '../../auth/entities/user';


@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  clientList: Client[] = [];
  patientList: Patient[] = [];
  clinicMaterialList: ClinicMaterial[] = [];
  workStatesList: DropDownOption[] = [];
  subWorkSituationList: DropDownOption[] = [];
  mainWorkTypeStatusList: StatesOfWork[] = [];
  articleList: Article[] = [];
  issureList: Issuer[] = [];
  colorList: Color[] = [];
  localizationList: Localizations[] = [];
  metalList: Metals[] = [];
  productList: Product[] = [];
  workerList: Worker[] = [];
  workTypeTemplateList: WorkType[] = [];
  messengerList: Messengers[] = [];
  shippingFormList: ShippingForms[] = [];
  externalCenterList: ExternalCenter[] = [];
  subscriptionReasonList: SubscriptionReason[] = [];
  labSectionList: LabSection[] = [];
  townList: Town[] = [];
  taskList: Task[] = [];
  ivaTypeList: IvaTypes[] = [];
  incidentReasonList: IncidentReason[] = [];
  userList: User[] = [];

  invoiceList: DropdownOption[] = [];
  receiptList: DropdownOption[] = [];
  deliveryList: DropdownOption[] = [];
  monthList: DropdownOption[] = [];
  incidentTypeList: DropdownOption[] = [];
  jobSearchDateTypeList: DropdownOption[] = [];
  jobSearchPriorityList: DropdownOption[] = [];
  urgencyTypeList: DropdownOption[] = [];
  orderReceptionStatusList: DropdownOption[] = [];
  orderStatusList: DropdownOption[] = [];
  movementTypesList: DropdownOption[] = [];
  movementTypesList2: DropdownOption[] = [];
  taskStatusList: DropdownOption[] = [];
  phaseStatusList: DropdownOption[] = [];
  phaseStatusList2: DropdownOption[] = [];
  genderList: DropdownOption[] = [];
  productInventoryFrequencyList: DropdownOption[] = [];
  auxiliaryNumTypeList: DropdownOption[] = [];
  workStateList: DropdownOption[] = [];
  orderStatusList2: DropdownOption[] = [];
  articleExpandDateList: DropdownOption[] = [];
  periodList: DropdownOption[] = [];
  workTypeExpandDateList: DropdownOption[] = [];
  receiptGenerateTypeList: DropdownOption[] = [];
  warehouseStockStatus: DropdownOption[] = [];
  externalShipmentStatus: DropdownOption[] = [];
  noteTypeList: DropdownOption[] = [];
  invoiceStatusList: DropdownOption[] = [];

  constructor(private _workService: WorkService, private _apiService: ApiService,
    private _mwtWorkerService: MwtWorkerService, private _messengerService: MessagingService,
    private _commonDataService: CommonDataService,
    private externalWorkService: ExternalWorkService, private _auxiliaryService: AuxiliaryService) { }

  async LoadListAsync(changes: string[]) {
    if (changes) {
      if (changes.includes(ListNames.ClientList)) {
        this.clientList = await this._workService.GetClientList();
      }
      if (changes.includes(ListNames.PatientList)) {
        this.patientList = await this._workService.GetPatientList();
      }
      if (changes.includes(ListNames.ClinicMaterialList)) {
        this.clinicMaterialList = await this._workService.GetClinicMaterialList();
      }
      if (changes.includes(ListNames.WorkStatesList)) {
        this.workStatesList = await this._workService.GetWorkStatesDropdownList();
      }
      if (changes.includes(ListNames.SubWorkSituationList)) {
        this.subWorkSituationList = this._workService.GetSubWorkSituationDropDownList();
      }
      if (changes.includes(ListNames.MainWorkTypeStatusList)) {
        this.mainWorkTypeStatusList = await this._workService.GetMainWorkTypeStatusList();
      }
      if (changes.includes(ListNames.ArticleList)) {
        this.articleList = await this._workService.LoadArticleList();
      }
      if (changes.includes(ListNames.IssuerList)) {
        this.issureList = await this._workService.LoadIssuerList();
      }
      if (changes.includes(ListNames.ColorList)) {
        this.colorList = await this._workService.GetColorList();
      }
      if (changes.includes(ListNames.LocalizationList)) {
        this.localizationList = await this._workService.GetVerbalLocalizationList();
      }
      if (changes.includes(ListNames.MetalList)) {
        this.metalList = await this._workService.GetMetalList();
      }
      if (changes.includes(ListNames.WorkTypeTemplateList)) {
        this.workTypeTemplateList = await this._workService.GetWorkTypeTemplateList();
      }
      if (changes.includes(ListNames.ProductList)) {
        this.productList = await this._workService.GetProductList();
      }
      if (changes.includes(ListNames.WorkerList)) {
        this.workerList = await this._mwtWorkerService.GetWorkerList();
      }
      if (changes.includes(ListNames.MessengerList)) {
        this.messengerList = await this._messengerService.GetMessengersList();
      }
      if (changes.includes(ListNames.ShippingFormList)) {
        this.shippingFormList = await this.externalWorkService.GetShippingFormsList();
      }
      if (changes.includes(ListNames.ExternalCenterList)) {
        this.externalCenterList = await this.externalWorkService.GetExternalCenterList();
      }
      if (changes.includes(ListNames.SubscriptionReasonList)) {
        const list = await this._auxiliaryService.GetSubscriptionReasonList();
        this.subscriptionReasonList = list ? list.filter(i => i.status) : [];
      }
      if (changes.includes(ListNames.LabSectionList)) {
        const list = await this._auxiliaryService.GetLabSectionList();
        this.labSectionList = list ? list.filter(i => i.status) : [];
      }
      if (changes.includes(ListNames.TownList)) {
        const list = await this._auxiliaryService.GetTownList();
        this.townList = list ? list.filter(i => i.status) : [];
      }
      if (changes.includes(ListNames.TaskList)) {
        const list = await this._workService.GetTaskList();
        this.taskList = list ? list.filter(i => i.status) : [];
      }
      if (changes.includes(ListNames.IvaTypeList)) {
        const list = await this._apiService
          .get(API.iva_types.getIvaTypesList, true)
          .map(list => list as IvaTypes[]).toPromise();
        this.ivaTypeList = list ? list.filter(it => it.status) : [];
      }
      if (changes.includes(ListNames.IncidentList)) {
        const list = await this._apiService
          .get(API.incident_reason.get_list, true)
          .map(list => list as IncidentReason[]).toPromise();
        this.incidentReasonList = list ? list.filter(it => it.status) : [];
      }
      if (changes.includes(ListNames.UserList)) {
        const list = await this._apiService
          .get(API.user.getUserList, true)
          .map(list => list as User[]).toPromise();
        this.userList = list ? list.filter(it => it.status) : [];
      }

    }
  }

  LoadList(changes: string[]) {
    if (changes) {
      if (changes.includes(ListNames.ClientList)) {
        this._apiService.get(API.client.getClientList, true)
          .subscribe((list: Client[]) => this.clientList = list);
      }
      if (changes.includes(ListNames.PatientList)) {
        this._apiService.get(API.patient.getPatientList, true)
          .subscribe((list: Patient[]) => this.patientList = list);
      }
      if (changes.includes(ListNames.ClinicMaterialList)) {
        this._apiService.get(API.clinic_material.getClinicMaterialList)
          .subscribe((list: ClinicMaterial[]) => this.clinicMaterialList = list);
      }
      if (changes.includes(ListNames.WorkStatesList)) {
        this._apiService.get(API.status_of_work.getStatusOfWorkList, true)
          .subscribe(list => {

            const workStatesDropDownList: DropDownOption[] = [];
            list.forEach(element => {
              const option = new DropDownOption(
                element.description,
                this._commonDataService.localizationLabelList[element.description],
                false
              );
              workStatesDropDownList.push(option);
            });

            workStatesDropDownList.push(new DropDownOption('Todos', this._commonDataService.localizationLabelList['Todos'], false));
            workStatesDropDownList.sort((a, b) => (a.label > b.label) ? 1 : -1);
            workStatesDropDownList.unshift(new DropDownOption('0', this._commonDataService.localizationLabelList['select'], false));
            this.workStatesList = workStatesDropDownList;
          });
      }
      if (changes.includes(ListNames.SubWorkSituationList)) {
        this.subWorkSituationList = this._workService.GetSubWorkSituationDropDownList();
      }
      if (changes.includes(ListNames.MainWorkTypeStatusList)) {
        this._apiService.get(API.status_of_work.getStatusOfWorkList, true)
          .subscribe((list: StatesOfWork[]) => this.mainWorkTypeStatusList = list);
      }
      if (changes.includes(ListNames.ArticleList)) {
        this._apiService.get(API.article.getArticles)
          .subscribe((list: Article[]) => this.articleList = list);
      }
      if (changes.includes(ListNames.IssuerList)) {
        this._apiService.get(API.issuer.getIssuerList)
          .subscribe((list: Issuer[]) => this.issureList = list);
      }
      if (changes.includes(ListNames.ColorList)) {
        this._apiService.get(API.color.getColorList)
          .subscribe((list: Color[]) => this.colorList = list);
      }
      if (changes.includes(ListNames.LocalizationList)) {
        this._apiService.get(API.localization.getLocalization)
          .subscribe((list: Localizations[]) => this.localizationList = list);
      }
      if (changes.includes(ListNames.MetalList)) {
        this._apiService.get(API.metals.getMetalsList)
          .subscribe((list: Metals[]) => this.metalList = list);
      }
      if (changes.includes(ListNames.WorkTypeTemplateList)) {
        this._apiService.get(API.work_type.getWorkTypeList)
          .subscribe((list: WorkType[]) => this.workTypeTemplateList = list);
      }
      if (changes.includes(ListNames.ProductList)) {
        this._apiService.get(API.product.getProductList)
          .subscribe((list: Product[]) => this.productList = list);
      }
      if (changes.includes(ListNames.WorkerList)) {
        this._apiService.get(API.worker.getWorkerList, true)
          .subscribe((list: Worker[]) => this.workerList = list);
      }
      if (changes.includes(ListNames.MessengerList)) {
        this._apiService.get(API.messenger.getMessengerList, true)
          .subscribe((list: Messengers[]) => this.messengerList = list.filter(i => i.status));
      }
      if (changes.includes(ListNames.ExternalCenterList)) {
        this._apiService.get(API.external_center.getExternalCenterList, true)
          .subscribe((list: ExternalCenter[]) => this.externalCenterList = list);
      }
      if (changes.includes(ListNames.ShippingFormList)) {
        this._apiService.get(API.shipping_forms.getShippingFormsList, true)
          .subscribe((list: ShippingForms[]) => this.shippingFormList = list);
      }
      if (changes.includes(ListNames.SubscriptionReasonList)) {
        this._apiService.get(API.reasonForSubscription.getReasonForSubscription)
          .subscribe((list: SubscriptionReason[]) =>
            this.subscriptionReasonList = list.filter(i => i.status));
      }
      if (changes.includes(ListNames.LabSectionList)) {
        this._apiService.get(API.lab_section.getLabSections)
          .subscribe((list: LabSection[]) =>
            this.labSectionList = list.filter(i => i.status));
      }
      if (changes.includes(ListNames.TownList)) {
        this._apiService.get(API.town.getTownList)
          .subscribe((list: Town[]) =>
            this.townList = list.filter(i => i.status));
      }
      if (changes.includes(ListNames.TaskList)) {
        this._apiService.get(API.task.getTaskList)
          .subscribe((list: Task[]) =>
            this.taskList = list.filter(i => i.status));
      }
      if (changes.includes(ListNames.IvaTypeList)) {
        this._apiService
          .get(API.iva_types.getIvaTypesList, true)
          .subscribe((list: IvaTypes[]) => {
            this.ivaTypeList = list ? list.filter(it => it.status) : [];
          });
      }
    }
  }


  initList(dropdowns: string[], dropdownTranslations: any) {
    if (dropdowns.includes(DropdownNames.InvoiceList)) {
      const sectionList = dropdownTranslations.INVOICE_LIST as any[];
      const optionList = InvoiceList.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.invoiceList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.ReceiptList)) {
      const sectionList = dropdownTranslations.RECEIPT_LIST as any[];
      const optionList = ReceiptList.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.receiptList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.DeliveryList)) {
      const sectionList = dropdownTranslations.DELIVERY_LIST as any[];
      const optionList = DeliveryList.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.deliveryList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.MonthList)) {
      const sectionList = dropdownTranslations.MONTH_LIST as any[];
      const optionList = MonthList.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.monthList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.JobSearchDateTypeList)) {
      const sectionList = dropdownTranslations.JOB_SEARCH_DATE_TYPE_LIST as any[];
      const optionList = JobSearchDateTypeList.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.jobSearchDateTypeList = this.getDropdown(optionList, undefined);
    }

    if (dropdowns.includes(DropdownNames.JobSearchPriorityList)) {
      const sectionList = dropdownTranslations.JOB_SEARCH_PRIORITY_LIST as any[];
      const optionList = JobSearchPriorityList.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.jobSearchPriorityList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.UrgencyTypeList)) {
      const sectionList = dropdownTranslations.URGENCY_TYPE_LIST as any[];
      const optionList = UrgencyTypeList.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.urgencyTypeList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.OrderReceptionStatusList)) {
      const sectionList = dropdownTranslations.ORDER_RECEPTION_STATUS_LIST as any[];
      const optionList = OrderReceptionStatusList.map(t =>
        new DropdownOption({ id: t.id, value: t.value, display: sectionList[t.value] }));
      this.orderReceptionStatusList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.OrderStatusList)) {
      const sectionList = dropdownTranslations.ORDER_STATUS_LIST as any[];
      const optionList = OrderStatusList.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.orderStatusList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.MovementTypesList)) {
      const sectionList = dropdownTranslations.MOVEMENT_TYPES_LIST as any[];
      const optionList = MovementTypesList.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.movementTypesList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.MovementTypesList2)) {
      const sectionList = dropdownTranslations.MOVEMENT_TYPES_LIST_2 as any[];
      const optionList = MovementTypesList2.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.movementTypesList2 = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.TaskStatusList)) {
      const sectionList = dropdownTranslations.TASK_STATUS_LIST as any[];
      const optionList = TaskStatusList.map(t =>
        new DropdownOption({ id: t.id, value: t.value, display: sectionList[t.value] }));
      this.taskStatusList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.PhaseStatusList)) {
      const sectionList = dropdownTranslations.PHASE_STATUS_LIST as any[];
      const optionList = PhaseStatusList.map(t =>
        new DropdownOption({ id: t.id, value: t.value, display: sectionList[t.value] }));
      this.phaseStatusList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.PhaseStatusList2)) {
      const sectionList = dropdownTranslations.PHASE_STATUS_LIST_2 as any[];
      const optionList = PhaseStatusList2.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.phaseStatusList2 = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.GenderList)) {
      const sectionList = dropdownTranslations.GENDER_LIST as any[];
      const optionList = GenderList.map(t =>
        new DropdownOption({ value: t.value, label: sectionList[t.value], disabled: false }));
      this.genderList = this.getDropdown(optionList);
    }
    if (dropdowns.includes(DropdownNames.ProductInventoryFrequencyList)) {
      const sectionList = dropdownTranslations.PRODUCT_INVENTORY_FREQUENCY_LIST as any[];
      const optionList = ProductInventoryFrequencyList.map(t =>
        new DropdownOption({ value: t.value, label: sectionList[t.value], disabled: false }));
      this.productInventoryFrequencyList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.AuxiliaryNumTypeList)) {
      const sectionList = dropdownTranslations.AUXILIARY_NUM_TYPE_List as any[];
      const optionList = AuxiliaryNumTypeList.map(t =>
        new DropdownOption({ value: t.value, label: sectionList[t.value], disabled: false }));
      this.auxiliaryNumTypeList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.WorkStateList)) {
      const sectionList = dropdownTranslations.WORK_STATE_LIST as any[];
      const optionList = WorkStateList.map(t =>
        new DropdownOption({ value: t.value, label: sectionList[t.value] }));
      this.workStateList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.OrderStatusList2)) {
      const sectionList = dropdownTranslations.ORDER_STATUS_LIST_2 as any[];
      const optionList = OrderStatusList2.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.orderStatusList2 = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.ArticleExpandDateList)) {
      const sectionList = dropdownTranslations.ARTICLE_EXPAND_DATE_LIST as any[];
      const optionList = ArticleExpandDateList.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.articleExpandDateList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.PeriodList)) {
      const sectionList = dropdownTranslations.PERIOD_LIST as any[];
      const optionList = PeriodList.map(t =>
        new DropdownOption({ id: t.id, value: t.value, display: sectionList[t.value] }));
      this.periodList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.WorkTypeExpandDateList)) {
      const sectionList = dropdownTranslations.WORK_TYPE_EXPAND_DATE_LIST as any[];
      const optionList = WorkTypeExpandDateList.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.workTypeExpandDateList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.ReceiptGenerateTypeList)) {
      const sectionList = dropdownTranslations.RECEIPT_GENERATE_TYPE_LIST as any[];
      const optionList = ReceiptGenerateTypeList.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.receiptGenerateTypeList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.WarehouseStockStatus)) {
      const sectionList = dropdownTranslations.WAREHOUSE_STOCK_STATUS as any[];
      const optionList = WarehouseStockStatus.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.warehouseStockStatus = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.ExternalShipmentStatus)) {
      const sectionList = dropdownTranslations.EXTERNAL_SHIPMENT_STATUS as any[];
      const optionList = ExternalShipmentStatus.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.externalShipmentStatus = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.NoteTypeList)) {
      const sectionList = dropdownTranslations.NOTE_TYPE_LIST as any[];
      const optionList = NoteTypeList.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.noteTypeList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.InvoiceStatusList)) {
      const sectionList = dropdownTranslations.INVOICE_STATUS_LIST as any[];
      const optionList = InvoiceStatusList.map(t =>
        new DropdownOption({ value: t.value, display: sectionList[t.value] }));
      this.invoiceStatusList = this.getDropdown(optionList);
    }

    if (dropdowns.includes(DropdownNames.IncidentTypeList)) {
      const incTypeList = dropdownTranslations.INCIDENT_TYPE_LIST as any[];
      const optionList = IncidentTypeList.map(t =>
        new DropdownOption({ value: t.value, display: incTypeList[t.value] }));
      this.incidentTypeList = this.getDropdown(optionList);
    }
  }

  getDropdown(list: DropdownOption[], defaultValue?: DropdownOption) {
    const arr = [];
    if (defaultValue) { arr.push(defaultValue); }
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < list.length; index++) {
      const option = list[index];
      arr.push(option);
    }
    return arr;
  }

  SearchTowns(query: string) {
    return this._apiService.get(API.town.search_towns + query, false)
      .map(list => list as Town[])
      .toPromise();
  }

  SearchPostcodes(query: string) {
    return this._apiService.get(API.town.search_postcodes + query, false)
      .map(list => list as Town[])
      .toPromise();
  }

}

