import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalNames } from 'src/app/modules/common/global';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { ShowInvalidFormControls, ValidateNgSelect } from '../../../common/services/validators';
import { Document } from '../../entities/document';
import { MainWorkType } from '../../entities/main-work-type';
import { DocumentService } from '../../services/document.service';
import API from './../../../common/api.config.json';

@Component({
  selector: 'app-add-documentview-modal',
  templateUrl: './add-documentview-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddDocumentviewModalComponent implements OnInit, AfterViewInit {
  showContent = false;
  document: Document;
  addDocumentFormGroup: FormGroup;

  documentList: Document[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];

  hasLoaded = true;

  // events
  @Output() updateView = new EventEmitter();
  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  constructor(private _modalService: ModalService,
    private _commonUiService: CommonUIService,
    private __service: DocumentService,
    private _formBuilder: FormBuilder,
    private _apiService: ApiService,
    private _toastyService: Toast,
    private _commonDataService: CommonDataService,
    private cdref: ChangeDetectorRef) {
    this.document = new Document();
    this.initForm();
    this.showContent = true;
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.document = new Document();
    this.initForm();
    if (this._modalService.modal === ModalNames.DocumentModal) {
      if (this._modalService.transferable) {
        const mainList = this._modalService.transferable.selectedMainWorkTypeList;
        this.AddDocumentModal(mainList);
      }
    }
  }

  initForm() {
    this.addDocumentFormGroup = this._formBuilder.group({
      mainWorkTypeId: ['', ValidateNgSelect],
      file: ['', Validators.required]
    });
  }

  AddDocumentModal(list: Array<MainWorkType>) {
    this.selectedMainWorkTypeList = list;
    this.document = new Document();
    this.addDocumentFormGroup.reset();
    if (this.selectedMainWorkTypeList.length > 0) {
      this.document.mainWorkTypeId = 0;
    }
    this.cdref.markForCheck();
  }

  OnSaveDocument(event: any) {
    if (event && this.document) {
      if (this.selectedMainWorkTypeList.length === 1) {
        this.addDocumentFormGroup.controls['mainWorkTypeId'].clearValidators();
        this.addDocumentFormGroup.controls['mainWorkTypeId'].setErrors(null);
        this.addDocumentFormGroup.controls['mainWorkTypeId'].setValidators(null);
      } else {
        this.addDocumentFormGroup.controls['mainWorkTypeId'].setValidators(ValidateNgSelect);
      }
      this.addDocumentFormGroup.updateValueAndValidity();
      if (this.addDocumentFormGroup.invalid) {
        ShowInvalidFormControls(this.addDocumentFormGroup);
        return;
      }
      this._commonUiService.isSpinnerVisible = true;
      if (this.selectedMainWorkTypeList.length === 1) {
        this.document.mainWorkTypeId = (this.selectedMainWorkTypeList[0]).id;
      }
      if (this.document.fileList.length > 0) {
        this.document.extension = ((this.document.fileList[0]).name).split('.')[(((this.document.fileList[0]).name).split('.')).length - 1];
        if (this.document.extension) { this.document.extension = this.document.extension.toLowerCase(); }
        this.document.fileName = ((this.document.fileList[0]).name).split('.')[0] + '.' + this.document.extension;
        this.AddDocument(this.document)
          .then(document => {
            if (document) {
              const formData: FormData = new FormData();
              formData.append(document.fileName, this.document.fileList[0], document.generateFileName);
              formData.append('folderName', 'Document');
              formData.append('id', document.id.toString());
              this.UploadDocument(formData);
            }
          });
        this.cdref.markForCheck();
        //   this.OnClose();
      }
    }
    this.cdref.markForCheck();
  }

  private UploadDocument(formData: FormData) {
    this._apiService.upload(API.file_upload.upload, formData)
      .subscribe(response => {
        if (response['body'] === 200) {
          this.updateView.emit();
          this.OnClose();
          this.SyncDocumentData();
          this._toastyService.success({
            title: this._commonDataService.localizationLabelList['document'],
            msg: this._commonDataService.localizationLabelList['document_upload_successfully']
          });
        }
      },
        err => console.log(err),
        () => this._commonUiService.isSpinnerVisible = false);
  }

  OnChooseDocument(event: any) {
    if (event) {
      const files: any = event.target.files;
      this.document.fileList = files;
      this.cdref.markForCheck();
    }
  }

  private AddDocument(document: Document): Promise<Document> {
    return new Promise<Document>((resolve, reject) => {
      this._apiService.post(API.main_work_type_work.saveDocumentDetails, document, true)
        .subscribe((doc: Document) => resolve(doc),
          err => reject(err));
    });
  }

  private async SyncDocumentData() {
    this.documentList = [];
    try {
      this.hasLoaded = false;
      const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
      if (idList) {
        let documentList = await this.__service.GetDocumentListByMainWorkTypeIdList(idList);
        documentList = documentList ? documentList : [];
        if (documentList.length > 0) {
          for (let index = 0; index < documentList.length; index++) {
            const document = documentList[index];
            const currentMainWorkType = this.selectedMainWorkTypeList.find(m => Number(m.id) === document.mainWorkTypeId);
            if (currentMainWorkType) {
              document.order = currentMainWorkType.order;
              document.cssClass = currentMainWorkType.cssClass;
              document.symbol = currentMainWorkType.symbol;
            }
            document.extension = document.fileName.split('.')[(document.fileName.split('.')).length - 1];
            document.link = this._apiService.BASE_URL + API.main_work_type_work.getDocument + document.id + '/' + document.generateFileName;
            if (document.path) {
              if (document.extension === 'pdf') {
                document.path = document.path.toString().includes('data:application/pdf;base64,')
                  ? document.path : 'data:application/pdf;base64,' + document.path;
              }
              if (document.extension === 'png') {
                document.path = document.path.toString().includes('data:image/png;base64,')
                  ? document.path : 'data:image/png;base64,' + document.path;
              }
              if (document.extension === 'jpg') {
                document.path = document.path.toString().includes('data:image/jpg;base64,')
                  ? document.path : 'data:image/jpg;base64,' + document.path;
              }
              if (document.extension === 'jpeg') {
                document.path = document.path.toString().includes('data:image/jpeg;base64,')
                  ? document.path : 'data:image/jpeg;base64,' + document.path;
              }
              if (document.extension === 'docx') {
                document.path = document.path.toString().includes('https://docs.google.com/gview?url=')
                  ? document.path : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
              }
              if (document.extension === 'xlsx' || document.extension === 'xls') {
                document.path = document.path.toString().includes('https://docs.google.com/gview?url=')
                  ? document.path : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
              }
            }
          }
        }
        this.documentList = documentList;
      }
    } catch (error) { console.log(error); }
    finally {
      this.hasLoaded = true;
      this.cdref.markForCheck();
    }
  }

  OnClose() {
    this.closeModel.emit();
    this._modalService.Invoke(ModalNames.DocumentModal, false, '');
  }

}
