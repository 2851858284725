import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { MwtPhase } from 'src/app/modules/work/entities/main-work-type-phase';
import { Work } from 'src/app/modules/work/entities/work';
import API from '../../../../common/api.config.json';
import { ApiService } from '../../../../common/services/api.service';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import { Toast } from '../../../../common/services/toast.service';
import { RequestData } from '../../../../work/pagination/requestData';
import { Client } from '../../../entities/client';
import { LabSection } from '../../../entities/lab-section';
import { MwtPhaseViewModel } from '../../../entities/main-work-type-phase-view-model';
import { Phases } from '../../../entities/phases';
import { WorkService } from '../../../services/work.service';

@Component({
  selector: 'app-tab-phases',
  templateUrl: './tab-phases.component.html',
  styleUrls: ['./tab-phases.component.scss']
})

export class TabPhasesComponent implements OnInit {

  @ViewChild('fd') from_date_picker: ElementRef;
  @ViewChild('td') to_date_picker: ElementRef;
  @Output() public OnClickWorkForWorkExpand:
    EventEmitter<{ workId: number, subWorkId: number }> =
    new EventEmitter<{ workId: number, subWorkId: number }>();

  private phaseList: number = 1;

  fromDate: any;
  toDate: any;
  phaseStatus: any;
  workNumber: string;
  labSectionValue: any;
  labSectionName: string;
  clientValue: any;
  clientName: string;
  phaseValue: any;
  phaseName: string;
  dateStringFormatForFromDate: any;
  dateStringFormatForToDate: any;
  // pagination propeties
  sessionGetUrl: string;
  pagPhaseList: any[] = [];
  requestData: RequestData = new RequestData(1, 20, '', '', '', this.phaseList);
  refresh: boolean = true;
  isShowExpandlist: boolean = false;

  public enableExportButton: boolean = false;
  public enableClientDropDown: boolean = false;
  public enableLabSectionDropDown: boolean = false;
  public enablePhaseDropDown: boolean = false;
  labSectionList: LabSection[] = [];
  phasesList: Phases[] = [];
  clientList: Client[] = [];
  phaseStatusList: any[] = [];

  public clientDropDownList: any[] = [];

  mwtPhaseViewModel: MwtPhaseViewModel;
  work: Work;
  mwtPhase: MwtPhase;
  // form groups
  mwtPhaseFormGroup: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private _apiService: ApiService,
    public _commonUIService: CommonUIService,
    public _commonDataService: CommonDataService,
    public dropdownService: DropdownService,
    private _toastyService: Toast,
    private _workService: WorkService) {
    this.mwtPhaseViewModel = new MwtPhaseViewModel();
    this.work = new Work();
    this.mwtPhase = new MwtPhase();
    this.mwtPhaseFormGroup = this._formBuilder.group({
      phaseName: [],
      clientName: [],
      labSectionName: [],
    });
  }

  ngOnInit() {
    this.clientValue = '';
    this.clientName = '';
    this.labSectionValue = '';
    this.labSectionName = '';
    this.fromDate = '';
    this.toDate = '';
    this.phaseValue = '';
    this.phaseName = '';
    this.workNumber = '';

    this.sessionGetUrl = API.work.getMwtPhaseExpandPaginationList;

    this.enableExportButton = true;
    this.mwtPhaseFormGroup.controls.phaseName.disable();
    this.mwtPhaseFormGroup.controls.clientName.disable();
    this.mwtPhaseFormGroup.controls.labSectionName.disable();
    this.PhaseStatusList();
    this.LoadClientList();
    this.LoadLabSectionList();
    this.LoadPhaseList();
    this.phaseStatus = 'Pendiente';
  }

  public LoadLabSectionList() {
    this._apiService.get(API.lab_sections.getlabSectionsList)
      .subscribe(res => {
        this.labSectionList = res;
        this.mwtPhaseFormGroup.controls.labSectionName.enable();
        this.enableLabSectionDropDown = true;
      });
  }

  public LoadClientList() {
    this._apiService.get(API.client.getClients)
      .subscribe(res => {
        this.clientList = res;
        this.mwtPhaseFormGroup.controls.clientName.enable();
        this.enableClientDropDown = true;
      });
  }

  public LoadPhaseList() {
    this._apiService.get(API.phases.getPhasesList)
      .subscribe(res => {
        this.phasesList = res;
        this.mwtPhaseFormGroup.controls.phaseName.enable();
        this.enablePhaseDropDown = true;
      });
  }

  public PhaseStatusList() {
    this.phaseStatusList = [
      { id: 1, text: 'Todos', value: 'Todos' },
      { id: 2, text: 'Pendiente', value: 'Pendiente' },
      { id: 3, text: 'Finalizado', value: 'Finalizado' },
      { id: 4, text: 'En curso', value: 'En curso' },
      { id: 5, text: 'Cancelada', value: 'Cancelada' },
    ];
    //All
    //Pending
    //Done
    //In progress
    //Canceled
  }

  public OnSelectClient(client: Client): void {
    this.clientValue = client.id;
    this.clientName = client.name;
  }

  public OnSelectLabSection(labSection: LabSection): void {
    this.labSectionValue = labSection.id;
    this.labSectionName = labSection.description;
  }

  public OnSelectPhase(phases: Phases): void {
    this.phaseValue = phases.id;
    this.phaseName = phases.description;
  }

  EnableSearchButton() {
    this.phaseValue;
    if (this.phaseStatus == '0') {
      this.phaseStatus = '';
    }

    if (this.dateStringFormatForFromDate || this.dateStringFormatForToDate || this.workNumber.length > 0 ||
      this.mwtPhaseFormGroup.controls.clientName.value || this.mwtPhaseFormGroup.controls.labSectionName.value
      || this.mwtPhaseFormGroup.controls.phaseName.value || this.phaseStatus) {
      this.enableExportButton = true;
    } else {
      this.enableExportButton = false;
      this.isShowExpandlist = false;
    }
  }

  ClearPhase(event) {
    this.phaseValue = '';
    this.EnableSearchButton();
  }

  ClearClient(event) {
    this.clientValue = '';
    this.EnableSearchButton();
  }

  ClearLabSection(event) {
    this.labSectionValue = '';
    this.EnableSearchButton();
  }

  FilterPhaseByText() {
    this.requestData.page = 1;
    if ((this.from_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = this._commonUIService.ChangeNgbDateToCustomFormat(this.dateStringFormatForFromDate).toString();
      if (dateString != undefined) {
        this.fromDate = dateString;
      } else {
        this.fromDate = '';
      }
    } else {
      this.fromDate = '';
    }

    if ((this.to_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = this._commonUIService.ChangeNgbDateToCustomFormat(this.dateStringFormatForToDate).toString();
      if (dateString != undefined) {
        this.toDate = dateString;
      } else {
        this.toDate = '';
      }
    } else {
      this.toDate = '';
    }

    if (this.phaseStatus == '0') {
      this.phaseStatus = 'Todos';
    }

    if (this.workNumber == undefined) {
      this.workNumber = '';
    }

    this.clientValue = this.mwtPhaseFormGroup.controls.clientName.value ? this.clientValue : '';
    this.phaseValue = this.mwtPhaseFormGroup.controls.phaseName.value ? this.phaseValue : '';
    this.labSectionValue = this.mwtPhaseFormGroup.controls.labSectionName.value ? this.labSectionValue : '';
    this.requestData.filter = `${this.fromDate};${this.toDate};${this.phaseStatus};${this.workNumber};${this.clientValue};${this.phaseValue};${this.labSectionValue};`;
    this.refresh = true;
    this.isShowExpandlist = true;
  }

  ExportAsXLSX(): void {

    if (this.pagPhaseList  && this.pagPhaseList[0].overrollCount > 1000) {
      this._toastyService.warning({
        title: this._commonDataService.localizationLabelList['phases'],
        msg: this._commonDataService.localizationLabelList['EXPORT_WARNING_MSG']
      });
      return;
    }

    let params = new URLSearchParams();
    for (let key in this.requestData) {
      params.set(key, this.requestData[key])
    }
    let query = params.toString().replace('+', '%20');
    var url = this._apiService.BASE_URL + `/api/Works/MwtPhaseExcelExport?${query}`;
    window.open(url, '_blank');
  }

  async LoadWorkRightPanel(mwtPhase: MwtPhase, event) {
    if (mwtPhase) {
      if (mwtPhase.workId && mwtPhase.subWorkId) {
        this.OnClickWorkForWorkExpand.emit({
          workId: mwtPhase.workId,
          subWorkId: mwtPhase.subWorkId
        });
        this._commonUIService.isActiveRightPanelOnly = false;
        this._commonUIService.isActiveLeftPanelOnly = false;
        this._commonUIService.isActiveBothPanel = false;
        this._commonUIService.isActiveStatisticPanel = false;
      }
    }
  }
}

