import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { ShowInvalidFormControls } from 'src/app/modules/common/services/validators';
import { LicesnseConfigModel } from './license-config-model';

import API from 'src/app/modules/common/api.config.json';

@Component({
  selector: 'app-modify-licensing-system',
  templateUrl: './modify-licensing-system.component.html',
  styleUrls: ['./modify-licensing-system.component.scss']
})
export class ModifyLicensingSystemComponent implements OnInit {

  licenseForm: FormGroup;
  licenseData: LicesnseConfigModel = new LicesnseConfigModel();

  constructor(private _formBuilder: FormBuilder, private _commonUiService: CommonUIService, private _toastyService: Toast,
    public _commonDataService: CommonDataService, private _apiService: ApiService) {
    this.InitFormGroup();
    this.licenseData = new LicesnseConfigModel();
  }

  ngOnInit() {
    this.licenseForm.reset();
    this.licenseData = new LicesnseConfigModel();
  }

  private InitFormGroup() {
    this.licenseForm = this._formBuilder.group({
      license: ['', [Validators.required]],
      status: [true]
    });
  }

  onSaveLicense(licesnseConfig: any) {
    if (this.licenseForm.invalid) { ShowInvalidFormControls(this.licenseForm); return; }
    try {
      this.licenseData.status = true;
      this.saveLicense(this.licenseData)
        .then(response => {
          const body = JSON.parse(response['_body']);
          const data = body['_data'];
          if (body) {
            if (body['_isSuccsess']) {
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList['license'],
                msg: this._commonDataService.localizationLabelList['save_success']
              });
              this.licenseForm.reset();
              this.licenseForm.controls.license.setValue('');
              this.licenseData = new LicesnseConfigModel();
              this.OnCloseModal();
            } else {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['expenses'],
                msg: this._commonDataService.localizationLabelList['save_error']
              });
            }
          }
        });
    } catch (error) { console.log(error); }
  }

  private async saveLicense(license: LicesnseConfigModel) {
    return this._apiService.post(API.auth.saveLicense, license, false).toPromise();
  }

  OnCloseModal() {
    this._commonUiService.CloseBasicModal('modify-licensing-modal');
  }

}
