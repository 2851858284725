export class Mwtproduct {
  id: number;
  mainWorkTypeId: number;
  productId: any;
  quantity?: number;
  lotId: string;
  stockOutDate?: any;
  price?: number;
  mwtArticleId?: number;
  workId: number;
  createdBy: number;
  createdDate: string;

  productCode: string;
  productName: string;
  prevQuantity: number;
  mainWorkTypeNo: string;
  warehouseId?: number;
  status: boolean;
  workerId: number;
  isUrgentOrder: boolean;
  note: string;
  isMomentUpdate: boolean;
  isUrgentOrderCreated: boolean;
  lotName: string;
  reltedArticleId: number;

  isModified: boolean;

  externalCenterId: number;
  isExternal: boolean;
  constructor(init?: Partial<Mwtproduct>) { if (init) { Object.assign(this, init); } }

}
