export class Client {
  id: number;
  type: string;
  clientTypeId: number;
  qualityTypeId: number;
  code: string;
  name: string;
  status: boolean;

  billToId?: number;
  nif: string;
  physicalName: string;
  address: string;
  townId?: number;
  postalCode: string;
  telephone1: string;
  telephone2: string;
  email: string;
  contactPerson: string;
  responsibleId: number;
  notes: string;
  languageId: number;
  paymentMethodTypeId: number;
  delivertNoteObservation: string;
  createdDate: any;

  clientTypeName: string;
  languageName: string;
  paymentMethodType: string;
  qualityType: string;
  townName: string;
  priceListName: string;
  provinceName: string;

  isAutomaticBilling: boolean;
} 
