import { Injectable } from '@angular/core';
import API from './../../common/api.config.json';
import { CommonFunctions } from '../../common/services/common-functions';
import { WorkTypeArticleTemplate } from '../../work-type/entities/work-type-model-templates';
import { ApiService } from '../../common/services/api.service';
import { MwtArticle } from '../entities/main-work-type-article';
import { MwtArticleUsage } from '../entities/mwt-article-usage';
import { IvaCalculationService } from './iva-calculation.service';

@Injectable({
  providedIn: 'root'
})
export class ArticleTemplateService {

  constructor(private _apiService: ApiService, private _ivaCalculationService: IvaCalculationService) { }

  GetArticleTemplateListByMainWorkTypeIdList(idList: { id: number }[]) {
    try {
      const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
      if (params) {
        const query = params.toString().replace('+', '%20');
        return this._apiService.get(API.work_panels.getArticleTemplateListByMainWorkTypeIdList + query, true)
          .map(a => a as WorkTypeArticleTemplate[]).toPromise();
      }
    } catch (error) { console.log(error); }
  }

  GetMwtArticlePriceDetails(articleId: number, clientId: number): Promise<MwtArticle> {
    return new Promise<MwtArticle>((resolve, reject) => {
      this._apiService.get(API.main_work_type_work.getMwtArticlePriceDetails + articleId + '/' + clientId)
        .subscribe((mwtArticle: MwtArticle) => resolve(mwtArticle));
    });
  }

  GetMwtArticlePriceDetailList(idList: any[]) {
    try {
      const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
      if (params) {
        const query = params.toString().replace('+', '%20');
        return this._apiService.get(API.main_work_type_work.getMwtArticlePriceDetailList + query, true)
          .map(a => a as MwtArticle[]).toPromise();
      }
    } catch (error) { console.log(error); }
  }

  GetQuantityByWorkTypeArticleId(workTypeId: number, articleId: number) {
    return this._apiService.get(API.work_type.getQuantityByWorkTypeArticleId + workTypeId + '/' + articleId, true)
      .map(x => x as number).toPromise();
  }

  GetArticleTemplateListByWorkTypeTemplateId(workTypeId: number) {
    return this._apiService.get(API.work_type.getArticleListById + workTypeId)
      .map(list => list as WorkTypeArticleTemplate[]).toPromise();
  }

  CheckMwtArticleUsage(mwtArticleId: number) {
    return this._apiService.get(API.main_work_type_work.check_mwt_article_usage + mwtArticleId)
      .map(response => response as MwtArticleUsage)
      .toPromise();
  }

  async GetMwtArticle(clientId: number, article: { id: number, description: string }, workTypeId: number) {
    try {
      const mwtArticle = await this.GetMwtArticlePriceDetails(article.id, clientId);
      if (mwtArticle) {
        mwtArticle.articleId = article.id;
        mwtArticle.description1 = article.description;
        mwtArticle.workTypeId = workTypeId;
        mwtArticle.mainWorkTypeId = workTypeId;
        if (workTypeId > 0) {
          const quantity = await this.GetQuantityByWorkTypeArticleId(workTypeId, Number(article.id));
          mwtArticle.quantity = quantity ? quantity : 1;
        } else { mwtArticle.quantity = 1; }

        mwtArticle.quantityWithWaste = this.GetQuantityWithWaste(mwtArticle);
        mwtArticle.priceWithDiscount = this.GetPriceWithDiscount(mwtArticle);
        const finalPriceWithoutIva = this.GetFinalPriceWithoutIva(mwtArticle);

        let ivaAmount = 0;
        if (mwtArticle.ivaTypeId) {
          ivaAmount = this._ivaCalculationService
            .getIvaAmount(finalPriceWithoutIva, mwtArticle.ivaPercentage ? mwtArticle.ivaPercentage : 0);
          mwtArticle.ivaAmount = Number(ivaAmount.toFixed(2));
        }
        mwtArticle.finalPrice = Number((finalPriceWithoutIva + ivaAmount).toFixed(2));

        return mwtArticle;
      }
    } catch (error) { console.log(error); }
  }

  GetQuantityWithWaste(mwtArticle: MwtArticle) {
    if (mwtArticle.waste !== undefined && mwtArticle.quantity !== undefined) {
      return Number((mwtArticle.quantity * (mwtArticle.waste + 100) / 100).toFixed(2));
    }
  }

  GetPriceWithDiscount(mwtArticle: MwtArticle) {
    if (mwtArticle.price !== undefined && mwtArticle.discount !== undefined) {
      if (mwtArticle.isAmount) {
        return Number((Number(mwtArticle.price) - Number(mwtArticle.discountAmount)).toFixed(2));
      } else {
        return Number((mwtArticle.price * (100 - mwtArticle.discount) / 100).toFixed(2));
      }
    }
  }

  GetFinalPriceWithoutIva(mwtArticle: MwtArticle) {
    if (mwtArticle.priceWithDiscount !== undefined && mwtArticle.quantityWithWaste !== undefined) {
      return Number((mwtArticle.priceWithDiscount * mwtArticle.quantityWithWaste).toFixed(2));
    }
  }

  SaveMwtArticle(mwtArticle: MwtArticle) {
    return this._apiService.post(API.main_work_type_work.saveMwtArticle, mwtArticle, false, true).toPromise();
  }

  SaveAllMwtArticle(articleList: MwtArticle[]) {
    return this._apiService.post(API.main_work_type_work.saveMwtColorArticleList, articleList, false, true).toPromise();
  }
}
