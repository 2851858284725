import { Injectable } from '@angular/core';
import API from './../../common/api.config.json';
import { CommonFunctions } from '../../common/services/common-functions';
import { WorkTypeProductTemplate } from '../../work-type/entities/work-type-model-templates';
import { ApiService } from '../../common/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductTemplateService {

  constructor(private _apiService: ApiService) { }

  GetProductTemplateListByMainWorkTypeIdList(idList: { id: number }[]) {
    try {
      const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
      if (params) {
        const query = params.toString().replace('+', '%20');
        return this._apiService.get(API.work_panels.getProductTemplateListByMainWorkTypeIdList + query, true)
          .map(p => p as WorkTypeProductTemplate[]).toPromise();
      }
    } catch (error) { console.log(error); }
  }
}
