import { OrderReceptionDetails } from './order-reception-details';

export class OrderReceipt {
    id: number;
    orderId: number;
    orderNo: string;
    providerId: number;
    orderDate: string;
    shippingCost: number;
    totalOrderAmount: number;
    deliveryNote: string;
    deliveryDate: string;
    receiptStatus: number;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    providerName: string;
    providerEmail: string;
    deliveryNoteDate?: string;
    wareHouseId: number;
    orderReceiptDetailList: OrderReceptionDetails[];

    issuerName: string;
}
