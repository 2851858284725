import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Guide } from '../../../entities/Guide';
import { FormGroup, NgForm, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../../common/services/api.service';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import API from './../../../../common/api.config.json';
import { CommonDataService } from '../../../../common/services/common.data.service';
import { Toast } from 'src/app/modules/common/services/toast.service';

@Component({
  selector: 'guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit {

  successMessage: any;
  updateMessage: any;
  errorMessage: any;
  deleteMessage: any;
  statusMessage: any;
  deleteErrorMessage: any;
  tableName: any;

  guide: Guide;
  isLoaded: boolean;
  position = 'bottom-right';
  guideList: Guide[];
  private _guideList: Guide[] = [];

  guideFormGroup: FormGroup;
  guideForm: NgForm;

  @ViewChild('description') nameField: ElementRef;
  isDisabledGuide = false;


  constructor(
    private _apiService: ApiService,
    private _fb: FormBuilder,
    private _commonUIService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: Toast) {
    this.guide = new Guide();
    this.isLoaded = false;
    this.isDisabledGuide = false;

  }

  ngOnInit(): void {
    this.guideFormGroup = this._fb.group({
      description: ['', [
        Validators.required
      ]]
    });
    this.LoadGuideList();
  }

  LoadGuideList() {
    this._apiService.get(API.guide.getGuideList)
      .subscribe(res => {
        this.guideList = res;
        this._guideList = res;
        this.isLoaded = true;
      });
  }

  LoadEditGuide(guide: Guide) {
    guide.isEdit = true;
    if (guide) {
      const index = this.guideList.findIndex(x => x.id === guide.id);
      if (index !== -1) {
        const c = new Guide();
        c.id = guide.id;
        c.description = guide.description;
        c.isDescriptionRequired = false;
        c.status = guide.status;
        c.isEdit = true;
        this.guideList[index] = c;
      }
    } return;
  }

  EditGuide(guide: Guide, statusChange: boolean) {
    this.isDisabledGuide = true;

    this.statusMessage = this._commonDataService.localizationLabelList['status_updated'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.tableName = this._commonDataService.localizationLabelList['guides'];
    guide.isEdit = false;
    if (guide.id && guide.description) {
      this._apiService.put(API.guide.editGuide + guide.id, guide, false)
        .subscribe(
          res => {
            if (res.status == 204 || res.status == 200) {
              let message = '';
              if (statusChange) {
                message = this.statusMessage;
              }
              else {
                message = this.updateMessage;
              }
              this._toastyService.success({
                title: this.tableName,
                msg: message
              });
              this.LoadGuideList();
            }
          },
          err => {
            console.log(err);
          },
          () => {
            this.isDisabledGuide = false;
          }
        );
    } else {
      if (guide) {
        const index = this.guideList.findIndex(x => x.id === guide.id);
        if (index !== -1) {
          const g = new Guide();
          g.id = guide.id;
          g.description = '';
          g.isDescriptionRequired = true;
          g.status = guide.status;
          this.guideList[index] = g;
        }
      } return;
    }
  }

  RemoveEditGuide(guide: Guide) {
    guide.isEdit = false;
    this.LoadGuideList();
  }

  ActiveDeactiveGuide(guide: Guide) {
    if (guide) {
      if (guide.status) {
        guide.status = false;
      }
      else {
        guide.status = true;
      }
      this.EditGuide(guide, true);
    }
  }

  OnOpenAddGuideModal() {

    this.successMessage = this._commonDataService.localizationLabelList['save_success'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.errorMessage = this._commonDataService.localizationLabelList['save_error'];
    this.tableName = this._commonDataService.localizationLabelList['guides'];

    this.guide = new Guide(); this.guideFormGroup.reset();
    this._commonUIService.openModal('guide-modal');

    setTimeout(() => {
      this.nameField.nativeElement.focus();
    }, 100);

  }

  OnCloseGuideModal(event) {
    (((((event.target.parentElement.parentElement).parentElement).parentElement).parentElement).parentElement).classList.remove('md-show');
  }

  SaveGuide(guide: Guide, event) {
    this.isDisabledGuide = true;

    if (this.guideFormGroup.invalid) {
      Object.keys(this.guideFormGroup.controls).forEach(field => {
        const control = this.guideFormGroup.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      this.isDisabledGuide = false;
      return;
    }

    this.OnCloseGuideModal(event);

    guide.status = true;
    this._apiService.post(API.guide.saveGuide, guide, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {
            this._toastyService.success({
              title: this.tableName,
              msg: this.successMessage
            });
            this.LoadGuideList();
          }
        },
        err => {
          this._toastyService.error({
            title: this.tableName,
            msg: err
          });
          this.isDisabledGuide = false;
        },
        () => {
          this.isDisabledGuide = false;
        }
      );
  }

  DeleteGuide(id: number) {
    this.isDisabledGuide = true;

    this.deleteMessage = this._commonDataService.localizationLabelList['delete_success'];
    this.deleteErrorMessage = this._commonDataService.localizationLabelList['delete_error'];
    this.tableName = this._commonDataService.localizationLabelList['guides'];

    if (id) {
      this._apiService.delete(API.guide.deleteGuide, id.toString())
        .subscribe(
          res => {
            if (res._statusCode == 200) {
              let index: number = this._guideList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._guideList.splice(index, 1);
                this._toastyService.success({
                  title: this.tableName,
                  msg: this.deleteMessage
                });
              }
            }
            if (res._statusCode == 409) {
              let index: number = this._guideList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._toastyService.warning({
                  title: this.tableName,
                  msg: this.deleteErrorMessage
                });
              }
            }
          },
          err => {
            this._toastyService.error({
              title: this.tableName,
              msg: err
            });
            this.isDisabledGuide = false;

          },
          () => {
            this.isDisabledGuide = false;
            this.guideList = this._guideList;
          }
        );
    }
  }
}
