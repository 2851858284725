import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ng2chart',
  templateUrl: './ng2chart.component.html'
})
export class Ng2chartComponent implements OnInit {
  @Input() chartData : any;

  constructor(){}

  ngOnInit(){
  }
}
