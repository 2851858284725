import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Color } from 'src/app/modules/auxiliary/entities/Color';
import { Localizations } from 'src/app/modules/auxiliary/entities/localizations';
import { Metals } from 'src/app/modules/auxiliary/entities/metals';
import { ModalNames } from 'src/app/modules/common/global';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { ShowInvalidFormControls, ValidateNgSelect } from 'src/app/modules/common/services/validators';
import { WorkTypeArticleTemplate } from 'src/app/modules/work-type/entities/work-type-model-templates';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtArticle } from '../../entities/main-work-type-article';
import { MwtColor } from '../../entities/main-work-type-color';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { ArticleTemplateService } from '../../services/article-template.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';

@Component({
  selector: 'app-color-modal',
  templateUrl: './color-modal.component.html',
  styleUrls: ['./color-modal.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorModalComponent implements OnInit, AfterViewInit {

  @Output() refreshData: EventEmitter<any> = new EventEmitter();
  @Output() closeModel: EventEmitter<any> = new EventEmitter();
  showContent = false;
  @ViewChild('articleLbl') articleLblElement: ElementRef;

  // form groups
  mwtColorFormGroup: FormGroup;

  // lists
  mwtColorList: MwtColor[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];
  mainWorkTypeList: MainWorkType[] = [];
  selectedTeethList: number[] = [];
  colorList: Color[] = [];
  localizationList: Localizations[] = [];
  articleTemplateList: WorkTypeArticleTemplate[] = [];
  metalList: Metals[] = [];
  mwtArticle: MwtArticle;

  // objects
  mwtColor: MwtColor;
  selectedSubWork: SubWork;
  selectedWork: Work;
  selectedMainWorkType: MainWorkType;
  hasLoaded = true;
  showPiezasSelector = false;
  isColorsEditable = false;
  isArticleDisabled = false;

  constructor(private _workService: WorkService, private _apiService: ApiService,
    public commonUiService: CommonUIService, private _commonDataService: CommonDataService,
    private _dropdownService: DropdownService, private _modalService: ModalService,
    private _formBuilder: FormBuilder, private _toastyService: Toast,
    private _articleTemplateService: ArticleTemplateService, private cdref: ChangeDetectorRef) {
    this.mwtColor = new MwtColor();
    this.mwtArticle = new MwtArticle();
    this.mwtColorFormGroup = this._formBuilder.group({
      mainWorkTypeId: ['', [ValidateNgSelect]],
      articleName: [], localizationText: [],
      colorName: [], pieces: [],
      notes: [], guideName: [], metalName: []
    });
    this.showContent = true;
  }

  async ngOnInit() {
    this.mwtColor = new MwtColor();
    if (this.metalList.length === 0) { this.metalList = this._dropdownService.metalList.filter(x => x.status == true); }
    if (this.colorList.length === 0) { this.colorList = this._dropdownService.colorList; }
    if (this.localizationList.length === 0) {
      this.localizationList = this._dropdownService.localizationList;
    }
    if (this._modalService.modal === ModalNames.ColorModal) {
      if (this._modalService.transferable) {
        this.selectedMainWorkTypeList = this._modalService.transferable.mainWorkTypeList as MainWorkType[];
        this.selectedWork = this._modalService.transferable.selectedWork as Work;
        if (this._modalService.transferable.isEdit) {
          await this.OnEditMwtColorModal(this._modalService.transferable.mwtColor as MwtColor);
        } else {
          await this.OnOpenAddColorModal();
        }
      }
    }
  }

  ngAfterViewInit() { }

  //#region CRUD

  private async SaveMwtColor(color: MwtColor) {
    return this._apiService.post(API.main_work_type_work.saveColor, color, false, true)
      .map(response => JSON.parse(response['_body'])).toPromise();
  }

  //#endregion

  //#region Events

  private async LoadArticleList() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (this.selectedMainWorkTypeList) {
        this.articleTemplateList = [];
        const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
        if (idList) {
          // this.cdref.markForCheck();
          const articleTemplateList =
            await this._articleTemplateService
              .GetArticleTemplateListByMainWorkTypeIdList(idList);
          this.articleTemplateList = articleTemplateList ? articleTemplateList : [];
        }
      }
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async LoadMwtColorPopupData() {
    try {
      this.colorList = this._dropdownService.colorList;
      this.localizationList = this._dropdownService.localizationList;
      this.metalList = this._dropdownService.metalList;
      // this.cdref.markForCheck();
    } catch (error) { console.log(error); }
  }

  OnClose() {
    this.showContent = false;
    this.selectedTeethList = [];
    this.showPiezasSelector = false;
    this.commonUiService.isPopupOpened = false;
    this.closeModel.emit();
  }

  async OnOpenAddColorModal() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      this.commonUiService.isPopupOpened = true;
      this.mwtColorFormGroup.reset();
      this.LoadArticleList();
      if (this.metalList.length === 0 || this.colorList.length === 0
        || this.localizationList.length === 0) {
        await this.LoadMwtColorPopupData();
      }
      this.mwtColor = new MwtColor();
      this.mwtColor.colorText = '';
      this.mwtColor.mainWorkTypeId = 0;
      this.mwtColor.guideName = '';
      this.mwtColor.localizationText = '';
      this.mwtColor.mwtArticleName = '';
      this.mwtColor.metalName = '';
      this.mwtColor.piecesList = [];
      this.selectedTeethList = [];
    } catch (error) { console.log(error); }
    finally {
      // this.cdref.markForCheck();
      this.isArticleDisabled = false;
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnEditMwtColorModal(mwtColor: MwtColor) {
    if (mwtColor) {
      this.commonUiService.isSpinnerVisible = true;
      this.commonUiService.isPopupOpened = true;

      this.mwtColorFormGroup.markAsPristine();
      this.mwtColorFormGroup.markAsUntouched();
      this.LoadArticleList();
      if (this.metalList.length === 0 || this.colorList.length === 0
        || this.localizationList.length === 0) {
        await this.LoadMwtColorPopupData();
      }
      this.mwtColor = Object.assign(this.mwtColor, mwtColor);
      this.mwtColorFormGroup.controls.articleName.setValue(this.mwtColor.mwtArticleName);
      this.mwtColorFormGroup.controls.localizationText.setValue(this.mwtColor.localizationText);
      this.mwtColorFormGroup.controls.colorName.setValue(this.mwtColor.colorText);
      this.mwtColorFormGroup.controls.metalName.setValue(this.mwtColor.metalName);
      this.mwtColor.piecesList = [];
      this.selectedTeethList = [];
      let pieces: string[] = [];
      if (mwtColor.pieces) {
        if (mwtColor.pieces.includes(',')) { pieces = mwtColor.pieces.split(','); } else {
          pieces.push(mwtColor.pieces);
        }
        pieces.forEach(piece => this.mwtColor.piecesList.push(Number(piece)));
        this.selectedTeethList = this.mwtColor.piecesList ? this.mwtColor.piecesList : [];
      }
      const noteHtml = document.getElementById('notesElement');
      if (noteHtml) { document.getElementById('notesElement').click(); }
      this.isArticleDisabled = false;
      this.mwtArticle = new MwtArticle();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  OnShowPiezasSelector() {
    this.showPiezasSelector = true;
    if (!this.mwtColor.id) {
      this.mwtColor.piecesList = [];
      this.selectedTeethList = [];
    }
  }

  OnSelectColorMwtArticle(articleTemplate: WorkTypeArticleTemplate) {
    if (articleTemplate) {
      this.mwtColor.mwtArticleId = Number(articleTemplate.mwtArticleId);
      this.mwtColor.mwtArticleName = articleTemplate.articleName;
    } else {
      this.mwtColor.mwtArticleId = null;
      this.mwtColor.mwtArticleName = null;
    }
    // this.cdref.markForCheck();
  }

  OnSelectColorLocalization(localization: Localizations) {
    if (localization) {
      this.mwtColor.localizationId = Number(localization.id);
      this.mwtColor.localizationText = localization.description;
    }
    // this.cdref.markForCheck();
  }

  OnSelectColor(color: Color) {
    if (color) {
      this.mwtColor.colorId = Number(color.id);
      this.mwtColor.colorText = color.description;
      this.mwtColor.guideName = color.guideName;
    } else { this.mwtColor.guideName = ''; }
    // this.cdref.markForCheck();
  }

  OnSelectPieces(pieces: number[]) {
    if (pieces) {
      let piecesStr = '';
      pieces.forEach(piece => piecesStr = piecesStr + (piecesStr ? ', ' : '') + piece);
      this.mwtColor.pieces = piecesStr;
    }
    // this.cdref.markForCheck();
  }

  async OnSaveMwtColor(event: any) {
    if (this.selectedMainWorkTypeList.length === 1) {
      this.mwtColorFormGroup.controls['mainWorkTypeId'].clearValidators();
      this.mwtColorFormGroup.controls['mainWorkTypeId'].setErrors(null);
      this.mwtColorFormGroup.controls['mainWorkTypeId'].setValidators(null);
    } else { this.mwtColorFormGroup.controls['mainWorkTypeId'].setValidators(ValidateNgSelect); }
    this.mwtColorFormGroup.updateValueAndValidity();
    if (this.mwtColorFormGroup.invalid) { ShowInvalidFormControls(this.mwtColorFormGroup); return; }
    try {
      this.commonUiService.isSpinnerVisible = true;
      this.mwtColor.mwtArticleId = this.mwtColorFormGroup.controls.articleName.value ? this.mwtColor.mwtArticleId : 0;
      this.mwtColor.localizationId = this.mwtColorFormGroup.controls.localizationText.value ? this.mwtColor.localizationId : 0;
      this.mwtColor.metalId = this.mwtColorFormGroup.controls.metalName.value ? this.mwtColor.metalId : 0;
      this.mwtColor.colorId = this.mwtColorFormGroup.controls.colorName.value ? this.mwtColor.colorId : 0;
      if (this.mwtColor.pieces || this.mwtColor.localizationId || this.mwtColor.colorId) {
        if (this.selectedMainWorkTypeList.length === 1) { this.mwtColor.mainWorkTypeId = (this.selectedMainWorkTypeList[0]).id; }
        this.mwtColor.workId = this.selectedWork.id;
        if (this.mwtArticle && this.mwtArticle.articleId > 0) {
          if (this.mwtArticle.articleId != this.mwtColor.mwtArticleId) {
            if (this.mwtColor.mwtArticleId > 0) {
              this._toastyService.warning({
                title: this._commonDataService.localizationLabelList['work_color'],
                msg: this._commonDataService.localizationLabelList['metal_configured_with_an_article']
              });
            }
            this.mwtArticle.mainWorkTypeId = this.mwtColor.mainWorkTypeId;
            const artResponse = await this._articleTemplateService.SaveMwtArticle(this.mwtArticle);
            const body = JSON.parse(artResponse['_body']);
            const data = JSON.parse(JSON.stringify(body['_data']));
            this.mwtColor.mwtArticleId = data.id;
            if (body['_isSuccsess']) {
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList['article'],
                msg: this._commonDataService.localizationLabelList['save_success']
              });
            } else {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['article'],
                msg: this._commonDataService.localizationLabelList['save_error']
              });
            }
          }
        }
        const response = await this.SaveMwtColor(this.mwtColor);
        if (response) {
          if (response['_isSuccsess']) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['work_color'],
              msg: this._commonDataService.localizationLabelList['save_success']
            });
            this.refreshData.emit();
            this._workService.SetDetailObject({
              selectedMainWorkTypeList: this.selectedMainWorkTypeList,
              selectedMainWorkType: this.selectedMainWorkType,
              selectedSubWork: this.selectedSubWork,
              selectedWork: this.selectedWork,
              mainWorkTypeList: this.mainWorkTypeList,
              invokeComponents: ['Color'],
            });
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['work_color'],
              msg: this._commonDataService.localizationLabelList['save_error']
            });
          }
          this.commonUiService.isSpinnerVisible = false;
        }
        this.OnClose();
      } else {
        this._toastyService.error({
          title: this._commonDataService.localizationLabelList['work_color'],
          msg: this._commonDataService.localizationLabelList['please_select_pieces']
        });
      }
    } catch (error) { console.error(error); } finally {
      // this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  /**
   * get fired when user selects a metal from the form.
   * @param metal | comes from event
   */
  async OnChangeColorMetal(metal: Metals) {
    if (metal) {
      this.mwtColor.metalId = metal.id;
      this.mwtColor.metalName = metal.description;
      if (metal.articleId > 0) {
        const articleTemplate = this.articleTemplateList.find(x => x.articleId == metal.articleId);
        if (articleTemplate) {
          this.mwtColor.mwtArticleId = Number(articleTemplate.mwtArticleId);
          this.mwtColor.mwtArticleName = articleTemplate.articleName;
          this.mwtColorFormGroup.controls.articleName.setValue(metal.articleName);
          this.articleLblElement.nativeElement.click();
          this.isArticleDisabled = true;
          await this.OnAddMetelArticle({ id: metal.articleId, description: metal.articleName });
        } else {
          const art = this._dropdownService.articleList.find(a => a.id === metal.articleId && a.isActive == true);
          if (art) {
            const template = new WorkTypeArticleTemplate();
            template.articleId = metal.articleId;
            template.articleName = metal.articleName;
            this.articleTemplateList.push(template);
            this.mwtColorFormGroup.controls.articleName.setValue(metal.articleName);
            this.articleLblElement.nativeElement.click();
          }
          this.isArticleDisabled = true;
          await this.OnAddMetelArticle({ id: metal.articleId, description: metal.articleName });
        }
      } else {
        this.mwtColorFormGroup.controls.articleName.setValue('');
        this.mwtColor.mwtArticleId = null;
        this.mwtColor.mwtArticleName = null;
        this.isArticleDisabled = true;
        this.mwtArticle = new MwtArticle();
      }
    } else {
      this.mwtColorFormGroup.controls.metalName.setValue('');
      this.mwtColorFormGroup.controls.articleName.setValue('');
      this.mwtColor.metalId = null;
      this.mwtColor.metalName = '';
      this.mwtColor.mwtArticleId = null;
      this.mwtColor.mwtArticleName = '';
      this.isArticleDisabled = false;
      this.mwtArticle = new MwtArticle();
    }
    // this.cdref.markForCheck();
  }

  async OnAddMetelArticle(article: { id: number, description: string }) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      this.mwtArticle = new MwtArticle();
      if (article.id) {
        const art = this._dropdownService.articleList.find(a => a.id === article.id && a.isActive == true);
        if (art) {
          const mwtArticle = await this._articleTemplateService.GetMwtArticle(this.selectedWork.clientId, { id: article.id, description: article.description }, Number(this.mwtColorFormGroup.value.mainWorkTypeId));
          if (mwtArticle) {
            this.mwtArticle = mwtArticle
          }
        }
      }
    } catch (error) { console.log(error); }
    finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  //#endregion
}
