export class Metals {
  id: number;
  description: string;
  code: string;
  status: boolean;
  includeInStatistics: boolean;
  articleId: any;
  byDefault?: boolean;

  isEdit?: boolean;

  articleName: string;

  constructor() {
    this.status = true;
  }
}
