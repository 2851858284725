import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import API from '../common/api.config.json';
import { RequestData } from 'src/app/shared/pagination/requestData';
import { ApiService } from '../common/services/api.service';
import { CommonFunctions } from '../common/services/common-functions';
import { CommonDataService } from '../common/services/common.data.service';
import { Doctor } from '../work/entities/doctor';
import { JobSearchFilter } from '../common/entities/job-search-model';
import { DropDownOption } from '../common/entities/dropdown-option';
import { Patient } from '../work/entities/patient';
import { dateTypes, PriorityOption } from 'src/app/modules/common/global';
import { WorkService } from '../work/services/work.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Priority } from '../common/entities/dropdown-enum';
import { Location } from '@angular/common';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { Observable } from 'rxjs';
import { CommonUIService } from '../common/services/common.ui.service';
import { Toast } from '../common/services/toast.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgbDate } from 'imp-ng-bootstrap/datepicker/ngb-date';
import { PatientService } from '../client/services/patient.service';
import { WorkConfigurationService } from '../configuration/services/work-configuration.service.js';
import { WorkNo, MwtNo, DeliveryNoteNo } from '../work/entities/global-search-no-filters';
import { DeliveryNoteService } from '../work/services/delivery-note.service';

@Component({
  selector: 'app-job-search',
  templateUrl: './job-search.component.html',
  styleUrls: ['./job-search.component.scss']
})
export class JobSearchComponent implements OnInit, AfterViewInit {
  //@ViewChild('workNoElement') searchInputElement: ElementRef;
  paginationRequestData: RequestData = new RequestData(1, 30, '', '', '', 0, true);
  refresh = false;
  workPaginationUrl: string;
  rowsOnPage = 30;
  filterQuery = '';
  sortBy = '';
  sortOrder = 'desc';
  workList = [];
  patientList: Patient[] = [];
  statusList = [];
  priorityOption = [];

  workNoList: WorkNo[] = [];
  workNo = new FormControl('');

  mwtNoList: MwtNo[] = [];
  mwtNo = new FormControl('');

  deliveryNoteNoList: DeliveryNoteNo[] = [];
  deliveryNoteNo = new FormControl('');

  searchFilter = new JobSearchFilter();
  statusDropDownList: DropDownOption[] = [];
  public clientList: any[] = [];
  doctorList: Doctor[];
  public workTypeList :any[] =[];
  canRedirectWork = false;
  showAutocomplete: boolean = true;

  dateTypeList = dateTypes;

  // in here using enum as Priority
  priorityEnum = Priority;

  clientId = new FormControl('');
  doctorId = new FormControl('');
  patientId = new FormControl('');
  worktypeId = new FormControl('');
  todayView: any;
  disableDates = false;


  constructor(
    private _toastyService: Toast,
    private _apiService: ApiService,
    private _router: Router,
    private _location: Location,
    private _workService: WorkService,
    private _delNoteService: DeliveryNoteService,
    public _commonDataService: CommonDataService,
    private _ngxPermissionService: NgxPermissionsService,
    public dropdownService: DropdownService,
    private _patientService: PatientService,
    private _commonUiService: CommonUIService,
    public _workConfigurationService: WorkConfigurationService) { }

  ngOnInit() {
    const today = new Date().toISOString();
    this.todayView = today ? this._commonUiService.ConvertToPickerDateFormat(today) : today;
    this.loadData();
    const permission = this._ngxPermissionService.getPermission('ViewWork');
    this.canRedirectWork = permission !== undefined;
    this.searchFilter.dateTypeId = '1';
  }

  ngAfterViewInit() {
    // Observable.fromEvent(this.searchInputElement.nativeElement, 'keyup').debounceTime(3000).subscribe(value =>
    //   this.filterTable()
    // );
  }

  isToday(date: NgbDate) {
    date = new NgbDate(date.year, date.month, date.day);
    return date.equals(this.todayView);
  }

  loadData() {
    this.searchFilter.fromDate = this.todayView;
    this.searchFilter.toDate = this.todayView;
    this.searchFilter.dateTypeId = '1';
    this.priorityOption = PriorityOption;
    this.LoadDoctorList();
    this.loadClientList();
    this.loadFilters();
    this.loadWorkConfig();
    this.searchPatients();
    this.searchWorkNo();
    this.searchMwtNo();
    this.searchDeliveryNoteNo();
    this.loadWorkTypeList();
    //  this.LoadPatientList();
  }

  loadFilters() {
    this.paginationRequestData.page = 1;
    const filter = `${this.getFilterValue(this.searchFilter.clientId)};${this.getFilterValue(this.searchFilter.doctorId)};
          ${this.getFilterValue(this.searchFilter.patientId)};${this.getFilterValue(this.searchFilter.dateTypeId)};
          ${this.getFilterValue(this.searchFilter.workNo ? this.searchFilter.workNo.toString().substring(0, this.searchFilter.workNo.length - 1) : '')};${this.getFilterValue(this.searchFilter.workTypeNo)};
          ${this.getFilterValue(this.searchFilter.deliverNoteNo)};${this.getFilterValue(this.getDate(this.searchFilter.fromDate))};
          ${this.getFilterValue(this.getDate(this.searchFilter.toDate))};${this.getFilterValue(this.searchFilter.state)};
          ${this.getFilterValue(this.searchFilter.priorityId)};${this.getFilterValue(this.searchFilter.patientName)};${this.getFilterValue(this.searchFilter.workTypeCode)};`
    this.paginationRequestData.filter = filter;
    this.workPaginationUrl = API.work.searchWork;
  }

  loadWorkConfig() {
    this._workConfigurationService.GetWorkConfigSelectionList()
      .then(list => {
        let config = list.find(o => o.code == 'PatientSearchSelection');
        if (config) {
          this.showAutocomplete = config.value1;
        }
      });
  }

  loadWorkTypeList(){
    this._apiService.get(API.work_type.getSearchWorkTypesList, true).subscribe(
      res => {
        this.workTypeList = [];
        this.workTypeList = res;
        console.log(this.workTypeList );       
      },
      () => { }
    );
  }

  onChangeWorkTypeCode(value: string) {
    if (value) {
      this.searchFilter.workTypeCode = value;
    } else {
      this.searchFilter.workTypeCode = '';
    }
    this.filterTable();
  }


  getFilterValue(value) {
    return value ? value : '';
  }

  getDate(ngbDate) {
    if (ngbDate && ngbDate.year) {
      return ngbDate.year + '-' + this.GetWholeNumber(ngbDate.month.toString()) + '-' + this.GetWholeNumber(ngbDate.day.toString());
    }
    return ngbDate;
  }
  private GetWholeNumber(num: string) {
    if (num) {
      if (num.length === 1) {
        num = num.padStart(2, '0');
      }
      return num;
    }
  }

  public OnClickTeeth(workId: number, subWorkId: number) {
    if (workId && subWorkId) {
      const url = this._router.serializeUrl(this._router.createUrlTree(['/work'], { queryParams: { workId, subWorkId } }));
      window.open(url, '_blank');
    } else { console.log('work id null'); }
  }

  OnChangeClient(clientId: number) {
    if (clientId) {
      this.searchFilter.clientId = clientId;
    } else {
      this.searchFilter.clientId = undefined;
    }
    this.filterTable();
  }

  OnChangeDoctor(doctorId: number) {
    if (doctorId) {
      this.searchFilter.doctorId = doctorId;
    } else {
      this.searchFilter.doctorId = undefined;
    }
    this.filterTable();
  }

  OnChangePatient(patientId: number) {
    if (patientId) {
      this.searchFilter.patientId = patientId;
    } else {
      this.searchFilter.patientId = undefined;
    }
    this.filterTable();
  }

  filterTable() {
    this.loadFilters();
    this.refresh = true;
  }

  loadClientList() {
    this._apiService.get(API.client.getClientList, true).subscribe(
      res => {
        this.clientList = [];
        this.clientList = res;
        this.clientList = CommonFunctions.Sort(this.clientList, 'name');
      },
      () => { }
    );
  }

  private LoadPatientList() {
    this._apiService.get(API.patient.getPatientList)
      .subscribe((list: Patient[]) => this.patientList = list,
        err => console.error(Error(err)),
        () => {
          this.patientList = CommonFunctions.Sort(this.patientList, 'name', true);
          this.patientList = this._commonDataService.GetAutoCompletePatientList(
            this.patientList
          );
        });
  }

  async searchPatients(query = '') {
    const patientList = await this._patientService.SearchPatients(query);
    // this.patientList = patientList ? this._commonDataService
    //   .GetAutoCompletePatientList(patientList) : [];
    if (patientList && patientList.length > 0) {
      let value = this.patientId.value;
      if (value) {
        if (value.toString().startsWith("'") && value.toString().endsWith("'")
          || value.toString().startsWith('"') && value.toString().endsWith('"')) {
          value = value ? value.toString().replace(/"/g, '').replace(/'/g, '') : '';
        }
      }
      this.patientId.setValue(value);
    }
    this.patientList = patientList ? patientList : [];
  }

  async searchWorkNo(query = '') {
    const workNoList = await this._workService.SearchWorkNo(query);
    this.workNoList = workNoList ? workNoList : [];
  }

  async searchMwtNo(query = '') {
    const mwtNoList = await this._workService.SearchMwtNo(query);
    this.mwtNoList = mwtNoList ? mwtNoList : [];
  }

  async searchDeliveryNoteNo(query = '') {
    const deliveryNoteNoList = await this._delNoteService.SearchDeliveryNoteNo(query);
    this.deliveryNoteNoList = deliveryNoteNoList ? deliveryNoteNoList : [];
  }

  LoadDoctorList() {

    this._apiService.get(API.doctor.getDoctorList)
      .subscribe(res => {
        this.doctorList = res;
      });
  }

  onBack() {
    this._location.back();
  }

  onChangeWorkNoText(value: string) {
    if (value) {
      this.searchFilter.workNo = value;
    } else {
      this.searchFilter.workNo = '';
    }
    this.filterTable();
  }

  onChangeMwtNoText(value: string) {
    if (value) {
      this.searchFilter.workTypeNo = value;
    } else {
      this.searchFilter.workTypeNo = '';
    }
    this.filterTable();
  }

  onChangeDeliveryNoteNoText(value: string) {
    if (value) {
      this.searchFilter.deliverNoteNo = value;
    } else {
      this.searchFilter.deliverNoteNo = '';
    }
    this.filterTable();
  }

  public OnExcelExport() {
    if (this.workList.length > 0 && this.workList[0].totalCount > 1000) {
      this._toastyService.warning({
        title: this._commonDataService.localizationLabelList['job_search'],
        msg: this._commonDataService.localizationLabelList['EXPORT_WARNING_MSG']
      });
      return;
    }

    this.loadFilters();
    const page = this.paginationRequestData.page;
    this.paginationRequestData.page = 1;
    this.paginationRequestData.pageSize = 1000;
    const params = new URLSearchParams();
    for (const key in this.paginationRequestData) {
      params.set(key, this.paginationRequestData[key]);
    }
    const query = params.toString().replace('+', '%20');
    const url = this._apiService.BASE_URL + `/api/Works/SearchWorkDataExcelExport?${query}`;
    window.open(url, '_blank');
    this.paginationRequestData.page = page;
    this.paginationRequestData.pageSize = 30;

  }

  onChangeDateType() {
    if (this.searchFilter.dateTypeId === '0') {
      this.disableDates = true;
      this.searchFilter.fromDate = null;
      this.searchFilter.toDate = null;
      this.filterTable();
    } else {
      this.disableDates = false;
      if (this.searchFilter.fromDate || this.searchFilter.toDate) {
        this.filterTable();
      }
    }
  }

  onKeyUpDates(element: string) {
    if (element === 'from' && (!this.searchFilter.fromDate || CommonFunctions.CheckNgbDateValidity(this.searchFilter.fromDate)) ||
      element === 'to' && (!this.searchFilter.toDate || CommonFunctions.CheckNgbDateValidity(this.searchFilter.toDate))) {
      this.filterTable();
    }
  }

  onSkipCharCountExceedsPatient() {
    this.searchPatients(this.patientId.value);
  }

  onSkipCharCountExceedsWork() {
    this.searchWorkNo(this.workNo.value);
  }

  onSkipCharCountExceedsMwt() {
    this.searchMwtNo(this.mwtNo.value);
  }

  onSkipCharCountExceedsDeliveryNote() {
    this.searchDeliveryNoteNo(this.deliveryNoteNo.value);
  }

}
