import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs/Subscription';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import swal from 'sweetalert2';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { ValidateNgSelect } from '../../../common/services/validators';
import { Document } from '../../entities/document';
import { MainWorkType } from '../../entities/main-work-type';
import { DocumentService } from '../../services/document.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {

  // subscriptions
  workService: Subscription;

  // form groups
  addDocumentFormGroup: FormGroup;//rm

  // lists
  documentList: Document[] = [];//rm
  selectedMainWorkTypeList: MainWorkType[] = [];//rm

  // objects
  document: Document;
  hasLoaded = true;//rm
  PDF = '../../../assets/images/config/pdf.png';
  PNG = '../../../assets/images/config/png.png';
  JPG = '../../../assets/images/config/jpg.png';
  JPEG = '../../../assets/images/config/jpg.png';
  XLS = '../../../assets/images/config/xls.png';
  DOC = '../../../assets/images/config/doc.png';

  showPopUp = false;
  showViewPopUp = false;
  allClosed = false;

  constructor(private _workService: WorkService, private _apiService: ApiService, private __service: DocumentService,
    public commonUiService: CommonUIService, private _commonDataService: CommonDataService,
    private _formBuilder: FormBuilder, private _toastyService: Toast, private _sanitizer: DomSanitizer,
    private _modalService: ModalService) {
    this.addDocumentFormGroup = this._formBuilder.group({
      mainWorkTypeId: ['', ValidateNgSelect],
      file: ['', Validators.required]
    });//rm
    this.workService = this._workService.detailObject.subscribe((args) => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (args.invokeComponents.includes('Document') || args.invokeComponents.includes('*')) {
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this.allClosed = this.selectedMainWorkTypeList.every(x => x.isClosed);
          this.SyncDocumentData();
        }
      }
    });
  }

  ngOnInit() { this.document = new Document(); }

  ngOnDestroy() { this.workService.unsubscribe(); }

  OpenModal(modal: string) {
    let mwtList = [] as MainWorkType[];
    for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
      const mwt = this.selectedMainWorkTypeList[index];
      if (!mwt.isClosed) {
        mwtList.push(mwt);
      }
    }
    this.showPopUp = true;
    const transferData = {
      selectedMainWorkTypeList: mwtList,
    };
    this._modalService.Invoke(modal, true, transferData);
  }

  refreshView() {
    this.SyncDocumentData();
  }
  //#region CRUD

  private async SyncDocumentData() {
    this.documentList = [];
    try {
      this.hasLoaded = false;
      const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
      if (idList) {
        let documentList = await this.__service.GetDocumentListByMainWorkTypeIdList(idList);
        documentList = documentList ? documentList : [];
        if (documentList.length > 0) {
          for (let index = 0; index < documentList.length; index++) {
            const document = documentList[index];
            const currentMainWorkType = this.selectedMainWorkTypeList.find(m => Number(m.id) === document.mainWorkTypeId);
            if (currentMainWorkType) {
              document.order = currentMainWorkType.order;
              document.cssClass = currentMainWorkType.cssClass;
              document.symbol = currentMainWorkType.symbol;
              if (currentMainWorkType.isClosed) {
                document.isClosed = true;
              } else {
                document.isClosed = false;
              }
            }
            document.extension = document.fileName.split('.')[(document.fileName.split('.')).length - 1];
            document.link = this._apiService.BASE_URL + API.main_work_type_work.getDocument + document.id + '/' + document.generateFileName;
            if (document.path) {
              if (document.extension === 'pdf') {
                document.path = document.path.toString().includes('data:application/pdf;base64,')
                  ? document.path : 'data:application/pdf;base64,' + document.path;
              }
              if (document.extension === 'png') {
                document.path = document.path.toString().includes('data:image/png;base64,')
                  ? document.path : 'data:image/png;base64,' + document.path;
              }
              if (document.extension === 'jpg') {
                document.path = document.path.toString().includes('data:image/jpg;base64,')
                  ? document.path : 'data:image/jpg;base64,' + document.path;
              }
              if (document.extension === 'jpeg') {
                document.path = document.path.toString().includes('data:image/jpeg;base64,')
                  ? document.path : 'data:image/jpeg;base64,' + document.path;
              }
              if (document.extension === 'docx') {
                document.path = document.path.toString().includes('https://docs.google.com/gview?url=')
                  ? document.path : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
              }
              if (document.extension === 'xlsx' || document.extension === 'xls') {
                document.path = document.path.toString().includes('https://docs.google.com/gview?url=')
                  ? document.path : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
              }
            }
          }
        }
        this.documentList = documentList;
      }
    } catch (error) { console.log(error); }
    finally { this.hasLoaded = true; }
  }

  private RemoveDocument(id: number) {
    return this._apiService.delete(API.main_work_type_work.deleteDocument, id.toString(), true).map(x => x as boolean).toPromise();
  }

  //#endregion

  //#region Events

  OnOpenPreviewDocumentModal(modal: string, document: Document) {
    if (modal && document) {
      this.showViewPopUp = true;
      this.document = document;
      this.document.safeUrl = this.document.path ? this._sanitizer.bypassSecurityTrustResourceUrl(this.document.path) : undefined;
      this._modalService.Invoke(modal, true, {
        document: this.document, isEdit: true
      });
    }

  }

  CloseModal(modal: string) {
    this.commonUiService.isPopupOpened = false;
    this.commonUiService.CloseModalEx(modal);
  }

  OnRemoveDocument(id: number) {
    if (id) {
      swal({
        title: this._commonDataService.localizationLabelList['document'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      }).then(async (result) => {
        if (result.value) {
          this.commonUiService.isSpinnerVisible = true;
          const isDeleted = await this.RemoveDocument(id);
          if (isDeleted) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['document'],
              msg: this._commonDataService.localizationLabelList['delete_success']
            });
            this.SyncDocumentData();
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['document'],
              msg: this._commonDataService.localizationLabelList['delete_error']
            });
          }
          this.commonUiService.isSpinnerVisible = false;
        }
      });
    }
  }

  OnCloseModal(modal: string) {
    if (modal) {
      this.commonUiService.isPopupOpened = false;
      this.commonUiService.CloseModalEx(modal);
    }
  }

  //#endregion
}
