import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbCalendar } from 'imp-ng-bootstrap';
import { NgbDate } from 'imp-ng-bootstrap/datepicker/ngb-date';
import { ToastData, ToastyService } from 'ng2-toasty';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Localizations } from 'src/app/modules/auxiliary/entities/localizations';
import swal from 'sweetalert2';
import { RequestData } from '../../shared/pagination/requestData';
import { Article } from '../articles/entities/article';
import { ClinicMaterial } from '../auxiliary/entities/clinic-material';
import { Color } from '../auxiliary/entities/Color';
import { ExternalWorksConfiguration } from '../auxiliary/entities/external-works-configuration';
import { Metals } from '../auxiliary/entities/metals';
import { WorkPageConfiguration } from '../auxiliary/entities/work-page-configuration';
import { Client } from '../client/entities/client';
import { Issuer } from '../client/entities/client-issuer';
import { Series } from '../client/entities/series';
import { DentboxWork } from '../common/dentbox/entities/dentbox-work';
import { DentboxLabService } from '../common/dentbox/services/dentbox-lab.service';
import { DropDownOption } from '../common/entities/dropdown-option';
import { ListNames } from '../common/global';
import { ApiService } from '../common/services/api.service';
import { CommonFunctions } from '../common/services/common-functions';
import { CommonDataService } from '../common/services/common.data.service';
import { CommonUIService } from '../common/services/common.ui.service';
import { DropdownService } from '../common/services/dropdown.service';
import { ModalService } from '../common/services/modal.service';
import { Delay, ShowInvalidFormControls, ValidateDatePicker, ValidateNgSelect } from '../common/services/validators';
import { ProviderViewModel } from '../provider/entities/provider-view-model';
import { TaskWorker } from '../tasks/entities/task-worker';
import { WorkType } from '../work-type/entities/work-type';
import { WorkTypeArticleTemplate } from '../work-type/entities/work-type-model-templates';
import API from './../common/api.config.json';
import { ConformityReport } from './entities/conformity-report';
import { DeliveryNote } from './entities/delivery-note';
import { Doctor } from './entities/doctor';
import { Document } from './entities/document';
import { GenerateNumberModel, SavedGeneratedNumber } from './entities/generate-number-model';
import { MainWorkType } from './entities/main-work-type';
import { MwtClinicMaterial } from './entities/main-work-type-clinic-material';
import { MwtColor } from './entities/main-work-type-color';
import { MwtImplantData } from './entities/main-work-type-implant-data';
import { Note } from './entities/note';
import { Patient } from './entities/patient';
import { Prescription } from './entities/prescription';
import { SanityGuaranteeReportModel } from './entities/sanity-guarantee-report-model';
import { SubWork } from './entities/sub-work';
import { UsingReportModel } from './entities/using-report-model';
import { Work } from './entities/work';
import { WorkConfig } from './entities/work-cofig';
import { WorkReport } from './entities/work-report';
import { DeliveryNoteService } from './services/delivery-note.service';
import { ExternalWorkService } from './services/external-work.service';
import { NoteService } from './services/note.service';
import { PhaseTemplateService } from './services/phase-template.service';
import { SubWorkService } from './services/sub-work.service';
import { WorkStatusService } from './services/work-status.service';
import { WorkService } from './services/work.service';
import { MwtColorService } from './services/mwt-color.service';
import { ReportEmailModel } from './entities/report-email-model';
import { ProductTemplateService } from './services/product-template.service';
import { MwtClinicMaterialService } from './services/mwt-clinic-material.service';
import { ArticleTemplateService } from './services/article-template.service';
import * as moment from 'moment';
import { Town } from '../auxiliary/entities/town';
import { WorkConfigurationSelection } from '../configuration/entities/work-configuration-selection';
import { DentboxValidationService } from '../common/dentbox/services/dentbox-validation.service';
import { InvoiceService } from './services/invoice.service';
import { MwtWorkerService } from './services/mwt-worker.service';
import { IncidentReport } from './entities/incident-report';
import { IncidentService } from './services/mwt-incident.service';

declare var $: any;
declare var notEnableTogglePan: boolean;
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss',
    '../../../assets/icon/icofont/css/icofont.scss',
    '../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../node_modules/ng2-toasty/style-material.css'],
  encapsulation: ViewEncapsulation.None
})

export class WorkComponent implements OnInit, AfterViewInit {

  // config
  showWorkModal = false;
  position = 'bottom-right';
  @ViewChild('workSearchDatePickerElement') workSearchDatePickerElement: any;
  @ViewChild('workTypeElement') workTypeElement: any;
  @ViewChild('observationsElement') observationsElement: any;
  @ViewChild('searchInputElement') searchInputElement: ElementRef;

  // form groups
  mainWorkTypeFormGroup: FormGroup;
  implantDataFormGroup: FormGroup;

  // objects
  private _existingWork: Work;

  productDescriptionName = '';
  therapeuticPurposeName = '';

  work: Work;
  selectedWork: Work;
  subWork: SubWork;
  selectedSubWork: SubWork;
  mainWorkType: MainWorkType;
  defaultMwt: MainWorkType;
  generateNumberModel: GenerateNumberModel;
  workSeries: Series;
  numOfDigits = 5;
  workConfig: WorkConfig;
  externalConfig = true;
  patient: Patient;
  implantData: MwtImplantData;
  mwtColor: MwtColor;
  mwtClinicMaterial: MwtClinicMaterial;
  prescription: Prescription;
  document: Document;

  workListPaginationUrl: string;
  requestData: RequestData;
  searchKey: string;
  workFliterStatus: string;
  today: any;
  todayView: any;
  fromDate: any;
  toDate: any;
  refresh: boolean;
  isShowingFilters = false;
  showPieces = false;
  isPrescriptionListLoaded = true;
  allowedImplant = false;
  enableSelectDoctor = false;
  showMwtPopup = false;
  todayDefault: any;
  showMWTPopUp = false;
  showWorkPopUp = false;
  showSubWorkPopUp = false;
  selectedWorkNo = '';
  isShowEmailPopup = false;
  reportEmailmodel: ReportEmailModel;
  reportType = '';
  isWorkPdf = false;
  enableSendButton = false;
  dentboxEnabled = false;

  // reports
  sanityGuaranteeReport: SanityGuaranteeReportModel;
  usingReport: UsingReportModel;
  workReport: WorkReport;
  conformityReport: ConformityReport;
  incidentReport: IncidentReport;

  // lists
  workList: Work[] = [];
  subWorkList: SubWork[] = [];
  mainWorkTypeList: MainWorkType[] = [];
  workTypeTemplateList: WorkType[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];
  workTypeArticleList: WorkTypeArticleTemplate[] = [];
  savedGeneratedNumberList: SavedGeneratedNumber[] = [];
  selectedTeethList: number[] = [];
  prescriptionList: Prescription[] = [];
  manufacturerList: ProviderViewModel[] = [];
  workTypeList: WorkType[] = [];
  mwtColorList: MwtColor[] = [];
  colorList: Color[] = [];
  metalList: Metals[] = [];
  townList: Town[] = [];
  clinicMaterialList: ClinicMaterial[] = [];
  taskWorkerList: TaskWorker[] = [];
  localizationList: Localizations[] = [];
  workStatesDropDownList: DropDownOption[] = [];
  mainWorkTypeStatusDropDownList: DropDownOption[] = [];

  constructor(
    private _formBuilder: FormBuilder, private _statusService: WorkStatusService,
    private _subWorkService: SubWorkService, public _workService: WorkService,
    private _deliveryNoteService: DeliveryNoteService, private _phaseTemplateService: PhaseTemplateService,
    private _route: ActivatedRoute, private _mwtClinicMaterialService: MwtClinicMaterialService,
    private _articleTemplateService: ArticleTemplateService,
    private _router: Router, private _translateService: TranslateService,
    private _workStatusService: WorkStatusService, private _toastyService: ToastyService, private _externalService: ExternalWorkService,
    private _apiService: ApiService,
    public commonUiService: CommonUIService, private _phaseService: PhaseTemplateService,
    calendar: NgbCalendar, private _modalService: ModalService,
    private _noteService: NoteService, public commonDataService: CommonDataService,
    public _dropdownService: DropdownService,
    private _dentboxLabService: DentboxLabService,
    private _dentboxValidationService: DentboxValidationService,
    private _invoiceService: InvoiceService,
    private __service: MwtColorService, private __productTempService: ProductTemplateService,
    private _mwtWorkerService: MwtWorkerService, private _incidentService: IncidentService) {
    this.commonUiService.isSpinnerVisible = true;
    this.InitModels(); // models
    document.body.classList.add('work-page');
    this.LoadFormGroups(); // load form groupss
    this.workListPaginationUrl = API.work.getPaginatedWorkList; // set work pagination url
    this.SetUIChanges(); // show/hide UI elements
    this._statusService.statusChangedSubject.subscribe((isChanged) => {
      if (isChanged) { this.ChangeStatus(); }
    });
    // toggle side bar when click on work items
    $(document).ready(() => {
      $('#worktoggle').on('click', function () {
        $('.sidebar').addClass('active');
        $('.slider-menu .topicon label i').addClass('icon-menu-mini');
        $('.slider-menu .topicon label i').addClass('icon-menu-expand');
        $('.content-wrapper').addClass('expand');
        $('.work-title').addClass('expand');
      });
    });
    this.todayDefault = calendar.getToday();
    this._workService.isShowEmailPopup = false;
    this.reportEmailmodel = new ReportEmailModel();

  }

  async ngOnInit() {
    this.commonUiService.isActiveBothPanel = true;
    this.commonUiService.isActiveLeftPanelOnly = false;

    this.InitProps(); // component props
    await this.InitDropdowns(); // dropdowns

    this.GetResources().subscribe((response) => {
      // this.metalList = response[0] ? response[0].filter(x => x.status) : [];
      // this.colorList = response[1] ? response[1].filter(x => x.status) : [];
      // this.clinicMaterialList = response[2] ? response[2].filter(x => x.status) : [];
      this.taskWorkerList = response[0];
      this.manufacturerList = response[1];
      this.LoadWorkConfigurations(response[2]);
      this.LoadWorkConfigurationsSelection(response[3]);
    }); // page resources

    await this.QueryParamsListener(); // listen to the query parameters

    // when language got changed, translate work statistic charts
    this._translateService.onLangChange
      .subscribe(async (obj: { lang: string, translations: any }) => {
        if (obj && this.commonUiService.isActiveStatisticPanel) {
          this.commonDataService.SetIsStatistic(true);
        }
        this._dropdownService.LoadList([ListNames.WorkStatesList]);
        // this.workStatesDropDownList = await this._workService.GetWorkStatesDropdownList();
      });

    this.dentboxEnabled = this._dentboxValidationService.checkDentboxServices();

    this.commonUiService.isSpinnerVisible = false;
    this._workService.isShowEmailPopup = false;

  }

  ngAfterViewInit() {
    Observable.fromEvent(this.searchInputElement.nativeElement, 'keyup')
      .distinctUntilChanged()
      .debounceTime(1000).subscribe(value =>
        this.OnFilterWork('search')
      );
  }

  //#region PageResources

  /**
   * Load all form groups with their validations
   */
  private LoadFormGroups() {
    this.mainWorkTypeFormGroup = this._formBuilder.group({
      name: [], workStatusId: ['', [ValidateNgSelect]],
      receiveDate: [], deliveryDate: ['', [ValidateDatePicker]],
      deliveryTime: ['', [Validators.minLength(4)]], realDeliveryDate: [],
      realDeliveryTime: [], workTypeId: ['', [ValidateNgSelect]],
      workTypeName: ['', [ValidateNgSelect]], providerManufacId: [],
      model: ['', []], reference: ['', []],
      longitud: [], pieces: [], occlusal: [],
      note: [], clinicMaterialName: [], quantity: [],
      materialNote: [], colorName: [], piecesSelectionText: [],
      localizationName: [], colorArticleName: [], colorMetalName: [],
      prescription: [], document: []
    });
    this.implantDataFormGroup = this._formBuilder.group({
      mainWorkTypeId: [], model: ['', [Validators.required]],
      reference: ['', [Validators.required]], apical: [], note: [],
    });
  }

  /**
   * Work page configurations (show/hide panels according to the configured auxiliary table)
   */
  private LoadWorkConfigurations(list: WorkPageConfiguration[]) {
    try {
      if (list) {
        this.workConfig.FASES = list.find(x => x.description === 'Fase') ?
          list.find(x => x.description === 'Fase').isRequired : false;
        this.workConfig.TASKS = list.find(x => x.description === 'Tareas') ?
          list.find(x => x.description === 'Tareas').isRequired : false;
        this.workConfig.ARTICLES = list.find(x => x.description === 'Articulo') ?
          list.find(x => x.description === 'Articulo').isRequired : false;
        this.workConfig.BUDGETS = list.find(x => x.description === 'Presupuestos') ?
          list.find(x => x.description === 'Presupuestos').isRequired : false;
        this.workConfig.CLINIC_MATERIALS = list.find(x => x.description === 'Materiales de la clinica') ?
          list.find(x => x.description === 'Materiales de la clinica').isRequired : false;
        this.workConfig.COLORS = list.find(x => x.description === 'Color/Metal/Piezas') ?
          list.find(x => x.description === 'Color/Metal/Piezas').isRequired : false;
        this.workConfig.PRODUCTS = list.find(x => x.description === 'Productos') ?
          list.find(x => x.description === 'Productos').isRequired : false;
        this.workConfig.IMPLANT_DATA = list.find(x => x.description === 'Datos implante') ?
          list.find(x => x.description === 'Datos implante').isRequired : false;
        this.workConfig.DELIVERY_NOTES = list.find(x => x.description === 'Albaranes') ?
          list.find(x => x.description === 'Albaranes').isRequired : false;
        this.workConfig.WORK_TYPES = list.find(x => x.description === 'FT/DP') ?
          list.find(x => x.description === 'FT/DP').isRequired : false;
        this.workConfig.RECORD_OF_INCIDENTS = list.find(x => x.description === 'Registro de incidencias') ?
          list.find(x => x.description === 'Registro de incidencias').isRequired : false;
      }
    } catch (error) { console.log(error); }
  }

  public LoadWorkConfigurationsSelection(list: WorkConfigurationSelection[]) {
    this.workConfig.ESTABLISH_FINISHED_WORK_WHEN_MAKING_DELIVERY_NOTES =
      list.find(x => x.code === 'FinishedWorkWhenMakingDeliveryNotes') ?
        list.find(x => x.code === 'FinishedWorkWhenMakingDeliveryNotes').value1 : false;
  }

  /**
   * Initialize models
   */
  private async LoadExternalConfig() {
    this.externalConfig = true;
    this.GetExternalConfig().then((response) => {
      if (response) {
        if (response[0].status === false) {
          const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
          if (idList) {
            this._externalService.GetMWTExternalDataListByMainWorkTypeIdList(idList).then(list => {
              if (list.length === 0) { this.externalConfig = false; }
            });
          }
        }
      }
    });
  }

  /**
   * Initialize models
   */
  private InitModels() {
    this.patient = new Patient();
    this.workConfig = new WorkConfig();
    this.work = new Work();
    this.selectedWork = new Work();
    this.subWork = new SubWork();
    this.selectedSubWork = new SubWork();
    this.mainWorkType = new MainWorkType();
    this.generateNumberModel = new GenerateNumberModel();
    this.workSeries = new Series();
    this.implantData = new MwtImplantData();

    this.sanityGuaranteeReport = new SanityGuaranteeReportModel();
    this.sanityGuaranteeReport.issuer = new Issuer();
    this.usingReport = new UsingReportModel();
    this.usingReport.issuer = new Issuer();
    this.workReport = new WorkReport();
    this.workReport.labNote = [];
    this.workReport.clinicNote = [];
    this.workReport.generalNote = [];
    this.conformityReport = new ConformityReport();
    this.incidentReport = new IncidentReport();
  }

  /**
   * Initialize component properties
   */
  private InitProps() {
    this.workFliterStatus = '0';
    this.today = new Date().toISOString();
    this.todayView = this.today ? this.commonUiService.ConvertToPickerDateFormat(this.today) : this.today;
    this.requestData = new RequestData(1, 10, new Date().toISOString().substring(0, 10), '0, , null, null', '', 0, true);
  }

  /**
   * Get search parameters from url and get work object and select it
   */
  private async QueryParamsListener() {
    this._route.queryParams.subscribe(async params => {
      if (params['referenceId']) {
        const work = await this._workService.GetWorkById(params['referenceId']);
        if (work) {
          this.searchKey = work.generateNo;
          this.OnFilterWork('search');
          await this.OnClickWork(work);
          this.ResetUrlQueryParams();
        }
      } else if (params['workid']) {
        if (sessionStorage.getItem('78dab9bb04fa45aa8f55ee9e01184a9f')) {
          const data = JSON.parse(atob(sessionStorage.getItem('78dab9bb04fa45aa8f55ee9e01184a9f')));
          if (data) {
            this.searchKey = data.work.generateNo;
            this.OnFilterWork('search');
            await this.AfterWorkSaved(
              {
                work: data.work,
                subWork: data.subWork,
                notes: data.notes,
                isEdit: false,
                existing: data.isExisting
                //  dentboxWork: data.dentboxWork // it will be undefined
              }, new MainWorkType({
                receiveDate: data.mwtWork.orderDate,
                deliveryDate: data.mwtWork.deliveryDate,
                workTypeId: data.mwtWork.workTypeId,
                workTypeName: data.mwtWork.workTypeName,
                reservationId: data.mwtWork.reservationId,
                isReservation: true,
                observation: data.mwtWork.observation,
                deliveryTime: data.mwtWork.deliveryTime,
                workReservation: data.mwtWork.workReservation
              }));
            this.ResetUrlQueryParams();
          }
        }
      } else {
        if (params['is_work_expand']) {
          this.commonUiService.isActiveLeftPanelOnly = true; this.ResetUrlQueryParams();
        }

        if (params['6463F9BE424D3B00E887576840F14E38']) {
          const data: {
            work: Work,
            subWork: SubWork,
            dentboxWork: DentboxWork,
            notes: Note[],
            isExisting: boolean
          } = JSON.parse(params['6463F9BE424D3B00E887576840F14E38']);
          if (data) {
            this.searchKey = data.work.generateNo;
            this.OnFilterWork('search');
            await this.AfterWorkSaved(
              {
                work: data.work,
                subWork: data.subWork,
                notes: data.notes,
                isEdit: false,
                existing: data.isExisting
                //  dentboxWork: data.dentboxWork
              }, new MainWorkType({
                receiveDate: data.dentboxWork.entryDate,
                deliveryDate: data.dentboxWork.deliveryDate,
                observation: (data.notes[0].content) ? data.notes[0].content : '',
                alert: (data.notes[1].content) ? data.notes[1].content : '',
                dentboxWorkGuid: data.dentboxWork.guid ? data.dentboxWork.guid : '',
                isDentboxWork: true
              }));
            this.ResetUrlQueryParams();
          }
        }

        if (params['workId'] && params['subWorkId']) {
          this.commonUiService.isSpinnerVisible = true;
          const sw = await this._subWorkService
            .GetSubWorkById(Number(params['subWorkId']));
          const work = await this._workService.GetWorkById(Number(params['workId']));
          if (work && sw) {
            this.searchKey = work.generateNo;
            this.OnFilterWork('search');
            this.selectedWork = work;
            this._existingWork = work;
            this.selectedSubWork = sw;
            await this.OnClickWork(work);
            this.ResetUrlQueryParams();
          }
        }
      }
    });
  }

  /**
   * Load dropdown lists
   */
  private async InitDropdowns() {
    try {
      this._dropdownService.LoadList([
        ListNames.ClientList,
        ListNames.PatientList,
        ListNames.SubWorkSituationList,
        ListNames.ClinicMaterialList,
        ListNames.WorkStatesList,
        ListNames.ColorList,
        ListNames.LocalizationList,
        ListNames.MetalList,
        ListNames.WorkTypeTemplateList,
        ListNames.TownList
      ]);
      await this._dropdownService.LoadListAsync([
        ListNames.MainWorkTypeStatusList,
        ListNames.ShippingFormList,
        ListNames.ExternalCenterList
      ]);
      // this.workStatesDropDownList = this._dropdownService.workStatesList;
      const mainWorkTypeStatusList = this._dropdownService.mainWorkTypeStatusList;
      this.clinicMaterialList = this._dropdownService.clinicMaterialList;
      this.metalList = this._dropdownService.metalList;
      this.colorList = this._dropdownService.colorList;
      this.townList = this._dropdownService.townList;
      this.mainWorkTypeStatusDropDownList = this.commonDataService.GetDropDownList(mainWorkTypeStatusList, 'description');
    } catch (error) { console.log(error); }
  }

  /**
   * Get list of metals, clinics materials, colors, taskWorkers and manufacturers reponses at once
   * Awaits until every list loads
   */
  private GetResources() {
    try {
      // const metalResponse = this._apiService.get(API.metals.getMetalsList).pipe(map(list => list as Metals[])).toPromise();
      // const colorResponse = this._apiService.get(API.color.getColorList).pipe(map(list => list as Color[])).toPromise();
      // const clinicMaterialsResponse = this._apiService.get(API.clinic_material.getClinicMaterialList)
      //   .pipe(map(list => list as ClinicMaterial[])).toPromise();
      const taskWorkersResponse = this._apiService.get(API.task_worker.getTaskWorkerListAsync, true)
        .pipe(map(list => list as TaskWorker[])).toPromise();
      const manufacturersResponse = this._apiService.get(API.provider_manufacturer_details.getProviderManufacturerDetails, true)
        .pipe(map(list => list as ProviderViewModel[])).toPromise();
      const workConfigsResponse = this._apiService.get(API.work_page_configuration.workPageConfigurationList)
        .pipe(map(list => list as WorkPageConfiguration[])).toPromise();
      const workConfigurationSelectionResponse = this._apiService.get(API.work_page_configuration.getWorkConfigSelection)
        .pipe(map(list => list as WorkConfigurationSelection[])).toPromise();

      return forkJoin([taskWorkersResponse, manufacturersResponse, workConfigsResponse, workConfigurationSelectionResponse, workConfigurationSelectionResponse]);
    } catch (error) { console.log(error); }
  }

  /**
   * Get external configuration
   * Awaits until every list loads
   */
  private GetExternalConfig() {
    try {
      return this._apiService.get(API.external_works_configuration.loadList)
        .pipe(map(list => list as ExternalWorksConfiguration[])).toPromise();
    } catch (error) { console.log(error); }
  }

  /**
   * get verbal localization list
   */
  private async GetVerbalLocalizationList() {
    return this._apiService.get(API.localization.getLocalization).map(x => x as Localizations[]).toPromise();
  }

  //#endregion

  //#region Events

  OnOpenWorkModal(modal: string, isEdit: boolean) {
    if (!isEdit) {
      this.work = new Work();
      this.selectedWork = new Work();
      this.selectedSubWork = new SubWork();
      this.ClearLists();
    } else {
      this.work = Object.assign(this.work, this.selectedWork);
      this.work.clientId = this.selectedWork.clientId ? this.selectedWork.clientId.toString() : '0';
      this.work.patientId = this.selectedWork.patientId ? this.selectedWork.patientId.toString() : '0';
    }
    this.showWorkPopUp = true;
    this._modalService.Invoke(modal, true, {
      selectedWork: this.work,
      selectedSubWork: this.selectedSubWork,
      isEdit: isEdit
    }, isEdit);
  }

  OnOpenSubWorkModal(modal: string, isEdit: boolean) {
    this.showSubWorkPopUp = true;
    this._modalService.Invoke(modal, true,
      { selectedWork: this.selectedWork, selectedSubWork: this.selectedSubWork, isEdit: isEdit }, isEdit);
  }

  /**
   * Work list filters toggle in left bar
   */
  ToggleFilters() { this.isShowingFilters = !this.isShowingFilters; }

  /**
   * Work date filters toggle in left bar
   * load all pending works for selected work
   */
  OnChangeSearchDate() {
    this.today = this.todayView ? this.commonUiService.ChangeNgbDateToCustomFormat(this.todayView) : this.todayView;
    this.workFliterStatus = '0';
    this.requestData = new RequestData(1, 10, this.today, '0, , null, null', '', 0, true);
    this.refresh = true;
  }

  onSearchDateclick() { this.workSearchDatePickerElement.toggle(); }

  isToday(date: NgbDate) {
    date = new NgbDate(date.year, date.month, date.day);
    return date.equals(this.todayView);
  }

  isSelected(date: NgbDate) {
    date = new NgbDate(date.year, date.month, date.day);
    return date.equals(this.todayDefault);
  }

  /**
   * on change filter
   * @param control | the html control which involved to filter works ('search' | 'date' | 'status')
   */
  OnFilterWork(control: string) {
    try {
      if (control) {
        //  this.selectedWork = new Work();
        //   this.selectedSubWork = new SubWork();

        switch (control) {
          case 'search':
            if (this.searchKey && this.searchKey.length > 0) {
              // filters will be null and status will be 'Todos'
              this.workFliterStatus = 'Todos';
              this.fromDate = null;
              this.toDate = null;
              this.isShowingFilters = false;
            } else {
              this.selectedWork = new Work();
              this.selectedSubWork = new SubWork();
            }
            break;
          case 'date':
            this.selectedWork = new Work();
            this.selectedSubWork = new SubWork();
            // if any date has been selected, search value will be empty
            if (this.fromDate || this.toDate) {
              this.searchKey = '';
              if (this.fromDate && this.toDate && this.workFliterStatus == '0') {
                this.workFliterStatus = 'Todos';
              }
              if (this.fromDate && (this.toDate == undefined || this.toDate == null)) {
                this.toDate = this.fromDate;
                this.workFliterStatus = 'Todos';
              }
            }
            break;
          case 'status':
            this.selectedWork = new Work();
            this.selectedSubWork = new SubWork();
            /**
             * when user selects a status, from date will be one month (30 days)
             * passed date from today and search key will be empty
             */
            if (this.workFliterStatus.toLocaleLowerCase() === 'todos'
              || this.workFliterStatus.toLocaleLowerCase() === 'pendiente' ||
              this.workFliterStatus.toLocaleLowerCase() === 'finalizado'
              || this.workFliterStatus.toLocaleLowerCase() === 'cancelada') {
              this.isShowingFilters = true;
              const from = new Date();
              this.searchKey = '';
              from.setDate(new Date().getDate() - 30);
              this.fromDate = this.fromDate ? this.fromDate :
                this.commonUiService.ConvertToPickerDateFormat(from.toISOString());
              this.toDate = this.toDate ? this.toDate :
                this.commonUiService.ConvertToPickerDateFormat(new Date().toISOString());
            }
            break;
        }
      }
      this.Filter(); // call filter api
    } catch (error) { console.log(error); }
  }

  /**
   * call the api with filteration values
   */
  Filter() {
    try {
      const fromDate = this.fromDate ? this.commonUiService.ChangeNgbDateToCustomFormat(this.fromDate).toString() : null;
      const toDate = this.toDate ? this.commonUiService.ChangeNgbDateToCustomFormat(this.toDate).toString() : null;
      this.requestData = new RequestData(1, 10, '', this.workFliterStatus + ', ' +
        this.searchKey + ', ' + fromDate + ', ' + toDate, '', 0, true);
      this.refresh = true;
    } catch (error) { console.log(error); }
  }

  FilterChanged(filter: any) {
    if (filter) {
      this.searchKey = filter.value;
      this.OnFilterWork(filter.filter);
    }
  }

  async OnClickWork(work: Work) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      notEnableTogglePan = true;
      this.commonUiService.isSidebarDisabled = true;
      this.selectedSubWork = this._existingWork ? this.selectedSubWork : new SubWork(); // cannot load recent sub work
      this.ClearLists();
      this.selectedWork = work;
      this.SetExpandViewUI();
      if (this.selectedWork.id) {
        const list = await this._subWorkService.GetSubWorkListByWorkId(this.selectedWork.id);
        if (list) {
          this.subWorkList = list;
          this.subWorkList.sort(function (a, b) {
            return (a.createdDate > b.createdDate) ? -1 : ((a.createdDate < b.createdDate) ? 1 : 0);
          });
           //when searching for a new work, subwork is selected
           this.selectedSubWork = this.subWorkList[0];   
          if (this.selectedSubWork != null) {
            if (this.selectedSubWork.id) {
              const subWork = this.subWorkList.find(x => x.id === this.selectedSubWork.id);
              if (subWork) { this.selectedSubWork = subWork; }
            } else {
              let pendingList = this.subWorkList.filter(x => x.subWorkStatus.toLowerCase() === 'pendiente');
              pendingList = pendingList ? CommonFunctions.SortByDate(pendingList, 'createdDate') : [];
              if (pendingList.length > 0) {
                this.selectedSubWork = pendingList[0];
                // this.selectedSubWork = pendingList[pendingList.length - 1];
              } else { this.selectedSubWork = this.subWorkList[0]; }
            }
            this.OnSelectSubWork(this.selectedSubWork);
          }
        }
        if (this.selectedSubWork && this.selectedWork.generateNo
          && this.selectedSubWork.generateNo) {
          this.selectedWorkNo = (this.selectedWork.generateNo +
            this.selectedSubWork.generateNo.toString()).toString();
        }
        this.commonUiService.isSpinnerVisible = false;
      }
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  OnRemoveWork(workId: number) {
    if (workId) {
      // tslint:disable-next-line: max-line-length
      if (this._workService.invoiceData.length > 0 || this._workService.deliveryNoteData.length > 0 || this._workService.budgetData.length > 0) {
        return this._toastyService.warning({
          title: this.commonDataService.localizationLabelList['work'],
          msg: this.commonDataService.localizationLabelList['cannot_delete'],
        });
      } else {
        swal({
          title: this.commonDataService.localizationLabelList['work'],
          text: this.commonDataService.localizationLabelList['delete_warning'],
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.commonDataService.localizationLabelList['common_yes'],
          cancelButtonText: this.commonDataService.localizationLabelList['common_cancel']
        }).then((result) => {
          if (result.value) {
            this._workService.RemoveWork(workId)
              .then(response => {
                this.selectedWork = new Work();
                this.work = new Work();
                this.selectedSubWork = new SubWork();
                if (response['_isSuccsess']) {
                  const index: number = this.workList.findIndex(w => w.id === workId);
                  if (index !== -1) { this.workList.splice(index, 1); }
                  this._toastyService.success({
                    title: this.commonDataService.localizationLabelList['work'],
                    msg: this.commonDataService.localizationLabelList['delete_success'],
                    showClose: true,
                    theme: 'default',
                    timeout: 5000,
                    onAdd: (toast: ToastData) => { },
                    onRemove: (toast: ToastData) => { }
                  });
                } else {
                  this._toastyService.error({
                    title: this.commonDataService.localizationLabelList['work'],
                    msg: this.commonDataService.localizationLabelList['delete_error'],
                    showClose: true,
                    theme: 'default',
                    timeout: 5000,
                    onAdd: (toast: ToastData) => { },
                    onRemove: (toast: ToastData) => { }
                  });
                }
              });
          }
        });
      }

    }
  }

  // Sub Work

  OnSelectSubWork(subWork: SubWork) {
    if (subWork) {
      this.selectedSubWork = subWork;
      this.selectedMainWorkTypeList = [];
      this.mainWorkTypeList = this.selectedSubWork.mainWorkTypeList ? this.selectedSubWork.mainWorkTypeList : [];
      const pendingList = this.mainWorkTypeList.filter(x => x.workTypeStatusName.toLowerCase() === 'pendiente');
      if (pendingList) {
        for (let i = 0; i < pendingList.length; i++) {
          const mainWorkType = pendingList[i];
          mainWorkType.isSelected = false;
          mainWorkType.receiveDate = mainWorkType.receiveDate ? mainWorkType.receiveDate.toString().substring(0, 10) : '';
          mainWorkType.deliveryDate = mainWorkType.deliveryDate ? mainWorkType.deliveryDate.toString().substring(0, 10) : '';
          const index = this.mainWorkTypeList.findIndex(x => Number(x.id) === Number(mainWorkType.id));
          mainWorkType.order = index + 1;
          if (!mainWorkType.isSelected) {
            // newly selected
            mainWorkType.isSelected = !mainWorkType.isSelected;
            const isIncluded =
              this.selectedMainWorkTypeList.findIndex(x => Number(x.id) === Number(mainWorkType.id)) > -1;
            if (!isIncluded) { this.selectedMainWorkTypeList.push(mainWorkType); }
            this.AssignMainWorkTypeBackgroundColorClass(mainWorkType, index);
            this.AssignMainWorkTypeBadgeColor(mainWorkType, index);
          } else {
            // already selected
            mainWorkType.isSelected = !mainWorkType.isSelected;
            const index_: number = this.selectedMainWorkTypeList.findIndex(x => Number(x.id) === Number(mainWorkType.id));
            if (index_ !== -1) { this.selectedMainWorkTypeList.splice(index_, 1); }
            mainWorkType.cssClass = '';
            mainWorkType.symbol = '';
          }
        }
        this.selectedMainWorkTypeList = pendingList;
        // check external config
        this.LoadExternalConfig();
        // check if any selected main work type has allowed implant data
        this.allowedImplant = this.selectedMainWorkTypeList.some(mwt => mwt.isImplantData);
        this._workService.SetDetailObject({
          selectedMainWorkTypeList: this.selectedMainWorkTypeList,
          selectedMainWorkType: new MainWorkType(),
          selectedSubWork: this.selectedSubWork,
          selectedWork: this.selectedWork,
          mainWorkTypeList: this.mainWorkTypeList,
          invokeComponents: ['*'],
          transferable: []
        });
      }
      this.selectedWorkNo = (this.selectedWork.generateNo + subWork.generateNo.toString()).toString();
    }
  }

  OnRemoveSubWork() {
    this.subWork = this.selectedSubWork;
    if (this.subWork !== undefined) {
      // tslint:disable-next-line: max-line-length
      if (this._workService.invoiceData.length > 0 ||
        this._workService.deliveryNoteData.length > 0
        || this._workService.budgetData.length > 0) {
        return this._toastyService.warning({
          title: this.commonDataService.localizationLabelList['work'],
          msg: this.commonDataService.localizationLabelList['cannot_delete'],
        });
      } else {
        swal({
          title: this.commonDataService.localizationLabelList['sub_work'],
          text: this.commonDataService.localizationLabelList['delete_warning'],
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.commonDataService.localizationLabelList['common_yes'],
          cancelButtonText: this.commonDataService.localizationLabelList['common_cancel']
        }).then((result) => {
          if (result.value) {
            this.showSubWorkPopUp = false;
            this._subWorkService.RemoveSubWork(this.subWork)
              .then(response => {
                if (response) {
                  this._toastyService.success({
                    title: this.commonDataService.localizationLabelList['sub_work'],
                    msg: this.commonDataService.localizationLabelList['delete_success'],
                    showClose: true,
                    theme: 'default',
                    timeout: 5000,
                    onAdd: (toast: ToastData) => { },
                    onRemove: (toast: ToastData) => { }
                  });
                  this.refresh = true;
                  this.OnClickWork(this.selectedWork);
                } else {
                  this._toastyService.error({
                    title: this.commonDataService.localizationLabelList['sub_work'],
                    msg: this.commonDataService.localizationLabelList['delete_error'],
                    showClose: true,
                    theme: 'default',
                    timeout: 5000,
                    onAdd: (toast: ToastData) => { },
                    onRemove: (toast: ToastData) => { }
                  });
                }
              });
          }
        });
      }
    }
  }

  // MainWorkType

  OnOpenAddWorkTypeModal(defaultMwt?: MainWorkType) {
    if (defaultMwt) { this.defaultMwt = defaultMwt; } else {
      this.defaultMwt = null;
    }
    this.showMwtPopup = true;
  }

  OnMwtPopupClose(isAfterSave: boolean) {
    if (isAfterSave) {
      this.refresh = true;
      this.searchKey = this.selectedWork.generateNo;
      this.OnFilterWork('search');
      this.OnClickWork(this.selectedWork);
    }
    this.showMwtPopup = false;
  }

  OnOpenAddImplantDataModel(modal: string) { if (modal) { this.commonUiService.openModal(modal); } }

  OnSelectManufacturer(manufacturer: ProviderViewModel) {
    this.implantData.providerManufacId = manufacturer.id;
    this.implantData.providerManufacName = manufacturer.name;
  }

  OnSaveMwtImplantData(event: any) {
    if (this.implantDataFormGroup.invalid) { ShowInvalidFormControls(this.implantDataFormGroup); return; }
    this.implantData.workId = this.selectedWork.id;
    this.SaveImplantData(this.implantData)
      .then((response) => {
        if (response['_isSuccsess']) {
          this._toastyService.success({
            title: this.commonDataService.localizationLabelList['implant_data'],
            msg: this.commonDataService.localizationLabelList['save_success'],
            showClose: true,
            theme: 'default',
            timeout: 5000,
            onAdd: (toast: ToastData) => { },
            onRemove: (toast: ToastData) => { }
          });
          if (!this.implantData.id) {
            const implantData: MwtImplantData = response['_data'] as MwtImplantData;
            const mwt: MainWorkType = this.selectedMainWorkTypeList.find(x => Number(x.id) === implantData.mainWorkTypeId);
            if (mwt) {
              implantData.cssClass = mwt.cssClass;
              implantData.symbol = mwt.symbol;
            }
            const manufacturer: ProviderViewModel = this.manufacturerList.find(x => x.id === implantData.providerManufacId);
            if (manufacturer) {
              implantData.manufacturerName = manufacturer.name;
              implantData.providerManufacName = manufacturer.name;
            }
          }
          this._workService.SetDetailObject({
            selectedMainWorkTypeList: this.selectedMainWorkTypeList,
            selectedMainWorkType: this.selectedMainWorkTypeList[0],
            selectedSubWork: this.selectedSubWork,
            selectedWork: this.selectedWork,
            mainWorkTypeList: this.mainWorkTypeList,
            invokeComponents: ['ImplantData']
          });
        } else {
          this._toastyService.error({
            title: this.commonDataService.localizationLabelList['implant_data'],
            msg: this.commonDataService.localizationLabelList['save_error'],
            showClose: true,
            theme: 'default',
            timeout: 5000,
            onAdd: (toast: ToastData) => { },
            onRemove: (toast: ToastData) => { }
          });
        }
        if (event.path) { (event.path[6]).classList.remove('md-show'); }
      });
  }

  OnChangeMwtImplantData(implantData: MwtImplantData) {
    if (implantData) {
      this.implantData = implantData;
      this.OnOpenAddImplantDataModel('implant-data-modal');
    }
  }

  OnPrintReport(reportModel: { mainWorkType: MainWorkType, report: string }) {
    if (reportModel) {
      switch (reportModel.report) {
        case 'sanity_guarantee_report':
          this.OnPrintSanityGuaranteeReport(reportModel.mainWorkType, true);
          break;
        case 'using_report':
          this.OnPrintUsingReport(reportModel.mainWorkType, true);
          break;
        case 'work_report':
          this.OnPrintWorkReport(reportModel.mainWorkType, true);
          break;
        case 'conformity_report':
          this.OnPrintConformityReport(reportModel.mainWorkType, true);
          break;
        case 'incident_report':
          this.OnPrintIncidentReport(reportModel.mainWorkType, true);
          break;
        default:
          console.log('no report provided!');
          break;
      }
    }
  }

  OnSendEmailModalOpen(reportModel: { mainWorkType: MainWorkType, report: string }) {
    this._workService.isShowEmailPopup = true;

    try {
      this.reportEmailmodel = new ReportEmailModel();
      this.reportType = reportModel.report;
      const user = this.commonDataService.GetUserFromSession();

      this.commonUiService.isSpinnerVisible = true;
      this.enableSendButton = false;
      const list = this._dropdownService.clientList;
      const client: Client = list.find(x => Number(x.id) === Number(this.selectedWork.clientId));
      if (reportModel) {

        this.reportEmailmodel.senderEmail = user != null && user.userRoleEmail != null ? user.userRoleEmail : null;
        this.reportEmailmodel.password = user != null && user.userRolePassword != null ? user.userRolePassword : null;
        // this.reportEmailmodel.receiverEmail = 'jayanga.chandrakumara@qualitapps.com';
        this.reportEmailmodel.receiverEmail = client ? client.email : '';
        const typeString = this.GetReportTypeTranslate(reportModel.report);

        this.reportEmailmodel.subject = typeString + ' - (' + reportModel.mainWorkType.generateNo + ')';
        this.reportEmailmodel.message = typeString + ' - (' + reportModel.mainWorkType.generateNo + ')';
        this.reportEmailmodel.attachmentName = typeString + ' - (' + reportModel.mainWorkType.generateNo + ')' + '.pdf';
        this.reportEmailmodel.reportType = reportModel.report;

        this.SetDataForSendMail(reportModel.mainWorkType, reportModel.report);


        if (!this.reportEmailmodel.senderEmail && !this.reportEmailmodel.receiverEmail) {
          return this._toastyService.warning({
            title: this.commonDataService.localizationLabelList['email'],
            msg: this.commonDataService.localizationLabelList['sender_recipient_missing'],
          });
        } else if (!this.reportEmailmodel.receiverEmail) {
          return this._toastyService.warning({
            title: this.commonDataService.localizationLabelList['email'],
            msg: this.commonDataService.localizationLabelList['sender_missing'],
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }


  async SetDataForSendMail(mainWorkType: MainWorkType, report: string) {
    this.commonUiService.isSpinnerVisible = true;
    switch (report) {
      case 'work_report':
        this.OnPrintWorkReport(mainWorkType, false);
        await Delay(1000);
        break;
      case 'conformity_report':
        this.OnPrintConformityReport(mainWorkType, false);
        await Delay(500);
        break;
      case 'using_report':
        this.OnPrintUsingReport(mainWorkType, false);
        await Delay(500);
        break;
      case 'sanity_guarantee_report':
        this.OnPrintSanityGuaranteeReport(mainWorkType, false);
        await Delay(500);
        break;
      case 'incident_report':
        this.OnPrintIncidentReport(mainWorkType, false);
        await Delay(500);
        break;
    }
    this.commonUiService.isSpinnerVisible = false;
  }

  GetReportTypeTranslate(reportType: string) {
    switch (reportType) {
      case 'work_report':
        return this.commonDataService.localizationLabelList['work_report'];
      case 'conformity_report':
        return this.commonDataService.localizationLabelList['conformity_report'];
      case 'using_report':
        return this.commonDataService.localizationLabelList['usability_report'];
      case 'sanity_guarantee_report':
        return this.commonDataService.localizationLabelList['guarantee_report'];
      case 'incident_report':
        return this.commonDataService.localizationLabelList['incident_report'];
    }
  }


  async OnSendEmail() {
    if (this.reportEmailmodel) {
      if (!this.reportEmailmodel.receiverEmail) {
        return this._toastyService.error({
          title: this.commonDataService.localizationLabelList['email'],
          msg: this.commonDataService.localizationLabelList['recipient_missing'],
        });
      } else {
        this.commonUiService.isSpinnerVisible = true;
        let base64String = '';
        const reportType = this.reportEmailmodel.reportType;
        switch (reportType) {
          case 'work_report':
            await Delay(1000);
            if (this.workReport) {
              base64String = await this.commonUiService.ConvertHtmlToPdfBase64('work-report-section');
              if (base64String) {
                this.reportEmailmodel.base64String = btoa(base64String);
              }
            }
            break;
          case 'conformity_report':
            await Delay(500);
            if (this.conformityReport) {
              base64String = await this.commonUiService.ConvertHtmlToPdfBase64('conformity-report-section');
              if (base64String) {
                this.reportEmailmodel.base64String = btoa(base64String);
              }
            }
            break;
          case 'using_report':
            await Delay(500);
            if (this.usingReport) {
              base64String = await this.commonUiService.ConvertHtmlToPdfBase64('using-report-section');
              if (base64String) {
                this.reportEmailmodel.base64String = btoa(base64String);
              }
            }
            break;
          case 'sanity_guarantee_report':
            await Delay(500);
            if (this.sanityGuaranteeReport) {
              base64String = await this.commonUiService.ConvertHtmlToPdfBase64('sanity-guarantee-section');
              if (base64String) {
                this.reportEmailmodel.base64String = btoa(base64String);
              }
            }
            break;
          case 'incident_report':
            await Delay(500);
            if (this.incidentReport) {
              base64String = await this.commonUiService.ConvertHtmlToPdfBase64('incident-report-section');
              if (base64String) {
                this.reportEmailmodel.base64String = btoa(base64String);
              }
            }
            break;
        }
        this.OnSendEmailModalClose();

        const response = await this.commonUiService.sendEmailRequest(this.reportEmailmodel);
        if (response) {
          const body = JSON.parse(response['_body']);
          switch (body.status) {
            case 200:
              if (body.isSent) {
                this.commonUiService.isSpinnerVisible = false;
                return this._toastyService.success({
                  title: this.commonDataService.localizationLabelList['email'],
                  msg: this.commonDataService.localizationLabelList['email_send_succesfully'],
                });
              } else {
                this.commonUiService.isSpinnerVisible = false;
                return this._toastyService.error({
                  title: this.commonDataService.localizationLabelList['email'],
                  msg: this.commonDataService.localizationLabelList['email_failed'],
                });
              }
            case 404:
              this.commonUiService.isSpinnerVisible = false;
              return this._toastyService.error({
                title: this.commonDataService.localizationLabelList['email'],
                msg: this.commonDataService.localizationLabelList['email_failed'],
              });
          }
        } else {
          this.commonUiService.isSpinnerVisible = false;
          return this._toastyService.error({
            title: this.commonDataService.localizationLabelList['email'],
            msg: this.commonDataService.localizationLabelList['email_failed'],
          });
        }
      }
    }
  }

  OnSendEmailModalClose() {
    this._workService.isShowEmailPopup = false;
  }

  async OnPrintSanityGuaranteeReport(mainWorkType: MainWorkType, isPrint: boolean) {
    if (this.selectedWork.clientId && mainWorkType) {

      // const idList = this.selectedMainWorkTypeList.map(m => ({
      //   id: Number(m.id)
      // }));
      const productTemplateList = await this.__productTempService.GetProductTemplateListByMainWorkTypeIdList(
        [{ id: Number(mainWorkType.id) }]
      );
      const phaseTemplateList = await this._phaseTemplateService.GetPhaseTemplateListByMainWorkTypeIdList(
        [{ id: Number(mainWorkType.id) }]
      );
      this.sanityGuaranteeReport.productTemplateList = productTemplateList;
      this.sanityGuaranteeReport.phaseTemplateList = phaseTemplateList;
      if (this.selectedWork) {
        this.sanityGuaranteeReport.workNo = this.selectedWork.generateNo ? this.selectedWork.generateNo : '';
      }
      if (this.selectedSubWork) {
        this.sanityGuaranteeReport.subWorkNo = this.selectedSubWork.generateNo.toString() ? this.selectedSubWork.generateNo.toString() : '';
        this.sanityGuaranteeReport.prescriptor = this.selectedSubWork.doctorName ? this.selectedSubWork.doctorName : '';
        this.sanityGuaranteeReport.doctorRegNo = this.selectedSubWork.doctorRegistrationNumber ? this.selectedSubWork.doctorRegistrationNumber : '';
      }
      this._workService.GetClientById(this.selectedWork.clientId)
        .then(async client => {
          this.sanityGuaranteeReport.clinic = client.name;
          this.sanityGuaranteeReport.mainWorkTypeName = mainWorkType.workTypeName;
          this.sanityGuaranteeReport.mainWorkTypeNo = mainWorkType.generateNo;
          this.sanityGuaranteeReport.patient = this.selectedWork.patientName;

          this._workService.GetClientIssuerByClientId(client.id)
            .then(async issuer => {
              this.sanityGuaranteeReport.issuer = issuer ? issuer : new Issuer();

              this.sanityGuaranteeReport.town = '';
              if (issuer && issuer.townId) {
                this.sanityGuaranteeReport.town = this._dropdownService.townList.find(x => x.id == issuer.townId).description;
              }

              const list = await this._deliveryNoteService.GetDeliveryNoteListBySubWorkId(this.selectedSubWork.id);
              if (list) {
                this.sanityGuaranteeReport.deliveryNoteDates = '';
                this.sanityGuaranteeReport.deliveryNoteGeneratedNumbers = '';
                this.sanityGuaranteeReport.invoiceGeneratedNumbers = '';
                list.forEach(deliveryNote => {
                  const invoiceHeaderNoList: string[] = deliveryNote.invoiceDetail.map(x => x.invoiceHeaderNo)
                    .filter((x, i, a) => a.indexOf(x) === i);
                  let generateDate = '';
                  if (deliveryNote.generateDate !== null && deliveryNote.generateDate !== '') {
                    generateDate = moment(deliveryNote.generateDate.substring(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY');
                  }
                  // tslint:disable-next-line: max-line-length
                  this.sanityGuaranteeReport.deliveryNoteDates = (this.sanityGuaranteeReport.deliveryNoteDates ? this.sanityGuaranteeReport.deliveryNoteDates + ', ' : '')
                    + (deliveryNote.generateDate ? generateDate : '');
                  this.sanityGuaranteeReport.deliveryNoteGeneratedNumbers =
                    (this.sanityGuaranteeReport.deliveryNoteGeneratedNumbers ?
                      this.sanityGuaranteeReport.deliveryNoteGeneratedNumbers + ', ' : '') + deliveryNote.generateNo;
                  if (invoiceHeaderNoList) {
                    invoiceHeaderNoList.forEach(headerNo => {
                      this.sanityGuaranteeReport.invoiceGeneratedNumbers =
                        (this.sanityGuaranteeReport.invoiceGeneratedNumbers ?
                          this.sanityGuaranteeReport.invoiceGeneratedNumbers + ', ' : '') + headerNo;
                    });
                  }
                });
                await Delay(1000);
                this.enableSendButton = true;
                if (this.sanityGuaranteeReport && isPrint) {
                  this.commonUiService.PrintHtmlReport('sanity-guarantee-section');
                }
              }
            });
        });
    }
  }

  async OnPrintUsingReport(mainWorkType: MainWorkType, isPrint: boolean) {
    if (this.selectedWork && mainWorkType) {
      this.usingReport = new UsingReportModel();
      this.usingReport.date = new Date().toISOString().substring(0, 10);
      this.usingReport.patient = this.selectedWork.patientName ? this.selectedWork.patientName : '';
      this.usingReport.typeOfWorkNo = mainWorkType.generateNo ? mainWorkType.generateNo : '';
      const deliveryNoteList = await this._deliveryNoteService.GetDeliveryNoteListBySubWorkId(Number(this.selectedSubWork.id));
      if (deliveryNoteList.length > 0) {
        this.usingReport.deliveryNoteNos = deliveryNoteList.map(e => e.generateNo).join(', ');
      }

      if (this.selectedSubWork) {
        this.usingReport.prescriptor = this.selectedSubWork.doctorName ? this.selectedSubWork.doctorName : '';
        this.usingReport.registeredNo = this.selectedSubWork.doctorRegistrationNumber ? this.selectedSubWork.doctorRegistrationNumber : '';
      }

      this._workService.GetClientById(this.selectedWork.clientId)
        .then(async client => {
          this.usingReport.healthCenter = client.name;
          this.usingReport.healthCenterAddress = client.address;
          this.usingReport.healthCenterTown = client.townName;
          this.usingReport.healthCenterProvince = client.provinceName;
          this.usingReport.healthCenterCp = client.postalCode;

          this._workService.GetClientIssuerByClientId(client.id)
            .then(async issuer => {
              this.usingReport.issuer = issuer ? issuer : new Issuer();
              await Delay(300);
              this.enableSendButton = true;
              if (this.usingReport && isPrint) {
                this.commonUiService.PrintHtmlReport('using-report-section');
              }
            });
        });
    }
  }

  async OnPrintWorkReport(mainWorkType: MainWorkType, isPrint: boolean) {
    if (this.selectedWork && mainWorkType && this.selectedSubWork) {
      this.isWorkPdf = true;
      this.workReport = new WorkReport();
      this.workReport.workNo = this.selectedWork.generateNo ? this.selectedWork.generateNo : '';
      this.workReport.mainWorkTypeNo = mainWorkType.generateNo ? mainWorkType.generateNo : '';
      this.workReport.mainWorkTypeStatus = mainWorkType.workTypeStatusName ? mainWorkType.workTypeStatusName : '';
      this.workReport.subWorkNo = this.selectedSubWork.generateNo.toString() ? this.selectedSubWork.generateNo.toString() : '';
      this.workReport.deliveryDate = mainWorkType.deliveryDate ? mainWorkType.deliveryDate : '';
      this.workReport.entryDate = mainWorkType.receiveDate ? mainWorkType.receiveDate : '';

      if (this.selectedSubWork) {
        this.workReport.doctorName = this.selectedSubWork.doctorName ? this.selectedSubWork.doctorName : '';
        this.workReport.defaultDoctor = this.selectedSubWork.doctorName ? this.selectedSubWork.doctorName : '';
      }

      const phaseList = await this._phaseService.GetPhaseTemplateListByMainWorkTypeIdList([{ id: Number(mainWorkType.id) }]);
      const mwtColorList = await this.__service.GetMwtColorListByMainWorkTypeIdList([{ id: Number(mainWorkType.id) }]);
      const colourList = [] as any[];

      mwtColorList.forEach(element => {
        if (colourList.indexOf(element.colorText) === -1) {
          colourList.push(element.colorText);
        }
      });
      this.workReport.colourList = colourList.join(', ');
      this.workReport.mainWorkTypeName = mainWorkType.workTypeName;

      this.workReport.phasesList = phaseList ? phaseList : [];

      const piecesList = [] as any[];

      mwtColorList.forEach(element => {
        if (piecesList.indexOf(element.pieces) === -1 && element.pieces != '') {
          piecesList.push(element.pieces);
        }
      });
      this.workReport.piecesList = piecesList.join(', ');

      const workers = await this._mwtWorkerService
        .GetWorkerListByMainWorkTypeIdList([{ id: mainWorkType.id }]);

      if (workers && workers.length > 0) {
        this.workReport.workerList = workers.map(w => w.name + ' ' + w.surname);
      } else { this.workReport.workerList = []; }

      const deliveryNoteList = await this._deliveryNoteService.GetDeliveryNoteListBySubWorkId(Number(this.selectedSubWork.id));
      if (deliveryNoteList) {
        for (let i = 0; i < deliveryNoteList.length; i++) {
          const deliveryNote = deliveryNoteList[i];
          if (deliveryNote.articleList) {
            if (deliveryNote.articleList.length > 0) {
              const isEligible = deliveryNote.articleList.some(dn => dn.mainWorkTypeId === Number(mainWorkType.id));
              if (isEligible) {
                if (!this.workReport.deliveryNoteNoList.includes(deliveryNote.generateNo)) {
                  this.workReport.deliveryNoteNoList.push(deliveryNote.generateNo);
                }
              }
            }
          }
        }
      }

      let noteList = await this._noteService.GetNoteListByMainWorkTypeIdList([{ id: Number(mainWorkType.id) }]);
      noteList = noteList ? noteList : [];
      if (noteList.length > 0) {
        const clinicNote = noteList.filter(n => n.type === 'clinic');
        const labNote = noteList.filter(n => n.type === 'lab');
        const generalNote = noteList.filter(n => n.type === 'general');
        this.workReport.clinicNote = clinicNote ? clinicNote : [];
        this.workReport.labNote = labNote ? labNote : [];
        this.workReport.generalNote = generalNote ? generalNote : [];

      }

      // tslint:disable-next-line: max-line-length
      this.workReport.clinicMaterialList = await this._mwtClinicMaterialService.GetMwtClinicMaterialListByMainWorkTypeIdList([{ id: Number(mainWorkType.id) }]);
      // tslint:disable-next-line: max-line-length
      this.workReport.articleList = await this._articleTemplateService.GetArticleTemplateListByMainWorkTypeIdList([{ id: Number(mainWorkType.id) }]);
      // tslint:disable-next-line: max-line-length
      this.workReport.productList = await this.__productTempService.GetProductTemplateListByMainWorkTypeIdList([{ id: Number(mainWorkType.id) }]);

      this._workService.GetClientById(this.selectedWork.clientId)
        .then(async client => {
          this.workReport.clientName = client.name;
          this.workReport.patientName = this.selectedWork.patientName ? this.selectedWork.patientName : '';
          this.workReport.qualitType = client.qualityTypeName;

          const tele1 = client.telephone1 ? client.telephone1 : '';
          const tele2 = client.telephone2 ? client.telephone2 : '';
          this.workReport.clientTelephone = tele1 + (tele2 ? '/' + tele2 : '');

          await Delay(1000);
          this.enableSendButton = true;
          if (this.workReport && isPrint) {
            this.isWorkPdf = false;
            this.commonUiService.PrintHtmlReport('work-report-section');
          }
        });
    }
  }

  async OnPrintConformityReport(mainWorkType: MainWorkType, isPrint: boolean) {
    if (this.selectedWork && mainWorkType) {
      this.conformityReport = new ConformityReport();
      let deliveryNoteList = await this._deliveryNoteService.GetDeliveryNoteListBySubWorkId(Number(this.selectedSubWork.id));
      // getting only deliverynote having MWTArticle within subwork
      var deliveryNoteIds = [];
      var deliveryNoteDates = [];
      if (deliveryNoteList.length > 0) {
        for (var t = 0; t < deliveryNoteList.length; ++t) {
          var mwtArticle = deliveryNoteList[t].articleList;
          if (mwtArticle.filter(g => g.mainWorkTypeId == mainWorkType.id).length != 0) {
            if (!deliveryNoteIds.includes(deliveryNoteList[t].id)) {
              deliveryNoteIds.push(deliveryNoteList[t].id);
              if (deliveryNoteList[t].generateDate && deliveryNoteList[t].generateDate != '') {
                var deliveryDate = moment(deliveryNoteList[t].generateDate.substring(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY');
                if (deliveryNoteDates.indexOf(deliveryDate) == -1) {
                  deliveryNoteDates.push(deliveryDate);
                }
              }
            }
          }
        }
      }
      deliveryNoteList = deliveryNoteList.filter(x => deliveryNoteIds.includes(x.id));
      this.conformityReport.mainWorkType = mainWorkType.name;
      this.conformityReport.deliveryDate = deliveryNoteDates.length != 0 ? deliveryNoteDates.join(', ') : '';
      this.conformityReport.therapeuticPurposeName = this.therapeuticPurposeName ? this.therapeuticPurposeName
        : mainWorkType.therapeuticPurposeName;
      this.conformityReport.productDescriptionName = this.productDescriptionName ? this.productDescriptionName
        : mainWorkType.productDescriptionName;
      this.conformityReport.today = new Date().toISOString();
      this.conformityReport.patientName = this.selectedWork.patientName ? this.selectedWork.patientName : '';

      if (this.selectedSubWork) {
        this.conformityReport.doctorName = this.selectedSubWork.doctorName ? this.selectedSubWork.doctorName : '';
        this.conformityReport.doctorLicenseNo = this.selectedSubWork.doctorRegistrationNumber ? this.selectedSubWork.doctorRegistrationNumber : '';
      }

      if (deliveryNoteList.length > 0) {
        this.conformityReport.deliveryNoteNos = deliveryNoteList.map(e => e.generateNo).join(', ');
        let issuerNameList = [];
        let issuerResponsibleList = [];
        let issuerLicenseNoList = [];
        let issuerAddressList = [];
        if (deliveryNoteList.length == 1) {
          const issuer: any = deliveryNoteList[0].issuer;
          this.conformityReport.issuerName = issuer ? issuer.description : '';
          this.conformityReport.issuerDireccion = issuer ? issuer.address : '';
          this.conformityReport.townName = issuer ? issuer.townName : '';
          this.conformityReport.provinceName = issuer ? issuer.provinceName : '';
          this.conformityReport.cp = issuer ? issuer.cp : '';
          this.conformityReport.licenseNo = issuer ? issuer.licenseNumber : '';
          this.conformityReport.responsiblePerson = issuer.name2;
          this.conformityReport.count = 1;
        } else {
          this.conformityReport.count = deliveryNoteList.length + 1;
          var issueIds = [];
          for (var t = 0; t < deliveryNoteList.length; ++t) {
            const issuer: any = deliveryNoteList[t].issuer;
            if (issuer && !issueIds.includes(issuer.id)) {
              issuerNameList.push(issuer.description ? issuer.description : '');
              issuerResponsibleList.push(issuer.name2 ? issuer.name2 : '');
              issuerLicenseNoList.push(issuer.licenseNumber ? issuer.licenseNumber : '');
              const address = issuer.address ? issuer.address : '';
              const town = issuer.townName ? issuer.townName : '';
              const province = issuer.provinceName ? issuer.provinceName : '';
              const cp = issuer.cp ? issuer.cp : '';
              const fullAddress = address + ',' + town + ',' + province + ',' + cp;
              issuerAddressList.push(fullAddress);
              issueIds.push(issuer.id);
            }
          }
          // keeping empty if any attribute is null;
          this.conformityReport.issuerName = issuerNameList.join(', ');
          this.conformityReport.issuerName = this.conformityReport.issuerName ? this.conformityReport.issuerName.replace(/,\s*$/, "") : '';
          this.conformityReport.responsiblePerson = issuerResponsibleList.join(', ');
          this.conformityReport.responsiblePerson = this.conformityReport.responsiblePerson ? this.conformityReport.responsiblePerson.replace(/,\s*$/, "") : '';
          this.conformityReport.licenseNo = issuerLicenseNoList.join(', ');
          this.conformityReport.licenseNo = this.conformityReport.licenseNo ? this.conformityReport.licenseNo.replace(/,\s*$/, "") : '';
          this.conformityReport.fullAddress = issuerAddressList.join('; ');
          this.conformityReport.fullAddress = this.conformityReport.fullAddress ? this.conformityReport.fullAddress.replace(/,\s*$/, "") : '';
        }
        this._workService.GetClientById(this.selectedWork.clientId)
          .then(async client => {
            this.conformityReport.defaultClinicName = client.name;

            await Delay(300);
            this.enableSendButton = true;
            if (this.conformityReport && isPrint) {
              this.commonUiService.PrintHtmlReport('conformity-report-section');
            }
          });
      } else {
        this.conformityReport.count = 1;
        this._workService.GetClientIssuerByClientId(this.selectedWork.clientId)
          .then(async issuer => {
            this.conformityReport.issuerName = issuer ? issuer.description : '';
            this.conformityReport.issuerDireccion = issuer ? issuer.address : '';
            var temp: any = issuer;
            this.conformityReport.townName = issuer ? temp.townName : '';
            this.conformityReport.provinceName = issuer ? issuer.provinceName : '';
            this.conformityReport.cp = issuer ? issuer.cp : '';
            this.conformityReport.licenseNo = issuer ? issuer.licenseNumber : '';
            this.conformityReport.responsiblePerson = temp.name2;
            this._workService.GetClientById(this.selectedWork.clientId)
              .then(async client => {
                this.conformityReport.defaultClinicName = client.name;

                await Delay(300);
                this.enableSendButton = true;
                if (this.conformityReport && isPrint) {
                  this.commonUiService.PrintHtmlReport('conformity-report-section');
                }
              });
          });
      }
    }
  }

  async OnPrintIncidentReport(mainWorkType: MainWorkType, isPrint: boolean) {
    if (this.selectedWork && this.selectedSubWork && mainWorkType) {

      this.incidentReport = new IncidentReport();
      this.incidentReport.workNo = (this.selectedWork.generateNo ? this.selectedWork.generateNo : '')
              .concat((this.selectedSubWork.generateNo) ? this.selectedSubWork.generateNo.toString() : '');
      this.incidentReport.mainWorkTypeNo = mainWorkType.generateNo ? mainWorkType.generateNo : '';
      this.incidentReport.typeofWorkName = mainWorkType.name ? mainWorkType.name : '';
      this.incidentReport.doctorName = this.selectedSubWork.doctorName ? this.selectedSubWork.doctorName : '';
      this.incidentReport.doctorRegistrationNo = this.selectedSubWork.doctorRegistrationNumber ? this.selectedSubWork.doctorRegistrationNumber : '';
      this.incidentReport.patientName = this.selectedWork.patientName ? this.selectedWork.patientName : '';
      this.incidentReport.date = new Date().toISOString();

      const incidentDataList = await this._incidentService.GetIncidentListByMainWorkTypeIdList([{ id: mainWorkType.id }]);
      if (incidentDataList) {
        this.incidentReport.mwtIncidentList = incidentDataList;
        this.incidentReport.incidentCount = incidentDataList.length;
      }

      const defaultIssuer = await this._workService.GetDefaultIssuer();
      if (defaultIssuer) {
        this.incidentReport.issuerName = defaultIssuer.description ? defaultIssuer.description : '';
        this.incidentReport.issuerRegistrationNo = defaultIssuer.licenseNumber ? defaultIssuer.licenseNumber : '';
      }

      this._workService.GetClientById(this.selectedWork.clientId)
        .then(async client => {
          this.incidentReport.clinicName = client.name ? client.name : '';
          await Delay(300);
          this.enableSendButton = true;
          if (this.incidentReport && isPrint) {
            this.commonUiService.PrintHtmlReport('incident-report-section');
          }
        });
    }
  }

  // get FT/DP data for report
  OnEmitFTDPData(mainWorkType: MainWorkType) {
    this.productDescriptionName = mainWorkType.productDescriptionName;
    this.therapeuticPurposeName = mainWorkType.therapeuticPurposeName;
  }

  OnPrescriptionListChange(subWorkId: number) { this.LoadPrescriptionListBySubWorkId(subWorkId); }

  OnChangeWorkTypeManufacturer(manufacturer: ProviderViewModel) {
    if (manufacturer) {
      this.implantData.providerManufacId = Number(manufacturer.id);
      this.implantData.manufacturerName = manufacturer.name;
    }
  }

  OnChangePiezasCheckbox() { this.showPieces = !this.showPieces; }

  async OnEditMainWorkType(mainWorkType: MainWorkType) {
    const modal = 'add-main-work-type-modal';
    this.showMWTPopUp = true;
    this._modalService.Invoke(modal, true, {
      mainWorkTypeStatusDropDownList: this.mainWorkTypeStatusDropDownList,
      workConfig: this.workConfig,
      selectedWork: this.selectedWork,
      selectedSubWork: this.selectedSubWork,
      mainWorkType: mainWorkType,
      isEdit: true
    }, true);
  }

  async OnDeleteMainWorkType(mainWorkTypeId: number) {
    if (mainWorkTypeId) {
      const checkResult = await this._workService.CheckBeforeDelete(mainWorkTypeId);
      if (checkResult.flag) {
        this.commonUiService.isSpinnerVisible = true;
        const mainWorkType = this.mainWorkTypeList.find(x => Number(x.id) === Number(mainWorkTypeId));
        if (mainWorkType) {
          mainWorkType.status = false;
          this._workService.DeleteMainWorkType(mainWorkType)
            .then(response => {
              const body = JSON.parse(response['_body']);
              if (body['_isSuccsess']) {
                this._toastyService.success({
                  title: this.commonDataService.localizationLabelList['work_type'],
                  msg: this.commonDataService.localizationLabelList['save_success'],
                  showClose: true,
                  theme: 'default',
                  timeout: 5000,
                  onAdd: (toast: ToastData) => { },
                  onRemove: (toast: ToastData) => { }
                });
                // const index: number = this.mainWorkTypeList.findIndex(x => Number(x.id) === Number(mainWorkTypeId));
                // if (index !== -1) { this.mainWorkTypeList.splice(index, 1); }
                this.refresh = true;
                this.searchKey = this.selectedWork.generateNo;
                this.OnFilterWork('search');
                this.OnClickWork(this.selectedWork);
              } else {
                this._toastyService.error({
                  title: this.commonDataService.localizationLabelList['work_type'],
                  msg: this.commonDataService.localizationLabelList['save_error'],
                  showClose: true,
                  theme: 'default',
                  timeout: 5000,
                  onAdd: (toast: ToastData) => { },
                  onRemove: (toast: ToastData) => { }
                });
              }

            });
        }
        this.commonUiService.isSpinnerVisible = false;
      } else {
        this._toastyService.error({
          title: this.commonDataService.localizationLabelList['work_type'],
          msg: this.commonDataService.localizationLabelList['delete_error'],
          showClose: true,
          theme: 'default',
          timeout: 5000,
          onAdd: (toast: ToastData) => { },
          onRemove: (toast: ToastData) => { }
        });
      }
    }
  }

  OnSelectMainWorkType(args: { mainWorkType: MainWorkType, index: number }) {
    if (args.mainWorkType && args.index !== undefined) {
      const mainWorkType: MainWorkType = args['mainWorkType'];
      const index = this.mainWorkTypeList.findIndex(x => Number(x.id) === Number(mainWorkType.id));
      mainWorkType.order = index + 1;
      if (!mainWorkType.isSelected) {
        // newly selected
        mainWorkType.isSelected = !mainWorkType.isSelected;
        const isIncluded: boolean = this.selectedMainWorkTypeList.findIndex(x => Number(x.id) === Number(mainWorkType.id)) > -1;
        if (!isIncluded) { this.selectedMainWorkTypeList.push(mainWorkType); }
        this.AssignMainWorkTypeBackgroundColorClass(mainWorkType, index);
        this.AssignMainWorkTypeBadgeColor(mainWorkType, index);
      } else {
        // already selected
        mainWorkType.isSelected = !mainWorkType.isSelected;
        const index_: number = this.selectedMainWorkTypeList.findIndex(x => Number(x.id) === Number(mainWorkType.id));
        if (index_ !== -1) { this.selectedMainWorkTypeList.splice(index_, 1); }
        mainWorkType.cssClass = '';
        mainWorkType.symbol = '';
      }
      // check if any selected main work type has allowed implant data
      this.allowedImplant = this.selectedMainWorkTypeList.some(mwt => mwt.isImplantData);

      this._workService.SetDetailObject({
        selectedMainWorkTypeList: this.selectedMainWorkTypeList,
        selectedMainWorkType: mainWorkType,
        selectedSubWork: this.selectedSubWork,
        selectedWork: this.selectedWork,
        mainWorkTypeList: this.mainWorkTypeList,
        invokeComponents: ['*'],
        transferable: []
      });
    }
  }

  async OnChangeDeliveryNoteList(mwtIdList: number[]) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (mwtIdList) {
        const mwtList = [...this.mainWorkTypeList];
        for (let i = 0; i < mwtIdList.length; i++) {
          const id = mwtIdList[i];
          const mwt = mwtList.find(mt => Number(mt.id) === Number(id));
          if (mwt) {
            mwt.manualStatus = true;
            const statusObj = this.mainWorkTypeStatusDropDownList.find(x => x.label.toLowerCase() === 'finalizado');
            if (statusObj) {
              mwt.workTypeStatusId = Number(statusObj.value);
              mwt.workTypeStatusName = statusObj.label;
              mwtList[mwtList.findIndex(mt => Number(mt.id) === Number(id))] = mwt;
            }
          }
        }
        this.mainWorkTypeList = [...mwtList];
      }
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async AfterWorkSaved(args: {
    work: Work, subWork: SubWork, notes: Note[],
    isEdit: boolean, existing: boolean
  }, defaultMwt?: MainWorkType) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      this.selectedWork = args.work;
      this.selectedSubWork = args.subWork;
      if (args.isEdit) {
        if (this.selectedWork) {
          if (this.selectedWork.patientId) {
            await this._dropdownService.LoadListAsync([ListNames.PatientList]);
            const list = this._dropdownService.patientList;
            if (list) {
              const patient: Patient = list.find(x => Number(x.id) === Number(this.selectedWork.patientId));
              this.selectedWork.patientName = patient ? patient.fullName : '';
            }
          }
          if (this.selectedWork.clientId) {
            const list = this._dropdownService.clientList;
            if (list) {
              const client: Client = list.find(x => Number(x.id) === Number(this.selectedWork.clientId));
              this.selectedWork.clientName = client ? client.name : '';
            }
          }
          this.refresh = true;
          await this.changeAdministrationPanels();
          this._workService.SetDetailObject({
            selectedMainWorkTypeList: this.selectedMainWorkTypeList,
            selectedMainWorkType: new MainWorkType(),
            selectedSubWork: this.selectedSubWork,
            selectedWork: this.selectedWork,
            mainWorkTypeList: this.mainWorkTypeList,
            invokeComponents: ['Budget', 'DeliveryNote', 'Invoice', 'Note'],
            transferable: []
          });
        }
      } else {
        if (!args.existing) {
          if (this.workList.length >= 10) {
            this.workList.unshift(this.selectedWork);
            this.workList.splice(-1, 9);
          } else {
            this.workList.splice(0, -1, this.selectedWork);
          }
        }
        this._existingWork = args.work;
        this.selectedSubWork = args.subWork;
        // this.refresh = true;
        // this.searchKey = args.work.generateNo;
        // this.OnFilterWork('search');
        // if (args.dentboxWork) {
        //   // tslint:disable-next-line: max-line-length
        //   this.selectedWork.patientName = args.dentboxWork.patientSurname ? args.dentboxWork.patientName + ' ' +  args.dentboxWork.patientSurname : args.dentboxWork.patientName;
        //   this.selectedWork.clientName =  args.dentboxWork.clientName;
        //   this.selectedWork.doctorName =  args.dentboxWork.collaboratorDescription;
        // }
        await this.OnClickWork(this.selectedWork);
        this.OnOpenAddWorkTypeModal(defaultMwt ? defaultMwt : undefined); // open main work type modal
      }
    } catch (error) { console.log(error); } finally {
      this.showWorkPopUp = false;
      this._existingWork = undefined;
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  private async changeAdministrationPanels() {
    try {
      const invoiceList = await this._invoiceService
        .GetInvoiceListByWorkId(this.selectedWork.id, this.selectedSubWork.id);

      if (invoiceList) {
        for (let index = 0; index < invoiceList.length; index++) {
          const invoice = invoiceList[index];
          invoice.clientId = this.selectedWork.clientId;
          invoice.patientId = this.selectedWork.patientId;
        }
        const response = await this._invoiceService
          .UpdateClientsInvoiceList(invoiceList);

      }
      const deliveryNoteList = await this._deliveryNoteService
        .GetDeliveryNoteListBySubWorkId(this.selectedSubWork.id);
      if (deliveryNoteList) {
        for (let index = 0; index < deliveryNoteList.length; index++) {
          const delNote = deliveryNoteList[index];
          delNote.clientId = this.selectedWork.clientId;
          delNote.patientId = this.selectedWork.patientId;
        }
        const response = await this._deliveryNoteService
          .UpdateClientsDeliveryNoteList(deliveryNoteList);
      }
    } catch (error) { console.log(error); }
  }

  async OpenLastPrescription(args: { work: Work, subWork: SubWork, isEdit: boolean, existing: boolean }) {
    this.ClearLists();
    this.showWorkPopUp = false;
    this.showSubWorkPopUp = false;
    this.commonUiService.isSpinnerVisible = true;
    //  this._existingWork = args.work;
    this.selectedWork = args.work;
    this.selectedSubWork = args.subWork;
    this.refresh = true;
    this.searchKey = args.work.generateNo;
    this.OnFilterWork('search');
    await this.OnClickWork(this.selectedWork);
    this.commonUiService.isSpinnerVisible = false;
  }

  async AfterSubWorkSaved(args: { work: Work, subWork: SubWork, isEdit: boolean }) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      this.selectedWork = args.work;
      if (args.isEdit) {
        this.OnClickWork(this.selectedWork);
      } else {
        if (this.selectedWork.id) {
          const list = await this._subWorkService.GetSubWorkListByWorkId(this.selectedWork.id);
          if (list) {
            this.subWorkList = list;
            this.subWorkList.sort(function (a, b) {
              return (a.createdDate < b.createdDate) ? -1 : ((a.createdDate > b.createdDate) ? 1 : 0);
            });
            this.selectedSubWork = args.subWork;
            this.OnSelectSubWork(this.selectedSubWork);
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this.showSubWorkPopUp = false;
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  //#endregion

  //#region Article

  private LoadArticleListByWorkTypeTemplateId(workTypeId: number) {
    this._apiService.get(API.work_type.getArticleListById + workTypeId)
      .subscribe((list: WorkTypeArticleTemplate[]) => {
        this.workTypeArticleList = list;
        if (this.workTypeArticleList.length > 0) {
          this.mainWorkTypeFormGroup.controls.colorArticleName.enable();
        } else {
          this.mainWorkTypeFormGroup.controls.colorArticleName.disable();
        }
      }
        ,
        err => console.log(err));
  }

  //#endregion

  //#region ImplantData

  private async SaveImplantData(implantData: MwtImplantData): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.post(API.main_work_type_work.saveImplantData, implantData, false, true)
        .subscribe(res => resolve(JSON.parse(res['_body'])),
          err => reject(Error(err)));
    });
  }

  //#endregion

  //#region Precription

  private LoadPrescriptionListBySubWorkId(subWorkId: number) {
    this._apiService.get(API.prescriptions.getListBySubWorkId + subWorkId)
      .subscribe((list: Prescription[]) => {
        if (list) {
          list.forEach(prescription => {
            prescription.extension = prescription.fileName.split('.')[(prescription.fileName.split('.')).length - 1];
            prescription.link = this._apiService.BASE_URL +
              API.prescriptions.getPrescription + prescription.id + '/' + prescription.generatedFileName;
            if (prescription.url) {
              if (prescription.extension === 'pdf') {
                prescription.url = prescription.url.toString().includes('data:application/pdf;base64,')
                  ? prescription.url : 'data:application/pdf;base64,' + prescription.url;
              }
              if (prescription.extension === 'png') {
                prescription.url = prescription.url.toString().includes('data:image/png;base64,')
                  ? prescription.url : 'data:image/png;base64,' + prescription.url;
              }
              if (prescription.extension === 'jpg') {
                prescription.url = prescription.url.toString().includes('data:image/jpg;base64,')
                  ? prescription.url : 'data:image/jpg;base64,' + prescription.url;
              }
              if (prescription.extension === 'jpeg') {
                prescription.url = prescription.url.toString().includes('data:image/jpeg;base64,')
                  ? prescription.url : 'data:image/jpeg;base64,' + prescription.url;
              }
              if (prescription.extension === 'docx') {
                prescription.url = prescription.url.toString().includes('https://docs.google.com/gview?url=')
                  ? prescription.url : 'https://docs.google.com/gview?url=' + prescription.link + '&embedded=true';
              }
              if (prescription.extension === 'xlsx' || prescription.extension === 'xls') {
                prescription.url = prescription.url.toString().includes('https://docs.google.com/gview?url=')
                  ? prescription.url : 'https://docs.google.com/gview?url=' + prescription.link + '&embedded=true';
              }
            }
          });
        }
        this.prescriptionList = list;
      },
        err => console.error(err),
        () => this.isPrescriptionListLoaded = true);
  }

  //#endregion

  //#region Other

  private SetUIChanges() {
    this.commonUiService.isShowLoyoutMenu = true;
    this.commonUiService.isShowStatMenu = true;
    this.commonUiService.isShowExpandView = false;
  }

  private SetExpandViewUI() {
    this.commonUiService.isShowLoyoutMenu = true;
    this.commonUiService.isShowStatMenu = true;
    this.commonUiService.isShowExpandView = true;
  }

  private ClearLists() {
    this.subWorkList = [];
    this.mainWorkTypeList = [];
    this.selectedMainWorkTypeList = [];
    this.selectedTeethList = [];
    this.productDescriptionName = '';
    this.therapeuticPurposeName = '';
  }

  private AssignMainWorkTypeBackgroundColorClass(mainWorkType: MainWorkType, index: number) {
    if (mainWorkType) {
      const cssClass: string = this._workService.cardBackgroundClassList[index];
      mainWorkType.cssClass = cssClass;
    }
  }

  private AssignMainWorkTypeBadgeColor(mainWorkType: MainWorkType, index: number) {
    if (mainWorkType) {
      const color: string = this._workService.badgeColorList[index];
      mainWorkType.symbol = color;
    }
  }

  private async ChangeStatus() {
    if (this.selectedWork) {
      if (this.selectedSubWork) {
        if (this.mainWorkTypeList.length > 0) {
          for (let i = 0; i < this.mainWorkTypeList.length; i++) {
            const mwt = this.mainWorkTypeList[i];
            if (!mwt.manualStatus) {
              const phaseTemplate = await this._phaseTemplateService
                .GetPhaseTemplateListByMainWorkTypeIdList([{ id: Number(mwt.id) }]);
              if (phaseTemplate) {
                const mwtStatus = this._workStatusService.GetMainWorkTypeStatus(phaseTemplate);
                const statusObj =
                  this.mainWorkTypeStatusDropDownList.find(x => x.label.toLowerCase() === mwtStatus.toLowerCase());
                if (statusObj) {
                  if (Number(statusObj.value) !== Number(mwt.workTypeStatusId)) {
                    mwt.workTypeStatusId = Number(statusObj.value);
                    mwt.workTypeStatusName = statusObj.label;
                    await this._workService.UpdateMainWorkType(mwt);
                  }
                }
              }
            }
          }
          const subWorkList1 = await this._subWorkService.GetSubWorkListByWorkId(this.selectedWork.id);
          if (subWorkList1) {
            const subWork = subWorkList1.find(x => x.id === this.selectedSubWork.id);
            if (subWork) {
              if (!subWork.manualStatus) {
                const subWorkStatus = this._workStatusService.GetSubWorkStatus(subWork.mainWorkTypeList);
                if (subWorkStatus.toLowerCase() !== this.selectedSubWork.subWorkStatus.toLowerCase()) {
                  this.selectedSubWork.subWorkStatus = subWorkStatus;
                  this.selectedSubWork.doctorId = this.selectedSubWork.doctorId == 0 ? null : this.selectedSubWork.doctorId;
                  await this._subWorkService.EditSubWork(this.selectedSubWork);
                }
              }
            }
          }
        }
        const subWorkList2 = await this._subWorkService.GetSubWorkListByWorkId(this.selectedWork.id);
        if (subWorkList2) {
          const workStatus = this._workStatusService.GetWorkStatus(subWorkList2);
          if (workStatus) {
            if (this.selectedWork.workStatus) {
              if (workStatus.toLowerCase() !== this.selectedWork.workStatus.toLowerCase()) {
                this.selectedWork.workStatus = workStatus;
                await this._workService.EditWork(this.selectedWork);
              }
            }
          }
          this.mainWorkTypeList = this.selectedSubWork.mainWorkTypeList;
        }
      }
    }
  }

  private ResetUrlQueryParams() {
    this._router.navigate([], { relativeTo: this._route, queryParams: {} });
  }

  //#endregion

  RefreshData(subWork: SubWork) {
    this.OnSelectSubWork(subWork);
    this.showMWTPopUp = false;
  }

  RedirectToDentbox() {
    const dentBoxGUID = (this.selectedSubWork.dentboxGuid != null) ? this.selectedSubWork.dentboxGuid : null;
    this._dentboxLabService.RouteToDentboxChat(dentBoxGUID);
  }

  OnWorkPlanSaved(isWorkPlanSaved: boolean) {
    if (isWorkPlanSaved) {
      this.OnClickWork(this.selectedWork);
    }
  }

  private filterAndLoadWork(workNo: string) {
    this.workFliterStatus = 'Todos';
    this.fromDate = null;
    this.toDate = null;
    this.isShowingFilters = false;
    this.requestData = new RequestData(1, 10, '', this.workFliterStatus + ', ' +
      workNo + ', ' + this.fromDate + ', ' + this.toDate, '', 0, true);
    this.refresh = true;
  }

  private async loadWorkSubWork(work: Work, subWork: SubWork) {
    if (work && subWork) {
      this.filterAndLoadWork(work.generateNo);
      this.selectedWork = work;
      this._existingWork = work;
      this.selectedSubWork = subWork;
      await this.OnClickWork(work);
      this._existingWork = undefined;
    }
  }

  async onEnterClicked() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (this.searchKey && this.searchKey.length > 6) {
        // search by mwt no
        const subWork = await this._workService.GetWorkByMainwork(this.searchKey);
        if (subWork && subWork.id > 0) {
          this.commonUiService.isSpinnerVisible = true;
          const sw = await this._subWorkService
            .GetSubWorkById(Number(subWork.id));
          const work = await this._workService.GetWorkById(Number(subWork.workId));
          await this.loadWorkSubWork(work, sw);
        } else {
          this._toastyService.warning({
            title: this.commonDataService.localizationLabelList['work'],
            msg: this.commonDataService.localizationLabelList['work_not_found'],
            showClose: true,
            theme: 'default',
            timeout: 5000,
            onAdd: (toast: ToastData) => { },
            onRemove: (toast: ToastData) => { }
          });
        }
      } else if (this.searchKey.length === 6) { // search work with prescription no
        const subWorkNum = this.searchKey.charAt(this.searchKey.length - 1);
        const workNum = this.searchKey.substring(0, this.searchKey.length - 1);
        if (workNum && subWorkNum) {
          const data: { work: Work, subWork: SubWork } = await this._workService
            .GetWorkAndSubWork(workNum, subWorkNum);
          if (data) {
            this.searchKey = data.work.generateNo;
            await this.loadWorkSubWork(data.work, data.subWork);
          } else {
            this._toastyService.warning({
              title: this.commonDataService.localizationLabelList['work'],
              msg: this.commonDataService.localizationLabelList['work_not_found'],
              showClose: true,
              theme: 'default',
              timeout: 5000,
              onAdd: (toast: ToastData) => { },
              onRemove: (toast: ToastData) => { }
            });
          }
        }
      } else {
        if (this.searchKey && this.searchKey.length != 0 && !isNaN(parseInt(this.searchKey))) {
          // only for work generated no. which is typed lesser than 6
          const work = await this._workService.SearchWorkByGeneratedNo(this.searchKey);
          if (work && work.length == 0) {
            this._toastyService.warning({
              title: this.commonDataService.localizationLabelList['work'],
              msg: this.commonDataService.localizationLabelList['work_not_found'],
              showClose: true,
              theme: 'default',
              timeout: 5000,
              onAdd: (toast: ToastData) => { },
              onRemove: (toast: ToastData) => { }
            });
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }
}
