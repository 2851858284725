import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Prescription } from '../../entities/prescription';
import { ModalService } from '../../../common/services/modal.service';
import { ModalNames } from '../../../common/global';
import { CommonUIService } from '../../../common/services/common.ui.service';

@Component({
  selector: 'app-precription-view-modal',
  templateUrl: './precription-view-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrecriptionViewModalComponent implements OnInit, AfterViewInit {
  prescription: Prescription;
  showContent = false;

  constructor(private _modalService: ModalService,
    private commonUiService: CommonUIService,
    private cdref: ChangeDetectorRef) { this.prescription = new Prescription(); }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this._modalService.modalSubject.subscribe(async (modalObject) => {
      if (modalObject) {
        if (modalObject.modal === ModalNames.PrescriptionViewModal) {
          if (modalObject.flag) {
            this.prescription = modalObject.transferable.prescription as Prescription;
            this.cdref.markForCheck();
            this.showContent = true;
          }
        }
      }
      //this.commonUiService.isSpinnerVisible = false;
    });
  }

  OnClickDocument(prescription: Prescription) { if (document) { window.open(prescription.link, '_blank'); } }

  OnCloseModal() {
    this.commonUiService.isPopupOpened = false;
    this.showContent = false;
  }
  //OnClickDocument(prescription: Prescription) { if (document) { window.open(prescription.link, '_blank'); } }

}
