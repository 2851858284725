import { Component, Input, OnChanges } from '@angular/core';
import { InvoiceHeader } from '../../entities/invoice-header';
import { InvoiceDetail } from '../../entities/invoice-detail';
import { IvaBreakdown } from 'src/app/modules/auxiliary/entities/iva-breakdown';
import { InvoiceService } from '../../services/invoice.service';
import { toFixedNumber } from '../../../common/services/calculations/calculation';

@Component({
  selector: 'app-invoice-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.scss']
})
export class InvoiceViewComponent implements OnChanges {

  @Input() invoiceHeader: InvoiceHeader;
  invoiceDetailList: InvoiceDetail[] = [];
  ivaBreakdownList: IvaBreakdown[] = [];

  constructor(private _invoiceService: InvoiceService) { }

  ngOnChanges() {
    if (this.invoiceHeader) { this.OnViewInvoiceDetails(this.invoiceHeader); }
  }

  private OnViewInvoiceDetails(header: InvoiceHeader) {
    try {
      if (!header.invoiceDetailList) {
        return null;
      }
      this.invoiceDetailList = [];
      this.ivaBreakdownList = [];
      const invList = header.invoiceDetailList.map(x => x.deliveryNoteId);
      const distictDeliverynoteIds = invList.filter((x, i) => invList.indexOf(x) === i);

      distictDeliverynoteIds.forEach(did => {
        const invoiceDetail = new InvoiceDetail();
        const dList = header.invoiceDetailList.filter(o => o.deliveryNoteId === Number(did));
        invoiceDetail.articleFinalPrice = toFixedNumber(dList
          .reduce((sum, current) => sum + current.articleFinalPrice, 0));
        invoiceDetail.deliveryNoteId = did;
        if (dList !== undefined && dList.length > 0) {
          invoiceDetail.deliveryNoteGenDate = dList[0].deliveryNoteGenDate;
          invoiceDetail.deliveryNoteGenNo = dList[0].deliveryNoteGenNo;
          invoiceDetail.deliveryNoteDiscount = toFixedNumber(dList[0].deliveryNoteDiscount);
          invoiceDetail.deliveryNoteTax = toFixedNumber(dList[0].deliveryNoteTax);
          invoiceDetail.deliveryNoteTotal = toFixedNumber(dList[0].deliveryNoteTotal);
          invoiceDetail.deliveryNoteSubTotal = toFixedNumber(dList[0].deliveryNoteSubTotal);

          //#region iva breakdown
          dList.forEach(art => {
            if (art.ivaTypeId && art.ivaTypeId != 0) {
              const ivaBreakdown = new IvaBreakdown();
              ivaBreakdown.ivaTypeId = art.ivaTypeId;
              ivaBreakdown.iva = art.articleIvaPercentage + '%';

              ivaBreakdown.base = toFixedNumber(art.articleFinalPrice - art.articleIvaAmount);
              ivaBreakdown.ivaAmount = art.articleIvaAmount;
              this.ivaBreakdownList.push(ivaBreakdown);
            }
          });
          //#endregion

        }
        this.invoiceDetailList.push(invoiceDetail);
      });

      if (this.ivaBreakdownList && this.ivaBreakdownList.length > 0) {
        this.ivaBreakdownList = this._invoiceService.LoadVatBreakdown(this.ivaBreakdownList);
      }

    } catch (error) { console.log(error); }
  }
}
