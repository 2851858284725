export class NumberingProcess {
    isNextAvailableNumber: boolean;
    isAnother: boolean;
    isNoNumberAssigned: boolean;
    isOtherAvailableNumbers: boolean;
    nextAvailableNumber: number;
    anotherNumber: number;
    otherAvailableNumberList: string[];
    otherAvailableNumber: string;

    constructor(model?: any) {
        if (model) {
            Object.keys(model).forEach(prop => {
                const value = model[prop];
                this[prop] = value;
            });
        }
    }
}

export class SavedNumber {
    seriesId: number;
    savedNumber: number;
}

export class NumberingProcessData {
    generateNumber: string;
    genNumber: number;
    deleteNumber: number;
    seriesId: number;
    seriesType: 'DeliveryNote' | 'Work' | 'Bill' | 'Budget' | 'Receipt' | 'Invoice Cancel';

    constructor(model?: any) {
        if (model) {
            Object.keys(model).forEach(prop => {
                const value = model[prop];
                this[prop] = value;
            });
        }
    }
}

