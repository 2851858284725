import { Component, OnInit, Input } from '@angular/core';
import { ReceiptHeader } from 'src/app/modules/receipt/entities/receipt-header';

@Component({
  selector: 'app-receipt-report-two',
  templateUrl: './receipt-report-two.component.html'
})
export class ReceiptReportTwoComponent implements OnInit {

  @Input() reportId: string;
  @Input() imageData: string;
  @Input() reportValue: number;
  @Input() selectedReceiptHeader: ReceiptHeader;

  isPdf: boolean;
  constructor() { }

  ngOnInit() {
  }

}
