import { Injectable, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { DropdownService } from '../dropdown.service';
import { DropdownNames } from '../../global';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    translations: any[];
    @Output() isTranslated = new EventEmitter<any>();

    constructor(private _translationService: TranslateService,
        private _dropdownService: DropdownService,
    ) {
        this._translationService.onLangChange.subscribe(
            (trans: { lang: string, translations: any }) => {
                this.translations = trans.translations;
                this.initDropdowns(this.translations);
                this.isTranslated.emit(trans.lang);
            });
    }

    async getTranslation(key: string) {
        return await this._translationService
            .get(key).pipe(map(trans => trans as string)).toPromise();
    }

    initDropdowns(translations: any) {
        this._dropdownService.initList(
            [DropdownNames.InvoiceList,
            DropdownNames.ReceiptList,
            DropdownNames.DeliveryList,
            DropdownNames.MonthList,
            DropdownNames.JobSearchDateTypeList,
            DropdownNames.JobSearchPriorityList,
            DropdownNames.UrgencyTypeList,
            DropdownNames.OrderReceptionStatusList,
            DropdownNames.OrderStatusList,
            DropdownNames.MovementTypesList,
            DropdownNames.MovementTypesList2,
            DropdownNames.TaskStatusList,
            DropdownNames.PhaseStatusList,
            DropdownNames.PhaseStatusList2,
            DropdownNames.GenderList,
            DropdownNames.ProductInventoryFrequencyList,
            DropdownNames.AuxiliaryNumTypeList,
            DropdownNames.WorkStateList,
            DropdownNames.OrderStatusList2,
            DropdownNames.ArticleExpandDateList,
            DropdownNames.PeriodList,
            DropdownNames.WorkTypeExpandDateList,
            DropdownNames.ReceiptGenerateTypeList,
            DropdownNames.WarehouseStockStatus,
            DropdownNames.ExternalShipmentStatus,
            DropdownNames.NoteTypeList,
            DropdownNames.IncidentTypeList,
            DropdownNames.InvoiceStatusList],
            translations.DROPDOWNS);
    }
}
