import { Component, Input, OnChanges } from '@angular/core';
import { InvoiceHeader } from '../../entities/invoice-header';
import { InvoiceDetail } from '../../entities/invoice-detail';

@Component({
  selector: 'app-new-invoice-view',
  templateUrl: './new-invoice-view.component.html',
  styleUrls: ['./new-invoice-view.component.scss']
})
export class NewInvoiceViewComponent implements OnChanges {

  @Input() invoiceHeader: InvoiceHeader;
  invoiceDetailList: InvoiceDetail[] = [];

  documentNo: number;
  seriesName: string;
  year: string;

  constructor() { }

  ngOnChanges() {
    if (this.invoiceHeader) {
      this.OnViewInvoiceDetails(this.invoiceHeader);
      if (this.invoiceHeader.labPicturePath) {
        this.invoiceHeader.labPicturePath = 'data:image/png;base64,' + this.invoiceHeader.labPicturePath;
      }
      if (this.invoiceHeader.cancellationGenerateNumber) {
        const gno = this.invoiceHeader.cancellationGenerateNumber;
        this.year = '20' + gno.substr(gno.length - 2);
        if (gno.includes('-')) {
          const strArr = gno.split('-');
          this.seriesName = strArr[0];
          if (strArr[1].includes('/')) {
            const docNo = strArr[1].substring(0, strArr[1].indexOf('/'));
            this.documentNo = Number(docNo);
          }
        }
      }
    }
  }

  private OnViewInvoiceDetails(header: InvoiceHeader) {
    try {
      if (!header.invoiceDetailList) {
        return null;
      }
      this.invoiceDetailList = [];
      const invList = header.invoiceDetailList.map(x => x.deliveryNoteId);
      const distictDeliverynoteIds = invList.filter((x, i) => invList.indexOf(x) === i);

      distictDeliverynoteIds.forEach(did => {
        const invoiceDetail = new InvoiceDetail();
        const dList = header.invoiceDetailList.filter(o => o.deliveryNoteId === Number(did));
        invoiceDetail.articleFinalPrice = dList.reduce((sum, current) => sum + current.articleFinalPrice, 0);
        invoiceDetail.deliveryNoteId = did;
        if (dList !== undefined && dList.length > 0) {
          invoiceDetail.deliveryNoteGenDate = dList[0].deliveryNoteGenDate;
          invoiceDetail.deliveryNoteGenNo = dList[0].deliveryNoteGenNo;
          invoiceDetail.workNumber = dList[0].workNumber;
        }
        this.invoiceDetailList.push(invoiceDetail);
      });
    } catch (error) { console.log(error); }
  }
}
