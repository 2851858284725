import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import { DocumentPrintField } from '../entities/document-print-field';
import API from '../../common/api.config.json'; 

@Injectable({
  providedIn: 'root'
})
export class DocumentPrintFieldService {


  constructor(private _apiService: ApiService) { }

  GetDocumentPrintNote() {
    return this._apiService
        .get(API.DocumentPrintField.getDocumentPrintNote)
        .map(documentPrintField => documentPrintField as DocumentPrintField).toPromise();
  }

  SaveDocumentPrintNote(documentPrintField: DocumentPrintField) {
    return this._apiService
        .post(API.DocumentPrintField.addDocumentPrintField, documentPrintField).toPromise();  
  }

  UpdateDocumentPrintNote(id: number, documentPrintField: DocumentPrintField) { 
    return this._apiService
        .put(API.DocumentPrintField.updateDocumentPrintField + id, documentPrintField, true).toPromise();
  }

  DeleteDocumentPrintNote(id: number) {
    return this._apiService
        .delete(API.DocumentPrintField.deleteDocumentPrintField, id.toString(), false).toPromise();
 }
}