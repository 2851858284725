import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import { TaskTopes } from '../../tasks/entities/task-topes';
import API from './../../common/api.config.json';
import * as moment from 'moment';
import { DailyTaskValue } from '../entities/daily-task-value';
import { LabSectionTopes } from '../../auxiliary/sections/components/entities/lab-section-topes';
import { DailySectionsValue } from '../entities/daily-sections-value';
import { Worker } from './../../workers/entities/worker';
import { MwtWorker } from '../entities/main-work-type-worker';
import { TaskDashboardRequestData } from '../../work-load/components/taskboard/entities/task-dashboard-models';
import { TaskDashboardData } from '../../work-load/components/taskboard/entities/task-dashboard-data';
import { MwtPhase } from '../entities/main-work-type-phase';

@Injectable({
  providedIn: 'root'
})
export class MwtTaskService {

  constructor(private _apiService: ApiService) { }

  GetMwtTaskTotalValueAndDialyLimit(taskId: number, deliveryDate: string) {
    const today = moment(new Date(deliveryDate)).format('YYYY-MM-DD');
    return this._apiService.get(API.main_work_type_work.getMwtTaskTotalValueAndDialyLimit + taskId + '/' + today)
      .map(res => res as DailyTaskValue).toPromise();
  }

  UpdateTaskTopes(taskTopes: TaskTopes) {
    return this._apiService
      .put(API.task_topes.updateTaskTopes + taskTopes.id, taskTopes, false, true)
      .toPromise();
  }

  GetLabSectionTotalTaskValueAndDialyLimit(labSectionId: number, deliveryDate: string) {
    const today = moment(new Date(deliveryDate)).format('YYYY-MM-DD');
    return this._apiService.get(API.main_work_type_work.getLabSectionTotalTaskValueAndDialyLimit + labSectionId + '/' + today)
      .map(res => res as DailySectionsValue).toPromise();
  }

  UpdateLabSectionsTaskTopes(labSectionTopes: LabSectionTopes) {
    return this._apiService
      .post(API.lab_section_topes.updateLabSectionsTaskTopes, labSectionTopes, false, true)
      .toPromise();
  }

  GetSectionTaskList(requestData: TaskDashboardRequestData) {
    return this._apiService.post(API.main_work_type_work.get_lab_section_task_list, requestData)
      .map(list => list as TaskDashboardData[]).toPromise();
  }

  AssignTaskWorker(mwtPhaseTaskId: number, workerId: number) {
    return this._apiService.put(API.main_work_type_work.assignWorkerForPhaseTask + mwtPhaseTaskId + '/' + workerId)
      .toPromise();
  }

  AssignWorkerForTask(mwtPhaseId: number, taskId: number, workerId: number): Promise<any> {
    return new Promise<MwtWorker[]>((resolve, reject) => {
      this._apiService.put(API.main_work_type_work.assignWorkerForTask + mwtPhaseId + '/' + taskId + '/' + workerId)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }


  GetWorkerList() {
    return this._apiService.get(API.worker.getWorkerList, true)
      .map(list => list as Worker[]).toPromise();
  }

  UpdatePhaseTaskDeliveryDate(mwtPhaseId: number, taskId: number, deliveryDate: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.put(API.main_work_type_work.updatePhaseTaskDeliveryDate + mwtPhaseId + '/' + taskId + '/' + deliveryDate)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }

  GetMtwphaseById(mwtPhaseId: number) {
    return this._apiService.get(API.main_work_type_work.getMtwphaseById + mwtPhaseId)
      .map(res => res as MwtPhase).toPromise();
  }

}
