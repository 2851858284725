import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { IvaBreakdown } from 'src/app/modules/auxiliary/entities/iva-breakdown';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { Budget } from 'src/app/modules/work/entities/budget';
import { Client } from 'src/app/modules/work/entities/client';
import { Work } from 'src/app/modules/work/entities/work';

@Component({
  selector: 'app-budget-report',
  templateUrl: './budget-report.component.html'
})
export class BudgetReportComponent implements OnInit, OnChanges {

  @Input() budget: Budget;
  @Input() subTotal: number;
  @Input() totalDiscount: number;
  @Input() totalVatAmount: number;
  @Input() client: Client;
  @Input() selectedWork: Work;
  @Input() ivaBreakdownList: IvaBreakdown[];
  imageData: any;
  total: number;

  constructor(private _commonUIService: CommonUIService) { }

  ngOnChanges(): void {
    if (this.subTotal !== undefined && this.totalDiscount !== undefined && this.totalVatAmount !== undefined) {
      this.total = Number((this.subTotal - this.totalDiscount + this.totalVatAmount).toFixed(2));
    }
  }

  ngOnInit() {
    this.LoadLabConfigurationList();
  }

  async LoadLabConfigurationList() {
    this.imageData = await this._commonUIService.LoadLabConfigurationList();
  }

}

