import API from '../../../../common/api.config.json';
import { ApiService } from '../../../../common/services/api.service';
import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import { RequestData } from '../../../../work/pagination/requestData';
import { LabSection } from '../../../entities/lab-section';
import { Client } from '../../../entities/client';
import { Doctor } from '../../../entities/doctor';
import { MainWorkType } from '../../../entities/main-work-type';
import { Work } from '../../../entities/work';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { PriorityOption } from 'src/app/modules/common/global';
import { Priority } from 'src/app/modules/common/entities/dropdown-enum';

@Component({
  selector: 'app-tab-work',
  templateUrl: './tab-work.component.html',
  styleUrls: ['./tab-work.component.scss']
})
export class TabWorkComponent implements OnInit {

  @Output() public OnClickWorkForWorkExpand: EventEmitter<Work> = new EventEmitter<Work>();

  private workList = 1;

  // pagination propeties
  sessionGetUrl: string;
  pagWorkList: any[] = [];
  requestData: RequestData = new RequestData(1, 20, '', '', '', this.workList);
  refresh: boolean;
  searchText: string;
  fromDate: any;
  toDate: any;
  labSectionValue: string;
  workTypeValue: string;
  clientValue: string;
  doctorValue: string;
  dateTypeId: any;
  fromDateModel: any;
  toDateModel: any;
  requiredDateTypeId = false;
  enableExportButton: boolean;
  isShowExpandlist = false;
  isDateAvailable = false;
  priorityValue: string;

  labSectionList: LabSection[];
  clientList: Client[] = [];
  doctorList: Doctor[];
  typeOfWorkList: MainWorkType[];
  priorityOption = [];

  // in here using enum as Priority
  priorityEnum = Priority;

  @ViewChild('df') from_date_picker: ElementRef;
  @ViewChild('dt') to_date_picker: ElementRef;

  work: Work;

  constructor(
    private _apiService: ApiService,
    public _commonUIService: CommonUIService,
    public _commonDataService: CommonDataService) {
    this.work = new Work();
  }

  ngOnInit() {
    this.labSectionValue = '';
    this.workTypeValue = '';
    this.clientValue = '';
    this.doctorValue = '';
    this.dateTypeId = '0';
    this.fromDateModel = '';
    this.toDateModel = '';
    this.priorityValue = '';

    this.LoadLabSectionList();
    this.LoadClientList();
    this.LoadDoctorList();
    this.LoadWorkTypeList();
    this.priorityOption = PriorityOption;

    this.sessionGetUrl = API.work.getWorkExpandPaginationList;
    this.enableExportButton = false;
  }

  LoadLabSectionList() {

    this._apiService.get(API.lab_sections.getlabSectionsList)
      .subscribe(res => {
        this.labSectionList = res;
        this.enableExportButton = false;
      });
  }

  LoadClientList() {

    this._apiService.get(API.client.getClients)
      .subscribe(res => {
        this.clientList = res;
        this.enableExportButton = false;
      });
  }

  LoadWorkTypeList() {

    this._apiService.get(API.main_work_type_work.getMainWorkTypeList)
      .subscribe(res => {
        this.typeOfWorkList = res;
        this.enableExportButton = false;
      });
  }

  LoadDoctorList() {

    this._apiService.get(API.doctor.getDoctorList)
      .subscribe(res => {
        this.doctorList = res;
        this.enableExportButton = false;
      });
  }

  EnableSearchButton() {
    if (this.clientValue || this.labSectionValue || this.workTypeValue || this.doctorValue || this.fromDateModel || this.toDateModel ||
      this.priorityValue) {
      this.enableExportButton = true;
    } else {
      this.enableExportButton = false;
      this.isShowExpandlist = false;
    }
  }

  EnableRequiredDateType() {
    if (this.fromDateModel || this.toDateModel) this.isDateAvailable = true; else this.isDateAvailable = false;

    if (this.dateTypeId === '0' && this.isDateAvailable) {
      this.requiredDateTypeId = true;
      this.enableExportButton = false;
    } else {
      this.requiredDateTypeId = false;
      this.EnableSearchButton();
    }
  }

  FilterWorkByText(event) {
    this.pagWorkList = [];
    this.requestData.page = 1;
    if ((this.from_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = this._commonUIService.ChangeNgbDateToCustomFormat(this.fromDateModel).toString();
      if (dateString) {
        this.fromDate = dateString;
      } else {
        this.fromDate = '';
      }
    } else {
      this.fromDate = '';
    }

    if ((this.to_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = this._commonUIService.ChangeNgbDateToCustomFormat(this.toDateModel).toString();
      if (dateString) {
        this.toDate = dateString;
      } else {
        this.toDate = '';
      }
    } else {
      this.toDate = '';
    }

    if (this.fromDate == '' && this.toDate == '') {
      this.dateTypeId = 0;
    }

    this.requestData.filter = `${this.dateTypeId};${this.clientValue};${this.labSectionValue};${this.doctorValue};${this.fromDate};${this.toDate};${this.priorityValue};`;
    this.refresh = true;
    this.isShowExpandlist = true;
  }

  ExportAsXLSX(): void {
    if ((this.from_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = this._commonUIService.ChangeNgbDateToCustomFormat(this.fromDateModel).toString();
      if (dateString !== undefined) {
        this.fromDate = dateString;
      } else {
        this.fromDate = '';
      }
    } else {
      this.fromDate = '';
    }

    if ((this.to_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = this._commonUIService.ChangeNgbDateToCustomFormat(this.toDateModel).toString();
      if (dateString) {
        this.toDate = dateString;
      } else {
        this.toDate = '';
      }
    } else {
      this.toDate = '';
    }

    if (this.fromDate == '' && this.toDate == '')
      this.dateTypeId = 0;

    this.work.dateTypeId = this.dateTypeId;
    this.work.clientValue = +this.clientValue;
    this.work.labSectionValue = +this.labSectionValue;
    this.work.doctorValue = +this.doctorValue;
    this.work.fromDate = this.fromDate;
    this.work.toDate = this.toDate;

    let params = new URLSearchParams();
    for (let key in this.work) {
      params.set(key, this.work[key])
    }

    let query = params.toString().replace('+', '%20')

    var url = this._apiService.BASE_URL + `/api/Works/WorkExcelExport?${query}`;
    window.open(url, '_blank');
  }

  LoadWorkRightPanel(work: Work, event) {
    this.OnClickWorkForWorkExpand.emit(work);
    this._commonUIService.isActiveRightPanelOnly = false;
    this._commonUIService.isActiveLeftPanelOnly = false;
    this._commonUIService.isActiveBothPanel = false;
    this._commonUIService.isActiveStatisticPanel = false;
  }
}
