import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { MainWorkType } from '../../entities/main-work-type';
import { CommonDataService } from '../../../common/services/common.data.service';
import swal from 'sweetalert2';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { WorkStatusService } from '../../services/work-status.service';
import { ExternalWorkService } from '../../services/external-work.service';
import { MWTExternalData } from '../../entities/main-work-type-external-data';
import { Priority } from 'src/app/modules/common/entities/dropdown-enum';
import { WorkService } from '../../services/work.service';
import { ToastyService } from 'ng2-toasty';

@Component({
  selector: 'app-work-type-carousel',
  templateUrl: './work-type-carousel.component.html',
  styleUrls: ['./work-type-carousel.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkTypeCarouselComponent implements OnInit, OnChanges {

  /* UI */
  imgags: string[] = ['assets/bg.jpg', 'assets/car.png', 'assets/canberra.jpg', 'assets/holi.jpg'];

  carouselTileItems: Array<any> = [0, 1, 2, 3, 4, 5];

  carouselTiles = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [] };

  carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 2, lg: 3, all: 0 },
    slide: 3,
    speed: 250,
    point: { visible: true },
    load: 2,
    velocity: 0,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  /* Objects */
  @Output() OnSelectMainWorkType: EventEmitter<{ mainWorkType: MainWorkType, index: number }> =
    new EventEmitter<{ mainWorkType: MainWorkType, index: number }>();
  @Output() OnDeleteMainWorkType: EventEmitter<number> = new EventEmitter<number>();
  @Output() OnEditMainWorkType: EventEmitter<MainWorkType> = new EventEmitter<MainWorkType>();
  @Output() OnPrintReport: EventEmitter<{ mainWorkType: MainWorkType, report: string }> =
    new EventEmitter<{ mainWorkType: MainWorkType, report: string }>();
  @Output() OnSendEmailReport: EventEmitter<{ mainWorkType: MainWorkType, report: string }> =
    new EventEmitter<{ mainWorkType: MainWorkType, report: string }>();
  @Output() workPlanSaved = new EventEmitter<boolean>();

  /* Lists */
  @Input() mainWorkTypeList: MainWorkType[];
  externalDataList: MWTExternalData[] = [];

  // in here using enum as Priority
  priorityEnum = Priority;
  hasbateaNo = false;

  constructor(private _commonDataService: CommonDataService,
    private _toastyService: ToastyService,
    private externalDataService: ExternalWorkService,
    public commonUiService: CommonUIService,
    public _workService: WorkService,
    private _statusService: WorkStatusService) { 
      this.checkHasBateaNo();
    }

  ngOnInit() { this.carouselTileItems.forEach(el => this.carouselTileLoad(el)); }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mainWorkTypeList.previousValue !== changes.mainWorkTypeList.currentValue) {
      this.checkIsExternalData();
      this.checkHasBateaNo();    
    }
  }

  async checkIsExternalData() {
    this.externalDataList = await this.externalDataService.GetMWTExternalDataList();
    if (this.mainWorkTypeList) {
      this.mainWorkTypeList.forEach(workType => {
        const externalData = this.externalDataList.find(x => x.mainWorkTypeId === workType.id);
        if (externalData != null && externalData.id > 0) {
          workType.isExternal = true;
        } else {
          workType.isExternal = false;
        }
      });
    }
  }

  checkHasBateaNo(){
    if(this.mainWorkTypeList && this.mainWorkTypeList.length>0){
      const obj = this.mainWorkTypeList.filter(x=>x.bateaNo)? this.mainWorkTypeList.filter(x=>x.bateaNo).length : 0 ;
      this.hasbateaNo = obj != 0? true : false;
    }
  }
  //#region Events

  OnClickMainWorkType(mainWorkType: MainWorkType, index: number) {
    if (mainWorkType && index > -1) { this.OnSelectMainWorkType.emit({ mainWorkType: mainWorkType, index: index }); }
    this._statusService.SetChangedStatus(true);
  }

  OnRemoveMainWorkType(mainWorkTypeId: number) {
    if (mainWorkTypeId) {      
       // checking if deliveryNote exist(also check Invoice) for main work type before deleting
       if(this._workService.deliveryNoteData && this._workService.deliveryNoteData.length > 0){
        for(var x=0;  x < this._workService.deliveryNoteData.length; ++x){
          const mwtArticle = this._workService.deliveryNoteData[x].articleList;
          if(mwtArticle && mwtArticle.length >0){
            if(mwtArticle.filter(t=>t.mainWorkTypeId == mainWorkTypeId).length > 0){
              return this._toastyService.warning({
                title: this._commonDataService.localizationLabelList['work'],
                msg: this._commonDataService.localizationLabelList['cannot_delete'],
              });
            }
          }
        }
      }
      // Checking if Budget exist for main work type before deleting
      if(this._workService.budgetData && this._workService.budgetData.length > 0){
        if(this._workService.budgetData.filter(t=>t.mainWorktypeId == mainWorkTypeId).length > 0){
          return this._toastyService.warning({
            title: this._commonDataService.localizationLabelList['work'],
            msg: this._commonDataService.localizationLabelList['cannot_delete'],
          });
        }
      }

      swal({
        title: this._commonDataService.localizationLabelList['work_type'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      }).then((result) => { if (result.value) { this.OnDeleteMainWorkType.emit(mainWorkTypeId); } });
    }
  }

  OnModifyMainWorkType(mainWorkType: MainWorkType) { if (mainWorkType) { this.OnEditMainWorkType.emit(mainWorkType); } }

  OnPrintConfirmityReport(mainWorkType: MainWorkType) {
    if (mainWorkType) { this.OnPrintReport.emit({ mainWorkType: mainWorkType, report: 'confirmity_report' }); }
  }

  OnPrintSanityGuaranteeReport(mainWorkType: MainWorkType) {
    if (mainWorkType) { this.OnPrintReport.emit({ mainWorkType: mainWorkType, report: 'sanity_guarantee_report' }); }
  }

  OnPrintUsingReport(mainWorkType: MainWorkType) {
    if (mainWorkType) { this.OnPrintReport.emit({ mainWorkType: mainWorkType, report: 'using_report' }); }
  }

  OnPrintWorkReport(mainWorkType: MainWorkType) {
    if (mainWorkType) { this.OnPrintReport.emit({ mainWorkType: mainWorkType, report: 'work_report' }); }
  }

  OnPrintIncidentReport(mainWorkType: MainWorkType) {
    if (mainWorkType) { this.OnPrintReport.emit({ mainWorkType: mainWorkType, report: 'incident_report' }); }
  }

  async OnSendEmailModalOpen(mainWorkType: MainWorkType, report: string) {
    if (mainWorkType) {
      this.OnSendEmailReport.emit({ mainWorkType: mainWorkType, report: report });
    }
  }

  OnPrintConformityReport(mainWorkType: MainWorkType) {
    if (mainWorkType) { this.OnPrintReport.emit({ mainWorkType: mainWorkType, report: 'conformity_report' }); }
  }

  async OnClickPlanWorkDates(mainWorkType: MainWorkType, index: number) {
    try {
      const swalResult = await swal({
        title: this._commonDataService.localizationLabelList['PLAN_WORK_DATES'],
        text: this._commonDataService.localizationLabelList['DO_YOU_WANT_TO_PLAN'],
        type: 'warning', showCancelButton: true,
        confirmButtonColor: '#3085d6', cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      });
      if (swalResult.value) {
        this.commonUiService.isSpinnerVisible = true;
        const result: { status: boolean, description: string, warning: string } = await this._workService
          .CheckPlanningDates(mainWorkType.id);
        if (result.status) {
          if (result.warning === 'TimeNotCompleted') {
            this._toastyService.warning({
              title: this._commonDataService.localizationLabelList['PLAN_WORK_DATES'],
              msg: this._commonDataService.localizationLabelList['TIME_NOT_COMPLETED'],
            });
          }
          await this.OnSavePlanningDates(mainWorkType, index);
        } else {
          if (result.warning === 'TimeNotCompleted') {
            this._toastyService.warning({
              title: this._commonDataService.localizationLabelList['PLAN_WORK_DATES'],
              msg: this._commonDataService.localizationLabelList['TIME_NOT_COMPLETED'],
            });
          }
          switch (result.description) {
            case 'MwtPhaseDateExceeds':
              this.OnMwtPhaseDateExceeds(mainWorkType, index);
              break;
            case 'MwtDeliveryDateExceeds':
              this.OnMwtDeliveryDateExceeds(mainWorkType, index);
              break;
            case 'TimeNotConfigured':
              this._toastyService.warning({
                title: this._commonDataService.localizationLabelList['PLAN_WORK_DATES'],
                msg: this._commonDataService.localizationLabelList['TIME_NOT_CONFIGURED'],
              });
              break;
            default:
              break;
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  private async OnMwtDeliveryDateExceeds(mwt: MainWorkType, index: number) {
    try {
      const swalResult = await swal({
        title: this._commonDataService.localizationLabelList['PLAN_WORK_DATES'],
        text: this._commonDataService.localizationLabelList['LAST_PHASE_DATE_EXCEEDS_MWT_DELIVERY_DATE'],
        type: 'warning', showCancelButton: true,
        confirmButtonColor: '#3085d6', cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      });
      if (swalResult) {
        if (swalResult.value) {
          await this.OnSavePlanningDates(mwt, index);
        }
      }
    } catch (error) { console.log(error); }
  }

  private async OnMwtPhaseDateExceeds(mwt: MainWorkType, index: number) {
    try {
      const swalResult = await swal({
        title: this._commonDataService.localizationLabelList['PLAN_WORK_DATES'],
        text: this._commonDataService.localizationLabelList['LAST_TASK_DATE_EXCEEDS_MWT_PHASE_DATE'],
        type: 'warning', showCancelButton: true,
        confirmButtonColor: '#3085d6', cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      });
      if (swalResult) {
        if (swalResult.value) {
          await this.OnSavePlanningDates(mwt, index);
        }
      }
    } catch (error) { console.log(error); }
  }

  private async OnSavePlanningDates(mwt: MainWorkType, index: number) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      const response: { isSaved: true } = await this._workService
        .SavePlanningDates(mwt.id);
      if (response) {
        if (response.isSaved) {
          this._toastyService.success({
            title: this._commonDataService.localizationLabelList['PLAN_WORK_DATES'],
            msg: this._commonDataService.localizationLabelList['save_success'],
          });
          this.workPlanSaved.emit(response.isSaved);
        }
      }
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  //#endregion

  //#region UI

  private carouselTileLoad(j: string | number) {
    const len = this.carouselTiles[j].length;
    if (len <= 30) {
      for (let i = len; i < len + 15; i++) {
        this.carouselTiles[j].push(this.imgags[Math.floor(Math.random() * this.imgags.length)]);
      }
    }
  }

  //#endregion
}
