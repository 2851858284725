import { Injectable } from '@angular/core';
import API from '../../common/api.config.json';
import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { UserRole } from '../../user/entities/user-role';
import { Province } from '../../auxiliary/entities/province';
import { Country } from '../../auxiliary/entities/country';
import { ApiService } from './api.service';
import { PermissionListViewModel } from '../../user/entities/permission-list-view-model';
import { Town } from '../../auxiliary/entities/town';
import { User } from '../../auth/entities/user';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { DropDownOption } from '../entities/dropdown-option';
import { CommonFunctions } from 'src/app/modules/common/services/common-functions';

@Injectable()
export class CommonDataService {

  private behaviorSubjectForLocalization: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private behaviorSubjectForStatistic: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private messageSourceForLocalization = this.behaviorSubjectForLocalization;
  private messageSourceForStatistic = this.behaviorSubjectForStatistic;
  public isLanguageChanged = this.messageSourceForLocalization.asObservable();
  public isStatisticPanel = this.messageSourceForStatistic.asObservable();

  @Output() userRoleEventEmitter = new EventEmitter();
  @Output() permissionListEventEmitter = new EventEmitter();
  @Output() townListEventEmitter = new EventEmitter();
  @Output() countryEventEmitter = new EventEmitter();
  @Output() provinceEventEmitter = new EventEmitter();
  @Output() workerScheduleChanged = new EventEmitter();

  userRoleList: UserRole[] = [];
  countryList: Country[] = [];
  provinceList: Province[] = [];
  townList: Town[] = [];
  userPermissionList: PermissionListViewModel;

  /* Localization */
  localizationLabelList: string[] = [];
  _currentLanguage: string;
  placeHolderValue: string;

  appCurrency: string;

  constructor(private _apiService: ApiService, private _translateService: TranslateService) { }

  public SetTranslatedLabels(prefix: string) {
    this._translateService.store.currentLang = prefix ? prefix : 'en';
    this._translateService.get('price_list.select_group').subscribe(
      (translation: string) => {
        this.localizationLabelList['select_group'] = translation;
      }
    );
    this._translateService.get('price_list.select_price_list').subscribe((translation: string) => {
      this.localizationLabelList['select_price_list'] = translation;
    });
    this._translateService.get('workers.worker_task_planning_message').subscribe((translation: string) => {
      this.localizationLabelList['worker_task_planning_message'] = translation;
    });
    this._translateService.get('common.save_success').subscribe((translation: string) => {
      this.localizationLabelList['save_success'] = translation;
    });
    this._translateService.get('common.update_success').subscribe((translation: string) => {
      this.localizationLabelList['update_success'] = translation;
    });
    this._translateService.get('common.update_error').subscribe((translation: string) => {
      this.localizationLabelList['update_error'] = translation;
    });
    this._translateService.get('common.image_upload_success').subscribe((translation: string) => {
      this.localizationLabelList['image_upload_success'] = translation;
    });
    this._translateService.get('common.invalid_gen_number_or_existing').subscribe((translation: string) => {
      this.localizationLabelList['invalid_gen_number_or_existing'] = translation;
    });
    this._translateService.get('invoice.invalid_date_range').subscribe((translation: string) => {
      this.localizationLabelList['invalid_date_range'] = translation;
    });
    this._translateService.get('common.image_upload_error').subscribe((translation: string) => {
      this.localizationLabelList['image_upload_error'] = translation;
    });
    this._translateService.get('common.image_removed_success').subscribe((translation: string) => {
      this.localizationLabelList['image_removed_success'] = translation;
    });
    this._translateService.get('common.image_removed_error').subscribe((translation: string) => {
      this.localizationLabelList['image_removed_error'] = translation;
    });
    this._translateService.get('common.save_error').subscribe((translation: string) => {
      this.localizationLabelList['save_error'] = translation;
    });
    this._translateService.get('common.delete_incident').subscribe((translation: string) => {
      this.localizationLabelList['delete_incident'] = translation;
    });
    this._translateService.get('common.delete_warning').subscribe((translation: string) => {
      this.localizationLabelList['delete_warning'] = translation;
    });
    this._translateService.get('common.delete_warning_2').subscribe((translation: string) => {
      this.localizationLabelList['delete_warning_2'] = translation;
    });
    this._translateService.get('common.delete_success').subscribe((translation: string) => {
      this.localizationLabelList['delete_success'] = translation;
    });
    this._translateService.get('common.status_updated').subscribe((translation: string) => {
      this.localizationLabelList['status_updated'] = translation;
    });
    this._translateService.get('common.status').subscribe((translation: string) => {
      this.localizationLabelList['status'] = translation;
    });
    this._translateService.get('common.delete_error').subscribe((translation: string) => {
      this.localizationLabelList['delete_error'] = translation;
    });
    this._translateService.get('common.on_delete_worker').subscribe((translation: string) => {
      this.localizationLabelList['on_delete_worker'] = translation;
    });
    this._translateService.get('common.delete_error2').subscribe((translation: string) => {
      this.localizationLabelList['delete_error2'] = translation;
    });
    this._translateService.get('common.yes').subscribe((translation: string) => {
      this.localizationLabelList['common_yes'] = translation;
    });
    this._translateService.get('common.accept').subscribe((translation: string) => {
      this.localizationLabelList['common_accept'] = translation;
    });
    this._translateService.get('common.cancel').subscribe((translation: string) => {
      this.localizationLabelList['common_cancel'] = translation;
    });
    this._translateService.get('auxiliary.color.colors').subscribe((translation: string) => {
      this.localizationLabelList['colors'] = translation;
    });
    this._translateService.get('auxiliary.group_of_material.group_of_material').subscribe((translation: string) => {
      this.localizationLabelList['group_of_material'] = translation;
    });
    this._translateService.get('auxiliary.group_of_article.group_of_articles').subscribe((translation: string) => {
      this.localizationLabelList['group_of_articles'] = translation;
    });
    this._translateService.get('auxiliary.expenses_group_auxiliary.expenses_group').subscribe((translation: string) => {
      this.localizationLabelList['expenses_group'] = translation;
    });
    this._translateService.get('auxiliary.guide.guides').subscribe((translation: string) => {
      this.localizationLabelList['guides'] = translation;
    });
    this._translateService.get('auxiliary.material.materials').subscribe((translation: string) => {
      this.localizationLabelList['materials'] = translation;
    });
    this._translateService.get('auxiliary.product_description.product_descriptions').subscribe((translation: string) => {
      this.localizationLabelList['product_descriptions'] = translation;
    });
    this._translateService.get('auxiliary.quality_type.quality_types').subscribe((translation: string) => {
      this.localizationLabelList['quality_types'] = translation;
    });
    this._translateService.get('auxiliary.work_motive_no_fee.work_motive_no_fee').subscribe((translation: string) => {
      this.localizationLabelList['work_motive_no_fee'] = translation;
    });
    this._translateService.get('auxiliary.reasons_for_regularization.reasons_for_regularization').subscribe((translation: string) => {
      this.localizationLabelList['reasons_for_regularization'] = translation;
    });
    this._translateService.get('auxiliary.states_of_work.states_of_work').subscribe((translation: string) => {
      this.localizationLabelList['states_of_work'] = translation;
    });
    this._translateService.get('auxiliary.states_of_work.default').subscribe((translation: string) => {
      this.localizationLabelList['default'] = translation;
    });
    this._translateService.get('auxiliary.states_of_work.finished').subscribe((translation: string) => {
      this.localizationLabelList['finished'] = translation;
    });
    this._translateService.get('auxiliary.therapeutic_purposes.therapeutic_purposes').subscribe((translation: string) => {
      this.localizationLabelList['therapeutic_purposes'] = translation;
    });
    this._translateService.get('auxiliary.shipping_forms.shipping_forms').subscribe((translation: string) => {
      this.localizationLabelList['shipping_forms'] = translation;
    });
    this._translateService.get('auxiliary.phases.phases').subscribe((translation: string) => {
      this.localizationLabelList['phases'] = translation;
    });
    this._translateService.get('auxiliary.phases.is_test').subscribe((translation: string) => {
      this.localizationLabelList['is_test'] = translation;
    });
    this._translateService.get('auxiliary.reasons_for_Absent.reasons_for_Absent').subscribe((translation: string) => {
      this.localizationLabelList['reasons_for_Absent'] = translation;
    });
    this._translateService.get('auxiliary.responsables.responsables').subscribe((translation: string) => {
      this.localizationLabelList['responsables'] = translation;
    });
    this._translateService.get('auxiliary.laboratory_sections.laboratory_sections').subscribe((translation: string) => {
      this.localizationLabelList['laboratory_sections'] = translation;
    });
    this._translateService.get('auxiliary.client_type.client_type').subscribe((translation: string) => {
      this.localizationLabelList['client_type'] = translation;
    });
    this._translateService.get('auxiliary.work_shifts.work_shifts').subscribe((translation: string) => {
      this.localizationLabelList['work_shifts'] = translation;
    });
    this._translateService.get('auxiliary.town.town').subscribe((translation: string) => {
      this.localizationLabelList['town'] = translation;
    });
    this._translateService.get('auxiliary.country.country').subscribe((translation: string) => {
      this.localizationLabelList['country'] = translation;
    });
    this._translateService.get('auxiliary.language.language').subscribe((translation: string) => {
      this.localizationLabelList['language'] = translation;
    });
    this._translateService.get('auxiliary.province.province').subscribe((translation: string) => {
      this.localizationLabelList['province'] = translation;
    });
    this._translateService.get('auxiliary.localizations.localizations').subscribe((translation: string) => {
      this.localizationLabelList['localizations'] = translation;
    });
    this._translateService.get('auxiliary.motives_cancel_phase.motives_cancel_phase').subscribe((translation: string) => {
      this.localizationLabelList['motives_cancel_phase'] = translation;
    });
    this._translateService.get('auxiliary.metals.metals').subscribe((translation: string) => {
      this.localizationLabelList['metals'] = translation;
    });
    this._translateService.get('auxiliary.resource.resource').subscribe((translation: string) => {
      this.localizationLabelList['resource'] = translation;
    });
    this._translateService.get('payment_methods.payment_methods').subscribe((translation: string) => {
      this.localizationLabelList['payment_methods'] = translation;
    });
    this._translateService.get('iva_types.iva_types').subscribe((translation: string) => {
      this.localizationLabelList['iva_types'] = translation;
    });
    this._translateService.get('warehouse_section.warehouse_section').subscribe((translation: string) => {
      this.localizationLabelList['warehouse_section'] = translation;
    });
    this._translateService.get('unit_type.unit_type').subscribe((translation: string) => {
      this.localizationLabelList['unit_type'] = translation;
    });
    this._translateService.get('issuer.issuer').subscribe((translation: string) => {
      this.localizationLabelList['issuer'] = translation;
    });
    this._translateService.get('auxiliary.series_aux.series').subscribe((translation: string) => {
      this.localizationLabelList['series'] = translation;
    });
    this._translateService.get('auxiliary.series_aux.series_already_used').subscribe((translation: string) => {
      this.localizationLabelList['series_already_used'] = translation;
    });
    this._translateService.get('auxiliary.series_aux.year_already_exist').subscribe((translation: string) => {
      this.localizationLabelList['series_year_already_exist'] = translation;
    });
    this._translateService.get('auxiliary.series_aux.already_clone').subscribe((translation: string) => {
      this.localizationLabelList['already_clone'] = translation;
    });
    this._translateService.get('auxiliary.series_aux.clone_error').subscribe((translation: string) => {
      this.localizationLabelList['clone_error'] = translation;
    });
    this._translateService.get('auxiliary.series_aux.clone_success').subscribe((translation: string) => {
      this.localizationLabelList['clone_success'] = translation;
    });
    this._translateService.get('workers.more_filter').subscribe((translation: string) => {
      this.localizationLabelList['more_filter'] = translation;
    });
    this._translateService.get('workers.less_filter').subscribe(
      (translation: string) => {
        this.localizationLabelList['less_filter'] = translation;
      });
    this._translateService.get('workers.no_permissions_for_tablet_mode').subscribe(
      (translation: string) => { this.localizationLabelList['no_permissions_for_tablet_mode'] = translation; });
    this._translateService.get('menu_items.task').subscribe((translation: string) => {
      this.localizationLabelList['task'] = translation;
    });
    this._translateService.get('menu_items.work').subscribe((translation: string) => {
      this.localizationLabelList['work'] = translation;
    });
    this._translateService.get('work.add_new_patient').subscribe((translation: string) => {
      this.localizationLabelList['add_new_patient_work'] = translation;
    });
    this._translateService.get('workers.worker').subscribe((translation: string) => {
      this.localizationLabelList['worker'] = translation;
    });
    this._translateService.get('task.workers').subscribe((translation: string) => {
      this.localizationLabelList['workers'] = translation;
    });
    this._translateService.get('product.product').subscribe((translation: string) => {
      this.localizationLabelList['product'] = translation;
    });
    this._translateService.get('product.lot').subscribe((translation: string) => {
      this.localizationLabelList['lot'] = translation;
    });
    this._translateService.get('product.active_lot_update').subscribe((translation: string) => {
      this.localizationLabelList['active_lot_update'] = translation;
    });
    this._translateService.get('product.no_quantity_lot_available_for_product').subscribe((translation: string) => {
      this.localizationLabelList['no_quantity_lot_available_for_product'] = translation;
    });
    this._translateService.get('product.product_undefined').subscribe((translation: string) => {
      this.localizationLabelList['product_undefined'] = translation;
    });
    this._translateService.get('product.stock').subscribe((translation: string) => {
      this.localizationLabelList['stock'] = translation;
    });
    this._translateService.get('product.months').subscribe((translation: string) => {
      this.localizationLabelList['months'] = translation;
    });
    this._translateService.get('product.consumption').subscribe((translation: string) => {
      this.localizationLabelList['consumption'] = translation;
    });
    this._translateService.get('product.provider').subscribe((translation: string) => {
      this.localizationLabelList['provider'] = translation;
    });
    this._translateService.get('product.select_provider').subscribe((translation: string) => {
      this.localizationLabelList['select_provider'] = translation;
    });
    this._translateService.get('product.select_unit_type').subscribe((translation: string) => {
      this.localizationLabelList['select_unit_type'] = translation;
    });
    this._translateService.get('product.select_inventory_frequency').subscribe((translation: string) => {
      this.localizationLabelList['select_inventory_frequency'] = translation;
    });
    this._translateService.get('product.select_lot').subscribe((translation: string) => {
      this.localizationLabelList['select_lot'] = translation;
    });
    this._translateService.get('product.list_empty').subscribe((translation: string) => {
      this.localizationLabelList['list_empty'] = translation;
    });
    this._translateService.get('product.select_iva_type').subscribe((translation: string) => {
      this.localizationLabelList['select_iva_type'] = translation;
    });
    this._translateService.get('product.select_manufacturer').subscribe((translation: string) => {
      this.localizationLabelList['select_manufacturer'] = translation;
    });
    this._translateService.get('product.select_manufacturer').subscribe((translation: string) => {
      this.localizationLabelList['select_manufacturer2'] = translation;
    });
    this._translateService.get('product.select_lab_section').subscribe((translation: string) => {
      this.localizationLabelList['select_lab_section'] = translation;
    });
    this._translateService.get('product.select_warehouse_section').subscribe((translation: string) => {
      this.localizationLabelList['select_warehouse_section'] = translation;
    });
    this._translateService.get('menu_items.article').subscribe((translation: string) => {
      this.localizationLabelList['article'] = translation;
    });
    this._translateService.get('article.article_product').subscribe((translation: string) => {
      this.localizationLabelList['article_product'] = translation;
    });
    this._translateService.get('article.price_list').subscribe((translation: string) => {
      this.localizationLabelList['price_list'] = translation;
    });
    this._translateService.get('article.article_product_save_error_msg').subscribe((translation: string) => {
      this.localizationLabelList['article_product_save_error_msg'] = translation;
    });
    this._translateService.get('article.article_already_existing').subscribe((translation: string) => {
      this.localizationLabelList['article_already_existing'] = translation;
    });
    this._translateService.get('article.article_group_already_exists').subscribe((translation: string) => {
      this.localizationLabelList['article_group_already_exists'] = translation;
    });
    this._translateService.get('article.select_article_group').subscribe((translation: string) => {
      this.localizationLabelList['select_article_group'] = translation;
    });
    this._translateService.get('article.select_product').subscribe((translation: string) => {
      this.localizationLabelList['select_product'] = translation;
    });
    this._translateService.get('providers.providers_manufacturers').subscribe((translation: string) => {
      this.localizationLabelList['providers_manufacturers'] = translation;
    });
    this._translateService.get('providers.select_format').subscribe((translation: string) => {
      this.localizationLabelList['select_format'] = translation;
    });
    this._translateService.get('price_list.select_required_price_tobe_transfer').subscribe((translation: string) => {
      this.localizationLabelList['select_required_price_tobe_transfer'] = translation;
    });
    this._translateService.get('price_list.select_price_list_in_right_side').subscribe((translation: string) => {
      this.localizationLabelList['select_price_list_in_right_side'] = translation;
    });
    this._translateService.get('price_list.select_price_list_are_empty_add').subscribe((translation: string) => {
      this.localizationLabelList['select_price_list_are_empty_add'] = translation;
    });
    this._translateService.get('client.client_bank').subscribe((translation: string) => {
      this.localizationLabelList['client_bank'] = translation;
    });
    this._translateService.get('client.client').subscribe((translation: string) => {
      this.localizationLabelList['client'] = translation;
    });
    this._translateService.get('client.client_doctor').subscribe((translation: string) => {
      this.localizationLabelList['client_doctor'] = translation;
    });
    this._translateService.get('client.doctor_metal').subscribe((translation: string) => {
      this.localizationLabelList['doctor_metal'] = translation;
    });
    this._translateService.get('auxiliary.metals.metals').subscribe((translation: string) => {
      this.localizationLabelList['metals'] = translation;
    });
    this._translateService.get('client.client_metal').subscribe((translation: string) => {
      this.localizationLabelList['client_metal'] = translation;
    });
    this._translateService.get('client.client_physical_data').subscribe((translation: string) => {
      this.localizationLabelList['client_physical_data'] = translation;
    });
    this._translateService.get('work_shifts.order').subscribe((translation: string) => {
      this.localizationLabelList['order'] = translation;
    });
    this._translateService.get('bank_extraction.bank_withdrawals').subscribe((translation: string) => {
      this.localizationLabelList['bank_withdrawals'] = translation;
    });
    this._translateService.get('client.client_bank_detail').subscribe((translation: string) => {
      this.localizationLabelList['client_bank_detail'] = translation;
    });
    this._translateService.get('client.client_notes').subscribe((translation: string) => {
      this.localizationLabelList['client_notes'] = translation;
    });
    this._translateService.get('client.client_series').subscribe((translation: string) => {
      this.localizationLabelList['client_series'] = translation;
    });
    this._translateService.get('auxiliary.metals.select_article').subscribe((translation: string) => {
      this.localizationLabelList['select_article'] = translation;
    });
    this._translateService.get('client.client_discount').subscribe((translation: string) => {
      this.localizationLabelList['client_discount'] = translation;
    });
    this._translateService.get('client.discount').subscribe((translation: string) => {
      this.localizationLabelList['discount'] = translation;
    });
    this._translateService.get('client.patient').subscribe((translation: string) => {
      this.localizationLabelList['patient'] = translation;
    });
    this._translateService.get('work_type.work_type').subscribe((translation: string) => {
      this.localizationLabelList['work_type'] = translation;
    });
    this._translateService.get('work_type.work_type_count').subscribe((translation: string) => {
      this.localizationLabelList['work_type_count'] = translation;
    });
    this._translateService.get('work.sub_work').subscribe((translation: string) => {
      this.localizationLabelList['sub_work'] = translation;
    });
    this._translateService.get('work.sub_total').subscribe((translation: string) => {
      this.localizationLabelList['sub_total'] = translation;
    });
    this._translateService.get('common.invoices').subscribe((translation: string) => {
      this.localizationLabelList['invoices'] = translation;
    });
    this._translateService.get('common.total_price').subscribe((translation: string) => {
      this.localizationLabelList['total_price'] = translation;
    });
    this._translateService.get('common.total_billing').subscribe((translation: string) => {
      this.localizationLabelList['total_billing'] = translation;
    });
    this._translateService.get('common.total_discount').subscribe((translation: string) => {
      this.localizationLabelList['total_discount'] = translation;
    });
    this._translateService.get('work_type.phase_task').subscribe((translation: string) => {
      this.localizationLabelList['phase_task'] = translation;
    });
    this._translateService.get('auxiliary.series_aux.delivery_notes').subscribe((translation: string) => {
      this.localizationLabelList['delivery_notes'] = translation;
    });
    this._translateService.get('work_type.task').subscribe((translation: string) => {
      this.localizationLabelList['task'] = translation;
    });
    this._translateService.get('work_type.task_workers').subscribe((translation: string) => {
      this.localizationLabelList['task_workers'] = translation;
    });
    this._translateService.get('work_type.worker_assigned_to_task_successfully').subscribe((translation: string) => {
      this.localizationLabelList['worker_assigned_to_task_successfully'] = translation;
    });
    this._translateService.get('work_type.phase_assigned_to_task_successfully').subscribe((translation: string) => {
      this.localizationLabelList['phase_assigned_to_task_successfully'] = translation;
    });
    this._translateService.get('work_type.phase_save_successfully').subscribe((translation: string) => {
      this.localizationLabelList['phase_save_successfully'] = translation;
    });
    this._translateService.get('work.works').subscribe((translation: string) => {
      this.localizationLabelList['works'] = translation;
    });
    this._translateService.get('work.another_no_is_equal_to_next_no').subscribe((translation: string) => {
      this.localizationLabelList['another_no_is_equal_to_next_no'] = translation;
    });
    this._translateService.get('work.tasks').subscribe((translation: string) => {
      this.localizationLabelList['tasks'] = translation;
    });
    this._translateService.get('workers.type_of_work').subscribe((translation: string) => {
      this.localizationLabelList['type_of_work'] = translation;
    });
    this._translateService.get('workers.finalized').subscribe((translation: string) => {
      this.localizationLabelList['finalized'] = translation;
    });
    this._translateService.get('workers.pending').subscribe((translation: string) => {
      this.localizationLabelList['pending'] = translation;
    });
    this._translateService.get('task.greater_than_phase_date_and_type_of_work').subscribe((translation: string) => {
      this.localizationLabelList['greater_than_phase_date_and_type_of_work'] = translation;
    });
    this._translateService.get('task.greater_than_phase_date').subscribe((translation: string) => {
      this.localizationLabelList['greater_than_phase_date'] = translation;
    });
    this._translateService.get('workers.started').subscribe((translation: string) => {
      this.localizationLabelList['started'] = translation;
    });
    this._translateService.get('providers.notes').subscribe((translation: string) => {
      this.localizationLabelList['notes'] = translation;
    });
    this._translateService.get('work_type.document_upload_successfully').subscribe((translation: string) => {
      this.localizationLabelList['document_upload_successfully'] = translation;
    });
    this._translateService.get('work_type.document').subscribe((translation: string) => {
      this.localizationLabelList['document'] = translation;
    });
    this._translateService.get('work.please_define_the_object').subscribe((translation: string) => {
      this.localizationLabelList['please_define_the_object'] = translation;
    });
    this._translateService.get('work.prescription_upload_successfully').subscribe((translation: string) => {
      this.localizationLabelList['prescription_upload_successfully'] = translation;
    });
    this._translateService.get('work.prescription').subscribe((translation: string) => {
      this.localizationLabelList['prescription'] = translation;
    });
    this._translateService.get('work.already_exist').subscribe((translation: string) => {
      this.localizationLabelList['work_already_exist'] = translation;
    });
    this._translateService.get('workers.select').subscribe((translation: string) => {
      this.localizationLabelList['select'] = translation;
    });
    this._translateService.get('work.bill_delivery_note_message').subscribe((translation: string) => {
      this.localizationLabelList['bill_delivery_note_message'] = translation;
    });
    this._translateService.get('client.doctorMetalErrorMessage').subscribe((translation: string) => {
      this.localizationLabelList['doctorMetalErrorMessage'] = translation;
    });
    this._translateService.get('clinic_materials.clinic_material').subscribe((translation: string) => {
      this.localizationLabelList['clinic_material'] = translation;
    });
    this._translateService.get('menu_items.invoice').subscribe((translation: string) => {
      this.localizationLabelList['invoice'] = translation;
    });
    this._translateService.get('menu_items.number_is_already_in_use').subscribe((translation: string) => {
      this.localizationLabelList['number_is_already_in_use'] = translation;
    });
    this._translateService.get('menu_items.please_select_delivery_notes_to_move').subscribe((translation: string) => {
      this.localizationLabelList['please_select_delivery_notes_to_move'] = translation;
    });
    this._translateService.get('task.fabrication_process').subscribe((translation: string) => {
      this.localizationLabelList['fabrication_process'] = translation;
    });
    this._translateService.get('task.process_exists').subscribe((translation: string) => {
      this.localizationLabelList['fabrication_process_exists'] = translation;
    });
    this._translateService.get('task.phase_exists').subscribe((translation: string) => {
      this.localizationLabelList['task_phase_exists'] = translation;
    });
    this._translateService.get('price_list.select_price_list').subscribe((translation: string) => {
      this.localizationLabelList['select_price_list'] = translation;
    });
    this._translateService.get('common.disabled').subscribe((translation: string) => {
      this.localizationLabelList['disabled'] = translation;
    });
    this._translateService.get('common.disable').subscribe((translation: string) => {
      this.localizationLabelList['disable'] = translation;
    });
    this._translateService.get('common.lock').subscribe((translation: string) => {
      this.localizationLabelList['lock'] = translation;
    });
    this._translateService.get('common.active').subscribe((translation: string) => {
      this.localizationLabelList['active'] = translation;
    });
    this._translateService.get('common.deactive').subscribe((translation: string) => {
      this.localizationLabelList['deactive'] = translation;
    });
    this._translateService.get('common.activate').subscribe((translation: string) => {
      this.localizationLabelList['activate'] = translation;
    });
    this._translateService.get('common.locked').subscribe((translation: string) => {
      this.localizationLabelList['locked'] = translation;
    });
    this._translateService.get('common.deactive').subscribe((translation: string) => {
      this.localizationLabelList['deactive'] = translation;
    });
    this._translateService.get('user.users').subscribe((translation: string) => {
      this.localizationLabelList['users'] = translation;
    });
    this._translateService.get('auxiliary.password_settings.password_settings').subscribe((translation: string) => {
      this.localizationLabelList['password_settings'] = translation;
    });
    this._translateService.get('common.email_already_exist').subscribe((translation: string) => {
      this.localizationLabelList['email_already_exist'] = translation;
    });
    this._translateService.get('common.password_already_exist').subscribe((translation: string) => {
      this.localizationLabelList['password_already_exist'] = translation;
    });
    this._translateService.get('user.user_role').subscribe((translation: string) => {
      this.localizationLabelList['user_role'] = translation;
    });
    this._translateService.get('auth.authentication').subscribe((translation: string) => {
      this.localizationLabelList['authentication'] = translation;
    });
    this._translateService.get('auth.invalid_username_password').subscribe((translation: string) => {
      this.localizationLabelList['invalid_username_password'] = translation;
    });
    this._translateService.get('auth.exceed_no_of_attempts_try_again').subscribe((translation: string) => {
      this.localizationLabelList['exceed_no_of_attempts_try_again'] = translation;
    });
    this._translateService.get('auth.password_expire').subscribe((translation: string) => {
      this.localizationLabelList['password_expire'] = translation;
    });
    this._translateService.get('auth.password_has_been_changed').subscribe((translation: string) => {
      this.localizationLabelList['password_has_been_changed'] = translation;
    });
    this._translateService.get('auth.user_deactivated').subscribe((translation: string) => {
      this.localizationLabelList['user_deactivated'] = translation;
    });
    this._translateService.get('user.user_permission').subscribe((translation: string) => {
      this.localizationLabelList['user_permission'] = translation;
    });
    this._translateService.get('auth.exceed_no_of_attempts').subscribe((translation: string) => {
      this.localizationLabelList['exceed_no_of_attempts'] = translation;
    });
    this._translateService.get('user.user_role_already_exist').subscribe((translation: string) => {
      this.localizationLabelList['user_role_already_exist'] = translation;
    });

    this._translateService.get('work.cannot_delete_due_to_delivery_notes').subscribe((translation: string) => {
      this.localizationLabelList['cannot_delete_due_to_delivery_notes'] = translation;
    });
    this._translateService.get('workers.worker_end_date_validation').subscribe((translation: string) => {
      this.localizationLabelList['worker_end_date_validation'] = translation;
    });
    this._translateService.get('client.client_work_type_note').subscribe((translation: string) => {
      this.localizationLabelList['client_work_type_note'] = translation;
    });
    this._translateService.get('client.doctor_work_type_note').subscribe((translation: string) => {
      this.localizationLabelList['doctor_work_type_note'] = translation;
    });
    this._translateService.get('common.apply_default_issuer_to_all_clients').subscribe((translation: string) => {
      this.localizationLabelList['apply_default_issuer_to_all_clients'] = translation;
    });
    this._translateService.get('issuer.validations.invalid_issuer_selection').subscribe((translation: string) => {
      this.localizationLabelList['invalid_issuer_selection'] = translation;
    });
    this._translateService.get('work.finish_date_is_not_valid').subscribe((translation: string) => {
      this.localizationLabelList['finish_date_is_not_valid'] = translation;
    });
    this._translateService.get('work.extend_delivery_date_warning').subscribe((translation: string) => {
      this.localizationLabelList['extend_delivery_date_warning'] = translation;
    });
    this._translateService.get('work.extend_delivery_date_warning_1').subscribe((translation: string) => {
      this.localizationLabelList['extend_delivery_date_warning_1'] = translation;
    });
    this._translateService.get('implant_data.implant_data').subscribe((translation: string) => {
      this.localizationLabelList['implant_data'] = translation;
    });
    this._translateService.get('mwt_color.color').subscribe((translation: string) => {
      this.localizationLabelList['work_color'] = translation;
    });
    this._translateService.get('auxiliary.work_page_configuration.work_page_config_update_success').subscribe((translation: string) => {
      this.localizationLabelList['work_page_config_update_success'] = translation;
    });
    this._translateService.get('auxiliary.work_page_configuration.work_page_config_update_error').subscribe((translation: string) => {
      this.localizationLabelList['work_page_config_update_error'] = translation;
    });
    this._translateService.get('auxiliary.work_page_configuration.work_page_configuration').subscribe((translation: string) => {
      this.localizationLabelList['work_page_config'] = translation;
    });
    this._translateService.get('auxiliary.system_configuration.system_configuration').subscribe((translation: string) => {
      this.localizationLabelList['system_configuration'] = translation;
    });
    this._translateService.get('auxiliary.system_configuration.system_configuration_required').subscribe((translation: string) => {
      this.localizationLabelList['system_configuration_required'] = translation;
    });
    this._translateService.get('invoice.from_date_is_missing').subscribe((translation: string) => {
      this.localizationLabelList['from_date_is_missing'] = translation;
    });
    this._translateService.get('invoice.to_date_is_missing').subscribe((translation: string) => {
      this.localizationLabelList['to_date_is_missing'] = translation;
    });
    this._translateService.get('work.delivery_date_should_be_greater_than_recieve_date').subscribe((translation: string) => {
      this.localizationLabelList['delivery_date_should_be_greater_than_recieve_date'] = translation;
    });
    this._translateService.get('work.end_date_should_be_greater_than_start_date').subscribe((translation: string) => {
      this.localizationLabelList['end_date_should_be_greater_than_start_date'] = translation;
    });
    this._translateService.get('price_list.price').subscribe((translation: string) => {
      this.localizationLabelList['price'] = translation;
    });
    this._translateService.get('product.units').subscribe((translation: string) => {
      this.localizationLabelList['units'] = translation;
    });
    this._translateService.get('product.validations.warehouse_is_not_valid').subscribe((translation: string) => {
      this.localizationLabelList['warehouse_is_not_valid'] = translation;
    });
    this._translateService.get('auxiliary.series_aux.selectNumberType').subscribe((translation: string) => {
      this.localizationLabelList['selectNumberType'] = translation;
    });
    this._translateService.get('common.required').subscribe((translation: string) => {
      this.localizationLabelList['required'] = translation;
    });
    this._translateService.get('providers.manufacturer').subscribe((translation: string) => {
      this.localizationLabelList['manufacturer'] = translation;
    });
    this._translateService.get('providers.provider').subscribe((translation: string) => {
      this.localizationLabelList['provider'] = translation;
    });
    this._translateService.get('permission.save_permission_error').subscribe((translation: string) => {
      this.localizationLabelList['save_permission_error'] = translation;
    });
    this._translateService.get('permission.save_permission_uncheck_error').subscribe((translation: string) => {
      this.localizationLabelList['save_permission_uncheck_error'] = translation;
    });
    this._translateService.get('lab_configuration.lab_configuration').subscribe((translation: string) => {
      this.localizationLabelList['lab_configuration'] = translation;
    });
    this._translateService.get('auxiliary.warehouse').subscribe((translation: string) => {
      this.localizationLabelList['warehouse'] = translation;
    });
    this._translateService.get('product.tickets').subscribe((translation: string) => {
      this.localizationLabelList['tickets'] = translation;
    });
    this._translateService.get('product.departures').subscribe((translation: string) => {
      this.localizationLabelList['departures'] = translation;
    });
    this._translateService.get('common.all').subscribe((translation: string) => {
      this.localizationLabelList['all'] = translation;
    });
    this._translateService.get('common.all_2').subscribe((translation: string) => {
      this.localizationLabelList['all_2'] = translation;
    });
    this._translateService.get('article.waste').subscribe((translation: string) => {
      this.localizationLabelList['waste'] = translation;
    });
    this._translateService.get('menu_items.work').subscribe((translation: string) => {
      this.localizationLabelList['work'] = translation;
    });
    this._translateService.get('warehouse.movement').subscribe((translation: string) => {
      this.localizationLabelList['movement'] = translation;
    });
    this._translateService.get('warehouse.others').subscribe((translation: string) => {
      this.localizationLabelList['others'] = translation;
    });
    this._translateService.get('warehouse.order_reception').subscribe((translation: string) => {
      this.localizationLabelList['order_reception'] = translation;
    });
    this._translateService.get('warehouse.return_order').subscribe((translation: string) => {
      this.localizationLabelList['return_order'] = translation;
    });
    this._translateService.get('auxiliary.administration_configuration.administration_config_update_success')
      .subscribe((translation: string) => {
        this.localizationLabelList['administration_config_update_success'] = translation;
      });
    this._translateService.get('auxiliary.administration_configuration.administration_config_update_error')
      .subscribe((translation: string) => {
        this.localizationLabelList['administration_config_update_error'] = translation;
      });
    this._translateService.get('auxiliary.administration_configuration.administration_configuration').subscribe((translation: string) => {
      this.localizationLabelList['administration_config'] = translation;
    });
    this._translateService.get('bank.bank').subscribe((translation: string) => {
      this.localizationLabelList['bank'] = translation;
    });
    this._translateService.get('bank.bankIncome').subscribe((translation: string) => {
      this.localizationLabelList['bankIncome'] = translation;
    });
    this._translateService.get('external_center.external_center').subscribe((translation: string) => {
      this.localizationLabelList['external_center'] = translation;
    });
    this._translateService.get('common.already_exists').subscribe((translation: string) => {
      this.localizationLabelList['already_exists'] = translation;
    });
    this._translateService.get('order.order').subscribe((translation: string) => {
      this.localizationLabelList['order'] = translation;
    });
    this._translateService.get('common.email_send_succesfully').subscribe((translation: string) => {
      this.localizationLabelList['email_send_succesfully'] = translation;
    });
    this._translateService.get('auxiliary.barcode_configuration.barcode_config_update_success').subscribe((translation: string) => {
      this.localizationLabelList['barcode_config_update_success'] = translation;
    });
    this._translateService.get('auxiliary.barcode_configuration.barcode_config_update_error').subscribe((translation: string) => {
      this.localizationLabelList['barcode_config_update_error'] = translation;
    });
    this._translateService.get('auxiliary.barcode_configuration.barcode_configuration').subscribe((translation: string) => {
      this.localizationLabelList['barcode_config'] = translation;
    });
    this._translateService.get('order.providerRequired').subscribe((translation: string) => {
      this.localizationLabelList['providerRequired'] = translation;
    });
    this._translateService.get('order.unitsToRequestRequired').subscribe((translation: string) => {
      this.localizationLabelList['unitsToRequestRequired'] = translation;
    });
    this._translateService.get('order.productRequired').subscribe((translation: string) => {
      this.localizationLabelList['productRequired'] = translation;
    });
    this._translateService.get('order.productRequired').subscribe((translation: string) => {
      this.localizationLabelList['productRequired'] = translation;
    });
    this._translateService.get('receipt.order_receipt_detail').subscribe((translation: string) => {
      this.localizationLabelList['order_receipt_detail'] = translation;
    });
    this._translateService.get('common.order_receipt_already_exist').subscribe((translation: string) => {
      this.localizationLabelList['order_receipt_already_exist'] = translation;
    });
    this._translateService.get('order.orderRequired').subscribe((translation: string) => {
      this.localizationLabelList['orderRequired'] = translation;
    });
    this._translateService.get('auxiliary.fiscal_accounts.fiscal_accounts').subscribe((translation: string) => {
      this.localizationLabelList['fiscal_accounts'] = translation;
    });
    this._translateService.get('auxiliary.expense_concept.expense_concept').subscribe((translation: string) => {
      this.localizationLabelList['expense_concept'] = translation;
    });
    this._translateService.get('common.edit_error').subscribe((translation: string) => {
      this.localizationLabelList['edit_error'] = translation;
    });
    this._translateService.get('auxiliary.payment_due.payment_due').subscribe((translation: string) => {
      this.localizationLabelList['payment_due'] = translation;
    });
    this._translateService.get('receipt_page.receipt').subscribe((translation: string) => {
      this.localizationLabelList['receipt'] = translation;
    });
    this._translateService.get('receipt_page.receiptDetailRequired').subscribe((translation: string) => {
      this.localizationLabelList['receiptDetailRequired'] = translation;
    });
    this._translateService.get('receipt_page.partialPaymentTypeRequired').subscribe((translation: string) => {
      this.localizationLabelList['partialPaymentTypeRequired'] = translation;
    });

    this._translateService.get('expenses.expenses').subscribe((translation: string) => {
      this.localizationLabelList['expenses'] = translation;
    });

    this._translateService.get('auth.validations.email_required').subscribe((translation: string) => {
      this.localizationLabelList['email_required'] = translation;
    });

    this._translateService.get('user.email').subscribe((translation: string) => {
      this.localizationLabelList['email'] = translation;
    });

    this._translateService.get('auxiliary.messenger.messenger').subscribe((translation: string) => {
      this.localizationLabelList['messenger'] = translation;
    });

    this._translateService.get('auxiliary.messenger.external').subscribe((translation: string) => {
      this.localizationLabelList['external'] = translation;
    });

    this._translateService.get('auxiliary.messenger.internal').subscribe((translation: string) => {
      this.localizationLabelList['internal'] = translation;
    });

    this._translateService.get('budget.validations.client_required').subscribe((translation: string) => {
      this.localizationLabelList['clientRequired'] = translation;
    });

    this._translateService.get('budget.validations.patient_required').subscribe((translation: string) => {
      this.localizationLabelList['patientRequired'] = translation;
    });

    this._translateService.get('messaging.shipment').subscribe((translation: string) => {
      this.localizationLabelList['shipment'] = translation;
    });

    this._translateService.get('messaging.pickups').subscribe((translation: string) => {
      this.localizationLabelList['pickups'] = translation;
    });

    this._translateService.get('work.please_select_pieces').subscribe((translation: string) => {
      this.localizationLabelList['please_select_pieces'] = translation;
    });

    this._translateService.get('client.series_type_required').subscribe((translation: string) => {
      this.localizationLabelList['series_type_required'] = translation;
    });
    this._translateService.get('common.inactive').subscribe((translation: string) => {
      this.localizationLabelList['inactive'] = translation;
    });
    this._translateService.get('common.inactive2').subscribe((translation: string) => {
      this.localizationLabelList['inactive2'] = translation;
    });
    this._translateService.get('price_list.rates').subscribe((translation: string) => {
      this.localizationLabelList['rates'] = translation;
    });
    this._translateService.get('price_list.rates_clone_warning').subscribe((translation: string) => {
      this.localizationLabelList['rates_clone_warning'] = translation;
    });

    this._translateService.get('common.all').subscribe((translation: string) => {
      this.localizationLabelList['Todos'] = translation;
    });
    this._translateService.get('workers.pending').subscribe((translation: string) => {
      this.localizationLabelList['Pendiente'] = translation;
    });
    this._translateService.get('workers.finalized').subscribe((translation: string) => {
      this.localizationLabelList['Finalizado'] = translation;
    });
    this._translateService.get('work.cancelled').subscribe((translation: string) => {
      this.localizationLabelList['Cancelada'] = translation;
    });
    this._translateService.get('work.internal_process').subscribe((translation: string) => {
      this.localizationLabelList['Proceso Interno'] = translation;
    });
    this._translateService.get('work.outsourced_process').subscribe((translation: string) => {
      this.localizationLabelList['Proceso externalizado'] = translation;
    });
    this._translateService.get('work.is_testing').subscribe((translation: string) => {
      this.localizationLabelList['En Pruebas'] = translation;
    });
    this._translateService.get('article.client_discount_required').subscribe((translation: string) => {
      this.localizationLabelList['client_discount_required'] = translation;
    });
    this._translateService.get('article.validations.code_exist').subscribe((translation: string) => {
      this.localizationLabelList['code_exist'] = translation;
    });
    this._translateService.get('budget.budget_article').subscribe((translation: string) => {
      this.localizationLabelList['budget_article'] = translation;
    });
    this._translateService.get('expenses.detailsofExpenses').subscribe((translation: string) => {
      this.localizationLabelList['detailsofExpenses'] = translation;
    });
    this._translateService.get('budget.budget').subscribe((translation: string) => {
      this.localizationLabelList['budget'] = translation;
    });
    this._translateService.get('task.phase_delivery_date_empty').subscribe((translation: string) => {
      this.localizationLabelList['phase_delivery_date_empty'] = translation;
    });
    this._translateService.get('task.phase_and_workType_delivery_dates_empty').subscribe((translation: string) => {
      this.localizationLabelList['phase_and_workType_delivery_dates_empty'] = translation;
    });
    this._translateService.get('task.type_of_work_date_empty').subscribe((translation: string) => {
      this.localizationLabelList['type_of_work_date_empty'] = translation;
    });
    this._translateService.get('task.greater_than_type_of_work_date').subscribe((translation: string) => {
      this.localizationLabelList['greater_than_type_of_work_date'] = translation;
    });
    this._translateService.get('delivery_note.delete_delivery_note_error').subscribe((translation: string) => {
      this.localizationLabelList['delete_delivery_note_error'] = translation;
    });

    this._translateService.get('delivery_note.delete_paid_inovoice_delivery_note_error').subscribe((translation: string) => {
      this.localizationLabelList['delete_paid_inovoice_delivery_note_error'] = translation;
    });

    this._translateService.get('lab_configuration.external_works_configuration').subscribe((translation: string) => {
      this.localizationLabelList['external_works_configuration'] = translation;
    });
    this._translateService.get('auxiliary.reservation_config.reservation_config').subscribe((translation: string) => {
      this.localizationLabelList['reservation_config'] = translation;
    });

    this._translateService.get('auxiliary.reservation_config.reservation_tope_exceed_warning').subscribe((translation: string) => {
      this.localizationLabelList['reservation_tope_exceed_warning'] = translation;
    });

    this._translateService.get('auxiliary.reservation_config.own_tope_exceed_warning').subscribe((translation: string) => {
      this.localizationLabelList['own_tope_exceed_warning'] = translation;
    });

    this._translateService.get('auxiliary.reservation_config.not_possible_add_reservation').subscribe((translation: string) => {
      this.localizationLabelList['not_possible_add_reservation'] = translation;
    });

    this._translateService.get('external_data.external_work').subscribe((translation: string) => {
      this.localizationLabelList['external_work'] = translation;
    });

    this._translateService.get('receipt.order_received').subscribe((translation: string) => {
      this.localizationLabelList['order_received'] = translation;
    });

    this._translateService.get('order.exceed_return_unit_lot').subscribe((translation: string) => {
      this.localizationLabelList['exceed_return_unit_lot'] = translation;
    });

    this._translateService.get('product.no_selected_product').subscribe((translation: string) => {
      this.localizationLabelList['no_selected_product'] = translation;
    });

    this._translateService.get('task.stops').subscribe((translation: string) => {
      this.localizationLabelList['task_stops'] = translation;
    });

    this._translateService.get('product.please_select').subscribe((translation: string) => {
      this.localizationLabelList['please_select'] = translation;
    });

    this._translateService.get('lab_calender.lab_calender').subscribe((translation: string) => {
      this.localizationLabelList['lab_calender'] = translation;
    });

    this._translateService.get('lab_calender.lab_work_layout_change_warning_msg').subscribe((translation: string) => {
      this.localizationLabelList['lab_work_layout_change_warning_msg'] = translation;
    });

    this._translateService.get('workers.workerStops').subscribe((translation: string) => {
      this.localizationLabelList['workerStops'] = translation;
    });

    this._translateService.get('workers.schedule').subscribe((translation: string) => {
      this.localizationLabelList['workersSchedule'] = translation;
    });

    this._translateService.get('sections.group').subscribe((translation: string) => {
      this.localizationLabelList['sectionsGroup'] = translation;
    });

    this._translateService.get('subscription_reason.subscription_reason').subscribe((translation: string) => {
      this.localizationLabelList['subscription_reason'] = translation;
    });

    this._translateService.get('invoice_cancellation.invoice_cancellation').subscribe((translation: string) => {
      this.localizationLabelList['invoice_cancellation'] = translation;
    });

    this._translateService.get('receipt_cancellation.receipt_cancellation').subscribe((translation: string) => {
      this.localizationLabelList['receipt_cancellation'] = translation;
    });

    this._translateService.get('workers.working_day').subscribe((translation: string) => {
      this.localizationLabelList['working'] = translation;
    });

    this._translateService.get('workers.leave').subscribe((translation: string) => {
      this.localizationLabelList['leave'] = translation;
    });

    this._translateService.get('task.task_value_exceeded_msg').subscribe((translation: string) => {
      this.localizationLabelList['task_value_exceeded_msg'] = translation;
    });

    this._translateService.get('task.reservation_tope_value_exceeded_msg').subscribe((translation: string) => {
      this.localizationLabelList['reservation_tope_value_exceeded_msg'] = translation;
    });

    this._translateService.get('task.worker_topes')
      .subscribe((translation: string) => {
        this.localizationLabelList['worker_topes'] = translation;
      });
    this._translateService.get('workers.invalid_time_format')
      .subscribe((translation: string) => {
        this.localizationLabelList['invalid_time_format'] = translation;
      });
    this._translateService.get('task.worker_task_value_exceeded_msg_1')
      .subscribe((translation: string) => {
        this.localizationLabelList['worker_task_value_exceeded_msg_1'] = translation;
      });

    this._translateService.get('task.worker_task_value_exceeded_msg_2')
      .subscribe((translation: string) => {
        this.localizationLabelList['worker_task_value_exceeded_msg_2'] = translation;
      });

    this._translateService.get('task.tope_of_the_day_must_be_greater_than_or_equal_to_total_daily_topes')
      .subscribe((translation: string) => {
        this.localizationLabelList['tope_of_the_day_must_be_greater_than_or_equal_to_total_daily_topes'] = translation;
      });

    this._translateService.get('task.lab_task_value_exceeded_msg').subscribe((translation: string) => {
      this.localizationLabelList['lab_task_value_exceeded_msg'] = translation;
    });

    this._translateService.get('lab_calendar.added_as_a').subscribe((translation: string) => {
      this.localizationLabelList['added_as_a'] = translation;
    });

    this._translateService.get('day.saturday').subscribe((translation: string) => {
      this.localizationLabelList['saturday'] = translation;
    });

    this._translateService.get('day.sunday').subscribe((translation: string) => {
      this.localizationLabelList['sunday'] = translation;
    });

    this._translateService.get('lab_calendar.holiday').subscribe((translation: string) => {
      this.localizationLabelList['holiday'] = translation;
    });

    this._translateService.get('lab_calendar.working_day').subscribe((translation: string) => {
      this.localizationLabelList['working_day'] = translation;
    });

    this._translateService.get('task.no_delivery_date').subscribe((translation: string) => {
      this.localizationLabelList['no_delivery_date'] = translation;
    });

    this._translateService.get('task.allowed_free_days').subscribe((translation: string) => {
      this.localizationLabelList['allowed_free_days'] = translation;
    });

    this._translateService.get('task.not_allowed_holidays').subscribe((translation: string) => {
      this.localizationLabelList['not_allowed_holidays'] = translation;
    });
    this._translateService.get('email_config.email_config').subscribe((translation: string) => {
      this.localizationLabelList['email_config'] = translation;
    });

    this._translateService.get('email_section.validations.sender_missing').subscribe((translation: string) => {
      this.localizationLabelList['sender_missing'] = translation;
    });

    this._translateService.get('email_section.validations.recipient_missing').subscribe((translation: string) => {
      this.localizationLabelList['recipient_missing'] = translation;
    });

    this._translateService.get('email_section.validations.sender_recipient_missing').subscribe((translation: string) => {
      this.localizationLabelList['sender_recipient_missing'] = translation;
    });

    this._translateService.get('email_section.notifications.email_sent').subscribe((translation: string) => {
      this.localizationLabelList['email_sent'] = translation;
    });

    this._translateService.get('email_section.notifications.email_failed').subscribe((translation: string) => {
      this.localizationLabelList['email_failed'] = translation;
    });

    this._translateService.get('work_type.not_available_phases').subscribe((translation: string) => {
      this.localizationLabelList['not_available_phases'] = translation;
    });

    this._translateService.get('work.cannot_delete').subscribe((translation: string) => {
      this.localizationLabelList['cannot_delete'] = translation;
    });

    this._translateService.get('common.code_exist').subscribe((translation: string) => {
      this.localizationLabelList['code_exist'] = translation;
    });

    this._translateService.get('client.client_does_not_have_any_dental_client_connected').subscribe((translation: string) => {
      this.localizationLabelList['client_does_not_have_any_dental_client_connected'] = translation;
    });

    this._translateService.get('client.center_already_synced').subscribe((translation: string) => {
      this.localizationLabelList['center_already_synced'] = translation;
    });

    this._translateService.get('client.dentbox_services_not_enabled').subscribe((translation: string) => {
      this.localizationLabelList['dentbox_services_not_enabled'] = translation;
    });

    this._translateService.get('work_type.code_already_exist').subscribe((translation: string) => {
      this.localizationLabelList['code_already_exist'] = translation;
    });

    this._translateService.get('work_type.delete_warning_article').subscribe((translation: string) => {
      this.localizationLabelList['delete_warning_article'] = translation;
    });

    this._translateService.get('work_type.delete_warning_phase').subscribe((translation: string) => {
      this.localizationLabelList['delete_warning_phase'] = translation;
    });

    this._translateService.get('work_type.delete_warning_task').subscribe((translation: string) => {
      this.localizationLabelList['delete_warning_task'] = translation;
    });

    this._translateService.get('work_type.job_type_use_must_disable_it').subscribe((translation: string) => {
      this.localizationLabelList['job_type_use_must_disable_it'] = translation;
    });

    this._translateService.get('work_type.job_type_used').subscribe((translation: string) => {
      this.localizationLabelList['job_type_used'] = translation;
    });

    this._translateService.get('PLAN_WORK_DATES.PLAN_WORK_DATES').subscribe((translation: string) => {
      this.localizationLabelList['PLAN_WORK_DATES'] = translation;
    });

    this._translateService.get('PLAN_WORK_DATES.DO_YOU_WANT_TO_PLAN').subscribe((translation: string) => {
      this.localizationLabelList['DO_YOU_WANT_TO_PLAN'] = translation;
    });

    this._translateService.get('PLAN_WORK_DATES.TIME_NOT_COMPLETED').subscribe((translation: string) => {
      this.localizationLabelList['TIME_NOT_COMPLETED'] = translation;
    });

    this._translateService.get('PLAN_WORK_DATES.TIME_NOT_CONFIGURED').subscribe((translation: string) => {
      this.localizationLabelList['TIME_NOT_CONFIGURED'] = translation;
    });

    this._translateService.get('PLAN_WORK_DATES.LAST_PHASE_DATE_EXCEEDS_MWT_DELIVERY_DATE')
      .subscribe((translation: string) => {
        this.localizationLabelList['LAST_PHASE_DATE_EXCEEDS_MWT_DELIVERY_DATE'] = translation;
      });

    this._translateService.get('PLAN_WORK_DATES.LAST_TASK_DATE_EXCEEDS_MWT_PHASE_DATE')
      .subscribe((translation: string) => {
        this.localizationLabelList['LAST_TASK_DATE_EXCEEDS_MWT_PHASE_DATE'] = translation;
      });

    this._translateService.get('workers.commission').subscribe((translation: string) => {
      this.localizationLabelList['commission'] = translation;
    });

    this._translateService.get('auxiliary.reservation_methods.reservation_methods').subscribe((translation: string) => {
      this.localizationLabelList['reservation_methods'] = translation;
    });

    this._translateService.get('WORK_RESERVATION.WORK_RESERVATIONS').subscribe((translation: string) => {
      this.localizationLabelList['WORK_RESERVATIONS'] = translation;
    });

    this._translateService.get('article.article_phase').subscribe((translation: string) => {
      this.localizationLabelList['article_phase'] = translation;
    });

    this._translateService.get('WORK_RESERVATION.DELIVERY_DATE_SHOULD_BE_GREATER_THAN_ORDER_DATE').subscribe((translation: string) => {
      this.localizationLabelList['DELIVERY_DATE_SHOULD_BE_GREATER_THAN_ORDER_DATE'] = translation;
    });

    this._translateService.get('WORK_RESERVATION.ARE_U_SURE_WANT_TO_CREATE_WORK').subscribe((translation: string) => {
      this.localizationLabelList['ARE_U_SURE_WANT_TO_CREATE_WORK'] = translation;
    });

    this._translateService.get('client.work_report').subscribe((translation: string) => {
      this.localizationLabelList['work_report'] = translation;
    });

    this._translateService.get('common.conformity_report').subscribe((translation: string) => {
      this.localizationLabelList['conformity_report'] = translation;
    });

    this._translateService.get('client.usability_report').subscribe((translation: string) => {
      this.localizationLabelList['usability_report'] = translation;
    });

    this._translateService.get('client.guarantee_report').subscribe((translation: string) => {
      this.localizationLabelList['guarantee_report'] = translation;
    });

    this._translateService.get('common.incident_report').subscribe((translation: string) => {
      this.localizationLabelList['incident_report'] = translation;
    });

    this._translateService.get('dashboard.prosthetic_lab_has_accepted_the_work').subscribe((translation: string) => {
      this.localizationLabelList['prosthetic_lab_has_accepted_the_work'] = translation;
    });

    this._translateService.get('task_board.no_section').subscribe((translation: string) => {
      this.localizationLabelList['no_section'] = translation;
    });

    this._translateService.get('workers.next_month').subscribe((translation: string) => {
      this.localizationLabelList['next_month'] = translation;
    });

    this._translateService.get('workers.previous_month').subscribe((translation: string) => {
      this.localizationLabelList['previous_month'] = translation;
    });

    this._translateService.get('common.print_warning').subscribe((translation: string) => {
      this.localizationLabelList['print_warning'] = translation;
    });
    this._translateService.get('common.accept_yes').subscribe((translation: string) => {
      this.localizationLabelList['accept_yes'] = translation;
    });
    this._translateService.get('workers.started_work').subscribe((translation: string) => {
      this.localizationLabelList['started_work'] = translation;
    });
    this._translateService.get('workers.end_work').subscribe((translation: string) => {
      this.localizationLabelList['end_work'] = translation;
    });
    this._translateService.get('article.used_in_delivery_note').subscribe((translation: string) => {
      this.localizationLabelList['used_in_delivery_note'] = translation;
    });
    this._translateService.get('article.used_in_invoice').subscribe((translation: string) => {
      this.localizationLabelList['used_in_invoice'] = translation;
    });
    this._translateService.get('article.used_in_delivery_note_plural').subscribe((translation: string) => {
      this.localizationLabelList['used_in_delivery_note_plural'] = translation;
    });
    this._translateService.get('OFFER_DATA.OFFERS').subscribe((translation: string) => {
      this.localizationLabelList['OFFER_DATA'] = translation;
    });
    this._translateService.get('workers.do_you_want_to_end_working_today').subscribe((translation: string) => {
      this.localizationLabelList['do_you_want_to_end_working_today'] = translation;
    });
    this._translateService.get('common.left_bar_to').subscribe((translation: string) => {
      this.localizationLabelList['left_bar_to'] = translation;
    });
    this._translateService.get('common.left_bar_less_filter').subscribe((translation: string) => {
      this.localizationLabelList['left_bar_less_filter'] = translation;
    });
    this._translateService.get('common.left_bar_more_filter').subscribe((translation: string) => {
      this.localizationLabelList['left_bar_more_filter'] = translation;
    });

    this._translateService.get('patient.patient').subscribe((translation: string) => {
      this.localizationLabelList['PATIENT_EXPORT_WARNING_TITLE'] = translation;
    });
    this._translateService.get('providers.provider').subscribe((translation: string) => {
      this.localizationLabelList['PROVIDER_EXPORT_WARNING_TITLE'] = translation;
    });
    this._translateService.get('messaging.messenger').subscribe((translation: string) => {
      this.localizationLabelList['MESSENGER_EXPORT_WARNING_TITLE'] = translation;
    });
    this._translateService.get('work_type.work_type').subscribe((translation: string) => {
      this.localizationLabelList['WORK_TYPE_EXPORT_WARNING_TITLE'] = translation;
    });
    this._translateService.get('user.users').subscribe((translation: string) => {
      this.localizationLabelList['USER_EXPORT_WARNING_TITLE'] = translation;
    });
    this._translateService.get('messaging.pickups').subscribe((translation: string) => {
      this.localizationLabelList['PICKUPS_EXPORT_WARNING_TITLE'] = translation;
    });
    this._translateService.get('messaging.shipments').subscribe((translation: string) => {
      this.localizationLabelList['SHIPMENT_EXPORT_WARNING_TITLE'] = translation;
    });

    this._translateService.get('job_search.job_search').subscribe((translation: string) => {
      this.localizationLabelList['job_search'] = translation;
    });

    this._translateService.get('task_board.task_board').subscribe((translation: string) => {
      this.localizationLabelList['task_board'] = translation;
    });

    this._translateService.get('common.export_warning').subscribe((translation: string) => {
      this.localizationLabelList['EXPORT_WARNING_MSG'] = translation;
    });

    this._translateService.get('common.permission_not_granted').subscribe((translation: string) => {
      this.localizationLabelList['permission_not_granted'] = translation;
    });

    this._translateService.get('order.deleteOrder').subscribe((translation: string) => {
      this.localizationLabelList['deleteOrder'] = translation;
    });

    this._translateService.get('order.deleteSentorder').subscribe((translation: string) => {
      this.localizationLabelList['deleteSentorder'] = translation;
    });

    this._translateService.get('order.orderInUse').subscribe((translation: string) => {
      this.localizationLabelList['orderInUse'] = translation;
    });

    this._translateService.get('price_list.price_list_has_removed_success').subscribe((translation: string) => {
      this.localizationLabelList['price_list_has_removed_success'] = translation;
    });

    this._translateService.get('price_list.price_list_is_already_in_use').subscribe((translation: string) => {
      this.localizationLabelList['price_list_is_already_in_use'] = translation;
    });

    this._translateService.get('price_list.rate').subscribe((translation: string) => {
      this.localizationLabelList['rate'] = translation;
    });

    this._translateService.get('work.work_not_found').subscribe((translation: string) => {
      this.localizationLabelList['work_not_found'] = translation;
    });
    this._translateService.get('work.date_is_not_available').subscribe((translation: string) => {
      this.localizationLabelList['date_is_not_available'] = translation;
    });

    this._translateService.get('free_budget.free_budget_already_exist').subscribe((translation: string) => {
      this.localizationLabelList['free_budget_already_exist'] = translation;
    });

    this._translateService.get('order.barcode_product_provider_not_found').subscribe((translation: string) => {
      this.localizationLabelList['barcode_product_provider_not_found'] = translation;
    });

    this._translateService.get('order.barcode_product_not_found').subscribe((translation: string) => {
      this.localizationLabelList['barcode_product_not_found'] = translation;
    });

    this._translateService.get('article.article_delete_warning').subscribe((translation: string) => {
      this.localizationLabelList['article_delete_warning'] = translation;
    });

    this._translateService.get('auxiliary.reservation_config.not_configured_topes').subscribe((translation: string) => {
      this.localizationLabelList['not_configured_topes'] = translation;
    });

    this._translateService.get('delivery_note.warning_delivery_notes_closed').subscribe((translation: string) => {
      this.localizationLabelList['warning_delivery_notes_closed'] = translation;
    });

    this._translateService.get('invoice.closing_billing_warning').subscribe((translation: string) => {
      this.localizationLabelList['closing_billing_warning'] = translation;
    });

    this._translateService.get('invoice.invoice_closed_successfully').subscribe((translation: string) => {
      this.localizationLabelList['invoice_closed_successfully'] = translation;
    });

    this._translateService.get('invoice.invoice_unblocked_successfully').subscribe((translation: string) => {
      this.localizationLabelList['invoice_unblocked_successfully'] = translation;
    });

    this._translateService.get('warehouse.code_description_exist').subscribe((translation: string) => {
      this.localizationLabelList['code_description_exist'] = translation;
    });

    this._translateService.get('product.select_lab_section_2').subscribe((translation: string) => {
      this.localizationLabelList['select_lab_section_2'] = translation;
    });
    this._translateService.get('common.select').subscribe((translation: string) => {
      this.localizationLabelList['select'] = translation;
    });
    this._translateService.get('patient.update_confirmation').subscribe((translation: string) => {
      this.localizationLabelList['patient_record_update_confirmation'] = translation;
    });
    this._translateService.get('mwt_color.metal_configured_with_an_article').subscribe((translation: string) => {
      this.localizationLabelList['metal_configured_with_an_article'] = translation;
    });
    this._translateService.get('auth.colneTheSeries').subscribe((translation: string) => {
      this.localizationLabelList['colneTheSeries'] = translation;
    });
    this._translateService.get('dent_box.dent_box_license').subscribe((translation: string) => {
      this.localizationLabelList['dent_box_license'] = translation;
    });
    this._translateService.get('common.no').subscribe((translation: string) => {
      this.localizationLabelList['common_no'] = translation;
    });
    this._translateService.get('dent_box.api_key_is_not_active').subscribe((translation: string) => {
      this.localizationLabelList['key_is_not_valid'] = translation;
    });
    this._translateService.get('dent_box.api_key_is_not_active').subscribe((translation: string) => {
      this.localizationLabelList['api_key_is_not_active'] = translation;
    });
    this._translateService.get('dent_box.license').subscribe((translation: string) => {
      this.localizationLabelList['license'] = translation;
    });
    this._translateService.get('dent_box.enterLicense').subscribe((translation: string) => {
      this.localizationLabelList['enterLicense'] = translation;
    });
    this._translateService.get('auth.reset_password').subscribe((translation: string) => {
      this.localizationLabelList['resetPassword'] = translation;
    });
    this._translateService.get('auth.emailMessage1').subscribe((translation: string) => {
      this.localizationLabelList['emailMessage1'] = translation;
    });
    this._translateService.get('auth.emailMessage2').subscribe((translation: string) => {
      this.localizationLabelList['emailMessage2'] = translation;
    });
    this._translateService.get('auth.password_reset_link_sent').subscribe((translation: string) => {
      this.localizationLabelList['passwordResetLinkSent'] = translation;
    });
    this._translateService.get('auth.email_not_sent').subscribe((translation: string) => {
      this.localizationLabelList['email_not_sent'] = translation;
    });
    this._translateService.get('auth.invalid_email_sent').subscribe((translation: string) => {
      this.localizationLabelList['invalid_email_sent'] = translation;
    });

    this._translateService.get('auth.smtpException').subscribe((translation: string) => {
      this.localizationLabelList['smtpException'] = translation;
    });
    this._translateService.get('auth.authenticationException1').subscribe((translation: string) => {
      this.localizationLabelList['authenticationException1'] = translation;
    });
    this._translateService.get('auth.authenticationException2').subscribe((translation: string) => {
      this.localizationLabelList['authenticationException2'] = translation;
    });
    this._translateService.get('auth.authenticationException3').subscribe((translation: string) => {
      this.localizationLabelList['authenticationException3'] = translation;
    });
    this._translateService.get('work_type.phase-job').subscribe((translation: string) => {
      this.localizationLabelList['phase'] = translation;
    });
    this._translateService.get('work_type.cannot_drop_here').subscribe((translation: string) => {
      this.localizationLabelList['cannot_drop_here'] = translation;
    });
    this._translateService.get('common.delivery_date').subscribe((translation: string) => {
      this.localizationLabelList['delivery_date'] = translation;
    });
    this._translateService.get('work_type.metal').subscribe((translation: string) => {
      this.localizationLabelList['metal'] = translation;
    });
    this._translateService.get('common.end_date_should_be_greater_than_start_date').subscribe((translation: string) => {
      this.localizationLabelList['start_end_date_error'] = translation;
    });
    this._translateService.get('budget.no_series').subscribe((translation: string) => {
      this.localizationLabelList['no_series'] = translation;
    });
    this._translateService.get('incident_reason.incident_reason').subscribe((translation: string) => {
      this.localizationLabelList['incident_reason'] = translation;
    });
    this._translateService.get('incident_reason.incident_reasons').subscribe((translation: string) => {
      this.localizationLabelList['incident_reasons'] = translation;
    });
    this._translateService.get('incident_reason.incident_reason').subscribe((translation: string) => {
      this.localizationLabelList['incident'] = translation;
    });
    this._translateService.get('invoice.automatic_invoices_saved').subscribe((translation: string) => {
      this.localizationLabelList['automatic_invoices_saved'] = translation;
    });
    this._translateService.get('invoice.automatic_no_series_found').subscribe((translation: string) => {
      this.localizationLabelList['automatic_no_series_found'] = translation;
    });
    this._translateService.get('invoice.automatic_no_series_found_some').subscribe((translation: string) => {
      this.localizationLabelList['automatic_no_series_found_some'] = translation;
    });
    this._translateService.get('invoice.automatic_no_invoices_saved').subscribe((translation: string) => {
      this.localizationLabelList['automatic_no_invoices_saved'] = translation;
    });
    this._translateService.get('invoice.warning_issuer_series').subscribe((translation: string) => {
      this.localizationLabelList['warning_issuer_series'] = translation;
    });
    this._translateService.get('external_data.exceeds_delivery_date_warning').subscribe((translation: string) => {
      this.localizationLabelList['exceeds_delivery_date_warning'] = translation;
    });
    this.SetIsLanguageChanged(true);
  }

  public InitializeData() {
    this.GetUserRoleList();
    this.GetAllPermissionList();
    this.GetTownList();
  }

  public GetUserRoleList(): UserRole[] {
    if (this.userRoleList.length === 0) {
      this._apiService.get(API.user_role.getUserRoleList)
        .subscribe(
          res => {
            this.userRoleList = res;
            this.userRoleEventEmitter.emit(this.userRoleList);
          },
          err => { console.log(err); },
          () => { }
        );
    } else {
      return this.userRoleList;
    }
  }

  public GetAllPermissionList(): PermissionListViewModel {
    this.userPermissionList = new PermissionListViewModel();
    if (this.userPermissionList) {
      if (this.userPermissionList.permissions !== undefined && this.userPermissionList.roles !== undefined) {
        return this.userPermissionList;
      } else {
        this._apiService.get(API.permission.getPermissionList)
          .subscribe(
            res => {
              this.userPermissionList = this.BindDataToViewModel(res);
              this.permissionListEventEmitter.emit(this.userPermissionList);
            },
            err => { console.log(err); },
            () => { }
          );
      }
    }
  }

  public BindDataToViewModel(data: any): PermissionListViewModel {
    const plvm = new PermissionListViewModel();
    if (data) {
      plvm.permissions = data.permisionList;
      plvm.rolePermissions = data.rolePermisionList;
      plvm.roles = data.userRoleList;
      return plvm;
    }
  }

  public GetCountryList(): Country[] {
    if (this.countryList.length === 0) {
      this._apiService.get(API.country.getCountryList)
        .subscribe(
          res => {
            this.countryList = res;
            this.countryEventEmitter.emit(this.countryList);
          },
          err => { console.log(err); },
          () => { }
        );
    } else {
      return this.countryList;
    }
  }

  public GetTownList(): Town[] {
    if (this.townList.length === 0) {
      this._apiService.get(API.town.getTownList)
        .subscribe(
          res => {
            this.townList = res;
          },
          err => { console.log(err); },
          () => { this.townListEventEmitter.emit(this.townList); }
        );
    } else {
      return this.townList;
    }
  }

  public GetProvinceList(): Province[] {
    if (this.provinceList.length === 0) {
      this._apiService.get(API.province.getProvinceList)
        .subscribe(
          res => {
            this.provinceList = res;
            this.provinceEventEmitter.emit(this.provinceList);
          },
          err => { console.log(err); },
          () => { }
        );
    } else {
      return this.provinceList;
    }
  }

  public GetUserFromSession(): User {
    if (sessionStorage.getItem('auth-user')) {
      return JSON.parse(atob(sessionStorage.getItem('auth-user')));
    }
  }

  public GetAccessTokenFromSession(): string {
    if (sessionStorage.getItem('access-token')) {
      return atob(sessionStorage['access-token']);
    }
  }

  public SetIsLanguageChanged(isLanguageChanged: boolean) {
    this.messageSourceForLocalization.next(isLanguageChanged);
  }

  public GetAutoCompleteList(list: any[], property: string): any[] {
    if (list) {
      const autoCompleteList = [];
      list.forEach(item => autoCompleteList.push({ display: item[property] ? item[property] : '', value: item.id, id: item.id }));
      return autoCompleteList;
    }
  }

  public GetDropDownList(list: any[], property: string): DropDownOption[] {
    if (list) {
      let _list = list;
      const dropDownList: DropDownOption[] = [];
      _list = CommonFunctions.Sort(_list, property);
      _list.forEach(item => dropDownList.push(new DropDownOption(item.id.toString(), item[property], false)));
      return dropDownList;
    }
  }

  public SetIsStatistic(isStatisticPanel: boolean) {
    this.messageSourceForStatistic.next(isStatisticPanel);
  }

  public SetCurrentLanguage(prefix: string) {
    this._currentLanguage = prefix;
  }

  public GetPlaceHolderValue(): string {
    return (this._currentLanguage === 'en' || this._currentLanguage === undefined) ? 'dd/mm/yyyy' : 'dd/mm/aaaa';
  }

  public GetCurrentLanguage(): string {
    if (localStorage.getItem('currentLang')) {
      return localStorage.getItem('currentLang');
    } else {
      return this._translateService.getBrowserLang();
    }
  }

  public GetPlaceHolderValueForEx(text: string): string {
    if (this._currentLanguage === 'en' || this._currentLanguage === undefined) {
      return text;
    } else {
      text = text.replace('Ex', 'Ej');
      return text;
    }
  }

  public GetAutoCompletePatientList(list: any[]): any[] {
    if (list) {
      const autoCompleteList = [];
      list.forEach(function (value, index) {
        value.name = value.name ? value.name.trim() : '';
        const name = value.surnames != null ? value.name + ' ' + value.surnames.trim() : value.name;
        const historyNumber = value.historyNumber != null ? '- ' + value.historyNumber : '';
        value.searchText = name + ' ' + historyNumber;
        value.name = name + ' ' + historyNumber;
        if (value.searchText === '') {
          value.searchText = null;
        }
      });
      return list;
    }
  }

  public GetAutoCompletePatientListForNgSelect(list: any[]): any[] {
    if (list) {
      const patientDropDownList = [];
      list.forEach(item => patientDropDownList.push({
        display: item['name'] + ' ' + (item['surnames'] ? item['surnames'] : '') + (item['historyNumber'] ? (' - ' + ' ' + item['historyNumber']) : ''),
        id: item.id
      }));
      return patientDropDownList
    }
  }

  setAppCurrency() {
    return this._apiService
      .get(API.system_configuration.get_app_currency)
      .subscribe(result => {
        if (result && result.appCurrency) {
          this.appCurrency = result.appCurrency;
        }
      });
  }
}
