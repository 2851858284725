export class Article {
  id: any;
  idList: any[];
  code: string;
  articleGroupId?: number;
  labSectionId?: number;
  waste?: number;
  iva?: number;
  characteristics: string;
  cost?: number;
  description1: string;
  description2: string;
  description3: string;
  points?: number;
  isActive: boolean;
  isVariableAmount: boolean;
  isPrintInBilling: boolean;
  isIncludedInStatistics: boolean;
  isAppliedDiscountClient: boolean;
  isProductReference: boolean;
  createdDate: any;
  createdBy: number;
  modifiedDate: any;
  modifiedBy: number;

  articleDiscount: number;
  articleFinalPrice: number;
  articlePrice: number;
  articleQuantity: number;
  articleWaste: number;
  articleDiscountAmount: number;
  articleIsAmount: boolean;
  workId: number;
  workName: string;
  articleName: string;
  mwtArticleId: number;
  mainWorkTypeId: number;
  deliveryNoteArticleId: number;
  quantity: number;
  quantityWaste: number;
  discount: number;

  isDiscountOverridden: boolean;
  ivaTypeId?: number;
  ivatype: any;
  ivaAmount: any;

  quantityWithWaste: number;
  priceWithDiscount: number;
  discountAmount: number;
  lineDiscountAmount: number;
  lineSubTotal: number;

  constructor(init?: Partial<Article>) {
    if (init) { Object.assign(this, init); }
  }
}


export class ArticleModel {
  id: number;
  code: string;
  articleGroupId?: any;
  labSectionId?: any;
  waste?: number;
  iva?: number;
  characteristics: string;
  cost?: number;
  description1: string;
  description2: string;
  description3: string;
  points?: number;
  isActive: boolean;
  isVariableAmount: boolean;
  isPrintInBilling: boolean;
  isIncludedInStatistics: boolean;
  isAppliedDiscountClient: boolean;
  isProductReference: boolean;
  createdDate: any;
  createdBy: number;
  modifiedDate: any;
  modifiedBy: number;
  articleGroupDto: any;
  labSectionsDto: any;
  //articleLanguageList: any[];
  articleGroupName: string;
  labSectionName: string;
  ivaTypeId?: any;
  ivatype: any;
}

export class ArticleViewModel {
  status: boolean;
  priceListValueForExpand: number;
  labSectionValueForExpand: number;
  clientValueForExpand: number;
  doctorValueForExpand: number;
  workStatusValueForExpand: number;
  consumptionValueForExpand: number;
  issuerValueForExpand: number;
  seriesValueForExpand: number;
  yearValueForExpand: number;
  deliveryNoteFromDate: any;
  deliveryNoteToDate: any;
  deliveryFromDate: any;
  deliveryToDate: any;
}
