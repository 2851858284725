import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Work } from '../../entities/work';
import { MWTExternalData } from '../../entities/main-work-type-external-data';
import { Subscription } from 'rxjs';
import { MainWorkType } from '../../entities/main-work-type';
import { ExternalWorkService } from '../../services/external-work.service';
import { WorkService } from '../../services/work.service';
import { ExternalDataStatus } from 'src/app/modules/common/global';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import swal from 'sweetalert2';
import { CommonDataService } from '../../../common/services/common.data.service';
import { ToastData } from 'ng2-toasty';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { ApiService } from 'src/app/modules/common/services/api.service';
import API from './../../../common/api.config.json';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';

@Component({
  selector: 'app-external-work',
  templateUrl: './external-work.component.html',
  styleUrls: ['./external-work.component.scss']
})
export class ExternalWorkComponent implements OnInit {

  // subscriptions
  private workService: Subscription;

  // lists
  mWTExternalDataList: MWTExternalData[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];
  externalDataStatuList = ExternalDataStatus;

  // objects
  hasLoaded = true;
  isDiscountEnabled = true;
  selectedWork: Work;
  mWTExternalData: MWTExternalData;
  // editableMwtArticle: MwtArticle;
  selectedMainWorkType: MainWorkType;
  total = 0;
  isPanelEditable = false;
  allowAdd = false;

  showPopUp = false;

  constructor(private _modalService: ModalService,
    public _commonUiService: CommonUIService,
    private _service: ExternalWorkService,
    private _workService: WorkService,
    public _dropdownService: DropdownService,
    private _commonDataService: CommonDataService,
    private _externalWorkService: ExternalWorkService, private _apiService: ApiService,
    public commonUiService: CommonUIService,
    private _toastyService: Toast) {
    this.workService = this._workService.detailObject.share().subscribe((args) => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (args.invokeComponents.includes('ExternalData') || args.invokeComponents.includes('*')) {
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this.selectedWork = args.selectedWork;
          this.externalDataStatuList = ExternalDataStatus;
          this.SyncMwtExternalData();
        }
      }
    });
  }

  ngOnInit() {
  }

  viewattachmentmodal() {
    this._commonUiService.OpenBasicModal('view-attachment-work');
  }

  popupviewattachmentmodal() {
    this._commonUiService.OpenBasicModal('view-attachment-work-down');
  }

  OnOpenAddEditModal(data: MWTExternalData, modal: string, isArchived) {
    
    if (data && data.id > 0) {
      this.showPopUp = true;
      const mwtList = this.selectedMainWorkTypeList.filter(t => t.id == data.mainWorkTypeId);
      data.statusId = data.statusId ? data.statusId : 0;
      this._modalService.Invoke(modal, true, {
        mainWorkTypeList: mwtList,
        selectedWork: '',
        externalData: data,
        isEdit: true,
        isArchived: isArchived
      });
    } else {
      let mwtList = [] as MainWorkType[];

      let mainWorkTypeIds = this.mWTExternalDataList.map((obj) => obj.mainWorkTypeId);
      const mwt = this.selectedMainWorkTypeList.filter(t => !mainWorkTypeIds.includes(t.id));
      const workTypeList = this._dropdownService.workTypeTemplateList.filter(wt => wt.status === true);
      for (let index = 0; index < mwt.length; index++) {
        const workType = workTypeList.filter(x => x.id == mwt[index].workTypeId);
        if (!mwt[index].isClosed && workType && workType[0] && workType[0].externalConfiguration) {
          mwtList.push(mwt[index]);
        }
      }
      this.showPopUp = true;
      data = new MWTExternalData();
      this._modalService.Invoke(modal, true, {
        mainWorkTypeList: mwtList,
        selectedWork: '',
        externalData: data,
        isEdit: false,
        isArchived: isArchived
      });
    }
  }


  viewattachment(modal: string) {
    if (modal) {
      this.commonUiService.OpenBasicModal(modal);
    }
  }

  //#region CRUD

  async SyncMwtExternalData() {
    this.mWTExternalDataList = [];
    try {
      this.hasLoaded = false;
      //  this.allowAdd = false;
      const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
      if (idList) {
        let externalDataList = await this._service.GetMWTExternalDataListByMainWorkTypeIdList(idList);
        externalDataList = externalDataList ? externalDataList : [];
        if (externalDataList.length > 0) {
          for (let index = 0; index < externalDataList.length; index++) {
            const externalData = externalDataList[index];
            if (externalData.statusId && externalData.statusId != 0) {
              externalData.statusName = externalData.statusId === 1 ? 'Sent' : 'Received';
            } else {
              externalData.statusId = 0;
              externalData.statusName = '';
            }
            const currentMainWorkType = this.selectedMainWorkTypeList.find(m => Number(m.id) === externalData.mainWorkTypeId);
            if (currentMainWorkType) {
              externalData.order = currentMainWorkType.order;
              externalData.cssClass = currentMainWorkType.cssClass;
              externalData.symbol = currentMainWorkType.symbol;
            }

            if (externalData.mwtExternalDataDocuments) {
              for (let index = 0; index < externalData.mwtExternalDataDocuments.length; index++) {
                const document = externalData.mwtExternalDataDocuments[index];
                if (document) {
                  document.extension = document.fileName.split('.')[(document.fileName.split('.')).length - 1];
                  document.extension = (document.extension) ? document.extension.toLowerCase() : document.extension;
                  document.link = this._apiService.BASE_URL + API.external_data.getExternalDocument + document.id + '/' + externalData.id + '/' + document.generateFileName;

                  if (document.path) {
                    if (document.extension === 'pdf') {
                      document.path = document.path.toString().includes('data:application/pdf;base64,')
                        ? document.path : 'data:application/pdf;base64,' + document.path;
                    }
                    if (document.extension === 'png') {
                      document.path = document.path.toString().includes('data:image/png;base64,')
                        ? document.path : 'data:image/png;base64,' + document.path;
                    }
                    if (document.extension === 'jpg') {
                      document.path = document.path.toString().includes('data:image/jpg;base64,')
                        ? document.path : 'data:image/jpg;base64,' + document.path;
                    }
                    if (document.extension === 'jpeg') {
                      document.path = document.path.toString().includes('data:image/jpeg;base64,')
                        ? document.path : 'data:image/jpeg;base64,' + document.path;
                    }
                    if (document.extension === 'docx') {
                      document.path = document.path.toString().includes('https://docs.google.com/gview?url=')
                        ? document.path : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
                    }
                    if (document.extension === 'xlsx' || document.extension === 'xls') {
                      document.path = document.path.toString().includes('https://docs.google.com/gview?url=')
                        ? document.path : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
                    }
                  }
                }
              }
            }



          }
        }
        this.mWTExternalDataList = externalDataList;
        this.AllowAddExternalWork();
      }
      this.hasLoaded = true;
    } catch (error) { console.log(error); }
    finally { this.hasLoaded = true; }
  }


  AllowAddExternalWork() {
    this.allowAdd = false;
    if (this.mWTExternalDataList && this.mWTExternalDataList.length != 0) {      
      let mainWorkTypeIds = this.mWTExternalDataList.map((obj) => obj.mainWorkTypeId);
      const mwt = this.selectedMainWorkTypeList.filter(t => !mainWorkTypeIds.includes(t.id));
      const workTypeList = this._dropdownService.workTypeTemplateList.filter(wt => wt.status === true);
      for (let index = 0; index < mwt.length; index++) {
        const workType = workTypeList.filter(x => x.id == mwt[index].workTypeId);
        if (!mwt[index].isClosed && workType && workType[0] && workType[0].externalConfiguration) {
          this.allowAdd = true;
        }
      }
    } else {
      if (this._dropdownService.workTypeTemplateList) {
        const workTypeList = this._dropdownService.workTypeTemplateList.filter(wt => wt.status === true);
        for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
          const workType = workTypeList.filter(x => x.id == this.selectedMainWorkTypeList[index].workTypeId);
          if (!this.selectedMainWorkTypeList[index].isClosed && workType && workType[0] && workType[0].externalConfiguration) {
            this.allowAdd = true;
          }
        }
      }
    }
  }

  onRemoveExternalData(externalData: MWTExternalData) {
    try {
      if (externalData) {
        swal({
          title: this._commonDataService.localizationLabelList['external'],
          text: this._commonDataService.localizationLabelList['delete_warning'],
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
          cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
        }).then(async (result) => {
          if (result.value) {
            this.hasLoaded = false;
            const response = await this._externalWorkService
              .removeExternalData(externalData.id);
            if (response) {
              if (response['_statusCode'] === 200) {
                const index: number = this.mWTExternalDataList
                  .findIndex(x => Number(x.id) === Number(externalData.id));
                if (index !== -1) { this.mWTExternalDataList.splice(index, 1); }

                this.SyncMwtExternalData();
                this._toastyService.success({
                  title: this._commonDataService.localizationLabelList['external'],
                  msg: this._commonDataService.localizationLabelList['delete_success'],
                  showClose: true,
                  theme: 'default',
                  timeout: 3000,
                  onAdd: (toast: ToastData) => { },
                  onRemove: (toast: ToastData) => { }
                });
              } else if (response['_statusCode'] === 409) {
                this._toastyService.error({
                  title: this._commonDataService.localizationLabelList['external'],
                  msg: this._commonDataService.localizationLabelList['delete_error'],
                  showClose: true,
                  theme: 'default',
                  timeout: 3000,
                  onAdd: (toast: ToastData) => { },
                  onRemove: (toast: ToastData) => { }
                });
              }
            }
            this.hasLoaded = true;
          }
        });
      }
    } catch (error) { console.log(error); }
  }
  //#endregion

}
