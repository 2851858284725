import { Doctor } from "../../work/entities/doctor";
import { Issuer } from "../../auxiliary/entities/issuer";

export class Client {
  id: number;
  type: string;
  clientTypeId?: number;
  messengersId?: number;
  qualityTypeId: number;
  code: string;
  name: string;
  status: boolean;
  billToId?: number;
  Nif: string;
  physicalName: string;
  address: string;
  townId?: number;
  postalCode: string;
  telephone1: string;
  telephone2: string;
  email: string;
  contactPerson: string;
  responsibleId?: number;
  notes: string;
  languageId: number;
  paymentMethodTypeId: number;
  delivertNoteObservation: string;
  createdDate: any;
  day1: any;
  day2: any;
  day3: any;
  isAutomaticBilling: boolean;
  nif: string

  clientTypeName: string;
  messengersName: string;
  languageName: string;
  paymentMethodType: string;
  qualityType: string;
  townName: string;
  priceListId: number;
  priceListName: string;
  provinceName: string;
  priceListExceptions: boolean;
  workerName: string;
  accountNo: string;
  registrationNumber: string;

  doctor: Doctor[];
  issuer: Issuer;
  isDefault: boolean;
  isSynced: boolean;
  dentboxGuid: string;
  qualityTypeName: string;
}
