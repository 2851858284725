﻿export class ShippingForms {
    id: number;
    description: string;
    status: boolean;
    isEdit: boolean;

    constructor() {
        this.status = true;
    }
}
