export class Issuer {
  id: any;
  description: string;
  name1: string;
  name2: string;
  series: string;
  address: string;
  townId: number;
  cp: string;
  telphone1: string;
  telphone2: string;
  mobile: string;
  fax: string;
  email: string;
  web: string;
  nif: string;
  registeredNumber: string;
  licenseNumber: string;
  irpfdefec: number;
  serieBudget: string;
  serieBill: string;
  serieDeliveryNote: string;
  serieOrder: string;
  serieReceipt: string;
  code: string;
  status: boolean;
  isEdit: boolean;
  isDefault: boolean;
  issuerTown: string;
  provinceName: string;

  constructor() {
    this.status = true;
  }
}
