import API from './../../../../common/api.config.json';
import { ApiService } from '../../../../common/services/api.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommonDataService } from '../../../../common/services/common.data.service';
import { Localizations } from '../../../entities/localizations';
import { Toast } from 'src/app/modules/common/services/toast.service';


@Component({
  templateUrl: './localizations.component.html',
  selector: 'localizations-list',
  styleUrls: []
})

export class LocalizationsComponent implements OnInit {

  successMessage: any;
  updateMessage: any;
  errorMessage: any;
  deleteMessage: any;
  statusMessage: any;
  deleteErrorMessage: any;
  tableName: any;

  localizations: Localizations;
  isLoad: boolean;

  position = 'bottom-right';
  localizationsList: Localizations[];
  private _localizationsList: Localizations[] = [];

  localizationsFormGroup: FormGroup;
  localizationsForm: NgForm;
  isDisabledLocalization = false;


  @ViewChild('description') nameField: ElementRef;

  constructor(
    private _router: Router,
    private _apiService: ApiService,
    private _fb: FormBuilder,
    private _commonUIService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: Toast) {
    this.localizations = new Localizations();
    this.isLoad = false;
    this.isDisabledLocalization = false;

  }

  ngOnInit(): void {

    this.localizationsFormGroup = this._fb.group({
      description: ['', [
        Validators.required
      ]]
    })

    this.LoadLocalizationsList();
    this.localizations = new Localizations();
  }

  LoadLocalizationsList() {
    this._apiService.get(API.localization.getLocalization)
      .subscribe(res => {
        this.localizationsList = res;
        this._localizationsList = res;
        this.isLoad = true;
      });
  }

  LoadEditLocalizations(localizations: Localizations) {
    localizations.isEdit = true;
  }

  EditLocalizations(localizations: Localizations, statusChange: boolean) {
    this.isDisabledLocalization = true;

    localizations.isEdit = false;

    this.statusMessage = this._commonDataService.localizationLabelList['status_updated'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.tableName = this._commonDataService.localizationLabelList['localizations'];

    this._apiService.put(API.localization.editLocalizations + localizations.id, localizations, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {

            let message = '';

            if (statusChange) {
              message = this.statusMessage;
            }
            else {
              message = this.updateMessage;
            }

            this._toastyService.success({
              title: this.tableName,
              msg: message
            });

            this.LoadLocalizationsList();
          }
        },
        err => {
          console.log(err);
        },
        () => {
          this.isDisabledLocalization = false;
        }
      );
  }

  RemoveEditLocalizations(localizations: Localizations) {
    localizations.isEdit = false;
    this.LoadLocalizationsList();
  }

  ActiveDeactiveLocalizations(localizations: Localizations) {

    if (localizations) {
      if (localizations.status) {
        localizations.status = false;
      }
      else {
        localizations.status = true;
      }
      this.EditLocalizations(localizations, true);
    }
  }

  OnOpenAddLocalizationsModal() {

    this.successMessage = this._commonDataService.localizationLabelList['save_success'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.errorMessage = this._commonDataService.localizationLabelList['save_error'];
    this.tableName = this._commonDataService.localizationLabelList['localizations'];

    this.localizations = new Localizations(); this.localizationsFormGroup.reset();
    this._commonUIService.openModal('localizations-modal');

    setTimeout(() => {
      this.nameField.nativeElement.focus();
    }, 100);

  }

  OnCloseLocalizationsModal(event) {
    (((((event.target.parentElement.parentElement).parentElement).parentElement).parentElement).parentElement).classList.remove('md-show');
  }

  SaveLocalizations(localizations: Localizations, event) {
    this.isDisabledLocalization = true;

    if (this.localizationsFormGroup.invalid) {
      Object.keys(this.localizationsFormGroup.controls).forEach(field => {
        const control = this.localizationsFormGroup.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      this.isDisabledLocalization = false;

      return;
    }

    this.OnCloseLocalizationsModal(event);

    this._apiService.post(API.localization.saveLocalizations, localizations, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {
            this._toastyService.success({
              title: this.tableName,
              msg: this.successMessage
            });
            this.LoadLocalizationsList();
          }
        },
        err => {
          this._toastyService.error({
            title: this.tableName,
            msg: err
          });
          this.isDisabledLocalization = false;
        },
        () => {
          this.isDisabledLocalization = false;
        }
      );
  }

  DeleteLocalizations(id: number) {
    this.isDisabledLocalization = true;

    this.deleteMessage = this._commonDataService.localizationLabelList['delete_success'];
    this.deleteErrorMessage = this._commonDataService.localizationLabelList['delete_error'];
    this.tableName = this._commonDataService.localizationLabelList['localizations'];

    if (id) {
      this._apiService.delete(API.localization.deleteLocalizations, id.toString())
        .subscribe(
          res => {
            if (res._statusCode == 200) {
              let index: number = this._localizationsList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._localizationsList.splice(index, 1);
                this._toastyService.success({
                  title: this.tableName,
                  msg: this.deleteMessage
                });
              }
            }
            if (res._statusCode == 409) {
              let index: number = this._localizationsList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._toastyService.warning({
                  title: this.tableName,
                  msg: this.deleteErrorMessage
                });
              }
            }
          },
          err => {
            this._toastyService.error({
              title: this.tableName,
              msg: err
            });
            this.isDisabledLocalization = false;
          },
          () => {
            this.isDisabledLocalization = false;
            this.localizationsList = this._localizationsList;
          }
        );
    }
  }
}



