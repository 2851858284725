export class RequestData {
    page: number;
    pageSize: number;
    columns: string;
    filter: string;
    orderBy: string;
    activeExpand: number;
    active?: boolean;

    constructor(page: number, pageSize: number, columns: string, filter: string, orderBy: string, activeExpand: number, active?: boolean) {
        this.page = page;
        this.pageSize = pageSize;
        this.columns = columns;
        this.filter = filter;
        this.orderBy = orderBy;
        this.activeExpand = activeExpand;
        this.active = active;
    }

}
