import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LabSectionTopes } from 'src/app/modules/auxiliary/sections/components/entities/lab-section-topes';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { MwtTaskService } from '../../../services/mwt-task.service';

@Component({
  selector: 'app-section-topes-edit-modal',
  templateUrl: './section-topes-edit-modal.component.html'
})
export class SectionTopesEditModalComponent implements OnInit {
  @Input() labSectionTopes: LabSectionTopes;
  @Output() closeSectionModel: EventEmitter<any> = new EventEmitter();

  dailyMaxlimitControl = new FormControl('');

  constructor(public commonUiService: CommonUIService, private _toastyService: Toast, private _commonDataService: CommonDataService,
    private _taskService: MwtTaskService) { }

  ngOnInit() {
  }

  OpenModal(dailyMaxLimit: any) {
    this.dailyMaxlimitControl.setValue(dailyMaxLimit);
    this.dailyMaxlimitControl.disable();
    this.commonUiService.OpenBasicModal('edit-section-daily-task');
  }

  async OnSaveSectionsTaskTopes() {
    this.commonUiService.isSpinnerVisible = true;
    this.labSectionTopes.tope = this.labSectionTopes.tope ? Number(this.labSectionTopes.tope) : 0;
    const topesResponse = await this._taskService.UpdateLabSectionsTaskTopes(this.labSectionTopes);
    if (topesResponse) {
      const body = JSON.parse(topesResponse['_body']);
      if (body['_isSuccsess']) {
        this.commonUiService.CloseBasicModal('edit-section-daily-task');
        this.closeSectionModel.emit(this.labSectionTopes);
        this._toastyService.success({
          title: this._commonDataService.localizationLabelList['task_stops'],
          msg: this._commonDataService.localizationLabelList['save_success']
        });
        this.commonUiService.isSpinnerVisible = false;
      } else {
        this._toastyService.error({
          title: this._commonDataService.localizationLabelList['task_stops'],
          msg: this._commonDataService.localizationLabelList['save_error']
        });
        this.commonUiService.isSpinnerVisible = false;
        this.commonUiService.CloseBasicModal('edit-section-daily-task');
      }
    }
  }

}
