import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastData, ToastyService } from 'ng2-toasty';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import swal from 'sweetalert2';
import { CommonDataService } from '../../../common/services/common.data.service';
import { ShipmentPickupDetails } from '../../../messaging/entities/shipment-pickup-details';
import { MessagingService } from '../../../messaging/services/messaging.service';
import { MainWorkType } from '../../entities/main-work-type';
import { Work } from '../../entities/work';
import { WorkService } from '../../services/work.service';

@Component({
  selector: 'app-shipment-collection',
  templateUrl: './shipment-collection.component.html',
  styleUrls: ['./shipment-collection.component.scss']
})
export class ShipmentCollectionComponent implements OnInit, OnDestroy {

  private workService: Subscription;
  selectedMainWorkTypeList: MainWorkType[] = [];
  private _selectedWork: Work;
  readMore = true;
  hasLoaded = true;
  shipmentPickupDetailList: ShipmentPickupDetails[] = [];
  showPopUp = false;
  allClosed = false;

  constructor(private _workService: WorkService, private _modalService: ModalService, private _commonDataService: CommonDataService,
    private __service: MessagingService, private _toastyService: ToastyService, private _messengerService: MessagingService) { }

  ngOnInit() {
    this.workService = this._workService.detailObject.share().subscribe((args) => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (args.invokeComponents.includes('Messenger') || args.invokeComponents.includes('*')) {
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this._selectedWork = args.selectedWork;
          this.readMore = true;
          this.allClosed = this.selectedMainWorkTypeList.every(x => x.isClosed);
          this.SyncShipmentPickupData();
        }
      }
    });
  }

  ngOnDestroy() { this.workService.unsubscribe(); }

  private async SyncShipmentPickupData() {
    try {
      this.shipmentPickupDetailList = [];
      this.hasLoaded = false;
      const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
      if (idList) {
        let shipmentPickupDetailList = await this.__service.GetShipmentPickupDetailListByMainWorkTypeIdList(idList);
        shipmentPickupDetailList = shipmentPickupDetailList ? shipmentPickupDetailList : [];
        if (shipmentPickupDetailList.length > 0) {
          for (let index = 0; index < shipmentPickupDetailList.length; index++) {
            const spDetail = shipmentPickupDetailList[index];
            const currentMainWorkType = this.selectedMainWorkTypeList.find(m => Number(m.id) === spDetail.mainWorkTypeId);
            if (currentMainWorkType) {
              spDetail.order = currentMainWorkType.order;
              spDetail.cssClass = currentMainWorkType.cssClass;
              spDetail.symbol = currentMainWorkType.symbol;
              spDetail.time = spDetail.time ? spDetail.time.toString().substring(0, 5) : '';
              spDetail.deliveryTime = spDetail.deliveryTime ? spDetail.deliveryTime.toString().substring(0, 5) : '';
              if (currentMainWorkType.isClosed) {
                spDetail.isClosed = true;
              } else {
                spDetail.isClosed = false;
              }
            }
          }
          this.shipmentPickupDetailList = shipmentPickupDetailList;
        }
      }
    } catch (error) { console.log(error); } finally {
      this.hasLoaded = true;
    }
  }

  async OnOpenModal(modal: string) {
    if (modal) {
      let mwtList = [] as MainWorkType[];
      for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
        const mwt = this.selectedMainWorkTypeList[index];
        if (!mwt.isClosed) {
          mwtList.push(mwt);
        }
      }
      this.showPopUp = true;
      this._modalService.Invoke(modal, true, {
        selectedMwtList: mwtList,
        selectedWork: this._selectedWork,
        isEdit: false
      }, false);
    }
  }

  AfterMessengerSaved() { this.SyncShipmentPickupData(); }

  async OnRemoveShipmentPickupDetail(id: number) {
    try {
      const result = await swal({
        title: this._commonDataService.localizationLabelList['messenger'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      });
      if (result) {
        if (result.value) {
          const response = await this._messengerService.DeleteShipmentPickupDetail(id);
          if (response) {
            if (response['_isSuccsess']) {
              const index: number = this.shipmentPickupDetailList.findIndex(x => x.id === id);
              if (index !== -1) { this.shipmentPickupDetailList.splice(index, 1); }
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList['messenger'],
                msg: this._commonDataService.localizationLabelList['delete_success'],
                showClose: true,
                theme: 'default',
                timeout: 5000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
            } else {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['messenger'],
                msg: this._commonDataService.localizationLabelList['save_error'],
                showClose: true,
                theme: 'default',
                timeout: 5000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
            }
          }
        }
      }
    } catch (error) { console.log(error); }
  }
}
