import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalNames } from 'src/app/modules/common/global';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Document } from '../../entities/document';

@Component({
  selector: 'app-view-documentview-modal',
  templateUrl: './view-documentview-modal.component.html'
})
export class ViewDocumentviewModalComponent implements OnInit {
  showContent = false;
  document: Document;
  constructor(private _modalService: ModalService) {
    this.showContent = true;
  }
  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    if (this._modalService.modal === ModalNames.DocumentViewModal) {
      if (this._modalService.transferable && this._modalService.transferable.document) {
        this.document = this._modalService.transferable.document;
      }
    }
  }

  OnClickDocument(document: Document) {
    if (document) {
      window.open(document.link, '_blank');
    }
  }

  OnClose() {
    this.closeModel.emit();
    this._modalService.Invoke(ModalNames.DocumentViewModal, false, '');
  }

}
