import { Injectable } from '@angular/core';
import API from './../../common/api.config.json';
import { CommonFunctions } from '../../common/services/common-functions';
import { ApiService } from '../../common/services/api.service';
import { MWTExternalData } from '../entities/main-work-type-external-data';
import { MWTExternalDataDocuments } from '../entities/main-work-type-external-data-documents';
import { ShippingForms } from '../../auxiliary/entities/shipping-forms';
import { ExternalCenter } from '../../external-shipment/entities/external-center';

@Injectable({
  providedIn: 'root'
})
export class ExternalWorkService {

  constructor(private _apiService: ApiService) { }

  GetMWTExternalDataListByMainWorkTypeIdList(idList: { id: number }[]) {
    try {
      const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
      if (params) {
        const query = params.toString().replace('+', '%20');
        return this._apiService.get(API.external_data.getMWTExternalDataListByMainWorkTypeIds + query, true)
          .map(a => a as MWTExternalData[]).toPromise();
      }
    } catch (error) { console.log(error); }
  }

  public async SaveExternalDataDocument(document: MWTExternalDataDocuments): Promise<MWTExternalDataDocuments> {
    return new Promise<MWTExternalDataDocuments>((resolve, reject) => {
      this._apiService.post(API.external_data_document.saveExternalDataDocument, document, true)
        .subscribe((externalDocument: MWTExternalDataDocuments) => resolve(externalDocument),
          err => reject(err));
    });
  }

  public GetMWTExternalDataList() {
    return this._apiService.get(API.external_data.getMWTExternalDataList, true)
      .map((list: MWTExternalData[]) => list).toPromise();
  }

  public GetShippingFormsList() {
    return this._apiService.get(API.shipping_forms.getShippingFormsList, true)
      .map((list: ShippingForms[]) => list.filter(i => i.status)).toPromise();
  }

  public GetExternalCenterList() {
    return this._apiService.get(API.external_center.getExternalCenterList, true)
      .map((list: ExternalCenter[]) => list.filter(i => i.status)).toPromise();
  }

  GetNextOutSourceNo() {
    return this._apiService.get(API.external_data.getNextOutSourceNo, true).toPromise();
  }

  removeExternalData(id: number) {
    return this._apiService.delete(API.external_data.deleteMwtExternalData, id.toString())
      .toPromise();
  }

}
