import { Component, Input, OnInit } from '@angular/core';
import { CommonUIService } from '../../../modules/common/services/common.ui.service';
import { IncidentReport } from '../../../modules/work/entities/incident-report';

@Component({
  selector: 'app-incident-report',
  templateUrl: './incident-report.component.html',
  styleUrls: ['./incident-report.component.scss']
})
export class IncidentReportComponent implements OnInit {

  @Input() public incidentReport: IncidentReport;
  imageData: any;

  constructor(private _commonUIService: CommonUIService) { }

  ngOnInit() {
    this.LoadLabConfigurationList();
  }

  async LoadLabConfigurationList() {
    this.imageData = await this._commonUIService.LoadLabConfigurationList();
  }

}
