import {
  Component, OnInit, ViewEncapsulation, KeyValueDiffer,
  KeyValueDiffers, DoCheck, OnDestroy, ViewChild, AfterViewInit
} from '@angular/core';
import { ApiService } from '../common/services/api.service';
import { CommonUIService } from '../common/services/common.ui.service';
import API from '../common/api.config.json';
import { InvoiceHeader } from './entities/invoice-header';
import { DropDownOption } from '../common/entities/dropdown-option';
import { Client } from '../work/entities/client';
import { InvoiceDetail } from './entities/invoice-detail';
import { DeliveryNote } from '../work/entities/delivery-note';
import { User } from '../auth/entities/user';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ValidateNgSelect, ShowInvalidFormControls, isNumber, Delay } from '../common/services/validators';
import { Issuer } from '../client/entities/client-issuer';
import { CommonDataService } from '../common/services/common.data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Series } from '../client/entities/series';
import { GenerateNumberModel, SavedGeneratedNumber } from '../work/entities/generate-number-model';
import { animate, style, transition, trigger } from '@angular/animations';
import { AutomaticInvoiceModel } from './entities/automatic-invoice-model';
import { ClientSeries } from '../client/entities/client-series';
import { RequestData } from 'src/app/modules/invoice/pagination/requestData';
import { CommonFunctions } from '../common/services/common-functions';
import { NumberingProcess, NumberingProcessData } from '../../shared/numbering-process/numbering-process';
import swal from 'sweetalert2';
import { NumberingProcessService } from '../../shared/numbering-process/numbering-process.service';
import { WorkService } from '../work/services/work.service';
import { InvoiceReportTemplate, ListNames, InvoiceStatusTypes } from '../common/global';
import { InvoiceService } from '../work/services/invoice.service';
import { Subject, Observable } from 'rxjs';
import { Toast } from '../common/services/toast.service';
import { DropdownService } from '../common/services/dropdown.service';
import { InvoiceDeliveryNoteViewModel, DisplayDeliveryNoteViewModel } from './entities/invoice-edit-view-models';
import { DeliveryNoteService } from '../work/services/delivery-note.service';
import { SendEmail } from '../work/entities/emailModel';
import { LanguageService } from '../common/services/ui/language.service';
import * as moment from 'moment';
import { Town } from '../auxiliary/entities/town';
import { IvaBreakdown } from '../auxiliary/entities/iva-breakdown';
import { toFixedNumber } from '../common/services/calculations/calculation';
import { ArticleCalculationService } from '../common/services/calculations/article-calculation.service';
import { DeliveryNoteCalculationService } from '../common/services/calculations/delivery-note-calculation.service';
import { translateXY } from '@swimlane/ngx-datatable/release/utils';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss',
    '../../../assets/icon/icofont/css/icofont.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('400ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})

export class InvoiceComponent implements OnInit, DoCheck, OnDestroy, AfterViewInit {
  @ViewChild('pdfGenerate') pdfGenerater: any;
  @ViewChild('searchbox') searchboxElement: any;

  sampleHtml = '';
  /* Config variables */
  position = 'bottom-right';
  sidebarFixedHeight: string;
  imageData: any;

  /* Auth variables */
  public user: User;

  /* properties */
  saveDisabled = false;
  private _isSearched = false;
  private _isFilteredByClient = false;

  differ: KeyValueDiffer<string, any>;
  refresh: boolean;
  isEdit = false;
  isSaveButtonEnabled = false;
  isActiveInvoiceHeaderList = true;
  isShowingFilters = false;
  private invoiceListActive = 1;
  requestData = new RequestData(1, 10, '', '', '', this.invoiceListActive);
  invoiceHeaderListUrl: string;
  invoiceHeader: InvoiceHeader;
  selectedInvoiceHeader: InvoiceHeader;
  searchKey = '';
  searchClientId = '0';
  searchIssuerId = '0';
  searchSerie = '';
  searchYear = '';
  generateNumberModel: GenerateNumberModel;
  usedSeries: Series;
  numOfDigits = 5;
  load = '';
  singleDeliveryNoteId = 0;
  automaticInvoice: AutomaticInvoiceModel;
  checkedClientCount = 0;
  showcloseBillingPopup = false;

  /* lists */
  private _invoiceHeaderList: InvoiceHeader[] = [];
  private _issuerList: Issuer[] = [];
  private _seriesList: Series[] = [];
  seriesList: Series[];
  ivaBreakdownList: IvaBreakdown[] = [];

  invoiceHeaderList: InvoiceHeader[] = [];
  pendingInvoiceDetailList: InvoiceDetail[] = [];
  relatedWorkList: any[] = [];
  invoicedDeliveryNoteList: any[] = [];
  pendingDeliveryNoteList: DeliveryNote[] = [];
  savedGeneratedNumberList: SavedGeneratedNumber[] = [];
  selectedClientList: { id: number, name: string, isSelected: boolean }[] = [];
  reportTemplateList: { value: number, description: string }[] = [];
  selectedTemplate: { value: number, description: string };
  townList: Town[] = [];

  invoiceDeliveryNoteList: InvoiceDeliveryNoteViewModel[] = [];

  /* dropdown lists */
  clientDropDownList: DropDownOption[] = [];
  issuerDropDownList: DropDownOption[] = [];
  seriesDropDownList: DropDownOption[] = [];
  serieDropDownList: DropDownOption[] = [];
  yearDropDownList: DropDownOption[] = [];
  deliveryNoteSeriesDropDownList: DropDownOption[] = [];
  invoiceSeriesDropDownList: DropDownOption[] = [];
  yearList: any[] = [];

  /* form groups */
  invoiceFormGroup: FormGroup;
  automaticInvoiceFormGroup: FormGroup;

  ivNumberingProcess: NumberingProcess;
  ivSeries: { id: number, type: 'Work' | 'Delivery Note' | 'Bill' | 'Budget', series: string };
  ivMaxSize = 5;
  ivFormGroup: FormGroup;
  currentNPData: NumberingProcessData;
  invoiceModalSubject: Subject<any> = new Subject();

  CANCELLED = InvoiceStatusTypes.Cancelled;
  CANCELLED2 = InvoiceStatusTypes.Cancelled2;
  PENDING = InvoiceStatusTypes.Pending;

  showCancellationPopup = false;
  emailmodel: SendEmail;

  constructor(
    private _apiService: ApiService,
    private _toastyService: Toast,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _workService: WorkService,
    private _router: Router,
    private _numberingProcessService: NumberingProcessService,
    private _invoiceService: InvoiceService,
    private _dropdownService: DropdownService,
    public _commonDataService: CommonDataService,
    private _deliveryNoteService: DeliveryNoteService,
    private differs: KeyValueDiffers,
    public commonUIService: CommonUIService,
    public dropdownService: DropdownService,
    public languageService: LanguageService,
    private _articleCalculationService: ArticleCalculationService,
    private _deliveryNoteCalculationService: DeliveryNoteCalculationService) {
    this.sidebarFixedHeight = 'calc(100vh - 190px)';
    this.invoiceFormGroup = _formBuilder.group({
      clientId: ['', [ValidateNgSelect]],
      issuerId: ['', [ValidateNgSelect]],
      seriesId: ['', [ValidateNgSelect]],
      generateNumber: ['', []]
    });
    this.ivFormGroup = this._formBuilder.group({
      isNextAvailableNumber: [], nextAvailableNumber: [],
      isAnother: [], anotherNumber: [], isOtherAvailableNumbers: [],
      otherAvailableNumber: [], isNoNumberAssigned: []
    });
    this.automaticInvoiceFormGroup = _formBuilder.group({
      fromDeliveryDate: [''],
      toDeliveryDate: [''],
      deliveryNoteIssuerId: [''],
      deliveryNoteSeriesId: [''],
      isClientsWithAutomaticBilling: [],
      isOnlyClientsWithPendingDeliveryNotes: [],
      defaultOrSpecificIssuerAndSeries: [],
      automaticInvoiceIssuerId: [],
      automaticInvoiceSeiriesId: [],
      automaticInvoiceDate: [],
      numOfInvoicesToReserve: [],
      automaticInvoiceComments: []
    });
    this.differ = this.differs.find({}).create();
    this.reportTemplateList = InvoiceReportTemplate;
    this.commonUIService.isSpinnerVisible = true;
    this.languageService.isTranslated.subscribe((val) => {
      this.manageNgSelectDropdown(val);
    });
  }

  ngOnInit() {
    this.invoiceHeaderListUrl = API.invoice.getInvoiceHeaderListWithPagination;
    this.LoadLabConfigurationList();
    this._dropdownService.LoadList([ListNames.IssuerList,
    ListNames.SubscriptionReasonList]);
    this.user = this._commonDataService.GetUserFromSession();
    this.SetUIChanges();
    this.invoiceHeader = new InvoiceHeader();
    this.invoiceHeader.clientId = '0';
    this.invoiceHeader.issuerId = '0';
    this.selectedInvoiceHeader = new InvoiceHeader();
    this.automaticInvoice = new AutomaticInvoiceModel();
    this.emailmodel = new SendEmail();
    this.ivNumberingProcess = new NumberingProcess({
      isNextAvailableNumber: true,
      nextAvailableNumber: 0,
      isAnother: false,
      isNoNumberAssigned: false,
      isOtherAvailableNumbers: false,
      anotherNumber: 1,
      otherAvailableNumber: 0
    });
    this.invoiceListActive = 1;

    this.LoadClientList().then(clients => this.PopulateClientDropdown(clients));
    this.LoadIssuerList();
    this.LoadCityList();
    this.LoadLabConfigurationList();
    this.YearList().then(async list => {
      this.yearDropDownList = list.map(x => new DropDownOption(x.id.toString(), x.text, false));
      this.seriesList = await this.GetInvoiceSeriesList();
      this.PopulateSeries(this.seriesList);
      this.searchSerie = '0';
      this.searchYear = '0';
    });
    this._route.queryParams
      .subscribe(params => {
        if (params['load'] && params['delivery'] && params['client']
          && params['issuer'] && params['ignNumber'] && params['series']) {
          if ((params['load'] === 'single' || params['load'] === 'all') &&
            isNumber(params['delivery']) && isNumber(params['client']) && isNumber(params['issuer'])) {
            if (params['load'] === 'single') {
              this.LoadPendingDeliveryNoteListById(Number(params['delivery'])).then(delNote => {
                this.invoiceHeader = new InvoiceHeader();
                this.invoiceHeader.issuerId = params['issuer'];
                this.invoiceHeader.clientId = params['client'];
                this.invoiceHeader.clientName = params['clientName'];
                this.invoiceHeader.generateNumber = params['ignNumber'];
                this.invoiceHeader.seriesId = params['series'];
                this.invoiceHeader.patientId = delNote.patientId ? delNote.patientId : null;
                this.invoiceHeader.billedToPatient = delNote.isToPatient ? delNote.isToPatient : false;
                // if delNote.isToPatient is null, it will cause an error in invoice table; (billedToPatient column is not null) 
                // so the logic changed like this -> delNote.isToPatient ? delNote.isToPatient : false
                this.load = params['load'];

                this.singleDeliveryNoteId = Number(params['delivery']);
                this.invoiceHeader.subTotal = toFixedNumber(delNote.totalPrice ? delNote.totalPrice : 0)
                  - toFixedNumber(delNote.tax ? delNote.tax : 0);
                this.invoiceHeader.tax = toFixedNumber(delNote.tax ? delNote.tax : 0);
                this.invoiceHeader.invoiceTotal = toFixedNumber(toFixedNumber(delNote.totalPrice ?
                  delNote.totalPrice : 0) );
                this.invoiceHeader.invoiceDetailList = this.GetInvoiceDetailListFromDeliveryNoteList([delNote]);
                this.InitNewInvoice();
                this.OnSelectSeries();
                this.AddInvoiceHeader(this.invoiceHeader);
                this.ResetUrlQueryParams();
              });
            } else {
              this.LoadPendingDeliveryNoteListByClientId(Number(params['client']))
                .then(list => {
                  this.load = params['load'];
                  this.invoiceHeader = new InvoiceHeader();
                  this.invoiceHeader.issuerId = params['issuer'];
                  this.invoiceHeader.clientId = params['client'];
                  this.invoiceHeader.clientName = params['clientName'];
                  this.invoiceHeader.generateNumber = params['ignNumber'];
                  this.invoiceHeader.seriesId = params['series'];
                  this.invoiceHeader.patientId = null;
                  this.invoiceHeader.billedToPatient = false

                  let subTotal = 0, total = 0, tax = 0;
                  for (let index = 0; index < list.length; index++) {
                    const deliveryNote = list[index];
                    subTotal += toFixedNumber(deliveryNote.totalPrice);
                    total += toFixedNumber(deliveryNote.totalPrice);
                    tax += toFixedNumber(deliveryNote.tax);
                  }

                  this.invoiceHeader.subTotal = toFixedNumber(subTotal - tax);
                  this.invoiceHeader.tax = tax;
                  this.invoiceHeader.invoiceTotal = toFixedNumber(total);
                  this.invoiceHeader.invoiceDetailList = this.GetInvoiceDetailListFromDeliveryNoteList(list);
                  this.InitNewInvoice();
                  this.OnSelectSeries();
                  this.AddInvoiceHeader(this.invoiceHeader);
                  this.ResetUrlQueryParams();
                });
            }
          } else { this._router.navigate(['/invoice']); }
        } else { this._router.navigate(['/invoice']); }
      });
    // Intel search
    this._route.queryParams.subscribe(async params => {
      if (params['referenceId']) {
        const invoice = await this._invoiceService
          .GetInvoiceDetailsById(Number(params['referenceId']));
        if (invoice) { this.OnSelectInvoiceHeader(invoice); }
        this.ResetUrlQueryParams();
      }
    });
    this.selectedTemplate = InvoiceReportTemplate[0];
  }

  ngAfterViewInit() {
    Observable.fromEvent(this.searchboxElement.nativeElement, 'keyup')
      .debounceTime(1000).subscribe(value =>
        this.FilterInvoice()
      );
  }

  ngDoCheck() {
    const change = this.differ.diff(this);
    if (change) {
      change.forEachChangedItem(item => {
        if (item['key'] === 'checkedClientCount') {
          if (item.currentValue === 0) {
            this.DisableAutomaticInvoiceFormSection();
          } else { this.EnableAutomaticInvoiceFormSection(); }
        }
      });
    }
  }

  ngOnDestroy() {
    this.invoiceModalSubject.unsubscribe();
  }

  //#region Other

  LoadCityList() {
    this._apiService.get(API.town.getTownList)
      .subscribe(res => this.townList = res);
  }

  private DisableAutomaticInvoiceFormSection() {
    this.automaticInvoiceFormGroup.get('defaultOrSpecificIssuerAndSeries').disable();
    this.automaticInvoiceFormGroup.get('automaticInvoiceDate').disable();
    this.automaticInvoiceFormGroup.get('automaticInvoiceComments').disable();
    this.automaticInvoiceFormGroup.get('numOfInvoicesToReserve').disable();
  }

  private EnableAutomaticInvoiceFormSection() {
    this.automaticInvoiceFormGroup.get('defaultOrSpecificIssuerAndSeries').enable();
    this.automaticInvoiceFormGroup.get('automaticInvoiceDate').enable();
    this.automaticInvoiceFormGroup.get('automaticInvoiceComments').enable();
    this.automaticInvoiceFormGroup.get('numOfInvoicesToReserve').enable();
  }

  CloseModal(event) {
    (((((event.target.parentElement.parentElement).parentElement)
      .parentElement).parentElement).parentElement).classList.remove('md-show');
  }

  Search() {
    if (this.searchKey !== '' && this.searchKey !== undefined) {
      if (!this._isSearched) { this._invoiceHeaderList = [...this.invoiceHeaderList]; }
      const source: InvoiceHeader[] = this._invoiceHeaderList;
      this.searchKey = this.searchKey.toLowerCase();
      const result: InvoiceHeader[] = source.filter((x, i, a) =>
        x.generateNumber.toLowerCase().includes(this.searchKey));
      this._isSearched = true;
      this.invoiceHeaderList = result;
    } else {
      this._isSearched = false;
      this.refresh = true;
    }
  }

  SetInvoiceStatus(active: number) {
    if (active === 1) {
      this.invoiceListActive = 1;
      this.requestData.active = 1;
      this.refresh = true;
    } else {
      this.invoiceListActive = 3;
      this.requestData.active = 3;
      this.refresh = true;
    }
  }

  FilterInvoice() {
    let seriesFilter = this.searchSerie;
    let yearFilter = this.searchYear;
    if (this.searchYear === '0') { yearFilter = ''; }
    if (this.searchSerie === '0') { seriesFilter = ''; }
    this.requestData.filter = `${this.searchKey};${this.searchIssuerId};${seriesFilter};${yearFilter};${this.searchClientId}`;
    this.requestData.page = 1;
    this.refresh = true;
  }

  ToggleFilters() {
    this.isShowingFilters = !this.isShowingFilters;
    if (this.yearDropDownList.findIndex(d => d.value === '0') === -1) {
      this.yearDropDownList.unshift(new DropDownOption('0',
        this._commonDataService.localizationLabelList['select'], false));
    }
  }

  private GetDropDownList(source: any[], key: string): DropDownOption[] {
    if (source !== undefined && key !== undefined) {
      let destination: DropDownOption[] = [];
      destination.push(new DropDownOption('0',
        this._commonDataService.localizationLabelList['select'], false));
      if (source.length > 0) {
        source.forEach((element) => {
          const option = new DropDownOption(element.id.toString(), element[key], false);
          destination.push(option);
        });
        destination = CommonFunctions.Sort(destination, 'label', false);
        return destination;
      }
    }
  }

  FilterByClient() {
    if (this.searchClientId !== '0') {
      if (!this._isFilteredByClient) { this._invoiceHeaderList = [...this.invoiceHeaderList]; }
      this._isFilteredByClient = true;
      this.invoiceHeaderList = this._invoiceHeaderList
        .filter(x => Number(x.clientId) === Number(this.searchClientId));
    } else { this.refresh = true; }
  }

  private GetInvoiceDetailListFromDeliveryNoteList(deliveryNoteList: DeliveryNote[]): InvoiceDetail[] {
    const invoiceDetailList: InvoiceDetail[] = [];
    if (deliveryNoteList) {
      deliveryNoteList.forEach(deliveryNote => {
        if (deliveryNote.articleList) {
          if (deliveryNote.articleList.length > 0) {
            deliveryNote.articleList.forEach(article => {
              const invoiceDetail = new InvoiceDetail();
              invoiceDetail.articleDiscount = toFixedNumber(article.articleDiscount);
              invoiceDetail.articleFinalPrice = toFixedNumber(article.articleFinalPrice);
              invoiceDetail.articleName = article.articleName;
              invoiceDetail.articlePrice = toFixedNumber(article.articlePrice);
              invoiceDetail.articleQuantity = article.articleQuantity;
              invoiceDetail.articleWaste = toFixedNumber(article.articleWaste);
              invoiceDetail.workId = deliveryNote.workId;
              invoiceDetail.workName = deliveryNote.workNumber;
              invoiceDetail.mwtArticleId = article.mwtArticleId;
              invoiceDetail.deliveryNoteId = deliveryNote.id;
              invoiceDetail.deliveryNoteGenNo = deliveryNote.generateNo;
              invoiceDetail.isInvoiced = deliveryNote.isInvoiced;
              invoiceDetailList.push(invoiceDetail);
            });
          }
        }
      });
    }
    return invoiceDetailList;
  }

  private CalculateInvoiceDetailListValues(invoiceDetailList: InvoiceDetail[]): InvoiceDetail[] {
    if (invoiceDetailList) {
      invoiceDetailList.forEach(detail => {
        detail.articleDiscountAmount = detail.isDiscountAmount ? detail.articleDiscount :
          this._articleCalculationService
            .getDiscountAmount(toFixedNumber(detail.articlePrice),
              toFixedNumber(detail.articleDiscount));

        detail.articleQuantityWithWaste = this._articleCalculationService
          .getQuantityWithWaste(detail.articleQuantity, toFixedNumber(detail.articleWaste));
        detail.articlePriceWithDiscount = toFixedNumber(detail.articlePrice - detail.articleDiscountAmount);
      });
    }
    return invoiceDetailList;
  }

  private InitNewInvoice() {
    this.invoiceHeader.invoiceStatus = 'pending';
    this.invoiceHeader.modifiedDate = new Date().toISOString();
    this.invoiceHeader.createdDate = new Date().toISOString();
    this.invoiceHeader.generateDate = new Date().toISOString();
    this.invoiceHeader.modifiedBy = this.user.id;
    this.invoiceHeader.createdBy = this.user.id;
    this.invoiceHeader.status = true;
    this.invoiceHeader.discount = 0;
    this.invoiceHeader.discountAmount = 0;
  }

  private ResetUrlQueryParams() {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {}
    });
  }

  //#endregion

  //#region Load Resources

  /* UI changes */
  private SetUIChanges() {
    this.commonUIService.isShowLoyoutMenu = true;
    this.commonUIService.isShowStatMenu = true;
    this.commonUIService.isShowExpandView = false;

    this.commonUIService.isActiveBothPanel = true;
    this.commonUIService.isActiveLeftPanelOnly = false;
  }

  async LoadClientList(): Promise<Client[]> {
    return new Promise<Client[]>((resolve, reject) => {
      this._apiService.get(API.client.getClientList)
        .subscribe((clients: Client[]) => resolve(clients),
          err => reject(Error(err)));
    });
  }

  private PopulateClientDropdown(list: Client[]) {
    if (list) {
      this.clientDropDownList = [];
      list.sort((a, b) => {
        return a.id - b.id;
      });
      this.clientDropDownList.push(new DropDownOption('0',
        this._commonDataService.localizationLabelList['select'], false));
      list.forEach((element) => {
        const option = new DropDownOption(element.id.toString(), element.name, false);
        this.clientDropDownList.push(option);
      });
    }
  }

  private LoadIssuerList() {
    this._apiService.get(API.issuer.getIssuerList)
      .subscribe((list: Issuer[]) =>
        this._issuerList = list.filter(x => x.status === true),
        err => console.log(err),
        () => {
          this.issuerDropDownList = this.GetDropDownList(this._issuerList, 'description');
          this.invoiceHeader.issuerId = '0';
          this.commonUIService.isSpinnerVisible = false;
        });
  }

  private async LoadSeriesList(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._apiService.get(API.series.getSeriesList)
        .subscribe((list: Series[]) => { this._seriesList = list; resolve(); },
          err => reject(err));
    });
  }

  private async GetSeriesByClientId(clientId: number): Promise<ClientSeries[]> {
    return new Promise<ClientSeries[]>((resolve, reject) => {
      this._apiService.get(API.client_series.getClientSeriesByClientId + clientId)
        .subscribe((list: ClientSeries[]) => resolve(list),
          err => reject(Error(err)));
    });
  }

  //#endregion

  //#region Number Generation

  private async GetSavedGeneratedNumberListBySeriesId(seriesId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._apiService.get(API.series.GetSavedGeneratedNumberListBySeriesId + seriesId)
        .subscribe(res => { this.savedGeneratedNumberList = res; resolve(); },
          err => reject(err),
          () => { });
    });
  }

  private UpdateSeries(config: Series) {
    this._apiService.post(API.series.updateSeries, config, true)
      .subscribe(res => { },
        err => console.log(err),
        () => { });
  }

  private SaveAsSavedGeneratedNumberList(list: SavedGeneratedNumber[]) {
    this._apiService.post(API.series.saveAsSavedGeneratedNumberList, list, true)
      .subscribe(res => { },
        err => console.log(err),
        () => { });
  }

  private RemoveSavedGeneratedNumberBySeriesIdAndSavedNumber(seriesId: number, savedNumber: number) {
    this._apiService.get(API.series.removeBySeriesIdAndSavedNumber + seriesId + '/' + savedNumber)
      .subscribe(res => { },
        err => console.log(err),
        () => { });
  }

  //#endregion

  //#region InvoiceHeader

  private UpdateInvoiceDetailList(list: DeliveryNote[]) {
    this.commonUIService.isSpinnerVisible = true;
    this._apiService.put(API.invoice.updateInvoiceDetail, list)
      .subscribe(async (isUpdated: boolean) => {
        if (isUpdated) {
          this._toastyService.success({
            title: this._commonDataService.localizationLabelList['invoice'],
            msg: this._commonDataService.localizationLabelList['update_success']
          });
          if (this.selectedInvoiceHeader) {
            if (this.selectedInvoiceHeader.sumOfDiscount === 0
              || this.selectedInvoiceHeader.invoiceTotal === 0) {

            } else {
              this.selectedInvoiceHeader.discount = toFixedNumber(this.selectedInvoiceHeader.sumOfDiscount);
              this.selectedInvoiceHeader.subTotal = toFixedNumber(this.selectedInvoiceHeader.invoiceTotal);
              this.UpdateInvoiceHeader(this.selectedInvoiceHeader);
            }
          }
        } else {
          this._toastyService.error({
            title: this._commonDataService.localizationLabelList['invoice'],
            msg: this._commonDataService.localizationLabelList['update_error']
          });
        }
      },
        err => console.log(err),
        () => { this.commonUIService.isSpinnerVisible = false; });
  }

  private UpdateInvoiceHeader(header: InvoiceHeader) {
    this._apiService.put(API.invoice.updateInvoiceHeader, header)
      .subscribe((invoiceHeader: InvoiceHeader) => { },
        err => console.log(err),
        () => {
          this.LoadPendingDeliveryNoteListByClientId(Number(header.clientId));
          this.OnToggleView(false);
        });
  }

  private AddInvoiceHeader(invoiceHeader: InvoiceHeader, event?: Event) {
    this._apiService.post(API.invoice.addInvoiceHeader, invoiceHeader, false)
      .subscribe(res => {
        const body = JSON.parse(res['_body']);
        switch (body['_statusCode']) {
          case 200:
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['invoice'],
              msg: this._commonDataService.localizationLabelList['save_success']
            });
            const header: InvoiceHeader = body['_data'];
            if (header) {
              if (invoiceHeader.invoiceDetailList) {
                header.invoiceDetailList = invoiceHeader.invoiceDetailList;
                this.invoiceHeader = invoiceHeader;
                this.selectedInvoiceHeader = this.invoiceHeader;
                this.DoCalculations(invoiceHeader.invoiceDetailList);
              }
              if (invoiceHeader) {
                header.clientName = header.clientName ?
                  header.clientName : invoiceHeader.clientName;
              }
              this.OnSelectInvoiceHeader(header);
              this.invoiceHeaderList.unshift(header);
              this.invoiceHeaderList.splice(-1, 9);

              if (this.invoiceHeader.invoiceDetailList) {
                for (let index = 0; index < header.invoiceDetailList.length; index++) {
                  const element = header.invoiceDetailList[index];
                  element.invoiceId = header.id;
                }
                this.invoiceDeliveryNoteList = this.GetInvoiceDeliveryNoteList(header.invoiceDetailList);
                this.selectedInvoiceHeader.id = header.id;
                this.selectedInvoiceHeader.issuerId = header.issuerId;
                this.OnSaveInvoice(true);
                this.OnToggleView(false);
              }
            }

            if (this.generateNumberModel) {
              if (this.generateNumberModel.isNextAvailableNumber) {
                this.RemoveSavedGeneratedNumberBySeriesIdAndSavedNumber(
                  this.usedSeries.id, this.usedSeries.lastNumber);
                this.usedSeries.lastNumber++;
                this.UpdateSeries(this.usedSeries);
              } else {
                if (this.generateNumberModel.isAnother) {
                  this.RemoveSavedGeneratedNumberBySeriesIdAndSavedNumber(
                    this.usedSeries.id, this.generateNumberModel.anotherNumber);
                  if (this.generateNumberModel.anotherNumber > this.generateNumberModel.nextAvailableNumber) {
                    let start: number = this.generateNumberModel.nextAvailableNumber;
                    const end: number = this.generateNumberModel.anotherNumber;
                    const savedGeneratedNumberList: SavedGeneratedNumber[] = [];
                    for (start; start < end; start++) {
                      const savedGeneratedNumber = new SavedGeneratedNumber();
                      savedGeneratedNumber.seriesId = this.usedSeries.id;
                      savedGeneratedNumber.savedNumber = start;
                      if (!this.savedGeneratedNumberList.some(x => x.seriesId
                        === savedGeneratedNumber.seriesId
                        && x.savedNumber === savedGeneratedNumber.savedNumber)) {
                        savedGeneratedNumberList.push(savedGeneratedNumber);
                      }
                    }
                    if (savedGeneratedNumberList.length > 0) {
                      this.SaveAsSavedGeneratedNumberList(savedGeneratedNumberList);
                    }
                  }
                }
              }
            } break;
          case 409:
            this._toastyService.warning({
              title: this._commonDataService.localizationLabelList['invoice'],
              msg: this._commonDataService.localizationLabelList['number_is_already_in_use']
            });
            break;
          default:
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['delivery_notes'],
              msg: this._commonDataService.localizationLabelList['save_error']
            }); break;
        }
      },
        err => console.log(err),
        () => { if (event) { this.CloseModal(event); } });
  }


  private GetInvoiceDeliveryNoteList(detailList: InvoiceDetail[]) {
    try {
      let deliveryNoteList = this.GetDeliveryNoteListFromInvoiceDetailList(detailList);
      deliveryNoteList = deliveryNoteList ? deliveryNoteList : [];
      return Array.from(new Set(deliveryNoteList.map(x => x.deliveryNoteId)))
        .map(x => {
          const delNote = deliveryNoteList.find(s => s.deliveryNoteId === x);
          return new InvoiceDeliveryNoteViewModel(
            {
              deliveryNoteGenNo: delNote.deliveryNoteGenNo,
              deliveryNoteId: x,
              isChecked: this.load === 'all' || (this.load === 'single'
                && this.singleDeliveryNoteId === x) ? true : delNote.isInvoiced,
              isVisible: true,
              isInvoiced: this.load === 'all' || (this.load === 'single'
                && this.singleDeliveryNoteId === x) ? true : delNote.isInvoiced,
              isCollapsed: false,
              deliveryNoteConcept: delNote.deliveryNoteConcept,
              deliveryNoteIssuerId: delNote.deliveryNoteIssuerId,
              deliveryNoteSeriesId: delNote.deliveryNoteSeriesId,
              deliveryNoteGenDate: delNote.deliveryNoteGenDate,
              deliveryNoteGenDateObject: new Date(moment(delNote
                .deliveryNoteGenDate).format('YYYY-MM-DD')),
              deliveryNoteTotalPrice: this.GetSum(deliveryNoteList
                .filter(s => s.deliveryNoteId === x), 'finalPrice'),
              invoiceDetailList: detailList.filter(d => d.deliveryNoteId === x),
              workId: delNote.workId ? delNote.workId : null,
              invoiceId: delNote.invoiceId
            }
          );
        });
    } catch (error) { console.log(error); }
  }

  private GetDeliveryNoteListFromInvoiceDetailList(detailList: InvoiceDetail[]) {
    if (detailList) {
      const deliveryNoteList = detailList.map(x => new DisplayDeliveryNoteViewModel({
        deliveryNoteId: x.deliveryNoteId,
        deliveryNoteGenNo: x.deliveryNoteGenNo,
        deliveryNoteConcept: x.deliveryNoteConcept,
        deliveryNoteGenDate: x.deliveryNoteGenDate,
        deliveryNoteIssuerId: x.deliveryNoteIssuerId,
        deliveryNoteSeriesId: x.deliveryNoteSeriesId,
        finalPrice: toFixedNumber(x.articleFinalPrice),
        workId: x.workId ? x.workId : null,
        invoiceId: x.invoiceId,
        isInvoiced: x.isInvoiced
      }));
      return deliveryNoteList;
    }
  }

  private async RemoveInvoiceHeader(invoiceHeader: InvoiceHeader): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.delete(API.invoice.deleteInvoice, invoiceHeader.id.toString())
        .subscribe(response => resolve(response),
          err => reject(Error(err)),
          () => { });
    });
  }

  //#endregion

  //#region Automatic Billing

  GetClientsForAutomaticBilling(automaticInvoice: AutomaticInvoiceModel):
    Promise<{ id: number, name: string, isSelected: boolean }[]> {
    return new Promise<{ id: number, name: string, isSelected: boolean }[]>((resolve, reject) => {
      this._apiService.get(API.automatic_invoice.getClientsForAutomaticBilling
        + automaticInvoice.deliveryNoteIssuerId + '/'
        + automaticInvoice.deliveryNoteSeriesId + '/'
        + automaticInvoice.fromDeliveryDate
        + '/' + automaticInvoice.toDeliveryDate, true)
        .subscribe((clients: { id: number, name: string, isSelected: boolean }[]) => {
          if (clients) { clients.forEach(client => client.isSelected = false); }
          resolve(clients);
        },
          err => reject(Error(err)));
    });
  }

  async GetClientSeriesByClientId(clientId: number): Promise<ClientSeries[]> {
    return new Promise<ClientSeries[]>((resolve, reject) => {
      this._apiService.get(API.client_series.getClientSeriesByClientId + clientId)
        .subscribe((clientSeriesList: ClientSeries[]) => resolve(clientSeriesList),
          err => reject(Error(err)));
    });
  }

  private async GenerateInvoiceAutomatically(automaticInvoice: AutomaticInvoiceModel) {
    return new Promise<any>((resolve, reject) => {
      this._apiService.post(API.automatic_invoice.generateInvoiceAutomatically,
        automaticInvoice, false)
        .subscribe((res) => {
          const body: any = JSON.parse(res['_body']);
          resolve(body._data);
        },
          err => reject(Error(err)),
          () => { });
    });
  }

  //#endregion

  //#region Delivery Notes

  private LoadPendingDeliveryNoteListByClientId(clientId: number): Promise<DeliveryNote[]> {
    return new Promise((resolve, reject) => {
      this._apiService.get(API.invoice.getPendingDeliveryNotesByClientId + clientId)
        .subscribe((list: DeliveryNote[]) => {
          this.pendingDeliveryNoteList = list;
          this.pendingInvoiceDetailList =
            this.GetInvoiceDetailListFromDeliveryNoteList(this.pendingDeliveryNoteList);
          this.CalculateInvoiceDetailListValues(this.pendingInvoiceDetailList);
          resolve(list);
        },
          err => reject(err),
          () => { });
    });
  }

  private LoadPendingDeliveryNoteListById(id: number): Promise<DeliveryNote> {
    return new Promise((resolve, reject) => {
      this._apiService.get(API.main_work_type_work.getDeliveryNoteById + id)
        .subscribe((deliveryNote: DeliveryNote) => {
          if (deliveryNote) {
            const deliveryNoteList: DeliveryNote[] = [deliveryNote];
            const detailList: InvoiceDetail[] =
              this.GetInvoiceDetailListFromDeliveryNoteList(deliveryNoteList);
            if (detailList) {
              this.CalculateInvoiceDetailListValues(detailList);
              this.invoiceHeader.invoiceDetailList = detailList;
              resolve(deliveryNote);
            }
          }
        },
          err => reject(err),
          () => { });
    });
  }

  //#endregion

  //#region Events

  //#region Delete invoice

  async OnDeleteInvoice() {
    if (this.selectedInvoiceHeader) {
      await this.DeleteInvoice(this.selectedInvoiceHeader.id)
        .then(res => {
          if (res['_data']) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['invoice'],
              msg: this._commonDataService.localizationLabelList['delete_success']
            });

            const index: number = this.invoiceHeaderList
              .findIndex(i => i.id === this.selectedInvoiceHeader.id);
            if (index !== -1) { this.invoiceHeaderList.splice(index, 1); }
            this.selectedInvoiceHeader = new InvoiceHeader();
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['invoice'],
              msg: this._commonDataService.localizationLabelList['delete_error2']
            });
          }
        }).catch(er => console.log(er));
    } else { console.log('no selected invoice header found!'); }
  }

  private DeleteInvoice(invoiceid: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.delete(API.invoice.deleteInvoice, invoiceid.toString(), false)
        .subscribe(res => {
          resolve(res);
        });
    });
  }

  //#endregion

  async OnInvoicePrint() {
    try {
      this.ivaBreakdownList = [];
      this.commonUIService.isSpinnerVisible = true;
      if (this.selectedTemplate) {
        if (this.invoicedDeliveryNoteList && this.selectedInvoiceHeader) {
          for (let index = 0; index < this.invoicedDeliveryNoteList.length; index++) {
            const deliveryNote = this.invoicedDeliveryNoteList[index];
            if (deliveryNote) {
              if (this.selectedInvoiceHeader.invoiceDetailList) {
                const invDetailList = this.selectedInvoiceHeader.invoiceDetailList
                  .filter(x => x.deliveryNoteId === deliveryNote.deliveryNoteId);
                if (invDetailList) {
                  deliveryNote.totalUnitPrice = toFixedNumber(invDetailList
                    .reduce((a, b) => a + (b.articlePrice || 0), 0));
                  deliveryNote.totalQuantity = toFixedNumber(invDetailList
                    .reduce((a, b) => a + (b.articleQuantity || 0), 0));
                  deliveryNote.totalDiscount = toFixedNumber(invDetailList.reduce((a, b) => {
                    return (b.isDiscountAmount ? (a + toFixedNumber(b.articleDiscountAmount
                      * b.articleQuantityWithWaste || 0)) :
                      (a + ((this._articleCalculationService
                        .getDiscountAmount(b.articlePrice, b.articleDiscount)) * toFixedNumber(b.articleQuantityWithWaste) || 0))
                    );
                  }, 0));
                }
              }
            }
          }
          
          //#region iva breakdown
          this.selectedInvoiceHeader.invoiceDetailList.forEach(art => {
            if (art.ivaTypeId && art.ivaTypeId != 0) {
              const ivaBreakdown = new IvaBreakdown();
              ivaBreakdown.ivaTypeId = art.ivaTypeId;
              ivaBreakdown.iva = art.articleIvaPercentage + '%';

              ivaBreakdown.base = this._deliveryNoteCalculationService.getIvaBaseAmount(art.articlePriceWithDiscount, art.articleQuantityWithWaste);
              ivaBreakdown.ivaAmount = toFixedNumber(art.articleIvaAmount);
              this.ivaBreakdownList.push(ivaBreakdown);
            }
          });
          //#endregion

          if (this.ivaBreakdownList && this.ivaBreakdownList.length > 0) {
            this.ivaBreakdownList = this._invoiceService
              .LoadVatBreakdown(this.ivaBreakdownList);
          }
        }
        await Delay(300);
        this.commonUIService.PrintHtmlReport(this.selectedTemplate.description);
      }
    } catch (error) { console.log(error); } finally {
      this.commonUIService.isSpinnerVisible = false;
    }
  }

  OnToggleView(flag: boolean) { this.isEdit = flag; }

  OnAddInvoiceModalOpen(modal: string) {
    try {
      this.commonUIService.isSpinnerVisible = true;
      this.invoiceModalSubject.next();
      this.commonUIService.openModal(modal);
    } catch (error) { console.log(error); }
    finally { this.commonUIService.isSpinnerVisible = false; }
  }

  async OnSelectInvoiceHeader(invoiceHeader: InvoiceHeader) {
    try {
      this.commonUIService.isSpinnerVisible = true;
      this.isEdit = false;
      this.SetExpandViewUI();
      if (invoiceHeader) {
        const invoice = await this._invoiceService
          .GetInvoiceDetailsById(invoiceHeader.id);
        if (invoice) {
          if (invoice.client && invoice.client.townId > 0 && this.townList) {
            var town = this.townList.filter(x => x.id == invoice.client.townId);
            if (town && town.length > 0) {
              invoice.client.provinceName = town[0].provinceName
              invoice.client.postalCode = town[0].postcode
            }
          }
          this.selectedInvoiceHeader = invoice;

          this.selectedInvoiceHeader.invoiceDetailList = this.selectedInvoiceHeader.invoiceDetailList
            ? this.selectedInvoiceHeader.invoiceDetailList : [];

          // calculations
          if (this.selectedInvoiceHeader.invoiceDetailList.length > 0) {
            this.selectedInvoiceHeader.sumOfTax = toFixedNumber(this.selectedInvoiceHeader.tax);
            this.selectedInvoiceHeader.sumOfSubTotal = toFixedNumber(this.selectedInvoiceHeader.subTotal);

            if (this.selectedInvoiceHeader.discount) {
              this.selectedInvoiceHeader.sumOfDiscount = this._invoiceService
                .GetInvoiceDiscountAmount(this.selectedInvoiceHeader.sumOfSubTotal,
                  this.selectedInvoiceHeader.discount);
            } else {
              this.selectedInvoiceHeader.sumOfDiscount = 0;
            }

            this.selectedInvoiceHeader.invoiceTotal = (this.selectedInvoiceHeader.sumOfSubTotal
              - this.selectedInvoiceHeader.sumOfDiscount) + this.selectedInvoiceHeader.sumOfTax;
          }

          // invoice templates for printing
          const selectedCdt = await this._invoiceService
            .GetSavedTemplateByClientId(Number(this.selectedInvoiceHeader.clientId));
          if (selectedCdt) {
            this.selectedTemplate = this.reportTemplateList
              .find(rt => rt.value === selectedCdt.reportConfigValue);
            if (!this.selectedTemplate) {
              this.selectedTemplate = this.reportTemplateList[0];
            }
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this.OnSelectedInvoiceHeaderChanged();
      this.commonUIService.isSpinnerVisible = false;
    }
  }

  private SetExpandViewUI() {
    this.commonUIService.isShowLoyoutMenu = true;
    this.commonUIService.isShowStatMenu = true;
    this.commonUIService.isShowExpandView = true;
  }

  private GetSum(arr: any[], key): any {
    return arr.reduce((a, b) => a + (b[key] || 0), 0);
  }

  OnSelectedInvoiceHeaderChanged() {
    if (this.selectedInvoiceHeader.invoiceDetailList) {
      this.ivaBreakdownList = [];
      const workList: any[] = this.selectedInvoiceHeader.invoiceDetailList
        .map((x, i) => ({ workName: x.workName, workId: x.workId }));
      this.relatedWorkList = Array.from(new Set(workList.map(x => x.workId)))
        // tslint:disable-next-line: triple-equals
        .map((x, i) => ({ workName: workList.find(s => s.workId == x).workName, workId: x }));
      const deliveryNoteList: any[] = this.selectedInvoiceHeader
        .invoiceDetailList.map((x, i) => (
          {
            deliveryNoteId: x.deliveryNoteId,
            deliveryNoteGenNo: x.deliveryNoteGenNo,
            deliveryNoteConcept: x.deliveryNoteConcept,
            deliveryNoteGenDate: x.deliveryNoteGenDate,
            deliveryNoteIssuerId: x.deliveryNoteIssuerId,
            deliveryNoteSeriesId: x.deliveryNoteSeriesId,
            finalPrice: toFixedNumber(x.articleFinalPrice),
            deliveryNoteTax: x.deliveryNoteTax,
            deliveryNoteTotal: x.deliveryNoteTotal,
            mainWorkTypeList: x.mainWorkTypeList
          }
        ));
      this.invoicedDeliveryNoteList = Array
        .from(new Set(deliveryNoteList.map(x => x.deliveryNoteId)))
        .map((x) => (
          {
            // tslint:disable-next-line: triple-equals
            deliveryNoteGenNo: deliveryNoteList
              .find(s => s.deliveryNoteId == x).deliveryNoteGenNo,
            deliveryNoteId: x,
            isChecked: true,
            isVisible: false,
            // tslint:disable-next-line: triple-equals
            deliveryNoteConcept: deliveryNoteList
              .find(s => s.deliveryNoteId == x).deliveryNoteConcept,
            // tslint:disable-next-line: triple-equals
            deliveryNoteIssuerId: deliveryNoteList
              .find(s => s.deliveryNoteId == x).deliveryNoteIssuerId,
            // tslint:disable-next-line: triple-equals
            deliveryNoteSeriesId: deliveryNoteList
              .find(s => s.deliveryNoteId == x).deliveryNoteSeriesId,
            // tslint:disable-next-line: triple-equals
            deliveryNoteGenDate: deliveryNoteList
              .find(s => s.deliveryNoteId == x).deliveryNoteGenDate,
            deliveryNoteGenDateObject:
              // tslint:disable-next-line: triple-equals
              new Date(deliveryNoteList.find(s => s.deliveryNoteId == x)
                .deliveryNoteGenDate.toString().substring(0, 10)),
            // tslint:disable-next-line: triple-equals
            //deliveryNoteTotalPrice: this.GetSum(deliveryNoteList.filter(s => s.deliveryNoteId == x), 'finalPrice'),
            deliveryNoteTotalPrice: deliveryNoteList
              .find((s) => s.deliveryNoteId == x).deliveryNoteTotal,
            deliveryNoteTax: deliveryNoteList
              .find((s) => s.deliveryNoteId == x).deliveryNoteTax,
            deliveryNoteSubTotalPrice: (deliveryNoteList
              .find((s) => s.deliveryNoteId == x).deliveryNoteTotal)
              - (deliveryNoteList.find((s) => s.deliveryNoteId == x)
                .deliveryNoteTax != null ? deliveryNoteList.find((s) =>
                  s.deliveryNoteId == x).deliveryNoteTax : 0),
            totalUnitPrice: 0,
            totalQuantity: 0,
            totalDiscount: 0,
            mainWorkTypeList: deliveryNoteList
              .find((s) => s.deliveryNoteId == x).mainWorkTypeList,
          }
        ));
      this.DoCalculations(this.selectedInvoiceHeader.invoiceDetailList);

      //#region iva breakdown
      this.selectedInvoiceHeader.invoiceDetailList.forEach(art => {
        if (art.ivaTypeId && art.ivaTypeId != 0) {
          const ivaBreakdown = new IvaBreakdown();
          ivaBreakdown.ivaTypeId = art.ivaTypeId;
          ivaBreakdown.iva = art.articleIvaPercentage + '%';

          ivaBreakdown.base = this._deliveryNoteCalculationService.getIvaBaseAmount(art.articlePriceWithDiscount, art.articleQuantityWithWaste);
          ivaBreakdown.ivaAmount = toFixedNumber(art.articleIvaAmount);
          this.ivaBreakdownList.push(ivaBreakdown);
        }
      });
      //#endregion

      if (this.ivaBreakdownList && this.ivaBreakdownList.length > 0) {
        this.ivaBreakdownList = this._invoiceService
          .LoadVatBreakdown(this.ivaBreakdownList);
      }
    }
  }

  OnInvoiceDetailListToBeSavedChanged(list: InvoiceDetail[]) {
    if (list) { this.DoCalculations(list); }
  }

  private DoCalculations(list: InvoiceDetail[]) {
    this.isSaveButtonEnabled = list.length > 0;
    this.CalculateInvoiceDetailListValues(list);

    this.selectedInvoiceHeader.invoiceTotal = this.selectedInvoiceHeader.invoiceTotal ?
      toFixedNumber(this.selectedInvoiceHeader.invoiceTotal)
      : toFixedNumber(this.selectedInvoiceHeader.subTotal);

    this.selectedInvoiceHeader.sumOfSubTotal = toFixedNumber(this.selectedInvoiceHeader.subTotal);
    this.selectedInvoiceHeader.sumOfDiscount =
      this._articleCalculationService
        .getDiscountAmount(this.selectedInvoiceHeader.sumOfSubTotal,
          toFixedNumber(this.selectedInvoiceHeader.discount));
    this.selectedInvoiceHeader.sumOfTax = this.selectedInvoiceHeader.tax ?
      toFixedNumber(this.selectedInvoiceHeader.tax) : 0;
    this.selectedInvoiceHeader.invoiceTotal = toFixedNumber(this.selectedInvoiceHeader.sumOfSubTotal -
      this.selectedInvoiceHeader.sumOfDiscount + this.selectedInvoiceHeader.sumOfTax);
  }

  OnSaveInvoiceDetailList() {
    if (this.selectedInvoiceHeader.invoiceDetailList) {
      // tslint:disable-next-line: triple-equals
      const deliveryNoteList: any[] = [...this.invoicedDeliveryNoteList
        .filter(x => x.isChecked === true)];
      if (deliveryNoteList) {
        const detailList: InvoiceDetail[] = [];
        deliveryNoteList.forEach(deliveryNote => {
          const arr: InvoiceDetail[] =
            this.selectedInvoiceHeader.invoiceDetailList
              .filter(x => x.deliveryNoteId === Number(deliveryNote.deliveryNoteId));
          detailList.push(...arr);
        });
        detailList.forEach(item => {
          item.isInvoiced = true;
          item.invoiceId = this.selectedInvoiceHeader.id;
        });
        if (detailList) {
          const tempList: any[] = detailList.map(x => ({
            deliveryNoteId: x.deliveryNoteId,
            deliveryNoteGenNo: x.deliveryNoteGenNo,
            isInvoiced: x.isInvoiced,
            invoiceId: x.invoiceId,
            workId: x.workId
          }));
          const deliveryNoteItemList: any[] = Array
            .from(new Set(tempList.map(x => x.deliveryNoteId)))
            .map(x => ({
              deliveryNoteId: x,
              // tslint:disable-next-line: triple-equals
              deliveryNoteGenNo: tempList.find(s => s.deliveryNoteId == x).deliveryNoteGenNo,
              // tslint:disable-next-line: triple-equals
              isInvoiced: tempList.find(s => s.deliveryNoteId == x).isInvoiced,
              // tslint:disable-next-line: triple-equals
              workId: tempList.find(s => s.deliveryNoteId == x).workId,
              // tslint:disable-next-line: triple-equals
              invoiceId: tempList.find(s => s.deliveryNoteId == x).invoiceId
            }));
          if (deliveryNoteItemList.length > 0) {
            // tslint:disable-next-line: no-shadowed-variable
            const deliveryNoteList: DeliveryNote[] = [];
            deliveryNoteItemList.forEach(item => {
              const deliveryNote: DeliveryNote = new DeliveryNote();
              deliveryNote.id = item.deliveryNoteId;
              deliveryNote.isInvoiced = item.isInvoiced;
              deliveryNote.workId = item.workId;
              deliveryNote.generateNo = item.deliveryNoteGenNo;
              deliveryNote.invoiceId = item.invoiceId;
              deliveryNoteList.push(deliveryNote);
            });
            this.UpdateInvoiceDetailList(deliveryNoteList);
          }
        }
      }
    }
  }

  OnPendingInvoiceDetailListChanged(pendingList: InvoiceDetail[]) {
    this.pendingInvoiceDetailList = pendingList;
  }

  OnCancelEditInvoice() {
    const invoiceHeader: InvoiceHeader = this.invoiceHeaderList
      .find(x => x.id === this.selectedInvoiceHeader.id);
    const isAnyInvoiced = this.selectedInvoiceHeader
      .invoiceDetailList.findIndex(i => i.isInvoiced) > -1;
    if (invoiceHeader) {
      if (isAnyInvoiced) {
        this.OnSelectInvoiceHeader(invoiceHeader);
        this.OnToggleView(false);
      } else {
        this.RemoveInvoiceHeader(invoiceHeader)
          .then(response => {
            if (response['_data']) {
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList['invoice'],
                msg: this._commonDataService.localizationLabelList['save_success']
              });
            } else {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['invoice'],
                msg: this._commonDataService.localizationLabelList['save_error']
              });
            }
            this.selectedInvoiceHeader = new InvoiceHeader();
            this.refresh = true;
            this.OnToggleView(true);
          });
      }
    }
  }

  async OnInvoiceSaved(invoice: InvoiceHeader) {
    if (invoice) {
      this.invoiceHeaderList.unshift(invoice);
      await this.OnSelectInvoiceHeader(invoice);
    }
  }

  async OnSelectDeleteInvoiceSaved(invoice: InvoiceHeader) {
    if (invoice) {
      const index: number = this.invoiceHeaderList
        .findIndex(i => i.id === invoice.id);
      if (index !== -1) {
        this.invoiceHeaderList.splice(index, 1);
      }
    }
  }

  OnProceedNewInvoice(event: Event) {
    if (this.invoiceFormGroup.invalid) {
      ShowInvalidFormControls(this.invoiceFormGroup); return;
    }
    if (this.invoiceHeader) {
      this.InitNewInvoice();
      this.AddInvoiceHeader(this.invoiceHeader, event);
    }
  }

  OnSelectIssuer() {
    if (this.invoiceHeader.issuerId && this.invoiceHeader.issuerId !== '0') {
      this._seriesList = [];
      this.serieDropDownList = [];
      this.seriesDropDownList = [];
      this.LoadSeriesList().then(response => {
        let seriesList = this._seriesList
          .filter(x => x.issuerId === Number(this.invoiceHeader.issuerId) &&
            x.seriesType.toLowerCase() === 'bill' &&
            x.year === new Date().getFullYear().toString() && x.status);
        seriesList = seriesList ? seriesList : [];
        if (seriesList.length > 0) {
          this.serieDropDownList = this.GetDropDownList(seriesList, 'series');
          this.serieDropDownList = CommonFunctions.Sort(this.serieDropDownList, 'label');
          this.seriesDropDownList = this.serieDropDownList;
        } else {
          this._toastyService.warning({
            title: this._commonDataService.localizationLabelList['invoice'],
            msg: this._commonDataService.localizationLabelList['warning_issuer_series'],
          });
        }
      });
    }
    if (this.seriesDropDownList.length === 0) {
      this.seriesDropDownList.push(new DropDownOption('0',
        this._commonDataService.localizationLabelList['select'], false));
    }
    this.invoiceHeader.seriesId = '0';
  }

  async OnOpenGenerateNumberModal(modal?: string) {
    try {
      this.commonUIService.isSpinnerVisible = true;
      if (this.invoiceHeader.seriesId) {
        const series = await this._workService
          .GetSeriesById(Number(this.invoiceHeader.seriesId));
        await this.LoadIvNumberingProcessComponent(series);
      }
    } catch (error) { console.log(error); } finally {
      this.commonUIService.openModal(modal);
      this.commonUIService.isSpinnerVisible = false;
    }
  }

  private async LoadIvNumberingProcessComponent(series: Series) {
    try {
      if (series) {
        this.ivSeries = { id: series.id, type: 'Bill', series: series.series };
        this.ivNumberingProcess = new NumberingProcess({
          isNextAvailableNumber: true,
          nextAvailableNumber: series.lastNumber,
          isAnother: false,
          isNoNumberAssigned: false,
          isOtherAvailableNumbers: false,
          anotherNumber: 1,
          otherAvailableNumber: 0
        });
        const reservedNumberList = await this._workService
          .GetSavedNumberListBySeriesId(series.id);
        if (reservedNumberList) {
          this.ivNumberingProcess.otherAvailableNumberList
            = reservedNumberList.map(r => r.savedNumber.toString());
        }
      }
    } catch (error) { console.log(error); }
  }

  async OnSelectSeries() {
    try {
      this.commonUIService.isSpinnerVisible = true;
      if (Number(this.invoiceHeader.seriesId)) {
        const series = await this._workService.GetSeriesById(this.invoiceHeader.seriesId);
        if (series) {
          await this.LoadIvNumberingProcessComponent(series);
          const genNumberObject = this._numberingProcessService
            .GetGeneratedNumber(this.ivNumberingProcess, this.ivMaxSize,
              { id: series.id, type: 'Bill', series: series.series });
          if (genNumberObject) {
            this.invoiceHeader.generateNumber = genNumberObject.generateNumber;
          }
        }
      } else { this.invoiceHeader.generateNumber = ''; }
    } catch (error) { console.log(error); } finally {
      this.commonUIService.isSpinnerVisible = false;
    }
  }

  /* automatic billing */

  OnAddAutomaticInvoiceModalOpen(modal: string) {
    this.automaticInvoiceFormGroup.reset();
    this.invoiceHeader = new InvoiceHeader();
    this.selectedInvoiceHeader = new InvoiceHeader();
    this.pendingInvoiceDetailList = [];
    this.invoicedDeliveryNoteList = [];
    this.pendingDeliveryNoteList = [];
    this.invoiceHeader.clientId = '0';
    this.invoiceHeader.issuerId = '0';
    this.invoiceHeader.seriesId = '0';
    this.LoadIssuerList();
    this.automaticInvoice = new AutomaticInvoiceModel();
    this.automaticInvoice.automaticInvoiceIssuerId = '0';
    this.automaticInvoice.automaticInvoiceSeiriesId = '0';
    this.automaticInvoice.deliveryNoteIssuerId = '0';
    this.automaticInvoice.deliveryNoteSeriesId = '0';
    this.automaticInvoice.defaultOrSpecificIssuerAndSeries = 'default_type';
    this.DisableAutomaticInvoiceFormSection();
    this.automaticInvoice.automaticInvoiceDateObject =
      this.commonUIService.ConvertToPickerDateFormat(
        new Date().toISOString().substring(0, 10));
    this.commonUIService.openModal(modal);
  }

  OnSelectDeliveryNoteIssuer(id) {
    if (this.invoiceHeader.seriesId) {
      this.deliveryNoteSeriesDropDownList = [];
      this.DisableAutomaticInvoiceFormSection();
      this.LoadSeriesList().then(response => {
        const seriesList = this._seriesList
          .filter(x => x.seriesType === 'Delivery Note' && x.issuerId ===
            Number(this.automaticInvoice.deliveryNoteIssuerId) && x.status
            && x.year === (new Date()).getFullYear().toString());
        if (seriesList) {
          this.deliveryNoteSeriesDropDownList = this.GetDropDownList(seriesList, 'series');
          if (seriesList != null && seriesList.length > 0) {
            this.automaticInvoice.deliveryNoteSeriesId =
              this.deliveryNoteSeriesDropDownList[1].value.toString();
          } else {
            this.automaticInvoice.deliveryNoteSeriesId = '0';
          }
        } else {
          this.automaticInvoice.deliveryNoteSeriesId = '0';
        }
      });
    }
  }

  OnSelectInvoiceIssuer() {
    this._seriesList = [];
    this.serieDropDownList = [];
    if (this.automaticInvoice.automaticInvoiceIssuerId) {
      this.LoadSeriesList().then((response) => {
        const seriesList = this._seriesList.filter(x =>
          x.seriesType.toLowerCase() === 'bill' && x.status
          && x.year === new Date().getFullYear().toString() && Number(x.issuerId) ===
          Number(this.automaticInvoice.automaticInvoiceIssuerId));
        if (seriesList) {
          this.invoiceSeriesDropDownList = this.GetDropDownList(seriesList, 'series');
          if (this.invoiceSeriesDropDownList != null && this.invoiceSeriesDropDownList.length > 0) {
            this.automaticInvoice.automaticInvoiceSeiriesId =
              this.invoiceSeriesDropDownList[1].value.toString();
          } else {
            this.automaticInvoice.automaticInvoiceSeiriesId = '0';
          }
        } else {
          this._toastyService.warning({
            title: this._commonDataService.localizationLabelList['invoice'],
            msg: this._commonDataService.localizationLabelList['warning_issuer_series'],
          });
        }
      });
    }
  }

  OnToggleEachClientCheckBox(flag: boolean, client?: any) {
    if (flag) { this.checkedClientCount++; } else { this.checkedClientCount--; }
    client.isSelected = flag;
    this.selectedClientList =
      this.automaticInvoice.automaticInvoiceClientList ?
        this.automaticInvoice.automaticInvoiceClientList.filter(x => x.isSelected) : [];
  }

  OnChangeDefaultOrSpecificIssuerAndSeriesRadio() {
    if (this.automaticInvoice.defaultOrSpecificIssuerAndSeries) {
      if (this.automaticInvoice.defaultOrSpecificIssuerAndSeries === 'default_type') {
        if (this.selectedClientList) {
          this.selectedClientList.forEach(client => {
            this.GetClientSeriesByClientId(client.id)
              .then(list => {
                const billingClientSeries: ClientSeries = list
                  .find(x => x.seriesType.toLowerCase() === 'bill');
                if (billingClientSeries) {
                  this.automaticInvoice.automaticInvoiceSeiriesId = billingClientSeries.seriesId;
                  this.automaticInvoice.automaticInvoiceIssuerId = billingClientSeries.issuerId;
                } else {
                  this._toastyService.warning({
                    title: this._commonDataService.localizationLabelList['invoice'],
                    msg: this._commonDataService.localizationLabelList['warning_issuer_series'],
                  });
                }
              });
          });
        }
      }
    }
  }

  OnLoadClients() {
    if (this.automaticInvoiceFormGroup.invalid) {
      ShowInvalidFormControls(this.automaticInvoiceFormGroup); return;
    }
    if (this.automaticInvoice) {
      this.automaticInvoice.fromDeliveryDate = this.automaticInvoice.fromDeliveryDateObject != null ?
        this.commonUIService.ConvertDateFormatToString(this.automaticInvoice.fromDeliveryDateObject) : null;
      this.automaticInvoice.toDeliveryDate = this.automaticInvoice.toDeliveryDateObject != null ?
        this.commonUIService.ConvertDateFormatToString(this.automaticInvoice.toDeliveryDateObject) : null;
      this.automaticInvoice.automaticInvoiceIssuerId = this.automaticInvoice.automaticInvoiceIssuerId !== '0' ?
        this.automaticInvoice.automaticInvoiceIssuerId : null;
      this.automaticInvoice.automaticInvoiceSeiriesId = this.automaticInvoice.automaticInvoiceSeiriesId !== '0' ?
        this.automaticInvoice.automaticInvoiceSeiriesId : null;

      this.GetClientsForAutomaticBilling(this.automaticInvoice)
        .then(clients => {
          this.checkedClientCount = 0;
          this.DisableAutomaticInvoiceFormSection();
          if (clients) {
            if (clients.length > 0) {
              this.automaticInvoice.automaticInvoiceClientList = clients;
            } else {
              this._toastyService.warning({
                title: this._commonDataService.localizationLabelList['invoice'],
                msg: 'No clients found!'
              });
              this.automaticInvoice.automaticInvoiceClientList = [];
            }
          } else {
            this._toastyService.warning({
              title: this._commonDataService.localizationLabelList['invoice'],
              msg: 'No clients found!'
            });
            this.automaticInvoice.automaticInvoiceClientList = [];
          }
        });
    }
  }

  OnProceedAutomaticInvoice(event: any) {
    if (this.automaticInvoice) {
      this.commonUIService.isSpinnerVisible = true;
      this.automaticInvoice.automaticInvoiceClientList = this.automaticInvoice.automaticInvoiceClientList
        ? this.automaticInvoice.automaticInvoiceClientList.filter(x => x.isSelected) : [];
      this.automaticInvoice.automaticInvoiceDate = this.automaticInvoice.automaticInvoiceDateObject ?
        this.commonUIService.ChangeNgbDateToCustomFormat(this.automaticInvoice.automaticInvoiceDateObject) : null;
      this.automaticInvoice.userId = this.user.id;
      if (this.automaticInvoice.automaticInvoiceClientList) {
        this.automaticInvoice.clientIdList = this.automaticInvoice.automaticInvoiceClientList.map(x => x.id);
        this.GenerateInvoiceAutomatically(this.automaticInvoice)
          .then((result: { dtoList: InvoiceHeader[], message: string }) => {
            if (result) {
              if (result.dtoList.length > 0) {
                result.dtoList.forEach(invoiceheader =>
                  this.invoiceHeaderList.unshift(invoiceheader));
                // set client name
                const _client = this.automaticInvoice.automaticInvoiceClientList
                  .find(i => i.id === Number(this.invoiceHeaderList[0].clientId));
                if (_client) { this.invoiceHeaderList[0].clientName = _client.name; }
                this.OnSelectInvoiceHeader(this.invoiceHeaderList[0]);
                this._toastyService.success({
                  title: this._commonDataService.localizationLabelList['invoice'],
                  msg: this._commonDataService.localizationLabelList['automatic_invoices_saved']
                });
                if (result.message === 'no_default_series') {
                  this._toastyService.error({
                    title: this._commonDataService.localizationLabelList['invoice'],
                    msg: this._commonDataService.localizationLabelList['automatic_no_series_found_some']
                  });
                }
              } else {
                if (result.message === 'no_default_series') {
                  this._toastyService.error({
                    title: this._commonDataService.localizationLabelList['invoice'],
                    msg: this._commonDataService.localizationLabelList['automatic_no_series_found']
                  });
                } else {
                  this._toastyService.error({
                    title: this._commonDataService.localizationLabelList['invoice'],
                    msg: this._commonDataService.localizationLabelList['automatic_no_invoices_saved']
                  });
                }
              }
            } else {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['invoice'],
                msg: this._commonDataService.localizationLabelList['save_error']
              });
            }
            if (event) { this.CloseModal(event); }
            this.commonUIService.isSpinnerVisible = false;
          });
      }
    }
  }

  OnKey(event: any, target: any) {
    target.className = 'ng-cus-focus';
    target.focus();
  }

  async OnChangeClient(clientItemList: { display: string, value: number, id: number }[]) {
    if (clientItemList) {
      if (clientItemList.length > 0) {
        this._seriesList = [];
        this.serieDropDownList = [];
        this.invoiceHeader.clientId = clientItemList[0] !== undefined ? Number(clientItemList[0].value) : null;
        this.invoiceHeader.clientName = clientItemList[0] !== undefined ? clientItemList[0].display : '';
        if (this.invoiceHeader.clientId) {
          const list = await this.GetSeriesByClientId(this.invoiceHeader.clientId);
          if (list) {
            const clientSeries = list.find(x => x.seriesType.toLowerCase() === 'bill');
            if (clientSeries) {
              this.invoiceHeader.issuerId = clientSeries.issuerId ? clientSeries.issuerId.toString() : '0';
              this.LoadSeriesList().then(() => {
                const seriesList = this._seriesList.filter(x => x.seriesType.toLowerCase() === 'bill'
                  && x.year === new Date().getFullYear().toString() && x.status);
                if (seriesList) {
                  this.serieDropDownList = this.GetDropDownList(seriesList, 'series');
                  this.seriesDropDownList = this.serieDropDownList;
                  this.invoiceHeader.seriesId = clientSeries.seriesId ? clientSeries.seriesId.toString() : '0';
                  this.OnSelectSeries();
                }
              });
            } else {
              this.invoiceHeader.issuerId = '0';
              this.invoiceHeader.seriesId = '0';
              this.invoiceHeader.generateNumber = '';
              this.LoadSeriesList();
            }
          }
        }
      } else {
        this.invoiceHeader.issuerId = '0';
        this.invoiceHeader.seriesId = '0';
        this.invoiceHeader.generateNumber = '';
        this.LoadSeriesList();
      }
    }
  }

  async OnIvNumberGenerated(data: NumberingProcessData) {
    try {
      this.commonUIService.isSpinnerVisible = true;
      if (data) {
        if (data.generateNumber === 'same_number') {
          this._toastyService.error({
            title: this._commonDataService.localizationLabelList['invoice'],
            msg: this._commonDataService.localizationLabelList['another_no_is_equal_to_next_no']
          });
        } else {
          const isEligible = await this._numberingProcessService.CheckGenerateNumber(data);
          if (isEligible) {
            if (data.genNumber > this.ivNumberingProcess.nextAvailableNumber) {
              swal({
                title: this._commonDataService.localizationLabelList['invoice'],
                text: 'There are ' + (data.genNumber - this.ivNumberingProcess.nextAvailableNumber) +
                  ' numbers will be left blank. Are you sure want to continue?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
                cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
              }).then(async (result) => {
                if (result.value) {
                  this.invoiceHeader.generateNumber = data.generateNumber;
                  this.currentNPData = data;
                } else { this.commonUIService.openModal('invoice-numbering-process'); }
              });
            } else {
              this.invoiceHeader.generateNumber = data.generateNumber;
              this.currentNPData = data;
            }
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['invoice'],
              msg: this._commonDataService.localizationLabelList['invalid_gen_number_or_existing']
            });
            this.commonUIService.openModal('invoice-numbering-process');
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this.commonUIService.isSpinnerVisible = false;
    }
  }

  OnSelectTemplate(template: any) {
    if (template) {
      this.selectedTemplate.value = template.value;
      this.selectedTemplate.description = template.display;
    }
  }

  /* eof automatic billing */

  //#endregion

  //#region Year

  private async YearList(): Promise<any[]> {
    return this.yearList = [
      { id: 2019, text: '2019' },
      { id: 2020, text: '2020' },
      { id: 2021, text: '2021' },
      { id: 2022, text: '2022' },
      { id: 2023, text: '2023' },
      { id: 2024, text: '2024' },
      { id: 2025, text: '2025' }
    ];
  }

  //#endregion

  //#region Serie

  GetInvoiceSeriesList() {
    return this._apiService.get(API.series.getSeriesList)
      .map(list => list.filter((s: Series) => s.seriesType.toLowerCase() === 'bill' && s.status
        && s.year === new Date().getFullYear().toString()) as Series[]).toPromise();
  }

  async FilterSeries(issuerId: number) {
    try {
      if (Number(issuerId)) {
        this.seriesList = [];
        this._apiService.get(API.invoice.getSeriesByIssuerId + issuerId)
          .subscribe((list: Series[]) => {
            list = list ? list : [];
            this.seriesList = list.filter(x => x.seriesType.toLowerCase() === 'bill' && x.status
              && x.year === (this.searchYear && this.searchYear !== '0' ?
                this.searchYear : new Date().getFullYear().toString()));
            if (this.seriesList != null) {
              this.PopulateSeries(this.seriesList);
            }
          }, err => { console.log(err + 'series load error'); });
      } else {
        if (this.serieDropDownList) {
          if (this.serieDropDownList.length === 0) {
            this.seriesList = await this.GetInvoiceSeriesList();
            this.seriesList = this.seriesList ? this.seriesList : [];
            this.seriesList = this.seriesList
              .filter(x => x.year === (this.searchYear && this.searchYear !== '0'
                ? this.searchYear : new Date().getFullYear().toString())
                && x.status);
            this.PopulateSeries(this.seriesList);
          }
        } else {
          this.seriesList = await this.GetInvoiceSeriesList();
          this.seriesList = this.seriesList ? this.seriesList : [];
          this.seriesList = this.seriesList
            .filter(x => x.year === (this.searchYear && this.searchYear !== '0'
              ? this.searchYear : new Date().getFullYear().toString())
              && x.status);
          this.PopulateSeries(this.seriesList);
        }
      }
      this.searchSerie = this.searchSerie === '0' ? '0' : this.searchSerie;
    } catch (error) { console.log(error); } finally {
      this.serieDropDownList = CommonFunctions.Sort(this.serieDropDownList, 'label');
    }
  }

  private PopulateSeries(list: Series[]) {
    if (list) {
      this.serieDropDownList = [];
      this.serieDropDownList.push(new DropDownOption('0',
        this._commonDataService.localizationLabelList['select'], false));
      list.forEach((element) => {
        const option = new DropDownOption(element.series, element.series, false);
        this.serieDropDownList.push(option);
      });
    }
  }
  //#endregion

  async LoadLabConfigurationList() {
    this.imageData = await this.commonUIService.LoadLabConfigurationList();
  }

  OnChangeInvoiceDeliveryNoteList(args: {
    list: InvoiceDeliveryNoteViewModel[],
    invoiceHeader: InvoiceHeader
  }) {
    try {
      if (args) {
        this.invoiceDeliveryNoteList = args.list;
        this.saveDisabled = args.list.length === 0;
        this.selectedInvoiceHeader = args.invoiceHeader;
      }
    } catch (error) { console.log(error); }
  }

  async OnSaveInvoice(loadView = true) {
    try {
      this._invoiceService.isSaving = true;
      this.commonUIService.isSpinnerVisible = true;
      if (this.invoiceDeliveryNoteList.length > 0) {
        let list = this.invoiceDeliveryNoteList.map(item => new DeliveryNote({
          id: item.deliveryNoteId,
          isInvoiced: item.isInvoiced,
          workId: item.workId,
          generateNo: item.deliveryNoteGenNo,
          invoiceId: this.selectedInvoiceHeader.id
        }));
        if (list) {
          list = list ? list : [];
          const isUpdated = await this._deliveryNoteService.UpdateInvoiceDetailList(list);
          if (isUpdated) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['invoice'],
              msg: this._commonDataService.localizationLabelList['update_success']
            });
            if (this.selectedInvoiceHeader) {
              const invoiceUpdateResponse = await this._invoiceService
                .UpdateInvoiceHeader(this.selectedInvoiceHeader);
              if (invoiceUpdateResponse && loadView) {
                this.OnSelectInvoiceHeader(this.selectedInvoiceHeader);
              }
            }
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['invoice'],
              msg: this._commonDataService.localizationLabelList['update_error']
            });
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this.commonUIService.isSpinnerVisible = false;
    }
  }

  OnCancellationPopupClose(args: { isSave: boolean, duplicate?: InvoiceHeader }) {
    if (args.isSave) {
      this.refresh = true;
      this.OnSelectInvoiceHeader(this.selectedInvoiceHeader);
      if (args.duplicate) {
        const index = this.invoiceHeaderList.findIndex(ih => ih.id === this.selectedInvoiceHeader.id);
        this.invoiceHeaderList[index + 1] = args.duplicate;
      }
    }
    this.showCancellationPopup = false;
  }

  //#region send email

  /**
   * get fired when open email compose popup load
   * */
  async OnSendEmailModalOpen(invoice: InvoiceHeader) {
    try {
      this.ivaBreakdownList = [];
      this.emailmodel = new SendEmail();
      const user = this._commonDataService.GetUserFromSession();
      this.commonUIService.isSpinnerVisible = true;
      if (this.selectedTemplate) {
        if (invoice.billedToPatient && invoice.patientId && invoice.patient) {
          this.emailmodel.receiverEmail = invoice.patient.email != null ? invoice.patient.email : null;
        } else if (!invoice.billedToPatient && invoice.clientId && invoice.client) {
          this.emailmodel.receiverEmail = invoice.client.email != null ? invoice.client.email : null;
        }
        if (this.invoicedDeliveryNoteList && this.selectedInvoiceHeader) {
          for (let index = 0; index < this.invoicedDeliveryNoteList.length; index++) {
            const deliveryNote = this.invoicedDeliveryNoteList[index];
            if (deliveryNote) {
              if (this.selectedInvoiceHeader.invoiceDetailList) {
                const invDetailList = this.selectedInvoiceHeader.invoiceDetailList
                  .filter(x => x.deliveryNoteId === deliveryNote.deliveryNoteId);
                if (invDetailList) {
                  deliveryNote.totalUnitPrice = toFixedNumber(invDetailList
                    .reduce((a, b) => a + (b.articlePrice || 0), 0));
                  deliveryNote.totalQuantity = toFixedNumber(invDetailList
                    .reduce((a, b) => a + (b.articleQuantity || 0), 0));
                  deliveryNote.totalDiscount = invDetailList.reduce((a, b) => {
                    return (b.isDiscountAmount ? toFixedNumber(a + (b.articleDiscountAmount
                      * b.articleQuantityWithWaste || 0)) :
                      (a + (this._articleCalculationService
                        .getDiscountAmount(b.articlePrice, b.articleDiscount)
                        * b.articleQuantityWithWaste || 0))
                    );
                  }, 0);
                }
                //#region iva breakdown
                this.selectedInvoiceHeader.invoiceDetailList.forEach(art => {
                  if (art.ivaTypeId && art.ivaTypeId != 0) {
                    const ivaBreakdown = new IvaBreakdown();
                    ivaBreakdown.ivaTypeId = art.ivaTypeId;
                    ivaBreakdown.iva = art.articleIvaPercentage + '%';

                    ivaBreakdown.base = toFixedNumber(art.articleFinalPrice
                      - art.articleIvaAmount);
                    ivaBreakdown.ivaAmount = toFixedNumber(art.articleIvaAmount);
                    this.ivaBreakdownList.push(ivaBreakdown);
                  }
                });
                //#endregion

                if (this.ivaBreakdownList && this.ivaBreakdownList.length > 0) {
                  this.ivaBreakdownList = this._invoiceService
                    .LoadVatBreakdown(this.ivaBreakdownList);
                }
              }
            }
          }
        }

        this.emailmodel.senderEmail = user != null && user.userRoleEmail != null ?
          user.userRoleEmail : null;
        this.emailmodel.password = user != null && user.userRolePassword != null ?
          user.userRolePassword : null;
        this.emailmodel.subject = this._commonDataService.localizationLabelList['invoice']
          + ' - (' + invoice.generateNumber + ')';
        this.emailmodel.message = this._commonDataService.localizationLabelList['invoice']
          + ' - (' + invoice.generateNumber + ')';
        this.emailmodel.attachmentName = this._commonDataService.localizationLabelList['invoice']
          + ' - (' + invoice.generateNumber + ')' +
          '.pdf';

        if (!this.emailmodel.senderEmail && !this.emailmodel.receiverEmail) {
          return this._toastyService.warning({
            title: this._commonDataService.localizationLabelList['email'],
            msg: this._commonDataService.localizationLabelList['sender_recipient_missing'],
          });
        } else if (!this.emailmodel.senderEmail) {
          return this._toastyService.warning({
            title: this._commonDataService.localizationLabelList['email'],
            msg: this._commonDataService.localizationLabelList['sender_missing'],
          });
        } else if (!this.emailmodel.receiverEmail) {
          swal({
            title: this._commonDataService.localizationLabelList['email'],
            text: this._commonDataService.localizationLabelList['recipient_missing'],
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
            cancelButtonText: this._commonDataService.localizationLabelList['common_cancel'],
          }).then((result) => {
            if (result.value) {
              this.commonUIService.OpenBasicModal('send-email');
            } else { return; }
          });
        } else {
          this.commonUIService.OpenBasicModal('send-email');
        }
      }
    } catch (error) { console.log(error); } finally {
      this.commonUIService.isSpinnerVisible = false;
    }
  }

  /**
   * get fired when open email compose popup close
   * */
  OnSendEmailModalClose() {
    this.commonUIService.CloseBasicModal('send-email');
  }

  /**
   * get fired when sending the mail
   * */
  async OnSendEmail() {
    if (!this.emailmodel.receiverEmail) {
      return this._toastyService.error({
        title: this._commonDataService.localizationLabelList['email'],
        msg: this._commonDataService.localizationLabelList['recipient_missing'],
      });
    } else {
      this.OnSendEmailModalClose();
      this.commonUIService.isSpinnerVisible = true;
      if (this.selectedInvoiceHeader) {
        await Delay(300);
        const base64String = await this.commonUIService
          .ConvertHtmlToPdfBase64(this.selectedTemplate.description);
        if (base64String) {
          this.emailmodel.base64String = btoa(base64String);
          const response = await this.commonUIService.sendEmailRequest(this.emailmodel);
          if (response) {
            const body = JSON.parse(response['_body']);
            switch (body.status) {
              case 200:
                if (body.isSent) {
                  this.commonUIService.isSpinnerVisible = false;
                  if (this.selectedInvoiceHeader) {
                    this.selectedInvoiceHeader.isMailed = true;
                    const invoiceUpdateResponse = await this._invoiceService
                      .UpdateInvoiceHeader(this.selectedInvoiceHeader);
                    if (invoiceUpdateResponse) {
                      this.OnSelectInvoiceHeader(this.selectedInvoiceHeader);
                    }
                  }
                  return this._toastyService.success({
                    title: this._commonDataService.localizationLabelList['email'],
                    msg: this._commonDataService.localizationLabelList['email_send_succesfully'],
                  });
                } else {
                  this.commonUIService.isSpinnerVisible = false;
                  return this._toastyService.error({
                    title: this._commonDataService.localizationLabelList['email'],
                    msg: this._commonDataService.localizationLabelList['email_failed'],
                  });
                }
              case 404:
                this.commonUIService.isSpinnerVisible = false;
                return this._toastyService.error({
                  title: this._commonDataService.localizationLabelList['email'],
                  msg: this._commonDataService.localizationLabelList['email_failed'],
                });
            }
          } else {
            this.commonUIService.isSpinnerVisible = false;
            return this._toastyService.error({
              title: this._commonDataService.localizationLabelList['email'],
              msg: this._commonDataService.localizationLabelList['email_failed'],
            });
          }
        }
      }
    }
  }
  //#endregion

  openCloseBillingModal() {
    this.showcloseBillingPopup = true;
  }

  closeCBModal() {
    this.showcloseBillingPopup = false;
  }

  manageNgSelectDropdown(lang) {
    this._commonDataService.SetTranslatedLabels(lang);
    if (this.issuerDropDownList && this.issuerDropDownList.length > 1) {
      this.issuerDropDownList = this.issuerDropDownList.filter(x => x.value !== '0');
      this.issuerDropDownList.unshift(new DropDownOption('0',
        this._commonDataService.localizationLabelList['select'], false));
    }
    if (this.serieDropDownList && this.serieDropDownList.length > 1) {
      this.serieDropDownList = this.serieDropDownList.filter(x => x.value !== '0');
      this.serieDropDownList.unshift(new DropDownOption('0',
        this._commonDataService.localizationLabelList['select'], false));
    }
    if (this.clientDropDownList && this.clientDropDownList.length > 1) {
      this.clientDropDownList = this.clientDropDownList.filter(x => x.value !== '0');
      this.clientDropDownList.unshift(new DropDownOption('0',
        this._commonDataService.localizationLabelList['select'], false));
    }
    if (this.yearDropDownList && this.yearDropDownList.length > 1) {
      this.yearDropDownList = this.yearDropDownList.filter(x => x.value !== '0');
      this.yearDropDownList.unshift(new DropDownOption('0',
        this._commonDataService.localizationLabelList['select'], false));
    }
  }
}
