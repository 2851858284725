import { Component, Input, OnInit } from '@angular/core';
import { FreeBudget } from 'src/app/modules/budgets/entities/freeBudget';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';

@Component({
  selector: 'app-detail-view-free-budget',
  templateUrl: './detail-view-free-budget.component.html',
  styleUrls: ['./detail-view-free-budget.component.scss']
})
export class DetailViewFreeBudgetComponent implements OnInit {

  @Input() freeBudget: FreeBudget;

  constructor( public _commonUiService: CommonUIService) {
   }

  ngOnInit() {
      this.freeBudget = new FreeBudget();
  }

}
