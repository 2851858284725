import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { NgbDate } from 'imp-ng-bootstrap/datepicker/ngb-date';
import { NgxPermissionsService } from 'ngx-permissions';
import { Patient } from 'src/app/modules/client/entities/patient';
import { DropDownOption } from 'src/app/modules/common/entities/dropdown-option';
import { IncidentTypeList } from 'src/app/modules/common/global'
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonFunctions } from 'src/app/modules/common/services/common-functions';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { RequestData } from 'src/app/shared/pagination/requestData';
import { Doctor } from '../../../entities/doctor';
import { WorkNo, MwtNo, DeliveryNoteNo } from '../../../entities/global-search-no-filters';
import { IncidentTrackSearchFilter } from '../entities/incident-track-search-model';
import API from '../../../../common/api.config.json';
import { WorkConfigurationService } from 'src/app/modules/configuration/services/work-configuration.service';
import { PatientService } from 'src/app/modules/client/services/patient.service';
import { DeliveryNoteService } from '../../../services/delivery-note.service';
import { Worker } from '../../../entities/worker';
import { WorkService } from '../../../services/work.service';
import { User } from 'src/app/modules/auth/entities/user';
import { IncidentReason } from 'src/app/modules/auxiliary/entities/incident-reason';
import { IncidentReasonService } from 'src/app/modules/auxiliary/services/incident-reason.service';
import { Router } from '@angular/router';
import { Toast } from 'src/app/modules/common/services/toast.service';

@Component({
  selector: 'app-tab-incident',
  templateUrl: './tab-incident.component.html',
  styleUrls: ['./tab-incident.component.scss']
})
export class TabIncidentComponent implements OnInit {
  @Output() public OnClickWorkForWorkExpand:
  EventEmitter<{ workId: number, subWorkId: number }> = new EventEmitter<{ workId: number, subWorkId: number }>();
  
  
  paginationRequestData: RequestData = new RequestData(1, 30, '', '', '', 0, true);
  refresh = false;
  incidentPaginationUrl: string;
  rowsOnPage = 30;
  filterQuery = '';
  sortBy = '';
  sortOrder = 'desc';
  incidentList = [];
  patientList: Patient[] = [];
  workerList: Worker[] = [];
  statusList = [];
  priorityOption = [];
  incidentTypeList = IncidentTypeList;

  workNoList: WorkNo[] = [];
  mwtNoList: MwtNo[] = [];
  deliveryNoteNoList: DeliveryNoteNo[] = [];
  
  searchFilter = new IncidentTrackSearchFilter();
  statusDropDownList: DropDownOption[] = [];
  public clientList: any[] = [];
  userList: User[] = [];
  doctorList: Doctor[];
  incidentReasonList: any[] = [];
  canRedirectWork = false;
  showAutocomplete: boolean = true;

  clientId = new FormControl('');
  workerId = new FormControl('');
  doctorId = new FormControl('');
  patientId = new FormControl('');
  mwtNo = new FormControl('');
  workNo = new FormControl('');
  userId = new FormControl('');
  deliveryNoteNo = new FormControl('');
  incidentReasonId = new FormControl('');

  todayView: any;
  disableDates = false;

  constructor(private _apiService: ApiService,
    private _formBuilder: FormBuilder,
    public _commonUIService: CommonUIService,
    public _commonDataService: CommonDataService,
    public dropdownService: DropdownService,
    private _ngxPermissionService: NgxPermissionsService,
    public _workConfigurationService: WorkConfigurationService,
    private _patientService: PatientService,
    private _delNoteService: DeliveryNoteService,
    private _workService: WorkService,
    private _incidentReasonService: IncidentReasonService,
    private _router: Router,
    private _toastyService: Toast) { }

  ngOnInit() {
    const today = new Date().toISOString();
    this.todayView = today ? this._commonUIService.ConvertToPickerDateFormat(today) : today;
    this.loadData();
    const permission = this._ngxPermissionService.getPermission('ViewWork');
    this.canRedirectWork = permission !== undefined;
  }
  
  loadData() {
    this.searchFilter.fromDate = this.todayView;
    this.searchFilter.toDate = this.todayView;
    this.searchFilter.incidentTypeId = '0';
    this.searchFilter.state = '0';
    this.loadDoctorList();
    this.loadClientList();
    this.loadFilters();
    this.loadWorkConfig();
    this.searchPatients();
    this.loadWorkerList();
    this.searchWorkNo();
    this.searchMwtNo();
    this.searchDeliveryNoteNo();
    this.loadUserList();
    this.searchIncidentReason();
  }

  filterTable() {
    this.loadFilters();
    this.refresh = true;
  }

  onKeyUpDates(element: string) {
    if (element === 'from' && (!this.searchFilter.fromDate || CommonFunctions.CheckNgbDateValidity(this.searchFilter.fromDate)) ||
      element === 'to' && (!this.searchFilter.toDate || CommonFunctions.CheckNgbDateValidity(this.searchFilter.toDate)) ||
      element === 'dr' && (!this.searchFilter.resolvedDate || CommonFunctions.CheckNgbDateValidity(this.searchFilter.resolvedDate)))
    {
      this.filterTable();
    }
  }

  isToday(date: NgbDate) {
    date = new NgbDate(date.year, date.month, date.day);
    return date.equals(this.todayView);
  }

  loadClientList() {
    this._apiService.get(API.client.getClientList, true).subscribe(
      res => {
        this.clientList = [];
        this.clientList = res;
        this.clientList = CommonFunctions.Sort(this.clientList, 'name');
      },
      () => { }
    );
  }

  OnChangeClient(clientId: number) {
    if (clientId) {
      this.searchFilter.clientId = clientId;
    } else {
      this.searchFilter.clientId = undefined;
    }
    this.filterTable();
  }

  loadWorkConfig() {
    this._workConfigurationService.GetWorkConfigSelectionList()
      .then(list => {
        let config = list.find(o => o.code == 'PatientSearchSelection');
        if (config) {
          this.showAutocomplete = config.value1;
        }
      });
  }

  OnChangeDoctor(doctorId: number) {
    if (doctorId) {
      this.searchFilter.doctorId = doctorId;
    } else {
      this.searchFilter.doctorId = undefined;
    }
    this.filterTable();
  }

  OnChangePatient(patientId: number) {
    if (patientId) {
      this.searchFilter.patientId = patientId;
    } else {
      this.searchFilter.patientId = undefined;
    }
    this.filterTable();
  }

  onSkipCharCountExceedsPatient() {
    this.searchPatients(this.patientId.value);
  }

  onSkipCharCountExceedsDeliveryNote() {
    this.searchDeliveryNoteNo(this.deliveryNoteNo.value);
  }

  onSkipCharCountExceedsWork() {
    this.searchWorkNo(this.workNo.value);
  }

  onSkipCharCountExceedsMwt() {
    this.searchMwtNo(this.mwtNo.value);
  }

  onSkipCharCountExceedsReason() {
    this.searchIncidentReason(this.incidentReasonId.value);
  }

  async searchPatients(query = '') {
    const patientList = await this._patientService.SearchPatients(query);
    if (patientList && patientList.length > 0) {
      let value = this.patientId.value;
      if (value) {
        if (value.toString().startsWith("'") && value.toString().endsWith("'")
          || value.toString().startsWith('"') && value.toString().endsWith('"')) {
          value = value ? value.toString().replace(/"/g, '').replace(/'/g, '') : '';
        }
      }
      this.patientId.setValue(value);
    }
    this.patientList = patientList ? patientList : [];
  }

  loadDoctorList() {
    this._apiService.get(API.doctor.getDoctorList)
      .subscribe(res => {
        this.doctorList = res;
      });
  }

  async searchDeliveryNoteNo(query = '') {
    const deliveryNoteNoList = await this._delNoteService.SearchDeliveryNoteNo(query);
    this.deliveryNoteNoList = deliveryNoteNoList ? deliveryNoteNoList : [];
  }

  onChangeDeliveryNoteNoText(value: string) {
    if (value) {
      this.searchFilter.deliverNoteNo = value;
    } else {
      this.searchFilter.deliverNoteNo = '';
    }
    this.filterTable();
  }

  loadFilters() {
    this.paginationRequestData.page = 1;
    const filter = `${this.getFilterValue(this.searchFilter.incidentTypeId)};
    ${this.getFilterValue(this.searchFilter.incidentReasonId)};
    ${this.getFilterValue(this.getDate(this.searchFilter.fromDate))};
    ${this.getFilterValue(this.getDate(this.searchFilter.toDate))};
    ${this.getFilterValue(this.searchFilter.workerId)};
    ${this.getFilterValue(this.searchFilter.userId)};
    ${this.getFilterValue(this.searchFilter.workNo ? this.searchFilter.workNo.toString() : '')};
    ${this.getFilterValue(this.searchFilter.workTypeNo)};
    ${this.getFilterValue(this.searchFilter.clientId)};
    ${this.getFilterValue(this.searchFilter.patientId)};
    ${this.getFilterValue(this.searchFilter.doctorId)};
    ${this.getFilterValue(this.searchFilter.state)};
    ${this.getFilterValue(this.searchFilter.deliverNoteNo)};
    ${this.getFilterValue(this.searchFilter.isResolved)};
    ${this.getFilterValue(this.getDate(this.searchFilter.resolvedDate))};
    ${this.getFilterValue(this.searchFilter.patientName)};`
    this.paginationRequestData.filter = filter;
    this.incidentPaginationUrl = API.mainWorkTypeIncident.searchIncidentTrackingList;
  }

  getFilterValue(value) {
    return value ? value : '';
  }

  getDate(ngbDate) {
    if (ngbDate && ngbDate.year) {
      return ngbDate.year + '-' + this.GetWholeNumber(ngbDate.month.toString()) + '-' + this.GetWholeNumber(ngbDate.day.toString());
    }
    return ngbDate;
  }
  private GetWholeNumber(num: string) {
    if (num) {
      if (num.length === 1) {
        num = num.padStart(2, '0');
      }
      return num;
    }
  }

  resolveCheckboxChange(_search: any, event) {
    if (event.target.checked) {
      this.searchFilter.isResolved = true;     
    }
    else {
      this.searchFilter.isResolved = false
    }
    this.filterTable();
  }

  public loadWorkerList() {
    this._apiService.get(API.worker.getWorkerList)
      .subscribe(res => {
        this.workerList = res;
      });
  }

  OnChangeWorker(workerId: number) {
    if (workerId) {
      this.searchFilter.workerId = workerId;
    } else {
      this.searchFilter.workerId = undefined;
    }
    this.filterTable();
  }

  async searchWorkNo(query = '') {
    const workNoList = await this._workService.SearchWorkNo(query);
    this.workNoList = workNoList ? workNoList : [];
  }

  onChangeWorkNoText(value: string) {
    if (value) {
      this.searchFilter.workNo = value;
    } else {
      this.searchFilter.workNo = '';
    }
    this.filterTable();
  }

  async searchMwtNo(query = '') {
    const mwtNoList = await this._workService.SearchMwtNo(query);
    this.mwtNoList = mwtNoList ? mwtNoList : [];
  }

  onChangeMwtNoText(value: string) {
    if (value) {
      this.searchFilter.workTypeNo = value;
    } else {
      this.searchFilter.workTypeNo = '';
    }
    this.filterTable();
  }

  loadUserList() {
    this._apiService.get(API.user.getUserList)
      .subscribe(res => {
        this.userList = res;
      });
  }

  onChangeUser(value: string) {
    if (value) {
      this.searchFilter.userId = value;
    } else {
      this.searchFilter.userId = '';
    }
    this.filterTable();
  }

  async searchIncidentReason(query = '') {
    this.incidentReasonList = [];
    const reasonList = await this._incidentReasonService.searchIncidentReason(query);

    if (reasonList != null && this.searchFilter.incidentTypeId != null && Number(this.searchFilter.incidentTypeId) > 0) {
      this.incidentReasonList = reasonList.filter(a => a.type == Number(this.searchFilter.incidentTypeId));
    }
    else {
      this.incidentReasonList = reasonList ? reasonList : [];
    }
  }

  onChangeIncidentReasonText(value: string) {
    if (value) {
      this.searchFilter.incidentReasonId = value;
    } else {
      this.searchFilter.incidentReasonId = '';
    }
    this.filterTable();
  }

  onIncidentTypeChnage() {
    this.incidentReasonList = [];
    this.searchFilter.incidentReasonId = '';
    this.incidentReasonId.setValue('');
    this.filterTable();
  }

  public openWork(workId: number, subWorkId: number) {
    if (workId && subWorkId) { 
      const url = this._router.serializeUrl(this._router.createUrlTree(['/work'], { queryParams: { workId, subWorkId } }));
      window.open(url, '_blank');
    } else { console.log('work id null'); }
  }

  public OnExcelExport() {
    if (this.incidentList.length > 0 && this.incidentList[0].totalCount > 1000) {
      this._toastyService.warning({
        title: this._commonDataService.localizationLabelList['job_search'],
        msg: this._commonDataService.localizationLabelList['EXPORT_WARNING_MSG']
      });
      return;
    }

    this.loadFilters();
    const page = this.paginationRequestData.page;
    this.paginationRequestData.page = 1;
    this.paginationRequestData.pageSize = 1000;
    const params = new URLSearchParams();
    for (const key in this.paginationRequestData) {
      params.set(key, this.paginationRequestData[key]);
    }
    const query = params.toString().replace('+', '%20');
    const url = this._apiService.BASE_URL + `/api/MainWorkTypeIncident/SearchIncidentTrackingListExcelExport?${query}`;
    window.open(url, '_blank');
    this.paginationRequestData.page = page;
    this.paginationRequestData.pageSize = 30;

  }

}
