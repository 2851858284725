export class MotivesCancelPhase {
    id: number;
    description: string;
    status: boolean;
    byDefault?: boolean;

    isEdit?: boolean;
    isDescriptionRequired: boolean;

    constructor() {
      this.status = true;
    }
}
