import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import swal from 'sweetalert2';
import { ClinicMaterial } from '../../../auxiliary/entities/clinic-material';
import { ApiService } from '../../../common/services/api.service';
import { CommonDataService } from '../../../common/services/common.data.service';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { ValidateNgSelect } from '../../../common/services/validators';
import { IWorkComponent } from '../../entities/i-work-component';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtClinicMaterial } from '../../entities/main-work-type-clinic-material';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { MwtClinicMaterialService } from '../../services/mwt-clinic-material.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';

@Component({
  selector: 'clinic-materials',
  templateUrl: './clinic-materials.component.html',
  styleUrls: ['./clinic-materials.component.scss']
})
export class ClinicMaterialsComponent implements OnInit, OnDestroy, IWorkComponent {

  // subscriptions
  private workService: Subscription;

  // form groups
  clinicMaterialFormGroup: FormGroup;

  // lists
  mwtClinicMaterialList: MwtClinicMaterial[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];
  clinicMaterialList: ClinicMaterial[] = [];
  mainWorkTypeList: MainWorkType[];

  // objects
  selectedWork: Work;
  selectedMainWorkType: MainWorkType;
  selectedSubWork: SubWork;
  hasLoaded = true;
  showPopUp = false;
  allClosed = false;

  constructor(private _workService: WorkService, private _apiService: ApiService,
    public commonUiService: CommonUIService, private _commonDataService: CommonDataService, private _modalService: ModalService,
    private __service: MwtClinicMaterialService, private _formBuilder: FormBuilder, private _toastyService: Toast) {
    this.clinicMaterialFormGroup = this._formBuilder.group({
      mainWorkTypeId: ['', [ValidateNgSelect]],
      clinicMaterialId: ['', [ValidateNgSelect]],
      quantity: ['', [Validators.required]],
      note: []
    });
    this.workService = this._workService.detailObject.share().subscribe((args) => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (args.invokeComponents.includes('ClinicMaterial') || args.invokeComponents.includes('*')) {
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this.selectedWork = args.selectedWork;
          this.allClosed = this.selectedMainWorkTypeList.every(x => x.isClosed);
          this.SyncMwtClinicMaterialData();
        }
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() { this.workService.unsubscribe(); }

  //#region CRUD

  private async SyncMwtClinicMaterialData() {
    this.mwtClinicMaterialList = [];
    try {
      this.hasLoaded = false;
      const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
      if (idList) {
        let mwtClinicMaterialList = await this.__service.GetMwtClinicMaterialListByMainWorkTypeIdList(idList);
        mwtClinicMaterialList = mwtClinicMaterialList ? mwtClinicMaterialList : [];
        if (mwtClinicMaterialList.length > 0) {
          for (let index = 0; index < mwtClinicMaterialList.length; index++) {
            const mwtClinicMaterial = mwtClinicMaterialList[index];
            const currentMainWorkType = this.selectedMainWorkTypeList.find(m => Number(m.id) === mwtClinicMaterial.mainWorkTypeId);
            if (currentMainWorkType) {
              mwtClinicMaterial.order = currentMainWorkType.order;
              mwtClinicMaterial.cssClass = currentMainWorkType.cssClass;
              mwtClinicMaterial.symbol = currentMainWorkType.symbol;
              if (currentMainWorkType.isClosed) {
                mwtClinicMaterial.isClosed = true;
              } else {
                mwtClinicMaterial.isClosed = false;
              }
            }
          }
        }
        this.mwtClinicMaterialList = mwtClinicMaterialList;
      }
    } catch (error) { console.log(error); }
    finally { this.hasLoaded = true; }
  }

  private RemoveMwtClinicMaterial(mwtClinicMaterialId: number) {
    return this._apiService.delete(API.main_work_type_work.deleteMwtClinicMaterial,
      mwtClinicMaterialId.toString() + '/' + this.selectedWork.id, true).toPromise();
  }

  //#endregion

  //#region Events

  OnOpenAddModal(modal: string) {
    let mwtList = [] as MainWorkType[];
    for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
      const mwt = this.selectedMainWorkTypeList[index];
      if (!mwt.isClosed) {
        mwtList.push(mwt);
      }
    }
    this.showPopUp = true;
    this._modalService.Invoke(modal, true, {
      mainWorkTypeList: mwtList,
      selectedWork: this.selectedWork,
      mwtClinicMaterial: undefined, isEdit: false
    });
  }

  OnOpenEditModal(mwtClinicMaterial: MwtClinicMaterial, modal: string) {
    let mwtList = [] as MainWorkType[];
    for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
      const mwt = this.selectedMainWorkTypeList[index];
      if (!mwt.isClosed) {
        mwtList.push(mwt);
      }
    }
    this.showPopUp = true;
    this._modalService.Invoke(modal, true, {
      mainWorkTypeList: mwtList,
      selectedWork: this.selectedWork,
      mwtClinicMaterial: mwtClinicMaterial, isEdit: true
    });
  }

  async OnRemoveMwtClinicMaterial(mwtClinicMaterialId: number) {
    if (mwtClinicMaterialId) {
      swal({
        title: this._commonDataService.localizationLabelList['clinic_material'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      }).then(async (result) => {
        if (result.value) {
          const response = await this.RemoveMwtClinicMaterial(mwtClinicMaterialId);
          if (response) {
            if (response['_statusCode'] === 200) {
              this.SyncMwtClinicMaterialData();
              const index: number = this.mwtClinicMaterialList.findIndex((x, i, a) => Number(x.id) == Number(mwtClinicMaterialId));
              if (index !== -1) { this.mwtClinicMaterialList.splice(index, 1); }
              this._toastyService.success({
                title: 'Clinic Material',
                msg: this._commonDataService.localizationLabelList['delete_success']
              });
            } else if (response['_statusCode'] === 409) {
              this._toastyService.error({
                title: 'Clinic Material',
                msg: this._commonDataService.localizationLabelList['delete_error']
              });
            }
          }
        }
      });
    }
  }

  //#endregion

  closePopup() {
    this.showPopUp = false;
  }
}
