import { FreeBudgetArticle } from "./freeBudgetArticle";

export class FreeBudget {
    id: number;
    description: string;
    generatedDate: any;
    issuerId?: any;
    clientId?: any;
    clientName: string;
    patientId?: any;
    patientName: string;
    seriesId?: number;
    generateNo: string;
    validDate?: any;
    totalPrice: number;
    isConvert?: boolean;
    status: boolean;
    createdDate?: any;

    articleList: FreeBudgetArticle[];
    articleId: any;

    sumPrice: number;
    totalDiscount: number;
    totalVatAmount: number;
    totalFinalPrice: number;
    totalWithDiscount: number;
    subTotal: number;
    discount: number;
    tax: number;

    cssClass: string;

    workTypeId?: number;
    workTypeName: string;

    mainWorktypeId: number;
    isMailed: boolean;
}