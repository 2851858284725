import { Injectable } from '@angular/core';
import { toFixedNumber } from '../../common/services/calculations/calculation';

@Injectable({
  providedIn: 'root'
})
export class IvaCalculationService {

  constructor() { }

  getIvaAmount(finalAmountWithDiscount: number, ivaPercentage: number) {
    return toFixedNumber(finalAmountWithDiscount * (ivaPercentage / 100));
  }

}
