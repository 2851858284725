import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Article } from 'src/app/modules/articles/entities/article';
import { IvaTypes } from 'src/app/modules/auxiliary/entities/iva-types';
import { ModalNames } from 'src/app/modules/common/global';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { ArticleCalculationService } from 'src/app/modules/common/services/calculations/article-calculation.service';
import { toFixedNumber } from 'src/app/modules/common/services/calculations/calculation';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { ValidateNgSelect } from 'src/app/modules/common/services/validators';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtArticle } from '../../entities/main-work-type-article';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { IvaCalculationService } from '../../services/iva-calculation.service';
import API from './../../../common/api.config.json';

@Component({
  selector: 'app-add-article-modal',
  templateUrl: './add-article-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddArticleModalComponent implements OnInit, AfterViewInit {
  @Output() onSaveBudgetArticle = new EventEmitter();
  @Input() ivaTypeList: IvaTypes[];

  showContent = false;

  // form goups
  addArticleFormGroup: FormGroup;

  // objects
  transferable: any = {};
  selectedArticle: MwtArticle = new MwtArticle();
  mwtArticle: MwtArticle = new MwtArticle();
  selectedWork: Work;
  selectedSubWork: SubWork;
  selectedMainWorkType: MainWorkType;
  mainWorkTypeList: MainWorkType[];
  articleList: Article[] = [];
  isDiscountEnabled = true;

  // lists
  selectedMainWorkTypeList: MainWorkType[] = [];

  constructor(
    private _modalService: ModalService,
    private _formBuilder: FormBuilder,
    private _apiService: ApiService,
    public commonUiService: CommonUIService,
    private _dropdownService: DropdownService,
    private cdref: ChangeDetectorRef,
    private _ivaCalculationService: IvaCalculationService,
    private _calculationService: ArticleCalculationService
  ) {
    this.selectedArticle = new MwtArticle();
    this.mwtArticle = new MwtArticle();
    this.selectedWork = new Work();
    this.InitFormGroups();
  }

  ngAfterViewInit(): void {
    this._modalService.modalSubject.subscribe(modalObject => {
      if (modalObject) {
        if (modalObject.modal === ModalNames.ArticleModal2) {
          if (modalObject.flag && modalObject.transferable != null) {
            this.transferable = modalObject.transferable;
            this.selectedMainWorkTypeList =
              modalObject.transferable.selectedMainWorkTypeList;
            this.selectedArticle = modalObject.transferable.selectedArticle;
            this.selectedWork = modalObject.transferable.selectedWork;
            this.LoadList();
            this.OnEditBudgetMwtArticleModal(this.selectedArticle);
          }
          this.showContent = modalObject.flag;
        }
      }
    });

  }

  ngOnInit() {
  }

  LoadList() {
    this.articleList = this._dropdownService.articleList
      ? this._dropdownService.articleList
      : [];
    this.cdref.markForCheck();
  }

  InitFormGroups() {
    this.addArticleFormGroup = this._formBuilder.group({
      articleId: ['', [ValidateNgSelect]],
      mainWorkTypeId: ['', [ValidateNgSelect]],
      quantity: [],
      waste: [],
      price: [],
      discount: [],
      finalPrice: [],
      description1: [],
      quantityWithWaste: [],
      priceWithDiscount: [],
      ivaTypeId: [],
      ivaAmount: []
    });
  }

  private OnEditBudgetMwtArticleModal(article: MwtArticle) {    
    if (article) {
      this.addArticleFormGroup.markAsPristine();
      this.addArticleFormGroup.markAsUntouched();
      this.mwtArticle = Object.assign(this.mwtArticle, article);
      this.addArticleFormGroup.controls.mainWorkTypeId.setValue(this.mwtArticle.mainWorkTypeId);
      this.commonUiService.isPopupOpened = true;
      this.cdref.markForCheck();
    }
  }

  OnClose() {
    this.onSaveBudgetArticle.emit(null);
    this.showContent = false;
  }

  async OnSelectArticle() {
    if (this.mwtArticle.articleId) {
      if (this.selectedWork.clientId) {
        const mwtArticle = await this.GetMwtArticlePriceDetails(
          this.mwtArticle.articleId,
          this.selectedWork.clientId
        );
        if (mwtArticle) {
          if (mwtArticle.price !== null) {
            this.mwtArticle.price = mwtArticle.price;
          }
          if (mwtArticle.discount !== null) {
            this.mwtArticle.discount = mwtArticle.discount;
          }
          if (mwtArticle.isAmount !== null) {
            this.mwtArticle.isAmount = mwtArticle.isAmount;
          }
          if (mwtArticle.discountAmount !== null) {
            this.mwtArticle.discountAmount = mwtArticle.discountAmount;
          }
          if (mwtArticle.waste !== null) {
            this.mwtArticle.waste = mwtArticle.waste;
          }

          this.OnKeyUpQuantityOrWaste();
          this.OnKeyUpPriceOrDiscount();
          this.OnChangeQtyWithWasteOrPriceWithDiscount();
        }
        const article: Article = this.articleList.find(
          (x, i, a) => Number(x.id) === Number(this.mwtArticle.articleId)
        );
        if (article) {
          this.mwtArticle.description1 = article.description1;
          this.mwtArticle.code = article.code;
        }
      }
    } else {
      this.mwtArticle.description1 = '';
      this.mwtArticle.code = '';
    }
    this.cdref.markForCheck();
  }

  public OnKeyUpQuantityOrWaste() {
    if (this.mwtArticle.waste !== undefined && this.mwtArticle.quantity !== undefined) {
      this.mwtArticle.quantityWithWaste = this._calculationService.getQuantityWithWaste(toFixedNumber(this.mwtArticle.quantity), toFixedNumber(this.mwtArticle.waste));
      this.mwtArticle.finalPrice = this.GetFinalPrice(this.mwtArticle);
    }
    this.cdref.markForCheck();
  }

  public OnKeyUpPriceOrDiscount() {
    if (this.mwtArticle.price !== undefined && this.mwtArticle.discount !== undefined) {
      this.mwtArticle.priceWithDiscount = this._calculationService.getPriceWithDiscount(toFixedNumber(this.mwtArticle.price), this.mwtArticle.isAmount ? toFixedNumber(this.mwtArticle.discountAmount) : toFixedNumber(this.mwtArticle.discount), this.mwtArticle.isAmount);
      this.mwtArticle.quantityWithWaste = this.GetQuantityWithWaste(this.mwtArticle);
      this.mwtArticle.ivaAmount = 0;
      let finalPriceWithoutIva = this.getFinalPriceWithoutIva(this.mwtArticle);
      if (this.mwtArticle.ivaTypeId) {
        this.mwtArticle.ivaAmount = this._ivaCalculationService.getIvaAmount(finalPriceWithoutIva, this.mwtArticle.ivaPercentage ? this.mwtArticle.ivaPercentage : 0);
        this.mwtArticle.ivaAmount = this.mwtArticle.ivaAmount ? toFixedNumber(this.mwtArticle.ivaAmount) : this.mwtArticle.ivaAmount;
      }
      this.mwtArticle.finalPrice = this.GetFinalPrice(this.mwtArticle);
    }
    this.cdref.markForCheck();
  }

  public OnChangeQtyWithWasteOrPriceWithDiscount() {
    if (this.mwtArticle.priceWithDiscount !== undefined && this.mwtArticle.quantityWithWaste !== undefined) {
      const finalPriceWithoutIva = this.getFinalPriceWithoutIva(this.mwtArticle);
      if (this.mwtArticle.ivaTypeId) {
        this.mwtArticle.ivaAmount = this._ivaCalculationService.getIvaAmount(finalPriceWithoutIva, this.mwtArticle.ivaPercentage ? this.mwtArticle.ivaPercentage : 0);
        this.mwtArticle.ivaAmount = this.mwtArticle.ivaAmount ? toFixedNumber(this.mwtArticle.ivaAmount) : this.mwtArticle.ivaAmount;
      }
      this.mwtArticle.finalPrice = this.GetFinalPrice(this.mwtArticle);
    }
    this.cdref.markForCheck();
  }

  private async GetMwtArticlePriceDetails(
    articleId: number,
    clientId: number
  ): Promise<MwtArticle> {
    return this._apiService
      .get(
        API.main_work_type_work.getMwtArticlePriceDetails +
        articleId +
        '/' +
        clientId
      )
      .toPromise();
  }

  OnSaveBudgetArticle(event: any) {
    this.onSaveBudgetArticle.emit(this.mwtArticle);
    this.showContent = false;
  }

  onChangeIvaType() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (Number(this.mwtArticle.ivaTypeId) && this.ivaTypeList && this.ivaTypeList.length > 0) {
        const iType = this.ivaTypeList.find(it => it.id === Number(this.mwtArticle.ivaTypeId));
        if (iType) { this.mwtArticle.ivaPercentage = iType.iva; }
      } else { this.mwtArticle.ivaPercentage = 0; }
      this.mwtArticle.ivaAmount = 0;
      this.OnChangeQtyWithWasteOrPriceWithDiscount();
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  private getFinalPriceWithoutIva(mwtArticle: MwtArticle) {
    if (mwtArticle.priceWithDiscount !== undefined && mwtArticle.quantityWithWaste !== undefined) {
      return toFixedNumber((mwtArticle.priceWithDiscount * mwtArticle.quantityWithWaste));
    }
  }

  //#region budget calculation modification

  private GetQuantityWithWaste(mwtArticle: MwtArticle) {
    if (mwtArticle.waste !== undefined && mwtArticle.quantity !== undefined) {
      mwtArticle.quantity = Number(mwtArticle.quantity);
      return this._calculationService
        .getQuantityWithWaste(toFixedNumber(mwtArticle.quantity),
          toFixedNumber(mwtArticle.waste));
    }
  }

  private GetPriceWithDiscount(mwtArticle: MwtArticle) {
    if (mwtArticle.price !== undefined && mwtArticle.discount !== undefined) {
      return this._calculationService
        .getPriceWithDiscount(toFixedNumber(mwtArticle.price),
          mwtArticle.isAmount ? toFixedNumber(mwtArticle.discountAmount)
            : toFixedNumber(mwtArticle.discount),
          mwtArticle.isAmount);
    }
  }

  private GetFinalPrice(mwtArticle: MwtArticle) {
    if (mwtArticle.priceWithDiscount !== undefined && mwtArticle.quantityWithWaste !== undefined) {
      return this._calculationService.getArticleFinalTotal(toFixedNumber(mwtArticle.quantityWithWaste),
        toFixedNumber(mwtArticle.priceWithDiscount), toFixedNumber(mwtArticle.ivaAmount));
    }
  }

  private GetLineDiscountAmount(finalTotal: number, vatAmount: number, subTotal: number) {
    try {
      return toFixedNumber(subTotal - (finalTotal - vatAmount));
    } catch (error) { console.log(error); }
  }

  private GetLineSubTotal(price: number, quantityWithWaste: number) {
    try {
      return this._calculationService
        .getLineSubTotal(price, quantityWithWaste);
    } catch (error) { console.log(error); }
  }

  //#endregion
}
