import { SafeResourceUrl } from '@angular/platform-browser';

export class Prescription {
    id: number;
    fileName: string;
    generatedFileName: string;
    subWorkId: number;
    file: any;
    fileList: any;
    extension: string;
    url: string;
    link: string;
    safeUrl: SafeResourceUrl;
    status: boolean;

    constructor(init?: Partial<Prescription>) { if (init) { Object.assign(this, init); } }
}
