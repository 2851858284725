import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ListNames } from 'src/app/modules/common/global';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { WorkTypePhaseTemplate } from 'src/app/modules/work-type/entities/work-type-model-templates';
import { DropdownService } from '../../../common/services/dropdown.service';
import { ModalService } from '../../../common/services/modal.service';
import { Worker } from '../../../workers/entities/worker';
import { IWorkComponent } from '../../entities/i-work-component';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtWorker } from '../../entities/main-work-type-worker';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { MwtWorkerService } from '../../services/mwt-worker.service';
import { WorkService } from '../../services/work.service';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import API from './../../../common/api.config.json';
import swal from 'sweetalert2';

declare var notEnableTogglePan: boolean;

@Component({
  selector: 'workers',
  templateUrl: './workers.component.html',
  styleUrls: ['./workers.component.scss']
})
export class WorkersComponent implements OnInit, OnDestroy, IWorkComponent {

  // subscrciptions
  private workService: Subscription;

  // form groups
  // addWorkerFormGroup: FormGroup;

  // lists
  workerList: Worker[] = [];
  allWorkerList: Worker[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];
  phaseTemplateList: WorkTypePhaseTemplate[] = [];
  selectedSubWork: SubWork;
  selectedWork: Work;
  mainWorkTypeList: MainWorkType[];

  // objects
  hasLoaded = true;
  selectedMainWorkType: MainWorkType;
  mwtWorker: MwtWorker;
  showPopUp = false;
  allClosed = false;

  constructor(private _workService: WorkService,private _apiService: ApiService, private _dropdownService: DropdownService,
    private __service: MwtWorkerService, public commonUiService: CommonUIService,private _commonDataService: CommonDataService,
    private _modalService: ModalService,private _toastyService: Toast) {

    this.workService = this._workService.detailObject.share().subscribe((args) => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (args.invokeComponents.includes('Worker') || args.invokeComponents.includes('*')) {
          this.hasLoaded = false;
          this.workerList = [];
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this.selectedMainWorkType = args.selectedMainWorkType;
          this.allClosed = this.selectedMainWorkTypeList.every(x => x.isClosed);
          if (args.selectedMainWorkTypeList.length > 0) { this.LoadList(); }
          this.SyncWorkerData();
        }
      }
    });
  }

  ngOnInit() { this.mwtWorker = new MwtWorker(); }

  ngOnDestroy() { this.workService.unsubscribe(); }

  //#region CRUD

  private async LoadList() {
    if (this._dropdownService.workerList !== undefined || this._dropdownService.workerList.length === 0) {
      await this._dropdownService.LoadListAsync([ListNames.WorkerList]);
    }
    this.allWorkerList = this._dropdownService.workerList;
  }

  private async SyncWorkerData() {
    this.workerList = [];
    try {
      const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
      if (idList) {
        let workerList = await this.__service.GetWorkerListByMainWorkTypeIdList(idList);
        workerList = workerList ? workerList : [];

        if (workerList.length > 0) {
          for (let index = 0; index < workerList.length; index++) {

            const mwtWorker = workerList[index];
            const currentMainWorkType = this.selectedMainWorkTypeList.find(m => Number(m.id) === mwtWorker.mainworktypeId);
            if (currentMainWorkType) {
              mwtWorker.order = currentMainWorkType.order;
              const existingWorker: Worker = this.workerList.find(x => Number(x.id) === Number(mwtWorker.id));
              if (existingWorker) {
                if (existingWorker.orderSymbolList.findIndex(x => x.order === currentMainWorkType.order
                  && x.symbol === currentMainWorkType.symbol) === -1) {
                  existingWorker.orderSymbolList.push({ order: currentMainWorkType.order, symbol: currentMainWorkType.symbol });
                }
              } else {
                const worker = new Worker();
                worker.name = mwtWorker.surname != null ? mwtWorker.name + ' ' + mwtWorker.surname : mwtWorker.name;
                worker.initals = worker.name.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '').toUpperCase().substring(0, 2);
                worker.id = mwtWorker.id;
                worker.workerTypeId = mwtWorker.mainworktypeId;
                if (mwtWorker.imagePathUrl) { worker.imagePathUrl = 'data:image/png;base64,' + mwtWorker.imagePathUrl; }
                if (worker.orderSymbolList.findIndex(x => x.order === currentMainWorkType.order
                  && x.symbol === currentMainWorkType.symbol) === -1) {
                  worker.orderSymbolList.push({ order: currentMainWorkType.order, symbol: currentMainWorkType.symbol });
                }
                this.workerList.push(worker);
              }
            }
          }
        }
      }
    } catch (error) { console.log(error); }
    finally {
      notEnableTogglePan = false;
      this.hasLoaded = true;
      this.commonUiService.isSidebarDisabled = false;
    }
  }
  
  private RemoveWorker(workerId: number, workerTypeId : number) {
    return this._apiService.delete(
      API.main_work_type_work.deleteMwtWorker, 
      workerId.toString()+ '/' +workerTypeId.toString(),
       true).toPromise();
  }

  //#endregion

  //#region Events

  OnOpenAddWorkerModal(modal: string) {
    try {
      let mwtList = [] as MainWorkType[];
      for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
        const mwt = this.selectedMainWorkTypeList[index];
        if (!mwt.isClosed) {
          mwtList.push(mwt);
        }
      }
      this.commonUiService.isSpinnerVisible = true;
      this.commonUiService.isPopupOpened = true;
      this.showPopUp = true;
      this._modalService.Invoke(modal, true, {
        selectedMainWorkTypeList: mwtList,
        allWorkerList: this.allWorkerList
      }, this.commonUiService.isSpinnerVisible = false);
    } catch (error) { console.log(error); }
  }

  AfterWorkerSaved() { this.SyncWorkerData(); }

  OnRemoveWorker(workerId: number,workerTypeId) {    
    if (workerId) {
      swal({
        title: this._commonDataService.localizationLabelList['worker'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      }).then(async (result) => {
        if (result.value) {
          this.commonUiService.isSpinnerVisible = true;
          const deleted = await this.RemoveWorker(workerId,workerTypeId);
          if (deleted._isSuccsess) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['worker'],
              msg: this._commonDataService.localizationLabelList['delete_success']
            });
            this.SyncWorkerData();
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['worker'],
              msg: this._commonDataService.localizationLabelList['delete_error']
            });
          }
          this.commonUiService.isSpinnerVisible = false;
        }
      });
    }
  }


  //#endregion

}
