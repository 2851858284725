import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { DropdownService } from '../../../common/services/dropdown.service';
import { ListNames, SeriesTypes } from '../../../common/global';
import { Issuer } from '../../../auxiliary/entities/issuer';
import * as moment from 'moment';
import { User } from '../../../auth/entities/user';
import { CommonDataService } from '../../../common/services/common.data.service';
import { WorkService } from '../../services/work.service';
import { Series } from 'src/app/modules/client/entities/series';
import { NumberingProcess } from '../../../../shared/numbering-process/numbering-process';
import { NumberingProcessService } from '../../../../shared/numbering-process/numbering-process.service';
import { Client } from '../../../client/entities/client';
import { DeliveryNoteService } from '../../services/delivery-note.service';
import { InvoiceHeader } from 'src/app/modules/invoice/entities/invoice-header';
import { InvoiceService } from '../../services/invoice.service';
import { InvoiceDetail } from 'src/app/modules/invoice/entities/invoice-detail';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { Patient } from '../../entities/patient';
import { DeliveryNote } from '../../entities/delivery-note';
import { DeliveryNoteListChangedSubjectArgs } from '../../entities/multiple-delivery-notes-models';

@Component({
  selector: 'app-add-multiple-deliverynotes',
  templateUrl: './add-multiple-deliverynotes.component.html',
  styleUrls: ['./add-multiple-deliverynotes.component.scss']
})
export class AddMultipleDeliverynotesComponent implements OnInit {

  @Input() args: {
    subWorkId: number, workId: number,
    issuerId: number, issuerName: string, patientId: number,
    seriesId: number, seriesName: string, clientId: number,
    fromClientSeries: boolean, fromDefaultIssuer: boolean,
    isToPatient: boolean
  };
  @Output() modalClosed = new EventEmitter();
  @Output() invoiceSaved = new EventEmitter();

  formGroup: FormGroup;
  invoice: InvoiceHeader;
  deliveryNoteList: DeliveryNote[] = [];
  seriesList: Series[] = [];
  user = new User();
  issuer = new Issuer();
  client = new Client();
  patient = new Patient();
  hasChecked = false;
  seriesId = 0;

  constructor(
    private _commonUiService: CommonUIService,
    private _formBuilder: FormBuilder,
    private _commonDataService: CommonDataService,
    private _workService: WorkService,
    private _numberingProcessService: NumberingProcessService,
    public dropdownService: DropdownService,
    private _deliveryNoteService: DeliveryNoteService,
    private _invoiceService: InvoiceService,
    private _toastyService: Toast) { }

  async ngOnInit() {
    this.initFormGroup();
    this.initResources();
    this.invoice = new InvoiceHeader();
    const sessionUser = this._commonDataService.GetUserFromSession();
    if (sessionUser) { this.user = sessionUser; }
    this.deliveryNoteArrayListener();
    await this.onOpenModal();
  }

  private initResources() {
    if (this.dropdownService.issureList.length === 0) {
      this.dropdownService.LoadList([ListNames.IssuerList]);
    }
  }

  private async setGenerateNoData() {
    if (this.args.issuerId && !this.args.seriesId) {
      this.seriesList = await this.getBillSeriesListByIssuerId(this.args.issuerId);
      this.setIssuerInfo();
    } else {
      if (this.args.issuerId && this.args.seriesId) {
        const series = await this._workService.GetSeriesById(this.args.seriesId);
        if (series) { this.invoice.generateNumber = this.getGeneratedInvoiceNo(series); }
        this.setIssuerInfo();
      }
    }
  }

  private async getBillSeriesListByIssuerId(issuerId: number) {
    try {
      let seriesList = await this._workService.GetSeriesListByIssuerId(issuerId);
      seriesList = seriesList ? seriesList : [];
      return seriesList.filter(
        s =>
          s.seriesType.toLowerCase() === SeriesTypes.Invoice.toLowerCase() && s.status
          && s.year === new Date().getFullYear().toString());
    } catch (error) { console.log(error); }
  }

  private initFormGroup() {
    this.formGroup = this._formBuilder.group({
      issuerName: [],
      seriesName: [],
      concept: [],
      deliveryNoteFormArray: this._formBuilder.array([])
    });
  }

  private getDeliveryNoteGroup(init?:
    {
      id: number,
      date: string,
      checked: boolean,
      issuerName: string,
      seriesName: string,
      generateNumber: string,
      total: number,
      subTotal: number,
      tax: number
    }) {
    const group = {
      id: [init ? init.id : null],
      checked: [init ? init.checked : null],
      date: [init ? init.date : null],
      issuerName: [init ? init.issuerName : null],
      seriesName: [init ? init.seriesName : null],
      generateNumber: [init ? init.generateNumber : null],
      total: [init ? init.total : null],
      subTotal: [init ? init.subTotal : null],
      tax: [init ? init.tax : null]
    };
    return this._formBuilder.group(group);
  }

  initNewInvoice() {
    this.invoice = new InvoiceHeader({
      clientId: this.args.clientId,
      patientId: this.args.patientId,
      generateDate: moment().format('YYYY-MM-DD'),
      issuerId: this.args.issuerId,
      seriesId: this.args.seriesId,
      issuerName: this.args.issuerName,
      seriesName: this.args.seriesName,
      invoiceStatus: 'pending',
      status: true,
      createdDate: moment().format('YYYY-MM-DD hh:mm:ss'),
      createdBy: this.user.id,
      modifiedDate: moment().format('YYYY-MM-DD hh:mm:ss'),
      modifiedBy: this.user.id,
      tax: 0,
      isPaymentDone: false,
      isReceipted: false,
      isMailed: false,
      billedToPatient: false
    });
  }

  private getGeneratedInvoiceNo(series: Series) {
    const np = new NumberingProcess({
      isNextAvailableNumber: true,
      isAnother: false,
      isNoNumberAssigned: false,
      isOtherAvailableNumbers: false,
      nextAvailableNumber: series.lastNumber,
      anotherNumber: 0,
      otherAvailableNumberList: [],
      otherAvailableNumber: ''
    });
    const gno = this._numberingProcessService.GetGeneratedNumber(np, 5, {
      id: series.id,
      type: 'Bill',
      series: series.series
    });
    return gno ? gno.generateNumber : '';
  }

  private addDeliveryNoteGroup(init?:
    {
      id: number,
      date: string,
      checked: boolean,
      issuerName: string,
      seriesName: string,
      generateNumber: string,
      total: number,
      subTotal: number,
      tax: number
    }) {
    const groupArray = this.formGroup.controls.deliveryNoteFormArray as FormArray;
    groupArray.push(this.getDeliveryNoteGroup(init ? init : undefined));
  }

  private async onOpenModal() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (this.args) {
        this.initNewInvoice();
        await this.setGenerateNoData();
        if (this.args.isToPatient) {
          await this.setPatientInfo();
        } else { await this.setClientInfo(); }

        this.invoice.billedToPatient = this.args.isToPatient ? this.args.isToPatient : false;
        await this.loadDeliveryNoteList(this.args.isToPatient);
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  async onSelectIssuer(issuer: Issuer) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (issuer) {
        this.seriesList = await this.getBillSeriesListByIssuerId(issuer.id);
        this.invoice.issuerId = issuer.id;
        this.setIssuerInfo();
      } else { this.seriesList = []; }
    } catch (error) { console.log(error); } finally {
      this.invoice.generateNumber = '';
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  onSelectSeries(id: number) {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (this.seriesList && Number(id)) {
        const series = this.seriesList.find(s => s.id === Number(id));
        if (series) {
          this.invoice.seriesId = series.id;
          this.invoice.generateNumber = this.getGeneratedInvoiceNo(series);
        }
      } else { this.invoice.generateNumber = ''; }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  onCloseModal() { this.modalClosed.emit(); }

  private async setClientInfo() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (this.invoice.clientId) {
        this.client = await this._workService
          .GetClientById(this.invoice.clientId);
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  private async setPatientInfo() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (this.invoice.patientId) {
        this.patient = await this._workService
          .GetPatientById(this.invoice.patientId);
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  private setIssuerInfo() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      if (this.invoice.issuerId) {
        const issuer = this.dropdownService.issureList
          .find(i => i.id === Number(this.invoice.issuerId));
        this.issuer = issuer ? issuer : new Issuer();
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  private async getDeliveryNoteList() {
    let deliveryNoteList = await this._deliveryNoteService
      .GetDeliveryNoteListBySubWorkId(this.args.subWorkId);
    return deliveryNoteList ? deliveryNoteList
      .filter(d => !d.isInvoiced && d.isToPatient === this.args.isToPatient) : [];
  }

  private async loadDeliveryNoteList(toPatient: boolean) {
    try {
      this._commonUiService.isSpinnerVisible = true; // filter delivery notes by patiens and clients

      const deliveryNoteList = await this.getDeliveryNoteList();
      if (deliveryNoteList.length > 0) {
        for (const delNote of deliveryNoteList) {
          this.addDeliveryNoteGroup({
            id: delNote.id,
            checked: true,
            date: delNote.generateDate,
            generateNumber: delNote.generateNo,
            issuerName: delNote.issuer.description,
            seriesName: delNote.seriesName,
            total: delNote.totalPrice,
            subTotal: delNote.subTotal,
            tax: delNote.tax
          });

        }
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  private deliveryNoteArrayListener() {
    this.formGroup.controls.deliveryNoteFormArray.valueChanges.subscribe((list: {
      id: number,
      date: string,
      checked: boolean,
      issuerName: string,
      seriesName: string,
      generateNumber: string,
      total: number,
      subTotal: number,
      tax: number
    }[]) => {

      let checkedList = list.filter(d => d.checked === true);
      checkedList = checkedList ? checkedList : [];
      this.hasChecked = checkedList.length > 0;
      const calculations = this.getCalculations(checkedList);
      this.invoice.tax = calculations.taxAmount;
      this.invoice.subTotal = calculations.finalTotal - this.invoice.tax;
      this.invoice.discountAmount = 0;
      this.invoice.baseAmount = this.invoice.subTotal - this.invoice.discountAmount;
      this.invoice.invoiceTotal = this.invoice.baseAmount + this.invoice.tax;
    });
  }

  private getCalculations(list: {
    id: number,
    date: string,
    checked: boolean,
    issuerName: string,
    seriesName: string,
    generateNumber: string,
    total: number,
    subTotal: number,
    tax: number
  }[]) {
    let sumAmount = 0,
      finalTotal = 0,
      taxAmount = 0;
    for (let index = 0; index < list.length; index++) {
      const detail = list[index];
      finalTotal += detail.total;
      sumAmount += detail.subTotal;
      taxAmount += detail.tax;
    }
    return {
      finalTotal: finalTotal,
      sumAmount: sumAmount,
      taxAmount: taxAmount
    };
  }

  async onSaveInvoice() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      const form = this.formGroup.value as {
        issuerName: string, seriesName: string,
        concept: string, deliveryNoteFormArray: {
          id: number, date: string,
          checked: boolean, issuerName: string,
          seriesName: string, generateNumber: string,
          total: number, subTotal: number
        }[]
      };
      if (form && form.deliveryNoteFormArray.length > 0) {
        this.invoice.comments = form.concept;
        const response = await this._invoiceService.AddInvoice(this.invoice);
        if (response) {
          if (response['_body']) {
            const body = JSON.parse(response['_body']);
            if (body._isSuccsess) {
              const invoice = body._data as InvoiceHeader;
              if (invoice) {
                const detailList = form.deliveryNoteFormArray.filter(d => d.checked)
                  .map(d => ({
                    invoiceId: invoice.id,
                    deliveryNoteId: d.id,
                    workId: this.args.workId
                  }));

                if (detailList) {
                  const isSaved = await this._invoiceService.AddInvoiceDetailList(
                    detailList as InvoiceDetail[]
                  );
                  if (isSaved) {

                    this._deliveryNoteService.setChangedDeliveryNoteList(
                      new DeliveryNoteListChangedSubjectArgs({
                        recentlySavedInfo: {
                          invoiceIssuerId: this.invoice.issuerId,
                          invoiceIssuerName: this.invoice.issuerName,
                          invoiceSeriesId: this.invoice.seriesId,
                          invoiceSeriesName: this.invoice.seriesName,
                          toPatient: this.invoice.billedToPatient,
                          clientId: this.invoice.clientId,
                          patientId: this.invoice.patientId,
                          workId: this.args.workId
                        }
                      }));

                    this._toastyService.success({
                      title: this._commonDataService.localizationLabelList['invoice'],
                      msg: this._commonDataService.localizationLabelList['save_success']
                    });
                    // this.invoiceSaved.emit(invoice);
                  } else {
                    this._toastyService.error({
                      title: this._commonDataService.localizationLabelList['invoice'],
                      msg: this._commonDataService.localizationLabelList['save_error']
                    });
                  }
                }
              }
            } else {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['invoice'],
                msg: this._commonDataService.localizationLabelList['save_error']
              });
            }
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }
}

