import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ModalService } from '../../../common/services/modal.service';
import { ModalNames, ListNames, ShipmentTypes, UrgencyType } from '../../../common/global';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MainWorkType } from '../../entities/main-work-type';
import { DropdownService } from '../../../common/services/dropdown.service';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { ValidateNgSelect, ShowInvalidFormControls } from '../../../common/services/validators';
import { Messengers } from '../../../messaging/entities/messenger';
import { ShipmentPickupDetails } from '../../../messaging/entities/shipment-pickup-details';
import * as moment from 'moment';
import { Work } from '../../entities/work';
import { CommonFunctions } from '../../../common/services/common-functions';
import { CommonDataService } from '../../../common/services/common.data.service';
import { MessagingService } from '../../../messaging/services/messaging.service';
import { ToastData, ToastyService } from 'ng2-toasty';
import { LabHolidaysService } from '../../services/lab-holidays.service';

@Component({
  selector: 'app-shipment-collection-modal',
  templateUrl: './shipment-collection-modal.component.html'
})
export class ShipmentCollectionModalComponent implements OnInit {

  showContent = false;
  selectedMwtList: MainWorkType[] = [];
  selectedWork: Work;
  shipmentPickupDetails: ShipmentPickupDetails;
  formGroup: FormGroup;
  urgencyTypeList = UrgencyType;

  @Output() messengerSaved = new EventEmitter();
  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  constructor(private _modalService: ModalService, public dropdownService: DropdownService, private _toastyService: ToastyService,
    public _commonDataService: CommonDataService, private _messagerService: MessagingService,
    private _formBuilder: FormBuilder, private _commonUiService: CommonUIService, private _labHolidaysService: LabHolidaysService) {
    this.InitFormGroup();
    this.showContent = true;
  }

  ngOnInit() {
    this.shipmentPickupDetails = new ShipmentPickupDetails();
    this.dropdownService.LoadList([ListNames.MessengerList]);
    if (this._modalService.modal === ModalNames.ShipmentModal) {
      if (this._modalService.transferable != null) {
        this.selectedMwtList = this._modalService.transferable.selectedMwtList as MainWorkType[];
        this.selectedWork = this._modalService.transferable.selectedWork as Work;
        if (!this._modalService.transferable.isEdit) { this.OnAdd(); }
      }
    }
  }

  InitFormGroup() {
    this.formGroup = this._formBuilder.group({
      mwtId: [0, ValidateNgSelect], messengerType: [],
      messengerName: [], messengerId: [],
      dateView: [], time: ['', [Validators.minLength(4)]],
      deliveryDateView: [], deliveryTime: ['', [Validators.minLength(4)]],
      urgencyType: [], numOfPackages: []
    });
  }

  OnAdd() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      this.formGroup.reset();
      if (this.selectedMwtList.length === 1) {
        this.formGroup.controls.mwtId.setValue(this.selectedMwtList[0].id);
      } else { this.formGroup.controls.mwtId.setValue(0); }
      // set default values
      this.formGroup.controls.messengerType.setValue(ShipmentTypes.Shipment);
      this.formGroup.controls.urgencyType.setValue(3);
      this.formGroup.controls.dateView.setValue(this._commonUiService.ConvertToPickerDateFormat(moment().format('YYYY-MM-DD')));
      this.formGroup.controls.deliveryDateView.setValue(this._commonUiService.ConvertToPickerDateFormat(moment().format('YYYY-MM-DD')));
      this.formGroup.controls.time.setValue((moment().format('HH:mm')));
      if (this.selectedWork.clientId) {
        const client = this.dropdownService.clientList.find(c => c.id === Number(this.selectedWork.clientId));
        if (client.messengersId) {
          const messenger = this.dropdownService.messengerList.find(m => m.id === client.messengersId);
          if (messenger) { this.OnChangeMessenger(messenger); }
        }
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  OnChangeMessenger(messenger: Messengers) {
    if (messenger) {
      this.formGroup.controls.messengerName.setValue(messenger.description);
      this.formGroup.controls.messengerId.setValue(messenger.id);
    }
  }

  OnClearMessenger() {
    this.formGroup.controls.messengerName.setValue('');
    this.formGroup.controls.messengerId.setValue(0);
  }

  async OnSaveMessenger() {
    try {
      if (this.formGroup.invalid) { ShowInvalidFormControls(this.formGroup); } else {
        this._commonUiService.isSpinnerVisible = true;
        const user = this._commonDataService.GetUserFromSession();
        const formData = this.formGroup.value as {
          mwtId: number;
          messengerType: string;
          messengerName: string;
          messengerId: number;
          date: string;
          dateView: any;
          time: string;
          deliveryDate: string;
          deliveryDateView: any;
          deliveryTime: string;
          urgencyType: number;
          numOfPackages: number;
        };
        if (formData.time) { formData.time = CommonFunctions.Get24HoursTimeFormat(formData.time.replace(':', '')); }
        if (formData.deliveryTime) { formData.deliveryTime = CommonFunctions.Get24HoursTimeFormat(formData.deliveryTime.replace(':', '')); }

        if (formData.dateView) { formData.date = this._commonUiService.ChangeNgbDateToCustomFormat(formData.dateView); }
        if (formData.deliveryDateView) {
          formData.deliveryDate = this._commonUiService.ChangeNgbDateToCustomFormat(formData.deliveryDateView);
        }

        if (this.shipmentPickupDetails.id) {
          // edit
        } else {
          this.shipmentPickupDetails = new ShipmentPickupDetails();
          this.shipmentPickupDetails.patientId = Number(this.selectedWork.patientId);
          this.shipmentPickupDetails.clientId = Number(this.selectedWork.clientId);
          this.shipmentPickupDetails.mainWorkTypeId = Number(formData.mwtId);
          this.shipmentPickupDetails.createdDate = moment().format('YYYY-MM-DD');
          this.shipmentPickupDetails.createdBy = user ? user.id : null;
          this.shipmentPickupDetails.status = true;
        }
        const selectedMwt = this.selectedMwtList.find(mwt => Number(mwt.id) === Number(formData.mwtId));
        this.shipmentPickupDetails.jobTypeNo = selectedMwt ? selectedMwt.generateNo : '';
        this.shipmentPickupDetails.isShipment = formData.messengerType === ShipmentTypes.Shipment;
        this.shipmentPickupDetails.time = formData.time;
        this.shipmentPickupDetails.date = formData.date;
        this.shipmentPickupDetails.urgencyTypeId = Number(formData.urgencyType);
        this.shipmentPickupDetails.numberOfPackages = Number(formData.numOfPackages);
        this.shipmentPickupDetails.messengerId = formData.messengerId;
        this.shipmentPickupDetails.deliveryTime = formData.deliveryTime;
        this.shipmentPickupDetails.deliveryDate = formData.deliveryDate;

        const response = await this._messagerService.SaveShipmentPickupDetails(this.shipmentPickupDetails);
        if (response) {
          if (response['_isSuccsess']) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['messenger'],
              msg: this._commonDataService.localizationLabelList['save_success'],
              showClose: true,
              theme: 'default',
              timeout: 5000,
              onAdd: (toast: ToastData) => { },
              onRemove: (toast: ToastData) => { }
            });
            this.OnClose();
            this.messengerSaved.emit();
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['messenger'],
              msg: this._commonDataService.localizationLabelList['save_error'],
              showClose: true,
              theme: 'default',
              timeout: 5000,
              onAdd: (toast: ToastData) => { },
              onRemove: (toast: ToastData) => { }
            });
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  OnClose() {
    this.showContent = false;
    this.closeModel.emit();
  }

  async onChangeDeliveryDate() {
    const deliveryDateObj = this.formGroup.controls.deliveryDateView.value;
    if (deliveryDateObj) {
      const deliveryDate = this._commonUiService.ChangeNgbDateToCustomFormat(deliveryDateObj);
      const isAvailable = await this._labHolidaysService.checkDate(deliveryDate);
      if (!isAvailable){
        this.formGroup.controls.deliveryDateView.setValue(null);
      }
    }
  }
}
