import { Pipe, PipeTransform } from '@angular/core';
import { LableConfiguration } from '../../configuration/entities/lable-configuration';

@Pipe({ name: 'cltranslate' })
export class CustomLableTranslatePipe implements PipeTransform {

  transform(value: string, code: string): string {    
    const configs: LableConfiguration[] = JSON.parse(localStorage.getItem('lableConfigs'));
    if (!configs)
      return value;

    const config = configs.find(o => o.code === code);
    if (!config)
      return value;

    if (config.text)
      return config.text;

    return value;
  }
}
