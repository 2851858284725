import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import API from '../../common/api.config.json';
import { IncidentReason } from '../entities/incident-reason';

@Injectable({
  providedIn: 'root'
})
export class IncidentReasonService {

  constructor(private _apiService: ApiService) { }

  getList() {
    return this._apiService
      .get(API.incident_reason.get_list);
  }

  save(ir: IncidentReason) {
    return this._apiService
      .post(API.incident_reason.save, ir, false)
      .toPromise();
  }

  delete(id: number) {
    return this._apiService
      .delete(API.incident_reason.delete, id.toString())
      .toPromise();
  }

  searchIncidentReason(query: string) {
    return this._apiService.get(API.incident_reason.search_incident_reason + query, false)
      .map(list => list as IncidentReason[])
      .toPromise();
  }
}
