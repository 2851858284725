import { Component, OnInit } from '@angular/core';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import API from './../../../common/api.config.json';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { workType } from '../../entities/WorkType';
import { WorkTypeClientId } from '../../entities/work-type-clientId';
import { workTypeChart3 } from '../../entities/WorkTypeChart3.js';
import { workTypeChart4 } from '../../entities/WorkTypeChart4.js';

@Component({
  selector: 'app-work-statistic',
  templateUrl: './work-statistic.component.html',
  styleUrls: ['./work-statistic.component.scss']
})
export class WorkStatisticComponent implements OnInit {
  public IsIsdeliveryChart1 = false;
  public IsIsdeliveryChart2 = false;
  public IsIsdeliveryChart3 = false;
  public IsIsdeliveryChart4 = false;

  constructor(
    private _commonDataService: CommonDataService,
    private _apiService: ApiService
  ) { }

  public chartOneData: workType[];
  public chartTwoData: WorkTypeClientId[];
  public charThreeData: workTypeChart3[];
  public chartFourData: workTypeChart4[];

  public globalChartOneData = {
    chartDatasets: [
      {
        data: [],
        label: ''
      }
    ] as any[],
    chartLabels: [] as string[],
    chartOptions: {
      scaleShowVerticalLines: true,
      responsive: true
    } as any,
    chartLegend: true,
    chartType: 'bar',
    chartColors: [{ backgroundColor: 'rgb(156, 204, 101)' }]
  };

  public globalChartTwoData = {
    chartDatasets: [
      {
        data: [],
        label: ''
      }
    ] as any[],
    chartLabels: [] as string[],
    chartOptions: {
      scaleShowVerticalLines: true,
      responsive: true
    } as any,
    chartLegend: true,
    chartType: 'bar',
    chartColors: [{ backgroundColor: 'rgb(156, 204, 101)' }]
  };

  public globalChartThreeData = {
    chartDatasets: [
      {
        data: [],
        label: ''
      }
    ] as any[],
    chartLabels: [] as string[],
    chartOptions: {
      scaleShowVerticalLines: true,
      responsive: true
    } as any,
    chartLegend: true,
    chartType: 'bar',
    chartColors: [{ backgroundColor: 'rgb(156, 204, 101)' }]
  };

  public globalChartFourData = {

    chartDatasets: [
      {
        data: [],
        label: ''
      }
    ] as any[],
    chartLabels: [] as string[],
    chartOptions: {
      scaleShowVerticalLines: true,
      responsive: true
    } as any,
    chartLegend: true,
    chartType: 'bar',
    chartColors: [{ backgroundColor: 'rgb(156, 204, 101)' }]
  };

  ngOnInit() {
    this._commonDataService.isStatisticPanel.subscribe(flag => {
      if (flag) {
        this.SyncChartData();
      }
    });
  }

  SyncChartData() {
    this.LoadWorkStatisticChartOneData();
    this.LoadWorkStatisticChartTwoData();
    this.LoadWorkStatisticChartThreeData();
    this.LoadWorkStatisticChartFourData();
  }

  LoadWorkStatisticChartOneData() {
    const coData = {
      chartDatasets: [
        {
          data: [],
          label: ''
        }
      ] as any[],
      chartLabels: [] as string[],
      chartOptions: {
        scaleShowVerticalLines: true,
        responsive: true
      } as any,
      chartLegend: true,
      chartType: 'bar',
      chartColors: [{ backgroundColor: 'rgb(156, 204, 101)' }]
    };
    this.LoadWorkGlobalChartOneData()
      .then(list => {
        this.chartOneData = list;
        if (list) {
          if (list.length > 0) {
            const dataList = [] as any[];
            list.forEach(item => {
              if (item) {
                dataList.push(item.workTypeIDCount);
                coData.chartLabels.push(item.description);
              }
            });
            this.globalChartOneData = coData;
            this.IsIsdeliveryChart1 = true;
            this.globalChartOneData.chartDatasets = [
              {
                data: dataList,
                label: this._commonDataService.localizationLabelList[
                  'work_type_count'
                ],
                categoryPercentage: 0.5,
                barPercentage: 1.0
              }
            ];
          } else {
            this.chartOneData = null;
            this.IsIsdeliveryChart1 = true;
          }
        } else {
          this.chartOneData = null;
          this.IsIsdeliveryChart1 = true;
        }
      })
      .catch(ex => (this.IsIsdeliveryChart1 = true));
  }

  private async LoadWorkGlobalChartOneData(): Promise<workType[]> {
    return new Promise<workType[]>((resolve, reject) => {
      this._apiService.get(API.work.GetTypeofWorkBywork).subscribe(
        (list: workType[]) => resolve(list),
        err => reject(Error(err)),
        () => { }
      );
    });
  }

  LoadWorkStatisticChartTwoData() {
    const coData = {
      chartDatasets: [
        {
          data: [],
          label: ''
        }
      ] as any[],
      chartLabels: [] as string[],
      chartOptions: {
        scaleShowVerticalLines: true,
        responsive: true
      } as any,
      chartLegend: true,
      chartType: 'bar',
      chartColors: [{ backgroundColor: 'rgb(156, 204, 101)' }]
    };

    this.LoadWorkGlobalChartTwoData()
      .then(list => {
        this.chartTwoData = list;
        if (list) {
          if (list.length > 0) {
            const dataList = [] as any[];
            list.forEach(item => {
              if (item) {
                dataList.push(item.workTypeIDCount);
                coData.chartLabels.push(item.name);
              }
            });
            this.globalChartTwoData = coData;
            this.IsIsdeliveryChart2 = true;
            this.globalChartTwoData.chartDatasets = [
              {
                data: dataList,
                label: this._commonDataService.localizationLabelList[
                  'work_type_count'
                ],
                categoryPercentage: 0.5,
                barPercentage: 1.0
              }
            ];
          } else {
            this.chartTwoData = null;
            this.IsIsdeliveryChart2 = true;
          }
        } else {
          this.chartTwoData = null;
          this.IsIsdeliveryChart2 = true;
        }
      })
      .catch(ex => (this.IsIsdeliveryChart2 = true));
  }

  private async LoadWorkGlobalChartTwoData(): Promise<WorkTypeClientId[]> {
    return new Promise<WorkTypeClientId[]>((resolve, reject) => {
      this._apiService.get(API.work.GetTypeofWorkByClientId).subscribe(
        (list: WorkTypeClientId[]) => resolve(list),
        err => reject(Error(err)),
        () => { }
      );
    });
  }

  LoadWorkStatisticChartThreeData() {
    const coData = {
      chartDatasets: [
        {
          data: [],
          label: ''
        }
      ] as any[],
      chartLabels: [] as string[],
      chartOptions: {
        scaleShowVerticalLines: true,
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      } as any,
      chartLegend: true,
      chartType: 'bar',
      chartColors: [{ backgroundColor: 'rgb(156, 204, 101)' }]
    };
    this.LoadWorkGlobalChartThreeData()
      .then(list => {
        if (list) {
          this.charThreeData = list;
          if (list.length > 0) {
            const dataList = [] as any[];
            list.forEach(item => {
              if (item) {
                dataList.push(item.workTypeIDCount);
                coData.chartLabels.push(
                  item.description ? item.description : ''
                );
              }
            });
            this.globalChartThreeData = coData;
            this.IsIsdeliveryChart3 = true;
            this.globalChartThreeData.chartDatasets = [
              {
                data: dataList,
                label: this._commonDataService.localizationLabelList[
                  'work_type_count'
                ],
                categoryPercentage: 0.5,
                barPercentage: 0.5
              }
            ];
          } else {
            this.globalChartThreeData = null;
            this.IsIsdeliveryChart3 = true;
          }
        } else {
          this.globalChartThreeData = null;
          this.IsIsdeliveryChart3 = true;
        }
      })
      .catch(ex => (this.IsIsdeliveryChart3 = true));
  }

  private async LoadWorkGlobalChartThreeData(): Promise<workTypeChart3[]> {
    return new Promise<workTypeChart3[]>((resolve, reject) => {
      this._apiService.get(API.work.GetTypeofWorkCountByWorkType).subscribe(
        (list: workTypeChart3[]) => resolve(list),
        err => reject(Error(err)),
        () => { }
      );
    });
  }

  LoadWorkStatisticChartFourData() {
    const coData = {
      chartDatasets: [
        {
          data: [],
          label: ''
        }
      ] as any[],
      chartLabels: [] as string[],
      chartOptions: {
        scaleShowVerticalLines: true,
        responsive: true
      } as any,
      chartLegend: true,
      chartType: 'bar',
      chartColors: [{ backgroundColor: 'rgb(156, 204, 101)' }]
    };

    this.LoadWorkGlobalChartFourData()
      .then(list => {
        this.chartFourData = list;
        if (list) {
          if (list.length > 0) {
            const dataList = [] as any[];
            list.forEach(item => {
              if (item) {
                dataList.push(item.workTypeIDCount);
                coData.chartLabels.push(item.description);
              }
            });
            this.globalChartFourData = coData;
            this.IsIsdeliveryChart4 = true;
            this.globalChartFourData.chartDatasets = [
              {
                data: dataList,
                label: this._commonDataService.localizationLabelList[
                  'work_type_count'
                ],
                categoryPercentage: 0.5,
                barPercentage: 0.5
              }
            ];
          } else {
            this.globalChartFourData = null;
            this.IsIsdeliveryChart4 = true;
          }
        } else {
          this.globalChartFourData = null;
          this.IsIsdeliveryChart4 = true;
        }
      })
      .catch(ex => (this.IsIsdeliveryChart4 = true));
  }

  private async LoadWorkGlobalChartFourData(): Promise<workTypeChart4[]> {
    return new Promise<workTypeChart4[]>((resolve, reject) => {
      this._apiService.get(API.work.GetTypeofWorkCountByWorkStatus).subscribe(
        (list: workTypeChart4[]) => resolve(list),
        err => reject(Error(err)),
        () => { }
      );
    });
  }
}
