import { UserRole } from './user-role';
import { Permission } from './permission';
import { RolePermission } from './role-permission';
import { UserGroup } from './user-group';

export class PermissionListViewModel {
    roles: UserRole[];
    permissions: Permission[];
    rolePermissions: RolePermission[];
    userGroups: UserGroup[];
}