import { MotivesCancelPhase } from '../../auxiliary/entities/motives-cancel-phase';

export class MwtPhase {
  id: number;
  mainWorkTypeId: number;
  phaseId: number;
  phaseOderNo: number;
  sectionId?: number;
  phaseStatus: string;
  motiveId: number;
  motiveName: string;
  motivesCancelPhase: MotivesCancelPhase;
  startDate: string;
  startDateObject: any;
  endDate: string;
  endDateObject: any;
  workId: number;
  subWorkId: number;
  mwtArticleId?: number; // defines the article of a particular phase
  mwtArticleName: string;
  locationId: number;

  // MwtPhase expand list properties
  mainWorkTypeNumber: string;
  workNumber: string;
  subWorkNumber: string;
  clientName: string;
  phaseName: string;
  labSectionName: string;
  motiveCancelName: string;
  workTypeName: string;
  isTest: any;
  finishDate: any;
  overrollCount: number;

  constructor(init?: Partial<MwtPhase>) {
    Object.assign(this, init);
  }

}
