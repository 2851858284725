import { Injectable } from '@angular/core';
import { Crypto } from '../../services/crypto';
import API from './../../api.config.json';
import { ApiService } from '../../services/api.service';
import { DentBoxService } from 'src/app/modules/configuration/services/dent-box.service';

@Injectable()
export class DentboxValidationService {

  LICENSE: any;
  constructor(private _apiService: ApiService, private _dentBoxService: DentBoxService) {
    // const license = this.GenerateJson();
    // console.log(license);
  }

  async ValidateLicenseKey() {
    try {
      this.LICENSE = await this._dentBoxService.getDentBoxLicenseKey();
      if (!this.LICENSE) { return [false, 'license not found']; }
      if (!this.LICENSE.licenseKey) { return [false, 'license key not found']; }
      const decryptedLicenseKey = Crypto.Decrypt2(this.LICENSE.licenseKey);
      const licenseKey = Crypto.DecryptLicenseKey(decryptedLicenseKey);
      if (licenseKey) {
        const licenseObject = JSON.parse(licenseKey);
        if (licenseObject) {
          return [true, 'valid license key found'];
        }
      }
    } catch (error) {
      return [false, error];
    }
  }

  public getJSON() {
    return this._apiService.get(API.auth.getLicenseJson, false).toPromise();
  }

  private async GetLicenseData() {
    if (!this.LICENSE) { this.LICENSE = await this._dentBoxService.getDentBoxLicenseKey(); }
    if (this.LICENSE && !this.LICENSE.licenseKey) { this.LICENSE = await this._dentBoxService.getDentBoxLicenseKey(); }
    if (this.LICENSE) {
      if (this.LICENSE.licenseKey) {
        const decryptedLicenseKey = Crypto.Decrypt2(this.LICENSE.licenseKey);
        const licenseKey = Crypto.DecryptLicenseKey(decryptedLicenseKey);
        if (licenseKey) {
          const licenseObject = JSON.parse(licenseKey);
          if (licenseObject) { return licenseObject; }
        }
      }
    }
  }

  IsDentboxApiKeyAvailable() {
    const key = this.GetDentboxApiKey();
    if (key) { return true; } else { return false; }
  }

  async GetLicenseClient() {
    const licenseClient = await this.GetLicenseData();
    if (licenseClient) { return licenseClient.client_name; }
  }

  async GetIdentityDocumentNo() {
    const licenseClient = await this.GetLicenseData();
    if (licenseClient) { return licenseClient.identity_document_no; }
  }

  async GetRidoUsername() {
    const licenseClient = await this.GetLicenseData();
    if (licenseClient) { return licenseClient.rido_username; }
  }

  async GetDentboxApiKey() {
    const licenseClient = await this.GetLicenseData();
    if (licenseClient) { return licenseClient.dentbox_api_key; }
  }

  async GetNovalabGUID() {
    const licenseClient = await this.GetLicenseData();
    if (licenseClient) { return licenseClient.novalab_guid; }
  }

  // for developer purposes
  GenerateJson() {
    const licString = {
      client_name: 'NOVALAB Demos Infomed',
      identity_document_no: '1Z',
      rido_username: 'novalab_demosinfomed',
      dentbox_api_key: '8870ec82-d0ea-8626-3403-13227362af4f',
      novalab_guid: '13c5e20ac733429a8f2b0c26ddac2e44'
    };
    const str = JSON.stringify(licString);
    return Crypto.Encrypt(str);
  }

  checkDentboxServices() {
    const token = sessionStorage.getItem('ZGVudGJveC1hY2Nlc3MtdG9rZW4=');
    return token !== undefined && token !== null && token !== '';
  }
}
