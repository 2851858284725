import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastData, ToastyService } from 'ng2-toasty';
import { NgxPermissionsService } from 'ngx-permissions';
import { ListNames, ModalNames } from 'src/app/modules/common/global';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { ProductLot } from 'src/app/modules/products/entities/product-lot';
import swal from 'sweetalert2';
import { ApiService } from '../../../common/services/api.service';
import { CommonDataService } from '../../../common/services/common.data.service';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { Product } from '../../../products/entities/product';
import { WorkTypeArticleTemplate, WorkTypeProductTemplate } from '../../../work-type/entities/work-type-model-templates';
import { MainWorkType } from '../../entities/main-work-type';
import { Mwtproduct } from '../../entities/main-work-type-product';
import { Work } from '../../entities/work';
import { ArticleTemplateService } from '../../services/article-template.service';
import { ProductTemplateService } from '../../services/product-template.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';

@Component({
  selector: "products",
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  // form groups
  addProductFormGroup: FormGroup;

  // lists
  selectedMainWorkTypeList: MainWorkType[] = [];
  productTemplateList: WorkTypeProductTemplate[] = [];
  articleTemplateList: WorkTypeArticleTemplate[] = [];
  productList: Product[] = [];
  productLotList: ProductLot[] = [];
  productAutoCompleteList: any[] = [];
  currentMainWorkTypeNumberList: any[] = [];

  // objects
  transferableObj: any = {};
  mwtProduct: Mwtproduct;
  selectedWork: Work;
  hasLoaded = true;

  showPopUp = false;
  showPopUp2 = false;
  isDisabledIcon = false;
  canEditWork = false;
  allClosed = false;
  isClosed = false;

  constructor(
    private _workService: WorkService,
    private _apiService: ApiService,
    private __service: ProductTemplateService,
    public commonUiService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _articleTemplateService: ArticleTemplateService,
    private _formBuilder: FormBuilder,
    private _toastyService: ToastyService,
    private _modalService: ModalService,
    private _dropdownService: DropdownService, private _ngxPermissionService: NgxPermissionsService
  ) {
    this.isDisabledIcon = false;
    this._workService.detailObject.share().subscribe(args => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (
          args.invokeComponents.includes('Product') ||
          args.invokeComponents.includes('*')
        ) {
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this.selectedWork = args.selectedWork;
          this.transferableObj = args;
          this.allClosed = this.selectedMainWorkTypeList.every(x => x.isClosed);
          this.SyncProductData();
        }
      }
    });
  }

  ngOnInit() {
    this.mwtProduct = new Mwtproduct();
    this.InitDropdowns();
    const permission = this._ngxPermissionService.getPermission('EditWork');
    this.canEditWork = permission !== undefined;
  }

  private InitDropdowns() {
    try {
      this._dropdownService.LoadList([ListNames.ProductList]);
    } catch (error) {
      console.log(error);
    }
  }

  //#region CRUD

  async SyncProductData() {
    this.productTemplateList = [];
    try {
      this.hasLoaded = false;
      const idList = this.selectedMainWorkTypeList.map(m => ({
        id: Number(m.id)
      }));
      if (idList) {
        let productTemplateList = await this.__service.GetProductTemplateListByMainWorkTypeIdList(
          idList
        );
        productTemplateList = productTemplateList ? productTemplateList : [];
        this.isClosed = false;
        if (productTemplateList.length > 0) {
          for (let index = 0; index < productTemplateList.length; index++) {
            const productTemplate = productTemplateList[index];

            const currentMainWorkType = this.selectedMainWorkTypeList.find(
              m => Number(m.id) === productTemplate.mainWorkTypeId
            );

            this.currentMainWorkTypeNumberList = this.selectedMainWorkTypeList;
            if (currentMainWorkType) {
              productTemplate.order = currentMainWorkType.order;
              productTemplate.cssClass = currentMainWorkType.cssClass;
              productTemplate.symbol = currentMainWorkType.symbol;
              if (currentMainWorkType.isClosed) {
                this.isClosed = true;
                productTemplate.isClosed = true;
              } else {
                productTemplate.isClosed = false;
              }
            }
          }
        }
        this.productTemplateList = productTemplateList;
        let countNotIsMommentUpdate = this.productTemplateList.filter(b => b.isMomentUpdate === false);

        this.isDisabledIcon = (countNotIsMommentUpdate.length <= 0);

      }
    } catch (error) {
      console.error(error);
    } finally {
      this.hasLoaded = true;
    }
  }

  private async RemoveMwtProduct(product: Mwtproduct) {
    return this._apiService
      .delete(
        API.main_work_type_work.deleteMwtProduct,
        product.id.toString() + '/' + this.selectedWork.id,
        true
      )
      .toPromise();
  }

  //#endregion

  //#region Events

  async OnRemoveMwtProduct(productTemplate: WorkTypeProductTemplate) {
    if (productTemplate) {
      swal({
        title: this._commonDataService.localizationLabelList['product'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList[
          'common_yes'
        ],
        cancelButtonText: this._commonDataService.localizationLabelList[
          'common_cancel'
        ]
      }).then(async result => {
        if (result.value) {
          const product: Mwtproduct = new Mwtproduct();
          product.id = productTemplate.id;
          product.lotId = productTemplate.lotId.toString();
          product.mainWorkTypeId = productTemplate.mainWorkTypeId;
          product.price = productTemplate.price;
          product.productId = productTemplate.productId;
          product.quantity = productTemplate.quantity;
          product.stockOutDate = productTemplate.stockOutDate;
          const response = await this.RemoveMwtProduct(product);
          if (response) {
            if (response['_statusCode'] == 200) {
              this.SyncProductData();
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList['product'],
                msg: this._commonDataService.localizationLabelList[
                  'delete_success'
                ],
                showClose: true,
                theme: 'default',
                timeout: 3000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
            } else if (response['_statusCode'] == 409) {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['product'],
                msg: this._commonDataService.localizationLabelList[
                  'delete_error'
                ],
                showClose: true,
                theme: 'default',
                timeout: 3000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
            }
          }
        }
      });
    }
  }

  OnCloseModal(modal: string) {
    if (modal) {
      this.commonUiService.isPopupOpened = false;
      this.commonUiService.CloseModalEx(modal);
    }
  }

  //#endregion

  OnOpenAddProductModal(modal: string) {
    let mwtList = [] as MainWorkType[];
    for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
      const mwt = this.selectedMainWorkTypeList[index];
      if (!mwt.isClosed) {
        mwtList.push(mwt);
      }
    }
    this.showPopUp = true;
    this._modalService.Invoke(ModalNames.ProductModal, true, {
      isEdit: false,
      selectedMainWorkTypeList: mwtList,
      selectedWork: this.selectedWork
    }, false);
  }

  OnOpenAddWarehouseProductsModal(modal: string) {
    this.showPopUp2 = true;
    this.transferableObj.productTemplateList = this.productTemplateList;
    this._modalService.Invoke(ModalNames.WarehouseProductsModal, true, this.transferableObj, false
    );
  }

  OnEditMwtProductModal(productTemplpate: WorkTypeProductTemplate, modal: string) {
    let mwtList = [] as MainWorkType[];
    for (let index = 0; index < this.selectedMainWorkTypeList.length; index++) {
      const mwt = this.selectedMainWorkTypeList[index];
      if (!mwt.isClosed) {
        mwtList.push(mwt);
      }
    }
    this.showPopUp = true;
    this.transferableObj.productTemplpate = {};
    this.transferableObj.productTemplpate = productTemplpate;
    this._modalService.Invoke(ModalNames.ProductModal, true, {
      isEdit: true,
      selectedMainWorkTypeList: mwtList,
      selectedWork: this.selectedWork,
      productTemplpate: this.transferableObj.productTemplpate
    }, true);
  }

  refreshData() {
    this.SyncProductData();
  }

  OnSaveProduct(event) {
    if (event) {
      this.SyncProductData();
    }
  }
}
