import { Injectable } from '@angular/core';
import { DentboxApiService } from './dentbox-api.service';
import { DENTBOX_AUTH } from '../dentbox-paths';

@Injectable()
export class DentboxAuthService {

    constructor(private _apiService: DentboxApiService) { }

    getAccessToken(username: string, apiKey: string, center: string) {
        return this._apiService.post(DENTBOX_AUTH.GET_ACCESS_TOKEN,
            { username: username, key: apiKey }
            , true, [{ key: 'RequestCenter', value: center }]).toPromise();
    }
}
