export class IncidentTrackSearchFilter {
    incidentTypeId;
    incidentReasonId;
    fromDate;
    toDate;
    workerId;
    userId;
    workNo;
    workTypeNo;    
    clientId;
    doctorId;
    patientId;
    state;
    deliverNoteNo;
    isResolved;
    resolvedDate;
    patientName;
  }
  