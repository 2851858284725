import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Client } from 'src/app/modules/client/entities/client';
import { Series } from 'src/app/modules/client/entities/series';
import { DeliverynoteViewTemplate, ListNames, ModalNames } from 'src/app/modules/common/global';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { DeliverynoteService } from 'src/app/modules/delivery-note/services/deliverynote.service';
import { NumberingProcessService } from 'src/app/shared/numbering-process/numbering-process.service';
import swal from 'sweetalert2';
import { NumberingProcess, NumberingProcessData } from '../../../../shared/numbering-process/numbering-process';
import { Article } from '../../../articles/entities/article';
import { StatesOfWork } from '../../../auxiliary/entities/status-of-work';
import { Issuer } from '../../../client/entities/client-issuer';
import { Delay } from '../../../common/services/validators';
import { WorkTypeArticleTemplate } from '../../../work-type/entities/work-type-model-templates';
import { BillInvoiceModel } from '../../entities/bill-invoice-model';
import { DeliveryNote } from '../../entities/delivery-note';
import { SendEmail } from '../../entities/emailModel';
import { GenerateNumberModel, SavedGeneratedNumber } from '../../entities/generate-number-model';
import { IWorkComponent } from '../../entities/i-work-component';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtArticle } from '../../entities/main-work-type-article';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { DeliveryNoteService } from '../../services/delivery-note.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';
import { InvoiceHeader } from '../../../invoice/entities/invoice-header';
import { NgxPermissionsService } from 'ngx-permissions';
import * as moment from 'moment';
import { IvaCalculationService } from '../../services/iva-calculation.service';
import { IvaBreakdown } from 'src/app/modules/auxiliary/entities/iva-breakdown';
import { Patient } from '../../entities/patient';
import { Town } from 'src/app/modules/auxiliary/entities/town';
import { DropDownOption } from 'src/app/modules/common/entities/dropdown-option';
import { DeliveryNoteListChangedSubjectArgs } from '../../entities/multiple-delivery-notes-models';
import * as _ from 'lodash';
import { AddMultipleDeliverynotesComponent } from '../../popups/add-multiple-deliverynotes/add-multiple-deliverynotes.component';
import { DeliveryNoteCalculationService } from 'src/app/modules/common/services/calculations/delivery-note-calculation.service';
import { MwtColorService } from '../../services/mwt-color.service';
import { MwtColor } from '../../entities/main-work-type-color';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'delivery-notes',
  templateUrl: './delivery-notes.component.html',
  styleUrls: ['./delivery-notes.component.scss']
})
export class DeliveryNotesComponent implements OnInit, OnDestroy, IWorkComponent {
  @Input() finishedWorkEnabled: boolean;
  @Output() OnChangeDeliveryNoteList: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Input() editPostalCodeComponent: any

  // subscriptions
  private workService: Subscription;

  // form groups
  generatedNumberForInvoiceHeaderFormGroup: FormGroup;

  // lists
  deliveryNoteList: DeliveryNote[] = [];
  remainedDeliveryNoteList: DeliveryNote[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];
  mainWorkTypeList: MainWorkType[] = [];
  seriesList: Series[] = [];
  issureList: Issuer[] = [];
  deliveryMwtArticleList: MwtArticle[] = [];
  savedGeneratedNumberList: SavedGeneratedNumber[] = [];
  articleTemplateList: WorkTypeArticleTemplate[] = [];
  seriesListForBilling: Series[] = [];
  mwtStatusList: StatesOfWork[] = [];
  articleList: Article[] = [];
  ivaBreakdownList: IvaBreakdown[] = [];
  townDropdownList: Town[] = [];
  townListDropdownList: DropDownOption[] = [];
  townDropdownActiveList: Town[] = [];
  townListDropdownActiveList: DropDownOption[] = [];
  mwtColorList: MwtColor[] = [];

  // objects
  transferableObj: any = {};
  private _deliveryNoteToBeBilled: DeliveryNote;
  reportTemplateList: { value: number, description: string }[] = [];
  selectedTemplate: { value: number, description: string };
  emailmodel: SendEmail;

  selectedMainWorkType: MainWorkType;
  mwtArticle: MwtArticle;
  selectedSubWork: SubWork;
  selectedWork: Work;
  hasLoaded = true;
  deliveryNote: DeliveryNote;
  issuer: Issuer;
  usedSeries: Series;
  client: Client;
  patient: Patient;
  generateNumberModel: GenerateNumberModel;
  numOfDigits = 5;
  today: any;
  billInvoiceModel: BillInvoiceModel;
  usedSeriesForInvoiceHeader: Series;
  generateNumberModelForInvoiceHeader: GenerateNumberModel;

  currentNPData: NumberingProcessData;

  dnNumberingProcess: NumberingProcess;
  dnSeries: {
    id: number;
    type: 'Work' | 'Delivery Note' | 'Bill' | 'Budget';
    series: string;
  };
  dnMaxSize = 5;
  dnFormGroup: FormGroup;

  ivNumberingProcess: NumberingProcess;
  ivSeries: {
    id: number;
    type: 'Work' | 'Delivery Note' | 'Bill' | 'Budget';
    series: string;
  };
  ivMaxSize = 5;
  selectedDeliveryNoteToEmail?: DeliveryNote = new DeliveryNote();
  isPdf = false;
  showMutipleDeliveryNotePopup = false;
  mutipleDeliveryNoteArgs: { workId: number, subWorkId: number };
  enabledBill = false;
  enabledBillView = false;

  @ViewChild('multipleDeliveryNoteModal')
  multipleDeliveryNoteModal: AddMultipleDeliverynotesComponent;

  constructor(
    private _workService: WorkService,
    private _apiService: ApiService,
    private __service: DeliveryNoteService,
    private _deliverynoteService: DeliverynoteService,
    private __delNoteServce: DeliveryNoteService,
    private __colorService: MwtColorService,
    public commonUiService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _formBuilder: FormBuilder,
    private _toastyService: Toast,
    private _numberingProcessService: NumberingProcessService,
    private _ngxPermissionService: NgxPermissionsService,
    private _modalService: ModalService,
    private _dropdownService: DropdownService,
    private _deliveryNoteCalculationService: DeliveryNoteCalculationService
  ) {
    this.InitFormGroups();
    this.workService = this._workService.detailObject.subscribe(args => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (
          args.invokeComponents.includes('DeliveryNote') ||
          args.invokeComponents.includes('*')
        ) {
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this.selectedSubWork = args.selectedSubWork;
          this.selectedWork = args.selectedWork;
          this.mainWorkTypeList = args.mainWorkTypeList;
          this.SyncDeliveryNoteData();
          this.LoadSavedTemplate();
          this.transferableObj = args;
        }
      }
    });
    this.listenToDeliveryNoteListChange();
  }

  ngOnInit() {
    this.InitDropdowns();
    this.deliveryNote = new DeliveryNote();
    this.client = new Client();
    this.issuer = new Issuer();
    this.usedSeries = new Series();
    this.selectedWork = new Work();
    this.mwtArticle = new MwtArticle();
    this.usedSeriesForInvoiceHeader = new Series();
    this._deliveryNoteToBeBilled = new DeliveryNote();
    this.billInvoiceModel = new BillInvoiceModel();
    this.generateNumberModel = new GenerateNumberModel();
    this.generateNumberModelForInvoiceHeader = new GenerateNumberModel();
    this.patient = new Patient();

    this.dnNumberingProcess = new NumberingProcess({
      isNextAvailableNumber: true,
      nextAvailableNumber: 0,
      isAnother: false,
      isNoNumberAssigned: false,
      isOtherAvailableNumbers: false,
      anotherNumber: 1,
      otherAvailableNumber: 0
    });

    this.ivNumberingProcess = new NumberingProcess({
      isNextAvailableNumber: true,
      nextAvailableNumber: 0,
      isAnother: false,
      isNoNumberAssigned: false,
      isOtherAvailableNumbers: false,
      anotherNumber: 1,
      otherAvailableNumber: 0
    });

    this.reportTemplateList = DeliverynoteViewTemplate;
    this.selectedTemplate = DeliverynoteViewTemplate[0];

    this.InitResources();
    this.emailmodel = new SendEmail();
    const permission = this._ngxPermissionService.getPermission('AddInvoices');
    this.enabledBill = permission !== undefined;

    // const permissionView = this._ngxPermissionService.getPermission('ViewInvoices');
    // this.enabledBillView = permissionView !== undefined;
  }

  private InitDropdowns() {
    try {
      this._dropdownService.LoadList([
        ListNames.ArticleList,
        ListNames.IssuerList,
        ListNames.TownList
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  ngOnDestroy() {
    this.workService.unsubscribe();
  }

  InitFormGroups() {
    this.dnFormGroup = this._formBuilder.group({
      isNextAvailableNumber: [],
      nextAvailableNumber: [],
      isAnother: [],
      anotherNumber: [],
      isOtherAvailableNumbers: [],
      otherAvailableNumber: [],
      isNoNumberAssigned: []
    });
  }

  private async LoadSavedTemplate() {
    try {

      if (this.selectedWork) {
        this.selectedTemplate = DeliverynoteViewTemplate[0];
        // set current template (call api and get saved template for client or auxiliary template)
        const selectedCdt = await this._deliverynoteService.GetSavedTemplateByClientId(Number(this.selectedWork.clientId));
        if (selectedCdt) {
          this.selectedTemplate = this.reportTemplateList.find(rt => rt.value === selectedCdt.reportConfigValue);
          if (!this.selectedTemplate) { this.selectedTemplate = this.reportTemplateList[0]; }
        }
      }
    } catch (error) { console.log(error); }
  }

  private removeDeliveryNotes(indices: number[]) {
    for (let i = indices.length - 1; i >= 0; i--)
      this.remainedDeliveryNoteList.splice(indices[i], 1);
  }

  private loadNextPopup(savedInfo: {
    invoiceIssuerId: number;
    invoiceIssuerName: string;
    invoiceSeriesId: number;
    invoiceSeriesName: string;
    toPatient: boolean;
    patientId: number;
    clientId: number;
    workId: number;
  }) {
    try {
      let groupedDeliveryNoteList = [];
      if (savedInfo.toPatient) {
        groupedDeliveryNoteList = _.toArray(
          _.groupBy(this.remainedDeliveryNoteList, 'patientId')) as any[];
      } else {
        groupedDeliveryNoteList = _.toArray(
          _.groupBy(this.remainedDeliveryNoteList, 'clientId')) as any[];
      }
      if (groupedDeliveryNoteList) {
        const relatedGroup = groupedDeliveryNoteList[0];
        if (relatedGroup && relatedGroup[0]) {
          const dn = relatedGroup[0] as DeliveryNote;
          this.mutipleDeliveryNoteArgs = {
            clientId: savedInfo.clientId,
            issuerId: savedInfo.invoiceIssuerId,
            issuerName: savedInfo.invoiceIssuerName,
            seriesId: savedInfo.invoiceSeriesId,
            seriesName: savedInfo.invoiceSeriesName,
            workId: savedInfo.workId,
            subWorkId: dn.subWorkId,
            isToPatient: dn.isToPatient
          } as any;
          this.multipleDeliveryNoteModal.ngOnInit();
        }
      }
    } catch (error) { console.log(error); }
  }

  private listenToDeliveryNoteListChange() {
    this.__delNoteServce.deliveryNoteListChanged
      .subscribe((args: DeliveryNoteListChangedSubjectArgs) => {
        if (args && args.recentlySavedInfo) {
          const savedInfo = args.recentlySavedInfo;
          if (savedInfo.toPatient) {
            const indices = this.remainedDeliveryNoteList
              .reduce((r, dn, i) => {
                dn.isToPatient && dn.patientId === savedInfo.patientId &&
                  r.push(i); return r;
              }, []);
            if (indices) { this.removeDeliveryNotes(indices); }
          } else {
            const indices = this.remainedDeliveryNoteList
              .reduce((r, dn, i) => {
                !dn.isToPatient &&
                  dn.clientId === savedInfo.clientId &&
                  r.push(i); return r;
              }, []);
            if (indices) { this.removeDeliveryNotes(indices); }
          }
          if (this.remainedDeliveryNoteList.length > 0) {
            this.loadNextPopup(args.recentlySavedInfo);
          } else {
            this.showMutipleDeliveryNotePopup = false;
            this._workService.SetDetailObject({
              selectedMainWorkTypeList: this.selectedMainWorkTypeList,
              selectedMainWorkType: this.selectedMainWorkType,
              selectedSubWork: this.selectedSubWork,
              selectedWork: this.selectedWork,
              mainWorkTypeList: this.mainWorkTypeList,
              invokeComponents: ['Invoice', 'DeliveryNote', 'Article'],
              transferable: []
            });
          }
        }
      });
  }

  //#region CRUD

  private async SyncDeliveryNoteData() {
    this.deliveryNoteList = [];
    this.deliveryMwtArticleList = [];
    try {
      this.hasLoaded = false;
      if (this.selectedSubWork.id) {
        this.deliveryNoteList = await this.__service.GetDeliveryNoteListBySubWorkId(
          this.selectedSubWork.id
        );
        this.deliveryNoteList = this.deliveryNoteList ? this.deliveryNoteList : [];
        this._workService.deliveryNoteData = this.deliveryNoteList;
        for (let index = 0; index < this.deliveryNoteList.length; index++) {
          const deliveryNote = this.deliveryNoteList[index];
          deliveryNote.cssClass = this.GetDeliveryNoteBorderColorClass(index);
          deliveryNote.generateDateStr = deliveryNote.generateDate
            .toString()
            .substring(0, 10);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.hasLoaded = true;
      this._workService.SetDetailObject({
        selectedMainWorkTypeList: this.selectedMainWorkTypeList,
        selectedMainWorkType: this.selectedMainWorkType,
        selectedSubWork: this.selectedSubWork,
        selectedWork: this.selectedWork,
        mainWorkTypeList: this.mainWorkTypeList,
        invokeComponents: ['Article'],
        transferable: [
          { name: 'deliveryNoteList', object: this.deliveryNoteList }
        ]
      });
    }
  }

  private async RemoveDeliveryNote(id: number) {
    return this._apiService
      .delete(API.main_work_type_work.deleteDeliveryNote, id.toString())
      .toPromise();
  }

  private async InitResources() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      this.mwtStatusList = await this._workService.GetMainWorkTypeStatusList();
    } catch (error) {
      console.log(error);
    } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  //#endregion

  //#region Events

  async OnDnNumberGenerated(data: NumberingProcessData) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (data) {
        if (data.generateNumber === 'same_number') {
          this._toastyService.error({
            title: this._commonDataService.localizationLabelList['delivery_notes'],
            msg: this._commonDataService.localizationLabelList['another_no_is_equal_to_next_no']
          });
        } else {
          const isEligible = await this._numberingProcessService.CheckGenerateNumber(
            data
          );
          if (isEligible) {
            if (data.genNumber > this.dnNumberingProcess.nextAvailableNumber) {
              swal({
                title: this._commonDataService.localizationLabelList[
                  'delivery_notes'
                ],
                text:
                  'There are ' +
                  (data.genNumber -
                    this.dnNumberingProcess.nextAvailableNumber) +
                  ' numbers will be left blank. Are you sure want to continue?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this._commonDataService
                  .localizationLabelList['common_yes'],
                cancelButtonText: this._commonDataService.localizationLabelList[
                  'common_cancel'
                ]
              }).then(async result => {
                if (result.value) {
                  this.deliveryNote.generateNo = data.generateNumber;
                  this.currentNPData = data;
                } else {
                  this.commonUiService.openModal(
                    'delivery-note-numbering-process'
                  );
                }
              });
            } else {
              this.deliveryNote.generateNo = data.generateNumber;
              this.currentNPData = data;
            }
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['delivery_notes'],
              msg: this._commonDataService.localizationLabelList['invalid_gen_number_or_existing']
            });
            this.commonUiService.openModal('delivery-note-numbering-process');
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  private async LoadDnNumberingProcessComponent(series: Series) {
    try {
      if (series) {
        this.dnSeries = {
          id: series.id,
          type: 'Delivery Note',
          series: series.series
        };
        this.dnNumberingProcess = new NumberingProcess({
          isNextAvailableNumber: true,
          nextAvailableNumber: series.lastNumber,
          isAnother: false,
          isNoNumberAssigned: false,
          isOtherAvailableNumbers: false,
          anotherNumber: 1,
          otherAvailableNumber: 0
        });
        const reservedNumberList = await this._workService.GetSavedNumberListBySeriesId(
          series.id
        );
        if (reservedNumberList) {
          this.dnNumberingProcess.otherAvailableNumberList = reservedNumberList.map(
            r => r.savedNumber.toString()
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async OnOpenGenerateNumberForDeliveryNoteModal(modal: string) {
    try {
      if (this.deliveryNote.seriesId) {
        const series = await this._workService.GetSeriesById(
          Number(this.deliveryNote.seriesId)
        );
        await this.LoadDnNumberingProcessComponent(series);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.commonUiService.openModal(modal);
    }
  }

  async OnDeleteDeliveryNote(id: number) {
    if (id) {
      swal({
        title: this._commonDataService.localizationLabelList['delivery_notes'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList[
          'common_yes'
        ],
        cancelButtonText: this._commonDataService.localizationLabelList[
          'common_cancel'
        ]
      }).then(async result => {
        if (result.value) {
          const response = await this.RemoveDeliveryNote(id);      
          if (response['_statusCode'] === 200) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['delivery_notes'],
              msg: this._commonDataService.localizationLabelList['delete_success']
            });
            this.SyncDeliveryNoteData();
          }else if (response['_statusCode'] === 403) {        
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['delivery_notes'],
              msg: this._commonDataService.localizationLabelList['delete_delivery_note_error']
              });
            }            
           else if (response['_statusCode'] === 406) {       
            this._toastyService.error({
            title: this._commonDataService.localizationLabelList['delivery_notes'],
            msg: this._commonDataService.localizationLabelList['delete_paid_inovoice_delivery_note_error']
            });             
          }else{
            this._toastyService.error({
            title: this._commonDataService.localizationLabelList['delivery_notes'],
            msg: this._commonDataService.localizationLabelList['delete_delivery_note_error']
            });              
          }
        }
      });
    }
  }

  async OnPrintDeliveryNote(deliveryNote: DeliveryNote) {
    if (deliveryNote) {
      this.patient = new Patient();
      this.today = new Date().toISOString().substring(0, 10);
      this.deliveryNote = deliveryNote;
      this.deliveryNote.generateDateStr = this.deliveryNote.generateDate.toString().substring(0, 10);
      this.deliveryNote.issuerTown = '';
      if (deliveryNote.issuer && deliveryNote.issuer.townId) {
        const town = this._dropdownService.townList.find(x => x.id == deliveryNote.issuer.townId);
        if (town) { this.deliveryNote.issuerTown = town.description; }
      }
      this.selectedWork.generatedWorkNo = (this.selectedWork.generateNo + this.selectedSubWork.generateNo.toString()).toString();
      let mwtList = await this._workService.GetMainWorkTypeListBySubWorkId(this.selectedSubWork.id);
      // get color list
      const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
      if (idList) {
        this.mwtColorList = [];
        this.mwtColorList = await this.__colorService.GetMwtColorListByMainWorkTypeIdList(idList);
      }

      // getting only MWT deliverynote having MWTArticle
      if (deliveryNote.articleList.length > 0 && mwtList.length > 0) {
        mwtList = mwtList.filter(x => deliveryNote.articleList.filter(y => y.mainWorkTypeId == x.id).length);
      }

      if (mwtList.length > 0) {
        this.deliveryNote.mainWorkTypeDesc = mwtList.filter(mwt => mwt.status).map(e => e.name).join(', ');
      }
      const createdDateList = [] as any[];
      const deliveryDateList = [] as any[];
      mwtList.forEach(item => {
        if (item) {
          if (item.receiveDate) { createdDateList.push(moment(item.receiveDate).format('DD/MM/YYYY')); }
          if (item.deliveryDate) { deliveryDateList.push(moment(item.deliveryDate).format('DD/MM/YYYY')); }
        }
      });
      this.deliveryNote.createdDateList = createdDateList.join(', ');
      this.deliveryNote.deliveryDateList = deliveryDateList.join(', ');

      deliveryNote.sumPrice = deliveryNote.subTotal ? deliveryNote.subTotal : 0;
      deliveryNote.totalDiscount = deliveryNote.discount ? deliveryNote.discount : 0;
      deliveryNote.totalVatAmount = deliveryNote.tax ? deliveryNote.tax : 0;
      // vatBreakdownList
      this.ivaBreakdownList = [];
      if (deliveryNote.articleList) {
        deliveryNote.articleList.forEach(article => {
          if (article.ivaTypeId && article.ivaTypeId != 0) {
            const ivaBreakdown = new IvaBreakdown();
            ivaBreakdown.ivaTypeId = article.ivaTypeId;
            ivaBreakdown.iva = article.iva + '%';
            ivaBreakdown.base = this._deliveryNoteCalculationService.getIvaBaseAmount(article.priceWithDiscount, article.quantityWithWaste);
            ivaBreakdown.ivaAmount = article.ivaAmount;
            this.ivaBreakdownList.push(ivaBreakdown);
          }
        });
        if (this.ivaBreakdownList && this.ivaBreakdownList.length > 0) {
          this.ivaBreakdownList = this._deliverynoteService.LoadVatBreakdown(this.ivaBreakdownList);
        }
      }

      if (this.selectedWork.patientId != null) {
        this.LoadPatient();
      }

      if (this.selectedSubWork.doctorId > 0) {
        this.deliveryNote.collaboratorName = this.selectedSubWork.doctorName ? this.selectedSubWork.doctorName : '';
      }

      if (this.selectedWork.clientId) {
        const client: any = await this._workService.GetClientById(
          this.selectedWork.clientId
        );
        if (client) {
          this.client = client;
          this.client.Nif = client.nif;
          this.deliveryNote.clientTown = client.townName;
          this.deliveryNote.clientNIF = client.nif ? client.nif.trim() : '';
          await Delay(300);
          this.commonUiService.PrintHtmlReport('delivery-note-section');
        }
      }
    }
  }

  OnSelectTemplate(template: { value: number, description: string }, index: number) {
    this.selectedTemplate = template;
  }

  OnCloseModal(modal: string) {
    if (modal) {
      this.commonUiService.isPopupOpened = false;
      this.commonUiService.CloseModalEx(modal);
    }
  }
  //#endregion

  //#region Other

  private GetDeliveryNoteBorderColorClass(index: number): string {
    if (index !== undefined) {
      return this._workService.deliveryNoteBorderColorClassList[index];
    }
  }

  //#endregion

  private OnOpenDeliveryNoteModal(modal: string) {
    this.commonUiService.isSpinnerVisible = true;
    this.transferableObj.isEdit = false;
    this._modalService.Invoke(modal, true, this.transferableObj);
  }

  private OnEditDeliveryNoteModal(modal: string, id: number, isInvoiced: boolean) {
    if (isInvoiced) {
      this._toastyService.error({
        title: this._commonDataService.localizationLabelList['delivery_notes'],
        msg: this._commonDataService.localizationLabelList['used_in_invoice']
      });
      return;
    }
    this.commonUiService.isSpinnerVisible = true;
    this.transferableObj.isEdit = true;
    this.transferableObj.selectedDeliveryNoteId = id;
    this._modalService.Invoke(modal, true, this.transferableObj);
  }

  OnChangeDeliveryNote(mwtIdList) {
    this.OnChangeDeliveryNoteList.emit(mwtIdList);
  }

  OnSyncDeliveryNoteData(event) {
    this.SyncDeliveryNoteData();
  }

  private OnBillButtonClicked(deliveryNote: DeliveryNote) {
    const billableDeliveryNotes = this.deliveryNoteList.filter(dn => !dn.isInvoiced);
    if (billableDeliveryNotes) {
      this.transferableObj.deliveryNoteToBeBilled = deliveryNote;
      if (billableDeliveryNotes.length > 1) {
        this._modalService.Invoke(ModalNames.BillModal, true, this.transferableObj);
      } else if (billableDeliveryNotes.length === 1) {
        this.transferableObj.arg = 'single';
        this._modalService.Invoke(ModalNames.BillNewInvoiceModal,
          true, this.transferableObj);
      }
    }
  }

  onClickYes(args: {
    subWorkId: number, workId: number,
    issuerId: number, issuerName: string, patientId: number,
    seriesId: number, seriesName: string, clientId: number,
    fromClientSeries: boolean, fromDefaultIssuer: boolean,
    isToPatient: boolean
  }) {
    this.mutipleDeliveryNoteArgs = args;
    const delNoteList = this.deliveryNoteList.filter(dn => !dn.isInvoiced);
    this.remainedDeliveryNoteList = Object
      .assign(this.remainedDeliveryNoteList, delNoteList ? delNoteList : []);
    this.showMutipleDeliveryNotePopup = true;
  }

  onCloseMutipleDeliveryNotesModal() {
    this.showMutipleDeliveryNotePopup = false;
  }

  onInvoiceMutipleDeliveryNotes(invoice: InvoiceHeader) {
    this.showMutipleDeliveryNotePopup = false;
  }

  //#region send email

  /**
   * get fired when open email compose popup load
   * */
  async OnSendEmailModalOpen(deliveryNote: DeliveryNote) {
    try {
      this.emailmodel = new SendEmail();
      this.client = new Client();
      this.patient = new Patient();
      const user = this._commonDataService.GetUserFromSession();
      // this.selectedTemplate = budget.;
      this.commonUiService.isSpinnerVisible = true;
      if (deliveryNote) {
        this.deliveryNote = deliveryNote;
        this.deliveryNote.issuerTown = '';
        if (deliveryNote.issuer && deliveryNote.issuer.townId) {
          const town = this._dropdownService.townList.find(x => x.id == deliveryNote.issuer.townId);
          if (town) { this.deliveryNote.issuerTown = town.description; }
        }
        this.selectedWork.generatedWorkNo = (this.selectedWork.generateNo + this.selectedSubWork.generateNo.toString()).toString();
        let mwtList = await this._workService.GetMainWorkTypeListBySubWorkId(this.selectedSubWork.id);

        // getting only MWT deliverynote having MWTArticle
        if (deliveryNote.articleList.length > 0 && mwtList.length > 0) {
          mwtList = mwtList.filter(x => deliveryNote.articleList.filter(y => y.mainWorkTypeId == x.id).length);
        }

        if (mwtList.length > 0) {
          this.deliveryNote.mainWorkTypeDesc = mwtList.filter(mwt => mwt.status).map(e => e.name).join(', ');
        }
        const createdDateList = [] as any[];
        const deliveryDateList = [] as any[];
        mwtList.forEach(item => {
          if (item) {
            if (item.receiveDate) { createdDateList.push(moment(item.receiveDate).format('DD/MM/YYYY')); }
            if (item.deliveryDate) { deliveryDateList.push(moment(item.deliveryDate).format('DD/MM/YYYY')); }
          }
        });
        this.deliveryNote.createdDateList = createdDateList.join(', ');
        this.deliveryNote.deliveryDateList = deliveryDateList.join(', ');

        deliveryNote.sumPrice = deliveryNote.subTotal ? deliveryNote.subTotal : 0;
        deliveryNote.totalDiscount = deliveryNote.discount ? deliveryNote.discount : 0;
        deliveryNote.totalVatAmount = deliveryNote.tax ? deliveryNote.tax : 0;
        // vatBreakdownList
        this.ivaBreakdownList = [];
        if (deliveryNote.articleList) {
          deliveryNote.articleList.forEach(article => {
            if (article.ivaTypeId && article.ivaTypeId != 0) {
              const ivaBreakdown = new IvaBreakdown();
              ivaBreakdown.ivaTypeId = article.ivaTypeId;
              ivaBreakdown.iva = article.iva + '%';
              ivaBreakdown.base = this._deliveryNoteCalculationService.getIvaBaseAmount(article.priceWithDiscount, article.quantityWithWaste);
              ivaBreakdown.ivaAmount = article.ivaAmount;
              this.ivaBreakdownList.push(ivaBreakdown);
            }
          });
          if (this.ivaBreakdownList && this.ivaBreakdownList.length > 0) {
            this.ivaBreakdownList = this._deliverynoteService.LoadVatBreakdown(this.ivaBreakdownList);
          }
        }

        if (this.selectedWork.patientId != null) {
          this.LoadPatient();
        }

        if (this.selectedWork.clientId) {
          const client: any = await this._workService.GetClientById(
            this.selectedWork.clientId
          );
          if (client) {
            if (client.doctor) {
              if (client.doctor.length > 0) {
                const doctor = client.doctor.find(d => d.isDefault === true);
                this.deliveryNote.collaboratorName = (this.selectedWork.doctorName) ? this.selectedWork.doctorName : doctor ? doctor.name : '';
              }
            }
            this.client = client;
            this.client.Nif = client.nif;
            this.deliveryNote.clientTown = client.townName;
            this.deliveryNote.clientNIF = client.nif ? client.nif.trim() : '';
          }
        }

        if (this.deliveryNote.isToPatient == true && this.patient) {
          this.emailmodel.receiverEmail = this.patient.email != null ? this.patient.email : null;
        }
        else {
          this.emailmodel.receiverEmail = this.client.email != null ? this.client.email : null;
        }

        this.emailmodel.password = user != null && user.userRolePassword != null ? user.userRolePassword : null;
        this.emailmodel.senderEmail = user != null && user.userRoleEmail != null ? user.userRoleEmail : null;
        this.emailmodel.subject = this._commonDataService.localizationLabelList['delivery_notes'] + ' - (' + deliveryNote.generateNo + ')';
        this.emailmodel.message = this._commonDataService.localizationLabelList['delivery_notes'] + ' - (' + deliveryNote.generateNo + ')';
        this.emailmodel.attachmentName = this._commonDataService.localizationLabelList['delivery_notes'] + ' - (' + deliveryNote.generateNo + ')' + '.pdf';

        if (!this.emailmodel.senderEmail && !this.emailmodel.receiverEmail) {
          return this._toastyService.warning({
            title: this._commonDataService.localizationLabelList['email'],
            msg: this._commonDataService.localizationLabelList['sender_recipient_missing'],
          });
        } else if (!this.emailmodel.senderEmail) {
          return this._toastyService.warning({
            title: this._commonDataService.localizationLabelList['email'],
            msg: this._commonDataService.localizationLabelList['sender_missing'],
          });
        } else if (!this.emailmodel.receiverEmail) {
          swal({
            title: this._commonDataService.localizationLabelList['email'],
            text: this._commonDataService.localizationLabelList['recipient_missing'],
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
            cancelButtonText: this._commonDataService.localizationLabelList['common_cancel'],
          }).then((result) => {
            if (result.value) {
              this.commonUiService.OpenBasicModal('deliverynote-send-email');
              window.scrollTo(0, 0);
            } else {
              return;
            }
          });
        } else {
          this.commonUiService.OpenBasicModal('deliverynote-send-email');
          window.scrollTo(0, 0);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  /**
 * get fired when open email compose popup close
 * */
  OnSendEmailModalClose() {
    this.commonUiService.CloseBasicModal('deliverynote-send-email');
  }

  /**
   * get fired when sending the mail
   * */
  async OnSendEmail() {
    if (!this.emailmodel.receiverEmail) {
      return this._toastyService.error({
        title: this._commonDataService.localizationLabelList['email'],
        msg: this._commonDataService.localizationLabelList['recipient_missing'],
      });
    } else {
      this.OnSendEmailModalClose();
      this.commonUiService.isSpinnerVisible = true;
      if (this.deliveryNote) {
        this.isPdf = true;
        await Delay(300);
        const base64String = await this.commonUiService.ConvertHtmlToPdfBase64('delivery-note-section');
        if (base64String) {
          this.emailmodel.base64String = btoa(base64String);
          const response = await this.commonUiService.sendEmailRequest(this.emailmodel);
          if (response) {
            const body = JSON.parse(response['_body']);
            switch (body.status) {
              case 200:
                if (body.isSent) {
                  this.commonUiService.isSpinnerVisible = false;
                  return this._toastyService.success({
                    title: this._commonDataService.localizationLabelList['email'],
                    msg: this._commonDataService.localizationLabelList['email_send_succesfully'],
                  });
                } else {
                  this.commonUiService.isSpinnerVisible = false;
                  return this._toastyService.error({
                    title: this._commonDataService.localizationLabelList['email'],
                    msg: this._commonDataService.localizationLabelList['email_failed'],
                  });
                }
              case 404:
                this.commonUiService.isSpinnerVisible = false;
                return this._toastyService.error({
                  title: this._commonDataService.localizationLabelList['email'],
                  msg: this._commonDataService.localizationLabelList['email_failed'],
                });
            }
          } else {
            this.commonUiService.isSpinnerVisible = false;
            return this._toastyService.error({
              title: this._commonDataService.localizationLabelList['email'],
              msg: this._commonDataService.localizationLabelList['email_failed'],
            });
          }
        }
      }
    }
  }

  //#endregion

  LoadPatient() {
    this._workService.GetPatientById(this.selectedWork.patientId)
      .then(patient => {
        this.patient = patient;
      });
  }
}
