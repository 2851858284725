import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import API from '../../common/api.config.json';
import { User } from '../../auth/entities/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _apiService: ApiService) { }

  updateUserLanguage(lang: string) {
    try {
      const encodedStr = sessionStorage.getItem('auth-user');
      if (encodedStr) {
        const jsonStr = atob(encodedStr);
        const user = JSON.parse(jsonStr) as User;
        if (user) {
          user.language = lang;
          this._apiService.put(API.user.editUser + user.id, user)
            .subscribe(res => {
              sessionStorage.setItem('auth-user',
                btoa(JSON.stringify(user)));
            });
        }
      }
    } catch (error) {

    }
  }
}
