export class MwtArticle {
    id: number;
    mainWorkTypeId: number;
    articleId: number;
    quantity: number;
    waste: number;
    price: number;
    discount: number;
    isAmount: boolean;
    discountAmount: number;
    finalPrice: number;
    code: string;
    description1: string;
    quantityWithWaste: number;
    priceWithDiscount: number;
    status: boolean;
    workId: number;
    isDiscountOverridden: boolean;
    deliveryNoteArticleId: number;
    autoCompleListItem: string;
    workTypeId: number;
    createdBy: number;
    createdDate: string;
    workTypeArticleId: number;

    isEdit: boolean;
    isEdited: boolean;
    isDuplicate: boolean;
    isEmpty: boolean;
    isQtyEmpty: boolean;

    ivaTypeId: number | null;
    ivaPercentage: number | null;
    ivaAmount: number | null;

    lineDiscountAmount: number | null;
    lineSubTotal: number | null;

    constructor(init?: Partial<MwtArticle>) {
        if (init) { Object.assign(this, init); }
    }
}
