import { BudgetArticle } from './budget-article';
import { Issuer } from '../../auxiliary/entities/issuer';

export class Budget {
  id: number;
  description: string;
  generatedDate: any;
  issuerId?: any;
  seriesId?: number;
  generateNo: string;
  validDate?: any;
  totalPrice: number;
  isConvert?: boolean;
  status: boolean;
  createdDate?: any;

  articleList: BudgetArticle[];

  workTypeId?: number;
  workTypeName: string;

  mainWorktypeId: number;
  articleId: any; //ui

  sumPrice: number;
  totalDiscount: number;
  totalFinalPrice: number;
  totalVatAmount: number;
  cssClass: string;
  totalVat: number;

  totalWithDiscount: number;
  subTotal: number;
  discount: number;
  tax: number;

  subWorkId: number;
  isMailed: boolean;
  patientFullName: string;
  issuer: Issuer;
}
