import API from './../../../../common/api.config.json';
import { ApiService } from '../../../../common/services/api.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommonDataService } from '../../../../common/services/common.data.service';
import { TherapeuticPurpose } from '../../../entities/therapeutic-purposes';
import { Toast } from 'src/app/modules/common/services/toast.service';


@Component({
  templateUrl: './therapeutic-purposes.component.html',
  selector: 'therapeutic-purposes-list',
  styleUrls: []
})

export class TherapeuticPurposesComponent implements OnInit {

  successMessage: any;
  updateMessage: any;
  errorMessage: any;
  deleteMessage: any;
  statusMessage: any;
  deleteErrorMessage: any;
  tableName: any;

  therapeuticPurposes: TherapeuticPurpose;
  isLoad: boolean;

  position = 'bottom-right';
  therapeuticPurposesList: TherapeuticPurpose[];
  private _therapeuticPurposesList: TherapeuticPurpose[] = [];

  therapeuticPurposesFormGroup: FormGroup;
  therapeuticPurposesForm: NgForm;

  @ViewChild('description') nameField: ElementRef;
  isDisabledTherapeutic = false;

  constructor(
    private _router: Router,
    private _apiService: ApiService,
    private _fb: FormBuilder,
    private _commonDataService: CommonDataService,
    private _commonUIService: CommonUIService,
    private _toastyService: Toast) {
    this.therapeuticPurposes = new TherapeuticPurpose();
    this.isDisabledTherapeutic = false;

  }

  ngOnInit(): void {

    this.therapeuticPurposesFormGroup = this._fb.group({
      description: ['', [
        Validators.required
      ]],
      code: ['']
    })

    this.LoadTherapeuticPurposesList();
    this.isLoad = false;
  }

  LoadTherapeuticPurposesList() {
    this._apiService.get(API.therapeutic_purposes.getTherapeuticPurposeList)
      .subscribe(res => {
        this.therapeuticPurposesList = res;
        this._therapeuticPurposesList = res;
        this.isLoad = true;
      });
  }

  LoadEditTherapeuticPurposes(therapeuticPurposes: TherapeuticPurpose) {
    therapeuticPurposes.isEdit = true;
  }

  EditTherapeuticPurposes(therapeuticPurposes: TherapeuticPurpose, statusChange: boolean) {
    this.isDisabledTherapeutic = true;

    this.statusMessage = this._commonDataService.localizationLabelList['status_updated'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.tableName = this._commonDataService.localizationLabelList['therapeutic_purposes'];

    this._apiService.put(API.therapeutic_purposes.editTherapeuticPurpose + therapeuticPurposes.id, therapeuticPurposes, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {

            let message = '';

            if (statusChange) {
              message = this.statusMessage;
            }
            else {
              message = this.updateMessage;
            }

            this._toastyService.success({
              title: this.tableName,
              msg: message
            });

            this.LoadTherapeuticPurposesList();
          }
        },
        err => {
          console.log(err);
        },
        () => {
          this.isDisabledTherapeutic = false;
        }
      );
  }

  RemoveEditTherapeuticPurposes(therapeuticPurposes: TherapeuticPurpose) {
    therapeuticPurposes.isEdit = false;
    this.LoadTherapeuticPurposesList();
  }

  ActiveDeactiveTherapeuticPurposes(therapeuticPurposes: TherapeuticPurpose) {

    if (therapeuticPurposes) {
      if (therapeuticPurposes.status) {
        therapeuticPurposes.status = false;
      }
      else {
        therapeuticPurposes.status = true;
      }
      this.EditTherapeuticPurposes(therapeuticPurposes, true);
    }
  }

  OnOpenAddTherapeuticPurposesModal() {

    this.successMessage = this._commonDataService.localizationLabelList['save_success'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.errorMessage = this._commonDataService.localizationLabelList['save_error'];
    this.tableName = this._commonDataService.localizationLabelList['therapeutic_purposes'];

    this.therapeuticPurposes = new TherapeuticPurpose(); this.therapeuticPurposesFormGroup.reset();
    this._commonUIService.openModal('therapeutic-purposes-modal');

    setTimeout(() => {
      this.nameField.nativeElement.focus();
    }, 100);

  }

  OnCloseTherapeuticPurposesModal(event) {
    (((((event.target.parentElement.parentElement).parentElement).parentElement).parentElement).parentElement).classList.remove('md-show');
  }

  SaveTherapeuticPurposes(therapeuticPurposes: TherapeuticPurpose, event) {
    this.isDisabledTherapeutic = true;

    if (this.therapeuticPurposesFormGroup.invalid) {
      Object.keys(this.therapeuticPurposesFormGroup.controls).forEach(field => {
        const control = this.therapeuticPurposesFormGroup.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      this.isDisabledTherapeutic = false;

      return;
    }

    this.OnCloseTherapeuticPurposesModal(event);

    this._apiService.post(API.therapeutic_purposes.saveTherapeuticPurpose, therapeuticPurposes, false)
      .subscribe(
        res => {
          if (res.status == 204 || res.status == 200) {
            console.log(res);

            this._toastyService.success({
              title: this.tableName,
              msg: this.successMessage
            });
            this.LoadTherapeuticPurposesList();
          }
        },
        err => {
          this._toastyService.error({
            title: this.tableName,
            msg: err
          });
          this.isDisabledTherapeutic = false;
        },
        () => {
          this.isDisabledTherapeutic = false;
        }
      );
  }

  DeleteTherapeuticPurposes(id: number) {
    this.isDisabledTherapeutic = true;

    this.deleteMessage = this._commonDataService.localizationLabelList['delete_success'];
    this.deleteErrorMessage = this._commonDataService.localizationLabelList['delete_error'];
    this.tableName = this._commonDataService.localizationLabelList['therapeutic_purposes'];

    if (id) {
      this._apiService.delete(API.therapeutic_purposes.deleteTherapeuticPurpose, id.toString())
        .subscribe(
          res => {
            if (res._statusCode == 200) {
              let index: number = this._therapeuticPurposesList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._therapeuticPurposesList.splice(index, 1);
                this._toastyService.success({
                  title: this.tableName,
                  msg: this.deleteMessage
                });
              }
            }
            if (res._statusCode == 409) {
              let index: number = this._therapeuticPurposesList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._toastyService.warning({
                  title: this.tableName,
                  msg: this.deleteErrorMessage
                });
              }
            }
          },
          err => {
            this._toastyService.error({
              title: this.tableName,
              msg: err
            });
            this.isDisabledTherapeutic = true;
          },
          () => {
            this.isDisabledTherapeutic = false;
            this.therapeuticPurposesList = this._therapeuticPurposesList;
          }
        );
      this.isDisabledTherapeutic = true;

    }
  }
}

