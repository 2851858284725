import { Injectable } from '@angular/core';
import { CalculationService } from './calculation.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceCalculationService extends CalculationService {

  constructor() { super(); }
}
