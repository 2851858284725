import { Town } from "../../auxiliary/entities/town";

export class Patient {
  id: number;
  code: string;
  name: string;
  surnames: string;
  historyNumber: string;
  clinicId?: number;
  clientId: number;
  notes: string;
  townId?: number;
  address: string;
  postalCode: string;
  nif: string;
  dateTime?: any;
  status: boolean;

  dateOfBirth: any;
  dateOfBirthView: any;
  searchText: string;
  townName: string;
  provinceName: string;
  countryName: string;
  clientName: string;
  fullName: string;
  fullNameWithHistoryNumber: string;
  email: string;

  town : Town;
  
  constructor() {
    this.status = true;
  }
}
