import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Article } from 'src/app/modules/articles/entities/article';
import { ModalNames } from 'src/app/modules/common/global';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { DeliveryNote } from '../../entities/delivery-note';
import { MainWorkType } from '../../entities/main-work-type';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { WorkService } from '../../services/work.service';
import { SeriesTypes } from '../../../common/global';
import { DropdownService } from '../../../common/services/dropdown.service';

@Component({
  selector: 'app-bill-model',
  templateUrl: './bill-model.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillModelComponent implements OnInit, AfterViewInit {
  showContent = false;

  // objects
  deliveryNoteToBeBilledObj: any = {};
  _deliveryNoteToBeBilled: DeliveryNote = new DeliveryNote();
  transferable: any = {};
  selectedWork: Work;
  selectedSubWork: SubWork;
  selectedMainWorkType: MainWorkType;

  // lists
  selectedMainWorkTypeList: MainWorkType[] = [];
  mainWorkTypeList: MainWorkType[];
  articleList: Article[] = [];

  @Output() yesClicked = new EventEmitter<{ workId: number, subWorkId: number }>();

  constructor(
    private _modalService: ModalService,
    private cdref: ChangeDetectorRef,
    private _workService: WorkService
  ) { }

  ngOnInit() { }

  ngAfterViewInit(): void {

    this._modalService.modalSubject.subscribe(modalObject => {
      if (modalObject) {
        if (modalObject.modal === ModalNames.BillModal) {
          if (modalObject.flag && modalObject.transferable != null) {
            this.selectedMainWorkTypeList =
              modalObject.transferable.selectedMainWorkTypeList;
            this.selectedSubWork = modalObject.transferable.selectedSubWork;
            this.selectedWork = modalObject.transferable.selectedWork;
            this.mainWorkTypeList = modalObject.transferable.mainWorkTypeList;
            this._deliveryNoteToBeBilled =
              modalObject.transferable.deliveryNoteToBeBilled;
            this.deliveryNoteToBeBilledObj = modalObject.transferable;
            this.cdref.markForCheck();
          }
          this.showContent = modalObject.flag;
        }
      }
    });
  }

  OnClose() {
    this.showContent = false;
  }

  async OnBillDeliveryNotes(arg: string, modal: string, event) {
    this.showContent = false;

    if (arg === 'all') {
      let args: any;
      let clientSeriesList = await this._workService
        .GetSeriesByClientId(Number(this.selectedWork.clientId));
      clientSeriesList = clientSeriesList ? clientSeriesList : [];
      if (clientSeriesList.length > 0) {
        const invoiceClientSeries = clientSeriesList.find(cs => cs.seriesType.toLowerCase()
          === SeriesTypes.Invoice.toLowerCase());

        if (invoiceClientSeries) {
          args = {
            subWorkId: this.selectedSubWork.id,
            workId: this.selectedWork.id,
            issuerId: invoiceClientSeries.issuerId,
            issuerName: invoiceClientSeries.issuerName,
            seriesId: invoiceClientSeries.seriesId,
            seriesName: invoiceClientSeries.seriesName,
            clientId: this.selectedWork.clientId,
            fromClientSeries: true,
            fromDefaultIssuer: false
          };
        } else {
          const defaultIssuer = await this._workService.GetDefaultIssuer();
          if (defaultIssuer) {
            args = {
              subWorkId: this.selectedSubWork.id,
              workId: this.selectedWork.id,
              issuerId: defaultIssuer.id,
              issuerName: defaultIssuer.description,
              seriesId: null,
              seriesName: null,
              clientId: this.selectedWork.clientId,
              fromClientSeries: false,
              fromDefaultIssuer: true
            };
          }
        }
      } else {
        const defaultIssuer = await this._workService.GetDefaultIssuer();
        if (defaultIssuer) {
          args = {
            subWorkId: this.selectedSubWork.id,
            workId: this.selectedWork.id,
            issuerId: defaultIssuer.id,
            issuerName: defaultIssuer.description,
            seriesId: null,
            seriesName: null,
            clientId: this.selectedWork.clientId,
            fromClientSeries: false,
            fromDefaultIssuer: true
          };
        }
      }
      if (args) {
        if (this._deliveryNoteToBeBilled) {
          args.patientId = this._deliveryNoteToBeBilled.patientId;
          args.isToPatient = this._deliveryNoteToBeBilled.isToPatient;
        }
        this.yesClicked.emit(args);
      }
    } else {
      this.deliveryNoteToBeBilledObj.deliveryNoteToBeBilled = this._deliveryNoteToBeBilled;
      this.deliveryNoteToBeBilledObj.arg = arg;
      this._modalService.Invoke(
        ModalNames.BillNewInvoiceModal,
        true,
        this.deliveryNoteToBeBilledObj
      );
    }
  }
}
