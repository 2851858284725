import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { InvoicesPerMonth } from '../../entities/invoices-statistic';
import { ApiService } from '../../../common/services/api.service';
import API from '../../../common/api.config.json';
import { InvoiceSubDetails } from '../../entities/invoice-sub-total';
import { Subscription } from 'rxjs';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { InvoiceDiscountDetails } from '../../entities/invoice-discount';
import { NovaCurrencyPipe } from 'src/app/modules/common/pipes/nova-currency.pipe';

@Component({
  selector: 'app-invoice-statistic',
  templateUrl: './invoice-statistic.component.html'
})
export class InvoiceStatisticComponent implements OnInit, OnDestroy {
  public IsLoadInvoicingChart1: boolean = false;
  public IsLoadInvoicingChart2: boolean = false;
  public IsLoadInvoicingChart3: boolean = false;

  private __subscription: Subscription;

  public InvoiceSubDetails: InvoiceSubDetails[];
  public InvoiceDiscountDetails: InvoiceDiscountDetails[];

  public globalChartOneDataTotalPrice = {
    chartDatasets: [
      {
        data: [],
        label: ''
      }
    ] as any[],
    chartLabels: [] as string[],
    chartOptions: {
      scaleShowVerticalLines: true,
      responsive: true
    } as any,
    chartLegend: true,
    chartType: 'bar',
    chartColors: [{ backgroundColor: 'rgb(156,204,101)' }]
  };

  public globalChartOneDataTotalDiscout = {
    chartDatasets: [
      {
        data: [],
        label: ''
      }
    ] as any[],
    chartLabels: [] as string[],
    chartOptions: {
      scaleShowVerticalLines: true,
      responsive: true
    } as any,
    chartLegend: true,
    chartType: 'bar',
    chartColors: [{ backgroundColor: 'rgb(156,204,101)' }]
  };

  public invoicesPerMonthChart = {
    chartDatasets: [
      {
        data: [],
        label: ''
      }
    ] as any[],
    chartLabels: [] as string[],
    chartOptions: {
      scaleShowVerticalLines: true,
      responsive: true
    } as any,
    chartLegend: true,
    chartType: 'bar',
    chartColors: [{ backgroundColor: 'rgb(156,204,101)' }]
  };

  constructor(
    private _apiService: ApiService,
    public commonUIService: CommonUIService,
    public _commonDataService: CommonDataService,
    private novaCurrencyPipe: NovaCurrencyPipe
  ) { }

  ngOnInit() {
    this.__subscription = this._commonDataService.isStatisticPanel.subscribe(
      flag => {
        if (flag) {
          this.LoadInvoicesPerMonthList();
          this.LoadinvoicingStatisticChartOneData();
          this.LoadinvoicingStatisticChartTwoData();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.__subscription.unsubscribe();
  }

  //#region Invoices Per Month

  LoadInvoicesPerMonthList() {
    let self = this;
    const coData = {
      chartDatasets: [
        {
          data: [],
          label: ''
        }
      ] as any[],
      chartLabels: [] as string[],
      chartOptions: {
        scaleShowVerticalLines: true,
        responsive: true,
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: this._commonDataService.localizationLabelList[
                  'months'
                ]
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (label, index, labels) {
                  return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                }
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return (
                self._commonDataService.localizationLabelList['sub_total'] +
                ': ' +
                self.novaCurrencyPipe.transform(Number(tooltipItem.yLabel))
              );
            }
          }
        }
      } as any,
      chartLegend: true,
      chartType: 'bar',
      chartColors: [{ backgroundColor: 'rgb(156,204,101)' }]
    };

    this.LoadInvoicesPerMonthListData().then(list => {
      if (list) {
        if (list.length > 0) {
          const dataList = [] as any[];
          list.forEach(item => {
            if (item) {
              dataList.push(item.subTotal);
              coData.chartLabels.push(item.invoiceMonth.toString());
            }
          });
          this.invoicesPerMonthChart = coData;
          this.invoicesPerMonthChart.chartDatasets = [
            {
              data: dataList,
              label: this._commonDataService.localizationLabelList['sub_total'],
              categoryPercentage: 0.5,
              barPercentage: 0.25
            }
          ];
          this.IsLoadInvoicingChart3 = true;
        } else {
          this.invoicesPerMonthChart = null;
          this.IsLoadInvoicingChart3 = true;
        }
      } else {
        this.invoicesPerMonthChart = null;
        this.IsLoadInvoicingChart3 = true;
      }
    });
  }

  private async LoadInvoicesPerMonthListData(): Promise<InvoicesPerMonth[]> {
    return new Promise<InvoicesPerMonth[]>((resolve, reject) => {
      this._apiService.get(API.invoice.getInvoicesPerMonthChartData).subscribe(
        (list: InvoicesPerMonth[]) => resolve(list),
        err => reject(err)
      );
    });
  }

  //#endregion

  LoadinvoicingStatisticChartOneData() {
    let self = this;
    const coData = {
      chartDatasets: [
        {
          data: [],
          label: ''
        }
      ] as any[],
      chartLabels: [] as string[],
      chartOptions: {
        scaleShowVerticalLines: true,
        responsive: true,
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (label, index, labels) {
                  return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                }
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return (
                self._commonDataService.localizationLabelList['total_billing'] +
                ': ' +
                self.novaCurrencyPipe.transform(Number(tooltipItem.yLabel))
              );
            }
          }
        }
      } as any,
      chartLegend: true,
      chartType: 'bar',
      chartColors: [{ backgroundColor: 'rgb(156,204,101)' }]
    };

    this.LoadInvoicingleGlobalChartOneData().then(list => {
      if (list) {
        if (list.length > 0) {
          const dataList = [] as any[];
          list.forEach(item => {
            if (item) {
              dataList.push(item.price);
              coData.chartLabels.push(item.clientName);
            }
          });
          this.globalChartOneDataTotalPrice = coData;
          this.globalChartOneDataTotalPrice.chartDatasets = [
            {
              data: dataList,
              label: this._commonDataService.localizationLabelList[
                'total_billing'
              ],
              categoryPercentage: 0.5,
              barPercentage: 1.0
            }
          ];
          this.IsLoadInvoicingChart1 = true;
        } else {
          this.globalChartOneDataTotalPrice = null;
          this.IsLoadInvoicingChart1 = true;
        }
      } else {
        this.globalChartOneDataTotalPrice = null;
        this.IsLoadInvoicingChart1 = true;
      }
    });
  }

  private async LoadInvoicingleGlobalChartOneData(): Promise<
    InvoiceSubDetails[]
  > {
    return new Promise<InvoiceSubDetails[]>((resolve, reject) => {
      this._apiService.get(API.invoice.GetInvoicePerClientPriceTotal).subscribe(
        (list: InvoiceSubDetails[]) => resolve(list),
        err => reject(Error(err)),
        () => { }
      );
    });
  }

  LoadinvoicingStatisticChartTwoData() {
    let self = this;
    const coData = {
      chartDatasets: [
        {
          data: [],
          label: ''
        }
      ] as any[],
      chartLabels: [] as string[],
      chartOptions: {
        scaleShowVerticalLines: true,
        responsive: true,
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (label, index, labels) {
                  return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                }
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return (
                self._commonDataService.localizationLabelList[
                'total_discount'
                ] +
                ': ' +
                self.novaCurrencyPipe.transform(Number(tooltipItem.yLabel))
              );
            }
          }
        }
      } as any,
      chartLegend: true,
      chartType: 'bar',
      chartColors: [{ backgroundColor: 'rgb(156,204,101)' }]
    };

    this.LoadInvoicingleGlobalChartTwoData().then(list => {
      if (list) {
        if (list.length > 0) {
          const dataList = [] as any[];
          list.forEach(item => {
            if (item) {
              dataList.push(item.discount);
              coData.chartLabels.push(item.clientName);
            }
          });
          this.globalChartOneDataTotalDiscout = coData;
          this.globalChartOneDataTotalDiscout.chartDatasets = [
            {
              data: dataList,
              label: this._commonDataService.localizationLabelList[
                'total_discount'
              ],
              categoryPercentage: 0.5,
              barPercentage: 1.0
            }
          ];
          this.IsLoadInvoicingChart2 = true;
        } else {
          this.globalChartOneDataTotalDiscout = null;
          this.IsLoadInvoicingChart2 = true;
        }
      } else {
        this.globalChartOneDataTotalDiscout = null;
        this.IsLoadInvoicingChart2 = true;
      }
    });
  }

  private async LoadInvoicingleGlobalChartTwoData(): Promise<
    InvoiceDiscountDetails[]
  > {
    return new Promise<InvoiceDiscountDetails[]>((resolve, reject) => {
      this._apiService
        .get(API.invoice.GetInvoicePerClientDiscountTotal)
        .subscribe(
          (list: InvoiceDiscountDetails[]) => resolve(list),
          err => reject(Error(err)),
          () => { }
        );
    });
  }
}
