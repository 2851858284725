import API from './../../../common/api.config.json';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { WorkService } from '../../services/work.service';
import { MainWorkType } from '../../entities/main-work-type';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Work } from '../../entities/work';
import { MwtIncident } from '../../entities/main-work-type-incident';
import { IncidentService } from '../../services/mwt-incident.service';
import { DropdownService } from '../../../common/services/dropdown.service';
import { ListNames } from '../../../common/global';
import swal from 'sweetalert2';
import { CommonDataService } from '../../../common/services/common.data.service';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { Toast } from '../../../common/services/toast.service';
import { ApiService } from '../../../common/services/api.service';

@Component({
  selector: 'app-incident-work-view',
  templateUrl: './incident-work-view.component.html',
  styleUrls: ['./incident-work-view.component.scss']
})
export class IncidentWorkViewComponent implements OnInit {

  // lists
  selectedMainWorkTypeList: MainWorkType[] = [];
  mwtIncidentDataList: MwtIncident[] = [];
  selectedWork: Work;

  // objects
  private _subscription: Subscription;
  hasLoaded = true;

  @Output() refresh = new EventEmitter();
  showPopUp = false;
  allClosed = false;

  constructor(private __service: WorkService, private _incidentService: IncidentService, public _commonUiService: CommonUIService,
    private _modalService: ModalService, public _dropdownService: DropdownService, private _commonDataService: CommonDataService,
    private _apiService: ApiService, private _toastyService: Toast) {
    this._subscription = this.__service.detailObject.subscribe((args) => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (args.invokeComponents.includes('WorkType') || args.invokeComponents.includes('*')) {
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this.selectedWork = args.selectedWork;
          this.SyncMwtIncident();
        }
      }
    });
    this.mwtIncidentDataList = [];
  }

  async ngOnInit() {
    if (this._dropdownService.incidentReasonList.length === 0) {
      this._dropdownService.LoadListAsync([
        ListNames.IncidentList
      ]);
    }
    if (this._dropdownService.workerList.length === 0) {
      this._dropdownService.LoadListAsync([
        ListNames.WorkerList
      ]);
    }
  }

  async SyncMwtIncident() {
    this.mwtIncidentDataList = [];
    try {
      this.hasLoaded = false;
      const idList = this.selectedMainWorkTypeList.map(m => ({ id: Number(m.id) }));
      if (idList) {
        let incidentDataList = await this._incidentService.GetIncidentListByMainWorkTypeIdList(idList);
        incidentDataList = incidentDataList ? incidentDataList : [];
        if (incidentDataList.length > 0) {
          for (let index = 0; index < incidentDataList.length; index++) {
            const incidentData = incidentDataList[index];
            const currentMainWorkType = this.selectedMainWorkTypeList.find(m => Number(m.id) === incidentData.mainWorkTypeId);
            if (currentMainWorkType) {
              incidentData.order = currentMainWorkType.order;
              incidentData.cssClass = currentMainWorkType.cssClass;
              incidentData.symbol = currentMainWorkType.symbol;
              if (currentMainWorkType.isClosed) {
                incidentData.isClosed = true;
              } else {
                incidentData.isClosed = false;
              }
            }

            if (incidentData.mwtincidentDocument) {
              for (let index = 0; index < incidentData.mwtincidentDocument.length; index++) {
                const document = incidentData.mwtincidentDocument[index];
                if (document) {
                  document.extension = document.fileName.split('.')[(document.fileName.split('.')).length - 1];
                  document.extension = (document.extension) ? document.extension.toLowerCase() : document.extension;
                  document.link = this._apiService.BASE_URL + API.incident_data.getIncidentDocument + document.id + '/' + incidentData.mainWorkTypeId + '/' + document.generateFileName;
                  if (document.path) {
                    if (document.extension === 'pdf') {
                      document.path = document.path.toString().includes('data:application/pdf;base64,')
                        ? document.path : 'data:application/pdf;base64,' + document.path;
                    }
                    if (document.extension === 'png') {
                      document.path = document.path.toString().includes('data:image/png;base64,')
                        ? document.path : 'data:image/png;base64,' + document.path;
                    }
                    if (document.extension === 'jpg') {
                      document.path = document.path.toString().includes('data:image/jpg;base64,')
                        ? document.path : 'data:image/jpg;base64,' + document.path;
                    }
                    if (document.extension === 'jpeg') {
                      document.path = document.path.toString().includes('data:image/jpeg;base64,')
                        ? document.path : 'data:image/jpeg;base64,' + document.path;
                    }
                    if (document.extension === 'docx') {
                      document.path = document.path.toString().includes('https://docs.google.com/gview?url=')
                        ? document.path : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
                    }
                    if (document.extension === 'xlsx' || document.extension === 'xls') {
                      document.path = document.path.toString().includes('https://docs.google.com/gview?url=')
                        ? document.path : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
                    }
                  }
                }
              }
            }
          }
        }
        this.mwtIncidentDataList = incidentDataList;
      }
    } catch (error) { console.log(error); }
    finally { this.hasLoaded = true; }
  }

  async onRemoveMwtIncidentData(id: number) {
    if (id) {
      swal({
        title: this._commonDataService.localizationLabelList['delete_incident'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      }).then(async (result) => {
        if (result.value) {
          this._commonUiService.isSpinnerVisible = true;
          await this._incidentService.DeleteIncident(id, this.selectedWork.id).then(isDeleted => {
            if (isDeleted) {
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList['incident'],
                msg: this._commonDataService.localizationLabelList['delete_success']
              });
              this.SyncMwtIncident();
            } else {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['incident'],
                msg: this._commonDataService.localizationLabelList['delete_error']
              });
            }
            this._commonUiService.isSpinnerVisible = false;
          });
        }
      }).catch(error => {
        console.log(error);
        this._commonUiService.isSpinnerVisible = false;
      });
    }
  }

  OnOpenModal(modal: string) {
    this.showPopUp = true;
    this._modalService.Invoke(modal, true, {
      mainWorkTypeList: this.selectedMainWorkTypeList,
      selectedWork: this.selectedWork,
      isEdit: false,
    }, false);
  }

  onOpenEditModal(incidentData: MwtIncident, modal: string) {
    this.showPopUp = true;
    this._modalService.Invoke(modal, true, {
      mainWorkTypeList: this.selectedMainWorkTypeList,
      selectedWork: this.selectedWork,
      incidentData: incidentData, isEdit: true
    });
  }

  async onchangeResolve(mwtincident: MwtIncident) {
    try {
      if (mwtincident.isResolved) {
        mwtincident.resolvedDate = new Date().toJSON();
      }
      else {
        mwtincident.resolvedDate = '';
      }
      this._commonUiService.isSpinnerVisible = true;
      const response = await this._incidentService.SaveIncident(mwtincident);
      if (response) {
        if (response.status === 200) {
          this._toastyService.success({
            title: this._commonDataService.localizationLabelList['incident'],
            msg: this._commonDataService.localizationLabelList['save_success']
          });
        } else {
          this._toastyService.error({
            title: this._commonDataService.localizationLabelList['incident'],
            msg: this._commonDataService.localizationLabelList['save_error']
          });
        }
        this._commonUiService.isSpinnerVisible = false;
      }
    }
    catch (error) { console.log(error); }
    finally {
      this._commonUiService.isSpinnerVisible = false;
    }
  }
}

