import { Worker } from '../../workers/entities/worker';
export class User {
    username: string;
    email: string;
    password: string;
    dirRptExp: string;
    rptGeneral: boolean;
    accesoZona: number;
    codColaborador: number;
    lastLogin: string; // not included in the form
    codEmisorDefec: number;
    pantallaInicial: string;
    idPerfil: number;
    adminGpo: boolean;
    passAdmin: string;
    roleId: number;
    roleName: string;
    status: number;
    passwordExpiryDate: string;
    token: string;
    apiKey: string;
    id: number;
    recentPasswords: string;
    ip: string;
    host: string;
    workerId: number;
    worker: Worker;
    workerName: string;
    labSectionName: string;
    userRoleEmail: string;
    userRolePassword: string;
    workerAttendance: boolean;
    startTime: string;
    createdDate: any;
    dayId: number;
    workDate: any;

    language: string;
}
