import { Article } from '../../articles/entities/article';
import { InvoiceDetail } from '../../invoice/entities/invoice-detail';
import { Issuer } from '../../auxiliary/entities/issuer';
import { Patient } from './patient';

export class DeliveryNote {
	id: number;
	description: string;
	status: boolean;
	articleList: Article[] = [];
	subWorkId?: number;
	generateDate?: any;
	generateDateStr?: string;
	issuerId?: any;
	seriesId?: number;
	generateNo: string;
	concept: string;
	isHaveBox?: boolean;
	isPrint: boolean;
	printDate?: any;
	isDelivered?: boolean;
	deliveredDate?: any;
	totalPrice?: number;
	workFinished: boolean;
	isInvoiced: boolean;
	invoiceId: number;
	workId: number;
	mainWorkTypeName: string;
	totalNoOfDeliveryNotes: number;
	cssClass: string;
	discount: number;

	invoiceDetail: InvoiceDetail[];
	isChecked: boolean;
	clientName: string;
	clientId: any;

	sumPrice: number;
	totalDiscount: number;
	totalFinalPrice: number;
	collaboratorName: string;
	totalVatAmount: number;

	subTotal: number;
	totalVat: number;
	isMailed: boolean;
	totalWithDiscount: number

	// report
	patientName: string;
	clientTel: string;
	clientNIF: string;
	clientTown: string;
	clientCP: string;
	clientAddress: string;
	issuerName: string;
	issuerEmail: string;
	issuerNIF: string;
	issuerTel: string;
  issuerTown: string;
  issuerProvince: string;
	issuerCP: string;
	issuerAddress: string;

	totalQuantity: any;
	totalPrices: any;
	tax: any;

	seriesName: string;
	workNumber: string;
	subWorkGeneratedNo: string;
	issuer: Issuer;
	generateDateView: any;
	checked: boolean;
	price: string;
	collaborator: string;
	workNo: string;
	mainWorkTypeDesc: string;
	mainWorkTypeList: string;
	doctorName: string;
	createdDateList: string;
	deliveryDateList: string;

	isClosed: boolean;

	isToPatient: boolean;
	patientId: any;
	
	constructor(init?: Partial<DeliveryNote>) { Object.assign(this, init); }
}


