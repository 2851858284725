﻿export class Phases {
    id: any;
    description: string;
    code: string;
    default: boolean;
    isTest: boolean;
    status: boolean;
    isEdit: boolean;
    day?: number;
    hour?: number;
    minute?: number;

    constructor() {
        this.status = true;
    }
}