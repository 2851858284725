export class InvoiceDetail {
    id: number;
    invoiceId: number;
    deliveryNoteId: number;
    workId?: number;
    workName: string;
    workNumber: string;
    deliveryNoteGenNo: string;
    deliveryNoteConcept: string;
    deliveryNoteGenDate: string;
    deliveryNoteIssuerId: number;
    deliveryNoteSeriesId: number;
    mwtArticleId: number;
    articleName: string;
    articleCode: string;
    articleQuantity?: number;
    articlePrice?: number;
    articleFinalPrice?: number;
    articleDiscount?: number;
    articleWaste?: number;
    articleDiscountAmount: number;
    isDiscountAmount: boolean;
    articleQuantityWithWaste: number;
    articlePriceWithDiscount: number;
    invoiceHeaderNo: string;
    isInvoiced: boolean;
    isSelected: boolean;
    isPrintInBilling: boolean;
    articleIvaAmount: number;
    articleIvaPercentage: number;
    ivaTypeId: number;

    clientName: string;
    deliveryNoteTotal: number;
    deliveryNoteSubTotal: number;
    deliveryNoteTax: number;
    deliveryNoteDiscount: number;
    deliveryNoteIssuerName: string;
    deliveryNoteSeriesName: string;

    mainWorkTypeList : any[] = [];

    constructor(init?: Partial<InvoiceDetail>) { Object.assign(this, init); }
}
