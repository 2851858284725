import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastData, ToastyService } from 'ng2-toasty';
import { MotivesCancelPhase } from 'src/app/modules/auxiliary/entities/motives-cancel-phase';
import { ModalNames } from 'src/app/modules/common/global';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { ValidateNgSelect } from 'src/app/modules/common/services/validators';
import { MwtPhase } from '../../entities/main-work-type-phase';
import API from './../../../common/api.config.json';

@Component({
  selector: 'app-cancel-phases-modal',
  templateUrl: './cancel-phases-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelPhasesModalComponent implements OnInit, AfterViewInit {
  showContent = false;
  motivesCancelPhaseFormGroup: FormGroup;
  mwtPhase: MwtPhase;
  motivesCancelPhaseList: MotivesCancelPhase[];

  constructor(private _modalService: ModalService,
    private _commonUiService: CommonUIService,
    private _formBuilder: FormBuilder,
    private _apiService: ApiService,
    private _commonDataService: CommonDataService,
    private _toastyService: ToastyService,
    private cdref: ChangeDetectorRef
  ) {
    this.mwtPhase = new MwtPhase();
    this.initform();
  }

  ngOnInit() {
    // this.initform();
    // this.modalListner();
  }

  ngAfterViewInit(): void {
    this.initform();
    this.modalListner();
  }


  initform() {
    this.motivesCancelPhaseFormGroup = this._formBuilder.group({
      motiveId: ['', [ValidateNgSelect]]
    });
  }

  modalListner() {
    this._modalService.modalSubject.subscribe(async (modalObject) => {
      if (modalObject.modal === ModalNames.CancelphasesModal) {
        if (modalObject.transferable && modalObject.transferable.mwtPhase) {
          if (modalObject.flag) {
            this.mwtPhase = modalObject.transferable.mwtPhase;
            this.cdref.markForCheck();
            this.loadData();
          }
        }
        this.showContent = modalObject.flag;
      }
      //this._commonUiService.isSpinnerVisible = false;
    });
  }


  async loadData() {
    this.motivesCancelPhaseList = await this.GetMotivesCancelPhaseList();
    this.cdref.markForCheck();
  }

  private GetMotivesCancelPhaseList() {
    return this._apiService.get(API.motives_cancel_phase.getMotivesCancelPhaseList)
      .map(list => list as MotivesCancelPhase[]).toPromise();
  }

  OnSaveMotiveForPhaseCancellation(event: any) {
    this.UpdateMwtPhaseCancelReason(this.mwtPhase.id, this.mwtPhase.motiveId)
      .then(isUpdated => {
        if (isUpdated) {
          this._toastyService.success({
            title: this._commonDataService.localizationLabelList['work_type'],
            msg: this._commonDataService.localizationLabelList['save_success'],
            showClose: true,
            theme: 'default',
            timeout: 5000,
            onAdd: (toast: ToastData) => { },
            onRemove: (toast: ToastData) => { }
          });
        } else {
          this._toastyService.error({
            title: this._commonDataService.localizationLabelList['work_type'],
            msg: this._commonDataService.localizationLabelList['save_error'],
            showClose: true,
            theme: 'default',
            timeout: 5000,
            onAdd: (toast: ToastData) => { },
            onRemove: (toast: ToastData) => { }
          });
        }
      });
    if (event) {
      this._commonUiService.CloseModal(event);
    }
  }

  OnClose() {
    this._modalService.Invoke(ModalNames.CancelphasesModal, false, '');
  }

  private UpdateMwtPhaseCancelReason(mwtPhaseId: number, motiveId: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._apiService.put(API.main_work_type_work.updateMwtPhaseCancelReason + mwtPhaseId + '/' + motiveId)
        .subscribe((isUpdated: boolean) => resolve(isUpdated),
          err => reject(err));
    });
  }

  OnSelectMotive(dropdownId: number, motive?: any) {
    if (dropdownId) {
      if (Number(dropdownId) === 1) {
        if (motive) {
          this.mwtPhase.motiveId = motive.id;
          this.mwtPhase.motiveName = motive.description;
        }
      } else {
        const motiveCancelPhase: MotivesCancelPhase = this.motivesCancelPhaseList.find(x => x.id === this.mwtPhase.motiveId);
        if (motiveCancelPhase) {
          this.mwtPhase.motiveName = motiveCancelPhase.description;
        }
      }
      this.cdref.markForCheck();
    }
  }

}
