import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalNames } from 'src/app/modules/common/global';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { ShowInvalidFormControls } from 'src/app/modules/common/services/validators';
import { ProviderViewModel } from 'src/app/modules/provider/entities/provider-view-model';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtImplantData } from '../../entities/main-work-type-implant-data';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import API from './../../../common/api.config.json';

@Component({
  selector: 'app-implant-data-model',
  templateUrl: './implant-data-model.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImplantDataModelComponent implements OnInit, AfterViewInit {
  @Output() refreshData: EventEmitter<any> = new EventEmitter();
  @Output() closeModel: EventEmitter<any> = new EventEmitter();
  showContent = false;

  // form groups
  implantDataFormGroup: FormGroup;

  // lists
  selectedMainWorkTypeList: MainWorkType[] = [];
  manufacturerList: ProviderViewModel[] = [];

  // objects
  selectedMainWorkType: MainWorkType;
  selectedSubWork: SubWork;
  implantData: MwtImplantData;
  selectedWork: Work;

  constructor(private _modalService: ModalService, private _apiService: ApiService, private _formBuilder: FormBuilder,
    public commonUiService: CommonUIService, private _commonDataService: CommonDataService,
    private _dropdownService: DropdownService, private _toastyService: Toast,
    private cdref: ChangeDetectorRef) {
    this.implantData = new MwtImplantData();
    this.implantDataFormGroup = this._formBuilder.group({
      mainWorkTypeId: [],
      model: ['', [Validators.required]],
      reference: ['', [Validators.required]],
      apical: [],
      note: [],
    });
    this.showContent = true;
  }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.implantData = new MwtImplantData();
    if (this._modalService.modal === ModalNames.ImplantDataModal) {
      if (this._modalService.transferable) {
        this.selectedMainWorkTypeList = this._modalService.transferable.mainWorkTypeList as MainWorkType[];
        this.selectedWork = this._modalService.transferable.selectedWork as Work;
        if (this._modalService.transferable.isEdit) {
          this.OnEditMwtImplantDataModal(this._modalService.transferable.implantData as MwtImplantData);
        } else {
          this.OnOpenAddImplantDataModal();
        }
      }
    }
  }

  //#region CRUD
  private SaveImplantData(implantData: MwtImplantData) {
    return this._apiService.post(API.main_work_type_work.saveImplantData, implantData, false, true)
      .map(response => JSON.parse(response['_body'])).toPromise();
  }
  //#endregion

  //#region Events
  private GetManufacturerList() {
    return this._apiService.get(API.provider_manufacturer_details.getProviderManufacturerDetails, true)
      .map(x => x as ProviderViewModel[]).toPromise();
  }

  async OnOpenAddImplantDataModal() {
    this.commonUiService.isSpinnerVisible = true;
    this.commonUiService.isPopupOpened = true;
    this.implantDataFormGroup.markAsPristine();
    this.implantDataFormGroup.markAsUntouched();
    this.implantData = new MwtImplantData();
    this.implantData.providerManufacName = '';
    this.implantData.providerManufacId = 0;
    if (this.selectedMainWorkTypeList.length === 1) {
      this.implantData.mainWorkTypeId = Number(this.selectedMainWorkTypeList[0].id);
    } else { this.implantData.mainWorkTypeId = 0; }
    this.commonUiService.isSpinnerVisible = false;
    this.cdref.markForCheck();
  }

  OnSelectManufacturer(manufacturer: ProviderViewModel): void {
    this.implantData.providerManufacId = manufacturer.id;
    this.implantData.providerManufacName = manufacturer.name;
    this.cdref.markForCheck();
  }

  async OnEditMwtImplantDataModal(implantData: MwtImplantData) {
    if (implantData) {
      this.commonUiService.isSpinnerVisible = true;
      this.commonUiService.isPopupOpened = true;
      this.implantDataFormGroup.markAsPristine();
      this.implantDataFormGroup.markAsUntouched();
      this.implantData = Object.assign(this.implantData, implantData);
      this.implantData.providerManufacName = implantData.manufacturerName;
      this.commonUiService.isSpinnerVisible = false;
      this.cdref.markForCheck();
    }
  }

  async OnSaveMwtImplantData(event: any) {
    if (this.implantDataFormGroup.invalid) { ShowInvalidFormControls(this.implantDataFormGroup); return; }
    try {
      this.implantData.workId = this.selectedWork.id;
      const response = await this.SaveImplantData(this.implantData);
      if (response) {
        this.commonUiService.isSpinnerVisible = true;
        if (response['_isSuccsess']) {
          this._toastyService.success({
            title: this._commonDataService.localizationLabelList['implant_data'],
            msg: this._commonDataService.localizationLabelList['save_success']
          });
          this.refreshData.emit();
        } else {
          this._toastyService.error({
            title: this._commonDataService.localizationLabelList['implant_data'],
            msg: this._commonDataService.localizationLabelList['save_error']
          });
        }
        this.OnClose();
        this.commonUiService.isSpinnerVisible = false;
      }
    } catch (error) { console.log(error); }
    finally {
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  OnClose() {
    this.showContent = false;
    this.closeModel.emit();
    this.commonUiService.isPopupOpened = false;
  }

  //#endregion
}
