import API from '../../../common/api.config.json';
import { ApiService } from '../../../common/services/api.service';
import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { RequestData } from '../../../invoice/pagination/requestData';
import { Series } from '../../entities/series';
import { Client } from '../../entities/client';
import { DropDownOption } from '../../../common/entities/dropdown-option';
import { CommonDataService } from '../../../common/services/common.data.service';
import { InvoiceHeader } from '../../entities/invoice-header';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Issuer } from '../../../client/entities/client-issuer';
import { Delay } from '../../../common/services/validators';
import { LanguageService } from 'src/app/modules/common/services/ui/language.service';
import { FormControl } from '@angular/forms';
import { BillingClientPatient } from '../../entities/billing-client-patient';
import { InvoiceService } from 'src/app/modules/work/services/invoice.service';

@Component({
  selector: 'app-invoice-expand',
  templateUrl: './invoice-expand.component.html',
  styleUrls: ['./invoice-expand.component.scss']
})

export class InvoiceExpandComponent implements OnInit {

  @Output() public OnSelectInvoiceHeader: EventEmitter<InvoiceHeader> = new EventEmitter<InvoiceHeader>();

  private invoiceList = 1;

  //pagination propeties
  sessionGetUrl: string;
  pagInvoiceList: any[] = [];
  requestData: RequestData = new RequestData(1, 20, '', '', '', this.invoiceList);
  refresh = true;
  seriesValue: string;
  clientValue: string;
  issuerValue: string;
  pendingValue: boolean;
  dateStringFormatForFromDate: any;
  dateStringFormatForToDate: any;
  fromDateModel: any;
  toDateModel: any;
  clientForExpand: string;
  fromDate: any;
  toDate: any;
  public enableExportButton: boolean;
  isShowExpandlist = false;
  billingClientPatientList: BillingClientPatient[] = [];
  billingClientPatient: BillingClientPatient;
  patientForExpand: number;

  serieslist: Series[];
  clientList: Client[];

  seriesDropdownFilter: DropDownOption[] = [];
  clientDropdownFilter: DropDownOption[] = [];
  issuerDropDownList: DropDownOption[] = [];

  @ViewChild('df') from_date_picker: ElementRef;
  @ViewChild('dt') to_date_picker: ElementRef;

  invoiceHeader: InvoiceHeader;
  defaultIssuer: Issuer;

  billingClientPatientControl = new FormControl('');

  constructor(
    private _apiService: ApiService,
    public _commonDataService: CommonDataService,
    public commonUIService: CommonUIService,
    public languageService: LanguageService,
    private _invoiceService: InvoiceService) {

    this.invoiceHeader = new InvoiceHeader();
    this.defaultIssuer = new Issuer();
    this.languageService.isTranslated.subscribe((val) => {
      this.manageNgSelectDropdown(val);
    });
  }

  ngOnInit() {

    this.seriesValue = '';
    this.clientValue = '';
    this.pendingValue = false;
    this.fromDateModel = '';
    this.toDateModel = '';
    this.clientForExpand = '';
    this.patientForExpand = 0;

    this.LoadSeriesList();
    this.LoadClientList();
    this.GetIssuerList().then(list => {
      this.issuerDropDownList = list.map(x => new DropDownOption(x.id.toString(), x.description, false));
      this.issuerDropDownList.unshift(new DropDownOption('0', this._commonDataService.localizationLabelList['select'], false));
    });

    this.sessionGetUrl = API.invoice.getInvoiceExpandPaginationList;

    this.enableExportButton = false;
  }

  LoadSeriesList() {

    this._apiService.get(API.series.getSeriesListByType + 'Bill')
      .subscribe((list: Series[]) => {
        this.serieslist = list.filter(x => x.year === new Date().getFullYear().toString() && x.status && (this.issuerValue == undefined || x.issuerId.toString() == this.issuerValue));

        this.PopulateSeries(this.serieslist);
      });
  }

  LoadClientList() {
    this._apiService.get(API.client.getDropDownList)
      .subscribe(res => {
        if (res) {
          this.clientList = res.filter(x => x.status);
          this.PopulateClients(this.clientList);
          this.enableExportButton = false;
        }
      });
  }

  private async GetIssuerList(): Promise<Issuer[]> {
    return new Promise<Issuer[]>((resolve, reject) => {
      this._apiService.get(API.issuer.getIssuerList)
        .subscribe((list: Issuer[]) => resolve(list),
          err => reject(err));
    });
  }

  EnableSearchButton(event: any, status = 0) {
    if (event === undefined) {
      if (event === 'A') {
        this.pendingValue = true;
      } else {
        this.pendingValue = false;
      }
    }

    if (this.toDateModel === '') {
      this.toDateModel = null;
    }
    if (this.fromDateModel === '') {
      this.fromDateModel = null;
    }

    if (!this.issuerValue) {
      this.issuerValue = null;
    }

    if (this.clientForExpand.length > 0 || this.seriesValue.length > 0 || (this.issuerValue != null && this.issuerValue.length > 0) ||
      this.fromDateModel != null || this.toDateModel != null || this.pendingValue || (this.billingClientPatient && this.billingClientPatient.id > 0)) {
      this.enableExportButton = true;
    } else {
      this.enableExportButton = false;
      this.isShowExpandlist = false;

    }
  }

  SearchInvoice() {
    if ((this.from_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = (this.from_date_picker['_elRef']).nativeElement['value'];
      if (dateString !== undefined) {
        this.fromDate = dateString;
      } else {
        this.fromDate = '';
      }
    } else {
      this.fromDate = '';
    }

    if ((this.to_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = (this.to_date_picker['_elRef']).nativeElement['value'];
      if (dateString !== undefined) {
        this.toDate = dateString;
      } else {
        this.toDate = '';
      }
    } else {
      this.toDate = '';
    }
    if (this.issuerValue == undefined || this.issuerValue == null) {
      this.issuerValue = "0";
    }

    if (!this.billingClientPatient) {
      this.requestData.filter = `${this.clientForExpand};${this.seriesValue};${this.fromDate};${this.toDate};${this.issuerValue};${0};${false}`;
    }
    else {
      this.requestData.filter = `${this.clientForExpand};${this.seriesValue};${this.fromDate};${this.toDate};${this.issuerValue};${this.billingClientPatient.id};${this.billingClientPatient.isClient}`;
    }

    this.requestData.page = 1;
    this.refresh = true;
    this.isShowExpandlist = true;

  }

  private PopulateClients(list: Client[]): void {
    if (list) {
      this.clientDropdownFilter = [];
      list.forEach((element) => {
        const option = new DropDownOption(element.id.toString(), element.name, false);
        this.clientDropdownFilter.push(option);
      });
    }
  }

  private PopulateSeries(list: Series[]): void {
    if (list) {
      this.seriesDropdownFilter = [];
      list.forEach((element) => {
        const option = new DropDownOption(element.id.toString(), element.series, false);
        this.seriesDropdownFilter.push(option);
      });
    }
  }

  ExportAsXLSX(): void {

    if ((this.from_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = (this.from_date_picker['_elRef']).nativeElement['value'];
      if (dateString !== undefined) {
        // this.dateStringFormatForFromDate = new Date(dateString).toISOString();
        this.fromDate = dateString;
      } else {
        this.fromDate = '';
      }
    } else {
      this.fromDate = '';
    }

    if ((this.to_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = (this.to_date_picker['_elRef']).nativeElement['value'];
      if (dateString !== undefined) {
        this.toDate = dateString;
      } else {
        this.toDate = '';
      }
    } else {
      this.toDate = '';
    }

    if (this.seriesValue === '0') {
      this.seriesValue = '';
    }
    if (this.issuerValue == undefined || this.issuerValue == null) {
      this.issuerValue = "0";
    }

    this.invoiceHeader.clientValue = this.clientForExpand;
    this.invoiceHeader.seriesValue = this.seriesValue;
    this.invoiceHeader.pendingValue = this.pendingValue;
    this.invoiceHeader.fromDate = this.fromDate;
    this.invoiceHeader.toDate = this.toDate;
    this.invoiceHeader.issuerId = this.issuerValue;
    this.invoiceHeader.billCustomer = this.billingClientPatient? this.billingClientPatient.id: 0;
    this.invoiceHeader.isClient = this.billingClientPatient? this.billingClientPatient.isClient: false;
    const params = new URLSearchParams();

    for (const key in this.invoiceHeader) {
      params.set(key, this.invoiceHeader[key])
    }

    const query = params.toString().replace('+', '%20');
    const url = this._apiService.BASE_URL + `/api/Invoice/InvoiceExcelExport?${query}`;
    window.open(url, '_blank');
  }

  LoadInvoiceRightPanel(invoiceHeader: InvoiceHeader, event) {
    this.OnSelectInvoiceHeader.emit(invoiceHeader);
    this.commonUIService.isActiveRightPanelOnly = false;
    this.commonUIService.isActiveLeftPanelOnly = false;
    this.commonUIService.isActiveBothPanel = false;
    this.commonUIService.isActiveStatisticPanel = false;
  }

  private GetDefaultIssuer() { return this._apiService.get(API.issuer.getDefaultIssuer).map(i => i as Issuer).toPromise(); }

  OnSelectBillingClientPatient(billingClientPatient: BillingClientPatient) {
    if (billingClientPatient) {
      this.billingClientPatient = billingClientPatient;
      this.enableExportButton = true;
    } else {
      this.billingClientPatientControl.setValue('');
      this.billingClientPatient = null;
    }
  }

  async onSkipCharCountExceedsBillingClientPatient() {
    const list = await this._invoiceService.SearchBillingClientPatient(this.billingClientPatientControl.value, 'INVOICE');
    this.billingClientPatientList = list ? list : [];
  }

  /**
   * Get default issuer configured in the auxiliary table
   * Filtered invoice list will be automatically passed in the template
   */
  async OnPrint() {
    try {
      this.commonUIService.isSpinnerVisible = true;
      this.defaultIssuer = await this.GetDefaultIssuer(); // get default issuer
      await Delay(300); // set delay until issuer loads (300 ms)
      if (this.defaultIssuer) { this.commonUIService.PrintHtmlReport('invoice-print-section'); }
    } catch (error) { console.log(error); }
    finally { this.commonUIService.isSpinnerVisible = false; }
  }

  manageNgSelectDropdown(lang) {
    if (this.issuerDropDownList && this.issuerDropDownList.length > 1) {
      this.issuerDropDownList = this.issuerDropDownList.filter(x => x.value !== '0');
      this._commonDataService.SetTranslatedLabels(lang);
      this.issuerDropDownList.unshift(new DropDownOption('0', this._commonDataService.localizationLabelList['select'], false));
    }
  }
}

