import { Component, OnInit } from '@angular/core';
import { Issuer } from 'src/app/modules/auxiliary/entities/issuer';
import { DropDownOption } from 'src/app/modules/common/entities/dropdown-option';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonFunctions } from 'src/app/modules/common/services/common-functions';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { LanguageService } from 'src/app/modules/common/services/ui/language.service';
import { RequestData } from '../../pagination/requestData';

import API from './../../../common/api.config.json';
@Component({
  selector: 'app-invoice-list347',
  templateUrl: './invoice-list347.component.html',
  styleUrls: ['./invoice-list347.component.scss']
})
export class InvoiceList347Component implements OnInit {

 // pagination propeties
  page = 1;
  pageSize = 16;
  collectionSize = 5;
  pagInvoice347List: any[] = [];
  recordCount = 0;
  overallAmount = 0;
  maxSize = 10;
  
   // varaibles lists
   issuerDropDownList: DropDownOption[] = [];
   yearList: any[] = [];
 
   // varaibles & objects
   issuerValue: string;
   selectedYear: string;
   enableSearchButton = true;
   enablePrintButton = true;
   showTable = false;

  constructor(
    private _apiService: ApiService,
    public _commonDataService: CommonDataService,
    private _toastyService: Toast,
    public _commonUIService: CommonUIService,
    public languageService: LanguageService) { }

  ngOnInit() {    
    this.loadYearList();
    this.getIssuerList().then(list => {
      this.issuerDropDownList = list.map(x => new DropDownOption(x.id.toString(), x.description, false));
      this.issuerDropDownList.unshift(new DropDownOption('0', this._commonDataService.localizationLabelList['select'], false));
      this.issuerValue = list.filter(x=>x.isDefault)? list.filter(x=>x.isDefault)[0].id.toString() : '0';
      const filterString = `${this.issuerValue};${this.selectedYear}`;
      const request: RequestData = new RequestData(this.page, this.pageSize, '', filterString, '', 0);
      this.onSearchInvoice(request);    
    });    

    this.languageService.isTranslated.subscribe((val) => {
      this.manageNgSelectDropdown(val);
    });
  }

  getIssuerList(){
    return new Promise<Issuer[]>((resolve, reject) => {
      this._apiService.get(API.issuer.getIssuerList)
        .subscribe((list: Issuer[]) => resolve(list),
          err => reject(err));
    });
  }

  loadYearList() {
    let year = new Date().getFullYear() + 1;
    this.selectedYear = new Date().getFullYear().toString();
    for (let x = 10; x > 0; --x) {
      this.yearList.push({ id: year, text: year.toString() });
      year = year - 1;
    }
  }

  searchInvoice(paginate: boolean){
    if(!paginate){
      this.page = 1;
    }
    if (this.issuerValue == undefined || this.issuerValue == null) {
      this.issuerValue = '0';
    }
    if (this.selectedYear == undefined || this.selectedYear == null) {
      this.selectedYear = '0';
    }
    const filterString = `${this.issuerValue};${this.selectedYear}`;
    const request: RequestData = new RequestData(this.page, this.pageSize, '', filterString, '', 0);
    this.onSearchInvoice(request);    
  }

  onSearchInvoice(request: RequestData){
    this._commonUIService.isSpinnerVisible = true;
    this._apiService.getUrlEncoded(API.invoice.getInvoice347ExpandPaginationList, request, true, false)
    .subscribe((response: any) => {
      if (response) {
        this.showTable = true; 
        const body = JSON.parse(response['_body']);
        this.recordCount = 0;
        this.overallAmount = 0;
        this.enablePrintButton = false;
        this.pagInvoice347List = body? body.items : '';
        this.page = body? body.page : 1;
        this.collectionSize = body? body.totalCount : 5;
        if(body && body.items.length != 0){         
          this.recordCount = body.items[0].overallCount;
          this.overallAmount = body.items[0].overallTotal;
          this.enablePrintButton = true;
        }
      }
    },error => { console.error(Error(error)) },
    () => { this._commonUIService.isSpinnerVisible = false; });
  }

  clear(){
    this.issuerValue = '0';
    this.selectedYear = new Date().getFullYear().toString();
    this.pagInvoice347List = [];
    this.enablePrintButton = false;
    this.recordCount = 0;
    this.overallAmount = 0;
    this.showTable = false;
  }

  changeFilter(){
    this.enablePrintButton = false;
  }

  exportToExcel(){
    try {
      this._commonUIService.isSpinnerVisible = true;      
      const filterString = `${this.issuerValue};${this.selectedYear};`;
      const queryString = CommonFunctions.GetUrlParams(new RequestData(1, 2000, '', filterString, '', 0));
      const url = this._apiService.BASE_URL + API.invoice.exportInvocieList347 +'?'+ `${queryString}`;
      window.open(url, '_blank');
    } catch (error) { console.log(error); }  
    finally { this._commonUIService.isSpinnerVisible = false;  }  
  }

  manageNgSelectDropdown(lang) {
    if (this.issuerDropDownList && this.issuerDropDownList.length > 1) {
      this.issuerDropDownList = this.issuerDropDownList.filter(x => x.value !== '0');
      this._commonDataService.SetTranslatedLabels(lang);
      this.issuerDropDownList.unshift(new DropDownOption('0', this._commonDataService.localizationLabelList['select'], false));
    }
  }
}
