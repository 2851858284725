import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { NumberingProcess } from './numbering-process';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NumberingProcessService } from './numbering-process.service';

@Component({
  selector: 'app-numbering-process',
  templateUrl: './numbering-process.component.html'
})
export class NumberingProcessComponent implements OnInit, OnChanges {

  @Input() numberingProcess: NumberingProcess;
  @Input() series: { id: number, type: 'Work' | 'Delivery Note' | 'Bill' | 'Budget' | 'Receipt', series: string };
  @Input() maxSize: number;
  @Input() modalId: string;
  @Input() formGroup: FormGroup;

  @Output() generated: EventEmitter<{
    generateNumber: string,
    genNumber: number,
    deleteNumber: number,
    seriesId: number,
    seriesType: 'DeliveryNote' | 'Work' | 'Bill' | 'Budget' | 'Delivery Note' | 'Receipt' | 'Invoice Cancel'
  }> = new EventEmitter<{
    generateNumber: string,
    genNumber: number,
    deleteNumber: number,
    seriesId: number,
    seriesType: 'DeliveryNote' | 'Work' | 'Bill' | 'Budget' | 'Delivery Note' | 'Receipt' | 'Invoice Cancel'
  }>();
  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();

  constructor(private __service: NumberingProcessService) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['numberingProcess']) {
      const change = changes['numberingProcess'];
      const np = change.currentValue as NumberingProcess;
      if (np && this.formGroup) {
        this.numberingProcess = np;
        this.formGroup.setValue(this.numberingProcess);
        if (!this.numberingProcess.isOtherAvailableNumbers) {
          this.formGroup.controls.otherAvailableNumber.disable();
        } else {
          this.formGroup.controls.otherAvailableNumber.enable();
        }
      }
    }
  }

  OnGenerateNumber() {
    try {
      if (this.formGroup.value && this.series) {
        this.numberingProcess = this.formGroup.value;
        const data = this.__service.GetGeneratedNumber(this.numberingProcess, this.maxSize, this.series);
        this.generated.emit(data);
        this.OnCloseModal(event);
      }
    } catch (error) { console.log(error); }
  }

  OnCheckOption(option: string) {
    if (option) {
      switch (option) {
        case 'NextAvailableNumber':
          this.numberingProcess.isNextAvailableNumber = !this.numberingProcess.isNextAvailableNumber;
          this.numberingProcess.isAnother = false;
          this.numberingProcess.isNoNumberAssigned = false;
          this.numberingProcess.isOtherAvailableNumbers = false;
          break;
        case 'AnotherNumber':
          this.numberingProcess.isNextAvailableNumber = false;
          this.numberingProcess.isAnother = !this.numberingProcess.isAnother;
          this.numberingProcess.isNoNumberAssigned = false;
          this.numberingProcess.isOtherAvailableNumbers = false;
          break;
        case 'OtherAvailableNumbers':
          this.numberingProcess.isNextAvailableNumber = false;
          this.numberingProcess.isAnother = false;
          this.numberingProcess.isNoNumberAssigned = false;
          this.numberingProcess.isOtherAvailableNumbers = !this.numberingProcess.isOtherAvailableNumbers;
          break;
        case 'NoNumberAssigned':
          this.numberingProcess.isNextAvailableNumber = false;
          this.numberingProcess.isAnother = false;
          this.numberingProcess.isNoNumberAssigned = !this.numberingProcess.isNoNumberAssigned;
          this.numberingProcess.isOtherAvailableNumbers = false;
          break;
        default:
          break;
      }
      this.formGroup.controls.isAnother.setValue(this.numberingProcess.isAnother);
      this.formGroup.controls.isNextAvailableNumber.setValue(this.numberingProcess.isNextAvailableNumber);
      this.formGroup.controls.isNoNumberAssigned.setValue(this.numberingProcess.isNoNumberAssigned);
      this.formGroup.controls.isOtherAvailableNumbers.setValue(this.numberingProcess.isOtherAvailableNumbers);
      if (!this.numberingProcess.isOtherAvailableNumbers) {
        this.formGroup.controls.otherAvailableNumber.disable();
      } else {
        this.formGroup.controls.otherAvailableNumber.enable();
      }
    }
  }

  OnCloseModal(event: any) { this.modalClosed.emit(event); }

  OnChangeAnotherAvailableNumber(option: 'increase' | 'decrease') {
    if (this.numberingProcess && option) {
      option === 'increase' ? this.numberingProcess.anotherNumber++ : this.numberingProcess.anotherNumber--;
      this.formGroup.controls.anotherNumber.setValue(this.numberingProcess.anotherNumber);
    }
  }
}
