import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastData, ToastyService } from 'ng2-toasty';
import { Subscription } from 'rxjs';
import { Note } from 'src/app/modules/client/entities/note';
import API from 'src/app/modules/common/api.config.json';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { ShowInvalidFormControls, ValidateNgSelect } from 'src/app/modules/common/services/validators';
import { MainWorkType } from '../../entities/main-work-type';

@Component({
  selector: 'app-notes-modal',
  templateUrl: './notes-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesModalComponent implements OnInit, AfterViewInit {
  showContent = false;
  // form groups
  addNoteFormGroup: FormGroup;
  // subscriptions
  private workService: Subscription;

  noteTypeList: any[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];

  note: Note;
  noteList: Note[] = [];
  hasLoaded = true;
  @Output() Saved = new EventEmitter<Note>();
  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  constructor(private _commonUiService: CommonUIService,
    private _modalService: ModalService,
    private _commonDataService: CommonDataService,
    private _formBuilder: FormBuilder,
    private _toastyService: ToastyService,
    private _apiService: ApiService,
    private cdref: ChangeDetectorRef,
    public dropdownService: DropdownService) {
    this.note = new Note();
    this.addNoteFormGroup = this._formBuilder.group({
      type: ['', ValidateNgSelect],
      mainWorkTypeId: ['', ValidateNgSelect],
      content: ['', Validators.required]
    });
    this.showContent = true;
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.note = new Note();
    this.selectedMainWorkTypeList = this._modalService.transferable.selectedMainWorkTypeList as MainWorkType[];
    this.OnOpenAddNoteModal();
    this.cdref.markForCheck();
  }

  private AddNote(note: Note): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.post(API.note.addNote, note, false)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }

  OnSaveNote(event: any) {
    if (event && this.note) {
      if (this.selectedMainWorkTypeList.length === 1) {
        this.addNoteFormGroup.controls['mainWorkTypeId'].clearValidators();
        this.addNoteFormGroup.controls['mainWorkTypeId'].setErrors(null);
        this.addNoteFormGroup.controls['mainWorkTypeId'].setValidators(null);
      } else { this.addNoteFormGroup.controls['mainWorkTypeId'].setValidators(ValidateNgSelect); }
      this.addNoteFormGroup.updateValueAndValidity();
      if (this.addNoteFormGroup.invalid) { ShowInvalidFormControls(this.addNoteFormGroup); return; }
      if (this.selectedMainWorkTypeList.length === 1) { this.note.mainWorkTypeId = (this.selectedMainWorkTypeList[0]).id; }
      this.note.status = true;
      this.note.modifiedDateTime = new Date().toISOString();
      this.note.createdDateTime = new Date().toISOString();
      if (this.IsEligibleToAddNote(this.noteList, this.note.type, this.note.mainWorkTypeId)) {
        this.AddNote(this.note)
          .then(response => {
            const body = JSON.parse(response['_body']);
            if (body['_isSuccsess']) {
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList['notes'],
                msg: this._commonDataService.localizationLabelList['save_success'],
                showClose: true,
                theme: 'default',
                timeout: 5000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
              const note: Note = body['_data'] as Note;
              if (note) {
                const mainWorkType: MainWorkType = this.selectedMainWorkTypeList.find(x => Number(x.id) === Number(note.mainWorkTypeId));
                if (mainWorkType) {
                  note.symbol = mainWorkType.symbol;
                  note.cssClass = mainWorkType.cssClass;
                  note.order = mainWorkType.order;
                  this.Saved.emit(note);
                }
              }
            } else {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList['notes'],
                msg: this._commonDataService.localizationLabelList['save_error'],
                showClose: true,
                theme: 'default',
                timeout: 5000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
            }
            this.OnClose();
          });
      } else {
        this._toastyService.warning({
          title: this._commonDataService.localizationLabelList['notes'],
          msg: 'Cannot add more ' + this.note.type + ' notes!',
          showClose: true,
          theme: 'default',
          timeout: 5000,
          onAdd: (toast: ToastData) => { },
          onRemove: (toast: ToastData) => { }
        });
      }
    }
  }

  private IsEligibleToAddNote(list: Note[], type: string, mainWorkTypeId: number) {
    if (type !== '' && type) {
      let generalNotesCount = 0; let clinicNotesCount = 0; let labNotesCount = 0;
      if (type.toLowerCase() === 'general' || type.toLowerCase() === 'clinic' || type.toLowerCase() === 'lab') {
        list.forEach(note => {
          if (note.type.toLowerCase() === 'general' && note.mainWorkTypeId === mainWorkTypeId) {
            generalNotesCount++;
          } else if (note.type.toLowerCase() === 'clinic' && note.mainWorkTypeId === mainWorkTypeId) {
            clinicNotesCount++;
          } else if (note.type.toLowerCase() === 'lab' && note.mainWorkTypeId === mainWorkTypeId) {
            labNotesCount++;
          }
        });
        if (type.toLowerCase() === 'general' && generalNotesCount >= 4) { return false; }
        if (type.toLowerCase() === 'clinic' && clinicNotesCount >= 1) { return false; }
        if (type.toLowerCase() === 'lab' && labNotesCount >= 1) { return false; }
        return true;
      }
      return false;
    } else { return false; }
  }

  OnOpenAddNoteModal() {
    this.addNoteFormGroup.reset();
    this.note = new Note();
    this.note.mainWorkTypeId = 0;
    this.note.type = '0';
    this.noteTypeList = [{ label: 'general', value: 'general' }, { label: 'clinic', value: 'clinic' },
    { label: 'laboratory', value: 'lab' }];
    this.cdref.markForCheck();
  }

  OnClose() {
    this.showContent = false;
    this.closeModel.emit();
  }

}
