export class IncidentReason {
    id: number;
    description: string;
    type: 1 | 2 | null;
    status: boolean;

    typeName: string;

    constructor(init?: Partial<IncidentReason>) {
        if (init) { Object.assign(this, init); }
    }
}
