import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import API from './../../common/api.config.json';
import { Patient } from '../../work/entities/patient';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private _apiService: ApiService) { }

  DeletePatient(id: number) {
    return this._apiService.delete(API.patient.deletePatient, id.toString(), true).toPromise();
  }

  AddPatient(patient: Patient) {
    return this._apiService.post(API.patient.addPatient, patient, false, true).toPromise();
  }

  UpdatePatient(patient: Patient) {
    return this._apiService.put(API.patient.updatePatient + patient.id, patient, false, true).toPromise();
  }

  GetPatientById(id: number) {
    return this._apiService.get(API.patient.getPatientById + id, false).toPromise();
  }

  GetPatientShippingNo(patientId: number, clientId: number) {
    return this._apiService.get(API.patient.getPatientShippingNo + patientId + '/' + clientId, false).toPromise();
  }

  SearchPatients(query: string) {
    return this._apiService.get(API.patient.search_patients + query, false)
      .map(list => list as Patient[])
      .toPromise();
  }
}
