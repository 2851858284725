import { MWTExternalDataDocuments } from './main-work-type-external-data-documents';
import { ExternalCenter } from '../../external-shipment/entities/external-center';
import { MainWorkType } from './main-work-type';

export class MWTExternalData {
    id: number;
    mainWorkTypeId: number;
    outSourcedNo: string;
    requestDate: Date;
    externalCenterId: number;
    shippingMethodId: number;
    statusId: number;
    deliveryDate: Date;
    deliveryTime: string;
    observations: string;
    deliveryNoteNo: string;
    total: number;
    createdDate: any;
    createdBy: number;
    modifiedDate: any;
    modifiedBy: number;

    cssClass: string;
    symbol: string;
    workId: number;
    order: number;

    deliveryDateView: any;
    requestDateView: any;
    statusName: string;
    workTypeName: string;

    mwtExternalDataDocuments: MWTExternalDataDocuments[];
    externalCenter: ExternalCenter;
    mainWorkType: MainWorkType;
    status: boolean;

    actualDeliveryDateView: any;
    actualDeliveryDate: Date;
    actualDeliveryTime: string;

    constructor(init?: Partial<MWTExternalData>) { if (init) { Object.assign(this, init); } }
}
