import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceRoutingModule } from './invoice-routing.module';
import { InvoiceComponent } from './invoice.component';
import { SharedModule } from '../../shared/shared.module';
import { HttpModule } from '@angular/http';
import { InvoiceViewComponent } from './component/invoice-view/invoice-view.component';
import { InvoiceEditComponent } from './component/invoice-edit/invoice-edit.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToastyModule } from 'ng2-toasty';
import { SelectModule } from 'ng-select';
import { WorkModule } from '../work/work.module';
import { InvoiceExpandComponent } from './component/invoice-expand/invoice-expand.component';
import { DataTableModule } from 'angular2-datatable';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxTypeaheadModule } from 'ngx-typeahead';
import { InvoiceStatisticComponent } from './component/invoice-statistic/invoice-statistic.component';
import { InvoiceReportComponent } from './component/invoice-report/invoice-report.component';
import { TagInputModule } from 'ngx-chips';
import { NgbModule } from 'imp-ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { InvoiceModalComponent } from './component/invoice-modal/invoice-modal.component';
import { NovaSearchModule } from 'nova-search';
import { InvoiceEditViewComponent } from './component/invoice-edit-view/invoice-edit-view.component';
import { InvoiceCancellationModalComponent } from './component/invoice-cancellation-modal/invoice-cancellation-modal.component';
import { NewInvoiceViewComponent } from './component/new-invoice-view/new-invoice-view.component';
import { CloseBillingComponent } from './component/close-billing/close-billing.component';
import { InvoiceList347Component } from './component/invoice-list347/invoice-list347.component';

@NgModule({
  imports: [
    CommonModule,
    InvoiceRoutingModule,
    SharedModule,
    FormsModule,
    DataTableModule,
    NgxDatatableModule,
    NgbModule,
    AutocompleteLibModule,
    ReactiveFormsModule,
    SelectModule,
    ToastyModule.forRoot(),
    WorkModule,
    NovaSearchModule,
    TagInputModule,
    NgxTypeaheadModule,
  ],
  declarations: [
    InvoiceComponent,
    InvoiceViewComponent,
    InvoiceEditComponent,
    InvoiceExpandComponent,
    InvoiceStatisticComponent,
    InvoiceReportComponent,
    InvoiceModalComponent,
    InvoiceEditViewComponent,
    InvoiceCancellationModalComponent,
    NewInvoiceViewComponent,
    CloseBillingComponent,
    InvoiceList347Component
  ]
})
export class InvoiceModule { }
