import { Component, Input, OnInit } from '@angular/core';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { ConformityReport } from '../../entities/conformity-report';

@Component({
  selector: 'app-conformity-report',
  templateUrl: './conformity-report.component.html'
})
export class ConformityReportComponent implements OnInit {

  @Input() public conformityReport: ConformityReport;
  imageData: any;

  constructor(private _commonUIService: CommonUIService) { }

  ngOnInit() {
    this.LoadLabConfigurationList();
  }

  async LoadLabConfigurationList() {
    this.imageData = await this._commonUIService.LoadLabConfigurationList();
  }
}
