import { Injectable } from '@angular/core';
import { MainWorkType } from '../../work/entities/main-work-type';
import { ApiService } from '../../common/services/api.service';
import API from './../../common/api.config.json';
import { Client } from '../../client/entities/client';
import { Patient } from '../../work/entities/patient';
import { ShipmentPickupDetails } from '../entities/shipment-pickup-details';
import { Messengers } from '../entities/messenger';
import { CommonFunctions } from '../../common/services/common-functions';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  constructor(private _apiService: ApiService) { }

  GetMainWorkTypeList() {
    return this._apiService.get(API.main_work_type_work.getMainWorkTypeList, true)
      .map(list => list as MainWorkType[]).toPromise();
  }

  GetClientList() {
    return this._apiService.get(API.client.getClientList, true)
      .map(list => list as Client[]).toPromise();
  }

  GetPatientList() {
    return this._apiService.get(API.patient.getPatientList, true)
      .map(list => list as Patient[]).toPromise();
  }

  GetMessengersList() {
    return this._apiService.get(API.messenger.getMessengerList, true)
      .map(list => list as Messengers[]).toPromise();
  }

  DeleteShipmentPickupDetail(id: number) {
    return this._apiService.delete(API.shipment_pickup.deleteShipmentPickupDetails, id.toString(), true)
      .toPromise();
  }

  async SaveShipmentPickupDetails(shipment: ShipmentPickupDetails) {
    return this._apiService.post(API.shipment_pickup.saveShipmentPickupDetails, shipment, false, true)
      .map(response => JSON.parse(response['_body'])).toPromise();
  }

  GetShipmentPickupDetailListByMainWorkTypeIdList(idList: { id: number }[]) {
    try {
      const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
      if (params) {
        const query = params.toString().replace('+', '%20');
        return this._apiService.get(API.work_panels.getShipmentPickupDetailListByMainWorkTypeIdList + query, true)
          .map(p => p as ShipmentPickupDetails[]).toPromise();
      }
    } catch (error) { console.log(error); }
  }

  async GetById(Id: number) {
    return this._apiService.get(API.shipment_pickup.getById + Id.toString(), true)
    .map(p => p as ShipmentPickupDetails).toPromise();
  }

  UpdateShipmentPickupDetail(id: number, shipment: ShipmentPickupDetails): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.put(API.shipment_pickup.updateShipmentPickupDetails + id.toString(), shipment, false, true)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }

}
