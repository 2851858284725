import { Component, OnInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonDataService } from './modules/common/services/common.data.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonUIService } from './modules/common/services/common.ui.service';
import { LanguageService } from './modules/common/services/ui/language.service';
import { AppSettingsService } from './modules/common/services/app-settings.service';
import { ApiService } from './modules/common/services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterContentChecked {
  title = 'Nova Lab';
  language: string;

  constructor(
    private router: Router,
    public commonUiService: CommonUIService,
    private _cdRef: ChangeDetectorRef,
    private _commonDataService: CommonDataService,
    private translate: TranslateService,
    private _apiService: ApiService,
    public languageService: LanguageService) {
  }

  async ngOnInit() {
    await this._apiService.setBaseUrl();
    if (localStorage.getItem('currentLang')) {
      this.language = localStorage.getItem('currentLang');
    } else {
      this.language = this.translate.getBrowserLang();
    }
    this.translate.setDefaultLang(this.language);
    this.translate.use(this.language);
    this._commonDataService.SetTranslatedLabels(this.language);
    this._commonDataService.SetCurrentLanguage(this.language);
    this._commonDataService.setAppCurrency();    

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngAfterContentChecked() { this._cdRef.detectChanges(); }
}
