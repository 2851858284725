import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ValidateNgSelect2, ShowInvalidFormControls } from '../../../common/services/validators';
import { SubWork } from '../../entities/sub-work';
import { ModalService } from '../../../common/services/modal.service';
import { ModalNames } from '../../../common/global';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { CommonDataService } from '../../../common/services/common.data.service';
import { DropdownService } from '../../../common/services/dropdown.service';
import { ToastyService, ToastData } from 'ng2-toasty';
import { SubWorkService } from '../../services/sub-work.service';
import { Work } from '../../entities/work';
import * as moment from 'moment';
import { WorkService } from '../../services/work.service';
import { WorkStatusService } from '../../services/work-status.service';

@Component({
  selector: 'app-sub-work-modal',
  templateUrl: './sub-work-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubWorkModalComponent implements OnInit, AfterViewInit {
  subWorkFormGroup: FormGroup;
  subWork: SubWork;
  selectedWork: Work;
  showContent = false;

  @Output() subWorkSaved = new EventEmitter<{ work: Work; subWork: SubWork; isEdit: boolean; }>();
  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  constructor(private _formBuilder: FormBuilder,
    private _commonUiService: CommonUIService,
    public dropdownService: DropdownService,
    private _workService: WorkService,
    private _subWorkService: SubWorkService,
    private _toastyService: ToastyService,
    private commonDataService: CommonDataService,
    private _modalService: ModalService,
    private cdref: ChangeDetectorRef,
    private _statusService: WorkStatusService) {
    this.subWork = new SubWork();
    this.InitFormGroups();
    this.showContent = true;
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (this._modalService.modal === ModalNames.SubWorkModal) {
      this.selectedWork = this._modalService.transferable.selectedWork as Work;
      if (!this._modalService.transferable.isEdit) {
        this.OnAdd();
      } else {
        this.subWork = this._modalService.transferable.selectedSubWork as SubWork;
        this.cdref.markForCheck();
      }
      this.cdref.markForCheck();
    }
  }

  private OnAdd() {
    try {
      this._commonUiService.isSpinnerVisible = true;
      this.subWork = new SubWork();
      this.subWork.situation = this.commonDataService.localizationLabelList['select'];
    } catch (error) { console.log(error); } finally {
      this.cdref.markForCheck();
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  private InitFormGroups() {
    this.subWorkFormGroup = this._formBuilder.group({
      situation: ['', [ValidateNgSelect2]]
    });
  }

  async OnSaveSubWork(modal: string) {
    try {
      if (this.subWorkFormGroup.invalid) {
        ShowInvalidFormControls(this.subWorkFormGroup); return;
      }
      if (this.subWork && modal) {
        this._commonUiService.isSpinnerVisible = true;
        let transferable: { work: Work; subWork: SubWork; isEdit: boolean; };
        if (this.subWork.id) {
          const response = await this._subWorkService.EditSubWork(this.subWork);
          if (response) {
            const body = JSON.parse(response['_body']);
            if (body['_statusCode'] === 200) {
              this._toastyService.success({
                title: this.commonDataService.localizationLabelList['sub_work'],
                msg: this.commonDataService.localizationLabelList['save_success'],
                showClose: true,
                theme: 'default',
                timeout: 5000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
              transferable = {
                work: this.selectedWork,
                subWork: body['_data'] as SubWork,
                isEdit: true
              };
            } else {
              this._toastyService.error({
                title: this.commonDataService.localizationLabelList['sub_work'],
                msg: this.commonDataService.localizationLabelList['save_error'],
                showClose: true,
                theme: 'default',
                timeout: 5000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
            }
            this._commonUiService.isSpinnerVisible = false;
          }
        } else {
          this.subWork.createdDate = moment().format('YYYY-MM-DD');
          this.subWork.status = true;
          this.subWork.workId = this.selectedWork.id;
          this.subWork.subWorkStatus = 'Pendiente';
          // get no of sub works from api
          const count: number = await this._subWorkService.GetSubWorkCountByWorkId(this.selectedWork.id);
          this.subWork.generateNo = count ? (count + 1) : 1;

          let doctorList = await this._workService.GetDoctorListByClientId(this.selectedWork.clientId);
          doctorList = doctorList ? doctorList.filter(c => c.status) : [];
          if (doctorList.length > 0) {
            const defaultDoctor = doctorList.find(a => a.isDefault === true);
            if (defaultDoctor) {
              this.subWork.doctorId = defaultDoctor.id;
              this.subWork.doctorName = defaultDoctor.name;
            } else {
              this.subWork.doctorId = null;
            }
          } else { this.subWork.doctorId = null; }

          const response = await this._subWorkService.AddSubWork(this.subWork);
          if (response) {
            const body = JSON.parse(response['_body']);
            if (body['_statusCode'] === 200) {

              this._toastyService.success({
                title: this.commonDataService.localizationLabelList['sub_work'],
                msg: this.commonDataService.localizationLabelList['save_success'],
                showClose: true,
                theme: 'default',
                timeout: 5000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });

              this._statusService.SetChangedStatus(true);
              const sw = body['_data'] as SubWork;
              sw.doctorName = this.subWork.doctorName;
              transferable = {
                work: this.selectedWork,
                subWork: sw,
                isEdit: false
              };
            } else {
              this._toastyService.error({
                title: this.commonDataService.localizationLabelList['sub_work'],
                msg: this.commonDataService.localizationLabelList['save_error'],
                showClose: true,
                theme: 'default',
                timeout: 5000,
                onAdd: (toast: ToastData) => { },
                onRemove: (toast: ToastData) => { }
              });
            }
          }
        }
        this.subWorkSaved.emit(transferable);
      }
    } catch (error) { console.log(error); } finally {
      this.showContent = false;
      this.cdref.markForCheck();
      this._commonUiService.isSpinnerVisible = false;
    }
  }

  OnClose() {
    this.showContent = false;
    this.closeModel.emit();
  }
}

