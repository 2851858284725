import { Injectable } from '@angular/core';
import { DentboxApiService } from './dentbox-api.service';
import { DENTBOX_LAB, DENTBOX_LAB_WORKS, DENTBOX_AUTH } from '../dentbox-paths';
import { CommonDataService } from '../../services/common.data.service';
import { DentboxValidationService } from './dentbox-validation.service';
import { DentboxAuthService } from './dentbox-auth.service';
import { DentboxWorkStatusModel } from '../../entities/dentbox-models';

@Injectable()
export class DentboxLabService {

    constructor(private _apiService: DentboxApiService,
        private _dentboxAuthService: DentboxAuthService,
        private _commonDataService: CommonDataService,
        private _dentboxLicenseService: DentboxValidationService) { }

    GetConnectedCenterList(guid: string) {
        return this._apiService.get(DENTBOX_LAB.GET_CONNECTED_CENTERS + guid).toPromise();
    }

    GetLabWorksList(guid: string, staus: string, page: number, pageSize: number) {
        const url = DENTBOX_LAB_WORKS.GET_LAB_WORKS + '?applicant=' + guid + staus + '&page=' + page + '&pageSize=' + pageSize;
        return this._apiService.get(url).toPromise();
    }

    GenerateTicket(data: any, center: string) {
        return this._apiService.post(DENTBOX_AUTH.GET_TICKET, data
            , true, [{ key: 'RequestCenter', value: center }, { key: 'OpeningMode', value: 'lab' }]).toPromise();
    }

    UpdateDentboxWork(guid: string, language: string, dbStatusModel: DentboxWorkStatusModel) {
        return this._apiService.put(DENTBOX_LAB_WORKS.UPDATE_LAB_WORKS
            + guid + '/' + language, dbStatusModel, true).toPromise();
    }

    async RouteToDentboxChat(workGuid?: string) {
        const centerGuid = await this._dentboxLicenseService.GetNovalabGUID();
        const user = this._commonDataService.GetUserFromSession();
        if (centerGuid && user) {
            const data = {
                'dateTime': new Date(),
                'user': user.workerName ? user.workerName : user.username,
                'userCode': user.id,
                'workGuid': workGuid ? workGuid : undefined
            };
            const ticket = await this.GenerateTicket(data, centerGuid);
            if (ticket) {
                window.open(this._apiService.CHAT_URL + '/auth?ticket=' + ticket.data, '_blank');
            }
        }
    }

}
