export class Document {
  generateFileName: string;
  extension: string;
  fileName: string;
  id: number;
  mainWorkTypeId: number;
  status: boolean;
  link: string;
  fileList: any;
  path: string;
  safeUrl: any;
  order: number;
  symbol: string;
  cssClass: string;
  file: any;
  isClosed: boolean;

  constructor(init?: Partial<Document>) { if (init) { Object.assign(this, init); } }
}

