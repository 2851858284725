import { Doctor } from 'src/app/modules/work/entities/doctor';
import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { Issuer } from '../../../client/entities/client-issuer';
import { WorkService } from '../../../work/services/work.service';
import { Series } from '../../../client/entities/series';
import { Client } from '../../entities/client';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { SeriesTypes } from '../../../common/global';
import { NumberingProcessService } from '../../../../shared/numbering-process/numbering-process.service';
import { NumberingProcess } from 'src/app/shared/numbering-process/numbering-process';
import { CommonDataService } from '../../../common/services/common.data.service';
import { InvoiceService } from '../../../work/services/invoice.service';
import { InvoiceDetail } from '../../entities/invoice-detail';
import { ShowInvalidFormControls, ValidateNgSelect } from '../../../common/services/validators';
import { User } from '../../../auth/entities/user';
import { InvoiceHeader } from '../../entities/invoice-header';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { InvoiceAddViewModel } from '../../entities/invoice-add-view-model';
import { ApiService } from 'src/app/modules/common/services/api.service';
import API from './../../../common/api.config.json';
import { toFixedNumber } from '../../../common/services/calculations/calculation';
import { InvoiceCalculationService } from '../../../common/services/calculations/invoice-calculation.service';

@Component({
  selector: 'app-invoice-modal',
  templateUrl: './invoice-modal.component.html',
  styleUrls: ['./invoice-modal.component.scss']
})
export class InvoiceModalComponent implements OnInit, OnDestroy {
  @Input() modalId: string;
  @Input() modelOpened: Subject<any>;
  @Input() user: User;
  @Output() invoiceSaved = new EventEmitter<InvoiceHeader>();

  formGroup: FormGroup;
  invoiceHeader: InvoiceHeader;
  isShowDoctorDropDown = false;

  issuerList: Issuer[] = [];
  invoiceSeriesList: Series[] = [];
  deliveryNoteSeriesList: Series[] = [];
  clientList: Client[] = [];
  invoiceDetailList: InvoiceDetail[] = [];
  invoiceDetailListChanged: Subject<InvoiceDetail[]> = new Subject();
  doctorList: Doctor[] = [];

  filterEnabled = false;
  isDetailListLoaded = true;
  selectedInvoiceDetailCount = 0;

  constructor(
    private _formBuilder: FormBuilder,
    public commonUiService: CommonUIService,
    private _toastyService: Toast,
    public _commonDataService: CommonDataService,
    private _workService: WorkService,
    private _invoiceService: InvoiceService,
    private _numberingProcessService: NumberingProcessService,
    private _apiService: ApiService,
    private _calculationService: InvoiceCalculationService
  ) {
    this.InitFormGroups();
  }

  ngOnInit() {
    this.modelOpened.subscribe(async opened => await this.ModelOpenListener());
    this.invoiceDetailListChanged.subscribe(list => {
      if (list) { this.InvoiceDetailListChangeListener(list); }
    });
    this.formGroup.valueChanges.subscribe(
      (form: InvoiceAddViewModel) => {
        if (form) { this.FormChangeListener(form); }
      });
  }

  ngOnDestroy() {
    this.modelOpened.unsubscribe();
    this.invoiceDetailListChanged.unsubscribe();
  }

  private async ModelOpenListener() {
    this.invoiceHeader = new InvoiceHeader();
    try {
      const today = this.commonUiService
        .ConvertToPickerDateFormat(moment().format('YYYY-MM-DD'));
      this.formGroup.reset();
      this.formGroup.controls.generateDate.setValue(today);
      this.formGroup.controls.invoiceSeriesId.setValue(0);
      this.formGroup.controls.deliveryNoteSeriesId.setValue(0);
      this.invoiceDetailList = [];
      this.selectedInvoiceDetailCount = 0;
    } catch (error) { console.log(error); } finally {
      await this.InitResources();
    }
  }

  private InvoiceDetailListChangeListener(list: InvoiceDetail[]) {
    try {
      this.isDetailListLoaded = false;
      let checkedList = list.filter(d => d.isSelected === true);
      checkedList = checkedList ? checkedList : [];
      this.selectedInvoiceDetailCount = checkedList.length;

      const calculations = this.GetCalculations(checkedList);

      this.invoiceHeader.tax = calculations.taxAmount;
      this.invoiceHeader.subTotal = toFixedNumber(calculations.finalTotal - this.invoiceHeader.tax);
      // final total with delivery note discounts => sub total

      if (this.invoiceHeader.discount) {
        this.invoiceHeader.discountAmount = this._calculationService
          .getDiscountAmount(this.invoiceHeader.subTotal, toFixedNumber(this.invoiceHeader.discount));
      } else { this.invoiceHeader.discountAmount = 0; }

      this.invoiceHeader.baseAmount = toFixedNumber(this.invoiceHeader.subTotal - this.invoiceHeader.discountAmount);
      this.invoiceHeader.invoiceTotal = toFixedNumber(this.invoiceHeader.baseAmount + this.invoiceHeader.tax);

      this.isDetailListLoaded = true;
    } catch (error) { console.log(error); }
  }

  private FormChangeListener(form: InvoiceAddViewModel) {
    try {
      if (form.deliveryNoteIssuerId > 0 || form.deliveryNoteSeriesId > 0
        || (form.deliveryNoteClientName && form.deliveryNoteClientId > 0)
        || form.deliveryNoteFromDate || form.deliveryNoteToDate) {
        this.filterEnabled = true;
        if (form.deliveryNoteFromDate && form.deliveryNoteToDate) {
          const isValid = this.ValidateDateFilters(form.deliveryNoteFromDate,
            form.deliveryNoteToDate);
          if (!isValid) {
            this.formGroup.controls.deliveryNoteFromDate.setValue(null);
            this.formGroup.controls.deliveryNoteToDate.setValue(null);
          }
        }
      } else { this.filterEnabled = false; }

      if (form.discount) {
        this.invoiceHeader.discountAmount = this._calculationService
          .getDiscountAmount(this.invoiceHeader.subTotal, toFixedNumber(form.discount));
      } else { this.invoiceHeader.discountAmount = 0; }

      this.invoiceHeader.baseAmount = toFixedNumber(this.invoiceHeader.subTotal
        - this.invoiceHeader.discountAmount);
      this.invoiceHeader.invoiceTotal = toFixedNumber(this.invoiceHeader.baseAmount
        + this.invoiceHeader.tax);
    } catch (error) { console.log(error); }
  }

  InitFormGroups() {
    this.formGroup = this._formBuilder.group({
      generateDate: [null, Validators.required],
      invoiceIssuerId: [], invoiceIssuerName: ['', [Validators.required]],
      invoiceSeriesId: ['', [ValidateNgSelect]],
      generateNumber: ['', Validators.required],
      discount: ['', [Validators.min(0), Validators.max(100)]],
      concept: [],
      clientName: ['', Validators.required],
      clientId: [],
      deliveryNoteClientName: [],
      deliveryNoteIssuerId: [],
      deliveryNoteIssuerName: [],
      deliveryNoteSeriesId: [],
      deliveryNoteFromDate: [],
      deliveryNoteToDate: [],
      deliveryNoteClientId: [],
      collaboratorId: [],
      collaboratorName: [],
    });
  }

  async InitResources() {
    this.issuerList = await this._workService.GetIssureList();
    this.clientList = await this._workService.GetClientList();
  }

  async OnInvoiceIssuerSelected(issuerId: number) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (issuerId) {
        this.formGroup.controls.invoiceIssuerId.setValue(issuerId);
        this.invoiceSeriesList = await
          this.GetSeriesListByIssuerIdAndType(Number(issuerId), SeriesTypes.Invoice);
        if (this.invoiceSeriesList != null && this.invoiceSeriesList.length > 0) {
          this.formGroup.controls.generateNumber.setValue('');
          this.formGroup.controls.invoiceSeriesId.setValue(this.invoiceSeriesList[0].id);
          await this.OnSeriesSelected(this.invoiceSeriesList[0].id);
        } else {
          this.formGroup.controls.invoiceSeriesId.setValue(0);
          this.formGroup.controls.generateNumber.setValue('');
        }
      } else {
        this.formGroup.controls.generateNumber.setValue('');
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnClientSelected(client: Client) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      const defaultIssuer = this.issuerList.find(a => a.isDefault);

      this._apiService.get(API.invoice.getSeriesByIssuerId + defaultIssuer.id)
        .subscribe((list: Series[]) => {
          list = list ? list : [];
          // tslint:disable-next-line: max-line-length
          this.invoiceSeriesList = list.filter(x => x.seriesType.toLowerCase() === 'bill'
            && x.status && x.year === (new Date().getFullYear().toString()));
        }, err => { console.log(err + 'series load error'); });

      if (client) {
        this.formGroup.controls.clientName.setValue(client.name);
        this.formGroup.controls.clientId.setValue(client.id);
        let clientSeriesList = await this._workService.GetSeriesByClientId(client.id);
        clientSeriesList = clientSeriesList ? clientSeriesList : [];
        if (clientSeriesList.length > 0) {
          const invoiceClientSeries = clientSeriesList.find(cs => cs.seriesType.toLowerCase()
            === SeriesTypes.Invoice.toLowerCase());
          if (invoiceClientSeries) {
            this.formGroup.controls.invoiceIssuerName.setValue(invoiceClientSeries.issuerName);
            this.formGroup.controls.invoiceIssuerId.setValue(invoiceClientSeries.issuerId);
            this.invoiceSeriesList = await
              this.GetSeriesListByIssuerIdAndType(invoiceClientSeries.issuerId, SeriesTypes.Invoice);
            this.formGroup.controls.invoiceSeriesId
              .setValue(invoiceClientSeries.seriesId);
            const series = this.invoiceSeriesList.find(s => s.id === invoiceClientSeries.seriesId);
            const generateNumber = this.GetInvoiceGenerateNumberBySeries(series.lastNumber,
              invoiceClientSeries.seriesName, invoiceClientSeries.seriesId);
            this.formGroup.controls.generateNumber.setValue(generateNumber);
          } else {
            this.formGroup.controls.invoiceIssuerName.setValue(defaultIssuer.name1);
            this.formGroup.controls.invoiceIssuerId.setValue(defaultIssuer.id);
            this.formGroup.controls.invoiceSeriesId.setValue(this.invoiceSeriesList[0].id);
            if (this.invoiceSeriesList.length > 0) {
              this.OnSeriesSelected(this.invoiceSeriesList[0].id);
            }
          }
        } else {
          this.formGroup.controls.invoiceIssuerName.setValue(defaultIssuer.description);
          this.formGroup.controls.invoiceIssuerId.setValue(defaultIssuer.id);
          this.formGroup.controls.invoiceSeriesId
            .setValue(
              this.invoiceSeriesList && this.invoiceSeriesList.length > 0
                ? this.invoiceSeriesList[0].id : 0);
          if (this.invoiceSeriesList.length > 0) {
            this.OnSeriesSelected(this.invoiceSeriesList[0].id);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      document.getElementById('invoice-series-id').click();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnSeriesSelected(seriesId: number) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (seriesId) {
        this.formGroup.controls.generateNumber.setValue('');
        const series = this.invoiceSeriesList.find(
          s => s.id === Number(seriesId)
        );
        if (series) {
          const generateNumber = this.GetInvoiceGenerateNumberBySeries(
            series.lastNumber,
            series.series,
            series.id
          );
          this.formGroup.controls.generateNumber.setValue(generateNumber);
        }
      }
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  private async GetSeriesListByIssuerIdAndType(issuerId: number, type: string) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      let seriesList = await this._workService.GetSeriesListByIssuerId(
        Number(issuerId)
      );
      seriesList = seriesList ? seriesList : [];
      return seriesList.filter(
        s =>
          s.seriesType.toLowerCase() === type.toLowerCase() &&
          s.year === new Date().getFullYear().toString() &&
          s.status === true
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  private GetInvoiceGenerateNumberBySeries(
    lastNumber: number,
    series: string,
    seriesId: number
  ) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      const np = new NumberingProcess({
        isNextAvailableNumber: true,
        isAnother: false,
        isNoNumberAssigned: false,
        isOtherAvailableNumbers: false,
        nextAvailableNumber: lastNumber,
        anotherNumber: 0,
        otherAvailableNumberList: [],
        otherAvailableNumber: ''
      });
      const gno = this._numberingProcessService.GetGeneratedNumber(np, 5, {
        id: seriesId,
        type: 'Bill',
        series: series
      });
      return gno ? gno.generateNumber : '';
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  async OnDeliveryNoteClientSelected(client: Client) {
    try {
      this.doctorList = await this._workService.GetDoctorListByClientId(client.id);
      this.isShowDoctorDropDown = true;
      this.commonUiService.isSpinnerVisible = true;
      if (client) {
        this.formGroup.controls.deliveryNoteClientName.setValue(client.name);
        this.formGroup.controls.deliveryNoteClientId.setValue(client.id);
        const clientSeriesList = await this._workService.GetSeriesByClientId(
          client.id
        );
        if (clientSeriesList && clientSeriesList.length !== 0) {
          const deliveryNoteClientSeries = clientSeriesList.find(
            cs =>
              cs.seriesType.toLowerCase() ===
              SeriesTypes.DeliveryNote.toLowerCase()
          );
          if (deliveryNoteClientSeries) {
            this.formGroup.controls.deliveryNoteIssuerId.setValue(
              deliveryNoteClientSeries.issuerId
            );
            this.formGroup.controls.deliveryNoteIssuerName.setValue(
              deliveryNoteClientSeries.issuerName
            );

            document.getElementById('delivery-note-series-id').click();

            this.deliveryNoteSeriesList = await this.GetSeriesListByIssuerIdAndType(
              deliveryNoteClientSeries.issuerId,
              SeriesTypes.DeliveryNote
            );
            this.formGroup.controls.deliveryNoteSeriesId.setValue(
              deliveryNoteClientSeries.seriesId
            );
          } else {
            this.invoiceSeriesList = [];
            this.formGroup.controls.deliveryNoteIssuerId.setValue(0);
            this.formGroup.controls.deliveryNoteIssuerName.setValue('');
            this.formGroup.controls.deliveryNoteSeriesId.setValue(0);
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      document.getElementById('delivery-note-series-id').click();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  OnDeliveryNoteClientCleared() {
    this.formGroup.controls.deliveryNoteClientName.setValue('');
    this.formGroup.controls.deliveryNoteClientId.setValue(0);
    this.isShowDoctorDropDown = false
    this.formGroup.controls.collaboratorId.setValue(0);
    this.formGroup.controls.collaboratorName.setValue('');
  }

  async OnDeliveryNoteIssuerSelected(issuerId: number) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (issuerId) {
        this.formGroup.controls.deliveryNoteIssuerId.setValue(issuerId);
        this.deliveryNoteSeriesList = await
          this.GetSeriesListByIssuerIdAndType(issuerId, SeriesTypes.DeliveryNote);
        if (this.deliveryNoteSeriesList != null && this.deliveryNoteSeriesList.length > 0) {
          this.formGroup.controls.deliveryNoteSeriesId.setValue(this.deliveryNoteSeriesList[0].id);
        }
      } else { this.formGroup.controls.deliveryNoteSeriesId.setValue(0); }
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  ValidateDateFilters(from: string, to: string) {
    if (from && to) {
      const fromDate = new Date(
        this.commonUiService.ChangeNgbDateToCustomFormat(from)
      );
      const toDate = new Date(
        this.commonUiService.ChangeNgbDateToCustomFormat(to)
      );
      if (fromDate > toDate) {
        this._toastyService.error({
          title: this._commonDataService.localizationLabelList['invoice'],
          msg: this._commonDataService.localizationLabelList[
            'invalid_date_range'
          ]
        });
        return false;
      }
    }
    return true;
  }

  OnSelectFilterDates() {
    const from = this.formGroup.controls.deliveryNoteFromDate.value;
    const to = this.formGroup.controls.deliveryNoteToDate.value;
    if (from && to) {
    } else {
      if (from) {
        this.formGroup.controls.deliveryNoteToDate.setValue(from);
      }
      if (to) {
        this.formGroup.controls.deliveryNoteFromDate.setValue(to);
      }
    }
  }

  OnClearFilters() {
    this.formGroup.controls.deliveryNoteIssuerId.setValue(0);
    this.formGroup.controls.deliveryNoteIssuerName.setValue('');
    this.formGroup.controls.deliveryNoteSeriesId.setValue(0);
    this.formGroup.controls.deliveryNoteClientId.setValue(0);
    this.formGroup.controls.deliveryNoteClientName.setValue('');
    this.formGroup.controls.deliveryNoteFromDate.setValue(null);
    this.formGroup.controls.deliveryNoteToDate.setValue(null);
    this.formGroup.controls.collaboratorId.setValue(0);
    this.formGroup.controls.collaboratorName.setValue('');
    this.invoiceDetailList = [];
    this.selectedInvoiceDetailCount = 0;
    this.invoiceHeader = new InvoiceHeader();
    this.isShowDoctorDropDown = false;
  }

  async OnFilter() {
    try {
      this.isDetailListLoaded = false;
      if (this.filterEnabled) {
        const form = this.formGroup.value as InvoiceAddViewModel;
        const filters = {
          clientId: form.deliveryNoteClientId ? form.deliveryNoteClientId : 0,
          from: form.deliveryNoteFromDate
            ? this.commonUiService.ChangeNgbDateToCustomFormat(
              form.deliveryNoteFromDate
            )
            : null,
          to: form.deliveryNoteToDate
            ? this.commonUiService.ChangeNgbDateToCustomFormat(
              form.deliveryNoteToDate
            )
            : null,
          issuerId: form.deliveryNoteIssuerId ? form.deliveryNoteIssuerId : 0,
          seriesId: form.deliveryNoteSeriesId ? form.deliveryNoteSeriesId : 0,
          collaboratorId: form.collaboratorId ? form.collaboratorId : 0
        };
        this.invoiceDetailList = await this._invoiceService.GetFilteredInvoiceDetailList(
          filters
        );
        this.invoiceDetailList = this.invoiceDetailList ? this.invoiceDetailList : [];
        if (this.invoiceDetailList) {
          this.invoiceDetailList.forEach(d => (d.isSelected = false));
        }
      }
    } catch (error) { console.log(error); } finally {
      this.formGroup.controls.discount.setValue('');
      this.invoiceHeader = new InvoiceHeader();
      this.invoiceDetailListChanged.next(this.invoiceDetailList);
      this.isDetailListLoaded = true;
    }
  }

  OnSelectDetailItem(index: number) {
    if (index !== -1) {
      const detail = this.invoiceDetailList[index];
      detail.isSelected = !detail.isSelected;
      this.invoiceDetailListChanged.next(this.invoiceDetailList);
    }
  }

  OnSelectAllDetailList(flag: boolean) {
    if (this.invoiceDetailList) {
      this.invoiceDetailList.forEach(d => (d.isSelected = flag));
      this.invoiceDetailListChanged.next(this.invoiceDetailList);
    }
  }

  private GetCalculations(list: InvoiceDetail[]) {
    let sumAmount = 0,
      finalTotal = 0,
      taxAmount = 0;
    for (let index = 0; index < list.length; index++) {
      const detail = list[index];
      finalTotal += detail.deliveryNoteTotal;
      sumAmount += detail.deliveryNoteSubTotal;
      taxAmount += detail.deliveryNoteTax;
    }
    return {
      finalTotal: toFixedNumber(finalTotal),
      sumAmount: toFixedNumber(sumAmount),
      taxAmount: toFixedNumber(taxAmount)
    };
  }

  async OnSaveInvoice() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (this.formGroup.invalid) { ShowInvalidFormControls(this.formGroup); } else {
        const form = this.formGroup.value as InvoiceAddViewModel;

        this.invoiceHeader.generateNumber = form.generateNumber;
        this.invoiceHeader.generateDate = this.commonUiService.ChangeNgbDateToCustomFormat(
          form.generateDate
        );
        this.invoiceHeader.issuerId =
          form.invoiceIssuerId > 0 ? form.invoiceIssuerId : null;
        this.invoiceHeader.seriesId =
          form.invoiceSeriesId > 0 ? form.invoiceSeriesId : null;
        this.invoiceHeader.clientId = form.clientId > 0 ? form.clientId : null;
        this.invoiceHeader.patientId = null;
        this.invoiceHeader.billedToPatient = false;
        this.invoiceHeader.discount = form.discount ? Number(form.discount) : 0;
        this.invoiceHeader.comments = form.concept ? form.concept : '';
        this.invoiceHeader.createdDate = moment().format('YYYY-MM-DD');
        this.invoiceHeader.modifiedDate = moment().format('YYYY-MM-DD');
        this.invoiceHeader.invoiceStatus = 'pending';
        this.invoiceHeader.isPaymentDone = false;
        this.invoiceHeader.isReceipted = false;
        if (this.user) {
          this.invoiceHeader.modifiedBy = this.user.id;
          this.invoiceHeader.createdBy = this.user.id;
        }
        this.invoiceHeader.status = true;
        this.invoiceHeader.isMailed = false;

        const response = await this._invoiceService.AddInvoice(
          this.invoiceHeader
        );
        if (response['_body']) {
          const body = JSON.parse(response['_body']);
          if (body._isSuccsess) {
            const invoice = body._data as InvoiceHeader;
            if (invoice) {
              const detailList = this.invoiceDetailList
                .filter(d => d.isSelected === true)
                .map(d => ({
                  invoiceId: invoice.id,
                  deliveryNoteId: d.deliveryNoteId,
                  workId: d.workId
                }));
              if (detailList) {
                const isSaved = await this._invoiceService.AddInvoiceDetailList(
                  detailList as InvoiceDetail[]
                );
                if (isSaved) {
                  this._toastyService.success({
                    title: this._commonDataService.localizationLabelList[
                      'invoice'
                    ],
                    msg: this._commonDataService.localizationLabelList[
                      'save_success'
                    ]
                  });
                  this.invoiceSaved.emit(invoice);
                } else {
                  this._toastyService.error({
                    title: this._commonDataService.localizationLabelList[
                      'invoice'
                    ],
                    msg: this._commonDataService.localizationLabelList[
                      'save_error'
                    ]
                  });
                }
              }
            }
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['invoice'],
              msg: this._commonDataService.localizationLabelList['save_error']
            });
          }
          this.commonUiService.CloseModalEx(this.modalId);
          this.HideCollaborator();
        }
      }
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  OnClearDeliveryNoteIssuer() {
    this.formGroup.controls.deliveryNoteSeriesId.setValue(0);
    this.formGroup.controls.deliveryNoteIssuerId.setValue(0);
    this.formGroup.controls.deliveryNoteIssuerName.setValue('');
    this.deliveryNoteSeriesList = [];
  }

  OnClearInvoiceIssuer() {
    this.formGroup.controls.invoiceSeriesId.setValue(0);
    this.formGroup.controls.invoiceIssuerId.setValue(0);
    this.formGroup.controls.invoiceIssuerName.setValue('');
    this.formGroup.controls.generateNumber.setValue('');
    this.invoiceSeriesList = [];
  }


  async OnSelectDoctor(doctor: Doctor) {
    this.formGroup.controls.collaboratorId.setValue(doctor.id);
  }
  OnDeliveryNoteDoctorCleared() {
    this.formGroup.controls.collaboratorId.setValue(0);
    this.formGroup.controls.collaboratorName.setValue('');

  }
  HideCollaborator()
  {
    this.isShowDoctorDropDown=false;
  }
}
