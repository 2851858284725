import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastyService } from 'ng2-toasty';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { WorkTypeProductTemplate } from 'src/app/modules/work-type/entities/work-type-model-templates';
import { MainWorkType } from '../../entities/main-work-type';
import { Mwtproduct } from '../../entities/main-work-type-product';
import { Work } from '../../entities/work';
import { ProductTemplateService } from '../../services/product-template.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';
@Component({
  selector: 'app-warehouse-product-modal',
  templateUrl: './warehouse-product-modal.component.html'
})
export class WarehouseProductModalComponent implements OnInit {
  @Output() closeModel: EventEmitter<any> = new EventEmitter();
  @Output() refreshData: EventEmitter<any> = new EventEmitter();
  @Input() productTemplateList: WorkTypeProductTemplate[];
  @Input() currentMainWorkTypeNumberList: any[];

  selectedMainWorkTypeList: MainWorkType[] = [];
  mwtProductList: Mwtproduct[] = [];

  selectedWork: Work;
  transferableObj: any = {};
  public enableExportButton: boolean;

  constructor(public commonUiService: CommonUIService,
    private _apiService: ApiService,
    private _workService: WorkService,
    private _toastyService: ToastyService,
    private _commonDataService: CommonDataService,
    private __service: ProductTemplateService) {

  }

  ngOnInit() {
  }

  async OnSaveMovement() {
    if (this.productTemplateList.length > 0) {
      this.productTemplateList = this.productTemplateList.filter(b => b.isMomentUpdate === false);
      let countWithoutWarehouseList = this.productTemplateList.filter(b => b.warehouseId == null);

      if (countWithoutWarehouseList.length > 0) {
        this._toastyService.warning({
          title: this._commonDataService.localizationLabelList['product'],
          msg: this._commonDataService.localizationLabelList['please_select']
        });
        return;
      }
      this.mwtProductList = this.productTemplateList.map(x => {

        const currentMainWorkType = this.currentMainWorkTypeNumberList.find(
          m => Number(m.id) === x.mainWorkTypeId
        );

        const mwtproduct = new Mwtproduct();
        mwtproduct.id = x.id;
        mwtproduct.mainWorkTypeId = x.mainWorkTypeId;
        mwtproduct.productId = x.productId;
        mwtproduct.quantity = x.quantity;
        mwtproduct.lotId = x.lotId.toString();
        mwtproduct.stockOutDate = x.stockOutDate;
        mwtproduct.price = x.price;
        mwtproduct.mwtArticleId = x.mwtArticleId;
        // mwtproduct.workId=x.workerId;
        // mwtproduct.createdBy=x;
        // mwtproduct.createdDate: string;
        mwtproduct.productName = x.productName;
        // mwtproduct.prevQuantity: number;
        // mwtproduct.mainWorkTypeNo: string;
        mwtproduct.warehouseId = x.warehouseId;
        // mwtproduct.status=x.st;
        mwtproduct.workerId = x.workerId;
        mwtproduct.isUrgentOrder = x.isUrgentOrder;
        mwtproduct.note = x.note;
        mwtproduct.mainWorkTypeNo = currentMainWorkType.generateNo;
        return mwtproduct;
      });


      this._apiService.post(API.main_work_type_work.createMovementAtOnce, this.mwtProductList, true)
        .subscribe(
          res => {
            if (res === true) {
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList["movement"],
                msg: this._commonDataService.localizationLabelList["update_success"]
              });
            } else {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList["movement"],
                msg: this._commonDataService.localizationLabelList["save_error"]
              });
            }
          },
          err => {
            console.log(err);
          },
          () => {
            this.closeModel.emit();
            this.refreshData.emit();
          }
        );
    }
  }

  OnClose() {
    this.closeModel.emit();
  }
}
