import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import API from './../../common/api.config.json';
import { ClientDefaultPrintReport } from '../entities/client-default-print-report';
import { ClientBank } from '../entities/client-bank';
import { ClientPhysicalData } from 'src/app/modules/client/entities/client-physical-data';
import { ClientMetal } from '../entities/client-metal';
import { Client } from '../entities/client';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private _apiService: ApiService) { }

  DeleteClient(id: number) {
    return this._apiService.delete(API.client.deleteClient, id.toString(), true).toPromise();
  }

  GetClientDefaultPrintReportByIdAndType(clientId: number, type: 'invoice' | 'receipt' | 'deliverynote') {
    return this._apiService.get(API.client_default_print.getClientDefaultPrintList + clientId + '/' + type)
      .map(list => list ? list as ClientDefaultPrintReport[] : []).toPromise();
  }

  SaveDefaultTemplates(defaultTemplate: ClientDefaultPrintReport) {
    return this._apiService.post(API.client_default_print.saveClientDefaultPrint, defaultTemplate)
      .map(cdp => cdp as ClientDefaultPrintReport).toPromise();
  }

  GetClientBankDetailsById(clientId: number) {
    return this._apiService.get(API.client_bank_details.getClientBankDetails + clientId)
      .map(bankDetails => bankDetails as ClientBank).toPromise();
  }

  SaveClientBankDetails(clientBankDetails: ClientBank) {
    return this._apiService
      .post(API.client_bank_details.saveClientBankDetails, clientBankDetails, false)
      .toPromise();
  }

  UpdateClientBankDetails(clientBankDetails: ClientBank) {
    return this._apiService.put(API.client_bank_details.updateClientBankDetails +
      clientBankDetails.id, clientBankDetails, false).toPromise();
  }

  GetClientPhysicalDataById(clientId: number) {
    return this._apiService.get(API.client_physicalData.getClientPhysicalDataList + clientId)
      .map(list => list as ClientPhysicalData).toPromise();
  }

  SaveClientMetal(clientMetal: ClientMetal) {
    return this._apiService.post(API.client_metal.addClientMetal, clientMetal, false)
      .toPromise();
  }

  DeleteClientMetal(id: number) {
    return this._apiService.delete(API.client_metal.deleteClientMetal, id.toString())
      .toPromise();
  }

  SearchClients(query: string) {
    return this._apiService.get(API.client.search_clients + query, false)
      .map(list => list as Client[])
      .toPromise();
  }

  GetClientMetalListByClientId(clientId: number) {
    return this._apiService.get(API.client_metal.getClientMetalList + clientId)
      .map(list => list as ClientMetal[]).toPromise();
  }
}
