import { Issuer } from "../../auxiliary/entities/issuer";

export class UsingReportModel {
    date: string;
    madeBy: string;
    address: string;
    cif: string;
    licenseNumber: string;
    patient: string;
    typeOfWorkNo: string;
    prescriptor: string;
    healthCenter: string;
    healthCenterAddress: string;
    healthCenterTown: string;
    healthCenterProvince: string;
    healthCenterCp: string;
    registeredNo: string;
    issuer: Issuer;
    town: string;
    deliveryNoteNos: string;

}