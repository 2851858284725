import { SafeResourceUrl } from '@angular/platform-browser';

export class MWTExternalDataDocuments {
    id: number;
    fileName: string;
    generateFileName: string;
    mwtexternalDataId: number;
    mainWorkTypeId: number;

    file: any;
    fileList: any;
    extension: string;
    url: string;
    link: string;
    safeUrl: SafeResourceUrl;
    path: string;

    constructor(init?: Partial<MWTExternalDataDocuments>) {
        if (init) { Object.assign(this, init); }
    }
}
