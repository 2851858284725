import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import { CommonFunctions } from '../../common/services/common-functions';
import API from './../../common/api.config.json';
import { Document } from '../entities/document';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private _apiService: ApiService) { }

  GetDocumentListByMainWorkTypeIdList(idList: { id: number }[]) {
    try {
      const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
      if (params) {
        const query = params.toString().replace('+', '%20');
        return this._apiService.get(API.work_panels.getDocumentListByMainWorkTypeIdList + query, true)
          .map(d => d as Document[]).toPromise();
      }
    } catch (error) { console.log(error); }
  }
}
