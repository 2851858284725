import { Component, OnInit, Input } from '@angular/core';
import { DocumentPrintFieldService } from 'src/app/modules/configuration/services/document-print-field.service';
import { InvoiceHeader } from 'src/app/modules/invoice/entities/invoice-header';

@Component({
  selector: 'app-invoice-template',
  templateUrl: './invoice-template.component.html'
})
export class InvoiceTemplateComponent implements OnInit {

  @Input() reportId: string;
  @Input() deliveryNoteDesc: string;
  @Input() imageData: string;
  @Input() selectedInvoiceHeader: InvoiceHeader;
  @Input() invoicedDeliveryNoteList: any[];
  @Input() ivaBreakdownList: any[];
  @Input() reportValue: number;
  @Input() isPdf?: boolean;

  footNote:string;
  
  constructor(_documentPrintFieldService : DocumentPrintFieldService,) {
    _documentPrintFieldService.GetDocumentPrintNote().then(note => {
      if(note){this.footNote = note.note}});        
  
  }

  ngOnInit() {
  }

}
