import { Directive, HostListener, Inject, LOCALE_ID } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[formControl][novaCurrencyMask]',
})
export class NovaCurrencyMaskDirective {
    locale = '';
    constructor(@Inject(LOCALE_ID) public _locale: string, public ngControl: NgControl) {
        this.locale = _locale;
    }

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event) {
        this.onInputChange(event, false);
    }

    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event) {
        this.onInputChange(event.target.value, true);
    }

    onInputChange(event, backspace) {
        let newVal = event.replace(/\D/g, '');
        if (backspace && newVal.length <= 6) {
            newVal = newVal.substring(0, newVal.length - 1);
        }
        if (newVal.length === 0) {
            newVal = '';
        } else if (newVal.length <= 3) {
            newVal = newVal.replace(/^(\d{0,3})/, '$1');
        } else if (newVal.length <= 4) {
            newVal = newVal.replace(/^(\d{0,1})(\d{0,3})/, '$1,$2');
        } else if (newVal.length <= 5) {
            newVal = newVal.replace(/^(\d{0,2})(\d{0,3})/, '$1,$2');
        } else if (newVal.length <= 6) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1,$2');
        } else if (newVal.length <= 7) {
            newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,4})/, '$1,$2,$3');
        } else if (newVal.length <= 8) {
            newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,4})/, '$1,$2,$3');
        } else if (newVal.length <= 9) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1,$2,$3');
        } else {
            newVal = newVal.substring(0, 10);
            newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/, '$1,$2,$3,$4');
        }

        let writeValue = this.replaceGTS(newVal, this.locale === 'es' ? ',' : '.');
        writeValue = this.locale === 'es' ? '€' + writeValue : writeValue + '€';
        this.ngControl.valueAccessor.writeValue(writeValue);
    }

    toNumber(val) {
        const valArr = val.split('');
        const valFiltered = valArr.filter(x => !isNaN(x));
        const valProcessed = valFiltered.join('');
        return valProcessed;
    }

    replaceGTS(val: string, replacement: string) {
        return val.replace(/,/g, replacement);
    }
}
