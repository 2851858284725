import { Injectable } from '@angular/core';
import { ApiService } from '../../common/services/api.service';
import API from '../../common/api.config.json';
import { WorkReservation } from '../entities/work-reservation';
import { ReservationMethods } from '../../auxiliary/entities/reservation-methods';
import { WorkReservationDetails } from '../entities/work-reservation-details';
import { Client } from '../../client/entities/client';
import { Patient } from '../../work/entities/patient';
import { WorkType } from '../../work-type/entities/work-type';
import { Task } from '../../work/entities/task';
import { Worker } from '../../workers/entities/worker';
import { ReservationConfiguration } from '../entities/reservation-configuration';
import { TaskTopes } from '../../tasks/entities/task-topes';

@Injectable({
  providedIn: 'root'
})
export class WorkReservationService {

  clientList: Client[] = [];
  patientList: Patient[] = [];
  workTypeList: WorkType[] = [];
  taskList: Task[] = [];
  reservationMethodList: ReservationMethods[] = [];
  workerList: Worker[] = [];

  constructor(private _apiService: ApiService) {
    this.clientList = [] as Client[];
    this.patientList = [] as Patient[];
    this.workTypeList = [] as WorkType[];
    this.taskList = [] as Task[];
    this.workerList = [] as Worker[];
    this.reservationMethodList = [] as ReservationMethods[];
  }

  /**
* get reservation details list
*/
  GetWorkReservationDetailsByReservationId(id: number) {
    return this._apiService.get(API.work_reservation.getWorkReservationDetailsByReservationId + id, true)
      .map(list => list as WorkReservationDetails[]).toPromise();
  }

  /**
* get reservation method list
*/
  GetReservationMethodList() {
    return this._apiService.get(API.reservation_method.getReservationMethodsList, true)
      .map(list => list as ReservationMethods[]).toPromise();
  }

  DeleteReservation(reservationId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._apiService.delete(API.work_reservation.deleteWorkReservation, reservationId.toString(), true)
        .subscribe(response => resolve(response),
          err => reject(err));
    });
  }

  /**
  * get reservation by id
  */
  GetWorkReservationById(id: number) {
    return this._apiService.get(API.work_reservation.getWorkReservationById + id, true)
      .map(data => data as WorkReservation).toPromise();
  }

  UpdateReservation(reservation: WorkReservation) {
    return this._apiService.put(API.work_reservation.editWorkReservation + reservation.id, reservation, false, true).toPromise();
  }

  /**
  * get reservation config
  */
  GetReservationConfig() {
    return this._apiService.get(API.reservation_configuration.get_reservation_config, true)
      .map((reservation: ReservationConfiguration) => (reservation) ? reservation.maxTope : 0).toPromise();
  }

  /**
  * validate task own tope for date
  */
  ValidateReservationTask(taskId: number, date: string, newValue: number) {
    return this._apiService.get(API.work_reservation.validateReservationTask + taskId + '/' + date + '/' + newValue, true)
      .map((validObj: any) => validObj).toPromise();
  }

  /**
 * validate all reservation with tasks
 */
  ValidateAllReservationTask(list: WorkReservationDetails[]) {    
    return this._apiService.post(API.work_reservation.validateReservationTasksWithTope, list, true)
      .map((isValid: boolean) => isValid).toPromise();
  }

  /**
  * update date tope using id
  */
  UpdateTaskTopeByDateAndId(taskTope: TaskTopes) {
    return this._apiService.post(API.reservation_configuration.update_taskTope_by_date_id, taskTope, true)
      .map((isUpdate: boolean) => isUpdate).toPromise();
  }

  /**
  * get reservation for client patient
  */
  GetWorkReservationForClientPatient(clientId: number, patientId: number) {
    return this._apiService.get(API.work_reservation.getWorkReservationForClientPatient + clientId + '/' + patientId, true)
      .map((reservationList: WorkReservation[]) => reservationList).toPromise();
  }

}
