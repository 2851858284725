import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Issuer } from '../../auxiliary/entities/issuer';
import { ShippingForms } from '../../auxiliary/entities/shipping-forms';
import API from '../../common/api.config.json';
import { ApiService } from '../../common/services/api.service';
import { Series } from '../../invoice/entities/series';
import { Product } from '../../products/entities/product';
import { ProductProvider } from '../../products/entities/product-provider';
import { ProviderViewModel } from '../../provider/entities/provider-view-model';
import { OrderDetail } from '../entities/order-detail';
import { OrderHeader } from '../entities/order-header';
import { VatBreakdownModel } from '../entities/vat-breakdown-model';
import { toFixedNumber } from '../../common/services/calculations/calculation';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  isMinimumStockLoad = false;
  isOrderDetailsLoad = false;
  isShowEmailPopup = false;
  isOrderView = false;
  isProductEdit = false;
  isSave = false;
  orderList: OrderHeader[] = [];

  constructor(private _apiService: ApiService) {
    this.isMinimumStockLoad = false;
    this.isOrderDetailsLoad = false;
  }

  //#region list data load

  /**
 * get active product list
 */
  GetProductList() {
    return this._apiService.get(API.product.getProductList, true)
      .map((list: Product[]) => list.filter(i => i.status)).toPromise();
  }

  /**
 * get provider list
 */
  GetProviderList() {
    return this._apiService.get(API.provider_manufacturer_details.getProviderManufacturerDetails, true)
      .map(list => list as ProviderViewModel[]).toPromise();
  }

  /**
* get product list by provider id
* @param providerId
*/
  GetProductListByProviderId(providerId: number) {
    return this._apiService.get(API.product.getProductListByProviderId + providerId)
      .map(list => list as Product[]).toPromise();
  }

  /**
  * get provider product list
  * @param providerId
  * @param productId
  */
  GetProviderProduct(providerId: number, productId: number, reference?: string) {
    const ref = reference ? reference : undefined;
    return this._apiService.get(API.product.getProviderProduct + providerId + '/' + productId + '/' + reference)
      .map(pp => pp as ProductProvider).toPromise();
  }

  /**
 * get active issuer list
 */
  GetIssuerList() {
    return this._apiService.get(API.issuer.getIssuerList, true)
      .map((list: Issuer[]) => list.filter(i => i.status)).toPromise();
  }

  /**
   * get active series list by type, year and issuer
   * @param issuerId | issuer
   * @param year | series type
   * @param type | series type
   */
  GetOrderSerieList(issuerId: number, year: string, type: string) {
    return this._apiService.get(API.client_series.getSeriesByYearAndIssuerId + issuerId + '/' + year, true)
      .map((list: Series[]) => list.filter(i => i.status && i.seriesType === type)).toPromise();
  }

  /**
 * get order status list
 */
  GetOrderStatusList() {
    return this._apiService.get(API.orders.getOrderStatusList, true)
      .map(list => list as string[]).toPromise();
  }

  /**
 * get active shipping forms list
 */
  GetShippingFormsList() {
    return this._apiService.get(API.shipping_forms.getShippingFormsList, true)
      .map((list: ShippingForms[]) => list.filter(i => i.status)).toPromise();
  }

  //#endregion

  //#region calculations

  GetBase(price: number, qty: number, discount: number) {
    const total = (price * qty);
    return toFixedNumber((total - (total * (discount / 100))));
  }

  GetTotal(total: number, iva: number) {
    return toFixedNumber((total + (total * iva / 100)));
  }

  GetFinalTotal(initialAmount: number, vat: number, discount: number, shippingCharge: number) { // here vat is a price
    return toFixedNumber(((initialAmount + vat + shippingCharge) - discount));
  }

  /**
* calculate base and total value in relative to discount, unit price and units
*/
  async GetProductCalculations(orderDetail: OrderDetail) {
    orderDetail.basePrice = this.GetBase(toFixedNumber(orderDetail.unitPrice),
      toFixedNumber(orderDetail.units), toFixedNumber(orderDetail.discount));
    orderDetail.total = this.GetTotal(orderDetail.basePrice, toFixedNumber(orderDetail.iva));
    orderDetail.initialAmount = toFixedNumber(toFixedNumber(orderDetail.unitPrice) * toFixedNumber(orderDetail.units));
    orderDetail.discountAmount = toFixedNumber(orderDetail.initialAmount * (toFixedNumber(orderDetail.discount) / 100));
    return orderDetail;
  }

  GetOrderTotal(orderData: OrderHeader) {
    orderData.vatAmount = 0;
    orderData.iva = 0;
    orderData.initialAmount = 0;
    orderData.baseAmount = 0;
    orderData.discount = 0;
    orderData.total = 0;
    for (let index = 0; index < orderData.orderDetail.length; index++) {
      const detail = orderData.orderDetail[index];
      detail.basePrice = this.GetBase(toFixedNumber(detail.unitPrice),
        toFixedNumber(detail.units), toFixedNumber(detail.discount));
      detail.total = this.GetTotal(detail.basePrice, toFixedNumber(detail.iva));
      detail.initialAmount = toFixedNumber((toFixedNumber(detail.unitPrice) * toFixedNumber(detail.units)));
      detail.discountAmount = toFixedNumber((detail.initialAmount * (toFixedNumber(detail.discount) / 100)));
      // update header
      orderData.initialAmount += detail.initialAmount;
      orderData.discount += detail.discountAmount;
      const iva = toFixedNumber((detail.iva * detail.basePrice) / 100);
      orderData.iva += iva;

      orderData.baseAmount += detail.basePrice;
      // get final total
      orderData.total = this.GetFinalTotal(orderData.initialAmount, orderData.iva,
        orderData.discount, toFixedNumber(orderData.deliveryCharge));
    }

    orderData.vatAmount = toFixedNumber((orderData.iva + orderData.baseAmount));

    if (orderData.vatAmount < orderData.minPriceOrder) {
      orderData.leftToAvoidShippingCost = (orderData.minPriceOrder - orderData.vatAmount);
      orderData.deliveryCharge = orderData.deliveryCharge ? orderData.deliveryCharge : orderData.shippingCost;
    } else {
      orderData.deliveryCharge = 0;
      orderData.leftToAvoidShippingCost = 0;
    }
    return orderData;
  }

  async GetFinalCalculations(orderHeader: OrderHeader, orderDetailList: OrderDetail[]) {
    orderHeader.vatAmount = 0;
    orderHeader.iva = 0;
    orderHeader.initialAmount = 0;
    orderHeader.baseAmount = 0;
    orderHeader.discount = 0;
    orderHeader.total = 0;
    orderHeader.deliveryCharge = 0;
    let deliveryCharge = 0;
    let detailsTotal = 0;
    let ivaTotal = 0;

    for (let index = 0; index < orderDetailList.length; index++) {
      const orderDetail = orderDetailList[index];
      orderHeader.initialAmount += toFixedNumber(orderDetail.initialAmount);
      orderHeader.discount += toFixedNumber(orderDetail.discountAmount);
      detailsTotal += toFixedNumber(orderDetail.total);
      deliveryCharge = toFixedNumber(orderDetail.deliveryCharge)
      orderHeader.baseAmount += toFixedNumber(orderDetail.basePrice);
    }

    // vat breakdown list
    let _orderDetailList = [];
    const vatBreakdownList = [];
    _orderDetailList = _.toArray(_.groupBy(orderDetailList, 'iva')) as any[];
    for (let k = 0; k < _orderDetailList.length; k++) {
      const orderDetail = _orderDetailList[k] as any[];
      const vb = new VatBreakdownModel();
      vb.baseAmount = 0;
      if (orderDetail) {
        orderDetail.forEach(e => {
          vb.iva = e.iva;
          vb.baseAmount = toFixedNumber(vb.baseAmount + e.basePrice);
          vb.vatAmount = toFixedNumber((vb.baseAmount * vb.iva) / 100);
        });
      }
      ivaTotal += vb.vatAmount;
      vatBreakdownList.push(vb);
    }

    orderHeader.iva = toFixedNumber(ivaTotal);
    orderHeader.vatAmount = toFixedNumber((orderHeader.iva + orderHeader.baseAmount));
    orderHeader.discount = toFixedNumber(((orderHeader.initialAmount + orderHeader.iva) - detailsTotal));

    // get final total
    orderHeader.total = this.GetFinalTotal(toFixedNumber(orderHeader.initialAmount),
      toFixedNumber(orderHeader.iva),
      toFixedNumber(orderHeader.discount), toFixedNumber(deliveryCharge));

    // 'Left to avoid shipping' amount calculate
    let leftToAvoidShippingCost = 0;
    if (orderHeader.providerId) {
      const providerList = await this.GetProviderList();
      const provider = providerList.find(x => x.id === orderHeader.providerId && x.isProvider === true && x.isManufacturer === false);
      if (provider != null && provider.minPriceOrder) {
        if (orderHeader.vatAmount < provider.minPriceOrder) {
          const avoidShippingCost = (provider.minPriceOrder - toFixedNumber(orderHeader.vatAmount));
          leftToAvoidShippingCost = toFixedNumber(avoidShippingCost);
          orderHeader.deliveryCharge = toFixedNumber(deliveryCharge);
        } else {
          orderHeader.deliveryCharge = 0;
          deliveryCharge = 0;
          leftToAvoidShippingCost = 0;
        }
      }
      if (orderDetailList.length == 0) {
        deliveryCharge = toFixedNumber(provider.shippingCost);
        orderHeader.deliveryCharge = toFixedNumber(provider.shippingCost);
      }
    }
    orderHeader.deliveryCharge = toFixedNumber(deliveryCharge);
    return ({ orderHeader, orderDetailList, leftToAvoidShippingCost, vatBreakdownList });
  }

  //#endregion

  async GetProductByBarcode(barcode: any) {
    return this._apiService.get(API.product.getProductByBarcode + barcode, true)
      .map((product: Product) => product).toPromise();
  }

}
