import { TaskWorker } from '../../tasks/entities/task-worker';
import { FormControl } from '@angular/forms';

export class WorkTypeTemplate {
    id: number;
    description: string;
    isMetalUsed: boolean;
    isImplant: boolean;
    metalId: number;
    productDescriptionId: number;
    therapeuticPurposeId: number;
    workTypeArticleList: WorkTypeArticleTemplate[] = [];
}

export class WorkTypePhaseTemplate {
    mwtPhaseId: number;
    workTypeId: number; // mwtId
    phaseId: number;
    phaseName: string;
    phaseOrderNo: number;
    labSectionId: number;
    labSectionName: string;
    taskList: WorkTypeTaskTemplate[] = [];
    phaseStatus: string;
    cssClass: string;
    symbol: string;
    order: number;
    startDate: string;
    endDate: string;
    workTypeArticleId: number;
    mwtArticleId: number;
    mwtArticleName: string;
    locationId: number;
    isCollapsedTask = false;
    isClosed: boolean;
}

export class WorkTypeLabSectionTemplate {
    labSectionId: number;
    labSectionName: string;
    taskList: WorkTypeTaskTemplate[] = [];
}

export class WorkTypeTaskTemplate {
    mwtPhaseTaskId: number;
    taskId: number;
    taskName: string;
    taskOrderNo: number;
    resourceList: WorkTypeResourceTemplate[] = [];
    taskStatus: string;
    taskValue: number;

    taskWorkerList: TaskWorker[] = [];
    selectedTaskWorkerList: TaskWorker[] = [];
    hasWorkersAssigned: boolean;
    workerId: number;
    workerName: string;
    workerImageUrl: string;

    isVisible: boolean;
    order: number;
    deliveryDate: string;
    endTime: string;

    workerList: { workerId: number, workerName: string }[] = [];
    taskWorkerControl = new FormControl('');
    mwtphaseId: number;
    isClosed: boolean;
}

export class WorkTypeResourceTemplate {
    resourceId: number;
    resourceName: string;
}

/* Articles */

export class WorkTypeArticleTemplate {
    mwtArticleId: number;
    workTypeId: number;
    articleId: number;
    articleName: string;
    code: string;
    productList: WorkTypeProductTemplate[] = [];
    phaseList: WorkTypePhaseTemplate[] = [];
    finalPrice?: number;
    cssClass: string;
    symbol: string;
    cssBorderColorClass: string;
    quantity?: number;
    waste?: number;
    price?: number;
    discount?: number;
    discountAmount?: number;
    isAmount?: boolean;
    order: number;
    workTypeArticleId: number;
    isExpand: boolean;

    id: number; // used as mwt article id when editing multiple
    mainWorkTypeId: number; // used as mwt id when editing multiple
    isDiscountOverridden: boolean;

    articleIdControl: FormControl;
    isInvoiced: boolean;
    isDelivered: boolean;
    linkedDeliveryNoteId: number;
    isClosed: boolean;

    ivaTypeId: number | null;
    ivaPercentage: number | null;
    ivaAmount: number | null;

    quantityWithWaste: number;
    priceWithDiscount: number;
    lineDiscountAmount: number | null;
    lineSubTotal: number | null;
}

export class WorkTypeProductTemplate {
    id: number; // mwtProductId
    productId: number;
    productName: string;
    productCode: string;
    quantity: number;
    providerName: string;
    activeLot: string;
    cssClass: string;
    symbol: string;
    order: number;
    mainWorkTypeId: number;
    lotId: number;
    lotName: string;
    stockOutDate?: any;
    price?: number;
    mwtArticleId: number;
    warehouseId?: number;
    isMinimumStock: boolean;
    workerId: number;
    isUrgentOrder: boolean;
    note: string;
    isMomentUpdate: boolean;
    isUrgentOrderCreated: boolean;
    articleId: number;
    isClosed: boolean;
    externalCenterId: number;
    isExternal: boolean;
}

