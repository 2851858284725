import { DatePipe } from '@angular/common';
import { Piezas } from '../global';
import { FormArray } from '@angular/forms';

export class CommonFunctions {

  /**
    * check in any list if the given id is already existing or not (if true, id is existing; if not, id is not available in the list)
    * @param id | current id to be checked
    * @param list
    * @param property | (optional) parameter to get the property name which should be checked in the given list.
    * if it is undefined, function will check 'id' property in the list
    */
  static CheckExistingObjectInList(id: any, list: any[], property?: string): boolean {
    if (id && list) {
      if (list.length > 0) {
        return list.findIndex(x => property ? (x[property] === id) : (x.id === id)) !== -1;
      }
    }
    return false;
  }

  static Sort(list: any[], prop: string, isUpperLowerCase = false) {
    if (list.length > 0) {
      if (isUpperLowerCase) {
        return list.sort((a, b) => (a[prop] < b[prop]) ? -1 : (a[prop] > b[prop]) ? 1 : 0);
      } else {
        return list.sort((a, b) => (a[prop].toLocaleLowerCase() < b[prop].toLocaleLowerCase()) ? -1
          : (a[prop].toLocaleLowerCase() > b[prop].toLocaleLowerCase()) ? 1 : 0);
      }
    }
    return [];
  }

  static SortByDate(list: any[], prop: string) {
    return list.sort(function (a, b) {
      return (a[prop] > b[prop]) ? -1 : ((a[prop] < b[prop]) ? 1 : 0);
    });
  }

  static GetUrlParamerizedObjectByList(list: any[]): URLSearchParams {
    const params = new URLSearchParams();
    for (let index = 0; index < list.length; index++) {
      const object = list[index];
      if (object) {
        for (const key in object) {
          if (object.hasOwnProperty(key)) {
            const element = object[key];
            params.append(key, element);
          }
        }
      }
    }
    return params;
  }

  public static GetUrlParams(object: any) {
    const params = new URLSearchParams();
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const element = object[key];
        params.append(key, element);
      }
    }
    return params;
  }

  static GetNgAutoCompleteList(list: any[], property: string): any[] {
    if (list) {
      const autoCompleteList = [];
      list.forEach(item => autoCompleteList.push({ name: item[property], id: item.id }));
      return autoCompleteList;
    }
  }

  /**
   * get time string and convert it to 24 hours time format
   * @param timeString | time as a string
   * @returns string (as 24 hours format)
   */
  static Get24HoursTimeFormat(timeString: string) {
    if (timeString) {
      const hours = timeString.toString().substring(0, 2);
      const minutes = timeString.toString().substring(2, 4);
      return hours + ':' + minutes + ':' + '00';
    }
  }

  /**
   * get browser's locale
   */
  static GetBrowserLocale() {
    return navigator.language;
  }

  static GetPatientAutoCompleteList(list: any[], name: string, dob: string): any[] {
    if (list) {
      const autoCompleteList = [];
      const datePipe = new DatePipe('en-US');
      list.forEach(item => autoCompleteList.push({
        display: item[name] + ' ' + (item['surnames'] ? item['surnames'] : '') + (item[dob] ? ' - ' + datePipe.transform(item[dob], 'dd/MM/yyyy') : ''),
        value: item[name] + ' ' + (item['surnames'] ? item['surnames'] : '') +
          (item[dob] ? ' - ' + datePipe.transform(item[dob], 'dd/MM/yyyy') : '') +
          (item['historyNumber'] ? (' - ' + ' ' + item['historyNumber']) : ''),
        id: item.id
      }));
      return autoCompleteList;
    }
  }

  static ValidatePiezasNumber(piecesString: string) {
    if (piecesString) {
      if (piecesString.length > 1) {

        if (piecesString.includes('.')) {
          piecesString = piecesString.replace('.', ',');
        }

        if (piecesString.length > 2) {
          const arr = piecesString.split('-');
          if (arr.length === 2) {
            let rangeStart = '';
            if (arr[0].includes(',')) {
              const splittedArr = arr[0].split(',');
              rangeStart = splittedArr[splittedArr.length - 1];
            } else { rangeStart = arr[0]; }
            if (rangeStart.length > 0 && arr[1].length === 2) {
              const sorted = [...Piezas].sort((a, b) => a - b);
              const startIndex = sorted.findIndex(p => p === Number(rangeStart));
              const endIndex = sorted.findIndex(p => p === Number(arr[1]));
              const rangeArr = sorted.slice(startIndex, endIndex + 1);
              if (rangeArr.length > 0) {
                piecesString = piecesString.replace(rangeStart + '-' + arr[1], '');
                rangeArr.forEach(n => {
                  piecesString +=
                    (piecesString.length > 0 ? ',' : '') + n.toString();
                });
              }
            }
          }
        }
        const lastChar = piecesString[piecesString.length - 1];
        const oneBeforeLastChar = piecesString[piecesString.length - 2];
        if (lastChar && lastChar !== '-' && lastChar !== ','
          && oneBeforeLastChar !== '-' && oneBeforeLastChar !== ',') {
          let piecesList = piecesString.trim().split(',');
          piecesList = piecesList ? piecesList : [];
          const _piecesList = [...piecesList];
          if (_piecesList.length > 0) {
            for (let index = 0; index < piecesList.length; index++) {
              const piece = Number(piecesList[index].trim());
              const includes = Piezas.includes(piece);
              if (!includes) { _piecesList.splice(index, 1); }
            }
            piecesList = [..._piecesList];
            const pList = piecesList.filter((x, i, a) => a.indexOf(x) === i);
            piecesString = '';
            pList.forEach(p => {
              piecesString +=
                (piecesString.length > 0 ? ',' : '') + p.trim();
            });
          }
        } else {
          if (lastChar === ',' && oneBeforeLastChar === ',') {
            piecesString = piecesString
              .substring(0, piecesString.length - 2);
          }
        }
      }
    }
    return piecesString;
  }

  static ClearFormArray(formArray: FormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  static Paginate(array: any[], page_size: number, page_number: number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  static TrimTimeStart(timeString: string) {

    if (timeString && timeString.length == 3) {
      const isColoneInclude = timeString.includes(':');
      const h = timeString.split(':');
      const hours = Number(h[0]);
      if (hours < 10) {
        if (isColoneInclude) { return ('0' + hours + ':'); }
      } else
        return hours;
    } else return timeString;
  }

  static GetYears() {
    let years: number[] = [];
    const date = new Date().getFullYear();
    for (let i = 5; i > 0; i--) { years.push(date - i); }
    years.push(date);
    for (let i = 1; i < 6; i++) { years.push(date + i); }
    return years;
  }

  static CheckNgbDateValidity(date: any) {
    return (date !== undefined && date !== null) && (date.year !== null && date.year !== undefined && date.year.toString().length === 4)
      && (date.month !== null && date.month !== undefined && (date.month.toString().length === 1 || date.month.toString().length === 2))
      && (date.day !== null && date.day !== undefined && (date.day.toString().length === 1 || date.day.toString().length === 2));
  }

  static ConvertExcelDateToJSDate(serial: number) {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);
    const fractional_day = serial - Math.floor(serial) + 0.0000001;
    let total_seconds = Math.floor(86400 * fractional_day);
    const seconds = total_seconds % 60;
    total_seconds -= seconds;
    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;
    return new Date(date_info.getFullYear(),
      date_info.getMonth(), date_info.getDate(),
      hours, minutes, seconds);
  }
}
