import { Time } from '@angular/common';
import { Messengers } from './messenger';
import { MainWorkType } from '../../work/entities/main-work-type';
import { Client } from '../../client/entities/client';
import { Patient } from '../../work/entities/patient';

export class ShipmentPickupDetails {
    id: number;
    jobTypeNo: string;
    mainWorkTypeId: number;
    clientId: number;
    patientId: number;
    messengerId: number;
    date: any;
    time: any;
    deliveryTime: any;
    deliveryDate: any;
    urgencyTypeId: number;
    numberOfPackages: number;
    isShipment: boolean;
    createdDate: any;
    createdBy: number;
    status: boolean;
    dateView: any;
    deliveryDateView: any;

    client: Client;
    mainWorkType: MainWorkType;
    messengerName: string;
    patient: Patient;

    symbol: string;
    order: number;
    cssClass: string;
    isClosed: boolean;
}
