import {
  Component, OnInit, ChangeDetectionStrategy, AfterViewInit,
  EventEmitter, Output, ChangeDetectorRef, ViewChild, ElementRef
} from '@angular/core';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MainWorkType } from '../../entities/main-work-type';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { MWTExternalData } from '../../entities/main-work-type-external-data';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { Toast } from '../../../../modules/common/services/toast.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalNames, ExternalDataStatus } from 'src/app/modules/common/global';
import API from './../../../common/api.config.json';
import { ShowInvalidFormControls, ValidateNgSelect } from 'src/app/modules/common/services/validators';
import { ShippingForms } from 'src/app/modules/auxiliary/entities/shipping-forms';
import { MWTExternalDataDocuments } from '../../entities/main-work-type-external-data-documents';
import { ExternalWorkService } from '../../services/external-work.service';
import { ExternalCenter } from 'src/app/modules/external-shipment/entities/external-center';
import { CommonFunctions } from 'src/app/modules/common/services/common-functions';
import { LabHolidaysService } from '../../services/lab-holidays.service';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-external-shipments-modal',
  templateUrl: './external-shipments-modal.component.html',
  styleUrls: ['./external-shipments-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalShipmentsModalComponent implements OnInit, AfterViewInit {
  @Output() refreshData: EventEmitter<any> = new EventEmitter();
  @Output() closeModel: EventEmitter<any> = new EventEmitter();
  @ViewChild('observationsElement') observationsElement: any;
  @ViewChild('fileInput') fileInputVariable: ElementRef;

  // form groups
  externalDataFormGroup: FormGroup;

  // lists
  selectedMainWorkTypeList: MainWorkType[] = [];
  shippingFormList: ShippingForms[] = [];
  externalDataDocumentList: MWTExternalDataDocuments[] = [];
  externalDataStatuList = ExternalDataStatus;
  externalCenterList: ExternalCenter[] = [];

  // objects
  selectedMainWorkType: MainWorkType;
  selectedSubWork: SubWork;
  externalData: MWTExternalData;
  selectedWork: Work;
  saveDisabled = false;
  showContent = false;
  isEdit = false;
  isArchived = false;
  showPopUp = false;

  constructor(private _modalService: ModalService, private _apiService: ApiService,
    private _formBuilder: FormBuilder, private _labHolidaysService: LabHolidaysService,
    public commonUiService: CommonUIService, public _commonDataService: CommonDataService, private _sanitizer: DomSanitizer,
    public _dropdownService: DropdownService, private _toastyService: Toast, private externalWorkService: ExternalWorkService,
    private cdref: ChangeDetectorRef) {
    this.externalData = new MWTExternalData();
    this.externalDataDocumentList = [] as MWTExternalDataDocuments[];
    this.shippingFormList = [] as ShippingForms[];
    this.externalCenterList = [] as ExternalCenter[];
    this.externalDataFormGroup = this._formBuilder.group({
      mainWorkTypeId: ['', [ValidateNgSelect]],
      outSourcedNo: [],
      requestDate: [Validators.required],
      externalCenterId: [],
      shippingMethodId: [],
      statusId: [],
      deliveryDate: [],
      deliveryTime: [],
      observations: [],
      deliveryNoteNo: [],
      total: [],
      actualDeliveryDate: [],
      actualDeliveryTime: [],
    });
    this.showContent = true;
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.externalData = new MWTExternalData();
    if (this._modalService.modal === ModalNames.ExternalWorkModal) {
      if (this._modalService.transferable) {
        this.selectedMainWorkTypeList = this._modalService.transferable.mainWorkTypeList as MainWorkType[];
        this.selectedWork = this._modalService.transferable.selectedWork as Work;
        this.isEdit = this._modalService.transferable.isEdit;
        this.isArchived = this._modalService.transferable.isArchived;       
        if (this._modalService.transferable.isEdit) {
          this.OnEditMWTExternalDataModal(this._modalService.transferable.externalData as MWTExternalData);
        } else {
          this.OnOpenMWTExternalDataModal();
        }
      }
    }
  }

  async OnSelectCenter(center: ExternalCenter) {
    if (center) {
      this.externalData.externalCenterId = center.id;
      this.externalDataFormGroup.controls.externalCenterId.setValue(center.name);
    }
  }

  OnClearCenter() {
    this.externalDataFormGroup.controls.externalCenterId.reset();
    this.externalData.externalCenterId = null;
  }

  async OnOpenMWTExternalDataModal() {
    this.externalData = new MWTExternalData();
    if (this.selectedMainWorkTypeList.length > 1) {
      this.externalDataFormGroup.controls.mainWorkTypeId.setValue(0);
      this.externalData.mainWorkTypeId = 0;
    }

    if (this.selectedMainWorkTypeList.length === 1) {    
      this.externalDataFormGroup.controls['mainWorkTypeId'].setValue(this.selectedMainWorkTypeList[0].id);
      this.externalData.mainWorkTypeId = this.selectedMainWorkTypeList[0].id;
    } else { this.externalData.mainWorkTypeId = 0; }

    this.shippingFormList = this._dropdownService.shippingFormList.filter(x => x.status);
    this.externalCenterList = this._dropdownService.externalCenterList.filter(x => x.status);
    const nextNo = await this.externalWorkService.GetNextOutSourceNo();
    this.commonUiService.isPopupOpened = true;
    const nextOutSourceNo = nextNo ? nextNo.toString() : '';
    const defaultExternalCenter = this.externalCenterList.find(e => e.isDefault);
    //setting default values
    this.externalDataFormGroup.controls.externalCenterId.setValue(defaultExternalCenter ? defaultExternalCenter.name : '');
    this.externalData.externalCenterId = defaultExternalCenter ? defaultExternalCenter.id : null;
    this.externalDataFormGroup.controls.outSourcedNo.setValue(nextOutSourceNo ? nextOutSourceNo : '');
    this.externalDataFormGroup.controls.requestDate.setValue(this.GetCurrentDate());
    this.externalDataFormGroup.controls.statusId.setValue('1');
    this.externalDataFormGroup.controls.shippingMethodId.setValue('0');
    const user = this._commonDataService.GetUserFromSession();
    this.externalData.createdDate = moment().format('YYYY-MM-DD');
    this.externalData.createdBy = user ? user.id : null;
    this.observationsElement.nativeElement.click();
  }

  GetCurrentDate() {
    return this.commonUiService
      .ConvertToPickerDateFormat(moment().format('YYYY-MM-DD'));
  }

  async OnEditMWTExternalDataModal(externalData: MWTExternalData) {
    if (externalData) {
      this.commonUiService.isSpinnerVisible = true;
      this.shippingFormList = this._dropdownService.shippingFormList;
      this.externalCenterList = this._dropdownService.externalCenterList;
      this.commonUiService.isSpinnerVisible = true;
      this.commonUiService.isPopupOpened = true;
      this.externalDataFormGroup.markAsPristine();
      this.externalDataFormGroup.markAsUntouched();
      this.externalData = Object.assign(this.externalData, externalData);
      this.externalDataDocumentList = Object.assign(this.externalDataDocumentList, externalData.mwtExternalDataDocuments);
      if (externalData.requestDate) {
        this.externalData.requestDateView = this.commonUiService.ConvertToPickerDateFormat(externalData.requestDate.toString());
      }
      if (externalData.deliveryDate) {
        this.externalData.deliveryDateView = this.commonUiService.ConvertToPickerDateFormat(externalData.deliveryDate.toString());
      }
      if (externalData.actualDeliveryDate) {
        this.externalData.actualDeliveryDateView = this.commonUiService.ConvertToPickerDateFormat(externalData.actualDeliveryDate.toString());
      }
      this.externalDataFormGroup.controls.externalCenterId.setValue(externalData.externalCenter ? externalData.externalCenter.name : '');
      this.externalDataFormGroup.controls.mainWorkTypeId.setValue(externalData.mainWorkType ? externalData.mainWorkType.workTypeName : '');
      this.externalData.mainWorkTypeId = externalData.mainWorkTypeId;


      if (!this.externalData.shippingMethodId) {
        this.externalData.shippingMethodId = 0;
      }
      this.commonUiService.isSpinnerVisible = false;
      this.observationsElement.nativeElement.click();
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  OnClose() {
    this.showContent = false;
    this.closeModel.emit();
    this.commonUiService.isPopupOpened = false;
  }

  private async UpdateMWTExternalData(externalData: MWTExternalData) {
    return this._apiService.put(`${API.external_data.updateMWTExternalData}`, externalData, false, true)
      .map(response => JSON.parse(response['_body'])).toPromise();
  }

  private async saveMWTExternalData(externalData: MWTExternalData) {
    return this._apiService.post(`${API.external_data.saveMWTExternalData}`, externalData, false)
      .map(response => JSON.parse(response['_body'])).toPromise();
  }

  async OnSaveMWTExternalData() {
    this.saveDisabled = true;
    if (this.externalDataFormGroup.invalid) { ShowInvalidFormControls(this.externalDataFormGroup); this.saveDisabled = false; return; }
    try {
      this.externalData.workId = this.selectedWork.id;
      this.externalData.mwtExternalDataDocuments = [] as MWTExternalDataDocuments[];
      this.externalData.mwtExternalDataDocuments = this.externalDataDocumentList;
      this.externalData.externalCenter = null;
      if (this.externalData.deliveryDateView) {
        this.externalData.deliveryDate = this.externalData.deliveryDateView
          ? this.commonUiService.ChangeNgbDateToCustomFormat(this.externalData.deliveryDateView) : '';
      } else {
        this.externalData.deliveryDate = null;
      }
      if (this.externalData.requestDateView) {
        this.externalData.requestDate = this.externalData.requestDateView
          ? this.commonUiService.ChangeNgbDateToCustomFormat(this.externalData.requestDateView) : '';
      } else {
        this.externalData.deliveryDate = null;
      }
      if (this.externalData.deliveryTime) {
        if (!this.externalData.deliveryTime.toString().includes(':')) {
          this.externalData.deliveryTime = CommonFunctions.Get24HoursTimeFormat(this.externalData.deliveryTime);
        }
      }
      if (this.externalData.createdDate) {
        this.externalData.createdDate = moment(this.externalData.createdDate).format('YYYY-MM-DD');
      }
      if (this.externalData.actualDeliveryDateView) {
        this.externalData.actualDeliveryDate = this.externalData.actualDeliveryDateView
          ? this.commonUiService.ChangeNgbDateToCustomFormat(this.externalData.actualDeliveryDateView) : '';
      } else {
        this.externalData.actualDeliveryDate = null;
      }
      if (this.externalData.actualDeliveryTime) {
        if (!this.externalData.actualDeliveryTime.toString().includes(':')) {
          this.externalData.actualDeliveryTime = CommonFunctions.Get24HoursTimeFormat(this.externalData.actualDeliveryTime);
        }
      }

      let response = '';
      if (this.externalData && this.externalData.id > 0) {
        response = await this.UpdateMWTExternalData(this.externalData);
      }
      else {
        response = await this.saveMWTExternalData(this.externalData);
      }

      if (response) {
        this.commonUiService.isSpinnerVisible = true;
        if (response['_isSuccsess']) {
          this._toastyService.success({
            title: this._commonDataService.localizationLabelList['external_work'],
            msg: this._commonDataService.localizationLabelList['update_success']
          });
          if (this.selectedMainWorkTypeList && this.selectedMainWorkTypeList.length > 0) {
            const workType = this.selectedMainWorkTypeList.filter(x => x.id == this.externalData.mainWorkTypeId);
            if (workType && workType.length > 0) {
              workType[0].isExternal = true
            }
          }
          this.refreshData.emit();
        } else {
          this._toastyService.error({
            title: this._commonDataService.localizationLabelList['external_work'],
            msg: this._commonDataService.localizationLabelList['update_error']
          });
        }
        this.OnClose();
        this.commonUiService.isSpinnerVisible = false;
      }
    } catch (error) { console.log(error); }
    finally {
      this.saveDisabled = false;
      this.cdref.markForCheck();
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  OnChooseDocument(event: any) {
    if (event) {
      const files: any = event.target.files;
      if (files && this.externalDataDocumentList) {
        const exist = this.externalDataDocumentList.find(o => o.fileName === files[0].name);
        if (exist) {
          this._toastyService.warning({
            title: this._commonDataService.localizationLabelList['incident'],
            msg: this._commonDataService.localizationLabelList['already_exists']
          });
          this.fileInputVariable.nativeElement.value = '';
          return;
        }
      }
      const documentData = new MWTExternalDataDocuments();
      documentData.fileList = files;
      if(this.externalData.id)
      {
        documentData.mwtexternalDataId = Number(this.externalData.id);
      }else
      {
        documentData.mwtexternalDataId = Number(this.externalData.outSourcedNo);
      }
      this.OnSaveExternalDataDocument(documentData);
      this.cdref.markForCheck();
    }

  }

  OnRemoveDocumentFromList(index: number) {
    if (index !== -1) {
      this.externalDataDocumentList.splice(index, 1);
    }
  }

  public OnSaveExternalDataDocument(externalDataDocument: MWTExternalDataDocuments): void {
    if (this.externalData.mainWorkTypeId == 0) {
      return;
    }
    if (externalDataDocument) {
      if (externalDataDocument.fileList.length > 0) {
        externalDataDocument.fileName = (externalDataDocument.fileList[0]).name;
        externalDataDocument.extension = ((externalDataDocument.fileList[0]).name).split('.')[(((externalDataDocument.fileList[0]).name)
          .split('.')).length - 1];

        this.externalWorkService.SaveExternalDataDocument(externalDataDocument)
          .then(externalDocument => {
            if (externalDocument) {
              const formData: FormData = new FormData();
              formData.append(externalDocument.fileName, externalDataDocument.fileList[0], externalDocument.generateFileName);
              formData.append('folderName', 'ExternalData');
              if(this.externalData.id)
              {
                formData.append('id', this.externalData.id.toString());//
              }else{
                formData.append('id', this.externalData.outSourcedNo.toString());//
              }
            
              this.UploadDocument(formData).then(o => {
                this.fileInputVariable.nativeElement.value = '';
                externalDocument.mainWorkTypeId = this.externalData.mainWorkTypeId;
                this.getDocumentPath(externalDocument).then(external => {
                  if (externalDocument && external) {
                    externalDocument.id;
                    externalDocument.path = (external.path) ? external.path : externalDocument.path;
                    externalDocument.extension = externalDocument.fileName.split('.')[(externalDocument.fileName.split('.')).length - 1];
                    externalDocument.link = this._apiService.BASE_URL + API.external_data.getExternalDocument + externalDocument.id +
                      '/' + externalDocument.mwtexternalDataId.toString() + '/' + externalDocument.generateFileName;

                    if (externalDocument.path) {
                      if (externalDocument.extension === 'pdf') {
                        externalDocument.path = externalDocument.path.toString().includes('data:application/pdf;base64,')
                          ? externalDocument.path : 'data:application/pdf;base64,' + externalDocument.path;
                      }
                      if (externalDocument.extension === 'png') {
                        externalDocument.path = externalDocument.path.toString().includes('data:image/png;base64,')
                          ? externalDocument.path : 'data:image/png;base64,' + externalDocument.path;
                      }
                      if (externalDocument.extension === 'jpg') {
                        externalDocument.path = externalDocument.path.toString().includes('data:image/jpg;base64,')
                          ? externalDocument.path : 'data:image/jpg;base64,' + externalDocument.path;
                      }
                      if (externalDocument.extension === 'jpeg') {
                        externalDocument.path = externalDocument.path.toString().includes('data:image/jpeg;base64,')
                          ? externalDocument.path : 'data:image/jpeg;base64,' + externalDocument.path;
                      }
                      if (externalDocument.extension === 'docx') {
                        externalDocument.path = externalDocument.path.toString().includes('https://docs.google.com/gview?url=')
                          ? externalDocument.path : 'https://docs.google.com/gview?url=' + externalDocument.link + '&embedded=true';
                      }
                      if (externalDocument.extension === 'xlsx' || externalDocument.extension === 'xls') {
                        externalDocument.path = externalDocument.path.toString().includes('https://docs.google.com/gview?url=')
                          ? externalDocument.path : 'https://docs.google.com/gview?url=' + externalDocument.link + '&embedded=true';
                      }
                    }
                  }

                });
              });

              this.fileInputVariable.nativeElement.value = '';
              this.externalDataDocumentList.push(externalDocument);
              this.cdref.markForCheck();
            }
          });
      }
      this.cdref.markForCheck();
    }
  }

  async UploadDocument(formData: FormData) {
    return new Promise<void>((resolve, reject) => {
      this._apiService.upload(API.file_upload.upload, formData)
        .subscribe(res => {
          if (res['body'] == 200) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['document'],
              msg: this._commonDataService.localizationLabelList['document_upload_successfully']
            });
            resolve();
          }
        },
          err => console.log(err),
          () => this.commonUiService.isSpinnerVisible = false);
    });
  }


  async getDocumentPath(external: MWTExternalDataDocuments) {
    return new Promise<MWTExternalDataDocuments>((resolve, reject) => {
      this._apiService.post(API.external_data.getMwtExternalDocumentPath, external, true)
        .subscribe((incidentDoc: MWTExternalDataDocuments) => resolve(incidentDoc),
          err => reject(err));
    });
  }

  async onChangeExternalDeliveryDate() {
    const deliveryDateObj = this.externalDataFormGroup.get('deliveryDate').value;
    if (deliveryDateObj) {
      const deliveryDate = this.commonUiService.ChangeNgbDateToCustomFormat(deliveryDateObj);
      const isAvailable = await this._labHolidaysService.checkDate(deliveryDate);
      if (!isAvailable) {
        this.externalDataFormGroup.get('deliveryDate').setValue(null);
        this.externalData.deliveryDateView = null;
      }
    }
  }

  async onChangeExternalRequestDate() {
    const requestDateObj = this.externalDataFormGroup.get('requestDate').value;
    if (requestDateObj) {
      const requestDate = this.commonUiService.ChangeNgbDateToCustomFormat(requestDateObj);
      const isAvailable = await this._labHolidaysService.checkDate(requestDate);
      if (!isAvailable) {
        this.externalDataFormGroup.get('requestDate').setValue(null);
        this.externalData.requestDateView = null;
      }
    }
  }

  onOpenPreviewDocumentModal(modal: string, document: MWTExternalDataDocuments) {
    if (modal && document) {
      this.showPopUp = true;
      document.safeUrl = document.path ? this._sanitizer.bypassSecurityTrustResourceUrl(document.path) : undefined;
      this._modalService.Invoke(modal, true, {
        document: document, isEdit: true
      });
    }
  }

  async onChangeActualDeliveryDate() {
    const deliveryDateObj = this.externalDataFormGroup.get('actualDeliveryDate').value;
    if (deliveryDateObj) {
      const actualDeliveryDate = this.commonUiService.ChangeNgbDateToCustomFormat(deliveryDateObj);
      const workType = this.selectedMainWorkTypeList.find(w => w.id === this.externalData.mainWorkTypeId);
      if (workType && workType.deliveryDate) {
        if (workType.deliveryDate < actualDeliveryDate) {
          this._toastyService.warning({
            title: this._commonDataService.localizationLabelList['external_work'],
            msg: this._commonDataService.localizationLabelList['exceeds_delivery_date_warning']
          });
          this.externalDataFormGroup.get('actualDeliveryDate').setValue(null);
          this.externalData.actualDeliveryDateView = null;
        }
      }
    }
  }

}
