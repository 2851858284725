import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { DropDownOption } from '../../../../common/entities/dropdown-option';
import { ApiService } from '../../../../common/services/api.service';
import { CommonDataService } from '../../../../common/services/common.data.service';
import { CommonUIService } from '../../../../common/services/common.ui.service';
import { ShowInvalidFormControls } from '../../../../common/services/validators';
import { Resource } from '../../../entities/resource';
import API from './../../../../common/api.config.json';

@Component({
  templateUrl: './resource.component.html',
  // tslint:disable-next-line: component-selector
  selector: 'resource-list',
  styleUrls: ['./resource.component.scss']
})

export class ResourceComponent implements OnInit {

  successMessage: any;
  updateMessage: any;
  errorMessage: any;
  deleteMessage: any;
  statusMessage: any;
  deleteErrorMessage: any;
  tableName: any;

  resource: Resource;
  isLoad: boolean;

  position = 'bottom-right';
  resourceList: Resource[];
  private _resourceList: Resource[] = [];

  resourceFormGroup: FormGroup;
  resourceForm: NgForm;
  articleDropdown: DropDownOption[] = [];
  provinceDropdown: DropDownOption[] = [];

  @ViewChild('description') nameField: ElementRef;
  isDisabledResource = false;

  constructor(
    private _router: Router,
    private _apiService: ApiService,
    private _fb: FormBuilder,
    private _commonUIService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: Toast) {
    this.resource = new Resource();
    this.isLoad = false;
    this.isDisabledResource = false;


  }

  ngOnInit() {
    this.resourceFormGroup = this._fb.group({
      description: ['', [
        Validators.required
      ]]
    });
    this.LoadResourceList();
    this.resource = new Resource();
  }

  LoadResourceList() {
    this._apiService.get(API.resource.getResourceList)
      .subscribe(res => {
        this.resourceList = res;
        this._resourceList = res;
        this.isLoad = true;
      });
  }

  LoadEditResource(resource: Resource) {
    resource.isEdit = true;
  }

  EditResource(resource: Resource, statusChange: boolean) {
    this.isDisabledResource = true;

    resource.isEdit = false;
    this.statusMessage = this._commonDataService.localizationLabelList['status_updated'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.tableName = this._commonDataService.localizationLabelList['resource'];

    this._apiService.put(API.resource.editResource + resource.id, resource, false)
      .subscribe(
        res => {
          if (res.status === 204 || res.status === 200) {
            this._toastyService.success({
              title: this.tableName,
              msg: this.updateMessage
            });
            this.LoadResourceList();
          }
        }, err => {
          console.log(err);
        },
        () => {
          this.isDisabledResource = false;
        }
      );
  }

  RemoveEditResource(resource: Resource) {
    resource.isEdit = false;
    this.LoadResourceList();
  }

  ActiveDeactiveResource(resource: Resource) {
    if (resource) {
      resource.status = !resource.status;
      this.EditResource(resource, true);
    }
  }

  OnOpenAddResourceModal() {
    this.successMessage = this._commonDataService.localizationLabelList['save_success'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.errorMessage = this._commonDataService.localizationLabelList['save_error'];
    this.tableName = this._commonDataService.localizationLabelList['resource'];
    this.resource = new Resource(); this.resourceFormGroup.reset();
    this._commonUIService.openModal('resource-modal');
    setTimeout(() => {
      this.nameField.nativeElement.focus();
    }, 100);
  }

  OnCloseResourceModal(event) {
    (((((event.target.parentElement.parentElement).parentElement).parentElement).parentElement).parentElement).classList.remove('md-show');
  }

  SaveResource(resource: Resource, event) {
    this.isDisabledResource = true;

    if (this.resourceFormGroup.invalid) {
      ShowInvalidFormControls(this.resourceFormGroup);
      this.isDisabledResource = false;
      return;
    }
    this.OnCloseResourceModal(event);
    this._apiService.post(API.resource.saveResource, resource, false)
      .subscribe(
        res => {
          if (res.status === 204 || res.status === 200) {
            this._toastyService.success({
              title: this.tableName,
              msg: this.successMessage
            });
            this.LoadResourceList();
          }
        },
        err => {
          this._toastyService.error({
            title: this.tableName,
            msg: err
          });
          this.isDisabledResource = false;
        },
        () => {
          this.isDisabledResource = false;
        }
      );
  }

  DeleteResource(id: number) {
    this.isDisabledResource = true;

    this.deleteMessage = this._commonDataService.localizationLabelList['delete_success'];
    this.deleteErrorMessage = this._commonDataService.localizationLabelList['delete_error'];
    this.tableName = this._commonDataService.localizationLabelList['resource'];
    if (id) {
      this._apiService.delete(API.resource.deleteResource, id.toString())
        .subscribe(
          res => {
            if (res._statusCode === 200) {
              const index: number = this._resourceList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._resourceList.splice(index, 1);
                this._toastyService.success({
                  title: this.tableName,
                  msg: this.deleteMessage
                });
              }
            }
            if (res._statusCode === 409) {
              const index: number = this._resourceList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._toastyService.warning({
                  title: this.tableName,
                  msg: this.deleteErrorMessage
                });
              }
            }
          },
          err => {
            this._toastyService.error({
              title: this.tableName,
              msg: err
            });
            this.isDisabledResource = false;
          },
          () => {
            this.isDisabledResource = false;
            this.resourceList = this._resourceList;
          }
        );
    }
  }
}



