import { Component, OnInit, EventEmitter, Output, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MainWorkType } from '../../entities/main-work-type';
import { Worker } from '../../../workers/entities/worker';
import { MwtWorker } from '../../entities/main-work-type-worker';
import { ValidateNgSelect, ShowInvalidFormControls } from 'src/app/modules/common/services/validators';
import { ModalService } from '../../../common/services/modal.service';
import { ModalNames } from '../../../common/global';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { ToastData, ToastyService } from 'ng2-toasty';
import { MwtWorkerService } from '../../services/mwt-worker.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-worker-modal',
  templateUrl: './worker-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkerModalComponent implements OnInit, AfterViewInit {
  addWorkerFormGroup: FormGroup;
  selectedMainWorkTypeList: MainWorkType[] = [];
  allWorkerList: Worker[] = [];
  mwtWorker: MwtWorker;
  showContent = false;
  canEditWorker = false;

  @Output() workerSaved = new EventEmitter();
  @Output() closeModel: EventEmitter<any> = new EventEmitter();

  constructor(private _formBuilder: FormBuilder, private _commonUiService: CommonUIService, private _ngxPermissionService: NgxPermissionsService,
    private _toastyService: ToastyService, private _commonDataService: CommonDataService,
    private _modalService: ModalService, private _mwtWorkerService: MwtWorkerService, private cdref: ChangeDetectorRef) {
    this.mwtWorker = new MwtWorker();
    this.InitFormGroups();
    this.showContent = true;
  }

  ngOnInit() {
    const permissionWorker = this._ngxPermissionService.getPermission('ViewWorkers');
    this.canEditWorker = permissionWorker !== undefined;
    if (!this.canEditWorker) {
      this.addWorkerFormGroup.controls['workerId'].disable();
    } 
  }

  ngAfterViewInit(): void {
    this.mwtWorker = new MwtWorker();
    if (this._modalService.modal === ModalNames.WorkerModal) {
      this.allWorkerList = this._modalService.transferable.allWorkerList as Worker[];
      this.selectedMainWorkTypeList = this._modalService.transferable.selectedMainWorkTypeList as MainWorkType[];
      this.cdref.markForCheck();
      this.OnAdd();
    }
  }

  InitFormGroups() {
    this.addWorkerFormGroup = this._formBuilder.group({
      mainWorkTypeId: ['', ValidateNgSelect],
      workerId: ['', Validators.required]
    });
  }

  OnAdd() {
    try {
      this.addWorkerFormGroup.reset();
      this.mwtWorker = new MwtWorker();
      this.mwtWorker.workerName = '';
      if (this.selectedMainWorkTypeList.length > 0) {
        this.mwtWorker.mainWorkTypeId = 0;
      }
      this.cdref.markForCheck();
    } catch (error) { console.log(error); }
  }

  OnChangeMwtWorker(worker: Worker) {
    if (worker) {
      this.mwtWorker.workerId = worker.id;
      this.mwtWorker.workerName = worker.name;
    }
    this.cdref.markForCheck();
  }

  OnSaveMwtWorker() {
    if (this.selectedMainWorkTypeList.length === 1) {
      this.addWorkerFormGroup.controls['mainWorkTypeId'].clearValidators();
      this.addWorkerFormGroup.controls['mainWorkTypeId'].setErrors(null);
      this.addWorkerFormGroup.controls['mainWorkTypeId'].setValidators(null);
    } else { this.addWorkerFormGroup.controls['mainWorkTypeId'].setValidators(ValidateNgSelect); }
    this.addWorkerFormGroup.updateValueAndValidity();
    if (this.addWorkerFormGroup.invalid) { ShowInvalidFormControls(this.addWorkerFormGroup); } else {
      if (this.selectedMainWorkTypeList.length === 1) {
        this.mwtWorker.mainWorkTypeId = (this.selectedMainWorkTypeList[0]).id;
      }
      this._commonUiService.isSpinnerVisible = true;
      this._mwtWorkerService.AddMwtWorker(this.mwtWorker)
        .then((response) => {
          const body: any = JSON.parse(response['_body']);
          if (body['_data']) {
            this.workerSaved.emit();
            this.OnClose();
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['worker'],
              msg: this._commonDataService.localizationLabelList['save_success'],
              showClose: true,
              theme: 'default',
              timeout: 5000,
              onAdd: (toast: ToastData) => { },
              onRemove: (toast: ToastData) => { }
            });
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['worker'],
              msg: this._commonDataService.localizationLabelList['save_error'],
              showClose: true,
              theme: 'default',
              timeout: 5000,
              onAdd: (toast: ToastData) => { },
              onRemove: (toast: ToastData) => { }
            });
          }
          this.cdref.markForCheck();
          this.showContent = false;
          this._commonUiService.isSpinnerVisible = false;
          this._commonUiService.isPopupOpened = false;
        });
    }
  }

  OnClose() {
    this.showContent = false;
    this.closeModel.emit();
  }

}
