export class Note {
    id: number;
    type: string;
    createdDateTime: any;
    modifiedDateTime?: any;
    content: string;
    mainWorkTypeId: number;
    status: boolean;

    symbol: string;
    cssClass: string;
    order: number;

    constructor(init?: Partial<Note>) {
        Object.assign(this, init);
    }
}
