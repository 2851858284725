import { FormControl } from '@angular/forms';
import { MwtArticle } from './main-work-type-article';
export class MwtColor {
    id: number;
    mwtArticleId: number;
    localizationId?: number;
    colorId?: number;
    pieces: string;
    notes: string;
    status: boolean;
    colorText: string;
    localizationText: string;
    mwtArticleName: string;
    guideName: string;
    metalId: number;
    metalName: string;
    mainWorkTypeId: number;
    cssClass: string;
    symbol: string;
    piecesList: number[];
    metalArticleId: number;

    order: number;
    workId: number;
    createdBy: number;
    createdDate: string;
    isClosed: boolean;

    mwtColorControl: FormControl;
    mwtColorMetalControl: FormControl;
    mwtArticleList: MwtArticle;

    constructor(init?: Partial<MwtColor>) {
        Object.assign(this, init);
    }
}
