export class ProductDescriptions {
    id: number;
    description: string;
    code: string;
    status: boolean;
    isEdit: boolean;

    isDescriptionRequired: boolean;

    constructor() {
        this.status = true;
    }
}
