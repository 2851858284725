import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { IvaBreakdown } from 'src/app/modules/auxiliary/entities/iva-breakdown';
import { DeliverynoteViewTemplate } from 'src/app/modules/common/global';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { DocumentPrintFieldService } from 'src/app/modules/configuration/services/document-print-field.service';
import { Client } from '../../entities/client';
import { DeliveryNote } from '../../entities/delivery-note';
import { MwtColor } from '../../entities/main-work-type-color';
import { Patient } from '../../entities/patient';
import { Work } from '../../entities/work';

@Component({
  selector: 'app-delivery-note-report',
  templateUrl: './delivery-note-report.component.html'
})
export class DeliveryNoteReportComponent implements OnInit {

  @Input() deliveryNote: DeliveryNote;
  @Input() componentName: string;
  @Input() client: Client;
  @Input() patient: Patient;
  @Input() selectedWork: Work;
  @Input() selectedTemplate: { value: number, description: string };
  @Input() ivaBreakdownList: IvaBreakdown[];
  @Input() mwtColorList: MwtColor[] = [];

  imageData: any;
  footNote:string;
  
  constructor(private _commonUIService: CommonUIService, _documentPrintFieldService : DocumentPrintFieldService) {
    _documentPrintFieldService.GetDocumentPrintNote().then(note => {
      if(note){this.footNote = note.note}});  
   }

  ngOnInit() {
    this.LoadLabConfigurationList();
    this.selectedTemplate = DeliverynoteViewTemplate[0];
  }

  ngOnChanges() {
    if ((this.mwtColorList.length > 0) && this.deliveryNote && (this.deliveryNote.articleList.length > 0)) {
      this.deliveryNote.articleList.forEach(article => {
        const color = this.mwtColorList.find(c => c.mwtArticleId === article.mwtArticleId);
        if (color) {
          article.description2 = '';
          article.description2 = (color.pieces ? (' (' + color.pieces + ') -') : '') + ((color.colorText)? color.colorText : '');
        }
      })
    }
  }

  async LoadLabConfigurationList() {
    this.imageData = await this._commonUIService.LoadLabConfigurationList();
  }
}
