import { Injectable } from '@angular/core';
import { FreeBudget } from '../../budgets/entities/freeBudget';
import { ApiService } from '../../common/services/api.service';
import { CommonFunctions } from '../../common/services/common-functions';
import { Budget } from '../entities/budget';
import API from './../../common/api.config.json';

@Injectable({
  providedIn: 'root'
})
export class BudgetService {
   

  constructor(private _apiService: ApiService) { }

  GetBudgetListByMainWorkTypeIdList(idList: { id: number }[]) {
    try {
      const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
      if (params) {
        const query = params.toString().replace('+', '%20');
        return this._apiService.get(API.work_panels.getBudgetListByMainWorkTypeIdList + query, true)
          .map(b => b as Budget[]).toPromise();
      }
    } catch (error) { console.log(error); }
  }

  GetFreeBudgetListByClientPatientId(clientId: number, patientId: number) {
    // tslint:disable-next-line: max-line-length
    return this._apiService.get(API.free_budget.getFreeBudgetListByClientPatientId + clientId + '/' + patientId, true).map(x => x as FreeBudget[]).toPromise();
  }

  GetFreeBudgetById(id: number) {
    // tslint:disable-next-line: max-line-length
    return this._apiService.get(API.free_budget.getFreeBudgetById + id, true).map(x => x as FreeBudget).toPromise();
  }
}
