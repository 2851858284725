import API from '../../../common/api.config.json';
import { ApiService } from '../../../common/services/api.service';
import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { RequestData } from '../../../work/pagination/requestData';
import { LabSection } from '../../entities/lab-section';
import { Client } from '../../entities/client';
import { Doctor } from '../../entities/doctor';
import { MainWorkType } from '../../entities/main-work-type';
import { Work } from '../../entities/work';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-work-expand-list',
  templateUrl: './work-expand-list.component.html',
  styleUrls: ['./work-expand-list.component.scss']
})
export class WorkExpandListComponent implements OnInit {

  @Output() public OnClickWork: EventEmitter<Work> = new EventEmitter<Work>();

  private workList = 1;

  // pagination propeties
  sessionGetUrl: string;
  pagWorkList: any[] = [];
  requestData: RequestData = new RequestData(1, 20, '', '', '', this.workList);
  refresh: boolean;
  searchText: string;
  receivedFromDate: any;
  receivedToDate: any;
  deliveryFromDate: any;
  deliveryToDate: any;
  labSectionValue: string;
  workTypeValue: string;
  clientValue: string;
  searchReferrer: string;
  doctorValue: string;
  dateStringFormatForReceivedFromDate: any;
  dateStringFormatForReceivedToDate: any;
  dateStringFormatForDeliveryFromDate: any;
  dateStringFormatForDeliveryToDate: any;
  receivedFromDateModel: any;
  receivedToDateModel: any;
  deliveryFromDateModel: any;
  deliveryToDateModel: any;

  public enableExportButton: boolean;

  labSectionList: LabSection[];
  clientList: Client[];
  doctorList: Doctor[];
  typeOfWorkList: MainWorkType[];

  @ViewChild('rdf') received_from_date_picker: ElementRef;
  @ViewChild('rdt') received_to_date_picker: ElementRef;
  @ViewChild('ddf') delivery_from_date_picker: ElementRef;
  @ViewChild('ddt') delivery_to_date_picker: ElementRef;

  work: Work;

  constructor(
    private _apiService: ApiService,
    public _commonUIService: CommonUIService,
    private _router: Router) {
    this.work = new Work();
  }

  ngOnInit() {
    this.sessionGetUrl = API.work.getWorkExpandPaginationList;
    this.searchReferrer = '';
    this.labSectionValue = '';
    this.workTypeValue = '';
    this.clientValue = '';
    this.labSectionValue = '';
    this.doctorValue = '';
    this.LoadLabSectionList();
    this.LoadClientList();
    this.LoadDoctorList();
    this.LoadWorkTypeList();
    this.receivedFromDateModel = '';
    this.receivedToDateModel = '';
    this.deliveryFromDateModel = '';
    this.deliveryToDateModel = '';
    this.enableExportButton = false;
  }

  LoadLabSectionList() {

    this._apiService.get(API.lab_sections.getlabSectionsList)
      .subscribe(res => {
        this.labSectionList = res;
        this.enableExportButton = false;
      });
  }

  LoadClientList() {

    this._apiService.get(API.client.getClients)
      .subscribe(res => {
        this.clientList = res;
        this.enableExportButton = false;
      });
  }

  LoadWorkTypeList() {
    this._apiService.get(API.main_work_type_work.getMainWorkTypeList)
      .subscribe(res => {
        this.typeOfWorkList = res;
        this.enableExportButton = false;
      }, err => console.log(err));
  }

  LoadDoctorList() {
    this._apiService.get(API.doctor.getDoctorList)
      .subscribe(res => {
        this.doctorList = res;
        this.enableExportButton = false;
      });
  }

  EnableSearchButton() {

    if (this.clientValue || this.labSectionValue || this.workTypeValue || this.doctorValue
      || this.searchReferrer || this.receivedFromDateModel || this.receivedToDateModel
      || this.deliveryFromDateModel || this.deliveryToDateModel) {
      this.enableExportButton = true;
    } else {
      this.enableExportButton = false;
    }
  }

  FilterWorkByText() {

    if ((this.received_from_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = (this.received_from_date_picker['_elRef']).nativeElement['value'];
      if (dateString != undefined) {
        this.dateStringFormatForReceivedFromDate = new Date(dateString).toISOString();
        this.receivedFromDate = dateString;
      } else {
        this.receivedFromDate = '';
      }
    } else {
      this.receivedFromDate = '';
    }

    if ((this.received_to_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = (this.received_to_date_picker['_elRef']).nativeElement['value'];
      if (dateString != undefined) {
        this.dateStringFormatForReceivedToDate = new Date(dateString).toISOString();
        this.receivedToDate = dateString;
      } else {
        this.receivedToDate = '';
      }
    } else {
      this.receivedToDate = '';
    }

    if ((this.delivery_from_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = (this.delivery_from_date_picker['_elRef']).nativeElement['value'];
      if (dateString != undefined) {
        this.dateStringFormatForDeliveryFromDate = new Date(dateString).toISOString();
        this.deliveryFromDate = dateString;
      } else {
        this.deliveryFromDate = '';
      }
    } else {
      this.deliveryFromDate = '';
    }

    if ((this.delivery_to_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = (this.delivery_to_date_picker['_elRef']).nativeElement['value'];
      if (dateString != undefined) {
        this.dateStringFormatForDeliveryToDate = new Date(dateString).toISOString();
        this.deliveryToDate = dateString;
      } else {
        this.deliveryToDate = '';
      }
    } else {
      this.deliveryToDate = '';
    }

    this.requestData.filter = `${this.clientValue};${this.labSectionValue};${this.doctorValue};${this.searchReferrer};${this.receivedFromDate};${this.receivedToDate};${this.deliveryFromDate};${this.deliveryToDate}`;
    //this.requestData.filter = `${this.clientValue};${this.labSectionValue};${this.workTypeValue};${this.doctorValue};${this.searchReferrer};${this.receivedFromDate};${this.receivedToDate};${this.deliveryFromDate};${this.deliveryToDate}`;
    this.refresh = true;

  }

  ExportAsXLSX(): void {

    if ((this.received_from_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = (this.received_from_date_picker['_elRef']).nativeElement['value'];
      if (dateString != undefined) {
        this.dateStringFormatForReceivedFromDate = new Date(dateString).toISOString();
        this.receivedFromDate = dateString;
      } else {
        this.receivedFromDate = '';
      }
    } else {
      this.receivedFromDate = '';
    }

    if ((this.received_to_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = (this.received_to_date_picker['_elRef']).nativeElement['value'];
      if (dateString != undefined) {
        this.dateStringFormatForReceivedToDate = new Date(dateString).toISOString();
        this.receivedToDate = dateString;
      } else {
        this.receivedToDate = '';
      }
    } else {
      this.receivedToDate = '';
    }

    if ((this.delivery_from_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = (this.delivery_from_date_picker['_elRef']).nativeElement['value'];
      if (dateString != undefined) {
        this.dateStringFormatForDeliveryFromDate = new Date(dateString).toISOString();
        this.deliveryFromDate = dateString;
      } else {
        this.deliveryFromDate = '';
      }
    } else {
      this.deliveryFromDate = '';
    }

    if ((this.delivery_to_date_picker['_elRef']).nativeElement['value']) {
      const dateString: string = (this.delivery_to_date_picker['_elRef']).nativeElement['value'];
      if (dateString != undefined) {
        this.dateStringFormatForDeliveryToDate = new Date(dateString).toISOString();
        this.deliveryToDate = dateString;
      } else {
        this.deliveryToDate = '';
      }
    } else {
      this.deliveryToDate = '';
    }

    this.work.clientValue = +this.clientValue;
    this.work.labSectionValue = +this.labSectionValue;
    // this.work.workTypeValue = +this.workTypeValue;
    this.work.doctorValue = +this.doctorValue;
    this.work.searchReferrer = this.searchReferrer;
    this.work.receivedFromDate = this.receivedFromDate;
    this.work.receivedToDate = this.receivedToDate;
    this.work.deliveryFromDate = this.deliveryFromDate;
    this.work.deliveryToDate = this.deliveryToDate;

    const params = new URLSearchParams();
    for (const key in this.work) {
      params.set(key, this.work[key])
    }

    const query = params.toString().replace('+', '%20');

    const url = this._apiService.BASE_URL + `/api/Works/WorkExcelExport?${query}`;
    window.open(url, '_blank');
  }

  async OnClickWorkForWorkExpand(args: { workId: number, subWorkId: number }) {
    if (args) {
      if (args.workId && args.subWorkId) {
        this._router.navigate(['/work'], { queryParams: args });
      } else { console.log('work id null'); }
    }
  }
}




