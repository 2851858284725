import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GenerateNumberModel } from '../../entities/generate-number-model';
import { Series } from 'src/app/modules/client/entities/series';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-generate-number',
  templateUrl: './generate-number.component.html'
})
export class GenerateNumberComponent implements OnInit {

  @Input() public generateNumberModel: GenerateNumberModel;
  @Input() public usedSeries: Series;
  @Input() public numberOfDigits: number;
  @Input() public modelId: string;

  @Output() public onSubmitGeneratedNumber: EventEmitter<string> = new EventEmitter<string>();

  public generateNumberFormGroup: FormGroup;

  constructor(private _formBuilder: FormBuilder) {
    this.generateNumberFormGroup = this._formBuilder.group({
      isReserveActualNumber: [],
      isNextAvailableNumber: [],
      isAnother: [],
      isNoNumberAssigned: [],
      isOtherAvailableNumbers: [],
      reserveActualNumber: [],
      nextAvailableNumber: [],
      anotherNumber: [],
      otherAvailableNumbers: []
    });
  }

  ngOnInit() { }

  //#region Generate Number

  public GenerateNumber(event: any): void {
    if (this.generateNumberModel && this.usedSeries && event) {
      if (this.usedSeries) {
        let prefix: string = (this.usedSeries.seriesType === 'Delivery Note'
          || this.usedSeries.seriesType === 'Bill' || this.usedSeries.seriesType === 'Budget') ? this.usedSeries.series.toUpperCase() : '';
        let generatedNumber: number = this.generateNumberModel.isNextAvailableNumber ? this.generateNumberModel.nextAvailableNumber :
          this.generateNumberModel.isAnother ? this.generateNumberModel.anotherNumber : 0;
        if (generatedNumber != 0) {
          let year: string = (this.usedSeries.seriesType === 'Delivery Note' || this.usedSeries.seriesType === 'Bill' || this.usedSeries.seriesType === 'Budget') ? new Date().getFullYear().toString() : '';
          let yearSuffix: string = (this.usedSeries.seriesType === 'Delivery Note' || this.usedSeries.seriesType === 'Bill' || this.usedSeries.seriesType === 'Budget') ? year.slice(-2) : '';
          let generatedNo = prefix + '-' + generatedNumber.toString().padStart(this.numberOfDigits, '0') + '/' + yearSuffix;
          if (this.usedSeries.seriesType === 'Work') {
            generatedNo = generatedNo.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
            generatedNo = generatedNo.replace('-', "");
          }
          if (generatedNo) this.onSubmitGeneratedNumber.emit(generatedNo);
        }
        else this.onSubmitGeneratedNumber.emit(null);
        this.OnCloseModal(event);
      }
    }
  }

  //#endregion

  //#region Events

  public OnIncreaseNextAvailableNumber(): void { if (this.generateNumberModel.nextAvailableNumber) this.generateNumberModel.nextAvailableNumber++; }

  public OnDecreaseNextAvailableNumber(): void { if (this.generateNumberModel.nextAvailableNumber) this.generateNumberModel.nextAvailableNumber--; }

  public OnIncreaseAnotherAvailableNumber(): void { if (this.generateNumberModel.anotherNumber) this.generateNumberModel.anotherNumber++; }

  public OnDecreaseAnotherAvailableNumber(): void { if (this.generateNumberModel.anotherNumber) this.generateNumberModel.anotherNumber--; }

  public OnCheckOption(option: string): void {
    this.generateNumberModel.isReserveActualNumber = false;
    this.generateNumberModel.isAnother = false;
    this.generateNumberModel.isNextAvailableNumber = false;
    this.generateNumberModel.isNoNumberAssigned = false;
    this.generateNumberModel.isOtherAvailableNumbers = false;

    if (option === 'ReserveActualNumber') this.generateNumberModel.isReserveActualNumber = true;
    else if (option === 'NextAvailableNumber') this.generateNumberModel.isNextAvailableNumber = true;
    else if (option === 'AnotherNumber') this.generateNumberModel.isAnother = true;
    else if (option === 'OtherAvailableNumbers') this.generateNumberModel.isOtherAvailableNumbers = true;
    else if (option === 'NoNumberAssigned') this.generateNumberModel.isNoNumberAssigned = true;
  }

  public OnCloseModal(event: any): void { (event.path[6]).classList.remove('md-show'); }

  //#endregion
}
