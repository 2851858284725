import { Province } from './province';
import { Country } from './country';
export class Town {
  id: number;
  description: string;
  provinceId: number;
  countryId: number;
  status: boolean;
  isEdit: boolean;
  province: Province;
  country: Country;
  provinceName: string;
  countryName: string;
  postcode: string;
  descriptionWithPostcode: string;

  model: string;

  constructor() {
    this.status = true;
  }
}
