import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubscriptionReason } from '../../../auxiliary/entities/subscription-reason.model';
import { DropdownService } from '../../../common/services/dropdown.service';
import { Issuer } from '../../../client/entities/client-issuer';
import { WorkService } from '../../../work/services/work.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { SeriesTypes } from '../../../common/global';
import { Series } from 'src/app/modules/client/entities/series';
import { InvoiceCancellationFormModel } from '../../entities/invoice-cancellation-form-model';
import { NumberingProcessService } from 'src/app/shared/numbering-process/numbering-process.service';
import { NumberingProcess } from 'src/app/shared/numbering-process/numbering-process';
import { ValidateNgSelect, ShowInvalidFormControls } from '../../../common/services/validators';
import { InvoiceCancellation } from '../../entities/invoice-cancellation';
import { InvoiceCancellationService } from '../../services/invoice-cancellation.service';
import { Toast } from '../../../common/services/toast.service';
import { CommonDataService } from '../../../common/services/common.data.service';
import { InvoiceHeader } from '../../entities/invoice-header';

@Component({
  selector: 'app-invoice-cancellation-modal',
  templateUrl: './invoice-cancellation-modal.component.html'
})
export class InvoiceCancellationModalComponent implements OnInit {

  @Input() args: { invoiceId: number, issuer: { id: number, name: string } };
  @Output() popupClosed = new EventEmitter<{ isSave: boolean, duplicate?: InvoiceHeader }>();
  formGroup: FormGroup;

  subscriptionReasonList: SubscriptionReason[] = [];
  issuerList: Issuer[] = [];
  seriesList: Series[] = [];

  constructor(private _formBuilder: FormBuilder,
    private _dropdownService: DropdownService,
    private commonUiService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _numberingProcessService: NumberingProcessService,
    private _cancellationService: InvoiceCancellationService,
    private _toastService: Toast,
    private _workService: WorkService) { this.InitFormGroup(); }

  InitFormGroup() {
    this.formGroup = this._formBuilder.group({
      generatedNo: ['', Validators.required],
      reasonForSubscriptionId: [0, Validators.required],
      reasonForSubscriptionName: ['', Validators.required],
      issuerId: [0, Validators.required],
      issuerName: ['', Validators.required],
      seriesId: ['0', ValidateNgSelect],
      invoiceId: [0],
      observation: ['']
    });
  }

  ngOnInit() {
    this.subscriptionReasonList = this._dropdownService.subscriptionReasonList;
    this.issuerList = this._dropdownService.issureList;
    if (this.args) {
      this.formGroup.controls.invoiceId.setValue(this.args.invoiceId);
      if (this.args.issuer.id) {
        this.SetCancellationSeriesList(this.args.issuer.id);
        this.formGroup.controls.issuerId.setValue(this.args.issuer.id);
        this.formGroup.controls.issuerName.setValue(this.args.issuer.name);
      }
    }
  }

  OnClosePopup(isSave: boolean, duplicate?: InvoiceHeader) {
    this.popupClosed.emit({ isSave: isSave, duplicate: duplicate });
  }

  async SetCancellationSeriesList(issuerId: number) {
    const list = await this.GetSeriesListByIssuerIdAndType(issuerId, SeriesTypes.CancelledInvoice);
    this.seriesList = list ? list : [];
  }

  private async GetSeriesListByIssuerIdAndType(issuerId: number, type: string) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      let seriesList = await this._workService.GetSeriesListByIssuerId(Number(issuerId));
      seriesList = seriesList ? seriesList : [];
      return seriesList.filter(s => s.seriesType.toLowerCase() === type.toLowerCase() &&
        s.year === new Date().getFullYear().toString() && s.status === true);
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  OnChangeSubscriptionReason(reason: SubscriptionReason) {
    if (reason) {
      this.formGroup.controls.reasonForSubscriptionId.setValue(reason.id);
      this.formGroup.controls.reasonForSubscriptionName.setValue(reason.description);
    } else {
      this.formGroup.controls.reasonForSubscriptionId.setValue(0);
      this.formGroup.controls.reasonForSubscriptionName.setValue('');
    }
  }

  OnChangeSeries() {
    const seriesId = this.formGroup.controls.seriesId.value;
    if (Number(seriesId)) {
      const series = this.seriesList.find(s => s.id === Number(seriesId));
      const np = new NumberingProcess({
        isNextAvailableNumber: true,
        isAnother: false,
        isNoNumberAssigned: false,
        isOtherAvailableNumbers: false,
        nextAvailableNumber: series.lastNumber,
        anotherNumber: 0,
        otherAvailableNumberList: [],
        otherAvailableNumber: ''
      });
      const gno = this._numberingProcessService.GetGeneratedNumber(np, 5, {
        id: seriesId,
        type: 'Invoice Cancel',
        series: series.series
      });
      this.formGroup.controls.generatedNo.setValue(gno.generateNumber);
    } else {
      this.formGroup.controls.generatedNo.setValue('');
    }
  }

  async OnSaveCancellation() {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (this.formGroup.invalid) { ShowInvalidFormControls(this.formGroup); } else {
        const form = this.formGroup.value as InvoiceCancellationFormModel;
        const cancellation = new InvoiceCancellation({
          invoiceId: form.invoiceId,
          generatedNo: form.generatedNo,
          issuerId: form.issuerId,
          seriesId: form.seriesId,
          reasonForSubscriptionId: form.reasonForSubscriptionId,
          observation: form.observation
        });

        const response = await this._cancellationService.SaveInvoiceCancellation(cancellation);
        if (response) {
          const body = JSON.parse(response['_body']);
          this.ShowFeedback(body);
        }
      }
    } catch (error) { console.log(error); } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  private ShowFeedback(body: any) {
    switch (body._statusCode) {
      case 200:
        const data = body._data;
        if (data.cancellationDone) {
          this._toastService.success({
            title: this._commonDataService.localizationLabelList['invoice_cancellation'],
            msg: this._commonDataService.localizationLabelList['save_success']
          });
          this.OnClosePopup(true, data.duplicate);
        } else {
          this._toastService.error({
            title: this._commonDataService.localizationLabelList['invoice_cancellation'],
            msg: this._commonDataService.localizationLabelList['save_error']
          });
        }
        break;
      case 409:
        this._toastService.warning({
          title: this._commonDataService.localizationLabelList['invoice_cancellation'],
          msg: this._commonDataService.localizationLabelList['invalid_gen_number_or_existing']
        });
        break;
      default:
        this._toastService.error({
          title: this._commonDataService.localizationLabelList['invoice_cancellation'],
          msg: this._commonDataService.localizationLabelList['save_error']
        });
        break;
    }
  }
}
