import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Issuer } from 'src/app/modules/auxiliary/entities/issuer';
import { IvaBreakdown } from 'src/app/modules/auxiliary/entities/iva-breakdown';
import { Client } from 'src/app/modules/client/entities/client';
import { Series } from 'src/app/modules/client/entities/series';
import { ListNames, ModalNames } from 'src/app/modules/common/global';
import { toFixedNumber } from 'src/app/modules/common/services/calculations/calculation';
import { DeliveryNoteCalculationService } from 'src/app/modules/common/services/calculations/delivery-note-calculation.service';
import { DropdownService } from 'src/app/modules/common/services/dropdown.service';
import { ModalService } from 'src/app/modules/common/services/modal.service';
import { Toast } from 'src/app/modules/common/services/toast.service';
import { DeliverynoteService } from 'src/app/modules/delivery-note/services/deliverynote.service';
import swal from 'sweetalert2';
import { NumberingProcess, NumberingProcessData } from '../../../../shared/numbering-process/numbering-process';
import { NumberingProcessService } from '../../../../shared/numbering-process/numbering-process.service';
import { Article } from '../../../articles/entities/article';
import { ApiService } from '../../../common/services/api.service';
import { CommonDataService } from '../../../common/services/common.data.service';
import { CommonUIService } from '../../../common/services/common.ui.service';
import { Delay } from '../../../common/services/validators';
import { WorkType } from '../../../work-type/entities/work-type';
import { Budget } from '../../entities/budget';
import { SendEmail } from '../../entities/emailModel';
import {
  GenerateNumberModel,
  SavedGeneratedNumber
} from '../../entities/generate-number-model';
import { MainWorkType } from '../../entities/main-work-type';
import { MwtArticle } from '../../entities/main-work-type-article';
import { SubWork } from '../../entities/sub-work';
import { Work } from '../../entities/work';
import { BudgetService } from '../../services/budget.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'budgets',
  templateUrl: './budgets.component.html',
  styleUrls: ['./budgets.component.scss']
})
export class BudgetsComponent implements OnInit, OnDestroy {
  private transferableObj: any = {};

  // subscriptions
  private workService: Subscription;

  // form goups
  buFormGroup: FormGroup;

  // lists
  selectedMainWorkTypeList: MainWorkType[] = [];
  budgetList: Budget[] = [];
  deliveryMwtArticleList: MwtArticle[] = [];
  seriesList: Series[] = [];
  issureList: Issuer[] = [];
  savedGeneratedNumberList: SavedGeneratedNumber[] = [];
  articleList: Article[] = [];
  workTypeList: WorkType[] = [];
  ivaBreakdownList: IvaBreakdown[] = [];

  // objects
  budget: Budget;
  issuer: Issuer;
  client: Client;
  selectedWork: Work;
  selectedSubWork: SubWork;
  selectedMainWorkType: MainWorkType;
  mainWorkTypeList: MainWorkType[];
  mwtArticle: MwtArticle;
  usedSeries: Series;
  generateNumberModel: GenerateNumberModel;
  hasLoaded = true;
  isArticleListLoaded = true;
  numOfDigits = 5;
  emailmodel: SendEmail;

  currentNPData: NumberingProcessData;
  buNumberingProcess: NumberingProcess;
  buSeries: {
    id: number;
    type: 'Work' | 'Delivery Note' | 'Bill' | 'Budget';
    series: string;
  };
  buMaxSize = 5;

  showPopUp = false;
  subTotal: number;
  totalDiscount: number;
  totalVatAmount: number;

  constructor(
    private _workService: WorkService,
    private _apiService: ApiService,
    private __service: BudgetService,
    public commonUiService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _numberingProcessService: NumberingProcessService,
    private _formBuilder: FormBuilder,
    private _toastyService: Toast,
    private _modalService: ModalService,
    private _dropdownService: DropdownService,
    private _deliverynoteService: DeliverynoteService,
    private _deliveryNoteCalculationService: DeliveryNoteCalculationService
  ) {
    this.buFormGroup = this._formBuilder.group({
      isNextAvailableNumber: [],
      nextAvailableNumber: [],
      isAnother: [],
      anotherNumber: [],
      isOtherAvailableNumbers: [],
      otherAvailableNumber: [],
      isNoNumberAssigned: []
    });

    this.workService = this._workService.detailObject.subscribe(args => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (
          args.invokeComponents.includes('Budget') ||
          args.invokeComponents.includes('*')
        ) {
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this.selectedWork = args.selectedWork;
          this.selectedSubWork = args.selectedSubWork;
          this.selectedMainWorkType = args.selectedMainWorkType;
          this.mainWorkTypeList = args.mainWorkTypeList;
          this.transferableObj = args;
          this.SyncBudgetData();
        }
      }
    });
  }

  ngOnInit() {
    this.budget = new Budget();
    this.issuer = new Issuer();
    this.client = new Client();
    this.usedSeries = new Series();
    this.selectedWork = new Work();
    this.generateNumberModel = new GenerateNumberModel();
    this.mwtArticle = new MwtArticle();
    this.emailmodel = new SendEmail();
    this.InitDropdowns();
    this.buNumberingProcess = new NumberingProcess({
      isNextAvailableNumber: true,
      nextAvailableNumber: 0,
      isAnother: false,
      isNoNumberAssigned: false,
      isOtherAvailableNumbers: false,
      anotherNumber: 1,
      otherAvailableNumber: 0
    });

    this._modalService.modalSubject.subscribe(modalObject => {
      if (modalObject.modal === ModalNames.BudgetModal && !modalObject.flag) {
        this.SyncBudgetData();
      }
    });
  }

  private InitDropdowns() {
    try {
      this._dropdownService.LoadList([
        ListNames.ArticleList,
        ListNames.IssuerList,
        ListNames.TownList
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  ngOnDestroy() {
    this.workService.unsubscribe();
  }

  private async SyncBudgetData() {
    this.budgetList = [];
    try {
      this.hasLoaded = false;
      const idList = this.selectedMainWorkTypeList.map(m => ({
        id: Number(m.id)
      }));
      if (idList) {
        let budgetList = await this.__service.GetBudgetListByMainWorkTypeIdList(idList);
        budgetList = budgetList ? budgetList : [];
        this._workService.budgetData = budgetList;
        if (budgetList.length > 0) {
          for (let index = 0; index < budgetList.length; index++) {
            const budget = budgetList[index];
            const currentMainWorkType = this.selectedMainWorkTypeList.find(
              m => Number(m.id) === budget.mainWorktypeId
            );
            if (currentMainWorkType) {
              budget.cssClass = currentMainWorkType.cssClass;
            }
          }
        }
        this.budgetList = budgetList;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.hasLoaded = true;
    }
  }

  private async RemoveBudget(budgetId: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._apiService
        .delete(API.budget.deleteBudget, budgetId.toString())
        .subscribe(
          response => resolve(response['_data']),
          err => reject(Error(err))
        );
    });
  }

  //#region Events

  async OnOpenGenerateNumberForBudgetModal(modal: string) {
    try {
      if (this.budget.seriesId) {
        const series = await this._workService.GetSeriesById(
          Number(this.budget.seriesId)
        );
        await this.LoadBuNumberingProcessComponent(series);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.commonUiService.openModal(modal);
    }
  }

  OnRemoveBudgetModal(budgetId: number) {
    if (budgetId) {
      swal({
        title: this._commonDataService.localizationLabelList["budget"],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList[
          'common_yes'
        ],
        cancelButtonText: this._commonDataService.localizationLabelList[
          'common_cancel'
        ]
      }).then(result => {
        if (result.value) {
          this.commonUiService.isSpinnerVisible = true;
          this.RemoveBudget(budgetId).then(isDeleted => {
            if (isDeleted) {
              this.SyncBudgetData();
              this._toastyService.success({
                title: this._commonDataService.localizationLabelList["budget"],
                msg: this._commonDataService.localizationLabelList['delete_success']
              });
            } else {
              this._toastyService.error({
                title: this._commonDataService.localizationLabelList["budget"],
                msg: this._commonDataService.localizationLabelList['delete_error']
              });
            }
            this.commonUiService.isSpinnerVisible = false;
          });
        }
      });
    }
  }

  OnKey(event, target) {
    target.className = 'ng-cus-focus';
    target.focus();
  }

  async OnPrintBudget(budget: Budget) {
    if (budget) {
      this.budget = budget;
      this.calculate(this.budget);
      this.budget.issuer.issuerTown = budget.issuer ? this._dropdownService.townList.find(x => x.id == budget.issuer.townId).description : '';
      this.budget.issuer.provinceName = budget.issuer ? this._dropdownService.townList.find(x => x.id == budget.issuer.townId).provinceName : ''

      if (this.selectedWork.clientId) {
        const client = await this._workService.GetClientById(
          this.selectedWork.clientId
        );
        if (client) {
          this.client = client;
          await Delay(300);
          this.commonUiService.PrintHtmlReport('budget-section');
        }
      }
    }
  }

  private calculate(budget: Budget) {
    if (budget) {
      this.subTotal = 0;
      this.totalDiscount = 0;
      this.totalVatAmount = 0;
      if (budget.articleList) {
        this.subTotal = budget.subTotal;
        this.totalDiscount = budget.discount;
        this.totalVatAmount = budget.tax;
        this.loadVatBreakdown(budget.articleList);
      } else {
        this.ivaBreakdownList = [];
      }
    }
  }

  loadVatBreakdown(budgetArticleList: any[]) {
    this.ivaBreakdownList = [];
    if (budgetArticleList) {
      budgetArticleList.forEach(article => {
        if (article.ivaTypeId && article.ivaTypeId != 0) {
          const ivaBreakdown = new IvaBreakdown();
          ivaBreakdown.ivaTypeId = article.ivaTypeId;
          ivaBreakdown.iva = article.ivaPercentage + '%';
          ivaBreakdown.base = this._deliveryNoteCalculationService.getIvaBaseAmount(article.priceWithDiscount, article.quantityWithWaste);
          ivaBreakdown.ivaAmount = toFixedNumber(article.ivaAmount);
          this.ivaBreakdownList.push(ivaBreakdown);
        }
      });
      if (this.ivaBreakdownList && this.ivaBreakdownList.length > 0) {
        this.ivaBreakdownList = this._deliverynoteService.LoadVatBreakdown(this.ivaBreakdownList);
      }
    }
  }

  private async LoadBuNumberingProcessComponent(series: Series) {
    try {
      if (series) {
        this.buSeries = {
          id: series.id,
          type: 'Budget',
          series: series.series
        };
        this.buNumberingProcess = new NumberingProcess({
          isNextAvailableNumber: true,
          nextAvailableNumber: series.lastNumber,
          isAnother: false,
          isNoNumberAssigned: false,
          isOtherAvailableNumbers: false,
          anotherNumber: 1,
          otherAvailableNumber: 0
        });
        const reservedNumberList = await this._workService.GetSavedNumberListBySeriesId(
          series.id
        );
        if (reservedNumberList) {
          this.buNumberingProcess.otherAvailableNumberList = reservedNumberList.map(
            r => r.savedNumber.toString()
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async OnBudgetNumberGenerated(data: NumberingProcessData) {
    try {
      this.commonUiService.isSpinnerVisible = true;
      if (data) {
        if (data.generateNumber === 'same_number') {
          this._toastyService.error({
            title: this._commonDataService.localizationLabelList['budget'],
            msg: this._commonDataService.localizationLabelList['another_no_is_equal_to_next_no']
          });
        } else {
          const isEligible = await this._numberingProcessService.CheckGenerateNumber(
            data
          );
          if (isEligible) {
            if (data.genNumber > this.buNumberingProcess.nextAvailableNumber) {
              swal({
                title: this._commonDataService.localizationLabelList['budget'],
                text:
                  'There are ' +
                  (data.genNumber -
                    this.buNumberingProcess.nextAvailableNumber) +
                  ' numbers will be left blank. Are you sure want to continue?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this._commonDataService
                  .localizationLabelList['common_yes'],
                cancelButtonText: this._commonDataService.localizationLabelList[
                  'common_cancel'
                ]
              }).then(async result => {
                if (result.value) {
                  this.budget.generateNo = data.generateNumber;
                  this.currentNPData = data;
                } else {
                  this.commonUiService.openModal('budget-numbering-process');
                }
              });
            } else {
              this.budget.generateNo = data.generateNumber;
              this.currentNPData = data;
            }
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['budget'],
              msg: this._commonDataService.localizationLabelList['invalid_gen_number_or_existing']
            });
            this.commonUiService.openModal('budget-numbering-process');
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  //#endregion

  private OnOpenAddBudgetModal(modal: string) {
    this.showPopUp = true;
    this.transferableObj.isEdit = false;
    this._modalService.Invoke(modal, true, this.transferableObj);
  }

  private OnOpenEditBudgetModal(modal: string, budget: Budget) {
    this.transferableObj.budget = {};
    this.showPopUp = true;
    this.transferableObj.budget = budget;
    this.transferableObj.isEdit = true;
    this._modalService.Invoke(modal, true, this.transferableObj);
  }

  //#region send email

  /**
   * get fired when open email compose popup load
   * */
  async OnSendEmailModalOpen(budget: Budget) {
    try {
      this.emailmodel = new SendEmail();
      const user = this._commonDataService.GetUserFromSession();
      this.commonUiService.isSpinnerVisible = true;
      if (budget) {
        this.budget = budget;
        this.calculate(this.budget);
        this.budget.issuer.issuerTown = budget.issuer ? this._dropdownService.townList.find(x => x.id == budget.issuer.townId).description : '';
        this.budget.issuer.provinceName = budget.issuer ? this._dropdownService.townList.find(x => x.id == budget.issuer.townId).provinceName : ''
        if (this.selectedWork.clientId) {
          const client = await this._workService.GetClientById(
            this.selectedWork.clientId
          );
          if (client) {
            this.client = client;
            this.emailmodel.receiverEmail = this.client.email != null ? this.client.email : null;
          }
        }

        this.emailmodel.password = user != null && user.userRolePassword != null ? user.userRolePassword : null;
        this.emailmodel.senderEmail = user != null && user.userRoleEmail != null ? user.userRoleEmail : null;
        this.emailmodel.subject = this._commonDataService.localizationLabelList['budget'] + ' - (' + budget.generateNo + ')';
        this.emailmodel.message = this._commonDataService.localizationLabelList['budget'] + ' - (' + budget.generateNo + ')';
        this.emailmodel.attachmentName = this._commonDataService.localizationLabelList['budget'] + ' - (' + budget.generateNo + ')' +
          '.pdf';

        if (!this.emailmodel.senderEmail && !this.emailmodel.receiverEmail) {
          return this._toastyService.warning({
            title: this._commonDataService.localizationLabelList['email'],
            msg: this._commonDataService.localizationLabelList['sender_recipient_missing'],
          });
        } else if (!this.emailmodel.senderEmail) {
          return this._toastyService.warning({
            title: this._commonDataService.localizationLabelList['email'],
            msg: this._commonDataService.localizationLabelList['sender_missing'],
          });
        } else if (!this.emailmodel.receiverEmail) {
          swal({
            title: this._commonDataService.localizationLabelList['email'],
            text: this._commonDataService.localizationLabelList['recipient_missing'],
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
            cancelButtonText: this._commonDataService.localizationLabelList['common_cancel'],
          }).then((result) => {
            if (result.value) {
              this.commonUiService.OpenBasicModal('send-email');
              window.scrollTo(0, 0);
            } else {
              return;
            }
          });
        } else {
          this.commonUiService.OpenBasicModal('send-email');
          window.scrollTo(0, 0);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.commonUiService.isSpinnerVisible = false;
    }
  }

  /**
   * get fired when open email compose popup close
   * */
  OnSendEmailModalClose() {
    this.commonUiService.CloseBasicModal('send-email');
  }

  /**
   * get fired when sending the mail
   * */
  async OnSendEmail() {
    if (!this.emailmodel.receiverEmail) {
      return this._toastyService.error({
        title: this._commonDataService.localizationLabelList['email'],
        msg: this._commonDataService.localizationLabelList['recipient_missing'],
      });
    } else {
      this.OnSendEmailModalClose();
      this.commonUiService.isSpinnerVisible = true;
      if (this.budget) {
        await Delay(300);
        const base64String = await this.commonUiService.ConvertHtmlToPdfBase64('budget-section');
        if (base64String) {
          this.emailmodel.base64String = btoa(base64String);
          const response = await this.commonUiService.sendEmailRequest(this.emailmodel);
          if (response) {
            const body = JSON.parse(response['_body']);
            switch (body.status) {
              case 200:
                if (body.isSent) {
                  this.commonUiService.isSpinnerVisible = false;
                  return this._toastyService.success({
                    title: this._commonDataService.localizationLabelList['email'],
                    msg: this._commonDataService.localizationLabelList['email_send_succesfully'],
                  });
                } else {
                  this.commonUiService.isSpinnerVisible = false;
                  return this._toastyService.error({
                    title: this._commonDataService.localizationLabelList['email'],
                    msg: this._commonDataService.localizationLabelList['email_failed'],
                  });
                }
              case 404:
                this.commonUiService.isSpinnerVisible = false;
                return this._toastyService.error({
                  title: this._commonDataService.localizationLabelList['email'],
                  msg: this._commonDataService.localizationLabelList['email_failed'],
                });
            }
          } else {
            this.commonUiService.isSpinnerVisible = false;
            return this._toastyService.error({
              title: this._commonDataService.localizationLabelList['email'],
              msg: this._commonDataService.localizationLabelList['email_failed'],
            });
          }
        }
      }
    }
  }

  //#endregion
  OnCloseModal(event) { }
}
