import API from './../../../../common/api.config.json';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import { Router } from '@angular/router';
import { Process } from '../../../entities/process.js';
import { CommonDataService } from '../../../../common/services/common.data.service';
import { ShowInvalidFormControls } from '../../../../common/services/validators';
import { CommonFunctions } from 'src/app/modules/common/services/common-functions';
import { Toast } from 'src/app/modules/common/services/toast.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'process-list',
  templateUrl: './process.component.html'
})
export class ProcessComponent implements OnInit {

  process: Process;
  isLoad: boolean;
  successMessage: any;
  updateMessage: any;
  errorMessage: any;
  deleteMessage: any;
  statusMessage: any;
  deleteErrorMessage: any;
  tableName: any;
  processFormGroup: FormGroup;
  processForm: NgForm;
  processList: Process[];
  private _processList: Process[] = [];

  constructor(
    private _router: Router,
    private _apiService: ApiService,
    private _fb: FormBuilder,
    private _commonUIService: CommonUIService,
    private _commonDataService: CommonDataService,
    private _toastyService: Toast) {
    this.process = new Process();
    this.isLoad = false;
  }

  ngOnInit() {
    this.processFormGroup = this._fb.group({
      name: ['', [Validators.required]],
      processTime: ['', [Validators.minLength(4)]]
    });
    this.LoadProcessList();
    this.process = new Process();
  }

  LoadProcessList() {
    this._apiService.get(API.fabrication_process.getFabricationProcessList)
      .subscribe(res => {
        this.processList = res;
        this._processList = res;
        this.isLoad = true;
      });
  }

  LoadEditProcess(process: Process) { process.isEdit = true; }

  RemoveEditProcesse(process: Process) {
    process.isEdit = false;
    this.LoadProcessList();
  }

  ActiveDeactiveProcess(process: Process) {
    if (process) {
      process.status = !process.status;
      this.EditProcess(process, true);
    }
  }

  EditProcess(process: Process, statusChange: boolean) {
    process.isEdit = false;
    this.statusMessage = this._commonDataService.localizationLabelList['status_updated'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.tableName = this._commonDataService.localizationLabelList['resource'];
    if (process.processTime) {
      if (!process.processTime.toString().includes(':')) {
        process.processTime = CommonFunctions.Get24HoursTimeFormat(process.processTime);
      }
    }
    this._apiService.put(API.fabrication_process.editFabricationProcess + process.id, process, false)
      .subscribe(
        res => {
          if (res.status === 204 || res.status === 200) {
            this._toastyService.success({
              title: this.tableName,
              msg: this.updateMessage
            });
            this.LoadProcessList();
          }
        },
        err => { console.log(err); }
      );

  }

  SaveProcess(process: Process, event) {
    if (this.processFormGroup.invalid) { ShowInvalidFormControls(this.processFormGroup); return; }
    this.OnCloseProcessModal(event);
    if (process.processTime) {
      if (!process.processTime.toString().includes(':')) {
        process.processTime = CommonFunctions.Get24HoursTimeFormat(process.processTime);
      }
    }

    this._apiService.post(API.fabrication_process.saveFabricationProcess, process, false)
      .subscribe(
        res => {
          if (res.status === 204 || res.status === 200) {
            this._toastyService.success({
              title: this.tableName,
              msg: this.successMessage
            });
            this.LoadProcessList();
          }
        },
        err => {
          console.log(err);
          this._toastyService.error({
            title: this.tableName,
            msg: err
          });
        }
      );
  }

  DeleteProcess(id: number) {
    this.deleteMessage = this._commonDataService.localizationLabelList['delete_success'];
    this.deleteErrorMessage = this._commonDataService.localizationLabelList['delete_error'];
    this.tableName = this._commonDataService.localizationLabelList['resource'];
    if (id) {
      this._apiService.delete(API.fabrication_process.deleteFabricationProcess, id.toString())
        .subscribe(
          res => {
            if (res._statusCode === 200) {
              const index: number = this._processList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._processList.splice(index, 1);
                this._toastyService.success({
                  title: this.tableName,
                  msg: this.deleteMessage
                });
              }
            }
            if (res._statusCode == 409) {
              const index: number = this._processList.findIndex(e => e.id === id);
              if (index !== -1) {
                this._toastyService.warning({
                  title: this.tableName,
                  msg: this.deleteErrorMessage
                });
              }
            }
          },
          err => {
            this._toastyService.error({
              title: this.tableName,
              msg: err
            });
          },
          () => { }
        );
    }
  }

  OnOpenAddProcessModal() {
    this.successMessage = this._commonDataService.localizationLabelList['save_success'];
    this.updateMessage = this._commonDataService.localizationLabelList['update_success'];
    this.errorMessage = this._commonDataService.localizationLabelList['save_error'];
    this.tableName = this._commonDataService.localizationLabelList['resource'];
    this.process = new Process(); this.processFormGroup.reset();
    this._commonUIService.openModal('process-modal');
  }

  OnCloseProcessModal(event) {
    (((((event.target.parentElement.parentElement).parentElement).parentElement).parentElement).parentElement).classList.remove('md-show');
  }
}
