import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastData, ToastyService } from 'ng2-toasty';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/modules/common/services/api.service';
import { CommonDataService } from 'src/app/modules/common/services/common.data.service';
import { CommonUIService } from 'src/app/modules/common/services/common.ui.service';
import swal from 'sweetalert2';
import { ModalService } from '../../../common/services/modal.service';
import { MainWorkType } from '../../entities/main-work-type';
import { Prescription } from '../../entities/prescription';
import { SubWork } from '../../entities/sub-work';
import { PrescriptionService } from '../../services/prescription.service';
import { WorkService } from '../../services/work.service';
import API from './../../../common/api.config.json';

@Component({
  selector: 'prescriptions',
  templateUrl: './prescriptions.component.html',
  styleUrls: ['./prescriptions.component.scss']
})
export class PrescriptionsComponent implements OnInit, OnDestroy {

  // subscriptions
  private workService: Subscription;

  // form groups
  //addPrescriptionFormGroup: FormGroup;

  // lists
  prescriptionList: Prescription[] = [];
  selectedMainWorkTypeList: MainWorkType[] = [];

  // objects
  prescription: Prescription;
  selectedSubWork: SubWork;
  hasLoaded = true;
  PDF = '../../../assets/images/config/pdf.png';
  PNG = '../../../assets/images/config/png.png';
  JPG = '../../../assets/images/config/jpg.png';
  JPEG = '../../../assets/images/config/jpg.png';
  XLS = '../../../assets/images/config/xls.png';
  DOC = '../../../assets/images/config/doc.png';

  showAddPopUp = false;
  showViewPopUp = false;

  constructor(private _workService: WorkService, private _apiService: ApiService, private _modalService: ModalService,
    private __service: PrescriptionService, public commonUiService: CommonUIService, private _commonDataService: CommonDataService,
    private _formBuilder: FormBuilder, private _toastyService: ToastyService, private _sanitizer: DomSanitizer) {
    // this.addPrescriptionFormGroup = this._formBuilder.group({ file: ['', Validators.required] });
    this.workService = this._workService.detailObject.share().subscribe((args) => {
      if (args.selectedMainWorkTypeList && args.invokeComponents) {
        if (args.invokeComponents.includes('Prescription') || args.invokeComponents.includes('*')) {
          this.selectedMainWorkTypeList = args.selectedMainWorkTypeList;
          this.selectedSubWork = args.selectedSubWork;
          this.SyncPrescriptionData();
        }
      }
    });
  }

  ngOnInit() { this.prescription = new Prescription(); }

  ngOnDestroy() { this.workService.unsubscribe(); }

  //#region CRUD

  private async SyncPrescriptionData() {
    try {
      if (this.selectedSubWork.id) {
        this.prescriptionList = [];
        let source: Prescription[] = [];
        if (this.selectedSubWork.id) {
          this.hasLoaded = false;
          const prescriptionList = await this.__service.GetPrescriptionListBySubWorkId(Number(this.selectedSubWork.id));
          if (prescriptionList) {
            for (let index = 0; index < prescriptionList.length; index++) {
              const prescription = prescriptionList[index];
              prescription.extension = prescription.fileName.split('.')[(prescription.fileName.split('.')).length - 1];
              prescription.link = this._apiService.BASE_URL + API.prescriptions.getPrescription + prescription.id + '/' + prescription.generatedFileName;
              if (prescription.url) {
                if (prescription.extension === 'pdf') {
                  prescription.url = prescription.url.toString().includes('data:application/pdf;base64,') ?
                    prescription.url : 'data:application/pdf;base64,' + prescription.url;
                }
                if (prescription.extension === 'png') {
                  prescription.url = prescription.url.toString().includes('data:image/png;base64,') ?
                    prescription.url : 'data:image/png;base64,' + prescription.url;
                }
                if (prescription.extension === 'jpg') {
                  prescription.url = prescription.url.toString().includes('data:image/jpg;base64,') ?
                    prescription.url : 'data:image/jpg;base64,' + prescription.url;
                }
                if (prescription.extension === 'jpeg') {
                  prescription.url = prescription.url.toString().includes('data:image/jpeg;base64,') ?
                    prescription.url : 'data:image/jpeg;base64,' + prescription.url;
                }
                if (prescription.extension === 'docx') {
                  prescription.url = prescription.url.toString().includes('https://docs.google.com/gview?url=') ?
                    prescription.url : 'https://docs.google.com/gview?url=' + prescription.link + '&embedded=true';
                }
                if (prescription.extension === 'xlsx' || prescription.extension === 'xls') {
                  prescription.url = prescription.url.toString().includes('https://docs.google.com/gview?url=') ?
                    prescription.url : 'https://docs.google.com/gview?url=' + prescription.link + '&embedded=true';
                }
              }
            }
            source = source.concat(prescriptionList);
          }
          this.prescriptionList = source;
        }
      }
    } catch (error) { console.log(error); }
    finally { this.hasLoaded = true; }
  }

  private RemovePrescription(id: number) {
    return this._apiService.delete(API.prescriptions.deletePrescription, id.toString(), true).map(x => x as boolean).toPromise();
  }

  //#endregion

  //#region Events

  OnOpenAddPrescriptionModal(modal: string) {
    if (modal) {
      this.showAddPopUp = true;
      this.prescription = new Prescription();
      this.commonUiService.isPopupOpened = true;
      this._modalService.Invoke(modal, true, {
        selectedSubWork: this.selectedSubWork
      }, false);
    }
  }

  async AfterPrescriptionSaved() {
    await this.SyncPrescriptionData();
  }

  OnOpenPreviewPrescriptionModal(modal: string, prescription: Prescription) {
    if (modal && prescription) {
      this.prescription = prescription;
      this.prescription.safeUrl = this.prescription.url ? this._sanitizer.bypassSecurityTrustResourceUrl(this.prescription.url) : undefined;
      this.commonUiService.isPopupOpened = true;
      this.showViewPopUp = true;
      this._modalService.Invoke(modal, true, {
        prescription: this.prescription
      }, false);
    }
  }

  OnClickDocument(prescription: Prescription) { if (document) { window.open(prescription.link, '_blank'); } }

  OnRemovePrescription(id: number) {
    if (id) {
      swal({
        title: this._commonDataService.localizationLabelList['prescription'],
        text: this._commonDataService.localizationLabelList['delete_warning'],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this._commonDataService.localizationLabelList['common_yes'],
        cancelButtonText: this._commonDataService.localizationLabelList['common_cancel']
      }).then(async (result) => {
        if (result.value) {
          this.commonUiService.isSpinnerVisible = true;
          const isDeleted = await this.RemovePrescription(id);
          if (isDeleted) {
            this._toastyService.success({
              title: this._commonDataService.localizationLabelList['prescription'],
              msg: this._commonDataService.localizationLabelList['delete_success'],
              showClose: true,
              theme: 'default',
              timeout: 3000,
              onAdd: (toast: ToastData) => { },
              onRemove: (toast: ToastData) => { }
            });
            this.SyncPrescriptionData();
          } else {
            this._toastyService.error({
              title: this._commonDataService.localizationLabelList['prescription'],
              msg: this._commonDataService.localizationLabelList['delete_error'],
              showClose: true,
              theme: 'default',
              timeout: 3000,
              onAdd: (toast: ToastData) => { },
              onRemove: (toast: ToastData) => { }
            });
          }
          this.commonUiService.isSpinnerVisible = false;
        }
      });

    }
  }

  //#endregion
}
