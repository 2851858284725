import { Injectable } from '@angular/core';
import { CommonFunctions } from '../../common/services/common-functions';
import { ApiService } from '../../common/services/api.service';
import API from './../../common/api.config.json';
import { MwtImplantData } from '../entities/main-work-type-implant-data';

@Injectable({
  providedIn: 'root'
})
export class ImplantDataService {

  constructor(private _apiService: ApiService) { }

  GetImplantDataListByMainWorkTypeIdList(idList: { id: number }[]) {
    try {
      const params = CommonFunctions.GetUrlParamerizedObjectByList(idList);
      if (params) {
        const query = params.toString().replace('+', '%20');
        return this._apiService.get(API.work_panels.getImplantDataListByMainWorkTypeIdList + query, true)
          .map(p => p as MwtImplantData[]).toPromise();
      }
    } catch (error) { console.log(error); }
  }
}
